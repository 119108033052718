import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisFilterCut } from 'api/interfaces';
import * as React from 'react';
import FilterMenuItem from './FilterMenuItem';
import './list-filter.scss';

export interface ListFilterProps {
  filterName: string;
  cuts: AnalysisFilterCut[];
  activeCutIds: string[];
  onChange: (cut: AnalysisFilterCut) => void;
}

class ListFilter extends React.Component<ListFilterProps, {}> {
  render() {
    const { cuts, filterName, activeCutIds, onChange } = this.props;
    return (
      <div
        className="list-filter"
        role="listbox"
      >
        {cuts.map((cut) => {
          const isActive = cut.id && activeCutIds.includes(cut.id);
          return (
            <FilterMenuItem
              key={cut.id}
              itemName={`${filterName}:${cut.name}`}
              onClick={() => onChange(cut)}
            >
              {isActive && <FontAwesomeIcon icon="check" />}
              <span className="option-name" style={{ WebkitBoxOrient: 'vertical' }}>
                {cut.name}
              </span>
            </FilterMenuItem>
          );
        })}
      </div>
    );
  }
}

export default ListFilter;
