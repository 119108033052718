<template>
  <div class="dashboard">
    Sorry, you don’t have permissions to view this dashboard.
  </div>
</template>

<script>
export default {
  name: 'DashboardUnknown',
  props: {
    config: { type: Object }
  }
};
</script>

<style scoped>
.dashboard {
  text-align: center;
  padding: 20px;
}
</style>
