import { IntegrationType } from 'api/enums';
import { map, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceGoogleBigQueryCreateFormProps {
  existingConfiguration?: GoogleBigQueryConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface GoogleBigQueryProjectInfo {
  title: string;
  id: string;
  kind: string;
}
interface GoogleBigQueryDatasetInfo {
  title: string;
  id: string;
  kind: string;
}
interface GoogleBigQueryTableInfo {
  title: string;
  id: string;
  kind: string;
}

interface GoogleBigQueryConfig {
  projectId: string;
  datasetId?: string;
  tableId?: string;
}

export interface DataSourceGoogleBigQueryCreateFormState {
  selectedProject?: GoogleBigQueryProjectInfo;
  selectedDataset?: GoogleBigQueryDatasetInfo;
  selectedTable?: GoogleBigQueryTableInfo;
  config?: GoogleBigQueryConfig;

  availableProjects?: GoogleBigQueryProjectInfo[];
  availableDatasets?: GoogleBigQueryDatasetInfo[];
  availableTables?: GoogleBigQueryTableInfo[];
}

@observer
class DataSourceGoogleBigQueryCreateForm extends React.Component<
  DataSourceGoogleBigQueryCreateFormProps,
  DataSourceGoogleBigQueryCreateFormState
> {
  state = {} as DataSourceGoogleBigQueryCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedProject = (_e, { value }: FormInputProps) => {
    const selectedProject = JSON.parse(value);

    const config = { projectId: selectedProject.id };

    this.setState({ selectedProject, config });

    if (this.props.setValidity) {
      this.props.setValidity(false); // need dataset and table
    }
  }

  changeSelectedDataset = (_e, { value }: FormInputProps) => {
    let { config } = this.state;
    const selectedDataset = JSON.parse(value);

    // can't set a dataset if the project hasn't been set
    if (!config || !config.projectId) {
      return;
    }

    // explicitly ensure table isn't set
    config = {
      projectId: config.projectId,
      datasetId: selectedDataset.id
    };

    this.setState({ selectedDataset, config });

    if (this.props.setValidity) {
      this.props.setValidity(false); // still need a table
    }
  }

  changeSelectedTable = (_e, { value }: FormInputProps) => {
    let { config } = this.state;
    const selectedTable = JSON.parse(value);

    // can't set a dataset if the project hasn't been set
    if (!config || !config.projectId) {
      return;
    }

    // should now have everything
    config = {
      projectId: config.projectId,
      datasetId: config.datasetId,
      tableId: selectedTable.id
    };

    this.setState({ selectedTable, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedTable.id); // all fields are set
    }
  }

  onEnumeration = (availableItems: GoogleBigQueryProjectInfo[] | GoogleBigQueryDatasetInfo[] | GoogleBigQueryTableInfo[]): void => {
    const { config } = this.state;
    if (!config || !config.projectId) {
      this.setState({ availableProjects: availableItems });
    }
    else if (!config.datasetId) {
      this.setState({ availableDatasets: availableItems });
    }
    else if (!config.tableId) {
      this.setState({ availableTables: availableItems });
    }
  }

  setDefaultProject = (availableProjects: GoogleBigQueryProjectInfo[]): void => {
    const { selectedProject } = this.state;
    const { existingConfiguration } = this.props;

    if (availableProjects.length === 0 || !existingConfiguration || selectedProject) {
      return;
    }

    const defaultSelectedProject = this.getExistingProject(availableProjects, existingConfiguration);
    if (!defaultSelectedProject) {
      return;
    }

    this.setState({
      selectedProject: defaultSelectedProject,
      config: existingConfiguration,
    });
  }

  getExistingProject = (
    availableProjects: GoogleBigQueryProjectInfo[],
    existingConfiguration?: GoogleBigQueryConfig
  ) => {
    return availableProjects.find(({ id }) => id == existingConfiguration?.projectId);
  }

  getExistingDataset = (
    availableDatasets: GoogleBigQueryProjectInfo[],
    existingConfiguration?: GoogleBigQueryConfig
  ) => {
    return availableDatasets.find(({ id }) => id == existingConfiguration?.datasetId);
  }

  getExistingTable = (
    availableTables: GoogleBigQueryProjectInfo[],
    existingConfiguration?: GoogleBigQueryConfig
  ) => {
    return availableTables.find(({ id }) => id == existingConfiguration?.tableId);
  }

  renderForm(): JSX.Element | null {
    const { selectedProject, selectedDataset, availableProjects, availableDatasets, availableTables } = this.state;
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableProjectOptions = map(availableProjects, item => {
      return {
        text: item.title,
        value: JSON.stringify(item)
      };
    });
    const sortedAvailableProjectOptions = sortBy(availableProjectOptions, 'text');

    const availableDatasetOptions = map(availableDatasets, item => {
      return {
        text: item.title,
        value: JSON.stringify(item)
      };
    });

    const availableTableOptions = map(availableTables, item => {
      return {
        text: item.title,
        value: JSON.stringify(item)
      };
    });

    if (availableProjects) {
      const defaultProjectValue = existingConfiguration
        ? JSON.stringify(this.getExistingProject(availableProjects, existingConfiguration))
        : undefined;

      const defaultDatasetValue = existingConfiguration
        ? JSON.stringify(this.getExistingDataset(availableProjects, existingConfiguration))
        : undefined;

      const defaultTableValue = existingConfiguration
        ? JSON.stringify(this.getExistingTable(availableProjects, existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Project</label>

            <Dropdown
              selection={true}
              search={true}
              options={sortedAvailableProjectOptions}
              defaultValue={defaultProjectValue}
              placeholder="Select"
              onChange={this.changeSelectedProject}
            />
          </Form.Field>
          {selectedProject && (
            <Form.Field>
              <label>Dataset</label>

              <Dropdown
                selection={true}
                search={true}
                options={availableDatasetOptions}
                defaultValue={defaultDatasetValue}
                placeholder="Select"
                onChange={this.changeSelectedDataset}
              />
            </Form.Field>
          )}
          {selectedProject && selectedDataset && (
            <Form.Field>
              <label>Table</label>

              <Dropdown
                selection={true}
                search={true}
                options={availableTableOptions}
                defaultValue={defaultTableValue}
                placeholder="Select"
                onChange={this.changeSelectedTable}
              />
            </Form.Field>
          )}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={this.onEnumeration}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.GOOGLEBIGQUERY}
          enumerationSelection={config} />
      </div>
    );
  }
}

export default DataSourceGoogleBigQueryCreateForm;
