import {
  DashboardWidgetType, WidgetSortValue
} from 'api/enums';
import { AnalysisConfig, Widget } from 'api/interfaces';
import { find } from 'lodash';
import config from 'runtime-config';
import { DashboardJson } from 'stores/DashboardStore';
import { canNavigate } from './navigation-helpers';

export function getConfigUrl(dashboardId: string) {
  return `${ config.apiLocation }/dashboard/${ dashboardId }/config`;
}

export function canClickTheme(options: {
  source: string,
  isWeighted: boolean,
  isCommentsEnabled: boolean,
  isSummaryEnabled: boolean
}) {
  const { source, isWeighted, isCommentsEnabled, isSummaryEnabled } = options;
  const widgetCanNavigate = canNavigate({ source, isWeighted });
  return widgetCanNavigate || isCommentsEnabled || isSummaryEnabled;
}

export const SUPPORTED_DASHBOARD_TYPES = {
  dashboard1: 'dashboard1',
  editable: 'editable'
};

export function calculateDefaultDashboard(dashboards: DashboardJson[]): string | undefined {
  const foundDashboard = find(dashboards, dashboard => {
    return dashboard.version in SUPPORTED_DASHBOARD_TYPES;
  });
  if (foundDashboard) {
    return String(foundDashboard.id);
  } else {
    return undefined;
  }
}

export function createDefaultDashboardWidgets(configurations: { id: string, config: AnalysisConfig }[]) {

  let widgets = [] as Widget[];

  const includeTitles = configurations.length > 1;

  configurations.forEach(source => {
    const sourceConfig = source.config;
    const sourceId = source.id;
    const score = sourceConfig.scores && sourceConfig.scores.length > 0 ? sourceConfig.scores[0] : undefined;
    const hasDate = sourceConfig.date_column !== undefined;

    if (score !== undefined) {
      widgets.push({
        source: sourceId,
        config: {
          type: DashboardWidgetType.KEY_TAKEAWAYS,
          title: includeTitles ? sourceConfig.title : undefined,
          score
        }
      });
      widgets.push({
        source: sourceId,
        config: {
          type: DashboardWidgetType.SCORE,
          title: includeTitles ? sourceConfig.title : undefined,
          score
        }
      });

      if (hasDate !== undefined) {
        widgets.push({
          source: sourceId,
          config: {
            type: DashboardWidgetType.SCORE_OVERTIME,
            title: includeTitles ? sourceConfig.title : undefined,
            score
          }
        });
        widgets.push({
          source: sourceId,
          config: {
            type: DashboardWidgetType.SCORE_CHANGES,
            title: includeTitles ? sourceConfig.title : undefined,
            score
          }
        });

      }

      widgets.push({
        source: sourceId,
        config: {
          type: DashboardWidgetType.IMPACT_SIMPLE,
          title: includeTitles ? sourceConfig.title : undefined,
          sort: WidgetSortValue.ASC,
          score
        }
      });
      widgets.push({
        source: sourceId,
        config: {
          type: DashboardWidgetType.IMPACT_SIMPLE,
          title: includeTitles ? sourceConfig.title : undefined,
          sort: WidgetSortValue.DESC,
          score
        }
      });
    }
    widgets.push({
      source: sourceId,
      config: {
        type: DashboardWidgetType.THEMES1,
        title: includeTitles ? sourceConfig.title : undefined,
      }
    });

  });

  return widgets;
}
