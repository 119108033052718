<template>
  <div
    :data-thread-index="indexes[0]"
    :data-thread-part-index="indexes[1]"
    :class="{ expanded: expanded, narrow: isNarrow }"
    class="vertical-thread-part"
  >
    <div
      v-if="seen"
      :class="!hasThemes && !areSupportMessagesVisible ? 'collapsable-comments' : 'non-collapsable-comments'"
      @click="() => $emit('collapseParts')"
    />
    <div
      v-if="seen"
      class="vertical-thread-part__bd"
    >
      <div class="vertical-thread-part__author-info">
        <div class="vertical-thread-part__author">
          <font-awesome-icon
            v-if="authorIcon"
            :icon="authorIcon"
          />
          {{ authorText }}
        </div>

        <el-tooltip
          class="item"
          popper-class="vertical-comment-state-tooltip"
          effect="dark"
          placement="top"
        >
          <font-awesome-icon
            icon="copy"
            class="vertical-comment__copy-comment"
            @click="copyComment()"
            @mouseenter="copyButtonClicked = false"
          />
          <div
            slot="content"
            class="copy-comment-text-content"
          >
            <font-awesome-icon
              v-if="copyButtonClicked"
              icon="check-circle"
              class="vertical-comment__comment-copied"
            />
            <p>
              {{ copyButtonClicked ? 'Copied!' : 'Copy comment' }}
            </p>
          </div>
        </el-tooltip>
      </div>
      <div
        v-for="(threadBlocks, index) in computedThreadParts"
        :key="`thread-author-part-${index}`"
        class="vertical-thread-part__comment"
      >
        <span
          v-for="(block, blockIndex) in threadBlocks"
          :key="block.key"
          :class="{
            highlighted: block.isHighlighted,
            'theme-highlight-grey': block.hasThemes && hoverOnThemes && !block.isHighlighted && showDefaultHighlight,
            [toSentimentClassname(block.sentiment, analysisConfigStore.hasSentiment)]: true,
            [commentHighlightOverride]: true,
          }"
          @mouseover="() => $emit('hoverBlock', block)"
          @mouseenter="() => $emit('onEnterBlock', block)"
          @mouseleave="() => $emit('leaveBlock')"
          @mousedown="(e) => $emit('onTextSelectionStart')"
        >
          <comment-theme-popup
            :show-truncation="false"
            :block="block"
            :hover-on-themes="hoverOnThemes"
            :is-sentiment-enabled="analysisConfigStore.hasSentiment"
            :block-index="blockIndex"
            :can-manage-themes="canManageThemes"
            :popup-themes="findPopupThemeItems(block)"
            :are-themes-in-applying-state="areThemesInApplyingState"
            :is-popup-disabled="isDisabled || selectedPhrase.length > 0"
            @handleSentimentSelection="(value) => handleSentimentSelection(value, block, threadBlocks)"
            @removeTheme="(theme) => removeTheme(theme, block)"
            @persistHoveredBlock="(block) => $emit('persistHoveredBlock', block)"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  some,
} from 'lodash';
import analytics from 'lib/analytics';
import { getAnalysisConfigStore, getAnalysisToolsUIStore, getThemesStore } from 'stores/RootStore';
import {
  sentimentMap,
} from 'lib/calculate-sentiment';
import logSentiment from 'vue/libs/log-sentiment';
import updateSentiment from 'vue/libs/update-sentiment';
import { copyText } from "../../../lib/clipboard";
import toSentimentClassname from "vue/libs/to-sentiment-classname";
import segmentsToBlocks from "lib/segments-to-blocks.ts";
import CommentThemePopup from './CommentThemePopup.vue';
import { isThemeRemovedInPopup } from "vue/libs/edited-comment-helpers";
import { isNonSpecialTheme } from "lib/special-theme-predicates";

export default {
  name: 'VerticalCommentThreadPart',
  components: {
    CommentThemePopup
  },
  props: {
    seen: { type: Boolean },
    indexes: { type: Array },
    commentId: { type: [String, undefined], default: '' },
    commentColumnId: { type: Number },
    intersectionObserver: { default: null, type: IntersectionObserver },
    highlightedLocations: { type: Array },
    threadParts: { default: () => [], type: Array },
    highlightType: { default: '', type: String },
    hoverOnThemes: { default: true, type: Boolean},
    isDisabled: { default: false, type: Boolean },
    isNarrow: { default: false, type: Boolean },
    showDefaultHighlight: {default: true, type: Boolean},
    supportMessagesVisible: {default: false, type: Boolean},
    areThemesInApplyingState: {default: false, type: Boolean},
    selectedPhrase: {default: '', type: String}
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      themesStore: getThemesStore(),
      expanded: !this.isNarrow || this.alwaysExpanded,
      copyButtonClicked: false,
    };
  },
  computed: {
    /**
     * The highlightType is usually `all` that yields the default style
     * different values (passed in from config) let us control highlighting style
     */
    commentHighlightOverride() {
      const { highlightType } = this;
      if (highlightType === 'none') {
        return 'theme-sentiment-off';
      }
      return '';
    },
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    canSeeInlineThemesEditing() {
      return this.canManageThemes;
    },
    computedThreadParts() {

      return this.threadParts.map(
        pair => segmentsToBlocks(
          pair.threadedComment.text,
          pair.threadedComment.segments,
          pair.meta.partIndex,
          this.highlightedLocations,
        )
      );

    },
    hasThemes() {
      return this.computedThreadParts.some(parts => parts.some(part => part.hasThemes));
    },
    authorText() {
      const { threadParts } = this;
      const { threadDisplayConfig } = this.analysisConfigStore;
      if (threadDisplayConfig && threadDisplayConfig.author && threadParts.length > 0 && threadParts[0].meta.author) {
        return threadParts[0].meta.author;
      }
      return '';
    },
    authorIcon() {
      return 'user';
    },
    hasSentiment() {
      const { computedThreadParts } = this;
      return some(computedThreadParts, (threadParts => {
        return some(threadParts, (part =>
          part.sentiment !== undefined
        ))
      }));
    },
    areSupportMessagesVisible() {
      return this.analysisToolsUIStore.areSupportMessagesVisible || this.supportMessagesVisible;
    },
  },
  watch: {
    selectedPhrase: {
      handler() {
        this.isPhraseSelected();
      },
      immediate: true
    }
  },
  mounted() {
    if (this.intersectionObserver) {
      this.intersectionObserver.observe(this.$el);
    }
  },
  methods: {
    isPhraseSelected() {
      return this.selectedPhrase.length > 0;
    },
    toSentimentClassname,
    findPopupThemeItems(block) {

      return this.threadParts.reduce((result, pair) => {

        const outerThemeItems = pair.threadedComment.segments
          .filter(segment => block.hasThemes && segment.location[0] === block.location[0] && segment.location[1] === block.location[1])
          .reduce((acc, segment) => {

            const nonSpecialThemes = segment.themes.filter(isNonSpecialTheme);

            const themeItems = nonSpecialThemes.map(theme => {
              return {
                theme,
                sentiment: segment.sentiment,
                hasBackgroundColour: false,
                isNew: false,
                isRemoved: isThemeRemovedInPopup(this.commentId, theme, block)
              };
            });

            return [...acc, ...themeItems];

          }, []);

        return [...result, ...outerThemeItems];

      }, []);

    },
    removeTheme(theme, block) {
      this.$emit('onRemoveThemeFromPart', theme, block);
      analytics.track('Analysis: Remove Theme Started', { Location: 'Sentence Popup' });
    },
    onSentencePopupHide(blockIndex) {
      // making sure that sentiment popup closes as well when theme popover is closed
      const sentimentPopoverRef = this.$refs.sentencePopover[blockIndex].$children[0].$refs.sentimentPopover;
      if (sentimentPopoverRef) {
        sentimentPopoverRef.doClose();
      }
    },
    handleSentimentSelection(value, block, blocks) {

      const lastSentiment = block.sentiment;
      const surveyId = this.themesStore.surveyId;
      const comment = blocks.reduce((acc, part) => `${acc}${part.content}`, '');
      const commentId = this.commentId;
      const partIndex = block.partIndex;
      const columnId = this.commentColumnId;
      const sentiment = sentimentMap.get(value);
      const [start, end] = block.location;

      /* Inform backend */
      updateSentiment(surveyId, commentId, {
        column: columnId,
        part: partIndex,
        location: [start, end],
        sentiment
      });

      /* Inform data team */
      logSentiment({
        sentiment: value,
        lastSentiment,
        block,
        surveyId,
        orgId: this.themesStore.orgId,
        comment,
        commentId,
      });

      /* Inform frontend */
      this.$emit('onSentimentChange', {
        sentiment,
        block,
      });

    },
    copyComment() {
      analytics.track('Analysis: Copy Comment', { 'Type': 'Conversation' });
      copyText(this.threadParts.map(part => part.text).join("\n\n"));
      this.copyButtonClicked = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';

.vertical-thread-part {
  min-height: 3rem;
  background: var(--white);
  border-radius: $--border-radius-small;
  display: grid;
  grid-template-columns: 30px auto;

  &__bd {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    position: relative;
    word-break: break-word;
    span {
      @extend .theme-highlight-base;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 0;
      white-space: pre-wrap;
    }
  }
  .collapsable-comments {
    border-left: 3px dotted $--neutral-400;
    margin: 0px 15px 10px 10px;
    border-radius: 0;
    cursor: pointer;
    &:hover {
      border-left: 3px dotted mix(
        $--color-white,
        $--color-primary,
        $--button-hover-tint-percent
      );
    }
  }
  .non-collapsable-comments {
    border-left: solid 3px $--neutral-400;
    margin: 0px 15px 10px 10px;
  }
  &__author {
    cursor: text;
    svg {
      cursor: default;
      margin-right: 5px;
      color: $--neutral-400;
    }

    font-weight: 600;
  }
  &__comment {
    cursor: text;
    margin-bottom: 10px;
  }
  &__comment span.hidden-block {
      background: $--color-white;
      color: $--primary-500;
      padding: 0 3px;
      border-radius: 4px;
      margin: 0 5px;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
    }
}

.vertical-thread-part__author-info {
  width: 100%;

  display: grid;
  grid-template: auto / auto 1fr;
  justify-items: start;
  align-items: center;

  .vertical-comment__copy-comment {
    justify-self: end;

    color: $--primary-500;
    cursor: pointer;
  }
}

.vertical-thread-part .vertical-comment__copy-comment {
  opacity:0;
  transition: opacity 200ms ease-out;
}
.vertical-thread-part:hover .vertical-comment__copy-comment {
  opacity:1;
}

.copy-comment-text-content {
  font-size: 1rem;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  .vertical-comment__comment-copied {
    color: $--green-500;
  }
}
</style>

<style lang="scss">
.thread-part-inline-sentiment.el-popover {
  min-width: 0;
  // need to style popper arrow because this popover is inside another popover
  // and parent popover has placement left and child popover has placement bottom
  // but element io still reads parent popover's position for arrow, hence !important styles to override that
  .popper__arrow {
    top: -4px !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    &:after {
      transform: rotate(45deg);
    }
  }
}
</style>
