import { inRange, max, template } from 'lodash';
import { Filter, FilterSelections } from 'stores/FilterStore';
import { getExploreToolUIStore } from 'stores/RootStore';
import { QueryFilter } from 'stores/types';
import { ViewOverlay } from 'stores/ui/AnalysisToolsUIStore';
import { ScoreConfigJson } from 'stores/ui/ExploreToolUIStore';
import { BarChartInsight, ExportableInsight, InsightVis, LegendInsight } from 'types/custom';
import { Theme } from 'vue/explore/theme-data-functions';
import { Score } from 'vue/libs/score-to-segments';

type Field = BarChartInsight['fields'][number];

export type ExploreInsightParams = {
  exploreAxis: string,
  selectedScore?: ScoreConfigJson,
  baselineScores?: Score,
  comparisonScores?: Score,
  baseTotal: number,
  compTotal?: number,
  filters: Filter,
  selectedThemeName: string | null,
  selectedSubThemeName: string | null,
  themes: Array<Theme>,
  filterSelections: FilterSelections,
  viewOverlay: ViewOverlay
};

function extractLegendData(filter: Filter['baseline' | 'comparison']): { [key: string]: string } {
  if (!filter) {
    return {};
  }

  return Object.values(filter).reduce((result, qf: QueryFilter) => {

    if (!qf.filterName) {
      return result;
    }
    if (!qf.selected || qf.selected.length === 0) {
      return result;
    }

    const key = qf.filterName;
    const value = qf.selected.map(sel => sel.name).join(', ');

    result[key] = value;

    return result;

  }, {});
}

// This is used to check indexes
// - that match a target index
// - or the two nearest
//   - one before and one after the target,
//   - two after, given the target is at the start (0)
//   - two before, given the target is at the end (lastIndex)
export function isNearIndex(targetIndex: number, index: number, lastIndex: number): boolean {

  if (targetIndex === -1) { return false; }

  const startOffset = targetIndex === lastIndex ? 2 : 1;
  const endOffset = targetIndex === 0 ? 3 : 2;

  return inRange(index, targetIndex - startOffset, targetIndex + endOffset);

}

// This is used to select the highest volume, then rounds up to the next 10.
export function getCount(fields: Array<Field>): number {

  const highestVolume: number = fields.reduce((result: number, field: Field): number => {
    const top = max([result, field.baseline, field.comparison || 0]);
    return top || 0;
  }, 0);

  return highestVolume === 0
    ? 0
    : Math.ceil(highestVolume / 10) * 10;

}

function getLegendLabel(
  exploreAxis: string,
  responses: number,
  selectedScore?: ScoreConfigJson,
  scores?: Score
): string {
  if ((exploreAxis === 'score' || exploreAxis === 'impact') && selectedScore !== undefined && scores !== undefined) {
    return `${selectedScore.name} = ${scores.score.toFixed(1)} • ${responses} responses`;
  }
  return `${responses} responses`;
}

function getUnit(exploreAxis: string): string {
  if (exploreAxis === 'score') {
    const units = getExploreToolUIStore().scoreFormatter;

    return template(units)({ value: '' }).trim();
  } else if (exploreAxis === 'impact') {
    const units = getExploreToolUIStore().impactFormatter;

    return template(units)({ value: '' }).trim();
  }
  // default is volume
  return '%';
}

function getChartLabel(exploreAxis: string): string {
  if (exploreAxis === 'score') {
    return 'score';
  } else if (exploreAxis === 'impact') {
    return 'impact';
  }
  // default is volume
  return 'volume';
}

function getThemeValue(exploreAxis: string, theme: Theme, baselineOrComparison: 'baseline' | 'comparison'): number {
  if (exploreAxis === 'score') {
    return baselineOrComparison === 'baseline' ? theme.baseScore : theme.compScore;
  } else if (exploreAxis === 'impact') {
    return baselineOrComparison === 'baseline'
      ? Math.round(theme.baseImpact * 10) / 10
      : Math.round(theme.compImpact * 10) / 10;
  }
  // default is volume
  return baselineOrComparison === 'baseline' ? theme.baseVolume : theme.compVolume;
}

function generateLegend(params: ExploreInsightParams): LegendInsight {

  const baseFilter: Filter['baseline'] = params.filters.baseline || {};
  const baseData = extractLegendData(baseFilter);
  const compFilter: Filter['comparison'] = params.filters.comparison || {};
  const compData = extractLegendData(compFilter);

  const legend: LegendInsight = {
    type: InsightVis.Legend,
    shouldShowComparison: isComparisonFilterSet(params.filterSelections),
    baseline: {
      label: getLegendLabel(params.exploreAxis, params.baseTotal, params.selectedScore, params.baselineScores),
      data: baseData
    },
  };

  if (compData && params.compTotal) {
    legend.comparison = {
      label: getLegendLabel(params.exploreAxis, params.compTotal, params.selectedScore, params.comparisonScores),
      data: compData
    };
  }
  return legend;

}

function isComparisonFilterSet(filterSelections: FilterSelections): boolean {
  return filterSelections.baseline.query !== filterSelections.comparison.query;
}

function generateBarchart(params: ExploreInsightParams): BarChartInsight {

  const matchingThemeIndex = params.themes.findIndex(theme => theme.title === params.selectedThemeName);

  const subThemes = params.themes[matchingThemeIndex]?.subthemes ?? [];

  const matchingSubThemeIndex = subThemes.findIndex(subtheme => subtheme.title === params.selectedSubThemeName);

  const fields: Array<Field> = params.themes.reduce((
    result: Array<Field>,
    theme: Theme,
    themeIndex: number
  ): Array<Field> => {

    // Given a subtheme is selected, filter out irrelevant base themes
    if (matchingSubThemeIndex > -1 && themeIndex !== matchingThemeIndex) {
      return result;
    }

    const isThemeSelected = isNearIndex(
      matchingThemeIndex,
      themeIndex,
      params.themes.length - 1
    );

    const field: Field = {
      isHighlighted: false,
      // Given a subtheme is selected, the base theme is not initially selected
      isSelected: matchingSubThemeIndex === -1 && isThemeSelected,
      label: theme.title,
      baseline: getThemeValue(params.exploreAxis, theme, 'baseline'),
      comparison: getThemeValue(params.exploreAxis, theme, 'comparison'),
      baselineSentiment: theme.baseSentiments,
      comparisonSentiment: theme.compSentiments
    };

    if (matchingSubThemeIndex > -1) {
      field.children = subThemes.map((subTheme: Theme, subThemeIndex: number): Field => {

        const isSubThemeSelected = isNearIndex(
          matchingSubThemeIndex,
          subThemeIndex,
          subThemes.length - 1
        );

        return {
          isHighlighted: false,
          isSelected: isSubThemeSelected,
          label: subTheme.title,
          baseline: getThemeValue(params.exploreAxis, subTheme, 'baseline'),
          comparison: getThemeValue(params.exploreAxis, subTheme, 'comparison'),
          baselineSentiment: theme.baseSentiments,
          comparisonSentiment: theme.compSentiments
        };
      });
    }

    result.push(field);

    return result;

  }, []);

  const barchart: BarChartInsight = {
    type: InsightVis.BarChart,
    fields,
    chart: {
      units: getUnit(params.exploreAxis),
      count: getCount(fields),
      countLabel: getChartLabel(params.exploreAxis),
      shouldShowComparison: isComparisonFilterSet(params.filterSelections),
      shouldShowSentiment: params.viewOverlay === ViewOverlay.Sentiment
    },
    form: {
      label: 'Themes',
      maxSelectedFields: 10,
      shouldShowBarValues: false
    }
  };

  return barchart;
}

export default function generateInsight(params: ExploreInsightParams): Array<ExportableInsight> {
  return [
    generateBarchart(params),
    generateLegend(params)
  ];
}
