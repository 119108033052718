import * as React from 'react';
import { Form, FormInputProps, Input } from 'semantic-ui-react';

export interface DataSourceDiscourseCreateFormProps {
  existingConfiguration?: DiscourseConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface DiscourseConfig {
  siteUrl: string;
}

interface DataSourceDiscourseCreateFormState {
  config: DiscourseConfig;
}

class DataSourceDiscourseCreateForm extends React.Component<
  DataSourceDiscourseCreateFormProps,
  DataSourceDiscourseCreateFormState
  > {

  state = {
    config: {
      siteUrl: ''
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;

    if (existingConfiguration) {
      this.setState({
        config: {
          siteUrl: existingConfiguration.siteUrl
        }
      });
    }

    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  updateExternal = () => {
    const { config } = this.state;

    if (this.props.onChange) {
      this.props.onChange(config, '');
    }
  }

  changeSelectedSiteUrl = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    // update the config
    const config = {
      siteUrl: value
    };

    this.setState({ config });

    if (this.props.setValidity) {
      this.props.setValidity(config.siteUrl.startsWith('http') && config.siteUrl.length > 10);
    }

    if (this.props.onChange) {
      this.props.onChange(config, '');
    }
  };

  render(): JSX.Element | null {
    const { config } = this.state;

    return (<Form>
      <Form.Field>
        <label>Discourse Site URL</label>
        <Input
          placeholder="Site URL"
          value={config.siteUrl}
          onChange={this.changeSelectedSiteUrl}
        />
        <div className="helper-text">This should look like https://discourse.getthematic.com</div>
      </Form.Field>
    </Form>
    );
  }
}

export default DataSourceDiscourseCreateForm;