<template>
  <div
    ref="container"
    class="scaled-bar"
  >
    <svg
      :height="height"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <g transform="translate(1)">
        <line
          v-for="offset in barOffsets"
          :key="offset"
          :x1="offset"
          :x2="offset"
          :y2="height"
          y1="0"
          :stroke="colors.neutral100"
        />

        <rect
          :class="{ [color]: true, 'is-comparison': isComparison }"
          :height="barHeight"
          :width="width"
          :x="xOffset"
          :y="barYOffset"
          rx="0"
          ry="0"
        />
        <g v-if="overlayValues">
          <g
            v-for="(segment, index) in overlayValues"
            :key="index"
          >
            <rect
              :height="barHeight"
              :fill="index < overlayColors.length ? overlayColors[index] : undefined"
              :width="segmentWidth(segment)"
              :x="segmentOffset(index)"
              :y="barYOffset"
              rx="0"
              ry="0"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { map, sum, throttle } from 'lodash';
import colors from 'vue/styles/element-variables.scss';
import safeDiv from 'lib/safe-div.ts';
export default {
  name: 'ScaledBar',
  props: {
    color: { default: 'primary', type: String },
    isComparison: { default: false, type: Boolean },
    small: { default: false, type: Boolean },
    value: { default: 0, type: Number },
    overlayValues: { default: undefined, type: Array },
    overlayColors: { default: undefined, type: Array },
    maxValue: { default: 10, type: Number },
    minValue: { default: 0, type: Number }
  },
  data() {
    return {
      size: 148,
      colors: colors
    };
  },
  computed: {
    barHeight() {
      const { small } = this;
      return small ? 10 : 20;
    },
    barOffsets() {
      const offsets = [];
      const { range, size } = this;

      let step;
      if (range < 1) {
        step = 0.1;
      } else if (range < 5) {
        step = 1;
      } else if (range <= 20) {
        step = 5;
      } else if (range <= 100) {
        step = 10;
      } else {
        // find the power of ten just under the range
        step = Math.pow(10, Math.ceil(Math.log10(range)) - 1);
      }

      for (let i = 0; i <= range; i += step) {
        const offset = safeDiv((size * i), range, 0);
        offsets.push(offset);
      }
      return offsets;
    },
    barYOffset() {
      const { isComparison, small } = this;
      if (small) {
        return isComparison ? 1 : 5;
      } else {
        return 5;
      }
    },
    height() {
      const { small } = this;
      return small ? 15 : 30;
    },
    width() {
      const { range, size, w } = this;

      const width = safeDiv((size * w), range, 0);
      return Math.abs(width);
    },
    w() {
      const { maxValue, minValue, value } = this;
      if (value < 0) {
        if (maxValue < 0) {
          return Math.abs(maxValue - value);
        } else {
          return Math.abs(value);
        }
      } else {
        if (minValue < 0) {
          return value;
        } else {
          return value - minValue;
        }
      }
    },
    range() {
      const { maxValue, minValue } = this;
      return maxValue - minValue;
    },
    xOffset() {
      const { range, size, value, minValue } = this;
      if (value < 0) {
        return safeDiv((size * (value - minValue)), range, 0);
      } else if (minValue < 0) {
        const offset = safeDiv((size * minValue), range, 0);
        return Math.abs(offset);
      } else {
        return 0;
      }
    },
    xPosOffset() {
      const { width,  posWidth } = this;
      return width-posWidth;
    }
  },
  mounted() {
    // add debounce
    this.updateSize = throttle(this.updateSize, 100);

    this.updateSize();
    window.addEventListener('resize', this.updateSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateSize);
  },
  methods: {
    updateSize() {
      const { container } = this.$refs;
      if (!container) {
        return;
      }
      const { width } = container.getBoundingClientRect();
      this.size = width - 2; // adjust for padding?
    },
    segmentWidth(segmentPercent) {
      return this.width * segmentPercent;
    },
    segmentOffset(index) {
      return sum(map(this.overlayValues.slice(0, index), segment => {
        return this.segmentWidth(segment);
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.scaled-bar {
  display: flex;
  width: 100%;
  rect {
    &.primary {
      fill: $--primary-500;
      &.is-comparison {
        fill: $--orange-500;
      }
    }
  }
}
</style>
