import { render, staticRenderFns } from "./ExploreToolSelectionDetail.vue?vue&type=template&id=36673bab&scoped=true"
import script from "./ExploreToolSelectionDetail.vue?vue&type=script&lang=js"
export * from "./ExploreToolSelectionDetail.vue?vue&type=script&lang=js"
import style0 from "./ExploreToolSelectionDetail.vue?vue&type=style&index=0&id=36673bab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36673bab",
  null
  
)

export default component.exports