<template>
  <div class="config-field">
    <label>
      Custom title (optional)
    </label>
    <el-input
      v-model="widgetTitle"
      @change.native="onTitleChange"
    />
  </div>
</template>

<script>

export default {
  name: 'ConfigureWidgetTitle',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      widgetTitle: ''
    }
  },
  mounted() {
    this.widgetTitle = this.widgetConfig.title;
  },
  methods: {
    onTitleChange() {
      this.$emit('onConfigChange', this.target, this.widgetTitle, true);
    }
  }
}
</script>