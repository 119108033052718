import { DataSourceIntegrationRunStatus, UploadStatus } from 'api/enums';
import { DataSourceIntegrationRun } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { Button, Modal, Popup, Table } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import './view-history-modal.scss';

interface ViewDataSourceHistoryModalStoreProps {
  setupStore?: SetupStoreInterface;
}

export interface ViewDataSourceHistoryModalProps extends ViewDataSourceHistoryModalStoreProps {
  survey: LiteSurvey;
  onClose: () => void;
}

interface ViewDataSourceHistoryModalState {
  dataSourceRuns?: DataSourceIntegrationRun[];
}

@inject('setupStore', 'integrationsStore')
@observer
class ViewDataSourceHistoryModal extends React.Component<
ViewDataSourceHistoryModalProps,
ViewDataSourceHistoryModalState> {
  state = {
  } as ViewDataSourceHistoryModalState;

  componentDidMount() {
    const { setupStore, survey } = this.props;
    setupStore!.getDataSourceHistory(survey.surveyId).then( dataSourceRuns => {
      this.setState({dataSourceRuns});
    });
  }

  renderStatusCell( status?: DataSourceIntegrationRunStatus | UploadStatus, message?: string) {

    return (
      <Popup
      position="right center"
      hoverable={true}
      mouseEnterDelay={200}
      mouseLeaveDelay={100}
      trigger={
        <div className={ `processing-status ${status}` } >
          {status ? status : ''}
        </div>
      }
      >
        <Popup.Content>
          {message ? message : 'No information available'}
        </Popup.Content>
      </Popup>
    );
  }

  renderHistory() {
    const { dataSourceRuns } = this.state;
    return (
      <div>
        <h2>Most recent history</h2>
        <div className="history-table">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Pulling data
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Processing data
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { dataSourceRuns?.map( run => {
                let uploadStatus = run.uploadStatuses.reduce( (result, value) => {
                  if (!result) {
                    // use the first value to initalize
                    result = value;
                  } else if (result === UploadStatus.COMPLETED) {
                    // if the current 'result' is completed, let it be overriden by this value
                    result = value;
                  }
                  // we will end up returning the first non-completed result
                  return result;
                }, undefined as UploadStatus | undefined);

                return (
                  <Table.Row key={run.id}>
                    <Table.Cell>{moment(run.submitted).format('hh:mm D MMM YYYY')}</Table.Cell>
                    <Table.Cell>{this.renderStatusCell(run.status, run.message)}</Table.Cell>
                    <Table.Cell>{this.renderStatusCell(uploadStatus, '')}</Table.Cell>
                  </Table.Row>);
                }
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }

  render() {
    const { onClose, setupStore } = this.props;
    const { dataSourceRuns } = this.state;

    return (
      <Modal
        open={true}
        onClose={onClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header className="modal-header">
          View dataset history
        </Modal.Header>
        <Modal.Content className="view-history-modal">
         { setupStore!.fetchingDataSourceRuns ? (
          <div>
            Retrieving history...
          </div>
         ) : !dataSourceRuns || dataSourceRuns.length === 0 ? (
           <div>
             No history recorded!
           </div>
         ) : (
            this.renderHistory()
         )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ViewDataSourceHistoryModal;
