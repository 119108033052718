export interface ErrorData {
  message: string;
  status: string;
  description?: string;
  code: string;
  redirect?: string;
}

export interface Fetchable<T> {
  error?: string;
  errorData?: ErrorData;
  ok: boolean;
  data?: T;
  status: number;
  headers?: Headers;
}

export default async <T>(
  url: string,
  options: RequestInit & { isRaw?: boolean, isText?: boolean } = {}
): Promise<Fetchable<T>> => {
  const { isRaw, isText } = options;

  try {
    let result = await fetch(url, options);
    const { ok, status } = result;
    if (ok) {
      if (isText) {

        const text = await result.text() as unknown as T;
        return { ok: true, data: text, status, headers: result.headers };

        // @ts-expect-error
      } else if (options.responseType === 'arraybuffer') {

        const data = await result.arrayBuffer() as T;
        return { ok: true, data, status, headers: result.headers };

      } else {
        const json = await result.json();
        let data: T;
        if (isRaw) {
          data = json;
        } else {
          data = json.data;
        }

        return { ok: true, data, status, headers: result.headers };
      }
    } else {
      let errorData;
      try {
        /* attempt to get json error */
        errorData = await result.clone().json();
      } catch (e) {
        /* default to text */
        errorData = await result.text();
      }
      return { ok: false, error: result.statusText, errorData, status };
    }
  } catch (e) {
    return { ok: false, error: e.message, status: 0 };
  }
};
