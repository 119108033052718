import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';

export interface ConfirmationModalProps {
  confirmationText?: string;
  confirmationButtonText?: string;
  discardButtonText?: string;
  danger?: boolean;
  processing?: boolean;
  cancel?: () => void;
  confirm: () => void;
}

class ConfirmationModal extends React.Component<ConfirmationModalProps> {
  render(): JSX.Element | null {
    const { children, cancel, confirm, danger, processing } = this.props;
    let { confirmationText, confirmationButtonText, discardButtonText } = this.props;

    confirmationText = confirmationText || 'Are you sure?';
    discardButtonText = discardButtonText || 'Cancel';
    confirmationButtonText = confirmationButtonText || 'Confirm';

    const confirmationButtonColor = danger ? 'red' : 'blue';

    return (
      <Modal open={true} onClose={() => cancel?.()}>
        <ModalHeader as="h2">
          {confirmationText}
        </ModalHeader>
        {children &&
          <ModalContent>
            {children}
          </ModalContent>
        }
        <ModalActions>
          {cancel && (<Button
            className="nw--cancel"
            onClick={cancel}>
            {discardButtonText}
          </Button>)}
          <Button
            className="nw--confirm"
            color={confirmationButtonColor}
            onClick={confirm}
            loading={processing}
          >
            {confirmationButtonText}
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}

export default ConfirmationModal;
