import HierarchicalFilterView from 'components/Filters/HierarchicalFilterView';
import { FilterOption } from 'lib/filters/hierarchical-filter-helper';
import * as React from 'react';
import { Radio } from 'semantic-ui-react';
import { FilterMatchType } from 'stores/types';
import './theme-filter.scss';

interface ThemeFilterProps {
  filterOptions: FilterOption[];
  match: FilterMatchType;
  onChange: (options: FilterOption[], match: FilterMatchType) => void;
}

class ThemeFilter extends React.Component<ThemeFilterProps, {}> {
  render() {
    const { match } = this.props;

    return (
      <div className="theme-filter">
        <div className="match-buttons">
          <Radio
            label="Match any themes"
            checked={match === 'any'}
            onChange={() => this.props.onChange(this.props.filterOptions, 'any')}
          />
          <Radio
            label="Match all themes"
            checked={match === 'all'}
            onChange={() => this.props.onChange(this.props.filterOptions, 'all')}
          />
        </div>
        <HierarchicalFilterView
          filterOptions={this.props.filterOptions}
          onFilterOptionsChanged={(newOptions) => this.props.onChange(newOptions, match)}
        />
      </div>
    );
  }
}

export default ThemeFilter;
