import * as React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Summary, SegmentLocation } from 'types/custom';
import './conversation-item.scss';

type Segment = {
  isHighlighted: boolean;
  text: string;
};

const getSegments = (text: string, locations: SegmentLocation[]) => {
  if (locations.length === 0) {
    return [
      {
        isHighlighted: false,
        text,
      },
    ];
  }

  const segments = locations.reduce<Segment[]>((acc, [start, end], index) => {
    const previousEnd = index === 0 ? 0 : locations[index - 1][1] + 1;

    // Add preceding non-highlighted text if there's a gap
    if (previousEnd < start) {
      acc.push({
        isHighlighted: false,
        text: text.slice(previousEnd, start),
      });
    }

    // Add highlighted text segment
    acc.push({
      isHighlighted: true,
      text: text.slice(start, end + 1),
    });

    return acc;
  }, []);

  const finalLocation = locations[locations.length - 1];
  const finalIndex = finalLocation ? finalLocation[1] : 0;
  const hasRemainingText = finalIndex + 1 < text.length;

  const remainderSegment = {
    isHighlighted: false,
    text: text.slice(finalIndex + 1),
  };

  return hasRemainingText ? [...segments, remainderSegment] : segments;
};

interface Props {
  collapseItems: (event: React.MouseEvent) => void;
  conversationPart: Summary['conversation']['parts'][number];
  locations: SegmentLocation[];
  isReference: boolean;
  isSelected: boolean;
  actorClassName: string;
}

const ConversationItem = ({
  collapseItems,
  conversationPart,
  locations,
  isReference,
  isSelected,
  actorClassName
}: Props) => {
  const { author, text } = conversationPart;
  const segments = getSegments(text, locations);

  const hasLocations = locations.length > 0;

  return (
    <div
      className={classNames('conversation-item', {
        'conversation-item--reference': isReference,
        'conversation-item--selected': isSelected,
      })}
      role="comment"
    >
      {!isReference && !isSelected && hasLocations && (
        <button
          className="conversation-item__collapsible"
          onClick={collapseItems}
          aria-label="Collapse item"
          type="button"
        />
      )}
      <div className="conversation-item__content">
        <div className="conversation-item__heading">
          <div className="conversation-item__title">
            <span
              className={classNames('conversation-item__author-icon', actorClassName)}
            >
              <FontAwesomeIcon icon="user" />
            </span>
            {author}
          </div>
        </div>
        <div className="conversation-item__message">
          {segments?.map((segment, index) => (
            <span
              key={`segment-${index}`}
              className={classNames('conversation-item__segment', {
                'conversation-item__segment--highlighted': segment.isHighlighted,
              })}
            >
              {segment.text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export { ConversationItem };
