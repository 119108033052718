import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Button, FormInputProps, Header, Input, Loader,
  Menu, Message, Modal, Segment, Table
} from 'semantic-ui-react';
import { ManageThematicAdminsStoreInterface } from 'stores/ManageThematicAdminsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import './manage-thematic-admins.scss';

export interface ManageLocalThematicAdminsProps {
  orgId: string;
  manageThematicAdminsStore?: ManageThematicAdminsStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

export interface ManageLocalThematicAdminsState {
  isCreateModalVisible: boolean;
  createEmailPrefix: string;
}

@inject('manageThematicAdminsStore', 'organizationStore')
@observer
export default class ManageLocalThematicAdmins extends React.Component<
  ManageLocalThematicAdminsProps,
  ManageLocalThematicAdminsState> {
  state = {
    isCreateModalVisible: false,
    createEmailPrefix: ''
  };

  componentDidMount() {
    this.initialize();
  }
  initialize = async () => {
    const { manageThematicAdminsStore, orgId } = this.props;

    if (orgId) {
      manageThematicAdminsStore!.getAdminsForOrg(orgId, true);
    }
  }

  closeCreateModal = () => {
    this.setState({ isCreateModalVisible: false, createEmailPrefix: '' });
  }
  createThematicAdmin = async () => {
    const { manageThematicAdminsStore, orgId } = this.props;
    const { createEmailPrefix } = this.state;

    if (orgId) {
      await manageThematicAdminsStore!.createAdmin(orgId, createEmailPrefix + '@getthematic.com');
      this.initialize();
    }

    this.closeCreateModal();
  }

  revokeThematicAdmin = async (email) => {
    const { manageThematicAdminsStore, orgId } = this.props;

    if (orgId) {
      await manageThematicAdminsStore!.revokeAdmin(orgId, email);
    }
    this.initialize();
  }

  render(): JSX.Element | null {
    const { manageThematicAdminsStore, organizationStore, orgId } = this.props;

    const {
      localAdmins,
      loadingAdmins,
      loadingAdminsError,
      creatingAdmin,
      creatingAdminError,
      revokingAdmin,
      revokingAdminError,
    } = manageThematicAdminsStore!;

    const { isCreateModalVisible, createEmailPrefix } = this.state;

    const errors = compact([
      loadingAdminsError,
      creatingAdminError,
      revokingAdminError
    ]);

    const admins = localAdmins[orgId];

    const orgName = organizationStore!.orgName;

    let body;

    if (loadingAdmins) {
      body = (
        <Table.Row className="loading">
          <Table.Cell textAlign="center">
            <Loader inline={true} size="small" active={loadingAdmins}>
              Fetching users&hellip;
            </Loader>
          </Table.Cell>
        </Table.Row>
      );
    } else if (!admins || isEmpty(admins)) {
      body = (
        <Table.Row className="loading">
          <Table.Cell textAlign="center">
            No users found
          </Table.Cell>
        </Table.Row>
      );
    } else {
      body = admins!.map(admin => {
        return (
          <Table.Row key={admin}>
            <Table.Cell>
              {admin}
            </Table.Cell>
            <Table.Cell>

              <Button
                type="button"
                color="blue"
                size="small"
                data-testid="revoke-button"
                loading={revokingAdmin}
                onClick={() => { this.revokeThematicAdmin(admin); }}
              >
                Revoke
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Segment className="fullscreen manage-thematic-admins">
        <Header className="header-two-row">
          <div>
            Manage Thematic Administrators with access to "{orgName}"
          </div>
        </Header>
        <Segment className="white">
          <Menu secondary={true}>
            <Menu.Item fitted={true}>
              <Button
                color="blue"
                className="nw--add-user"
                disabled={loadingAdmins}
                onClick={() => { this.setState({ isCreateModalVisible: true }); }}
              >
                <FontAwesomeIcon
                  className="icon"
                  fixedWidth={true}
                  icon="plus"
                />
                Add new admin
              </Button>
            </Menu.Item>
          </Menu>

          {errors.map(error => {
            return (
              <Message
                key={error}
                className="error"
                negative={true}
                header={error}
              />
            );
          })}

          <Table
            unstackable={true}
            fixed={true}
            sortable={true}
            className="thematic-admins-grid"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Revoke
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{body}</Table.Body>
          </Table>
        </Segment>
        <Modal
          open={isCreateModalVisible}
          size="tiny"
          className="swap-modal"
        >
          <Modal.Content>
            <div className="modal-content">
              <div className="modal-body">

                <Input
                  value={createEmailPrefix}
                  label={{ content: '@getthematic.com' }}
                  onChange={(e, { value }: FormInputProps) => { this.setState({ createEmailPrefix: value }); }}
                  labelPosition="right"
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeCreateModal}>
              Cancel
            </Button>
            <Button
              type="button"
              color="blue"
              loading={creatingAdmin}
              onClick={this.createThematicAdmin}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}
