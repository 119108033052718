import { PhraseSegment } from 'types/custom';
import { getSentimentName } from 'lib/calculate-sentiment';
import { isNonSpecialTheme } from 'lib/special-theme-predicates';

export type ContentBlock = {
  isHighlighted: boolean,
  location: [start: number, end: number],
  content: string,
  hasThemes: boolean,
  key: string,
  sentiment: number,
  partIndex: number,
  sentimentName: string,
};

type NonContentBlock = {
  content: string,
  isHighlighted: boolean,
  key: string,
};

export type Block = ContentBlock | NonContentBlock;

/* String.prototype.slice will divide surrogate pairs, which is unhelpful.
 * To handle the locations of emoji, we split the original string into strings
 * representing one character (which may be multi-byte).
 * Then we can use the location indexes to extract the intended string slice.
 */
function extract(chars: Array<string>, start: number, end: number): string {
  return chars.slice(start, end).join('');
}

export default function segmentsToBlocks(
  comment: string,
  segments: Array<PhraseSegment>,
  partIndex: number,
  highlightedLocations: Array<[start: number, end: number]>
): Array<Block> {

  const commentChars = Array.from(comment);
  const contentLength = commentChars.length;

  if (segments.length === 0) {
    return [{
      content: comment,
      isHighlighted: false,
      key: comment
    }];
  }

  return segments.reduce((
    result: Array<Block>,
    segment: PhraseSegment,
    index: number
  ) => {

    // Segment
    const [start, end] = segment.location;
    const nonSpecialThemes = segment.themes.filter(isNonSpecialTheme);
    const content: string = extract(commentChars, start, end + 1);
    const key = `${start}-${end}`;

    const prevSegment = segments.length >= index ? segments[index - 1] : null;

    if (prevSegment) {
      const prevEnd = prevSegment.location[1];

      if (prevEnd !== end - 1 ) {

        // Given some characters exist between the end of the last segment
        // and the start of this segment, insert a non-content block
        const ncbStart = prevEnd + 1;
        const ncbEnd = start;

        result.push({
          content: extract(commentChars, ncbStart, ncbEnd),
          isHighlighted: false,
          key: `${ncbStart}-${ncbEnd}`,
        });

      }

    }

    // Given this is the first segment, and some characters exist before it,
    // insert a non-content block
    if (!prevSegment && start > 0) {

      const ncbStart = 0;
      const ncbEnd = start;

      result.push({
        content: extract(commentChars, ncbStart, ncbEnd),
        isHighlighted: false,
        key: `${ncbStart}-${ncbEnd}`,
      });

    }

    const isHighlighted = highlightedLocations.some(loc => {
      return start === loc[0] && end === loc[1];
    });

    const contentBlock = {
      isHighlighted,
      location: segment.location,
      content,
      hasThemes: nonSpecialThemes.length > 0,
      key,
      partIndex,
      sentiment: segment.sentiment,
      sentimentName: getSentimentName(segment.sentiment),
    };

    result.push(contentBlock);

    const nextIndex = index + 1;
    const nextSegment = segments.length > nextIndex ? segments[nextIndex] : null;

    // Given this is the last segment and some characters exist after it,
    // insert a non-content block
    if (!nextSegment && contentLength - 1 > end) {

      const ncbStart = end + 1;

      result.push({
        content: extract(commentChars, ncbStart, contentLength),
        isHighlighted: false,
        key: `${ncbStart}-${contentLength}`,
      });

    }

    return result;

  }, []);

}
