<template>
  <div
    ref="chartEl"
    class="explore-chart"
    aria-label="explore chart"
    :class="{
      'ob-explore-chart-edit': isVolume && canManageThemes,
      'ob-explore-chart-no-edit': isVolume && !canManageThemes,
      'ob-impact-chart': isImpact
    }"
  >
    <div
      :class="{ empty: emptyThemes }"
      class="explore-chart__base"
    >
      <div v-if="!emptyThemes">
        <div class="scale-row">
          <div class="scale-name">
            Base themes &nbsp;
          </div>
          <explore-scale
            v-if="isVolume"
            :max="maxVolume"
            suffix="%"
          />
          <explore-scale
            v-else-if="isImpact"
            :max="maxImpact"
            :min="minImpact"
            :formatter="impactFormatter"
            :title="impactChartSubtitle"
          />
          <explore-scale
            v-else
            :max="maxScore"
            :min="minScore"
            :formatter="scoreFormatter"
            :title="impactChartSubtitle"
          />
        </div>
        <el-tooltip
          v-for="theme in themes"
          :key="theme.key"
          :value="theme.visibleTooltip"
          :enterable="false"
          :class="{ 'filtered-theme': !theme.matches }"
          :popper-options="popperOptions"
          :open-delay="300"
          placement="top"
          popper-class="el-tooltip__popper-no-mouse volume-tooltip"
        >
          <div
            v-if="theme.matches"
            slot="content"
          >
            <h4>{{ theme.title }}</h4>
            <div class="volume-tooltip__row">
              <span class="baseline" />
              <div class="volume-tooltip__vol">
                <span v-if="isVolume">
                  {{ volumeLabel(theme.baseVolume) }}
                </span>
                <span v-else-if="isImpact">
                  {{ valueLabel(theme.baseImpact) }}
                </span>
                <span v-else>
                  {{ valueLabel(theme.baseScore) }}
                </span>
              </div>
              <div class="volume-tooltip__proportion">
                {{ tooltipProportion(theme.baseCount, baseTotal) }}
              </div>
            </div>
            <div
              v-if="hasCalculatedComparison"
              class="volume-tooltip__row"
            >
              <span class="comparison" />
              <div class="volume-tooltip__vol">
                <span v-if="isVolume">
                  {{ volumeLabel(theme.compVolume) }}
                </span>
                <span v-else-if="isImpact">
                  {{ valueLabel(theme.compImpact) }}
                </span>
                <span v-else>
                  {{ valueLabel(theme.compScore) }}
                </span>
              </div>
              <div class="volume-tooltip__proportion">
                {{ tooltipProportion(theme.compCount, compTotal) }}
              </div>
            </div>
            <div
              v-if="theme.significant && isVolume"
              class="volume-tooltip__significance"
            >
              <font-awesome-icon icon="exchange-alt" />
              Statistically significant difference
            </div>
          </div>
          <div
            v-if="theme.matches"
            :id="themeRowId(theme.title)"
            :class="{ selected: theme.title === selectedTheme, 'selected-focus': !selectedSubtheme, comparison: hasCalculatedComparison }"
            class="theme-row ob-theme-row"
            :aria-label="`theme: ${theme.title}`"
            :aria-selected="theme.title === selectedTheme"
            @click="selectTheme(theme.title)"
            @mouseleave="theme.visibleTooltip = false"
          >
            <div class="theme-name">
              {{ theme.title }}
            </div>
            <div class="theme-percentage-bar">
              <scaled-bar
                v-if="isVolume"
                :max-value="maxVolume"
                :small="hasCalculatedComparison"
                :value="theme.baseVolume"
                :overlay-values="showSentiment ? sentimentBandsFromTheme(theme.baseCount, theme.baseSentiments) : undefined"
                :overlay-colors="baseSentimentColors"
              />
              <scaled-bar
                v-else-if="isImpact"
                :min-value="minImpact"
                :max-value="maxImpact"
                :small="hasCalculatedComparison"
                :value="theme.baseImpact"
              />
              <scaled-bar
                v-else
                :max-value="maxScore"
                :min-value="minScore"
                :small="hasCalculatedComparison"
                :value="theme.baseScore"
              />
            </div>
            <div
              v-if="hasCalculatedComparison"
              class="theme-percentage-bar theme-comparison-percentage-bar"
            >
              <scaled-bar
                v-if="isVolume"
                :is-comparison="true"
                :max-value="maxVolume"
                :small="true"
                :value="theme.compVolume"
                :overlay-values="showSentiment ? sentimentBandsFromTheme(theme.compCount, theme.compSentiments) : undefined"
                :overlay-colors="compSentimentColors"
              />
              <scaled-bar
                v-else-if="isImpact"
                :is-comparison="true"
                :min-value="minImpact"
                :max-value="maxImpact"
                :small="true"
                :value="theme.compImpact"
              />
              <scaled-bar
                v-else
                :is-comparison="true"
                :min-value="minScore"
                :max-value="maxScore"
                :small="true"
                :value="theme.compScore"
              />
            </div>
            <div
              class="theme-label"
              aria-label="theme label"
            >
              <span v-if="isVolume">
                {{ formatLabel(theme.baseVolume) }}%
              </span>
              <span v-else-if="isImpact">
                {{ formatLabel(theme.baseImpact, impactFormatter) }}
              </span>
              <span v-else>
                {{ formatLabel(theme.baseScore, scoreFormatter) }}
              </span>
            </div>
            <div
              v-if="hasCalculatedComparison"
              class="theme-label theme-comparison-label"
              aria-label="theme comparison label"
            >
              <span v-if="isVolume">
                {{ formatLabel(theme.compVolume) }}%
              </span>
              <span v-else-if="isImpact">
                {{ formatLabel(theme.compImpact, impactFormatter) }}
              </span>
              <span v-else>
                {{ formatLabel(theme.compScore, scoreFormatter) }}
              </span>
            </div>
          </div>
        </el-tooltip>
        <div class="scale-row">
          <div class="theme-name" />

          <explore-scale
            v-if="isVolume"
            :max="maxVolume"
            :top="true"
            suffix="%"
          />
          <explore-scale
            v-else-if="isImpact"
            :max="maxImpact"
            :min="minImpact"
            :formatter="impactFormatter"
            :top="true"
            :title="impactChartSubtitle"
          />
          <explore-scale
            v-else
            :max="maxScore"
            :min="minScore"
            :formatter="scoreFormatter"
            :top="true"
            :title="impactChartSubtitle"
          />
        </div>
      </div>
      <div
        v-else
        class="empty-bd"
      >
        No base themes
      </div>
    </div>
    <div
      :class="{ empty: !focusedSubthemes }"
      :style="`marginTop:${subthemeOffset}px`"
      class="explore-chart__sub"
    >
      <div v-if="focusedSubthemes">
        <div class="scale-row">
          <div class="scale-name">
            Subthemes
          </div>
          <explore-scale
            v-if="isVolume"
            :max="maxVolume"
            suffix="%"
          />
          <explore-scale
            v-else-if="isImpact"
            :max="maxImpact"
            :min="minImpact"
            :formatter="impactFormatter"
            :title="impactChartSubtitle"
          />
          <explore-scale
            v-else
            :max="maxScore"
            :min="minScore"
            :formatter="scoreFormatter"
            :title="impactChartSubtitle"
          />
        </div>
        <el-tooltip
          v-for="subtheme in focusedSubthemes"
          :key="subtheme.key"
          :value="subtheme.visibleTooltip"
          :enterable="false"
          :class="{ 'filtered-theme': !subtheme.matches }"
          :popper-options="popperOptions"
          :open-delay="300"
          placement="top"
          popper-class="el-tooltip__popper-no-mouse volume-tooltip"
        >
          <div slot="content">
            <h4>{{ subtheme.title }}</h4>
            <div class="volume-tooltip__row">
              <span class="baseline" />
              <div class="volume-tooltip__vol">
                <span v-if="isVolume">
                  {{ volumeLabel(subtheme.baseVolume) }}
                </span>
                <span v-else-if="isImpact">
                  {{ valueLabel(subtheme.baseImpact) }}
                </span>
                <span v-else>
                  {{ valueLabel(subtheme.baseScore) }}
                </span>
              </div>
              <div class="volume-tooltip__proportion">
                {{ tooltipProportion(subtheme.baseCount, baseTotal) }}
              </div>
            </div>
            <div
              v-if="hasCalculatedComparison"
              class="volume-tooltip__row"
            >
              <span class="comparison" />
              <div class="volume-tooltip__vol">
                <span v-if="isVolume">
                  {{ volumeLabel(subtheme.compVolume) }}
                </span>
                <span v-else-if="isImpact">
                  {{ valueLabel(subtheme.compImpact) }}
                </span>
                <span v-else>
                  {{ valueLabel(subtheme.compScore) }}
                </span>
              </div>
              <div class="volume-tooltip__proportion">
                {{ tooltipProportion(subtheme.compCount, compTotal) }}
              </div>
            </div>
            <div
              v-if="subtheme.significant && isVolume"
              class="volume-tooltip__significance"
            >
              <font-awesome-icon icon="exchange-alt" />
              Statistically significant difference
            </div>
          </div>
          <div
            :id="themeRowId(subtheme.title, 'sub')"
            :class="{ selected: subtheme.title === selectedSubtheme, 'selected-focus': subtheme.title === selectedSubtheme, comparison: hasCalculatedComparison }"
            class="theme-row ob-subtheme-row"
            @click="selectTheme(selectedTheme, subtheme.title)"
            @mouseleave="subtheme.visibleTooltip = false"
          >
            <div class="theme-name">
              {{ subtheme.title }}
            </div>
            <div class="theme-percentage-bar">
              <scaled-bar
                v-if="isVolume"
                :max-value="maxVolume"
                :small="hasCalculatedComparison"
                :value="subtheme.baseVolume"
                :overlay-values="showSentiment ? sentimentBandsFromTheme(subtheme.baseCount, subtheme.baseSentiments) : undefined"
                :overlay-colors="baseSentimentColors"
              />
              <scaled-bar
                v-else-if="isImpact"
                :min-value="minImpact"
                :max-value="maxImpact"
                :small="hasCalculatedComparison"
                :value="subtheme.baseImpact"
              />
              <scaled-bar
                v-else
                :min-value="minScore"
                :max-value="maxScore"
                :small="hasCalculatedComparison"
                :value="subtheme.baseScore"
              />
            </div>
            <div
              v-if="hasCalculatedComparison"
              class="theme-percentage-bar theme-comparison-percentage-bar"
            >
              <scaled-bar
                v-if="isVolume"
                :is-comparison="true"
                :max-value="maxVolume"
                :small="true"
                :value="subtheme.compVolume"
                :overlay-values="showSentiment ? sentimentBandsFromTheme(subtheme.compCount, subtheme.compSentiments) : undefined"
                :overlay-colors="compSentimentColors"
              />
              <scaled-bar
                v-else-if="isImpact"
                :is-comparison="true"
                :min-value="minImpact"
                :max-value="maxImpact"
                :small="true"
                :value="subtheme.compImpact"
              />
              <scaled-bar
                v-else
                :is-comparison="true"
                :min-value="minScore"
                :max-value="maxScore"
                :small="true"
                :value="subtheme.compScore"
              />
            </div>
            <div class="theme-label">
              <span v-if="isVolume">
                {{ formatLabel(subtheme.baseVolume) }}%
              </span>
              <span v-else-if="isImpact">
                {{ formatLabel(subtheme.baseImpact, impactFormatter) }}
              </span>
              <span v-else>
                {{ formatLabel(subtheme.baseScore, scoreFormatter) }}
              </span>
            </div>
            <div
              v-if="hasCalculatedComparison"
              class="theme-label theme-comparison-label"
            >
              <span v-if="isVolume">
                {{ formatLabel(subtheme.compVolume) }}%
              </span>
              <span v-else-if="isImpact">
                {{ formatLabel(subtheme.compImpact, impactFormatter) }}
              </span>
              <span v-else>
                {{ formatLabel(subtheme.compScore, scoreFormatter) }}
              </span>
            </div>
          </div>
        </el-tooltip>
        <div class="scale-row">
          <div class="scale-name" />
          <explore-scale
            v-if="isVolume"
            :max="maxVolume"
            :top="true"
            suffix="%"
          />
          <explore-scale
            v-else-if="isImpact"
            :max="maxImpact"
            :min="minImpact"
            :formatter="impactFormatter"
            :top="true"
            :title="impactChartSubtitle"
          />
          <explore-scale
            v-else
            :max="maxScore"
            :min="minScore"
            :top="true"
            :formatter="scoreFormatter"
            :title="impactChartSubtitle"
          />
        </div>
      </div>
      <div
        v-else
        class="empty-bd"
      >
        No subthemes
      </div>
    </div>
  </div>
</template>

<script>
import {
debounce,
filter,
find,
findIndex,
flattenDeep,
get,
kebabCase,
map,
max,
min,
some,
template
} from 'lodash';

import {
  getAnalysisConfigStore,
  getAnalysisToolsKeyTakeawaysUIStore,
  getAnalysisToolsUIStore,
  getExploreToolUIStore,
  getFilterStore,
  getInitConfigStore,
  getExportInsightUIStore
} from 'stores/RootStore';
import ScaledBar from 'vue/components/Charts/ScaledBar.vue';
import ExploreScale from 'vue/explore/ExploreScale.vue';
import fuzzNum from 'vue/libs/fuzz-num';
import { roundMinMax } from 'vue/libs/min-max';
import { impactTakeaway, scoreTakeaway, volumeTakeaway } from 'vue/libs/takeaways';
import toFixed from 'vue/libs/to-fixed';
import { AxisType } from "stores/ui/ExploreToolUIStore";
import colors from 'vue/styles/element-variables.scss';
import 'vue/styles/theme-row.scss';
import generateInsight from "lib/explore-tool-insight-generator";

export default {
  name: 'ExploreChart',
  components: {
    ExploreScale,
    ScaledBar,
  },
  props: {
    baseTotal: { default: 0, type: Number },
    compTotal: { default: 0, type: Number },
    hasComparison: { default: false, type: Boolean },
    hasImpactComparison: { default: false, type: Boolean },
    showMaxThemes: { default: undefined, type: Number },
    themes: { default: () => [], type: Array }
  },
  data() {
    return {
      baseSentimentColors: [
        colors.sentimentFrown,
        colors.sentimentMeh,
        colors.sentimentSmile,
      ],
      compSentimentColors: [
        colors.sentimentFrownBG,
        colors.sentimentMehBG,
        colors.sentimentSmileBG,
      ],
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      analysisToolsKeyTakeawaysUIStore: getAnalysisToolsKeyTakeawaysUIStore(),
      filterStore: getFilterStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      exportInsightUIStore: getExportInsightUIStore(),
      popperOptions: {
        boundariesElement: undefined,
      },
      subthemeOffset: 0,
      tooltipKey: undefined,
      tooltipTimeout: undefined,
    };
  },
  computed: {
    axisType() {
      return this.exploreToolUIStore.axisType;
    },
    impactChartSubtitle() {
      return this.exploreToolUIStore.impactChartSubtitle;
    },
    impactFormatter() {
      return this.exploreToolUIStore.impactFormatter;
    },
    scoreFormatter() {
      return this.exploreToolUIStore.scoreFormatter;
    },
    scoreName() {
      return this.exploreToolUIStore.scoreName;
    },
    organization() {
      return this.initConfigStore.organization;
    },
    themeFilterSet() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    showSentiment() {
      return this.analysisToolsUIStore.viewOverlay === 'sentiment';
    },
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    emptyThemes() {
      return !some(this.themes, theme => theme.matches);
    },
    emptySubthemes() {
      return !some(this.focusedSubthemes, theme => theme.matches);
    },
    hasCalculatedComparison() {
      const { hasComparison, hasImpactComparison, isVolume } = this;

      if (isVolume) {
        return hasComparison;
      } else {
        return hasImpactComparison;
      }
    },
    isImpact() {
      return this.axisType === AxisType.Impact;
    },
    isVolume() {
      return this.axisType === AxisType.Volume;
    },
    maxImpact() {
      const impacts = this.collectThemeFields('baseImpact', 'compImpact');
      return Math.max(0, roundMinMax(max(impacts)));
    },
    maxScore() {
      const scores = this.collectThemeFields('baseScore', 'compScore');
      return Math.max(0, roundMinMax(max(scores)));
    },
    maxVolume() {
      const volumes = this.collectThemeFields('baseVolume', 'compVolume');
      const maxVolume = max(volumes);

      return roundMinMax(maxVolume);
    },
    minImpact() {
      const impacts = this.collectThemeFields('baseImpact', 'compImpact');
      return Math.min(0, roundMinMax(min(impacts)));
    },
    minScore() {
      const scores = this.collectThemeFields('baseScore', 'compScore');
      return Math.min(0, roundMinMax(min(scores)));
    },
    selectedSubtheme() {
      return get(this, 'themeFilterSet.subtheme.name', null);
    },
    selectedTheme() {
      return get(this, 'themeFilterSet.theme.name', null);
    },
    focusedSubthemes() {
      const { themes, selectedTheme } = this;
      const theme = find(themes, { title: selectedTheme });

      return theme && theme.subthemes;
    },
  },
  watch: {
    axisType() {
      this.updateKeyTakeaway();
    },
    selectedSubtheme() {
      this.focusRows();
    },
    selectedTheme() {
      this.focusRows();
    },
    showMaxThemes() {
      this.focusRows();
    },
    themes: {
      handler() {
        this.focusRows();
      },
      immediate: true
    }
  },
  async mounted() {
    this.$root.$on('showExportInsight', (params) => {
      this.showExportInsight(params);
    });
    this.popperOptions.boundariesElement = this.$el;
    this.focusRows = debounce(this.focusRows, 5);
    this.updateKeyTakeaway();
  },
  methods: {
    showExportInsight(exportInsightParams) {

      const filterSet = this.analysisToolsUIStore.themeFilterSet;

      const params = generateInsight({
        exploreAxis: exportInsightParams.axisType,
        selectedScore: exportInsightParams.score,
        baselineScores: exportInsightParams.baselineScores,
        comparisonScores: exportInsightParams.comparisonScores,
        baseTotal: this.baseTotal,
        compTotal: this.compTotal,
        filters: this.filterStore.filters,
        selectedThemeName: filterSet.theme.name,
        selectedSubThemeName: filterSet.subtheme.name,
        themes: this.themes,
        filterSelections: this.filterStore.selections,
        viewOverlay: this.analysisToolsUIStore.viewOverlay
      });

      this.exportInsightUIStore.show(params);
      this.exportInsightUIStore.updateFileName(exportInsightParams.fileName);

    },
    calculateOffset() {
      let offset = 0;
      const { $refs, showMaxThemes } = this;

      if ($refs.chartEl) {
        const el = $refs.chartEl.querySelector('.theme-row.selected');
        if (el && !showMaxThemes) {
          const { top } = el.getBoundingClientRect();
          const { top: parentTop } = el.parentNode.getBoundingClientRect();

          offset = top - parentTop - 32;
        }
        this.subthemeOffset = offset;
      }
    },
    collect(themes, fields) {
      return map(fields, field => map(themes, field));
    },
    collectThemeFields(...fields) {
      const { themes } = this;

      const fieldVals = this.collect(themes, fields);
      const subthemeFieldVals = map(themes, theme => {
        return this.collect(theme.subthemes, fields);
      });

      return flattenDeep([fieldVals, ...subthemeFieldVals]);
    },
    focusRow(theme) {
      const { themes, showMaxThemes } = this;
      if (showMaxThemes) {
        const index = this.themeRowIndex(theme);
        if (index >= showMaxThemes) {
          const firstTheme = find(themes, { matches: true });
          if (firstTheme) {
            this.selectTheme(firstTheme.title);
          }
        }
      }
    },
    focusRows() {
      const { selectedTheme, selectedSubtheme } = this;
      if (selectedTheme) {
        this.focusRow(selectedTheme);
        this.calculateOffset();
      }
      if (selectedSubtheme) {
        this.focusRow(selectedSubtheme);
      }
      this.updateKeyTakeaway();
    },
    updateKeyTakeaway() {
      const { selectedTheme, selectedSubtheme, axisType, themes, filterStore, baseTotal, compTotal } = this;

      let takeaways;
      let theme = find(themes, { title: selectedTheme });
      if( theme && selectedSubtheme ) {
        theme = find(theme.subthemes, { title: selectedSubtheme })
      }

      if(theme) {
        if( axisType === AxisType.Volume) {
          takeaways = [volumeTakeaway(selectedSubtheme ? selectedSubtheme: selectedTheme, theme, baseTotal, compTotal, filterStore.selections)];
        } else if( axisType === AxisType.Impact) {
          takeaways = [impactTakeaway(selectedSubtheme ? selectedSubtheme: selectedTheme,
                                      theme,
                                      filterStore.selections,
                                      this.scoreName,
                                      this.impactFormatter)];
        } else if( axisType === AxisType.Score) {
          takeaways = [scoreTakeaway(selectedSubtheme ? selectedSubtheme: selectedTheme,
                                     theme,
                                     filterStore.selections,
                                     this.scoreName,
                                     this.scoreFormatter)];
        }
      }
      this.analysisToolsKeyTakeawaysUIStore.updateTakeaway('explore-chart', takeaways);
    },
    formatLabel(val, formatter) {
      if (!formatter) {
        formatter = '<%= value %>';
      }
      return template(formatter)({ value: fuzzNum(val, 1) });
    },
    hasTooltip(theme) {
      return theme.key === this.tooltipKey;
    },
    hideTooltip(theme) {
      theme.visible = false;
    },
    selectTheme(theme, subtheme) {
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
    },
    sentimentBandsFromTheme(count, sentimentCounts) {
      if (sentimentCounts) {
        return [sentimentCounts.neg/count, sentimentCounts.neut/count, sentimentCounts.pos/count];
      }
      return undefined;
    },
    themeRowId(theme, prefix = '') {
      return `${prefix}theme-row--${kebabCase(theme)}`;
    },
    themeRowIndex(theme) {
      const visibleThemes = filter(this.themes, { matches: true });
      return findIndex(visibleThemes, { title: theme });
    },
    tooltipProportion(count, base) {
      count = fuzzNum(count);
      base = fuzzNum(base);
      return `${count} / ${base}`;
    },
    valueLabel(val) {
      const { maxValue, isImpact, scoreFormatter, impactFormatter } = this;
      let formatter = isImpact ? impactFormatter : scoreFormatter;
      if (!formatter) {
        formatter = '<%= value %> points';
      }
      const dp = maxValue < 1 ? 2 : 1;
      formatter = template(formatter);
      return formatter({ value: toFixed(val, dp) });
    },
    volumeLabel(vol) {
      return toFixed(vol, 1, '%');
    },
  }
};
</script>

<style lang="scss">
@import '../styles/element-variables.scss';
.volume-tooltip {
  padding: 8px;
  text-align: left;
  h4 {
    color: $--color-white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.35px;
    line-height: 19px;
    margin: 0 0 10px;
  }
  &__row {
    align-items: center;
    display: grid;
    font-size: 14px;
    grid: auto / auto minmax(60px, 1fr) auto;
    grid-gap: 5px;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    > span {
      border-radius: 50%;
      height: 14px;
      width: 14px;
    }
    .baseline {
      background: $--color-primary;
    }
    .comparison {
      background: $--color-comparison;
    }
  }
  &__vol {
    padding-right: 10px;
  }
  &__significance {
    font-size: 10px;
    letter-spacing: 0.25px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
<style lang="scss" scoped>
@import '../styles/element-variables.scss';
@import '../components/Charts/volume-chart-variables.scss';
.explore-chart {
  display: grid;
  grid: #{$--volume-chart-height} / 1fr 1fr;
  height: $--volume-chart-height;
  padding: 0;
  padding-top: 3px;
  overflow: hidden;
  .explore-tool__main.expanded & {
    height: 100%;
    grid: auto / 1fr 1fr;
    .explore-chart__base,
    .explore-chart__sub {
      height: unset;
    }
  }
  &__base,
  &__sub {
    height: $--volume-chart-height;
    padding: 10px 0 20px;
    overflow-y: hidden;
    .scale-row {
      align-items: flex-end;
      display: grid;
      font-size: 13px;
      grid: 32px / 1fr minmax(100px, 1fr) 7ch;
      grid-gap: 0 5px;
      padding: 0 20px 0 10px;
      .scale-name {
        color: $--neutral-400;
        display: flex;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.4px;
        line-height: 19px;
        text-transform: uppercase;
      }
    }
    .theme-row {
      border-radius: 0;
      cursor: pointer;
      padding: 0 20px 0 10px;
      display: grid;
      grid: 15px 15px / 1fr minmax(100px, 1fr) 7ch;
      grid-template-areas:
        'name base-bar base-label'
        'name base-bar base-label';
      grid-gap: 0 5px;
      min-height: unset;
      &:hover,
      &.selected {
        background-color: $--background-color-highlight;
      }
      &.selected {
        padding-left: 5px;
        border-left: 5px solid $--color-text-primary;
        &.selected-focus {
          border-left-color: $--color-comparison;
        }
      }
      &.comparison {
        grid-template-areas:
          'name base-bar base-label'
          'name comp-bar comp-label';
        .theme-label {
          align-self: start;
        }
        .theme-comparison-label {
          align-self: end;
        }
      }
      .theme-name {
        grid-area: name;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .theme-percentage-bar {
        grid-area: base-bar;
        padding: 0 15px;
      }
      .theme-comparison-percentage-bar {
        grid-area: comp-bar;
      }
      .theme-label {
        grid-area: base-label;
        text-align: right;
        letter-spacing: 0.4px;
      }
      .theme-comparison-label {
        grid-area: comp-label;
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;
      .empty-bd {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        opacity: 0.7;
      }
    }
  }
  &__sub {
    align-self: flex-start;
    background: $--primary-100;
    border-radius: 4px;
    margin-right: 3px;
    .theme-row {
      &.selected {
        background-color: $--transparent-primary !important;
      }
      &.filtered-theme {
        .theme-name,
        .theme-label {
          opacity: 0.5;
        }
        .theme-percentage-bar {
          filter: grayscale(25%) opacity(50%);
        }
      }
    }
  }
  h4 {
    align-items: center;
    display: flex;
    line-height: 22px;
    margin: 0;
    padding: 0 10px;
    button {
      margin-right: 12px;
    }
  }
}
</style>

