import {
  AggregateViewSource,
  AnalysisConfig,
  AnalysisFilter,
  AnalysisViewSource,
  MetadataColumn,
  ThreadDisplayOptions
} from 'api/interfaces';
import Auth from 'Auth/Auth';
import { includes, isArray, isNumber } from 'lodash';
import { action, computed, observable } from 'mobx';
import * as moment from 'moment';
import { getAnalysisId, getSourceUrl } from './utils/analysis-helper';

export interface AnalysisConfigStoreInterface {
  config?: AnalysisConfig;
  configs: { [key: string]: AnalysisConfig };
  fetchConfigError?: string;
  fetchConfigsError?: string;
  filters: AnalysisFilter[];
  threadDisplayConfig?: ThreadDisplayOptions;
  metadataColumns?: MetadataColumn[];
  excludeSignificantChanges: boolean;
  hasSentiment: boolean;
  hasDate: boolean;
  hasScore: boolean;
  availableDateRange?: { start: moment.Moment; end: moment.Moment; };
  isThematicAdmin: boolean;
  canManageThemes: boolean;
  canReadThemes: boolean;
  setConfig: (config: AnalysisConfig) => void;
  setConfigs: (analysisId: string, config: AnalysisConfig) => void;
  getConfig: (sources: (AnalysisViewSource | AggregateViewSource), dashboardId?: string) => Promise<AnalysisConfig|undefined>;
  getConfigs: (sources: (AnalysisViewSource | AggregateViewSource)[], dashboardId?: string) =>
    Promise<AnalysisConfig[]>;
  reset: () => void;
}

class AnalysisConfigStore implements AnalysisConfigStoreInterface {
  @observable
  config?: AnalysisConfig = undefined;

  @observable
  configs = {};

  @observable
  fetchConfigError?: string = undefined;

  @observable
  fetchConfigsError?: string = undefined;

  @computed
  get filters() {
    return this.config?.filters || [];
  }

  @computed
  get excludeSignificantChanges() {
    return this.config?.visualization_options?.exclude_significant_changes || false;
  }

  @computed
  get threadDisplayConfig() {
    return this.config?.threadDisplayConfig || {};
  }

  @computed
  get metadataColumns() {
    return this.config?.metadata_columns;
  }

  @computed
  get hasSentiment() {
    return this.config?.sentiment !== 'disabled';
  }

  @computed
  get scopes() {
    return this.config?.scopes || [];
  }

  @computed
  get hasScore() {
    return isArray(this.config?.scores);
  }

  @computed
  get canDownload() {
    return this.scopes.includes('download:result');
  }

  @computed
  get canManageThemes() {
    return this.scopes.includes('manage:themes');
  }

  @computed
  get canEditData() {
    return this.scopes.includes('create:upload');
  }

  @computed
  get canManageSurvey() {
    return this.scopes.includes('manage:survey');
  }

  @computed
  get canReadThemes() {
    return this.scopes.includes('read:themes');
  }

  @computed
  get hasDate() {
    return isNumber(this.config?.date_column);
  }

  @computed
  get availableDateRange() {
    return this.config?.date_range ? {
      start: moment(this.config?.date_range[0]),
      end: moment(this.config?.date_range[1])
    } : undefined;
  }

  @computed
  get isThematicAdmin() {
    return includes(this.config?.scopes, 'manage:internalResource');
  }

  @action
  setConfig = async (config: AnalysisConfig) => {
    this.config = config;
  }

  @action
  setConfigs = async (analysisId: string, config: AnalysisConfig) => {
    this.configs = {
      ...this.configs,
      [analysisId]: config
    };
  }

  @action
  getConfig = async (source: AnalysisViewSource | AggregateViewSource, dashboardId?: string): Promise<AnalysisConfig | undefined> => {
    this.fetchConfigError = undefined;

    const analysisId = getAnalysisId(source);

    if (this.configs[analysisId]) {
      return this.configs[analysisId];
    }

    try {
      const config = await this.fetchConfig(source, dashboardId);
      if (config) {
        this.setConfigs(analysisId, config);
      }
      return config;
    } catch (e) {
      this.fetchConfigError = e.message;
      return;
    }
  }

  fetchConfig = async (source: AnalysisViewSource | AggregateViewSource, dashboardId?: string) => {
    const sourceUrl = getSourceUrl(source);
    let url = `${ sourceUrl }config`;

    if (dashboardId) {
      url += `?forReport=${ dashboardId }`;
    }

    try {
      const { data, ok, errorData } = await Auth.fetch<AnalysisConfig>(url, { isRaw: true });
      if (data && ok) {
        return data;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
      return;
    } catch (e) {
      // intentional throw here to let the consumer handle the error
      throw new Error(e.message);
    }
  }

  @action
  getConfigs = async (sources: (AnalysisViewSource | AggregateViewSource)[], dashboardId?: string) => {
    this.fetchConfigsError = undefined;

    let configs: AnalysisConfig[] = [];
    let promises: { analysisId: string; promise: Promise<AnalysisConfig | undefined> }[] = [];

    sources.forEach(source => {
      const analysisId = getAnalysisId(source);

      if (this.configs[analysisId]) {
        configs.push(this.configs[analysisId]);
      } else {
        promises.push({ analysisId, promise: this.fetchConfig(source, dashboardId) });
      }
    });

    if (promises.length > 0) {
      try {
        const responses = await Promise.all(promises.map(u => u.promise));
        responses.forEach((response, index) => {
          if (response) {
            this.setConfigs(promises[index].analysisId, response);
            configs.push(response);
          }
        });
      } catch (e) {
        this.fetchConfigsError = e.message;
      }
    }
    return configs;
  }

  @action
  reset = () => {
    this.config = undefined;
    this.configs = {};
    this.fetchConfigError = undefined;
  }
}

export default AnalysisConfigStore;
