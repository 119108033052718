<template>
  <div class="feedback-sentiment">
    <sentiment
      :sentiment="sentiment"
    />
  </div>
</template>


<script>
import Sentiment from 'vue/components/Sentiment/Sentiment.vue';

export default {
  name: 'FeedbackTableSentimentCell',
  components: { Sentiment },
  props: {
    sentiment: { default: 0, type: Number },
  },
  data() {
    return {
      hover: undefined,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.feedback-sentiment {
  padding: 5px;

  .sentiment-icon {
    width: 28px;
    height: 28px;
    margin: auto;
  }
}


</style>
