<template>
  <div
    class="score-breakdown-headline"
    :class="{ selected: !compareFilter }"
    @click="clearSelection()"
  >
    <h1>{{ label }}</h1>
    <span class="score-breakdown-headline__score">{{ scoreLabel }}</span>
    <div
      class="score-breakdown-headline__change"
    >
      <font-awesome-icon
        v-if="arrow"
        :icon="arrow"
        :class="`movement-${arrow}`"
      />
      <span>{{ changeLabel }}</span>
    </div>
  </div>
</template>

<script>
import { isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';

export default {
  name: 'ScoreBreakdownHeadline',
  props: {
    compareFilter: { type: String },
    label: { type: String },
    previousScore: { type: Number },
    score: { type: Number }
  },
  computed: {
    active() {
      return !this.compareFilter;
    },
    arrow() {
      const { change } = this;

      if (change >= 0.1) {
        return 'arrow-alt-up';
      } else if (change <= -0.1) {
        return 'arrow-alt-down';
      } else {
        return undefined;
      }
    },
    change() {
      const { previousScore } = this;
      if (isNumber(previousScore)) {
        const change = this.score - this.previousScore;
        return parseFloat(toFixed(change, 1));
      }
      return undefined;
    },
    changeLabel() {
      const { change } = this;
      if (isNumber(change)) {
        return Math.abs(change, 1);
      }
      return undefined;
    },
    scoreLabel() {
      const { score } = this;
      if (isNumber(score)) {
        return toFixed(score, 1);
      }
      return undefined;
    }
  },
  methods: {
    clearSelection() {
      this.$emit('clearSelection');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.score-breakdown-headline {
  color: $--neutral-900;
  border: 1px solid $--neutral-200;
  text-align: center;
  padding: 20px;
  max-width: 300px;
  margin: 20px auto;
  height: 150px;
  cursor: pointer;

  &:hover {
    background-color: $--neutral-100;
  }

  &.selected {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    border-bottom: 4px solid $--orange-500;
  }

  h1 {
    color: $--neutral-900;
    font-size: 24px;
  }

  .score-breakdown-headline__score {
    text-align: center;
    padding: 20px 0 20px;
    color: $--primary-500;
    display: inline-block;
    font-size: 64px;
  }

  .score-breakdown-headline__change {
    font-weight: 600;
    display: inline-block;

    span {
      color: $--neutral-600;
      font-size: 16px;
    }

    svg {
      color: $--neutral-600;
      font-size: 12px;
    }
  }
  .movement-arrow-alt-up {
      color: $--green-700 !important;
  }
  .movement-arrow-alt-down {
    color: $--red-600 !important;
  }
}
</style>