import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ThemeCardDropdown } from './ThemeCardDropdown';
import { HoverHint, ThemeCardInterface } from './ThemeTree';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import './theme-card-actions.scss';

interface Props {
  groupId: string;
  id: string;
  disabled: boolean;
  onActionsClose: () => void;
  onDelete: () => void;
  parentId: string | undefined;
  updateTheme: (item: ThemeCardInterface) => void;
  setUpdateCandidate: (updateCandidate?: HoverHint) => void;
}

const ThemeCardActions = React.forwardRef<any, Props>(({ disabled, groupId, id, setUpdateCandidate, onDelete, parentId, updateTheme, onActionsClose }: Props, forwardRef) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const actionsMenuRef = React.useRef<HTMLDivElement | null>(null);

  React.useImperativeHandle(forwardRef, () => ({
    focus: () => {
      actionsMenuRef.current?.focus();
    },
  }));

  const handleDropdownClose = () => {
    if (showDropdown) {
      setShowDropdown(false);
      onActionsClose();
    }
  };

  const handleDropdownOpen = () => {
    setShowDropdown(true);
  };

  const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setShowDropdown(!showDropdown);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      setShowDropdown(!showDropdown);
    }

    if (event.key === 'ArrowDown') {
      event.stopPropagation();
    }
  };

  useOnClickOutside(actionsMenuRef, handleDropdownClose);

  return (
    <div className="theme-card-actions" onKeyDown={handleKeyDown} ref={actionsMenuRef} tabIndex={0}>
      <button
        className={classNames('theme-card-actions__button', {
          'theme-card-actions__button--disabled': disabled
        })}
        disabled={disabled}
        onClick={handleToggleClick}
        type="button"
      >
        <FontAwesomeIcon icon="ellipsis-v" />
      </button>
      {showDropdown && (
        <ThemeCardDropdown
          updateTheme={updateTheme}
          groupId={groupId}
          id={id}
          setUpdateCandidate={setUpdateCandidate}
          open={showDropdown}
          onClose={handleDropdownClose}
          onDelete={onDelete}
          onOpen={handleDropdownOpen}
          parentId={parentId}
        />
      )}
    </div>
  );
});

export { ThemeCardActions };
