import Auth from 'Auth/Auth';
import { AssumedUserToken, PageRequestParams, PaginatedList, User, UserSeatType } from 'api/interfaces';
import { remove } from 'lodash';
import { action, observable } from 'mobx';
import { stringify } from 'query-string';
import { OrganizationStoreInterface } from './OrganizationStore';
import { CreateUserJson, SeatErrorCode } from './UserStore';

const USERS_PAGE_LENGTH = 25;
const USERS_PAGE_PARAMS = {
  page: 1,
  page_len: USERS_PAGE_LENGTH,
  sort_by: 'name'
} as PageRequestParams;

export interface ManageUsersStoreInterface {
  pageParams: PageRequestParams;

  deleting: boolean;
  deleteError: string;

  reinvited: boolean;
  reinviting: boolean;
  reinviteError: string;

  updating: boolean;
  updateError: string;

  creating: boolean;
  createError: string;

  seatError: { code: SeatErrorCode, seatType?: UserSeatType } | null;

  users?: User[];
  totalPages: number;

  fetchingUsers: boolean;
  fetchingUsersError: string;

  assumingUser: boolean;
  assumeUserError: string;

  getUser: (userId: string) => User | undefined;

  setPageParams: (options: PageRequestParams) => void;
  resetPageParams: () => void;

  fetchUsers: () => Promise<void>;

  refreshUsersState: (user: User) => Promise<void>;
  removeUserFromState: (userId: string) => Promise<void>;
  removeRoleForUsers: (roleId: string) => void;

  create: (user: CreateUserJson) => Promise<User>;
  update: (user: User) => Promise<User>;
  delete: (userId: string) => void;
  reinvite: (userId: string) => void;

  reset: () => void;
  resetCurrentUser: () => void;
  resetSeatError: () => void;

  assumeUser: (userId: string, orgId: string) => Promise<boolean>;
}

class ManageUsersStore implements ManageUsersStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  deleting = false;

  @observable
  deleteError = '';

  @observable
  reinvited = false;

  @observable
  reinviting = false;

  @observable
  reinviteError = '';

  @observable
  updating = false;

  @observable
  updateError = '';

  @observable
  creating = false;

  @observable
  createError = '';

  @observable
  seatError: { code: SeatErrorCode, seatType?: UserSeatType } | null = null;

  @observable
  users = [] as User[];

  @observable
  totalPages = 0;

  @observable
  pageParams = { ...USERS_PAGE_PARAMS };

  @observable
  fetchingUsers = false;

  @observable
  fetchingUsersError = '';

  @observable
  assumingUser = false;

  @observable
  assumeUserError = '';

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;
  }

  @action
  setPageParams = (params: PageRequestParams) => {
    this.pageParams = {
      ...this.pageParams,
      ...params
    };
  }

  @action
  resetPageParams = () => {
    this.pageParams = { ...USERS_PAGE_PARAMS };
  }

  @action
  fetchUsers = async () => {
    this.fetchingUsers = true;
    this.fetchingUsersError = '';
    this.totalPages = 0;

    const query = stringify({
      organization: this.currentOrg.orgId,
      ...this.pageParams
    });

    try {
      const { data, ok, errorData } = await Auth.fetch<PaginatedList<User>>(`/users?${ query }`);
      if (ok && data) {
        const { items, total_items } = data;
        this.totalPages = Math.ceil(total_items / USERS_PAGE_LENGTH);
        this.users = items;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.fetchingUsersError = `Failed to fetch users: ${ e.message }`;
    } finally {
      const updatedQuery = stringify({
        organization: this.currentOrg.orgId,
        ...this.pageParams
      });
      // Check if there is a newer API call to continue showing loading in the event of debounce
      if (query === updatedQuery) {
        this.fetchingUsers = false;
      }
    }
  }

  @action
  refreshUsersState = async (user: User) => {
    let { users } = this;
    if (users) {
      const index = users.findIndex(u => u.id === user.id);
      if (index < 0) {
        users.push(user);
      } else {
        users[index] = user;
      }
      this.users = users;
    } else {
      this.users = [user];
    }
  }

  @action
  removeUserFromState = async (userId: string) => {
    if (this.users) {
      remove(this.users, (u) => u.id === userId);
    }
  }

  @action
  removeRoleForUsers = (roleId: string) => {
    this.users = this.users.map(user => {
      if (user.userSpecificRole && user.userSpecificRole.id === roleId) {
        delete user.userSpecificRole;
      }
      remove(user.roles, (role) => role.id === roleId);
      return user;
    });
  }

  getUser = (userId: string) => {
    return this.users.find(user => user.id === userId);
  }

  @action
  create = async (user: CreateUserJson) => {
    this.creating = true;
    this.createError = '';
    this.seatError = null;
    let createdUser;

    try {
      const roles = user.roles ? user.roles.map(role => role.id) : [];
      const userToCreate = { ...user, roles };
      const query = this.currentOrg.orgIdQueryParam;
      const { ok, data, errorData } = await Auth.fetch<CreateUserJson>(`/user?${ query }`, {
        body: JSON.stringify(userToCreate),
        method: 'POST'
      });
      if (ok && data) {
        createdUser = data;

        if (data.seatError) {
          this.seatError = { code: data.seatError, seatType: user.seatType };
        }
      } else {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.createError = `Failed to create user: ${ e.message }`;
    } finally {
      this.creating = false;
    }
    return createdUser;
  };

  @action
  update = async (user: User) => {
    this.updating = true;
    this.updateError = '';
    this.seatError = null;
    let updatedUser;

    try {
      const roles = user.roles.map(role => role.id);
      const userToUpdate = { ...user, roles };
      const query = this.currentOrg.orgIdQueryParam;
      const { ok, data, errorData } = await Auth.fetch<User>(`/user/${ user.id }?${ query }`, {
        body: JSON.stringify(userToUpdate),
        method: 'PUT'
      });
      if (ok && data) {
        updatedUser = data;
        this.refreshUsersState(updatedUser);
      } else {
        if (errorData && errorData.code === 'NO_SEATS_AVAILABLE') {
          this.seatError = { code: errorData.code, seatType: user.seatType };
        }
        else {
          throw new Error(errorData ? errorData.message : 'Please try again');
        }
      }
    } catch (e) {
      this.updateError = `Failed to update user: ${ e.message }`;
    } finally {
      this.updating = false;
    }
    return updatedUser;
  };

  @action
  delete = async (userId: string) => {
    const { orgId: organization } = this.currentOrg;
    const query = stringify({ organization });
    this.deleting = true;
    this.deleteError = '';

    try {
      const { ok, errorData } = await Auth.fetch(
        `/user/${ userId }?${ query }`,
        {
          method: 'DELETE'
        }
      );
      if (!ok) {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.deleteError = `Failed to delete user: ${ e.message }`;
    } finally {
      this.deleting = false;
    }
  };

  @action
  reinvite = async (userId: string) => {
    const { orgId: organization } = this.currentOrg;
    const query = stringify({ organization });
    this.reinvited = false;
    this.reinviting = true;
    this.reinviteError = '';

    try {
      const { ok, errorData } = await Auth.fetch(
        `/user/${ userId }/reinvite?${ query }`,
        {
          method: 'PUT'
        }
      );

      if (ok) {
        this.reinvited = true;
      } else {
        if (!ok) {
          throw new Error(errorData ? errorData.message : 'Please try again');
        }
      }
    } catch (e) {
      this.reinviteError = `Failed to reinvite user: ${ e.message }`;
    } finally {
      this.reinviting = false;
    }
  };

  @action
  reset = () => {
    this.users = [];
    this.fetchingUsers = false;
    this.fetchingUsersError = '';

    this.resetPageParams();
    this.resetCurrentUser();
  }

  @action
  resetCurrentUser = () => {
    this.deleting = false;
    this.deleteError = '';

    this.reinviting = false;
    this.reinvited = false;
    this.reinviteError = '';

    this.creating = false;
    this.createError = '';

    this.updating = false;
    this.updateError = '';
  }
  @action
  resetSeatError = () => {
    this.seatError = null;
  }

  @action
  assumeUser = async (userId: string, orgId: string) => {
    this.assumingUser = true;
    this.assumeUserError = '';
    let result = true;
    try {
      const { data, ok, errorData } = await Auth.fetch<AssumedUserToken>(`/user/${ userId }/assume?organization=${ orgId}`);
      if (ok && data) {
        const { accessToken } = data;
        await Auth.assumeUser(userId, accessToken);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.assumeUserError = `Failed to assume user permissions: ${ e.message }`;
      result = false;
    }
    this.assumingUser = false;
    return result;
  }

}

export default ManageUsersStore;