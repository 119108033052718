import { Button } from 'components/Shared/Button';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import TagAddIcon from 'images/icons/tag-add.svg';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { MAX_SELECTED_WORDS, SELECTION_STATUS, SelectionStatus } from '../AddTheme/types';
import './add-theme-pop-up.scss';

interface Props {
  clearSelection: () => void;
  isOpen: boolean;
  isApplyingThemes: boolean;
  onAddTheme: (event: React.MouseEvent) => void;
  selectionStatus: SelectionStatus;
}

const AddThemePopUp = ({
  clearSelection,
  isOpen,
  isApplyingThemes,
  onAddTheme,
  selectionStatus
}: Props) => {
  const addThemeRef = React.useRef<HTMLDivElement>(null);

  const getPopUpContent = () => {
    if (isApplyingThemes) {
      return 'Theme editing is not possible while theme application is in progress.';
    }

    if (selectionStatus === SELECTION_STATUS.InvalidCrossesBoundary) {
      return 'Please select text that does not cross a sentence boundary.';
    }

    if (selectionStatus === SELECTION_STATUS.InvalidTooManyWords) {
      return `Please choose a phrase of ${MAX_SELECTED_WORDS} words or fewer. We advise choosing the minimum number of words which capture the meaning of the theme that you would like to represent.`;
    }

    if (selectionStatus === SELECTION_STATUS.Valid) {
      return (
        <div className="react-add-theme-pop-up__add-theme" ref={addThemeRef}>
          <Button
            onClick={onAddTheme}
            icon={<TagAddIcon />}
            subvariant="neutral-text"
            variant="tertiary"
          >
            Add theme
          </Button>
        </div>
      );
    }

    return null;
  };

  useOnClickOutside(addThemeRef, () => {
    clearSelection();
  })

  return (
    <div>
      <Popup
        basic={true}
        className="react-add-theme-pop-up"
        content={getPopUpContent()}
        open={isOpen}
        position="bottom center"
        trigger={<span></span>}
      />
    </div>
  );
};

export { AddThemePopUp };
