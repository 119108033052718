import { traverseNodes } from "lib/node-helpers";
import { AnswerOutput, Node } from "types/custom";

export function assignFallbackDates(
  nodes: Node<AnswerOutput>[],
  fallbackUtcDateString: string
):Node<AnswerOutput>[] {
  return traverseNodes(node => {
    node.item.createdAt = node.item.createdAt || fallbackUtcDateString;

    return node;
  }, nodes);
}
