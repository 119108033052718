<template>
  <div class="comment-tags">
    <el-alert
      v-if="deleteError || updateError"
      :title="deleteError || updateError"
      type="error"
    />
    <div
      v-if="commentTags.length > 0"
      class="comment-tag-list"
    >
      <el-tag
        v-for="tag in commentTags"
        :key="tag"
        class="comment-tag"
        type="info"
        :closable="canEditData && editableView"
        :disable-transitions="false"
        @close="removeTag(tag)"
      >
        {{ (tagStore.tags[tag] || {}).fullName }}
      </el-tag>
    </div>
    <tag-dropdown
      v-if="canEditData && editableView"
      :tags="tagOptions"
      :tag-dropdown-placement="tagDropdownPlacement"
      @toggleTag="toggleTag"
      @createNewTag="createNewTag"
    />
  </div>
</template>

<script>
import {
  getAnalysisToolsUIStore,
  getTagStore,
  getSurveyStore,
  getAnalysisConfigStore
} from 'stores/RootStore';
import TagDropdown from './TagDropdown';
import analytics from 'lib/analytics';

export default {
  name: 'CommentTags',
  components: { TagDropdown },
  props: {
    commentTags: { default: () => [], type: Array },
    commentId: { type: String, required: true },
    commentColumnId: { type: [Number, String], required: true },
    location: { type: String },
    tagDropdownPlacement: { type: String },
    editableView: { type: Boolean, default: true }
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      surveyStore: getSurveyStore(),
      tagStore: getTagStore(),
      deleteError: '',
      updateError: '',
      createError: ''
    };
  },
  computed: {
    currentSurveyId() {
      return this.analysisToolsUIStore.currentSurveyId;
    },
    canManageSurvey() {
      return this.analysisConfigStore.canManageSurvey;
    },
    canEditData() {
      return this.analysisConfigStore.canEditData;
    },
    tagOptions() {
      return this.tagStore.sortedTags.map(t => ({
        ...t,
        isActive: this.commentTags.includes(t.id)
      }));
    },
    deletedTags() {
      return this.commentTags.filter(tagId => !this.tagStore.tags[tagId]);
    }
  },
  watch: {
    deletedTags: {
      handler() {
        // If tags are deleted globally via the 'manage labels' modal, remove them from this commment.
        this.deletedTags.forEach(this.removeTag);
      },
      immediate: true
    }
  },
  methods: {
    async removeTag(tag) {
      this.resetErrors();

      const previousTags = this.commentTags;
      const newTags = this.commentTags.filter(t => t !== tag);

      this.$emit('updateTags', newTags);

      await this.surveyStore.updateCommentRecords(
        this.currentSurveyId,
        this.commentColumnId,
        this.commentId,
        newTags
      );

      if (this.surveyStore.updateCommentRecordsError) {
        this.deleteError = this.surveyStore.updateCommentRecordsError;
        this.$emit('updateTags', previousTags);
      }

      analytics.track('Comment untagged', {
        category: 'Analysis',
        location: this.location
      });
    },

    toggleTag(tagId) {
      if (this.commentTags.includes(tagId)) {
        this.removeTag(tagId);
      } else {
        this.addTag(tagId);
      }
    },

    async addTag(tag, isNewTag = false) {
      const tagAlreadyExists = this.commentTags.find(t => t === tag);
      if (tagAlreadyExists) {
        return;
      }

      this.resetErrors();

      const previousTags = this.commentTags;
      const newTags = [...this.commentTags, tag];

      this.$emit('updateTags', newTags);

      await this.surveyStore.updateCommentRecords(
        this.currentSurveyId,
        this.commentColumnId,
        this.commentId,
        newTags
      );

      if (this.surveyStore.updateCommentRecordsError) {
        this.updateError = this.surveyStore.updateCommentRecordsError;
        this.$emit('updateTags', previousTags);
      }

      analytics.track('Comment tagged', {
        category: 'Analysis',
        location: this.location,
        'New Tag': isNewTag
      });
    },

    onCreateTag(tag) {
      this.addTag(tag, true);
    },

    resetErrors() {
      this.updateError = '';
      this.deleteError = '';
    },

    async createNewTag(tagName, parentId, onTagCreated) {
      this.createError = '';

      const tag = await this.tagStore.createTag(tagName, parentId);
      onTagCreated();
      if (tag) {
        this.addTag(tag.id, true);
      } else {
        this.createError = this.tagStore.createTagError;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.comment-tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px 5px -5px;

  > * {
    margin: 5px;
  }
}

.comment-tag {
  line-height: 25px;
  height: 26px;
  font-size: 14px;
}

.comment-tag.el-tag.el-tag--info {
  color: $--neutral-700;
}

.el-alert--error {
  margin-bottom: 5px;
}
</style>
