import * as React from 'react';
import * as classnames from 'classnames';
import { Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './button-bar.scss';
interface ButtonBarInfoProps {
  isError?: boolean;
  message: string;
  onRetry?: () => void;
  title?: string;
}
export class ButtonBarInfo extends React.Component<ButtonBarInfoProps> {
  render() {
    let el;

    const { isError = false, message, onRetry, title } = this.props;
    if (isError) {
      el = (
        <div className="button-bar__info button-bar__info-error">
          <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
          {message}
          <button onClick={onRetry}>Retry</button>
        </div>
      );
    } else {
      el = <div className="button-bar__info">{message}</div>;
    }

    if (title) {
      return (
        <Popup
          content={title}
          inverted={true}
          on="hover"
          position="top center"
          trigger={el}
        />
      );
    } else {
      return el;
    }
  }
}

interface ButtonGroupProps {
  shrink?: boolean;
}
export class ButtonGroup extends React.Component<ButtonGroupProps> {
  render() {
    const { children, shrink } = this.props;
    return (
      <div
        className={classnames('button-bar__group', {
          'button-bar__group-shrink': shrink
        })}
      >
        {children}
      </div>
    );
  }
}

export interface ButtonBarProps {
  noGap?: boolean;
}
export default class ButtonBar extends React.Component<
  ButtonBarProps & React.ReactNode
> {
  render() {
    const { noGap } = this.props;
    return (
      <div
        className={classnames('button-bar', { 'button-bar__no-gap': noGap })}
      >
        {this.props.children}
      </div>
    );
  }
}
