import * as React from 'react';

interface BoldSubstringProps {
  value: string;
  regex: RegExp;
}

class BoldSubstring extends React.Component<BoldSubstringProps, {}> {
  render() {
    const { value, regex } = this.props;
    const parts = value.split(regex);
    return parts.map((part, i) => {
      if (regex.test(part)) {
        return <b key={i}>{part}</b>;
      } else {
        return <React.Fragment key={i}>{part}</React.Fragment>;
      }
    });
  }
}

export default BoldSubstring;
