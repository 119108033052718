import * as React from 'react';
import './theme-percentage-pill.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import toFixed from 'vue/libs/to-fixed';

type Theme = {
  name: string;
  percentage: number;
};

type Props = {
  theme: Theme;
};

const ThemePercentagePill = withCamelCaseProps(({ theme }: Props) => {

  return (
    <div
      className="theme-pill"
      aria-label={`theme pill: ${theme.name}`}
    >
      <div
        className="theme__name"
        aria-label="theme name"
      >
        {theme.name}
      </div>
      <div
        className="theme__percentage"
        aria-label="theme percentage"
      >
        {toFixed(theme.percentage, 0, '%')}
      </div>
    </div>
  );
});

export { ThemePercentagePill };
