import { OrganizationJson } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { action, observable } from 'mobx';
import config from '../runtime-config';

export interface OrganizationsStoreInterface {
  organizations: OrganizationJson[];

  fetchingOrganizations: Promise<any> | null;
  fetchedOrganizations: boolean;
  fetchingOrganizationsError: string;
  adminConfigLinks: {[key: string]: string};

  fetchOrganizations: ()  => Promise<void>;
  getCanAction: (orgId: string, actionRequired: string) => boolean;
  getWorkspaceName: (orgId: string) => string | undefined;
  getConfigLink: (displayId: string) => string | undefined;
}

class OrganizationsStore implements OrganizationsStoreInterface {
  @observable
  organizations = [] as OrganizationJson[];

  @observable
  adminConfigLinks: {[key: string]: string} = {};

  @observable
  fetchingOrganizations = null as Promise<any> | null;

  @observable
  fetchedOrganizations = false;

  @observable
  fetchingOrganizationsError = '';

  @action
  fetchOrganizations = async () => {
    // if we aren't already fetching, go fetch
    if (!this.fetchingOrganizations) {
      this.fetchedOrganizations = false;
      this.fetchingOrganizationsError = '';
      this.fetchingOrganizations = Auth.fetch<OrganizationJson[]>('/organizations');
    }
    // at this point there should be a promise
    try {
      const { ok, data, errorData } = await this.fetchingOrganizations;
      if (ok && data) {
        this.organizations = data;
        this.fetchedOrganizations = true;
      } else {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.fetchingOrganizationsError = `Failed to fetch workspaces: ${e.message}`;
    } finally {
      this.fetchingOrganizations = null;
    }
  }

  @action
  getConfigLink = (displayId: string) => {
    if (this.getCanAction(displayId, 'manage:internalResource')) {
      if (displayId in this.adminConfigLinks) {
        return this.adminConfigLinks[displayId];
      } else {
        const adminLocation = config.adminLocation;
        const tokens = [] as string[];
        if (adminLocation) {
          tokens.push(adminLocation);
        }
        tokens.push(`/admin/#!/c/${displayId}/thematic_admin/${displayId}`);
        const configLink =  tokens.join('');
        this.adminConfigLinks[displayId] = configLink;
        return configLink;
      }
    } else {
      return undefined;
    }
  }

  getCanAction = (displayId: string, actionRequired: string) => {
    if (this.organizations) {
      const org = this.organizations.find(o => o.displayId === displayId);
      if (!org) {
        return false;
      }
      return org.currentUserCan.includes(actionRequired);
    }
    return false;
  }

  getWorkspaceName = (displayId: string) => {
    return (this.organizations.find(o => o.displayId === displayId) || {}).name;
  }
}

export default OrganizationsStore;
