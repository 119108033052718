import * as React from 'react';
import { AnswersError } from './AnswersError';

export const BoundaryError = () => (
  <AnswersError
    tracking={{
      event: "Error: Answers",
      eventOptions: {
        "title": "An unknown error occurred",
        "description": "Please refresh and try again. If the problem persists, please contact your Thematic representative."
      }
    }}
  >
    <p>Please refresh and try again. If the problem persists, please contact your Thematic representative.</p>
  </AnswersError>
);
