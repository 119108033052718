import analytics from 'lib/analytics';
import { getAnalysisConfigStore, getAnalysisToolsUIStore, getThemesStore } from 'stores/RootStore';
import { getHighlightsFromParts } from "vue/libs/find-highlighted-sentences";
import toThreadParts from "vue/libs/to-thread-parts.ts";

export default function () {
  return {
    props: {
      comment: { default: [], type: Array },
    },
    data() {
      return {
        analysisToolsUIStore: getAnalysisToolsUIStore(),
        analysisConfigStore: getAnalysisConfigStore(),
        themesStore: getThemesStore(),
      };
    },
    computed: {
      areThemesInApplyingState() {
        return this.themesStore.applying;
      },
      canManageThemes() {
        return this.analysisConfigStore.canManageThemes;
      },
      canSeeInlineThemesEditing() {
        return this.canManageThemes;
      },
      segments() {
        return this.comment.flatMap(comment => comment.segments);
      },
      conversations() {

        const { threadDisplayConfig } = this.analysisConfigStore;

        const conversations = toThreadParts(
          threadDisplayConfig,
          this.comment,
        );

        return conversations;
      },
    },
    methods: {
      removeTheme(theme, block, where) {

        if (!this.areThemesInApplyingState) {

          const highlightedSentences = block
            ? [block.content]
            : getHighlightsFromParts(this.comment, theme);

          const removeThemeParams = {
            highlightedSentences,
            commentId: this.commentId,
            selectedTheme: {...theme},
            selectedBlock: block,
          };
          this.analysisToolsUIStore.showRemoveThemeDialog(removeThemeParams);
          analytics.track('Analysis: Remove Theme Started', { Location: where });
        }
      },
    }
  };
}
