import { AnswersDataSet } from 'api/interfaces';
import { getSurveyStore } from 'stores/RootStore';
import { DatasetVis } from 'stores/data-structure/datasets';

export function analysisToDataset(analysis: DatasetVis): AnswersDataSet {
  const surveyStore = getSurveyStore();
  const canReadSurvey = surveyStore.getSurveyCanAction(analysis.surveyId, 'read:survey');
  return {
    surveyId: analysis.surveyId,
    viewId: analysis.viewId || null,
    visId: analysis.visualizationId,
    title: analysis.fullTitle,
    canReadSurvey
  };
}
