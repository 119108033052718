<template>
  <div
    class="explore-tool__header"
    :class="{ 'has-score-selector': showScoreSelect, 'has-sentiment-selector': showSentimentFilter }"
  >
    <div class="explore-tool__axis-selector">
      <span class="explore-tool__header-title">Browse themes by</span>
      <el-radio-group
        v-model="exploreAxis"
        size="mini"
      >
        <el-radio-button
          v-for="axis in exploreAxes"
          :key="axis.key"
          class="ob-explore-chart-browse-radio-button"
          :value="axis.key"
          :label="axis.key"
          :title="axis.title"
        >
          {{ axis.title }}
        </el-radio-button>
      </el-radio-group>

      <el-select
        v-if="showScoreSelect"
        v-model="score"
        class="el-select--inline explore-tool__header-score-selector"
        size="mini"
        value-key="key"
        popper-class="select-popper"
      >
        <el-option
          v-for="item in scores"
          :key="item.key"
          :label="item.name"
          :value="item.key"
        />
      </el-select>

      <span
        v-if="showSentimentFilter"
        class="explore-tool__header-title explore-tool__header-title2"
      >Show Sentiment&nbsp;</span>
      <el-switch
        v-if="showSentimentFilter"
        :value="showSentimentOverlay"
        @change="onViewOverlayChange"
      />
    </div>

    <el-input
      v-model="searchFilter"
      :suffix-icon="searchFilter ? '' : 'el-icon-search'"
      autofocus="on"
      clearable
      size="mini"
      placeholder="Search themes"
      @keyup.esc.native="onSearchClear"
    />
    <el-dropdown
      :hide-on-click="false"
      trigger="click"
    >
      <el-button
        class="chart-action"
        size="small"
        type="plain"
      >
        <font-awesome-icon icon="cog" />
        <font-awesome-icon icon="chevron-down" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <div class="chart-options">
          <div class="chart-option">
            <div class="chart-option-label">
              Sort by:
            </div>
            <ul class="chart-option-list">
              <li class="chart-option-item">
                <el-radio
                  v-model="sort"
                  label="high"
                  @change="sortTouched = true"
                >
                  <span v-if="axisType === 'volume'">Highest volume</span>
                  <span v-else>Highest value</span>
                </el-radio>
              </li>
              <li class="chart-option-item">
                <el-radio
                  v-model="sort"
                  label="low"
                  @change="sortTouched = true"
                >
                  <span v-if="axisType === 'volume'">Lowest volume</span>
                  <span v-else>Lowest value</span>
                </el-radio>
              </li>

              <li class="chart-option-item">
                <el-radio
                  v-model="sort"
                  :disabled="!hasCalculatedComparison"
                  label="difference"
                  @change="sortTouched = true"
                >
                  Comparison difference
                </el-radio>
                <thematic-tooltip
                  :class="{ disabled: !hasCalculatedComparison }"
                  placement="top-end"
                >
                  Orders the themes by the difference between blue and orange
                  <br>
                </thematic-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <el-button
      v-if="downloading"
      disabled="disabled"
      size="small"
      type="plain"
    >
      <font-awesome-icon
        spin
        icon="spinner"
      />
    </el-button>
    <el-dropdown
      v-else-if="analysisConfigStore.canDownload"
      trigger="click"
      @command="handleCommand"
    >
      <el-button
        size="small"
        type="plain"
      >
        <font-awesome-icon icon="cloud-download-alt" />
        <font-awesome-icon icon="chevron-down" />
      </el-button>
      <el-dropdown-menu
        slot="dropdown"
        class="explore-tool-download-items"
      >
        <el-dropdown-item
          class="download-item"
          command="showExportInsight"
        >
          <font-awesome-icon icon="file-image" />
          <span class="download-text">Download chart (PNG)</span>
        </el-dropdown-item>
        <el-dropdown-item
          class="download-item"
          command="downloadPPTX"
        >
          <font-awesome-icon icon="file-powerpoint" />
          <span class="download-text">Download chart (PPTX)</span>
        </el-dropdown-item>
        <el-dropdown-item
          class="download-item"
          command="downloadChartData"
        >
          <font-awesome-icon icon="file-csv" />
          <span class="download-text">Download chart (CSV)</span>
        </el-dropdown-item>
        <el-dropdown-item
          class="download-item"
          command="downloadAll"
          :divided="true"
        >
          <font-awesome-icon icon="file-csv" />
          <span class="download-text">Download raw data (CSV)</span>
        </el-dropdown-item>
        <el-dropdown-item
          class="download-item"
          command="openExportAssistant"
        >
          <font-awesome-icon icon="file-csv" />
          <span class="download-text">Download table data (CSV)</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import analytics from 'lib/analytics';
import { capitalize, get } from 'lodash';
import { addWidgetToDigest, createDigest, downloadDigest, generateSubtitle } from 'vue/libs/pptx-digest';
import { AxisType } from 'stores/ui/ExploreToolUIStore';

import {
  getAnalysisConfigStore,
  getAnalysisToolsUIStore,
  getFilterStore,
  getInitConfigStore,
  getExploreToolUIStore,
} from 'stores/RootStore';
import ThematicTooltip from 'vue/components/Tooltip/Tooltip.vue';
import { toImpactArray, toScoreArray, toVolumeArray } from 'vue/explore/theme-data-functions';
import downloadResultsHelper from 'vue/libs/download-results';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'ExploreToolHeader',
  components: { ThematicTooltip },
  props: {
    hasComparison: { default: false, type: Boolean },
    hasImpactComparison: { default: false, type: Boolean },
    themes: { default: undefined, type: Array },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      filterStore: getFilterStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      downloading: false,
      exploreAxis: 'volume',
      searchFilter: '',
      score: undefined,
      sort: 'difference',
      sortTouched: false,
      colors: colors,
    };
  },
  computed: {
    axisType() {
      return this.exploreToolUIStore.axisType;
    },
    baseTotal() {
      return this.exploreToolUIStore.baselineCount;
    },
    compTotal() {
      return this.exploreToolUIStore.comparisonCount;
    },
    exploreAxes() {
      return this.exploreToolUIStore.exploreAxes;
    },
    exploreScore() {
      return this.exploreToolUIStore.score;
    },
    exploreSelectedScore() {
      return this.exploreToolUIStore.selectedScore;
    },
    exploreSearchFilter() {
      return this.exploreToolUIStore.searchFilter;
    },
    exploreSort() {
      return this.exploreToolUIStore.sort;
    },
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    scores() {
      return this.initConfigStore.scores;
    },
    toolTitle() {
      return this.initConfigStore.title;
    },
    selectedThemes() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    selections() {
      return this.filterStore.selections;
    },
    hasSentiment() {
      return this.analysisConfigStore.hasSentiment;
    },
    baseTitle() {
      return get(this, 'selections.baseline.title');
    },
    compTitle() {
      return get(this, 'selections.comparison.title');
    },
    hasCalculatedComparison() {
      const { axisType, hasComparison, hasImpactComparison } = this;

      if (axisType === 'impact') {
        return hasImpactComparison;
      } else {
        return hasComparison;
      }
    },
    showScoreSelect() {
      const { scores } = this;
      return scores && scores.length > 1 && this.exploreAxis !== 'volume';
    },
    showSentimentFilter() {
      return this.analysisToolsUIStore.showSentimentFilterOnExploreTool;
    },
    showSentimentOverlay() {
      return this.analysisToolsUIStore.viewOverlay === 'sentiment';
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (!to) {
          return;
        }
        const { subtool, volumeBy } = to.query;
        if (subtool === AxisType.Impact || subtool === AxisType.Score) {
          this.exploreToolUIStore.setAxis(subtool);
        }
        if (volumeBy) {
          this.exploreToolUIStore.setSentimentFilter(volumeBy);
        }
      },
      immediate: true,
    },
    exploreAxis(axisType) {
      this.exploreToolUIStore.setAxis(axisType);
    },
    sentimentFilter(sentimentFilter) {
      this.exploreToolUIStore.setSentimentFilter(sentimentFilter);
    },
    hasCalculatedComparison() {
      this.checkComparison();
    },
    score(score) {
      this.exploreToolUIStore.setScore(score);
    },
    searchFilter(searchFilter) {
      this.exploreToolUIStore.setSearchFilter(searchFilter);
    },
    sort(sort) {
      this.exploreToolUIStore.setSort(sort);
    },
    axisType() {
      // Sync with latest sentiment filter when sentiment filter is shown
      if (this.showSentimentFilter) {
        this.exploreToolUIStore.setSentimentFilter(this.volumeType);
      }
    },
    volumeType: {
      handler(volumeType) {
        this.sentimentFilter = volumeType;
      },
      immediate: true,
    },
  },
  mounted() {
    const { axisType, exploreScore, exploreSearchFilter, exploreSort, scores } = this;

    // if not set, default score to first score
    if (!exploreScore && scores && scores.length) {
      this.score = scores[0].key;
    } else {
      this.score = exploreScore;
    }

    this.exploreAxis = axisType;
    this.searchFilter = exploreSearchFilter;
    this.sort = exploreSort;

    this.checkComparison();
  },
  methods: {
    checkComparison() {
      const { hasCalculatedComparison, sort, sortTouched } = this;
      if (hasCalculatedComparison && !sortTouched) {
        this.sort = 'difference';
      } else if (!hasCalculatedComparison && sort === 'difference') {
        this.sort = 'high';
      }
    },
    downloadAll: async function () {
      const { baseTitle, compTitle } = this;
      this.downloading = true;

      analytics.track('Export: All data', {
        category: 'Analysis',
        label: baseTitle == 'All' && compTitle == 'All' ? 'All' : 'Filtered',
      });

      try {
        await downloadResultsHelper.downloadResults();
      } finally {
        this.downloading = false;
      }
    },
    downloadPPTX: async function () {
      const { commentColumns, toolTitle, exploreSelectedScore, sort } = this;

      this.downloading = true;

      const selectedBasetheme = get(this, 'selectedThemes.theme.code', null);
      const selectedSubtheme = get(this, 'selectedThemes.subtheme.code', null);

      let toolSubtitle = generateSubtitle(
        get(this, 'selectedThemes.theme.name', null),
        get(this, 'selectedThemes.subtheme.name', null),
        true,
      );

      let ordering = 'baseline';
      let sorting = undefined;
      if (sort == 'difference') {
        ordering = 'difference';
      } else if (sort == 'low') {
        sorting = 'asc_abs';
      } else if (sort == 'high') {
        sorting = 'desc_abs';
      }

      const digest = createDigest(toolTitle, toolSubtitle);
      addWidgetToDigest(digest, {
        commentColumns,
        focusTheme: selectedBasetheme,
        numThemes: 10,
        ordering,
        sort: sorting,
        compareToOverall: true,
        type: 'THEMES_SIMPLE',
      });
      addWidgetToDigest(digest, {
        commentColumns,
        basetheme: selectedBasetheme,
        focusTheme: selectedSubtheme,
        numThemes: 10,
        ordering,
        sort: sorting,
        compareToOverall: true,
        type: 'THEMES_SIMPLE_SUB',
      });
      if (exploreSelectedScore) {
        const score = {
          type: exploreSelectedScore.score_type,
          column: exploreSelectedScore.score_column,
          name: exploreSelectedScore.name,
          options: exploreSelectedScore.score_options,
        };

        addWidgetToDigest(digest, {
          commentColumns,
          score,
          ordering,
          sort: sorting,
          focusTheme: selectedBasetheme,
          numThemes: 10,
          compareToOverall: true,
          type: 'IMPACT_SIMPLE',
        });
        addWidgetToDigest(digest, {
          commentColumns,
          score,
          ordering,
          sort: sorting,
          basetheme: selectedBasetheme,
          focusTheme: selectedSubtheme,
          numThemes: 10,
          compareToOverall: true,
          type: 'IMPACT_SIMPLE_SUB',
        });
      }

      try {
        downloadDigest(digest);
      } finally {
        this.downloading = false;
      }
    },
    downloadChartData: async function () {
      const { axisType, toolTitle, baseTitle, compTitle } = this;

      analytics.track('Export: Chart csv', {
        category: 'Analysis',
        label: baseTitle == 'All' && compTitle == 'All' ? 'All' : 'Filtered',
      });

      this.downloading = true;
      try {
        let arr;
        if (axisType === AxisType.Impact) {
          arr = toImpactArray(this.themes, this.baseTitle, this.compTitle);
        } else if (axisType === AxisType.Score) {
          arr = toScoreArray(this.themes, this.baseTitle, this.compTitle);
        } else {
          arr = toVolumeArray(this.themes, this.baseTotal, this.compTotal, this.baseTitle, this.compTitle);
        }

        let filename;
        if (baseTitle === compTitle) {
          filename = `${toolTitle} -- ${baseTitle}.csv`;
        } else {
          filename = `${toolTitle} -- ${baseTitle} - ${compTitle}.csv`;
        }
        await downloadResultsHelper.downloadArray(arr, filename);
      } finally {
        this.downloading = false;
      }
    },
    handleCommand(command) {
      const { toolTitle, baseTitle, compTitle } = this;
      if (command === 'downloadAll') {
        this.downloadAll();
      } else if (command === 'downloadChartData') {
        this.downloadChartData();
      } else if (command === 'downloadPPTX') {
        this.downloadPPTX();
      } else if (command === 'showExportInsight') {
        analytics.track('Export: Config PNG', {
          Source: 'Analysis',
          Data: `Theme ${capitalize(this.exploreAxis)}`,
        });
        const fileName =
          baseTitle === compTitle
            ? `${toolTitle} -- ${baseTitle}.png`
            : `${toolTitle} -- ${baseTitle} - ${compTitle}.png`;
        const params = {
          axisType: this.exploreAxis,
          score: this.exploreSelectedScore,
          baselineScores: this.exploreToolUIStore.baselineScores,
          comparisonScores: this.exploreToolUIStore.comparisonScores,
          fileName,
        };
        this.$root.$emit('showExportInsight', params);
      } else if (command === 'openExportAssistant') {
        this.analysisToolsUIStore.showExportAssistant();
      } else {
        // eslint-disable-next-line no-console
        console.error('Unsupported command: ', command);
      }
    },
    onSearchClear() {
      this.searchFilter = '';
    },
    onViewOverlayChange() {
      const showSentimentOverlay = !this.showSentimentOverlay;
      const viewOverlay = showSentimentOverlay ? 'sentiment' : 'none';
      this.analysisToolsUIStore.setViewOverlay(viewOverlay);
      analytics.track('Analysis: Sentiment Selected', { category: 'Analysis' });
    },
    openExportAssistant() {
      this.analysisToolsUIStore.showExportAssistant();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';
@import '../components/Charts/volume-chart-variables.scss';
.explore-tool {
  margin: 10px 15px 20px;
  text-align: left;
  &__main {
    box-shadow: 0 4px 16px rgba(102, 117, 138, 0.18);
    display: grid;
    padding: 0;
    grid-template-rows: auto auto $--volume-chart-height;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'counts'
      'body'
      'comments';
  }
  &__comments {
    grid-area: comments;
    margin-top: 20px;
  }
  &__counts {
    border-bottom: 2px solid $--border-color;
    grid-area: counts;
    display: flex;
    padding: 5px 10px;
    > label {
      align-items: center;
      display: grid;
      grid: 14px 12px / 15px auto;
      grid-gap: 0 5px;
      grid-template-areas: 'spot count';
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      margin: 0 30px 0 0;
      span {
        border-radius: 50%;
        display: block;
        grid-area: spot;
        height: 15px;
        justify-self: center;
        width: 15px;
      }
      &.baseline span {
        background: $--color-primary;
      }
      &.comparison span {
        background: $--color-comparison;
      }
      .count-label {
        color: $--neutral-900;
        grid-area: count;
        font-size: 14px;
        line-height: 12px;
      }
    }
  }
  &__header {
    align-items: center;
    background: $--background-color-light;
    grid-area: header;
    display: flex;
    padding: 6px 10px;
    height: 40px;

    &.has-score-selector,
    &.has-sentiment-selector {
      @media only screen and (max-width: 1270px) {
        .explore-tool__header-title {
          display: none;
        }
      }
      @media only screen and (max-width: 990px) {
        .explore-tool__header-score-selector {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 1230px) {
      &-title {
        display: none;
      }
    }
    &-title2 {
      padding-left: 20px;
    }
    .explore-tool__axis-selector {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      font-size: 14px;
      .el-radio-group {
        margin-right: 5px;
        margin-left: 5px;
      }
      .el-radio-button {
        margin-bottom: 0;
      }
      ::v-deep .el-radio-button__orig-radio + .el-radio-button__inner {
        height: 28px;
        padding: 5px 15px;
      }
    }
    .el-input {
      flex: 0 0 140px;
      margin-right: 10px;
      font-size: 14px;
    }
    .el-dropdown {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      .el-button {
        height: 28px;
      }
    }
  }
  &__chart {
    grid-area: body;
    overflow: hidden;
  }
}
.el-tooltip.disabled {
  opacity: 0.5;
}

.sentiment-filters {
  label {
    margin-bottom: 0px;
    ::v-deep .el-radio-button__inner {
      display: inline-flex;
      line-height: 14px;
      padding: 6px 10px;
      svg {
        height: 14px;
      }
    }
  }
}
</style>

<style lang="scss">
// dropdown is attached to body, therefore this styles are not scoped but are contained with unique classname
.el-dropdown-menu {
  &.explore-tool-download-items {
    .el-dropdown-menu__item {
      padding: 0 14px;
      svg {
        margin-right: 14px;
        line-height: 30px;
      }
    }
    .el-dropdown-menu__item--divided:before {
      margin: 0 -14px;
    }
  }
}
</style>

