import { PhraseSegment } from 'types/custom';
import matches from './matches';
import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';

export default function getCommentHighlightLocations(
  segments: Array<PhraseSegment>,
  selectedThemeCodes: ThemeCodes | undefined,
  hoveredLocation?: [start: number, end: number] | undefined,
  hoveredThemeCodes?: ThemeCodes | undefined
): Array<[start: number, end: number]> {

  if (hoveredLocation) {
    return [hoveredLocation];
  }

  const themeCodes = hoveredThemeCodes || selectedThemeCodes;

  return segments
    .filter(segment => segment.themes.some(theme => matches(theme, themeCodes)))
    .map(segment => segment.location);

}
