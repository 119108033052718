import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';
import { ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import './concept.scss';

interface DescriptorInputProps {
  descriptor: string;
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
  onHandleBlur: (newDescriptorValue: string) => void;
}

interface DescriptorInputState {
  descriptorValue: string;
}

@inject('conceptsEditorUIStore')
@observer
export default class DescriptorInput extends React.Component<DescriptorInputProps, DescriptorInputState> {

  constructor(props: DescriptorInputProps) {
    super(props);
    this.state = {
      descriptorValue: props.descriptor
    };
  }

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  handleDescriptorChange = (e) => {
    const value = e.target.value;
    this.setState({ descriptorValue: value });
    this.conceptsEditorUIStore.validateDescriptor(value, this.props.descriptor);
  }

  handleDescriptorKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.onHandleBlur(this.state.descriptorValue);
    }
  }

  render () {
    const {
      hasMoreThanTwoWords,
      isDuplicate,
      isEmpty,
      existsAsNonDescriptor
    } = this.conceptsEditorUIStore.descriptorErrors;
    const {
      descriptorValue
    } = this.state;
    return (
      <div className="descriptor-input">
        <Form.Field>
          <Input
            type="text"
            value={descriptorValue}
            autoFocus={true}
            ref={input => input && input.focus()}
            onBlur={() => this.props.onHandleBlur(descriptorValue)}
            onChange={this.handleDescriptorChange}
            onKeyPress={this.handleDescriptorKeyPress}
            style={{width: (((descriptorValue.length) + 2) + 'ch')}}
            error={hasMoreThanTwoWords || isDuplicate || isEmpty || existsAsNonDescriptor}
          />
          {hasMoreThanTwoWords &&
            <Label pointing={false} color="red" className="alert">
              Key term cannot contain more than two words.
            </Label>
          }
          {(isDuplicate || existsAsNonDescriptor) &&
            <Label pointing={false} color="red" className="alert">
              This term is already in use.
            </Label>
          }
          {isEmpty &&
            <Label pointing={false} color="red" className="alert">
              Terms cannot be empty.
            </Label>
          }
        </Form.Field>
      </div>
    );
  }
}