import {
  InputColumnType
} from 'api/enums';
import {
  SurveyConfiguration, SurveySynopsis,
  Column as SynopsisColumn
} from 'api/interfaces';
import analytics from 'lib/analytics';
import { indexToColumnName } from 'lib/column-helper';
import { isColumnAComment, isColumnAScore } from 'lib/survey-helpers';
import { compact, first, isArray, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Checkbox, Divider, Dropdown, Form, FormInputProps, Message, Segment } from 'semantic-ui-react';
import 'components/Shared/button.scss';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ConfigureColumns from './ConfigureColumns';
import ConfigureSurveyActions from './ConfigureSurveyActions';
import ConfigureUniqueId from './ConfigureUniqueId';
import ConfirmAnalysisModal from './ConfirmAnalysisModal';
import SampleData from './SampleData';
import { COMMENT_TREATMENT_OPTIONS, Column } from './interfaces';
import { getCommentColumns, getUpdatedConfiguration } from './review-data-helper';
import './review-data.scss';

export interface ReviewDataParams {
  orgId: string;
  surveyId: string;
}

interface ReviewDataStoreProps {
  setupStore?: SetupStoreInterface;
  surveyStore?: SurveyStoreInterface;
}

export interface ReviewDataProps extends ReviewDataStoreProps, RouteComponentProps<ReviewDataParams> {
  dataSynopsis: SurveySynopsis;
  isLastStep?: boolean;
  isAdminOverwrite: boolean;
  onSetupComplete: () => void;
  onBack: () => void;
  onCancel: () => void;
}

export interface ReviewDataState {
  columns: Column[];
  columnsWithError: Column[];
  // Excel column header - A, B, C .... AA, AB, etc.)
  columnAlphabets: { [key: number]: string };
  commentColumnsWarningMessage: string;
  updating: boolean;
  showConfirmationModal: boolean;
  showOverwriteConfirmation: boolean;
  totalRows: number;
  requiresTranslation: boolean;
  requiresRedaction: boolean;
  commentTreatment: string;
  uniqueId: number[];
  hasErrorColumns: boolean;
  hasHeader: boolean;
  showSubmitValidationErrors: boolean;
}

@inject('setupStore', 'surveyStore')
@observer
export class ReviewData extends React.Component<ReviewDataProps, ReviewDataState> {
  constructor(props: ReviewDataProps) {
    super(props);

    this.state = {
      columns: [] as Column[],
      columnsWithError: [] as Column[],
      columnAlphabets: {},
      hasHeader: true,
      commentColumnsWarningMessage: '',
      updating: false,
      showConfirmationModal: false,
      showOverwriteConfirmation: false,
      totalRows: 0,
      requiresTranslation: false,
      requiresRedaction: false,
      commentTreatment: '',
      uniqueId: [] as number[],
      hasErrorColumns: false,
      showSubmitValidationErrors: false
    };
  }

  get survey() {
    const { surveyStore, match } = this.props;
    const { surveyId } = match.params;

    return surveyStore!.surveys.find(s => s.surveyId === surveyId);
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps: ReviewDataProps) {
    if (prevProps.dataSynopsis !== this.props.dataSynopsis ||
      prevProps.match.params.surveyId !== this.props.match.params.surveyId) {
      this.initializeData();
    }
  }

  initializeData = () => {
    const { dataSynopsis } = this.props;
    if (!dataSynopsis || !this.survey) {
      return;
    }

    const configuration = JSON.parse(this.survey.configuration);
    const inputColumns = configuration.input_columns || {};

    // Ignore the columns from the end that are empty
    const synopsisColumns = this.getTrimmedSynopsis(dataSynopsis.columns);

    const columnAlphabets = {};
    synopsisColumns.forEach((_c, i) => {
      columnAlphabets[i] = indexToColumnName(i);
    });

    let columns = synopsisColumns.map((c, i) => {
      const inputColumn = inputColumns[i] || {};
      const column = {
        index: i,
        // Default to column alphabets since we default to doesn't have a header row
        name: inputColumn.name || c.sampleHeader || `Column ${ columnAlphabets[i] }`,
        type: inputColumn.type || c.colType,
        isComment: isColumnAComment(c),
        isScore: isColumnAScore(c),
        totalValues: c.numRows,
        uniqueValues: c.numUniqueValues,
        sampleHeader: c.sampleHeader,
        sample: c.sample
      } as Column;
      if (column.type === InputColumnType.DATE) {
        column.dayFirst = inputColumn.options?.dayfirst !== undefined ? inputColumn.options?.dayfirst : (
          c.colOptions?.dayfirst !== undefined ? c.colOptions?.dayfirst : true);
      }
      return column;
    });

    const totalRows = dataSynopsis.numRows;
    const commentColumnsWarningMessage = this.commentColumnsWarningForColumns(columns, totalRows);

    this.setState({ columns, columnAlphabets, totalRows, commentColumnsWarningMessage });
  }

  getTrimmedSynopsis = (synopsisColumns: SynopsisColumn[]) => {
    const columns = [...synopsisColumns];
    // Remove the columns that are empty starting from the end until a non empty column is found
    for (let i = columns.length - 1; i >= 0; i--) {
      const column = columns[i];
      const { numUniqueValues, sample } = column;
      if (numUniqueValues === 1 && sample.length === 1 && sample[0] === '') {
        columns.splice(i);
      } else {
        break;
      }
    }
    return columns;
  }

  commentColumnsWarningForColumns(columns: Column[], numRows: number): string {
    if (columns.length < 4) {
      return `This file only has a few columns. If you have additional columns,
              upload them so that you can filter your results by e.g. score, date, location, etc.`;
    }

    if (numRows >= 100 && numRows < 200) {
      return `This file has ${ numRows } rows. If you have more data, please
              upload it to provide additional context for Thematic’s AI.`;
    }
    return '';
  }

  onColumnsChange = (columns: Column[]) => {
    this.setState({ columns });
  }

  onHasHeaderChange = (hasHeader: boolean) => {
    this.setState({ hasHeader });
  }

  onColumnsError = (hasErrorColumns: boolean) => {
    this.setState({ hasErrorColumns });
  }

  onNext = () => {
    const { hasErrorColumns, columns } = this.state;
    const { isAdminOverwrite } = this.props;
    if (hasErrorColumns) {
      return;
    }

    const hasConfig = !isEmpty(JSON.parse(this.survey!.configuration));
    const hasComments = !isEmpty(getCommentColumns(columns));
    if (hasComments) {
      if (hasConfig && isAdminOverwrite) {
        this.setState({ showOverwriteConfirmation: true });
      } else {
        this.setState({ showConfirmationModal: true });
      }
    } else {
      this.setState({ showSubmitValidationErrors: true });
    }
  }

  onCancelConfirmation = () => {
    this.setState({ showConfirmationModal: false });
  }

  onProceed = async () => {
    this.setState({ showConfirmationModal: false });

    if (!this.survey) {
      return;
    }

    analytics.track('Setup Flow: Complete', { category: 'Setup Flow' });

    this.setState({ updating: true });

    const { columns, hasHeader, requiresTranslation, requiresRedaction, commentTreatment, uniqueId } = this.state;
    const { isAdminOverwrite, dataSynopsis, match, setupStore, surveyStore } = this.props;
    const { surveyId } = match.params;

    let existingConfiguration = JSON.parse(this.survey.configuration) as SurveyConfiguration;
    if (isAdminOverwrite) {
      existingConfiguration = {};
    }
    const hasNoConfig = isEmpty(existingConfiguration);

    const configuration = getUpdatedConfiguration(
      existingConfiguration,
      columns,
      hasHeader,
      requiresTranslation,
      requiresRedaction,
      commentTreatment,
      dataSynopsis.numColumns,
      uniqueId
    );

    const updatedSurvey = await surveyStore!.updateSurvey(surveyId, { configuration });

    if (updatedSurvey && !surveyStore!.updateSurveyError) {
      // This should only run after comment configuration is complete
      let promises = [setupStore!.runInitialJob(surveyId)] as any[];

      const updatedConfig = JSON.parse(updatedSurvey.configuration) as SurveyConfiguration;
      const { comment_columns: commentColumns, input_columns: inputColumns } = updatedConfig;

      if (hasNoConfig && isArray(commentColumns) && commentColumns.length > 1) {
        // Create a view for each comment column selected
        commentColumns.forEach((commentColumn, index) => {
          const config = { comment_columns: [commentColumn] };
          let name = (inputColumns && inputColumns[commentColumn] && inputColumns[commentColumn].name) || 'View';
          if (name.length > 50) {
            name = `${ name.substring(0, 20) } ... ${ name.substring(name.length - 20) }`;
          }
          promises.push(
            setupStore!.createView(surveyId, {
              configuration: config,
              isPreview: false,
              name,
              order: index
            })
          );
        });
      }
      const survey = first(await Promise.all(promises));

      if (!setupStore!.runInitialJobError && survey) {
        this.props.onSetupComplete();
      }
    }
    this.setState({ updating: false });
  }

  isThematicAdmin = () => {
    // currently this permission is restricted to Thematic Admins (they are the only ones with manage:internalResource)
    return !!this.survey && this.props.surveyStore!.getSurveyCanAction(this.survey.surveyId, 'manage:internalResource');
  };

  onTranslationValueChange = () => {
    this.setState(prevState => ({ requiresTranslation: !prevState.requiresTranslation }));
  }

  onRedactionValueChange = () => {
    this.setState(prevState => ({ requiresRedaction: !prevState.requiresRedaction }));
  }

  renderThematicOptionsCheckbox = () => {
    const { requiresTranslation, requiresRedaction } = this.state;

    return (
      <div className="admin-comment-config">
        <h4>Extra Options (Thematic only)</h4>
        <Checkbox
          id="requires-redaction"
          className="nw-redact-comments"
          label={<label htmlFor="requires-redaction">Requires redaction</label>}
          checked={requiresRedaction}
          onChange={this.onRedactionValueChange}
        />
        <br />
        <Checkbox
          id="translate-comments"
          data-testid="translate-comments"
          className="nw-translate-comments"
          label={<label htmlFor="translate-comments">Requires translation into English</label>}
          checked={requiresTranslation}
          onChange={this.onTranslationValueChange}
        />
      </div>
    );
  }

  changeCommentTreatment = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    this.setState({ commentTreatment: value });
  };

  renderCommentColumnTreatmentOptions = () => {
    const { columns } = this.state;
    const commentColumns = getCommentColumns(columns);
    const disableCommentTreatmentOptions = commentColumns.length <= 1;

    return (
      <div className="admin-comment-config">
        <h4>Comment treatment (Thematic only)</h4>
        <Dropdown
          className="nw-comment-treatment"
          selection={true}
          disabled={disableCommentTreatmentOptions}
          options={COMMENT_TREATMENT_OPTIONS}
          placeholder="Select"
          onChange={this.changeCommentTreatment}
          selectOnBlur={false}
        />
      </div>
    );
  }

  onUniqueIdChange = (uniqueId: number[]) => {
    this.setState({ uniqueId });
  }

  confirmOverwrite = () => {
    this.setState({ showOverwriteConfirmation: false, showConfirmationModal: true });
  }

  cancelOverwrite = () => {
    this.setState({ showOverwriteConfirmation: false });
  }

  render() {
    const {
      totalRows, updating, showConfirmationModal, columns,
      commentColumnsWarningMessage, showOverwriteConfirmation, columnAlphabets, hasHeader,
      showSubmitValidationErrors, uniqueId
    } = this.state;
    const { onBack, onCancel } = this.props;
    const { runInitialJobError } = this.props.setupStore!;
    const { updateSurveyError } = this.props.surveyStore!;
    const comments = getCommentColumns(columns);
    const noCommentSelectedError = 'Please indicate which column(s) contain comments in order to start analysis.';
    const errors = compact([
      showSubmitValidationErrors && comments.length === 0 ? noCommentSelectedError : '',
      updateSurveyError,
      runInitialJobError
    ]);

    const isThematicAdmin = this.isThematicAdmin();

    const formatter = new Intl.NumberFormat();

    const formattedTotalRows = formatter.format(totalRows);
    const rows = totalRows === 1 ? 'row' : 'rows';

    const commentColumns = columns.filter(c => c.isComment);
    const columnsWithInsufficientUniqueComments = commentColumns.filter(column => column.uniqueValues < 100);
    const disableNext = isThematicAdmin ? false : columnsWithInsufficientUniqueComments.length > 0;

    return (
      <Segment className="review-data">
        {showOverwriteConfirmation &&
          <ConfirmationModal
            confirmationText={`Overwrite config for "${ this.survey?.title }"?`}
            confirmationButtonText="Overwrite config"
            danger={true}
            cancel={this.cancelOverwrite}
            confirm={this.confirmOverwrite}
          >
            This will overwrite the existing configuration for "{this.survey?.title}".
          </ConfirmationModal>
        }
        {showConfirmationModal &&
          <ConfirmAnalysisModal
            commentColumns={(comments || []).map(c => c.name)}
            onCancel={this.onCancelConfirmation}
            onProceed={this.onProceed}
          />
        }
        <h4>Imported {formattedTotalRows} {rows}</h4>
        {commentColumnsWarningMessage && (
          <Message
            key={commentColumnsWarningMessage}
            className="warning"
            negative={true}
            header={commentColumnsWarningMessage}
          />
        )}
        <Form>
          <SampleData
            columns={columns}
            columnAlphabets={columnAlphabets}
            hasHeader={hasHeader}
          />
          <Divider />
          <ConfigureColumns
            columns={columns}
            columnAlphabets={columnAlphabets}
            hasHeader={hasHeader}
            bypassUniqueValueCheck={isThematicAdmin}
            onHasHeaderChange={this.onHasHeaderChange}
            onColumnsChange={this.onColumnsChange}
            onColumnsError={this.onColumnsError}
          />
          <Divider />
          <ConfigureUniqueId
            columns={columns}
            uniqueId={uniqueId}
            onUniqueIdChange={this.onUniqueIdChange}
          />
          {isThematicAdmin &&
            <>
              <Divider />
              {this.renderThematicOptionsCheckbox()}
              <Divider />
              {this.renderCommentColumnTreatmentOptions()}
            </>
          }
        </Form>
        {errors.map(error =>
          <Message
            className="error"
            negative={true}
            header={error}
            key={error}
          />
        )}

        <ConfigureSurveyActions
          isLastStep={true}
          processing={updating}
          disableNext={disableNext}
          onCancel={onCancel}
          onNext={this.onNext}
          onBack={onBack}
        />
      </Segment>
    );
  }
}

export default withRouter(ReviewData);
