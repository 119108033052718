<template>
  <div class="config-field">
    <label> Data source </label>
    <el-select
      v-model="source"
      :disabled="!!existingSource"
      placeholder="Select source"
      @change="onSourceChange"
    >
      <el-option
        v-for="item in sourceOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { getActiveDashboardUIStore, getAnalysisToolsStore } from 'stores/RootStore';

export default {
  name: 'ConfigureSource',
  props: {
    // Properties for existing widget
    existingSource: { type: String },
  },
  data() {
    return {
      analysisToolsStore: getAnalysisToolsStore(),
      activeDashboardUIStore: getActiveDashboardUIStore(),

      source: undefined,
    };
  },
  computed: {
    sourceOptions() {
      let sourceOptions = [];

      // Only show sources that were selected on dashboard
      const { sources } = this.activeDashboardUIStore;
      for (const sourceKey in sources) {
        const source = sources[sourceKey];
        if (source) {
          const { survey, view, visualization } = source;
          // TODO: PORTAL-3310 Handle aggregate views
          const title = this.analysisToolsStore.getAnalysisTitle(survey, visualization, view);
          // If no title is returned, it means no matching analysis is found (possible an inaccessible dataset)
          if (title) {
            sourceOptions.push({ label: title, value: sourceKey });
          }
        }
      }

      return sourceOptions;
    },
  },
  mounted() {
    if (this.existingSource) {
      const { sources } = this.activeDashboardUIStore;
      const source = sources[this.existingSource];
      if (source) {
        this.source = this.existingSource;
      }
    } else {
      if (this.sourceOptions.length === 1) {
        // Pre-select the first source if there is only one source
        this.source = this.sourceOptions[0].value;
        this.onSourceChange(this.source);
      }
    }
  },
  methods: {
    onSourceChange(source) {
      this.$emit('onSourceUpdate', source);
    },
  },
};
</script>
