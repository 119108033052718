
export type WorkflowId = string;
export type RunId = string;
export interface Workflow {
  id?: WorkflowId;
  orgId: string;
  surveyId: string;
  createdAt: string;
  enabled: boolean;
  name: string;
  roleId: string;
  status: WorkflowStatus;
  mostRecentRunStatus: WorkflowHistoryStatus;
  triggers: {
    webhookUrl: string | null;
    eventCadence: WorkflowCadence | null;
    calledAfterProcessing: string[] | null;
  };
  configuration: WorkflowConfiguration;
}

export interface CreateWorkflowTriggers {
  hasWebhookUrl?: boolean;
  calledWithCadence?: WorkflowCadence;
  calledAfterProcessing?: string[];
}

export interface WorkflowCadence {
  period: WorkflowCadenceString;
  offset: number;
}

export interface CreateWorkflowPartial {
  name?: string;
  roleId?: string;
  configuration?: object;
  triggers?: CreateWorkflowTriggers;
  organization?: string;
  isPreview?: boolean;
  enabled?: boolean;
}

export interface WorkflowHistory {
  workflowId: WorkflowId;
  runId: RunId;
  executingRole: string;
  created: string;
  finished: string;
  status: WorkflowHistoryStatus;
  statusDetails: string;
  trigger: WorkflowHistoryTrigger;
  triggerDetails?: string;
}

interface WorkflowRunDetailsStep {
  id: number;
  type: string;
  stateEnteredEventDetails?: {
    name: string;
    input: object;
  };
  stateExitedEventDetails?: {
    name: string;
    output: object;
  };
  executionFailedEventDetails?: {
    name: string;
    cause: string;
    error: string; // class of error
  };
}

export interface WorkflowRunDetails {
  history: WorkflowRunDetailsStep[];
  result: ThemesDiscoveryWorkflowRunDetailsResult | SpikeWorkflowRunDetailsResult | RouteWorkflowRunDetailsResult;
  log: string;
  userLog: string;
}

interface WorkflowRunDetailsResult {
  input: {
    uploadId?: string;
  };
  result?: {
    requestId: string;
  };
  runId: RunId;
  workflowId: WorkflowId;
  workflowName: string;
}

export interface ThemesDiscoveryWorkflowRunDetailsResult extends WorkflowRunDetailsResult {
  themes: {
    itemCount: number;
    items: WorkflowRunDetailsResultItems[];
    parameters: {
      date: string;
      dateResolution: string;
      orgName: string;
      surveyName: string;
      targetUrl: string;
    };
    resultsLink: string;
    workflowName: string;
  };
}

export interface SpikeWorkflowRunDetailsResult extends WorkflowRunDetailsResult {
  spikes: {
    itemCount: number;
    items: WorkflowRunDetailsResultItems[];
    parameters: {
      date: string;
      dateResolution: string;
      for: {
        counts: {
          empty: number;
          nothing: number;
          other: number;
          total: number;
        };
        filter: string;
        labels: string;
        range: string;
      };
      previousPeriods: number;
      spikeType: string;
      title: string;
    };
    resultsLink: string;
    templateData: {
      filtersTitle: string;
    };
    workflowName: string;
  };
}

export interface RouteWorkflowRunDetailsResult extends WorkflowRunDetailsResult {
  matches: {
    fullResults: string;
    itemCount: number;
    items: WorkflowRunDetailsResultItems[];
    parameters: {
      date: string;
      title: string;
    };
  };
}

interface WorkflowRunDetailsResultItems {
  average: number;
  current: number;
  stdev: number;
  subtheme: string;
  theme: string;
  trend: {
    period: string;
    total: number;
    value: number;
  }[];
}

export enum WorkflowStatus {
  NORMAL = 'normal',
  PREVIEW_ONLY = 'preview_only'
}

export enum WorkflowTemplateType {
  SPIKE = 'spike',
  ROUTE = 'route'
}

export enum WorkflowHistoryStatus {
  COMPLETED = 'completed',
  PROCESSING = 'processing',
  ERRORED = 'errored',
  QUEUED = 'queued',
  CANCELED = 'canceled',
}

export enum WorkflowHistoryTrigger {
  WEBHOOK = 'webhook',
  UPLOAD = 'upload',
  TIME = 'time',
  MANUAL = 'manual'
}

export enum WorkflowCadenceString {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export interface WorkflowConfiguration {
  name: string;
  stateMachine?: any;
  templateId?: string;
}