import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classNames from 'classnames';
import { ThemeSelectCreateNewKey } from 'components/ThemeDiscovery/ThemeSelect';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import { generateExampleComments } from 'lib/generate-example-comments';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Checkbox, Input, Popup } from 'semantic-ui-react';
import { ThemeDiscoveryStoreInterface } from 'stores/ThemeDiscoveryStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import './theme-discovery.scss';
import ThemeDiscoveryItemDropdown from './ThemeDiscoveryItemDropdown';
import { ProposedDiscoveredTheme } from './ThemeDiscoveryResolution';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

interface ThemeDiscoveryItemProps {
  index: number;
  theme: ProposedDiscoveredTheme;
  themeItemsRef: React.RefObject<HTMLDivElement>;
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
  themesDiscoveryStore: ThemeDiscoveryStoreInterface;
}

interface ThemeDiscoveryItemState {
  hasItemsOverflowing: boolean;
  isExpanded: boolean;
  isInEditMode: boolean;
  processing: boolean;
  exampleComments: string[];
  showPopup: boolean;
}

const withHocs = compose(
  inject('themesStore', 'themesDiscoveryStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class ThemeDiscoveryItem extends React.Component<ThemeDiscoveryItemProps, ThemeDiscoveryItemState> {
  get injected(): InjectedProps {
    return this.props as ThemeDiscoveryItemProps & InjectedProps;
  }
  commentWorker?: Worker;

  state = {
    hasItemsOverflowing: false,
    isExpanded: false,
    isInEditMode: false,
    processing: false,
    exampleComments: [],
    showPopup: false,
  };

  private phrasesRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const clientHeight = this.phrasesRef?.current?.clientHeight;
    const scrollHeight = this.phrasesRef?.current?.scrollHeight;

    if (clientHeight && scrollHeight) {
      this.setState({ hasItemsOverflowing: clientHeight < scrollHeight });
    }
  }

  togglePhrasesExpansion = () => {
    this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
  };

  renderMoreButton = () => {
    const { isExpanded } = this.state;

    return (
      <div className="gradient-button-wrapper">
        <button className="expand-button" onClick={() => this.togglePhrasesExpansion()}>
          {isExpanded ? 'Less' : 'More'}
        </button>
      </div>
    );
  };

  toggleEditClick = () => {
    this.setState(({ isInEditMode }) => ({ isInEditMode: !isInEditMode }));
  };

  handleEnterKeyDown = (key) => {
    if (key === 'Enter') {
      this.toggleEditClick();
    }

    return;
  };

  generateExampleComments = async (phrase) => {
    this.setState({ processing: true, exampleComments: [] });
    const comments = await generateExampleComments([phrase]);
    this.setState({ exampleComments: comments, processing: false });
  };

  render() {
    const { themesDiscoveryStore } = this.injected;
    const {
      selectedDropdownAction,
      updateSelectedDropdownAction,
      updateProposedTitle,
      handleThemeSelection,
      handleProposeTheme,
      removeThemePhrase,
      handleMergeTheme
    } = themesDiscoveryStore;
    const { hasItemsOverflowing, isExpanded, isInEditMode, showPopup, exampleComments, processing } = this.state;
    const {
      index,
      theme: { proposedTitle, selected, proposedTheme, map, suggestedAction },
    } = this.props;

    const {
      themesStore,
      themeEditorSessionStore
    } = this.injected;
    const groupId = themesStore.groups.length > 0 ? themesStore.groups[0].id : '';

    const scrollHeight = this.phrasesRef?.current?.scrollHeight;

    let themeValue;
    if (suggestedAction === 'merge_to_nearest_sub') {
      // the only instance where we send subtheme is when we need to merge given theme with subtheme
      themeValue = proposedTheme && proposedTheme.subtheme;
    } else {
      themeValue = proposedTheme && proposedTheme.theme;
    }

    // we will only have subtheme if the suggested action is to merge with a subtheme
    const proposedThemeValue = proposedTheme && proposedTheme.theme !== 'other'
      ? themeValue
      : ThemeSelectCreateNewKey;

    const cls = classNames('theme-item__discovered-theme', { selected: selected });

    return (
      <>
        <div className="theme-item">
          <Checkbox
            checked={selected}
            onChange={(_e, { checked }) => handleThemeSelection(index, checked)}
            className="individual-checkbox"
          />
          <div className={cls}>
            <div className="theme-edit-row">
              {isInEditMode ? (
                <Input
                  size="small"
                  value={proposedTitle}
                  onBlur={() => this.toggleEditClick()}
                  onChange={(e) => updateProposedTitle(e.currentTarget.value, index)}
                  onKeyDown={(e) => this.handleEnterKeyDown(e.key)}
                  autoFocus={true}
                />
              ) : (
                <div className="theme-title" onClick={() => this.toggleEditClick()}>
                  {proposedTitle}
                  <FontAwesomeIcon icon="pencil" className="icon" />
                </div>
              )}
              <div className="move">
                <ThemeDiscoveryItemDropdown
                  groupId={groupId}
                  suggestedAction={suggestedAction}
                  proposedTheme={proposedTheme}
                  proposedThemeValue={proposedThemeValue}
                  selectedDropdownAction={selectedDropdownAction}
                  onChange={(selectedTheme, selectTheme, selectedOption) => {
                    updateSelectedDropdownAction(selectedOption);
                    handleProposeTheme(index, selectedTheme);
                    if (selectTheme) {
                      handleThemeSelection(index, true);
                      themeEditorSessionStore.addEvent({
                        type: 'Discover',
                        subType: 'ChangeSuggested',
                        timestamp: Date.now()
                      });
                    }
                  }}
                  mergeTheme={(selectedTheme, selectTheme, selectedOption) => {
                    updateSelectedDropdownAction(selectedOption);
                    handleMergeTheme(index, selectedTheme);
                    if (selectTheme) {
                      handleThemeSelection(index, true);
                      themeEditorSessionStore.addEvent({
                        type: 'Discover',
                        subType: 'ChangeSuggested',
                        timestamp: Date.now()
                      });
                    }
                  }}
                  themeItemsRef={this.props.themeItemsRef}
                />
              </div>
            </div>
            <div className="phrases" style={{ maxHeight: isExpanded ? scrollHeight : '58px' }} ref={this.phrasesRef}>
              {map.map((phrase, phraseIndex) => (
                <span key={`phrase-${ index }-${ phraseIndex }`}>
                  <Popup
                    className="theme-disovery-example-phrases"
                    position="bottom left"
                    wide="very"
                    content={
                      // Need this condition here to make sure this content doesn't render unless hovered on term
                      showPopup && (
                        <div>
                          <Popup.Header>Example phrases</Popup.Header>
                          {exampleComments.length > 0 || processing ? (
                            <div className="theme-disovery-example-comments">
                              <ExampleComments
                                comments={exampleComments}
                                phrases={[phrase]}
                                processing={processing}
                                processerror={false}
                                istruncated={true}
                              />
                            </div>
                          ) : (
                            <div>No phrases available for this term.</div>
                          )}
                        </div>
                      )
                    }
                    trigger={
                      <div
                        className="popup-trigger"
                        onMouseEnter={() => {
                          this.setState({ showPopup: true });
                          this.generateExampleComments(phrase);
                        }}
                        onMouseLeave={() => {
                          if (this.commentWorker) {
                            this.commentWorker.terminate();
                          }
                          this.setState({ showPopup: false });
                        }}
                      >
                        {phrase}
                      </div>
                    }
                  />
                  <Button icon="close" onClick={() => removeThemePhrase(index, phraseIndex)} />
                </span>
              ))}
            </div>
            {hasItemsOverflowing && this.renderMoreButton()}
          </div>
        </div>
      </>
    );
  }
});
