import Auth from 'Auth/Auth';
import { action, observable } from 'mobx';
import { OrganizationStoreInterface } from './OrganizationStore';

export interface SupportStoreInterface {
  supportRequestError: string;
  requestingSupport: boolean;

  requestSupport: (userEmail: string, subject: string, request: string) => void;
}

class SupportStore implements SupportStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  supportRequestError = '';

  @observable
  requestingSupport = false;

  constructor( currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;
  }

  @action
  requestSupport = async (userEmail: string, subject: string, request: string) => {
    const params = this.currentOrg.orgIdQueryParam;
    const postData = {
      request,
      subject,
      user: userEmail
    };

    const url = `/supportRequest?${ params }`;

    this.requestingSupport = true;
    this.supportRequestError = '';

    try {
      const { data, ok, errorData } = await Auth.fetch<object>(url, {
        method: 'POST',
        body: JSON.stringify(postData)
      });
      if (!ok || !data) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        this.supportRequestError = errorMessage;
      }
    } catch (e) {
      this.supportRequestError = `${ e }`;
    } finally {
      this.requestingSupport = false;
    }
  }
}

export default SupportStore;
