import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisFilter, Segment } from 'api/interfaces';
import classNames from 'classnames';
import SavedFilters from 'components/Filters/SavedFilters';
import FilterPopupContent from 'components/Filters/FilterPopupContent';
import Popup from 'components/Shared/Popup';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Ref } from 'semantic-ui-react';
import { getFilterStore, getSegmentStore } from 'stores/RootStore';
import './filter-button.scss';
import { FilterConfig } from './types';
import { QueryFilter } from 'stores/types';
import { FilterType } from 'api/enums';

interface FilterButtonProps {
  filters: AnalysisFilter[];
  shouldTrackBaseline?: boolean;
  className?: string;
  filterButtonId?: string;
  onChange?: () => void;
  onClose?: () => void;
  onSavedFilterSelected?: (savedFilter: Segment) => void;
  config: FilterConfig;
}

const POPUP_GAP = 10;

class FilterButton extends React.Component<FilterButtonProps> {
  buttonRef = React.createRef<HTMLButtonElement>();
  contentRef = React.createRef<HTMLDivElement>();

  componentDidUpdate() {
    const height = this.getPopupHeight();
    if (this.contentRef.current) {
      this.contentRef.current.style.maxHeight = `${height - POPUP_GAP}px`;
    }
  }

  getPopupHeight(): number {
    const isOpen = this.filterIsOpen();

    const buttonRect = this.buttonRef?.current?.getBoundingClientRect();

    if (isOpen && buttonRect) {
      return Math.max(buttonRect.top, window.innerHeight - buttonRect.bottom);
    }

    return 0;
  }

  afterFilterClose = () => {
    const filterStore = getFilterStore();
    const { filterKey } = this.props.config;

    const currentFilters = filterStore.queryStrings[filterKey];
    const previousFilters = filterStore.selections[filterKey].query;
    const filtersHaveUpdated = currentFilters !== previousFilters;

    if (filtersHaveUpdated) {
      filterStore.updateSelections();
      this.props.onClose?.();
    }
  }

  onAllFiltersReset = () => {
    const { filterKey } = this.props.config;
    const filterStore = getFilterStore();
    const segmentStore = getSegmentStore();

    if (filterKey === 'baseline') {
      filterStore.clearToInitialState(filterKey);
    } else {
      filterStore.forceSyncToBaseline(filterKey);
    }
    segmentStore.selectSegment(filterKey, '');
  };

  onQueryFilterChange = (queryFilter: QueryFilter) => {
    const filterStore = getFilterStore();

    filterStore.setQueryFilter(queryFilter);

    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  onFilterReset = (allFilters: AnalysisFilter[], filterId: string) => {
    const { filterKey } = this.props.config;
    const filterStore = getFilterStore();
    const filter = allFilters.find((f) => f.id === filterId);
    const queryFilter = filterStore.filters[filterKey]?.[filterId];

    if (filter && queryFilter) {
      filterStore.clearFilterToInitialState(filterKey, filter);
    }
  };

  onUpdateSavedFilter() {
    const segmentStore = getSegmentStore();
    segmentStore.updateCurrentSegment(this.props.config.filterKey);
  }

  filterIsOpen() {
    const { config } = this.props;
    const filterStore = getFilterStore();

    return filterStore.openFilterPopUpId === config.filterPopUpId;
  }

  handleClose = () => {
    const filterStore = getFilterStore();

    filterStore.closeFilterPopUp();
    this.afterFilterClose();
  }

  toggleFilterButton = () => {
    const { config } = this.props;
    const filterStore = getFilterStore();

    if (this.filterIsOpen()) {
      this.handleClose();
    } else {
      analytics.track('Analysis: Filters Opened', { Segment: `${ config.filterKey }` });
      filterStore.openFilterPopUp(config.filterPopUpId);
    }
  }

  renderSavedFilters() {
    const { config } = this.props;
    const disableSavedFilterWithTheme = config.disabledFilterTypes?.includes(FilterType.THEMES) ?? false;

    return (
      <SavedFilters
        onSavedFilterSelected={this.props.onSavedFilterSelected}
        canCreatePublicSavedFilters={config.canCreatePublicSavedFilters}
        disableSavedFilterWithTheme={disableSavedFilterWithTheme}
        filterKey={config.filterKey}
        onAllFiltersReset={() => this.onAllFiltersReset()}
      />
    );
  }

  render() {
    const {
      filters,
      shouldTrackBaseline,
      className,
      filterButtonId,
      config,
    } = this.props;
    const filterStore = getFilterStore();
    const segmentStore = getSegmentStore();
    const open = this.filterIsOpen();
    const queryTitles = filterStore.queryTitles;
    const queryFilters = filterStore.filters[config.filterKey] ?? {};

    const showCompareWith = shouldTrackBaseline && filterStore.shouldQueryFilterRemainSynced('baseline');
    const filterTitle = showCompareWith ? 'Compare with' : queryTitles[config.filterKey] || 'All';

    const selectedSegment = segmentStore.selectedSegment[config.filterKey];
    const selectedSavedFilter = segmentStore.getSegmentById(selectedSegment);
    const hasSelectedSavedFilterChanged = selectedSavedFilter
      ? segmentStore.hasSelectionChanged(config.filterKey, selectedSavedFilter.id)
      : false;

    return (
      <Popup
        open={open}
        className={classNames('filter-button-wrapper', className)}
        host={
          // Note: https://react.semantic-ui.com/migration-guide/#removal-of-ref-component
          <Ref innerRef={this.buttonRef}>
            <Button
              type="button"
              className="filter-button"
              size={config?.buttonSize || 'small'}
              active={open}
              data-testid={filterButtonId}
              onClick={() => this.toggleFilterButton()}
            >
              <FontAwesomeIcon className="filter-icon" icon="filter" />
              <span>{filterTitle}</span>
              <FontAwesomeIcon icon="chevron-down" />
            </Button>
          </Ref>
        }
        onClose={() => this.handleClose()}
      >
        <Ref innerRef={this.contentRef}>
          <FilterPopupContent
            data-testid="filter-popup-content"
            savedFilters={this.renderSavedFilters()}
            selectedSavedFilter={selectedSavedFilter}
            hasSelectedSavedFilterChanged={hasSelectedSavedFilterChanged}
            filters={filters}
            queryFilters={queryFilters}
            onQueryFilterChange={this.onQueryFilterChange}
            onFilterReset={this.onFilterReset}
            onAllFiltersReset={this.onAllFiltersReset}
            onUpdateSavedFilter={() => this.onUpdateSavedFilter()}
            config={config}
          />
        </Ref>
      </Popup>
    );
  }
}

export default observer(FilterButton);
