import getCommentHighlightLocations from "lib/get-comment-highlight-locations";
import { matches } from "lodash";
import { ThemeCodes } from "stores/ui/AnalysisToolsUIStore";
import { Comment, CommentTheme, PhraseSegmentTheme, PlainComment, Thread } from "types/custom";

const NUM_COMMENTS_TO_SHOW = 5;

function isThreadArray(comments: PlainComment[] | Thread[]): comments is Thread[] {
  return comments.length > 0 && Array.isArray(comments[0].comment);
}

function extractCommentFromThread(thread: Thread, testTheme: ThemeCodes): Omit<Comment, 'highlightLength'> | null {
  const relevantParts = thread.comment.filter(part => {
    const allThemes: PhraseSegmentTheme[] = part.segments.flatMap(segment => segment.themes);
    return allThemes.filter(matches(testTheme)).length > 0;
  });


  if (relevantParts.length > 0) {
    const firstPart = relevantParts[0];

    return {
      // @ts-ignore FIXME https://thematicanalysis.atlassian.net/browse/PORTAL-5763
      data: firstPart.data,
      id: thread.id,
      segments: firstPart.segments,
      column: thread.column,
      comment:firstPart.text,
    };
  }

  return null;
}

const processThreads = (threads: Thread[], themeCodes: ThemeCodes): Comment[] => {
  return threads.reduce((acc: Comment[], comment: Thread) => {
    const candidate = extractCommentFromThread(comment, themeCodes);

    if (!candidate) {
      return acc;
    }

    const highlightLength = getCommentHighlightLocations(
      candidate.segments,
      themeCodes
    ).reduce((result, curr) => {
      return result + curr[1] - curr[0];
    }, 0);


    return [...acc, {
      ...candidate,
      highlightLength
    }];
  }, []);
}

const processPlainComment = (comment: PlainComment, themeCodes: ThemeCodes): Comment => {
  const highlightLocations = getCommentHighlightLocations(
    comment.segments,
    themeCodes
  );

  const highlightLength = highlightLocations.reduce((result, curr) => {
    return result + curr[1] - curr[0];
  }, 0);

  return {
    ...comment,
    // @ts-ignore FIXME https://thematicanalysis.atlassian.net/browse/PORTAL-5763
    data: comment.data,
    highlightLength
  };
};

export const processComments = (
  comments: PlainComment[] | Thread[],
  theme: CommentTheme
): Comment[] => {
  if (!comments?.length || !theme) {
    return [];
  }

  const testTheme: ThemeCodes = {
    base: theme.theme.code
  };

  if (theme.subtheme) {
    testTheme['sub'] = theme.subtheme.code
  }

  const nextComments = isThreadArray(comments)
    ? processThreads(comments, testTheme)
    : comments.map(comment => processPlainComment(comment, testTheme));

  return nextComments
    .sort((a: Comment, b: Comment) => b.highlightLength - a.highlightLength)
    .slice(0, NUM_COMMENTS_TO_SHOW);
}

