import { ImageFile } from 'react-dropzone';
import config from 'runtime-config';
import { LiteSurvey } from 'stores/AnalysisToolsStore';

export async function uploadSurvey (
  target: LiteSurvey,
  file: ImageFile,
  onProgress: (progress: number | boolean, fileName: string, status?: number, errorMessage?: string) => void
) {
  if (typeof XMLHttpRequest === 'undefined') {
    return;
  }
  const xhr = new XMLHttpRequest();

  if (xhr.upload) {
    xhr.upload.onprogress = function progress(e: ProgressEvent) {
      if (e.total > 0) {
        onProgress(e.loaded / e.total, file.name);
      }
    };
  }
  xhr.onloadend = function() {
    // File upload is complete and successful
    if (xhr.status === 200) {
      onProgress(true, file.name);
    } else {
      // Upload failed
      const response = xhr.response ? JSON.parse(xhr.response) : {};
      onProgress(false, file.name, xhr.status, response?.message);
    }
  };

  const url = `${config.apiLocation}/survey/${target.surveyId}/upload`;
  xhr.open('post', url);

  xhr.addEventListener('error', (event) => {
    if (window.rg4js) {
      window.rg4js('send', {
        customData: { url, event, file, target }
      });
    }
  });

  const token = localStorage.getItem('access_token');
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  const formData = new FormData();
  formData.append('file', file, file.name);
  xhr.send(formData);
}

export default {
  uploadSurvey
};