import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CreateWorkflowPartial,
  CreateWorkflowTriggers,
  Workflow,
  WorkflowConfiguration
} from 'api/workflow-interfaces';
import { getWorkflowsRoute } from 'lib/route-helper';
import { compact } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Form, FormInputProps, Header, Input, Loader, Message,
  Segment
} from 'semantic-ui-react';
import { WorkflowsStoreInterface } from 'stores/WorkflowsStore';
import SelectTemplate from './SelectTemplate';
import { getTemplateFromConfiguration } from './Templates/available-templates';
import { TemplateProps } from './Templates/WorkflowTemplateBase';
import './workflows.scss';

/*
Should be:
 ManageWorkflow
   if new workflow and no template:
     WorkflowSelectTemplate
   else:
     Name component
     Selected Template for workflow
     Cancel button
     Update/Create button

On load check if the workflow id is 'create' and if so then don't load and set to no template
if there is an acionid then retrieve it. Check configuration to determine
IF there is a template and if so select that template

Each Template will have its own internal validation / UI
*/

export interface ManageWorkflowParams {
  workflowId: string;
  orgId: string;
}

export interface ManageWorkflowProps extends RouteComponentProps<ManageWorkflowParams> {
  workflowsStore?: WorkflowsStoreInterface;
}
export interface ManageWorkflowState {
  isPristine: boolean;
  name: string;
  configuration?: WorkflowConfiguration;
  triggers: CreateWorkflowTriggers;
  workflowTemplate?: React.ComponentClass<TemplateProps>;
  templateDefaults: object;
  selectedRole: string;
}

@inject('workflowsStore')
@observer
class ManageWorkflow extends React.Component<ManageWorkflowProps, ManageWorkflowState> {

  get workflowId(): string {
    const { workflowId } = this.props.match.params;
    return workflowId;
  }
  get workflowsStore() {
    return this.props.workflowsStore!;
  }
  get workflow(): Workflow | undefined {
    const { getWorkflow } = this.workflowsStore;
    return getWorkflow(this.workflowId);
  }
  get isValid(): boolean {
    const {
      name,
      configuration,
      triggers
    } = this.state;

    return !!name  && !!configuration && !!triggers;
  }
  constructor(props: ManageWorkflowProps) {
    super(props);

    this.state = {
      isPristine: true,
      name: '',
      triggers: {},
      templateDefaults: {},
      selectedRole: ''
    };
  }
  componentDidMount() {
    this.initialize();
  }
  componentDidUpdate(prevProps: ManageWorkflowProps) {
    if (prevProps.match.params.workflowId !== this.workflowId) {
      this.initialize();
    }
  }
  initialize = async () => {
    this.workflowsStore.clearErrors();
    this.fetchData();
  }
  fetchData = async () => {
    await this.workflowsStore.fetchWorkflows();
    const workflow = this.workflowsStore.getWorkflow(this.workflowId);
    // load the values for this
    if (workflow) {
      let triggers = {} as CreateWorkflowTriggers;
      if (workflow.triggers.webhookUrl) {
        triggers.hasWebhookUrl = true;
      }
      if (workflow.triggers.calledAfterProcessing) {
        triggers.calledAfterProcessing = workflow.triggers.calledAfterProcessing;
      }
      if (workflow.triggers.eventCadence) {
        triggers.calledWithCadence = workflow.triggers.eventCadence;
      }
      const { template, defaults } = getTemplateFromConfiguration(workflow.configuration);
      this.setState({
        name: workflow.name,
        configuration: workflow.configuration,
        triggers,
        workflowTemplate: template,
        templateDefaults: defaults,
        selectedRole: workflow.roleId,
        isPristine: false
      });
    } else {
      this.setState({name: 'New Workflow', isPristine: true});
    }
  }

  goBack = () => {
    this.props.history.push(getWorkflowsRoute(this.props.match.params.orgId));
  }
  saveWorkflow = async (roleId: string, trigger: object, config: object) => {
    const { orgId } = this.props.match.params;

    const {
      name
    } = this.state;

    if (!config) {
      return;
    }

    let workflow = {
      name: name,
      configuration: config,
      triggers: trigger,
      roleId: roleId,
      organization: orgId,
    };
    await this.setState({ configuration: workflow, triggers: trigger});

    if (!this.isValid) {
      return;
    }

    if (this.workflowId === 'create') {
      await this.create(workflow);
    } else {
      await this.update(workflow);
    }
  }
  setTemplate = (workflowTemplate: React.ComponentClass<TemplateProps>, templateDefaults: object) => {
    this.setState({workflowTemplate, templateDefaults});
  }

  suggestName = (title: string) => {
    const { isPristine } = this.state;
    if (isPristine) {
      this.setState({name: title});
    }
  }

  handleNameChange = ({ value }: FormInputProps) => {
    this.setState({ name: value, isPristine: false });

  }
  update = async (workflow: CreateWorkflowPartial) => {
    // Only update workflow if there are any changes to the workflow details
    if (this.state.isPristine) {
      return;
    }
    await this.workflowsStore.updateWorkflow(this.workflowId, workflow);

    if (!this.workflowsStore.updateWorkflowErroredMessage) {
      this.goBack();
    }

  }
  create = async (workflow: CreateWorkflowPartial) => {

    await this.workflowsStore.createWorkflow(workflow);

    if (!this.workflowsStore.createWorkflowErroredMessage) {
          this.goBack();
    }
  }
  render(): JSX.Element | null {
    const workflow = this.workflow;

    const {
      fetching,
      creating,
      createWorkflowErroredMessage,
      deleting,
      updating,
      updateWorkflowErroredMessage
    } = this.workflowsStore;

    const {
      isPristine,
      workflowTemplate: SelectedTemplate,
      templateDefaults,
      configuration,
      name,
      selectedRole,
      triggers
    } = this.state;

    const errors = compact([
      updateWorkflowErroredMessage,
      createWorkflowErroredMessage
    ]);

    const performingEvent = fetching || deleting || creating || updating;
    const disabled = isPristine || performingEvent;
    const canEdit = workflow || this.workflowId === 'create';
    const noSuchWorkflow = !canEdit && !fetching;

    if (noSuchWorkflow) {
      return (
        <Segment>
          <Header>No such workflow exists</Header>
        </Segment>
      );
    }

    if (fetching) {
      return (
        <Segment className="fullscreen nw--manage-workflow">
          <Loader size="large" content="Loading..." active={fetching} />
        </Segment>
      );
    }

    return (
      <Segment className="fullscreen nw--manage-workflow">
        <Header>
          <div className="nw--back back-arrow-workflow" onClick={this.goBack}>
            <FontAwesomeIcon
              size="lg"
              className="icon"
              icon="arrow-alt-circle-left"
            />
          </div>
          Create Workflow
        </Header>

        <Segment className={`white content ${!SelectedTemplate ? 'select-template-segment' : '' }`}>
          {errors.map(error => {
            return (
              <Message
                key={error}
                className="error"
                negative={true}
                header={error}
              />
            );
          })}

          {!SelectedTemplate && (
            <SelectTemplate
              setTemplate={this.setTemplate}
            />
          )}
          {SelectedTemplate && (
            <div className="actions">
              <Form.Field className="workflow-title">
                <label>Title</label>
                <Input
                  name="name"
                  autoComplete="off"
                  className="workflow-name-input"
                  onChange={(_e, data) => this.handleNameChange(data)}
                  value={name}
                />
              </Form.Field>

              <SelectedTemplate
                defaults={templateDefaults}
                configuration={configuration}
                workflow={workflow}
                performingEvent={performingEvent}
                disabled={disabled}
                loading={updating || creating}
                workflowId={this.workflowId}
                selectedRole={selectedRole}
                triggers={triggers}
                onSave={(roleId, trigger, config) => this.saveWorkflow(roleId, trigger, config)}
                onCancel={this.goBack}
                onSuggestName={this.suggestName}
              />
            </div>
          )}
        </Segment>
      </Segment>);
   }
}

export { ManageWorkflow };
