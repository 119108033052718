import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeSelect from 'components/ThemeDiscovery/ThemeSelect';
import MappedPhrase from 'components/ThemeEditor/MappedPhrase';
import { ThemeGroup } from 'lib/theme-file-parser';
import { includes, map, slice, sortBy, without } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, List } from 'semantic-ui-react';
import { ThemesStoreInterface, ThemeTreeItem } from 'stores/ThemesStore';
import toLocaleString from 'vue/libs/to-locale-string';
import './mapped-phrases.scss';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

interface MappedPhrasesStoreProps {
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

interface MappedPhrasesProps extends MappedPhrasesStoreProps {
  activeNode?: ThemeTreeItem;
  group: ThemeGroup;
}

interface MappedPhrasesState {
  selectedPhrases: string[];
  showAll: boolean;
}
const withHocs = compose(
  inject('themesStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class MappedPhrases extends React.Component<
  MappedPhrasesProps,
  MappedPhrasesState
> {
  mappedPhrasesContainerRef: HTMLElement | null;
  state = {
    selectedPhrases: [] as string[],
    showAll: false
  };
  componentDidUpdate(prevProps: MappedPhrasesProps) {
    if (prevProps.activeNode?.id !== this.props.activeNode?.id) {
      // Scroll the list to top on theme change
      if (this.mappedPhrasesContainerRef) {
        this.mappedPhrasesContainerRef.scrollTop = 0;
      }
      // Reset show all on theme change
      this.setState({ selectedPhrases: [], showAll: false });
    }
  }
  isSelected = (phrase: string) => {
    return includes(this.state.selectedPhrases, phrase);
  };
  togglePhrase = (phrase: string) => {
    const { selectedPhrases } = this.state;
    if (this.isSelected(phrase)) {
      this.setState({ selectedPhrases: without(selectedPhrases, phrase) });
    } else {
      selectedPhrases.push(phrase);
      this.setState({ selectedPhrases });
    }
  };
  toggleShowAll = () => {
    this.setState({ showAll: true });
  };

  deleteMappedPhrases = () => {
    const { activeNode, group, themesStore } = this.props;
    const { selectedPhrases } = this.state;

    if (!activeNode) {
      return;
    }

    themesStore!.deletePhrases(group, activeNode, selectedPhrases);
  }

  render() {
    const {
      activeNode,
      group,
      themesStore,
      themeEditorSessionStore
    } = this.props;
    const { selectedPhrases, showAll } = this.state;

    let phraseEls;
    let phrases;
    const count = activeNode ? activeNode.phrases.length : 0;
    const LIMIT = 10;
    const overflow = count - LIMIT;
    const hasOverflow = !showAll && overflow > 0;
    if (activeNode && count > 0) {
      phrases = sortBy(activeNode.phrases);
      if (!showAll) {
        phrases = slice(phrases, 0, LIMIT);
      }
      phraseEls = (
        <div
          className="mapped-phrases-container"
          ref={a => this.mappedPhrasesContainerRef = a}
        >
          <List className="mapped-phrases">
            {map(phrases, (phrase, i) => {
              const isSelected = this.isSelected(phrase);
              return (
                <MappedPhrase
                  onClick={() => this.togglePhrase(phrase)}
                  key={`${i}-${phrase}`}
                  group={group}
                  node={activeNode}
                  phrase={phrase}
                  isSelected={isSelected}
                />
              );
            })}
            {hasOverflow && (
              <div className="item list-link" onClick={this.toggleShowAll}>
                Show {toLocaleString(overflow, 'more phrase', 'more phrases')}
              </div>
            )}
          </List>
        </div>
      );
    } else {
      phraseEls = [];
    }

    return (
      <>
        {phrases && phrases.length ?
          <div className="mapped-phrases__action-buttons">
            <ThemeSelect
              groupId={group.id}
              disabled={selectedPhrases.length === 0}
              icon="arrow-alt-to-right"
              hasOther={false}
              onChange={selected => {
                const { theme, subtheme } = selected;
                const node = themesStore!.findNodeById(subtheme || theme);
                if (!node) {
                  return;
                }
                themesStore.addPhrases(group, node, selectedPhrases);
                themeEditorSessionStore.addEvent({
                  type: 'Addition',
                  subType: 'AddMappedPhrase',
                  timestamp: Date.now()
                });
                this.setState({ selectedPhrases: [] });
              }}
              placeholder="Move"
              resetAfterChange={true}
            />
            <Button
              size="small"
              disabled={!selectedPhrases.length || !activeNode}
              onClick={this.deleteMappedPhrases}
            >
              <FontAwesomeIcon icon="trash" className="icon" />
              Delete
            </Button>
          </div>
        :
          <div className="mapped-phrases__none">No Mapped Phrases</div>
        }
        {phraseEls}
      </>
    );
  }
});
