import * as React from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonGroup } from 'components/Shared/ButtonBar';
import { SurveyStoreInterface } from 'stores/SurveyStore';

interface SurveySelectorStoreProps {
  surveyStore?: SurveyStoreInterface;
}

export interface SurveySelectorProps extends SurveySelectorStoreProps {
  orgId: string;
  surveyId: string;
}

@inject('surveyStore')
@observer
export default class SurveySelector extends React.Component<
  SurveySelectorProps
> {
  render() {
    const { orgId, surveyId, surveyStore } = this.props;
    const surveys = surveyStore!.getSurveysThatCanAction('manage:themes');
    if (!surveys || surveys.length <= 0) {
      return null;
    }
    const surveyName = surveyStore!.getSurveyTitle(surveyId);
    return (
      <ButtonGroup>
        <Dropdown
          item={true}
          icon={false}
          trigger={
            <div className="dropdown__trigger">
              <span className="nw-theme-editor-page-title">{surveyName}</span>
              <FontAwesomeIcon className="icon" icon="chevron-down" />
            </div>
          }
        >
          <Dropdown.Menu className="dropdown__label-grid">
            {surveys.map(s => (
              <Dropdown.Item
                key={s.surveyId}
                as={Link}
                to={`/c/${orgId}/s/${s.surveyId}/themes`}
              >
                {s.surveyId === surveyId && (
                  <FontAwesomeIcon className="icon" icon="check" />
                )}
                <span>{s.title}</span>
                {s.hasDraft && <Label content="Draft" />}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
    );
  }
}
