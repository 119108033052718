import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus, SurveyStatus } from 'api/enums';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import UploadCompleteIcon from 'images/icons/upload-complete.svg';
import UploadErrorIcon from 'images/icons/upload-error.svg';
import UploadIcon from 'images/icons/upload.svg';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ImageFile } from 'react-dropzone/dist/index';
import { Progress } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SupportStoreInterface } from 'stores/SupportStore';
import { UserStoreInterface } from 'stores/UserStore';
import ImportContainer, { ImportStatus } from './ImportContainer';
import ProcessingDataModal from './ProcessingDataModal';
import './import-data.scss';
import { uploadSurvey } from './survey-uploader';

const ACCEPT = [
  'text/csv',
  'text/plain',
  'application/csv',
  'application/json',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/octet-stream',
  'multipart/x-zip',
  'application/zip',
  'application/zip-compressed',
  'application/x-zip-compressed'
].join(', ');

export enum PROGRESS_STATES {
  initial = 'initial',
  complete = 'complete',
  progress = 'progress',
  error = 'error'
}

export enum PROGRESS {
  initial = 0,
  complete = 1
}

export interface Progress {
  fileName: string;
  progress: number;
  state: PROGRESS_STATES;
}

interface UploadDataStoreProps {
  supportStore?: SupportStoreInterface;
  userStore?: UserStoreInterface;
}

export interface UploadDataProps extends UploadDataStoreProps {
  survey: LiteSurvey;
  showInitialDropzone?: boolean;
  onProgress?: (progress: boolean) => void;
  onError?: (uploadFailed: boolean) => void;
  onComplete?: () => void;
}

interface UploadDataState {
  completed: boolean;
  fileTypeError: boolean;
  networkError: boolean;
  invalidDataError: boolean;
  uploadErrorMessage?: string;
  successDialogVisible: boolean;
  progress?: number;
  aggregateProgress: Array<Progress>;
  showConfirmation: boolean;
}

@inject('supportStore', 'userStore')
@observer
class UploadData extends React.Component <UploadDataProps, UploadDataState> {
  state = {
    completed: false,
    fileTypeError: false,
    networkError: false,
    invalidDataError: false,
    uploadErrorMessage: undefined,
    successDialogVisible: false,
    progress: undefined,
    showConfirmation: false,
    aggregateProgress: Array<Progress>()
  };

  /**
   * If progress is a boolean it's either completed (true) or an error (false)
   */
  onProgress = (progress: number | boolean, fileName: string, status?: number, uploadErrorMessage?: string) => {
    let currentFileProgress = PROGRESS.initial;
    let currentFileState = PROGRESS_STATES.initial;
    if (typeof (progress) === 'number') {
      if (progress === 1) {
        // We get a progress update of true when upload is complete
        // Setting progress here would also cause the success modal to show again
        return;
      }
      currentFileProgress = progress;
      currentFileState = PROGRESS_STATES.progress;
    } else {
      if (progress === true) {
        currentFileProgress = PROGRESS.complete;
        currentFileState = PROGRESS_STATES.complete;
      } else {
        currentFileState = PROGRESS_STATES.error;
      }
    }

    // Update current file progress
    const aggregateProgress = this.getAggregateProgress(fileName, currentFileProgress, currentFileState);
    this.setState({ aggregateProgress });
    const { onProgress, onError, onComplete } = this.props;
    const { invalidDataError } = this.state;
    const isComplete = aggregateProgress.every(item => item.state === PROGRESS_STATES.complete);
    const isError = !!aggregateProgress.find(item => item.state === PROGRESS_STATES.error);

    if (isComplete) {
      // Only set the progress value to be complete if all files are complete
      this.setState({
        completed: true,
        progress: PROGRESS.complete,
        successDialogVisible: true
      });

      if (onProgress) {
        onProgress(false);
      }

      if (onError) {
        onError(false);
      }

      if (onComplete) {
        onComplete();
      }
    } else if (isError) {
      // Only set the progress value to be error if there are no files yet to be processed and one of the files errored

      // Bad data
      if (status === 400) {
        this.setState({
          completed: false,
          progress: PROGRESS.initial,
          invalidDataError: true,
          uploadErrorMessage
        });
      } else if (!invalidDataError) {
        // No status exists, successful file upload after an error
        this.setState({
          completed: false,
          progress: PROGRESS.initial,
          networkError: true
        });
      }

      if (onProgress) {
        onProgress(true);
      }

      if (onError) {
        onError(true);
      }
    } else {
      // File upload is still in progress, set the average progress value for all files being uploaded
      const totalProgressValue = aggregateProgress.reduce((a, b) => {
        return { fileName: '', progress: a.progress + b.progress, state: PROGRESS_STATES.progress };
      }).progress;
      const averageProgressValue = totalProgressValue / aggregateProgress.length;

      this.setState({
        completed: false,
        progress: averageProgressValue,
      });

      if (onProgress) {
        onProgress(!!averageProgressValue);
      }
    }
  };

  getAggregateProgress = (fileName, progress, state) => {
    const aggregateProgress = this.state.aggregateProgress.map(item => {
      if (item.fileName === fileName) {
        return { ...item, progress, state };
      }
      return item;
    });
    return aggregateProgress;
  };

  onDrop = (accepted: ImageFile[]) => {
    // Give a user immediate feedback on drop
    this.setState({ progress: 0.1 });
    if (this.props.onProgress) {
      this.props.onProgress(true);
    }

    const { survey, onError } = this.props;
    if (accepted.length) {
      // Set initial state for each file so we can show appropriate progress indicator
      const aggregateProgress = accepted.map(file => ({
        fileName: file.name,
        progress: PROGRESS.initial,
        state: PROGRESS_STATES.initial
      }));
      this.setState({
        aggregateProgress,
        fileTypeError: false,
        networkError: false,
        invalidDataError: false,
        uploadErrorMessage: undefined
      });

      accepted.forEach(file => {
        uploadSurvey(survey, file, this.onProgress);
      });
    } else {
      // Not accepted
      if (onError) {
        onError(true);
      }
      this.setState({ fileTypeError: true });
    }
  };

  closeSuccessDialog = () => {
    this.setState({ successDialogVisible: false });
  };

  sendPricingRequest = async () => {
    const { survey, supportStore, userStore } = this.props;

    const { user } = userStore!;

    // send an email to let US know that pricing was requested
    if (user) {
      const subject = `${ user.email } Extra Data Request`;
      const request = `${ user.email } from ${ survey.orgId } requesting pricing for extra data on ${ survey.title }`;

      await supportStore!.requestSupport(user.email, subject, request);

      this.setState({ showConfirmation: true });
    }
  }

  renderInitialDropzone = (dropzoneProps) => {
    return (
      <ImportContainer
        dropzoneProps={dropzoneProps}
        status={ImportStatus.DEFAULT}
        icon={<UploadIcon />}
        content={<a>Upload a CSV or Excel file</a>}
      />
    );
  }

  renderContent = (dropzoneProps) => {
    const {
      progress,
      networkError,
      fileTypeError,
      invalidDataError,
      completed,
      successDialogVisible,
      showConfirmation,
      uploadErrorMessage
    } = this.state;
    const { survey } = this.props;
    const { dataStatus, surveyStatus, lastUpdated } = survey;

    const uploadDisallowed = surveyStatus === SurveyStatus.ONE_OFF;

    const noResults = dataStatus === SurveyDataStatus.NORESULTS;
    const processing = dataStatus === SurveyDataStatus.PROCESSING;
    const ready = dataStatus === SurveyDataStatus.READY;

    if (uploadDisallowed) {
      return (
        <>
          <ImportContainer
            status={ImportStatus.DEFAULT}
            content={
              <>
                <p className="import-data__title">Dataset is ready for analysis</p>
                <p className="import-data__note">Want to add more data to this dataset?</p>
                <p className="import-data__note">
                  <a
                    onClick={this.sendPricingRequest}
                    style={{ fontSize: '14px'}}
                  >Contact us</a> to find out about pricing options
                </p>
              </>
            }
          />
          {showConfirmation && (
            <ConfirmationModal
              confirmationText="Thanks, we will be in touch"
              confirmationButtonText="OK"
              danger={false}
              confirm={() => {
                this.setState({ showConfirmation: false });
              }}
            />
          )}
        </>
      );
    }

    if (fileTypeError) {
      return (
        <ImportContainer
          dropzoneProps={dropzoneProps}
          status={ImportStatus.ERROR}
          icon={<UploadErrorIcon />}
          content={
            <p className="import-data__error">
              Only CSV and Excel files are supported<br />
              <a>Please try again</a>
            </p>
          }
        />
      );
    }

    if (networkError) {
      return (
        <ImportContainer
          dropzoneProps={dropzoneProps}
          status={ImportStatus.ERROR}
          icon={<UploadErrorIcon />}
          content={
            <p className="import-data__error">
              A network error occurred<br />
              <a>Please try again</a>
            </p>
          }
        />
      );
    }

    if (invalidDataError) {
      return (
        <>
          <ImportContainer
            dropzoneProps={dropzoneProps}
            status={ImportStatus.ERROR}
            icon={<UploadErrorIcon />}
            content={
              <>
                <p className="import-data__error">
                  {uploadErrorMessage ? uploadErrorMessage : 'Upload error' }
                </p>
                <p>
                  Please check your file and <a>try again</a>.
                </p>
              </>
            }
          />
          <p className="import-data__subtext">
            If your problem persists, contact <a href="mailto:support@getthematic.com">support@getthematic.com</a>.
          </p>
        </>
      );
    }

    if (completed) {
      return (
        <>
          <ImportContainer
            dropzoneProps={dropzoneProps}
            status={ImportStatus.SUCCESS}
            icon={<UploadCompleteIcon />}
            content={<p>Upload complete!</p>}
            footer={
              <a className="import-data__footer">
                {ready ? 'Upload another file' : 'Overwrite with a different file'}
              </a>
            }
          />
          {ready && successDialogVisible &&
            <ProcessingDataModal
              header="Processing data"
              showUpdatingImage={true}
              onClose={this.closeSuccessDialog}
            >
              <p>Your data will be available soon.</p>
            </ProcessingDataModal>
          }
        </>
      );
    }

    if (progress) {
      return (
        <ImportContainer
          status={ImportStatus.IN_PROGRESS}
          content={
            <>
              <Progress size="tiny" color="orange" total={1} value={progress} />
              <p className="import-data__title">Uploading: {((progress || 0) * 100).toFixed(0)}%</p>
            </>
          }
        />
      );
    }

    if (noResults) {
      return (
        <ImportContainer
          dropzoneProps={dropzoneProps}
          hideDropzone={true}
          status={ImportStatus.DEFAULT}
          content={<p className="import-data__title">Ready to process data</p>}
          footer={<a className="import-data__footer">Overwrite with a different file</a>}
        />
      );
    }

    if (processing) {
      return (
        <ImportContainer
          status={ImportStatus.IN_PROGRESS}
          content={
            <>
              <FontAwesomeIcon
                className="import-data__spinner-icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
                size="2x"
              />
              <p className="import-data__title">Processing comments</p>
              <p className="import-data__note">Your data will be available soon.</p>
            </>
          }
        />
      );
    }

    if (ready) {
      return (
        <ImportContainer
          dropzoneProps={dropzoneProps}
          status={ImportStatus.DEFAULT}
          icon={<UploadIcon />}
          content={
            <>
              <a>Upload a CSV or Excel file</a>
              <p className="import-data__note">Last Refreshed: {lastUpdated}</p>
            </>
          }
        />
      );
    }

    // noData
    return this.renderInitialDropzone(dropzoneProps);
  }

  render() {
    const dropzoneProps = {
      accept: ACCEPT,
      acceptClassName: 'acceptable',
      rejectClassName: 'rejectable',
      onDrop: this.onDrop,
      multiple: true
    };

    const { showInitialDropzone } = this.props;

    return (
      <div className="import-data">
        {showInitialDropzone
          ? this.renderInitialDropzone(dropzoneProps)
          : this.renderContent(dropzoneProps)
        }
      </div>
    );
  }
}

export default UploadData;
