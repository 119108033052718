import { Workflow, WorkflowRunDetails, WorkflowTemplateType } from 'api/workflow-interfaces';
import analytics from 'lib/analytics';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { toRoute } from 'lib/route-helper';
import { compact, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import { stringify } from 'query-string';
import * as React from 'react';
import { Button, Loader, Message, Modal } from 'semantic-ui-react';
import { WorkflowsStoreInterface } from 'stores/WorkflowsStore';
import './workflow-history.scss';

export interface WorkflowRunDetailsProps {
  workflowId: string;
  runId?: string;
  orgId: string;
  onClose: () => void;
  workflowsStore?: WorkflowsStoreInterface;
}

export interface WorkflowRunDetailsState {
  workflowRunDetails?: WorkflowRunDetails;
  workflowDetails: Workflow | undefined;
  templateType: string | undefined;
}

@inject('workflowsStore')
@observer
export default class WorkflowRunDetailsComponent extends
  React.Component<WorkflowRunDetailsProps, WorkflowRunDetailsState> {

  state = {} as WorkflowRunDetailsState;

  get workflow(): Workflow | undefined {
    const { getWorkflow } = this.props.workflowsStore!;
    return getWorkflow(this.props.workflowId);
  }

  componentDidUpdate(prevProps: WorkflowRunDetailsProps) {
    const { workflowId, runId } = this.props;
    if (prevProps.workflowId !== workflowId || prevProps.runId !== runId) {
      this.fetchData();
      if (runId) {
        analytics.track('Workflows: View Run', { category: 'Workflows' });
      }
    }
  }

  fetchData() {
    const { workflowsStore, workflowId, runId } = this.props;
    if (runId && workflowsStore) {
      workflowsStore.fetchWorkflowRunDetails(workflowId, runId).then(details => {
        this.setState({ workflowRunDetails: details });
      });
      workflowsStore.fetchWorkflowDetails(workflowId).then(details => {
        const templateId = details?.configuration.templateId;
        this.setState({ workflowDetails: details, templateType: templateId });
      });
    } else {
      this.setState({ workflowRunDetails: undefined, workflowDetails: undefined });
    }
  }

  redirectToAnalysisTool() {
    const { workflowDetails, templateType } = this.state;
    if (workflowDetails) {
      const stateType = templateType === WorkflowTemplateType.ROUTE ? 'MatchDetection' : 'SpikeDetection';
      const tool = templateType === WorkflowTemplateType.ROUTE ? 'explore' : 'significant-changes';
      const workflowParams = workflowDetails.configuration.stateMachine['States'][stateType]['Parameters'];
      const pathToAnalysisTool = `${ toRoute(this.props.orgId, workflowParams.surveyId, undefined, '_') }/${ tool }`;
      const updateFiltersStructure = Object.keys(workflowParams.filterMetadata)
        .reduce((filters, key) => ({ ...filters, [key]: [...workflowParams.filterMetadata[key].ids] }), {});
      const query = {
        filters: stringify(updateFiltersStructure),
      };
      const toNavigate = `/#${ pathToAnalysisTool }?${ stringify(query) }`;
      window.location.href = toNavigate;
    }
  }

  getThemesDiscoveryWorkflowResultsUrl() {
    const { workflowRunDetails } = this.state;
    if (workflowRunDetails && 'themes' in workflowRunDetails.result) {
      const workflowResult = workflowRunDetails.result;
      return workflowResult.themes.parameters.targetUrl;
    }
    return;
  }

  onSeeFullResultsClick() {
    const { templateType } = this.state;
    const isRouteOrSpikeTemplate = templateType === WorkflowTemplateType.ROUTE
      || templateType === WorkflowTemplateType.SPIKE;
    if (isRouteOrSpikeTemplate) {
      this.redirectToAnalysisTool();
    } else {
      const url = this.getThemesDiscoveryWorkflowResultsUrl();
      if (url) {
        window.location.href = url;
      }
    }
  }

  render() {
    const { workflow } = this;
    const { workflowRunDetails } = this.state;
    const { runId, onClose } = this.props;
    const {
      fetchingRunDetails,
      fetchRunDetailsErroredMessage,
      fetchingWorkflowDetails,
      fetchWorkflowDetailsErroredMessage
    } = this.props.workflowsStore!;
    const canSeeDevTools = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_DEV_ONLY_TOOLS);

    // Add check to see if permissions are initialized as we have the
    // interim state when fetching flags of the API calls are not yet set
    const loading = fetchingRunDetails || fetchingWorkflowDetails;
    const errors = compact([fetchRunDetailsErroredMessage, fetchWorkflowDetailsErroredMessage]);
    return (
      <Modal
        dimmer="blurring"
        className="permissions"
        open={!!runId}
      >
        <Modal.Content>
          {loading &&
            <Loader size="large" content="Loading..." active={loading} />
          }
          {!loading &&
            <React.Fragment>
              {errors.map(error => {
                return (
                  <Message
                    key={error}
                    className="error"
                    negative={true}
                    header={error}
                  />
                );
              })}
              <Modal.Header>
                <h2>Workflow {workflow ? `"${ workflow.name }"` : ''}</h2>
                <strong>Run: {runId}</strong>
              </Modal.Header>
              <Button
                primary={true}
                size="medium"
                disabled={!!fetchRunDetailsErroredMessage}
                onClick={() => this.onSeeFullResultsClick()}
              >
                See the full Results
              </Button>
              {canSeeDevTools && (
                <div className="dev-only-tools">
                  <div className="title">
                    Dev only tools
                  </div>
                  <h1>Steps:</h1>
                  <div>{workflowRunDetails && (
                    workflowRunDetails.history.map(entry => {
                      let name = '';
                      if (entry.stateEnteredEventDetails) {
                        name = entry.stateEnteredEventDetails.name;
                      } else if (entry.stateExitedEventDetails) {
                        name = entry.stateExitedEventDetails.name;
                      } else if (entry.executionFailedEventDetails) {
                        name = entry.executionFailedEventDetails.name;
                      }
                      return (<div className="step-detail-entry" key={entry.id}>
                        <h2>{name} ({entry.type})</h2>
                        {entry.stateEnteredEventDetails && (
                          <div>
                            <h3>Input</h3>
                            {map(entry.stateEnteredEventDetails.input, (value, key) => {
                              return (<div className="step-entry-data">
                                <div className="step-entry-data__title">
                                  {key}
                                </div>
                                <div>
                                  {JSON.stringify(value)}
                                </div>
                              </div>);
                            })}
                          </div>
                        )}
                        {entry.stateExitedEventDetails && (
                          <div>
                            <h3>Output</h3>
                            {map(entry.stateExitedEventDetails.output, (value, key) => {
                              return (<div className="step-entry-data">
                                <div className="step-entry-data__title">
                                  {key}
                                </div>
                                <div>
                                  {JSON.stringify(value)}
                                </div>
                              </div>);
                            })}
                          </div>
                        )}
                        {entry.executionFailedEventDetails && (
                          <div className="step-entry-data">
                            <div className="step-entry-data__title">
                              Cause
                            </div>
                            <div>
                              {entry.executionFailedEventDetails.cause}
                            </div>
                          </div>
                        )}
                      </div>
                      );
                    }
                    ))}</div>
                </div>
              )}
            </React.Fragment>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="nw--close"
            onClick={onClose}
            type="button"
            color="blue"
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
