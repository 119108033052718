<template>
  <div v-loading="loading">
    <el-alert
      v-if="error"
      :title="`Something went wrong. Try reloading. ${errorMessage}`"
      type="error"
      show-icon
    />
    <div class="widget__header">
      <div>Volume of</div>
      <div class="widget__header-theme">
        {{ selectedThemeName }}
      </div>
      <div v-if="hasFilterWithCuts">
        by
      </div>
      <dimension-selector v-if="hasFilterWithCuts" />
      <div class="widget__header-right">
        <el-dropdown
          :hide-on-click="false"
          class="float-right"
          trigger="click"
        >
          <el-button
            class="chart-action"
            size="small"
            type="plain-text"
          >
            <font-awesome-icon icon="cog" />
            <font-awesome-icon icon="chevron-down" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <div class="chart-options">
              <div class="chart-option">
                <div class="chart-option-label">
                  Show:
                </div>
                <ul class="chart-option-list">
                  <li class="chart-option-item">
                    <el-radio
                      v-model="showBy"
                      label="percentage"
                    >
                      Percentage
                    </el-radio>
                  </li>
                  <li class="chart-option-item">
                    <el-radio
                      v-model="showBy"
                      label="count"
                    >
                      Theme Count
                    </el-radio>
                  </li>
                  <li class="chart-option-item">
                    <el-radio
                      v-model="showBy"
                      label="totalCount"
                    >
                      Total Count
                    </el-radio>
                  </li>
                </ul>
              </div>
              <div class="chart-option">
                <div class="chart-option-label">
                  Order:
                </div>
                <ul class="chart-option-list">
                  <li class="chart-option-item">
                    <el-radio
                      v-model="orderBy"
                      label="original"
                    >
                      Original
                    </el-radio>
                  </li>
                  <li class="chart-option-item">
                    <el-radio
                      v-model="orderBy"
                      label="high"
                    >
                      Highest
                    </el-radio>
                  </li>
                  <li class="chart-option-item">
                    <el-radio
                      v-model="orderBy"
                      label="low"
                    >
                      Lowest
                    </el-radio>
                  </li>
                  <li
                    :class="{ disabled:!isComparison }"
                    class="chart-option-item"
                  >
                    <el-radio
                      v-model="orderBy"
                      label="difference"
                    >
                      Difference
                    </el-radio>
                  </li>
                </ul>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div
      ref="dimensionBarGraphContainer"
      class="widget__body comparison-container"
    >
      <div
        v-if="hasFilterWithCuts"
        :style="containerStyle"
      >
        <div
          ref="dimensionBarGraph"
        />
      </div>
      <div
        v-else
      >
        <div
          v-if="analysisConfigStore.canManageSurvey"
          class="no-segmentation"
        >
          Setup filters to view theme volume by segment
          <el-button
            size="medium"
            type="primary"
            @click="goToConfigureData"
          >
            Setup filters
          </el-button>
        </div>
        <div
          v-else
          class="no-segmentation"
        >
          Filters must be setup to view theme volume by segment
        </div>
      </div>
      <section
        v-if="hasFilterWithCuts"
        class="dimension-themes-key-selection"
      >
        <button
          :class="{
            'count-selection-action': true,
            'count-selection-action--baseline': true,
            'count-selection-action--inactive': !showBaseThemeBaselineValues
          }"
          type="button"
          @click="toggleThemeKeySelection(true)"
        >
          {{ baselineTitle }}
        </button>
        <button
          :class="{
            'count-selection-action': true,
            'count-selection-action--comparison': true,
            'count-selection-action--inactive': !showBaseThemeComparisonValues
          }"
          type="button"
          @click="toggleThemeKeySelection(false)"
        >
          {{ comparisonTitle }}
        </button>
      </section>
    </div>
  </div>
</template>

<script>
const BAR_WIDTH = 50;
const BAR_HEIGHT = 250;
import { cloneDeep, findIndex, get, includes, isEmpty } from 'lodash';

import bargraph from 'charts/Utils/bargraph';
import DimensionSelector from 'vue/components/Filters/FilterThemeDimensionSelector.vue';
import doFetch, { topNToDisplay } from 'vue/components/Tiles/Utils/dimension-fetcher';
import { watchAll } from 'vue/libs/vueExt';

import { getAnalysisConfigStore, getAnalysisToolsUIStore, getInitConfigStore, getExploreToolUIStore } from 'stores/RootStore';
import queryBuilder from 'vue/libs/queryBuilder';
import { generateDimensionData, sortBarGraphData } from 'charts/Utils/bargraphHelpers';
import { Orientation } from 'types/custom';

function makeDefaultCommentCount() {
  return {
    baseCount: 0,
    baseSentimentCounts: { neg: 0, neut: 0, pos: 0 },
    compCount: 0,
    compSentimentCounts: { neg: 0, neut: 0, pos: 0 }
  };
}
export default {
  name: 'Dimension',
  components: {
    DimensionSelector
  },
  props: {
    highlight: {
      type: Object,
      base: { type: String },
      sub: { type: String }
    },
    selections: {
      type: Object,
      baseline: { type: Object },
      comparison: { type: Object }
    },
    selectionsWithTheme: {
      type: Object,
      baseline: { type: Object },
      comparison: { type: Object }
    },
    title: { type: String }
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      error: undefined,
      latestFetch: undefined,
      loading: true,
      selectedDimensionTitle: '',
      selectedDimensionLabel: '',
      selectedDimensionIndex: -1,
      selectedDimensionCategory: undefined,
      orderBy: 'original',
      showBy: 'percentage',
      showBaseThemeBaselineValues: true,
      showBaseThemeComparisonValues: false,
      chartData: undefined,
      canvas: undefined,
      canvasContext: undefined,
      d3Tip: undefined,
    };
  },
  computed: {
    errorMessage() {
      return this.error?.errorData?.message ?? '';
    },
    selectedThemeName() {
      return this.exploreToolUIStore.selectedThemeName;
    },
    organization() {
      return this.initConfigStore.organization;
    },
    surveyId() {
      return this.initConfigStore.surveyId;
    },
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    initConfig() {
      return this.initConfigStore.config;
    },
    themeFilterDimension() {
      return this.analysisToolsUIStore.getThemeFilterDimension(this.initConfig.filters);
    },
    containerStyle() {
      const { chartData } = this;
      if (chartData && chartData.baselineData) {
        const numberFields = Math.min(chartData.baselineData.length, topNToDisplay);
        const width = 25 * numberFields;
        return {"min-width": `${width}px`, "height": "250px"};
      }
      return {width: "100%"};
    },
    isComparison() {
      return this.selections.baseline.query !== this.selections.comparison.query;
    },
    hasFilterWithCuts () {
      const filters = get(this, 'initConfig.filters', []);
      const filterWithCuts = filters.find(filter => !isEmpty(filter.cuts));
      return !!filterWithCuts;
    },
    selectionsWithDimension() {
      const selections = cloneDeep(this.selectionsWithTheme);
      selections.baseline = queryBuilder.appendToFilter(
        get(selections, 'baseline.query'),
        get(this, 'selectedDimensionCategory.rql')
      );
      selections.comparison = queryBuilder.appendToFilter(
        get(selections, 'comparison.query'),
        get(this, 'selectedDimensionCategory.rql')
      );
      return selections;
    },
    commentsTitleSuffix() {
      return this.selectedDimensionLabel || '';
    },
    baselineCommentCount() {
      const defaultInfo = makeDefaultCommentCount();
      return this.chartData?.baselineData[this.selectedDimensionIndex]?.counts ?? defaultInfo;
    },
    comparisonCommentCount() {
      const defaultInfo = makeDefaultCommentCount();
      return this.chartData?.comparisonData[this.selectedDimensionIndex]?.counts ?? defaultInfo;
    },
    commentCountInfo() {
      const {
        baselineCommentCount: baseline,
        comparisonCommentCount: comparison
      } = this;
      return { baseline, comparison };
    },
    baselineTitle() {
      return this.chartData?.baselineTitle || 'Baseline';
    },
    comparisonTitle() {
      return this.chartData?.comparisonTitle || 'Comparison';
    },
  },
  watch: {
    selections: {
      handler(selections) {
        if (selections) {
          // if filters differ then we show comparison values
          this.showBaseThemeComparisonValues = this.isComparison;
          this.showSubthemeComparisonValues = this.isComparison;
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateChart);
    this.canvas = document.createElement('canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.canvasContext.font = getComputedStyle(document.body).font;

    watchAll(
      this,
      [
        'selectionsWithTheme',
        'themeFilterDimension',
        'showBy'
      ],
      () => this.fetchData()
    );
    // Do not set default order by when user selects sort order
    watchAll(
      this,
      [
        'orderBy'
      ],
      () => this.fetchData(false)
    )
    if (this.selections) {
      this.fetchData();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChart);
    this.canvas.remove();
    this.d3Tip?.destroy();
  },
  methods: {
    toggleThemeKeySelection(isBaseline) {
      if (isBaseline) {
        this.showBaseThemeBaselineValues = !this.showBaseThemeBaselineValues;
      } else {
        this.showBaseThemeComparisonValues = !this.showBaseThemeComparisonValues;
      }
      this.fetchData();
    },
    goToConfigureData () {
      const { organization, surveyId } = this;
      const link = `/#/c/${organization}/s/${surveyId}/configure-analysis`;
      window.location = link;
    },
    selectDimension(label) {
      if (!label) {
        this.$emit('onSelectionCleared', undefined);
        return;
      }
      const cuts = get(this, 'themeFilterDimension.cuts');
      const foundIndex = findIndex(cuts, { name: label });
      const cutKey = `themeFilterDimension.cuts[${foundIndex}]`;
      this.selectedDimensionCategory = get(this, cutKey);
      this.selectedDimensionLabel = label;
      // The minus one is because cuts should(?) always start with an all
      this.selectedDimensionIndex = foundIndex - 1;

      const { commentCountInfo, commentsTitleSuffix } = this;
      this.$emit(
        'onSelectionChanged',
        this.selectionsWithDimension,
        commentCountInfo,
        commentsTitleSuffix
      );
    },
    setDefaultOrderBy() {
      // If the selected column is a score or date column, then the
      // ordering should be by 'original'; if it isn't a score or date,
      // the default ordering should be by 'difference' if there is a
      // comparison and 'highest' if there isn't a comparison

      const dateColumn = get(this, 'initConfig.date_column');
      const scoreColumns = get(this, 'initConfig.scores', []).map(config => get(config, 'score_column'))

      const columnIdsForDefaultOriginal = [
        dateColumn,
        ...scoreColumns
      ];
      const selectedDimensionColumn = get(this, 'themeFilterDimension.column');
      const sortByOriginal = includes(columnIdsForDefaultOriginal, selectedDimensionColumn);

      if (sortByOriginal) {
        this.orderBy = 'original';
      } else if (this.isComparison) {
        this.orderBy = 'difference';
      } else {
        this.orderBy = 'high';
      }
    },
    getChartWidth(baseLineData) {
      const barGraphContainerWidth = this.$refs.dimensionBarGraphContainer.getBoundingClientRect().width - 20;
      if (!baseLineData) {
        return barGraphContainerWidth;
      }
      // Conditions on how we are calculating the width of the bar graph
      // - If the number of fields is less than 10, then we will use the default width of the bar graph container
      // - If the number of fields is greater than 10,
      //   then we will use the width which is calculated based on the number of fields in baseline data
      const numberFields = Math.min(baseLineData.length, 100);
      const width = BAR_WIDTH * numberFields;
      const shouldReturnWidthBasedOnFields = width > barGraphContainerWidth && numberFields > 10;
      return shouldReturnWidthBasedOnFields ? width : barGraphContainerWidth;
    },
    fetchData: async function(setDefaultOrderBy = true) {
      const areFiltersPresent = this.selections && !isEmpty(this.selections.baseline.title);
      if (!this.hasFilterWithCuts || !areFiltersPresent) {
        this.loading = false;
        return;
      }
      this.loading = true;
      this.selectedDimensionTitle = this.themeFilterDimension.name;

      if(setDefaultOrderBy) {
        this.setDefaultOrderBy();
      }

      const fetch = doFetch({
        commentColumns: this.commentColumns,
        selections: this.selections,
        selectionsWithTheme: this.selectionsWithTheme,
        selectedDimension: this.themeFilterDimension.id,
        options: this.requestOptions,
        graphShowBy: this.showBy
      });

      this.latestFetch = fetch;
      try {
        const {
          barOptions,
          labels,
          baselineCollectedData,
          comparisonCollectedData
        } = await fetch;

        // if there's been another fetch in between, bail out now
        if (fetch !== this.latestFetch) {
          return;
        }
        this.chartData = {
          baselineTitle: barOptions.baselineTitle,
          comparisonTitle: barOptions.comparisonTitle,
          chartLabel: barOptions.valueLabel,
          baselineLabels: labels,
          baselineData: baselineCollectedData,
          comparisonData: comparisonCollectedData
        };

        const options = this.getBarGraphOptions();
        this.d3Tip?.destroy();
        this.d3Tip = bargraph.getBarGraphTip(options);
        bargraph.renderBarGraphData({
          el: this.$refs.dimensionBarGraph,
          options,
          tip: this.d3Tip
        });
      } catch (e) {
        this.error = e;
      } finally {
        // only turn off loading if it's the latest fetch
        if (fetch === this.latestFetch) {
          this.loading = false;
        }
      }
    },
    updateChart() {
      const { chartData } = this;
      if (chartData) {
        const options = this.getBarGraphOptions();
        this.d3Tip?.destroy();
        this.d3Tip = bargraph.getBarGraphTip(options);
        bargraph.renderBarGraphData({
          el: this.$refs.dimensionBarGraph,
          options,
          tip: this.d3Tip
        });
      }
    },
    getBarGraphOptions() {
      const {
        chartLabel,
        baselineLabels,
        baselineData,
        comparisonData
       } = this.chartData;
      const width = this.getChartWidth(baselineData);
      const sortedData = sortBarGraphData(
        generateDimensionData(baselineData, comparisonData, baselineLabels),
        this.orderBy
      );
      const chartUnit = this.showBy === 'percentage' ? '%' : '';
      return {
        labels: sortedData.map(item => item.label),
        showBaselineValues: this.showBaseThemeBaselineValues,
        showComparisonValues: this.showBaseThemeComparisonValues,
        chartLabel,
        orderBy: this.orderBy,
        data: sortedData,
        chartUnit,
        orientation: Orientation.VERTICAL,
        barChartWidth: width,
        barChartHeight: BAR_HEIGHT,
        showVerticalChartLabels: this.shouldShowDiagonalLabels(),
        showCommentsCountInTooltip: this.showBy === 'percentage',
        showSigDiffLabelInTooltip: true,
        onClick: (theme) => this.selectDimension(theme),
      };
    },
    getTextWidth(text) {
      return this.canvasContext.measureText(text).width;
    },
    shouldShowDiagonalLabels() {
      const {
        baselineLabels
      } = this.chartData;
      const barGraphContainerWidth = this.$refs.dimensionBarGraphContainer.getBoundingClientRect().width - 60;
      const widthOfLabels = baselineLabels.map(label => this.getTextWidth(label));
      const sumOfWidthLabels = widthOfLabels.reduce((acc, curr) => acc + curr, 0);
      return sumOfWidthLabels > barGraphContainerWidth;
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../styles/element-variables';
.row {
  margin-bottom: 5px;
}
.comparison-container {
  padding-top: 10px;
  overflow-x: auto;
}
.chart-option-list {
  .disabled {
    .el-radio {
      pointer-events: none;
      color: $--neutral-400 !important;
    }
    .el-radio__inner {
      background-color: $--neutral-300 !important;
    }
  }
}
.no-segmentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: center;

  .el-button {
    background-color: $--primary-500;
    border-color: $--primary-500;
    color: $--color-white;
    margin-top: 20px;
  }
}
.dimension-themes-key-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.count-selection-action {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 12px;
    margin-right: 5px;
    background: var(--primary-500);
  }
}
.count-selection-action--baseline::before {
  background: var(--primary-500);
}
.count-selection-action--comparison::before {
  background: var(--orange-500);
}
.count-selection-action--inactive {
  text-decoration: line-through;
}
</style>
