import * as React from 'react';
import './comment-blocks.scss';
import { Block } from 'lib/segments-to-blocks';

interface Props {
  blocks: Block[]
}

const CommentBlocks = ({ blocks }: Props) => {
  return (
    <div>
      {blocks.map((block, index) =>
        <span className="comments__block" key={index}>{ block.content }</span>
      )}
    </div>
  );
};

export { CommentBlocks };
