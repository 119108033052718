import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoleDetailsJson } from 'api/interfaces';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import AdminPermissions from 'components/ManageUsers/AdminPermissions';
import DashboardPermissions from 'components/ManageUsers/DashboardPermissions';
import SurveyPermissions from 'components/ManageUsers/SurveyPermissions';
import { getRolesRoute } from 'lib/route-helper';
import { compact, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button, Form, FormInputProps,
  Header, Input, Loader,
  Message,
  Segment,
  TextArea, TextAreaProps
} from 'semantic-ui-react';
import { ManageUsersStoreInterface } from 'stores/ManageUsersStore';
import {
  DEFAULT_ROLE_DETAILS, RoleDetails, RoleStoreInterface,
  TEMP_ROLE_ID
} from 'stores/RoleStore';
import { mapSelectedPermissions } from 'stores/utils/roles';
import { FieldValidation, initialValidationState, required } from 'validations';
import analytics from '../../lib/analytics';
import './manage-role.scss';

export interface ManageRoleParams {
  roleId: string;
  orgId: string;
}

export interface ManageRoleState {
  name: FieldValidation<string>;
  description: FieldValidation<string>;
  showDelete: boolean;
  isUserSpecific?: boolean;
}

export interface ManageRoleProps extends RouteComponentProps<ManageRoleParams> {
  roleStore?: RoleStoreInterface;
  manageUsersStore?: ManageUsersStoreInterface;
}

@inject('roleStore', 'manageUsersStore')
@observer
export default class ManageRole extends React.Component<ManageRoleProps, ManageRoleState> {
  nameInput: Input | null;
  constructor(props: ManageRoleProps) {
    super(props);

    this.state = {
      name: initialValidationState(''),
      description: initialValidationState(''),
      showDelete: false,
      isUserSpecific: false
    };
  }
  get roleId () {
    const { roleId } = this.props.match.params;
    // Can either be create role or edit an existing role
    return roleId || TEMP_ROLE_ID;
  }
  get isNew () {
    const { roleId } = this.props.match.params;
    return !roleId;
  }
  get roleStore() {
    return this.props.roleStore!;
  }
  get manageUsersStore() {
    return this.props.manageUsersStore!;
  }
  get roleDetails (): RoleDetails {
    return this.roleStore.roleDetails[this.roleId];
  }
  get isValid(): boolean {
    const { name } = this.state;

    return !!name.value && !name.error;
  }
  get isPristine(): boolean {
    const { name, description } = this.state;

    return name.pristine && description.pristine;
  }
  get hasUnsavedPermissions(): boolean {
    return this.roleStore.hasChanged(this.roleId);
  }
  get hasUnsavedChanges(): boolean {
    return !this.isPristine || this.hasUnsavedPermissions;
  }
  componentDidMount () {
    analytics.track('Role Mgmt: Role detail view', {category: 'Role Mgmt'});

    if (this.nameInput) {
      this.nameInput.focus();
    }

    this.initialize();
  }
  componentDidUpdate(prevProps: ManageRoleProps) {
    const newRoleId = prevProps.match.params.roleId;
    // Check if route changed from create role to edit role
    // or edit role to create role and initialize role details
    if ((!!newRoleId && this.roleId !== newRoleId) ||
        (!newRoleId && this.roleId !== TEMP_ROLE_ID)) {
      this.initialize();
    }
  }
  componentWillUnmount() {
    this.resetState();
  }
  initialize = async () => {
    const { roles, emptyPermissions } = this.roleStore;

    // TODO: empty roles is not a reliable way of checking if roles have been fetched
    if (isEmpty(roles)) {
      await this.roleStore.fetchRoles();
    }

    if (!emptyPermissions) {
      await this.roleStore.fetchEmptyPermissions();
    }

    // If role details already exists in list of roles, map it to displayable format
    // Otherwise, fetch role details and map it to displayable format
    if (!this.roleStore.roleDetails[this.roleId]) {
      if (this.roleId !== TEMP_ROLE_ID) {
        let roleDetails: RoleDetailsJson;
        const roleIndex = this.roleStore.roles.findIndex(role => role.id === this.roleId);
        if (roleIndex < 0) {
          roleDetails = await this.roleStore.fetchRoleDetails(this.roleId);
        } else {
          roleDetails = this.roleStore.roles[roleIndex];
        }
        if (!this.roleStore.fetchRoleDetailsError) {
          this.roleStore.roleDetails[this.roleId] = mapSelectedPermissions(roleDetails);
        }
      } else {
        this.roleStore.roleDetails[this.roleId] = DEFAULT_ROLE_DETAILS;
      }
    }

    const isUserSpecific = (this.roleStore.roles.find(role => role.id === this.roleId) || {}).isUserSpecific;
    this.setState({ isUserSpecific: !!isUserSpecific });

    this.initializeRoleDetails();
  }
  initializeRoleDetails = () => {
    const roleDetails = this.roleStore.roleDetails[this.roleId];
    if (roleDetails) {
      const { name, description } = roleDetails;
      const nameUpdate = { value: name, error: undefined, pristine: true };
      const descriptionUpdate = { value: description, error: undefined, pristine: true };

      this.setState({ name: nameUpdate, description: descriptionUpdate });
    }
  }
  resetState = () => {
    this.roleStore.resetRoleState(this.roleId);
  }
  handleNameChange = ({ value }: FormInputProps) => {
    const error = required(value, 'Name');
    const update = { error, pristine: false, value };

    this.setState({ name: update });
  }
  handleDescriptionChange = ({ value }: TextAreaProps) => {
    const update = { error: undefined, pristine: false, value: value as string };

    this.setState({ description: update });
  }
  cancel = () => {
    this.resetState();
    this.goBack();
  }
  goBack = () => {
    this.props.history.push(getRolesRoute(this.props.match.params.orgId));
  }
  showDelete = () => {
    this.setState({ showDelete: true });
  }
  cancelDelete = () => {
    this.setState({ showDelete: false });
  }
  delete = async () => {
    analytics.track('Role Mgmt: Role delete', {category: 'Role Mgmt'} );

    await this.roleStore.deleteRole(this.roleId);
    this.setState({ showDelete: false });

    const { deleteRoleError } = this.roleStore;
    if (!deleteRoleError) {
      // Remove the deleted role from the users roles
      this.manageUsersStore.removeRoleForUsers(this.roleId);
      this.goBack();
    }
  }
  saveRole = async () => {
    if (!this.isValid) {
      return;
    }

    const name = this.state.name.value;
    const description =  this.state.description ? this.state.description.value : '';

    if (this.isNew) {
      analytics.track('Role Mgmt: Role create', {category: 'Role Mgmt'} );
      await this.roleStore.createRole(name, description, this.roleDetails);
    } else {
      analytics.track('Role Mgmt: Role update', {category: 'Role Mgmt'} );
      await this.roleStore.updateRole(this.roleId, name, description, this.roleDetails);
    }

    if (!this.roleStore.createRoleError || !this.roleStore.updateRoleError) {
      // Reset temporary role details
      this.roleStore.resetRoleState(TEMP_ROLE_ID);
      this.goBack();
    }
  }
  renderDelete = () => {
    const { deletingRole } = this.roleStore;
    return (
      <ConfirmationModal
        confirmationButtonText="Delete"
        danger={true}
        processing={deletingRole}
        cancel={this.cancelDelete}
        confirm={this.delete}
      />
    );
  };
  render(): JSX.Element | null {
    const {
      fetchingRoles,
      fetchRolesError,
      fetchingRoleDetails,
      fetchRoleDetailsError,
      fetchingEmptyPermissions,
      fetchEmptyPermissionsError,
      creatingRole,
      createRoleError,
      updatingRole,
      updateRoleError
    } = this.props.roleStore!;

    const loading = fetchingRoles || fetchingRoleDetails || fetchingEmptyPermissions;
    const performingEvent = creatingRole || updatingRole;
    const disabled = !this.isValid || !this.hasUnsavedChanges || performingEvent || loading;
    const errors = compact([
      fetchRoleDetailsError,
      fetchRolesError,
      fetchEmptyPermissionsError,
      createRoleError,
      updateRoleError
    ]);

    const {
      name,
      description,
      showDelete,
      isUserSpecific
    } = this.state;

    if (loading) {
      return (
        <Segment className="fullscreen nw--manage-role">
          <Loader size="large" content="Loading..." active={loading} />
        </Segment>
      );
    }

    if (!this.roleDetails) {
      return (
        <Segment>
          <Header>No such role exists</Header>
        </Segment>
      );
    }

    return (
      <Segment className="fullscreen nw--manage-role permissions">
        <Header>
          <div className="nw--back" onClick={this.goBack}>
            <FontAwesomeIcon
              size="lg"
              className="icon"
              icon="arrow-alt-circle-left"
              />
          </div>
          {this.isNew ? 'Create role' : 'Edit role'}
        </Header>

        {showDelete && this.renderDelete()}

        <Segment className="white content">
          {errors.map(error => {
            return (
              <Message
                key={error}
                className="error"
                negative={true}
                header={error}
              />
            );
          })}
          <Form>
            <Form.Field className="name">
              <label htmlFor="name">Name</label>
              <Input
                id="name"
                name="name"
                ref={input => {
                  this.nameInput = input;
                }}
                autoComplete="off"
                onChange={(_e, data) => this.handleNameChange(data)}
                placeholder="e.g.: Analyst"
                disabled={isUserSpecific}
                value={name.value}
              />
              {!name.pristine && name.error}
            </Form.Field>
            <Form.Field className="description">
              <label htmlFor="description">Description</label>
              <TextArea
                id="description"
                name="description"
                autoComplete="off"
                placeholder="e.g.: See and download from all datasets"
                value={description.value}
                disabled={isUserSpecific}
                onChange={(_e, data) => this.handleDescriptionChange(data)}
              />
              {!description.pristine && description.error}
            </Form.Field>
            <AdminPermissions
              roleId={this.roleId}
            />
            <br />
            <DashboardPermissions
              roleId={this.roleId}
            />
            <br />
            <SurveyPermissions
              roleId={this.roleId}
            />
          </Form>
          <div className="actions">
            <div className="left-action">
              {!this.isNew &&
                <Button
                  className="nw--delete-button"
                  color="red"
                  basic={true}
                  type="button"
                  disabled={loading || performingEvent}
                  onClick={this.showDelete}
                >
                  Delete
                </Button>
              }
            </div>
            <Button
              className="nw--cancel-button"
              disabled={loading || performingEvent}
              onClick={this.cancel}
              type="button"
            >
              Cancel
            </Button>
            <Button
              className="nw--update-button"
              color="blue"
              onClick={this.saveRole}
              disabled={disabled}
              loading={performingEvent}
            >
              {this.isNew ? 'Create' : 'Save'}
            </Button>
          </div>
        </Segment>
      </Segment>
    );
  }
}
