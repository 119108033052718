







import renderBarChart from 'charts/renderBarChart';
import colors from 'vue/styles/element-variables.scss';


export default {
  name: 'InsightsBarChart',
  props: {
    insight: { type: Object, required: true }
  },
  watch: {
    insight: {
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      const el = this.$refs.chart;
      renderBarChart({ el, config: this.insight, colors, width: 400, height: 365 });
    }
  }
};
