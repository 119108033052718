<template>
  <div class="config-field">
    <label>
      Examples
    </label>
    <el-checkbox
      v-model="showComments"
      class="config-check-box"
      @change="onShowCommentsChange()"
    >
      <p>
        Show examples when a theme is clicked
      </p>
    </el-checkbox>
  </div>
</template>

<script>

export default {
  name: 'ConfigureWidgetSize',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
    selectedWidget: { type: String }
  },
  data() {
    return {
      showComments: false,
    }
  },
  mounted() {
    const { showComments } = this.widgetConfig;
    if (showComments) {
      this.showComments = showComments;
    }
  },
  methods: {
    onShowCommentsChange() {
      this.$emit('onConfigChange', this.target, this.showComments, true);
    }
  }
}
</script>
