import { AnalysisFilterCut } from 'api/interfaces';
import { get, includes, omitBy, reduce } from 'lodash';
import { Filter } from 'stores/FilterStore';
import { QueryFilter } from 'stores/types';
import queryBuilder from 'vue/libs/queryBuilder';

export function withoutFilterById(filters: Filter, key: string, ignoreFilterIds: string[]) {
  const keyFilters = get(filters, key, []);
  return omitBy(keyFilters, val => {
    return includes(ignoreFilterIds, val.filterId);
  });
}

export function getActiveCutIds(queryFilter: QueryFilter): string[] {
  return queryFilter.selected?.reduce((result, c) => {
    return c.id ? [...result, c.id] : result;
  }, []) ?? [];
}

export function queryFilterToSegmentFilter(filters: Filter, key: string): string | undefined {
  const keyFilters: { [key: string]: QueryFilter } = filters[key] ?? {};
  const segmentFilter = reduce(keyFilters, (result, value) => {
    if (value.filterType === 'category') {
      value.selected?.forEach((cut: AnalysisFilterCut) => {
        result = queryBuilder.appendToSegmentFilter(result, `category:${ cut.id }`);
      });
    } else if (value.filterType === 'sentiment') {
      value.selected?.forEach((cut: AnalysisFilterCut) => {
        result = queryBuilder.appendToSegmentFilter(result, `sentiment:${ cut.id }`);
      });
    } else if (value.filterType === 'themes') {

      const selected = value.selected ?? [];

      selected
        .filter(cut => cut.id !== 'untagged_comments')
        .forEach(cut => {
      let cutId = cut.id;
      if (cut.parentId) {
          cutId = `${ cut.parentId }!${ cut.id }`;
        }
      result = queryBuilder.appendToSegmentFilter(result, `theme:${ cutId }`);
      });
    }
    return result;
  }, '');

  if (segmentFilter !== '') {
    return segmentFilter;
  }
  return undefined;
}
