import domtoimage from 'dom-to-image-more';
import FileSaver from 'file-saver';

export const copyText = (text: string) => {
  if (!navigator.clipboard) {
    copyTextFallback(text);
    return;
  }

  try {
    navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(`Copy failed — ${ error }`);
  }
};

const copyTextFallback = (text: string): void => {
  const tempTextArea = document.createElement('textarea');
  tempTextArea.value = text;
  tempTextArea.style.position = 'fixed'; // avoid scrolling to bottom
  document.body.appendChild(tempTextArea);
  tempTextArea.focus();
  tempTextArea.select();

  try {
    // deprecated
    document.execCommand('copy');
  } catch (error) {
    console.error(`Copy failed — ${ error }`);
  }

  document.body.removeChild(tempTextArea);
};

export const copyNodeToClipboardAsImage = async (node, options) => {
  const blob = await domtoimage.toBlob(node, options);

  if (navigator.clipboard) {
    const data = [new ClipboardItem({ [blob.type]: blob })];
    navigator.clipboard.write(data);
  } else {
    FileSaver.saveAs(blob, 'clipboard.png');
  }
};

const filterCopyableElements = (node: HTMLElement) => {
  Array.from(node.children).forEach((element) => {
    // remove elements marked as being hidden from copy
    if (element.classList.contains('do-not-copy')) {
      node.removeChild(element);
      return;
    }
    // make visible elements that are marked as 'must be included in copy'
    if (element.classList.contains('ensure-contents-copied')) {
      if (element instanceof HTMLElement) {
        element.style.display = 'initial';
      }
    }

    // apply the same logic to all other children
    filterCopyableElements(element as HTMLElement);
  });
};

export const copyNodeToClipboardAsHtml = async (node: HTMLElement) => {
  const range = document.createRange();

  const filteredNode = node.cloneNode(true) as HTMLElement;

  filterCopyableElements(filteredNode);

  document.body.appendChild(filteredNode);

  range.selectNodeContents(filteredNode);
  const selection = window.getSelection();

  if (!selection) {
    throw new Error('Failed to get selection');
  }

  selection.removeAllRanges();
  selection.addRange(range);

  if (!window.ClipboardItem) {
    copyTextFallback(selection.toString());
  } else {

    const clonedSelection = range.cloneContents();
    const tempDiv = document.createElement('div');
    tempDiv.appendChild(clonedSelection);
    const html = tempDiv.innerHTML;
    const item = new ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([selection.toString()], { type: 'text/plain' })
    });

    try {
      await navigator.clipboard.write([item]);
    } catch {
      throw new Error('Failed to copy');
    } finally {
      selection.removeAllRanges();
    }
  }
  document.body.removeChild(filteredNode);
};
