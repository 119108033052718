import { cloneDeep } from "lodash";
import { RequestOptions } from "stores/InitConfigStore";

type Options = {
  scoreCommentsOnly: boolean | null | undefined,
  scoreColumn: RequestOptions['scoreColumn'] | null,
  scoreType: RequestOptions['scoreType'] | null,
  scoreOptions: RequestOptions['scoreOptions'] | null
};

export function setupRequestOptions(
  requestOptions: RequestOptions,
  options: Options
): RequestOptions {
  const {
    scoreCommentsOnly,
    scoreColumn,
    scoreType,
    scoreOptions
  } = options;

  if (scoreCommentsOnly === null || scoreCommentsOnly === undefined) {
    throw 'Score comments only not defined';
  }
  const nextRequestOptions = cloneDeep(requestOptions);

  if (nextRequestOptions.scoreOptions) {
    nextRequestOptions.scoreOptions.scoreCommentsOnly = scoreCommentsOnly;
  }
  if (scoreColumn != null) {
    nextRequestOptions.scoreColumn = scoreColumn;
  }
  if (scoreType != null) {
    nextRequestOptions.scoreType = scoreType;
  }
  if (scoreOptions != null) {
    nextRequestOptions.scoreOptions = scoreOptions;
  }
  return nextRequestOptions;
}
