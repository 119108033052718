import { AnswersFilters, SegmentFilters } from 'api/interfaces';

export function segmentFiltersToAnswersFilters(segmentFilters: SegmentFilters): AnswersFilters {

  return Object.entries(segmentFilters).reduce((result: AnswersFilters, [key, value]): AnswersFilters => {

    if (value.startDate && value.endDate) {
      result[key] = [value.startDate, value.endDate];
      return result;
    }

    if (value.ids) {
      result[key] = value.ids;
      return result;
    }

    if (value.names) {
      result[key] = value.names.map(name => name.id);
      return result;
    }

    if (value.text) {
      result[key] = [value.text];
      return result;
    }

    return result;

  }, {});

}
