import UploadDisabledSvg from 'images/icons/upload-disabled.svg';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import DataSourceStatusLabel from './DataSourceStatusLabel';
import './survey-incomplete-action.scss';
import './upload-disabled.scss';

interface UploadDisabledProps {
  survey: LiteSurvey;
}

class UploadDisabled extends React.Component<UploadDisabledProps> {
  render() {
    const { survey } = this.props;
    const { surveyId, orgId } = survey;
    const uniqueIdLink = `/c/${orgId}/s/${surveyId}/configure-analysis`;

    const dataSource = this.props.survey.dataSourceIntegration;

    return (
      <div className="upload-disabled">
        <UploadDisabledSvg className="upload-disabled__icon" />
        <div className="upload-disabled__content">
          {dataSource && (
            <DataSourceStatusLabel dataSource={dataSource} />
          )}
          <p className="upload-disabled__title">New uploads are disabled</p>
          <p className="upload-disabled__note">
            To upload new data to this dataset, first <Link to={uniqueIdLink}>
              set the unique ID column
            </Link> to prevent duplicates. <a
              href="https://help.getthematic.com/article/106-unique-id-columns"
              target="blank"
              rel="noopener noreferrer"
            >Learn more.</a>
          </p>
        </div>
      </div>
    );
  }
}

export default UploadDisabled;
