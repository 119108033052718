
import { BarVisualizationSection, VisSeries } from 'api/interfaces';
import { uniq } from 'lodash';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { formatCount } from '../utils/formatCount';

interface TableCellData {
  volume?: string; // only applicable when showing theme percentages
  count: string;
}

interface VisualizationBarTableProps {
  content: BarVisualizationSection;
}

export class VisualizationBarTable extends React.Component<
  VisualizationBarTableProps
> {

  initializeTableDataFromSeriesUsingComponents = (series: VisSeries[], sources: string[]): TableCellData[][] => {
    // Initializes table data from series data
    // each row is per label
    // inside each row there is a set of columns for each series,
    // which is one column per source in the order specified by sources

    const data = series[0].values.map((_, index) => {
      // now for this index we need to go through each series.
      const row = series.reduce((acc: TableCellData[], s) => {
        // for each source, find the value for this row
        const seriesData = sources.map(source => {
          const sourceForRow = s.components ? s.components[index].find(value => value.source === source) : null;
          const volume = (sourceForRow && sourceForRow.volume !== undefined) ? `${ sourceForRow.volume.toFixed(1) }%` : '';
          const count = sourceForRow ? formatCount(sourceForRow.count) : '-';

          return { count, volume };
        });
        return acc.concat(seriesData);
      }, []);
      return row;
    });

    return data;
  }

  initializeTableDataFromSeriesUsingValues = (series: VisSeries[]): TableCellData[][] => {
    const data = series[0].values.map((_, index) => {
      // now for this index we need to go through each series.
      const row = series.map(s => {
        const count = formatCount(s.values[index]);

        return { count };
      });
      return row;
    });
    return data;
  }

  getAllSources = (series: VisSeries[]): string[] => {
    // Finds all referenced sources from all series and return as a list of unique sources

    const sources: string[] = uniq(series.flatMap(s => s.components ? s.components.flatMap(
      component => component.map(componentValue => componentValue.source)
    ) : []));

    return sources;
  }

  render() {
    const { content } = this.props;

    const series: VisSeries[] = content.data.series || [content.data];
    const sources = this.getAllSources(series);

    const data = (sources.length > 0 ?
      this.initializeTableDataFromSeriesUsingComponents(series, sources) :
      this.initializeTableDataFromSeriesUsingValues(series));

    const headerSpan = (sources.length > 0 ? sources.length * series.length + 1 : 2);

    return (
      <Table className="answers__visualization-table" sortable={true} celled={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={headerSpan}>
              {content.data.yLabel}
            </Table.HeaderCell>
          </Table.Row>
          {series.length > 1 && <Table.Row>
            <Table.HeaderCell />
            {series.map((s, index) => {
              return (
                <Table.HeaderCell key={index} colSpan={sources.length} className="visualization-table__series-cell">
                  {s.name}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>}

          <Table.Row>
            <Table.HeaderCell>
              {content.data.xLabel}
            </Table.HeaderCell>
            {sources.length > 0 ? series.map(() => sources.map(source =>
              <Table.HeaderCell key={source}>
                {source}
              </Table.HeaderCell>
            )) : (<Table.HeaderCell>Value</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {content.data.labels.map((label, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  {label}
                </Table.Cell>
                {data[index].map((d, dIndex) =>
                  <Table.Cell className="visualization-table__data-cell" key={`${ index }-${ dIndex }`}>
                    {d.volume !== undefined && <span className="visualization-table__volume">{d.volume}</span>}
                    <span className="visualization-table__count">{d.count}</span>
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}
