<template>
  <widget-wrapper
    :aria-label="subtitle"
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    class="widget-width-2"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        <span class="key-text">
          <key-takeaways-icon icon="key-takeaways" />
          {{ subtitle }}
        </span>
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <ul class="takeaways-list">
        <li
          v-for="(takeaway, index) in getTakeaways()"
          :key="index"
        >
          <key-takeaways-icon
            :icon="takeaway.icon"
          />
          <!-- eslint-disable vue/no-v-html -->
          <p v-html="takeaway.text" />
          <!--eslint-enable-->
        </li>
      </ul>
      <div
        v-if="empty"
        class="empty-change-list"
      >
        No changes found
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { get } from 'lodash';

import toFixed from 'vue/libs/to-fixed';

import WidgetWrapper from './WidgetWrapper';
import KeyTakeawaysIcon from 'vue/explore-detail/KeyTakeawaysIcon.vue';
import colors from 'vue/styles/element-variables.scss';

const DashboardTakeawayType = {
  SCORE_CHANGE: 'SCORE_CHANGE',
  THEMES_NEGATIVE_TO_SCORE: 'THEMES_NEGATIVE_TO_SCORE',
  THEMES_POSITIVE_TO_SCORE: 'THEMES_POSITIVE_TO_SCORE',
  EMERGING_THEMES: 'EMERGING_THEMES',
  RISING_THEMES: 'RISING_THEMES',
  FALLING_THEMES: 'FALLING_THEMES',
};

export default {
  name: 'KEY_TAKEAWAYS',
  components: {
    WidgetWrapper,
    KeyTakeawaysIcon
  },
  props: {
    config: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    empty() {
      return !this.takeaways || this.takeaways.length === 0;
    },
    currentPeriodName() {
      return get(this, 'data.currentPeriodName');
    },
    subtitle() {
      let title = 'Key Takeaways';
      if (this.currentPeriodName) {
        title += ` for ${this.currentPeriodName}`;
      }
      return title;
    },
    takeaways() {
      return get(this, 'data.takeaways');
    },
  },
  methods: {
    getTakeaways() {
      return (this.takeaways || []).map((takeaway) => {
        const themes =
          Array.isArray(takeaway.value) && `${takeaway.value.map((value) => `<b>${value}</b>`).join(' and ')}`;

        switch (takeaway.type) {
          case DashboardTakeawayType.SCORE_CHANGE:
            return this.formatScoreChange(takeaway);

          case DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE:
          case DashboardTakeawayType.THEMES_POSITIVE_TO_SCORE:
            return this.formatThemeChangeToScore(takeaway);

          case DashboardTakeawayType.EMERGING_THEMES:
            return {
              icon: 'themes-emerging',
              text: `New emerging themes this period are <b>${themes}</b>`,
            };

          case DashboardTakeawayType.RISING_THEMES:
            return {
              icon: 'themes-up-neutral',
              text: `There are now more people who talk about <b>${themes}</b>`,
            };

          case DashboardTakeawayType.FALLING_THEMES:
            return {
              icon: 'themes-down-neutral',
              text: `Less people now talk about <b>${themes}</b>`,
            };

          default:
            return {
              icon: 'themes',
              text: takeaway.string,
            };
        }
      });
    },
    formatScoreChange(takeaway) {
      const { movement, movementIsSlight, pointsNoun, scoreName } = takeaway.value;
      const absMovement = toFixed(Math.abs(movement), 1);
      const score = toFixed(takeaway.value.score, 1);

      switch (true) {
        case movement < 0:
          return {
            icon: 'score-change-down',
            text: `<b>${scoreName}</b> ${
              movementIsSlight ? 'decreased slightly by' : 'decreased'
            } <b>${absMovement}</b> ${pointsNoun} to <b>${score}</b>`,
          };

        case movement > 0:
          return {
            icon: 'score-change-up',
            text: `<b>${scoreName}</b> ${
              movementIsSlight ? 'increased slightly by' : 'increased'
            } <b>${absMovement}</b> ${pointsNoun} to <b>${score}</b>`,
          };

        default:
          return {
            icon: 'score-change-no-change',
            text: `<b>${scoreName}</b> did not change, and is still <b>${score}</b>`,
          };
      }
    },
    formatThemeChangeToScore(takeaway) {
      const { volumeChange, scoreChange, themes } = takeaway.value;

      const icon =
        takeaway.type === DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE ? 'themes-down-neutral' : 'themes-up-neutral';

      let peopleCount = '';
      let sentiment = '';
      let impact = '';

      if (volumeChange === 'INCREASED' || volumeChange === 'NOCHANGE') {
        peopleCount = 'more ';
        switch (scoreChange) {
          case 'INCREASED':
            sentiment = ' more positively';
            break;

          case 'DECREASED':
            sentiment = ' more negatively';
            break;

          default:
            impact =
              takeaway.type === DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE
                ? 'which hurt the score'
                : 'which improved the score';
        }
      }

      if (volumeChange === 'DECREASED') {
        peopleCount = 'less ';
        switch (scoreChange) {
          case 'INCREASED':
            impact = ', but they were overall more positive';
            break;

          case 'DECREASED':
            impact = ', but they were overall more negative';
            break;

          default:
            impact =
              takeaway.type === DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE
                ? 'which negatively impacted the score'
                : 'which positively impacted the score';
        }
      }

      if (volumeChange === 'NOCHANGE') {
        peopleCount = '';
      }

      const text = `${peopleCount}people talked${sentiment} about ${themes.map((theme) => `<b>${theme}</b>`).join(' and ')}</b> ${impact}`;

      return { icon, text };
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 3.57rem;
  margin-right: 0.5em;
}

.key-text {
  display: flex;
  align-items: center;
}

ul.takeaways-list {
  list-style: none;
  width: 100%;
  margin: unset;
  padding: unset;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(20em, 35em));
  gap: 1rem;

  li {
    flex: 1 0 60vmin;

    display: flex;
    align-items: center;

    p {
      font-size: 1.1rem;
      margin: 0;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>
