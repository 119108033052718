import { getSelectedThemeItems } from 'lib/get-selected-theme-items';
import { compareThemes } from 'lib/segments-to-ordered-themes';
import { isSpecialTheme } from 'lib/special-theme-predicates';
import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { CommentThemeItem, Summary, SummarySegment } from 'types/custom';

const getAllReferences = (summary: Summary) => {
  return summary.segments.reduce<number[]>((indexes, item) => {
    item.conversationReferences.forEach((reference) => {
      if (indexes.length < 1 || !indexes.includes(reference.index)) {
        indexes.push(reference.index);
      }
    });
    return indexes;
  }, []);
};

const getThemeItems = (segments: SummarySegment[], selectedSegment: SummarySegment | null) => {
  if (!selectedSegment) {
    return [];
  }

  return getSelectedThemeItems(segments, selectedSegment).filter((item) => !isSpecialTheme(item.theme));
};

const getSelectableSegments = (summary: Summary) => {
  return summary.segments.filter(segment => {
    return segment.themes.some(theme => !isSpecialTheme(theme));
  });
};

const getSortedThemeItems = (themeItems: CommentThemeItem[], themeCodes: ThemeCodes | null):CommentThemeItem[] => {
  if (!themeCodes) {
    return themeItems;
  }

  const compare = compareThemes(themeCodes);

  return themeItems.sort((a, b) => compare(a.theme, b.theme));
};

// Produces a list of all CommentThemeItems related to a summary
// - items with "special" themes are excluded
// - items are unique
// - items that relate to selected segments are highlighted
// - items are ordered (first by relation to the underlying selected themecodes, then by title)
const getRefinedThemeItems = (
  selectedSegments: SummarySegment[],
  allSegments: SummarySegment[],
  initialSelectedThemeCodes: ThemeCodes | null,
  hoveredSegment: SummarySegment | null
): CommentThemeItem[] => {
  const selectedThemeItems: CommentThemeItem[] = selectedSegments
    .flatMap(segment => getSelectedThemeItems(allSegments, segment))
    .filter(item => !isSpecialTheme(item.theme));

  const themeItemsWithUpdatedHighlightState: CommentThemeItem[] = selectedThemeItems
    .map(item => ({
      ...item,
      isHighlighted: hoveredSegment ? hoveredSegment.themes.some(theme => theme.title === item.theme.title) : false
    }));

  const selectedThemeItemMap = new Map(themeItemsWithUpdatedHighlightState.map(item => [item.theme.title, item]));
  const uniqueSelectedThemeItems = [...selectedThemeItemMap.values()];
  return getSortedThemeItems(uniqueSelectedThemeItems, initialSelectedThemeCodes);
};

export { getAllReferences, getThemeItems, getSelectableSegments, getRefinedThemeItems }
