import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Reference } from 'api/interfaces';
import classNames from 'classnames';
import * as React from 'react';
import './quotes-list.scss';
import getActorsClassNames from 'lib/get-actors-class-names';

interface Props {
  references: Reference[];
}

export const QuotesList = ({ references }: Props) => {

  const actorsClassNames = getActorsClassNames(references);

  return (
    <ul className="quotes-list">
      {references.map((reference, referenceIndex) => {

        const trimmedQuotes = reference.excerpts.map(str => str.trim());
        const quotes = "…" + trimmedQuotes.join("…") + "…";

        return (
          <li key={referenceIndex} className="quotes__item">
            <span className="quotes__author">
              <span
                className={classNames('quotes__author-icon', {
                  [
                    actorsClassNames[reference.author]
                  ]: actorsClassNames[reference.author],
                })}
              >
                <FontAwesomeIcon icon="user" />
              </span>
              {reference.author}
            </span>
            <span className="quotes__text">{quotes}</span>
          </li>
        )
      })}
    </ul>
  );
};

