import classNames from 'classnames';
import * as React from 'react';
import { Error } from 'components/Shared/Error';
import AnswersErrorIcon from 'images/icons/answers-error.svg';
import './answers-error.scss';

interface AnswersProps {
  children: React.ReactElement;
  className?: string;
  tracking?: {
    event: string,
    eventOptions: Record<string, string>
  }
}

export const AnswersError = ({ children, className, tracking }: AnswersProps) => {
  return (
    <div className={classNames('answers-error', className)}>
      <Error
        title='An unknown error occurred'
        icon={
          <AnswersErrorIcon />
        }
        tracking={tracking}
      >
        {children}
      </Error>
    </div>
  );
};
