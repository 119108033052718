import { getQuickEditUIStore } from 'stores/RootStore';
import { AddedTheme, IntersectedTheme } from 'stores/ui/QuickEditUIStore';
import { PhraseSegmentTheme } from 'types/custom';
import { isEqual } from 'lodash';
import { Block } from 'lib/segments-to-blocks';

export function createThemeTitle(theme: IntersectedTheme): string {
  if (theme.sub || theme.subTitle) {
    return `${theme.baseTitle}: ${theme.subTitle}`;
  }
  return theme.baseTitle;
}

export function isCommentEdited(commentId: string): boolean {
  const quickEditUIStore = getQuickEditUIStore();
  return commentId in quickEditUIStore.editedComments;
}

export function themesAdded(commentId: string): Array<AddedTheme> {
  const quickEditUIStore = getQuickEditUIStore();
  return isCommentEdited(commentId)
    ? quickEditUIStore.editedComments[commentId].themesAdded
    : [];
}

export function themesRemoved(commentId: string): Array<PhraseSegmentTheme> {
  const quickEditUIStore = getQuickEditUIStore();
  return isCommentEdited(commentId)
    ? quickEditUIStore.editedComments[commentId].themesRemoved
    : [];
}

export function themesRemovedFromPopup(commentId: string): Array<{
  theme: PhraseSegmentTheme
  location: [start: number, end: number]
}> {
  const quickEditUIStore = getQuickEditUIStore();
  return isCommentEdited(commentId)
    ? quickEditUIStore.editedComments[commentId].themesRemovedFromPopup
    : [];
}

export function isNewlyAddedTheme(commentId: string, theme: PhraseSegmentTheme): boolean {

  const isAdded = themesAdded(commentId).some(value => createThemeTitle(value) === theme.title);
  const isRemoved = themesRemoved(commentId).some(value => value.title === theme.title);

  return isAdded && !isRemoved;

}

export function isThemeRemoved(
  commentId: string,
  theme: PhraseSegmentTheme,
  themeLocations: Array<[start: number, end: number]>
): boolean {

  const isRemoved = themesRemoved(commentId).some(val => val.title === theme.title);

  const isEveryLocationRemovedFromPopup = themeLocations.every(loc => {

    return themesRemovedFromPopup(commentId).some(pair => {
      const titleMatches = pair.theme.title === theme.title;

      const locMatches = isEqual(pair.location, loc);
      return titleMatches && locMatches;
    });

  });

  return isRemoved || isEveryLocationRemovedFromPopup;

}

export function isThemeRemovedInPopup(
  commentId: string,
  theme: PhraseSegmentTheme,
  block: Block
): boolean {

  const isRemoved = themesRemoved(commentId).some(val => val.title === theme.title);

  const isRemovedFromPopup = themesRemovedFromPopup(commentId).some(pair => {
    const titleMatches = pair.theme.title === theme.title;
    const blockLocation = 'location' in block ? block.location : [];

    const locMatches = isEqual(pair.location, blockLocation);

    return titleMatches && locMatches;
  });

  return isRemoved || isRemovedFromPopup;
}

export function doesThemeAlreadyExist(
  commentId: string,
  theme: IntersectedTheme,
  themes: Array<PhraseSegmentTheme>
): boolean {
  const title = createThemeTitle(theme);
  const hasTheme = themes.some(value => value.title === title);
  const hasThemeBeenRemoved = themesRemoved(commentId).some(value => value.title === title);
  return hasTheme && !hasThemeBeenRemoved;
}
