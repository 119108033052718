import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceDiscordCreateFormProps {
  existingConfiguration?: DiscordConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface DiscordInfo {
  id: string;
  name: string;
}

interface DiscordConfig {
  channelIds: string[];
}

export interface DataSourceDiscordCreateFormState {
  selectedDiscordChannel?: DiscordInfo;
  config?: DiscordConfig;
}

@observer
class DataSourceDiscordCreateForm extends React.Component<
DataSourceDiscordCreateFormProps,
DataSourceDiscordCreateFormState
> {
  state = {} as DataSourceDiscordCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedDiscordChannel = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedDiscordChannel = JSON.parse(value);

    const config = {
        channelIds: [selectedDiscordChannel.id]
    };

    this.setState({ selectedDiscordChannel, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedDiscordChannel.id);
    }
  }

  setDefaultDiscordChannel = (availableDiscordChannels: DiscordInfo[]): void => {
    const { selectedDiscordChannel } = this.state;
    const { existingConfiguration } = this.props;

    if (availableDiscordChannels.length === 0 || !existingConfiguration || selectedDiscordChannel) {
      return;
    }

    const defaultSelectedDiscordChannel = this.getExistingDiscordChannel(availableDiscordChannels,
    existingConfiguration);
    if (!defaultSelectedDiscordChannel) {
      return;
    }

    this.setState({
      selectedDiscordChannel: defaultSelectedDiscordChannel,
      config: existingConfiguration,
    });
  }

  getExistingDiscordChannel = (
    availableDiscordChannels: DiscordInfo[],
    existingConfiguration?: DiscordConfig
  ) => {
    return availableDiscordChannels.find(({ id }) => existingConfiguration?.channelIds.includes(id));
  }

  renderForm(availableDiscordChannels: DiscordInfo[] | undefined): JSX.Element | null {
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableDiscordChannelOptions = map(availableDiscordChannels, item => {
      return {
        text: `[${item.id}] ${item.name}`,
        value: JSON.stringify(item)
      };
    });

    if (availableDiscordChannels) {
      const defaultValue = existingConfiguration
        ? JSON.stringify(this.getExistingDiscordChannel(availableDiscordChannels, existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Discord Channel</label>

            <Dropdown
              selection={true}
              options={availableDiscordChannelOptions}
              defaultValue={defaultValue}
              placeholder="Select"
              onChange={this.changeSelectedDiscordChannel}
            />
          </Form.Field>
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={(availableDiscordChannels: DiscordInfo[]) =>
          this.setDefaultDiscordChannel(availableDiscordChannels)}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.DISCORD} />
      </div>
    );
  }
}

export default DataSourceDiscordCreateForm;
