import * as React from 'react';
import './theme-multi-actions.scss';
import { Button } from 'components/Shared/Button';
import { Button as SemanticButton } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeSelectDropdown } from './ThemeSelectDropdown';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { ThemeCardInterface } from 'components/ThemeTree/ThemeTree';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

interface Props {
  groupId: string;
  multipleSelectItems: ThemeCardInterface[];
  onClear: () => void;
  onDelete: () => void;
  onMergeSelect: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
  onMoveSelect: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
}
const ThemeMultiActions = ({ groupId, multipleSelectItems, onClear, onDelete, onMergeSelect, onMoveSelect }: Props) => {
  const [displayMergeMenu, setDisplayMergeMenu] = React.useState(false);
  const [displayMoveMenu, setDisplayMoveMenu] = React.useState(false);
  const selectCount = multipleSelectItems.length;
  const mergeMenuRef = React.useRef<HTMLDivElement | null>(null);
  const moveMenuRef = React.useRef<HTMLDivElement | null>(null);

  const handleMergeMenuClose = () => {
    setDisplayMergeMenu(false);
  };

  const handleMergeMenuOpen = () => {
    setDisplayMergeMenu(true);
  };

  const handleMoveMenuClose = () => {
    setDisplayMoveMenu(false);
  };

  const handleMoveMenuOpen = () => {
    setDisplayMoveMenu(true);
  };

  useOnClickOutside(mergeMenuRef, handleMergeMenuClose);
  useOnClickOutside(moveMenuRef, handleMoveMenuClose);

  return (
    <div className="theme-multi-actions">
      <div className="theme-multi-actions__content">
        <div className="theme-multi-actions__left">
          <div className="theme-multi-actions__close-button">
            <Button
              icon={<FontAwesomeIcon icon="times" size="sm" />}
              onClick={onClear}
              subvariant="neutral-text"
              variant="tertiary"
            />
          </div>
          <span>{selectCount} selected</span>
        </div>
        <div className="theme-multi-actions__right">
          <div ref={moveMenuRef}>
            <ThemeSelectDropdown
              direction="left"
              groupId={groupId}
              onThemeChange={onMoveSelect}
              hideSubThemes={true}
              multipleSelectItems={multipleSelectItems}
              onClose={handleMoveMenuClose}
              onOpen={handleMoveMenuOpen}
              open={displayMoveMenu}
              trigger={
                <SemanticButton className="theme-multi-actions__button" size="small">
                  Move to
                  <FontAwesomeIcon className="icon" icon="chevron-down" />
                </SemanticButton>
              }
            />
          </div>
          <div ref={mergeMenuRef}>
            <ThemeSelectDropdown
              direction="left"
              groupId={groupId}
              multipleSelectItems={multipleSelectItems}
              onThemeChange={onMergeSelect}
              onClose={handleMergeMenuClose}
              onOpen={handleMergeMenuOpen}
              open={displayMergeMenu}
              trigger={
                <SemanticButton className="theme-multi-actions__button" size="small">
                  Merge with
                  <FontAwesomeIcon className="icon" icon="chevron-down" />
                </SemanticButton>
              }
            />
          </div>
          <SemanticButton className="theme-multi-actions__button" onClick={onDelete} size="small">
            <FontAwesomeIcon className="icon" icon="trash" />
            Delete
          </SemanticButton>
        </div>
      </div>
    </div>
  )
};

export { ThemeMultiActions }
