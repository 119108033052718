import * as React from 'react';
import { Answers } from './Main';
import { inject, observer } from 'mobx-react';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { Dimmer, Loader } from 'semantic-ui-react';
import { AnswersError } from './components/AnswersError';

type Props = {
  surveyStore: SurveyStoreInterface
};

@inject('surveyStore')
@observer
class AnswersLoader extends React.Component<Props> {
  render() {
    const { surveyStore } = this.props;

    if (surveyStore.fetchSurveysError) {
      return (
        <section className="answers">
          <AnswersError
            tracking={{
              event: "Error: Answers",
              eventOptions: {
                "title": "An unknown error occurred",
                "description": surveyStore.fetchSurveysError
              }
            }}
          >
            <p>{surveyStore.fetchSurveysError}</p>
          </AnswersError>
        </section>
      );
    }

    if (surveyStore.fetchingSurveys) {
      return (
        <Dimmer active inverted>
          <Loader size="large" content="Loading surveys..."/>
        </Dimmer>
      )
    }
    return <Answers/>;
  }
}

export { AnswersLoader };
