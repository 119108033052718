import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import auth from 'Auth/Auth';
import ThemeEditorLink from 'components/ThemeEditor/ThemeEditorLink';
import OrgConfigureIcon from 'images/icons/org-configure.svg';
import OrgSearchIcon from 'images/icons/org-search.svg';
import { get, isEmpty, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AlternativeRegion } from 'runtime-config';
import { Dropdown, Image } from 'semantic-ui-react';
import { DashboardStoreInterface } from 'stores/DashboardStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { OrganizationsStoreInterface } from 'stores/OrganizationsStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { UserStoreInterface } from 'stores/UserStore';

interface UserMenuStoreProps {
  userStore?: UserStoreInterface;
  surveyStore?: SurveyStoreInterface;
  organizationStore?: OrganizationStoreInterface;
  organizationsStore?: OrganizationsStoreInterface;
  dashboardStore?: DashboardStoreInterface;
}
export interface UserMenuProps extends UserMenuStoreProps {
  adminLocation?: string;
  orgId: string;
  userIcon?: string;
  alternativeRegions: AlternativeRegion[];
}

@inject('userStore', 'surveyStore', 'organizationStore', 'organizationsStore', 'dashboardStore')
@observer
export default class UserMenu extends React.Component<UserMenuProps> {
  adminUrl(orgId: string): string {
    const { adminLocation } = this.props;
    const tokens = [] as string[];
    if (adminLocation) {
      tokens.push(adminLocation);
    }
    tokens.push(`/admin/#!/c/${ orgId }/thematic_admin/${ orgId }`);

    return tokens.join('');
  }
  regionUrl(regionUrl: string): string {
    const currentToken = localStorage.getItem('access_token');
    return `${ regionUrl }/#/?sso_token=${ currentToken }`;
  }
  stopAssuming = () => {
    auth.stopAssumingUser();
  };
  logout = () => {
    analytics.track('logout', { action: 'clicked Logout in UserMenu' });
    auth.logout();
  };
  homeUrl = () => {
    const { orgId } = this.props;
    return orgId ? `/c/${ orgId }` : `/`;
  };
  profileUrl = () => {
    const { orgId } = this.props;
    return orgId ? `/c/${ orgId }/profile` : `/profile`;
  };
  render() {
    const {
      orgId,
      userIcon,
      alternativeRegions
    } = this.props;
    const { surveyStore, organizationStore, organizationsStore, dashboardStore, userStore } = this.props;
    const { getSurveysThatCanAction } = surveyStore!;
    const { orgName, orgExists, getCanAction, billingAccount } = organizationStore!;
    const { organizations } = organizationsStore!;
    const hasSurveys = !isEmpty(surveyStore!.surveys);
    const hasManageableSurveys = (!isEmpty(getSurveysThatCanAction('create:upload'))
      || !isEmpty(getSurveysThatCanAction('manage:survey')));
    const canUploadInOrg = getCanAction('manage:survey') || getCanAction('create:upload');
    const canManageRoles = getCanAction('manage:role');
    const canManageUsers = getCanAction('manage:user');
    const canSeeIntegrations = getCanAction('manage:integration');
    const canThematicAdmin = get(userStore!, 'user.currentUserCan', []).includes('manage:internalResource');
    const canThematicAdminOrg = getCanAction('manage:internalResource');
    const canManageSubscriptions = orgExists && dashboardStore!.canManageSubscriptions;
    const canSwitchOrganizations = (organizations || []).length > 1;
    const canViewDataMetrics = organizations.some(
      o => o.currentUserCan.includes('read:metrics') && o.billingAccount?.id === billingAccount?.id);
    const canViewBillingAndUsage = canViewDataMetrics && billingAccount;

    const canManageActions = getCanAction('manage:workflow');

    const canManageAnswers = getCanAction('manage:answer');

    // check if there are any surveys the current user can edit themes or upload data for
    const canThemeEditor = orgExists && !isEmpty(getSurveysThatCanAction('manage:themes'));
    const canUpload = orgExists && (hasManageableSurveys || (!hasSurveys && canUploadInOrg));
    const hasAdminLinks = canUpload || canThemeEditor;

    // check if there is an assumed user
    const assumingUser = auth.assumedUser();

    const regionSwitcherElements = map(alternativeRegions, region => {
      const { name, url } = region;
      return (
        <Dropdown.Item
          key={name}
          as="a"
          href={this.regionUrl(url)}
          target="_blank"
        >
          <OrgConfigureIcon className="icon" style={{ height: '1em' }} />
          <span>Open {name} Server</span>
        </Dropdown.Item>
      );
    });

    return (
      <Dropdown
        item={true}
        icon={
          <div className="icon-container">
            {userIcon && (
              <Image bordered={true} circular={true} src={userIcon} />
            )}
            <FontAwesomeIcon
              className="icon"
              icon="angle-down"
              fixedWidth={true}
            />
          </div>
        }
        className="normal-size user-dropdown"
        aria-label="User menu"
      >
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={this.homeUrl()} key="home">
            <FontAwesomeIcon className="icon" icon="home" fixedWidth={true} />
            Home
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={this.profileUrl()} key="profile">
            <FontAwesomeIcon className="icon" icon="user" fixedWidth={true} />
            Profile
          </Dropdown.Item>
          {hasAdminLinks && <Dropdown.Divider />}
          {canUpload && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/upload`}>
              <FontAwesomeIcon
                className="icon"
                icon="cloud-upload"
                fixedWidth={true}
              />
              Manage data
            </Dropdown.Item>
          )}
          {canManageAnswers && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/audit-answers`}>
              <FontAwesomeIcon
                className="icon"
                icon="lightbulb"
                fixedWidth={true}
              />
              Audit answers
            </Dropdown.Item>
          )}
          {canThemeEditor && (
            <ThemeEditorLink />
          )}
          {canManageSubscriptions && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/subscriptions`}>
              <FontAwesomeIcon
                className="icon"
                icon="envelope"
                fixedWidth={true}
              />
              Manage subscriptions
            </Dropdown.Item>
          )}
          {canManageActions && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/workflows`}>
              <FontAwesomeIcon
                className="icon"
                icon="bolt"
                fixedWidth={true}
              />
              Manage workflows
            </Dropdown.Item>
          )}
          {canSeeIntegrations && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/integrations`}>
              <FontAwesomeIcon className="icon" icon="plug" fixedWidth={true} />
              Manage integrations
            </Dropdown.Item>
          )}
          {canManageUsers && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/users`}>
              <FontAwesomeIcon
                className="icon"
                icon="users"
                fixedWidth={true}
              />
              Manage users
            </Dropdown.Item>
          )}
          {canManageRoles && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/roles`}>
              <FontAwesomeIcon className="icon" icon="users" fixedWidth={true} />
              Manage roles
            </Dropdown.Item>
          )}
          {canThematicAdminOrg && (
            <Dropdown.Item as="a" href={this.adminUrl(orgId)} target="_blank" rel="noopener noreferrer">
              <OrgConfigureIcon className="icon" style={{ height: '1em' }} />
              <span>Configure {orgName}</span>
            </Dropdown.Item>
          )}
          {(canThematicAdminOrg && orgId) && (
            <Dropdown.Item as={Link} to={`/c/${ orgId }/thematic-admin?view=local`}>
              <FontAwesomeIcon
                className="icon"
                icon="users"
                fixedWidth={true}
              />
              <span>Manage Thematic access</span>
            </Dropdown.Item>
          )}
          {(canThematicAdmin && !orgId) && (
            <Dropdown.Item as={Link} to={`/thematic-admin?view=global`}>
              <FontAwesomeIcon
                className="icon"
                icon="users"
                fixedWidth={true}
              />
              <span>Manage Thematic access</span>
            </Dropdown.Item>
          )}
          {canSwitchOrganizations && [
            <Dropdown.Divider key="view-all-divider" />,

            <Dropdown.Item as={Link} to="/" key="view-all">
              <OrgSearchIcon className="icon" style={{ height: '1em' }} />
              <span>View all workspaces</span>
            </Dropdown.Item>
          ]}
          {canViewBillingAndUsage &&
            <Dropdown.Item as={Link} to={`/c/${ orgId }/billing-usage`}>
              <FontAwesomeIcon
                className="icon"
                icon="chart-line"
                fixedWidth={true}
              />
              View plan usage
            </Dropdown.Item>
          }
          {canThematicAdminOrg && regionSwitcherElements}
          <Dropdown.Divider />
          {assumingUser && (<Dropdown.Item onClick={this.stopAssuming}>
            <FontAwesomeIcon
              className="icon"
              icon="sign-out"
              fixedWidth={true}
            />
            Stop Assuming {assumingUser}
          </Dropdown.Item>)}
          <Dropdown.Item onClick={this.logout}>
            <FontAwesomeIcon
              className="icon"
              icon="sign-out"
              fixedWidth={true}
            />
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
