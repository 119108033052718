import { Block } from './segments-to-blocks';

export default function reduceBlocksToHighlighted(blocks: Array<Block>, segmentsAroundHighlightedSentences: number) {
  return blocks.reduce((result, block, index) => {
    const shouldBeRetained = blocks.slice(
      Math.max(index - segmentsAroundHighlightedSentences, 0),
      index + segmentsAroundHighlightedSentences + 1
    ).some(b => b.isHighlighted);

    if (!shouldBeRetained) {
      block.content = '...';
      if (index > 0 && blocks[index - 1].content === '...') {
        return result;
      }
    }
    result.push(block);

    return result;
  }, [] as Array<Block>);
}
