<template>
  <div
    class="over-time"
  >
    <score-graph
      class="over-time__graph"
      :selections="filteredSelection"
      :hide-selected-state="customDateRangeSelected"
      @onDateSelected="onDateSelected"
      @selectedScoreChanged="selectedScoreChanged"
    />

    <div
      class="over-time__waterfall"
    >
      <filter-date-range-picker
        range-prefix="Theme changes between"
        :date-config="dateFilterConfig"
        :current-date-filter="dateFilter"
        :previous-date-filter="previousDateFilter"
        @onCurrentDateFilterChange="onCurrentDateFilterChange"
        @onPreviousDateFilterChange="onPreviousDateFilterChange"
      />

      <section
        v-if="canSeeSummarization"
        class="score-change-card"
      >
        <score-change-summary
          :current-period="dateFilter"
          :previous-period="previousDateFilter"
        />
      </section>

      <theme-waterfall
        v-if="dateFilter && previousDateFilter"
        :no-data-error="noComparisonDataError"
        :error="comparisonError"
        :loading="isFetchingComparison"
        :selected-theme="selectedTheme"
        :comparison-data="comparisonData"
        :current-period="dateFilter"
        :previous-period="previousDateFilter"
        :selected-score="selectedScore"
        :compare-to="compareTo"
        @onCompareToChange="handleCompareToChange"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

import analytics from 'lib/analytics';
import queryBuilder from 'vue/libs/queryBuilder';

import { getAnalysisToolsUIStore, getFilterStore, getInitConfigStore } from 'stores/RootStore';
import FilterDateRangePicker from 'vue/components/Filters/FilterDateRangePicker.vue';
import ScoreGraph from 'vue/components/Tiles/ScoreGraph.vue';
import ThemeWaterfall from 'vue/over-time/ThemeWaterfall';
import ScoreChangeSummary from 'vue/over-time/ScoreChangeSummary';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { setupRequestOptions } from 'vue/libs/setup-request-options';
import { getRequestConfig } from 'vue/libs/get-request-config';
import { totalCountFromCountsObject } from 'vue/libs/counts';
import thematicData from 'vue/libs/thematicData';

function lastyear(rangeString) {
  const year = parseInt(rangeString.slice(0,4));
  return `${year-1}${rangeString.slice(4)}`;
}

export default {
  name: 'OverTime',
  components: {
    ScoreChangeSummary,
    FilterDateRangePicker,
    ScoreGraph,
    ThemeWaterfall
  },
  data() {
    const initConfigStore = getInitConfigStore();
    return {
      compareTo: 'previousPeriod',
      isFetchingComparison: false,
      comparisonError: null,
      noComparisonDataError: false,
      previousComparisonRequest: null,
      comparisonData: null,
      counts: null,
      filterStore: getFilterStore(),
      dateExplicitlySet: false,
      dateFilter: undefined,
      dateRql: undefined,
      previousRql: undefined,
      filteredSelection: undefined,
      previousDateFilter: undefined,
      scoreGraphRql: undefined,
      previousScoreGraphRql: undefined,
      selectedScore: initConfigStore.scores?.[0],
      commentsExtraFilter: undefined,
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore,
    };
  },
  computed: {
    canSeeSummarization() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_SCORE_CHANGE_SUMMARIZATION);
    },
    IdsFromColumn() {
      return this.initConfigStore.IdsFromColumn;
    },
    stateConfig() {
      return this.initConfigStore.config;
    },
    themeFilterSet() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    selections() {
      return this.filterStore.selections;
    },
    dateColumn() {
      const defaultColumn = get(this, 'stateConfig.date_column', null);
      return get(this, 'config.date_column', defaultColumn);
    },
    dateResolution() {
      const defaultResolution = get(this, 'stateConfig.date_resolution', null);
      return get(this, 'config.date_resolution', defaultResolution);
    },
    dateRange() {
      const defaultDateRange = get(this, 'stateConfig.date_range', []);
      return get(this, 'config.date_range', defaultDateRange);
    },
    dateFilterConfig() {
      const { dateColumn: column, dateRange: range, dateResolution: resolution } = this;
      return { column, range, resolution };
    },
    customDateRangeSelected() {
      const { dateRql, previousRql, scoreGraphRql, previousScoreGraphRql } = this;
      const haveValue = !!dateRql && !!previousRql && !!scoreGraphRql && !!previousScoreGraphRql;

      return haveValue && (dateRql !== scoreGraphRql || previousRql !== previousScoreGraphRql);
    },
    commentsSelection() {
      const { dateFilter, dateRql: query, commentsExtraFilter, filteredSelection } = this;
      // a more specific filter is selected
      if (commentsExtraFilter) {
        return commentsExtraFilter;
      }

      const commentsSelection = {
        subTitle: get(dateFilter, 'label'),
        selections: {
          baseline: {
            title: get(filteredSelection, 'baseline.title'),
            query: queryBuilder.appendToFilter(get(filteredSelection, 'baseline.query'), query),
            count: get(this, 'counts.baseline.count', 0),
            sentimentCounts: get(this, 'counts.baseline.sentimentCounts', { pos: 0, neut:0, neg: 0})
          },
          comparison: {
            query: queryBuilder.appendToFilter(get(filteredSelection, 'baseline.query'), query), // to show it is the same as baseline
          }
        }
      };
      return commentsSelection;
    },
    filterTitle() {
      return get(this, 'extraFilter.title');
    },
    selectedSubtheme() {
      return get(this, 'themeFilterSet.subtheme.name', null);
    },
    selectedTheme() {
      return get(this, 'themeFilterSet.theme.name', null);
    },
    isPeriodValid() {
      const { dateFilter, previousDateFilter } = this;

      return dateFilter && previousDateFilter && dateFilter.period && previousDateFilter.period;
    }
  },
  watch: {
    selections: {
      handler() {
        const dateColumnIds = this.IdsFromColumn(this.dateColumn) || [];

        const applyIgnoreToFilters = this.filterStore.applyIgnoreToFilters;
        const baseline = applyIgnoreToFilters('baseline', ...dateColumnIds);

        this.filteredSelection = { baseline, comparison: baseline };
        this.fetchWaterfallData();
      },
      immediate: true
    },
    commentsSelection() {
      this.analysisToolsUIStore.setCommentsSelection(this.commentsSelection);
    },
    dateFilter() {
      this.fetchWaterfallData();
    },
    previousDateFilter() {
      this.fetchWaterfallData();
    },
    selectedSubtheme() {
      this.updateSelection();
    },
    selectedTheme() {
      this.updateSelection();
    },
  },
  methods: {
    onDateSelected(
      dateName,
      rql,
      period,
      periodCounts,
      previousDateName,
      previousRql,
      previousPeriod,
      isExplicitSelection
    ) {

      this.counts = periodCounts;

      // ignore implicit selections (i.e. filter changes) if we have already selected something
      if( this.dateExplicitlySet && !isExplicitSelection) {
        return;
      }
      this.dateExplicitlySet = isExplicitSelection;

      // clear the selected theme state when we change dates
      this.analysisToolsUIStore.setThemeState({ theme: null, subtheme: null });

      this.scoreGraphRql = rql;
      this.previousScoreGraphRql = previousRql;

      this.dateRql = rql;
      this.previousRql = previousRql;

      this.dateFilter = {
        label: dateName,
        period,
        rql
      };

      this.previousDateFilter = {
        label: previousDateName,
        period: previousPeriod
      };

      // track that the date changed
      analytics.track('Overtime: Date Selection Changed', { category: 'Overtime', value: dateName });
    },
    handleCompareToChange(compareTo) {
      this.compareTo = compareTo;
      this.fetchWaterfallData();
    },
    handleComparison(data) {
      // we require there to be at least a small amount of data in both time periods
      if (data && totalCountFromCountsObject(data.counts) > 10 && totalCountFromCountsObject(data.previousCounts) > 10) {
        this.comparisonData = data;
      } else {
        this.noComparisonDataError = true;
      }

      this.updateSelection();
    },
    async fetchWaterfallData() {
      if (!this.isPeriodValid) {
        return;
      }

      const { scores, config, requestOptions } = this.initConfigStore;
      const {
        compareTo,
        dateFilter,
        previousDateFilter,
        filteredSelection,
        selectedScore
      } = this;

      this.isFetchingComparison = true;
      this.comparisonError = null;
      this.noComparisonDataError = false;

      const filter = filteredSelection.baseline.query;
      const currentPeriodRange = dateFilter.period;
      const range = currentPeriodRange.split(':');

      const previousPeriodRange = compareTo === 'lastYear'
        ? `${lastyear(range[0])}:${lastyear(range[1])}`
        : previousDateFilter.period;


      const requestConfig = getRequestConfig({
        selectedScore,
        scores,
        analysisConfig: config
      });

      const options = setupRequestOptions(requestOptions, {
        scoreCommentsOnly: true,
        ...requestConfig
      });

      let request = null;
      try {
        request = thematicData.getComparePeriods(
          filter,
          previousPeriodRange,
          currentPeriodRange,
          undefined, // comment columns
          options
        );
        this.previousComparisonRequest = request;
        const data = await request;
        if (request !== this.previousComparisonRequest) {
          return;
        }
        this.handleComparison(data);
      } catch (e) {
        this.comparisonError = e.toString();
      } finally {
        if (request === this.previousComparisonRequest) {
          this.isFetchingComparison = false;
        }
      }
    },
    getSelectedThemeObject() {
      const themes = this.comparisonData?.themes;
      const { selectedSubtheme, selectedTheme } = this;

      if (themes) {
        const theme = themes[selectedTheme];
        if (theme && theme.subthemes && selectedSubtheme) {
          return theme.subthemes[selectedSubtheme];
        } else {
          return theme;
        }
      } else {
        return undefined;
      }
    },
    updateSelection() {
      const { dateRql } = this;

      const selectedThemeObject = this.getSelectedThemeObject();

      if (!selectedThemeObject) {
        this.commentsExtraFilter = undefined;
        return;
      }

      const baseline = this.filteredSelection.baseline;
      const query = queryBuilder.appendToFilter(baseline.query, dateRql);
      const selection = {
        subTitle: this.dateFilter.label,
        selections: {
          baseline: {
            title: baseline.title,
            query,
            count: selectedThemeObject.count,
            sentimentCounts: selectedThemeObject.sentimentCounts
          },
          comparison: {
            query // so recipients know it is the same as baseline
          }
        }
      };
      this.commentsExtraFilter = selection;
    },
    selectedScoreChanged(score) {
      this.selectedScore = score;
      this.fetchWaterfallData();
    },
    onCurrentDateFilterChange(filter, isExplicitSelection) {
      // ignore implicit selections (i.e. filter changes) if we have already selected something
      if( this.dateExplicitlySet && !isExplicitSelection) {
        return;
      }
      this.dateExplicitlySet = isExplicitSelection;

      const period = `${filter.startDate}:${filter.endDate}`;
      if (this.dateFilter && this.dateFilter.period === period) {
        return;
      }
      this.dateFilter = {
        label: filter.name,
        period
      };
      this.dateRql = (filter.rql || '').replace(/[(]|[)]/g, '');
      analytics.track(
        'Overtime: Custom Current Period Selection',
        {
          category: 'Overtime',
          value: filter.name
        }
      );
    },
    onPreviousDateFilterChange(filter, isExplicitSelection) {
      // ignore implicit selections (i.e. filter changes) if we have already selected something
      if( this.dateExplicitlySet && !isExplicitSelection) {
        return;
      }
      this.dateExplicitlySet = isExplicitSelection;

      this.previousDateFilter = {
        label: filter.name,
        period: `${filter.startDate}:${filter.endDate}`
      };
      this.previousRql = (filter.rql || '').replace(/[(]|[)]/g, '');
      analytics.track(
        'Overtime: Custom Previous Period Selection',
        {
          category: 'Overtime',
          value: filter.name
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';

.over-time {
  align-content: flex-start;
  display: contents;

 .filter-date-range-picker {
    padding-top: 25px;
  }
}
.score-change-card {
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(84, 99, 119, 0.3);
}
</style>
