<template>
  <div class="sentiment-widget">
    <sentiment-dial-chart-mini
      :value="value"
    />

    <span class="sentiment-number sentiment-number--positive">{{ positivePercent }}%</span>
    <sentiment
      class="sentiment-face sentiment-face--positive"
      :sentiment="1"
    />
    <div
      class="sentiment-bar sentiment-bar--positive"
      :style="{ width: `${positivePercent * scale}%`}"
    />

    <span class="sentiment-number sentiment-number--neutral">{{ neutralPercent }}%</span>
    <sentiment
      class="sentiment-face sentiment-face--neutral"
      :sentiment="0"
    />
    <div
      class="sentiment-bar sentiment-bar--neutral"
      :style="{ width: `${neutralPercent * scale}%`}"
    />

    <span class="sentiment-number sentiment-number--negative">{{ negativePercent }}%</span>
    <sentiment
      class="sentiment-face sentiment-face--negative"
      :sentiment="-1"
    />
    <div
      class="sentiment-bar sentiment-bar--negative"
      :style="{ width: `${negativePercent * scale}%` }"
    />
  </div>
</template>

<script>
import SentimentDialChartMini from 'vue/components/Charts/SentimentDialChartMini.vue';
import Sentiment from 'vue/components/Sentiment/Sentiment.vue';

export default {
  name: 'OverviewSentimentWidget',
  components: {
    Sentiment,
    SentimentDialChartMini
  },
  props: {
    value: { type: Number },
    pos: { type: Number },
    neut: { type: Number },
    neg: { type: Number }
  },
  computed: {
    scale() {
      const maxPercent = Math.max(
        this.positivePercent,
        this.neutralPercent,
        this.negativePercent
      );
      return 100 / maxPercent;
    },
    total() {
      return this.pos + this.neut + this.neg;
    },
    positivePercent() {
      const percent = this.pos / this.total;
      return Number.isFinite(percent) ? (percent * 100).toFixed(1) : 0;
    },
    neutralPercent() {
      const percent = this.neut / this.total;
      return Number.isFinite(percent) ? (percent * 100).toFixed(1) : 0;
    },
    negativePercent() {
      const percent = this.neg / this.total;
      return Number.isFinite(percent) ? (percent * 100).toFixed(1) : 0;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.sentiment-widget {
  display: grid;

  grid-template: "dial pos-number  pos-face  pos-bar"
                 "dial neut-number neut-face neut-bar"
                 "dial neg-number  neg-face  neg-bar";

  grid-template-columns: min-content min-content min-content 1fr;
  grid-gap: 0.125rem 0.75rem;

}

.dial {
  grid-area: dial;
  top: 5px;
}

.sentiment-number {
  color: $--neutral-900;
  font-size: 0.9rem;
  align-self: center;
  text-align: right;
  &--positive { grid-area: pos-number; }
  &--neutral  { grid-area: neut-number; }
  &--negative { grid-area: neg-number; }
}

.sentiment-face {
  align-self: center;
  &--positive { grid-area: pos-face; }
  &--neutral  { grid-area: neut-face; }
  &--negative { grid-area: neg-face; }
}
.sentiment-bar {
  height: 12px;
  align-self: center;
  &--positive { grid-area: pos-bar; background-color: $--green-400; }
  &--neutral  { grid-area: neut-bar;background-color: $--yellow-500;  }
  &--negative { grid-area: neg-bar; background-color: $--red-500; }
}

</style>
