<template v-if="initConfig">
  <analysis-filters
    :issticky="isSticky"
    :initconfig="initConfig"
    :initialfiltervalues="initialFilterValues"
    :initialcomparevalues="initialCompareValues"
    :enablecomparisonfilter="enableComparisonFilter"
    :surveyid="surveyId"
  />
</template>

<script>
import AnalysisFilters from 'components/Filters/AnalysisFilters.tsx';
import { parse } from 'query-string';
import { getInitConfigStore } from 'stores/RootStore';
import { ReactInVue } from 'vuera';

export default {
  name: 'ThematicFilters',
  components: {
    'analysis-filters': ReactInVue(AnalysisFilters),
  },
  props: {
    isSticky: { default: false, type: Boolean },
    enableComparisonFilter: { default: true, type: Boolean },
    surveyId: { default: undefined, type: String },
  },
  data() {
    return {
      initConfigStore: getInitConfigStore(),
    };
  },
  computed: {
    initConfig() {
      return this.initConfigStore.config;
    },
    initialFilterValues() {
      const filtersQuery = this.$router.currentRoute.query.filters;
      if (filtersQuery) {
        return parse(decodeURIComponent(filtersQuery));
      }
      return {};
    },
    initialCompareValues() {
      const compareQuery = this.$router.currentRoute.query.compare;
      if (compareQuery) {
        return parse(decodeURIComponent(compareQuery));
      }
      return {};
    },
  },
};
</script>
