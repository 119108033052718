import * as React from 'react';
import { calculateSimpleSentiment, ValidSimpleSentiments } from 'lib/calculate-sentiment';
import './sentiment.scss';

const getLabel = (simpleSentiment: ValidSimpleSentiments | undefined) => {
  if (!simpleSentiment) {
    return undefined;
  }

  const sentimentIsValid = Object.values(ValidSimpleSentiments).includes(simpleSentiment);

  return sentimentIsValid ? simpleSentiment : undefined;
}

const isSmile = (label: ValidSimpleSentiments) => {
  return label === ValidSimpleSentiments.smile;
}

const isMeh = (label: ValidSimpleSentiments) => {
  return label === ValidSimpleSentiments.meh;
}

const isFrown = (label: ValidSimpleSentiments) => {
  return label === ValidSimpleSentiments.frown;
}

interface Props {
  sentiment: number;
}

const Sentiment = ({ sentiment }: Props) => {
  const simpleSentiment = calculateSimpleSentiment(sentiment);
  const label = getLabel(simpleSentiment)

  if (!label) {
    return null;
  }

  return (
    <div className={`react-sentiment react-sentiment-${label}`}>
      <svg
        className="react-sentiment__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
      >
        <g fill="currentColor">
          {isSmile(label) && (
            <path
              d="M11.531 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm-5.625 0a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm6.849 5.984c.474-.573-.394-1.29-.865-.72a4.12 4.12 0 0 1-3.171 1.483 4.108 4.108 0 0 1-3.171-1.484c-.478-.57-1.343.148-.865.72a5.238 5.238 0 0 0 4.036 1.892 5.238 5.238 0 0 0 4.036-1.891z"
            />
          )}
          {isMeh(label) && (
            <path
              d="M5.906 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm6.188 6.75c.745 0 .745-1.125 0-1.125h-6.75c-.746 0-.746 1.125 0 1.125h6.75zm-.563-4.5a1.124 1.124 0 1 0 0-2.25 1.124 1.124 0 1 0 0 2.25z"
            />
          )}
          {isFrown(label) && (
            <path
              d="M11.531 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm-5.625 0a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm5.984 7.671c.474.573 1.34-.147.861-.717a5.25 5.25 0 0 0-8.068-.004c-.475.574.39 1.294.865.721a4.125 4.125 0 0 1 3.17-1.483c1.228 0 2.384.541 3.172 1.483z"
            />
          )}
        </g>
      </svg>
    </div>
  );
}

export { Sentiment }
