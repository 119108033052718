import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus } from 'api/enums';
import MagicWand from 'images/icons/magic-wand.svg';
import UploadCompleteIcon from 'images/icons/upload-complete.svg';
import { hasFailedImport } from 'lib/survey-helpers';
import * as React from 'react';
import { Message } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import DataSourceStatusLabel from './DataSourceStatusLabel';
import ImportContainer, { ImportStatus } from './ImportContainer';
import SurveyIncompleteAction from './SurveyIncompleteAction';
import './import-data.scss';

export interface ImportDataProps {
  survey: LiteSurvey;
  showError?: boolean;
  doesNotRequireWaiting?: boolean;
}

class ImportData extends React.Component <ImportDataProps> {
  renderContent = () => {
    const { survey, showError, doesNotRequireWaiting } = this.props;
    const { dataStatus, dataSourceIntegration } = survey;

    const noData = dataStatus === SurveyDataStatus.NODATA;
    const noResults = dataStatus === SurveyDataStatus.NORESULTS;
    const processing = dataStatus === SurveyDataStatus.PROCESSING;

    const importing = noData && !dataSourceIntegration?.lastFailureDate;
    const importFailed = hasFailedImport(survey);

    if (importing) {
      return (
        <ImportContainer
          status={ImportStatus.IN_PROGRESS}
          content={
            <>
              <FontAwesomeIcon
                className="import-data__spinner-icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
                size="2x"
              />
              <p className="import-data__title">Importing data</p>
              <p className="import-data__note">This may take a few minutes</p>
              {doesNotRequireWaiting && (<p className="import-data__note">
                You do not have to wait for this to complete
              </p>)}
            </>
          }
        />
      );
    }

    if (showError && importFailed) {
      const errorMessage =  dataSourceIntegration?.lastFailureReason || 'Something went wrong. Please try again.';

      return (
        <Message
          negative={true}
          header={errorMessage}
        />
      );
    }

    if (!showError && importFailed && noData) {
      // Show continue setup button in upload page if errors on initial import
      return <SurveyIncompleteAction survey={survey} />;
    }

    if (processing) {
      return (
        <ImportContainer
          status={ImportStatus.IN_PROGRESS}
          content={
            <>
              <FontAwesomeIcon
                className="import-data__spinner-icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
                size="2x"
              />
              <p className="import-data__title">Processing comments</p>
              <p className="import-data__note">Your data will be available soon</p>
            </>
          }
        />
      );
    }

    if (noResults) {
      return (
        <ImportContainer
          status={ImportStatus.SUCCESS}
          icon={<UploadCompleteIcon />}
          content={<p>Import complete!</p>}
        />
      );
    }

    // ready
    return (
      <ImportContainer
        status={ImportStatus.DEFAULT}
        icon={<MagicWand/>}
        content={
          <>
            {this.props.survey.dataSourceIntegration && (
              <DataSourceStatusLabel dataSource={this.props.survey.dataSourceIntegration} />
            )}
            <p className="import-data__text">New data is imported automatically</p>
            <p className="import-data__note">
              Last Refreshed: {survey.lastUpdated}&nbsp;&nbsp;
            </p>
          </>
        }
      />
    );
  }

  render() {
    return (
      <div className="import-data">
        {this.renderContent()}
      </div>
    );
  }
}

export default ImportData;
