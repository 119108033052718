






















import ErrorIcon from "images/vue-icons/error.svg";

export default {
  components: {
    ErrorIcon
  },
  props: {
    variant: { type: String },
    buttonText: { type: String },
    title:  { type: String },
    message:  { type: String }
  }
}
