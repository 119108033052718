import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceJotFormCreateFormProps {
  existingConfiguration?: JotFormConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface JotFormInfo {
  name: string;
  id: string;
  status: string;
  created: string;
  responses: number;
}

interface JotFormConfig {
  formId: string;
}

export interface DataSourceJotFormCreateFormState {
  selectedForm?: JotFormInfo;
  config?: JotFormConfig;
}

@observer
class DataSourceJotFormCreateForm extends React.Component<
DataSourceJotFormCreateFormProps,
DataSourceJotFormCreateFormState
> {
  state = {} as DataSourceJotFormCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedForm = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedForm = JSON.parse(value);

    const config = {
      formId: selectedForm.id
    };

    this.setState({ selectedForm, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedForm.id);
    }
  }

  setDefaultForm = (availableForms: JotFormInfo[]): void => {
    const { selectedForm } = this.state;
    const { existingConfiguration } = this.props;

    if (availableForms.length === 0 || !existingConfiguration || selectedForm) {
      return;
    }

    const defaultSelectedForm = this.getExistingSurvey(availableForms, existingConfiguration);

    if (!defaultSelectedForm) {
      return;
    }

    this.setState({
      selectedForm: defaultSelectedForm,
      config: existingConfiguration,
    });
  }

  getExistingSurvey = (
    availableSurveys: JotFormInfo[],
    existingConfiguration?: JotFormConfig
  ) => {
    return availableSurveys.find(({ id }) => id === existingConfiguration?.formId);
  }

  renderForm(availableForms: JotFormInfo[] | undefined): JSX.Element | null {
    const { selectedForm } = this.state;
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableFormsOptions = map(availableForms, item => {
      return {
        text: item.name,
        value: JSON.stringify(item)
      };
    });

    if (availableForms) {
      const defaultValue = existingConfiguration
        ? JSON.stringify(this.getExistingSurvey(availableForms, existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Form</label>

            <Dropdown
              selection={true}
              options={availableFormsOptions}
              defaultValue={defaultValue}
              placeholder="Select"
              onChange={this.changeSelectedForm}
            />
          </Form.Field>
          { selectedForm && (
            <div>
              <Form.Field>
                <label>Title</label> <span>{selectedForm.name}</span>
              </Form.Field>
              <Form.Field>
                <label>Created</label> <span>{selectedForm.created}</span>
              </Form.Field>
              <Form.Field>
                <label>Is Active</label> <span>{selectedForm.status}</span>
              </Form.Field>
              <Form.Field>
                <label>Responses</label> <span>{selectedForm.responses}</span>
              </Form.Field>
            </div>
          )}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={(availableForms: JotFormInfo[]) => this.setDefaultForm(availableForms)}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.JOTFORM} />
      </div>
    );
  }
}

export default DataSourceJotFormCreateForm;
