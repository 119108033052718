import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import * as workerPath from 'file-loader?name=[name].[hash].js!./CommentMatcher.worker';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { ThemeGroup } from 'lib/theme-file-parser';
import { autorun } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Button, Segment } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import { UntaggedExampleComments } from './UntaggedExampleComments';
import './theme-info.scss';
import { getAllCommentsText } from 'lib/comment-helpers';

const EXAMPLE_COMMENTS_SECTION = 'example_comments';
const APPROXIMATE_COVERAGE_SECTION = 'approx_coverage';

export interface UntaggedCommentsProps {
  orgId: string;
  surveyId: string;
  group: ThemeGroup;
}

interface UntaggedCommentsState {
  exampleComments: string[];
  processing: boolean;
  processError: boolean;
  coverage: number;
  activeSections: string[];
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

@inject('themesStore')
@observer
export default class UntaggedComments extends React.Component<
  UntaggedCommentsProps,
  UntaggedCommentsState
> {
  state = {
    exampleComments: [],
    coverage: 0,
    processing: false,
    processError: false,
    activeSections: [],
  } as UntaggedCommentsState;
  commentWorker?: Worker;

  get injected(): InjectedProps {
    return this.props as UntaggedCommentsProps & InjectedProps;
  }
  componentDidMount() {
    const { themesStore: store } = this.injected;
    const commentWorker = new Worker(workerPath.default);
    this.commentWorker = commentWorker;
    this.setState({ activeSections: [EXAMPLE_COMMENTS_SECTION, APPROXIMATE_COVERAGE_SECTION] });

    commentWorker.onmessage = (ev: MessageEvent) => {
      const exampleComments = ev.data['comments'] as string[];
      const coverage = ev.data['coverage'] as number;

      this.setState({ exampleComments, processing: false, coverage });
    };
    commentWorker.onerror = () => {
      this.setState({ processing: false });
    };
    disposeOnUnmount(
      this,
      autorun(() => {
        const { exampleComments } = store;
        if (exampleComments.length) {
          const comments = getAllCommentsText(exampleComments);
          // handle whether there is an active node to show
          let phrases = [] as string[];
          let invertResults = false;
          let resultsSize = 50;
          // if there is no active node then we are looking for ALL phrases
          phrases = store.allPhrases;
          // if we are showing all phrases we are showing comments NOT covered (so invert)
          invertResults = true;
          resultsSize = 500;
          this.setState({ exampleComments: [], processing: true });
          commentWorker.postMessage({
            comments,
            phrases,
            invertResults,
            resultsSize
          });
        } else {
          return;
        }
      })
    );
  }
  componentWillUnmount() {
    if (this.commentWorker) {
      this.commentWorker.terminate();
    }
  }
  handleAccordionClick = (_e, titleProps) => {
    const { index } = titleProps;
    const { activeSections } = this.state;
    let newActiveSections = [...activeSections];
    const currentSectionIndex = activeSections.indexOf(index);

    if (currentSectionIndex > -1) {
      newActiveSections.splice(currentSectionIndex, 1);
    } else {
      newActiveSections.push(index);
    }
    this.setState({ activeSections: newActiveSections });
  }
  render() {
    const { activeSections } = this.state;
    const { toggleThemeInfo, showThemeInfo } = this.injected.themesStore;
    const { exampleComments, processing, processError, coverage } = this.state;
    const canSeeAddThemeFromUntaggedComments = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_ADD_THEME_FROM_UNTAGGED_COMMENTS);

    if (!showThemeInfo) {
      return null;
    }
    const sections = [
      {
        key: APPROXIMATE_COVERAGE_SECTION,
        title: 'Approximate coverage',
        content: <>Approximately {Math.round(100 * (1.0 - coverage))}% of comments have a theme</>
      },
      {
        key: EXAMPLE_COMMENTS_SECTION,
        title: 'Example comments',
        content: canSeeAddThemeFromUntaggedComments ?
          <UntaggedExampleComments
            comments={exampleComments}
            processing={processing}
            processError={processError}
          />
          :
          <ExampleComments
            comments={exampleComments}
            istruncated={false}
            isquickedit={false}
            phrases={undefined}
            processing={processing}
            processerror={processError}
          />
      }
    ];

    return (
      <Segment className="theme-editor__info theme-info">
        <div className="theme-info__header">
          <h2>Review untagged comments</h2>
          <div>
            <Button size="small" className="hide-theme-info-cta" onClick={() => toggleThemeInfo(false)}>
              <FontAwesomeIcon className="icon" size="sm" icon="times" />
              Close
            </Button>
          </div>
        </div>
        <Accordion>
          {sections.map(section => {
            const isActive = activeSections.includes(section.key);
            return (
              <div
                key={section.key}
                className="theme-info__sub-section"
              >
                <Accordion.Title
                  active={isActive}
                  index={section.key}
                  onClick={this.handleAccordionClick}
                >
                  {isActive && <FontAwesomeIcon icon="chevron-down" />}
                  {!isActive && <FontAwesomeIcon icon="chevron-right" />}
                  {section.title}
                </Accordion.Title>
                <Accordion.Content
                  active={isActive}
                >
                  {section.content}
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
      </Segment>
    );
  }
}
