import classNames from 'classnames';
import FilterButton from 'components/Filters/FilterButton';
import * as React from 'react';
import { getFilterStore } from 'stores/RootStore';
import './analysis-filters.scss';
import {
  getAnalysisConfig,
  getCanCreatePublicSavedFilters,
  getCommentColumns,
  getSortedTags,
  getThemesHierarchy
} from './filters.service';
import { FilterConfig, filterPopUpId } from './types';
import { AnalysisConfig } from 'api/interfaces';

export interface AnalysisFiltersProps {
  initconfig: AnalysisConfig;
  // NB: this can not be camel case because it is react in vue and the linter will complain
  initialfiltervalues: { [id: string]: string };
  initialcomparevalues: { [id: string]: string };
  issticky: boolean;
  enablecomparisonfilter: boolean;
  surveyid: string;
}

class AnalysisFilters extends React.Component<AnalysisFiltersProps, {}> {
  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: AnalysisFiltersProps) {
    if (
      prevProps.initconfig !== this.props.initconfig ||
      prevProps.initialfiltervalues !== this.props.initialfiltervalues
    ) {
      this.initialize();
    }
  }

  getFilterConfigs(surveyId: string) {
    const canCreatePublicSavedFilters = getCanCreatePublicSavedFilters(surveyId);
    const { isFeedbackTool, showSentiment } = getAnalysisConfig();
    const sortedTags = getSortedTags();
    const themes = getThemesHierarchy();
    const commentColumns = getCommentColumns();

    const commonConfig = {
      commentColumns,
      canCreatePublicSavedFilters,
      isFeedbackTool,
      showSentiment,
      sortedTags,
      themes,
    };

    const baseLineConfig: FilterConfig = {
      filterKey: 'baseline',
      filterPopUpId: filterPopUpId.AnalysisBaseLine,
      ...commonConfig
    };

    const comparisonConfig: FilterConfig = {
      filterKey: 'comparison',
      filterPopUpId: filterPopUpId.AnalysisComparison,
      ...commonConfig
    };

    return { baseLineConfig, comparisonConfig };
  }

  initialize() {
    const { initconfig, initialfiltervalues, initialcomparevalues } = this.props;
    const { filters } = initconfig;
    const filterStore = getFilterStore();
    if (filters) {
      filterStore.setFilterConfig(filters, initialfiltervalues, initialcomparevalues);
      filterStore.updateSelections();
    }
  }

  render() {
    const { initconfig, issticky, enablecomparisonfilter, surveyid } = this.props;
    const { filters } = initconfig;
    const { baseLineConfig, comparisonConfig } = this.getFilterConfigs(surveyid);

    return (
      <div className={classNames({ 'analysis-filters': !issticky }, { 'analysis-filters-sticky': issticky })}>
        {filters && (
          <>
            <div className="analysis-filters-options">
              <span className="analysis-filters-title">{issticky ? 'Filter:' : 'Filter'}</span>
              <span className="analysis-filters-buttons">
                <FilterButton
                  className="baseline-filters"
                  config={baseLineConfig}
                  filterButtonId="analysis-baseline-filter-button"
                  filters={filters}
                />
                {enablecomparisonfilter &&
                  <FilterButton
                    className="comparison-filters"
                    config={comparisonConfig}
                    filterButtonId="analysis-comparison-filter-button"
                    filters={filters}
                    shouldTrackBaseline={true}
                  />
                }
              </span>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default AnalysisFilters;
