import { compact, trim } from 'lodash';

interface Nameable {
  firstName: string;
  lastName: string;
  preferredName?: string;
}

/*
  Returns name in the format of [First name] [Last name] (Preferred name)
  If no name exists returns — (eg. email only accounts don't have name info)
*/
export function userName(user: Nameable): string {
  const firstName = trim(user.firstName);
  const lastName = trim(user.lastName);
  const preferredName = trim(user.preferredName);
  const tokens = compact([firstName, lastName]);
  if (preferredName) {
    tokens.push(`(${preferredName})`);
  }

  if (tokens.length) {
    return tokens.join(' ');
  } else {
    return '—';
  }
}
