import { ColumnType, DateResolution } from 'api/enums';
import { isAnArrayIndex } from 'lib/array-helper';
import { isEmpty, partition } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface, SurveyConfig } from 'stores/ui/ConfigureDataUIStore';
import { getColumnOptions } from './column-options';
import './configure-date.scss';
import { isCommentColumn } from './configure-survey-helper';

interface ConfigureDateStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureDateProps extends ConfigureDateStoreProps {
}

interface ConfigureDateState {
  columnOptions: DropdownItemProps[];
}

const NO_DATE_COLUMN_VALUE = -1;

const DATE_RESOLUTION_OPTIONS = [
  {
    value: DateResolution.MONTHLY,
    text: 'Monthly'
  },
  {
    value: DateResolution.WEEKLY,
    text: 'Weekly'
  }
];

@inject('configureDataUIStore')
@observer
export default class ConfigureDate extends React.Component<ConfigureDateProps, ConfigureDateState> {

  state = {
    columnOptions: []
  } as ConfigureDateState;

  get hasDateColumn () {
    const { surveyConfig } = this.props.configureDataUIStore!;
    return isAnArrayIndex(surveyConfig.date_column);
  }

  componentDidMount () {
    const { columns, surveyConfig } = this.props.configureDataUIStore!;
    const { comment_columns } = surveyConfig;
    const availableColumns = columns.filter(column => !isCommentColumn(column, comment_columns));
    const [ dateColumns, otherColumns ] = partition(availableColumns, (column) => column.colType === ColumnType.DATE);
    const dateOptions = getColumnOptions(dateColumns);
    const otherOptions = getColumnOptions(otherColumns);

    // This is so we can have a select option instead of a dropdown, having headers in the dropdown
    // menu makes it not have the selected state and displays empty value for selected state
    const columnOptions = [
      {
        value: NO_DATE_COLUMN_VALUE,
        text: 'No date column',
        key: 'No date column'
      },
      // Only add header if corresponding options exists
      ...(!isEmpty(dateOptions) ? [{
        value: undefined,
        text: 'Maybe: Date columns',
        key: 'Maybe: Date columns',
        className: 'header',
        disabled: true
      }] : []),
      ...dateOptions,
      // Only add header if corresponding options exists
      ...(!isEmpty(otherOptions) ? [{
        value: undefined,
        text: 'Other columns',
        key: 'Other columns',
        className: 'header',
        disabled: true
      }] : []),
      ...otherOptions
    ];

    this.setState({ columnOptions });
  }

  handleColumnChange = ({ value }: DropdownProps) => {
    this.handleValueChange({ date_column: value as number });
  }

  handleResolutionChange = ({ value }: DropdownProps) => {
    this.handleValueChange({ date_resolution: value as DateResolution });
  }

  handleValueChange = (configOptions: Partial<SurveyConfig>) => {
    const { date_column, date_resolution } = this.props.configureDataUIStore!.surveyConfig;

    const dateOptions = {
      date_column,
      date_resolution,
      ...configOptions
    };

    if (isAnArrayIndex(dateOptions.date_column) && !dateOptions.date_resolution) {
      dateOptions.date_resolution = DateResolution.MONTHLY;
    }

    // Reset data if `No date column` option is selected
    if (dateOptions.date_column === NO_DATE_COLUMN_VALUE) {
      dateOptions.date_column = undefined;
      dateOptions.date_resolution = undefined;
    }

    this.props.configureDataUIStore!.updateDate(dateOptions);
  }

  render() {
    const { surveyConfig } = this.props.configureDataUIStore!;
    const { date_column, date_resolution } = surveyConfig;
    const { columnOptions } = this.state;

    return (
      <div className="configure-date">
        <h4>Date (optional)</h4>
        <p className="description">Specifying a date column allows you to view changes in themes over time.</p>
        <div className="date-options">
          <div className="date-option">
            <Form.Field>
              <label>Date column</label>
              <Dropdown
                className="column-dropdown"
                options={columnOptions}
                placeholder="Select"
                value={date_column !== undefined ? date_column : -1}
                onChange={(_e, data) => this.handleColumnChange(data)}
              />
            </Form.Field>
          </div>
          {this.hasDateColumn && <>
            <div className="date-option">
              <Form.Field>
                <label>Resolution</label>
                <Dropdown
                  options={DATE_RESOLUTION_OPTIONS}
                  placeholder="Select"
                  value={date_resolution || DateResolution.MONTHLY}
                  selectOnBlur={false}
                  onChange={(_e, data) => this.handleResolutionChange(data)}
                />
              </Form.Field>
            </div>
          </>}
        </div>
      </div>
    );
  }
}
