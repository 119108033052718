export default function getActorsClassNames (
  references: { author: string }[]
): { [key: string]: string } {
  const actors = [...new Set(references.map(ref => ref.author))];
  const classNames = [
    "actor-01",
    "actor-02",
    "actor-03",
    "actor-04",
    "actor-05",
    "actor-06",
    "actor-07",
    "actor-08",
  ];

  // we want actor colors to repeat if more than 8 actors
  const actorsClassNames = actors.reduce((acc, actor, index) => {
    return {
      ...acc,
      [actor]: classNames[index % classNames.length],
    };
  }, {});
  return actorsClassNames;
}