import NavBar from 'components/NavBar/NavBar';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { OrganizationsStoreInterface } from 'stores/OrganizationsStore';
import AuthenticatedRoute from '../AuthenticatedRoute';
import LoadingHome from './LoadingHome';

export interface HomeStoreProps {
  organizationStore?: OrganizationStoreInterface;
  organizationsStore?: OrganizationsStoreInterface;
}

export interface HomeProps extends HomeStoreProps {
}

@inject('organizationStore', 'organizationsStore')
@observer
class Home extends React.Component<HomeProps, {}> {

  constructor(props: HomeProps) {
    super(props);
  }

  render(): JSX.Element | null {
    const { organizationsStore } = this.props;
    const { organizations } = organizationsStore!;

    const loading = !!organizationsStore!.fetchingOrganizations || !organizationsStore!.fetchedOrganizations;

    if (loading) {
      return <LoadingHome isLoading={loading} />;
    } else {
      const defaultOrganization = organizations.length ? organizations[0] : undefined;
      // try to find the  most recent org that was used by this browser
      const mostRecentOrganization = localStorage.getItem('mostRecentOrganization');
      const organization = mostRecentOrganization
        ? (organizations.find(o => o.displayId === mostRecentOrganization) || defaultOrganization)
        : defaultOrganization;
      if (organization) {
        return <Redirect to={`/c/${ organization.displayId }`} />;
      }
      return (
        <>
          <AuthenticatedRoute path="/" component={NavBar} fetching={loading} />
          <Segment className="fullscreen">
            <div>You do not have access to any teams</div>
          </Segment>
        </>
      );
    }
  }
}

export default Home;
