import AlertRevert from 'images/icons/alert-revert.svg';
import * as React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export interface RevertFormProps {
  surveyName: string;
  revertLabel: string;
  by: string;
  onAccept(): void;
  onClose(): void;
}

export default class RevertForm extends React.Component<RevertFormProps> {
  handleKeyPress = (event: KeyboardEvent) => {
    const { onAccept } = this.props;
    if (event.keyCode !== 13) {
      return;
    }
    onAccept();
  };
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  render() {
    const { revertLabel, onClose, onAccept, surveyName, by } = this.props;
    return (
      <Modal dimmer="blurring" open={true} onClose={onClose} size="tiny">
        <Modal.Content>
          <div className="modal-content ">
            <AlertRevert className="alert-image" />
            <div className="modal-body">
              <div className="modal-title">
                Do you want to revert “{surveyName}” themes?
              </div>
              {revertLabel !== '' && (
                <div className="modal-text">
                  Your draft will revert to the themes applied by {by} at{' '}
                  {revertLabel}.
                </div>
              )}
              {revertLabel === '' && (
                <div className="modal-text">
                  Your draft will be reverted to the currently applied themes.
                </div>
              )}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="orange"
            onClick={onAccept}
            className="nw-revert-confirm-button"
          >
            Revert draft
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
