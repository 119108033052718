import * as React from 'react';
import './link-button.scss';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  onClick: (event: React.MouseEvent) => void;
}

export const LinkButton = (props: Props) => {
  const { children, onClick, ...rest } = props;

  return (
    <button
      className="link-button"
      onClick={(event) => onClick(event)}
      {...rest}
      type="button"
    >
      {children}
    </button>
  );
};
