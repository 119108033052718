
import FileSaver from 'file-saver';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Message, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';
import { WorkflowsStoreInterface } from 'stores/WorkflowsStore';

export interface WorkflowResultsParams {
  orgId: string;
  workflowId: string;
  runId: string;
}
export interface WorkflowResultsProps extends RouteComponentProps<WorkflowResultsParams> {
  workflowsStore: WorkflowsStoreInterface;
}

export interface WorkflowResultsState {
  loadingResults: boolean;
}

@inject('workflowsStore')
@observer
export default class WorkflowResultsComponent extends React.Component<WorkflowResultsProps, WorkflowResultsState> {
  state = {
    loadingResults: false
  } as WorkflowResultsState;

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  get workflowId(): string {
    const { workflowId } = this.props.match.params;
    return workflowId;
  }
  get runId(): string {
    const { runId } = this.props.match.params;
    return runId;
  }

  componentDidMount() {
    analytics.track('Workflows: Retrieve results', { category: 'Workflows' });
  }
  onConfirm = async () => {
    this.setState({ loadingResults: true });
    const result = await this.props.workflowsStore.fetchWorkflowResults(this.workflowId, this.runId);
    if (result) {
      const blob = new Blob([result.data], {
        type: 'text/csv;charset=utf-8'
      })
      FileSaver.saveAs(blob, result.filename);
      this.onClose();
    }
    this.setState({ loadingResults: false });
  }

  onClose = () => {
    this.props.history.push(`/c/${ this.orgId }`);
  }

  render() {
    const { loadingResults } = this.state;
    const { workflowsStore } = this.props;
    return (

      <Modal
        open={true}
        size="large"
        onClose={this.onClose}
        className='workflow-results-modal'
      >
        <ModalHeader>Download workflow results</ModalHeader>
        <ModalContent>
          <p>This will download the results of the workflow run.</p>
          {workflowsStore.fetchingWorkflowResultsError && (

            <Message
              className="error"
              negative={true}
              header={workflowsStore.fetchingWorkflowResultsError}
            />
          )}
        </ModalContent>
        <ModalActions>
          <Button onClick={this.onClose}>Cancel</Button>
          <Button
            loading={loadingResults}
            disabled={loadingResults}
            onClick={this.onConfirm}
            autoFocus={true}
          >
            Download
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}
