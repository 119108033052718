import * as React from 'react';
import "./tag-list.scss";

type Props = {
  items: string[]
};

const TagList = (props: Props) => {
  return (
    <ul className="conversation-tag-list">
      {props.items.map((item, index) => (
        <li key={`${item}--${index}`}>
          <span className="conversation-tag-item">{item}</span>
        </li>
      ))}
    </ul>
  );
};

export { TagList };
