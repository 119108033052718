import { AnalysisFilterCut, SegmentFilters } from "api/interfaces";
import { QueryFilter } from "stores/types";
import { DateShortcutMap } from "./filters/saved-filter-helper";

export function queryFiltersToSegmentFilters(
  filters: Record<string, QueryFilter>,
  dateShortcuts: DateShortcutMap
): SegmentFilters {
  return Object.entries(filters).reduce((segmentFilters: SegmentFilters, [key, queryFilter]) => {
    const cuts = queryFilter.selected || [];

    cuts.forEach((filter: AnalysisFilterCut) => {
      const { id, startDate, endDate, text } = filter;
      // Add filter only if they're not `All`
      if (id !== 'all_') {
        if (id) {
          if (key === 'themes') {
            const existingIds = segmentFilters[key]?.ids || [];
            segmentFilters[key] = { ...segmentFilters[key], ids: [...existingIds, id] };
            const existingNames = segmentFilters[key]?.names || [];
            segmentFilters[key].names = [...existingNames, { id: id, name: filter.name }];
          } else {
            const existingIds = segmentFilters[key]?.ids || [];
            segmentFilters[key] = { ids: [...existingIds, id] };
          }
        } else if (startDate) {
          segmentFilters[key] = { startDate, endDate };
          const shortcut = dateShortcuts[filter.name.toLowerCase()];
          // Only add name property if the selection is from date filter shortcuts
          // so we can compare the relative dates instead of absolute ones
          // ex. Last 31 days, All, etc.
          if (shortcut) {
            segmentFilters[key].name = filter.name;
          }
        } else if (text) {
          segmentFilters[key] = { text };
        }
      }
    });
    return segmentFilters;
  }, {});
}
