import { inject } from 'mobx-react';
import * as React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';
import UserSubscription from './UserSubscription';

interface UserSubscriptionsStoreProps {
  userStore?: UserStoreInterface;
}

interface UserSubscriptionsProps extends UserSubscriptionsStoreProps {
}

@inject('userStore')
export default class UserSubscriptions extends React.Component<
  UserSubscriptionsProps
> {
  render() {
    const { user } = this.props.userStore!;
    if (!user) {
      return null;
    }

    const subscriptions = user.subscriptions || [];

    return (
      <Segment>
        <Table striped={true} basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="4">Email digests</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {subscriptions.map(subscription => {
              return (
                <UserSubscription
                  key={subscription.id}
                  subscription={subscription}
                />
              );
            })}
            {subscriptions.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="4">
                  No subscriptions found. Contact your admin to get one set up.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
