<template>
  <el-col>
    This version doesn't understand the widget requested
  </el-col>
</template>

<script>
export default {
  name: 'WidgetUnknown',
  props: { config: { default: undefined, type: Object } }
};
</script>
