import * as React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import './configure-unique-id.scss';

interface ConfigureUniqueIdProps {
  columns: {
    name: string;
    index: number;
  }[];
  uniqueId?: number[];
  onUniqueIdChange: (uniqueId: number[]) => void;
}

export class ConfigureUniqueId extends React.Component<ConfigureUniqueIdProps> {
  handleUniqueIdChange = ({ value }: DropdownProps) => {
    this.props.onUniqueIdChange(value as number[]);
  }

  render() {
    const { columns, uniqueId } = this.props;
    const options = columns.map((c) => ({
      key: c.index,
      text: c.name,
      value: c.index
    }));

    return (
      <>
        <div>
          <h4>Unique ID (optional)</h4>
          <div className="unique-id-config">
            <div className="config-info">
              <p>Select column(s) that uniquely identify a row:</p>
              <p className="note">We'll use this to ignore duplicates whenever new data is uploaded.
                &nbsp;
                <a
                  href="https://help.getthematic.com/article/106-unique-id-columns"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </p>
            </div>
            <Dropdown
              data-testid="unique-id"
              className="unique-id nw-unique-id"
              options={options}
              placeholder="Select"
              value={uniqueId || []}
              selectOnBlur={false}
              multiple={true}
              selection={true}
              onChange={(_e, data) => this.handleUniqueIdChange(data)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ConfigureUniqueId;
