import { PhraseSegmentTheme, PlainComment, Summary } from "types/custom";
import { Block } from 'lib/segments-to-blocks';
import { RemoveThemeParams } from "stores/ui/AnalysisToolsUIStore";

interface GetRemoveThemePlainCommentsParams {
  block?: Block;
  comment: PlainComment | Summary;
  theme: PhraseSegmentTheme;
}

const getRemovePlainCommentParams = ({
  block,
  comment,
  theme,
}: GetRemoveThemePlainCommentsParams): RemoveThemeParams => {
  const locationsContainingTheme = comment.segments
    .filter(seg => seg.themes.some(t => t.base === theme.base && t.sub === theme.sub))
    .map(seg => seg.location);

  const highlightedSentences = block
    ? [block.content]
    : locationsContainingTheme.map(
      location => comment.comment.slice(location[0], location[1])
    );

  return {
    highlightedSentences,
    commentId: comment.id,
    selectedTheme: {...theme},
    selectedBlock: block,
  };
}

export { getRemovePlainCommentParams };
