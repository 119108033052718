import { AnswerSubject } from 'api/interfaces';
import * as React from 'react';
import { processText, TextSegment } from '../utils/processText';
import { Button } from 'components/Shared/Button';
import './highlighted-paragraph.scss';

type Props = {
  contents: string
  subjects: AnswerSubject[],
  passiveThemes: string[],
  isReadOnly: boolean,
  onThemeClick: (x: { theme: string, themeCode: string }) => void,
  onTextSelection: (text: string) => void,
  onCopyClick: () => void
};

export const HighlightedParagraph = (props: Props) => {

  const processedText = processText(
    props.contents,
    props.subjects,
    props.passiveThemes
  );

  function onMouseUp() {

    const selectedText = window.getSelection() as Selection;
    const trimmedText = selectedText.toString().trim();
    if (trimmedText.length > 0) {
      props.onTextSelection(trimmedText);
    }

  }

  return (
    <p className="answers__highlighted-paragraph"
      role="article"
      onMouseUp={onMouseUp}
    >
      {
        processedText.map((item: TextSegment, index: number) => {

          if ('theme' in item && item.isInteractive) {
            return (
              <Button
                inline={true}
                variant="tertiary"
                disabled={props.isReadOnly}
                key={`${item.text}-${index}`}
                onClick={() => props.onThemeClick({ theme: item.theme, themeCode: item.themeCode })}
                size="small"
              >{item.text}</Button>
            );
          }

          return <span key={`${item.text}-${index}`} >{item.text}</span>;

        })
      }
    </p>
  );

};
