import { UserSeatType } from 'api/interfaces';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import './seat-type-selector.scss';

interface SeatTypeSelectorProps {
  userId: string;
  existingSeatType: UserSeatType;
  needsToConfirmSeatChange?: boolean;
  onSeatChange: (userId: string, seatType: UserSeatType) => void;
}

interface SeatTypeSelectorState {
  seatTypeAsString: string;
  confirmSeatChangeModalOpen: boolean;
}

export default class SeatTypeSelector extends React.Component<SeatTypeSelectorProps, SeatTypeSelectorState> {
  state = {
    seatTypeAsString: 'viewer',
    confirmSeatChangeModalOpen: false,
  };

  componentDidMount(): void {
    this.revertSeatTypeToExisting();
  }

  componentDidUpdate(prevProps: SeatTypeSelectorProps) {
    if (prevProps.existingSeatType !== this.props.existingSeatType) {
      this.revertSeatTypeToExisting();
    }
  }

  revertSeatTypeToExisting = () => {
    if (this.props.existingSeatType === null) {
      this.setState({ seatTypeAsString: 'viewer' });
    } else {
      this.setState({ seatTypeAsString: this.props.existingSeatType });
    }
    this.setState({ confirmSeatChangeModalOpen: false });
  }

  changeSeatType = ({ value = 'viewer' }: DropdownProps | DropdownItemProps, seatChangeConfirmed: boolean) => {
    // first save the seat within the component
    this.setState({ seatTypeAsString: value as string, confirmSeatChangeModalOpen: false }, () => {
      // now check if an actual change would need a 'confirmation'
      if (this.props.needsToConfirmSeatChange && !seatChangeConfirmed) {
        this.setState({ confirmSeatChangeModalOpen: true });
      } else {
        // otherwise we can just update the user
        const seatType = (this.state.seatTypeAsString === 'viewer' ?
          null :
          this.state.seatTypeAsString as UserSeatType);
        this.props.onSeatChange(this.props.userId, seatType);
      }
    }
    );

  }

  render(): JSX.Element | null {
    const { seatTypeAsString, confirmSeatChangeModalOpen } = this.state;

    const allOptions = [
      {
        value: 'core',
        text: 'Core User',
      },
      {
        value: 'explorer',
        text: 'Explorer User',
      },
      {
        value: 'viewer',
        text: 'Viewer User',
      },
    ];

    return (
      <>
        {confirmSeatChangeModalOpen && <ConfirmationModal
          confirmationText="This user may have access to other workspaces"
          discardButtonText="No"
          confirmationButtonText="Yes"
          cancel={this.revertSeatTypeToExisting}
          confirm={() => this.changeSeatType({ value: seatTypeAsString }, true)}
        >
          <p>Changing their seat type will affect those workspaces as well.</p>
          <p> Are you sure you want to change their seat type?</p>
        </ConfirmationModal>}
        <Dropdown
          className="seat-type-selector"
          options={allOptions}
          value={seatTypeAsString}
          onChange={(_e, data) => this.changeSeatType(data, false)}
        />
      </>
    );
  }
}
