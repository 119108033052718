import * as React from 'react';

interface Props {
  children: React.ReactNode;
  title: string;
}

const ExportAssistantConfigSection = ({ children, title }: Props) => {
  return (
    <section className="export-assistant-config-section">
      <h2 className="export-assistant-config-section__title">{title}</h2>
      <div>
        {children}
      </div>
    </section>
  );
};

export { ExportAssistantConfigSection };
