import { WaterfallData, WaterfallDataPoint } from 'api/interfaces';
import * as d3 from 'd3';
import d3tip, { D3Tip } from 'd3-tip';
import { isFinite, map, sortBy } from 'lodash';
import toFixed from 'vue/libs/to-fixed';

export type renderWaterfallChartArgs = {
  el: SVGElement;
  options: {
    data: WaterfallData;
    chartWidth: number;
    labelOverflow?: boolean;
    setLabelOverflow?: (labelOverflow: boolean) => void;
    selectedTheme?: string
    selectTheme?: (theme: { code: string, title: string }, subtheme: { code: string, title: string } | null) => void;
  },
};

let tip: D3Tip | null = null;

const PADDING_Y = 72;
const MARGIN_TOP = 0;
const MARGIN_RIGHT = 30;
const MARGIN_BOTTOM = 64;
const MARGIN_LEFT = 40;
const CHART_HEIGHT = 300;
const CHART_PADDING = 0;
const BAR_WIDTH = 30;

function wrap(
  textEls: d3.textEl,
  width: number,
  noSet: boolean,
  setLabelOverflow?: (labelOverflow: boolean) => void
) {
  let overflow = false;
  textEls.each(function (this: SVGTextElement) {
    const text = d3.select(this);
    let words: string[] = [];
    const spans = text.selectAll('tspan');
    if (spans.empty()) {
      words = text
        .text()
        .split(/\s+/)
        .reverse();
    } else {
      spans.each(function (this: SVGTextElement) {
        words.unshift(d3.select(this).text());
      });
    }

    let word: string | undefined;
    let line: string[] = [];
    let lineNumber = 0;
    const lineHeight = 1; // ems
    const y = text.attr('y');
    const dy = parseFloat(text.attr('dy'));
    let tspan = text
      .text(null)
      .append('tspan')
      .attr('x', 0)
      .attr('y', y)
      .attr('dy', dy + 'em');
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        if (line.length === 1) {
          // we can't linebreak; we have to overflow
          overflow = true;
        } else {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${ ++lineNumber * lineHeight + dy }em`)
            .text(word);
        }
      }
    }
  });
  if (!noSet && setLabelOverflow) {
    setLabelOverflow(overflow);
  }
}

function getDomain(extent: number[], height: number): number[] {
  const [min, max] = extent;
  const perPixel = (max - min) / height;

  const bufferedMin = min - perPixel * PADDING_Y;
  const bufferedMax = max + perPixel * PADDING_Y;

  // different strat if very close together
  if (max - min < 1) {
    return [
      Math.floor(5 * bufferedMin) / 5,
      Math.ceil(5 * bufferedMax) / 5
    ];
  } else {
    return [Math.floor(bufferedMin), Math.ceil(bufferedMax)];
  }
}

function getPrecision(min: number, max: number): number {
  if (max - min < 0.5) {
    return 2;
  } else {
    return 1;
  }
}

function isBaseTheme(data: WaterfallDataPoint): boolean {
  return !data.isOther && !data.isNoText;
}

function percentage(numerator: number, denominator: number): string {
  if (denominator === 0) {
    return '0%';
  }
  return toFixed(100 * (numerator / denominator), 1, '%');
}

function renderTipData(data: WaterfallDataPoint, options: renderWaterfallChartArgs['options']) {
  const { isOther, isNoText, title, themes } = data;
  if (isOther || isNoText) {
    const heading = isOther ? `Other themes` : `'No comment' contribution`;
    const details = isOther
      ? `
          ${ map(
        sortBy(themes, theme => -theme.value),
        theme => `<div>${ theme.title }</div><div>${ theme.label }</div>`
      ).join('') }
        `
      : `This represents the contribution from responses with a score, 
        but no themes because there is no comment given.`;
    return `
      <div>
        <h4>${ heading }</h4>
        <div class="detail-items detail-items__2-c">
          ${ details }
        </div>
      </div>
      <div x-arrow class="popper__arrow" style="top:50%;transform:translateY(-50%);"></div>
    `;
  } else {
    return `
      <div class="detail-items__3-c">
        <div>
          <b>${ title }</b>
        </div>
        <div>
          ${ options.data.previousData.label }
        </div>
        <div>
          ${ options.data.currentData.label }
        </div>
        <div>
          Volume
        </div>
        <div>
          ${ percentage(data.previousCount, options.data.previousData.total) }
        </div>
        <div>
          ${ percentage(data.count, options.data.currentData.total) }
        </div>
        <div>
          Score
        </div>
        <div>
          ${ toFixed(data.previousScore, 1) }
        </div>
        <div>
          ${ toFixed(data.score, 1) }
        </div>
      </div>
      <div x-arrow class="popper__arrow" style="top:50%;transform:translateY(-50%);"></div>
    `;
  }
}

function renderWaterfallChart(
  {
    el,
    options,
  }: renderWaterfallChartArgs
) {
  // For expediency, erase any existing chart and build a new one.
  d3.select(el).select('svg').remove();

  type RenderableDataPoint = WaterfallDataPoint & { start: number, end: number };

  let start = options.data.previousData.score;
  const dataPoints = options.data.dataPoints.reduce(
    (result, cont, i) => {
      if (i === 0) {
        result.push({
          title: options.data.previousData.label,
          label: toFixed(options.data.previousData.score, 2),
          value: options.data.previousData.score
        });
      }
      const { isOther, value } = cont;
      result.push({
        ...cont,
        end: start + value,
        isOther,
        start
      });
      start += value;

      if (i === options.data.dataPoints.length - 1) {
        result.push({
          title: options.data.currentData.label,
          label: toFixed(options.data.currentData.score, 2),
          value: options.data.currentData.score
        });
      }
      return result;
    },
    [] as (RenderableDataPoint | { title: string, label: string, value: number })[]
  );

  if (tip) {
    tip.destroy();
  }

  tip = d3tip()
    .attr(
      'class',
      'el-tooltip__popper is-dark el-tooltip__popper-no-mouse'
    )
    .attr('x-placement', 'right')
    .direction('e')
    .offset([0, -15])
    .html((data: WaterfallDataPoint) => renderTipData(data, options));

  const margin = {
    top: MARGIN_TOP,
    right: MARGIN_RIGHT,
    bottom: MARGIN_BOTTOM,
    left: MARGIN_LEFT
  };
  const width = options.chartWidth - margin.left - margin.right;
  const height = CHART_HEIGHT - margin.top - margin.bottom;
  const padding = CHART_PADDING;

  const extent = d3.extent([...map(dataPoints, 'start'), ...map(dataPoints, 'end')]);
  const [min, max] = extent;
  const precision = getPrecision(min, max);
  const domain = getDomain(extent, height);
  const [minDomain, maxDomain] = domain;
  const range = maxDomain - minDomain;

  const xScale = d3
    .scaleBand()
    .rangeRound([0, width])
    .padding(padding);

  const yScale = d3
    .scaleLinear()
    .range([height, 20]);

  const xAxis = d3
    .axisBottom(xScale)
    .tickFormat((s, i, ticks) => {
      if (i === 0 || i === ticks.length - 1) {
        return '';
      } else {
        return s;
      }
    })
    .tickSize(0)
    .tickPadding(5);

  const yAxis = d3
    .axisLeft(yScale)
    .tickSize(-width)
    .tickPadding(10)
    .bind(domain);

  // clear previous
  d3.select(el)
    .selectAll('g')
    .remove();

  const chart = d3
    .select(el)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr(
      'transform',
      'translate(' + margin.left + ',' + margin.top + ')'
    )
    .call(tip);

  if (dataPoints.length === 0) {
    return;
  }

  xScale.domain(dataPoints.map(d => d.title));

  let step = 1;
  if (range >= 500) {
    step = 100;
  } else if (range >= 200) {
    step = 50;
  } else if (range >= 100) {
    step = 25;
  } else if (range >= 25) {
    step = 10;
  } else if (range >= 10) {
    step = 5;
  }
  const values = d3.range(
    Math.ceil(minDomain / step) * step,
    Math.ceil(maxDomain / step) * step,
    step
  );

  yScale.domain(domain);

  if (yAxis.tickValues) {

    yAxis.tickValues(values).tickFormat((d, i) => {
      if (i === 0 || i === values.length - 1) {
        return toFixed(d, precision);
      } else {
        return undefined;
      }
    });

  }

  const bands = chart
    .selectAll('.bar')
    .data(dataPoints)
    .enter()
    .filter((d: RenderableDataPoint, i: number) => i > 0 && i < dataPoints.length - 1)
    .append('g')
    .attr('class', (d: RenderableDataPoint) =>
      isBaseTheme(d) ? 'band' : 'other-themes'
    )
    .classed('selected', (d: RenderableDataPoint) => {
      return d.title === options.selectedTheme;
    })
    .attr('transform', (d: RenderableDataPoint) => {
      return `translate(${ xScale(d.title) },0)`;
    });

  bands
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('height', height)
    .attr('width', xScale.bandwidth())
    .on('mouseover', function (this: SVGElement, data: RenderableDataPoint) {
      tip?.show(data, this);
      // no hover for "other" and "no comment"
      if (isBaseTheme(data)) {
        d3.select(this).classed('hovered', true);
      }
    })
    .on('mouseout', function (this: SVGElement) {
      tip?.hide();
      d3.select(this).classed('hovered', false);
    })
    .on('click', (data: RenderableDataPoint) => {
      tip?.hide();
      // no click for "other" and "no comment"
      if (isBaseTheme(data) && options.selectTheme) {
        options.selectTheme({ code: data.code, title: data.title }, null);
      }
    });

  bands
    .append('rect')
    .classed('accent', true)
    .attr('x', 0)
    .attr('y', height - 2.5)
    .attr('height', 2)
    .attr('width', xScale.bandwidth());

  chart
    .append('g')
    .attr('class', 'y axis')
    .call(yAxis)
    .call(g => g.select('.domain').remove());

  const bar = chart
    .selectAll('.bar')
    .data(dataPoints)
    .enter()
    .append('g')
    .attr('class', 'bar')
    .attr('transform', (d: RenderableDataPoint) => {
      return `translate(${ xScale(d.title) },0)`;
    });

  const lhs = (xScale.bandwidth() - 30) / 2;

  bar
    .filter((d: RenderableDataPoint, i: number) => i > 0 && i < dataPoints.length - 1)
    .append('rect')
    .attr('class', (d: RenderableDataPoint) => (d.start < d.end ? 'positive' : 'negative'))
    .attr('y', (d: RenderableDataPoint) => {
      return yScale(Math.max(d.start, d.end));
    })
    .attr('x', lhs)
    .attr('height', (d: RenderableDataPoint) => {
      const h = Math.abs(yScale(d.start) - yScale(d.end));
      if (isFinite(h)) {
        return Math.max(h, 0.5); // if <0.5px height, return 0.5px
      } else {
        return 0.5;
      }
    })
    .attr('width', BAR_WIDTH);

  // Add the value on each bar
  bar
    .filter((d: RenderableDataPoint, i: number) => i > 0 && i < dataPoints.length - 1)
    .append('text')
    .attr('class', 'val-label')
    .attr('x', xScale.bandwidth() / 2)
    .attr('y', (d: RenderableDataPoint) => {
      const val = yScale(d.end) + (d.start < d.end ? -4 : 12);
      if (isFinite(val)) {
        return val;
      } else {
        return 0;
      }
    })
    .text((d: RenderableDataPoint) => {
      const diff = d.end - d.start;
      if (Math.abs(diff) < Math.pow(10, -1 - precision)) {
        return '-';
      } else {
        return toFixed(d.end - d.start, precision);
      }
    });

  bar
    .filter((d: RenderableDataPoint, i: number) => {
      return i === 0 || i === dataPoints.length - 1;
    })
    .attr('class', (d: RenderableDataPoint, i: number) => {
      return i === 0 ? 'bar waterfall-start' : 'bar waterfall-end';
    })
    .append('circle')
    .attr('class', 'circle')
    .attr('cx', xScale.bandwidth() / 2)
    .attr('cy', (d: RenderableDataPoint) => yScale(d.value))
    .attr('r', 26);
  // Add the value on circles
  bar
    .filter((d: RenderableDataPoint, i: number) => i === 0 || i === dataPoints.length - 1)
    .append('text')
    .attr('class', 'date-label')
    .attr('x', xScale.bandwidth() / 2)
    .attr('y', (d: RenderableDataPoint) => {
      return yScale(d.value);
    })
    .attr('dy', '44px')
    .text((d: RenderableDataPoint) => d.title);

  bar
    .filter((d: RenderableDataPoint, i: number) => {
      return i === 0 || i === dataPoints.length - 1;
    })
    .append('text')
    .attr('x', xScale.bandwidth() / 2)
    .attr('y', (d: RenderableDataPoint) => yScale(d.value))
    .attr('dy', '.3em')
    .attr('class', 'bubble')
    .text((d: RenderableDataPoint) => toFixed(d.value, precision));

  // Add the connecting line between each bar
  bar
    .filter((d: RenderableDataPoint, i: number) => {
      return i !== dataPoints.length - 1;
    })
    .append('line')
    .attr('class', 'connector')
    .attr('x1', (d: RenderableDataPoint, i: number) => (i === 0 ? lhs + 46 : lhs + 32))
    .attr('y1', (d: RenderableDataPoint, i: number) => yScale(i === 0 ? d.value : d.end))
    .attr('x2', (d: RenderableDataPoint) => {
      if (isBaseTheme(d)) {
        return lhs + xScale.bandwidth() / (1 - padding) - 2;
      } else {
        return lhs + xScale.bandwidth() / (1 - padding) - 16;
      }
    })
    .attr('y2', (d: RenderableDataPoint, i: number) => yScale(i === 0 ? d.value : d.end));

  chart
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${ height })`)
    .call(xAxis)
    .call(g => {
      g.select('.domain').remove();
    })
    .selectAll('.tick text')
    .call(wrap, xScale.bandwidth() - 6, false, options.setLabelOverflow)
    .call(els => {
      // if the previous wrap call set labelOverflow, call again with a bit more space
      if (options.labelOverflow) {
        wrap(els, 1.6 * xScale.bandwidth(), true, options.setLabelOverflow); // ALERT - fix labelOverflow
      }
    })
    .call(textEls => {
      const { labelOverflow } = options;
      textEls.each(function (this: SVGTextElement) {
        if (labelOverflow) {
          const textEl = d3.select(this);
          const textElHeight = textEl.node().getBoundingClientRect().height;
          const xScaleWidth = xScale.bandwidth();

          textEl.attr('text-anchor', 'end').attr(
            'transform',
            `rotate(-30 ${ textElHeight * 0.14 } ${ -xScaleWidth * 0.5 })`
          );
        }
      });
    });

  return tip;
}

export default {
  renderWaterfallChart,
};
