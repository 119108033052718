import { CommentThemeItem, SummarySegment } from "types/custom";

export function getSelectedThemeItems(
  segments: SummarySegment[],
  selectedSegment: SummarySegment
): CommentThemeItem[] {

  const allThemeItems = segments.flatMap((segment) => {
    return segment.themes.map(theme => {
      return {
        theme,
        isNew: false,
        isRemoved: false,
        sentiment: segment.sentiment,
        hasBackgroundColour: segment.index === selectedSegment.index
      };
    });
  });

  return allThemeItems.reduce((result: CommentThemeItem[], themeItem: CommentThemeItem) => {
    const indexOfExistingItem = result.findIndex(
      element => element.theme.title === themeItem.theme.title
    );

    // We deduplicate theme items, preferring those with background color.
    if (indexOfExistingItem > -1) {
      return result.map((element, index) => {
        const hasSameTheme = index === indexOfExistingItem;
        return hasSameTheme && themeItem.hasBackgroundColour ? themeItem : element;
      });
    }

    return [...result, themeItem];
  }, []);
}
