import { trim } from 'lodash';
interface Nameish {
  firstName: string;
  lastName: string;
  preferredName?: string;
}
export default function(obj: Nameish): string {
  const firstName = trim(obj.firstName);
  const lastName = trim(obj.lastName);
  const preferredName = trim(obj.preferredName);
  if (preferredName) {
    return preferredName;
  } else if (firstName || lastName) {
    return trim(`${firstName} ${lastName}`);
  } else {
    return 'No Name Set';
  }
}
