import { mapThemeObject } from 'vue/explore/theme-data-functions';
import queryBuilder from 'vue/libs/queryBuilder';
import thematicData from 'vue/libs/thematicData';
import { getDateLabelAndQuery } from './export-assistant-date.util';
import { getBaselineCount } from './fetch-themes.util';
import { AvailableDateRange, Category, DateOptions, FetchOptions, FilterCut } from './types';

const getDimensions = async (selectedDimension: string, fetchOptions: FetchOptions) => {
  const { commentColumns, requestOptions, selectionQueries } = fetchOptions;

  if (!requestOptions) {
    throw new Error('No request options');
  }

  const selectionsQueryWithoutTheme = queryBuilder.appendToFilter(
    selectionQueries.baseline.query,
    'theme!=empty_string'
  );

  return await thematicData.getStatistics(
    selectionsQueryWithoutTheme,
    commentColumns,
    requestOptions as any,
    selectedDimension,
  );
};


const getDimensionsBySegments = async (
  selectedDimension: string,
  baselineQuery: string,
  fetchOptions: FetchOptions,
  filterCuts: FilterCut[]
) => {
  if (filterCuts.length === 0) {
    throw new Error('No segment values selected');
  }

  const fetchAllDimensions = filterCuts.map((segmentValue) => {
    const selectionsQueryWithSegment = queryBuilder.appendToFilter(
      baselineQuery,
      segmentValue.rql
    );
    const updatedFetchOptions = {
      ...fetchOptions,
      selectionQueries: {
        ...fetchOptions.selectionQueries,
        baseline: {
          ...fetchOptions.selectionQueries.baseline,
          query: selectionsQueryWithSegment
        },
      }
    };

    return getDimensions(selectedDimension, updatedFetchOptions);
  });

  const dimensions = await Promise.all(fetchAllDimensions);
  const labels = filterCuts.map((cut) => cut.name);
  return {
    labels,
    dimensions
  };
};


const dateCutsFromCategory = (category: Category, availableDateRange: AvailableDateRange) => {
  const { id, dateOptions } = category;

  if (!dateOptions) {
    throw new Error('No date options');
  }
  const timePeriod = dateOptions.timePeriod;
  const repeatFrequency = parseInt(dateOptions.repeatFrequency, 10);

  const dates = Array.from({ length: repeatFrequency }).map((_, index) => {
    return getDateLabelAndQuery(timePeriod, index, id, availableDateRange);
  });
  const filterCuts = dates.map((date) => ({
    name: date.dateLabel,
    value: date.query,
    rql: date.query
  }));

  return filterCuts;
}

function getThemesRequestOptions(fetchOptions: FetchOptions) {
  return {
    ...fetchOptions.requestOptions,
    themesSegmentFilterString: fetchOptions.segmentQueryStrings?.baseline || null,
  }
}

const getThemes = async (fetchOptions: FetchOptions) => {
  const { commentColumns, requestOptions, selectionQueries } = fetchOptions;

  if (!requestOptions) {
    throw new Error('No request options');
  }

  const baseline = await thematicData.getThemes(
      selectionQueries.baseline.query,
      commentColumns,
      getThemesRequestOptions(fetchOptions)
    );

  const baselineCount = getBaselineCount(baseline.counts);
  const baselineScores = baseline.scoreAllRows;
  const baselineThemes = baseline.themes;

  const themes = mapThemeObject(
    baselineScores,
    baselineScores,
    requestOptions.scoreType,
    requestOptions?.scoreOptions?.range,
    baselineThemes,
    baselineThemes,
    baselineCount,
    baselineCount,
    'difference',
    'volume',
    ''
  );

  return {
    baselineCount,
    themes
  };
}

const getThemesByDate = async (dateOptions: DateOptions, fetchOptions: FetchOptions) => {
  const { commentColumns, requestOptions, selectionQueries } = fetchOptions;
  const requestOptionsWithDateResolution = {
    ...getThemesRequestOptions(fetchOptions),
    dateMaxPast: parseInt(dateOptions?.repeatFrequency || '2', 10),
    dateResolution: dateOptions?.timePeriod
  }
  if (!requestOptions) {
    throw new Error('Request options not found');
  }

  const result = await thematicData.getThemesByDate(
    selectionQueries.baseline.queryWithoutDate,
    commentColumns,
    requestOptionsWithDateResolution
  );
  return result;
};

const getThemesBySegments = async (
  baselineQuery: string,
  fetchOptions: FetchOptions,
  cutOptions: FilterCut[] | DateOptions
) => {
  const { commentColumns, requestOptions } = fetchOptions;

  if (!requestOptions) {
    throw new Error('Request options not found');
  }
  if (!Array.isArray(cutOptions)) {
    const byDateResult = await getThemesByDate(cutOptions, fetchOptions);
    return {
      labels: byDateResult.dateLabels,
      queries: byDateResult.dateQueries,
      themes: byDateResult.themes
    }
  }

  const requests = cutOptions.map(async (cut) => {
      const query = queryBuilder.appendToFilter(baselineQuery, cut.rql);
      const result = await thematicData.getThemes(query, commentColumns, getThemesRequestOptions(fetchOptions));
      return { ...result, cut };
  });

  const results = await Promise.all(requests);
  const validResults = results.filter(result => result !== null);

  if (validResults.length === 0) {
    throw new Error("No valid results returned from theme requests");
  }

  const labels = validResults.map(result => result.cut.name);
  const queries = validResults.map(result => result.cut.rql);

  return {
    labels: labels,
    queries: queries,
    themes: validResults
  }
};



export { dateCutsFromCategory, getDimensions, getDimensionsBySegments, getThemes, getThemesByDate, getThemesBySegments };

