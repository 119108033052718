import * as React from 'react';
import classNames from 'classnames';
import toFixed from 'vue/libs/to-fixed';
import './bar.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';

interface Props {
  value: number;
  maxValue: number;
  dp?: number;
  narrow?: boolean;
  displayPercentage?: number;
  range?: [number, number];
  suffix?: string;
  lastItem?: boolean;
}

const Bar = withCamelCaseProps(({
  value,
  maxValue,
  dp = 1,
  narrow = false,
  displayPercentage = 0,
  range,
  suffix,
  lastItem = false,
}: Props) => {
  const precision = dp >= 0 ? dp : 1;
  const proportion = range ? (value / (range[1] - range[0])) * 100 : value;
  const valueLabel = precision === 0 ? toFixed(Math.round(proportion), 0) : toFixed(proportion, precision);
  const percent = displayPercentage ? `${precision === 0 ? toFixed(Math.round(displayPercentage), 0) : toFixed(displayPercentage, precision)}%` : '';

  let max: number;
  if (range) {
    const [low, high] = range;
    max = (maxValue / (high - low)) * 100;
  } else {
    max = maxValue;
  }

  const width = Math.abs((0.9 * proportion) / max * 100);
  const isNegative = value < 0;
  const x = value > 0 ? 0 : 100 - width + '%';

  let suffixLabel = suffix;
  if (range && !suffix) {
    suffixLabel = '%';
  }

  return (
    <div
      className={classNames('percentage-bar', {
        'percentage-bar--is-narrow': narrow,
      })}
      aria-label="percentage-bar"
    >
      <div
        className={classNames('percentage-bar-container', {
          'percentage-bar-container--has-right-bar': isNegative && lastItem,
          'percentage-bar-container--has-left-bar': !isNegative && lastItem,
          'percentage-bar-container--has-forced-height': lastItem
        })}
      >
        <svg
          className="percentage-bar-indicator"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="overall">
              <stop offset="0%" stopColor="var(--primary-400)" />
              <stop offset="100%" stopColor="var(--primary-300)" />
            </linearGradient>
            <linearGradient id="negativeOverall">
              <stop offset="0%" stopColor="var(--primary-300)" />
              <stop offset="100%" stopColor="var(--primary-400)" />
            </linearGradient>
          </defs>

          <rect
            x={x}
            width={`${width}%`}
            fill={`url(#${isNegative ? 'negativeOverall' : 'overall'})`}
            y="0"
            rx="0"
            ry="0"
            height={narrow ? 10 : 20}
          />
        </svg>
      </div>
      <div className="bar-label">
        <span className="value-label" aria-label="value">{valueLabel}{suffixLabel}</span>
        {displayPercentage ? <span className="percentage-label"> {percent}</span> : null}
      </div>
    </div>
  );
});

export { Bar };
