import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import ArrowMergeRight from 'images/icons/arrow-merge-right.svg';
import { find, forEach, includes, reduce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Input, Ref } from 'semantic-ui-react';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import './theme-select.scss';

const ThemeSelectCreateNewKey = '-create-new-';
export { ThemeSelectCreateNewKey };

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

export interface ThemeSelectProps {
  groupId: string;
  disabled?: boolean;
  excludeIds?: (string | undefined)[] | undefined;
  hasOther?: boolean;
  hasMakeNew?: boolean;
  icon?: IconProp | 'arrow-merge-right';
  onChange?: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
  mergeTheme?: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
  placeholder?: string;
  resetAfterChange?: boolean;
  themesOnly?: boolean;
  value?: string;
  isThemesDiscoveryItem?: boolean;
  themeSelectionDropdown?: boolean;
  isMerge?: boolean;
  suggestedAction?: string;
  proposedTheme?: SelectedTheme;
  inputRef?: React.RefObject<HTMLInputElement>;
}

interface ThemeSelectState {
  selected?: string;
  dropdownInputValue: string;
  focusedOptionIndex: number;
}
@inject('themesStore')
@observer
export default class ThemeSelect extends React.Component<ThemeSelectProps, ThemeSelectState> {
  filteredOptionRefs: React.RefObject<HTMLSpanElement[]>;

  state = {
    selected: undefined,
    dropdownInputValue: '',
    focusedOptionIndex: 0
  };
  get injected() {
    return this.props as ThemeSelectProps & InjectedProps;
  }
  componentDidMount() {
    const { value, suggestedAction, isThemesDiscoveryItem, proposedTheme } = this.props;
    if (value) {
      this.setState({ selected: String(value) });
      if (isThemesDiscoveryItem) {
        const theme = this.getThemeById(value);
        if (suggestedAction === 'merge_to_nearest_sub') {
          // there is only one option where we will have subtheme
          this.onThemeSelection({
            theme: proposedTheme ? proposedTheme.theme : undefined,
            subtheme: value,
            text: theme ? theme.text : value.replace('_', ' ')
          }, true);
        } else {
          this.onThemeSelection({
            theme: value,
            subtheme: null,
            text: theme ? theme.text : value.replace('_', ' ')
          }, true);
        }
      }
    }
  }
  componentDidUpdate(prevProps: ThemeSelectProps) {
    // We need to update the copy of value because it has changed
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ selected: String(value) });
    }
  }
  getThemes = () => {
    const { excludeIds, hasOther, themesOnly, groupId, hasMakeNew } = this.props;
    const { themesStore: store } = this.injected;

    const filteredThemes = store.getMergedActiveThemes(groupId).filter(theme => {
      const { id } = theme;
      return !excludeIds?.includes(id);
    });

    const filteredThemesWithSubthemes = filteredThemes.map(theme => {
      const filteredSubthemes = theme?.children?.filter(subtheme => {
        return !excludeIds?.includes(subtheme.id);
      });
      return { ...theme, children: filteredSubthemes };
    });

    const themes: DropdownItemProps[] = reduce(
      filteredThemesWithSubthemes,
      (result, theme) => {
        const { id: key, title: text } = theme;
          result.push({
            className: 'select-theme__theme',
            key: `${ groupId }-${ key }`,
            text,
            theme: key,
            value: key
          });
          if (!themesOnly) {
            forEach(theme.children, subtheme => {
              result.push({
                className: 'select-theme__subtheme',
                key: `${ groupId }-${ subtheme.id }`,
                text: subtheme.title,
                theme: key,
                subtheme: subtheme.id,
                value: subtheme.id
              });
            });
          }
        return result;
      },
      [] as DropdownItemProps[]
    );
    if (hasOther !== false) {
      themes.unshift({
        key: 'other',
        text: 'without a theme',
        theme: 'other',
        value: 'other'
      });
    }
    if (hasMakeNew) {
      themes.unshift({
        key: ThemeSelectCreateNewKey,
        text: 'Create new',
        theme: undefined,
        value: ThemeSelectCreateNewKey
      });
    }

    return themes;
  };
  getThemeById = id => {
    const themes = this.getThemes();

    const val = find(themes, t => t.value === id);
    if (val) {
      const { theme, subtheme = null, text } = val;
      return { theme, subtheme, text };
    }
    return undefined;
  };
  onChange = (
    e: React.SyntheticEvent<HTMLInputElement>,
    data: DropdownProps
  ) => {
    const { onChange, resetAfterChange } = this.props;
    const selected = String(data.value);
    const theme = this.getThemeById(selected);
    if (theme && onChange) {
      onChange(theme);
    }
    if (resetAfterChange) {
      this.setState({ selected: '' });
    } else {
      this.setState({ selected });
    }
  };
  search = (options: DropdownItemProps[], search: string): DropdownItemProps[] => {
    search = search.toLowerCase();
    if (search) {
      let lastTheme;
      return reduce(
        options,
        (result, item) => {
          if (!item.subtheme) {
            lastTheme = item;
          }
          const text = String(item.text).toLowerCase();

          if (includes(text, search)) {
            // include the parent if is subtheme
            if (item.subtheme && !includes(result, lastTheme)) {
              result.push(lastTheme);
            }
            result.push(item);
          }

          return result;
        },
        [] as DropdownItemProps[]
      );
    } else {
      return options;
    }
  };
  onThemeSelection = (data, isMounted) => {
    const { isMerge, mergeTheme, onChange } = this.props;
    const theme = {
      theme: data.theme,
      subtheme: data.subtheme ? data.subtheme : null,
    };
    if (isMerge && mergeTheme) {
      mergeTheme(theme, data.text, isMounted);
    } else if (onChange) {
      onChange(theme, data.text, isMounted);
    }
  };
  onInputValueChange = (value) => {
    this.setState({ dropdownInputValue: value });
  };

  handleInputKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    const { inputRef } = this.props;
    const { key } = event;

    if (key === 'ArrowDown') {
      event.preventDefault(); // don't scroll the list
      const selectOptions = this.filteredOptionRefs.current;
      const [firstSelectOption] = selectOptions ? selectOptions : [];

      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }

      firstSelectOption.focus();
      firstSelectOption.scrollIntoView({ block: 'nearest' });
    }
  };

  addSelectOptionRef = (el: HTMLSpanElement) => {
    if (!this.filteredOptionRefs.current) {
      return;
    }

    this.filteredOptionRefs.current.push(el);
  };

  getNextFocusIndex = (key: string) => {
    const { focusedOptionIndex } = this.state;

    if (key === 'ArrowDown') {
      const incrementedIndex = focusedOptionIndex + 1;

      return incrementedIndex >= 0 ? incrementedIndex : 0;
    }

    if (key === 'ArrowUp') {
      return focusedOptionIndex - 1;
    }

    return 0;
  };

  handleSelectOptionChange = (key: string) => {
    if (!this.filteredOptionRefs.current) {
      return;
    }

    const { inputRef } = this.props;

    const nextFocusIndex = this.getNextFocusIndex(key);

    if (nextFocusIndex === -1) {
      inputRef?.current?.querySelector('input')?.focus();
      return;
    }

    this.filteredOptionRefs.current[nextFocusIndex].focus();

    this.setState({ focusedOptionIndex: nextFocusIndex });
  }

  handleSelectFilterOptionKeyDown = (event: React.KeyboardEvent, option: any) => {
    event.stopPropagation();

    const { key } = event;

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      event.preventDefault();
      this.handleSelectOptionChange(key);
    }

    if (key === 'Enter' || key === 'Space') {
      this.onThemeSelection(option, false)
    }
  };

  render() {
    const {
      disabled,
      placeholder,
      isThemesDiscoveryItem,
      themeSelectionDropdown,
      themesOnly,
      inputRef,
    } = this.props;
    const { selected, dropdownInputValue } = this.state;
    const options = this.getThemes();

    const emptyText = themesOnly ? 'No base themes match' : 'No themes match';

    this.filteredOptionRefs = { current: [] };
    let filteredOptions: DropdownItemProps[] = [];
    if (isThemesDiscoveryItem) {
      // we only show filtered option inside themes discovery result screen
      filteredOptions = this.search(options, dropdownInputValue);
    }
    let icon: React.ReactNode = null;
    if (this.props.icon === 'arrow-merge-right') {
      icon = (
        <ArrowMergeRight
          className={classnames('svg-inline--fa', { disabled: disabled })}
        />
      );
    } else if (this.props.icon) {
      icon = (
        <FontAwesomeIcon
          className={classnames({ disabled: disabled })}
          icon={this.props.icon}
        />
      );
    }
    return (
      <>
        {isThemesDiscoveryItem ? (
          themeSelectionDropdown ? (
            <div className="theme-select-filter">
              {inputRef ? (
                // Note: https://react.semantic-ui.com/migration-guide/#removal-of-ref-component
                <Ref innerRef={inputRef}>
                  <Input
                    className="theme-select-filter__input"
                    onChange={(e) => this.onInputValueChange(e.target.value)}
                    onKeyDown={this.handleInputKeyDown}
                    size="small"
                    type="text"
                    value={dropdownInputValue}
                  />
                </Ref>
              ) : (
                <Input
                  className="theme-select-filter__input"
                  onChange={(e) => this.onInputValueChange(e.target.value)}
                  onKeyDown={this.handleInputKeyDown}
                  size="small"
                  type="text"
                  value={dropdownInputValue}
                />
              )}
              <div className="theme-select-filter__options">
                {filteredOptions.map((option, index) => {
                  return (
                    <Dropdown.Item
                    key={option.key}
                    className={option.className}
                    onClick={() => this.onThemeSelection(option, false)}
                    >
                      <span
                        className="theme-select-filter__option"
                        onKeyDown={(event) => this.handleSelectFilterOptionKeyDown(event, option)}
                        ref={(el) => {
                          if (el && this.filteredOptionRefs.current) {
                            this.filteredOptionRefs.current[index] = el;
                          }
                        }}
                        tabIndex={0}
                        >
                        {option.text}
                      </span>
                    </Dropdown.Item>
                  );
                })}
                {filteredOptions.length === 0 && (
                  <div className="theme-select__no-options">{ emptyText }</div>
                )}
              </div>
            </div>
          ) : null
        ) : (
          <div className={classnames('theme-select strategy', { iconified: !!icon })}>
            {icon}
            <Dropdown
              disabled={disabled}
              placeholder={placeholder || 'Select theme'}
              icon={<FontAwesomeIcon icon="chevron-down" fixedWidth={true} className="icon" />}
              search={this.search}
              fluid={true}
              selection={true}
              onChange={this.onChange}
              options={options}
              selectOnNavigation={false}
              selectOnBlur={false}
              value={selected}
              className="normal-size"
            />
          </div>
        )}
      </>
    );
  }
}
