import { stringify } from 'query-string';
import { getOrganizationStore, getUserStore } from 'stores/RootStore';

function constructMixMaxLink(url: string): string {
  const userStore = getUserStore();
  const organizationStore = getOrganizationStore();
  const { orgName } = organizationStore;
  const { user } = userStore;

  const mixmaxParams = {
    email: user ? user.email : '',
    company: orgName,
    name: user ? `${user.firstName} ${user.lastName}` : ''
  };

  return `${ url }?${ stringify(mixmaxParams) }`;
}

export { constructMixMaxLink };
