
import HeroNoDashboard from 'images/hero-no-dashboard.svg';
import * as React from 'react';
import { Header } from 'semantic-ui-react';
import './no-dashboard-placeholder.scss';

class NoDashboardPlaceholder extends React.Component {
  render() {

    return (
      <div className="no-dashboard-placeholder">
        <HeroNoDashboard />
        <Header className="no-dashboard-title" as="h2">There doesn't seem to be anything here.</Header>
        <div className="no-dashboard-subtitle">Here are a few quick pointers for getting started in Thematic.</div>
      </div>
    );
  }
}

export default NoDashboardPlaceholder;