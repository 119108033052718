import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, Form, FormInputProps, Loader } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceSlackCreateFormProps {
  existingConfiguration?: SlackConfig;
  datasetName?: string;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface ChannelInfo {
  id: string;
  name: string;
  is_member: boolean;
  is_private: boolean;
}

interface SlackConfig {
  channel_ids: string[];
  type: string;
}

export interface DataSourceSlackCreateFormState {
  config: SlackConfig;
}

@observer
class DataSourceSlackCreateForm extends React.Component<
DataSourceSlackCreateFormProps,
DataSourceSlackCreateFormState
> {
  state = {
    config: {
      channel_ids: [],
      type: 'slack'
    }
  } as DataSourceSlackCreateFormState;

  componentDidMount() {
    const { existingConfiguration } = this.props;
    if (existingConfiguration) {
      this.setState({
        config: { ...existingConfiguration },
      });
    }

    if (this.props.setValidity) {
      const isValid = !!existingConfiguration && existingConfiguration.channel_ids.length > 0;
      this.props.setValidity(isValid);
    }
  }

  changeSelectedSlackChannels = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const config = {
      channel_ids: value,
      type: 'slack'
    };
    this.setState({ config });
    if (this.props.setValidity) {
      this.props.setValidity(config.channel_ids.length > 0);
    }
  };

  renderForm(channels: ChannelInfo[] | undefined): JSX.Element | null {
    if (!channels) {
      return <Loader />;
    }
    const visibleChannels = channels.filter(s => s.is_member);
    const hasVisibleChannels = visibleChannels.length > 0;
    const options = channels.filter(s => s.is_member).map(s => ({
      key: s.id, text: s.name, value: s.id
    }));
    return <div>
      <div><p>Please <a
        href="https://help.getthematic.com/article/122-adding-slack-integration"
        target="_blank"
        rel="noopener noreferrer"
      >
        follow these steps
      </a> to add the Thematic App to Slack channels you want to pull data from. </p>
        <Button onClick={() => {
          // quick hack to force DataSourceWithIntegrationForm
          // to refech channels
          this.setState({ config: { ...this.state.config } });
        }}>Refresh connected Slack channels</Button>
    </div>
      {hasVisibleChannels && <Form role="form">
        <Form.Field>
          <Dropdown placeholder="Select channel" fluid={true} multiple={true} selection={true} options={options}
            onChange={this.changeSelectedSlackChannels} />
        </Form.Field>
      </Form>
      }
    </div>;
  }

  render(): JSX.Element | null {
    const { onChange, datasetName } = this.props;
    const { config } = this.state;
    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.SLACK}
          enumerationSelection={config}
          datasetName={datasetName}
        />
      </div>
    );
  }
}

export default DataSourceSlackCreateForm;
