import Auth from 'Auth/Auth';
import FileSaver from 'file-saver';
import { action, observable } from 'mobx';

type AdminList = { id: string }[];
type OrgList = { id: string, name: string }[];

interface AdminAudit {
  global: AdminList;
  limited: { [userId: string]: OrgList };
}

export interface ManageThematicAdminsStoreInterface {
  globalAdmins: string[];
  localAdmins: { [key: string]: string[] };
  audit?: AdminAudit;

  loadingAdmins: boolean;
  loadingAdminsError?: string;

  loadingAudit: boolean;
  loadingAuditError?: string;

  creatingAdmin: boolean;
  creatingAdminError?: string;

  revokingAdmin: boolean;
  revokingAdminError?: string;

  downloadingAudit: boolean;

  getAdminsForOrg: (orgId?: string | null, refresh?: boolean) => Promise<string[] | undefined>;
  createAdmin: (orgId: string | null, email: string) => Promise<void>;
  revokeAdmin: (orgId: string | null, email: string) => Promise<void>;
  auditAdmins: () => Promise<string[] | undefined>;
  downloadAudit: () => Promise<void>;
}

class ManageThematicAdminsStore implements ManageThematicAdminsStoreInterface {
  @observable
  globalAdmins = [] as string[];
  @observable
  localAdmins = {} as { [key: string]: string[] };
  @observable
  audit = undefined as AdminAudit | undefined;

  @observable
  loadingAdmins = false;
  @observable
  loadingAdminsError = undefined as string | undefined;

  @observable
  loadingAudit = false;
  @observable
  loadingAuditError = undefined as string | undefined;

  @observable
  creatingAdmin = false;
  @observable
  creatingAdminError = undefined as string | undefined;

  @observable
  revokingAdmin = false;
  @observable
  revokingAdminError = undefined as string | undefined;

  @observable
  downloadingAudit = false;

  @action
  createAdmin = async (orgId: string | null, email: string) => {
    this.creatingAdmin = true;
    this.creatingAdminError = undefined;

    let url = `/thematic_admin/elevate/${ email }`;
    if (orgId !== null) {
      url += `?organization=${ orgId }`;
    }

    try {
      const { ok, errorData } = await Auth.fetch<void>(url, {
        method: 'POST'
      });
      if (!ok) {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.creatingAdminError = `Failed to create user: ${ e.message }`;
    } finally {
      this.creatingAdmin = false;
    }
  }

  @action
  revokeAdmin = async (orgId: string | null, email: string) => {
    this.revokingAdmin = true;
    this.revokingAdminError = undefined;

    let url = `/thematic_admin/revoke/${ email }`;
    if (orgId !== null) {
      url += `?organization=${ orgId }`;
    }

    try {
      const { ok, errorData } = await Auth.fetch<void>(url, {
        method: 'POST'
      });
      if (!ok) {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.revokingAdminError = `Failed to revoke user: ${ e.message }`;
    } finally {
      this.revokingAdmin = false;
    }
  }

  @action
  getAdminsForOrg = async (orgId: string | null, refresh?: boolean) => {

    if (!refresh) {
      // return the existing values if we already have them
      if (orgId === null && this.globalAdmins.length > 0) {
        return this.globalAdmins;
      } else if (orgId !== null && this.localAdmins.orgId) {
        return this.localAdmins[orgId];
      }
    }

    this.loadingAdmins = true;
    this.loadingAdminsError = undefined;

    let url = '/thematic_admin/listAdmins';
    if (orgId !== null) {
      url += `?organization=${ orgId }`;
    }

    const { data, ok } = await Auth.fetch<{ users: AdminList }>(url);

    if (data && ok) {
      if (orgId) {
        this.localAdmins[orgId] = data.users.map(user => user.id);
      } else {
        this.globalAdmins = data.users.map(user => user.id);
      }
    } else {
      this.loadingAdminsError = 'Failed to retrieve admins';
    }
    this.loadingAdmins = false;

    return [];
  };

  @action
  auditAdmins = async () => {

    this.loadingAudit = true;
    this.loadingAuditError = undefined;

    let url = '/thematic_admin/auditAdmins';

    const { data, ok } = await Auth.fetch<AdminAudit>(url);

    if (data && ok) {
      this.audit = data;
    } else {
      this.loadingAuditError = 'Failed to retrieve audit';
    }
    this.loadingAudit = false;

    return [];
  };

  @action
  downloadAudit = async () => {
    this.downloadingAudit = true;
    // ensure we have data
    await this.auditAdmins();

    if (this.audit) {

      // create a csv
      const filename = 'thematic-admin-audit.csv';

      let data = 'email,global,limited\n';
      data += this.audit.global.map(admin => {
        return `${ admin.id },true,`;
      }).join('\n');
      const limitedAdmins = this.audit.limited;
      data += '\n' + Object.keys(limitedAdmins).map((admin) => {
        const orgs = limitedAdmins[admin];
        return `${ admin },false,` + orgs.map(org => org.id).join(' ');
      }).join('\n');

      // save to file
      const blob = new Blob([data as any], {
        type:
          'text/csv'
      });
      FileSaver.saveAs(blob, filename);
    }

    this.downloadingAudit = false;
  }
}

export default ManageThematicAdminsStore;
