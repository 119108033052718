enum FlagKeys {
  // used to determine who can see 'labs'
  QA = 'QA',

  CAN_ASSUME_USER = 'can-assume-user',
  CAN_SEE_THEMES_SUMMARIZATION = 'can-see-themes-summarization',
  CAN_SEE_DATASET_SUMMARIZATION = 'can-see-dataset-summarization',
  CAN_SEE_NEW_MERGED_THEMES = 'can-see-new-merged-themes',
  CAN_SEE_SCORE_CHANGE_SUMMARIZATION = 'can-see-score-change-summarization',
  CAN_SEE_SUMMARIES_ON_DASHBOARD = 'can-see-summaries-on-dashboard',
  CAN_USE_PROTOTYPE_EXPORT = 'prototype-export',
  CAN_SEE_QUICK_PHRASE_SELECTION = 'quick-phrase-selection',
  CAN_SEE_MOVABLE_THEMES_IN_THEME_EDITOR = 'can-see-movable-themes-in-theme-editor',
  CAN_SEE_THEMES_QUALITY = 'can-see-themes-quality',
  CAN_SEE_ADD_THEME_FROM_UNTAGGED_COMMENTS = 'can-see-add-theme-from-untagged-comments',

  // permissions
  CAN_SEE_DEV_ONLY_TOOLS = 'can-see-dev-only-tools',
  CAN_SEE_THEME_DISCOVERY_WORKFLOW = 'can-see-theme-discovery-workflow',
  CAN_SEE_THEMESV2_RECREATE = 'can-see-themesv2-recreate',

  CAN_SEE_ANSWER_BANNERS = 'can-see-answer-banners',
  CAN_SEE_SLACK_SHARING = 'can-see-slack-sharing',
  USE_CLAUDE_SUMMARIZER = 'use-claude-summarizer',
  USE_CLUSTERING_TEXT_TYPE = 'use-cluster-text-type',

}

export default FlagKeys;
