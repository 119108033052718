<template>
  <el-dialog
    title="Add date picker"
    :visible="true"
    width="500px"
    class="add-filter-modal"
    :show-close="false"
  >
    <div class="config-field">
      <label>
        Default date range
      </label>
      <el-select
        v-model="resolution"
        placeholder="Select"
        @change="onResolutionChange"
      >
        <el-option
          v-for="item in resolutionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div
      slot="footer"
      class="actions"
    >
      <el-button
        @click="close"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="addFilter"
      >
        Ok
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getActiveDashboardUIStore } from 'stores/RootStore';
import { isEmpty } from 'lodash';
import { DashboardDateResolution } from 'api/enums';
import { RESOLUTION_OPTIONS } from './constants';
export default {
  name: 'ConfigureDateFilter',
  props: {
    datePickerConfig: { type: Object }
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),

      updatedDatePickerConfig: {},
      dateFilterTarget: 'datePicker',
      resolution: undefined,
      resolutionOptions: []
    };
  },
  mounted() {
    this.resolutionOptions = RESOLUTION_OPTIONS;
    // Default resolution to be monthly
    this.resolution = DashboardDateResolution.MONTHLY;

    this.onResolutionChange();
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    onResolutionChange() {
      this.updatedDatePickerConfig = { ...this.datePickerConfig, resolution: this.resolution };
    },
    addFilter() {
      if (!isEmpty(this.updatedDatePickerConfig)) {
        this.activeDashboardUIStore.setValue(this.dateFilterTarget, this.updatedDatePickerConfig);
      }
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-filter-modal {
  ::v-deep .el-dialog {
    min-height: 150px;
  }
  .no-filter {
    padding-bottom: 20px;
  }
}
</style>