import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceQualtricsCreateFormProps {
  existingConfiguration?: QualtricsConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface QualtricsInfo {
  name: string;
  surveyId: string;
  isActive: boolean;
  creationDate: string;
}

interface QualtricsConfig {
  surveyIds: string[];
}

export interface DataSourceQualtricsCreateFormState {
  selectedSurvey?: QualtricsInfo;
  config?: QualtricsConfig;
}

@observer
class DataSourceQualtricsCreateForm extends React.Component<
DataSourceQualtricsCreateFormProps,
DataSourceQualtricsCreateFormState
> {
  state = {} as DataSourceQualtricsCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedSurvey = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedSurvey = JSON.parse(value);

    const config = {
      surveyIds: [selectedSurvey.surveyId]
    };

    this.setState({ selectedSurvey, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedSurvey.surveyId);
    }
  }

  setDefaultSurvey = (availableSurveys: QualtricsInfo[]): void => {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    if (availableSurveys.length === 0 || !existingConfiguration || selectedSurvey) {
      return;
    }

    const defaultSelectedSurvey = this.getExistingSurvey(availableSurveys, existingConfiguration);
    if (!defaultSelectedSurvey) {
      return;
    }

    this.setState({
      selectedSurvey: defaultSelectedSurvey,
      config: existingConfiguration,
    });
  }

  getExistingSurvey = (
    availableSurveys: QualtricsInfo[],
    existingConfiguration?: QualtricsConfig
  ) => {
    return availableSurveys.find(({ surveyId }) => existingConfiguration?.surveyIds.includes(surveyId));
  }

  renderForm(availableSurveys: QualtricsInfo[] | undefined): JSX.Element | null {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableSurveyOptions = map(availableSurveys, item => {
      return {
        text: item.name,
        value: JSON.stringify(item)
      };
    });

    if (availableSurveys) {
      const defaultValue = existingConfiguration
        ? JSON.stringify(this.getExistingSurvey(availableSurveys, existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Survey</label>

            <Dropdown
              selection={true}
              options={availableSurveyOptions}
              defaultValue={defaultValue}
              placeholder="Select"
              onChange={this.changeSelectedSurvey}
            />
          </Form.Field>
          { selectedSurvey && (
            <div>
              <Form.Field>
                <label>Title</label> <span>{selectedSurvey.name}</span>
              </Form.Field>
              <Form.Field>
                <label>Created</label> <span>{selectedSurvey.creationDate}</span>
              </Form.Field>
              <Form.Field>
                <label>Is Active</label> <span>{selectedSurvey.isActive ? 'True' : 'False'}</span>
              </Form.Field>
            </div>
          )}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={(availableSurveys: QualtricsInfo[]) => this.setDefaultSurvey(availableSurveys)}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.QUALTRICS} />
      </div>
    );
  }
}

export default DataSourceQualtricsCreateForm;
