import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { isTruthy } from 'lib/type-utils';
import { AnalysisFilterKey, FilterMatchType, QueryFilter } from 'stores/types';

export const getFreeTextSearchTerms = (queryFilter?: QueryFilter): string[] =>
  queryFilter?.selected?.map((cut) => cut.text).filter(isTruthy) || [];

export const getFreeTextMatchType = (queryFilter?: QueryFilter) => queryFilter?.match || 'any';

function encodeText(text: string) {
  // rql has brackets as special characters so we need to encode these
  return encodeURIComponent(text).replace(/[!'()*]/g, function (c: string) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

export const createFreeTextCut = (text: string, filterId: string): AnalysisFilterCut => {
  return {
    name: text,
    rql: `${filterId}==${encodeText(text)}`,
    text
  };
};

export const createFreeTextQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  searches: string[],
  match: FilterMatchType,
) => {
  const rqlId = filter.id;

  let selected = [] as AnalysisFilterCut[];

  if (searches.length > 0) {
    let combinedRql = '';
    if (match === 'advanced') {
      const textToSearch = searches[0].replace('\n', ' ');
      combinedRql = `${ rqlId }==${ encodeText(textToSearch) }`;
    } else {
      const matchChar = match === 'any' ? ',' : ';';
      combinedRql = searches.map((text) => `${ rqlId }==${ encodeText(text) }`).join(matchChar);
    }

    selected = searches.map((text, i) => ({
      name: text,
      rql: i === 0 ? combinedRql : '',
      text,
    }));
  }

  return {
    filterKey,
    filterId: filter.id,
    selected,
    match,
  };
};
