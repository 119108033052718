<template>
  <div
    ref="el"
    class="theme-waterfall"
  >
    <svg
      key="chart"
      ref="chart"
      :class="{ 'label-overflow': labelOverflow }"
    />
  </div>
</template>

<script>
const MIN_BAR_WIDTH = 700;

import {
  difference,
  get,
  map,
  reduce,
  slice,
  sortBy,
  throttle
} from 'lodash';
import renderWaterfall from 'charts/renderWaterfall';
import toFixed from 'vue/libs/to-fixed';

export default {
  name: 'ThemeWaterfall',
  props: {
    data: { default: undefined, type: Object },
    selectedTheme: { default: undefined, type: String },
    numThemesToShow: { default: 7, type: Number }
  },
  data() {
    return {
      d3tip: undefined,
      labelOverflow: false,
      resizeEventListener: undefined,
    };
  },
  computed: {
    currentScore() {
      return get(this, 'data.current.score');
    },
    currentLabel() {
      return get(this, 'data.current.label');
    },
    previousScore() {
      return get(this, 'data.previous.score');
    },
    previousLabel() {
      return get(this, 'data.previous.label');
    },
    themes() {
      return get(this, 'data.themes');
    },
    noTextContribution() {
      const value = get(this, 'data.noTextContribution', 0.0);
      return {
        isNoText: true,
        label: toFixed(value, 2),
        title: 'no comment',
        value: value
      };
    },
    contributions() {
      const contributions = get(this, 'data.contributions', []);
      return contributions.map(c => ({
        ...c,
        score: c.score.score,
        previousScore: c.previousScore.score
      }));
    },
    otherContribution() {
      // to avoid confusion we calculate the 'other' contribution off the rounded values so they will add up
      const { contributions, topContributions } = this;
      const tail = difference(contributions, topContributions);
      // if no difference, show nothing
      if (tail.length === 0) {
        return undefined;
      }
      const themes = map(tail, theme => {
        const { title, value } = theme;
        return {
          label: toFixed(value, 2),
          title,
          value
        };
      });
      const other = reduce(
        tail,
        (result, theme) => {
          return result + theme.value;
        },
        0
      );
      return {
        isOther: true,
        label: toFixed(other, 2),
        themes,
        title: 'other themes',
        value: other
      };
    },
    series() {
      const {
        otherContribution,
        noTextContribution
      } = this;

      // take first 8 of contributions (sorted in value)
      const contributions = this.topContributions;

      if (otherContribution) {
        contributions.push(otherContribution);
      }

      if (noTextContribution) {
        contributions.push(noTextContribution);
      }

      return contributions;
    },
    topContributions() {
      return sortBy(slice(this.contributions, 0, this.numThemesToShow), c => {
        if (c.value >= 0) {
          return -c.value;
        } else {
          return Number.MAX_SAFE_INTEGER + c.value;
        }
      });
    },
    validData() {
      // Weighting isn't valid if changeWeighting is much higher than the score movement
      // This is to avoid trying to show the waterfall graph in the event of bad data
      const scoreMovement = Math.abs(this.previousScore - this.currentScore);
      const maxValidWeighting = Math.max(10, scoreMovement * 4);
      return !this.contributions.find(c => c.changeWeighting > maxValidWeighting);
    }
  },
  watch: {
    data() {
      this.draw();
    },
    labelOverflow() {
      this.draw();
    },
    validData() {
      this.draw();
    },
    selectedTheme() {
      this.draw();
    }
  },
  mounted() {
    this.draw = throttle(this.draw, 50);

    this.resizeEventListener = window.addEventListener('resize', this.draw);
    this.draw();
  },
  beforeDestroy() {
    if (this.d3tip) {
      this.d3tip.destroy();
    }
  },
  methods: {
    selectTheme(theme, subtheme) {
      this.$emit("selectTheme", theme, subtheme)
    },
    setLabelOverflow(labelOverflow) {
      this.labelOverflow = labelOverflow;
    },
    getChartWidth() {
      const chartContainer = this.$refs.el;
      if (!chartContainer) {
        return MIN_BAR_WIDTH;
      }
      return chartContainer.getBoundingClientRect().width;
    },
    draw() {
      const data = {
        dataPoints: this.series,
        previousData: this.data.previous,
        currentData: this.data.current,
      };
      const options = {
        data,
        chartWidth: this.getChartWidth(),
        labelOverflow: this.labelOverflow,
        setLabelOverflow: this.setLabelOverflow,
        selectedTheme: this.selectedTheme,
        selectTheme: this.selectTheme
      };
      this.d3tip = renderWaterfall.renderWaterfallChart({
        el: this.$refs.chart,
        options
      })
    },
  }
};
</script>

<style lang="scss">
@import '../../../vue/styles/element-variables';
.theme-waterfall {
  padding-bottom: 5px;
  .other-themes {
    pointer-events: all;
    fill: none;
  }
  .band {
    cursor: pointer;
    pointer-events: all;
    fill: none;
    .hovered {
      fill: $--neutral-100;
    }
    &.selected rect {
      fill: $--primary-100;
      &.accent {
        fill: $--orange-400;
      }
    }
  }
  g {
    pointer-events: none;
  }
  .bar {
    rect {
      &.positive {
        fill: $--green-400;
      }
      &.negative {
        fill: $--red-400;
      }
    }
    line.connector {
      stroke: $--neutral-400;
      stroke-dasharray: 5;
      stroke-width: 2px;
      z-index: var(--z-index-chart-line-connector);
    }
  }
  text {
    text-anchor: middle;
    &.val-label {
      fill: $--neutral-500;
      font-size: 11px;
    }
    &.bubble {
      fill: $--primary-800;
      font-size: 13px;
    }
    &.date-label {
      font-size: 13px;
      color: $--neutral-800;
    }
  }
  .circle {
    fill: $--color-white;
    stroke: $--primary-500;
    stroke-width: 2;
  }
  .tick {
    line {
      stroke: $--neutral-100;
    }
    text {
      fill: $--neutral-800;
      font-size: 13px;
    }
  }
  .label-overflow .x.axis .tick text {
    text-anchor: end;
  }
  .y.axis .tick text {
    fill: $--neutral-500;
  }

}
</style>
