<template>
  <selector-wrapper
    :loading="loading"
    :error="error"
    :warning="warning"
    class="selection-score-rank"
  >
    <div
      v-if="activeDashboardUIStore.isEditing"
      class="settings"
    >
      <settings-menu
        :show-delete="true"
        @onDelete="deleteSelectionWidget()"
      />
    </div>
    <stack-rank
      v-show="!loading || !!widgetData"
      :data="widgetData"
      :error="error"
      :compare-filter="compareFilter"
      :config="activeDashboardUIStore.selectionWidget"
      class="dashboard-widget"
      @selectionChanged="selectionChanged"
    />
    <div
      v-if="loading && !widgetData"
      class="dashboard-widget empty-score-rank"
    >
      Loading&hellip;
    </div>
  </selector-wrapper>
</template>

<script>
import { getActiveDashboardUIStore } from 'stores/RootStore';

import StackRank from 'vue/dashboards/Dashboard/Widgets/Components/StackRank';
import SelectorWrapper from './SelectorWrapper.vue';

import WidgetDataMixin from 'vue/components/mixins/WidgetDataMixin';
import queryBuilder from 'vue/libs/queryBuilder';
import analytics from 'lib/analytics';
import SettingsMenu from 'vue/dashboards/DashboardEditable/SettingsMenu.vue';

export default {
  name: 'SelectionScoreRank',
  components: {
    StackRank,
    SelectorWrapper,
    SettingsMenu,
  },
  mixins: [WidgetDataMixin],
  props: {
    compareFilter: { type: String },
    compareFilterName: { type: String },
    filterString: { type: String },
    filterNames: { type: String },
    dateFilter: { type: Object },
    baseFilter: { type: String },
    source: { type: String },
  },
  data() {
    return {
      ignoreCompare: true,
      activeDashboardUIStore: getActiveDashboardUIStore(),
    };
  },
  mounted() {
    const unwatch = this.$watch('widgetData', (data) => {
      if (data) {
        this.$emit('onReady');
        unwatch();
      }
    });
  },
  methods: {
    selectionChanged(queryFilter, title) {
      const filter = { compareTo: queryFilter };
      const compareRql = queryBuilder.build(filter);
      const compareParam = queryBuilder.getSelectedIds(filter);
      this.$emit('updateCompareTo', compareRql, title, compareParam);

      analytics.track('Dashboard: Update CompareTo', {
        category: 'Dashboard',
        type: 'SCORERANK',
        compareRql,
      });
    },
    deleteSelectionWidget() {
      this.activeDashboardUIStore.deleteSelectionWidget();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';
.selection-score-rank {
  margin: 1.4em auto;
  padding: 0;

  @media (max-width: $--md) {
    margin: 20px 10px;
  }

  .settings {
    display: flex;
    justify-content: flex-end;
    padding: 8px 20px;
    background-color: $--neutral-100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    .settings-dropdown.el-dropdown {
      padding: 0;
    }
  }
}
.selection-score-rank__footer {
  border-bottom: 3px solid $--color-text-primary;
  color: $--color-text-primary;
  font-size: 18px;
  font-weight: 600;
  margin: 20px -20px -20px;
  padding: 0.5em 35px;
}

.empty-score-rank {
  margin: 20px;
  padding: 25px 5px;
  text-align: center;
}
</style>
