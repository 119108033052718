import * as React from 'react';
import { Skeleton } from 'components/Shared/Skeleton';

export const AnswerSkeleton = () => {

  return (
    <>
      <header className="answers__editable-header">
        <div className="answers__editable-container answers__container">
          <div className="answers__container">
            <div className="editable-header__container">
              <Skeleton height={23} width={300} />
            </div>
          </div>
        </div>
      </header>
      <ul className="answers__content answers__container">
        <header className="answers__text-header">
          <Skeleton height={20} width="75%" />
        </header>
        <div className="answers__text-content">
          <Skeleton count={6} />
          <Skeleton count={1} width="66%" />
        </div>
        <div className="answers__copy-content">
          <Skeleton height={20} width={60} />
        </div>
      </ul>
    </>
  );
};
