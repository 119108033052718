import { memoize, debounce } from 'lodash';

// Debounce if the function arguments are the same within the wait period
// memoizeDebounce key would default to the first argument unless a resolver
// is provided through `options` similar to how `memoize` caches the key
export function memoizeDebounce (func, wait = 0, options = {}) {
  var mem = memoize(function() {
    return debounce(func, wait, options);
  }, options.resolver);
  return function() { mem.apply(this, arguments).apply(this, arguments) };
}