import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { RevertItem, ThemesStoreInterface } from 'stores/ThemesStore';
import RevertForm from './RevertForm';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

export interface RevertButtonProps {
  hasDraft: boolean;
  disabled: boolean;
  reverting: boolean;
  surveyName: string;
  revertItems: RevertItem[];
}

export interface RevertButtonState {
  working: boolean;
  focusedRevertIndex?: number;
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}
const withHocs = compose(
  inject('themesStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class RevertButton extends React.Component<
  RevertButtonProps,
  RevertButtonState
> {
  state = {
    working: false,
    focusedRevertIndex: undefined as undefined | number
  };
  get injected(): InjectedProps {
    return this.props as RevertButtonProps & InjectedProps;
  }
  onClick = async () => {
    const { themesStore, themeEditorSessionStore } = this.injected;
    const { focusedRevertIndex } = this.state;

    if (focusedRevertIndex === undefined) {
      return;
    }

    this.setState({ working: true, focusedRevertIndex: undefined });

    await themesStore.revert(focusedRevertIndex);
    themeEditorSessionStore.addEvent({
      timestamp: Date.now(),
      type: 'Session',
      subType: 'Reset'
    });

    this.setState({ working: false });
  };
  cancelModal = () => {
    this.setState({ focusedRevertIndex: undefined });
  };
  showModal = (index: number) => {
    this.setState({ focusedRevertIndex: index });
  };
  revertLabelling = () => {
    let label = '';
    let updatedBy = '';
    const { revertItems } = this.props;
    const { focusedRevertIndex: id } = this.state;
    const selected = find(revertItems, { id });

    if (selected) {
      label = selected.label;
      updatedBy = selected.updatedBy;
    }

    return { updatedBy, label };
  };
  render() {
    const {
      revertItems,
      reverting,
      hasDraft,
      disabled,
      surveyName
    } = this.props;
    const { working, focusedRevertIndex } = this.state;
    const { label, updatedBy } = this.revertLabelling();
    return (
      <>
        <Dropdown
          direction="right"
          disabled={
            reverting || disabled || (!hasDraft && revertItems.length <= 0)
          }
          icon={null}
          trigger={
            <Button
              loading={reverting || working}
              className="nw-revert-dropdown-button"
              size="small"
            >
              Revert draft
              <FontAwesomeIcon icon="chevron-down" className="icon" />
            </Button>
          }
        >
          <Dropdown.Menu direction="right">
            {hasDraft && [
              <Dropdown.Item
                key={-1}
                as={Button}
                fluid={true}
                className="nw-revert-dropdown-current-themes"
                onClick={() => {
                  this.showModal(-1);
                }}
              >
                Current themes
              </Dropdown.Item>,
              <Dropdown.Divider key={'currentItem_Divider'} />
            ]}
            {revertItems.map(revertItem => (
              <Dropdown.Item
                key={revertItem.id}
                as={Button}
                fluid={true}
                onClick={() => {
                  this.showModal(revertItem.id);
                }}
              >
                {revertItem.label}
                <span className="dropdown-updatedby">
                  {' — ' + revertItem.updatedBy}
                </span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {focusedRevertIndex !== undefined && (
          <RevertForm
            surveyName={surveyName}
            revertLabel={label}
            by={updatedBy}
            onAccept={this.onClick}
            onClose={this.cancelModal}
          />
        )}
      </>
    );
  }
});
