import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Input, LabelProps } from 'semantic-ui-react';
import LabelList from 'components/Shared/LabelList';
import { isEmail } from '../../../validations';
import './email-action.scss';

interface EmailActionProps {
  existingEmails?: string[];
  enteredEmails: (emailsObject: EmailsObject) => void;
}

export interface EmailsObject {
  emails: string[];
  enteredValue: string | undefined;
  enteredValueValidation: boolean | undefined;
}
export interface EmailActionState {
  emails: string[];
  enteredEmail: string;
  validation: JSX.Element | undefined;
  isValid: boolean;
}

@observer
class EmailAction extends React.Component<EmailActionProps, EmailActionState> {

  state = {
    emails: this.props.existingEmails ? this.props.existingEmails : [],
    enteredEmail: '',
    validation: undefined,
    isValid: true,
  } as EmailActionState;

  removeEmail = (e: React.MouseEvent<HTMLElement>, props: LabelProps) => {
    const emails = this.state.emails.filter(email => email !== props.content);
    this.setState({ emails }, () => this.updateEnteredEmails());

  };

  onBlur = () => {
    const { emails, enteredEmail } = this.state;
    if (!enteredEmail) {
      return;
    }
    const invalid = isEmail(enteredEmail);
    this.setState({ validation: invalid });
    if (!invalid && !emails.includes(enteredEmail)) {
      emails.push(enteredEmail);
      this.setState({ emails, enteredEmail: '' }, () => this.updateEnteredEmails());
    }
  };

  updateEmail = (e: React.FormEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    value = value.trim();
    const isValid = !value || !isEmail(value);
    this.updateEnteredEmails((isValid && value.length > 0), value);
    this.setState({
      isValid,
      enteredEmail: value,
      validation: undefined
    });
  };

  updateEnteredEmails = (validation?: boolean, value?: string) => {
    const { emails } = this.state;
    const emailsObject = {
      emails: emails,
      enteredValue: value,
      enteredValueValidation: validation
    };
    this.props.enteredEmails(emailsObject);
  }

  onEmailKeypress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): false | undefined => {
    const { emails, enteredEmail } = this.state;
    if (e.key === 'Escape') {
      this.setState({ enteredEmail: '' });
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else if (
      e.key === 'Enter' ||
      e.key === ' ' ||
      e.key === ',' ||
      e.key === 'Tab'
    ) {
      const trimmedEmail = enteredEmail.trim();
      if (!emails.includes(trimmedEmail)) {
        const invalid = isEmail(trimmedEmail);

        this.setState({ validation: invalid });
        if (!invalid) {
          emails.push(trimmedEmail);
          // wait a tick so that `updateEmail` has fired before clearing out proposedEmail
          setTimeout(() => {
            this.setState({ emails, enteredEmail: '' }, () => this.updateEnteredEmails());
          });
        }
      }
    }
    return;
  };

  render(): JSX.Element | null {
    const { emails, isValid, enteredEmail, validation } = this.state;
    return (
      <div className="email-section">
        Send to
        <LabelList
          size="small"
          itemClassName="green-border"
          items={emails}
          onRemove={this.removeEmail}
        />
        <Input
          action={
            <Button onClick={this.onBlur}>
              <FontAwesomeIcon icon="plus" className="icon large blue" />
            </Button>
          }
          error={!isValid}
          placeholder="Add email addresses"
          type="email"
          value={enteredEmail}
          onChange={this.updateEmail}
          onKeyDown={this.onEmailKeypress}
        />
        {validation}
      </div>
    );
  }
}

export default EmailAction;