import { AnalysisFilter } from 'api/interfaces';
import * as React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Category, FilterCut } from './types';

interface Props {
  filter: AnalysisFilter;
  category: Category;
  updateCategory: (categoryUpdate: Category) => void;
}

export const ExportAssistantFilterValuesSelect: React.FC<Props> = ({
  filter,
  category,
  updateCategory,
}) => {
  if (!filter?.cuts) {
    return <div>No filter values available for this filter.</div>;
  }

  const options = filter.cuts.map(cut => ({
    key: cut.id || '',
    text: cut.name || '',
    value: cut.id || '',
  }));

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const selectedValues = data.value as string[];

    const filterCuts: FilterCut[] = filter.cuts
      ?.filter(option => selectedValues.includes(option.id || ''))
      .map(cut => ({
        rql: cut.rql,
        name: cut.name,
        value: cut.id || '',
      })) || [];

    updateCategory({
      ...category,
      filterCuts,
    });
  };

  return (
    <div>
      <h4>{filter.name}</h4>
      <Dropdown
        style={{ display: 'inline-block' }}
        placeholder={`Select ${filter.name}`}
        fluid
        multiple
        selection
        options={options}
        value={category.filterCuts?.map(cut => cut.value) || []}
        onChange={handleChange}
      />
    </div>
  );
};