<template>
  <div
    v-loading="loading"
    class="theme-waterfall ob-waterfall-chart"
  >
    <el-alert
      v-if="error"
      :title="`Something went wrong. Try reloading. ${error}`"
      type="error"
      show-icon
    />
    <div v-if="validPeriod && validData && !error && !noDataError">
      <theme-waterfall-tile
        :data="waterfallData"
        :selected-theme="selectedTheme"
        @selectTheme="selectTheme"
      />
      <el-dropdown
        :hide-on-click="false"
        class="float-right button-inset"
        trigger="click"
      >
        <el-button
          class="chart-action"
          size="small"
          type="secondary"
        >
          Options
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <div class="chart-options">
            <div class="chart-option">
              <div class="chart-option-label">
                Compare to:
              </div>
              <el-radio-group
                :value="compareTo"
                @input="handleCompareToChange"
              >
                <ul class="chart-option-list">
                  <li class="chart-option-item">
                    <el-radio
                      label="previousPeriod"
                    >
                      Previous Period
                    </el-radio>
                  </li>
                  <li>
                    <el-radio
                      label="lastYear"
                    >
                      Last Year
                    </el-radio>
                  </li>
                </ul>
              </el-radio-group>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <subtheme-contribs
        v-if="selectedTheme && selectedSubthemes.length"
        class="subtheme-margin-top"
        :score="selectedScore.name"
        :subthemes="selectedSubthemes"
        :current-label="currentLabel"
        :current-total="currentTotal"
        :previous-label="previousLabel"
        :previous-total="previousTotal"
        @close="selectTheme(null, null)"
      />
    </div>
    <div
      v-else
      class="theme-waterfall__nothing"
    >
      <h4>Not enough data for score analysis</h4>
      Please select a different time period or clear the filters to view theme changes
    </div>
  </div>
</template>

<script>
import {
first,
find,
get,
map,
sortBy
} from 'lodash';


import analytics from 'lib/analytics';
import ThemeWaterfallTile from 'vue/components/Tiles/ThemeWaterfall.vue';
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';
import toFixed from 'vue/libs/to-fixed';

import SubthemeContribs from 'vue/over-time/SubthemeContribs';
import { getAnalysisToolsUIStore } from 'stores/RootStore';

let subthemeId = 0;
export default {
  name: 'ThemeWaterfall',
  components: { SubthemeContribs, ThemeWaterfallTile, },
  props: {
    error: { default: null, type: [String, null] },
    noDataError: { type: Boolean },
    loading: { type: Boolean },
    compareTo: { type: String },
    selectedTheme: { default: null, type: String },
    selectedScore: { type: Object },
    comparisonData: { default: null, type: Object },
    currentPeriod: { default: undefined, type: Object },
    previousPeriod: { default: undefined, type: Object },
    selectedScoreExternal: { default: undefined, type: Object },
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
    };
  },
  computed: {
    waterfallData() {
      return {
        contributions: this.contributions,
        noTextContribution: this.noTextContribution,
        previous: {
          score: this.previousScore,
          label: this.previousLabel,
          total: this.previousTotal
        },
        current: {
          score: this.currentScore,
          label: this.currentLabel,
          total: this.currentTotal
        },
        themes: this.themes
      }
    },
    currentTotal() {
      const counts = get(this, 'comparisonData.counts');
      if (counts) {
        return nonemptyCountFromCountsObject(counts);
      }
      return 0;
    },
    currentScore() {
      return get(this, 'comparisonData.scoreAllRows.score');
    },
    currentLabel() {
      return get(this, 'comparisonData.period');
    },
    previousTotal() {
      const counts = get(this, 'comparisonData.previousCounts');
      if (counts) {
        return nonemptyCountFromCountsObject(counts);
      }
      return 0;
    },
    previousScore() {
      return get(this, 'comparisonData.previousScoreAllRows.score');
    },
    previousLabel() {
      return get(this, 'comparisonData.previousPeriod');
    },
    themes() {
      return get(this, 'comparisonData.themes');
    },
    noTextContribution() {
      const value = get(this, 'comparisonData.unthemedWeighting', 0.0);
      return value;
    },
    contributions() {
      const { themes } = this;

      const contributions = map(themes, (theme, title) => {
        const subthemes = map(theme.subthemes, (subtheme, title) => {
          const { changeWeighting } = subtheme;
          return {
            ...subtheme,
            changeWeighting,
            key: `${++subthemeId}`,
            title,
            label: toFixed(changeWeighting, 2)
          };
        });
        return {
          ...theme,
          title,
          label: toFixed(theme.changeWeighting, 2),
          value: theme.changeWeighting,
          subthemes
        };
      });
      return sortBy(contributions, theme => -Math.abs(theme.value));
    },
    selectedSubthemes() {
      const { contributions, selectedTheme } = this;
      const theme = find(contributions, { title: selectedTheme });
      if (selectedTheme && theme) {
        return theme.subthemes;
      } else {
        return [];
      }
    },
    validPeriod() {
      const { currentPeriod, previousPeriod } = this;

      return currentPeriod && previousPeriod && currentPeriod.period && previousPeriod.period;
    },
    validData() {
      // Weighting isn't valid if changeWeighting is much higher than the score movement
      // This is to avoid trying to show the waterfall graph in the event of bad data
      const scoreMovement = Math.abs(this.previousScore - this.currentScore);
      const maxValidWeighting = Math.max(10, scoreMovement * 4);
      return !this.contributions.find(c => c.changeWeighting > maxValidWeighting);
    }
  },
  updated() {
    if (!this.selectedTheme && this.contributions.length > 0) {
      this.selectTheme(first(this.contributions).title, null);
    }
  },
  methods: {
    handleCompareToChange(compareTo) {
      this.$emit('onCompareToChange', compareTo);
    },
    selectTheme(theme, subtheme) {
      this.analysisToolsUIStore.setThemeState({
        theme: theme ? theme.title : '',
        subtheme: subtheme ? subtheme.title: '' });
      analytics.track('Waterfall: Selected Theme', { category: 'Overtime' });
    },
  }
};
</script>

<style lang="scss">
@import '../../vue/styles/element-variables';
.theme-waterfall {
  padding-bottom: 5px;
  &__nothing {
    text-align: center;
    padding: 90px;
    background-color: $--neutral-100;
    h4 {
      text-align: center;
      color: $--neutral-700;
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
      margin: 0;
      padding-bottom: 10px;
      text-transform: none;
      border-top: none;
    }
  }

  .subtheme-margin-top {
    margin-top: 35px;
  }

  .button-inset {
    margin-right: 10px;
  }
}
</style>
