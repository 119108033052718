import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScoreType } from 'api/enums';
import GripDotsVerticalIcon from 'images/icons/grip-dots-vertical.svg';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, DropdownProps, Form, Input, InputProps } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface } from 'stores/ui/ConfigureDataUIStore';
import { FieldError, required } from 'validations';
import ConfigureScoreOptions from './ConfigureScoreOptions';
import { SCORE_TYPE_OPTIONS } from './constants';
import './sortable-column.scss';

interface ConfigureScoreStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureScoreProps extends ConfigureScoreStoreProps {
  id: string;
  toggleForbidDrag: (forbidDrag: boolean) => void;
}

interface ConfigureScoreState {
  nameError: FieldError;
  showAdvancedOptions: boolean;
}

@inject('configureDataUIStore')
@observer
export default class ConfigureScore extends React.Component<ConfigureScoreProps, ConfigureScoreState> {

  state = {
    nameError: undefined,
    showAdvancedOptions: false
  };

  get score () {
    const { id, configureDataUIStore } = this.props;
    return configureDataUIStore!.getScore(id);
  }

  get hasAdvancedOptions () {
    const scoreType = (this.score || {}).score_type;

    // For now we only show the cog for score type threshold
    return scoreType && scoreType === ScoreType.THRESHOLD;
  }

  handleNameChange = ({ value }: InputProps) => {
    if (!this.score) {
      return;
    }

    const { id, configureDataUIStore } = this.props;
    const change = { ...this.score, name: value };

    const nameError = required(value);

    /* Only update the error count when valid status changes to avoid updating it on each change */
    if (nameError !== this.state.nameError) {
      configureDataUIStore!.updateErrorCount(!!nameError);
    }

    this.setState({ nameError });

    configureDataUIStore!.updateScore(id, change);
  }

  handleTypeChange = ({ value }: DropdownProps) => {
    this.changeScoreType(value as ScoreType);
  }

  changeScoreType = (scoreType: ScoreType) => {
    if (!this.score) {
      return;
    }

    const { id, configureDataUIStore } = this.props;
    const change = { ...this.score, score_type: scoreType as ScoreType };

    configureDataUIStore!.updateScore(id, change);

    if (scoreType === ScoreType.THRESHOLD) {
      this.showAdvancedOptions();
    }
  }

  removeScore = () => {
    const { id, configureDataUIStore } = this.props;
    /* Remove the associated error count if invalid */
    if (this.state.nameError) {
      configureDataUIStore!.decrementErrorCount();
    }
    configureDataUIStore!.removeScore(id);
  }

  showAdvancedOptions = () => {
    this.setState({ showAdvancedOptions: true });
  }

  hideAdvancedOptions = () => {
    this.setState({ showAdvancedOptions: false });
  }

  checkScoreOptions = () => {
    const { score_type, score_options } = this.score || {};
    const { threshold } = score_options || {};

    // If score type is threshold and there is no value for threshold specified, we reset it to
    // default score type, this could happen if threshold type was chosen for a score and
    // modal to set threshold was cancelled before setting a value for threshold
    if (score_type === ScoreType.THRESHOLD && threshold === undefined) {
      this.changeScoreType(ScoreType.AVERAGE);
    }
    this.hideAdvancedOptions();
  }

  render() {
    if (!this.score) {
      return null;
    }

    const { toggleForbidDrag } = this.props;
    const { name, score_type } = this.score;
    const { nameError, showAdvancedOptions } = this.state;
    const namePlaceHolder = nameError ? 'Name is required' : 'Name';

    if (showAdvancedOptions) {
      return <ConfigureScoreOptions
        score={this.score}
        onClose={this.hideAdvancedOptions}
        onCancel={this.checkScoreOptions}
      />;
    }

    return (
      <div className="sortable-column">
        <GripDotsVerticalIcon />
        <div className="column-name">
          <Form.Field
            error={!!nameError}
          >
            <Input
              onFocus={() => toggleForbidDrag(true)}
              onBlur={() => toggleForbidDrag(false)}
              placeholder={namePlaceHolder}
              value={name}
              onChange={(_e, data) => this.handleNameChange(data)}
            />
          </Form.Field>
        </div>
        <div className="column-type">
          <Form.Field>
            <label>Type</label>
            <Dropdown
              options={SCORE_TYPE_OPTIONS}
              placeholder="Select"
              value={score_type}
              selectOnBlur={false}
              onChange={(_e, data) => this.handleTypeChange(data)}
            />
          </Form.Field>
        </div>
        <div className="column-actions">
          {this.hasAdvancedOptions &&
            <Button
              basic={true}
              onClick={this.showAdvancedOptions}
              key="settings"
              size="small"
              className="settings"
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="cog"
                  fixedWidth={true}
                />
              }
            />
          }
          <Button
            basic={true}
            onClick={this.removeScore}
            key="delete"
            size="small"
            className="delete"
            icon={
              <FontAwesomeIcon
                className="icon"
                icon="trash-alt"
                fixedWidth={true}
              />
            }
          />
        </div>
      </div>
    );
  }
}
