import LogoIcon from 'images/logo.svg';
import { parse } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Header, Message } from 'semantic-ui-react';

interface SsoErrorProps extends RouteComponentProps<{ message: string }> {}

export default class SsoError extends React.Component<SsoErrorProps> {
  render() {
    const search = parse(this.props.location.search);
    const message = search.message || 'Unable to login via Single Sign On.';
    return (
      <div className="login-grid">
        <Header as="h1">
          <a
            href="https://getthematic.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoIcon />
          </a>
        </Header>
        <Container text={true}>
          <Message negative={true}>
            <Message.Header>
              Uh oh! We can't log you in with Single Sign On.
            </Message.Header>
            <p>Something went wrong. Here's the message we got:</p>
            <blockquote>
              <code>{message}</code>
            </blockquote>
          </Message>{' '}
          <p>
            <a href="/#/?ssoModal=open">Click here</a> to try again.
          </p>
          <p>
            Tried again and still can't log in?{' '}
            <a
              href="mailto:support@getthematic.com?subject=SSO%20Login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact support.
            </a>
          </p>
        </Container>
      </div>
    );
  }
}
