<template>
  <div
    ref="scoreGraphParent"
    v-loading="loading"
    class="tile--score-graph"
  >
    <div
      class="selectors"
    >
      <div
        v-if="showScoreSelector"
        class="score-selector"
      >
        <el-select
          v-model="selectedScore"
          class="el-select--inline"
          size="small"
          value-key="key"
          popper-class="select-popper"
        >
          <el-option
            v-for="item in scores"
            :key="item.key"
            :label="item.name"
            :value="item"
          />
        </el-select>
      </div>
      <div
        class="resolution-selector"
      >
        <el-select
          v-model="selectedDateResolution"
          class="el-select--inline"
          size="small"
          value-key="key"
          popper-class="select-popper"
        >
          <el-option
            v-for="item in dateResolutions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>

    <div class="widget score-graph__chart">
      <div
        ref="scoreGraph"
        className="score-graph"
      />
    </div>
  </div>
</template>

<script>
import { get, indexOf, last } from 'lodash';
import { doFetch, doFetchCounts } from 'vue/components/Tiles/Utils/score-graph-fetcher';
import { setupRequestOptions } from 'vue/libs/setup-request-options';
import { getInitConfigStore } from 'stores/RootStore';
import {
  getScoreGraphToolTip,
  renderScoreGraphData
} from 'charts/Utils/scoreGraph';

import { watchAll } from 'vue/libs/vueExt';

import configMixin from 'vue/tools/Utils/configMixin';

export default {
  name: 'ScoreGraph',
  mixins: [configMixin],
  props: {
    hideSelectedState: { default: false, type: Boolean },
    selections: { type: Object }
  },
  data() {
    return {
      data: undefined,
      errorMessage: undefined,
      loading: true,
      selectedDateLabel: undefined,
      selectedDateResolution: undefined,
      dateResolutions: [
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'quarterly', label: 'Quarterly' },
        { value: 'biannually', label: 'Biannually' },
        { value: 'rolling90', label: '90 day rolling' },
      ],
      initConfigStore: getInitConfigStore(),
      d3Tip: undefined,
    };
  },
  computed: {
    chartDateResolution() {
      return this.initConfigStore.getChartDateResolution;
    },
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    scoreColumnId() {
      return this.initConfigStore.scoreColumnId;
    },
    currentTimePeriod() {
      const { dateIndex, dateRanges } = this;
      return dateRanges[dateIndex] || '';
    },
    currentTimePeriodRql() {
      const { dateIndex, dateQueries } = this;
      return dateQueries[dateIndex] || '';
    },
    dateLabels() {
      return get(this, 'data.baseline.dateLabels', []);
    },
    dateQueries() {
      return get(this, 'data.baseline.dateQueries', []);
    },
    dateRanges() {
      return get(this, 'data.baseline.dateRanges', []);
    },
    previousTimeLabel() {
      const { dateIndex, dateLabels } = this;
      return dateLabels[dateIndex - 1] || '';
    },
    previousTimePeriod() {
      const { dateIndex, dateRanges } = this;
      return dateRanges[dateIndex - 1] || '';
    },
    previousTimePeriodRql() {
      const { dateIndex, dateQueries } = this;
      return dateQueries[dateIndex - 1] || '';
    },
    dateIndex() {
      const { selectedDateLabel, dateLabels } = this;
      return indexOf(dateLabels, selectedDateLabel);
    },
  },
  watch: {
    selectedScore(selectedScore) {
      this.$emit('selectedScoreChanged', selectedScore);
    },
    hideSelectedState: {
      handler(newValue) {
        if (newValue) {
          this.fetchData();
        }
      }
    },
    selectedDateResolution(dateResolution) {
      // reset selected date
      this.selectedDateLabel = undefined;

      this.initConfigStore.setChartDateResolution(dateResolution);
      this.requestOptions.dateResolution = dateResolution;
      this.fetchData();
    }
  },
  mounted() {
    window.addEventListener('resize', this.renderChart);
    watchAll(
      this,
      ['selectedScore', 'selections'],
      this.fetchData
    );
    if (this.selections) {
      this.fetchData();
    }
    this.$emit('selectedScoreChanged', this.selectedScore);
    this.selectedDateResolution = this.initConfigStore.getChartDateResolution;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderChart);
    this.d3Tip?.destroy();
  },
  methods: {
    fetchData: async function() {
      this.loading = true;
      const requestOptions = setupRequestOptions(this.requestOptions, {
        scoreCommentsOnly: false,
        scoreColumn: this.scoreColumn,
        scoreType: this.scoreType,
        scoreOptions: this.scoreOptions
      });
      if (this.chartDateResolution !== null) {
        requestOptions.dateResolution = this.initConfigStore.getChartDateResolution;
      }

      const dateMaxPast = this.initConfigStore.dateMaxPast;

      if (dateMaxPast !== null) {
        requestOptions.dateMaxPast = dateMaxPast;
      }
      const { commentColumns, selections } = this;
      try {
        const opts = { commentColumns, requestOptions, selections };

        const data = await doFetch(opts);
        if (!data) {
          return;
        }

        this.data = Object.freeze(data);

        this.renderChart();

        this.selectLast();
      } catch (e) {
        this.errorMessage = e;
      } finally {
        this.loading = false;
      }
    },
    getChartWidth() {
      const el = this.$refs.scoreGraphParent;
      return el.getBoundingClientRect().width;
    },
    renderChart() {
      const { data } = this;

      if (!data) {
        return;
      }

      const el = this.$refs.scoreGraph;
      const options = this.getScoreGraphOptions();

      this.d3Tip?.destroy();
      this.d3Tip = getScoreGraphToolTip(options);

      renderScoreGraphData({
        el,
        options,
        tip: this.d3Tip,
      });
    },
    getScoreGraphOptions() {
      const {
        baseline,
        comparison,
        yAxisLabel,
        combinedData
      } = this.data;
      return {
        baselineTitle: `${baseline.title} (${baseline.count})`,
        comparisonTitle: `${comparison.title}: (${comparison.count})`,
        data: combinedData,
        labels: baseline.dateLabels,
        chartLabel: yAxisLabel,
        onScoreGraphClick: this.onScoreGraphClick,
        barChartWidth: this.getChartWidth(),
        chartUnit: '',
        isComparison: false,
        startFromZero: false,
        tipValueUnit: '',
        showTotalCommentsInTip: false
      };
    },
    onScoreGraphClick(label, isExplicitSelection = true) {
      const { selections, commentColumns } = this;
      const requestOptions = setupRequestOptions(this.requestOptions, {
        scoreCommentsOnly: false,
        scoreColumn: this.scoreColumn,
        scoreType: this.scoreType,
        scoreOptions: this.scoreOptions
      });

      // handle point selection
      this.selectedDateLabel = label;

      // get counts, and once they are ready emit a date selected (which will include counts)
      // uses promises to ensure we have the data necessary before emitting
      doFetchCounts({
        commentColumns,
        selections,
        timePeriodRql: this.currentTimePeriodRql,
        requestOptions
      }).then( counts => {
        this.emitDateSelected(counts, isExplicitSelection);
      },
      () => {
        this.emitDateSelected(undefined, isExplicitSelection);
      });

    },
    emitDateSelected(counts, isExplicitSelection) {
      this.$emit(
          'onDateSelected',
          this.selectedDateLabel,
          this.currentTimePeriodRql,
          this.currentTimePeriod,
          counts,
          this.previousTimeLabel,
          this.previousTimePeriodRql,
          this.previousTimePeriod,
          isExplicitSelection
      );
    },
    selectLast() {
      const { dateLabels } = this;
      const label = last(dateLabels);
      this.onScoreGraphClick(label, false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.tile--score-graph {
  background: $--neutral-100;
  margin: auto;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 100%;

  .selectors {
    display: flex;
    padding: 0 15px 20px;
  }

  .score-selector,
  .resolution-selector {
    font-weight: 600;
    text-align: left;
  }
}
.score-graph__chart {
  display: flex;
  .score-graph__canvas {
    flex: 1 1 auto;
    margin-left: 40px;
    min-width: 320px;
  }
  .el-dropdown {
    align-self: flex-start;
    display: block;
    flex: 0 0 80px;
  }
  .option-button {
    min-width: 80px;
    margin: 5px 5px;
  }
}
.score-graph__unlock-row {
  text-align: right;
  padding-bottom: 15px;
  padding-right: 15px;
}
</style>
