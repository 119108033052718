import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceQuestionProCreateFormProps {
  existingConfiguration?: QuestionProConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface QuestionProInfo {
  name: string;
  surveyID: string;
  status: string;
  creationDate: string;
  completedResponses: string;
}

interface QuestionProConfig {
  surveyId: string;
}

export interface DataSourceQuestionProCreateFormState {
  selectedSurvey?: QuestionProInfo;
  config?: QuestionProConfig;
}

@observer
class DataSourceQuestionProCreateForm extends React.Component<
DataSourceQuestionProCreateFormProps,
DataSourceQuestionProCreateFormState
> {
  state = {} as DataSourceQuestionProCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedSurvey = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedSurvey = JSON.parse(value);

    const config = {
      surveyId: String(selectedSurvey.surveyID),
    };

    this.setState({ selectedSurvey, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedSurvey.surveyID);
    }
  }

  setDefaultSurvey = (availableSurveys: QuestionProInfo[]): void => {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    if (availableSurveys.length === 0 || !existingConfiguration || selectedSurvey) {
      return;
    }

    const defaultSelectedSurvey = this.getExistingSurvey(availableSurveys, existingConfiguration);
    if (!defaultSelectedSurvey) {
      return;
    }

    this.setState({
      selectedSurvey: defaultSelectedSurvey,
      config: existingConfiguration,
    });
  }

  getExistingSurvey = (
    availableSurveys: QuestionProInfo[],
    existingConfiguration?: QuestionProConfig
  ) => {
    return availableSurveys.find(({ surveyID }) => existingConfiguration?.surveyId === surveyID);
  }

  renderForm(availableSurveys: QuestionProInfo[] | undefined): JSX.Element | null {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    if (!availableSurveys) {
        return null;
    }

    const defaultValue = existingConfiguration
    ? JSON.stringify(this.getExistingSurvey(availableSurveys, existingConfiguration))
    : undefined;

    // create the source type options as list for dropdown
    const availableSurveyOptions = availableSurveys.map((item) => {
      return {
        text: `[${item.surveyID}]\t${item.name}`,
        value: JSON.stringify(item)
      };
    });

    return (
    <Form role="form">
      <Form.Field>
        <label>Survey</label>

        <Dropdown
          selection={true}
          options={availableSurveyOptions}
          defaultValue={defaultValue}
          placeholder="Select"
          onChange={this.changeSelectedSurvey}
        />
      </Form.Field>

      { selectedSurvey && (
        <div>
          <Form.Field>
            <label>Title</label> <span>{selectedSurvey.name}</span>
          </Form.Field>
          <Form.Field>
            <label>Survey ID</label> <span>{selectedSurvey.surveyID}</span>
          </Form.Field>
          <Form.Field>
            <label>Created</label> <span>{selectedSurvey.creationDate}</span>
          </Form.Field>
          <Form.Field>
            <label>Status</label> <span>{selectedSurvey.status}</span>
          </Form.Field>
          <Form.Field>
            <label>Completed Responses</label> <span>{selectedSurvey.completedResponses}</span>
          </Form.Field>
        </div>
      )}
    </Form>
    );
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={(availableSurveys: QuestionProInfo[]) => this.setDefaultSurvey(availableSurveys)}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.QUESTIONPRO} />
      </div>
    );
  }
}

export default DataSourceQuestionProCreateForm;
