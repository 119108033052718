<template>
  <div class="config-field">
    <label>
      Ordering
    </label>
    <el-select
      v-model="selectedOrdering"
      placeholder="Select"
      @change="onOrderingChange"
    >
      <el-option
        v-for="item in orderingOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { DashboardWidgetType, WidgetOrderingValue } from 'api/enums';
import { WIDGET_ORDERING_OPTIONS } from '../constants';


export default {
  name: 'ConfigureWidgetOrdering',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
    selectedWidget: { type: String }
  },
  data() {
    return {
      selectedOrdering: undefined,
    }
  },
  computed: {
    orderingOptions() {
      return WIDGET_ORDERING_OPTIONS;
    },
  },
  mounted() {
    const { ordering, type } = this.widgetConfig;
    if (ordering) {
      this.selectedOrdering = this.widgetConfig.ordering;
    } else if (type === DashboardWidgetType.IMPACT_CHANGES || this.selectedWidget === DashboardWidgetType.IMPACT_CHANGES) {
      // Difference is the default value for impact changes widget if nothing is specified
      this.selectedOrdering = WidgetOrderingValue.DIFFERENCE;
    }
  },
  methods: {
    onOrderingChange() {
      this.$emit('onConfigChange', this.target, this.selectedOrdering, true);
    }
  }
}
</script>