<template>
  <div>
    <div
      v-if="showTitle"
      class="widget__header"
    >
      <div>Trends for</div>
      <div class="widget__header-theme">
        {{ selectedThemeName }}
      </div>
    </div>
    <div
      class="widget__body"
      :class="{ 'both-tiles': hasScore }"
    >
      <over-time-frequency-chart
        key-takeaways-key="frequency-chart"
        :selected-date-label="dateLabel"
        :selections="selections"
        :segment-query-strings="segmentQueryStrings"
        :selected-score-external="selectedScoreExternal"
        @dateSelected="dateSelected"
        @dateResolutionUpdated="dateResolutionUpdated"
      />
      <over-time-impact-chart
        v-if="hasScore"
        key-takeaways-key="impact-chart"
        :selected-score-external="selectedScoreExternal"
        :selected-date-label="dateLabel"
        :selections="selections"
        :segment-query-strings="segmentQueryStrings"
        :date-resolution="dateResolution"
        @dateSelected="dateSelected"
      />
    </div>
  </div>
</template>

<script>
import OverTimeFrequencyChart from 'vue/explore-detail/OverTimeFrequencyChart.vue';
import OverTimeImpactChart from 'vue/explore-detail/OverTimeImpactChart.vue';

import queryBuilder from 'vue/libs/queryBuilder';
import { getInitConfigStore, getExploreToolUIStore } from 'stores/RootStore';

export default {
  name: 'OverTimeChart',
  components: {
    OverTimeFrequencyChart,
    OverTimeImpactChart,
  },
  props: {
    keyTakeawaysKey: { type: String },
    selections: { default: undefined, type: Object },
    segmentQueryStrings: { default: undefined, type: Object },
    theme: { default: undefined, type: String },
    subtheme: { default: undefined, type: String },
    selectedScoreExternal: { default: undefined, type: Object },
    showTitle: { type: Boolean, default: true },
    dateLabel: { default: undefined, type: [String, undefined] }
  },
  data() {
    return {
      keyTakeaways: {},
      initConfigStore: getInitConfigStore(),
      dateResolution: 'monthly',
    };
  },
  computed: {
    selectedThemeName() {
      return getExploreToolUIStore().selectedThemeName;
    },
    hasScore() {
      return this.initConfigStore.hasScore;
    },
  },
  methods: {
    dateResolutionUpdated(newDateResolution) {
      this.initConfigStore.chartDateResolution = newDateResolution;
      this.dateResolution = newDateResolution;
    },
    dateSelected(label, query, counts) {
      const baselineQuery = queryBuilder.appendToFilter(this.selections.baseline.query, query);
      const comparisonQuery = queryBuilder.appendToFilter(this.selections.comparison.query, query);
      this.$emit(
        'onSelectionChanged',
        {
          baseline: baselineQuery,
          comparison: comparisonQuery,
        },
        counts,
        label,
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.card__clamped-header {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.card__actions {
  display: flex;
  flex-direction: column;
}
.card__option-action {
  align-self: flex-end;
}
</style>
