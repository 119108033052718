import { AutoCutSortType, AutoCutType, ColumnType, DateResolution, FilterType, InputColumnType, ScoreType } from 'api/enums';
import { FilterConfiguration, ScoreConfiguration, SurveyConfiguration, SurveySynopsis } from 'api/interfaces';
import { mapColumnType } from 'components/ConfigureSurvey/configure-survey-helper';
import { transformToMatchStructure } from 'lib/object-helper';
import { generateColumnId } from 'lib/survey-helpers';
import { compact, get, isEmpty, remove, uniq } from 'lodash';
import { action, computed, observable } from 'mobx';
import {
  DateAutoCutStructure,
  DateFilterStructure,
  FreetextFilterStructure, ListAutoCutStructure,
  ScoreAutoCutStructure
} from 'stores/data-structure/filter';

const DEFAULT_LIST_AUTO_CUT = {
  include_all: true,
  type: AutoCutType.LIST,
  options: {
    ordering: AutoCutSortType.ALPHABETICAL
  }
};

export interface ColumnInfo {
  index: number;
  colType: ColumnType | InputColumnType;
  sampleHeader: string;
  sample: string[];
  name: string;
}

export enum DisplayFilterType {
  DATE = 'date',
  FREETEXT = 'freetext',
  HIERARCHICAL = 'hierarchical',
  SCORE = 'score',
  DEFAULT = 'default'
}

export interface FilterConfig extends FilterConfiguration {
  id: string;
}

export interface ScoreConfig extends ScoreConfiguration {
  id: string;
}

export interface SurveyConfig extends SurveyConfiguration {
  filters?: FilterConfig[];
  scores?: ScoreConfig[];
}

export interface ConfigureDataUIStoreInterface {
  surveyId: string;
  surveyStatus: string;
  columns: ColumnInfo[];
  rawSurveyConfig: string;
  surveyConfig: SurveyConfig;
  hasChanged: boolean;
  errorCount: number;
  isValid: boolean;

  setColumns: (surveySynopsis: SurveySynopsis) => void;
  initializeSurvey: (surveyId: string, surveyConfig: string, surveyStatus: string) => void;

  getScore: (id: string) => ScoreConfig | undefined;
  getScoreByColumn: (column: number) => ScoreConfig | undefined;
  updateDate: (dateOptions: { date_column?: number, date_resolution?: DateResolution }) => void;
  updateUniqueId: (uniqueId: number[]) => void;

  addScore: (index: number, columnName: string) => void;
  removeScore: (id: string) => void;
  updateScore: (id: string, score: ScoreConfig) => void;
  updateScores: (scores: ScoreConfig[]) => void;

  createDefaultFilter: (
    columnIndex: number,
    columnName: string,
    cutType: AutoCutType,
    existingFilterIds: string[]
  ) => FilterConfig;

  getFilter: (id: string) => FilterConfig | undefined;
  addFilter: (filter: FilterConfig) => void;
  removeFilter: (id: string) => void;
  updateFilter: (id: string, filter: FilterConfig) => void;
  updateFilters: (filters: FilterConfig[]) => void;

  updateErrorCount: (hasError: boolean) => void;
  decrementErrorCount: () => void;

  prepareAPIData: () => SurveyConfiguration;

  reset: () => void;
}

class ConfigureDataUIStore implements ConfigureDataUIStoreInterface {
  @observable
  surveyId = '';

  @observable
  surveyStatus = '';

  @observable
  columns = [] as ColumnInfo[];

  @observable
  rawSurveyConfig = '';

  @observable
  surveyConfig = {} as SurveyConfig;

  @observable
  hasChanged = false;

  @observable
  errorCount = 0;

  @observable
  filterIdCounter = 0;

  @observable
  scoreIdCounter = 0;

  @computed
  get isValid() {
    return !this.errorCount;
  }

  @action
  setColumns = (surveySynopsis: SurveySynopsis) => {
    const columns = this.mapForDisplay(surveySynopsis, this.surveyConfig);
    this.columns = columns;
  }

  @action
  initializeSurvey = (surveyId: string, config: string, surveyStatus: string) => {
    this.surveyId = surveyId;
    this.surveyStatus = surveyStatus;

    this.filterIdCounter = 0;
    this.scoreIdCounter = 0;

    this.rawSurveyConfig = config;
    let surveyConfig = JSON.parse(this.rawSurveyConfig) as SurveyConfig;

    const { filters, scores } = surveyConfig;

    /* Give filters and scores unique ids to help with re-ordering */
    const existingFilterIds = (filters || []).map(f => f.id);
    if (!isEmpty(filters)) {
      surveyConfig.filters = filters!.map(filter => {
        const { id, name } = filter;
        return {
          ...filter,
          id: id ? id : generateColumnId(name, existingFilterIds)
        };
      });
    } else {
      // We add a search filter
      const searchFilterName = 'Response contains';
      surveyConfig.filters = [
        {
          id: generateColumnId(searchFilterName, existingFilterIds),
          name: searchFilterName,
          type: FilterType.FREETEXT
        }
      ];
      this.hasChanged = true;
    }

    if (!isEmpty(scores)) {
      const existingScoreIds = (scores || []).map(s => s.id);
      surveyConfig.scores = scores!.map(score => {
        const { id, name } = score;
        return {
          ...score,
          id: id ? id : generateColumnId(name, existingScoreIds)
        };
      });
    }
    this.surveyConfig = surveyConfig;
  }

  @action
  updateDate = (dateOptions: { date_column: number, date_resolution: DateResolution }) => {
    let { date_column, date_resolution } = dateOptions;

    /* Pre-select default values on selecting a date */
    if (this.surveyConfig.date_column !== date_column) {
      const { filters } = this.surveyConfig;
      const existingFilter = !!(filters || []).find(filter => filter.column === date_column);
      const existingFilterIds = (filters || []).map(filter => filter.id);

      if (!existingFilter) {
        const name = (this.columns.find(col => col.index === date_column) || {}).sampleHeader || 'Date';
        const dateFilter = {
          name,
          column: date_column,
          type: FilterType.DATE,
          id: generateColumnId(name, existingFilterIds)
        };
        this.addFilter(dateFilter);
      }
    }

    this.surveyConfig = { ...this.surveyConfig, date_column, date_resolution };
    this.hasChanged = true;
  }

  @action
  updateUniqueId = (uniqueId: number[]) => {
    this.surveyConfig = { ...this.surveyConfig, sort_column: uniqueId };
    this.hasChanged = true;
  }

  getScore = (id: string) => {
    return (this.surveyConfig.scores || []).find(score => score.id === id);
  }

  getScoreByColumn = (column: number) => {
    return (this.surveyConfig.scores || []).find(score => score.score_column === column);
  }

  @action
  addScore = (scoreIndex: number, columnName: string) => {
    const scoreColumn = this.columns.find(col => col.index === scoreIndex);
    const { scores } = this.surveyConfig;
    const existingScoreIds = (scores || []).map(s => s.id);
    if (scoreColumn) {
      const score = {
        name: columnName,
        score_column: scoreIndex,
        score_type: ScoreType.AVERAGE,
        id: generateColumnId(scoreColumn.sampleHeader, existingScoreIds)
      } as ScoreConfig;

      if (this.surveyConfig.scores) {
        this.surveyConfig.scores.push(score);
      } else {
        this.surveyConfig.scores = [score];
      }
    }

    // Add a filter for the new score, no type since a default filter
    const scoreFilterExists = !!(this.surveyConfig.filters || [])
      .find(filter => filter.column === scoreIndex);

    if (!scoreFilterExists) {
      const { filters } = this.surveyConfig;
      const existingFilterIds = (filters || []).map(filter => filter.id);
      const scoreFilter = this.createDefaultFilter(scoreIndex, columnName, AutoCutType.SCORE, existingFilterIds);
      this.addFilter(scoreFilter);
    }

    this.hasChanged = true;
  }

  @action
  removeScore = (id: string) => {
    const { scores } = this.surveyConfig;

    if (!scores) {
      return;
    }

    remove(scores, (score) => score.id === id);

    if (isEmpty(scores)) {
      this.surveyConfig.scores = undefined;
    }

    this.hasChanged = true;
  }

  @action
  updateScore = (id: string, score: ScoreConfig) => {
    if (!this.surveyConfig.scores) {
      return;
    }

    const index = (this.surveyConfig.scores || []).findIndex(s => s.id === id);

    if (index < 0) {
      return;
    }

    this.surveyConfig.scores[index] = score;

    const { filters } = this.surveyConfig;

    const existingScoreFilters = (filters || [])
      .filter(filter => filter.column === score.score_column);

    // Update the score type in the corresponding filters
    if (!isEmpty(existingScoreFilters)) {
      existingScoreFilters.forEach(existingFilter => {
        const auto_cut = existingFilter.auto_cut;
        const options = (auto_cut || {}).options;

        const filter = {
          ...existingFilter,
          auto_cut: {
            ...auto_cut,
            type: AutoCutType.SCORE,
            options: {
              ...options,
              score_type: score.score_type
            }
          }
        };
        this.updateFilter(existingFilter.id, filter);
      });
    }

    this.hasChanged = true;
  }

  @action
  updateScores = (scores: ScoreConfig[]) => {
    this.surveyConfig.scores = scores;
    this.hasChanged = true;
  }

  getFilter = (id: string) => {
    return (this.surveyConfig.filters || []).find(filter => filter.id === id);
  }

  createDefaultFilter = (
    columnIndex: number,
    columnName: string,
    cutType: AutoCutType,
    existingFilterIds: string[]
  ) => {
    const filter = {
      name: columnName,
      column: columnIndex,
      id: generateColumnId(columnName, existingFilterIds),
    } as FilterConfig;
    if (cutType === AutoCutType.LIST) {
      filter.auto_cut = {
        include_all: true,
        type: AutoCutType.LIST,
        options: {
          ordering: AutoCutSortType.ALPHABETICAL
        }
      };
    } else if (cutType === AutoCutType.SCORE) {
      filter.auto_cut = {
        include_all: true,
        type: AutoCutType.SCORE,
        options: {
          score_type: ScoreType.AVERAGE
        }
      };
    } else if (cutType === AutoCutType.DATE) {
      filter.auto_cut = {
        include_all: true,
        type: AutoCutType.DATE,
        options: {
          resolution: DateResolution.MONTHLY
        }
      };
    }
    return filter;
  }

  @action
  addFilter = (filter: FilterConfig) => {
    if (this.surveyConfig.filters) {
      this.surveyConfig.filters.push(filter);
    } else {
      this.surveyConfig.filters = [filter];
    }
    this.hasChanged = true;
  }

  @action
  removeFilter = (id: string) => {
    const { filters } = this.surveyConfig;

    if (!filters) {
      return;
    }

    remove(filters, (filter) => filter.id === id);

    this.hasChanged = true;
  }

  @action
  updateFilter = (id: string, filter: FilterConfig) => {
    if (!this.surveyConfig.filters) {
      return;
    }

    const index = (this.surveyConfig.filters || []).findIndex(f => f.id === id);

    if (index < 0) {
      return;
    }

    const previousFilterConfig = this.surveyConfig.filters[index];

    const previousAutoCutType = get(previousFilterConfig, 'auto_cut.type');
    const currentAutoCutType = get(filter, 'auto_cut.type');

    if (previousFilterConfig.type !== filter.type || previousAutoCutType !== currentAutoCutType) {
      this.surveyConfig.filters[index] = this.handleFilterTypeChange(filter);
    } else {
      this.surveyConfig.filters[index] = filter;
    }

    this.hasChanged = true;
  }

  @action
  updateFilters = (filters: FilterConfig[]) => {
    this.surveyConfig.filters = filters;
    this.hasChanged = true;
  }

  updateErrorCount = (hasError: boolean) => {
    if (hasError) {
      ++this.errorCount;
    } else {
      this.decrementErrorCount();
    }
  }

  @action
  decrementErrorCount = () => {
    if (this.errorCount > 0) {
      --this.errorCount;
    }
  }

  @action
  reset = () => {
    this.surveyStatus = '';
    this.surveyId = '';
    this.rawSurveyConfig = '';
    this.surveyConfig = {};
    this.columns = [];
    this.hasChanged = false;
  }

  mapForDisplay = (surveySynopsis: SurveySynopsis, surveyConfig: SurveyConfig) => {
    const { columns } = surveySynopsis;
    let mappedColumns = [] as ColumnInfo[];

    columns.forEach((column, index) => {
      const { colType, sample } = column;
      const sampleData = compact(uniq(sample));
      // Hide columns that don't have sample data until we can improve this experience
      if (isEmpty(sampleData)) {
        return;
      }

      const inputColumns = surveyConfig.input_columns || {};
      const columnType = mapColumnType(colType);
      const name = inputColumns[index]?.name || sampleData[0];
      mappedColumns.push({
        index,
        colType: inputColumns[index]?.type || columnType,
        sample: sampleData.slice(1), // Everything other than the first is sample values
        sampleHeader: name, // First value is the header
        name: name
      });
    });

    return mappedColumns;
  }

  prepareAPIData = () => {
    let surveyConfig = { ...this.surveyConfig };

    surveyConfig = this.processNames(surveyConfig);

    const inputColumns = this.prepareInputColumns(surveyConfig);
    surveyConfig.input_columns = inputColumns;

    return surveyConfig;
  }

  processNames = (surveyConfig: SurveyConfig) => {
    const filters = (surveyConfig.filters || []);
    const scores = (surveyConfig.scores || []);

    surveyConfig.filters = filters
      .map(filter => {
        const existingFilterIds = filters.map(f => f.id);
        let newFilter = { ...filter };
        newFilter.id = generateColumnId(filter.name, existingFilterIds);
        return newFilter;
      });

    if (!isEmpty(surveyConfig.scores)) {
      surveyConfig.scores = scores
        .map(score => {
          const existingScoreIds = scores.map(s => s.id);
          let newScore = { ...score };
          newScore.id = generateColumnId(score.name, existingScoreIds);
          return newScore;
        });
    }

    return surveyConfig;
  }

  prepareInputColumns = (surveyConfig: SurveyConfig) => {
    let { date_column, scores } = surveyConfig;
    let inputColumns = surveyConfig.input_columns || {};

    if (date_column !== undefined) {
      inputColumns[date_column] = {
        ...inputColumns[date_column] || {},
        type: InputColumnType.DATE};
    }

    if (scores) {
      scores.forEach(score => {
        inputColumns[score.score_column] = {
          ...inputColumns[score.score_column] || {},
          type: InputColumnType.NUMBER};
      });
    }

    return inputColumns;
  }

  handleFilterTypeChange = (filter: FilterConfig) => {
    let schema;

    if (!filter.type) {
      const { auto_cut } = filter;
      if (auto_cut) {
        const { type } = auto_cut;
        if (type === AutoCutType.LIST) {
          schema = ListAutoCutStructure;
        } else if (type === AutoCutType.DATE) {
          schema = DateAutoCutStructure;
        } else if (type === AutoCutType.SCORE) {
          schema = ScoreAutoCutStructure;
        }
      } else {
        // auto_cut won't exist when changing from a different filter type
        // in this case, we populate the default auto_cut config
        return { ...filter, auto_cut: DEFAULT_LIST_AUTO_CUT };
      }
    } else if (filter.type === FilterType.DATE) {
      schema = DateFilterStructure;
    } else if (filter.type === FilterType.FREETEXT) {
      schema = FreetextFilterStructure;
    } else {
      // TODO: Add data structure for hierarchical filter
      return filter;
    }

    let filterChange = transformToMatchStructure(filter, schema) as FilterConfig;

    return filterChange;
  }
}

export default ConfigureDataUIStore;