import { AggregateView } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { action, observable, reaction } from 'mobx';
import { OrganizationStoreInterface } from './OrganizationStore';

export interface AggregateViewStoreInterface {
  aggregateViews: AggregateView[];
  fetchingAggregateViews: boolean;
  fetchedAggregateViews: boolean;
  fetchAggregateViewsError: string;

  fetchAggregateViews: () => void;
}

class AggregateViewStore implements AggregateViewStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  aggregateViews: AggregateView[] = [];

  @observable
  fetchingAggregateViews = false;

  @observable
  fetchedAggregateViews = false;

  @observable
  fetchAggregateViewsError = '';

  constructor( currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;

    // React to orgId changes
    reaction(
      () => this.currentOrg.orgId,
      () => this.reset(),
      { fireImmediately: true }
    );
  }

  @action
  reset = () => {
    this.aggregateViews = [];
    this.fetchAggregateViewsError = '';
    this.fetchingAggregateViews = false;
    this.fetchedAggregateViews = false;
  };

  @action
  fetchAggregateViews = async () => {
    if (this.fetchedAggregateViews) {
      return;
    }
    const { orgIdQueryParam, orgId } = this.currentOrg;
    if (!orgId) {
      return;
    }
    const url = `/aggregateViews?${orgIdQueryParam}`;

    this.fetchingAggregateViews = true;
    this.fetchAggregateViewsError = '';

    try {
      const { ok, data: aggregateViews, errorData } = await Auth.fetch<AggregateView[]>(url);
      if (ok && aggregateViews) {
        this.aggregateViews = aggregateViews;
        this.fetchedAggregateViews = true;
      } else {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        this.fetchAggregateViewsError = errorMessage;
      }
    } catch (e) {
      this.fetchAggregateViewsError = `${e}`;
    } finally {
      this.fetchingAggregateViews = false;
    }
  }
}

export default AggregateViewStore;
