<template>
  <div class="config-field">
    <label>
      Theme level (optional)
    </label>
    <div
      class="config-radio-options"
    >
      <div
        v-for="item in themeLevelOptions"
        :key="item.value"
        class="config-radio-option"
      >
        <el-radio
          v-model="selectedThemeLevel"
          :label="item.value"
          @change="onThemeLevelChange"
        >
          {{ item.label }}
        </el-radio>
      </div>
    </div>
    <p class="note">
      Choose whether this widget shows the top base themes, or top themes across all subthemes.
    </p>
  </div>
</template>

<script>
import { WIDGET_THEME_LEVEL_OPTIONS } from '../constants';
import { WidgetThemeLevel } from '../../../../api/enums';

export default {
  name: 'ConfigureWidgetThemeLevel',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      selectedThemeLevel: undefined,
    }
  },
  computed: {
    themeLevelOptions() {
      return WIDGET_THEME_LEVEL_OPTIONS;
    },
  },
  mounted() {
    this.selectedThemeLevel = this.widgetConfig.themeLevel || WidgetThemeLevel.BASETHEME;
  },
  methods: {
    onThemeLevelChange() {
      this.$emit('onConfigChange', this.target, this.selectedThemeLevel, true);
    }
  }
}
</script>