import _ from 'lodash';

function isMatch(theme, searchFilter) {
  if (searchFilter.trim() === '') {
    return false;
  }
  return theme.title.toLowerCase().includes(searchFilter.toLowerCase());
}

function searchThemes(themes, searchFilter) {
  return themes.reduce((acc, theme) => {
    if (acc) {
      return acc;
    }

    const themeMatches = isMatch(theme, searchFilter);

    if (themeMatches) {
      return theme;
    }

    return theme.subthemes.reduce((acc_, st) => {
      if (acc_) {
        return acc_;
      }

      const subThemeMatches = isMatch(st, searchFilter);
      if (subThemeMatches) {
        return theme;
      }
      return acc_;
    }, null);
  }, null);
}

function getTheme(themeData, theme, subtheme) {
  let themeOut = _.get(themeData, `${theme}`, null);
  if (themeOut) {
    themeOut.name = theme;
    themeOut.level = 'theme';
    if (subtheme) {
      themeOut = _.get(themeOut, `subthemes.${subtheme}`, null);
      if (themeOut) {
        themeOut.name = subtheme;
        themeOut.level = 'subtheme';
      }
    } else if (Object.keys(themeOut.subthemes).length > 0) {
      themeOut.examples = _.get(themeOut, `subthemes.${Object.keys(themeOut.subthemes)[0]}`).examples;
    }
  }
  return themeOut;
}

function filterThemes(themes, filters) {
  if (!filters) {
    return themes;
  }
  const filteredThemes = _.reduce(
    themes,
    function (accumulator, themeDetail, themeName) {
      // filter out basethemes
      if (!_.includes(filters.basethemes, themeName)) {
        const themeOut = _.cloneDeep(themeDetail);
        themeOut.subthemes = _.reduce(
          themeOut.subthemes,
          function (accumulator2, subthemeDetail, subthemeName) {
            // filter out subthemes
            if (!_.includes(filters.subthemes, subthemeName)) {
              accumulator2[subthemeName] = subthemeDetail;
            }
            return accumulator2;
          },
          {},
        );
        accumulator[themeName] = themeOut;
      }
      return accumulator;
    },
    {},
  );
  return filteredThemes;
}

export default {
  searchThemes,
  getTheme,
  filterThemes,
};
