import { AnalysisDateFilter, AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import * as moment from 'moment';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';
import queryBuilder from 'vue/libs/queryBuilder';
import { assertUnreachable } from '../type-utils';
import { DateRange } from 'stores/FilterStore';

// order of these presets are important
export const dateRangePresets = [
  'all',
  'last31Days',
  'last90Days',
  'lastMonth',
  'thisMonth',
  'thisQuarter',
  'thisYear',
  'lastQuarter',
  'lastYear',
] as const;

export type DateRangePreset = typeof dateRangePresets[number];

export const getDefaultDateRangePreset = (filter: AnalysisDateFilter): DateRangePreset =>
  filter?.default_range === 'all' ? 'all' : 'last31Days';

export const toServerDate = (date: Date) => moment(date).format('YYYY-MM-DD');

export const fromServerDate = (dateString: string) => moment(dateString).toDate();

export const getDateRangePresetLabel = (preset: DateRangePreset) => {
  switch (preset) {
    case 'thisMonth':
      return 'This Month';
    case 'thisQuarter':
      return 'This Quarter';
    case 'thisYear':
      return 'This Year';
    case 'last31Days':
      return 'Last 31 Days';
    case 'last90Days':
      return 'Last 90 Days';
    case 'lastMonth':
      return 'Last Month';
    case 'lastQuarter':
      return 'Last Quarter';
    case 'lastYear':
      return 'Last Year';
    case 'all':
      return 'All';
    default:
      return assertUnreachable(preset);
  }
};

export const getDateRangePresetMoments = (preset: DateRangePreset, maxDate?: Date) => {
  switch (preset) {
    case 'thisMonth':
      return { start: moment().startOf('month'), end: moment() };
    case 'thisQuarter':
      return { start: moment().startOf('quarter'), end: moment() };
    case 'thisYear':
      return { start: moment().startOf('year'), end: moment() };
    case 'last31Days':
      return { start: moment(maxDate).subtract(31, 'days'), end: moment(maxDate) };
    case 'last90Days':
      return { start: moment(maxDate).subtract(90, 'days'), end: moment(maxDate) };
    case 'lastMonth':
      return {
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
      };
    case 'lastQuarter':
      return {
        start: moment().subtract(1, 'quarter').startOf('quarter'),
        end: moment().subtract(1, 'quarter').endOf('quarter'),
      };
    case 'lastYear':
      return {
        start: moment().subtract(1, 'year').startOf('year'),
        end: moment().subtract(1, 'year').endOf('year'),
      };
    case 'all':
      return { start: moment(new Date(0)), end: moment() };
    default:
      return assertUnreachable(preset);
  }
};

export const applyDateRangePreset = (preset: DateRangePreset, minDate?: Date, maxDate?: Date) => {
  const { start, end } = getDateRangePresetMoments(preset, maxDate);
  const minMoment = minDate ? moment(minDate) : undefined;
  const maxMoment = maxDate ? moment(maxDate) : undefined;
  const startClamped = minMoment && minMoment.isAfter(start) ? minMoment : start;
  const endClamped = maxMoment && maxMoment.isBefore(end) ? maxMoment : end;
  const startDate = startClamped.toDate();
  const endDate = endClamped.toDate();
  return { startDate, endDate };
};

export const getDateRangePresetState = (
  preset: DateRangePreset,
  startDate: Date,
  endDate: Date,
  minDate?: Date,
  maxDate?: Date,
) => {
  const { startDate: presetStart, endDate: presetEnd } = applyDateRangePreset(preset, minDate, maxDate);
  if (presetStart.getTime() > presetEnd.getTime()) {
    return { isActive: false, isDisabled: true };
  } else {
    const isActive =
      toServerDate(presetStart) === toServerDate(startDate) && toServerDate(presetEnd) === toServerDate(endDate);

    return { isActive, isDisabled: false };
  }
};

export const getAvailableDateRange = (filter: AnalysisDateFilter) => {
  const { date_start, date_end } = filter;
  const minDate = fromServerDate(date_start) || undefined;
  const maxDate = fromServerDate(date_end) || new Date();
  return { minDate, maxDate };
};

export const createDateQueryFilter = (
  filter: AnalysisDateFilter,
  filterKey: AnalysisFilterKey,
  startDate: Date,
  endDate: Date,
): QueryFilter => {
  const { minDate, maxDate } = getAvailableDateRange(filter);
  const activePreset = dateRangePresets.find(
    (p) => getDateRangePresetState(p, startDate, endDate, minDate, maxDate).isActive,
  );

  let name = activePreset
    ? getDateRangePresetLabel(activePreset)
    : moment(startDate).format('D MMM YYYY') + ' - ' + moment(endDate).format('D MMM YYYY');

  const currentDateSelectionRql = queryBuilder.buildDateQuery(filter.id, startDate, endDate);

  if (!activePreset) {
    const updatedRql = currentDateSelectionRql.replace(/[{()}]/g, '');
    const findExistingDateRangeFromCuts = filter.cuts?.find(cut => cut.rql === updatedRql);
    name = findExistingDateRangeFromCuts ? findExistingDateRangeFromCuts.name.replace(' ', '-') : name;
  }

  const defaultPreset = getDefaultDateRangePreset(filter);
  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    applyDateRangePreset(defaultPreset, minDate, maxDate);

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    selected: [
      {
        name,
        startDate: toServerDate(startDate),
        endDate: toServerDate(endDate),
        rql: currentDateSelectionRql,
        isDefaultSelection: toServerDate(startDate) === toServerDate(defaultStartDate)
          && toServerDate(endDate) === toServerDate(defaultEndDate),
      },
    ],
  };
};

export const getDefaultDateSelection = (filter: AnalysisDateFilter): AnalysisFilterCut[] => {
  const { minDate, maxDate } = getAvailableDateRange(filter);
  const defaultPreset = getDefaultDateRangePreset(filter);
  const { startDate, endDate } = applyDateRangePreset(defaultPreset, minDate, maxDate);
  const queryFilter = createDateQueryFilter(filter, 'baseline', startDate, endDate);
  return queryFilter.selected ? queryFilter.selected : [];
};

export const isDateFilter = (filter: AnalysisFilter | AnalysisDateFilter): filter is AnalysisDateFilter => {
  return (
    filter.type === 'date' &&
    (filter as AnalysisDateFilter).date_end !== undefined &&
    (filter as AnalysisDateFilter).date_start !== undefined
  );
};

export function getDateShortcuts(filterId: string, filterConfig: AnalysisFilter[]): Record<string, { dateRange: DateRange }> {
  const filter = filterConfig.find((f) => f.id === filterId);
  if (!filter || !isDateFilter(filter)) {
    return {};
  }
  const { maxDate } = getAvailableDateRange(filter);
  return dateRangePresets.reduce((result: Record<string, { dateRange: DateRange }>, preset) => {
    const label = getDateRangePresetLabel(preset);
    const dateRange = getDateRangePresetMoments(preset, maxDate);
    result[label.toLowerCase()] = {
      dateRange: {
        start: dateRange.start.toDate(),
        end: dateRange.end.toDate(),
      },
    };
    return result;
  }, {});
}
