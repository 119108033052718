import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubscriptionJson } from 'api/interfaces';
import analytics from 'lib/analytics';
import fetch from 'lib/authed-fetch';
import { Fetchable } from 'lib/fetch';
import { clone, compact, difference, find, forEach, map } from 'lodash';
import { inject } from 'mobx-react';
import { stringify } from 'query-string';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  FormInputProps, Header,
  Input,
  LabelProps,
  List, Message,
  Modal,
  Select
} from 'semantic-ui-react';
import { SubscriptionInterface, SubscriptionRequest, SubscriptionStoreInterface } from 'stores/SubscriptionStore';
import { UserStoreInterface } from 'stores/UserStore';
import LabelList from 'components/Shared/LabelList';
import { isEmail } from '../../validations';
import './subscription-modal.scss';
import SubscriptionModalPreview from './SubscriptionModalPreview';
import { ActiveDashboardPanel, ActiveDashboardUIStoreInterface } from 'stores/ui/ActiveDashboardUIStore';
import { DashboardWidgetType } from 'api/enums';

enum SubscriptionFrequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ONCE = 'once'
}
const FREQUENCIES = [
  { key: 'weekly', value: SubscriptionFrequency.WEEKLY, text: 'Weekly' },
  { key: 'monthly', value: SubscriptionFrequency.MONTHLY, text: 'Monthly' },
  { key: 'once', value: SubscriptionFrequency.ONCE, text: 'Once' }
];

interface SubscriptionModalStoreProps {
  activeDashboardUIStore?: ActiveDashboardUIStoreInterface;
  subscriptionStore?: SubscriptionStoreInterface;
  userStore?: UserStoreInterface;
}

export interface SubscriptionModalProps extends SubscriptionModalStoreProps {
  close: () => void;
  digest?: SubscriptionInterface;
  orgId: string;
  dashboardId: string;
  request: SubscriptionRequest;
}

interface SubscriptionModalState {
  created: boolean;
  sent: boolean;
  working: boolean;
  emails: Set<string>;
  error?: string;
  frequency: string;
  isValid: boolean;
  name: string;
  pristine: boolean;
  proposedEmail: string;
  validation: JSX.Element | undefined;
}

@inject('activeDashboardUIStore', 'subscriptionStore', 'userStore')
export default class SubscriptionModal extends React.Component<
SubscriptionModalProps,
SubscriptionModalState
> {
  constructor(props: SubscriptionModalProps) {
    super(props);
    const { digest, request, userStore } = props;
    const { user } = userStore!;
    let frequency = SubscriptionFrequency.ONCE as string;
    let name = '';
    let emails = new Set<string>();
    if (digest) {
      frequency = digest.cadence;
      name = digest.name;
      forEach(digest.subscribers, subscriber => {
        emails.add(subscriber.email);
      });
    } else if (user && user.email) {
      emails.add(user.email);
    }
    if (!name) {
      name = request.dashboardName;
    }
    this.state = {
      created: false,
      sent: false,
      working: false,
      emails,
      error: undefined,
      frequency,
      isValid: true,
      name,
      pristine: true,
      proposedEmail: '',
      validation: undefined
    };
  }
  close = () => {
    this.props.close();
  };
  formAction = () => {
    const { digest } = this.props;
    // if there's an existing digest it's an edit
    if (digest) {
      this.edit();
    } else {
      this.create();
    }
  };
  getButtonText = () => {
    const { frequency } = this.state;
    const isOnce = frequency === SubscriptionFrequency.ONCE;

    if (this.isEdit()) {
      return 'Save changes';
    } else if (isOnce) {
      return 'Send digest';
    } else {
      return 'Create digest';
    }
  };
  getTitle = () => {
    const { frequency } = this.state;
    const isOnce = frequency === SubscriptionFrequency.ONCE;

    if (this.isEdit()) {
      return 'Edit email digest';
    } else if (isOnce) {
      return 'Send email digest';
    } else {
      return 'Create email digest';
    }
  };
  isEdit = (): boolean => {
    const { digest } = this.props;

    return !!digest;
  };
  create = async () => {
    const { orgId, dashboardId, request, activeDashboardUIStore } = this.props;
    const { emails, name, frequency } = this.state;
    const params = stringify({ organization: orgId });
    this.setState({ working: true, error: undefined });

    const panels: ActiveDashboardPanel[] = activeDashboardUIStore?.dashboardConfig?.panels ?? [];

    const hasSummary: boolean = panels.some(p => p.widgets?.some(w => {
        return w.config?.type === DashboardWidgetType.SUMMARY || false;
    }) ?? false);

    const additionalFields = hasSummary ? { hasSummary } : {};

    try {
      if (frequency !== 'once') {
        const { ok, data } = await fetch<SubscriptionJson>(
          `/digest?${ params }`,
          {
            body: JSON.stringify({
              cadence: frequency,
              dashboardID: dashboardId,
              name,
              context: request.context
            }),
            method: 'POST'
          }
        );

        if (!ok || !data) {
          throw new Error('Subscription creation failed');
        }

        const addSubscribers = [...emails].map(email => {
          const { id: digestId } = data;
          return fetch(`/digest/${ digestId }/subscriber`, {
            body: JSON.stringify({ email }),
            method: 'POST'
          });
        });
        await Promise.all(addSubscribers);

        analytics.track('Dashboard: Create subscription', { category: 'Dashboard', ...additionalFields });

        this.setState({ created: true });
      } else {
        // Send a one off
        const { ok, data } = await fetch(`/dashboard/${ dashboardId }/sendDigest`, {
          body: JSON.stringify({
            cadence: frequency,
            context: request.context,
            emailAddresses: [...emails]
          }),
          method: 'POST'
        });

        if (!ok || !data) {
          throw new Error('Send digest failed');
        }

        analytics.track('Dashboard: Sent one-off email', { category: 'Dashboard', ...additionalFields });
        this.setState({ sent: true });
      }
    } catch (error) {
      this.setState({ error: error.message });
    } finally {
      this.setState({ working: false });
    }
  };
  edit = async () => {
    const { digest, subscriptionStore } = this.props;
    const { name, frequency } = this.state;

    // this should never be true, but just in case make edit a NOOP
    if (!digest) {
      return;
    }
    const { id: digestId } = digest;
    this.setState({ working: true, error: undefined });
    try {
      const initialEmails = map(digest.subscribers, 'email');
      const emails = [...this.state.emails];

      const toAdd = difference(emails, initialEmails);
      const toRemove = difference(initialEmails, emails);
      // compact shouldn't be needed because *should* always be able to find subscriber
      const toRemoveIds = compact(
        map(toRemove, email => {
          const sub = find(digest.subscribers, { email });
          return sub && sub.id;
        })
      );

      const emailPromises = [] as Promise<Fetchable<{}>>[];
      forEach(toAdd, email => {
        emailPromises.push(
          fetch(`/digest/${ digestId }/subscriber`, {
            body: JSON.stringify({ email }),
            method: 'POST'
          })
        );
      });
      forEach(toRemoveIds, id => {
        emailPromises.push(
          fetch(`/digest/${ digestId }/subscriber/${ id }`, {
            method: 'DELETE'
          })
        );
      });

      await Promise.all(emailPromises);

      await subscriptionStore!.updateSubscription(digest.id, name, frequency);

      this.close();
    } catch (error) {
      this.setState({ error: error.message });
    } finally {
      this.setState({ working: false });
    }
  };

  onBlur = () => {
    const { emails, proposedEmail } = this.state;
    if (!proposedEmail) {
      return;
    }
    const invalid = isEmail(proposedEmail);
    this.setState({ validation: invalid });
    if (!invalid) {
      emails.add(proposedEmail);
      this.setState({ emails, proposedEmail: '' });
    }
  };
  onEmailKeypress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): false | undefined => {
    const { emails, proposedEmail } = this.state;
    if (e.key === 'Escape') {
      this.setState({ proposedEmail: '' });
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else if (
      e.key === 'Enter' ||
      e.key === ' ' ||
      e.key === ',' ||
      e.key === 'Tab'
    ) {
      const invalid = isEmail(proposedEmail.trim());

      this.setState({ validation: invalid });
      if (!invalid) {
        emails.add(proposedEmail.trim());
        // wait a tick so that `updateEmail` has fired before clearing out proposedEmail
        setTimeout(() => {
          this.setState({ emails, proposedEmail: '' });
        });
      }
    }
    return;
  };
  removeEmail = (e: React.MouseEvent<HTMLElement>, props: LabelProps) => {
    const { emails } = this.state;
    emails.delete(props.content as string);
    this.setState({ emails, pristine: false });
  };
  updateEmail = (e: React.FormEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    value = value.trim();
    const isValid = !value || !isEmail(value);
    this.setState({
      isValid,
      proposedEmail: value,
      pristine: false,
      validation: undefined
    });
  };
  updateFrequency = (
    e: React.SyntheticEvent<HTMLSelectElement>,
    { value: frequency }: FormInputProps
  ) => {
    this.setState({ frequency, pristine: false });
  };
  updateName = (e: React.FormEvent<HTMLInputElement>) => {
    let { value: name } = e.currentTarget;
    this.setState({ name, pristine: false });
  };

  render() {
    const { orgId, dashboardId, request } = this.props;
    const {
      created,
      sent,
      working,
      error,
      frequency,
      isValid,
      name,
      proposedEmail,
      validation
    } = this.state;
    const emails = [...this.state.emails];

    if (created) {
      return (
        <Modal
          dimmer="blurring"
          open={true}
          onClose={this.close}
          size="large"
        >
          <Modal.Header as={Header}>Subscription created</Modal.Header>
          <Modal.Content>
            <p>Subscription "{name}" created successfully!</p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Close" onClick={this.close} />
            <Button
              color="blue"
              content="View digests"
              as={Link}
              to={`/c/${ orgId }/subscriptions`}
            />
          </Modal.Actions>
        </Modal>
      );
    } else if (sent) {
      return (
        <Modal
          dimmer="blurring"
          open={true}
          onClose={this.close}
          size="small"
          closeOnDocumentClick={true}
        >
          <Modal.Header>Digest sent</Modal.Header>
          <Modal.Content>
            <p>Digest sent to </p>
            <List bulleted={true} size={'small'}>
              {map(emails, email => {
                return <List.Item key={email}>{email}</List.Item>;
              })}
            </List>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Close" onClick={this.close} />
          </Modal.Actions>
        </Modal>
      );
    }
    const title = this.getTitle();
    const buttonText = this.getButtonText();
    const { currentFilters, compareFilterName } = request.context;
    const filters = clone(currentFilters) || [];
    if (compareFilterName) {
      filters.unshift(compareFilterName);
    }
    return (
      <Modal
        dimmer="blurring"
        onClose={this.close}
        open={true}
        size="large"
        closeOnDocumentClick={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{title}</Modal.Header>
        <div className="alert-bar-yellow">
          <span className="name survey-name-spacing">Dashboard: </span>
          <span className="name bubble-spacing">{request.dashboardName}</span>
          <LabelList
            itemClassName="yellow-background"
            notPositioned={true}
            size="small"
            items={filters}
          />
        </div>
        <Modal.Content>
          {error && <Message negative={true}>{error}</Message>}
          <div className="subscription-modal">
            <div className="subscription-modal__form">
              <Form noValidate="novalidate" className="subscription-form">
                <div className="fluid-cell">
                  <Form.Field>
                    <label>DIGEST NAME</label>
                    <Input
                      placeholder="Subscription name"
                      value={name}
                      onChange={this.updateName}
                    />
                  </Form.Field>
                </div>
                <div className="cell">
                  <Form.Field>
                    <label>SEND</label>
                    <Select
                      onChange={this.updateFrequency}
                      placeholder="Select frequency"
                      options={FREQUENCIES}
                      value={frequency}
                    />
                  </Form.Field>
                </div>
                <div className="fluid-cell">
                  <Form.Field>
                    <label>RECIPIENTS</label>
                    <LabelList
                      size="small"
                      itemClassName="green-border"
                      items={emails}
                      onRemove={this.removeEmail}
                    />
                    <Input
                      action={
                        <Button onClick={this.onBlur}>
                          <FontAwesomeIcon icon="plus" className="icon large blue" />
                        </Button>
                      }
                      error={!isValid}
                      placeholder="Add email addresses"
                      type="email"
                      value={proposedEmail}
                      onChange={this.updateEmail}
                      onKeyDown={this.onEmailKeypress}
                      onBlur={this.onBlur}
                    />
                    {validation}
                  </Form.Field>
                </div>
              </Form>
            </div>
            <div className="subscription-modal__preview">
              <div className="fluid-cell">
                <label>
                  PREVIEW
                </label>
                <SubscriptionModalPreview request={request} orgId={orgId} dashboardId={dashboardId} />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={this.close} />
          <Button
            color="blue"
            content={buttonText}
            disabled={emails.length === 0}
            loading={working}
            onClick={this.formAction}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
