import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrganizationJson } from 'api/interfaces';
import { find, slice, sortBy } from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Table } from 'semantic-ui-react';
import { OrganizationsStoreInterface } from 'stores/OrganizationsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import './inline-org-list.scss';

const LIMITED_ENTRIES = 3;

export interface InlineOrgListProps {
  orgId: string;
  organizationStore?: OrganizationStoreInterface;
  organizationsStore?: OrganizationsStoreInterface;
  maxEntries: number;
}

// Organization with properties this component needs
interface Organization extends OrganizationJson {
  configureLink?: string;
}

interface InlineOrgListState {
  showLimited: boolean;
  organizations: OrganizationJson[];
  sortedOrganizations: Organization[];
  totalOrganizations: number;
  active: boolean;
}

@inject('organizationStore', 'organizationsStore')
@observer
class InlineOrgList extends React.Component<InlineOrgListProps, InlineOrgListState> {
  disposer?: IReactionDisposer = undefined;
  state = {
    showLimited: true,
    organizations: [] as OrganizationJson[],
    sortedOrganizations: [] as Organization[],
    totalOrganizations: 0,
    active: true,
  };

  saveAccordionState(): void {
    localStorage.setItem(
      'dashboardAccordions',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('dashboardAccordions') as string),
        InlineOrgList: this.state.active,
      }),
    );
  }

  componentDidMount() {
    this.handleSort();
    this.disposer = reaction(
      () => {
        if (this.props.organizationsStore) {
          return this.props.organizationsStore.organizations;
        }
        return undefined;
      },
      () => {
        this.checkUpdateOrganizations();
      },
      { fireImmediately: true },
    );

    this.setState({
      active: JSON.parse(localStorage.getItem('dashboardAccordions') as string)?.InlineOrgList ?? true,
    });
    this.saveAccordionState();
  }

  componentDidUpdate(): void {
    this.saveAccordionState();
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }
  checkUpdateOrganizations = () => {
    let { organizations } = this.state;
    const { organizationsStore } = this.props;

    if (organizationsStore && organizationsStore.organizations !== organizations) {
      this.setState({ organizations: organizationsStore.organizations }, () => {
        this.handleSort();
      });
    }
  };
  handleSort = () => {
    const { organizations, showLimited } = this.state;
    const { orgId, organizationsStore, maxEntries } = this.props;
    const currentOrg = find(organizations, { displayId: orgId });
    // sort the remaining organizations
    let data = sortBy(
      organizations.filter((o) => o.displayId !== orgId),
      'name',
    );
    // restrict to only 25 entries (to not kill the DOM)
    let numEntries = showLimited ? LIMITED_ENTRIES : maxEntries;
    // we will be prepending the current org, numEntries will be one less
    if (currentOrg) {
      data = [currentOrg, ...slice(data, 0, numEntries - 1)];
    } else {
      data = slice(data, 0, numEntries);
    }
    let sortedOrganizations: Organization[] = [];
    if (organizationsStore) {
      sortedOrganizations = data;
      this.setState({ sortedOrganizations, totalOrganizations: data.length });
    }
  };
  toggleShowLimited = () => {
    const { showLimited } = this.state;
    this.setState({ showLimited: !showLimited }, this.handleSort);
  };

  render(): JSX.Element | null {
    const { orgId } = this.props;
    const { sortedOrganizations, showLimited } = this.state;
    return (
      <Accordion>
        <Accordion.Title
          onClick={() => {
            this.setState({ active: !this.state.active });
          }}
          active={this.state.active}
        >
          <h3>Jump to Workspace</h3>
          <FontAwesomeIcon
            className="icon"
            icon={this.state.active ? 'chevron-up' : 'chevron-down'}
            fixedWidth={true}
          />
        </Accordion.Title>
        <Accordion.Content active={this.state.active}>
          <hr />
          <Table selectable={true} className="inline-org-list-table">
            <Table.Body>
              {sortedOrganizations.map((org) => {
                return (
                  <Table.Row key={org.displayId} verticalAlign="middle">
                    <Table.Cell className="check-cell">
                      {orgId === org.displayId ? (
                        <FontAwesomeIcon className="icon" icon="check" fixedWidth={true} />
                      ) : (
                        ''
                      )}
                    </Table.Cell>
                    <Table.Cell className="link-cell">
                      <Link to={`/c/${org.displayId}`} className={orgId === org.displayId ? 'selected' : ''}>
                        {org.name}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <div className="show-more-link" onClick={this.toggleShowLimited}>
            {showLimited ? 'Show More' : 'Show Less'}
          </div>
        </Accordion.Content>
      </Accordion>
    );
  }
}
export default InlineOrgList;
