

export function customTreeFilterFunction(value) {
    var filterNodeMethod = this.filterNodeMethod;
    var lazy = this.lazy;

    var makeChildrenVisible = function makeChildrenVisible(node) {
        var childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(function (child) {
            child.visible = true;
            makeChildrenVisible(child);
        });
    }

    var traverse = function traverse(node) {
      var childNodes = node.root ? node.root.childNodes : node.childNodes;

        // first set ONLY those nodes that match the search to visible
      childNodes.forEach(function (child) {
        child.collapse();
        child.visible = filterNodeMethod.call(child, value, child.data, child);

        traverse(child);
      });

      // if
      if (childNodes.length) {

        // determine if any of the children are visible
        var allHidden = true;
        childNodes.forEach(function (child) {
            if (child.visible) {
                    allHidden = false;
            }
        });

        // this node isn't visible, so we are interested in whether we should make it so (because kids)
        if (!node.visible) {

            if (node.root) {
            node.root.visible = allHidden === false;
            } else {
            node.visible = allHidden === false;
            }

            if (node.visible && !node.isLeaf && !lazy) node.expand();
        }
        else {
            // this node IS visible, which means that it matches the criteria

            // that means all kids all the way down need to be made visible
            makeChildrenVisible(node);

            // if all the children were hidden, then this matching node should be collapsed
            if (!allHidden) {
                node.expand();
            }
        }
      }

      if (!value) return;

    };

    traverse(this);
}