import * as React from 'react';
import ErrorIcon from 'images/icons/error.svg';
import './error.scss';
import analytics from 'lib/analytics';

interface Props {
  children?: React.ReactElement;
  description?: string;
  icon?: React.ReactNode;
  tracking?: {
    event: string,
    eventOptions: Record<string, string>
  }
  title: string;
}

const Error = ({ children, description, icon, title, tracking }: Props) => {

  React.useEffect(() => {
    if (tracking) {
      analytics.track(tracking.event, tracking.eventOptions);
    }
  }, [tracking]);

  return (
    <div className="error-state">
      <div className="error-state__content">
        <div className="error-state__icon">
          {icon ? icon : <ErrorIcon />}
        </div>
        <h2 className="error-state__title">{ title }</h2>
        { description && <p className="error-state__description">{ description }</p> }
        {children}
      </div>
    </div>
  );
}

export { Error };
