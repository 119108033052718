import Auth from 'Auth/Auth';
import { SurveyDataStatus, SurveyStatus } from 'api/enums';
import { DataAlertingWorkflow, DataSourceIntegration, Survey, View } from 'api/interfaces';
import { WorkflowHistory } from 'api/workflow-interfaces';
import { parseAsSurveys } from 'lib/dataset-tool-parser';
import { filter, find, forEach, isEmpty, remove } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import config from 'runtime-config';
import thematicData from 'vue/libs/thematicData';
import { LiteSurvey } from './AnalysisToolsStore';
import { OrganizationStoreInterface } from './OrganizationStore';
import { SurveyOptions, prepareAPIData } from './SetupStore';

export interface SurveyStoreInterface {
  hasDraft: (surveyId: string) => boolean | undefined;
  canUpgradeSurvey: (surveyId: string) => Promise<boolean>;
  getSurvey: (surveyId: string, ignoreCache?: boolean) => Promise<LiteSurvey | undefined>;
  getSurveyTitle: (surveyId: string) => string;
  getSurveysBySurveyStatus: (surveyStatus: SurveyStatus) => LiteSurvey[];
  getSurveysByDataStatus: (surveyDataStatus: SurveyDataStatus, excludeSurveyStatus?: SurveyStatus) => LiteSurvey[];
  getSurveyStatus: (surveyId: string) => Promise<string>;
  getSurveyDataStatus: (surveyId: string) => Promise<string>;
  getSurveyDataSource: (surveyId: string) => Promise<DataSourceIntegration | undefined>;
  getSurveyCanAction: (surveyId: string, actionRequired: string) => boolean;
  getSurveysThatCanAction: (actionRequired: string) => LiteSurvey[];
  getSurveyConfig: (surveyId: string) => Promise<string | undefined>;
  fetchSurveys: (reset?: boolean) => Promise<void>;
  clearSurveys: () => void;
  deleteSurvey: (surveyId: string) => Promise<void>;
  addSurveyToState: (survey: Survey) => void;
  updateSurveyInState: (survey: Survey) => void;
  updateDataSourceInState: (surveyId: string, dataSource: DataSourceIntegration) => void;
  updateViewInState: (surveyId: string, view: View) => void;
  updateCommentRecords: (surveyId: string, commentColId: string, commentId: string, tags: string[]) => void;
  updateSurvey: (
    surveyId: string,
    surveyOptions: SurveyOptions
  ) => Promise<Survey | undefined>;
  setDataAlerting: (surveyId: string, dataAlertingWorkflow: DataAlertingWorkflow) => Promise<void>;
  removeDataAlerting: (surveyId: string) => Promise<void>;
  fetchDataAlertingHistory: (surveyId: string) =>
    Promise<WorkflowHistory[] | undefined>;

  fetchingSurveys: Promise<any> | null;
  fetchSurveysError: string;
  fetchedSurveys: boolean;

  fetchSurveyError: string;

  deletingSurvey: boolean;
  deleteSurveyError: string;

  updatingSurvey: boolean;
  updatingSurveyIds: string[];
  updateSurveyError: string | undefined;

  updateDataAlertingError: string | undefined;
  fetchDataAlertingHistoryError: string | undefined;

  updateCommentRecordsError: string | undefined;

  surveys: LiteSurvey[];
  surveysJson?: Survey[];
}

class SurveyStore implements SurveyStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  fetchingSurveys = null as Promise<any> | null;

  @observable
  fetchSurveysError = '';

  @observable
  fetchSurveyError = '';

  @observable
  fetchedSurveys = false;

  @observable
  updateCommentRecordsError: string | undefined = undefined;

  @observable
  surveysJson?: Survey[] = undefined;

  @observable
  deletingSurvey = false;

  @observable
  deleteSurveyError = '';

  @observable
  updatingSurveyIds: string[] = [];

  @observable
  updateSurveyError: string | undefined = undefined;

  @observable
  updateDataAlertingError = undefined;

  @observable
  fetchDataAlertingHistoryError = undefined;

  @computed
  get updatingSurvey() {
    return this.updatingSurveyIds.length > 0;
  }

  @computed
  get surveys() {
    const { surveysJson } = this;
    const { orgId } = this.currentOrg;
    if (surveysJson && orgId) {
      return parseAsSurveys(orgId, surveysJson);
    } else {
      return [];
    }
  }

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;

    // React to orgId changes
    reaction(
      () => this.currentOrg.orgId,
      () => this.orgUpdated(),
      { fireImmediately: true }
    );
  }

  @action
  orgUpdated = async () => {
    this.reset();

    const { orgId } = this.currentOrg;
    if (orgId) {
      await this.fetchSurveys();
    }
  };

  @action
  reset = () => {
    this.fetchingSurveys = null as Promise<any> | null;
    this.updateSurveyError = undefined;
    this.fetchSurveysError = '';
    this.fetchedSurveys = false;
    this.clearSurveys();
  };

  @action
  fetchSurvey = async (surveyId: string) => {
    const { orgId } = this.currentOrg;
    if (!orgId) {
      return;
    }

    this.fetchSurveyError = '';

    const url = `/survey/${ surveyId }`;

    try {
      const { data: survey, ok } = await Auth.fetch<Survey>(url);
      if (survey && ok) {
        this.updateSurveyInState(survey);
      } else {
        throw new Error('Failed to fetch survey');
      }
    } catch (e) {
      this.fetchSurveyError = e.message;
    }
  };

  @action
  fetchSurveys = async (useCachedValue?: boolean) => {
    if (useCachedValue && this.fetchedSurveys) {
      return;
    }

    const { orgIdQueryParam, orgId } = this.currentOrg;
    if (!orgId) {
      return;
    }

    const url = `/surveys?${ orgIdQueryParam }`;

    if (!this.fetchingSurveys) {
      this.fetchSurveysError = '';
      this.fetchingSurveys = Auth.fetch<Survey[]>(url);
    }

    try {
      const { data: surveyList, ok } = await this.fetchingSurveys;
      if (surveyList && ok) {
        this.surveysJson = surveyList;
        this.fetchedSurveys = true;
      } else {
        throw new Error('Failed to fetch surveys');
      }
    } catch (e) {
      this.fetchSurveysError = e.message;
    } finally {
      this.fetchingSurveys = null;
    }
  };

  @action
  deleteSurvey = async (surveyId: string) => {
    this.deletingSurvey = true;
    this.deleteSurveyError = '';

    const url = `/survey/${ surveyId }`;

    try {
      const { ok, errorData } = await Auth.fetch(url, { method: 'DELETE' });

      if (!ok) {
        this.deleteSurveyError = errorData ? errorData.message : 'Please try again';
      } else {
        this.removeSurveyFromState(surveyId);
      }
    } catch (e) {
      this.deleteSurveyError = `Failed to delete dataset: ${ e.message }`;
    } finally {
      this.deletingSurvey = false;
    }
  };

  @action
  updateSurvey = async (
    surveyId: string,
    surveyOptions: SurveyOptions
  ) => {
    const { apiLocation } = config;
    this.updatingSurveyIds.push(surveyId);
    this.updateSurveyError = undefined;

    const postData = prepareAPIData(surveyOptions);

    const url = `${ apiLocation }/survey/${ surveyId }`;

    try {
      const { data, ok } = await Auth.fetch<Survey>(url, {
        method: 'PUT',
        body: JSON.stringify(postData)
      });

      if (!data || !ok) {
        throw new Error('Error updating survey');
      }

      this.updateSurveyInState(data as Survey);
      return data;
    } catch (e) {
      this.updateSurveyError = e.message;
    } finally {
      this.updatingSurveyIds = this.updatingSurveyIds.filter(id => id !== surveyId);
    }

    return undefined;
  };

  @action
  removeDataAlerting = async (surveyId: string) => {
    const { apiLocation } = config;

    const url = `${ apiLocation }/survey/${ surveyId }/dataAlerting`;
    try {
      const { data, ok, errorData } = await Auth.fetch<Survey>(url, { method: 'DELETE' });

      if (!ok || !data) {
        throw new Error(errorData ? errorData.message : 'Something went wrong while saving data alerting, Please try again');
      }

      // update the survey state here with the data alerting state returned
      this.updateDataAlertingInState(surveyId, undefined);
    } catch (e) {
      this.updateDataAlertingError = e.message;
    }
  };

  @action
  setDataAlerting = async (surveyId: string, dataAlertingWorkflow: DataAlertingWorkflow) => {
    const { apiLocation } = config;

    this.updateDataAlertingError = undefined;

    const url = `${ apiLocation }/survey/${ surveyId }/dataAlerting`;
    try {
      const { data, ok, errorData } = await Auth.fetch<DataAlertingWorkflow>(
        url,
        {
          body: JSON.stringify(dataAlertingWorkflow),
          method: 'PUT'
        }
      );

      if (!ok || !data) {
        throw new Error(errorData ? errorData.message : 'Something went wrong while saving data alerting, Please try again');
      }

      // update the survey state here with the data alerting state returned
      this.updateDataAlertingInState(surveyId, data);
    } catch (e) {
      this.updateDataAlertingError = e.message;
    }
  };

  @action
  fetchDataAlertingHistory = async (surveyId: string) => {
    const { apiLocation } = config;

    this.fetchDataAlertingHistoryError = undefined;

    const url = `${ apiLocation }/survey/${ surveyId }/dataAlerting/history`;
    try {
      const { data, ok, errorData } = await Auth.fetch<{ items: WorkflowHistory[] }>(url);

      if (!ok || !data) {
        throw new Error(errorData ? errorData.message : 'Something went wrong while saving data alerting, Please try again');
      }

      // update the survey state here with the data alerting state returned
      return data.items;
    } catch (e) {
      this.fetchDataAlertingHistoryError = e.message;
    }
    return undefined;
  };

  @action
  removeSurveyFromState = (surveyId: string) => {
    if (this.surveysJson) {
      remove(this.surveysJson, (s) => s.id === surveyId);
    }
  }

  @action
  updateCommentRecords = async (surveyId: string, commentColId: string, commentId: string, tags: string[]) => {
    this.updateCommentRecordsError = undefined;

    try {
      const body = {};
      body[`tags_${ commentColId }`] = tags;
      const { ok, data, errorData } = await Auth.fetch<object>(`/survey/${ surveyId }/records/${ commentId }`,
        {
          body: JSON.stringify(body),
          method: 'PUT'
        }
      );

      if (!ok || !data) {
        this.updateCommentRecordsError = errorData
          ? errorData.message
          : 'Something went wrong while saving labels, Please try again';
      } else {
        thematicData.clearCommentsCache();
      }

    } catch (error) {
      this.updateCommentRecordsError =
        'Something went wrong while saving labels, Please try again';
    }
  }

  @action
  addSurveyToState = (survey: Survey) => {
    if (this.surveysJson) {
      this.surveysJson = [...this.surveysJson, survey];
    }
  }

  @action
  updateSurveyInState = (survey: Survey) => {
    if (this.surveysJson) {
      this.surveysJson = this.surveysJson.map(s => s.id === survey.id ? survey : s);
    }
  }

  @action
  updateDataSourceInState = (surveyId: string, dataSource: DataSourceIntegration) => {
    const survey = find(this.surveysJson, { id: surveyId }) as Survey;
    if (survey) {
      survey.dataSourceIntegration = dataSource;
      this.updateSurveyInState(survey);
    }
  }

  @action
  updateDataAlertingInState = (surveyId: string, dataAlertingWorkflow?: DataAlertingWorkflow) => {
    const survey = find(this.surveysJson, { id: surveyId }) as Survey;
    if (survey) {
      survey.dataAlertingWorkflow = dataAlertingWorkflow;
      this.updateSurveyInState(survey);
    }
  }

  @action
  updateViewInState = (surveyId: string, view: View) => {
    if (this.surveysJson) {
      this.surveysJson = this.surveysJson.map(s => {
        if (s.id === surveyId) {
          if (s.views) {
            // Create new view where it has an updated value to make it work wit
            // updating nested objects and change detection
            forEach(s.views, v => {
              if (v.id === view.id) {
                Object.assign(v, view);
              }
            });
          }
        }
        return s;
      });
    }
  }

  @action
  clearSurveys = () => {
    this.surveysJson = undefined;
  }

  getSurvey = async (surveyId: string, ignoreCache?: boolean) => {
    await this.fetchingSurveys;
    const survey = find(this.surveys, { surveyId });
    if (!ignoreCache && survey) {
      return survey;
    }

    await this.fetchSurvey(surveyId);
    return find(this.surveys, { surveyId });
  };

  canUpgradeSurvey = async (surveyId: string) => {
    const survey = await this.getSurvey(surveyId);

    if (!survey) {
      return false;
    }

    const { dataVolume, dataSourceIntegration, surveyStatus } = survey;
    const canUpgradeSurvey = !!dataVolume && !dataSourceIntegration && surveyStatus === SurveyStatus.LIMITED;

    return canUpgradeSurvey;
  }

  _getSurveyCanAction = (survey: LiteSurvey, actionRequired: string, scopes?: string[]): boolean => {
    // can't manage themes for a survey that doesn't have data!
    if (!scopes) {
      scopes = survey.scopes;
    }
    if (actionRequired === 'manage:themes') {
      if (survey.dataStatus === SurveyDataStatus.NODATA || survey.dataStatus === SurveyDataStatus.NORESULTS || survey.dataStatus === SurveyDataStatus.PROCESSING) {
        // If there is no data yet loaded, no results yet available, or we are in initial processing: no one can manage these themes.
        actionRequired = 'impossible:action';
      } else if (survey.dataStatus === SurveyDataStatus.REVIEWING) {
        // Only thematic admins can manage themes for surveys that are in the reviewing phase.
        actionRequired = 'manage:internalResource';
      }
    }
    return scopes.includes(actionRequired);
  }

  getSurveyCanAction = (surveyId: string, actionRequired: string, scopes?: string[]) => {
    const survey = find(this.surveys, { surveyId });
    if (survey) {
      return this._getSurveyCanAction(survey, actionRequired, scopes);
    }
    return false;
  };

  getSurveysThatCanAction = (actionRequired: string) => {
    return filter(this.surveys, survey => {
      return this._getSurveyCanAction(survey, actionRequired);
    });
  }

  getSurveyTitle = (surveyId: string) => {
    const survey = find(this.surveys, { surveyId });
    return survey ? survey.title : '';
  };

  getSurveyStatus = async (surveyId: string) => {
    if (isEmpty(this.surveys)) {
      await this.fetchSurveys();
    }

    const survey = find(this.surveys, { surveyId });
    return survey ? survey.surveyStatus : '';
  };

  getSurveyDataStatus = async (surveyId: string) => {
    if (isEmpty(this.surveys)) {
      await this.fetchSurveys();
    }

    const survey = find(this.surveys, { surveyId });
    return survey ? survey.dataStatus : '';
  };

  getSurveyDataSource = async (surveyId: string) => {
    if (isEmpty(this.surveys)) {
      await this.fetchSurveys();
    }

    const survey = find(this.surveys, { surveyId });
    return survey ? survey.dataSourceIntegration : undefined;
  };

  getSurveysBySurveyStatus = (surveyStatus: SurveyStatus) => {
    return this.surveys.filter(s => s.surveyStatus === surveyStatus);
  };

  getSurveysByDataStatus = (surveyDataStatus: SurveyDataStatus, excludeSurveyStatus?: SurveyStatus) => {
    return this.surveys.filter(s =>
      s.dataStatus === surveyDataStatus
      && (excludeSurveyStatus ? s.surveyStatus !== excludeSurveyStatus : true)
    );
  }

  getSurveyConfig = async (surveyId: string) => {
    const survey = await this.getSurvey(surveyId);

    return survey ? survey.configuration : undefined;
  }

  hasDraft = (surveyId: string): boolean | undefined => {
    const survey = find(this.surveys, { surveyId });

    if (survey) {
      return survey.hasDraft;
    } else {
      return undefined;
    }
  };
}

export default SurveyStore;
