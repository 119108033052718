
import { cloneDeep } from 'lodash';
import { action, observable } from 'mobx';

export enum KeyTakeawayIcon {
  SCORE_INCREASE = 'score-change-up',
  SCORE_DECREASE = 'score-change-down',
  SCORE_NOCHANGE = 'score-change-no-change',
  THEMES = 'themes',
  THEMES_INCREASE = 'themes-up',
  THEMES_DECREASE = 'themes-down',
}

export interface KeyTakeaway {
  html: string;
  text: string;
  icon: KeyTakeawayIcon;
  onClick?: (takeaway: KeyTakeaway) => void;
}

type KeyTakeaways = {[key: string]: KeyTakeaway[] | undefined};

export interface AnalysisToolsKeyTakeawaysUIStoreInterface {
  takeaways: KeyTakeaways;
  updateTakeaway: (key: string, takeaways?: KeyTakeaway[]) => void;

  reset: () => void;
}

export default class AnalysisToolsKeyTakeawaysUIStore {

  @observable
  takeaways = {} as KeyTakeaways;

  @action
  updateTakeaway = (key: string, takeaways?: KeyTakeaway[]) => {
    this.takeaways[key] = takeaways;
    this.takeaways = cloneDeep(this.takeaways);
  }

  @action
  reset = () => {
    this.takeaways = {};
  }
}