import { inject } from 'mobx-react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import ApplyForm from './ApplyForm';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

export interface ApplyButtonProps {
  surveyName: string;
  applying: boolean;
  disabled: boolean;
  dirty: boolean;
  hasNewThemes: boolean;
}

export interface ApplyButtonState {
  error?: string;
  working: boolean;
  showModal: boolean;
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}
@inject('themesStore', 'themeEditorSessionStore')
export default class ApplyButton extends React.Component<
  ApplyButtonProps,
  ApplyButtonState
> {
  get injected(): InjectedProps {
    return this.props as ApplyButtonProps & InjectedProps;
  }
  state = {
    error: undefined,
    working: false,
    showModal: false
  };
  cancelModal = () => {
    this.setState({ showModal: false });
  };
  openModal = () => {
    this.setState({ showModal: true });
  };
  onClick = async (clearNewThemes: boolean) => {
    const {
      themesStore: store,
      themeEditorSessionStore
    } = this.injected;

    this.setState({ error: undefined, working: true });
    try {
      if (clearNewThemes) {
        store.markThemesAsReviewed();
      }
      themeEditorSessionStore.addEvent({
        type: 'Session',
        subType: 'Apply',
        timestamp: Date.now()
      });
      await store.apply();
      this.cancelModal();
    } catch (e) {
      this.setState({
        error: e.message,
        working: false
      });
    }
  };
  render() {
    const { disabled, dirty, surveyName, hasNewThemes } = this.props;
    const { error, working, showModal } = this.state;

    return (
      <>
        <Button
          color="blue"
          onClick={this.openModal}
          disabled={!dirty || disabled}
          size="small"
          className="ob-apply-themes-button nw-apply-themes-button"
        >
          Apply themes
        </Button>
        {showModal && (
          <ApplyForm
            error={error}
            surveyName={surveyName}
            working={working}
            hasNewThemes={hasNewThemes}
            onAccept={this.onClick}
            onClose={this.cancelModal}
          />
        )}
      </>
    );
  }
}
