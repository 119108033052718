import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import { inject } from 'mobx-react';
import * as React from 'react';
import { Button, Form, FormInputProps, Input, Message } from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';

interface CreateRefreshTokenModalState {
  error?: string;
  refreshToken?: string;
  integrationName: string;
  username: string;
  password: string;
  isGenerating: boolean;
}

interface CreateRefreshTokenModalProps {
  userStore?: UserStoreInterface;
}

@inject('userStore')
class CreateRefreshTokenModal extends React.Component<CreateRefreshTokenModalProps, CreateRefreshTokenModalState> {
  state = {
    integrationName: 'Unnamed',
    username: '',
    password: '',
    isGenerating: false
  } as CreateRefreshTokenModalState;

  showCreateRefreshToken = () => {
    const { userStore } = this.props;
    const { integrationName, username, password } = this.state;
    this.setState({ isGenerating: true });

    userStore!.createRefreshToken(username, password, integrationName).then(result => {
      const refreshToken = result ? result.refreshToken : undefined;
      this.setState({ refreshToken, error: undefined, isGenerating: false });
    }).catch(e => {
      let errorMessage = "We couldn't generate a refresh token";
      if (e.message) {
        errorMessage = e.message;
      }
      this.setState({ refreshToken: undefined, error: errorMessage, isGenerating: false });
    });

  };
  integrationNameChange = (e: React.ChangeEvent, { value }: FormInputProps) => {
    this.setState({ integrationName: value });
  };
  usernameChange = (e: React.ChangeEvent, { value }: FormInputProps) => {
    this.setState({ username: value });
  };

  passwordChange = (e: React.ChangeEvent, { value }: FormInputProps) => {
    this.setState({ password: value });
  };

  render(): JSX.Element | null {
    const { error, refreshToken, integrationName, username, password, isGenerating } = this.state;

    return (
      <div>
        <div className="modal-content">

          <div className="modal-body">
            <div className="modal-text">

              <Form>
                <Form.Field>
                  <label>
                    Integration name:
                  </label>
                  <Input
                    value={integrationName}
                    name="integrationName"
                    onChange={this.integrationNameChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    Username:
                  </label>
                  <Input
                    value={username}
                    name="username"
                    type="email"
                    onChange={this.usernameChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    Password:
                  </label>
                  <Input
                    value={password}
                    type="password"
                    name="password"
                    onChange={this.passwordChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Button size="small" onClick={this.showCreateRefreshToken} loading={isGenerating}>
                    Generate Refresh Token
                  </Button>
                  <label>
                    This will require logging in again
                  </label>
                </Form.Field>
              </Form>
            </div>
          </div>
        </div>
        {error && (
          <Message negative={true} size="mini" icon={true}>
            <FontAwesomeIcon
              className="icon"
              icon="exclamation-triangle"
              fixedWidth={true}
            />
            <Message.Content>
              <Message.Header>Uh oh! Something went wrong.</Message.Header>
              <p>
                {error}
              </p>
            </Message.Content>
          </Message>
        )}
        {!error && refreshToken && (
          <Message negative={false} size="mini" icon={false}>
            <Message.Content>
              <Message.Header>Your Refresh Token</Message.Header>
              <div>
                <CopyToClipboard
                  textToDisplay={refreshToken}
                  popupText="Copy refresh token"
                />
              </div>
              <div>
                Keep this in a safe place! It can be used to access Thematic on your behalf.
              </div>
              <div>
                This is not stored anywhere and will disappear when this modal is closed.
              </div>
            </Message.Content>
          </Message>
        )}
      </div>
    );
  }
}

export default CreateRefreshTokenModal;
