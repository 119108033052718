import LogoIcon from 'images/logo.svg';
import analytics from 'lib/analytics';
import { parse } from 'query-string';
import * as React from 'react';
import config from 'runtime-config';
import {
  Button,
  Container,
  Form,
  FormInputProps,
  Grid, Header,
  Input,
  Loader, Message
} from 'semantic-ui-react';
import 'styling/semantic.scss';
import {
  FieldValidation,
  initialValidationState, isEmail, matches,
  passwordComplexity,
  required
} from 'validations';
import './create-account.scss';

interface CreateAccountAppProps { }

interface CreateAccountAppState {
  code: string;

  haveValidCode: boolean;
  invalidCodeReason: string;
  loadingProfile: boolean;
  submitting: boolean;
  submissionError?: string;

  email: FieldValidation<string>;
  firstName: FieldValidation<string>;
  lastName: FieldValidation<string>;
  phoneNumber: FieldValidation<string>;
  orgName: FieldValidation<string>;
  password: FieldValidation<string>;
  verifyPassword: FieldValidation<string>;
}

export class CreateAccountApp extends React.Component<CreateAccountAppProps, CreateAccountAppState> {
  state = {
    code: '',
    haveValidCode: true,
    invalidCodeReason: '',
    loadingProfile: false,
    submitting: false,
    submissionError: undefined,
    email: initialValidationState(''),
    firstName: initialValidationState(''),
    lastName: initialValidationState(''),
    phoneNumber: initialValidationState(''),
    orgName: initialValidationState(''),
    password: initialValidationState(''),
    verifyPassword: initialValidationState(''),
  };
  componentDidMount() {
    // initialize mixpanel
    analytics.init();

    const search = parse(window.location.search);
    const code = search.code;

    if (!code) {
      this.setState({ haveValidCode: false, invalidCodeReason: 'No code provided' });
    } else if (typeof code === 'string') {
      this.setState({ code }, this.fetchProfile);
    }
  }
  fetchProfile = async () => {
    /*
    Given a 'create account' code, fetch the data that was stored alongside its creation
    */
    const { code } = this.state;
    this.setState({ loadingProfile: true });
    const url = config.apiLocation + `/accounts/retrieve-verify-email-link/${ code }`;
    let result: Response;
    try {
      result = await fetch(url);
      const { ok, status } = result;
      const json = await result.json();

      if (ok && status === 200) {
        // update state held about data
        this.updateValue('email', json.data.email);
        if (json.data.firstName) {
          this.updateValue('firstName', json.data.firstName);
        }
        if (json.data.lastName) {
          this.updateValue('lastName', json.data.lastName);
        }
        if (json.data.orgName) {
          this.updateValue('orgName', json.data.orgName);
        } else {
          this.updateValue('orgName', json.data.email);
        }
        if (json.data.phone) {
          this.updateValue('phoneNumber', json.data.phone);
        }

        this.setState({ haveValidCode: true });
      } else if (status === 410) {
        // a 410 indicates that the code is old and we should be redirecting to the login page
        window.location.href = '/';
      } else {
        const reason = json.message || 'Failed to get info on this code';
        this.setState({ haveValidCode: false, invalidCodeReason: reason });
      }
    } catch (e) {
      this.setState({ haveValidCode: false, invalidCodeReason: 'Failed to get info on this code' });
    } finally {
      this.setState({ loadingProfile: false });
    }
  };
  submit = async () => {
    /*
    Submits the account creation
    */
    const {
      code,
      email,
      firstName,
      lastName,
      phoneNumber,
      orgName,
      password
    } = this.state;

    this.setState({ submitting: true });
    const url = config.apiLocation + `/accounts/create-account/${ code }`;
    let result: Response;
    try {
      result = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
          phoneNumber: phoneNumber.value,
          orgName: orgName.value,
          password: password.value
        }),
      });
      const { ok, status } = result;
      const json = await result.json();
      if (ok && status === 200) {
        const redirectUrl = json.data.redirect;
        window.location.href = redirectUrl;

      } else {
        const reason = json.message || 'Creation didn\'t work';
        this.setState({ submissionError: reason });
      }
    } catch (e) {
      this.setState({ submissionError: 'Creation didn\'t work' });
    } finally {
      this.setState({ submitting: false });
    }

  };
  validate = (
    name: string,
    value: string
  ): FieldValidation<string> | undefined => {
    /*
    Validate an individual field and if necessary set an error message on it
    */
    let error;
    if (name === 'email') {
      error = required(value, 'Email') || isEmail(value);
    } else if (name === 'firstName') {
      error = required(value, 'A user first name');
    } else if (name === 'lastName') {
      error = required(value, 'A user last name');
    } else if (name === 'phoneNumber') {
      error = undefined;
    } else if (name === 'orgName') {
      error = required(value, 'An organization name');
    } else if (name === 'password') {
      error = passwordComplexity(value);
    } else if (name === 'verifyPassword') {
      const { password } = this.state;
      error = matches(value, password.value, 'password');
    } else {
      return undefined;
    }
    return { error, pristine: false, value: value };
  };

  updateValue = (name: string, value: string) => {
    /*
    Checks that a value can be updated and do so
    */
    const update = this.validate(name, value);
    if (!update) {
      return;
    }
    if (name === 'email') {
      this.setState({ email: update });
    } else if (name === 'firstName') {
      this.setState({ firstName: update });
    } else if (name === 'lastName') {
      this.setState({ lastName: update });
    } else if (name === 'phoneNumber') {
      this.setState({ phoneNumber: update });
    } else if (name === 'orgName') {
      this.setState({ orgName: update });
    } else if (name === 'password') {
      this.setState({ password: update });
    } else if (name === 'verifyPassword') {
      this.setState({ verifyPassword: update });
    }
  }

  onInputChange = (e: React.ChangeEvent, { name, value }: FormInputProps) => {
    /*
    Form event on input change
    */
    // Update is inside a timeout to get auto complete working as both password
    // and verify password fields update at the same time and when validating
    // verify password field, the password field update is not complete yets
    if (name === 'verifyPassword') {
      setTimeout(() => this.updateValue(name, value), 10);
    } else {
      this.updateValue(name, value);
    }
  };

  isValid = () => {
    /*
    Determines if this whole form is valid
    */
    const {
      password,
      verifyPassword
    } = this.state;

    const valid = (
      !password.error &&
      !verifyPassword.error);
    const hasPristineFields =
      password.pristine ||
      verifyPassword.pristine;

    return valid && !hasPristineFields;
  };

  render(): JSX.Element | null {
    // state
    const { haveValidCode, invalidCodeReason, loadingProfile, submitting, submissionError } = this.state;
    const valid = this.isValid();
    //  fields
    const {
      email,
      firstName,
      password,
      verifyPassword
    } = this.state;

    return (
      <div className="create-account-grid" style={{ paddingTop: '50px' }}>
        <Header as="h1">
          <LogoIcon />
        </Header>
        { loadingProfile && (
          <Loader active={true} size="large">
            <Message negative={false}>
              <Message.Header>Getting your details</Message.Header>
              <p>Loading the details you provided earlier to make this easier</p>
            </Message>{' '}
          </Loader>
        )}
        { !loadingProfile && (
          <div>
            { !haveValidCode && (
              <Container text={true}>
                <Message negative={true}>
                  <Message.Header>Unfortunately we can't finish making your account</Message.Header>
                  <p>
                    Something went wrong. Reloading the page may solve this issue.
                        </p>
                  <p>Here's the message we got:</p>
                  <blockquote>
                    <code>{invalidCodeReason}</code>
                  </blockquote>
                </Message>{' '}
                <p>
                  Tried reloading and still can't verify your email?{' '}
                  <a
                    href="mailto:support@getthematic.com?subject=Account%20Creation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact support.
                        </a>
                </p>
              </Container>
            )}
            { haveValidCode && (
              <Grid relaxed={true}>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Welcome {firstName.value}</Header>
                    <p>Set a password for {email.value}.</p>

                    <Form>
                        <Input
                          value={email.value}
                          name="email"
                          id="email"
                          type="email"
                          autoComplete="username"
                          disabled={true}
                          className="hidden-field"
                        />

                      <Form.Field>
                        <label>Password</label>
                        <Input
                          value={password.value}
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          onChange={this.onInputChange}
                        />
                        {!password.pristine && password.error}
                      </Form.Field>
                      <Form.Field>
                        <label>Verify Password</label>
                        <Input
                          value={verifyPassword.value}
                          name="verifyPassword"
                          type="password"
                          autoComplete="new-password"
                          onChange={this.onInputChange}
                        />
                        {!verifyPassword.pristine && verifyPassword.error}
                      </Form.Field>
                      {submissionError && (
                        <Message negative={true}>
                          <p>
                            Something went wrong creating your account!
                                </p>
                          <p>{submissionError}</p>
                        </Message>
                      )}
                      <Button
                        color="blue"
                        type="submit"
                        fluid={true}
                        onClick={this.submit}
                        disabled={!valid || submitting}
                        loading={submitting}
                      >
                        Set Password
                      </Button>

                    <p>By continuing with a Thematic account, you agree to Thematic's&nbsp;
                        <a target="_blank"
                          rel="noopener noreferrer"
                          href="https://getthematic.com/terms-of-service/">Terms of Service</a>
                                &nbsp;and&nbsp;
                        <a target="_blank"
                          rel="noopener noreferrer"
                          href="https://getthematic.com/privacy-terms/">Privacy Statement</a>.
                    </p>
                    </Form>

                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default CreateAccountApp;
