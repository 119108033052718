import Auth from 'Auth/Auth';
import { AnswersAuditRecord, PageRequestParams, PaginatedList } from 'api/interfaces';
import { action, observable } from 'mobx';
import { stringify } from 'query-string';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';

export enum AnswerAuditError {
  NONE = 'none',
  UNKNOWN = 'unknown',
  NO_SAVED_ANSWERS = 'noSavedAnswers',
  FAILED_TO_FETCH_ANSWERS = 'failedToFetchAnswers'
}

export interface AuditAnswersPageRequestParams extends PageRequestParams {
  include_failed?: boolean;
}

const AUDIT_PAGE_LENGTH = 25;
const AUDIT_PAGE_PARAMS: AuditAnswersPageRequestParams = {
  page: 1,
  page_len: AUDIT_PAGE_LENGTH,
  search_term: '',
  sort_by: 'recordedAt',
  include_failed: false
};


export interface AnswersAuditStoreInterface {
  abortController: AbortController | null;
  error: AnswerAuditError;
  fetchAnswers: () => Promise<void>;
  init: () => void;
  isFetchingAnswers: boolean;
  reset: () => void;

  setPageParams: (options: AuditAnswersPageRequestParams) => void;
  resetPageParams: () => void;

  auditRecords: AnswersAuditRecord[];
  pageParams: AuditAnswersPageRequestParams;
  totalPages: number;
  totalItems: number;
}

export default class AnswersAuditStore implements AnswersAuditStoreInterface {
  currentOrg: OrganizationStoreInterface;

  abortController: AbortController | null = null;

  @observable
  isFetchingAnswers = false;

  @observable
  auditRecords: AnswersAuditRecord[] = [];

  @observable
  error: AnswerAuditError = AnswerAuditError.NONE;

  @observable
  totalPages = 0;

  @observable
  totalItems = 0;

  @observable
  pageParams = { ...AUDIT_PAGE_PARAMS };


  constructor(
    currentOrg: OrganizationStoreInterface
  ) {
    this.currentOrg = currentOrg;
  }

  @action
  setPageParams = (params: AuditAnswersPageRequestParams) => {
    this.pageParams = {
      ...this.pageParams,
      ...params
    };
  }

  @action
  resetPageParams = () => {
    this.pageParams = { ...AUDIT_PAGE_PARAMS };
  }

  @action
  handleError(message?: string) {

    if (message?.includes('Saved answers not found')) {
      this.error = AnswerAuditError.NO_SAVED_ANSWERS;
      return;
    }

    this.error = AnswerAuditError.UNKNOWN;

  }

  @action
  async fetchAnswers() {
    this.isFetchingAnswers = true;

    const query = stringify({
      organization: this.currentOrg.orgId,
      ...this.pageParams
    });

    try {
      this.abortController = new AbortController();


      const { data, ok, errorData } = await Auth.fetch<PaginatedList<AnswersAuditRecord>>(
        `/answers/audit?${ query }`,
        { signal: this.abortController.signal }
      );

      if (ok && data) {
        const { items, total_items } = data;
        this.totalPages = Math.ceil(total_items / AUDIT_PAGE_LENGTH);
        this.totalItems = total_items;
        this.auditRecords = items;
      } else {
        this.handleError(errorData?.message);
      }

    } catch (e) {
      this.handleError(e.message);
    } finally {
      const updatedQuery = stringify({
        organization: this.currentOrg.orgId,
        ...this.pageParams
      });
      // Check if there is a newer API call to continue showing loading in the event of debounce
      if (query === updatedQuery) {
        this.isFetchingAnswers = false;
      }
    }

  }

  @action
  init() {
    this.reset();
    this.fetchAnswers();
  }

  @action
  reset() {
    this.isFetchingAnswers = false;
    this.auditRecords = [];
    this.abortController?.abort();
    this.abortController = null;
    this.error = AnswerAuditError.NONE;

    this.resetPageParams();
  }

}
