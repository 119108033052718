<template>
  <div class="vertical-comments__contents">
    <div
      v-if="!loading && analysisToolsUIStore.getComments(type).length === 0"
      class="vertical-comments__empty"
    >
      No {{ isSummary ? 'summaries' : 'comments' }} match the current filters
    </div>
    <div
      v-if="hasHiddenParts"
      class="vertical-comments__toggle"
    >
      Show full threads
      <el-switch
        :value="showSupportMessages"
        @change="toggleSupportMessageVisibility"
      />
    </div>
    <div
      v-else-if="!isThreaded"
      class="vertical-comments__toggle"
    >
      Show full comments
      <el-switch
        :value="showFullMessages"
        @change="toggleFullMessageVisibility"
      />
    </div>
    <div v-if="isThreaded && !isSummary">
      <vertical-comment-thread
        v-for="c in analysisToolsUIStore.getComments(type)"
        :key="c.key"
        :seen-map="seenMap"
        :intersection-observer="intersectionObserver"
        :comment="c.comment"
        :comment-id="c.id"
        :comment-column-id="c.column"
        :comment-tags="c.tags"
        :selected-theme-codes="selectedThemeCodes"
        :is-comment-edited="editedCommentIds.includes(c.id)"
        :highlight-type="commentHighlightingType"
        :is-narrow="isNarrow"
        :info="c.data"
        @onCommentThemeSelected="onCommentThemeSelected"
        @onSentimentChange="({ sentiment, block }) => onSentimentChange(c.id, sentiment, block)"
      />
    </div>
    <div v-else>
      <vertical-comment
        v-for="c in analysisToolsUIStore.getComments(type)"
        :key="c.key"
        :comment="c.comment"
        :comment-id="c.id"
        :comment-column-id="c.column"
        :comment-tags="c.tags"
        :selected-theme-codes="selectedThemeCodes"
        :highlight-type="commentHighlightingType"
        :is-comment-edited="editedCommentIds.includes(c.id)"
        :is-narrow="isNarrow"
        :info="c.data"
        :segments="c.segments"
        :is-summary="isSummary"
        @onCommentThemeSelected="onCommentThemeSelected"
        @onCommentSegmentsUpdated="segments => onCommentSegmentsUpdated(c.id, segments)"
        @onShowFullConversation="currentFullConversation = c"
      />
    </div>
    <div
      v-if="hasMore"
      class="vertical-comments__action"
    >
      <el-button
        :disabled="loading"
        :loading="loading"
        :type="type"
        @click="fetchComments()"
      >
        Load more {{ isSummary ? 'summaries' : 'comments' }}
      </el-button>
    </div>
    <conversation-drawer
      v-if="currentFullConversation"
      :summary="currentFullConversation"
      :initialselectedthemecodes="selectedThemeCodes"
      :columns="analysisConfigStore.metadataColumns"
      :tags="tagStore.tags"
      :close="() => currentFullConversation = null"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { assign, forEach, get, some } from 'lodash';
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import queryBuilder from 'vue/libs/queryBuilder';
import thematicData from 'vue/libs/thematicData';

import {
  getAnalysisConfigStore,
  getAnalysisToolsUIStore,
  getInitConfigStore,
  getTagStore,
  getThemesStore,
} from 'stores/RootStore';
import VerticalComment from 'vue/components/Comments/VerticalComment.vue';
import VerticalCommentThread from 'vue/components/Comments/VerticalCommentThread.vue';
import editedCommentsMixin from 'vue/components/Comments/EditedCommentsMixin';
import updateSentimentInThreadedComment from 'lib/update-sentiment-in-threaded-comment';
import { ConversationalAnalytics } from 'components/ConversationalAnalytics/ConversationalAnalytics';

const PAGE_SIZE = 25;
const PAGE_SIZE_THREADED = 5;

let keyIndex = 0;
export default {
  name: 'VerticalCommentsArea',
  components: {
    VerticalCommentThread,
    VerticalComment,
    ConversationDrawer: ReactInVue(ConversationalAnalytics),
  },
  mixins: [
    editedCommentsMixin()
  ],
  props: {
    isNarrow: { default: false, type: Boolean },
    sentimentFilter: { default: 'all', type: String },
    type: { default: 'baseline', type: String },
    query: { default: '', type: String },
    selectedTheme: { default: () => ({}), type: Object },
    shouldFetchComments: { default: true, type: Boolean },
    isSummary: { default: false, type: Boolean },
  },
  data() {
    return {
      seenMap: {},
      intersectionObserver: null,
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      tagStore: getTagStore(),
      themesStore: getThemesStore(),
      initConfigStore: getInitConfigStore(),
      hasMore: true,
      loading: false,
      page: 1,
      promise: undefined,
      commentHasNoThemes: false,
      currentFullConversation: null,
    };
  },
  computed: {
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    commentHighlightingType() {
      return this.initConfigStore.commentHighlightingType;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    selectedThemeCodes() {
      const themeCodes = this.analysisToolsUIStore.selectedThemeCodes;
      return 'base' in themeCodes ? themeCodes : null;
    },
    themesHierarchy() {
      return this.themesStore.themesHierarchy;
    },
    hasSentiment() {
      return this.analysisConfigStore.hasSentiment;
    },
    metadataColumns() {
      return this.analysisConfigStore.metadataColumns;
    },
    commentRequestOptions() {
      const { metadataColumns, requestOptions } = this;
      const options = assign({}, requestOptions, { metadataColumns });
      options.sortDirection = 'desc';
      options.sortColumn = undefined;
      return options;
    },
    isThreaded() {
      const isThreaded = !!this.analysisConfigStore?.config?.threadConfig;
      return isThreaded;
    },
    pageSize() {
      return this.isThreaded ? PAGE_SIZE_THREADED : PAGE_SIZE;
    },
    showSupportMessages() {
      return this.analysisToolsUIStore.areSupportMessagesVisible;
    },
    showFullMessages() {
      return this.analysisToolsUIStore.shouldShowFullMessages;
    },
    hasHiddenParts() {
      return (
        this.isThreaded &&
        ((this.analysisConfigStore.threadDisplayConfig.hiddenThreadParts || []).length > 0 || this.commentHasNoThemes)
      );
    },
  },
  watch: {
    query: {
      handler() {
        this.fetchComments(true);
      },
      immediate: true,
    },
    themesHierarchy() {
      this.fetchComments(true);
    },
    sentimentFilter() {
      this.fetchComments(true);
    },
  },
  created() {
    this.intersectionObserver = new IntersectionObserver(
      this.onElementObserved,
      {
        root: this.$el,
        threshold: 0,
        trackVisibility: true,
        delay: 100
      }
    );
  },
  beforeDestroy() {
    this.intersectionObserver.disconnect();
  },
  methods: {
    onElementObserved(entries) {
      this.seenMap = entries
        .filter(e => e.isIntersecting || e.isVisible)
        .reduce((result, e) => {
          const threadIndex = e.target.dataset.threadIndex;
          const threadPartIndex = e.target.dataset.threadPartIndex;

          Vue.set(result, threadIndex, result[threadIndex] || {});
          Vue.set(result[threadIndex], threadPartIndex, true);

          return result;
        }, this.seenMap);
    },
    reset() {
      this.analysisToolsUIStore.clearComments(this.type);
      this.hasMore = true;
      this.page = 1;
    },
    getFilterString() {
      let query = get(this, `query`);
      const basetheme = get(this.selectedTheme, 'theme.title');
      const subtheme = get(this.selectedTheme, 'subtheme.title');
      const themeRql = this.analysisToolsUIStore.rqlForThemes(basetheme, subtheme);
      return queryBuilder.appendToFilter(query, themeRql);
    },
    doFetch: async function () {
      if (!this.shouldFetchComments) {
        return;
      }

      let filterString = this.getFilterString();

      let page;
      this.loading = true;
      page = this.page++;

      const { commentColumns, commentRequestOptions, pageSize } = this;

      commentRequestOptions.dateResolution = this.initConfigStore.getChartDateResolution;

      this.promise = thematicData.getComments({
        filter: filterString,
        commentColumns,
        requestOptions: commentRequestOptions,
        pagination: { page, pageSize }
      });

      let comments;
      let hasMore = false;
      try {
        // check that the returned promise is the 'latest' one and if not return
        const promise = this.promise;
        comments = await promise;

        if (this.promise != promise) {
          return;
        }
        hasMore = comments.length >= pageSize;
      } catch (e) {
        this.error = e;
        return;
      }

      forEach(comments, (comment) => {
        comment.key = `${keyIndex++}-comment-key`;
      });

      const fromIndex = (page - 1) * this.pageSize;

      const nextComments = [...this.analysisToolsUIStore.getComments(this.type)];

      // Mutate copy
      nextComments.splice(fromIndex, this.pageSize, ...comments);

      this.analysisToolsUIStore.setComments(this.type, nextComments);

      this.commentHasNoThemes = some(nextComments, (comment) =>
        some(comment.comment, (part) => {
          return !!part.themes && part.themes.length === 0;
        }),
      );
      this.hasMore = hasMore;
      this.loading = false;
    },
    fetchComments(reset = false) {
      const { query, themesHierarchy, type } = this;

      // if we don't have a filtering object or themesHierarchy, bail
      if (typeof query === 'undefined' || !themesHierarchy) {
        return;
      }

      if (reset) {
        this.reset();
      } else {
        // a reset means that it was an initial load of comments. NO reset means it was load more
        const pageCount = this.page;
        analytics.track('Comments: Load more', {
          category: 'Comments',
          label: type,
          value: pageCount,
        });
      }

      this.doFetch();
    },
    toggleSupportMessageVisibility() {
      this.analysisToolsUIStore.toggleSupportMessageVisibility();
    },
    toggleFullMessageVisibility() {
      this.analysisToolsUIStore.toggleFullMessageVisibility();
    },
    onCommentThemeSelected(theme) {
      this.$emit('onCommentThemeSelected', theme);
    },
    onCommentSegmentsUpdated(commentId, segments) {
      const comments = this.analysisToolsUIStore.getComments(this.type);

      const nextComments = comments.map(comment => {
        if (comment.id === commentId) {
          comment.segments = segments;
        }
        return comment;
      });
      this.analysisToolsUIStore.setComments(this.type, nextComments);

      // Suppose the user had changed sentiment for a comment related to Theme
      // A, we need to clear the cache. Otherwise they could switch to Theme B
      // and back, and receive the cached comments for Theme A with the previous sentiment.
      thematicData.clearCommentsCache();
    },
    onSentimentChange(commentId, sentiment, block) {

      const comments = this.analysisToolsUIStore.getComments(this.type);

      const nextComments = updateSentimentInThreadedComment(
        comments,
        commentId,
        sentiment,
        block
      );

      this.analysisToolsUIStore.setComments(this.type, nextComments);

    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables';
.vertical-comments {
  background: $--color-white;
  flex: 1 1 auto;
  grid-area: comments;
  box-shadow: 0 7px 6px -3px rgba(84, 99, 119, 0.3);

  &.narrow {
    background-color: $--neutral-100;
    box-shadow: none;
    padding-top: 5px;
    transition: padding ease-in-out 0.1s;
  }

  &__toggle {
    padding: 10px 20px;
  }

  &__title {
    background: $--background-color-light;

    color: $--neutral-600;
    font-weight: 400;

    align-items: center;
    display: flex;
    .narrow & {
      padding: 6px 20px 2px;
      background: inherit;
    }

    .el-dropdown {
      .el-button {
        height: 28px;
      }
    }
  }

  &__empty {
    font-size: 12px;
    padding: 10px;
    text-align: center;
  }
  &__contents {
    overflow-y: auto;
    color: $--neutral-900;
    background: $--neutral-100;
    max-height: calc(100vh - 175px);
    padding-bottom: 150px;
  }
  &__action {
    padding: 15px;
    text-align: center;
  }
}
</style>
