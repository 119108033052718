import { PhraseSegment, PhraseSegmentTheme, ThreadedComment } from 'types/custom';

export function getHighlightsFromParts(
  threadedComments: Array<ThreadedComment>,
  selectedTheme: PhraseSegmentTheme
): Array<string> {
  return threadedComments.reduce((result, tc) => {

    const segmentsContainingTheme: Array<PhraseSegment> = tc.segments.filter(seg => {
      return seg.themes.some((theme: PhraseSegmentTheme): boolean =>
        theme.base === selectedTheme.base && theme.sub === selectedTheme.sub
      );
    });

    const highlights = segmentsContainingTheme.map(seg => tc.text.slice(...seg.location));

    return [...result, ...highlights];

  }, []);
}
