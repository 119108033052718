<template>
  <div class="config-field">
    <label>
      Sort
    </label>
    <el-select
      v-model="selectedSort"
      placeholder="Select"
      @change="onSortChange"
    >
      <el-option
        v-for="item in sortOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { DashboardWidgetType, WidgetSortValue } from 'api/enums';
import { WIDGET_SORT_OPTIONS } from '../constants';


export default {
  name: 'ConfigureWidgetSort',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
    selectedWidget: { type: String }
  },
  data() {
    return {
      selectedSort: undefined,
    }
  },
  computed: {
    sortOptions() {
      return WIDGET_SORT_OPTIONS;
    },
  },
  mounted() {
    const { sort, type } = this.widgetConfig;
    if (sort) {
      this.selectedSort = this.widgetConfig.sort;
    } else if (type === DashboardWidgetType.IMPACT_CHANGES || this.selectedWidget === DashboardWidgetType.IMPACT_CHANGES) {
      // Descending absolute is the default value for impact changes widget if nothing is specified
      this.selectedSort = WidgetSortValue.DESC_ABS;
    }
  },
  methods: {
    onSortChange() {
      this.$emit('onConfigChange', this.target, this.selectedSort, true);
    }
  }
}
</script>