import { calculateSimpleSentiment, ValidSimpleSentiments } from 'lib/calculate-sentiment';

export default function toSentimentClassname(sentiment: number, hasSentiment: boolean) {

  if (!hasSentiment) {
    return 'no-sentiment';
  }
  const label: ValidSimpleSentiments | undefined = calculateSimpleSentiment(sentiment);

  if (!label) {
    return '';
  }

  return `theme-sentiment-${label}`;
}
