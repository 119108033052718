
import config from 'runtime-config';

interface IntegrationCallbackState {
  orgId: string;
  surveyId?: string;
  datasetName?: string;
  fieldValues?: { [key: string]: string };
}

export enum Source {
  CONFIGURE = 'configure',
  UPLOAD = 'upload'
}

export function getIntegrationUrlWithState(
  options: {
    orgId: string;
    integrationUrl?: string;
    surveyId?: string;
    datasetName?: string;
    fieldValues?: { [key: string]: string };
  }
) {
  const { orgId, integrationUrl, surveyId, datasetName, fieldValues } = options;

  if (!integrationUrl) {
    return;
  }

  const state = {
    base: config.appUrlBase,
    orgId,
    ...(surveyId ? { surveyId } : {}),
    ...(datasetName ? { datasetName } : {}),
    ...(fieldValues ? { fieldValues } : {}),
  } as IntegrationCallbackState;

  const url = `${ integrationUrl }&state=${ encodeURIComponent(JSON.stringify(state)) }`;

  return url;
}

export function getContinueLink(
  options: {
    orgId?: string;
    surveyId?: string;
    datasetName?: string;
    dataSource?: string;
  }
) {
  const { orgId, surveyId, dataSource, datasetName } = options;

  let continueLink = {
    pathname: '/',
    state: {}
  };

  if (orgId) {
    continueLink.pathname = `/c/${ orgId }`;
    if (dataSource) {
      continueLink.pathname = surveyId
        ? `/c/${ orgId }/s/${ surveyId }/configure-survey`
        : `/c/${ orgId }/integrations`;
      continueLink.state = {
        dataSource,
        ...(surveyId ? { surveyId } : {}),
        ...(datasetName ? { datasetName } : {}),
      };
    } else {
      continueLink.pathname = `/c/${ orgId }/integrations`;
    }
  }

  return continueLink;
}