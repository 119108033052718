
































import SentimentPopoverMenu from 'vue/components/Sentiment/SentimentPopoverMenu.vue';
import Sentiment from 'vue/components/Sentiment/Sentiment.vue';

export default {
  name: 'CommentSentimentPopup',
  components: {
    SentimentPopoverMenu,
    Sentiment
  },
  props: {
    canManageThemes: { type: Boolean, required: true },
    isSentimentEnabled: {type: Boolean, default: true},
    block: { type: Object, required: true },
  },
  data() {
    return {
      canSeeInlineThemesEditing: this.canManageThemes
    };
  },
};

