import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import './data-source-reevoo.scss';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceReevooCreateFormProps {
    onChange(configuration: object, integrationId: string): void;
}

interface ReevooInfo {
  id: string;
  name: string;
  ratingsCount: number;
  deliveryCount: number;
  serviceCount: number;
}

interface ReevooConfig {
  organizationIds: string[];
}

interface DataSourceReevooCreateFormState {
  selectedOrg?: ReevooInfo;
  selectedOrgs: ReevooInfo[];
  config?: ReevooConfig;
}

@observer
class DataSourceReevooCreateForm extends React.Component<
    DataSourceReevooCreateFormProps,
    DataSourceReevooCreateFormState
> {
  state = {
    selectedOrgs: [] as ReevooInfo[]
  } as DataSourceReevooCreateFormState;

  changeSelectedOrg = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {

    const selectedOrg = JSON.parse(value);

    this.setState({ selectedOrg });
  }

  addSelectedOrg = () => {
    const { selectedOrgs, selectedOrg } = this.state;

    if (selectedOrg) {
      selectedOrgs.push(selectedOrg);
    }

    this.setState({ selectedOrgs });
  }

  removeOrgAtIndex = (index: number) => {
    const { selectedOrgs } = this.state;

    selectedOrgs.splice(index, 1);

    this.setState({ selectedOrgs });
  }

  renderForm(availableOrganizations: ReevooInfo[] | undefined): JSX.Element | null {
    const { selectedOrgs } = this.state;

    // create the source type options as list for dropdown
    const availableOrganizationsOptions = map(availableOrganizations, item => {
      return {
        text: item.name,
        value: JSON.stringify(item)
      };
    });

    const selectedOrgDivs = map(selectedOrgs, (selectedOrg, index) => {
      return (
        <div key={index} className="reevoo-row">
                <span>{selectedOrg.name} ({selectedOrg.id})</span>
                <Button onClick={() => {this.removeOrgAtIndex(index); }}
                  icon={
                      <FontAwesomeIcon
                      className="icon"
                      icon="trash"
                      fixedWidth={true}
                      />
                  }/>
        </div>);
    });

    if (availableOrganizations) {
      return (
        <Form>
            <Form.Field>
                <label>Organization</label>

                <Dropdown
                  selection={true}
                  options={availableOrganizationsOptions}
                  placeholder="Select..."
                  onChange={this.changeSelectedOrg}
                />
                <Button onClick={this.addSelectedOrg}>Add</Button>
              </Form.Field>
            { selectedOrgDivs }
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { selectedOrgs } = this.state;

    const config = {
      organizationIds: map(selectedOrgs, org => {
          return org.id;
      })
    } as ReevooConfig;

    return (
        <div>
            <DataSourceWithIntegrationForm
              renderFormComponent={this}
              onChange={onChange}
              config={config}
              integrationType={IntegrationType.REEVOO} />
        </div>
    );
  }
}

export default DataSourceReevooCreateForm;
