<template>
  <div
    class="significant-changes"
  >
    <score-graph
      class="significant-changes__graph"
      :selections="filteredSelection"
      @onDateSelected="onDateSelected"
      @selectedScoreChanged="selectedScoreChanged"
    />

    <theme-trends
      v-if="dateFilter && previousDateFilter"
      class="significant-changes__trends"
      :selection="filteredSelection.baseline"
      :current-period="dateFilter"
      :selected-score-external="selectedScore"
      @onThemeSelection="onThemeSelection"
      @onCommentCount="onCommentCount"
    />
  </div>
</template>

<script>
import { get } from 'lodash';

import analytics from 'lib/analytics';
import queryBuilder from 'vue/libs/queryBuilder';

import { getAnalysisToolsUIStore, getFilterStore, getInitConfigStore } from 'stores/RootStore';
import ScoreGraph from 'vue/components/Tiles/ScoreGraph.vue';
import ThemeTrends from 'vue/over-time/ThemeTrends';

export default {
  name: 'SignificantChanges',
  components: {
    ScoreGraph,
    ThemeTrends,
  },
  data() {
    return {
      dateFilter: undefined,
      dateRql: undefined,
      filteredSelection: undefined,
      previousDateFilter: undefined,
      selectedScore: undefined,
      commentCount: undefined,
      themeObject: undefined,
      filterStore: getFilterStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
    };
  },
  computed: {
    IdsFromColumn() {
      return this.initConfigStore.IdsFromColumn;
    },
    stateConfig() {
      return this.initConfigStore.config;
    },
    themeFilterSet() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    selections() {
      return this.filterStore.selections;
    },
    dateColumn() {
      const defaultColumn = get(this, 'stateConfig.date_column', null);
      return get(this, 'config.date_column', defaultColumn);
    },
    selectedCounts() {
      const { commentCount, themeObject } = this;
      if (themeObject) {
        const {
          count: baseCount,
          sentimentCounts: baseSentimentCounts
        } = themeObject;
        if (baseSentimentCounts) {
          return { baseCount, baseSentimentCounts };
        } else {
          return undefined;
        }
      } else {
        return commentCount;
      }
    },
    selectedSubtheme() {
      return get(this, 'themeFilterSet.subtheme.name', null);
    },
    selectedTheme() {
      return get(this, 'themeFilterSet.theme.name', null);
    },
    commentsSelection() {
      const { dateFilter, dateRql: query, filteredSelection, selectedCounts } = this;

      let baseCount = get(dateFilter, 'counts.baseline.count', 0);
      let baseSentimentCounts = get(dateFilter, 'counts.baseline.sentimentCounts', {neg: 0, pos: 0});
      if (selectedCounts) {
        baseCount = selectedCounts.baseCount;
        baseSentimentCounts = selectedCounts.baseSentimentCounts;
      }

      const commentsSelection = {
        subTitle: get(dateFilter, 'label'),
        selections: {
          baseline: {
            title: get(filteredSelection, 'baseline.title'),
            query: queryBuilder.appendToFilter(get(filteredSelection, 'baseline.query'), query),
            count: baseCount,
            sentimentCounts: baseSentimentCounts
          },
          comparison: {
            query: queryBuilder.appendToFilter(get(filteredSelection, 'baseline.query'), query), // to show it is the same as baseline
          }
        }
      };
      return commentsSelection;
    }
  },
  watch: {
    selections: {
      handler() {
        const dateColumnIds = this.IdsFromColumn(this.dateColumn) || [];

        const applyIgnoreToFilters = this.filterStore.applyIgnoreToFilters;
        const baseline = applyIgnoreToFilters('baseline', ...dateColumnIds);

        this.filteredSelection = { baseline, comparison: baseline };
      },
      immediate: true
    },
    commentsSelection() {
      this.analysisToolsUIStore.setCommentsSelection(this.commentsSelection);
    }
  },
  methods: {
    onDateSelected(
      dateName,
      rql,
      period,
      periodCounts,
      previousDateName,
      previousRql,
      previousPeriod
    ) {
      // clear the selected theme state when we change dates
      this.analysisToolsUIStore.setThemeState({ theme: null, subtheme: null });
      this.themeObject = undefined;
      this.dateRql = rql;
      this.dateFilter = {
        label: dateName,
        period,
        rql,
        counts: periodCounts
      };
      this.previousDateFilter = {
        label: previousDateName,
        period: previousPeriod
      };
      // track that the date changed
      analytics.track('Significant Changes: Date Selection Changed', { category: 'Significant Changes', value: dateName });
    },
    onCommentCount(count) {
      this.commentCount = count;
    },
    onThemeSelection(themeObject) {
      this.themeObject = themeObject;
    },
    selectedScoreChanged(score) {
      this.selectedScore = score;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';

.significant-changes {
  align-content: flex-start;
  display: contents;
}
</style>
