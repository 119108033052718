import * as moment from 'moment';
import queryBuilder from 'vue/libs/queryBuilder';
import { AvailableDateRange } from './types';

type TimePeriod = 'weekly' | 'monthly' | 'quarterly' | 'biannually';

const roundUpDateFrequencies = (frequencies: number) => {
  const decimalComponent = frequencies - Math.floor(frequencies);

  if (decimalComponent < 0.5) {
    return Math.ceil(frequencies);
  } else {
    return Math.ceil(frequencies) + 1;
  }
};

export const getMaxTimePeriodFrequencies = async ({ startDate, endDate }: AvailableDateRange) => {
  const dateEnd = moment(endDate);
  const dateStart = moment(startDate);
  const weeklyDiff = dateEnd.diff(dateStart, 'weeks', true);
  const monthlyDiff = dateEnd.diff(dateStart, 'months', true);
  const quarterlyDiff = dateEnd.diff(dateStart, 'quarters', true);
  const biAnnualDiff = dateEnd.diff(dateStart, 'months', true) / 6;

  return {
    weekly: roundUpDateFrequencies(weeklyDiff),
    monthly: roundUpDateFrequencies(monthlyDiff),
    quarterly: roundUpDateFrequencies(quarterlyDiff),
    biannually: roundUpDateFrequencies(biAnnualDiff),
  };
};

export const getDateLabelAndQuery = (
  timePeriod: TimePeriod,
  index: number,
  dateId: string,
  availableDateRange: AvailableDateRange
) => {
  // recent available date starts from the range end
  const rangeEnd = moment(availableDateRange.endDate || new Date());

  if (timePeriod === 'weekly') {
    const startDateMoment = rangeEnd.clone().subtract(index, 'week').startOf('isoWeek');
    const endDateMoment = rangeEnd.clone().subtract(index, 'week').endOf('isoWeek');

    const startDate = startDateMoment.toDate();
    const endDate = endDateMoment.toDate();

    let startDateLabel = startDateMoment.format('DD MMM');
    const endDateLabel = endDateMoment.format('DD MMM');
    const startDateYear = startDateMoment.format('YYYY');
    const endDateYear = endDateMoment.format('YYYY');

    if (startDateYear !== endDateYear) {
      startDateLabel += ` ${startDateYear}`;
    }

    const dateLabel = `${startDateLabel} - ${endDateLabel} ${endDateYear}`;

    const query = queryBuilder.buildDateQuery(dateId, startDate, endDate);

    return {
      dateLabel,
      query,
    };
  }

  if (timePeriod === 'monthly') {
    const startDateMoment = rangeEnd.clone().subtract(index, 'month').startOf('month');
    const endDateMoment = rangeEnd.clone().subtract(index, 'month').endOf('month');

    const startDate = startDateMoment.toDate();
    const endDate = endDateMoment.toDate();

    const dateLabel = startDateMoment.format('MMM YYYY');
    const query = queryBuilder.buildDateQuery(dateId, startDate, endDate);

    return {
      dateLabel,
      query,
    };
  }

  if (timePeriod === 'quarterly') {
    const quarterStartMonths = [0, 3, 6, 9];
    const currentQuarter = Math.floor(moment().month() / 3);
    const fiscalQuarterIndex = (currentQuarter - index + 4) % 4;
    const startMonth = quarterStartMonths[fiscalQuarterIndex];

    const startDateMoment = rangeEnd.clone().month(startMonth).startOf('month');
    const endDateMoment = startDateMoment.clone().add(2, 'months').endOf('month');

    const startDate = startDateMoment.toDate();
    const endDate = endDateMoment.toDate();

    const dateLabel = `Q${fiscalQuarterIndex + 1} ${startDateMoment.format('YYYY')}`;
    const query = queryBuilder.buildDateQuery(dateId, startDate, endDate);

    return {
      dateLabel,
      query,
    };
  }

  if (timePeriod === 'biannually') {
    const startPeriod = rangeEnd.clone().subtract(index * 6, 'months').startOf('month');
    const endPeriod = moment(startPeriod).add(6, 'months').endOf('month');

    const startDate = startPeriod.toDate();
    const endDate = endPeriod.toDate();

    const dateLabel = startPeriod.format('YYYY');
    const query = queryBuilder.buildDateQuery(dateId, startDate, endDate);

    return {
      dateLabel,
      query,
    };
  }

  throw new Error('Invalid time period');
};
