import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import * as React from 'react';
import { Button } from 'semantic-ui-react';

interface DeleteModalProps {
  title: string;
  isVisible: boolean;
  isDeleting: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onUnmount?: () => void;
}

export class DeleteModal extends React.Component<DeleteModalProps> {
  render() {
    const { title, isVisible, isDeleting, onCancel, onConfirm, onUnmount } = this.props;

    return (
      <Modal
        open={isVisible}
        size="small"
        onClose={onCancel}
        onUnmount={onUnmount}
      >
        <ModalHeader>Delete saved answer?</ModalHeader>
        <ModalContent>
          <p>This will delete "{title}"</p>
        </ModalContent>
        <ModalActions>
          <Button onClick={onCancel} autoFocus={true}>Cancel</Button>
          <Button
            color="red"
            loading={isDeleting}
            onClick={onConfirm}
          >
            Delete
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}
