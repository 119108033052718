import analytics from 'lib/analytics';
import { getAnalysisToolsUIStore, getThemesStore } from 'stores/RootStore';
import { sentimentMap } from "lib/calculate-sentiment";
import logSentiment from 'vue/libs/log-sentiment';
import updateSentiment from 'vue/libs/update-sentiment';

export default function () {
  return {
    props: {
      comment: { default: '', type: String },
      commentId: { type: String, default: '' },
      segments: { default: () => [], type: Array },
      commentColumnId: { type: Number, required: true }
    },
    data() {
      return {
        analysisToolsUIStore: getAnalysisToolsUIStore(),
        themesStore: getThemesStore(),
      };
    },
    computed: {
      areThemesInApplyingState() {
        return this.themesStore.applying;
      },
    },
    methods: {
      removeTheme(eventOrigin, theme, block) {
        if (!this.areThemesInApplyingState) {

          const locationsContainingTheme = this.segments
            .filter(seg => seg.themes.some(t => t.base === theme.base && t.sub === theme.sub))
            .map(seg => seg.location);

          const highlightedSentences = block
            ? [block.content]
            : locationsContainingTheme.map(
              location => this.comment.slice(location[0], location[1])
            );

          const removeThemeParams = {
            highlightedSentences,
            commentId: this.commentId,
            selectedTheme: {...theme},
            selectedBlock: block,
          };

          this.analysisToolsUIStore.showRemoveThemeDialog(removeThemeParams);
          analytics.track('Analysis: Remove Theme Started', { Location: eventOrigin });
        }
      },
      handleSentimentSelection(value, block) {

        const lastSentiment = block.sentiment;
        const surveyId = this.themesStore.surveyId;
        const commentId = this.commentId;
        const sentiment = sentimentMap.get(value);
        const [start, end] = block.location;

        /* Inform backend */
        updateSentiment(surveyId, commentId, {
          column: this.commentColumnId,
          location: [start, end],
          sentiment
        });

        /* Inform data team */
        logSentiment({
          sentiment: value,
          lastSentiment,
          block,
          surveyId,
          orgId: this.themesStore.orgId,
          comment: this.comment,
          commentId,
        });

        const nextSegments = this.segments.map(segment => {
          const isSameLocation = segment.location[0] === block.location[0] && segment.location[1] === block.location[1];
          return isSameLocation ? { ...segment, sentiment } : segment;
        });

        /* Inform frontend */
        this.$emit('onCommentSegmentsUpdated', nextSegments);

      },
    }
  };
}
