import { ScoreType } from 'api/enums';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, DropdownProps, Form, Modal } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface, FilterConfig } from 'stores/ui/ConfigureDataUIStore';
import { SCORE_TYPE_OPTIONS } from './constants';
import './configure-score-cut.scss';
import { set } from 'lodash';

interface ConfigureScoreCutStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureScoreCutProps extends ConfigureScoreCutStoreProps {
  filter: FilterConfig;

  onClose: () => void;
}

interface ConfigureScoreCutState {
  scoreType?: ScoreType;
}

@inject('configureDataUIStore')
@observer
export default class ConfigureScoreCut extends React.Component<ConfigureScoreCutProps, ConfigureScoreCutState> {

  state = {
    scoreType: undefined
  };

  componentDidMount () {
    const { auto_cut } = this.props.filter;
    if (auto_cut && auto_cut.options) {
      this.setState({ scoreType: auto_cut.options.score_type });
    }
  }

  cancel = () => {
    this.props.onClose();
  }

  save = () => {
    const { configureDataUIStore, filter } = this.props;
    const { scoreType } = this.state;

    const change = { ...filter };

    set(change, 'auto_cut.options.score_type', scoreType);

    configureDataUIStore!.updateFilter(filter.id, change);
    this.props.onClose();
  }

  handleScoreTypeChange = ({ value }: DropdownProps) => {
    this.setState({ scoreType: value as ScoreType });
  }

  render() {
    const { scoreType } = this.state;

    return <Modal
      className="configure-score-cut"
      dimmer="blurring"
      open={true}
      onClose={this.cancel}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Edit filter
      </Modal.Header>
      <Modal.Content>
        <div className="score-type">
          <Form.Field>
            <label>Score type</label>
            <Dropdown
              options={SCORE_TYPE_OPTIONS}
              placeholder="Select"
              value={scoreType}
              selectOnBlur={false}
              onChange={(_e, data) => this.handleScoreTypeChange(data)}
            />
          </Form.Field>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={this.cancel}>
          Cancel
        </Button>
        <Button
          color="blue"
          onClick={this.save}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>;
  }
}
