'use strict';

import { stringify } from 'query-string';
import Vue from 'vue';

export function toRoute(self: Vue, urlSlug: string) {
  const { query, path } = self.$route;
  let q = stringify(query);
  q = q ? `?${q}` : '';
  const re = /(.+\/v\/[^/]+).*/;
  const match = path.match(re);
  if (match) {
    const newPath = match[1];
    return `${newPath}/${urlSlug}${q}`;
  } else {
    return `${path}/${urlSlug}`;
  }
}