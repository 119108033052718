import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationType } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { getContinueLink } from './integration-helper';
import IntegrationLogo from './IntegrationLogo';

interface IntegrationRedirectStoreProps {
  integrationsStore?: IntegrationsStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

interface IntegrationRedirectParams {
  code: string;
  state: string;
}

export interface IntegrationRedirectProps extends
  IntegrationRedirectStoreProps,
  RouteComponentProps<IntegrationRedirectParams> {
  integrationName: string;
  integrationType: IntegrationType;
}

interface IntegrationRedirectState {
  orgId?: string;
  surveyId?: string;
  datasetName?: string;
  errorType?: string;
  errorDescription?: string;

  integration?: ConnectedIntegration;
}

@inject('integrationsStore', 'organizationStore', 'analysisToolsStore')
@observer
export default class IntegrationRedirect extends React.Component<IntegrationRedirectProps, IntegrationRedirectState> {
  state = {
    errorType: undefined,
    errorDescription: undefined,
    orgId: undefined,
    surveyId: undefined,
    datasetName: undefined,
  } as IntegrationRedirectState;
  componentDidMount() {
    const { integrationsStore, organizationStore, location, integrationType } = this.props;

    const search = parse(location.search) as {
      state: string,
      code: string,
      error?: string,
      error_description?: string
    };

    const state = JSON.parse(decodeURIComponent(search.state));
    const {orgId, surveyId, datasetName } = state;

    this.setState({ orgId, surveyId, datasetName });

    const authInfo = state;
    authInfo['code'] = search.code;

    organizationStore!.setOrg(orgId).then(async () => {
      const integration = await integrationsStore!.createIntegration(
        authInfo,
        integrationType
      );
      this.setState({orgId, integration});
    });

    this.setState({ errorType: search.error, errorDescription: search.error_description });
  }
  renderConnecting() {
    return (
      <Loader inline="centered" size="large" active={true}>
        Connecting
      </Loader>
    );
  }
  renderError() {
    const { integrationsStore, integrationName } = this.props;
    const { errorType, errorDescription } = this.state;
    const { createIntegrationErroredMessage } = integrationsStore!;

    return (
      <Message negative={true} icon={true}>
        <FontAwesomeIcon
          className="icon"
          icon="exclamation-triangle"
          fixedWidth={true}
        />
        <Message.Content>
          <Message.Header>
            Uh oh! Something went wrong linking to {integrationName}.
          </Message.Header>
          <p>Please refresh the page to try again.</p>
          { createIntegrationErroredMessage && (
            <p>{ createIntegrationErroredMessage }</p>
          )}
          { errorType && (
            <p>{ errorType }: { errorDescription }</p>
          )}
        </Message.Content>
      </Message>
    );
  }
  renderSuccessfullyConnected() {
    const { orgId, surveyId, datasetName } = this.state;
    const { integrationName, integrationType } = this.props;

    const continueLink = getContinueLink({
      orgId,
      surveyId,
      datasetName,
      dataSource: integrationType,
    });

    return (
      <div>
        <Message positive={true}>
          <Message.Content>
            <Message.Header>
              {integrationName} successfully connected
            </Message.Header>
          </Message.Content>
        </Message>

        <Button as={Link} to={continueLink}>
          Continue
        </Button>
      </div>
    );
  }
  render() {
    const { errorType, integration } = this.state;
    const { integrationsStore, integrationName, integrationType } = this.props;
    const { creating, createIntegrationErroredMessage } = integrationsStore!;

    const errored = createIntegrationErroredMessage || errorType;

    return (
      <Segment>
        <Header>
          <IntegrationLogo integrationType={integrationType} />
          {integrationName} Integration
        </Header>

        {
          creating ? this.renderConnecting() :
          errored ? this.renderError() :
          integration ? this.renderSuccessfullyConnected() :
          null
        }
      </Segment>
    );
  }
}
