import { HelperEnum } from 'api/enums';
import { reduce } from 'lodash';

const getWordCount = (str: string) => {
  return str.trim().split(' ').length;
};

export const getAverageWordCount = (strs: string[]) => {
  let wordCount = 0;
  strs.forEach((s) => (wordCount += getWordCount(s)));
  return Math.floor(wordCount / strs.length);
};

/*
  Checks if a value is a score
  - Must be numeric
  - Absolute value must not be exceed threshold
*/
export const isValueAScore = (value: string, maxThreshold: number) => {
  const numericValue = +value;
  return !isNaN(numericValue) && Math.abs(numericValue) <= maxThreshold;
};

export const getContextStringFromTerms = (value: string, terms: string[]) => {
  const candidateStrings = terms.map((term) => {
    return getContextString(value, term);
  });

  return reduce(candidateStrings, (result, candidate) => {
    return result.length > candidate.length ? result : candidate;
  }, '');
};

export const getContextString = (value: string, term: string) => {
  try {
    const termExistsInValue = value.toLowerCase().match(term.toLowerCase());
    if (termExistsInValue) {
      const termKey = term.replace(/ /g, '_');

      const modifiedValue = value
        .replace(/-/g, ' ')
        .replace(/\n/g, ' ')
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .toLowerCase()
        .replace(new RegExp(term, 'ig'), termKey);

      const termIndex = modifiedValue.split(' ').findIndex((el: string) => el === termKey);

      const startPosition = termIndex - HelperEnum.PAD_WORDS >= 0 ? termIndex - HelperEnum.PAD_WORDS : 0;
      const endPosition = termIndex + HelperEnum.PAD_WORDS;

      const newComment = value
        .replace(new RegExp(term, 'g'), termKey)
        .replace(/\n/g, ' ')
        .split(' ')
        .slice(startPosition, endPosition)
        .join(' ')
        .replace(new RegExp(termKey, 'g'), term);

      return newComment;
    }
  } catch (e) {
    return value;
  }
  return '';
};

export const removeBoundaryPeriodsAndCommas = (value) => {
  let newValue = value;
  if (newValue.startsWith('.') || newValue.startsWith(',')) {
    newValue = newValue.substring(1).trim();
  }
  if (newValue.endsWith('.') || newValue.endsWith(',')) {
    newValue = newValue.substring(0, newValue.length - 1).trim();
  }
  return newValue;
};

export const searchWithinWordBoundaries = (phrase, sentence) => {
  const updatedPhrase = phrase
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .split(' ')
    .filter((word: string) => word.length);
  const updatedSentence = sentence
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .split(' ')
    .filter((word: string) => word.length);
  const areAllPhraseWordsPresent = updatedPhrase.every((phraseWord) => {
    return updatedSentence.some((sentenceWord) => phraseWord.match(`\\b${ sentenceWord }\\b`));
  });
  return areAllPhraseWordsPresent;
};