<template>
  <div
    id="add-theme-to-comment"
    class="add-theme-to-comment"
  >
    <el-popover
      :value="selectionStatus !== 'NONE' && !openThemeDropdown"
      :style="{
        position: 'absolute',
        top: verticalPosition + 'px',
        left: horizontalButtonPosition() + 'px'
      }"
      placement="bottom-start"
    >
      <el-button
        v-if="selectionStatus === 'VALID' && !areThemesInApplyingState"
        @click.stop="onOpenThemeDropdown"
      >
        <span class="button-content">
          <tag-add-icon class="icon" />
          Add theme
        </span>
      </el-button>

      <span
        v-if="selectionStatus === 'INVALID_CROSSES_BOUNDARY' && !areThemesInApplyingState"
        class="warning"
      >
        Please select text that does not cross a sentence boundary
      </span>
      <span
        v-if="selectionStatus === 'INVALID_TOO_MANY_WORDS' && !areThemesInApplyingState"
        class="warning"
      >
        Please choose a phrase of {{ maxWords }} words or fewer. We advise choosing the minimum number of words which capture the meaning of the theme that you would like to represent.
      </span>

      <span
        v-if="areThemesInApplyingState"
        class="warning"
      ><span>Theme editing is not possible while theme application is in progress.</span></span>
    </el-popover>
    <theme-dropdown
      v-if="openThemeDropdown"
      :vertical-position="verticalPosition"
      :open-theme-dropdown="openThemeDropdown"
      :plain-blocks="plainBlocks"
      :conversations="conversations"
      :comment-id="commentId"
      :pre-selected-phrase="selectedPhrase"
      @closeThemeDropdown="onCloseThemeDropdown"
    />
  </div>
</template>

<script>
import analytics from 'lib/analytics';
import ThemeDropdown from './ThemeDropdown.vue';
import TagAddIcon from 'images/vue-icons/tag-add.svg';

export default {
  name: 'AddThemeToComment',
  components: { ThemeDropdown, TagAddIcon },
  props: {
    plainBlocks: { type: [Array, null] },
    conversations: { type: [Array, null] },
    areThemesInApplyingState: {default: false, type: Boolean},
    commentId: { default: '', type: [String, Number] },
    preSelectedPhrase: {default: '', type: String},
    maxWords: { type: Number },
    selectionStatus: { type: String, required: true },
    horizontalPosition: {default: undefined, type: Number},
    verticalPosition: {default: undefined, type: Number},
  },
  data() {
    return {
      openThemeDropdown: false,
      selectedPhrase: {default: '', type: String},
    };
  },
  methods: {
    onOpenThemeDropdown() {
      this.selectedPhrase = this.preSelectedPhrase
      this.openThemeDropdown = true;
      this.selectedPhrase = this.preSelectedPhrase;
      analytics.track('Analysis: Add Theme Started', { Location: 'Text Selection' });
    },
    onCloseThemeDropdown() {
      this.selectedPhrase = '';
      this.openThemeDropdown = false;
    },
    tagAddIcon() {
      return require(`images/vue-icons/tag-add.svg`);
    },
    horizontalButtonPosition() {
      const offset = this.selectionStatus === 'VALID' ? 70 : 120;

      const position = this.horizontalPosition - offset;
      if (position < 0) {
        return 0;
      } else {
        return position;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.add-theme-to-comment ::v-deep .el-popover {
  margin-top: 1em !important;
  min-width: auto;
  padding: 0;
  border: none;
}
.add-theme-to-comment {
  .el-button {
    font-size: 16px;
    padding: 14px 18px 12px 16px;
    border: none;
    &.disabled-button {
      color: $--color-text-primary;
      opacity: 0.5;
    }
    &:hover {
      color: $--color-text-primary;
    }
  }
  .button-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 6px;
    }
  }
}
.warning {
  display: flex;
  flex-direction: column;
  user-select: none;
  border-radius: 0.5rem;
  box-shadow: $--box-shadow-light;
  background: $--color-text-regular;
  text-align: initial;
  display: block;
  padding: 0.75rem;
  width: 16rem;
  color: $--white;
}

</style>
