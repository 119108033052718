import { AnalysisFilter } from 'api/interfaces';
import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Category } from './types';

interface Props {
  filters: AnalysisFilter[];
  category: Category | null;
  setCategory: (category: Category) => void;
}

const NONE_CATEGORY: Category = {
  id: 'none',
  name: 'None',
  type: 'none',
  columns: []
}

export const ExportAssistantCategorySelect: React.FC<Props> = ({ filters, category, setCategory }) => {
  const filtersWithNoneDefault = [NONE_CATEGORY, ...filters];
  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    const selectedId = data.value;
    const selectedFilter = filtersWithNoneDefault.find(f => f.id === selectedId);
    if (selectedFilter) {
      setCategory({
        id: selectedFilter.id,
        name: selectedFilter.name,
        type: selectedFilter.type === 'date' ? 'date' : 'filter',
        columns: []
      });
    }
  };
  const options = filtersWithNoneDefault
    .map(filter => ({
      key: filter.id,
      text: filter.name,
      value: filter.id,
    }));

  return (
    <Dropdown
      fluid
      selection
      options={options}
      value={category?.id || 'none'}
      onChange={handleChange}
    />
  );
};
