import { AnalysisFilter } from 'api/interfaces';
import * as React from 'react';
import { Select } from 'semantic-ui-react';

interface Props {
  disabledFilters?: string[];
  filters: AnalysisFilter[];
  onFilterSelect: (filterId: string) => void;
  placeholder?: string;
  value: string;
}

export const ExportAssistantFilterSelect = ({
  disabledFilters,
  filters,
  onFilterSelect,
  placeholder = 'Please select',
  value
}: Props) => {
  if (!filters) {
    return null;
  }

  const filterOptions = filters
    .filter(({ type }) => {
      if (!type) {
        return true;
      }

      return !disabledFilters?.includes(type)
    })
    .map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));

  const handleRowChange = (filterId: string) => {
    onFilterSelect(filterId);
  };

  return (
    <Select
      onChange={(_, data) => handleRowChange(data.value as string)}
      options={filterOptions}
      placeholder={placeholder}
      value={value}
    />
  );
};
