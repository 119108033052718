import * as React from 'react';
import { max, min } from 'lodash';
import lineGraph from 'charts/Utils/lineGraph';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { useResizeObserver } from 'hooks/useResizeObserver';
import './line-graph.scss';
import { D3Tip } from 'd3-tip';

interface Props {
  values: number[];
  labels: string[];
  partialValues?: number[];
}

const LineGraph = withCamelCaseProps((props: Props) => {
  const { partialValues, values, labels } = props;
  const [containerRef, dimensions] = useResizeObserver<HTMLDivElement>();
  const lineGraphRef = React.useRef<SVGSVGElement>(null);
  const tipRef = React.useRef<D3Tip | undefined>(undefined);

  const high = Math.max(0, max(values) ?? 0);
  const low = Math.min(0, min(values) ?? 0);

  const updateChart = React.useCallback(({width, height}) => {
    if (!lineGraphRef.current || !containerRef.current) {
      return;
    }

    const chartOptions = {
      containerHeight: height,
      containerWidth: width,
      labels,
      baselineValues: values,
      low,
      high
    };

    if (tipRef.current) {
      tipRef.current.destroy();
    }

    tipRef.current = lineGraph.getLineGraphTip();

    lineGraph.renderLineGraph({
      el: lineGraphRef.current,
      chartOptions,
      tip: tipRef.current
    });
  }, [containerRef, labels, values, low, high]);

  React.useEffect(() => {
    updateChart(dimensions);
  }, [partialValues, dimensions, updateChart]);

  React.useEffect(() => {
    return () => {
      if (tipRef.current) {
        tipRef.current.destroy();
      }
    };
  }, []);

  return (
    <div ref={containerRef} className="line-graph-container">
      <svg ref={lineGraphRef} />
    </div>
  );
});

export { LineGraph };
