import * as React from 'react';
import { Button } from 'components/Shared/Button';
import { Tooltip } from 'components/Shared/Tooltip';
import {
  faBuilding,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sharing-button.scss';

interface SharingButtonProps {
  isSharingAllowed: boolean;
  orgName: string;
  onShare: () => void;
}

const TOOLTIP_OFFSET: [number, number] = [5, 0];

export const SharingButton = (props: SharingButtonProps) => {

  const privateCopy = 'Private - only I can see this answer';
  const publicCopy = `Anyone in ${props.orgName} with the link can view this answer`;
  return (
    <div className="answers__sharing ob-answers-sharing">
      <Tooltip
        content={props.isSharingAllowed ? publicCopy : privateCopy}
        position="bottom right"
        inverted
        offset={TOOLTIP_OFFSET}
        on={['hover', 'focus']}
        trigger={
          <span className="answers__sharing-icon">
            <FontAwesomeIcon icon={props.isSharingAllowed ? faBuilding : 'lock'} />
          </span>
        }
      />
      <Button
        variant='secondary'
        size='medium'
        onClick={props.onShare}
      >
        Share
      </Button>
    </div>
  );
};
