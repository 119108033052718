import * as React from 'react';
import { isNumber } from 'lodash';
import { VALID_SENTIMENTS } from 'lib/calculate-sentiment';
import { SegmentedBar } from 'components/Dashboard/Widgets/Components/SegmentedBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import ArrowUp from 'images/icons/arrow-up.svg';
import ArrowDown from 'images/icons/arrow-down.svg';
import './stack-rank-headline.scss';
import toFixed from 'vue/libs/to-fixed';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';

type Segment = {
  label: string;
  count: number;
  color: string;
  colorEnd: string;
};

type StackRankHeadlineProps = {
  count: number;
  label: string;
  maxScore: number;
  minScore: number;
  previousScore?: number;
  segments: Segment[];
  score: number;
  sentiment?: string;
  countSuffix?: string;
  isWeighted?: boolean;
  isSelected?: boolean;
  onClearSelection?: () => void;
};

const StackRankHeadline = withCamelCaseProps(({
  count,
  label,
  maxScore,
  minScore,
  previousScore,
  segments,
  score,
  sentiment,
  countSuffix = ' responses',
  isSelected = false,
  isWeighted = false,
  onClearSelection,
}: StackRankHeadlineProps) => {
  const change = isNumber(previousScore) ? score - previousScore : undefined;
  const hasChange = isNumber(change);

  const getChangeArrow = () => {
    if (change && change >= 0.1) {
      return ArrowUp;
    } else if (change && change <= -0.1) {
      return ArrowDown;
    }
    return null;
  };

  const ChangeArrow = getChangeArrow();

  const changeLabel = change ? toFixed(change, 1) : '';
  const countLabel = `${toFixed(count, 0)}${countSuffix}`;

  const scoreLabel = !isNaN(score) && isNumber(score)
    ? (score > 0 ? `+${toFixed(score, 1)}` : toFixed(score, 1))
    : undefined;

  const sentimentIcon = sentiment && VALID_SENTIMENTS.includes(sentiment)
    ? (sentiment as IconName)
    : undefined;

  return (
    <div
      className={`
        stack-rank-headline 
        ${hasChange ? 'stack-rank-headline--has-change' : ''} 
        ${isSelected ? 'stack-rank-headline--selected' : ''}
      `}
      onClick={() => onClearSelection && onClearSelection()}
    >
      <div className="stack-rank-headline__label">
        <h3>Overall {label}</h3>
      </div>
      <div className="stack-rank-headline__score">
        {scoreLabel}
      </div>
      <div className="stack-rank-headline__nps">
        <SegmentedBar
          large={true}
          max={maxScore}
          min={minScore}
          segments={segments}
          score={score}
          showCommentCount={!isWeighted}
        />
      </div>
      {hasChange && (
        <div className="stack-rank-headline__change">
          {ChangeArrow && <ChangeArrow />}
          <span>{change && change >= 0 ? `+${changeLabel}` : changeLabel}</span>
        </div>
      )}
      <div className="stack-rank-headline__volume">
        {countLabel}
      </div>
      <div className="stack-rank-headline__sentiment">
        {sentimentIcon && (
          <FontAwesomeIcon
            icon={sentimentIcon}
            className={`sentiment-${sentiment}`}
          />
        )}
      </div>
    </div>
  );
});

export { StackRankHeadline };
