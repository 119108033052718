<template>
  <div class="remove-theme-example-comments">
    <el-dialog
      :visible="dialogVisible"
      width="50%"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div
        v-if="commentsUpdating"
        v-loading="commentsUpdating"
        element-loading-text="Loading..."
        class="show-comments-loader"
      />
      <div
        v-else
        class="show-comments-body"
      >
        <div class="icon__tag-remove">
          <tag-remove class="icon" />
        </div>
        <div>
          <div class="title">
            Remove <span class="theme-name">{{ title }}</span> from
            {{ affectedComments >= 1 ? "approx." : "less than" }} {{ affectedComments > 1 ? affectedComments : '1' }}% of comments?
            <div class="description">
              This will affect all comments containing the phrase "<span class="selected-phrase">{{ selectedPhrases[0] }}</span>".
            </div>
          </div>
          <div class="content">
            <example-comments
              v-if="exampleComments.length > 0"
              :comments="exampleComments.slice(0, 3)"
              :phrases="selectedPhrases"
              :processing="processing"
              :processerror="false"
              :istruncated="true"
              :isquickedit="true"
            />
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancel-button"
          @click="onCancel"
        >Cancel</el-button>
        <el-button
          type="primary"
          class="untag-comments"
          :disabled="commentsUpdating"
          @click="onConfirm"
        >
          Remove theme
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExampleComments from 'components/ThemeEditor/ExampleComments.tsx';
import {
  getThemesStore,
  getOrganizationStore,
  getQuickEditUIStore,
  getAnalysisToolsUIStore
} from 'stores/RootStore';
import { getCoverageAndExamplesForPhrases } from 'vue/libs/quick-edits-review-changes';
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import { isEmpty } from 'lodash';
import TagRemove from 'images/vue-icons/tag-remove-right.svg';

export default {
  name: 'RemoveThemeExampleComments',
  components: {
    'example-comments': ReactInVue(ExampleComments),
    TagRemove
  },
  commentWorker: Worker,
  props: {
    selectedPhrases: {default: () => [], type: Array},
    selectedTheme: {default: () => ({}), type: Object},
    isPhrasePreSelected: {default: false, type: Boolean},
    commentId: { default: '', type: [String, Number] },
    selectedBlock: {default: () => ({}), type: Object},
  },
  data() {
    return {
      themesStore: getThemesStore(),
      organizationStore: getOrganizationStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      quickEditUIStore: getQuickEditUIStore(),

      dialogVisible: this.selectedPhrases.length > 0,
      exampleComments: [],
      processing: false,
      affectedComments: 0,
      commentsUpdating: false
    };
  },
  computed: {
    title() {
      if (this.selectedTheme.title && this.selectedTheme.title.includes(':')) {
        return `${this.selectedTheme.baseTitle}: ${this.selectedTheme.subTitle}`;
      } else {
        return this.selectedTheme.baseTitle;
      }
    },
    isThemeRemovedFromPopup() {
      return !isEmpty(this.selectedBlock);
    }
  },
  mounted() {
    this.updateCommentsAndCoverage();
  },
  methods: {
    onConfirm() {
      this.dialogVisible = false;
      const { editingGroup: group } = this.themesStore;

      this.deleteMappedPhrases(group);
      analytics.track('Analysis: Remove Theme Completed');

      // create the change to track
      let changes;
      if (this.isThemeRemovedFromPopup) {
        changes = {
          themesRemovedFromPopup: [{
            theme: {...this.selectedTheme},
            location: this.selectedBlock.location
          }]
        };
      } else {
        changes = {
          themesRemoved: [{...this.selectedTheme}]
        };
      }
      this.quickEditUIStore.haveEditedComment(this.commentId, changes);
      this.quickEditUIStore.saveEditedComments(this.analysisToolsUIStore.currentSurveyId);
      this.$emit('onAction');
    },
    deleteMappedPhrases(group) {
      const activeNode = this.themesStore.getActiveNode(group);
      if (!activeNode) {
        return;
      }

      this.themesStore.deletePhrases(group, activeNode, this.selectedPhrases);
    },
    onCancel() {
      this.dialogVisible = false;
      this.$emit('onAction');
    },
    async updateCommentsAndCoverage() {
      this.commentsUpdating = true;
      const orgId = this.organizationStore.orgId;
      const surveyId = this.analysisToolsUIStore.currentSurveyId;
      this.processing = true;
      const results = await getCoverageAndExamplesForPhrases(orgId, surveyId, this.selectedPhrases);
      this.affectedCommentsPercentage = results.coverage;
      this.exampleComments = results.exampleComments;
      this.processing = false;
      this.commentsUpdating = false;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.remove-theme-example-comments ::v-deep .el-dialog__header {
  padding: 0;
}
.remove-theme-example-comments {
  .show-comments-loader {
    padding: 20px 20px 40px 20px;
  }
  .show-comments-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .icon {
      &__tag-remove {
        svg {
          background-color: $--red-600;
          border-radius: 50%;
          padding: 0.5rem;
          display: block;
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }
      }
    }
    .title {
      font-size: 16px;
      color: $--color-text-primary;
      padding-bottom: 15px;
      line-height: 22px;
      font-weight: 600;
      .theme-name {
        align-items: center;
        border: none;
        color: $--neutral-700;
        line-height: 1;
        border-radius: 10em;
        padding: 4px 8px;
        background: $--neutral-100;
        height: 22px;
      }
      .description {
        font-size: 14px;
        color: $--neutral-900;
        font-weight: 400;
        padding-top: 4px;
        .selected-phrase {
          font-weight: bold;
        }
      }
    }
    .content {
      font-size: 14px;
      border-radius: 4px;
      line-height: 1.6em;
      div {
        text-align: left;
        ::v-deep .ui.list {
          .list-action {
            background: $--neutral-100;
            padding: 12px;
            border-bottom: none;
            mark {
              background-color: transparent;
              font-weight: bold;
              color: $--neutral-900;
            }
          }
        }
      }
    }
  }
  .dialog-footer {
    .untag-comments {
      background-color: $--red-600;
      border-color: $--red-600;
    }
    .untag-comments.is-disabled {
      background-color: $--red-300;
      border-color: $--red-300;
    }
  }
}
</style>
