import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Block } from 'lib/segments-to-blocks';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import SelectPhrase from 'vue/components/Themes/SelectPhrase.vue';
import { VueInReact } from 'vuera';
import { ThemeDropdown as ReactThemeDropdown } from '../AddTheme/ThemeDropdown';
import {
  AddThemeBaseItem,
  SELECTION_STATUS,
  SelectedPhrasePosition,
  SelectedTheme,
  SelectionStatus,
} from '../AddTheme/types';
import './add-theme-to-comment.scss';
import { AddThemePopUp } from './AddThemePopUp';
import { useSimilarSentencesContext } from './similar-sentences.context';
import { useThemes } from 'components/AddTheme/useThemes';
import { getQuickEditUIStore, getThemesStore } from 'stores/RootStore';
import { SelectPhraseModal } from 'components/AddTheme/SelectPhraseModal';
import { ShowCommentsModal } from './ShowCommentsModal';
import { AddThemeExampleCommentsModal } from 'components/AddTheme/AddThemeExampleCommentsModal';
import NewTheme from 'vue/components/Themes/NewTheme.vue';
import { ThemeGroup } from 'lib/theme-file-parser';
import { ThemeTreeItem } from 'stores/ThemesStore';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import ThemeDropdown from 'vue/components/Themes/ThemeDropdown.vue';

const NewThemeVue = VueInReact(NewTheme);
const SelectPhraseVue = VueInReact(SelectPhrase);
const ThemeDropdownVue = VueInReact(ThemeDropdown);

interface Props {
  blocks?: Block[];
  clearSelection: () => void;
  commentId: string;
  isApplyingThemes: boolean;
  orgId: string;
  preSelectedPhrase: string;
  selectedPhrasePosition: SelectedPhrasePosition;
  selectionStatus: SelectionStatus;
  surveyId: string;
}

const AddThemeToComment = observer(({
  blocks,
  clearSelection,
  commentId,
  orgId,
  preSelectedPhrase,
  selectedPhrasePosition,
  selectionStatus,
  surveyId,
}: Props) => {
  const [openThemeDropdown, setOpenThemeDropdown] = React.useState(false);
  const [selectedPhrase, setSelectedPhrase] = React.useState<string>('');
  const [selectedTheme, setSelectedTheme] = React.useState<SelectedTheme | null>(null);
  const [showNewThemeDialog, setShowNewThemeDialog] = React.useState<boolean>(false);
  const [newTheme, setNewTheme] = React.useState<string>('');
  const addThemeToCommentRef = React.useRef<HTMLDivElement>(null);
  const themeDropdownRef = React.useRef<HTMLDivElement>(null);
  const { isApplyingThemes } = useSimilarSentencesContext();
  const { themesHierarchy, transforms } = getThemesStore();
  const themes = useThemes(themesHierarchy, transforms);

  const handleThemeDropdownOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenThemeDropdown(true);
  };

  const positionTop = selectedPhrasePosition.y + 10;

  const showPopup = selectionStatus !== SELECTION_STATUS.None && !openThemeDropdown && !selectedTheme;

  useOnClickOutside(themeDropdownRef, () => {
    setOpenThemeDropdown(false);
    clearSelection();
  });

  const handleThemeDropdownClose = () => {
    setOpenThemeDropdown(false);
  };

  const handleThemeSelect = (addedTheme: SelectedTheme) => {
    setSelectedTheme(addedTheme);
    setOpenThemeDropdown(false);
  };

  const handleAddNewTheme = (newTheme: string) => {
    setNewTheme(newTheme);
    setShowNewThemeDialog(true);
  };

  const handleThemeCreation = (newTheme: AddThemeBaseItem) => {
    setSelectedTheme(newTheme);
    setNewTheme('');
    setShowNewThemeDialog(false);
  };

  const handleSelectPhraseModalClose = () => {
    clearSelection();
  };


  const handleConfirmSelection = (selectedPhrase: string) => {
    setSelectedPhrase(selectedPhrase);
  };

  const handleCloseShowComments = () => {
    setSelectedTheme(null);
    clearSelection();
  };

  const addMappedPhrases = (group: ThemeGroup, newNode: ThemeTreeItem | undefined) => {
    if (!selectedTheme) {
      return;
    }

    const themesStore = getThemesStore();
    const updatedGroupWithActiveNodeId = {
      ...group,
      activeNodeId: 'sub' in selectedTheme ? selectedTheme.sub : selectedTheme.base
    };
    const node = newNode || themesStore.getActiveNode(updatedGroupWithActiveNodeId);

    if (node) {
      themesStore.addPhrases(group, node, [selectedPhrase]);
    }
  };

  const handleConfirmComments = () => {
    const themesStore = getThemesStore();
    const quickEditUIStore = getQuickEditUIStore();
    const { editingGroup } = themesStore;

    if (!editingGroup || !selectedTheme) {
      return;
    }

    const { base, label } = selectedTheme;
    const newNode = !selectedTheme.id ? themesStore.addTheme(editingGroup, label, base, [selectedPhrase]) : undefined;

    addMappedPhrases(editingGroup, newNode);

    const changes = {
      themesAdded: [{...selectedTheme}],
    };

    quickEditUIStore.haveEditedComment(commentId, changes);
    quickEditUIStore.saveEditedComments(surveyId);

    setSelectedTheme(null);
    clearSelection();
  };

  const showCommentsModal = selectedTheme && (selectedPhrase || preSelectedPhrase);
  const showSelectPhraseModal = selectedTheme && !preSelectedPhrase && !selectedPhrase;
  const canSeeQuickPhraseSelection = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_QUICK_PHRASE_SELECTION);
  const canSeeAddThemeFromUntaggedComments = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_ADD_THEME_FROM_UNTAGGED_COMMENTS);

  return (
    <div className="react-add-theme-to-comment" ref={addThemeToCommentRef}>
      <div
        className="react-add-theme-to-comment__pop-up"
        style={{
          left: `${selectedPhrasePosition.x}px`,
          top: `${positionTop}px`,
        }}
      >
        <AddThemePopUp
          clearSelection={clearSelection}
          isOpen={showPopup}
          isApplyingThemes={isApplyingThemes}
          onAddTheme={handleThemeDropdownOpen}
          selectionStatus={selectionStatus}
          />
        {canSeeAddThemeFromUntaggedComments && openThemeDropdown && (
          <div>
            <ReactThemeDropdown
              blocks={blocks}
              commentId={commentId}
              onAddNewTheme={handleAddNewTheme}
              onClose={handleThemeDropdownClose}
              onThemeSelect={handleThemeSelect}
              open={openThemeDropdown}
              preSelectedPhrase={preSelectedPhrase}
              themeDropdownRef={themeDropdownRef}
              themes={themes}
            />
          </div>
        )}
        {!canSeeAddThemeFromUntaggedComments && openThemeDropdown && (
          <div ref={themeDropdownRef}>
            <ThemeDropdownVue
              plainBlocks={blocks}
              commentId={commentId}
              openThemeDropdown={openThemeDropdown}
              preSelectedPhrase={preSelectedPhrase}
              verticalPosition={10}
            />
          </div>
        )}
      </div>
      {canSeeAddThemeFromUntaggedComments && (
        <>
          {showSelectPhraseModal && (
            <SelectPhraseModal
              blocks={blocks}
              onClose={handleSelectPhraseModalClose}
              onConfirmSelectedPhrase={handleConfirmSelection}
              selectedTheme={selectedTheme}
            />
          )}
          {showCommentsModal && !canSeeQuickPhraseSelection && (
            <ShowCommentsModal
              commentId={commentId}
              isPhrasePreSelected={!!preSelectedPhrase}
              onBack={handleCloseShowComments}
              onClose={handleCloseShowComments}
              onConfirm={handleConfirmComments}
              orgId={orgId}
              selectedPhrases={[selectedPhrase || preSelectedPhrase]}
              selectedTheme={selectedTheme}
              surveyId={surveyId}
            />
          )}
          {showCommentsModal && canSeeQuickPhraseSelection && (
            <AddThemeExampleCommentsModal
              commentId={commentId}
              isPhrasePreSelected={!!preSelectedPhrase}
              onConfirm={handleConfirmComments}
              onClose={handleCloseShowComments}
              orgId={orgId}
              selectedPhrases={[selectedPhrase || preSelectedPhrase]}
              selectedTheme={selectedTheme}
              surveyId={surveyId}
            />
          )}
        </>
      )}
      {!canSeeAddThemeFromUntaggedComments && selectedTheme && (
        <div>
          <SelectPhraseVue
            plainBlocks={blocks}
            commentId={commentId}
            on={{
              'onAction': () => handleCloseShowComments()
            }}
            preSelectedPhrase={preSelectedPhrase}
            selectedTheme={selectedTheme}
          />
        </div>
      )}
      {showNewThemeDialog && (
        <div>
          <NewThemeVue
            allThemes={themes}
            on={{
              'onThemeCreation': (newTheme: AddThemeBaseItem) => handleThemeCreation(newTheme),
              'onAction': () => setNewTheme('')
            }}
            themeName={newTheme}
          />
        </div>
      )}
    </div>
  );
});

export { AddThemeToComment };
