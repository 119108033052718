import { formatDateWithTime } from 'lib/date';
import { dateToDateMonth } from 'lib/date';
import * as React from 'react';
import { Button } from 'components/Shared/Button';
import { Tooltip } from 'components/Shared/Tooltip';

type Props = {
  onRefreshAnswer?: () => void;
  utcDate: string;
  showRefreshTooltip?: boolean;
};

export const FormattedDate = (props: Props) => {
  const shortDate = dateToDateMonth(props.utcDate);
  const longDate = formatDateWithTime(props.utcDate);

  return (
    <span className="answers__formatted-date">
      {!props.showRefreshTooltip && (
        <time
          title={longDate}
          dateTime={props.utcDate}>
          {shortDate}
        </time>
      )}
      {props.showRefreshTooltip && (
        <Tooltip
          disabled={!props.showRefreshTooltip}
          hoverable
          position="bottom left"
          trigger={
            <span>
              <Button size="small" variant="tertiary">
                <time
                  title={longDate}
                  dateTime={props.utcDate}>
                  {shortDate}
                </time>
              </Button>
            </span>
          }
        >
          <p>This answer was created on {shortDate}.</p>
          <Button onClick={props.onRefreshAnswer} size="small" variant="primary">
            Update answer
          </Button>
        </Tooltip>
      )}
    </span>
  );
};

