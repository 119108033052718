import * as React from 'react';
import { Button } from 'semantic-ui-react';
import './theme-multi-select-info.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultipleThemeSelect from '../../images/icons/multiple-theme-select.svg';

interface Props {
  onClear: () => void;
  multiSelectCount: number;
}

const ThemeMultiSelectInfo = ({ multiSelectCount, onClear }: Props) => {
  return (
    <div className="theme-multi-select-info">
      <header className="theme-multi-select-info__header">
        <h3 className="theme-multi-select-info__title">{multiSelectCount} {multiSelectCount > 1 ? 'themes' : 'theme'} selected</h3>
        <Button size="small" className="theme-multi-select-info__close" onClick={() => onClear()}>
          Clear
          <FontAwesomeIcon className="icon" size="sm" icon="times" />
        </Button>
      </header>
      <div className="theme-multi-select-info__content">
        <MultipleThemeSelect />
      </div>
    </div>
  )
}

export { ThemeMultiSelectInfo }
