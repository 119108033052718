import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnswersAction, InputData, SegmentFilters } from 'api/interfaces';
import classnames from 'classnames';
import { Tooltip } from 'components/Shared/Tooltip';
import { dropWhile, takeWhile } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { isMoreDepthNode, isQuotesNode, isSuggestionNode, isToggleableNode, isVisualizationNode } from 'stores/utils/answers-helpers';
import { AnswerNode } from 'types/custom';
import { getErrorTitle } from '../utils/getErrorTitle';
import { getQuestion } from '../utils/getQuestion';
import { Answer } from './Answer';
import { AnswerSupplementary } from './AnswerSupplementary';
import { AnswersError } from './AnswersError';
import { Loading } from './Loading';
import { NoData } from './NoData';
import { NotEnoughData } from './NotEnoughData';
import { QuestionNotClear } from './QuestionNotClear';
import { QuestionOutsideDomain } from './QuestionOutsideDomain';
import { Unsupported } from './Unsupported';
import { Warning } from './Warning';
import './tree-item.scss';

type FollowUp = {
  type: 'answers-action',
  payload: {
    action: AnswersAction,
    node: AnswerNode
  }
};

type RefreshAnswer = {
  type: 'refresh-answer',
  payload: {
    action: AnswersAction,
    node: AnswerNode
  }
};

type HideClick = {
  type: 'hide-click'
  payload: {
    node: AnswerNode
  }
};

type RemoveClick = {
  type: 'remove-click'
  payload: {
    node: AnswerNode
  }
};

type SuggestClick = {
  type: 'suggest-click',
  payload: string
};

type TogglePin = {
  type: 'toggle-pin',
  payload: {
    node: AnswerNode,
    example: InputData
  }
};


export type UserAction
  = FollowUp
  | SuggestClick
  | HideClick
  | RemoveClick
  | RefreshAnswer
  | TogglePin;

type Props = {
  className?: string,
  savedAnswerId: string,
  node: AnswerNode,
  getSiblings: () => AnswerNode[],
  dispatch: (action: UserAction) => void,
  onRefineQuery: (question: string) => void,
  isReadOnly: boolean;
  disableCloseButton?: boolean,
  isChild?: boolean,
};

export const TreeItem = observer(function (props: Props) {
  const { node, dispatch, onRefineQuery } = props;
  const filters: SegmentFilters = node.item.state === 'output' ? node.item.filters ?? {} : {};

  const visibleChildren = node.children.filter(c => c.item.isVisible);
  const hasChildren = visibleChildren.length > 0;

  function onAction(
    action: AnswersAction,
    type: 'answers-action' | 'refresh-answer' = 'answers-action'
  ) {
    dispatch({
      type,
      payload: { action, node }
    });
  }

  function removeItem() {

    // Certain items should be hidden, not removed
    const shouldHide: boolean = isToggleableNode(node);

    dispatch({
      type: shouldHide ? 'hide-click' : 'remove-click',
      payload: { node }
    });

  }

  function onSuggestionClick(suggestion: string) {
    dispatch({
      type: 'suggest-click',
      payload: suggestion
    });
  }

  function handleToggleExamplePin(example: InputData) {
    dispatch({
      type: 'toggle-pin',
      payload: { node, example },
    });
  }

  function getView(item: AnswerNode['item']) {

    const question = getQuestion(item);

    switch (item.state) {
      case 'loading': return (
        <Loading
          message={item.message}
        />
      );
      case 'error': {

        switch (item.originalError.errorCode) {
          case 'NO_DATA': {
            return (
              <NoData
                question={question}
                error={item.originalError}
                datasets={item.originalDatasets}
                onRefineQuery={onRefineQuery}
              />
            );
          }
          case 'NO_DATA_PAIR_COMPARISON': {
            return (
              <NoData
                question={question}
                error={item.originalError}
                datasets={item.originalDatasets}
                onRefineQuery={onRefineQuery}
              />
            );
          }
          case 'QUESTION_OUTSIDE_DOMAIN': {
            return (
              <QuestionOutsideDomain
                question={question}
                error={item.originalError}
                onSuggestionClick={onSuggestionClick}
              />
            );
          }
          case 'NOT_ENOUGH_DATA': {
            return (
              <NotEnoughData
                filters={filters}
                question={question}
                error={item.originalError}
                datasets={item.originalDatasets}
              />
            );
          }
          case 'QUESTION_NOT_SUPPORTED': {
            return (
              <Unsupported
                onSuggestionClick={onSuggestionClick}
                question={question}
                error={item.originalError}
              />
            );
          }
          case 'QUESTION_NOT_CLEAR': {
            return (
              <QuestionNotClear
                question={question}
                error={item.originalError}
                onSuggestionClick={onSuggestionClick}
              />
            );
          }
          case 'INTERNAL_SERVER_ERROR':
          case 'CLIENT_ERROR':
            return (
              <AnswersError
                tracking={{
                  event: "Error: Answers",
                  eventOptions: {
                    "title": "An unknown error occurred",
                    "description": `${item.originalError.error}`
                  }
                }}
              >
                <p>{item.originalError.error}</p>
              </AnswersError>
            );
          case 'NO_DATASETS_SELECTED':
            return (
              <section>
                <h4 className="answers__tree-item-title">{getErrorTitle(question)}</h4>
                <Warning
                  title="No dataset was selected!"
                >
                  <p>
                    Please select a dataset.
                  </p>
                </Warning>
              </section>
            );
          default: {
            return (
              <section>
                <h4 className="answers__tree-item-title">{getErrorTitle(question)}</h4>
                <Warning
                  title={item.originalError.error}
                />
              </section>
            );
          }
        }

      }
      case 'output': {

        // Top level items have toggles as children, and are not followed by toggleable items
        // Nested items are the reverse.
        const siblings = props.getSiblings();

        if (item.actionType === 'ask' || item.actionType === 'moreDepth') {

          const siblingsFromItem = dropWhile(siblings, n => n.item.id !== node.item.id);
          const siblingToggles = takeWhile(siblingsFromItem.slice(1), isToggleableNode);
          const childToggles = takeWhile(props.node.children, isToggleableNode);
          const toggleableNodes = [...siblingToggles, ...childToggles];

          const hasVisualization = toggleableNodes.find(isVisualizationNode)?.item.isVisible ?? false;
          const hasSuggestions = toggleableNodes.find(isSuggestionNode)?.item.isVisible ?? false;
          const hasQuotes = toggleableNodes.find(isQuotesNode)?.item.isVisible ?? false;

          return (
            <Answer
              item={item}
              savedAnswerId={props.savedAnswerId}
              hasVisualization={hasVisualization}
              hasSuggestions={hasSuggestions}
              hasQuotes={hasQuotes}
              isReadOnly={props.isReadOnly}
              onAction={onAction}
              showRefreshTooltip={!props.isChild}
            />
          );
        } else {
          return (
            <AnswerSupplementary
              item={item}
              isReadOnly={props.isReadOnly}
              onToggleExamplePin={handleToggleExamplePin}
            />
          );
        }
      }
      default: return null;
    }
  }

  const isToggleable = isToggleableNode(props.node);
  const isMoreDepth = isMoreDepthNode(props.node);

  const classNames = classnames('tree-item', props?.className, {
    'tree-item--toggleable': isToggleable,
    'tree-item--more-depth': isMoreDepth,
  });

  const extraItemProps = 'originalError' in node.item ? {
    'data-error-code': node.item.originalError.errorCode
  } : {};

  return (
    <li
      className={classNames}
      data-node-id={node.item.id}
      tabIndex={0}
      {...extraItemProps}
    >
      <Tooltip
        position="top center"
        inverted={true}
        content="Remove answer"
        on={['focus', 'hover']}
        trigger={
          <Button
            icon={true}
            role="button"
            aria-label="remove-item"
            onClick={() => removeItem()}
            disabled={props?.disableCloseButton}
            className="tree-item__remove-button"
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        }
      />
      {getView(node.item)}
      {hasChildren && (
        <ul>
          {visibleChildren.map((child: AnswerNode) => (
            <TreeItem
              key={child.item.id}
              isChild={true}
              savedAnswerId={props.savedAnswerId}
              isReadOnly={props.isReadOnly}
              node={child}
              getSiblings={() => visibleChildren}
              dispatch={dispatch}
              onRefineQuery={onRefineQuery}
              className="tree-item--child"
            />
          ))}
        </ul>
      )}
    </li>
  );
});
