import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import * as React from 'react';
import { Button, Dropdown, Form, FormInputProps, Input } from 'semantic-ui-react';
import './data-source-app-store.scss';

export interface DataSourceAppStoreCreateFormProps {
  existingConfiguration?: AppStoreConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

export enum AppStore {
  ios = 'ios',
  googleplay = 'googleplay'
}

const AppStoreToNameMap = {
  ios: 'Apple iOS',
  googleplay: 'Google Play',
};

interface AppStoreAppPair {
  appstore: AppStore;
  appid: string;
}

interface AppStoreConfig {
  appids: AppStoreAppPair[];
}

interface DataSourceAppStoreCreateFormState {
  config: AppStoreConfig;
}

class DataSourceAppStoreCreateForm extends React.Component<
  DataSourceAppStoreCreateFormProps,
  DataSourceAppStoreCreateFormState
  > {

  // config starts with one entry because it makes sense
  state = {
    config: {
      appids: [{
        appstore: AppStore.ios,
        appid: ''
      }] as AppStoreAppPair[]
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;

    if (existingConfiguration) {
      this.setState({
        config: {
          appids: existingConfiguration.appids
        }
      });
    }

    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  updateExternal = () => {
    const { config } = this.state;

    if (this.props.onChange) {
      this.props.onChange(config, '');
    }
  }

  addEntry = () => {
    // adds a new entry to the bottom of the list
    const { config } = this.state;
    const newConfig = {
      appids: [
        ...config.appids,
        {
          appstore: AppStore.ios,
          appid: ''
        }
      ]
     };

    this.setState({ config: newConfig }, () => {
      this.checkValidity();
    });
  };

  checkValidity = () => {
    if (this.props.setValidity) {
      const isValid = this.state.config.appids.every(entry => !!entry.appid);
      this.props.setValidity(isValid);
    }
  }

  render(): JSX.Element | null {
    const { config } = this.state;

    // create the source type options as list for dropdown
    const availableApps = map(AppStore, key => {
      return {
        text: AppStoreToNameMap[key],
        value: key
      };
    });

    const existingApps = map(config.appids, (appinfo, index) => {
      const { appid, appstore } = appinfo;

      let helpMessage = '';
      if (appstore === AppStore.ios) {
        helpMessage = 'Identifiers should look like 284882215';
      } else if (appstore === AppStore.googleplay) {
        helpMessage = 'Identifiers should look com.facebook.katana';
      }

      return (
        <Form.Field key={index}>
          <div className="app-store-row">
            <Dropdown
              selection={true}
              value={appstore}
              options={availableApps}
              onChange={(_e: React.FormEvent<HTMLInputElement>, inputProps: FormInputProps) => {
                const { value } = inputProps;
                // handle change in app store type
                const { config: localConfig } = this.state;
                localConfig.appids[index].appstore = value;
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                });
              }
              }
            />
            <Input
              placeholder="App Identifier"
              value={appid}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                // handle change in app store id
                const { config: localConfig } = this.state;
                localConfig.appids[index].appid = e.currentTarget.value;
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                });

                this.checkValidity();
              }}
            />
            <Button onClick={() => {
              // remove the selected entry
              const { config: localConfig } = this.state;
              localConfig.appids.splice(index, 1);
              this.setState({ config: localConfig }, () => {
                this.updateExternal();
              });
            }}
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="trash"
                  fixedWidth={true}
                />
              } />
          </div>
          <div className="app-store-row">
            <div />
            <div className="helper-text">{helpMessage}</div>
          </div>
        </Form.Field>
      );
    });

    return (
      <div>
        <Form.Field>
          <label>App Store identifiers to monitor</label>
        </Form.Field>
        {existingApps}
        <Form.Field>
          <Button onClick={this.addEntry}>Add App Store Entry</Button>
        </Form.Field>
      </div>
    );
  }
}

export default DataSourceAppStoreCreateForm;
