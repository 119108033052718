<template>
  <div
    class="sentiment-popover-menu"
  >
    <span class="sentiment-popover-title">SENTIMENT</span>
    <span class="sentiment-popover-items">
      <div
        class="sentiment-popover-items__item"
        @click="handleSentimentSelection('smile')"
      >
        <sentiment
          :sentiment="0.06"
        />
      </div>
      <div
        class="sentiment-popover-items__item"
        @click="handleSentimentSelection('meh')"
      >
        <sentiment
          :sentiment="0.04"
        />
      </div>
      <div
        class="sentiment-popover-items__item"
        @click="handleSentimentSelection('frown')"
      >
        <sentiment
          :sentiment="-0.06"
        />
      </div>
    </span>
  </div>
</template>

<script>
import Sentiment from './Sentiment.vue';

export default {
  name: 'SentimentPopoverMenu',
  components: {Sentiment},
  methods: {
    handleSentimentSelection(sentiment) {
      this.$emit('change', sentiment)
    }
  }
};

</script>

<style lang="scss" scoped>
@import '../../styles/element-variables';
.sentiment-popover-titles {
  text-transform: uppsercase;
}
.sentiment-popover-menu {
  display: flex;
  flex-direction: column;
  .sentiment-popover-title {
    font-size: 11px;
    color: $--neutral-500;
    font-weight: 600;
    color: $--neutral-500;
    letter-spacing: 0.4px;
  }
}
.sentiment-popover-items {
  display: flex;
  flex-direction: row;
  &__item {
    padding: 7.5px;
    line-height: unset;
    border-radius: 4px;
    .sentiment-icon {
      width: 18px;
      height: 18px;
      margin: 0;
    }
    &:hover {
      background-color: $--primary-100;
      cursor: pointer;
    }
  }
}
</style>
