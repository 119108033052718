import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import './copy-to-clipboard.scss';
import { copyText } from 'lib/clipboard';

export interface CopyToClipboardProps {
  textToDisplay: string;
  popupText?: string;
}

export interface CopyToClipboardState {
  showCopy: boolean;
  copySuccess: boolean;
}

class CopyToClipboard extends React.Component<CopyToClipboardProps, CopyToClipboardState> {
  textArea: HTMLTextAreaElement | null;
  iconRef = React.createRef<HTMLDivElement>();

  state = {
    showCopy: false,
    copySuccess: false
  };
  onMouseEnter = () => {
    this.setState({ showCopy: true });
  }
  onMouseLeave = () => {
    this.setState({ showCopy: false, copySuccess: false });
  }

  initialiseCopyStates = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.setState({ showCopy: false, copySuccess: false });
  }

  copy = (event: React.MouseEvent) => {
    event.stopPropagation();

    copyText(this.props.textToDisplay);
    this.setState({ copySuccess: true });
  }

  render(): JSX.Element | null {
    const { textToDisplay, popupText } = this.props;
    const { showCopy, copySuccess } = this.state;
    const popupContent = popupText ? popupText : 'Copy';

    const popupStyle = {
      padding: '0.5em',
      fontSize: '12px'
    };

    if (!document.queryCommandSupported || !document.queryCommandSupported('copy')) {
      return <div>{textToDisplay}</div>;
    }

    return (
      <div className="copy-to-clipboard-wrapper">
        <Popup
          trigger={
            <div
              className="copy-to-clipboard"
              onClick={this.copy}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              {textToDisplay}
              <div ref={this.iconRef}>
                {showCopy && !copySuccess &&
                  <FontAwesomeIcon icon="copy" className="icon" />
                }
              </div>
            </div>
          }
          content={popupContent}
          inverted={true}
          position="top center"
          style={popupStyle}
          context={this.iconRef.current || undefined}
          open={showCopy && !copySuccess}
        />
        {copySuccess &&
          <div
            onClick={this.initialiseCopyStates}
            onMouseLeave={this.initialiseCopyStates}
          >
            <FontAwesomeIcon icon="check-circle" className="icon success" />
          </div>
        }
      </div>
    );
  }
}

export default CopyToClipboard;
