<template>
  <widget-wrapper
    :title="title"
    :loading="loading"
    :error="error"
    :warning="warning"
    class="impact-with-subthemes ob-impact-with-subthemes"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div
        v-if="!empty"
        class="section-header"
      >
        <h5>Base Theme</h5>
        <h5>Impact</h5>
        <h5>Subthemes</h5>
        <h5>Impact</h5>
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="theme + index"
        class="theme-row"
        :aria-label="theme.name"
      >
        <div class="base-theme">
          <div
            v-if="!isWeighted"
            :class="{ 'clickable-theme': canNavigate }"
            class="theme-name"
            aria-label="theme name"
            @click="doNavigate({code: theme.code, title: theme.name})"
          >
            <el-tooltip
              :content="theme.name"
              placement="bottom"
              :open-delay="300"
            >
              <span>{{ theme.name }}</span>
            </el-tooltip>
          </div>
          <theme-name-with-sources
            v-else
            :source="source"
            tool="explore"
            subtool="impact"
            :theme="theme.name"
            :theme-components="theme.components"
            :theme-name="theme.name"
            :theme-sources="themeSources"
            :compare-and-filters="compareAndFilters"
          />
          <bar
            :value="theme.impact"
            :max-value="maxValue"
            :range="range"
            :last-item="index + 1 == themes.length"
          />
        </div>
        <div class="subthemes">
          <div
            v-for="(subtheme, i) in theme.subthemes"
            :key="theme + i"
            class="subtheme"
          >
            <div
              v-if="!isWeighted"
              :class="{ 'clickable-theme': canClickTheme }"
              class="theme-name"
              @click="doNavigate({code: theme.code, title: theme.name},
                                 {code: subtheme.code, title: subtheme.name})"
            >
              <el-tooltip
                :content="subtheme.name"
                placement="bottom"
                :open-delay="300"
              >
                <span class="truncate">{{ subtheme.name }}</span>
              </el-tooltip>
            </div>
            <theme-name-with-sources
              v-else
              :source="source"
              tool="explore"
              subtool="impact"
              :theme="subtheme.name"
              :theme-components="subtheme.components"
              :theme-name="subtheme.name"
              :theme-sources="themeSources"
              :truncate-theme-name="true"
              :compare-and-filters="compareAndFilters"
            />
            <bar
              :value="subtheme.impact"
              :narrow="true"
              :max-value="maxValue"
              :range="range"
              :last-item="index + 1 == theme.subthemes.length"
            />
          </div>
        </div>
      </div>
      <div
        v-if="empty"
        class="empty-theme-list"
      >
        {{ emptyMessage }}
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters.baseline"
      :theme="selectedTheme"
      :sentiment="sort === 'asc' ? 'negative' : 'positive'"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import { Bar } from 'components/Dashboard/Widgets/Bar';
import { ThemeNameWithSources } from 'components/Dashboard/Widgets/Components/ThemeNameWithSources';
import SummaryComponent from './SummaryComponent';
import { canClickTheme } from 'components/Dashboard/Utils/dashboard-helper';
import {
  canNavigate,
  goToAnalysis,
  getDestination
} from 'components/Dashboard/Utils/navigation-helpers';
import WidgetWrapper from '../WidgetWrapper';
import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';

export default {
  name: 'IMPACTWITHSUBTHEMESCOMPONENT',
  components: {
    Bar: ReactInVue(Bar),
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
    ThemeNameWithSources: ReactInVue(ThemeNameWithSources),
  },
  props: {
    orgId: { type: String },
    isWeighted: { type: Boolean, default: false },
    filters: {type: Object},
    error: { type: String },
    warning: { type: String },
    loading: { default: false, type: Boolean },
    maxValue: { type: Number },
    range: { type: Array },
    scoreName: { type: String },
    showComments: { default: false, type: Boolean },
    showSummary: { default: false, type: Boolean },
    sort: { type: String },
    source: { type: String },
    title: { type: String },
    themes: { type: Array },
    themeSources: { type: Array },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      selectedTheme: null,
      isCommentsEnabled: false,
      isSummaryEnabled: false,
    };
  },
  computed: {
    canClickTheme() {
      return canClickTheme({
        source: this.source,
        isWeighted: this.isWeighted,
        isCommentsEnabled: this.isCommentsEnabled,
        isSummaryEnabled: this.isSummaryEnabled,
      });
    },
    canNavigate() {
      return canNavigate({ source: this.source, isWeighted: this.isWeighted });
    },
    empty() {
      return !this.themes || !this.themes.length;
    },
    subtitle() {
      const { scoreName, sort } = this;
      const tokens = [];

      if (sort === 'asc') {
        tokens.push('Key negative drivers of');
      } else {
        tokens.push('Key positive drivers of');
      }

      if (scoreName) {
        tokens.push(scoreName);
      } else {
        tokens.push('score');
      }

      return tokens.join(' ');
    },
    emptyMessage() {
      if (this.sort === 'asc') {
        return 'No negative themes found';
      } else {
        return 'No positive themes found';
      }
    },
    compareAndFilters() {
      const { compare, filters } = this.$route.query;
      return { compare, filters };
    }
  },
  watch: {
    showComments: {
      handler (showComments) {
        this.isCommentsEnabled = showComments;
      },
      immediate: true
    },
    showSummary: {
      handler (showSummary) {
        this.isSummaryEnabled = showSummary;
      },
      immediate: true
    },
  },
  methods: {
    doNavigate(theme, subtheme, options, isFinalNavigate) {
      const {
        source,
        isCommentsEnabled,
        isSummaryEnabled,
        isWeighted,
        orgId,
      } = this;

      // if the navigation is to a specific theme, and there is a comments component, don't navigate yet
      if ((isCommentsEnabled || isSummaryEnabled) && !isFinalNavigate && theme) {
        this.selectedTheme = { theme, subtheme, options };
        analytics.track('Report: open comments', { category: 'Report' });
        return;
      }
      if (!canNavigate({ source, isWeighted })) {
        return;
      }

      const destination = getDestination({
        options,
        orgId,
        source,
        subtheme,
        theme,
        subtool: 'impact',
        tool: 'explore'
      });
      goToAnalysis(destination);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.dashboard-widget.widget.impact-with-subthemes {
  @media (max-width: 1070px) {
    flex: 1 1 calc(100% - 40px);
  }
}
.dashboard-widget .themes-body {
  padding-bottom: 0;
}
.section-header,
.theme-row {
  display: grid;
  margin: 0 -10px; // bleed background color
}
.section-header {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid $--neutral-200;
  padding-bottom: 0.5rem;
}
.section-header h5 {
  color: $--neutral-400 !important;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;

  &:nth-child(even) {
    justify-self: end;
  }
  &:nth-child(2) {
    padding-right: 20px;
  }
}
.theme-row {
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  border-radius: 0;
  height: 100px;
  padding: 5px 20px;

  &:nth-child(odd) {
    background: $--neutral-100;
  }
}
.theme-row .clickable-theme {
  width: 50%;
  flex-basis: auto;
  flex-grow: 1;
  display: grid;
  > span {
    width: 100%;
    max-width: 100%;
  }
}
.theme-row .clickable-theme span.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.theme-row div:has(> .theme-name-with-sources)  {
  flex-basis: auto;
  flex-grow: 1;
  display: grid;
}
.base-theme {
  display: flex;
  > div {
    display: flex;
  }
}
.subtheme {
  display: flex;
  margin: 5px 0;
}
.base-theme {
  padding-right: 20px;
}
</style>
