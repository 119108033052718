export const DateFilterStructure = {
  id: undefined,
  name: undefined,
  column: undefined,
  type: undefined
};

export const FreetextFilterStructure = {
  id: undefined,
  name: undefined,
  column: undefined,
  type: undefined
};

export const ListAutoCutStructure = {
  id: undefined,
  name: undefined,
  column: undefined,
  type: undefined,
  forceDropdown: undefined,
  auto_cut: {
    type: undefined,
    include_all: undefined,
    options: {
      case_handling: undefined,
      ordering: undefined,
      rql_template: undefined,
      ignore_values: undefined
    }
  },
  cuts: [{
    filter: undefined,
    name: undefined,
    type: undefined,
    rql: undefined,
    id: undefined,
    value: undefined
  }]
};

export const DateAutoCutStructure = {
  id: undefined,
  name: undefined,
  column: undefined,
  type: undefined,
  auto_cut: {
    type: undefined,
    include_all: undefined,
    options: {
      dayfirst: undefined,
      resolution: undefined,
      max_past: undefined
    }
  },
  cuts: [{
    filter: undefined,
    name: undefined,
    type: undefined,
    rql: undefined,
    id: undefined,
    value: undefined
  }]
};

export const ScoreAutoCutStructure = {
  id: undefined,
  name: undefined,
  column: undefined,
  type: undefined,
  auto_cut: {
    type: undefined,
    include_all: undefined,
    options: {
      score_type: undefined
    }
  },
  cuts: [{
    filter: undefined,
    name: undefined,
    type: undefined,
    rql: undefined,
    id: undefined,
    value: undefined
  }]
};