import * as React from 'react';
import { AnalysisFilter } from 'api/interfaces';
import { ExportAssistantFilterSelect } from './ExportAssistantFilterSelect';
import { Rows } from './types';

const DISABLED_FILTER_TYPES = ['saved-filters', 'freetext', 'date'];

interface Props {
  filters: AnalysisFilter[];
  rows: Rows | null;
  onRowChange: (rows: Rows) => void;
}

export const ExportAssistantRowSelect = ({ filters, onRowChange, rows }: Props) => {
  const handleRowSelect = (rowId: string) => {
    const rowName = filters.find(({ id }) => id === rowId)?.name || '';

    onRowChange({ id: rowId, name: rowName });
  };

  const enabledFilters = filters.filter(({ type }) => {
    if (!type) {
      return true;
    }

    return !DISABLED_FILTER_TYPES.includes(type);
  });

  return (
    <ExportAssistantFilterSelect
      filters={enabledFilters}
      onFilterSelect={handleRowSelect}
      value={rows?.id || ''}
    />
  );
};
