<template>
  <widget-wrapper
    :aria-label="scoreName"
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        {{ scoreName }}
      </div>
    </div>
    <div
      v-if="scoreLabel"
      class="widget-body original-score-widget scores"
    >
      <div class="score-container">
        <div class="overall score">
          <h2>
            {{ scoreLabel }}
            <div class="score-change">
              <font-awesome-icon
                v-if="scoreChangeIndicator"
                :icon="scoreChangeIndicator"
              />
              {{ scoreChangeLabel }}
            </div>
          </h2>
          <legend>{{ scoreName }}</legend>
          <legend
            v-if="compareFilter"
            class="goodwill"
          >
            Goodwill
          </legend>
        </div>
        <div
          v-if="compareFilter"
          class="original score"
        >
          <h2>
            {{ originalScoreLabel }}
            <div class="score-change">
              <font-awesome-icon
                v-if="originalScoreChangeIndicator"
                :icon="originalScoreChangeIndicator"
              />
              {{ originalScoreChangeLabel }}
            </div>
          </h2>
          <legend>{{ originalScoreName }}</legend>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty widget-body"
    >
      No data found
    </div>
  </widget-wrapper>
</template>

<script>
import { get, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import { getScoreChange, getScoreChangeIndicator } from 'vue/libs/widget-utils';

import WidgetWrapper from './WidgetWrapper';

export default {
  name: 'ORIGINAL_SCORE',
  components: {
    WidgetWrapper
  },
  props: {
    config: { type: Object },
    context: { type: Object },
    data: { type: Object },
    warning: { type: String },
    error: { type: String },
    limit: { default: 5, type: Number },
    loading: { type: Boolean },
    source: { type: String }
  },
  computed: {
    compareFilter () {
      return get(this, 'context.compareFilter', undefined);
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    currentScore() {
      return get(this, 'data.currentScore.score.score');
    },
    previousScore() {
      return get(this, 'data.previousScore.score.score');
    },
    currentOriginalScore() {
      return this.getOriginalScore(get(this, 'data.currentScore'));
    },
    previousOriginalScore() {
      return this.getOriginalScore(get(this, 'data.previousScore'));
    },
    scoreName() {
      if (this.compareFilter) {
        return get(this, 'context.compareFilterName', '');
      }
      return get(this, 'data.currentScore.name', '');
    },
    originalScoreName() {
      return get(this, 'currentOriginalScore.name', '');
    },
    scoreLabel() {
      return toFixed(this.currentScore, 1);
    },
    originalScoreLabel() {
      return toFixed(this.currentOriginalScore.score, 1);
    },
    scoreChange() {
      return getScoreChange(this.currentScore, this.previousScore);
    },
    scoreChangeLabel() {
      return this.getScoreChangeLabel(this.scoreChange);
    },
    scoreChangeIndicator() {
      return getScoreChangeIndicator(this.scoreChange);
    },
    originalScoreChange() {
      return getScoreChange(this.currentOriginalScore.score, this.previousOriginalScore.score);
    },
    originalScoreChangeLabel() {
      return this.getScoreChangeLabel(this.originalScoreChange);
    },
    originalScoreChangeIndicator() {
      return getScoreChangeIndicator(this.originalScoreChange)
    }
  },
  methods: {
    getPrecision (score) {
      let precision = get(this,'scoreConfig.options.precision', this.config.precision);
      if (isNumber(precision)) {
        return precision;
      }

      const absChange = Math.abs(score);
      if (absChange < 10) {
        return 1;
      } else {
        return 0;
      }
    },
    getScoreChangeLabel (scoreChange) {
      if (scoreChange) {
        const precision = this.getPrecision(scoreChange);
        const label = toFixed(scoreChange, precision);
        const absChange = Math.abs(label, 1);
        return absChange;
      }
      return undefined;
    },
    getOriginalScore (score) {
      if (!score) {
        return {};
      }
      return get(score, 'score.componentScores.0.originalScore');
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../styles/element-variables';

.widget-body.original-score-widget.scores {
  align-items: center;

  .score-container {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex: 1 1 100px;
    flex-direction: row;
  }

  .score legend {
    color: $--neutral-900;

    &.goodwill {
      font-size: 14px;
    }
  }

  .overall, .original {
    flex: auto;
    padding: 2%;
  }

  .overall h2 {
    word-spacing: -10px;
  }

  .original h2 {
    font-size: 40px;
    color: $--color-primary;
  }

  .score-change {
    display: inline-flex;
    font-size: 16px;
    line-height: 1;
    color: $--neutral-600;

    svg {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
</style>
