import { AnswerResponse, AnswersAction, AnswersActionType, TextAnswerPartType } from 'api/interfaces';
import * as React from 'react';
import { HighlightedParagraph } from './HighlightedParagraph';
import './text-answer-part.scss';

interface TextProps {
  savedAnswerId: string;
  textData: TextAnswerPartType;
  answerInfo: AnswerResponse;
  isReadOnly: boolean;
  onThemeClick: (action: AnswersAction) => void;
  onTextSelection: (text: string) => void;
  onCopyClick: () => void;
}

const TextAnswerPart = ({
  savedAnswerId,
  answerInfo,
  textData,
  isReadOnly,
  onThemeClick,
  onTextSelection,
  onCopyClick
}: TextProps) => {
  return (
    <>
      <HighlightedParagraph
        contents={textData.contents}
        subjects={textData.subjects}
        passiveThemes={answerInfo.filters.themes || []}
        isReadOnly={isReadOnly}
        onThemeClick={({ theme }) => onThemeClick({
          action: AnswersActionType.moreDepth,
          previousQuestion: answerInfo.question,
          previousQuestionType: answerInfo.questionType,
          previousContents: textData.contents,
          previousFilters: answerInfo.filters,
          moreDepthOn: { 'themes': [theme] },
          collectionId: savedAnswerId
        })}
        onTextSelection={onTextSelection}
        onCopyClick={onCopyClick}
      />
    </>
  );
};

export { TextAnswerPart };
