import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import { TextArea } from 'components/Shared/TextArea';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { FeedbackModalText } from './types';
interface FeatureFeedbackModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onUnmount?: () => void;
  onSubmit: (description: string) => void;
  textConfig?: FeedbackModalText;
}

const FeatureFeedbackModal = ({
  isOpen,
  onCancel,
  onSubmit,
  onUnmount,
  textConfig,
}: FeatureFeedbackModalProps) => {
  const [description, setDescription] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(description);
  };

  return (
    <Modal open={isOpen} onClose={() => onCancel()} onUnmount={onUnmount}>
      <ModalHeader>{textConfig?.title || 'Anything you would like to add?'}</ModalHeader>
      <ModalContent>
        {textConfig?.subTitle && <p>{textConfig?.subTitle}</p>}
        <TextArea
          id="feature-feedback-modal-textarea"
          rows={3}
          placeholder={textConfig?.textAreaPlaceholder || 'Optional more detail'}
          onChange={handleChange}
          autoFocus={true}
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={() => onCancel()} type="button">
          Skip
        </Button>
        <Button
          color="blue"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </ModalActions>
    </Modal>
  );
};

export { FeatureFeedbackModal };
