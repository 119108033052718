import { ValidSimpleSentiments } from "lib/calculate-sentiment";
import { Block } from "lib/segments-to-blocks";
import { PhraseSegment, PhraseSegmentTheme, PlainComment, Summary } from "types/custom";
import { sentimentMap } from "lib/calculate-sentiment";
import updateSentiment from "vue/libs/update-sentiment";
import logSentiment from "vue/libs/log-sentiment";

export type NextSegments = (PhraseSegment | {
  sentiment: ValidSimpleSentiments;
  index: number;
  categories: Array<string>;
  location: [start: number, end: number];
  themes: Array<PhraseSegmentTheme>;
})[] | undefined

interface HandleSentimentSelectionParams {
  block: Block;
  comment: PlainComment | Summary;
  sentiment: ValidSimpleSentiments;
  orgId: string;
  segments: PhraseSegment[];
  surveyId: string;
}

const selectSentiment = ({
  block,
  comment,
  orgId,
  segments,
  sentiment,
  surveyId,
}: HandleSentimentSelectionParams): NextSegments => {
  if (!('sentiment' in block)) {
    return;
  }

  const lastSentiment = block.sentiment;
  const commentId = comment.id;
  const sentimentValue = sentimentMap.get(sentiment);
  const [start, end] = block.location;

  if (!sentimentValue) {
    return
  }

  /* Inform backend */
  updateSentiment(surveyId, comment.id, {
    column: comment.column,
    location: [start, end],
    sentiment: sentimentValue
  });

  /* Inform data team */
  logSentiment({
    sentiment,
    lastSentiment,
    block,
    surveyId,
    orgId,
    comment: comment.comment.toString(),
    commentId,
  });

  return segments.map(segment => {
    const isSameLocation = segment.location[0] === block.location[0] && segment.location[1] === block.location[1];
    return isSameLocation ? { ...segment, sentiment } : segment;
  });
}

export { selectSentiment };
