import { ConnectedIntegration } from "api/interfaces";
import { WorkflowConfiguration } from "api/workflow-interfaces";

export const getSlackIntegrationId = (
  config: WorkflowConfiguration,
  currentIntegration: ConnectedIntegration[] | undefined
): string => {
  const notify = config.stateMachine.States.Notify;
  const configIntegrationId = notify.Parameters.integrationId;
  const currentIntegrationId = currentIntegration && currentIntegration.length > 0
    ? currentIntegration[0].id.toString()
    : '';
  return configIntegrationId === currentIntegrationId ? configIntegrationId : currentIntegrationId;
}