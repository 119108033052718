import { template } from 'lodash';
import { FilterSelections } from 'stores/FilterStore';
import { KeyTakeaway, KeyTakeawayIcon } from 'stores/ui/AnalysisToolsKeyTakeawaysUIStore';
import { Theme } from 'vue/explore/theme-data-functions';
import toFixed from 'vue/libs/to-fixed';
import statistics from './statistics';

/*
Rendering of key takeaways allows for html to be provided including spans with the following classes:
* theme
* filter-name
*/

enum SummaryCategory {
  DEFAULT = 'default',
  BUG = 'bug',
  FEATURE_REQUEST = 'feature request',
  QUESTION = 'question'
}
enum SummarySentiment {
  ALL = 'all',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export function volumeTakeaway(themeTitle: string,
                               themeInfo: Theme,
                               baseTotal: number,
                               compTotal: number,
                               filterSelections: FilterSelections,
                               onClick?: (takeaway: KeyTakeaway) => void): KeyTakeaway {
  let html = `<span class="theme">${ themeTitle }</span> was mentioned by ${ toFixed(themeInfo.baseVolume, 1, '%') } of ${ baseTotal } responses`;
  let text = `${ themeTitle } was mentioned by ${ toFixed(themeInfo.baseVolume, 1, '%') } of ${ baseTotal } responses`;
  if (filterSelections.comparison.query !== filterSelections.baseline.query) {
    html += ` in <span class="filter-name">${ filterSelections.baseline.title }</span>`;
    text += ` in ${ filterSelections.baseline.title }`;
    html += ` compared with ${ toFixed(themeInfo.compVolume, 1, '%') } in <span class="filter-name">${ filterSelections.comparison.title }</span>`;
    text += ` compared with ${ toFixed(themeInfo.compVolume, 1, '%') } in ${ filterSelections.comparison.title }`;
  }
  return { html, text, icon: KeyTakeawayIcon.THEMES, onClick };
}

export function impactTakeaway(themeTitle: string,
                               themeInfo: Theme,
                               filterSelections: FilterSelections,
                               scoreName: string,
                               impactFormat: string,
                               onClick?: (takeaway: KeyTakeaway) => void): KeyTakeaway {
  if (!impactFormat) {
    impactFormat = '<%= value %> points';
  }
  const impactFormatter = template(impactFormat);

  let changeText = 'having no real impact';
  let icon = KeyTakeawayIcon.SCORE_NOCHANGE;
  let directionText = '';
  if (themeInfo.baseImpact > 0.1) {
    changeText = `increasing ${ scoreName } by ${ impactFormatter({ value: toFixed(themeInfo.baseImpact, 1) }) }`;
    directionText = ' higher than average';
    icon = KeyTakeawayIcon.SCORE_INCREASE;
  }
  if (themeInfo.baseImpact < -0.1) {
    changeText = `decreasing ${ scoreName } by ${ impactFormatter({ value: toFixed(themeInfo.baseImpact, 1) }) }`;
    directionText = ' lower than average';
    icon = KeyTakeawayIcon.SCORE_DECREASE;
  }
  let html = `<span class="theme">${ themeTitle }</span> is ${ changeText } because ${ toFixed(themeInfo.baseVolume, 1, '%') }`;
  let text = `${ themeTitle } is ${ changeText } because ${ toFixed(themeInfo.baseVolume, 1, '%') }`;
  html += ` of responses mention it with a${ directionText } score`;
  text += ` of responses mention it with a${ directionText } score`;
  if (filterSelections.comparison.query !== filterSelections.baseline.query) {
    html += ` in <span class="filter-name">${ filterSelections.baseline.title }</span>`;
    text += ` in ${ filterSelections.baseline.title }`;
    html += ` compared with an impact of ${ impactFormatter({ value: toFixed(themeInfo.compImpact, 1) }) }`;
    text += ` compared with an impact of ${ impactFormatter({ value: toFixed(themeInfo.compImpact, 1) }) }`;
    html += ` in <span class="filter-name">${ filterSelections.comparison.title }</span>`;
    text += ` in ${ filterSelections.comparison.title }`;
  }
  return { html, text, icon, onClick };
}

export function scoreTakeaway(themeTitle: string,
                              themeInfo: Theme,
                              filterSelections: FilterSelections,
                              scoreName: string,
                              scoreFormat: string,
                              onClick?: (takeaway: KeyTakeaway) => void): KeyTakeaway {
  if (!scoreFormat) {
    scoreFormat = '<%= value %> points';
  }
  const scoreFormatter = template(scoreFormat);
  let html = `Resonses mentioning <span class="theme">${ themeTitle }</span> have an average ${ scoreName } of \
${ scoreFormatter({ value: toFixed(themeInfo.baseScore, 1) }) }`;
  let text = `Resonses mentioning ${ themeTitle } have an average ${ scoreName } of \
${ scoreFormatter({ value: toFixed(themeInfo.baseScore, 1) }) }`;
  if (filterSelections.comparison.query !== filterSelections.baseline.query) {
    html += ` in <span class="filter-name">${ filterSelections.baseline.title }</span>`;
    text += ` in ${ filterSelections.baseline.title }`;
    html += ` compared with an average ${ scoreName } of  ${ scoreFormatter({ value: toFixed(themeInfo.compScore, 1) }) }`;
    text += ` compared with an average ${ scoreName } of  ${ scoreFormatter({ value: toFixed(themeInfo.compScore, 1) }) }`;
    html += ` in <span class="filter-name">${ filterSelections.comparison.title }</span>`;
    text += ` in ${ filterSelections.comparison.title }`;
  }

  return { html, text, icon: KeyTakeawayIcon.SCORE_NOCHANGE, onClick };
}

function calculateTrendDirection(timeseries: number[]): { trendDirection?: number, volatile?: boolean } {
  const mean = statistics.mean(timeseries);
  if (timeseries.length === 0 || mean === 0) {
    return { trendDirection: undefined, volatile: undefined };
  }

  const valuesX = timeseries.map((c, i) => { return i; });
  const lineY = statistics.find_line_by_least_squares(valuesX, timeseries)[1];

  const trend = (lineY[lineY.length - 1] - lineY[0]) / Math.abs(mean);
  const trendDirection = trend > 0.05 ? 1 : trend < -0.05 ? -1 : 0;

  const volatility = timeseries.reduce((r, v, i) => {
    r += Math.abs(v - lineY[i]);
    return r;
  }, 0) / timeseries.length;
  const volatile = volatility > Math.abs(mean) * 0.2;

  return { trendDirection, volatile };
}

export function timeSeriesTakeaway(metricName: string,
                                   themeTitle: string,
                                   timeseries: number[],
                                   onClick?: (takeaway: KeyTakeaway) => void): KeyTakeaway | undefined {
  const mean = statistics.mean(timeseries);
  if (timeseries.length === 0 || mean === 0) {
    return undefined;
  }
  let trendIsRecent = false;
  let { trendDirection, volatile } = calculateTrendDirection(timeseries);

  // as a solution to the issue where the _overall_ trend is one direction
  // but the _recent_ trend is in a different direction
  // we will calculate the trend again, but using the last 6 months (max) data
  if (timeseries.length > 6) {
    const last6Months = timeseries.slice(timeseries.length - 6);
    const { trendDirection: trendDirection6Months } = calculateTrendDirection(last6Months);
    if (trendDirection6Months !== trendDirection) {
      trendDirection = trendDirection6Months; // the direction didn't match, so we will qualify as 'recent'
      trendIsRecent = true;
    }
  }

  if (trendDirection === 0) {
    if (volatile) {
      return {
        html: `<span class="metric">${ metricName }</span> of <span class="theme">${ themeTitle }</span> <span class="trend-delta">changes a lot</span>`,
        text: `<span class="metric">${ metricName }</span> of ${ themeTitle } changes a lot`,
        icon: KeyTakeawayIcon.SCORE_NOCHANGE,
        onClick
      };
    } else {
      return {
        html: `<span class="metric">${ metricName }</span> of <span class="theme">${ themeTitle }</span> is <span class="trend-delta">steady<span>`,
        text: `<span class="metric">${ metricName }</span> of ${ themeTitle } is steady`,
        icon: KeyTakeawayIcon.SCORE_NOCHANGE,
        onClick
      };
    }
  } else if (trendDirection === 1) {
    return {
      html: `<span class="metric">${ metricName }</span> of <span class="theme">${ themeTitle }</span> is ${ trendIsRecent ? 'recently ' : '' }<span class="trend-delta">trending up</span> \
${ volatile ? ' but is changing a lot' : '' }`,
      text: `<span class="metric">${ metricName }</span> of ${ themeTitle } is  ${ trendIsRecent ? 'recently ' : '' }trending up' ${ volatile ? ' but is changing a lot' : '' }`,
      icon: KeyTakeawayIcon.SCORE_INCREASE,
      onClick
    };
  } else {
    return {
      html: `<span class="metric">${ metricName }</span> of <span class="theme">${ themeTitle }</span> is ${ trendIsRecent ? 'recently ' : '' }<span class="trend-delta">trending down</span> \
${ volatile ? ' but is changing a lot' : '' }`,
      text: `<span class="metric">${ metricName }</span> of ${ themeTitle } is ${ trendIsRecent ? 'recently ' : '' }trending down' ${ volatile ? ' but is changing a lot' : '' }`,
      icon: KeyTakeawayIcon.SCORE_DECREASE,
      onClick
    };

  }
}

export function sentimentTakeaway(themeTitle: string,
                                  sentimentCounts: { pos: number, neg: number, neut: number },
                                  onClick?: (takeaway: KeyTakeaway) => void): KeyTakeaway {
  const total = (sentimentCounts.pos + sentimentCounts.neg + sentimentCounts.neut);
  const average = (sentimentCounts.pos - sentimentCounts.neg) / total;
  let takeaway;
  if (average > 0.1) {
    takeaway = {
      html: `<span class="theme">${ themeTitle }</span> is mostly positive`,
      text: `${ themeTitle } is mostly positive`,
      icon: KeyTakeawayIcon.SCORE_INCREASE,
      onClick
    };
  } else if (average < -0.1) {
    takeaway = {
      html: `<span class="theme">${ themeTitle }</span> is mostly negative`,
      text: `${ themeTitle } is mostly negative`,
      icon: KeyTakeawayIcon.SCORE_DECREASE,
      onClick
    };
  } else if (sentimentCounts.neut < total * 0.3) { // average is neutral AND is mostly neutral
    takeaway = {
      html: `<span class="theme">${ themeTitle }</span> is neither negative or positive`,
      text: `${ themeTitle } is neither negative or positive`,
      icon: KeyTakeawayIcon.SCORE_NOCHANGE,
      onClick
    };
  } else { // average is neutral, but thats because of positive and negative
    takeaway = {
      html: `<span class="theme">${ themeTitle }</span> is polarizing; both positive and negative`,
      text: `${ themeTitle } is polarizing; both positive and negative`,
      icon: KeyTakeawayIcon.SCORE_NOCHANGE,
      onClick
    };
  }
  return takeaway;

}

export function sentenceSummaryTakeaway(category: SummaryCategory,
                                        sentiment: SummarySentiment,
                                        sentence: string,
                                        onClick?: (takeaway: KeyTakeaway) => void): KeyTakeaway {
  let prefix = '';
  if (category === SummaryCategory.BUG) {
    prefix = 'Issues: ';
  } else if (category === SummaryCategory.FEATURE_REQUEST) {
    prefix = 'Feature Request: ';
  } else if (category === SummaryCategory.QUESTION) {
    prefix = 'Questions: ';
  } else if (sentiment === SummarySentiment.NEGATIVE) {
    prefix = 'What people don\'t like: ';
  } else if (sentiment === SummarySentiment.POSITIVE) {
    prefix = 'What people like: ';
  }

  return {
    html: prefix + sentence,
    text: prefix + sentence,
    icon: KeyTakeawayIcon.THEMES,
    onClick
  };
}
