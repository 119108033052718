<template>
  <span
    v-if="!hideReviewControls"
    :class="{
      'review-controls-container': true,
      'review-controls-container--hide': hideReviewControls,
    }"
  >
    <el-tooltip
      class="item"
      popper-class="summary-review-control-popper"
      effect="dark"
      placement="top"
      :enterable="false"
    >
      <font-awesome-icon
        class="review-icon thumbs-down"
        icon="thumbs-down"
        @click="submitFeedback('thumbs-down')"
      />
      <span
        slot="content"
        class="summary-review-control-description"
      >
        This is a poor summary
      </span>
    </el-tooltip>
    <el-tooltip
      class="item"
      popper-class="summary-review-control-popper"
      effect="dark"
      placement="top"
      :enterable="false"
    >
      <font-awesome-icon
        class="review-icon thumbs-up"
        icon="thumbs-up"
        @click="submitFeedback('thumbs-up')"
      />
      <span
        slot="content"
        class="summary-review-control-description"
      >
        This is a good summary
      </span>
    </el-tooltip>
  </span>
  <span
    v-else
    :class="{
      'review-description': true,
      'hide': hideReviewDescription,
    }"
  >
    Thank you for your feedback
  </span>
</template>

<script>
import {
  getFeatureFeedbackStore
} from 'stores/RootStore';

export default {
  name: 'SummaryReviewControls',
  props: {
    summary: { type: String },
    featureId: { type: String, required: true },
    location: { type: String, required: true },
  },
  data() {
    return {
      feedbackStore: getFeatureFeedbackStore(),

      hideReviewControls: false,
      hideReviewDescription: false,
    };
  },
  methods: {
    async submitFeedback(value) {
      const description = '';

      this.hideReviewControls = true;

      const rating = value === 'thumbs-up' ? 5 : 1;

      this.feedbackStore.sendFeedback(
        this.featureId,
        this.location,
        rating,
        description,
        this.summary
      );

      setTimeout(() => {
        this.hideReviewDescription = true
      }, 5000)
    }
  },
};
</script>


<style lang="scss" scoped>
@import '../../styles/element-variables';
.review-controls-container {
  display: flex;
  flex-direction: row-reverse;
  .review-icon {
    margin-left: 8px;
    margin-top: 2px;
    color: $--primary-500;
    cursor: pointer;
    font-size: 0.855rem;
    &:hover {
      color: $--primary-600;
    }
  }
}
.review-description {
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
  font-size: 1rem;
  color: $--neutral-600;
  margin-top: -3px;
  &.hide {
    opacity: 0;
    transition: opacity 5000ms ease-out;
  }
}
</style>
