<template>
  <div
    v-if="sentimentClass"
    :class="sentimentClass"
    :title="title"
    class="sentiment-icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <g fill="currentColor">
        <path
          v-if="isSmile"
          d="M11.531 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm-5.625 0a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm6.849 5.984c.474-.573-.394-1.29-.865-.72a4.12 4.12 0 0 1-3.171 1.483 4.108 4.108 0 0 1-3.171-1.484c-.478-.57-1.343.148-.865.72a5.238 5.238 0 0 0 4.036 1.892 5.238 5.238 0 0 0 4.036-1.891z"
        />
        <path
          v-else-if="isMeh"
          d="M5.906 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm6.188 6.75c.745 0 .745-1.125 0-1.125h-6.75c-.746 0-.746 1.125 0 1.125h6.75zm-.563-4.5a1.124 1.124 0 1 0 0-2.25 1.124 1.124 0 1 0 0 2.25z"
        />
        <path
          v-else-if="isFrown"
          d="M11.531 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm-5.625 0a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm5.984 7.671c.474.573 1.34-.147.861-.717a5.25 5.25 0 0 0-8.068-.004c-.475.574.39 1.294.865.721a4.125 4.125 0 0 1 3.17-1.483c1.228 0 2.384.541 3.172 1.483z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { calculateSimpleSentiment, ValidSimpleSentiments } from 'lib/calculate-sentiment';

export default {
  name: 'Sentiment',
  props: {
    sentiment: { type: Number }
  },
  computed: {
    label() {
      const label = calculateSimpleSentiment(this.sentiment);
      if (Object.values(ValidSimpleSentiments).includes(label)) {
        return label;
      } else {
        return undefined;
      }
    },
    isSmile() {
      return this.label === ValidSimpleSentiments.smile;
    },
    isMeh() {
      return this.label === ValidSimpleSentiments.meh;
    },
    isFrown() {
      return this.label === ValidSimpleSentiments.frown;
    },
    sentimentClass() {
      const { label } = this;
      let token;
      if (label) {
        token = (`sentiment-${label}`);
      }
      return token;
    },
    title() {
      const { label } = this;
      if (label) {
        const titles = {
          frown: 'Negative',
          meh: 'Neutral',
          smile: 'Positive',
        };
        return titles[label];
      } else {
        return undefined;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.sentiment-icon {
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin: -2px 3px -2px -2px;
  background: $--sentiment-inactive-bg-color;
  color: $--sentiment-inactive-text-color;

  &.sentiment-frown {
    background: $--simple-sentiment-bg-color-frown !important;
    color: $--simple-sentiment-text-color-frown !important;
  }
  &.sentiment-meh {
    background: $--simple-sentiment-bg-color-meh !important;
    color: $--simple-sentiment-text-color-meh !important;
  }
  &.sentiment-smile {
    background: $--simple-sentiment-bg-color-smile !important;
    color: $--simple-sentiment-text-color-smile !important;
  }
}
</style>

