import { IntegrationType } from 'api/enums';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import IntegrationRedirect, { IntegrationRedirectProps } from './IntegrationRedirect';
export default class IntegrationRedirects extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/redirects/surveymonkey" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Surveymonkey"
              integrationType={IntegrationType.SURVEYMONKEY}
            />
          )}
        />
        <Route path="/redirects/salesforce" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Salesforce"
              integrationType={IntegrationType.SALESFORCE}
            />
          )}
        />
        <Route path="/redirects/googleplay" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Google Play"
              integrationType={IntegrationType.GOOGLEPLAY}
            />
          )}
        />
        <Route path="/redirects/discord" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Discord"
              integrationType={IntegrationType.DISCORD}
            />
          )}
        />
        <Route path="/redirects/googlesheets" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Google Sheets"
              integrationType={IntegrationType.GOOGLESHEETS}
            />
          )}
        />
        <Route path="/redirects/googlebigquery" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Google BigQuery"
              integrationType={IntegrationType.GOOGLEBIGQUERY}
            />
          )}
        />
        <Route path="/redirects/typeform" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Typeform"
              integrationType={IntegrationType.TYPEFORM}
            />
          )}
        />
        <Route path="/redirects/zendesk" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Zendesk"
              integrationType={IntegrationType.ZENDESK}
            />
          )}
        />
        <Route path="/redirects/intercom" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Intercom"
              integrationType={IntegrationType.INTERCOM}
            />
          )}
        />
        <Route path="/redirects/slack" render={
          (props: IntegrationRedirectProps) => (
            <IntegrationRedirect
              {...props}
              integrationName="Slack"
              integrationType={IntegrationType.SLACK}
            />
          )}
        />
        <Route>
          <Segment>
            <Header>Integration Not Found</Header>
          </Segment>
        </Route>
      </Switch>
    );
  }
}
