import { AutoCutType } from 'api/enums';
import Container from 'components/SortableList/Container';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface, FilterConfig } from 'stores/ui/ConfigureDataUIStore';
import { getColumnOptions } from './column-options';
import './column-options.scss';
import './configure-filters.scss';
import ConfigureFilter from './ConfigureFilter';

interface ConfigureFiltersStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureFiltersProps extends ConfigureFiltersStoreProps {
}

interface ConfigureFiltersState {
  columnOptions: DropdownItemProps[];
  forbidDrag: boolean;
}

@inject('configureDataUIStore')
@observer
export default class ConfigureFilters extends React.Component<ConfigureFiltersProps, ConfigureFiltersState> {

  state = {
    columnOptions: [] as DropdownItemProps[],
    forbidDrag: false,
  };

  componentDidMount() {
    const { columns } = this.props.configureDataUIStore!;
    const columnOptions = getColumnOptions(columns, this.addFilter);
    this.setState({ columnOptions });
  }

  addFilter = (columnIndex: number, columnName: string) => {
    const { addFilter, createDefaultFilter } = this.props.configureDataUIStore!;
    const surveyConfig = { ...this.props.configureDataUIStore!.surveyConfig };
    const existingFilterIds = (surveyConfig.filters || []).map(f => f.id);
    const filter = createDefaultFilter(columnIndex, columnName, AutoCutType.LIST, existingFilterIds);
    addFilter(filter);
  }

  onOrderChange = (order: string[]) => {
    const surveyConfig = { ...this.props.configureDataUIStore!.surveyConfig };
    if (surveyConfig.filters) {
      let filters = [] as FilterConfig[];
      order.forEach(id => {
        const filter = surveyConfig.filters!.find(f => f.id === id);
        if (filter) {
          filters.push(filter);
        }
      });
      this.props.configureDataUIStore!.updateFilters(filters);
    }
  }

  toggleForbidDrag = (forbidDrag: boolean) => {
    this.setState({ forbidDrag });
  }

  render() {
    const { columnOptions, forbidDrag } = this.state;
    const { surveyConfig } = this.props.configureDataUIStore!;
    const { filters } = surveyConfig;

    const components = (filters || []).map((filter) => {
      return {
        id: filter.id,
        content: <ConfigureFilter
          key={filter.id}
          id={filter.id}
          toggleForbidDrag={this.toggleForbidDrag}
        />
      };
    });

    return (
      <div className="configure-filters">
        <h4>Filters (optional)</h4>
        <p className="description">
          Add filters based on columns in your data.
          For example, if you have a “location” column, adding it will let you view feedback by location.
          &nbsp;<a href="https://help.getthematic.com/article/54-filter-customization" target="_blank" rel="noopener noreferrer">Learn more</a>
        </p>
        {components.length > 0 && <DndProvider backend={HTML5Backend}>
          <Container
            id="configure-filters"
            list={components}
            onOrderChange={this.onOrderChange}
            forbidDrag={forbidDrag}
          />
        </DndProvider>}
        <Dropdown
          text="Add filter"
          icon="plus"
          floating={true}
          labeled={true}
          button={true}
          className="icon column-dropdown-menu"
        >
          <Dropdown.Menu>
            <Dropdown.Menu scrolling={true}>
              {columnOptions.map(option => option.content)}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
