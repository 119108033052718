<template>
  <div
    class="score-breakdown"
    @click="select()"
  >
    <div
      class="score-breakdown__details"
      :class="{ selected: active }"
    >
      <div class="score-breakdown__score-details">
        <div
          v-if="!isEmpty"
          class="score-breakdown__score"
        >
          <span
            class="score-breakdown__label"
            :style="scoreLabelStyle"
          >
            {{ scoreLabel }}
          </span>
          <div
            class="score-breakdown__change"
          >
            <font-awesome-icon
              v-if="arrow"
              :icon="arrow"
              :class="`movement-${arrow}`"
            />
            <span>{{ changeLabel }}</span>
          </div>
        </div>
        <div
          v-if="isEmpty"
          class="score-breakdown__empty"
        >
          <font-awesome-icon
            icon="exclamation-triangle"
          />
          <span>No data</span>
        </div>
        <el-tooltip
          class="score-breakdown__tooltip"
          placement="bottom"
        >
          <div
            slot="content"
          >
            <div
              style="min-width: 180px"
            >
              <el-row
                v-for="(value, index) in toolTipDetails"
                :key="index"
                style="padding: 5px"
              >
                <el-col
                  :span="20"
                >
                  <font-awesome-icon :icon="value.icon" />
                  <span>{{ value.label }}</span>
                </el-col>
                <el-col :span="4">
                  <span>{{ value.value }}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="score-breakdown__info">
            <font-awesome-icon
              icon="info-circle"
            />
          </div>
        </el-tooltip>
      </div>
      <h2>{{ label }}</h2>
    </div>
  </div>
</template>

<script>
import { get, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';

export default {
  name: 'ScoreBreakdown',
  props: {
    compareFilter: { type: String },
    count: { type: Number },
    filterId: { type: String },
    filterName: { type: String },
    id: { type: String },
    label: { type: String },
    previousScore: { type: Number },
    rql: { type: String },
    score: { type: Number },
    componentScores: { type: Array },
    segments: { type: Array },
    totalFilters: { type: Number }
  },
  computed: {
    active() {
      return this.compareFilter === `((${this.rql}))`;
    },
    arrow() {
      const { change } = this;

      if (change >= 0.1) {
        return 'arrow-alt-up';
      } else if (change <= -0.1) {
        return 'arrow-alt-down';
      } else {
        return undefined;
      }
    },
    isEmpty() {
      return !this.segments;
    },
    change() {
      const { previousScore } = this;
      if (isNumber(previousScore)) {
        const change = this.score - this.previousScore;
        return parseFloat(toFixed(change, 1));
      }
      return undefined;
    },
    changeLabel() {
      const { change } = this;
      if (isNumber(change)) {
        return Math.abs(change, 1);
      }
      return undefined;
    },
    scoreLabel() {
      const { isEmpty, score } = this;
      if (!isEmpty && isNumber(score)) {
        return toFixed(score, 1);
      }
      return undefined;
    },
    scoreLabelStyle() {
      // Change font size depending on the number of filters in the hierarchy chart
      // Atleast Firefox doesn't support min and max in CSS
      const fontSize = Math.min(Math.max((16 / this.totalFilters), 2), 4);
      const roundedFontSize = toFixed(fontSize, 1);
      return { 'font-size': `${roundedFontSize}vw` };
    },
    weighting() {
      return toFixed(this.count, 0);
    },
    rawScore() {
      const score = get(this,'componentScores.0.originalScore.score');
      if (score != undefined) {
        return toFixed(score, 1);
      }
      return undefined;
    },
    rawScoreName() {
      return get(this,'componentScores.0.originalScore.name');
    },
    toolTipDetails() {
      const toolTipDetails = [];

      if (this.rawScore != undefined) {
        const rawScore = {
          icon: 'tachometer-alt',
          label: this.rawScoreName,
          value: this.rawScore
        }
        toolTipDetails.push(rawScore);
      }

      const weighting = {
        icon: 'chart-pie',
        label: 'Weighting',
        value: `${this.weighting}%`
      };

      toolTipDetails.push(weighting);

      return toolTipDetails;
    }
  },
  methods: {
    select() {
      const { isEmpty, id, label, rql } = this;
      if (isEmpty) {
        return;
      }
      const queryFilter = {
        filterId: this.filterId,
        filterName: this.filterName,
        selected: [{ id, label, name: label, rql }]
      };
      this.$emit('selectionChanged', queryFilter, label);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.score-breakdown {
  text-align: center;
  padding: 20px 20px 0;
  min-width: 225px;
  max-width: 300px;
  margin: auto;
  cursor: pointer;

  h2 {
    color: $--neutral-900;
    font-size: 14px;
    margin: 0;
    line-height: 22px;
    padding-top: 10px;
  }

  .score-breakdown__details {
    padding: 15px;
    border: 1px solid $--neutral-200;
    &:hover {
      background-color: $--neutral-100;
    }

    &.selected {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
      border-bottom: 4px solid $--orange-500;
    }
  }

  .score-breakdown__score {
    display: inline-block;
  }

  .score-breakdown__label {
    text-align: center;
    color: $--primary-500;
    display: inline-block;
  }

  .score-breakdown__change {
    font-weight: 600;
    display: inline-block;

    span {
      color: $--neutral-600;
      font-size: 16px;
    }

    svg {
      color: $--neutral-600;
      font-size: 12px;
    }
  }

  .score-breakdown__tooltip {
    float: right;
    color: $--neutral-600;
  }

  .score-breakdown__score-details {
    margin-top: 10px;
  }

  .score-breakdown__empty {
    display: inline-block;
    span {
      color: $--red-600;
    }
    svg path {
      fill: $--red-600;
    }
  }
  .movement-arrow-alt-up {
      color: $--green-700 !important;
  }
  .movement-arrow-alt-down {
    color: $--red-600 !important;
  }
}
</style>