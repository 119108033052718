import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActiveDashboardUIStore, getAnalysisToolsStore } from 'stores/RootStore';
import ConfigureWidgetVue from 'vue/dashboards/DashboardEditable/ConfigureWidget/ConfigureWidget.vue';
import { CONFIG_FIELDS, DELETABLE_WIDGETS } from 'vue/dashboards/DashboardEditable/constants';
import { get } from 'lodash';
import SettingsMenuVue from 'vue/dashboards/DashboardEditable/SettingsMenu.vue';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { VueInReact } from 'vuera';
import { isAnalysisViewSource } from 'api/interfaces';
import { Dimmer, DimmerDimmable, Loader } from 'semantic-ui-react';
import { VNode } from 'vue';

const ConfigureWidget = VueInReact(ConfigureWidgetVue);
const SettingsMenu = VueInReact(SettingsMenuVue);

const VNodeRenderer = VueInReact({
  props: ['vnodes'],
  render() {
    return this.vnodes;
  }
});

interface Props {
  loading?: boolean;
  error?: string;
  warning?: string;
  title?: string;
  panelOrder: number;
  widgetOrder: number;
  children: React.ReactNode;
  subtitleNodes?: VNode | VNode[];
  titleNodes?: VNode | VNode[];
}

const WidgetWrapper = withCamelCaseProps((props: Props) => {
  const {
    loading,
    error,
    warning,
    title,
    panelOrder,
    widgetOrder,
    children,
    subtitleNodes,
    titleNodes
  } = props;

  const [isActivelyEditing, setIsActivelyEditing] = React.useState(false);
  const activeDashboardUIStore = getActiveDashboardUIStore();
  const analysisToolsStore = getAnalysisToolsStore();

  const widgetPath = activeDashboardUIStore.getPanelWidgetPath(panelOrder, widgetOrder) ?? '';
  const widgetConfigPath = widgetPath ? `${widgetPath}.config` : undefined;
  const widgetConfig = widgetConfigPath ? activeDashboardUIStore.getValue(widgetConfigPath) : undefined;
  const widgetType = get(widgetConfig, 'type');

  const isConfigurable = React.useMemo(() => {
    try {
      const source = activeDashboardUIStore.getSourceByWidgetPath(widgetPath);
      if (!source || !isAnalysisViewSource(source)) {
        throw new Error('Bad config');
      }
      const { survey, view, visualization } = source;
      const analysis = analysisToolsStore.getAnalysis(survey, visualization, view);
      return !!analysis && !!widgetType && !!CONFIG_FIELDS[widgetType];
    } catch (error) {
      return false;
    }
  }, [activeDashboardUIStore, analysisToolsStore, widgetPath, widgetType]);

  const isDeletable = DELETABLE_WIDGETS.includes(widgetType);

  const enterEditMode = () => setIsActivelyEditing(true);
  const exitEditMode = () => setIsActivelyEditing(false);
  const deleteWidget = () => activeDashboardUIStore.deleteWidget(panelOrder, widgetOrder);

  return (
    <DimmerDimmable
      dimmed={loading}
      className={`widget ${activeDashboardUIStore.isEditing ? 'edit-mode' : ''}`}
    >
      <div
        className={`widget-header ${activeDashboardUIStore.isEditing ? `edit-mode ${activeDashboardUIStore.dragHandleClass}` : ''}`}
        style={activeDashboardUIStore.isEditing ? {...activeDashboardUIStore.dragHandleStyle} : {}}
      >
        {title &&
          <>
            <h3 className="widget-title">{title}</h3>
            {titleNodes &&
              <div>
                <VNodeRenderer vnodes={titleNodes} />
              </div>
            }
          </>
        }
        <div className="edit-options">
          {activeDashboardUIStore.isEditing && (
            <FontAwesomeIcon className="reorder-icon" icon="arrows" />
          )}
          {activeDashboardUIStore.isEditing && (
            <SettingsMenu
              showEdit={isConfigurable}
              showDelete={isDeletable}
              on={{
                'onEdit': () => enterEditMode(),
                'onDelete': () => deleteWidget(),
              }}
            />
          )}
        </div>
      </div>
      {subtitleNodes &&
        <VNodeRenderer vnodes={subtitleNodes} />
      }
      {error ? (
        <div className="initialization-failed widget-body">
          <FontAwesomeIcon icon="exclamation-triangle" />
          Uh oh! {error}
        </div>
      ) : (
        children
      )}
      {warning && (
        <div className="widget-warning">
          <FontAwesomeIcon icon="exclamation-triangle" />
          {warning}
        </div>
      )}
      {loading &&
        <Dimmer active inverted>
          <Loader size="medium" active />
        </Dimmer>
      }
      {isConfigurable && isActivelyEditing && (
        <div>
          <ConfigureWidget
            widgetPath={widgetPath}
            on={{
              'onCancel': () => exitEditMode(),
            }}
          />
        </div>
      )}
    </DimmerDimmable>
  );
});

export { WidgetWrapper };
