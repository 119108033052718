import { TableAnswerPartType } from 'api/interfaces';
import * as React from 'react';
import { Table } from 'semantic-ui-react';

interface TableAnswerPartProps {
  data: TableAnswerPartType;
}

const TableAnswerPart = ({ data }: TableAnswerPartProps) => {
  const tableData = data.table;

  return (
    <Table celled={true}>
      <Table.Header>
        <Table.Row>
          {tableData.columns.map((col, index) => (
            <Table.HeaderCell key={index}>{col.name}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {tableData.data.map((row, rowIndex) => (
          <Table.Row key={rowIndex}>
            {tableData.columns.map((col, colIndex) => (
              <Table.Cell key={colIndex}>{row[col.accessor]}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export { TableAnswerPart };
