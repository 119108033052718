<template>
  <div
    v-if="isLoading"
    v-loading="true"
    class="dashboard-loading"
  />
  <dashboard-base v-else />
</template>

<script>
import DashboardBase from 'vue/dashboards/DashboardBase.vue';
import { getActiveDashboardUIStore, getAggregateViewStore, getSurveyStore } from 'stores/RootStore';

// We need to use this file as a mobx-vue observer can't be used directly by vuera

export default {
  name: 'Dashboard',
  components: {
    DashboardBase,
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
      aggregateViewStore: getAggregateViewStore(),
      surveyStore: getSurveyStore(),

      isLoadingSurveys: false,
      isLoadingAggregateViews: false,
    };
  },
  computed: {
    isEditing() {
      return this.activeDashboardUIStore.isEditing;
    },
    isLoading() {
      return this.isLoadingSurveys || this.isLoadingAggregateViews;
    },
  },
  watch: {
    isEditing: {
      handler(isEditing) {
        // Fetch aggregate views when in edit mode to get source info
        if (isEditing) {
          this.getAggregateViews();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // Survey data is needed to evaluate flags based on surveys (ex. canNavigate)
    this.getSurveys();
  },
  methods: {
    async getSurveys() {
      this.isLoadingSurveys = true;
      await this.surveyStore.fetchSurveys(true);
      this.isLoadingSurveys = false;
    },
    async getAggregateViews() {
      this.isLoadingAggregateViews = true;
      await this.aggregateViewStore.fetchAggregateViews();
      this.isLoadingAggregateViews = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-loading {
  margin-top: 100px;
}
</style>

