import { Reference } from 'api/interfaces';
import * as React from 'react';
import './quotes.scss';
import { QuotesList } from './QuotesList';

interface Props {
  references: Reference[];
}

export const Quotes = ({ references }: Props) => {
  return (
    <div className="quotes">
      <h3 className="quotes__title">QUOTES</h3>
      <QuotesList references={references}/>
    </div>
  );
};
