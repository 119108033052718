<template>
  <div class="promo-message">
    <div class="promo-message__icon">
      <font-awesome-icon
        class="icon"
        :icon="icon"
      />
    </div>
    <div
      class="promo-message__content"
      :class="{ 'full-page': fullPage }"
    >
      <div class="promo-message__text">
        <div class="promo-message__text-content">
          <div class="promo-message__title">
            {{ title }}
          </div>
          <div class="promo-message__description">
            {{ description }}
          </div>
          <div
            v-if="message"
            class="promo-message__message"
          >
            {{ message }}
          </div>
        </div>
        <div
          v-if="showCta"
          class="promo-message__cta"
        >
          <el-button
            class="chart-action"
            size="medium"
            type="comparison"
            @click="onClick"
          >
            Book a call
          </el-button>
        </div>
      </div>
      <div class="promo-message__image">
        <component
          :is="image"
          class="icon"
        />
      </div>
    </div>
  </div>
</template>

<script>

import analytics from 'lib/analytics';
import { constructMixMaxLink } from 'lib/mixmax-link';

export default {
  name: 'PromoMessage',
  props: {
    title: { type: String },
    description: { type: String },
    message: { type: String },
    location: { type: String },
    icon: { type: String, default: 'exclamation-triangle' },
    showCta: { type: Boolean, default: false },
    fullPage: { type: Boolean, default: true },
    image: { type: Function }
  },
  data () {
    return {};
  },
  methods: {
    onClick () {
      analytics.track(
        'Upgrade promo: Book your walkthrough click',
        {
          category: 'Upgrade',
          location: this.location
        }
      );

      const mixMaxLink = constructMixMaxLink('https://cal.mixmax.com/austinpresley/catchupcall');

      window.open(
        mixMaxLink,
        '_blank',
        'noopener,noreferrer'
      );
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.promo-message {
  background-color: $--color-white;
  border-radius: 4px;
  box-shadow: $--box-shadow-base;
  padding: 15px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 40px auto;

  &__content {
    display: grid;
    grid-gap: 15px;
    &.full-page {
      grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    }
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  &__image {
    display: flex;

    img {
      width: 100%;
      max-width: 500px;
    }
  }

  &__icon {
    font-size: 26px;
    padding-top: 5px;
    text-align: center;
  }

  &__text {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  &__text-content {
    display: grid;
    grid-gap: 15px;
    align-content: baseline;
  }

  &__title {
    font-weight: 600;
  }

  &__cta {
    .el-button {
      min-width: 150px;
    }

    .el-button:hover {
      background: $--orange-400;
      color: $--color-white;
    }
  }
}
</style>