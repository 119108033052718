

































































































































import thematicData from 'vue/libs/thematicData';
import queryBuilder from 'vue/libs/queryBuilder';
import CopyToClipboard from "vue/components/CopyToClipboard/CopyToClipboard.vue";
import analytics from 'lib/analytics';
import ReviewControls from 'vue/components/Summary/ReviewControls.vue';
import {
  getActiveDashboardUIStore,
} from 'stores/RootStore';

export default {
  name: 'SummaryComponent',
  components: {
    CopyToClipboard,
    ReviewControls
  },
  props: {
    theme: { type: Object },
    source: {type: String},
    filters: {type: Object},
    canNavigate: { type: Boolean },
    comparisonThemes: { type: Object }
  },
  data() {
    return {
      loading: false,
      error: false,
      baselineSummary: '',
      comparisonSummary: '',

      activeDashboardUIStore: getActiveDashboardUIStore(),
    };
  },
  computed: {
    hasComparison(): boolean {
      return this.comparisonThemes !== undefined;
    }
  },
  watch: {
    theme: {
      handler() {
        // if theme changes fetch new summary
        this.fetchSummary();
      },
      immediate: true,
    },
  },
  methods: {
    getLocationForFeedback() {
      const dashboardId = this.activeDashboardUIStore.currentDashboardId;
      return `dashboard:${dashboardId}`;
    },
    loadMore() {
      this.$emit('doNavigate');
    },
    retrySummaryRequests() {
      thematicData.clearSummarizationCache();
      this.fetchSummary();
    },

    fetchSummary: async function () {
      const { theme, source, filters } = this;
      if(!theme) {
        return;
      }
      this.error = false;
      this.loading = true;

      this.baselineSummary = '';


      const base = theme.theme.code || '';
      const sub = theme.subtheme ? theme.subtheme.code : null;
      const sentiment = 'all';


      const summaryContext = `${ this.selectedThemeName() }`;

      const segmentSelection = theme
        ? queryBuilder.appendThemesToSegmentFilter('', base, sub)
        : '';


      const options = {
        dataSource: source,
        segmentSelection,
        summaryContext,
        type: 'default',
        summarize: true,
        maxTokens: 100,
      };

      try {
        const filter: string = filters ? filters.baseline : '';

        const data = await thematicData.getSummarization(
          filter,
          base,
          sub,
          sentiment,
          options,
          false, // cachedOnly
        );

        this.baselineSummary = data.data.summary;

        // only fetch comparison summary if comparison themes are defined
        if (this.hasComparison) {
          const filter: string = filters ? filters.comparison : '';

          const data1 = await thematicData.getSummarization(
            filter,
            base,
            sub,
            sentiment,
            options,
            false, // cachedOnly
          );

          this.comparisonSummary = data1.data.summary;
        }

      } catch(e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    selectedThemeName() {
      const { theme, subtheme } = this.theme;
      return subtheme ? subtheme.title : theme.title;
    },
    onMouseUp() {
      const selectedText = (window.getSelection() || '').toString();
      const includesInBaselineSummary = this.baselineSummary.includes(selectedText);
      const includesInComparisonSummary = this.comparisonSummary.includes(selectedText);
      if (selectedText.length > 0 && (includesInBaselineSummary || includesInComparisonSummary)) {
        analytics.track('Dashboard: Select Summary');
      }
    },
    onTextCopied() {
      analytics.track('Dashboard: Copy Summary');
    }
  },
};
