import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {
  Modal as SemanticModal,
  ModalHeaderProps as SemanticModalHeaderProps,
  ModalProps as SemanticModalProps
} from 'semantic-ui-react';
import classNames from 'classnames';
import './modal.scss';

interface ModalCloseProps {
  onClose: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void);
}

const ModalCloseButton = ({ onClose }: ModalCloseProps) => {
  return (
    <button className="modal__close" onClick={(event) => onClose(event)} type="button" aria-label="Close">
      <FontAwesomeIcon icon="times" size="sm" />
    </button>
  );
};

export const ModalHeader = ({ children, ...remainingProps }: SemanticModalHeaderProps) => {
  return (
    <SemanticModal.Header
      className="modal__header"
      id="dialog-title"
      {...remainingProps}
    >
      {children}
    </SemanticModal.Header>
  );
};

export const ModalContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <SemanticModal.Content className="modal__content" id="dialog-content">{children}</SemanticModal.Content>
  );
};

export const ModalActions = ({ children }: { children: React.ReactNode }) => {
  return (
    <SemanticModal.Actions className="modal__actions">{children}</SemanticModal.Actions>
  );
};

interface ModalProps extends SemanticModalProps {
  focusAfterClose?: HTMLElement | null;
  // semantic's modal doesn't have a medium size, hence this prop
  isSizeMedium?: boolean;
  // onClose exists on SemanticModalProps but is added here to make it required
  onClose: (event: React.MouseEvent<HTMLElement>, data: SemanticModalProps) => void;
  onUnmount?: () => void;
  overflowVisible?: boolean;
}

export const Modal = (semanticModalProps: ModalProps) => {
  const { focusAfterClose, size, overflowVisible, isSizeMedium, ...remainingProps } = semanticModalProps;
  const { children, onClose, onUnmount } = remainingProps;

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClose(event, semanticModalProps);
    semanticModalProps?.focusAfterClose?.focus();
  };

  const handleUnmount = () => {
    focusAfterClose?.focus();

    if (onUnmount) {
      onUnmount();
    }
  };

  return (
    <SemanticModal
      className={classNames(`modal modal--${size || 'small'}`, {
        'modal--overflow-visible': overflowVisible,
        'modal--medium': isSizeMedium
      })}
      closeOnDimmerClick={true}
      closeIcon={<ModalCloseButton onClose={handleClose} />}
      dimmer="blurring"
      size={size || 'small'}
      role="dialog"
      aria-modal="true"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-content"
      {...remainingProps}
      onUnmount={() => handleUnmount()}
    >
      {children}
    </SemanticModal>
  );
};
