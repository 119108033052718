import { AutoCutSortType, AutoCutType, DateResolution, FilterType, InputColumnType, ScoreType } from 'api/enums';
import { FilterConfiguration, InputColumn, MetadataColumn, PreprocessStep, ScoreConfiguration, SurveyConfiguration } from 'api/interfaces';
import { isAnArrayIndex } from 'lib/array-helper';
import { generateColumnId } from 'lib/survey-helpers';
import { isEmpty, tail } from 'lodash';
import { Column, CommentTreatmentValues } from './interfaces';

export const getUpdatedConfiguration = (
  existingConfiguration: SurveyConfiguration,
  columns: Column[],
  hasHeader: boolean,
  requiresTranslation: boolean,
  requiresRedaction: boolean,
  commentTreatment: string,
  totalColumns: number,
  uniqueId: number[]
) => {

  const inputColumns = getInputColumns(columns);
  const commentColumns = getCommentColumns(columns).map(k => k.index);

  const commentColumnConfigs = getCommentColumnConfigs(
    commentColumns,
    commentTreatment,
    requiresTranslation,
    requiresRedaction,
    totalColumns
  );

  let configuration = {
    ...existingConfiguration,
    input_columns: inputColumns,
    has_header: hasHeader,
    ...commentColumnConfigs
  } as SurveyConfiguration;

  if (!isEmpty(uniqueId)) {
    configuration.sort_column = uniqueId;
  }

  // Set score column and corresponding filter
  const scoreColumns = columns.filter(c => c.isScore);
  let scoreFilters = [] as FilterConfiguration[];
  scoreColumns.forEach(scoreColumn => {
    const scoreConfig = getScoreColumn(scoreColumn, columns, configuration.scores);
    if (scoreConfig) {
      const scores = configuration.scores || [];
      const filters = configuration.filters || [];
      configuration.scores = [ ...scores, scoreConfig ];
      // There should be at least 3 unique values if there is a header row as 1 of them is a header
      const uniqueValueThreshold = hasHeader ? 2 : 1;
      if (scoreColumn.uniqueValues > uniqueValueThreshold) {
        const filter = getFilterForScoreColumn(scoreConfig, [ ...filters, ...scoreFilters ]);
        if (filter) {
          scoreFilters = [ ...scoreFilters, filter ];
        }
      }
    }
  });

  // Add the first score filter to filters - otherwise all scores get shown at the beginning in filters
  if (scoreFilters.length) {
    configuration.filters = [ ...(configuration.filters || []), scoreFilters[0] ];
  }

  // Set date column and corresponding filter
  const dateColumn = getDateColumn(columns, configuration.date_column);
  if (dateColumn) {
    configuration.date_column = dateColumn.index;
    configuration.date_resolution = DateResolution.MONTHLY;
    const filters = configuration.filters || [];
    const filter = getFilterForDateColumn(dateColumn, filters);
    if (filter) {
      configuration.filters = [ ...filters, filter ];
    }
  }

  // Set filter for first comment column
  const commentFilter = getDefaultCommentFilter(columns, configuration.filters);
  if (commentFilter) {
    const filters = configuration.filters || [];
    configuration.filters = [ ...filters, commentFilter ];
  }

  // Add remaining score filters to the filters list
  configuration.filters = [ ...(configuration.filters || []), ...tail(scoreFilters)];

  // Set extra filters as appropriate
  const defaultFilters = getDefaultFilterConfig(columns, configuration.filters, hasHeader);
  if (defaultFilters) {
    const filters = configuration.filters || [];
    configuration.filters = [ ...filters, ...defaultFilters ];
  }

  const metadata = getDefaultMetadata(
    configuration.metadata_columns,
    dateColumn,
    configuration.scores,
    defaultFilters
  );

  if (!!metadata && !isEmpty(metadata)) {
    configuration.metadata_columns = metadata;
  }

  return configuration;
};

/* Add date, first score and first filter as default metadata */
function getDefaultMetadata (
  existingMetadata: MetadataColumn[] = [],
  dateColumn?: Column,
  scores: ScoreConfiguration[] = [],
  defaultFilters: FilterConfiguration[] = []
) {
  let metadata = [ ...existingMetadata ];
  if (dateColumn) {
    if (metadata.find(m => m.column === dateColumn.index)) {
      return;
    }
    metadata.push({
      name: dateColumn.name,
      column: dateColumn.index
    });
  }
  if (scores.length > 0) {
    const firstScore = scores[0];
    if (metadata.find(m => m.column === firstScore.score_column)) {
      return;
    }
    metadata.push({
      name: firstScore.name,
      column: firstScore.score_column
    });
  }
  if (defaultFilters.length > 0) {
    const firstFilter = defaultFilters[0];
    if (metadata.find(m => m.column === firstFilter.column)) {
      return;
    }
    if (firstFilter.column) {
      metadata.push({
        name: firstFilter.name,
        column: firstFilter.column
      });
    }
  }
  return metadata;
}

function getInputColumns (columns: Column[]) {
  const inputColumns = {};
  columns.forEach((c, i) => {
    let { name, type } = c;

    inputColumns[i] = {
      name: name,
      type: type,
      ...(type === InputColumnType.DATE && { options: { dayfirst: !!c.dayFirst } })
    } as InputColumn;
  });
  return inputColumns;
}

export function getCommentColumns (columns: Column[]) {
  return columns.filter(c => c.isComment) || [];
}

function getCommentColumnConfigs (
  initialCommentColumns: number[],
  commentTreatment: string,
  requiresTranslation: boolean,
  requiresRedaction: boolean,
  totalColumns: number
) {
  const preprocessSteps: PreprocessStep[] = [];
  let commentColumns = [...initialCommentColumns];

  const shouldAddCommentTreatmentToConfig = commentColumns.length > 1 && !!commentTreatment;
  const concatenateComments = shouldAddCommentTreatmentToConfig &&
    commentTreatment === CommentTreatmentValues.CONCATENATE;

  let numColumns = totalColumns;
  if (concatenateComments) {
    preprocessSteps.push({
      step: CommentTreatmentValues.CONCATENATE,
      columns: commentColumns,
    });
    commentColumns = [totalColumns];
    numColumns += 1;
  }

  if (requiresRedaction) {
    commentColumns.forEach(column => {
      preprocessSteps.push({
        step: 'redact',
        column: column
      });
    });

    commentColumns = getTransformedCommentColumns(commentColumns, numColumns);
    numColumns += commentColumns.length;
  }

  if (requiresTranslation) {
    commentColumns.forEach(column => {
      preprocessSteps.push({
        step: 'translate',
        column: column
      });
    });

    commentColumns = getTransformedCommentColumns(commentColumns, numColumns);
    numColumns += commentColumns.length;
  }

  return {
    ...(preprocessSteps.length && { preprocessConfiguration: { preprocessSteps: preprocessSteps }}),
    ...(shouldAddCommentTreatmentToConfig && {
      comment_column_treatment : commentTreatment === CommentTreatmentValues.CONCATENATE
        ? CommentTreatmentValues.SAME_THEMES
        : commentTreatment
    }),
    comment_columns: commentColumns
  };
}

function getTransformedCommentColumns (commentColumns: number[], numColumns: number) {
  return commentColumns.map((columnIndex, index) => numColumns + index  );
}

function getScoreColumn (
  scoreColumn: Column,
  columns: Column[],
  existingScores: ScoreConfiguration[] = []
) {
  const scoreType = getGuessedScoreType(scoreColumn.index, columns);

  // Defensive
  const scoreExists = existingScores.find(score => score.score_column === scoreColumn.index);
  if (!scoreExists) {
    const id = generateColumnId(scoreColumn.name, existingScores.map(s => s.id));
    const score = {
      id,
      name: scoreColumn.name,
      score_column: scoreColumn.index,
      score_type: scoreType
    };
    return score;
  }
  return;
}

function getGuessedScoreType (index: number, columns: Column[]) {
  const column = columns[index];
  const originalName = column.sampleHeader;
  const userSpecifiedName = columns.find(c => c.index === index)?.name || '';

  // Guessing a score to be of type NPS if column name is nps (or)
  // the number of unique values are 11 - 0 to 10
  if (originalName.toLowerCase().trim() === 'nps' ||
    userSpecifiedName.toLowerCase().trim() === 'nps' ||
    column.uniqueValues === 11) {
    return ScoreType.NPS;
  }
  return ScoreType.AVERAGE;
}

function getFilterForScoreColumn (
  score: ScoreConfiguration,
  existingFilters: FilterConfiguration[] = []
) {
  // Defensive
  const scoreFilterExists = existingFilters.find(filter => filter.column === score.score_column);
  if (!scoreFilterExists) {
    const id = generateColumnId(score.name, existingFilters.map(s => s.id));
    const filter = {
      id,
      name: score.name,
      column: score.score_column,
      auto_cut: {
        include_all: true,
        type: AutoCutType.SCORE,
        options: {
          score_type: score.score_type,
          ignore_values: [
            'N/A',
            ''
          ]
        }
      }
    };
    return filter;
  }
  return;
}

function getDateColumn (columns: Column[], existingDateColumn: number | undefined) {
  if (!isAnArrayIndex(existingDateColumn)) {
    const dates = columns.filter(c => c.type === InputColumnType.DATE);
    if (dates.length) {
      // Pick the first date column as date
      return dates[0];
    }
  }
  return;
}

function getDefaultCommentFilter (columns: Column[], filters: FilterConfiguration[] = []) {
  const comments = getCommentColumns(columns);

  if (comments.length) {
    const filterName = 'Response contains';
    const id = generateColumnId(filterName, filters.map(s => s.id));
    const filter = {
      id,
      name: filterName,
      type: FilterType.FREETEXT
    };
    return filter;
  }
  return;
}

function getFilterForDateColumn (dateColumn: Column, filters: FilterConfiguration[] = []) {
  const id = generateColumnId(dateColumn.name, filters.map(s => s.id));

  // Defensive
  const dateFilterExists = filters.find(filter => filter.column === dateColumn.index);
  if (!dateFilterExists) {
    const dateFilter = {
      name: dateColumn.name,
      column: dateColumn.index,
      type: FilterType.DATE,
      id
    };
    return dateFilter;
  }
  return;
}

function getDefaultFilterConfig (columns: Column[], existingFilters: FilterConfiguration[] = [], hasHeader: boolean) {
  let filters = [] as FilterConfiguration[];
  let filterCount = 0;
  columns
    .filter(c => c.type !== InputColumnType.DATE && !c.isScore && !c.isComment)
    .forEach(c => {
      // Only add first 6 filters
      if (filterCount >= 6) {
        return;
      }
      // There should be at least 3 unique values if there is a header row as 1 of them is a header
      const uniqueValueMinThreshold = hasHeader ? 2 : 1;
      // Add column as filter if there are no more than 20 unique values
      if (c.uniqueValues > uniqueValueMinThreshold && c.uniqueValues <= 20) {
        const filterExists = existingFilters.find(f => f.column === c.index);
        if (filterExists) {
          return;
        }

        filterCount++;
        const allFilters = [ ...existingFilters, ...filters ];
        const id = generateColumnId(c.name, allFilters.map(s => s.id));
        const filter = {
          name: c.name,
          column: c.index,
          id,
          auto_cut: {
            include_all: true,
            type: AutoCutType.LIST,
            options: {
              ordering: AutoCutSortType.ALPHABETICAL,
              ignore_values: [
                'N/A',
                ''
              ]
            }
          }
        };
        filters = [ ...filters, filter ];
      }
    });
  return filters;
}