import * as React from 'react';
import { exampleQuestions } from '../utils/exampleQuestions';
import { Card } from 'components/Shared/Card';
import './example-questions.scss';

interface Props {
  onSuggestionClick: (suggestion: string) => void;
}

export const ExampleQuestions = ({ onSuggestionClick }: Props) => {
  return (
    <>
      <h4 className="example-questions__heading">Ask example questions:</h4>
      <ul className="example-questions__list">
        {exampleQuestions.map((presetQuestion, index) => (
          <li key={index}>
            <Card
              size="small"
              icon={presetQuestion.icon}
              color={presetQuestion.color}
              heading={presetQuestion.label}
              content={presetQuestion.category}
              onClick={() => onSuggestionClick(presetQuestion.label)}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
