import * as React from 'react';
import { Label, LabelProps } from 'semantic-ui-react';

import './label-list.scss';

interface LabelListProps {
  size?:
    | 'mini'
    | 'small'
    | 'tiny'
    | 'medium'
    | 'large'
    | 'big'
    | 'huge'
    | 'massive';
  itemClassName?: 'yellow-background' | 'green-border';
  notPositioned?: boolean;
  items: string[];
  onRemove?: (e: React.MouseEvent<HTMLElement>, props: LabelProps) => void;
}
export default class LabelList extends React.Component<LabelListProps> {
  render(): JSX.Element | null {
    const { items, onRemove, size, notPositioned, itemClassName } = this.props;
    if (items.length) {
      return (
        <div
          className={`label-list ${
            notPositioned ? 'no-padding-no-margin-vertical' : ''
          }`}
        >
          {items.map(item => (
            <Label
              size={size}
              content={item}
              className={itemClassName}
              key={item}
              onRemove={onRemove}
            />
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}
