import { PhraseSegment } from 'types/custom';

type Category = 'issue' | 'question' | 'request';

type Categories = {
  [P in Category]?: { locations: [[number, number]] }
} ;

export default function segmentsToCategories(segments: Array<PhraseSegment>): Categories {

  return segments.reduce((result, seg) => {

    seg.categories.forEach((cat: Category): void => {
      result[cat] = result[cat] || { locations: [] };
      result[cat].locations.push(seg.location);
    });

    return result;
  }, {});

}
