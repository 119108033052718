import analytics from 'lib/analytics';
import { getAnalysisToolsStore } from 'stores/RootStore';
import { toRoute } from 'lib/route-helper';
import { parse, ParsedQuery, stringify } from 'query-string';
import customHistory from 'routing/history';

interface VisParams {
  surveyID: string;
  viewID?: string;
  visID: string;
}
export function matchUrl(source: string): VisParams {
  let surveyID = '';
  let viewID = '';
  let visID = '';

  const matches = source.match(
    /survey\/([^/]+)(\/view\/([^/]+))?\/visualization\/([^/]+)\/?/
  );
  if (matches) {
    surveyID = matches[1];
    viewID = matches[3];
    visID = matches[4];
  }
  return { surveyID, viewID, visID };
}

export function canNavigate(options: {
  source?: string,
  isWeighted: boolean,
}): boolean {
  const { source, isWeighted } = options;

  if (isWeighted) return false;
  if (typeof source !== 'string') return false;

  const { surveyID, viewID } = matchUrl(source);
  return isSourceValid(surveyID, viewID);
}

export function getDestination(params: {
  options?: {
    volumeBy?: string,
  },
  orgId: string,
  source: string,
  subtheme?: { code: string, title: string },
  subtool?: 'impact'
  theme?: { code: string, title: string },
  tool: 'over-time' | 'explore'

}) {
  const queryParams: ParsedQuery<string> = parse(customHistory.location.search);

  const { filters, compare } = queryParams;

  const {
    options,
    orgId,
    source,
    subtheme,
    subtool,
    theme,
    tool,
  } = params;

  const { viewID, visID, surveyID } = matchUrl(source);

  const query = stringify({
    ...options,
    compare,
    filters,
    expand: !!theme,
    subtheme: subtheme?.title,
    subtool,
    theme: theme?.title
  });

  return `${toRoute(orgId, surveyID, viewID, visID)}/${tool}?${query}`;
}
export function goToAnalysis(url: string) {
  analytics.track('Report: to Analysis link', { category: 'Report' });
  customHistory.push(url);
}

export function isSourceValid (
  surveyID: string,
  viewID: string | undefined
) {
  const surveys = getAnalysisToolsStore().flat;
  if (!surveys) {
    return false;
  }

  const found = surveys.find(k => {
    if (k.viewId && k.viewId.toString() !== viewID) {
      return false;
    }
    return k.surveyId.toString() === surveyID;
  });

  return !!found;
}

