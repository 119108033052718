import * as React from 'react';
import { Button, ButtonGroup } from 'semantic-ui-react';
import './filter-quick-select.scss';
import { FilterAction } from 'types/custom';
import { QueryFilter } from 'stores/types';
import { createCategoryFilterOptions } from 'lib/filters/category-filter-helper';
import { createSentimentFilterOptions } from 'lib/filters/sentiment-filter-helper';
import { FilterConfig } from './types';

interface QuickSelectFilters {
  categoryFilter: QueryFilter | undefined;
  sentimentFilter: QueryFilter | undefined;
}

interface Props {
  config: FilterConfig;
  filters: QuickSelectFilters;
  onQuickFilterSelection: (action: FilterAction) => void;
}

export const FilterQuickSelect = ({ config, filters, onQuickFilterSelection }: Props) => {
  const filterOptions = {
    categories: createCategoryFilterOptions(filters.categoryFilter),
    sentiment: createSentimentFilterOptions(filters.sentimentFilter),
  };

  const isCutSelected = (cutId: 'issue' | 'request' | 'positive' | 'negative') => {
    const allOptions = Object.values(filterOptions).flat();

    return allOptions.find((filter) => filter.id === cutId)?.isSelected;
  };

  const handleSelectToggle = (selectedCutId: string, filterType: 'sentiment' | 'categories') => {
    const selectedFilterOptions = filterOptions[filterType];
    const options = selectedFilterOptions?.map?.((filter) => {
      if (filter.id === selectedCutId) {
        return {
          ...filter,
          isSelected: !filter.isSelected,
        };
      } else {
        return filter;
      }
    });
    const action: FilterAction = {
      type: filterType,
      options
    };

    analytics.track('Analysis: Quick Filter Selected', {
      category: 'Analysis',
      filterCut: selectedCutId,
      label: config.filterKey,
    });

    onQuickFilterSelection(action);
  };

  const buttonWidth = config.showSentiment ? 'four' : 'two';

  return (
    <ButtonGroup className="filter-quick-select" widths={buttonWidth}>
      <Button
        active={isCutSelected('issue')}
        className="filter-quick-select__button"
        onClick={() => handleSelectToggle('issue', 'categories')}
        size="small"
        type="button"
      >
        Issues
      </Button>
      <Button
        active={isCutSelected('request')}
        className="filter-quick-select__button"
        onClick={() => handleSelectToggle('request', 'categories')}
        size="small"
        type="button"
      >
        Requests
      </Button>
      {config.showSentiment && (
        <>
          <Button
            active={isCutSelected('positive')}
            className="filter-quick-select__button"
            onClick={() => handleSelectToggle('positive', 'sentiment')}
            size="small"
            type="button"
          >
            Positive
          </Button>
          <Button
            active={isCutSelected('negative')}
            className="filter-quick-select__button"
            onClick={() => handleSelectToggle('negative', 'sentiment')}
            size="small"
            type="button"
          >
            Negative
          </Button>
        </>
      )}
    </ButtonGroup>
  );
};
