import { Survey } from 'api/interfaces';
import { reduce } from 'lodash';
import * as moment from 'moment';
import config from 'runtime-config';

export interface AnalysisToolsConfiguration {
  checked: boolean;
  commentColumns?: number[];
  dateColumn?: number;
  dateRange?: {
    start: moment.Moment;
    end: moment.Moment;
  };
  exampleColumns?: number[];
  errored: boolean;
  fetching: boolean;
  scores?: { scoreColumn: number; scoreType: string }[];
  scoreOptions?: object;
}

export interface ConfigUrlLookup {
  [key: string]: string;
}

export function toDataSourceUrl(
  surveyId: string,
  visId: string,
  viewId?: string
): string {
  const { apiLocation } = config;
  const tokens = [apiLocation, 'survey', surveyId, 'visualization', visId];
  if (viewId) {
    tokens.splice(3, 0, 'view', viewId);
  }
  return tokens.join('/');
}
export function toConfigUrl(
  surveyId: string,
  visId: string,
  viewId?: string
): string {
  return toDataSourceUrl(surveyId, visId, viewId) + '/config';
}

export function parseAsLookup(surveys: Survey[]) {
  // go through the surveys and find the first config in visualizations or views -> visualizations
  return reduce(
    surveys,
    (lookup: ConfigUrlLookup, survey: Survey) => {
      const surveyId = String(survey.id);
      if (survey.visualizations.length) {
        const [vis] = survey.visualizations;
        lookup[survey.id] = toConfigUrl(surveyId, vis.id);
      } else if (survey.views.length) {
        const [view] = survey.views;
        const viewId = String(view.id);
        if (view.visualizations.length) {
          const [vis] = view.visualizations;
          lookup[survey.id] = toConfigUrl(surveyId, vis.id, viewId);
        }
      }
      return lookup;
    },
    {}
  );
}