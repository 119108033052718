import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestion,
  faSyncAlt
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RunId,
  Workflow,
  WorkflowHistory,
  WorkflowHistoryStatus,
  WorkflowHistoryTrigger, WorkflowRunDetails
} from 'api/workflow-interfaces';
import WorkflowRunDetailsModal from 'components/Workflows/WorkflowRunDetails';
import analytics from 'lib/analytics';
import { getWorkflowsRoute } from 'lib/route-helper';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Header, Loader, Message, Pagination, PaginationProps, Popup, Segment, Table } from 'semantic-ui-react';
import { WorkflowsStoreInterface } from 'stores/WorkflowsStore';
import { formatDateWithTime } from 'lib/date';
import './workflow-history.scss';

const MAX_ATTEMPTS_TO_TRY_HISTORY = 5;

export interface WorkflowHistoryParams {
  orgId: string;
  workflowId: string;
  runId?: string;
}
export interface WorkflowHistoryProps extends RouteComponentProps<WorkflowHistoryParams> {
  workflowsStore: WorkflowsStoreInterface;
}

export interface WorkflowHistoryState {
  currentPage: number;
  showHistoryForRunId?: RunId;
  workflowRunDetails?: WorkflowRunDetails;
}

@inject('workflowsStore')
@observer
export default class WorkflowHistoryComponent extends React.Component<WorkflowHistoryProps, WorkflowHistoryState> {

  state = {
    currentPage: 1
  } as WorkflowHistoryState;

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  get workflowId(): string {
    const { workflowId } = this.props.match.params;
    return workflowId;
  }
  get runId(): string | undefined {
    const { runId } = this.props.match.params;
    return runId;
  }
  get workflow(): Workflow | undefined {
    const { getWorkflow } = this.props.workflowsStore;
    return getWorkflow(this.workflowId);
  }

  componentDidMount() {
    this.checkHistoryParams({
      orgId: this.orgId,
      workflowId: '',
    });

    analytics.track('Workflows: View History', { category: 'Workflows' });
  }
  componentDidUpdate(prevProps: WorkflowHistoryProps) {
    this.checkHistoryParams(prevProps.match.params);
  }
  checkHistoryParams(params: WorkflowHistoryParams) {
    if (params.workflowId !== this.workflowId) {
      this.initialize();
    }
    if (params.runId !== this.runId) {
      if (this.runId) {
        this.showHistoryDetails(this.runId);
      } else {
        this.hideHistoryDetails();
      }
    }
  }
  initialize = async () => {
    await this.fetchData();
  }
  fetchData = async () => {
    if (!this.props.workflowsStore.getWorkflow(this.workflowId)) {
      this.props.workflowsStore.fetchWorkflows();
    }
    await this.props.workflowsStore.fetchWorkflowHistory(this.workflowId, this.state.currentPage, true);
  }
  onPageChange = async ({ activePage }: PaginationProps) => {
    this.setState({ currentPage: Number(activePage) });
    this.props.workflowsStore.fetchWorkflowHistory(this.workflowId, Number(activePage), true);
  }

  showHistoryDetails(runId: RunId) {
    this.setState({ showHistoryForRunId: runId });
  }

  hideHistoryDetails() {
    this.setState({ showHistoryForRunId: undefined });
  }

  goBack = () => {
    this.props.history.push(getWorkflowsRoute(this.orgId));
  }

  expectWorkflowHistoryToChange = (expectedLength: number, attemptsRemaining: number) => {
    // wait a short while and check if the history length is at least as long as expected
    setTimeout(() => {
      this.onPageChange({ activePage: 1 } as PaginationProps);
      const { workflowsStore: store } = this.props;
      const history = store.getCurrentWorkflowHistoryPage(this.workflowId);
      const currentLength = history ? history.items.length : 0;

      if (currentLength < expectedLength && attemptsRemaining > 0) {
        // will set another timeout and try again
        this.expectWorkflowHistoryToChange(expectedLength, attemptsRemaining - 1);
      }
    }, 1000);
  };

  manuallyTriggerWorkflow = () => {
    const { workflowsStore: store } = this.props;
    store.manuallyTriggerWorkflow(this.workflowId).then(() => {
      // go back to first page and reload it
      const history = store.getCurrentWorkflowHistoryPage(this.workflowId);
      const expectedLength = history ? history.items.length + 1 : 1;
      this.expectWorkflowHistoryToChange(expectedLength, MAX_ATTEMPTS_TO_TRY_HISTORY);
    });

  }

  formatTrigger(entry: WorkflowHistory) {
    if (entry.trigger === WorkflowHistoryTrigger.TIME) {
      return 'Cadence';
    } else if (entry.trigger === WorkflowHistoryTrigger.UPLOAD) {
      return 'Upload processed';
    } else if (entry.trigger === WorkflowHistoryTrigger.WEBHOOK) {
      return 'Webhook';
    } else if (entry.trigger === WorkflowHistoryTrigger.MANUAL) {
      return 'Manual';
    }
    return 'Unknown';
  }

  formatElapsed(entry: WorkflowHistory) {
    if (!entry.finished || entry.status !== WorkflowHistoryStatus.COMPLETED) {
      return '-';
    }
    const created = new Date(entry.created);
    const finished = new Date(entry.finished);
    return Math.floor((finished.valueOf() - created.valueOf()) / 1000) + 's';
  }

  formatStatus(entry: WorkflowHistory) {
    let icon = faQuestion;
    let details;
    let classes;
    if (entry.status === WorkflowHistoryStatus.COMPLETED) {
      icon = faCheckCircle;
      classes = 'workflow-history-status-success';
    } else if (entry.status === WorkflowHistoryStatus.PROCESSING || entry.status === WorkflowHistoryStatus.QUEUED) {
      icon = faSyncAlt;
      classes = 'workflow-history-status-processing';
    } else if (entry.status === WorkflowHistoryStatus.ERRORED || entry.status === WorkflowHistoryStatus.CANCELED) {
      icon = faExclamationTriangle;
      details = entry.statusDetails;
      classes = 'workflow-history-status-errored';
    }
    let status = (
      <span className={classes}>
        <FontAwesomeIcon
          className="icon"
          fixedWidth={true}
          icon={icon}
        />
      </span>);
    if (details) {
      status = (<Popup
        hoverable={true}
        position="bottom center"
        mouseEnterDelay={200}
        mouseLeaveDelay={100}
        className="column-popup"
        trigger={status}
      >
        <Popup.Content>{details}</Popup.Content>
      </Popup>);
    }
    return status;
  }

  render() {
    const { workflowsStore: store } = this.props;
    const { showHistoryForRunId } = this.state;
    const { workflow, workflowId } = this;

    const {
      fetching,
      fetchingHistory,
      manuallyTriggering,
      fetchWorkflowsErroredMessage,
      fetchWorkflowHistoryErroredMessage,
      manualTriggerErrorMessage
    } = store;

    const history = store.getCurrentWorkflowHistoryPage(this.workflowId);

    const workflowName = workflow ? workflow.name : '';

    let body;

    if (fetchingHistory || fetching) {
      body = (
        <Table.Row className="loading">
          <Table.Cell textAlign="center">
            <Loader inline={true} size="small" active={fetchingHistory || fetching}>
              Fetching history&hellip;
            </Loader>
          </Table.Cell>
        </Table.Row>
      );
    } else if (isEmpty(history)) {
      body = (
        <Table.Row className="loading">
          <Table.Cell textAlign="center">
            No workflows found
          </Table.Cell>
        </Table.Row>
      );
    } else {
      body = (history ? history.items : []).map(entry => {
        return (<Table.Row key={entry.runId}>
          <Table.Cell
          >
            {formatDateWithTime(entry.created)}
          </Table.Cell>
          <Table.Cell>
            {this.formatElapsed(entry)}
          </Table.Cell>
          <Table.Cell>
            {this.formatTrigger(entry)}
          </Table.Cell>
          <Table.Cell>
            {entry.executingRole}
          </Table.Cell>
          <Table.Cell className="workflow-history-status">
            {this.formatStatus(entry)}
            <Button
              size="small"
              className="workflow-history-status-details"
              onClick={() => { this.showHistoryDetails(entry.runId); }}
            >
              View Details
            </Button>
          </Table.Cell>
        </Table.Row>
        );
      });
    }

    return (
      <Segment className="workflow-history">
        <Header>
          <div className="nw--back" onClick={this.goBack}>
            <FontAwesomeIcon
              size="lg"
              className="icon"
              icon="arrow-alt-circle-left"
            />
          </div>
          History of {workflowName}
        </Header>
        {(fetchWorkflowHistoryErroredMessage || fetchWorkflowsErroredMessage || manualTriggerErrorMessage) && (
          <Message negative={true} icon={true}>
            <FontAwesomeIcon
              className="icon"
              icon="exclamation-triangle"
              fixedWidth={true}
            />
            <Message.Content>
              <Message.Header>
                Uh oh! Something went wrong retrieving the workflow history:
                {fetchWorkflowsErroredMessage}
                {fetchWorkflowHistoryErroredMessage}
                {manualTriggerErrorMessage}
              </Message.Header>
              <p>Please refresh the page to try again.</p>
            </Message.Content>
          </Message>
        )}
        <Segment className="white">
          <div>
            <Button
              loading={manuallyTriggering}
              onClick={this.manuallyTriggerWorkflow}>
              Manually Trigger Workflow
            </Button>
            <Button
              className="reload-list-button"
              onClick={this.fetchData}>
              <FontAwesomeIcon
                className="icon reload-icon"
                fixedWidth={true}
                icon="sync-alt"
              />
            </Button>
          </div>

          <Table
            unstackable={true}
            fixed={true}
            sortable={true}
            className="workflow-history-grid"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                >
                  Completed in
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Trigger
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Role
                </Table.HeaderCell>
                <Table.HeaderCell
                >
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{body}</Table.Body>
          </Table>

          <div className="pagination-block">
            <Pagination
              activePage={history ? history.page : 1}
              totalPages={history ? history.totalPages : 1}
              disabled={fetchingHistory}
              onPageChange={(_e, data) => this.onPageChange(data)}
            />
          </div>
        </Segment>
        <WorkflowRunDetailsModal
          workflowId={workflowId}
          runId={showHistoryForRunId}
          orgId={this.orgId}
          onClose={() => { this.hideHistoryDetails(); }} />
      </Segment>
    );
  }
}
