import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus } from 'api/enums';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Popup, Dropdown, Loader, Message, Segment, Checkbox } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import ManageDatasetModal from './ManageDatasetModal';
import './survey-header.scss';
import ViewDataSourceHistoryModal from './ViewDataSourceHistoryModal';
import ViewUploadHistoryModal from './ViewUploadHistoryModal';
import { Survey } from 'api/interfaces';

interface SurveyHeaderStoreProps {
  surveyStore?: SurveyStoreInterface;
}

export interface SurveyHeaderProps extends SurveyHeaderStoreProps {
  survey: LiteSurvey;
}

interface SurveyHeaderState {
  errorMessage?: string;
  showDeleteModal: boolean;
  showManageDatasetModal: boolean;
  showViewHistoryModal: boolean;
}

@inject('surveyStore')
@observer
export class SurveyHeader extends React.Component<
SurveyHeaderProps,
SurveyHeaderState
> {
  state = {
    errorMessage: undefined,
    showDeleteModal: false,
    showManageDatasetModal: false,
    showViewHistoryModal: false
  };

  get surveyStore() {
    return this.props.surveyStore!;
  }

  get title () {
    const { title } = this.props.survey;
    const surveyTitle = title.length > 100 ? `${title.substring(0, 100)}...` : title;
    return surveyTitle;
  }

  canConfigureSurvey = (survey: LiteSurvey) => {
    return this.surveyStore.getSurveyCanAction(survey.surveyId, 'manage:survey');
  };

  canSetupColumns = (survey: LiteSurvey) => {
    // currently this is restricted to Thematic Admins (they are the only ones with manage:internalResource)
    // also needs data to have been uploaded
    return (this.surveyStore.getSurveyCanAction(survey.surveyId, 'manage:internalResource')
      && survey.dataStatus !== SurveyDataStatus.NODATA);
  };

  canEditThemes = (survey: LiteSurvey) => {
    // can edit themes if manage:themes
    return this.surveyStore.getSurveyCanAction(survey.surveyId, 'manage:themes');
  };

  openManageDatasetModal = () => {
    this.setState({ showManageDatasetModal: true });
  }

  closeManageDatasetModal = () => {
    this.setState({ showManageDatasetModal: false });
  }

  openViewHistoryModal = () => {
    this.setState({ showViewHistoryModal: true });
  }

  closeViewHistoryModal = () => {
    this.setState({ showViewHistoryModal: false });
  }

  menu = () => {
    const { survey } = this.props;
    const { orgId, surveyId } = survey;

    const canConfigureAnalysis = survey.dataStatus !== SurveyDataStatus.NODATA &&
      survey.dataStatus !== SurveyDataStatus.NORESULTS;
    const canConfigureSurvey = this.canConfigureSurvey(survey);
    const canSetupColumns = this.canSetupColumns(survey);
    const canEditThemes = this.canEditThemes(survey);

    const hasSomeOptions = canConfigureSurvey || canSetupColumns || canEditThemes;
    if (!hasSomeOptions) {
      return <div />;
    }

    return <Dropdown
      className="icon"
      icon="cog"
      floating={true}
      pointing="top"
      size="large"
    >
      <Dropdown.Menu>
        {canConfigureSurvey &&
          <Dropdown.Item onClick={this.openManageDatasetModal}>
            Manage dataset
          </Dropdown.Item>
        }
        {canConfigureSurvey && canConfigureAnalysis &&
          <Dropdown.Item as={Link} to={`/c/${orgId}/s/${surveyId}/configure-analysis`}>
            Configure analysis
          </Dropdown.Item>
        }
        <Dropdown.Item
          disabled={!canEditThemes}
          as={Link}
          to={`/c/${orgId}/s/${surveyId}/themes`} >
          Manage themes
        </Dropdown.Item>
        {canConfigureSurvey &&
          <Dropdown.Item onClick={this.openViewHistoryModal}>
            View History
          </Dropdown.Item>
        }
        {canSetupColumns &&
          <Dropdown.Item as={Link} to={`/c/${orgId}/s/${surveyId}/configure-survey?step=review`}>
            <FontAwesomeIcon className="icon" icon="eye-slash" />
            Reset setup (Thematic only)
          </Dropdown.Item>
        }
        {canConfigureSurvey &&
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => this.toggleDeleteModal(true)} className="delete-dataset">
              Delete dataset
            </Dropdown.Item>
          </>
        }
      </Dropdown.Menu>
    </Dropdown>;
  };

  toggleDeleteModal = (showDeleteModal: boolean) => {
    this.setState({ showDeleteModal });
  }

  toggleActive = (isActive: boolean) => {

    const surveyJson: Survey | null = this.surveyStore
      .surveysJson?.find(s => s.id === this.props.survey.surveyId) ?? null;

    // Optimistically update so that the UI remains responsive
    if (surveyJson) {
      this.surveyStore.updateSurveyInState({
        ...surveyJson,
        isActive
      });
    }

    this.surveyStore.updateSurvey(this.props.survey.surveyId, {
      isActive
    });
  }

  deleteSurvey = async (surveyId: string) => {
    await this.surveyStore.deleteSurvey(surveyId);

    const { deleteSurveyError } = this.surveyStore;

    if (deleteSurveyError) {
      this.setState({ errorMessage: deleteSurveyError });
      return;
    }

    this.toggleDeleteModal(false);
  }

  render(): JSX.Element | null {
    const { survey } = this.props;
    const { errorMessage, showDeleteModal, showManageDatasetModal, showViewHistoryModal } = this.state;

    const isActive = this.props.survey.isActive;
    const isUpdating = this.surveyStore.updatingSurveyIds.includes(survey.surveyId);

    return (
      <Segment className="survey-header">
        <div className="title">
          <h3>{this.title}</h3>
        </div>
        <div className="survey-header-menu">
          <Loader active={isUpdating} size="mini"/>
          <Popup
            content={isActive ? 'Slide off to hide in menus' : 'Slide on to show in menus'}
            trigger={
              <Checkbox
                disabled={isUpdating}
                toggle={true}
                checked={isActive}
                onChange={() => this.toggleActive(!isActive)}
              />
            }
          />
          {this.menu()}
        </div>
        {showManageDatasetModal &&
          <ManageDatasetModal
            survey={survey}
            onClose={this.closeManageDatasetModal}
          />
        }
        { showViewHistoryModal && survey.dataSourceIntegration &&
          <ViewDataSourceHistoryModal
            survey={survey}
            onClose={this.closeViewHistoryModal}
          />
        }
        { showViewHistoryModal && !survey.dataSourceIntegration &&
          <ViewUploadHistoryModal
            survey={survey}
            onClose={this.closeViewHistoryModal}
          />
        }
        {showDeleteModal &&
          <ConfirmationModal
            confirmationText={`Are you sure you want to delete "${survey.title}"?`}
            confirmationButtonText="Delete"
            danger={true}
            processing={this.surveyStore.deletingSurvey}
            cancel={() => this.toggleDeleteModal(false)}
            confirm={() => this.deleteSurvey(survey.surveyId)}
          >
            This dataset will be deleted. You can't undo this action.
            {errorMessage &&
              <Message
                className="error"
                negative={true}
                header={errorMessage}
              />
            }
          </ConfirmationModal>
        }
      </Segment>
    );
  }
}

export default SurveyHeader;
