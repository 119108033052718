<template>
  <div>
    <div class="feedback-table-comment">
      <p class="feedback-table-comment__bd">
        <span
          v-for="(block, blockIndex) in blocks"
          :key="block.key"
          :class="{
            highlighted: block.isHighlighted,
            'theme-highlight-grey': block.hasThemes && !block.isHighlighted,
            [toSentimentClassname(block.sentiment, hasSentiment)]: true,
            [commentHighlightOverride]: true,
          }"
          @mouseover="() => $emit('hoverBlock', block)"
          @mouseleave="() => $emit('leaveBlock')"
          @mousedown="onTextSelectionStart"
          @mouseenter="onEnterBlock(block)"
        >
          <comment-theme-popup
            :are-themes-in-applying-state="areThemesInApplyingState"
            :block-index="blockIndex"
            :block="block"
            :can-manage-themes="canManageThemes"
            :is-sentiment-enabled="hasSentiment"
            :popup-themes="findPopupThemeItems(block)"
            @handleSentimentSelection="(value) => handleSentimentSelection(value, block)"
            @persistHoveredBlock="(block) => $emit('persistHoveredblock', block)"
            @removeTheme="(theme) => removeTheme('Sentence Popup', theme, block)"
          />
        </span>
        <add-theme-to-comment
          v-if="canSeeInlineThemesEditing"
          :selection-status="selectionStatus"
          :max-words="maxWords"
          :vertical-position="selectedPhrasePositionY"
          :horizontal-position="selectedPhrasePositionX"
          :pre-selected-phrase="selectedPhrase"
          :plain-blocks="blocks"
          :comment-id="commentId"
          :are-themes-in-applying-state="areThemesInApplyingState"
        />
      </p>
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
      >
        <font-awesome-icon
          icon="copy"
          class="copy-comment"
          @click="copyComment"
          @mouseenter="copyButtonClicked = false"
        />
        <div
          slot="content"
          class="copy-comment-text-content"
        >
          <font-awesome-icon
            v-if="copyButtonClicked"
            icon="check-circle"
            class="comment-copied"
          />
          <p>
            {{ copyButtonClicked ? 'Copied!' : getCopyText() }}
          </p>
        </div>
      </el-tooltip>
    </div>
    <div
      v-if="isSummary"
      class="feedback-table-full-conversation"
    >
      <full-conversation-button
        @click="() => $emit('showFullConversation')"
      />
    </div>
  </div>
</template>


<script>
import analytics from 'lib/analytics';
import AddThemeToComment from 'vue/components/Themes/AddThemeToComment.vue';
import CommentThemePopup from 'vue/components/Comments/CommentThemePopup.vue';
import { getAnalysisConfigStore } from 'stores/RootStore';
import { copyText } from "../../../lib/clipboard.ts";
import toSentimentClassname from "vue/libs/to-sentiment-classname";
import segmentsToBlocks from "lib/segments-to-blocks";
import getCommentHighlightLocations from "lib/get-comment-highlight-locations.ts";
import addThemeToSelectionMixin from 'vue/components/Comments/AddThemeToSelectionMixin.js';
import plainCommentMixin from "vue/components/Comments/PlainCommentMixin.js";
import popupDataMixin from 'vue/components/Comments/PopupDataMixin.js';
import FullConversationButton from 'vue/components/ConversationalAnalytics/FullConversationButton.vue';

export default {
  name: 'FeedbackTableCommentCell',
  components: {
    AddThemeToComment,
    CommentThemePopup,
    FullConversationButton
  },
  mixins: [
    plainCommentMixin(),
    addThemeToSelectionMixin(),
    popupDataMixin()
  ],
  props: {
    hoveredThemeCodes: { default: undefined, type: Object },
    hoveredLocation: { default: undefined, type: Array },
    comment: { default: '', type: String },
    segments: { type: Array },
    highlightType: { default: '', type: String },
    commentId: { default: '', type: String },
    commentColumnId: { type: [Number, String], required: true },
    isSummary: { default: false, type: Boolean },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      copyButtonClicked: false,
    };
  },
  computed: {
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    canSeeInlineThemesEditing() {
      return this.canManageThemes;
    },
    hasSentiment () {
      return this.analysisConfigStore.hasSentiment;
    },
    highlightedLocations() {
      return getCommentHighlightLocations(
        this.segments,
        undefined, // selectedThemeCodes is not used for Feedback table
        this.hoveredLocation,
        this.hoveredThemeCodes
      );
    },
    /**
     * The highlightType is usually `all` that yields the default style
     * different values (passed in from config) let us control highlighting style
     */
    commentHighlightOverride() {
      const { highlightType } = this;
      if (highlightType === 'none') {
        return 'theme-sentiment-off';
      }
      return '';
    },
    blocks() {
      return segmentsToBlocks(
        this.comment,
        this.segments,
        -1,
        this.highlightedLocations
      );
    }
  },
  watch: {
    selectionStatus(status) {
      if (status !== 'NONE') {
        const commentType = this.isSummary ? 'Summary' : 'Comment';
        analytics.track('Analysis: Select Comment', { 'Type': commentType });
      }
    }
  },
  methods: {
    toSentimentClassname,
    copyComment() {
      const commentType = this.isSummary ? 'Summary' : 'Comment';
      analytics.track('Analysis: Copy Comment', { 'Type': commentType });
      copyText(this.comment);
      this.copyButtonClicked = true;
    },
    getCopyText() {
      return this.isSummary ? 'Copy summary' : 'Copy comment';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';

.feedback-table-comment {
  display: grid;
  grid-template: 1fr / 1fr 1rem;
  gap: 1rem;

  &__bd {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    position: relative;
    word-break: break-word;
    span {
      @extend .theme-highlight-base;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 0;
      white-space: pre-wrap;
    }
  }
}

.feedback-table-comment .copy-comment {
  opacity:0;
  transition: opacity 200ms ease-out;
}

.feedback-table-comment:hover .copy-comment {
  opacity:1;
}

.copy-comment {
  color: $--primary-500;
  margin-block: 0.25em;
  cursor: pointer;
}

.copy-comment-text-content {
  font-size: 1rem;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  .comment-copied {
    color: $--green-500;
  }
}

.feedback-table-full-conversation {
  margin-top: var(--spacing-02)
}
</style>
