<template>
  <el-dialog
    title="Manage labels"
    :visible="isManageTagDialogVisible"
    class="manage-tags"
    width="30%"
    :show-close="false"
  >
    <el-alert
      v-if="deleteError"
      :title="deleteError"
      type="error"
    />
    <el-input
      ref="inputElement"
      v-model="filterText"
      placeholder="Search labels"
      class="search-tags"
      size="small"
      clearable
    />
    <div class="content">
      <el-tree
        ref="filterTree"
        :data="treeData"
        :expand-on-click-node="false"
        :default-expand-all="true"
        :filter-node-method="filterNode"
        class="filter-tree"
      >
        <span
          slot-scope="{ node, data }"
          class="manage-tags-tree-node"
        >
          <span>{{ node.label }}</span>
          <span>
            <span @click="() => removeTags(node, data)">
              <font-awesome-icon
                class="trash-icon"
                icon="trash-alt"
              />
            </span>
          </span>
        </span>
      </el-tree>
    </div>
    <span
      slot="footer"
    >
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="!isValid"
        :loading="makingChanges"
        @click="saveChanges"
      >
        Save changes
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getTagStore, getTagsUIStore } from 'stores/RootStore';
import {
  map,
  orderBy,
  partition,
  each
} from 'lodash';

export default {
  name: 'ManageTags',
  data() {
    return {
      tagStore: getTagStore(),
      tagsUIStore: getTagsUIStore(),

      tags: [],
      deletedTags: [],

      filterText: '',

      deletingTags: false,
      deleteError: '',
    };
  },
  computed: {
    treeData() {
      return map(this.tags, this.treeElementFromNode);
    },
    isValid() {
      return !!this.deletedTags.length;
    },
    makingChanges() {
      return this.deletingTags;
    },
    tagExist() {
      return Object.keys(this.tagStore.tags).length;
    },
    isManageTagDialogVisible() {
      return this.tagsUIStore.showManageTagsDialog;
    }
  },
  watch: {
    filterText(val) {
      this.$refs.filterTree.filter(val);
    },
    tagExist: {
      handler(tagExist) {
        if (tagExist) {
          this.getTags();
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.getTags();
  },
  methods: {
    async getTags() {
      const tags = await this.tagStore.getTags();
      if (!tags) {
        return;
      }
      let values = Object.values(tags);
      let tagsValue = orderBy(values || [], [value => value.name.toLowerCase()], 'name');
      let childTags = [];
      let parentTags = [];
      [childTags, parentTags] = partition(tagsValue, tag => tag.parentId);

      each(childTags, childTag => {
        let index = parentTags.findIndex(parentTag => parentTag.id === childTag.parentId);
        if (index > -1) {
          if (parentTags[index].children) {
            let existingChildIndex = parentTags[index].children.findIndex(child => child.id === childTag.id);
            if (existingChildIndex === -1) {
              parentTags[index].children.push(childTag)
            }
          } else {
            parentTags[index].children = [childTag]
          }
        }
      });
      this.tags = parentTags;
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    treeElementFromNode(node) {
      const { id, name, mergedId, parentId, fullName } = node;

      const element = { id, label: name, name, mergedId, parentId, fullName };

      if (node.children) {
        element.children = map(node.children, this.treeElementFromNode);
      }
      return element;
    },
    cancel() {
      this.tagsUIStore.closeManageTags();
      // fetching tags again incase user decides to save changes also resseting values
      this.getTags();
      this.deletedTags = [];
    },
    removeTags(node, data) {
      // adding tag ids into an array, later make api calls to delete all the tags when clicked on save changes
      this.deletedTags.push(data.id);
      if (data.children) {
        each(data.children, tag => this.deletedTags.push(tag.id));
      }

      // removing from state so it disappears from current dialog
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
      parent.childNodes.splice(index, 1);
    },

    async deleteTag() {
      this.deleteError = '';
      this.deletingTags = true;

      let deleteTagErrors = [];

      for (let tag in this.deletedTags) {
        await this.tagStore.deleteTag(this.deletedTags[tag]);

        if (this.tagStore.deleteTagError) {
          deleteTagErrors.push(this.tagStore.deleteTagError);
        }
      }
      this.deleteError = deleteTagErrors[deleteTagErrors.length - 1] || '';

      if (this.deleteError.length === 0) {
        this.deletingTags = false;
        this.deletedTags = [];
        this.tagsUIStore.closeManageTags();
        this.tagsUIStore.toggleDeletingTags(true);
      }
    },
    saveChanges() {
      this.deleteTag();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.manage-tags {
  .el-alert--error {
    margin-bottom: 10px;
  }
  .search-tags {
    padding-bottom: 10px;
  }
  .content {
    max-height: 250px;
    overflow-y: auto;
    ::v-deep .el-tree {
      .manage-tags-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        .trash-icon {
          &:hover {
            color: $--red-600;
          }
        }
      }
    }
  }
}

</style>

