import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import './tips.scss';

class Tips extends React.Component {
  render() {
    return (
      <div className="tips__wrapper">
        <FontAwesomeIcon
          className="tips__icon"
          icon="lightbulb"
        />
        <div className="tips__content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Tips;