import analytics from 'lib/analytics';
import registerIcons from 'lib/registerIcons';
import { reduce } from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';
import * as React from 'react';
import { Router } from 'react-router-dom';
import Routing from 'routing';
import fetchPageResources from 'routing/fetch-page-resources';
import history from 'routing/history';
import { getRootStore } from 'stores/RootStore';
import 'styling/semantic.scss';
import 'styling/variables/main.scss';
import Notification from '../Notification/Notification';
import { Toast } from 'components/Shared/Toast';

registerIcons();

interface AppProps {}

export class App extends React.Component<AppProps> {

  surveysReactionDisposer?: IReactionDisposer = undefined;
  orgIdReactionDisposer?: IReactionDisposer = undefined;

  constructor(props: AppProps) {
    super(props);

    // initialize mixpanel
    analytics.init();

    // fetch page resources, including user profile
    fetchPageResources();

    const { organizationStore, surveyStore } = getRootStore().stores;

    // track the loaded surveys so we can update identity information
    this.surveysReactionDisposer = reaction(() => {
      if (surveyStore) {
        return surveyStore.surveys;
      }
      return undefined;
    },
      () => {
        this.updateIdentity();
      });

    // listen & respond to orgId changes
    this.orgIdReactionDisposer = reaction(
      () => organizationStore.orgId,
      () => this.onOrgIdChange(),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    if (this.surveysReactionDisposer) {
      this.surveysReactionDisposer();
    }
    if (this.orgIdReactionDisposer) {
      this.orgIdReactionDisposer();
    }
  }

  onOrgIdChange = () => {
    // Register updated org details in analytics
    this.updateIdentity();

    // reset the stores
    const { roleStore, manageUsersStore } = getRootStore().stores;

    // This should live in the respective stores
    roleStore.reset();
    manageUsersStore.reset();
  };
  updateIdentity = () => {
    const { organizationStore, surveyStore } = getRootStore().stores;
    const { orgName: organization, orgType, orgId } = organizationStore;
    const { surveys } = surveyStore;
    if (orgId && orgType) {
      const traits = {
        organization,
        orgType,
        orgId,
        surveyCount: surveys.length,
        surveyVolume: reduce(surveys, (sum: number, survey) => {
          sum += survey.dataVolume;
          return sum;
        },
          0)
      };
      analytics.identify(undefined, traits);
    }
  }
  render(): JSX.Element | null {
    return (
      <MobxProvider
        {...getRootStore().stores}
      >
        <>
          <Router history={history}>
            <Routing />
          </Router>
          <Notification/>
          <Toast />
        </>
      </MobxProvider>
    );
  }
}

export default App;
