



























































































import analytics from 'lib/analytics';
import { getAnalysisConfigStore, getActiveDashboardUIStore } from 'stores/RootStore';
import { WIDGET_TYPE_OPTIONS, REQUIRED_COLUMNS } from '../constants';
import { FeatureFlagManager } from 'lib/feature-flag';
import ConfigureWidgetFields from './ConfigureWidgetFields.vue';
import { isEmpty, set } from 'lodash';
import ConfigureSource from '../ConfigureSource.vue';
import { CustomDashboardWidgetType, ConfigWidgetSize } from '../enums';
import { DashboardWidgetType, WidgetSortValue, SignificantChangeType } from '../../../../api/enums';
export default {
  name: 'ConfigureWidget',
  components: { ConfigureWidgetFields, ConfigureSource },
  props: {
    // Properties for new widget
    isNew: { type: Boolean },
    panelOrder: { type: Number },

    // Properties for existing widget
    widgetPath: { type: String },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      activeDashboardUIStore: getActiveDashboardUIStore(),

      isLoading: false,
      isConfigValid: false,

      analysisConfig: {},
      updatedConfig: { size: ConfigWidgetSize.FULL },

      fetchConfigError: undefined,

      existingSourceKey: undefined,
      updatedSourceKey: undefined,

      selectedWidgetType: undefined,
    };
  },
  computed: {
    widgetConfigPath() {
      return this.widgetPath ? `${this.widgetPath}.config` : undefined;
    },
    widgetSourcePath() {
      return this.widgetPath ? `${this.widgetPath}.source` : undefined;
    },
    widgetTypePath() {
      return this.widgetPath ? `${this.widgetPath}.type` : undefined;
    },
    widgetConfig() {
      if (this.widgetConfigPath) {
        return { ...this.activeDashboardUIStore.getValue(this.widgetConfigPath) };
      }
      return {};
    },
    widgetTypeOptions() {
      return WIDGET_TYPE_OPTIONS.filter((option) => {
        if (option.requiresFlag && !FeatureFlagManager.checkFlag(option.requiresFlag)) {
          return false;
        }
        const requiredColumns = REQUIRED_COLUMNS[option.value];
        if (requiredColumns) {
          // Can select the widget type if all required columns exists on the dataset
          const hasRequiredColumns = requiredColumns.every((requiredColumn) => {
            const value = this.analysisConfig[requiredColumn];
            const type = typeof value;
            if (type === 'number') {
              return value !== undefined; // numeric value of 0 (for column number) is valid
            }
            return !isEmpty(value);
          });
          return hasRequiredColumns;
        }
        return true;
      });
    },
    widgetType() {
      return this.calculateWidgetType(this.selectedWidgetType, this.updatedConfig);
    },
  },
  watch: {
    analysisConfig: {
      handler() {
        this.updatedConfig = { ...this.widgetConfig } || {};
      },
      immediate: true,
    },
    widgetConfig: {
      handler(widgetConfig) {
        if (widgetConfig.type === DashboardWidgetType.IMPACT_SIMPLE) {
          if (widgetConfig.sort === WidgetSortValue.ASC) {
            this.selectedWidgetType = CustomDashboardWidgetType.IMPACT_SIMPLE_ASC;
          } else if (widgetConfig.sort === WidgetSortValue.DESC) {
            this.selectedWidgetType = CustomDashboardWidgetType.IMPACT_SIMPLE_DESC;
          }
        } else if (widgetConfig.type === DashboardWidgetType.SIGNIFICANT_CHANGES) {
          if (widgetConfig.changeType === SignificantChangeType.VOLUME) {
            this.selectedWidgetType = CustomDashboardWidgetType.SIGNIFICANT_CHANGES_VOLUME;
          } else if (widgetConfig.changeType === SignificantChangeType.EMERGING) {
            this.selectedWidgetType = CustomDashboardWidgetType.SIGNIFICANT_CHANGES_EMERGING;
          }
        } else if (
          widgetConfig.type === DashboardWidgetType.THEMES1 ||
          widgetConfig.type === DashboardWidgetType.THEMES_SIMPLE
        ) {
          this.selectedWidgetType = CustomDashboardWidgetType.UNIFIED_THEMES;
        } else {
          this.selectedWidgetType = widgetConfig.type;
        }
        this.updatedConfig = { ...widgetConfig } || {};
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.isNew) {
      const widget = this.activeDashboardUIStore.getValue(this.widgetPath);
      this.existingSourceKey = this.activeDashboardUIStore.getWidgetSourceKey(widget);
      this.updatedSourceKey = this.existingSourceKey;

      if (this.existingSourceKey) {
        await this.updateAnalysisConfig(this.existingSourceKey);
      }
    }
  },
  methods: {
    async updateAnalysisConfig(sourceKey) {
      this.isLoading = true;
      this.fetchConfigError = undefined;

      const source = this.activeDashboardUIStore.sources[sourceKey];
      const dashboardId = this.activeDashboardUIStore.currentDashboardId;
      this.analysisConfig = await this.analysisConfigStore.getConfig(source, dashboardId);
      if (this.analysisConfigStore.fetchConfigError) {
        this.fetchConfigError = this.analysisConfigStore.fetchConfigError;
      }

      this.isLoading = false;
    },
    calculateWidgetType(selectedWidgetType, config) {
      if (
        selectedWidgetType === CustomDashboardWidgetType.IMPACT_SIMPLE_ASC ||
        selectedWidgetType === CustomDashboardWidgetType.IMPACT_SIMPLE_DESC
      ) {
        return DashboardWidgetType.IMPACT_SIMPLE;
      } else if (
        selectedWidgetType === CustomDashboardWidgetType.SIGNIFICANT_CHANGES_VOLUME ||
        selectedWidgetType === CustomDashboardWidgetType.SIGNIFICANT_CHANGES_EMERGING
      ) {
        return DashboardWidgetType.SIGNIFICANT_CHANGES;
      } else if (selectedWidgetType === CustomDashboardWidgetType.UNIFIED_THEMES) {
        if (config.size == ConfigWidgetSize.HALF) {
          return DashboardWidgetType.THEMES_SIMPLE;
        } else {
          return DashboardWidgetType.THEMES1;
        }
      }
      return selectedWidgetType;
    },
    cancel() {
      this.$emit('onCancel');
    },
    updateConfig(target, update) {
      this.updatedConfig = set(this.updatedConfig, target, update);
    },
    updateValidity(isValid) {
      this.isConfigValid = isValid;
    },
    saveEdits() {
      let widgetTypeOption = this.widgetTypeOptions.find((option) => option.value === this.selectedWidgetType);
      let defaultConfig = widgetTypeOption?.defaultConfig ?? {};
      this.updatedConfig = { ...this.updatedConfig, ...defaultConfig };
      this.updatedConfig.type = this.calculateWidgetType(this.selectedWidgetType, this.updatedConfig);

      if (this.isNew) {
        this.activeDashboardUIStore.addWidget(this.panelOrder, this.updatedConfig, this.updatedSourceKey);
        analytics.track('Dashboard: Add widget', {type: this.selectedWidgetType});
      } else {
        this.activeDashboardUIStore.setValue(this.widgetConfigPath, this.updatedConfig);
        if (this.updatedSourceKey) {
          this.activeDashboardUIStore.setValue(this.widgetSourcePath, this.updatedSourceKey);
        }
      }
      this.$emit('onCancel');
    },
    async onSourceUpdate(sourceKey) {
      this.updatedSourceKey = sourceKey;
      this.selectedWidgetType = undefined;
      await this.updateAnalysisConfig(sourceKey);
    },
  },
};
