















export default {
  name: 'ConfigureWidgetShowSummary',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
    selectedWidget: { type: String }
  },
  data() {
    return {
      showSummary: false,
    }
  },
  mounted() {
    const { showSummary } = this.widgetConfig;
    if (showSummary) {
      this.showSummary = showSummary;
    }
  },
  methods: {
    onShowSummaryChange() {
      this.$emit('onConfigChange', this.target, this.showSummary, true);
    }
  }
}
