import fetch from 'lib/authed-fetch';
import * as React from 'react';
import { SubscriptionRequest } from 'stores/SubscriptionStore';
import './subscription-modal-preview.scss';
import './getSubscriptionPreviewStyle';
import getSubscriptionPreviewStyle from './getSubscriptionPreviewStyle';

interface SubscriptionModalPreviewProps {
  orgId: string;
  dashboardId: string;
  request: SubscriptionRequest;
}

interface SubscriptionModalPreviewState {
  requestingHTMLPreview: boolean;
  requestingHTMLPreviewError?: string;
  htmlPreviewContent?: string;
}

export default class SubscriptionModalPreview extends React.Component<
  SubscriptionModalPreviewProps,
  SubscriptionModalPreviewState
> {

  private iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();
  constructor(props: SubscriptionModalPreviewProps) {
    super(props);
    this.state = {
      requestingHTMLPreview: true
    };

  }
  componentDidMount() {
    this.fetchHTMLPreview();
  }

  fetchHTMLPreview = async () => {
    const { dashboardId, request } = this.props;
    this.setState({ requestingHTMLPreviewError: undefined });
    this.setState({ requestingHTMLPreview: true });

    try {
      const { ok, data } = await fetch<string>(`/dashboard/${ dashboardId }/createDigest`,
        {
          body: JSON.stringify({
            context: request.context,
            type: 'html'
          }),
          isText: true,
          method: 'POST'
        }
      );

      if (!ok || !data) {
        throw new Error('Failed to get data');
      }

      const htmlPreviewContent = `
        <style>${getSubscriptionPreviewStyle()}</style>
        ${data}
      `;

      this.setState({ htmlPreviewContent });

      if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
        this.iframeRef.current.contentWindow.document.close();
        this.iframeRef.current.contentWindow.document.write(htmlPreviewContent);
      }
    } catch (error) {
      this.setState({ requestingHTMLPreviewError: error.message });
    } finally {
      this.setState({ requestingHTMLPreview: false });
    }
  };
  render() {
    const {
      requestingHTMLPreview,
      requestingHTMLPreviewError
    } = this.state;

    return (
      <div>
        {requestingHTMLPreviewError && (
          <div>
            Error Loading Preview: {requestingHTMLPreviewError}
          </div>
        )}
        {requestingHTMLPreview && (
          <div>
            Loading Preview....
          </div>
        )}
        <iframe
          className="subscription-preview"
          ref={this.iframeRef}
          title="Email Preview"
        />
      </div>);
  }
}
