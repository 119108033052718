import * as React from 'react';
import { useDrop } from 'react-dnd';

import './theme-card.scss';
import { ThemeNodeTypes } from './ThemeCard';

export interface HintCancelProps {
  cancelHint: () => void;
}

const HintCancel: React.FC<HintCancelProps> = props => {
  const { cancelHint } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: ThemeNodeTypes.THEME_CARD,
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    hover() {
      if (!ref.current || !isOver) {
        return;
      }
      cancelHint();
    }
  });

  drop(ref);

  return <div ref={ref} className="hint-cancel" />;
};

export default HintCancel;
