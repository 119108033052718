import { FilterType } from 'api/enums';
import { ThemesObject } from 'lib/filters/theme-filter-helper';
import { SemanticSIZES } from 'semantic-ui-react';
import { TagInfo } from 'stores/TagStore';
import { AnalysisFilterKey } from 'stores/types';

export const filterPopUpId = {
  AnalysisBaseLine: 'analysis-baseline',
  AnalysisComparison: 'analysis-comparison',
  Answers: 'answers',
  RefreshMappedPhrases: 'refresh-mapped-phrases',
  ExportAssistant: 'export-assistant',
  RouteTemplate: 'route-template',
  SpikeTemplate: 'spike-template',
  ThemeDiscovery: 'theme-discovery',
} as const;

export type FilterPopUpId = typeof filterPopUpId[keyof typeof filterPopUpId];

export interface FilterConfig {
  buttonSize?: Extract<SemanticSIZES, 'small' | 'medium'>;
  commentColumns: number[];
  canCreatePublicSavedFilters: boolean;
  disabledFilterTypes?: FilterType[];
  filterKey: AnalysisFilterKey;
  filterPopUpId: FilterPopUpId;
  isFeedbackTool: boolean;
  showSentiment: boolean;
  sortedTags: TagInfo[];
  themes: ThemesObject;
}
