import {
  forEach,
  get,
  includes,
  kebabCase,
  keys,
  lowerCase,
  orderBy,
  some,
  sortBy,
  union
} from 'lodash';
import { AxisType } from 'stores/ui/ExploreToolUIStore';
import { Score } from 'vue/libs/score-to-segments';
import toFixed from 'vue/libs/to-fixed';
import toLocaleString from 'vue/libs/to-locale-string';

export interface Theme {
  baseCount: number;
  baseCountLabel: string;
  baseImpact: number;
  baseScore: number;
  baseRawScore: object;
  baseSentiment: number;
  baseSentiments?: {
    neg: number;
    neut: number;
    pos: number;
  };
  baseVolume: number;

  compCount: number;
  compCountLabel: string;
  compImpact: number;
  compScore: number;
  compRawScore: object;
  compSentiment: number;
  compSentiments?: {
    neg: number;
    neut: number;
    pos: number;
  };
  compVolume: number;

  key: string;
  matches?: boolean;
  significant: boolean;
  subthemes?: Theme[];
  title: string;
}

function sortThemes(themes: Theme[], sort: string, axisType: string) {
  const isImpact = axisType === AxisType.Impact;
  const isVolume = axisType === AxisType.Volume;
  if (sort === 'high' || sort === 'low') {
    const sortDirection = sort === 'high' ? 'desc' : 'asc';
    if (isVolume) {
      return orderBy(themes, ['baseCount'], [sortDirection]);
    } else if (isImpact) {
      return orderBy(themes, [item => Math.abs(item.baseImpact)], [sortDirection]);
    } else {
      return orderBy(themes, ['baseScore'], [sortDirection]);
    }
  } else {
    if (isVolume) {
      // relies on having baseVolume & compVolume
      return sortBy(
        themes,
        theme => -Math.abs(theme.baseVolume - theme.compVolume)
      );
    } else if (isImpact) {
      return sortBy(
        themes,
        theme => -Math.abs(theme.baseImpact - theme.compImpact)
      );
    } else {
      return sortBy(
        themes,
        theme => -Math.abs(theme.baseScore - theme.compScore)
      );
    }
  }
}

export function mapThemeObject(
  baseBasis: Score,
  compBasis: Score,
  scoreType: string,
  range: number[] | undefined,
  base: object = {},
  comp: object = {},
  baseTotal = 1,
  compTotal = 1,
  sort = 'high',
  axisType: string,
  filter: string
) {
  filter = lowerCase(filter);
  const themeKeys = union(keys(base), keys(comp));

  const themes = [] as Theme[];
  forEach(themeKeys, title => {
    const baseTheme = base[title] || { score: { score: 0 }, impact: 0 };
    const compTheme = comp[title] || { score: { score: 0 }, impact: 0 };

    const kebabTitle = kebabCase(title);
    const key = baseTheme.code
      ? `${baseTheme.code}-${kebabTitle}`
      : `${compTheme.code}-${kebabTitle}`;

    let baseCount = get(baseTheme, 'count', 0);
    let compCount = get(compTheme, 'count', 0);

    const baseCountLabel = toLocaleString(baseCount, 'comment', 'comments');
    const compCountLabel = toLocaleString(compCount, 'comment', 'comments');

    const baseVolume = ((100.0 * baseCount) / baseTotal) || 0; // Handle NaN
    const compVolume = ((100.0 * compCount) / compTotal) || 0; // Handle NaN
    const significant = !!baseTheme.sig_diff;

    const baseCalc = {
      score: baseTheme.score.score,
      effect: baseTheme.impactPercent ? baseTheme.impactPercent : baseTheme.impact
    } as ImpactResult;

    const compCalc = {
      score: compTheme.score.score,
      effect: compTheme.impactPercent ? compTheme.impactPercent : compTheme.impact
    } as ImpactResult;

    const theme = {
      baseCount,
      compCount,
      baseImpact: baseCalc.effect,
      compImpact: compCalc.effect,
      baseScore: baseCalc.score,
      compScore: compCalc.score,
      baseRawScore: baseTheme.score,
      compRawScore: compTheme.score,
      baseSentiments: baseTheme.sentimentCounts,
      compSentiments: compTheme.sentimentCounts,
      baseSentiment: baseTheme.sentiment,
      compSentiment: compTheme.sentiment,
      baseCountLabel,
      compCountLabel,
      key,
      significant,
      title,
      baseVolume,
      compVolume
    } as Theme;

    let subthemeFilterMatch = false;
    if (baseTheme.subthemes || compTheme.subthemes) {
      const subthemes = mapThemeObject(
        baseBasis,
        compBasis,
        scoreType,
        range,
        baseTheme.subthemes,
        compTheme.subthemes,
        baseTotal,
        compTotal,
        sort,
        axisType,
        filter

      );
      if (filter && some(subthemes, subtheme => subtheme.matches)) {
        subthemeFilterMatch = true;
      }
      theme.subthemes = subthemes;
    }

    // if it matches the filter
    theme.matches =
      !filter ||
      subthemeFilterMatch ||
      includes(lowerCase(theme.title), filter);

    themes.push(theme);
  });

  return sortThemes(themes, sort, axisType);
}

export function walkThemes(themes: Theme[], fn: (theme: Theme) => void) {
  forEach(themes, theme => {
    fn(theme);
    if (theme.subthemes) {
      walkThemes(theme.subthemes, fn);
    }
  });
}
interface ImpactResult {
  effect: number;
  score: number;
}

export function toImpactArray(
  themes: Theme[],
  baseFilter: string,
  compFilter: string
): string[][] {
  const isComparison = baseFilter !== compFilter;
  const result = [] as string[][];
  const headline = ['Base theme', 'Subtheme'];
  if (isComparison) {
    headline.push(
      `Impact — ${baseFilter}`,
      `Impact — ${compFilter}`,
      `Score — ${baseFilter}`,
      `Score — ${compFilter}`
    );
  } else {
    headline.push('Impact', 'Score');
  }
  result.push(headline);

  function addTheme(theme: Theme, parentTitle?: string) {
    const row = [toFixed(theme.baseImpact, 1), toFixed(theme.baseScore, 1)];
    if (parentTitle) {
      row.unshift(parentTitle, theme.title);
    } else {
      row.unshift(theme.title, '');
    }
    if (isComparison) {
      row.splice(3, 0, toFixed(theme.compImpact, 1));
      row.push(toFixed(theme.compScore, 1));
    }
    result.push(row);
  }

  forEach(themes, theme => {
    addTheme(theme);
    forEach(theme.subthemes, subtheme => {
      addTheme(subtheme, theme.title);
    });
  });
  return result;
}
export function toScoreArray(
  themes: Theme[],
  baseFilter: string,
  compFilter: string
): string[][] {
  const isComparison = baseFilter !== compFilter;
  const result = [] as string[][];
  const headline = ['Base theme', 'Subtheme'];
  if (isComparison) {
    headline.push(`Theme Score — ${baseFilter}`, `Theme Score — ${compFilter}`);
  } else {
    headline.push('Theme Score');
  }
  result.push(headline);

  function addTheme(theme: Theme, parentTitle?: string) {
    const row = [toFixed(theme.baseScore, 1)];
    if (parentTitle) {
      row.unshift(parentTitle, theme.title);
    } else {
      row.unshift(theme.title, '');
    }
    if (isComparison) {
      row.push(toFixed(theme.compScore, 1));
    }
    result.push(row);
  }

  forEach(themes, theme => {
    addTheme(theme);
    forEach(theme.subthemes, subtheme => {
      addTheme(subtheme, theme.title);
    });
  });
  return result;
}

export function toVolumeArray(
  themes: Theme[],
  baseTotal: number,
  compTotal: number,
  baseFilter: string,
  compFilter: string
): string[][] {
  const isComparison = baseFilter !== compFilter;
  const result = [] as string[][];
  const headline = ['Base theme', 'Subtheme'];
  if (isComparison) {
    headline.push(
      `Volume (%) — ${baseFilter}`,
      `Volume (#) — ${baseFilter}`,
      `Total comments — ${baseFilter}`,
      `Volume (%) — ${compFilter}`,
      `Volume (#) — ${compFilter}`,
      `Total comments — ${compFilter}`
    );
  } else {
    headline.push('Volume (%)', 'Volume (#)', 'Total comments');
  }
  result.push(headline);

  function addTheme(theme: Theme, parentTitle?: string) {
    const row = [
      toFixed(theme.baseVolume, 1, '%'),
      String(theme.baseCount),
      String(baseTotal)
    ];
    if (parentTitle) {
      row.unshift(parentTitle, theme.title);
    } else {
      row.unshift(theme.title, '');
    }
    if (isComparison) {
      row.push(
        toFixed(theme.compVolume, 1, '%'),
        String(theme.compCount),
        String(compTotal)
      );
    }
    result.push(row);
  }

  forEach(themes, theme => {
    addTheme(theme);
    forEach(theme.subthemes, subtheme => {
      addTheme(subtheme, theme.title);
    });
  });
  return result;
}
