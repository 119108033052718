import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classnames from 'classnames';
import { isDatasetVis } from 'components/Answers/utils/datasetToPickerItems';
import { UnregisterCallback } from 'history';
import { get, map, reduce } from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Dropdown, DropdownProps, Menu } from 'semantic-ui-react';
import {
  AnalysisToolsStoreInterface,
} from 'stores/AnalysisToolsStore';
import {
  Dataset,
  DatasetView,
  DatasetVis
} from 'stores/data-structure/datasets';
import { SurveyStoreInterface } from 'stores/SurveyStore';

export interface AnalysisToolNavProps
  extends RouteComponentProps<{ orgId: string }> {
  analysisToolsStore?: AnalysisToolsStoreInterface;
  surveyStore?: SurveyStoreInterface;
}

interface AnalysisToolState {
  isActive: boolean;
}

@inject('analysisToolsStore', 'surveyStore')
@observer
export default class NestedAnalysisToolNav extends React.Component<
  AnalysisToolNavProps,
  AnalysisToolState
> {
  state = {
    isActive: false
  };
  dropdown: React.Component<DropdownProps> | null = null;
  disposer?: IReactionDisposer = undefined;
  unregister?: UnregisterCallback = undefined;
  componentDidMount() {
    const { history } = this.props;

    // listen for URL changes
    this.unregister = history.listen(loc => this.updateIsActive(loc.pathname));
    this.disposer = reaction(
      () => {
        if (this.props.analysisToolsStore) {
          return this.props.analysisToolsStore.nested;
        } else {
          return undefined;
        }
      },
      () => {
        this.updateIsActive(location.pathname);
      },
      { fireImmediately: true }
    );
  }
  componentWillUnmount() {
    if (this.unregister) {
      this.unregister();
    }
    if (this.disposer) {
      this.disposer();
    }
  }
  updateIsActive(pathname: string) {
    const { analysisToolsStore: store } = this.props;

    let isActive = false;
    if (store) {
      isActive = store.isActive(pathname);
    }
    this.setState({ isActive });
  }
  renderLink(vis: DatasetVis, surveyName?: string): JSX.Element {
    const { isPreview, order, title, url, surveyId, isReviewing } = vis;
    const key = `${ surveyId }-${ order }`;

    return (
      <Dropdown.Item key={key} as={Link} to={url} onClick={this.close}>
        {isPreview && (
          <FontAwesomeIcon
            icon="eye-slash"
            className="icon"
            fixedWidth={true}
          />
        )}
        {!isPreview && isReviewing && (
          <FontAwesomeIcon
            icon="sparkles"
            className="icon"
            fixedWidth={true}
          />
        )}
        {surveyName ? `${ surveyName }: ${ title }` : title}

      </Dropdown.Item>
    );
  }
  close = () => {
    if (this.dropdown) {
      this.dropdown.setState({ open: false });
    }
  };
  renderDatasetChildren = (
    items: (DatasetVis | DatasetView)[],
    surveyName?: string
  ): JSX.Element[] => {
    return reduce(
      items,
      (els: JSX.Element[], item: DatasetVis | DatasetView) => {
        if (isDatasetVis(item)) {
          els.push(this.renderLink(item));
        } else {
          const { children } = item;
          if (children.length === 1) {
            const vis = children[0];
            els.push(this.renderLink(vis, surveyName));
          } else {
            els.push(<Dropdown.Header key={item.order} content={item.title} />);
            els.push(...map(children, child => this.renderLink(child)));
          }
        }

        return els;
      },
      [] as JSX.Element[]
    );
  };
  extractUrl = (survey: Dataset): string => {
    return (
      get(survey, 'children.0.url') || get(survey, 'children.0.children.0.url')
    );
  };
  renderDataset = (dataset: Dataset): JSX.Element => {
    if (dataset.children.length === 1 && isDatasetVis(dataset.children[0])) {
      return this.renderLink(dataset.children[0]);
    } else {
      const { order, title, isPreview } = dataset;
      return (
        <Dropdown.Item key={order} className="nested-dropdown-item">

          <Dropdown
            trigger={(
              <span>
                {isPreview && (
                  <FontAwesomeIcon
                    icon="eye-slash"
                    className="icon nested-dropdown-status-icon"
                    fixedWidth={true}
                  />
                )}
                {title}
              </span>
            )}
            simple={true}
            icon={<FontAwesomeIcon icon="angle-right" className="icon" />}
          >
            <Dropdown.Menu>{this.renderDatasetChildren(dataset.children)}</Dropdown.Menu>
          </Dropdown>
        </Dropdown.Item>
      );
    }
  };
  isSingle = () => {
    const { analysisToolsStore: store } = this.props;
    if (store) {
      const { children: links } = store.nested;
      if (links.length === 1) {
        const items = links[0].children;
        return items.length === 1 && isDatasetVis(items[0]);
      }
    }
    return false;
  };
  render(): JSX.Element | null {
    let loading = false;
    const { analysisToolsStore: store, surveyStore } = this.props;
    const { isActive } = this.state;
    let datasets = [] as Dataset[];
    if (store) {
      datasets = store.nested.children.filter(child => child.isActive);
      loading = !!surveyStore!.fetchingSurveys;
    }

    const linkEls = datasets.length === 1
      ? this.renderDatasetChildren(datasets[0].children, datasets[0].title)
      : map(datasets, this.renderDataset);

    if (linkEls.length === 0) {
      return null;
    }

    if (loading) {
      return (
        <Dropdown
          data-testid="analysis-tool-nav"
          icon={
            <FontAwesomeIcon
              className="icon"
              icon="spinner"
              spin={true}
              fixedWidth={true}
            />
          }
          text="Analysis"
          item={true}
        />
      );
    }

    const classes = classnames('normal-size', 'nw-analysis-menu', {
      'is-active': isActive
    });

    if (this.isSingle()) {
      return (
        <Menu.Item
          data-testid="analysis-tool-nav"
          as={Link}
          to={this.extractUrl(datasets[0])}
          className={classes}
        >
          Analysis
        </Menu.Item>
      );
    } else {
      return (
        <Dropdown
          data-testid="analysis-tool-nav"
          ref={c => (this.dropdown = c)}
          item={true}
          icon={
            <FontAwesomeIcon
              icon="angle-down"
              fixedWidth={true}
              className="icon"
            />
          }
          className={classes}
          text="Analysis"
        >
          <Dropdown.Menu className="allow-overflow">{linkEls}</Dropdown.Menu>
        </Dropdown>
      );
    }
  }
}
