












const intlConfig = {
  notation: 'compact',
  compactDisplay: 'short'
};

export default {
  name: 'OverviewVolumeWidget',
  props: {
    value: { type: Number },
    total: { type: Number },
  },
  computed: {
    percentString() {
      const percent = this.value/this.total;

      return Number.isFinite(percent)
        ? new Intl.NumberFormat('en', {
            style: 'percent',
            maximumFractionDigits: 1,
          }).format(percent)
        : '';
    },
    valueString() {
      return Number.isFinite(this.value)
        ? new Intl.NumberFormat('en', intlConfig).format(this.value)
        : '';
    },
    totalString() {
      return Number.isFinite(this.total)
        ? new Intl.NumberFormat('en', intlConfig).format(this.total)
        : '';
    }
  }
};
