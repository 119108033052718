import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Form } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceGongCreateFormProps {
  onChange(configuration: object, integrationId: string): void;
}

interface GongSurveyInfo {
}

interface DataSourceGongCreateFormState {
}

@observer
class DataSourceGongCreateForm extends React.Component<
  DataSourceGongCreateFormProps,
  DataSourceGongCreateFormState
> {

  renderForm(selectedSurvey: GongSurveyInfo | undefined): JSX.Element | null {
    if (selectedSurvey) {
      return (
        <Form>
          <Form.Field>
            <label>Workspaces</label> <span>Not sure</span>
          </Form.Field>
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          integrationType={IntegrationType.GONG} />
      </div>
    );
  }
}

export default DataSourceGongCreateForm;