import {
  find, flatten,

  forEach,
  has,
  includes,
  invertBy,
  map,
  pick,
  pickBy,
  reduce,
  some,
  sortBy,
  values
} from 'lodash';
import { LiteTreeItem, ThemeTreeItem } from 'stores/ThemesStore';

export interface Themes {
  groups: ThemeGroup[];
  titles: { [key: string]: string };
}
export interface ThemeGroup {
  activeNodeId?: string;
  deleted: LiteTreeItem[];
  id: string;
  nodes: ThemeTreeItem[];
  proposedPhrase: string;
  proposedNodeTitle: string;
  title: string;
}

export interface ThemesJson {
  map: { [phrase: string]: string };
  titles: TitleJson;
  themes1: ThemeGroupJson;
}

interface PhraseMap {
  [code: string]: string[];
}

export interface ThemeGroupJson {
  [key: string]: ThemeEntryJson;
}
export interface ThemeEntryJson {
  deleted?: boolean;
  freq: number;
  new?: boolean;
  merged?: string;
  review?: boolean;
  sub_themes?: string[];
}
export interface TitleJson {
  [key: string]: string;
}
function isSpecialThemeName(key: string) {
  return includes(['nothing', 'other'], key);
}
function findMerged(
  json: ThemeGroupJson,
  titles: TitleJson,
  key: string
): LiteTreeItem[] {
  const merged = pickBy(json, { merged: key });

  return map(merged, (val: ThemeEntryJson, id: string) => {
    const { freq } = val;
    return { freq, id, isNew: has(val, 'new'), title: titles[id] || id };
  });
}
export function findMergeRoot(
  themeFile: ThemesJson,
  groupId: string,
  themeId: string
): { entry: ThemeEntryJson, themeId: string } | null {
  const group = themeFile[groupId];
  if (!group) { return null; }

  const entry = group[themeId];
  if (!entry) { return null; }

  return entry.merged
    ? findMergeRoot(themeFile, groupId, entry.merged)
    : { entry, themeId };
}

function extractTreeItems(
  json: ThemeGroupJson,
  titles: TitleJson,
  phraseMap: PhraseMap
): ThemeTreeItem[] {
  // get the parentable nodes
  const parentable = pickBy(json, val => !!val.sub_themes);
  const parentNodes = reduce(
    parentable,
    (nodes, val, key) => {
      if (!val.deleted && !val.merged && !isSpecialThemeName(key)) {
        const merged = findMerged(json, titles, key);
        const mergedIds = map(merged, 'id');
        mergedIds.unshift(key);

        // find all the phrase arrays by ids, flatten them
        const phrases = flatten(values(pick(phraseMap, ...mergedIds)));
        const isNew = has(val, 'new');
        const hasMergedNew = !isNew && some(merged, m => m.isNew);
        const node: ThemeTreeItem = {
          comments: 0,
          expanded: true,
          freq: val.freq,
          id: key,
          hasMergedNew,
          isNew,
          merged,
          phrases,
          title: titles[key],
          toReview: !!val.review
        };
        nodes.unshift(node);
      }
      return sortBy(nodes, 'title');
    },
    [] as ThemeTreeItem[]
  );

  // decorate parentable nodes with childable nodes
  forEach(parentable, (theme, key) => {
    const node = find(parentNodes, n => n.id === key);
    if (node) {
      forEach(theme.sub_themes, subKey => {
        node.children = node.children || [];
        const subtheme = json[subKey];
        if (subtheme) {
          const merged = findMerged(json, titles, subKey);
          const mergedIds = map(merged, 'id');
          mergedIds.unshift(subKey);

          // find all the phrase arrays by ids, flatten them
          const phrases = flatten(values(pick(phraseMap, ...mergedIds)));
          const isNew = has(subtheme, 'new');
          const hasMergedNew = !isNew && some(merged, m => m.isNew);
          node.children.push({
            comments: 0,
            id: subKey,
            freq: subtheme.freq,
            hasMergedNew,
            isNew,
            merged,
            phrases,
            title: titles[subKey],
            toReview: !!subtheme.review
          });
        }
      });
      node.children = sortBy(node.children, 'title');
    }
  });

  return parentNodes;
}

function extractDeleted(
  json: ThemeGroupJson,
  titles: TitleJson
): LiteTreeItem[] {
  const deleted: LiteTreeItem[] = [];
  forEach(json, (val: ThemeEntryJson, id: string) => {
    if (val.deleted) {
      const { freq } = val;
      deleted.push({
        freq,
        id,
        isNew: has(val, 'new'),
        title: titles[id] || id
      });
    }
  });
  return deleted;
}

export function parseGroup(json: ThemesJson, id: string): ThemeGroup {
  let nodes: ThemeTreeItem[];
  const { map: phraseToCodeMap, titles } = json;
  const themes = json[id];
  const deleted = extractDeleted(themes, titles);
  const title = "Group 1";
  const phraseMap = invertBy(phraseToCodeMap);
  if (themes) {
    nodes = extractTreeItems(themes, titles, phraseMap);
  } else {
    nodes = [];
  }

  return {
    activeNodeId: undefined,
    deleted,
    id,
    nodes,
    proposedPhrase: '',
    proposedNodeTitle: '',
    title
  };
}

export function parse(json: ThemesJson): Themes {
  return reduce(
    json,
    (result: Themes, val: object, key: string) => {
      if (key === 'themes1') {
        const group = parseGroup(json, key);
        result.groups.push(group);
      } else if (key === 'titles') {
        result.titles = val as { [key: string]: string };
      }
      return result;
    },
    { groups: [], titles: {} }
  );
}
