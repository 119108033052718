import FileSaver from 'file-saver';
import { forEach, isArray, isEmpty, map } from 'lodash';
import { getFilterStore, getInitConfigStore } from 'stores/RootStore';
import thematicData from 'vue/libs/thematicData';

function arraysToCSV(rows: string[][]) {
  let processRow = function (row: string[]) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"';
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return finalVal + '\n';
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }
  return csvFile;
}

function createFilename(title: string) {
  const today = new Date();
  const filename =
    (window as any).title +
    ' in ' +
    title +
    ' at ' +
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1) +
    '-' +
    today.getDate() +
    '.csv';
  return filename;
}

async function downloadResults(filterString: string, filename: string) {
  const initConfigStore = getInitConfigStore();
  const commentColumns = initConfigStore.commentColumns;
  const requestOptions = initConfigStore.requestOptions;

  if (!filterString) {
    filterString = getFilterStore().queryStrings.baseline || '';
  }

  if (!filename) {
    const title = getFilterStore().queryTitles.baseline || '';
    filename = createFilename(title);
  }

  const results = await thematicData.getResults(
    filterString,
    commentColumns,
    /* @ts-expect-error */
    requestOptions
  );
  if (isEmpty(results)) {
    return;
  }

  const arr = [['Comment', 'Sentiment', 'Themes', 'Categories']];
  // add any extra data columns that have been provided (Should be the filters)
  const [fields] = arr;

  if (isArray(results.fields)) {
    fields.push(...results.fields);
  }

  const { results: sourceRows } = results;
  forEach(sourceRows, sourceRow => {
    const themes = map(sourceRow.themes, theme => `'${theme}'`);
    const { comment, data, sentiment, categories } = sourceRow;

    const row = [comment, sentiment, themes.join(' , '), categories.join(' , '), ...data];

    arr.push(row);
  });
  downloadArray(arr, filename);
}

function downloadArray(arr: string[][], filename: string) {
  const data = arraysToCSV(arr);
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
}

export default { downloadArray, downloadResults, createFilename };
