import classNames from 'classnames';
import * as React from 'react';
import './filter-menu-item.scss';

interface FilterMenuItemProps {
  itemName?: string;
  active?: boolean;
  className?: string;
  disable?: boolean;
  onClick?: () => void;
}

// Generic styling for pretty much all "menu items" inside the filters popup.
class FilterMenuItem extends React.Component<FilterMenuItemProps, {}> {
  render() {
    const { active, className, children, itemName, disable, onClick } = this.props;
    return (
      <button
        role="option"
        type="button"
        data-testid={itemName}
        className={classNames('filter-menu-item plain', className, { active }, { disable })}
        disabled={disable}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
}

export default FilterMenuItem;
