import * as React from 'react';
import { ButtonProps } from 'semantic-ui-react';
import { getFilterStore } from 'stores/RootStore';
import { FilterPopUpId } from './types';
import { Button } from 'components/Shared/Button';

interface Props extends ButtonProps {
  filterPopUpId: FilterPopUpId;
}

export const OpenFilterButton = (props: Props) => {
  const { children, filterPopUpId } = props;

  const filterStore = getFilterStore();
  const filterIsOpen = filterStore.openFilterPopUpId === filterPopUpId;

  const handleClose = () => {
    filterStore.closeFilterPopUp();
  };

  const handleOpen = () => {
    filterStore.openFilterPopUp(filterPopUpId);
  };

  const toggleFilterPopUp = () => {
    if (filterIsOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <Button
      active={filterIsOpen}
      variant='primary'
      size='medium'
      onClick={() => toggleFilterPopUp()}
    >
      {children}
    </Button>
  );
};
