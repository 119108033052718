<template>
  <div
    :class="{ hide: shouldHide }"
    class="info-box"
  >
    <dashboard-editable-string :target="target" />
  </div>
</template>

<script>
import { getActiveDashboardUIStore } from 'stores/RootStore';
import DashboardEditableString from 'vue/dashboards/Dashboard/Components/DashboardEditableString.vue';

export default {
  name: 'DashboardDescription',
  components: {
    DashboardEditableString,
  },

  props: {},
  data() {
    return {
      target: 'metadata.description',
      activeDashboardUIStore: getActiveDashboardUIStore(),
    };
  },
  computed: {
    description() {
      const metadata = this.activeDashboardUIStore.dashboardConfig.metadata;
      const description = metadata ? metadata.description : undefined;
      return description;
    },
    shouldHide() {
      return !this.activeDashboardUIStore.isEditing && !this.description;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';
.info-box {
  background: $--color-white;
  color: $--neutral-800;
  border-radius: $--border-radius-small;
  box-shadow: 0 4px 8px rgba(215, 236, 254, 0.2);
  line-height: 22px;
  padding: 17px 20px;
  margin: 20px 30px 30px;
  @media print {
    display: none;
    margin: 0;
  }
}
</style>