import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { copyNodeToClipboardAsHtml } from '../../lib/clipboard';
import './copy-to-clipboard-button.scss';
import { Button } from 'components/Shared/Button';
import { Tooltip } from 'components/Shared/Tooltip';

type Props = {
  popupText: string;
  getContentElement: () => HTMLElement | null;
};

const CopyContentToClipboard = ({ getContentElement, popupText }: Props) => {
  const [copySuccess, setCopySuccess] = React.useState(false);
  const timeoutId = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const onCopySuccess = () => {
    setCopySuccess(true);

    timeoutId.current = setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const copyContent = async () => {
    const contentElement = getContentElement();

    if (!(contentElement instanceof HTMLElement)) {
      console.error('Could not find content element');

      return;
    }

    try {
      await copyNodeToClipboardAsHtml(contentElement);
      onCopySuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tooltip
      position="bottom left"
      content={popupText}
      on={['hover', 'focus']}
      inverted={true}
      trigger={
        <span>
          <Button
            role="button"
            variant="tertiary"
            size="small"
            onClick={() => copyContent()}
            icon={copySuccess ? (
              <FontAwesomeIcon icon="check-circle" className="success clipboard-buttons" />
            ) : (
              <FontAwesomeIcon icon="copy" fixedWidth={true} />
            )}
          />
        </span>
      }
    />
  );
};

export { CopyContentToClipboard };
