import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FilterMenuItem from 'components/Filters/FilterMenuItem';
import {
  FilterOption,
  getParentSelectionStateFromChildren,
  selectAllChildren,
} from 'lib/filters/hierarchical-filter-helper';
import * as React from 'react';
import './hierarchical-filter-view.scss';

interface HierarchicalFilterViewProps {
  filterOptions: FilterOption[];
  onFilterOptionsChanged: (newOptions: FilterOption[]) => void;
}

interface HierarchicalFilterViewState {
  expandedOptions: string[];
  expandedChildOptions: string[];
}

class HierarchicalFilterView extends React.Component<HierarchicalFilterViewProps, HierarchicalFilterViewState> {
  state: HierarchicalFilterViewState = { expandedOptions: [], expandedChildOptions: [] };

  toggleExpanded = (id: string) => {
    const { expandedOptions } = this.state;
    const newValue = expandedOptions.includes(id)
      ? expandedOptions.filter((value) => value !== id)
      : [...expandedOptions, id];

    this.setState({ expandedOptions: newValue });
  };

  toggleChildExpanded = (id: string) => {
    const { expandedChildOptions } = this.state;
    const newValue = expandedChildOptions.includes(id)
      ? expandedChildOptions.filter((value) => value !== id)
      : [...expandedChildOptions, id];

    this.setState({ expandedChildOptions: newValue });
  };

  onParentClick = (parent: FilterOption) => {
    const { filterOptions, onFilterOptionsChanged } = this.props;
    // when selecting any parent we will be selecting all of its children as well
    const newOptions = filterOptions.map((p) => {
      if (p === parent) {
        return {
          ...p,
          isSelected: !p.isSelected,
          children: p.children?.map((c) => selectAllChildren(c, !p.isSelected)),
        };
      } else {
        return p;
      }
    });
    onFilterOptionsChanged(newOptions);
  };

  onChildClick = (parent: FilterOption, child: FilterOption) => {
    const { filterOptions, onFilterOptionsChanged } = this.props;
    // hierarchical filter can have more than two levels of options hence recursive method to select the option
    const newOptions: FilterOption[] = this.recursiveChildClick(filterOptions, parent, child);
    onFilterOptionsChanged(newOptions);
  };

  recursiveChildClick = (filterOptions: FilterOption[], parent: FilterOption, child: FilterOption) => {
    // this recursive method to select the child option when it is deeply nested
    return filterOptions.map((p) => {
      if (p.id !== parent.id) {
        if (p.children) {
          p.children = this.recursiveChildClick(p.children, parent, child);
        }
        return p;
      } else {
        const newOption = this.updateChildOption(p, child);
        // changing selection state of child's parent based on how many children are currently selected
        if (newOption.children?.every((val) => val.isSelected)) {
          return {
            ...newOption,
            isSelected: true,
          };
        } else {
          return {
            ...newOption,
            isSelected: false,
          };
        }
      }
    });
  };

  updateChildOption = (parent: FilterOption, child: FilterOption) => {
    // if the children option which is being selected has it's own children option as well
    // then we will select all of its children option
    return {
      ...parent,
      children: parent.children?.map((c) => {
        if (c.id === child.id) {
          if (c.children) {
            return {
              ...c,
              isSelected: !c.isSelected,
              children: c.children?.map((val) => selectAllChildren(val, !c.isSelected)),
            };
          } else {
            return {
              ...c,
              isSelected: !c.isSelected,
            };
          }
        } else {
          return c;
        }
      }),
    };
  };

  renderChildOptions = (child, parent, optionPadding) => {
    // this is a recursive method to display child options
    // this method handles showing child option when there are two or more levels in filter
    const { expandedChildOptions } = this.state;
    let parentIcon: IconProp | undefined = undefined;

    const parentSelectionState = getParentSelectionStateFromChildren(child);
    if (parentSelectionState === 'all') {
      parentIcon = 'check';
    } else if (parentSelectionState === 'some') {
      parentIcon = 'minus';
    }
    // adding the individual child id with pre-built child id to create an unique id
    const updatedChildId = child.id + ((child.individualId && child.individualId.length > 0) ? child.individualId : '');

    return (
      <React.Fragment key={updatedChildId}>
        <FilterMenuItem
          key={updatedChildId}
          className="child-option"
          onClick={() => this.onChildClick(parent, child)}
        >
          <div style={{ paddingLeft: `${optionPadding - 36}px` }}>
            {!!child.children?.length && (
              <div
                role="button"
                className="toggle-expanded-button"
                style={{ paddingLeft: `${optionPadding - 36}px` }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.toggleChildExpanded(updatedChildId);
                }}
              >
                {expandedChildOptions.includes(updatedChildId) ? (
                  <FontAwesomeIcon icon="chevron-down" />
                ) : (
                  <FontAwesomeIcon icon="chevron-right" />
                )}
              </div>
            )}
            {child.children && child.children.length > 0
              ? parentIcon && (
                  <FontAwesomeIcon
                    className="check-icon"
                    style={{ left: `${optionPadding - 20}px` }}
                    icon={parentIcon}
                  />
                )
              : child.isSelected && (
                  <FontAwesomeIcon className="check-icon" style={{ left: `${optionPadding - 20}px` }} icon="check" />
                )}
            <span className="option-name" style={{ WebkitBoxOrient: 'vertical' }}>
              {child.name}
            </span>
          </div>
        </FilterMenuItem>
        {child.children &&
          expandedChildOptions.includes(updatedChildId) &&
          child.children.map((c) => {
            if (c.children) {
              return this.renderChildOptions(c, child, optionPadding + 22);
            }
            return (
              <FilterMenuItem
                key={c.id}
                className="nested-child-option"
                onClick={() => this.onChildClick(child, c)}
              >
                <div style={{ paddingLeft: `${optionPadding - 12}px` }}>
                  {c.isSelected && (
                    <FontAwesomeIcon className="check-icon" style={{ left: `${optionPadding + 3}px` }} icon="check" />
                  )}
                  <span className="option-name" style={{ WebkitBoxOrient: 'vertical' }}>
                    {c.name}
                  </span>
                </div>
              </FilterMenuItem>
            );
          })}
      </React.Fragment>
    );
  };

  render() {
    const { filterOptions } = this.props;
    const { expandedOptions } = this.state;
    const noChildrenAnywhere = filterOptions.every((parent) => !parent.children?.length);

    return (
      <div className={classNames('hierarchical-filter-view', { 'no-children-anywhere': noChildrenAnywhere })}>
        {filterOptions
          .filter((option) => option.rql !== '')
          .map((parent) => {
            let parentIcon: IconProp | undefined = undefined;
            const parentSelectionState = getParentSelectionStateFromChildren(parent);
            if (parentSelectionState === 'all' || parent.isSelected) {
              parentIcon = 'check';
            } else if (parentSelectionState === 'some') {
              parentIcon = 'minus';
            }

            return (
              <React.Fragment key={parent.id}>
                <FilterMenuItem
                  className="parent-option"
                  onClick={() => this.onParentClick(parent)}
                >
                  {!!parent.children?.length && (
                    <div
                      role="button"
                      className="toggle-expanded-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleExpanded(parent.id);
                      }}
                    >
                      {expandedOptions.includes(parent.id) ? (
                        <FontAwesomeIcon icon="chevron-down" />
                      ) : (
                        <FontAwesomeIcon icon="chevron-right" />
                      )}
                    </div>
                  )}
                  {parentIcon && <FontAwesomeIcon className="check-icon" icon={parentIcon} />}
                  <span className="option-name" style={{ WebkitBoxOrient: 'vertical' }}>
                    {parent.name}
                  </span>
                </FilterMenuItem>
                {parent.children &&
                  expandedOptions.includes(parent.id) &&
                  parent.children.map((child) => this.renderChildOptions(child, parent, 90))}
              </React.Fragment>
            );
          })}
      </div>
    );
  }
}

export default HierarchicalFilterView;
