import { isSurveyNameValid } from 'lib/survey-helpers';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Dropdown, DropdownItemProps, Form, FormInputProps, Input } from 'semantic-ui-react';
import { DataSourceType, ManualUploadString } from 'stores/SetupStore';
import { supportedDataSources } from './constants';
import './data-source-configure-form.scss';

interface DataSourceConfigureFormProps {
  sourceTypes: DataSourceType[];
  existingTitle?: string;
  existingConfiguration?: object;
  existingSourceType?: string;
  disableEdit?: boolean;
  setValidity?: (isValid: boolean) => void;
  onChange(title: string, valid: boolean, configuration: object): void;
}

interface DataSourceConfigureFormState {
  title: string;
  configuration: object;
  sourceType: string;
  sourceIntegrationId: string | null;
}

class DataSourceConfigureForm extends React.Component<
  DataSourceConfigureFormProps,
  DataSourceConfigureFormState
> {

  state = {
    title: '',
    configuration: {},
    sourceType: ManualUploadString,
    sourceIntegrationId: null,
  };

  componentDidMount = () => {
    const { existingTitle, existingConfiguration, existingSourceType } = this.props;
    if (existingTitle) {
      this.setState({ title: existingTitle });
    }
    if (existingSourceType) {
      this.setState({ sourceType: existingSourceType });
    }
    if (existingConfiguration) {
      this.setState({ configuration: existingConfiguration });
    }
  }

  updateExternal = () => {
    const { title, configuration, sourceType, sourceIntegrationId } = this.state;
    const valid = isSurveyNameValid(title) && this.checkConfigValid();

    const configToBroadcast = {
      type: sourceType,
      configuration,
      integrationId: sourceIntegrationId
    };

    if (this.props.onChange) {
      this.props.onChange(title, valid, configToBroadcast);
    }
  }

  setTitle = (title: string) => {
    this.setState({ title }, () => {
      this.updateExternal();
    });
  };

  setConfig = (configuration: object, integrationId: string) => {
    this.setState({ configuration, sourceIntegrationId: integrationId }, () => {
      this.updateExternal();
    });
  }

  checkConfigValid = (): boolean => {
    // ensures that we have all the configuration needed for the selected source type
    const { sourceType, configuration } = this.state;
    if (sourceType === ManualUploadString) {
      return true;
    } else if (!isEmpty(configuration)) {
      // TODO: this should/could check that the config is valid through sending to the server to run
      // against the known schema. For now we will just accept it all
      return true;
    }

    return false;
  };

  changeSourceType = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const sourceInfo = value.split(' ');
    this.setState({ sourceType: sourceInfo[0] }, () => {
      this.updateExternal();
    });
  };

  renderDataSourceForm = (selectedDataSourceType: string) => {
    // find the correct update form and return it
    if (supportedDataSources[selectedDataSourceType]) {
      const component = supportedDataSources[selectedDataSourceType].formComponent;
      return React.createElement(component, {
        existingConfiguration: this.props.existingConfiguration,
        onChange: this.setConfig,
        ...(this.props.setValidity && { setValidity: this.props.setValidity }),
        // Only integrations that redirect pass datasetName to DataSourceWithIntegrationForm
        datasetName: this.state.title,
        disableEdit: this.props.disableEdit
      });
    }
    return null;
  }

  render(): JSX.Element | null {
    const { sourceTypes, existingSourceType, disableEdit } = this.props;
    const { sourceType, title } = this.state;

    let defaultDropdownValue = ManualUploadString;

    if (existingSourceType && supportedDataSources[existingSourceType]) {
      defaultDropdownValue = existingSourceType;
    }

    const firstOption: DropdownItemProps = {
      text: ManualUploadString,
      value: ManualUploadString
    };

    const restOptions: DropdownItemProps[] = sourceTypes
      .filter(type => supportedDataSources[type.name])
      .map(type => ({
        text: supportedDataSources[type.name].name,
        value: type.name
      }))
      .sort((a, b) => a.text > b.text ? 1 : -1);

    // add relevant entries to list of available sources for sources that don't require a connected integration
    const options = [firstOption, ...restOptions];

    return (
      <div className="data-source-configure-form">
        <Form>
          <Form.Field>
            <label htmlFor="dataset-name">Dataset Name</label>
            <Input
              id="dataset-name"
              fluid={true}
              placeholder="Dataset Name"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                this.setTitle(e.currentTarget.value)
              }
              value={title}
            />
          </Form.Field>
          <Form.Field disabled={disableEdit}>
            <label>Data Source Type</label>
            <Dropdown
              selection={true}
              search={true}
              defaultValue={defaultDropdownValue}
              options={options}
              onChange={this.changeSourceType}
            />
          </Form.Field>
          <Form.Field disabled={disableEdit}>
            {this.renderDataSourceForm(sourceType)}
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default DataSourceConfigureForm;
