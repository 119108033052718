import { AnswerSubject } from 'api/interfaces';

type ThemedSegment = {
  text: string,
  theme: string,
  themeCode: string,
  isInteractive: boolean
};

type PlainSegment = {
  text: string,
};

export type TextSegment = ThemedSegment | PlainSegment;

export function processText(
  contents: string,
  subjects: AnswerSubject[],
  passiveThemes: string[]
): TextSegment[] {
  if (subjects.length === 0) {
    return [{
      text: contents
    }];
  }

  const sortedSubjects = [...subjects].sort((a, b) => a.location[0] - b.location[0]);

  // Handle overlapping locations
  const mergedSubjects = sortedSubjects.reduce((result: AnswerSubject[], subject: AnswerSubject): AnswerSubject[] => {
    const prevSubject = result[result.length - 1];

    if (!prevSubject) {
      return [...result, subject];
    }

    const hasOverlap = prevSubject.location[1] > subject.location[0];
    const prevLength = prevSubject.location[1] - prevSubject.location[0];
    const currLength = subject.location[1] - subject.location[0];

    if (hasOverlap && prevLength >= currLength) {
      prevSubject.location[1] = Math.max(prevSubject.location[1], subject.location[1]);
      const appendix = subject.text.slice(prevSubject.text.length);
      prevSubject.text = `${prevSubject.text}${appendix}`;
      return result;
    }

    if (hasOverlap) {
      prevSubject.location[1] = Math.min(prevSubject.location[1], subject.location[0]);
      prevSubject.text = contents.slice(prevSubject.location[0], subject.location[0]);
    }

    return [...result, subject];
  }, []);

  // Handle gaps between locations
  return mergedSubjects.reduce((
    result: TextSegment[],
    subject: AnswerSubject,
    index: number
  ): TextSegment[] => {

    // Handle initial gap
    if (index === 0 && subject.location[0] > 0) {
      result.push({
        text: contents.slice(0, subject.location[0])
      });
    }

    result.push({
      isInteractive: !passiveThemes.includes(subject.theme),
      theme: subject.theme,
      themeCode: subject.themeCode,
      text: contents.slice(subject.location[0], subject.location[1])
    });

    const nextSubject = mergedSubjects[index + 1];

    // Given a gap follows the current subject
    if (nextSubject && subject.location[1] < nextSubject.location[0]) {
      result.push({
        text: contents.slice(subject.location[1], nextSubject.location[0])
      });
    }

    // Given we are at the end
    if (index === mergedSubjects.length - 1 && subject.location[1] < contents.length) {
      result.push({
        text: contents.slice(subject.location[1], contents.length)
      });
    }

    return result;
  }, []);
}
