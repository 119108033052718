import { AnalysisConfig, AnswersDataSet } from "api/interfaces";

export type NestedConfigs = {
  [surveyId: string]: {
    [viewId: string]: {
      [visId: string]: AnalysisConfig | null
    }
  }
};

export function flattenConfigs(configs: NestedConfigs): Record<string, AnalysisConfig> {
  return Object.entries(configs).reduce<Record<string, AnalysisConfig>>((result, [surveyId, views]) => {
    Object.entries(views).forEach(([viewId, visualizations]) => {
      Object.entries(visualizations).forEach(([visId, analysisConfig]) => {
        if (visId === "None") {
          return;
        }

        const key = [surveyId, viewId, visId]
          .filter(key => key !== 'None')
          .join('-');

        if (analysisConfig) {
          result[key] = analysisConfig;
        }

      });
    });
    return result;
  }, {});
}

export function nestConfigs(entries: [AnswersDataSet, AnalysisConfig][]): NestedConfigs {
  return entries.reduce<NestedConfigs>((result, [dataset, analysisConfig]) => {
    const { surveyId, viewId, visId } = dataset;

    if (!result[surveyId]) {
      result[surveyId] = {};
    }

    const viewIdKey = viewId === null ? 'None' : viewId;

    if (!result[surveyId][viewIdKey]) {
      result[surveyId][viewIdKey] = {};
    }

    const visIdKey = visId === null ? 'None' : visId;

    result[surveyId][viewIdKey][visIdKey] = analysisConfig;

    return result;
  }, {});
}
