import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BillingContract, BillingDatasetUsage, BillingHiddenDatasetUsage } from 'api/interfaces';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Button, Divider, Header, Loader, Message, Progress, Segment } from 'semantic-ui-react';
import { BillingAccountStoreInterface } from 'stores/BillingAccountStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import SpecialConditionsBanner from './SpecialConditionsBanner';
import UsageByDataset from './UsageByDataset';
import UsageInHiddenDatasets from './UsageInHiddenDatasets';
import './billing-usage.scss';
import { downloadCSV } from './billing-usage.service';

interface BillingUsageProps {
  billingAccountStore?: BillingAccountStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

interface BillingUsageState {
  accountId: string;
  accountName: string;
  contracts: BillingContract[];
  contractsIndex: number;

  loadingMetrics: boolean;
  currentVerbatimCount: number;
  currentTranslationCount: number;
  totalVerbatimAllocation: number;
  totalTranslationAllocation: number;
  currentDatasetCount: number;
  totalDatasetAllocation: number;
  currentCoreUserCount: number;
  currentExplorerUserCount: number;
  totalCoreUserAllocation: number;
  totalExplorerUserAllocation: number;
  startMonth: string;
  endMonth: string;
  datasets: BillingDatasetUsage[];
  datasetsHidden: BillingHiddenDatasetUsage;
  downloadingMetrics: boolean;
  hasSpecialConditions: boolean;
}

@inject('billingAccountStore', 'organizationStore')
@observer
export default class BillingUsage extends React.Component<BillingUsageProps, BillingUsageState> {

  state = {
    accountId: '',
    accountName: '',
    contracts: [] as BillingContract[],
    contractsIndex: 0,
    loadingMetrics: true,
    startMonth: '',
    endMonth: '',
    currentVerbatimCount: 0,
    currentTranslationCount: 0,
    totalVerbatimAllocation: 0,
    totalTranslationAllocation: 0,
    currentDatasetCount: 0,
    totalDatasetAllocation: 0,
    currentCoreUserCount: 0,
    currentExplorerUserCount: 0,
    totalCoreUserAllocation: 0,
    totalExplorerUserAllocation: 0,
    datasets: [] as BillingDatasetUsage[],
    datasetsHidden: { count: 0, verbatims: 0, translations: 0 },
    downloadingMetrics: false,
    hasSpecialConditions: false,
  };

  componentDidMount() {
    analytics.track('Usage Reporting: View billing usage', { category: 'Usage Reporting' });
    const { organizationStore, billingAccountStore } = this.props;

    if (organizationStore && organizationStore.billingAccount) {
      billingAccountStore!.setAccount(organizationStore.billingAccount.id).then(accountDetail => {
        if (accountDetail) {
          this.setState({
            accountId: accountDetail.id,
            accountName: accountDetail.name,
            contracts: accountDetail.contracts,
            contractsIndex: accountDetail.contracts.length > 0 ? accountDetail.contracts.length - 1 : 0,
            hasSpecialConditions: !!accountDetail.hasSpecialConditions,
          }, () => { this.fetchMetrics(); });
        }
      });
    }
  }

  previousContract = () => {
    const { contractsIndex } = this.state;
    if (contractsIndex > 0) {
      this.setState({ contractsIndex: contractsIndex - 1 }, () => { this.fetchMetrics(); });

    }
  }

  nextContract = () => {
    const { contractsIndex, contracts } = this.state;
    if (contractsIndex < contracts.length - 1) {
      this.setState({ contractsIndex: contractsIndex + 1 }, () => { this.fetchMetrics(); });

    }
  }

  fetchMetrics = async () => {
    const { accountId, contracts, contractsIndex } = this.state;

    const { billingAccountStore } = this.props;
    if (contracts.length > 0) {
      this.setState({ loadingMetrics: true });
      const contract = contracts[contractsIndex];
      const metrics = await billingAccountStore!.fetchMetrics(accountId, contract.startDate);
      if (metrics) {
        this.setState({
          startMonth: metrics.usageInfo.startDate,
          endMonth: metrics.usageInfo.endDate,
          currentVerbatimCount: metrics.usageInfo.verbatimsUsed,
          totalVerbatimAllocation: metrics.usageInfo.verbatimsAllocated,
          currentTranslationCount: metrics.usageInfo.translationsUsed,
          totalTranslationAllocation: metrics.usageInfo.translationsAllocated,
          currentDatasetCount: metrics.usageInfo.datasetsUsed,
          totalDatasetAllocation: metrics.usageInfo.datasetsAllocated,
          currentCoreUserCount: metrics.usageInfo.coreUsersUsed,
          currentExplorerUserCount: metrics.usageInfo.explorerUsersUsed,
          totalCoreUserAllocation: metrics.usageInfo.coreUsersAllocated,
          totalExplorerUserAllocation: metrics.usageInfo.coreUsersAllocated,
          datasets: metrics.usageInfo.datasets,
          datasetsHidden: metrics.usageInfo.datasetsHidden
        });
      }
    }
    this.setState({ loadingMetrics: false });

  }

  downloadCSV = async () => {
    const { accountName, startMonth, endMonth, datasets } = this.state;
    this.setState({ downloadingMetrics: true });
    await downloadCSV(accountName, startMonth, endMonth, datasets);
    this.setState({ downloadingMetrics: false });
  }

  render() {
    const {
      contractsIndex,
      contracts,
      accountName,
      loadingMetrics,
      startMonth,
      endMonth,
      currentVerbatimCount,
      totalVerbatimAllocation,
      currentTranslationCount,
      totalTranslationAllocation,
      currentDatasetCount,
      totalDatasetAllocation,
      currentCoreUserCount,
      currentExplorerUserCount,
      totalCoreUserAllocation,
      totalExplorerUserAllocation,
      datasets,
      datasetsHidden,
      downloadingMetrics,
      hasSpecialConditions,
    } = this.state;

    const { fetchAccountMetricsError } = this.props.billingAccountStore!;

    const verbatimPercent = currentVerbatimCount / totalVerbatimAllocation * 100;
    const translationPercent = currentTranslationCount / totalTranslationAllocation * 100;
    const datasetPercent = currentDatasetCount / totalDatasetAllocation * 100;

    const coreUserPercent = totalCoreUserAllocation >= 0 ? currentCoreUserCount / totalCoreUserAllocation * 100 : 0;
    const explorerUserPercent = (totalExplorerUserAllocation >= 0 ?
      currentExplorerUserCount / totalExplorerUserAllocation * 100 : 0);
    const totalCoreUserAllocationLabel = totalCoreUserAllocation >= 0 ? totalCoreUserAllocation : '∞';

    const accountIsInOverage = ((currentVerbatimCount > totalVerbatimAllocation) ||
      (currentTranslationCount > totalTranslationAllocation)
      || (currentDatasetCount > totalDatasetAllocation));
    const usersIsInOverage = ((currentCoreUserCount > totalCoreUserAllocation) ||
      (currentExplorerUserCount > totalExplorerUserAllocation));

    return (
      <>
        {hasSpecialConditions && <SpecialConditionsBanner />}
        <Segment className="fullscreen billing-usage-container">
          <Header>
            Billing and Usage for {accountName}
          </Header>
          {loadingMetrics &&
            <Segment className="white billing-usage">
              <Loader inline="centered" active={true}>
                Loading metrics&hellip;
              </Loader>
            </Segment>
          }
          {!loadingMetrics && contracts.length > 0 &&
            <>
              {fetchAccountMetricsError &&
                <Message negative={true}>
                  {fetchAccountMetricsError}
                </Message>
              }
              <Segment>
                <h1>
                  <Button disabled={contractsIndex === 0} onClick={this.previousContract} data-testid="previous-contract"><FontAwesomeIcon icon="chevron-left" /></Button>&nbsp;
                  Contract period {startMonth} to {endMonth}&nbsp;
                  <Button
                    disabled={contractsIndex >= contracts.length - 1}
                    onClick={this.nextContract}
                    data-testid="next-contract">
                    <FontAwesomeIcon icon="chevron-right" />
                  </Button>
                </h1>
              </Segment>
              <Segment className="white billing-usage">
                <h2>Data</h2>
                <Divider />
                <div className="progress-title">
                  <div className="progress-header">
                    Included Responses
                    <span className="pull-right">{currentVerbatimCount} of {totalVerbatimAllocation}</span>
                  </div>
                  <Progress percent={verbatimPercent} progress={true} precision={0} />
                </div>
                <div className="progress-title">
                  <div className="progress-header">
                    Included Translations
                    <span className="pull-right">{currentTranslationCount} of {totalTranslationAllocation}</span>
                  </div>
                  <Progress percent={translationPercent} progress={true} precision={0} />
                </div>
                <div className="progress-title">
                  <div className="progress-header">
                    Included Datasets
                    <span className="pull-right">{currentDatasetCount} of {totalDatasetAllocation}</span>
                  </div>
                  <Progress percent={datasetPercent} progress={true} precision={0} />
                  {accountIsInOverage && (
                    <Message negative={true}>
                      <FontAwesomeIcon className="icon" icon="exclamation-triangle" fixedWidth={true} />
                      Account is in overage
                    </Message>
                  )}
                </div>

                <Divider />
                <Accordion panels={[
                  {
                    key: 0,
                    title: {
                      content: <h3>Usage by dataset</h3>
                    },
                    content: {
                      content: <div>
                        <Button
                          className="download-csv-button"
                          onClick={this.downloadCSV}
                          color="blue"
                          loading={downloadingMetrics}
                          disabled={downloadingMetrics || datasets.length === 0}
                        >
                          Download CSV
                        </Button>
                        <UsageByDataset datasets={datasets} />
                        {datasetsHidden.count > 0 && <UsageInHiddenDatasets datasetsHidden={datasetsHidden} />}
                      </div>
                    }
                  }
                ]} />
              </Segment>
              <Segment className="white billing-usage">
                <h2>Users</h2>
                <Divider />
                <div className="progress-title">
                  <div className="progress-header">
                    Core User Accounts
                    <span className="pull-right">{currentCoreUserCount} of {totalCoreUserAllocationLabel}</span>
                  </div>
                  {totalCoreUserAllocation > 0 && <Progress percent={coreUserPercent} progress={true} precision={0} />}
                </div>
                <div className="progress-title">
                  <div className="progress-header">
                    Explorer User Accounts
                    <span className="pull-right">{currentExplorerUserCount} of {totalExplorerUserAllocation}</span>
                  </div>
                  {<Progress percent={explorerUserPercent} progress={true} precision={0} />}
                </div>
                {usersIsInOverage && (
                  <Message negative={true}>
                    <FontAwesomeIcon className="icon" icon="exclamation-triangle" fixedWidth={true} />
                    Account is in overage
                  </Message>
                )}
              </Segment>
            </>
          }
          {!loadingMetrics && contracts.length === 0 && (
            <Segment className="white billing-usage">
              This billing account has no contracts
            </Segment>
          )}
        </Segment>
      </>
    );
  }
}
