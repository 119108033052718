export enum ConfigFieldType {
  SCORE = 'SCORE',
  TITLE = 'TITLE',
  SORT = 'SORT',
  ORDERING = 'ORDERING',
  THEME_LEVEL = 'THEME_LEVEL',
  SHOW_COMMENTS = 'SHOW_COMMENTS',
  SUMMARY_TYPE = 'SUMMARY_TYPE',
  SHOW_SUMMARY = 'SHOW_SUMMARY',
  SIZE = 'SIZE'
}
