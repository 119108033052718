import { ErrorMessage } from 'api/interfaces';
import * as React from 'react';
import { ErrorViewWithExampleQuestions } from './ErrorViewWithExampleQuestions';
import UnsupportedIcon from 'images/icons/unsupported-icon.svg';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, { errorCode: 'QUESTION_NOT_SUPPORTED' }>;
  onSuggestionClick: (suggestion: string) => void;
}

export const Unsupported = (props: Props) => {

  return (
    <ErrorViewWithExampleQuestions
      question={props.question}
      error={props.error}
      warningTitle="You've discovered a question type we're still learning about."
      warningIcon={<UnsupportedIcon />}
      warningDescription={
        <p className="answers-warning__description">
          Our team is developing new ways to understand questions like yours. Can you try rephrasing it in the meantime? For more guidance on supported questions check <a
            className="answers-warning__description-link"
            rel="noopener noreferrer"
            target="_blank"
            href="https://help.getthematic.com/article/133-question-types-that-answers-supports">
            Knowledge base
          </a>
        </p>
      }
      onSuggestionClick={props.onSuggestionClick}
    />
  );
};
