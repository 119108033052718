<template>
  <div class="feedback-themes">
    <!-- Only show the first base theme if not expanded -->
    <div class="theme-list">
      <CommentThemesList
        :items="getOrderedThemeItems()"
        :are-themes-in-applying-state="areThemesInApplyingState"
        :has-sentiment="true"
        :is-sentiment-enabled="hasSentiment"
        :can-manage-themes="canManageThemes"
        @removeTheme="(theme) => removeTheme(theme)"
        @mouseOverTheme="(theme) => isThemeRemoved(theme) ? null : hoverTheme(theme)"
        @mouseLeaveTheme="leaveTheme"
        @clickTheme="selectTheme"
      />

      <div
        v-for="(theme, index) in themesAdded()"
        :key="index + JSON.stringify(theme)"
        :class="{'themes-added': !doesThemeAlreadyExist(theme)}"
      >
        <span v-if="!doesThemeAlreadyExist(theme)">
          <font-awesome-icon
            icon="sparkles"
          />
          <span class="added-theme-title">{{ createThemeTitle(theme) }}</span>
        </span>
      </div>
      <add-theme
        v-if="canSeeInlineThemesEditing"
        :are-themes-in-applying-state="areThemesInApplyingState"
        :conversations="conversations"
        :plain-blocks="blocks"
        :comment-id="commentId"
      />
    </div>
  </div>
</template>


<script>
import AddTheme from 'vue/components/Themes/AddTheme.vue';
import CommentThemesList from 'vue/components/Comments/CommentThemesList.vue';
import { getAnalysisConfigStore, getThemesStore, getAnalysisToolsUIStore } from 'stores/RootStore';
import analytics from 'lib/analytics';
import toCommentThemeItem from "vue/libs/to-comment-theme-item";
import segmentsToOrderedThemes from "lib/segments-to-ordered-themes";
import {
  createThemeTitle,
  isNewlyAddedTheme,
  doesThemeAlreadyExist,
  isThemeRemoved,
  themesAdded,
} from "vue/libs/edited-comment-helpers";
import { getHighlightsFromParts } from "vue/libs/find-highlighted-sentences";
import toThreadParts from "vue/libs/to-thread-parts.ts";
import segmentsToBlocks from "lib/segments-to-blocks.ts";

export default {
  name: 'FeedbackTableThemesCell',
  components: {
    AddTheme,
    CommentThemesList
  },
  props: {
    selectedThemeCodes: { default: () => ({}), type: Object },
    hoveredLocation: { default: undefined, type: Array },
    segments: { default: () => [], type: Array },
    rowRef: { default: undefined, type: [String, Number] },
    comment: { default: '', type: [String, undefined] },
    commentId: { default: '', type: String },
    parts: { default: () => [], type: [Array, undefined] },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      themesStore: getThemesStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      hoveredThemeCodes: undefined,
      selectedThemeToRemove: undefined,
      selectedSentimentTheme: undefined,
      sentimentPopoverIndex: undefined
    };
  },
  computed: {
    conversations() {

      if (!this.parts || this.parts.length === 0) {
        return null;
      }

      const { threadDisplayConfig } = this.analysisConfigStore;

      const conversations = toThreadParts(
        threadDisplayConfig,
        this.parts,
      );

      return conversations;
    },
    blocks() {
      if (Array.isArray(this.comment)) {
        return null;
      }

      return segmentsToBlocks(
        this.comment,
        this.segments,
        -1,
        []
      );
    },
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    canSeeInlineThemesEditing() {
      return this.canManageThemes;
    },
    orderedThemes() {
      return segmentsToOrderedThemes(
        this.segments,
        this.selectedThemeCodes
      );
    },
    hasSentiment () {
      return this.analysisConfigStore.hasSentiment;
    },
    areThemesInApplyingState() {
      return this.themesStore.applying;
    },
  },
  methods: {
    createThemeTitle,
    isThemeRemoved(theme) {
      return isThemeRemoved(
        this.commentId,
        theme,
        this.getThemeLocations(theme)
      );
    },
    getThemeLocations(theme) {
      return this.segments.reduce((result, segment) => {

        const index = segment.themes.findIndex(t => {
          return t.base === theme.base && t.sub === theme.sub;
        });

        const segmentHasTheme = index > -1;

        return segmentHasTheme ? [...result, segment.location] : result;

      }, []);
    },

    doesThemeAlreadyExist(theme) {
      return doesThemeAlreadyExist(this.commentId, theme, this.orderedThemes);
    },
    themesAdded() {
      return themesAdded(this.commentId);
    },
    getOrderedThemeItems() {
      return this.orderedThemes.map(theme => toCommentThemeItem({
        theme,
        segments: this.segments,
        isNew: isNewlyAddedTheme(this.commentId, theme),
        isRemoved: isThemeRemoved(
          this.commentId,
          theme,
          this.getThemeLocations(theme)
        ),
        hoveredLocation: this.hoveredLocation,
        hoveredThemeCodes: this.hoveredThemeCodes,
        selectedThemeCodes: this.selectedThemeCodes,
      }))
    },
    selectTheme(theme) {
      this.$emit('onCommentThemeSelected', this.rowRef, theme);
    },
    hoverTheme(theme) {
      const { base, sub = null } = theme;
      this.hoveredThemeCodes = { base, sub };
      this.$emit('onCommentThemeHighlighted', this.rowRef, this.hoveredThemeCodes);
    },
    leaveTheme() {
      this.hoveredThemeCodes = undefined;
      this.$emit('onCommentThemeHighlighted', this.rowRef, this.hoveredThemeCodes);
    },
    removeTheme(theme) {
      if (!this.areThemesInApplyingState) {
        this.selectedThemeToRemove = theme;

        const locationsContainingTheme = this.segments
          .filter(seg => seg.themes.some(t => t.base === theme.base && t.sub === theme.sub))
          .map(seg => seg.location);

        const highlightedSentences = this.parts.length > 0
          ? getHighlightsFromParts(this.parts, theme)
          : locationsContainingTheme.map(
            location => this.comment.slice(location[0], location[1])
          );

        const removeThemeParams = {
          highlightedSentences,
          commentId: this.commentId,
          selectedTheme: {...theme},
        };
        this.analysisToolsUIStore.showRemoveThemeDialog(removeThemeParams);
        analytics.track('Analysis: Remove Theme Started', { Location: 'Metadata' });
      }
    },
    onHide() {
      this.sentimentPopoverIndex = undefined;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';

.feedback-themes {
  padding: 0 0 8px 15px;
  width: 100%;

  .add-theme {
    margin-top: 0.5rem;
  }

  .themes-added {
    padding: 6px 8px;
    margin: 0 5px 5px 0;
    color: $--orange-700;
    .added-theme-title {
      padding-left: 2px;
    }
  }

  .theme {
    align-items: start;
    border: none;
    color: $--neutral-700;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1;

    $border-radius: 0.3em;
    border-radius: $border-radius;
    &:hover  {
      .remove-theme-icon {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    margin: 0 5px 5px 0;
    cursor: pointer;
    &.theme-removed {
      color: $--neutral-500;
      background-color: transparent;
      text-decoration: line-through;
      cursor: default;
      pointer-events: none;
      .remove-theme-icon {
        display: none;
      }
      .feedback-comment-theme-sentiment {
        .sentiment-icon {
          // need to use important here because original sentiment file is using important as well
          color: $--neutral-500 !important;
          background-color: $--neutral-300 !important;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
    .feedback-comment-theme-sentiment {
      // remove this when removing inline themes flag
      padding: 7px 0 6px 8px;
      align-self: stretch;
      .sentiment-icon {
        width: 16px;
        height: 16px;
      }
    }
    .theme-sentiment {
      display: flex;
      flex-direction: row;
      margin: 2px 6px 2px 0;
      align-items: start;
      cursor: pointer;
      height: 100%;
      svg {
        font-size: 0.8em;
      }
      .sentiment-icon {
        width: 16px;
        height: 16px;
      }
      .caret-wrapper-feedback-table {
        display: block;
        border: 4px solid transparent;
        top: 4px;
        position: relative;
        &.down {
          border-top-color: inherit;
        }
      }
    }
    .theme-title {
      width: 100%;
      padding: 6px 2px 6px 2px;
      .new-theme {
        background-color: $--orange-600;
        border-radius: 2px;
        color: $--orange-100;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.4px;
        padding: 0 2px;
      }
    }
    .remove-theme-icon {
      padding: 6px 8px 6px 8px;
      cursor: pointer;
      align-self: stretch;
    }
    .disabled-remove-theme-icon {
      padding: 6px 8px 6px 8px;
      opacity: 0.4;
      cursor: not-allowed;
    }
    &.no-sentiment {
      grid-template-columns: auto;
    }
    &.highlighted,
    &.hovered {
      background-color: $--sentiment-off-bg-color;
      color: $--sentiment-off-text-color;
      .remove-theme-icon {
        &:hover {
          background-color: $--neutral-300;
        }
      }

      &.theme-sentiment-smile {
        background-color: $--simple-sentiment-smile;
        color: $--simple-sentiment-text-color-smile;
        .remove-theme-icon {
          &:hover {
            background-color: $--green-200;
          }
        }
      }

      &.theme-sentiment-meh {
        background-color: $--simple-sentiment-meh;
        color: $--simple-sentiment-text-color-meh;
        .remove-theme-icon {
          &:hover {
            background-color: $--yellow-200;
          }
        }
      }

      &.theme-sentiment-frown {
        background-color: $--simple-sentiment-frown;
        color: $--simple-sentiment-text-color-frown;
        .remove-theme-icon {
          &:hover {
            background-color: $--red-200;
          }
        }
      }
    }
  }
}
</style>
