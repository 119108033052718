import { DebounceEnum } from 'api/enums';
import { cloneDeep, defaults, get } from 'lodash';
import { getAnalysisToolsUIStore, getFilterStore, getInitConfigStore } from 'stores/RootStore';
import { fetchData } from 'vue/components/Tiles/Utils/bar-graph-fetcher';
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';
import statistics from 'vue/libs/statistics';
import themeHelpers from 'vue/libs/themeHelpers';
import toLocaleString from 'vue/libs/to-locale-string';

export default {
  data() {
    return {
      filterStore: getFilterStore(),
      initConfigStore: getInitConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      baselineData: undefined,
      comparisonData: undefined,
      loading: true,
      errored: false,
      filterChangeTimeout: undefined,
    };
  },
  computed: {
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    IdsFromColumn() {
      return this.initConfigStore.IdsFromColumn;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    themeFilterSet() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    calculatedSelections() {
      const { selections } = this;
      let result = cloneDeep(selections);
      if (this.axisType === 'impact') {
        const { IdsFromColumn, scoreColumn } = this;
        const filter = this.filterStore.getQueryStringByKey;
        const scoreColumnIds = IdsFromColumn(scoreColumn) || [];

        const baseQuery = filter('baseline', ...scoreColumnIds);
        const compQuery = filter('comparison', ...scoreColumnIds);

        result.baseline.query = baseQuery;
        result.comparison.query = compQuery;
      }

      return result;
    },
    selectedSubtheme() {
      return get(this, 'themeFilterSet.subtheme', null);
    },
    selectedTheme() {
      return get(this, 'themeFilterSet.theme', null);
    },
    selectedThemeObject() {
      const { selectedTheme, selectedSubtheme, baselineThemes, comparisonThemes } = this;
      return {
        baseline: themeHelpers.getTheme(baselineThemes, get(selectedTheme, 'name'), get(selectedSubtheme, 'name')),
        comparison: themeHelpers.getTheme(comparisonThemes, get(selectedTheme, 'name'), get(selectedSubtheme, 'name')),
      };
    },
    baselineCount() {
      const { baselineCounts: counts } = this;
      return nonemptyCountFromCountsObject(counts);
    },
    baselineCounts() {
      const defaultCounts = { other: 0, nothing: 0, total: 0, empty: 0 };
      const baselineCounts = get(this, 'baselineData.counts');
      return defaults({}, baselineCounts, defaultCounts);
    },
    baselineCountLabel() {
      return toLocaleString(this.baselineCount, 'comment', 'comments');
    },
    baselineScores() {
      return get(this, 'baselineData.scoreAllRows');
    },
    baselineSelectedCountLabel() {
      if (get(this, 'selectedThemeObject.baseline')) {
        return toLocaleString(this.selectedCounts.baseCount, 'comment', 'comments');
      }
      return this.baselineCountLabel;
    },

    baselineThemes() {
      return get(this, 'baselineData.themes', {});
    },
    baselineTitle() {
      return get(this, 'selections.baseline.title');
    },
    baselineErrorMessage() {
      return get(this, 'baselineData.error');
    },
    selectedCounts() {
      const { selectedThemeObject } = this;
      const baseCount = get(selectedThemeObject, 'baseline.count', 0);
      const compCount = get(selectedThemeObject, 'comparison.count', 0);
      const baseSentiment = get(selectedThemeObject, 'baseline.sentiment', 0);
      const compSentiment = get(selectedThemeObject, 'comparison.sentiment', 0);
      const baseSentimentCounts = get(selectedThemeObject, 'baseline.sentimentCounts', { neg: 0, pos: 0, neut: 0 });
      const compSentimentCounts = get(selectedThemeObject, 'comparison.sentimentCounts', { neg: 0, pos: 0, neut: 0 });
      return {
        baseCount,
        compCount,
        baseSentiment,
        compSentiment,
        baseSentimentCounts,
        compSentimentCounts,
      };
    },
    comparisonCount() {
      const { comparisonCounts: counts } = this;
      return nonemptyCountFromCountsObject(counts);
    },
    comparisonCounts() {
      const defaultCounts = { other: 0, nothing: 0, total: 0, empty: 0 };
      const comparisonCounts = get(this, 'comparisonData.counts');
      return defaults({}, comparisonCounts, defaultCounts);
    },
    comparisonCountLabel() {
      return toLocaleString(this.comparisonCount, 'comment', 'comments');
    },
    comparisonScores() {
      return get(this, 'comparisonData.scoreAllRows');
    },
    comparisonSelectedCountLabel() {
      if (get(this, 'selectedThemeObject.comparison')) {
        return toLocaleString(this.selectedCounts.compCount, 'comment', 'comments');
      }
      return this.comparisonCountLabel;
    },
    comparisonThemes() {
      return get(this, 'comparisonData.themes', {});
    },
    comparisonTitle() {
      return get(this, 'selections.comparison.title');
    },
    comparisonErrorMessage() {
      return get(this, 'comparisonData.error');
    },
    hasComparison() {
      const baselineQuery = get(this, 'selections.baseline.query');
      const comparisonQuery = get(this, 'selections.comparison.query');
      return baselineQuery !== comparisonQuery;
    },
    hasImpactComparison() {
      const { calculatedSelections } = this;
      const { baseline, comparison } = calculatedSelections;

      return baseline.query !== comparison.query;
    },
    scoreColumn() {
      return get(this, 'requestOptions.scoreColumn');
    },
    scoreType() {
      return get(this, 'requestOptions.scoreType');
    },
  },
  methods: {
    onFilterChange() {
      clearTimeout(this.filterChangeTimeout);
      this.filterChangeTimeout = setTimeout(this.fetchData, DebounceEnum.FILTER_CALLS);
    },
    fetchData: async function () {
      this.loading = true;
      this.analysisToolsUIStore.setExploreToolLoading(this.loading);

      // reset to blank if we're fetching new data
      this.baselineData = this.comparisonData = undefined;
      const { commentColumns, requestOptions, calculatedSelections: selections } = this;

      // ensure we are ready
      if (!selections) {
        return;
      }

      const fetch = fetchData({
        commentColumns,
        requestOptions,
        selections,
        themeSegmentSelection: this.filterStore.segmentQueryStrings
      });
      this.latestFetch = fetch;

      try {
        const result = await fetch;
        if (!result || fetch !== this.latestFetch) {
          return;
        }
        const [baselineData, comparisonData] = result;

        // apply sig diffs
        statistics.create_sig_diff_values(comparisonData, baselineData);
        statistics.create_sig_diff_values(baselineData, comparisonData);

        this.baselineData = baselineData;
        this.comparisonData = comparisonData;
      } catch (e) {
        this.errored = true;
      } finally {
        if (fetch === this.latestFetch) {
          this.loading = false;
          this.analysisToolsUIStore.setExploreToolLoading(this.loading);
        }
      }
    },
  },
};
