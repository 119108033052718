import * as React from 'react';
import { AnswersDataSet, AnswersFilters, TranslatedFilter } from 'api/interfaces';
import { translateFilters } from '../utils/getTranslatedFilters';
import { useAnswersContext } from '../utils/context';

export const useSingleFilters = (answerFilters: AnswersFilters, dataSets: AnswersDataSet[]) => {
  const { analysisFilters } = useAnswersContext();
  const [translatedFilters, setTranslatedFilters] = React.useState<TranslatedFilter[]>([]);

  React.useEffect(() => {
    const filters = translateFilters(answerFilters, dataSets, analysisFilters);

    setTranslatedFilters(filters);
  }, [answerFilters, dataSets, analysisFilters]);

  return translatedFilters;
};
