import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Workflow from 'components/Workflows/Workflow';
import CreateWorkflowImage from 'images/create-workflow.svg';
import analytics from 'lib/analytics';
import { isEmpty, sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Header, Loader, Menu, Message, Segment, Table } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { WorkflowsStoreInterface } from 'stores/WorkflowsStore';
import './workflows.scss';

export interface WorkflowsParams {
  orgId: string;
}
export interface WorkflowsProps extends RouteComponentProps<WorkflowsParams> {
  workflowsStore: WorkflowsStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

@inject('workflowsStore', 'organizationStore')
@observer
export default class Workflows extends React.Component<WorkflowsProps> {

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  componentDidMount() {
    this.initialize();
  }
  initialize = async () => {
    await this.fetchData();
  }
  fetchData = async () => {
    await this.props.workflowsStore.fetchWorkflows();
  }

  createWorkflow = () => {
    analytics.track('Workflows: Create Workflow', {category: 'Workflows'});
    const url = `/c/${this.orgId}/workflow/create`;

    this.props.history.push(url);
  };

  isThematicAdmin = () => {
    // currently this permission is restricted to Thematic Admins (they are the only ones with manage:internalResource)
    return this.props.organizationStore!.getCanAction('manage:internalResource');
  };

  render() {
    const { workflowsStore: store } = this.props;
    const {
      fetching,
      workflows,
      fetchWorkflowsErroredMessage,
      deleteWorkflowErroredMessage,
      updateWorkflowErroredMessage
    } = store;
    const isThematicAdmin = this.isThematicAdmin();

    let body;

    if (store.fetching) {
      body = (
      <Table.Row className="loading">
          <Table.Cell textAlign="center">
            <Loader inline={true} size="small" active={fetching}>
              Fetching workflows&hellip;
            </Loader>
          </Table.Cell>
        </Table.Row>
      );
    } else if (isEmpty(workflows)) {
      body = (
        <Table.Row className="create-workflow">
          <Table.Cell textAlign="center">
            <div
              className="create-workflow-image"
              onClick={this.createWorkflow}
            >
              <CreateWorkflowImage />
            </div>
            <p className="create-workflow-title">No workflows created yet</p>
            <p className="create-workflow-description">There doesn't seem to be anything here</p>
            <Button
              type="button"
              onClick={this.createWorkflow}
            >
              <FontAwesomeIcon
                className="icon"
                icon="plus"
                fixedWidth={true}
              />
              Create your first workflow
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    } else {
      let data = sortBy(workflows, 'name');
      body = data!.map(workflow => {
        return (
          <Workflow
            key={workflow.id}
            orgId={this.orgId}
            workflow={workflow}
            showVisibility={isThematicAdmin}
          />
        );
      });
    }

    return (
      <Segment className="workflows">
        <Header>Manage Workflows</Header>
        {fetchWorkflowsErroredMessage && (
          <Message negative={true} icon={true}>
            <FontAwesomeIcon
              className="icon"
              icon="exclamation-triangle"
              fixedWidth={true}
            />
            <Message.Content>
              <Message.Header>
                Uh oh! Something went wrong checking your workflows.
              </Message.Header>
              <p>Please refresh the page to try again.</p>
            </Message.Content>
          </Message>
        )}
        {!!deleteWorkflowErroredMessage && (
          <Message
            negative={true}
            icon={true}
          >
            <FontAwesomeIcon
              className="icon"
              icon="exclamation-triangle"
              fixedWidth={true}
            />
            <Message.Content>
              <p>{deleteWorkflowErroredMessage}</p>
            </Message.Content>
          </Message>
        )}
        {!!updateWorkflowErroredMessage && (
          <Message
            negative={true}
            icon={true}
          >
            <FontAwesomeIcon
              className="icon"
              icon="exclamation-triangle"
              fixedWidth={true}
            />
            <Message.Content>
              <p>{updateWorkflowErroredMessage}</p>
            </Message.Content>
          </Message>
        )}
        <Segment className="white">
          <Menu secondary={true}>
            <Menu.Item fitted={true}>
              <Button
                color="blue"
                className="nw--add-user"
                disabled={!!fetching}
                onClick={this.createWorkflow}
              >
                <FontAwesomeIcon
                  className="icon"
                  fixedWidth={true}
                  icon="plus"
                />
                Create Workflow
              </Button>
            </Menu.Item>
          </Menu>

          <Table
            unstackable={true}
            fixed={true}
            sortable={true}
            className="workflows-grid"
          >
            <Table.Header>
              <Table.Row className={isThematicAdmin ? 'active-visibility-cell' : 'not-active-visibility-cell'}>
                <Table.HeaderCell>
                  Active
                </Table.HeaderCell>
                {isThematicAdmin &&
                  <Table.HeaderCell>
                    Visibility
                  </Table.HeaderCell>
                }
                <Table.HeaderCell className="workflows-name-cell">
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell>&nbsp;
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{body}</Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
}
