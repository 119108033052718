import * as React from 'react';
import './bar-difference-normalized.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';

interface Props {
  baseline: number;
  baselineWidth: number;
  comparison: number;
  comparisonWidth: number;
  suffix: string;
  dp: string;
}

function format(value: number, precision: string | number, suffix: string) {
  const precisionNumber = typeof precision === 'string' ? parseInt(precision, 10) : precision;
  if (precisionNumber === 0) {
    return `${Math.round(value)}${suffix}`;
  }
  return `${value.toFixed(precisionNumber)}${suffix}`;
}

const BarDifferenceNormalized = withCamelCaseProps(({
  baseline,
  baselineWidth,
  comparison,
  comparisonWidth,
  suffix,
  dp,
}: Props) => {
  const baselineLabel = format(baseline, dp, suffix);
  const comparisonLabel = format(comparison, dp, suffix);

  return (
    <div
      className="bar-difference-normalized"
      aria-label="percentage-bar"
    >
      <svg
        className="bar-difference-normalized__indicator"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 32"
      >
        <defs>
          <linearGradient id="overall">
            <stop offset="0%" stopColor={'var(--primary-400)'} />
            <stop offset="100%" stopColor={'var(--primary-300)'} />
          </linearGradient>
          <linearGradient id="comparison">
            <stop offset="0%" stopColor={'var(--orange-400)'} />
            <stop offset="100%" stopColor={'var(--orange-200)'} />
          </linearGradient>
        </defs>
        <rect
          width={`${baselineWidth}%`}
          x="0"
          y="5"
          rx="0"
          ry="0"
          height="10"
          fill="url(#overall)"
        />
        <rect
          width={`${comparisonWidth}%`}
          x="0"
          y="17"
          rx="0"
          ry="0"
          height="10"
          fill="url(#comparison)"
        />
      </svg>
      <div
        className="bar-difference-normalized__label bar-difference-normalized__label--baseline"
        aria-label="baseline value"
      >{baselineLabel}</div>
      <div
        className="bar-difference-normalized__label bar-difference-normalized__label--comparison"
        aria-label="comparison value"
      >{comparisonLabel}</div>
    </div>
  );
});

export { BarDifferenceNormalized };
