<template>
  <el-select
    v-model="selectedSources"
    multiple
    @change="onSourcesChange"
  >
    <el-option
      v-for="item in sourceOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :title="isSourceUsed(item.value) ? noAccessMessage : null"
      :disabled="isSourceUsed(item.value)"
    />
  </el-select>
</template>


<script>
import { getAnalysisToolsStore, getActiveDashboardUIStore, getSurveyStore } from 'stores/RootStore';
import { findKey, isEmpty, omitBy } from 'lodash';
import { getAnalysisId } from 'stores/utils/analysis-helper';

export default {
  name: 'ConfigureDashboardSources',
  data() {
    return {
      analysisToolsStore: getAnalysisToolsStore(),
      activeDashboardUIStore: getActiveDashboardUIStore(),
      surveyStore: getSurveyStore(),

      sourceOptions: [],
      selectedSources: [],

      noAccessMessage: 'This source cannot be removed as it is in use by 1 or more widgets.',
      restrictedDatasetLabel: 'Restricted dataset',
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // Get list of available sources from analysis
      this.availableSources = this.getAvailableSources();

      // Make source dropdown options from analysis list with value as analysis id and label as analysis title
      this.sourceOptions = this.getSourceOptions();

      // Pre-select existing sources if any
      this.selectedSources = this.getExistingSources();

      await this.surveyStore.fetchSurveys();
    },
    getAvailableSources() {
      // Get flattened analysis list, makes a key value pair with key as
      // <surveyid-viewid-visualizationid> and value as the analysis
      let sources = {};

      this.analysisToolsStore.flat.forEach((analysis) => {
        const { title, surveyId: survey, viewId: view, visualizationId: visualization } = analysis;
        const sourceId = getAnalysisId({ survey, view, visualization });
        sources[sourceId] = { title, survey, view, visualization };
      });

      // TODO: PORTAL-3310 Get aggregate sources

      return sources;
    },
    getSourceOptions() {
      let sourceOptions = [];
      const surveys = this.surveyStore.surveysJson || [];

      for (const analysisId in this.availableSources) {
        const source = this.availableSources[analysisId];
        let indexOfSurvey = surveys.findIndex((survey) => survey.id === source.survey);
        if (source) {
          sourceOptions.push({
            label: indexOfSurvey !== -1 ? `${surveys[indexOfSurvey].name}: ${source.title}` : source.title,
            value: analysisId,
          });
        }
      }

      return sourceOptions;
    },
    getExistingSources() {
      const selectedSources = [];

      const { sourcesWithAnalysisId } = this.activeDashboardUIStore;
      for (const sourceKey in sourcesWithAnalysisId) {
        const source = sourcesWithAnalysisId[sourceKey];
        if (source) {
          // If a source exists thats not in available sources, it is possible an
          // inaccessbile dataset which we cant get any info
          const isRestricted = !Object.keys(this.availableSources).includes(source.analysisId);
          if (isRestricted) {
            selectedSources.push(this.restrictedDatasetLabel);
          } else {
            selectedSources.push(source.analysisId);
          }
        }
      }

      return selectedSources;
    },
    getRestrictedSources() {
      const restrictedSources = {};

      const { sourcesWithAnalysisId } = this.activeDashboardUIStore;
      for (const sourceKey in sourcesWithAnalysisId) {
        const source = sourcesWithAnalysisId[sourceKey];
        if (source) {
          const isRestricted = !Object.keys(this.availableSources).includes(source.analysisId);
          if (isRestricted) {
            restrictedSources[sourceKey] = source;
          }
        }
      }

      return restrictedSources;
    },
    onSourcesChange() {
      const { sources, sourcesWithAnalysisId } = this.activeDashboardUIStore;
      // Get restricted sources as we won't have that information in the selected sources
      const updatedSources = { ...this.getRestrictedSources() };

      this.selectedSources.forEach((analysisId) => {
        // We have restricted data sources above
        if (analysisId === this.restrictedDatasetLabel) {
          return;
        }
        const sourceKey = findKey(sourcesWithAnalysisId, { analysisId });
        if (sourceKey) {
          // existing source, so use existing key (ex. default) so we don't break existing
          updatedSources[sourceKey] = sources[sourceKey];
        } else {
          // new source, use analysis id as key
          const source = this.availableSources[analysisId];
          if (source) {
            updatedSources[analysisId] = omitBy(
              {
                survey: source.survey,
                view: source.view,
                visualization: source.visualization,
              },
              isEmpty,
            );
          }
        }
      });

      const areSourcesValid = !isEmpty(this.selectedSources);

      this.$emit('onSourcesUpdate', updatedSources, areSourcesValid);
    },
    isSourceUsed(analysisId) {
      const { sourcesInUse } = this.activeDashboardUIStore;
      const isSourceUsed = !!findKey(sourcesInUse, { analysisId: analysisId });
      return isSourceUsed;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.el-select__tags {
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .el-tag.el-tag--info {
  color: $--neutral-900;
}

// Don't show option to remove an option from the input box as it needs a check
::v-deep .el-tag__close.el-icon-close {
  display: none;
}
</style>