import * as React from 'react';
import { ThemesObject } from 'lib/filters/theme-filter-helper';
import { Transform } from 'components/ThemeEditor/theme-file-transforms';
import { AddThemeToSelection } from './useAddThemeToSelection';
import { ThreadDisplayOptions } from 'api/interfaces';
import { EditedComments } from 'stores/ui/QuickEditUIStore';

interface SimilarSentencesContext {
  addThemeToSelection: AddThemeToSelection | null;
  areSupportMessagesVisible: boolean;
  canManageThemes: boolean;
  editedComments: EditedComments | null;
  isApplyingThemes: boolean;
  hasSentiment: boolean;
  orgId: string;
  themesHierarchy: ThemesObject | null;
  shouldShowFullMessages: boolean;
  surveyId: string;
  threadDisplayConfig: ThreadDisplayOptions | null;
  transforms: Transform[];
}

const SimilarSentencesContext = React.createContext<SimilarSentencesContext>({
  addThemeToSelection: null,
  areSupportMessagesVisible: false,
  canManageThemes: false,
  editedComments: null,
  hasSentiment: false,
  isApplyingThemes: false,
  orgId: '',
  shouldShowFullMessages: false,
  surveyId: '',
  themesHierarchy: null,
  threadDisplayConfig: null,
  transforms: [],
});

export function useSimilarSentencesContext() {
  const context = React.useContext(SimilarSentencesContext);

  if (!context) {
    throw new Error('useSimilarSentencesContext must be used within SimilarSentencesProvider');
  }

  return context;
}

export const SimilarSentencesProvider = SimilarSentencesContext.Provider;
