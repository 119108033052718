import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { ExportAssistantCategoryData } from './ExportAssistantCategoryData';
import { Column, Columns, DimensionsByCategoryConfig } from './types';

interface Props {
  columns: Column[];
  tableConfig: DimensionsByCategoryConfig;
}

const ExportAssistantDimensionByCategoryTable = ({ columns, tableConfig }: Props) => {
  const { columnOneTitle } = tableConfig;
  const showCount = columns.includes(Columns.count);
  const showImpact = columns.includes(Columns.impact);
  const showScore = columns.includes(Columns.score);
  const showSentimentCounts = columns.includes(Columns.sentimentCounts);
  const showVolume = columns.includes(Columns.volume);

  const headerColumnWidth = showSentimentCounts ? columns.length + 2 : columns.length;

  if (!tableConfig.tableData) {
    return null;
  }

  return (
    <div className="export-assistant__table">
      <div className="export-assistant-category-table">
        <Table size="small" compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="1">{tableConfig.filterTitle}</Table.HeaderCell>
              {tableConfig.categoryLabels?.map((categoryLabel) => (
                <Table.HeaderCell key={`${categoryLabel}`} colSpan={headerColumnWidth}>
                  {categoryLabel}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              {columnOneTitle && <Table.HeaderCell>{columnOneTitle}</Table.HeaderCell>}
              {tableConfig.categoryLabels?.map((categoryLabel) => (
                <React.Fragment key={categoryLabel}>
                  {showCount && <Table.HeaderCell>Count</Table.HeaderCell>}
                  {showVolume && <Table.HeaderCell>Volume</Table.HeaderCell>}
                  {showImpact && <Table.HeaderCell>Impact</Table.HeaderCell>}
                  {showScore && <Table.HeaderCell>Score</Table.HeaderCell>}
                  {showSentimentCounts && (
                    <>
                      <Table.HeaderCell>Positive</Table.HeaderCell>
                      <Table.HeaderCell>Neutral</Table.HeaderCell>
                      <Table.HeaderCell>Negative</Table.HeaderCell>
                    </>
                  )}
                </React.Fragment>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.entries(tableConfig.tableData).map(([dimensionName, dimensionValues]) => {
              return (
                <React.Fragment key={dimensionName}>
                  <Table.Row>
                    <Table.Cell className="export-assistant-category-table__row-name">{dimensionName}</Table.Cell>
                    {dimensionValues.map((dimensionData, index) => {
                      return (
                        <ExportAssistantCategoryData
                          key={`${dimensionName}-${tableConfig.categoryLabels?.[index]}`}
                          columns={columns}
                          data={dimensionData}
                        />
                      );
                    })}
                  </Table.Row>
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export { ExportAssistantDimensionByCategoryTable }
