
import auth from 'Auth/Auth';
import { PaginatedList } from 'api/interfaces';
import { CreateWorkflowPartial, RunId, Workflow, WorkflowHistory, WorkflowId, WorkflowRunDetails } from 'api/workflow-interfaces';
import { action, observable, reaction } from 'mobx';
import { stringify } from 'query-string';
import { OrganizationStoreInterface } from './OrganizationStore';

interface WorkflowHistoryPage {
  items: WorkflowHistory[];
  page: number;
  totalPages: number;
}
export interface WorkflowsStoreInterface {
  clearErrors: () => void;

  fetchWorkflows: () => Promise<void>;
  createWorkflow: (workflow: CreateWorkflowPartial) => Promise<object | undefined>;
  updateWorkflow: (workflowId: WorkflowId, workflow: CreateWorkflowPartial) => Promise<object | undefined>;
  deleteWorkflow: (workflowId: WorkflowId) => Promise<void>;

  fetchWorkflowHistory: (workflowId: WorkflowId, page: number, refresh: boolean) =>
    Promise<WorkflowHistoryPage | undefined>;

  getWorkflow: (workflowId: WorkflowId) => Workflow | undefined;
  fetchWorkflowRunDetails: (workflowId: WorkflowId, runId: RunId) => Promise<WorkflowRunDetails | undefined>;
  fetchWorkflowDetails: (workflowId: WorkflowId) => Promise<Workflow | undefined>;
  fetchWorkflowResults: (workflowId: WorkflowId, runId: RunId) => Promise<{ data: string, filename: string } | undefined>;

  manuallyTriggerWorkflow: (workflowId: WorkflowId) => Promise<void>;

  getCurrentWorkflowHistoryPage: (workflowId: WorkflowId) => WorkflowHistoryPage | undefined;

  fetching: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
  fetchingHistory: boolean;
  fetchingRunDetails: boolean;
  fetchingWorkflowDetails: boolean;
  fetchWorkflowDetailsErroredMessage: string | null;
  fetchWorkflowsErroredMessage?: string;
  createWorkflowErroredMessage?: string;
  updateWorkflowErroredMessage?: string;
  deleteWorkflowErroredMessage?: string;
  fetchWorkflowHistoryErroredMessage?: string;
  fetchRunDetailsErroredMessage?: string;
  fetchingWorkflowResultsError?: string;

  manuallyTriggering: boolean;
  manualTriggerErrorMessage?: string;

  workflows: Workflow[];
  workflowHistoryStore: { [key: string]: WorkflowHistoryPage };
}

class WorkflowsStore {
  currentOrg: OrganizationStoreInterface;

  @observable
  fetching = false;

  @observable
  creating = false;

  @observable
  updating = false;

  @observable
  deleting = false;

  @observable
  fetchingHistory = false;

  @observable
  fetchingRunDetails = false;

  @observable
  fetchingWorkflowDetails = false;

  @observable
  fetchWorkflowDetailsErroredMessage: string | null = null;

  @observable
  manuallyTriggering = false;

  @observable
  workflows: Workflow[] = [];
  workflowsFetched = false;

  @observable
  fetchWorkflowsErroredMessage?: string = undefined;

  @observable
  createWorkflowErroredMessage?: string = undefined;

  @observable
  updateWorkflowErroredMessage?: string = undefined;

  @observable
  deleteWorkflowErroredMessage?: string = undefined;

  @observable
  fetchWorkflowHistoryErroredMessage?: string = undefined;

  @observable
  fetchRunDetailsErroredMessage?: string = undefined;

  @observable
  fetchingWorkflowResultsError?: string = undefined;

  @observable
  manualTriggerErrorMessage?: string = undefined;

  @observable
  workflowHistoryStore: { [key: string]: WorkflowHistoryPage } = {};

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;

    // React to orgId changes
    reaction(
      () => this.currentOrg.orgId,
      () => this.reset(),
      { fireImmediately: true }
    );
  }

  @action
  clearErrors = () => {
    this.fetchWorkflowsErroredMessage = undefined;
    this.createWorkflowErroredMessage = undefined;
    this.updateWorkflowErroredMessage = undefined;
    this.deleteWorkflowErroredMessage = undefined;
    this.fetchWorkflowHistoryErroredMessage = undefined;
    this.fetchRunDetailsErroredMessage = undefined;
    this.manualTriggerErrorMessage = undefined;
  };

  @action
  fetchWorkflows = async () => {
    if (this.workflowsFetched) {
      return;
    }
    const { orgIdQueryParam } = this.currentOrg;
    const url = `/workflows?${ orgIdQueryParam }`;

    const check = auth.fetch<Workflow[]>(url);

    this.fetching = true;
    this.fetchWorkflowsErroredMessage = undefined;

    try {
      const { ok, data: results } = await check;
      if (ok && results) {
        this.workflows = results;
        this.workflowsFetched = true;
      }
    } catch (e) {
      this.fetchWorkflowsErroredMessage = `Failed to retrieve workflows: ${ e }`;
    } finally {
      this.fetching = false;
    }
  };

  @action
  createWorkflow = async (workflowDetails: CreateWorkflowPartial) => {
    const { orgId } = this.currentOrg;

    this.creating = true;
    this.createWorkflowErroredMessage = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch<Workflow>(
        '/workflow',
        {
          method: 'POST',
          body: JSON.stringify({ ...workflowDetails, organization: orgId })
        }
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      // add the workflow to the list
      this.workflows.push(data);

      return data;
    } catch (e) {
      this.createWorkflowErroredMessage = `Failed to create workflow: ${ e }`;
    } finally {
      this.creating = false;
    }

    return undefined;
  };

  @action
  updateWorkflow = async (workflowId: WorkflowId, workflowDetails: CreateWorkflowPartial) => {

    this.updating = true;
    this.updateWorkflowErroredMessage = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch<Workflow>(
        `/workflow/${ workflowId }`,
        {
          method: 'PUT',
          body: JSON.stringify({ ...workflowDetails })
        }
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      // add the workflow to the list
      let workflows = this.workflows.filter(workflow => workflow.id !== workflowId);
      workflows.push(data);

      this.workflows = workflows;

      return data;
    } catch (e) {
      this.updateWorkflowErroredMessage = `Failed to update workflow: ${ e }`;
    } finally {
      this.updating = false;
    }

    return undefined;
  };

  @action
  deleteWorkflow = async (workflowId: WorkflowId) => {
    this.deleting = true;
    this.deleteWorkflowErroredMessage = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch<Workflow>(
        `/workflow/${ workflowId }`,
        {
          method: 'DELETE'
        }
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      // remove the workflow from the list
      this.workflows = this.workflows.filter(workflow => workflow.id !== workflowId);
    } catch (e) {
      this.deleteWorkflowErroredMessage = `Failed to delete workflow: ${ e }`;
    } finally {
      this.deleting = false;
    }
  };

  @action
  fetchWorkflowHistory = async (workflowId: string, page: number, refresh: boolean) => {
    // if we already have the history, break out
    if (this.workflowHistoryStore[workflowId] && !refresh) {
      return this.workflowHistoryStore[workflowId];
    }

    this.fetchingHistory = true;
    this.fetchWorkflowHistoryErroredMessage = undefined;

    const query = stringify({
      page: page
    });

    try {
      const { data, ok, errorData } = await auth.fetch<PaginatedList<WorkflowHistory>>(
        `/workflow/${ workflowId }/history?${ query }`
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      this.workflowHistoryStore[workflowId] = {
        items: data.items,
        page: data.page,
        totalPages: Math.ceil(data.total_items / data.page_len)
      };
      return this.workflowHistoryStore[workflowId];
    } catch (e) {
      this.fetchWorkflowHistoryErroredMessage = `Failed to retrieve workflow history: ${ e }`;
    } finally {
      this.fetchingHistory = false;
    }
    return undefined;
  };

  @action
  fetchWorkflowRunDetails = async (workflowId: WorkflowId, runId: RunId) => {
    this.fetchingRunDetails = true;
    this.fetchRunDetailsErroredMessage = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch<WorkflowRunDetails>(
        `/workflow/${ workflowId }/run/${ runId }/details`
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      if (data.result['error']) {
        this.fetchRunDetailsErroredMessage = data.result['error'];
      }
      return data;
    } catch (e) {
      this.fetchRunDetailsErroredMessage = `Failed to retrieve details: ${ e }`;
    } finally {
      this.fetchingRunDetails = false;
    }
    return undefined;
  }

  @action
  fetchWorkflowDetails = async (workflowId: WorkflowId) => {
    this.fetchingWorkflowDetails = true;
    this.fetchWorkflowDetailsErroredMessage = null;
    try {
      const { data, ok, errorData } = await auth.fetch<Workflow>(
        `/workflow/${ workflowId }`
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      return data;
    } catch (e) {
      this.fetchWorkflowDetailsErroredMessage = `Failed to retrieve details: ${ e }`;
    } finally {
      this.fetchingWorkflowDetails = false;
    }
    return undefined;
  }

  @action
  fetchWorkflowResults = async (workflowId: WorkflowId, runId: RunId) => {
    this.fetchingWorkflowResultsError = undefined;
    try {
      const response = await auth.fetch<string>(
        `/workflow/${ workflowId }/run/${ runId }/results/csv`, { isText: true }
      );
      if (typeof response.data !== 'undefined') {
        const filename = (response.headers && response.headers.get('Content-Disposition')) || 'results.csv';
        return {
          data: response.data,
          filename: filename.replace('attachment; filename=', '')
        };
      } else {
        const errorMessage = response.errorData ? response.errorData.message : 'Please try again or contact support';
        throw new Error(errorMessage);
      }

    } catch (e) {
      this.fetchingWorkflowResultsError = `We couldn't retrieve results: ${ e.message }`;
    }
    return undefined;
  }

  manuallyTriggerWorkflow = async (workflowId: WorkflowId) => {
    this.manuallyTriggering = true;
    this.manualTriggerErrorMessage = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch<void>(
        `/workflow/${ workflowId }/trigger`,
        {
          method: 'POST'
        }
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        throw new Error(errorMessage);
      }
      return data;
    } catch (e) {
      this.manualTriggerErrorMessage = `Failed to trigger run: ${ e }`;
    } finally {
      this.manuallyTriggering = false;
    }
    return undefined;
  }

  getWorkflow = (workflowId: WorkflowId) => {
    return this.workflows.find(s => s.id === workflowId);
  };

  getCurrentWorkflowHistoryPage = (workflowId: WorkflowId) => {
    return this.workflowHistoryStore[workflowId];
  };

  reset() {
    this.fetching = false;
    this.creating = false;
    this.fetchingHistory = false;
    this.deleting = false;

    this.fetchWorkflowsErroredMessage = undefined;
    this.createWorkflowErroredMessage = undefined;
    this.deleteWorkflowErroredMessage = undefined;
    this.fetchWorkflowHistoryErroredMessage = undefined;

    this.createWorkflowErroredMessage = undefined;
    this.workflows = [];
    this.workflowHistoryStore = {};
    this.workflowsFetched = false;
  }

}

export default WorkflowsStore;
