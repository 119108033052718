import { BillingHiddenDatasetUsage } from 'api/interfaces';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import './usage-in-hidden-datasets.scss';

type Props = {
  datasetsHidden: BillingHiddenDatasetUsage
};

const UsageInHiddenDatasets = ({ datasetsHidden }: Props): JSX.Element | null => {

  return <div className="usage-in-hidden">
    <h3>Some details are in workspaces you can't access</h3>
    <Table
      fixed={true}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell
            width={2}> Count
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Datasets</Table.Cell>
          <Table.Cell>{datasetsHidden.count.toLocaleString()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Responses</Table.Cell>
          <Table.Cell>{datasetsHidden.verbatims.toLocaleString()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Translations</Table.Cell>
          <Table.Cell>{datasetsHidden.translations.toLocaleString()}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>;

};

export default UsageInHiddenDatasets;
