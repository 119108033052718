import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import './saved-filters-panel.scss';
import { Segment } from 'api/interfaces';

interface SavedFiltersPanelProps {
  selectedSavedFilter: Segment | undefined;
  isDirty: boolean;
  onFilterSelected: () => void;
  onAllFiltersReset: () => void;
  onUpdateClick: () => void;
}

class SavedFiltersPanel extends React.Component<SavedFiltersPanelProps, {}> {

  render() {
    const {
      selectedSavedFilter,
      isDirty,
      onUpdateClick,
      onFilterSelected,
      onAllFiltersReset
    } = this.props;

    return (
      <div className="filter-saved-filters-panel">
        <div
          className="saved-filters-button plain"
          onClick={onFilterSelected}
        >
          <span className="saved-filter-panel-content">
            {selectedSavedFilter ? (
              <span
                className={classnames(
                  { 'saved-filters-can-update': isDirty },
                  'saved-filters-update',
                )}
              >
                <span
                  role="button"
                  className="clear-selected-saved-filter"
                  onClick={onAllFiltersReset}
                >
                  <FontAwesomeIcon icon="times" />
                </span>
                <span className="saved-filter-title">{selectedSavedFilter.name}</span>
                {isDirty && (
                  <Button className="filter-button" size="small" onClick={onUpdateClick}>
                    Update
                  </Button>
                )}
              </span>
            ) : (
              'Saved filters'
            )}
          </span>
          <FontAwesomeIcon icon="chevron-right" />
        </div>
      </div>
    );
  }
}

export default SavedFiltersPanel;
