import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeQualityDetails } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Header, Message, Modal, Table } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import toFixed from 'vue/libs/to-fixed';
import './score-theme-quality.scss';

export interface ScoreThemeQualityProps {
  themesStore?: ThemesStoreInterface;

  open: boolean;
  surveyId: string;

  cancel: () => void;
}
export interface ScoreThemeQualityState {
  results?: ThemeQualityDetails;
}

@inject('themesStore')
@observer
export default class ScoreThemeQuality extends React.Component<
ScoreThemeQualityProps,
ScoreThemeQualityState
> {
  abortController = new AbortController();
  state = {
    results: undefined,
  } as ScoreThemeQualityState;

  componentDidMount() {
    this.evaluateThemes();
  }

  componentDidUpdate(prevProps: ScoreThemeQualityProps) {
    const { open } = this.props;
    const { open: wasOpen } = prevProps;
    if (open && !wasOpen) {
      this.evaluateThemes();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  evaluateThemes = async () => {
    const { themesStore, surveyId } = this.props;

    const results = await themesStore!.evaluateThemes(surveyId, this.abortController.signal);

    if (results) {
      this.setState({ results });
    }
  }

  close = () => {
    this.props.cancel();
  };

  render() {
    const { open, themesStore } = this.props;
    const { results } = this.state;
    const {
      evaluatingThemeQuality,
      evaluatingThemeQualityError,
    } = themesStore!;

    return (
      <div>
        <Modal
          open={open}
          size="small"
          dimmer="blurring"
          className="score-theme-quality-modal"
        >
          <Modal.Header as={Header}>Theme Quality</Modal.Header>
          <Modal.Content style={{ minHeight: '150px' }}>
            {evaluatingThemeQualityError &&
              <Message negative={true}>
                <Message.Content>
                  <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
                  Error evaluating theme quality, please try again.
                </Message.Content>
              </Message>
            }
            {!evaluatingThemeQualityError && !evaluatingThemeQuality && results &&
              <>
                <div className="score-theme-quality-overall">
                  Overall quality: <span>{toFixed(results.overallScore, 0, '%')}</span>
                </div>

                <Table className="score-theme-quality-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={16} />
                      <Table.HeaderCell width={2}>
                        Score
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        Result
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {results.scores.map(score => {
                      return (<Table.Row key={score.name}>
                        <Table.Cell width={16}>
                          <div className="theme-quality-score-name">
                            {score.name}
                          </div>
                          <div className="theme-quality-score-description">
                            {score.description} (Good is between {score.expectedRange[0]} and {score.expectedRange[1]})
                          </div>
                        </Table.Cell>
                        <Table.Cell width={2}>
                          {toFixed(score.value, 0, '%')}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          {score.level}
                        </Table.Cell>
                      </Table.Row>);
                    })}
                  </Table.Body>
                </Table>

                {results.recommendations.length > 0 && (
                  <div>
                    Recommendations:
                    <ul>
                      {results.recommendations.map(recommendation => {
                        return (<li key={recommendation}>{recommendation}</li>);
                      })}
                    </ul>
                  </div>
                )}
              </>
            }
            {evaluatingThemeQuality &&
              <FontAwesomeIcon
                className="icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
              />
            }
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.close}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
