import * as React from 'react';
import lineGraph from 'charts/Utils/lineGraph';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { useResizeObserver } from 'hooks/useResizeObserver';

interface Props {
  values: number[][];
  labels: string[];
}

const MultiLineGraph = withCamelCaseProps((props: Props) => {
  const { values, labels } = props
  const multiLineGraphRef = React.useRef<SVGSVGElement>(null);
  const [containerRef, dimensions] = useResizeObserver<HTMLDivElement>();

  const lowest = Math.min(...values.flat());
  const low = lowest > 0 ? 0 : lowest;

  const highest = Math.max(...values.flat());
  const high = highest < 0 ? 0 : highest;

  const series = values.slice(0, 3).map((data: number[]) => ({ data }));

  const renderSvg = React.useCallback(({ width, height }) => {
    if (!containerRef.current || !multiLineGraphRef.current || values.length === 0) {
      return;
    }

    const baselineValues = series[0].data;
    const comparisonValues = series[1]?.data;

    const chartOptions = {
      containerHeight: height,
      containerWidth: width,
      labels,
      low,
      high,
      baselineValues,
      comparisonValues,
    };

    lineGraph.renderLineGraph({
      el: multiLineGraphRef.current,
      chartOptions,
    });

  }, [containerRef, high, low, values.length, series, labels]);

  React.useEffect(() => {
    renderSvg(dimensions);
  }, [dimensions, renderSvg]);

  return (
    <div ref={containerRef} className="multi-line-graph">
      <svg ref={multiLineGraphRef} />
    </div>
  );
});

export { MultiLineGraph };
