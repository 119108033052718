import { reduceNodes } from 'lib/node-helpers';
import { AnswerNode } from 'types/custom';

export function getFirstOutputItem(nodes: AnswerNode[]): AnswerNode['item']  | null {
  return reduceNodes((result: null | AnswerNode['item'], node: AnswerNode) => {
    if (result) {
      return result;
    }
    return node.item.state === 'output' ? node.item : null;
  }, nodes, null);

}
