import * as React from 'react';
import './dataset-status-label.scss';

type Props = {
  status: string
};

const DataSetStatusLabel = ({ status }: Props): JSX.Element | null => {

  if (status === 'normal') {
    return <div className="dataset-status-label label--green">Active</div>;
  } else if (status === 'needs_review') {
    return <div className="dataset-status-label label--grey">In Review</div>;
  } else if (status === 'deleted') {
    return <div className="dataset-status-label label--red">Deleted</div>;
  }

  return null;

};

export default DataSetStatusLabel;
