import Auth from 'Auth/Auth';
import {
  PageRequestParams,
  PaginatedList,
  PermissionJson,
  PolicyJson,
  RoleDetailsJson,
  RoleJson,
  SurveyPermissionJson
} from 'api/interfaces';
import FileSaver from 'file-saver';
import { isEqual, remove } from 'lodash';
import { action, computed, observable } from 'mobx';
import { stringify } from 'query-string';
import { OrganizationStoreInterface } from './OrganizationStore';
import { mapEmptyPermissions, mapPermissionsToPolicy, mapSelectedPermissions } from './utils/roles';

const ROLES_PAGE_LENGTH = 25;
const ROLES_PAGE_PARAMS = {
  page: 1,
  page_len: ROLES_PAGE_LENGTH,
  sort_by: 'name',
  is_user_specific: false
} as RolesPageRequestParams;
export const EMPTY = 'empty';
export const TEMP_USER_SPECIFIC_ROLE_ID = 'temp_user_specific_role_id';
export const TEMP_ROLE_ID = 'temp_role_id';
export const DEFAULT_ROLE_DETAILS = {
  name: '',
  description: '',
  admin: [],
  reports: [],
  surveys: [],
  isPerReport: false,
  isPerSurvey: false
} as RoleDetails;

// Scopes to be shown to the user
export enum AdminScopes {
  'manageusers' = 'Manage users',
  'manageroles' = 'Manage roles',
  'manageintegrations' = 'Manage integrations',
  'manageWorkflows' = 'Manage workflows',
  'manageAnswers' = 'Manage Answers',
  'createSurvey' = 'Create datasets',
  'createReport' = 'Create dashboards',
  'readMetrics' = 'Access usage metrics',
}

export enum ReportScopes {
  'read' = 'View',
  'managereport' = 'Manage',
  'managesubscriptions' = 'Manage digests'
}

export enum SurveyScopes {
  'view' = 'Analysis',
  'answers' = 'Answers',
  'download' = 'Download',
  'manage' = 'Manage',
  'editthemes' = 'Manage themes',
  'upload' = 'Upload data',
}

export enum ViewScopes {
  'view' = 'Analysis',
  'answers' = 'Answers',
  'download' = 'Download'
}

// Each scope that we show the user has a one to many mapping, for example,
// `View` scope for survey means that they can view survey, themes &
// visualisations ('read:survey', 'read:themes', 'read:visualization')
export const SCOPES = {
  admin: {
    'manageusers': ['read:user', 'read:role', 'manage:user'],
    'manageroles': ['read:role', 'manage:role'],
    'manageintegrations': ['read:integration', 'manage:integration'],
    'createSurvey': ['manage:survey'],
    'createReport': ['manage:report'],
    'readMetrics': ['read:metrics'],
    'manageWorkflows': ['read:workflow', 'manage:workflow'],
    'manageAnswers': ['manage:answer']
  },
  reports: {
    'read': ['read:report'],
    'managereport': ['manage:report'],
    'managesubscriptions': ['manage:subscriptions']
  },
  surveys: {
    'view': ['read:survey', 'read:visualization', 'view:analysis'],
    'answers': ['read:survey', 'read:themes', 'read:visualization', 'create:answer'],
    'download': ['read:survey', 'download:result', 'read:result'],
    'editthemes': ['read:themes', 'manage:themes'],
    'upload': ['read:survey', 'create:upload', 'read:upload'],
    'manage': ['manage:survey']
  },
  views: {
    'view': ['read:survey', 'read:visualization', 'view:analysis'],
    'answers': ['read:survey', 'read:themes', 'read:visualization', 'create:answer'],
    'download': ['read:survey', 'read:visualization', 'download:result', 'read:result']
  }
};

export const SCOPE_ENABLE_MAPPING = {
  'download': ['view'],
  'editthemes': ['view'],
  'manage': ['view'],
  'managereport': ['read'],
  'managesubscriptions': ['read']
};

// Display name and value of a scope, eg. { name: 'Upload data', value: 'upload' }
export interface Scope {
  name: string;
  value: string;
}

export interface Scopes {
  admin: Scope[];
  reports: Scope[];
  surveys: Scope[];
  views: Scope[];
}

export interface RoleDetails {
  name: string;
  description: string;
  isPerReport: boolean;
  isPerSurvey: boolean;
  admin: string[];
  reports: PermissionJson[];
  surveys: SurveyPermissionJson[];
}

export interface RolesPageRequestParams extends PageRequestParams {
  is_user_specific?: boolean;
}

export interface RoleStoreInterface {
  pageParams: RolesPageRequestParams;

  fetchingRoles: boolean;
  fetchRolesError: string;

  fetchingRoleDetails: boolean;
  fetchRoleDetailsError: string;

  creatingRole: boolean;
  createRoleError: string;

  deletingRole: boolean;
  deleteRoleError: string;

  updatingRole: boolean;
  updateRoleError: string;

  creatingCustomPermissions: boolean;
  createCustomPermissionsError: string;

  fetchingEmptyPermissions: boolean;
  fetchEmptyPermissionsError: string;

  roles: RoleDetailsJson[];
  totalPages: number;
  totalRoles: number;

  roleDetails: {
    // Role id
    [key: string]: RoleDetails
  };
  emptyPermissions: RoleDetails;
  scopes: Scopes;
  adminScopes: string[];
  reportPermissions: PermissionJson[];
  surveyPermissions: SurveyPermissionJson[];
  reportScopes: Scope[];
  surveyScopes: Scope[];
  viewScopes: Scope[];

  fetchingPermissionsAudit: boolean;
  auditPermissionsError: string;

  setPageParams: (options: RolesPageRequestParams) => void;
  resetPageParams: () => void;

  fetchRoles: (pageLength?: number) => Promise<void>;
  fetchEmptyPermissions: () => Promise<void>;
  fetchRoleDetails: (roleId: string) => Promise<RoleDetailsJson>;

  createRole: (name: string, description: string, roleDetails: RoleDetails) => Promise<void>;
  deleteRole: (id: string) => Promise<void>;
  updateRole: (
    id: string,
    name: string,
    description: string,
    roleDetails: RoleDetails
  ) => Promise<void>;
  createCustomPermissions: (userEmail: string, roleDetails: RoleDetails) => Promise<RoleDetailsJson>;

  hasChanged: (roleId: string) => boolean;

  getSelectedReportScopes: (roleId: string, dashboardId: string) => string[];
  getSelectedSurveyScopes: (roleId: string, surveyId: string) => string[];
  getSelectedViewScopes: (roleId: string, surveyId: string, viewId: string) => string[];

  updateAdminPermissions: (roleId: string, scopes: string[]) => void;
  updateDashboardPermissions: (roleId: string, dashboardId: string, scopes: string[]) => void;
  updateSurveyPermissions: (roleId: string, surveyId: string, scopes: string[]) => void;
  updateViewPermissions: (roleId: string, surveyId: string, viewId: string, scopes: string[]) => void;

  updateIsPerReport: (roleId: string, isPerReport: boolean) => void;
  updateIsPerSurvey: (roleId: string, isPerReport: boolean) => void;

  reset: () => void;
  resetRoleState: (roleId: string) => void;
  removeRoleFromState: (roleId: string) => void;
  refreshPermissionsState: (roleDetails: RoleDetailsJson) => void;

  downloadPermissionsAudit: () => void;
}

class RoleStore implements RoleStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  fetchingRoles = false;

  @observable
  fetchRolesError = '';

  @observable
  fetchingRoleDetails = false;

  @observable
  fetchRoleDetailsError = '';

  @observable
  creatingRole = false;

  @observable
  createRoleError = '';

  @observable
  deletingRole = false;

  @observable
  deleteRoleError = '';

  @observable
  updatingRole = false;

  @observable
  updateRoleError = '';

  @observable
  creatingCustomPermissions = false;

  @observable
  createCustomPermissionsError = '';

  @observable
  fetchingEmptyPermissions = false;

  @observable
  fetchEmptyPermissionsError = '';

  @observable
  roleDetails = {};

  @observable
  roles = [] as RoleDetailsJson[];

  @observable
  totalPages = 0;

  @observable
  totalRoles = 0;

  @observable
  pageParams = { ...ROLES_PAGE_PARAMS };

  @observable
  fetchingPermissionsAudit = false;

  @observable
  auditPermissionsError = '';

  /*
    Display values for all scopes applicable to an organization
  */
  @observable
  scopes = {
    admin: Object.keys(AdminScopes).map(key => ({ name: AdminScopes[key], value: key } as Scope)),
    reports: Object.keys(ReportScopes).map(key => ({ name: ReportScopes[key], value: key } as Scope)),
    surveys: Object.keys(SurveyScopes).map(key => ({ name: SurveyScopes[key], value: key } as Scope)),
    views: Object.keys(ViewScopes).map(key => ({ name: ViewScopes[key], value: key } as Scope))
  } as Scopes;

  /*

  */
  get emptyPermissions() {
    return this.roleDetails[EMPTY];
  }

  /*
    List of admin scopes in an organization
  */
  @computed
  get adminScopes() {
    return (this.emptyPermissions || {}).admin;
  }

  /*
    List of reports in an organization and possible scopes
  */
  @computed
  get reportPermissions() {
    return (this.emptyPermissions || {}).reports;
  }

  /*
    List of surveys in an organization and possible scopes
  */
  @computed
  get surveyPermissions() {
    return (this.emptyPermissions || {}).surveys;
  }

  /*
    Display values for all scopes for reports
  */
  @computed
  get reportScopes() {
    return (this.scopes || {}).reports;
  }

  /*
    Display values for all scopes for surveys
  */
  @computed
  get surveyScopes() {
    return (this.scopes || {}).surveys;
  }

  /*
    Display values for all scopes for views
  */
  @computed
  get viewScopes() {
    return (this.scopes || {}).views;
  }

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;
  }

  @action
  setPageParams = (params: RolesPageRequestParams) => {
    this.pageParams = {
      ...this.pageParams,
      ...params
    };
  }

  @action
  resetPageParams = () => {
    this.pageParams = { ...ROLES_PAGE_PARAMS };
  }

  /*
    Fetches roles for a given organization
  */
  @action
  fetchRoles = async (pageLength?: number) => {
    this.fetchingRoles = true;
    this.fetchRolesError = '';
    this.totalPages = 0;
    this.setPageParams({ page_len: pageLength || ROLES_PAGE_LENGTH });

    const query = stringify({
      organization: this.currentOrg.orgId,
      ...this.pageParams
    });

    try {
      const { data, ok, errorData } = await Auth.fetch<PaginatedList<RoleDetailsJson>>(`/roles?${ query }`);
      if (ok && data) {
        const { items, total_items } = data;
        this.totalPages = Math.ceil(total_items / ROLES_PAGE_LENGTH);
        this.totalRoles = total_items;
        this.roles = items;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.fetchRolesError = `Failed to fetch roles: ${ e.message }`;
    } finally {
      const updatedQuery = stringify({
        organization: this.currentOrg.orgId,
        ...this.pageParams
      });
      // Check if there is a newer API call to continue showing loading in the event of debounce
      if (query === updatedQuery) {
        this.fetchingRoles = false;
      }
    }
  }

  /*
    Fetches empty permissions to show the list of available reports/surveys/view & scopes
  */
  @action
  fetchEmptyPermissions = async () => {
    if (this.emptyPermissions) {
      return;
    }

    this.fetchingEmptyPermissions = true;
    this.fetchEmptyPermissionsError = '';

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { data, ok, errorData } = await Auth.fetch<PolicyJson>(`/role/empty?${ query }`);
      if (ok && data) {
        if (data) {
          this.roleDetails[EMPTY] = mapEmptyPermissions(data);
        }
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.fetchEmptyPermissionsError = `Failed to fetch permissions: ${ e.message }`;
    } finally {
      this.fetchingEmptyPermissions = false;
    }
  }

  /*
    Create a new role
  */
  @action
  createRole = async (name: string, description: string, roleDetails: RoleDetails) => {
    this.creatingRole = true;
    this.createRoleError = '';

    const policy = mapPermissionsToPolicy(roleDetails);

    const role = {
      name,
      description,
      policy
    };

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { ok, data, errorData } = await Auth.fetch<RoleJson>(`/role?${ query }`, {
        body: JSON.stringify(role),
        method: 'POST'
      });
      if (ok && data) {
        const roleDetail = { id: data.id, ...role };
        this.refreshPermissionsState(roleDetail);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.createRoleError = `Something went wrong: ${ e.message }`;
    } finally {
      this.creatingRole = false;
    }
  }

  /*
    Deletes a role
  */
  @action
  deleteRole = async (id: string) => {
    this.deletingRole = true;
    this.deleteRoleError = '';

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { ok, errorData } = await Auth.fetch<RoleJson>(`/role/${ id }?${ query }`, {
        method: 'DELETE'
      });
      if (ok) {
        this.removeRoleFromState(id);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.deleteRoleError = `Something went wrong: ${ e.message }`;
    } finally {
      this.deletingRole = false;
    }
  }

  /*
    update an existing role
  */
  @action
  updateRole = async (
    id: string,
    name: string,
    description: string,
    roleDetails: RoleDetails
  ) => {
    this.updatingRole = true;
    this.updateRoleError = '';

    const policy = mapPermissionsToPolicy(roleDetails);

    const role = {
      name,
      description,
      policy
    };

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { ok, data, errorData } = await Auth.fetch<RoleDetailsJson>(`/role/${ id }?${ query }`, {
        body: JSON.stringify(role),
        method: 'PUT'
      });
      if (ok && data) {
        const roleDetail = { id, ...role, isUserSpecific: data.isUserSpecific };
        this.refreshPermissionsState(roleDetail);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.updateRoleError = `Something went wrong: ${ e.message }`;
    } finally {
      this.updatingRole = false;
    }
  }

  /*
     Create custom permissions for a user
   */
  @action
  createCustomPermissions = async (userEmail: string, roleDetails: RoleDetails) => {
    this.creatingCustomPermissions = true;
    this.createCustomPermissionsError = '';
    let customPermissions;

    const policy = mapPermissionsToPolicy(roleDetails);

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const url = `/role/customPermissions/user/${ userEmail }?${ query }`;
      const { ok, data, errorData } = await Auth.fetch<RoleDetailsJson>(url, {
        body: JSON.stringify({ policy }),
        method: 'PUT'
      });
      if (ok && data) {
        customPermissions = data;
        this.refreshPermissionsState(data);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.createCustomPermissionsError = `Something went wrong: ${ e.message }`;
    } finally {
      this.creatingCustomPermissions = false;
    }
    return customPermissions;
  }

  /*
    Fetches roles details for a given role id
  */
  @action
  fetchRoleDetails = async (roleId: string) => {
    this.fetchingRoleDetails = true;
    this.fetchRoleDetailsError = '';
    let roleDetails;

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { data, ok, errorData } = await Auth.fetch<RoleDetailsJson>(`/role/${ roleId }?${ query }`);
      if (ok && data) {
        this.refreshPermissionsState(data);
        roleDetails = data;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.fetchRoleDetailsError = `Failed to fetch role details: ${ e.message }`;
    } finally {
      this.fetchingRoleDetails = false;
    }
    return roleDetails;
  }

  /*
    Refresh roles based on the action performed to the role
  */
  @action
  refreshPermissionsState(roleDetails: RoleDetailsJson) {
    let { roles } = this;
    if (roles) {
      const roleIndex = this.roles.findIndex(role => role.id === roleDetails.id);
      if (roleIndex < 0) {
        this.roles = [...this.roles, roleDetails];
      } else {
        this.roles[roleIndex] = roleDetails;
      }
    } else {
      this.roles = [roleDetails];
    }
    // Update role details state with the updated data
    this.roleDetails[roleDetails.id] = mapSelectedPermissions(roleDetails);
  }

  /*
    Remove given role from the roles state
  */
  @action
  removeRoleFromState(roleId: string) {
    let { roles } = this;
    if (roles) {
      remove(this.roles, (r) => r.id === roleId);
    }
    // Remove role from role details state
    delete this.roleDetails[roleId];
  }

  /*
    Checks if role details have changed from original value
  */
  hasChanged(roleId: string) {
    const currentRoleDetails = this.roleDetails[roleId];
    const role = this.roles.find(r => r.id === roleId);

    if (!currentRoleDetails) {
      return false;
    }

    if (role) {
      const originalRoleDetails = mapSelectedPermissions(role);
      return !isEqual(originalRoleDetails, currentRoleDetails);
    }
    return !isEqual(DEFAULT_ROLE_DETAILS, currentRoleDetails);
  }

  /*
    Returns selected scoped for given role id and report id
  */
  getSelectedReportScopes(roleId: string, dashboardId: string) {
    const { reports: reportPermissions } = this.roleDetails[roleId];
    const report = (reportPermissions || []).find(r => r.id === dashboardId);
    return (report || {}).scopes || [];
  }

  /*
    Returns selected scoped for given role id and survey id
  */
  getSelectedSurveyScopes(roleId: string, surveyId: string) {
    const { surveys: surveyPermissions } = this.roleDetails[roleId];
    const survey = (surveyPermissions || []).find(s => s.id === surveyId);
    return (survey || {}).scopes || [];
  }

  /*
    Returns selected scoped for given role id, survey id & view id
  */
  getSelectedViewScopes(roleId: string, surveyId: string, viewId: string) {
    const { surveys: surveyPermissions } = this.roleDetails[roleId];
    const viewPermissions = ((surveyPermissions || []).find(survey => survey.id === surveyId) || {}).views || [];
    const view = viewPermissions.find(v => v.id === viewId);
    return (view || {}).scopes || [];
  }

  /*
    Update admin scopes for the given role
  */
  @action
  updateAdminPermissions = (roleId: string, scopes: string[]) => {
    if (!this.roleDetails[roleId]) {
      return;
    }
    this.roleDetails[roleId].admin = scopes;
  }

  /*
    Update reports scopes for the given role and report ids
  */
  @action
  updateDashboardPermissions = (roleId: string, dashboardId: string, scopes: string[]) => {
    if (!this.roleDetails[roleId]) {
      return;
    }
    let { reports } = this.roleDetails[roleId];
    const index = reports.findIndex(report => report.id === dashboardId);
    if (index < 0) {
      reports = [...reports, { id: dashboardId, scopes }];
    } else {
      reports[index] = { id: dashboardId, scopes };
    }
    this.roleDetails[roleId].reports = reports;
  }

  /*
    Updates if permissions are on per report basis
  */
  @action
  updateIsPerReport = (roleId: string, isPerReport: boolean) => {
    if (!this.roleDetails[roleId]) {
      return;
    }
    this.roleDetails[roleId].isPerReport = isPerReport;
  }

  /*
    Update permissions for surveys
  */
  @action
  updateSurveyPermissions = (roleId: string, surveyId: string, scopes: string[]) => {
    if (!this.roleDetails[roleId]) {
      return;
    }
    let { surveys } = this.roleDetails[roleId];
    const index = surveys.findIndex(survey => survey.id === surveyId);
    if (index < 0) {
      surveys = [...surveys, { id: surveyId, scopes }];
    } else {
      surveys[index] = { id: surveyId, scopes };
    }
    this.roleDetails[roleId].surveys = surveys;
  }

  /*
    Updates if permissions are on per survey basis
  */
  @action
  updateIsPerSurvey = (roleId: string, isPerSurvey: boolean) => {
    if (!this.roleDetails[roleId]) {
      return;
    }
    this.roleDetails[roleId].isPerSurvey = isPerSurvey;
  }

  /*
    Update permissions for views
  */
  @action
  updateViewPermissions = (roleId: string, surveyId: string, viewId: string, scopes: string[]) => {
    if (!this.roleDetails[roleId]) {
      return;
    }
    let { surveys } = this.roleDetails[roleId];
    const index = surveys.findIndex(survey => survey.id === surveyId);
    if (index < 0) {
      surveys = [...surveys, { id: surveyId, views: [{ id: viewId, scopes }] }];
    } else {
      const views = surveys[index].views || [];
      const viewIndex = views.findIndex(view => view.id === viewId);
      if (viewIndex < 0) {
        surveys[index].views = [...views, { id: viewId, scopes }];
      } else {
        surveys[index].views[viewIndex] = { id: viewId, scopes };
      }
    }
    this.roleDetails[roleId].surveys = surveys;
  }

  /*
    Resets the entire permission state
  */
  @action
  reset = () => {
    this.roleDetails = {};
    this.roles = [];
    this.totalRoles = 0;
    this.resetPageParams();
    this.resetErrorsAndLoadingFlags();
  }

  /*
    Resets the loading and error flags
  */
  @action
  resetErrorsAndLoadingFlags = () => {
    this.fetchingRoles = false;
    this.fetchRolesError = '';
    this.fetchingRoleDetails = false;
    this.fetchRoleDetailsError = '';
    this.creatingRole = false;
    this.createRoleError = '';
    this.updatingRole = false;
    this.updateRoleError = '';
    this.creatingCustomPermissions = false;
    this.createCustomPermissionsError = '';
    this.fetchingEmptyPermissions = false;
    this.fetchEmptyPermissionsError = '';
  }

  /*
    Resets the role state and loading and error flags
  */
  @action
  resetRoleState = (roleId: string) => {
    const role = this.roles.find(r => r.id === roleId);
    if (role) {
      this.roleDetails[roleId] = mapSelectedPermissions(role);
    } else {
      this.roleDetails[roleId] = DEFAULT_ROLE_DETAILS;
    }
    this.resetErrorsAndLoadingFlags();
  }

  downloadPermissionsAudit = async () => {
    this.auditPermissionsError = '';
    this.fetchingPermissionsAudit = true;
    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { data, ok, errorData } = await Auth.fetch(`/users/audit?${ query }`, { isText: true });
      if (ok && data) {
        const filename = 'thematic-user-audit.csv';
        const blob = new Blob([data as any], {
          type:
            'text/csv'
        });
        FileSaver.saveAs(blob, filename);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.auditPermissionsError = `Failed to fetch audit: ${ e.message }`;
    }
    finally {
      this.fetchingPermissionsAudit = false;
    }
  };
}

export default RoleStore;