import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus } from 'api/enums';
import { Survey } from 'api/interfaces';
import analytics from 'lib/analytics';
import { compact, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Dimmer, Form, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { ConfigureDataUIStoreInterface } from 'stores/ui/ConfigureDataUIStore';
import ConfigureDate from './ConfigureDate';
import ConfigureFilters from './ConfigureFilters';
import ConfigureScores from './ConfigureScores';
import ConfigureUniqueId from './ConfigureUniqueId';
import './configure-analysis.scss';

interface ConfigureAnalysisParams {
  orgId: string;
  surveyId: string;
}

interface ConfigureAnalysisStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
  surveyStore?: SurveyStoreInterface;
  setupStore?: SetupStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

interface ConfigureAnalysisProps extends
  ConfigureAnalysisStoreProps,
  RouteComponentProps<ConfigureAnalysisParams> {
}

interface ConfigureAnalysisState {
  updatingSurvey: boolean;
}

@inject('configureDataUIStore', 'setupStore', 'surveyStore', 'organizationStore')
@observer
class ConfigureAnalysis extends React.Component<ConfigureAnalysisProps, ConfigureAnalysisState> {
  state = {
    updatingSurvey: false
  } as ConfigureAnalysisState;

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }

  get surveyId(): string {
    const { surveyId } = this.props.match.params;
    return surveyId;
  }

  get survey() {
    const { surveyStore, match } = this.props;
    const { surveyId } = match.params;

    return surveyStore!.surveys.find(s => s.surveyId === surveyId);
  }

  componentDidMount() {
    this.getSurveyDetails();
    analytics.startRecording(true);
  }

  componentDidUpdate(prevProps: ConfigureAnalysisProps) {
    if (prevProps.match.params.surveyId !== this.surveyId) {
      this.getSurveyDetails();
    }
  }

  getSurveyDetails() {
    this.props.configureDataUIStore!.reset();
    if (this.surveyId) {
      this.getSurveyConfig();
      this.getSurveySynopsis();
    }
  }

  getSurveyConfig = async () => {
    const { configureDataUIStore, surveyStore } = this.props;
    const surveyConfig = await surveyStore!.getSurveyConfig(this.surveyId);
    const surveyStatus = await surveyStore!.getSurveyStatus(this.surveyId);
    if (surveyConfig) {
      configureDataUIStore!.initializeSurvey(this.surveyId, surveyConfig, surveyStatus);
    }
  }

  getSurveySynopsis = async () => {
    const { configureDataUIStore, setupStore } = this.props;
    const surveySynopsis = await setupStore!.getSurveySynopsis(this.surveyId);

    if (surveySynopsis) {
      configureDataUIStore!.setColumns(surveySynopsis);
    }
  };

  updateSurvey = async () => {
    const { configureDataUIStore } = this.props;
    if (!configureDataUIStore?.hasChanged) {
      this.navigateToManageData();
      return;
    }

    this.setState({ updatingSurvey: true });
    analytics.track('Manage Data: Configure Analysis', { category: 'Manage Data' });

    const { updateSurvey } = this.props.surveyStore!;

    const configuration = configureDataUIStore!.prepareAPIData();
    const updateResult = await updateSurvey(this.surveyId, { configuration }) as Survey;

    this.setState({ updatingSurvey: false });

    if (!this.props.surveyStore!.updateSurveyError) {
      this.navigateToManageData(updateResult.dataStatus);
    }
  }

  navigateToManageData = (_dataStatus?: SurveyDataStatus) => {
    const pathname = `/c/${ this.orgId }/upload`;
    this.props.history.push(pathname);
  }

  onUniqueIdChange = (uniqueId: number[]) => {
    this.props.configureDataUIStore!.updateUniqueId(uniqueId);
  }

  render() {
    const { updatingSurvey } = this.state;
    const { fetchingSurveySynopsis, fetchSurveySynopsisError } = this.props.setupStore!;
    const { updateSurveyError } = this.props.surveyStore!;
    const { columns, hasChanged, isValid, surveyConfig } = this.props.configureDataUIStore!;
    const { fetchingSurveys } = this.props.surveyStore!;
    const disabled = !(hasChanged && isValid);
    const loading = !updatingSurvey && (!!fetchingSurveys || fetchingSurveySynopsis);
    const errors = compact([fetchSurveySynopsisError, updateSurveyError]);
    const surveyTitle = this.survey ? `${ this.survey?.title }:` : '';

    return (
      <Segment className="configure-analysis">
        <Header>
          <div className="back" onClick={() => this.navigateToManageData()}>
            <FontAwesomeIcon
              size="lg"
              className="icon"
              icon="arrow-alt-circle-left"
            />
          </div>
          {`${ surveyTitle }`} Edit filters & scores
        </Header>
        <Segment className="white configure-analysis-options">
          {errors.map(error =>
            <Message
              className="error"
              negative={true}
              header={error}
              key={error}
            />
          )}
          {loading ?
            <Dimmer active={true} inverted={true}>
              <Loader size="medium">Loading survey configuration&hellip;</Loader>
            </Dimmer>
            :
            // Show the form when there is an error with update but not fetch
            !isEmpty(columns) ?
              <>
                <Form>
                  <ConfigureUniqueId
                    columns={columns}
                    uniqueId={surveyConfig.sort_column}
                    onUniqueIdChange={this.onUniqueIdChange}
                  />
                  <ConfigureDate />
                  <ConfigureScores />
                  <ConfigureFilters />
                </Form>
                <div className="actions">
                  <Button
                    disabled={updatingSurvey}
                    onClick={() => this.navigateToManageData()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save"
                    color="blue"
                    type="submit"
                    onClick={this.updateSurvey}
                    disabled={disabled || updatingSurvey}
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
              </>
              :
              null
          }
        </Segment>
      </Segment>
    );
  }
}

export default ConfigureAnalysis;