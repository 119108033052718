import auth from 'Auth/Auth';
import { SurveyDataStatus } from 'api/enums';
import { DataAlertingWorkflow, DataSourceIntegration } from 'api/interfaces';
import { flatten, parseAsNested } from 'lib/dataset-tool-parser';
import { compact, isArray, map, some } from 'lodash';
import { action, computed, observable } from 'mobx';
import * as moment from 'moment';
import { DatasetVis, DatasetsNestedMenu } from 'stores/data-structure/datasets';
import { AnalysisToolsConfiguration, parseAsLookup } from 'stores/surveys/config-parser';
import { OrganizationStoreInterface } from './OrganizationStore';
import { SurveyStoreInterface } from './SurveyStore';

interface ScoreConfig {
  score_column: number;
  score_options?: {
    range?: number[];
  };
  score_type: string;
}

interface AnalysisToolsConfigJson {
  comment_columns: number[] | number;
  date_column?: number;
  date_range: string[];
  example_columns: number;
  score_column: number;
  score_options?: object;
  score_type: string;
  scores?: ScoreConfig[];
}

export interface LiteSurvey {
  isActive: boolean;
  isPreview: boolean;
  configuration: string;
  hasDraft: boolean;
  manualUploadAllowed: boolean;
  surveyId: string;
  title: string;
  url: string;
  lastUpdated: string;
  orgId: string;
  dataStatus: SurveyDataStatus;
  surveyStatus: string;
  scopes: string[];
  dataSourceIntegration?: DataSourceIntegration;
  dataAlertingWorkflow?: DataAlertingWorkflow;
  dataVolume: number;
}

export interface InjectedAnalysisToolsStore {
  analysisToolsStore: AnalysisToolsStoreInterface;
}

export interface AnalysisToolsStoreInterface {
  getAnalysisToolsConfig: (surveyId: string) => Promise<void>;
  getAnalysis: (surveyId: string, visualizationId: string, viewId?: string) => DatasetVis | undefined;
  getAnalysisUrl: (surveyId: string) => string;
  getAnalysisTitle: (surveyId: string, visualizationId: string, viewId?: string) => string | undefined;
  isActive: (url: string) => boolean;
  errored: boolean;

  flat: DatasetVis[];
  nested: DatasetsNestedMenu;
  analysisToolsConfig: AnalysisToolsConfiguration;
}

class AnalysisToolsStore implements AnalysisToolsStoreInterface {
  currentOrg: OrganizationStoreInterface;
  surveyStore: SurveyStoreInterface;

  @observable
  errored = false;
  @observable
  analysisToolsConfig: AnalysisToolsConfiguration = {
    checked: false,
    errored: false,
    fetching: false
  };
  @computed
  get flat() {
    const { nested } = this;
    return flatten(nested);
  }
  @computed
  get nested() {
    const { surveysJson, getSurveyCanAction } = this.surveyStore;
    const { orgId, getCanAction } = this.currentOrg;
    const canThematicAdmin = getCanAction('manage:internalResource');
    if (surveysJson && orgId) {
      return parseAsNested(orgId, surveysJson, getSurveyCanAction, canThematicAdmin, 'view:analysis');
    } else {
      return { children: [], title: '' };
    }
  }
  @computed
  get lookup() {
    const { surveysJson } = this.surveyStore;
    if (surveysJson) {
      return parseAsLookup(surveysJson);
    } else {
      return {};
    }
  }

  @computed
  get commentColumns() {
    const { analysisToolsConfig } = this;

    return analysisToolsConfig ? analysisToolsConfig.commentColumns : [];
  }

  constructor(currentOrg: OrganizationStoreInterface, surveyStore: SurveyStoreInterface) {
    this.currentOrg = currentOrg;
    this.surveyStore = surveyStore;
  }

  isActive = (url: string): boolean => {
    return some(this.flat, { url });
  };

  @action
  getAnalysisToolsConfig = async (surveyId: string) => {
    const { lookup, analysisToolsConfig: config } = this;
    config.checked = false;
    config.fetching = true;
    config.errored = false;
    config.dateRange = undefined;
    const configUrl = lookup[surveyId];

    try {
      if (!surveyId || !configUrl) {
        throw new Error('Unable to fetch survey config');
      }
      const { data, ok } = await auth.fetch<AnalysisToolsConfigJson>(configUrl, {
        isRaw: true
      });
      if (data && ok) {
        const {
          comment_columns: commentColumns,
          date_column: dateColumn,
          date_range: range,
          example_columns: exampleColumns,
          score_column: scoreColumn,
          score_options: scoreOptions,
          score_type: scoreType,
          scores
        } = data;
        if (isArray(commentColumns)) {
          config.commentColumns = commentColumns;
        } else {
          config.commentColumns = compact([commentColumns]);
        }
        config.dateColumn = dateColumn;
        if (scores) {
          config.scores = map(scores, score => {
            return {
              scoreColumn: score.score_column,
              scoreType: score.score_type
            };
          });
        } else {
          config.scores = [{ scoreColumn, scoreType }];
        }
        if (scoreOptions) {
          config.scoreOptions = scoreOptions;
        }
        if (range) {
          const [start, end] = range;
          const format = 'DD-MM-YYYY';
          config.dateRange = {
            start: moment(start, format),
            end: moment(end, format)
          };
        } else {
          config.dateRange = undefined;
        }
        if (exampleColumns) {
          config.exampleColumns = [exampleColumns];
        }
      } else {
        throw new Error('Error fetching survey config');
      }
    } catch (e) {
      config.dateRange = undefined;
      config.errored = true;
    } finally {
      config.checked = true;
      config.fetching = false;
    }
  };

  getAnalysisUrl = (surveyId: string): string => {
    const analysisForSurvey = this.flat.find(analysis => analysis.surveyId === surveyId);
    return analysisForSurvey ? analysisForSurvey.url : `/c/${ this.currentOrg.orgId }/s/${ surveyId }/v/_`;
  }

  getAnalysis = (surveyId: string, visualizationId: string, viewId?: string) => {
    return this.flat.find(a => {
      return a.surveyId === surveyId &&
        a.visualizationId === visualizationId &&
        a.viewId === viewId;
    });
  }

  getAnalysisTitle = (surveyId: string, visualizationId: string, viewId?: string) => {
    const analysis = this.getAnalysis(surveyId, visualizationId, viewId);
    return analysis ? analysis.title : undefined;
  }
}

export default AnalysisToolsStore;