<template>
  <div
    class="stack-rank"
    aria-label="Stack Rank"
  >
    <stack-rank-headline
      v-if="headline"
      v-bind="headline"
      :label="scoreLabel"
      :is-selected="!compareFilter"
      :count-suffix="headlineCountSuffix"
      :is-weighted="isWeighted"
      :on-clear-selection="clearSelection"
    />
    <div
      :class="{ 'has-change' : hasChange }"
      class="stack-rank__header"
    >
      <div
        :class="{ selected: sort === 'label' }"
        @click="sortBy('label')"
      >
        {{ name }}
        <span
          v-if="sort === 'label' && direction === 'asc'"
          key="up"
        >
          <font-awesome-icon icon="chevron-up" />
        </span>
        <span
          v-else
          key="down"
        >
          <font-awesome-icon icon="chevron-down" />
        </span>
      </div>
      <div
        :class="{ selected: sort === 'score' }"
        class="score"
        @click="sortBy('score')"
      >
        {{ scoreLabel }}
        <span
          v-if="sort === 'score' && direction === 'asc'"
          key="up"
        >
          <font-awesome-icon icon="chevron-up" />
        </span>
        <span
          v-else
          key="down"
        >
          <font-awesome-icon icon="chevron-down" />
        </span>
      </div>
      <div />
      <div
        v-if="hasChange"
        :class="{ selected: sort === 'change' }"
        class="change"
        @click="sortBy('change')"
      >
        Change
        <span
          v-if="sort === 'change' && direction === 'asc'"
          key="up"
        >
          <font-awesome-icon icon="chevron-up" />
        </span>
        <span
          v-else
          key="down"
        >
          <font-awesome-icon icon="chevron-down" />
        </span>
      </div>
      <div
        :class="{ selected: sort === 'volume' }"
        class="volume"
        @click="sortBy('volume')"
      >
        {{ volumeLabel }}
        <span
          v-if="sort === 'volume' && direction === 'asc'"
          key="up"
        >
          <font-awesome-icon icon="chevron-up" />
        </span>
        <span
          v-else
          key="down"
        >
          <font-awesome-icon icon="chevron-down" />
        </span>
      </div>
      <div
        :class="{ selected: sort === 'sentiment' }"
        class="sentiment"
        @click="sortBy('sentiment')"
      >
        Sentiment
        <span
          v-if="sort === 'sentiment' && direction === 'asc'"
          key="up"
        >
          <font-awesome-icon icon="chevron-up" />
        </span>
        <span
          v-else
          key="down"
        >
          <font-awesome-icon icon="chevron-down" />
        </span>
      </div>
    </div>
    <stack-rank-row
      v-for="row in sortedRows"
      :key="row.id"
      :aria-label="`Stack Rank Row: ${row.label}`"
      v-bind="row"
      :filter-id="data.id"
      :filter-name="data.name"
      :compare-filter="compareFilter"
      :count-suffix="barCountSuffix"
      :sort="sort"
      :is-weighted="isWeighted"
      @selectionChanged="selectionChanged"
    />
  </div>
</template>

<script>
import {
  find,
  get,
  indexOf,
  isNumber,
  reject,
  reverse,
  some,
  sortBy,
  map
} from 'lodash';
import { VALID_SENTIMENTS } from 'lib/calculate-sentiment';
import { asAverage, asNps, asThreshold } from 'vue/libs/score-to-segments';
import StackRankRow from './StackRankRow.vue';
import { matchesFilter } from 'vue/libs/filter-watcher';
import { ReactInVue } from 'vuera';
import { StackRankHeadline } from 'components/Dashboard/Widgets/Components/StackRankHeadline';

export default {
  name: 'StackRank',
  components: {
    StackRankRow,
    StackRankHeadline: ReactInVue(StackRankHeadline),
  },
  props: {
    compareFilter: { type: String },
    config: { type: Object },
    data: {
      type: Object,
      count: { type: Number },
      cuts: { type: Array },
      id: { type: String },
      name: { type: String }
    }
  },
  data() {
    return {
      direction: 'desc',
      sort: 'score'
    };
  },
  computed: {
    isWeighted() {
      return get(this, 'data.metadata.weighted', false);
    },
    cuts() {
      const defaultScore = get(this, 'data.score.score');
      const scoreConfig = this.scoreConfig;

      if (scoreConfig.type === 'average') {
        const low = get(scoreConfig, 'options.range.0', 0);
        const high = get(scoreConfig, 'options.range.1', 100);
        let segmentNames = get(scoreConfig, 'options.range_thresholds');
        segmentNames = map(segmentNames, s => get(s, 'name'));
        return asAverage(this.data, defaultScore, low, high, segmentNames);
      } else if (scoreConfig.type === 'threshold') {
        return asThreshold(this.data, defaultScore);
      } else {
        return asNps(this.data, defaultScore);
      }
    },
    filterId() {
      return get(this, 'data.id');
    },
    filterName() {
      return get(this, 'data.name');
    },
    hasChange() {
      return some(this.cuts, cut => isNumber(cut.previousScore));
    },
    headline() {
      return find(this.cuts, { id: 'all_' });
    },
    name() {
      return get(this, 'data.name', '<Unknown>');
    },
    rows() {
      return reject(this.cuts, { id: 'all_' });
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreLabel() {
      return (
        get(this, 'scoreConfig.name') ||
        get(this, 'scoreConfig.type') ||
        'Score'
      );
    },
    scoreType() {
      return (
        get(this, 'scoreConfig.type') ||
        'nps'
      );
    },
    sortedRows() {
      const { direction, sort } = this;

      const sorted = sortBy(this.rows, row => {
        if (sort === 'sentiment') {
          return indexOf(VALID_SENTIMENTS, row.sentiment);
        } else if (sort === 'change') {
          return row.score - row.previousScore;
        } else if (sort === 'volume') {
          return row.volume;
        } else if (sort === 'score') {
          if (row.count === 0) {
            return Number.MIN_SAFE_INTEGER;
          }
          return get(row, 'score');
        } else {
          return row.label;
        }
      });
      if (direction === 'asc') {
        return sorted;
      } else {
        return reverse(sorted);
      }
    },
    volumeLabel() {
      if( this.isWeighted ) {
        return 'Weighting'
      }
      return 'Volume';
    },
    barCountSuffix() {
      if( this.isWeighted ) {
        return '%'
      }
      return '';
    },
    headlineCountSuffix() {
      if( this.isWeighted ) {
        return '%'
      }
      return ' responses';
    }
  },
  watch: {
    $route(to) {
      if (!to || !this.filterId) {
        return;
      }
      this.matchRoute(to);
    },
    filterId(filterId) {
      if (filterId) {
        this.matchRoute(this.$route);
      }
    }
  },
  methods: {
    clearSelection() {
      const { filterId, filterName } = this;
      this.$emit('selectionChanged', {
        filterId,
        filterName,
        selected: []
      });
    },
    matchRoute(to) {
      const { filterId } = this;
      const selected = matchesFilter(to.query.compare, filterId);
      if (selected) {
        this.setFromIds(selected);
      }
    },
    selectionChanged(queryFilter, title) {
      this.$emit('selectionChanged', queryFilter, title);
    },
    setFromIds(ids) {
      const [id] = ids;
      const { cuts } = this;
      const cut = find(cuts, { id });
      if (cut) {
        const { id, label, rql } = cut;
        const { filterId, filterName } = this;

        // fake clicking on the row
        const queryFilter = {
          filterId,
          filterName,
          selected: [{ id, label, name: label, rql }]
        };
        this.selectionChanged(queryFilter, label);
      } else {
        this.clearSelection();
      }
    },
    sortBy(column) {
      const { direction, sort } = this;
      if (sort === column) {
        this.direction = direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.direction = 'asc';
        this.sort = column;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
@import './stack-rank.scss';
.stack-rank {
  background: $--color-white;
  font-family: $--font-family;
  width: 100%;
  .stack-rank__header {
    align-items: center;
    padding: 10px 0 15px;
    display: grid;
    grid: $stack-rank-grid;
    &.has-change {
      grid: $stack-rank-grid-with-change;
    }
    justify-content: center;
    text-transform: capitalize;
    > div {
      cursor: pointer;
      font-weight: 600;
      line-height: 24px;
      padding: 0 10px;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }

    .selected {
      color: $--color-primary;
    }
  }
}
</style>

