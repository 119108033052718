import { PickerItem } from 'components/Shared/Picker';
import { getIdFromDatasetVis } from 'lib/dataset-picker-helper';
import { Dataset, DatasetView, DatasetVis, DatasetsNestedMenu } from 'stores/data-structure/datasets';

function isValidDatasetVis(vis: DatasetVis): boolean {
  return !vis.isPreview && !vis.isReviewing;
}

function isValidDatasetChild(child: Dataset['children'][0]): boolean {
  if (isDatasetVis(child)) {
    return isValidDatasetVis(child);
  }
  return true;
}

function isValidDataset(dataset: Dataset, isThematicAdmin?: boolean): boolean {
  const hasValidChildren = dataset.children.some(isValidDatasetChild);
  // only thematic admins can see hidden datasets
  return dataset.isActive && (!!isThematicAdmin || !dataset.isPreview)  && hasValidChildren;
}

export function datasetsNestedMenuToActiveDatasets(datasetsNestedMenu: DatasetsNestedMenu, isThematicAdmin?: boolean): Dataset[] {
  const activeParentDatasets: Dataset[] = datasetsNestedMenu.children.filter((child) => isValidDataset(child, isThematicAdmin));

  const activeViewDatasets: Dataset[] = activeParentDatasets.map(parentDataset => {
    const newChildren = parentDataset.children.map(child => {
      if (isDatasetVis(child)) {
        return child;
      }
      return filterActiveChildViewDatasets(child);
    });
    return { ...parentDataset, children: newChildren };
  });

  const allActiveDatasets: Dataset[] = activeViewDatasets.map(
    parentDataset => filterActiveChildDatasets(parentDataset)
  );
  return allActiveDatasets;
}

function filterActiveChildDatasets(parentDataset: Dataset): Dataset {
  const newChildren = parentDataset.children.filter(child => {
    if (isDatasetVis(child)) {
      return isValidDatasetVis(child);
    }
    return child.children.length > 0;
  });
  return { ...parentDataset, children: newChildren };
}

function filterActiveChildViewDatasets(child: DatasetView): DatasetView {
  return {
    ...child,
    children: child.children.filter(isValidDatasetVis)
  };
}

export function datasetsToPickerItems(datasets: Dataset[]): PickerItem[] {
  return datasets.map(dataset => {
    if (dataset.children.length === 1 && isDatasetVis(dataset.children[0])) {
      return visToPickerItem(dataset.children[0]);
    } else {
      return {
        children: flatChildItems(dataset).map((child) => visToPickerItem(child)),
        id: dataset.order,
        isSelected: false,
        label: dataset.title,
      };
    }
  });
}

function flatChildItems(dataset: Dataset): DatasetVis[] {
  return dataset.children.reduce((acc: DatasetVis[], child: DatasetVis | DatasetView) => {
    if (isDatasetVis(child)) {
      acc.push(child);
    } else {
      acc.push(...child.children);
    }
    return acc;
  }, []);
}

function visToPickerItem(vis: DatasetVis): PickerItem {
  return {
    id: getIdFromDatasetVis(vis),
    isSelected: false,
    label: vis.title,
  };
}

export function isDatasetVis(item: DatasetVis | DatasetView): item is DatasetVis {
  return 'url' in item && item.url !== undefined;
}
