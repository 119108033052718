import { Thread } from 'types/custom';
import { ContentBlock } from './segments-to-blocks';

export default function updateSentimentInThreadedComment(
  comments: Thread[],
  commentId: string,
  sentiment: number,
  block: ContentBlock,
): Thread[] {
  return comments.map((comment) => {

    if (comment.id === commentId) {

      comment.comment = comment.comment.map((threadedComment, index) => {

        if (block.partIndex === index) {
          threadedComment.segments = threadedComment.segments.map((seg) => {

            if (seg.location[0] === block.location[0] && seg.location[1] === block.location[1]) {
              seg.sentiment = sentiment;
            }

            return seg;
          });
        }

        return threadedComment;
      });
    }

    return comment;
  });
}
