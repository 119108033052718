import { SubscriptionJson } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { action, observable } from 'mobx';
import { OrganizationStoreInterface } from './OrganizationStore';
import { mapSubscriptions } from './utils/subscription';

export interface SubscriptionInterface extends SubscriptionJson {
  cadenceLabel: string;
  subscribersLabel: string;
}

export interface SubscriptionRequest {
  context: {
    compareFilter?: string;
    compareFilterName?: string;
    filter?: string;
    currentFilters?: string[];
    selectedPeriod: string;
    viewDashboardUrl: string;
  };
  dashboardName: string;
}

export interface SubscriptionStoreInterface {
  subscriptions: SubscriptionInterface[];
  subscriptionRequest?: SubscriptionRequest;
  changeSubscriptionError: string;

  fetchSubscriptions: () => Promise<SubscriptionJson[] | undefined>;
  changeSubscription: (digestId: string, status: string) => void;
  updateSubscription: (digestId: string, name: string, cadence: string) => void;
  deleteSubscription: (digestId: string) => void;
}
export interface InjectedSubscriptionStore {
  subscriptionStore: SubscriptionStoreInterface;
}
class SubscriptionStore implements SubscriptionStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  subscriptions = [] as SubscriptionInterface[];

  @observable
  subscriptionRequest = undefined;

  @observable
  changeSubscriptionError = '';

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;
  }

  /* Update the current users subscription to a digest */
  @action
  changeSubscription = async (digestId: string, status: string) => {
    const { data, ok } = await Auth.fetch<SubscriptionJson>(
      `/digest/${ digestId }/changeSubscription`,
      {
        body: JSON.stringify({ status }),
        method: 'PUT'
      }
    );

    if (!ok || !data) {
      throw new Error('Failed to change subscription');
    }

    await Auth.refreshProfile();
  }

  /* Update an existing digest */
  @action
  updateSubscription = async (digestId: string, name: string, cadence: string) => {
    const { orgIdQueryParam } = this.currentOrg;
    const { ok, data } = await Auth.fetch<SubscriptionJson>(
      `/digest/${ digestId }?${ orgIdQueryParam }`,
      {
        body: JSON.stringify({ name, cadence }),
        method: 'PUT'
      }
    );

    if (!ok || !data) {
      throw new Error('Subscription update failed');
    }

    await this.fetchSubscriptions();
  }

  fetchSubscriptions = async () => {
    const { orgIdQueryParam } = this.currentOrg;
    const { data, ok } = await Auth.fetch<SubscriptionJson[]>(
      `/digests?${ orgIdQueryParam }`
    );

    if (data && ok) {
      this.subscriptions = mapSubscriptions(data);
      return data;
    } else {
      throw new Error(`Couldn't fetch digests successfully`);
    }
  }

  @action
  deleteSubscription = async (digestId: string) => {
    const { orgIdQueryParam } = this.currentOrg;
    const { data, ok } = await Auth.fetch<SubscriptionJson>(
      `/digest/${ digestId }?${ orgIdQueryParam }`,
      { method: 'DELETE' }
    );

    if (!ok || !data) {
      throw new Error('Failed to change subscription');
    }

    await this.fetchSubscriptions();
  }
}

export default SubscriptionStore;
