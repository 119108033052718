import { ValidSimpleSentiments, } from 'lib/calculate-sentiment';
import Auth from 'Auth/Auth';
import analytics from 'lib/analytics';

export default async function logSentiment(opts: {
  comment: string,
  commentId: string,
  lastSentiment: number,
  orgId: string,
  sentiment: ValidSimpleSentiments,
  surveyId: string,
  block: unknown,
}): Promise<void> {
  const {
    comment,
    commentId,
    lastSentiment,
    orgId,
    sentiment,
    surveyId,
    block,
  } = opts;

  const url = `/survey/${surveyId}/flag_comment`;

  const description = {
    block,
    lastSentiment,
    sentiment,
    orgId
  };

  analytics.track('Analysis: Sentiment Changed');

  await Auth.fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      comment,
      commentId,
      description: JSON.stringify(JSON.stringify(description)),
      flagType: 'sentiment'
    })
  });

}
