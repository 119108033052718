import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Input } from 'semantic-ui-react';
import './filter-search-input.scss';

interface FilterSearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

class FilterSearchInput extends React.Component<FilterSearchInputProps, {}> {
  render() {
    const { value, onChange } = this.props;
    return (
      <div className="filter-search-input">
        <Input
          value={value}
          icon={<FontAwesomeIcon icon="search" />}
          size="small"
          autoFocus={true}
          spellCheck="false"
          placeholder="Search"
          onChange={(e) => onChange(e.target.value)}
        />
        {value && (
          <button
            className="filter-search-input-clear-button plain"
            onClick={() => onChange('')}
            onMouseDown={(e) => e.preventDefault()}
          >
            <FontAwesomeIcon icon="times-circle" />
          </button>
        )}
      </div>
    );
  }
}

export default FilterSearchInput;
