import * as React from 'react';
import './bar-difference.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';

function toFixed(value: number, precision = 1) {
  if (precision === 0) {
    return Math.round(value).toString();
  }
  return value.toFixed(precision);
}

function format(value: number, precision = 1, suffix: string) {
  return `${toFixed(value, precision)}${suffix}`;
}

type Props = {
  value: number;
  oldValue: number;
  maxValue: number;
  suffix: string;
  dp: number;
}

const BarDifference = withCamelCaseProps(({
  value,
  oldValue,
  maxValue,
  suffix,
  dp,
}: Props) => {
  const baseline = format(value, dp, suffix);
  const comparison = format(oldValue, dp, suffix);
  const width = Math.abs(0.9 * (value / maxValue * 100));
  const oldWidth = Math.abs(0.9 * (oldValue / maxValue * 100));
  const isNegative = value < 0;
  const x = value > 0 ? 0 : 100 - width + '%';

  return (
    <div
      className="bar-difference"
      aria-label="percentage-bar"
    >
      <svg
        className="bar-difference__indicator"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 32"
      >
        <defs>
          <linearGradient id="overall">
            <stop offset="0%" stopColor={'var(--primary-400)'} />
            <stop offset="100%" stopColor={'var(--primary-300)'} />
          </linearGradient>
          <linearGradient id="comparison">
            <stop offset="0%" stopColor={'var(--orange-400)'} />
            <stop offset="100%" stopColor={'var(--orange-200)'} />
          </linearGradient>
          <linearGradient id="negative-overall">
            <stop offset="0%" stopColor={'var(--primary-300)'} />
            <stop offset="100%" stopColor={'var(--primary-400)'} />
          </linearGradient>
          <linearGradient id="negative-comparison">
            <stop offset="0%" stopColor={'var(--orange-200)'} />
            <stop offset="100%" stopColor={'var(--orange-400)'} />
          </linearGradient>
        </defs>
      <rect
        x={x}
        width={`${width}%`}
        fill={`url(#${isNegative ? 'negative-' : ''}overall)`}
        y="4"
        rx="0"
        ry="0"
        height="10"
      />
      <rect
        x={x}
        width={`${oldWidth}%`}
        fill={`url(#${isNegative ? 'negative-' : ''}comparison)`}
        y="18"
        rx="0"
        ry="0"
        height="10"
      />
      </svg>
      <div
        className="bar-difference__label bar-difference__label--baseline"
        aria-label="baseline value"
      >{baseline}</div>
      <div
        className="bar-difference__label bar-difference__label--comparison"
        aria-label="comparison value"
      >{comparison}</div>
    </div>
   );
});

export { BarDifference };
