import { action, observable } from 'mobx';

export interface TagsUIStoreInterface {
  showManageTagsDialog: boolean;
  deletingTags: boolean;

  openManageTags: () => void;
  closeManageTags: () => void;

  toggleDeletingTags: (toggle: boolean) => void;
}

class TagsUIStore implements TagsUIStoreInterface {

  @observable
  showManageTagsDialog = false;

  @observable
  deletingTags = false;

  @action
  openManageTags = () => {
    this.showManageTagsDialog = true;
  }

  @action
  closeManageTags = () => {
    this.showManageTagsDialog = false;
  }

  @action
  toggleDeletingTags = (toggle: boolean) => {
    this.deletingTags = toggle;
  }
}

export default TagsUIStore;