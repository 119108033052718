import Auth from 'Auth/Auth';
import { action, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { SurveyStoreInterface } from './SurveyStore';
import analytics from 'lib/analytics';

export type ThemeEditorEvent =
  | {
      timestamp: number;
      type: 'Navigation';
      subType: 'Enter' | 'Exit';
  }
  | {
      timestamp: number;
      type: 'Session';
      subType: 'Active' | 'Reset' | 'LoadedReset' | 'Apply';
    }
  | {
      timestamp: number;
      type: 'Session';
      subType: 'Inactive';
      data: number; // count of active seconds in the session
    }
  | {
      timestamp: number;
      type: 'Addition';
      subType: 'AddBaseTheme' | 'AddSubTheme' | 'AddMappedPhrase';
    }
  | {
      timestamp: number;
      type: 'Modify';
      subType: 'RenameTheme';
    }
  | {
      timestamp: number;
      type: 'Modify';
      subType: 'MoveTheme';
      data: number; // number of positions moved, approx
    }
  | {
      timestamp: number;
      type: 'Discover';
      subType: 'ChangeSuggested';
    }
  | {
      timestamp: number;
      type: 'Discover';
      subType: 'Themes' | 'MappedPhrases';
      data: number; // percentage
    };

interface ThemeEditorSession {
  id: string;
  surveyId: string;
  events: ThemeEditorEvent[];
}

export interface ThemeEditorSessionStoreInterface {
  surveyStore: SurveyStoreInterface;
  sessions: Record<string, ThemeEditorSession>;
  currentSessionId: string | null;

  logEvent: (event: ThemeEditorEvent) => void;
  isActive: () => boolean;
  createSession: (surveyId: string) => string;
  addEvent: (event: ThemeEditorEvent) => void;
  getSessionDuration: (sessionId: string) => number;
  loadSession: (surveyId: string) => string | null;
  saveToLocalStorage: () => void;
  loadFromLocalStorage: () => void;
}

const STORAGE_KEY = 'theme-editor-sessions';

class ThemeEditorSessionStore implements ThemeEditorSessionStoreInterface {
  surveyStore: SurveyStoreInterface;

  constructor(surveyStore: SurveyStoreInterface) {
    this.surveyStore = surveyStore;
    this.loadFromLocalStorage();
  }

  @observable sessions: Record<string, ThemeEditorSession> = {};
  @observable currentSessionId: string | null = null;

  @action
  createSession = (surveyId: string): string => {
    const sessionId = uuidv4();

    const event: ThemeEditorEvent = {
      type: 'Session',
      subType: 'Active',
      timestamp: Date.now()
    };

    this.sessions[sessionId] = {
      id: sessionId,
      surveyId,
      events: [],
    };
    this.currentSessionId = sessionId;
    this.addEvent(event);
    this.saveToLocalStorage();
    return sessionId;
  }


  @action
  addEvent = (event: ThemeEditorEvent): void => {
    const sessionId = this.currentSessionId;
    if (!sessionId) {
      return;
    }

    const session = this.sessions[sessionId];
    if (session) {

      if (event.type === 'Session' && event.subType === 'Active') {
        analytics.startRecording(true);
      }

      if (event.type === 'Session' && event.subType === 'Inactive') {
        analytics.stopRecording();
      }

      if (event.type === 'Session' && event.subType === 'Inactive' && !this.isActive()) {
        // Already inactive, this event can be skipped
        return;
      }

      session.events.push(event);
      this.saveToLocalStorage();
      this.logEvent(event);

      if (event.type === 'Session' && event.subType === 'Reset') {
        // Replace the current session with a new session for the same survey.
        const newSessionId = this.createSession(session.surveyId);
        this.currentSessionId = newSessionId;

        // Delete the old session from localStorage
        delete this.sessions[sessionId];
        this.saveToLocalStorage();
      }
    }
  }

  logEvent(event: ThemeEditorEvent) {
    if (!this.currentSessionId) {
      return;
    }

    const currentSession = this.sessions[this.currentSessionId];

    if (!currentSession) {
      return;
    }

    const surveyId = currentSession.surveyId;

    const url = `/survey/${surveyId}/themes/analytics`;

    const timestamp = new Date(event.timestamp);
    const formattedTimestamp = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    }).format(timestamp);
    const eventData = 'data' in event ? event.data : {};

    const body = {
      timestamp: formattedTimestamp,
      sessionId: this.currentSessionId,
      event: event.type,
      eventSubtype: event.subType,
      data: eventData,
    };

    Auth.fetch(url, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  // returns duration in ms
  getSessionDuration = (sessionId: string): number => {
    const session = this.sessions[sessionId];
    if (!session) return 0;

    let totalDuration = 0;
    let lastActiveTimestamp: number | null = null;

    for (const event of session.events) {
      if (event.type === 'Session') {
        if (event.subType === 'Active') {
          lastActiveTimestamp = event.timestamp;
        } else if (event.subType === 'Inactive' && lastActiveTimestamp !== null) {
          totalDuration += event.timestamp - lastActiveTimestamp;
          lastActiveTimestamp = null;
        }
      }
    }

    // If the session is still active, add the duration up to now
    if (lastActiveTimestamp !== null) {
      totalDuration += Date.now() - lastActiveTimestamp;
    }

    return totalDuration;
  }

  isActive = (): boolean => {
    if (!this.currentSessionId) return false;

    const currentSession = this.sessions[this.currentSessionId];
    if (!currentSession) return false;

    let isActive = false;

    for (let i = currentSession.events.length - 1; i >= 0; i--) {
      const event = currentSession.events[i];
      if (event.type === 'Session') {
        if (event.subType === 'Active') {
          isActive = true;
          break;
        } else if (event.subType === 'Inactive') {
          isActive = false;
          break;
        }
      }
    }

    return isActive;
  }

  @action
  loadSession = (surveyId: string): string | null => {
    const existingSession = Object.values(this.sessions).find(
      session => session.surveyId === surveyId
    );

    if (existingSession) {
      const hasDraft = this.surveyStore.hasDraft(surveyId);

      if (hasDraft) {
        this.currentSessionId = existingSession.id;
        return existingSession.id;
      } else {
        // Discard the existing session since it has no change events
        delete this.sessions[existingSession.id];
        const newSessionId = this.createSession(surveyId);
        this.addEvent({ type: 'Session', subType: 'LoadedReset', timestamp: Date.now() });
        return newSessionId;
      }
    }

    const newSessionId = this.createSession(surveyId);
    this.addEvent({ type: 'Session', subType: 'LoadedReset', timestamp: Date.now() });
    return newSessionId;
  }

  @action
  saveToLocalStorage = (): void => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.sessions));
  }

  @action
  loadFromLocalStorage = (): void => {
    const storedSessions = localStorage.getItem(STORAGE_KEY);
    if (storedSessions) {
      this.sessions = JSON.parse(storedSessions);
    }
  }
}

export { ThemeEditorSessionStore };
