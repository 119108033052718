import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Shared/Button';
import { useAsyncFunction } from 'lib/useAsyncFunction';
import * as React from 'react';
import { getActiveDashboardUIStore } from 'stores/RootStore';
import { Comment, CommentTheme, PlainComment, Summary, Thread } from 'types/custom';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { useFetchWidgetComments } from '../Hooks/useFetchWidgetComments';
import classNames from 'classnames';
import './comment-component.scss';
import { Loader } from 'semantic-ui-react';
import { InlineNotification } from 'components/Shared/InlineNotification';
import { QuotesList } from 'components/Shared/QuotesList';
import { getBlocks } from './getBlocks';
import { processComments } from './processComments';
import { CommentBlocks } from './CommentBlocks';
import { SummaryPage, processSummaries } from './processSummaries';

export interface Props {
  doNavigate: () => void,
  canNavigate: boolean;
  filterString: string;
  segmentsAroundHighlightedSentences: number;
  sentiment: 'positive' | 'negative' | 'neutral';
  source: string;
  theme: CommentTheme;
}

type CommentData = PlainComment[] | Thread[] | Summary[] | null;

const isSummaryArray = (data: PlainComment[] | Thread[] | Summary[]): data is Summary[] => {
  return data.length > 0 && 'conversation' in data[0];
}

const getComments = ( data: CommentData, theme: CommentTheme): Comment[] => {
  if (!data || isSummaryArray(data)) {
    return [];
  }
  return processComments(data, theme);
}

const getSummaryPages = ( data: CommentData, theme: CommentTheme): SummaryPage[] => {
  if (!data || !isSummaryArray(data)) {
    return [];
  }
  return processSummaries(data, theme);
}

const CommentComponent = withCamelCaseProps(
  ({
    doNavigate,
    source,
    filterString,
    theme,
    sentiment,
    canNavigate,
    segmentsAroundHighlightedSentences = 1,
  }: Props) => {
    if (!theme) {
      return null;
    }

    const [currentPage, setCurrentPage] = React.useState(0);
    const { currentDashboardId } = getActiveDashboardUIStore();

    const conversationScrollRef = React.useRef<HTMLDivElement | null>(null);

    const {
      fetch: fetchWidgetComments,
      data,
      error,
      loading,
    } = useAsyncFunction(useFetchWidgetComments);

    React.useEffect(() => {
      fetchWidgetComments({
        dashboardId: currentDashboardId,
        filterString,
        sentiment,
        source,
        theme
      });
      setCurrentPage(0);
    }, [currentDashboardId, fetchWidgetComments, filterString, sentiment, source, theme]);

    React.useEffect(() => {
      if (conversationScrollRef.current) {
        // Given the conversation on page 1 is scrolled, then the user switches to page 2,
        // the conversation element should no longer be scrolled.
        conversationScrollRef.current.scrollTop = 0;
      }
    }, [currentPage]);

    const comments = getComments(data, theme);
    const summaryPages = getSummaryPages(data, theme);
    const blocks = getBlocks(currentPage, comments, segmentsAroundHighlightedSentences, theme);
    const pageCount = Math.max(comments.length, summaryPages.length);

    const previousPage = () => {
      setCurrentPage(currentPage > 0 ? currentPage - 1 : currentPage);
    }

    const nextPage = () => {
      setCurrentPage(currentPage < pageCount - 1 ? currentPage + 1 : currentPage);
    }

    return (
      <div className="comments">
        <header className="comments__header">
          <div className="comments__header-left">
            <h5 className="comments__title">Examples for { theme?.subtheme ? theme.subtheme.title : theme.theme.title }</h5>
            {canNavigate && (
              <Button
                variant="tertiary"
                onClick={doNavigate}
              >
                See more
              </Button>
            )}
          </div>
          {pageCount > 0 && (
            <div className="comments__header-right">
              {pageCount && (
                <span>{ `${currentPage + 1 } of ${ pageCount }` }</span>
              )}
              <div className="comments__navigate-icons">
                <button
                  className="comments__pagination-button"
                  disabled={currentPage === 0}
                  onClick={previousPage}
                >
                  <FontAwesomeIcon
                    className={classNames('comments__navigate-icon', {
                      'disabled': currentPage === 0
                    })}
                    icon="chevron-left"
                  />
                </button>
                <button
                  className="comments__pagination-button"
                  disabled={currentPage === pageCount - 1}
                  onClick={nextPage}
                >
                  <FontAwesomeIcon
                    className={classNames('comments__navigate-icon', {
                      'disabled': currentPage === pageCount - 1
                    })}
                    icon="chevron-right"
                  />
                </button>
              </div>
            </div>
          )}
        </header>
        <div className="comments__comment">
          {!loading && !error && blocks && (
            <div className="comments__comment-contents">
              {summaryPages[currentPage] ? (
                <>
                  <div className="comments__examples">
                     <span className="comments__block">{ summaryPages[currentPage].summaryExcerpt }</span>
                  </div>
                  <div className="comments__conversation" ref={conversationScrollRef}>
                    <QuotesList references={summaryPages[currentPage].references} />
                 </div>
                </>
              ) : (
                <div className="comments__examples">
                  <CommentBlocks blocks={blocks} />
                </div>
              )}
            </div>
          )}
          {loading && (
            <div className="comments__loading">
              <Loader active={true} inline={true} />
            </div>
          )}
          {!loading && pageCount === 0 && (
            <div>
              No valid comments found
            </div>
          )}
          {error && (
            <div className="comments__error">
              <InlineNotification
                isHighlighted={true}
                title="There was an error loading the comments"
                type="error"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export { CommentComponent };
