import * as React from 'react';
import { Button, Loader, Popup } from 'semantic-ui-react';
import './themes-quality-popup.scss';
import { QualityIndicator, QualityRating, getQualityClass, qualityRatings } from './QualityIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classnames from 'classnames';

interface QualityInfo {
  name: string;
  level: QualityRating;
  description: string;
}

interface ThemesQualityPopupProps {
  overallScore: QualityRating;
  overallScoreDescription: string;
  scores: QualityInfo[];
}

export const ThemesQualityPopup: React.FC<ThemesQualityPopupProps> = ({
  overallScore,
  overallScoreDescription,
  scores
}) => {
  const renderQualityItem = (item: QualityInfo) => (
    <li key={`${item.name}-${item.level}`} className='themes-quality-popup__item'>
      <h4 className='themes-quality-popup__item-label'>{item.name}</h4>
      <QualityIndicator
        rating={item.level}
        description={item.description}
      />
    </li>
  );

  const qualityClass: string = getQualityClass(overallScore);
  const currentQualityIndex = qualityRatings.indexOf(overallScore);
  const loading = false; // TODO: This section needs to be updated with the value from the network request

  return (
    <Popup
      trigger={
        <Button size='small' className='themes-quality-popup__button'>
          {loading ?
            <Loader
              size="mini"
              content="Loading..."
              active={loading}
              inline={true}
              className='themes-quality-popup__loader'
            />
          :
            <>
              <div className="themes-quality-popup__progress-bars">
                {qualityRatings.map((rating, index) => {
                  const isBarActive = index <= currentQualityIndex;
                  return (
                    <div
                      key={rating}
                      className={classnames('themes-quality-popup__progress-bar', {
                        [`themes-quality-popup__progress-bar--${qualityClass}`]: isBarActive
                      })}
                    />
                  );
                })}
              </div>
              {overallScore}
            </>
          }
          <FontAwesomeIcon
            className='themes-quality-popup__icon'
            icon="chevron-down"
          />
        </Button>
      }
      content={
        <>
          <h3
            className={classnames(
              'themes-quality-popup__header',
              `themes-quality-popup__header--${qualityClass}`
            )}
          >
            {overallScoreDescription}
          </h3>
          <ul className='themes-quality-popup__list'>
            {scores.map((score) => renderQualityItem(score))}
          </ul>
        </>
      }
      className='themes-quality-popup'
      on={'click'}
    />
  );
};
