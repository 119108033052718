import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBolt,
  faCalendarAlt,
  faChartLine,
  faCommentDots,
  faComments,
  faFrown
} from '@fortawesome/pro-light-svg-icons';
import { CardColors } from 'components/Shared/Card';

export type ExampleQuestion = {
  label: string,
  category: string,
  icon: IconDefinition
  color: CardColors;
};

export const exampleQuestions: ExampleQuestion[] = [
  {
    label: 'What are the most common issues people face?',
    category: 'To identify problems',
    icon: faFrown,
    color: CardColors.TEAL
  },
  {
    label: 'What are the most common requests?',
    category: 'To suggest improvement actions',
    icon: faChartLine,
    color: CardColors.LIME

  },
  {
    label: 'What do customers mention the most?',
    category: 'To see sentiment and feedback',
    icon: faCommentDots,
    color: CardColors.BLUE
  },
  {
    label: 'What themes impact the score?',
    category: 'To evaluate score impact',
    icon: faBolt,
    color: CardColors.YELLOW
  },
  {
    label: 'Compare positive to negative feedback.',
    category: 'To compare segments',
    icon: faComments,
    color: CardColors.PURPLE
  },
  {
    label: 'Compare this month to last month.',
    category: 'To track change over time',
    icon: faCalendarAlt,
    color: CardColors.GOLD
  },
];