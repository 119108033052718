import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { TagInfo } from 'stores/TagStore';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';
import { createCategorySearchQueryFilter } from './category-filter-helper';
import { createHierarchicalSearchQueryFilter } from './hierarchical-filter-helper';
import { toggleFilterCutSelected } from './list-filter-helper';
import { createTagSearchQueryFilter } from './tag-filter-helper';
import { createThemesSearchQueryFilter, getThemeMatchType, ThemesObject } from './theme-filter-helper';

const updateFilter = (
  filter: AnalysisFilter,
  cut: AnalysisFilterCut,
  commentColumns: number[],
  filterKey: AnalysisFilterKey,
  themes: ThemesObject,
  tags: TagInfo[],
  queryFilter?: QueryFilter,
  isFeedbackTool?: boolean
): QueryFilter => {

  const match = getThemeMatchType(queryFilter);

  switch (filter.type) {

    case 'tags':
      return createTagSearchQueryFilter(
        filter, filterKey, cut, commentColumns, tags, queryFilter
      );

    case 'themes':
      return createThemesSearchQueryFilter(
        filter, filterKey, cut, themes, match, queryFilter, isFeedbackTool
      );

    case 'hierarchical':
      return createHierarchicalSearchQueryFilter(
        filter, filterKey, cut, queryFilter
      );

    case 'categories':
      return createCategorySearchQueryFilter(
        filter, filterKey, cut, queryFilter
      );

    default:
      return toggleFilterCutSelected(
        filter, filterKey, cut, queryFilter
      );
  }

};

export default updateFilter;
