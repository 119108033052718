import _, { get } from 'lodash';
import { SPECIAL_EMPTY_NAME } from 'vue/libs/consts';
import thematicData from 'vue/libs/thematicData';
import { mapBaselineAndComparisonData, scoreGraphNormalizeTwoDatasetsForThemeFreq } from './scoreGraphHelpers';
import { RequestOptions } from 'stores/InitConfigStore';
import Vue from 'vue';
import { ThemeFreq } from './types';

interface Selection {
  query: string;
  shortTitle: string;
  title: string;
}

interface Selections {
  baseline: Selection;
  comparison: Selection;
}

interface DoFetch {
  baseline: any;
  comparison: any;
  baselineFreq: ThemeFreq[];
  selectionFreq: ThemeFreq[];
}

type GraphOptions = {
  el: Vue | Element | Vue[] | Element[] | HTMLElement,
  commentColumns: number[],
  dateResolution: string,
  showBy: string,
  requestOptions: RequestOptions,
  selectedThemes: string[],
  selections: Selections,
  themeSegmentSelection: { baseline?: string; comparison?: string },
  chartWidth: number,
  isComparison: boolean,
  selectedChartOption: string,
  graphType: string,
  onScoreGraphClick: (label: string) => void,
};

export default async function doFetch(opts: GraphOptions): Promise<DoFetch> {
  const {
    commentColumns,
    dateResolution,
    selectedThemes,
    requestOptions,
    graphType,
    selections,
    themeSegmentSelection,
  } = opts;
  // override dateResolution from globals with config
  const requestOpts = _.extend({}, requestOptions, { dateResolution });
  // _.set(requestOpts, 'scoreOptions.scoreCommentsOnly', true);

  const baseline = await thematicData.getThemesByDate(
    selections.baseline.query,
    commentColumns,
    { ...requestOpts, themesSegmentFilterString: themeSegmentSelection ? themeSegmentSelection.baseline : null }
  );
  const comparison = await thematicData.getThemesByDate(
    selections.comparison.query,
    commentColumns,
    { ...requestOpts, themesSegmentFilterString: themeSegmentSelection ? themeSegmentSelection.comparison : null }
  );

  if ('ok' in baseline && !baseline.ok) {
    throw baseline.error;
  }

  if ('ok' in comparison && !comparison.ok) {
    throw comparison.error;
  }

  let baselineFreq: ThemeFreq[] = [];
  let selectionFreq: ThemeFreq[] = [];
  let theme;
  let score;
  // calculate baseline impact
  const range = _.get(requestOptions, 'scoreOptions.range');
  const haveRange = _.isArray(range);
  for (let i = 0; i < baseline.themes.length; i++) {
    theme = baseline.themes[i].themes[selectedThemes[0]];
    if (
      theme &&
      selectedThemes[1] != SPECIAL_EMPTY_NAME &&
      selectedThemes[1]
    ) {
      theme = theme.subthemes?.[selectedThemes[1]];
    }
    const count = get(theme, 'count', 0);
    const sentimentCounts = get(theme, 'sentimentCounts', {
      neg: 0,
      pos: 0,
      neut: 0
    });
    // this is for historical reasons / because scoreGraphLoadData expects it
    score = {
      v: theme ? (haveRange ? theme.impactPercent : theme.impact) : 0,
      counts: { count, sentimentCounts }
    };
    if (graphType === 'base') {
      score.v = theme ? theme.score.score : 0;
    }
    baselineFreq.push(score);
  }
  // calculate selection impact
  for (let i = 0; i < comparison.themes.length; i++) {
    theme = comparison.themes[i].themes[selectedThemes[0]];
    if (
      theme &&
      selectedThemes[1] != SPECIAL_EMPTY_NAME &&
      selectedThemes[1]
    ) {
      theme = theme.subthemes[selectedThemes[1]];
    }
    const count = get(theme, 'count', 0);
    const sentimentCounts = get(theme, 'sentimentCounts', {
      neg: 0,
      pos: 0,
      neut: 0
    });
    score = {
      v: theme ? (haveRange ? theme.impactPercent : theme.impact) : 0,
      counts: { count, sentimentCounts }
    };
    if (graphType === 'base') {
      score.v = theme ? theme.score.score : 0;
    }
    selectionFreq.push(score);
  }

  selectionFreq = scoreGraphNormalizeTwoDatasetsForThemeFreq(
    baseline.dateLabels,
    comparison.dateLabels,
    selectionFreq
  );

  return {
    baseline,
    comparison,
    baselineFreq,
    selectionFreq
  };
}

export function getOverTimeImpactScoreGraphOptions(
  baseline: any,
  comparison: any,
  baselineFreq: ThemeFreq[],
  selectionFreq: ThemeFreq[],
  opts: GraphOptions
) {
  const {
    selections,
    chartWidth,
    isComparison,
    graphType,
    requestOptions,
    selectedChartOption,
    onScoreGraphClick
  } = opts;

  const scoreType = requestOptions.scoreType || '';
  const yAxisLabel = getChartLabel(scoreType, graphType);
  const range = requestOptions.scoreOptions?.range || {};
  const haveRange = _.isArray(range);
  const tipValueUnit = getToolTipUnit(haveRange, graphType);

  const combinedData = mapBaselineAndComparisonData(baselineFreq, selectionFreq, baseline.dateLabels);

  return {
    baselineTitle: selections.baseline.title + ' (' + baseline.count + ')',
    comparisonTitle: selections.baseline.title + ' (' + comparison.count + ')',
    data: combinedData,
    labels: baseline.dateLabels,
    chartLabel: yAxisLabel,
    onScoreGraphClick: onScoreGraphClick,
    barChartWidth: chartWidth,
    chartUnit: getChartUnit(haveRange, graphType),
    isComparison,
    startFromZero: true,
    tipValueUnit,
    showTotalCommentsInTip: false,
    selectedChartOption
  };
}

function getToolTipUnit(haveRange: boolean, graphType: string): string {
  if (haveRange && graphType === 'effect') {
    return '%';
  }
  return ` points`;
}

function getChartLabel(scoreType: string, graphType: string): string {
  if (scoreType === 'nps') {
    return graphType === 'effect' ? 'Impact on NPS' : 'NPS of theme';
  }
  return graphType === 'effect' ? 'Impact on score' : 'Score';
}

function getChartUnit(haveRange: boolean, graphType: string): string {
  if (haveRange && graphType === 'effect') {
    return '%';
  }
  return '';
}
