import * as React from 'react';
import { Button } from 'components/Shared/Button';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import * as classnames from 'classnames';
import analytics from 'lib/analytics';
import './link-to-answer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AggregateViewSource, AnalysisViewSource, isAnalysisViewSource } from 'api/interfaces';
import * as queryString from 'query-string';

interface LinkToAnswerProps {
  orgId: string;
  sources: (AnalysisViewSource | AggregateViewSource)[];
  location: typeof AnswersBannerLocations[keyof typeof AnswersBannerLocations];
  inline?: boolean;
  onDismiss: () => void;
}

export const AnswersBannerLocations = {
  Dashboard: 'Dashboard',
  Home: 'Home',
  Analysis: 'Analysis'
} as const;

const LinkToAnswer = withCamelCaseProps(({
  orgId,
  inline,
  sources,
  location,
  onDismiss
}: LinkToAnswerProps) => {

  const datasetParams = sources
    .filter(isAnalysisViewSource)
    .map((source: AnalysisViewSource) => {
      const { survey, view, visualization } = source;
      return JSON.stringify({
        surveyId: survey,
        viewId: view,
        visId: visualization
      })
    }).join(',');

  const serialized = queryString.stringify({ datasets: `[${datasetParams}]` });

  const url = `/#/c/${orgId}/answers?${serialized}`;

  const navigateToAnswer = () => {
    window.location.href = url;
    analytics.track(`${location}: Link to Answers`);
  };

  return (
    <section
      className={classnames("link-to-answer", {"link-to-answer--inline": inline})}
    >
      <div className="link-to-answer__content">
        <div className="link-to-answer__text-content">
          <h3 className="link-to-answer__title">
            Need quick insights?
          </h3>
          <p className="link-to-answer__description">
            Ask questions about your data and get instant answers.
          </p>
        </div>
        <Button
          variant="secondary"
          size="medium"
          onClick={navigateToAnswer}
        >
          Ask in Answers
        </Button>
      </div>
      <Button
        role="button"
        variant="secondary"
        size="medium"
        aria-label="remove-banner"
        onClick={() => onDismiss()}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    </section>
  );
});

export { LinkToAnswer };
