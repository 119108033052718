import * as React from 'react';
import { AnswerComparison, InputData } from 'api/interfaces';
import { DropdownProps } from 'semantic-ui-react';
import { getComparisonOptions } from '../utils/getAnswerComparisonOptions';
import { ExamplesList } from './ExamplesList';
import analytics from 'lib/analytics';

interface Props {
  comparisons: AnswerComparison[];
  isReadOnly: boolean;
  onTextSelection: (text: string) => void;
  onToggleExamplePin: (example: InputData) => void;
}

export const ComparisonExamples = ({ comparisons, isReadOnly, onToggleExamplePin, onTextSelection }: Props) => {
  const [selectedComparison, setSelectedComparison] = React.useState<string>(comparisons[0].name);

  const getComparisonOptionKey = (comparison: string): string => {
    if (comparison === 'all') return 'All examples';
    if (comparison === comparisons[0].name) return 'Baseline';
    if (comparison === comparisons[1].name) return 'Comparison';
    return '';
  };

  const comparisonOptions = getComparisonOptions(comparisons);

  const onSelectedComparisonChange = (data: DropdownProps) => {
    setSelectedComparison(String(data.value));
    analytics.track('Answers: Comparison example comments', { 'Type' : getComparisonOptionKey(String(data.value)) });
  };

  const content = selectedComparison === 'all'
    ? comparisons.flatMap(comparison => comparison.inputData)
    : comparisons.find(comparison => comparison.name === selectedComparison)?.inputData || [];

  return (
    <section className="answers__examples">
      <ExamplesList
        content={content}
        isReadOnly={isReadOnly}
        onTextSelection={onTextSelection}
        onToggleExamplePin={onToggleExamplePin}
        comparisonOptions={comparisonOptions}
        selectedComparison={selectedComparison}
        onSelectedComparisonChange={onSelectedComparisonChange}
      />
    </section>
  );
};
