import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionJson, SurveyPermissionJson } from 'api/interfaces';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Select } from 'semantic-ui-react';
import { RoleStoreInterface } from 'stores/RoleStore';
import Scopes from './Scopes';
import './survey-permissions.scss';

export interface SurveyPermissionsProps {
  roleId: string;
  roleStore?: RoleStoreInterface;
  isInModal?: boolean;
}

interface SurveyPermissionsState {
  selectedValue: string;
}

const ALL = 'all';
const INDIVIDUAL = 'individual';
const selectOptions = [
  { key: ALL, value: ALL, text: ALL },
  { key: INDIVIDUAL, value: INDIVIDUAL, text: INDIVIDUAL }
];

@inject('roleStore')
@observer
class SurveyPermissions extends React.Component<SurveyPermissionsProps, SurveyPermissionsState> {
  get roleStore(): RoleStoreInterface {
    return this.props.roleStore!;
  }
  get roleId () {
    return this.props.roleId;
  }
  @computed
  get roleDetails () {
    return this.roleStore.roleDetails[this.roleId];
  }

  constructor(props: SurveyPermissionsProps) {
    super(props);
    const { isPerSurvey } = this.roleDetails;

    this.state = {
      selectedValue: isPerSurvey ? INDIVIDUAL : ALL,
    };
  }

  handlePerSurveyChange = (isPerSurvey) => {
    this.roleStore.updateIsPerSurvey(this.roleId, isPerSurvey);
  };

  onSurveyPermissionsChange = (surveyId: string, scopes: string[]) => {
    this.roleStore.updateSurveyPermissions(this.roleId, surveyId, scopes);
  };

  onViewPermissionsChange = (surveyId: string, viewId: string, scopes: string[]) => {
    this.roleStore.updateViewPermissions(this.roleId, surveyId, viewId, scopes);
  };

  renderSurveyScopes = (surveyId: string) => {
    const availableScopes = this.roleStore.surveyScopes;
    const selectedScopes = this.roleStore.getSelectedSurveyScopes(this.roleId, surveyId);

    return (
      <Scopes
        availableScopes={availableScopes}
        selectedScopes={selectedScopes}
        onScopesChange={(scopes) => this.onSurveyPermissionsChange(surveyId, scopes)}
      />
    );
  }

  renderViewScopes = (surveyId: string, viewId: string) => {
    const availableScopes = this.roleStore.viewScopes;
    const selectedScopes = this.roleStore.getSelectedViewScopes(this.roleId, surveyId, viewId);

    if (availableScopes) {
      return (
        <Scopes
          availableScopes={availableScopes}
          selectedScopes={selectedScopes}
          onScopesChange={(scopes) => this.onViewPermissionsChange(surveyId, viewId, scopes)}
        />
      );
    }
    return null;
  }

  renderViewPermission = (surveyId: string, permission: PermissionJson) => {
    const { id: viewId, name } = permission;
    const roleDetails = (this.props.roleStore!.roles.find(r => r.id === this.roleId) || {}).policy;
    const survey = roleDetails ? (roleDetails.surveys || []).find(r => r.id === surveyId) : undefined;
    const hasRestrictions = survey && !!((survey.views || []).find(r => r.id === viewId) || {}).subResource;

    return (
      <div key={viewId} className="nw--view-permission">
        <div>
          <label className="view-name">{name}</label>
          {this.renderViewScopes(surveyId, viewId)}
        </div>
        {hasRestrictions && <div className="ui mini compact icon yellow message">
          <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
          <div className="header">
            This view has further hidden restrictions
          </div>
        </div>}
      </div>
    );
  }

  renderPermission = (permission: SurveyPermissionJson) => {
    const { id: surveyId, name, views } = permission;
    const roleDetails = (this.props.roleStore!.roles.find(r => r.id === this.roleId) || {}).policy;
    const hasRestrictions = roleDetails && !!(roleDetails.surveys.find(r => r.id === surveyId) || {}).subResource;

    return (
      <div key={surveyId} className="nw--survey-permission">
        <div>
          <label className="nw--survey-name">{name}</label>
          {this.renderSurveyScopes(surveyId)}
        </div>
        {hasRestrictions && <div className="ui mini compact icon yellow message">
          <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
          <div className="header">
            This survey has further hidden restrictions
          </div>
        </div>}
        {views && views.map(viewPermission => this.renderViewPermission(surveyId, viewPermission))}
      </div>
    );
  }

  renderPerSurveyScopes = () => {
    const perSurveyPermissions = this.roleStore.surveyPermissions.filter(p => p.id !== '*');

    return (
      <div className="survey-permissions">
        {perSurveyPermissions.map(permission => this.renderPermission(permission))}
      </div>
    );
  };

  handleSelectChange = (data) => {
    const { selectedValue } = this.state;
    const { value } = data;
    const isPerSurvey = value !== ALL;

    if (selectedValue !== value) {
      this.clear();
    }

    this.setState({ selectedValue: value });
    this.handlePerSurveyChange(isPerSurvey);
  }

  clear = () => {
    const { roleId } = this.props;
    this.roleStore.roleDetails[roleId].surveys = [];
  }

  render(): JSX.Element | null {
    const { selectedValue } = this.state;
    const { isPerSurvey } = this.roleDetails;
    const { isInModal } = this.props;

    return (
      <section>
        <strong>Datasets</strong>
        <div className="is-per-survey">
          <legend>
            Set permissions for
            <Select
              className={`nw--per-survey ${selectedValue === ALL ? 'select-short' : 'select-long'}`}
              value={selectedValue}
              options={selectOptions}
              onChange={(_e, data) => this.handleSelectChange(data)}
              // Workaround for Semantic-UI bug for scrolling modal content not working with dropdown popup z-index
              // https://github.com/Semantic-Org/Semantic-UI-React/issues/497
              upward={isInModal && selectedValue === ALL}
            />
            datasets:
          </legend>
          {!isPerSurvey && this.renderSurveyScopes('*')}
        </div>
        {isPerSurvey && this.renderPerSurveyScopes()}
      </section>
    );
  }
}

export default SurveyPermissions;
