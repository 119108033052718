import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import FilterMenuItem from 'components/Filters/FilterMenuItem';
import { FilterSearchResult, FilterSearchResultCategory, getFilterSearchRegex } from 'lib/filters/filter-search-helper';
import { flatMap } from 'lodash';
import * as React from 'react';
import FilterSearchResultList from './FilterSearchResultList';
import './filter-search-results.scss';

interface FilterSearchResultsProps {
  filtersMatchingSearchText: FilterSearchResultCategory[];
  filters: AnalysisFilter[];
  searchText: string;
  onSearchTextCleared: () => void;
  onSearchResultClick: (filter: AnalysisFilter, cut: AnalysisFilterCut) => void;
  onFreeTextFilterClick: (filter: AnalysisFilter) => void;
}

interface FilterSearchResultsState {
  activeItemIndex: number;
}

type IndexedResult = [FilterSearchResult, number];

function getIndexedResults(results: FilterSearchResult[], offset: number): IndexedResult[] {
  return results.map((result: FilterSearchResult, index) => [result, offset + index]);
}

class FilterSearchResults extends React.Component<FilterSearchResultsProps, FilterSearchResultsState> {
  state: FilterSearchResultsState = { activeItemIndex: 0 };

  componentDidMount() {
    document.addEventListener('keydown', this.onDocumentKeyDown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeyDown, true);
  }

  componentDidUpdate(prevProps: FilterSearchResultsProps, prevState: FilterSearchResultsState) {
    if (this.props.searchText !== prevProps.searchText && this.state.activeItemIndex !== 0) {
      this.setState({ activeItemIndex: 0 });
    }
    if (this.state.activeItemIndex !== prevState.activeItemIndex) {
      this.scrollActiveItemIntoView();
    }
  }

  onDocumentKeyDown = (e: KeyboardEvent) => {
    const isUp = e.key === 'ArrowUp';
    const isDown = e.key === 'ArrowDown';
    const isEnter = e.key === 'Enter';
    const isEscape = e.key === 'Escape';

    if (isUp || isDown || isEnter) {
      e.preventDefault();
      const { activeItemIndex } = this.state;
      const freeTextFilters = this.getFreeTextFilters();
      const filtersShowing = this.props.filtersMatchingSearchText;
      const flattenedResults = flatMap(filtersShowing, (f) => f.results);

      // handling the key down when searching in filters
      if (isUp && activeItemIndex > 0) {
        // "- 1" so when hit up arrow inside search result,
        // it doesn't go directly to the top option and goes one by one
        this.setState({ activeItemIndex: activeItemIndex - 1 });
      } else if (isDown && activeItemIndex < flattenedResults.length + freeTextFilters.length - 1) {
        // "+ 1" so when hit down arrow inside search result,
        // it doesn't go directly to the last option and goes one by one
        this.setState({ activeItemIndex: activeItemIndex + 1 });
      } else if (isEnter) {
        if (activeItemIndex < freeTextFilters.length) {
          // will enter the test into "response contains" directly
          this.props.onFreeTextFilterClick(freeTextFilters[activeItemIndex]);
        } else {
          const activeResult = flattenedResults.find((_, i) => i === activeItemIndex + freeTextFilters.length - 2);
          const activeCategory = activeResult
            ? filtersShowing.find((f) => f.results.includes(activeResult))
            : undefined;
          if (activeResult && activeCategory) {
            this.props.onSearchResultClick(activeCategory.source, activeResult.source as AnalysisFilterCut);
          }
        }
      }
    }

    if (isEscape) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onSearchTextCleared();
    }
  };

  scrollActiveItemIntoView = () => {
    const { activeItemIndex } = this.state;
    const element = Array.from(document.querySelectorAll('.filter-menu-item'))[activeItemIndex];
    if (element instanceof HTMLElement) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  getFreeTextFilters = () => this.props.filters.filter((filter) => filter.type === 'freetext');

  render() {
    const { searchText, filtersMatchingSearchText } = this.props;
    const { activeItemIndex } = this.state;
    const freeTextFilters = this.getFreeTextFilters();

    return (
      <div className="filter-search-results">
        <div className="filter-search-results-section-title">Text search</div>
        {freeTextFilters.map((filter, i) => (
          <FilterMenuItem
            key={filter.id}
            className="free-text-option"
            active={activeItemIndex === i}
            onClick={() => this.props.onFreeTextFilterClick(filter)}
          >
            {filter.name}: "{searchText}"
            <FontAwesomeIcon icon="search" />
          </FilterMenuItem>
        ))}
        {filtersMatchingSearchText.map((category, index) => {

          // We present search results for multiple categories.
          // To ensure the correct result item is "active", we match the activeItemIndex with an index in each category.
          // This means each item needs to have an index that is offset by
          // - the number of free text filters, plus
          // - the total number of items in categories before it
          const precedingResultCount = filtersMatchingSearchText
            .reduce((result: number, innerCategory, innerIndex): number => {
              return innerIndex >= index ? result : result + innerCategory.results.length;
            }, 0);

          const offset = precedingResultCount + freeTextFilters.length;
          const indexedResults = getIndexedResults(category.results, offset);

          return (
            <React.Fragment key={category.id}>
              <div className="filter-search-results-section-title">{category.name}</div>
              <FilterSearchResultList
                activeItemIndex={activeItemIndex}
                indexedResults={indexedResults}
                regex={getFilterSearchRegex(searchText)}
                onSearchResultClick={cut => this.props.onSearchResultClick(category.source, cut)}
              />
            </React.Fragment>
          );
      })}
      </div>
    );
  }
}

export default FilterSearchResults;
