import { DashboardWidgetConfig, ReportWidget } from "api/interfaces";
import { VolumeType } from "stores/ui/ExploreToolUIStore";

export function getVolumeBy(config: DashboardWidgetConfig): string {
  if ('volumeBy' in config) {
    if (config.volumeBy === 'countPosSentiment') {
      return VolumeType.Positive;
    }
    if (config.volumeBy === 'countNegSentiment') {
      return VolumeType.Negative;
    }
  }
  return VolumeType.All;
}

export function getWidgetSubtitle(config: DashboardWidgetConfig, data?: ReportWidget): string | null {
  const volumeBy = getVolumeBy(config);

  let title = '';

  if (volumeBy === VolumeType.Positive) {
    title = 'Top positive themes';
  } else if (volumeBy === VolumeType.Negative) {
    title = 'Top negative themes';
  } else {
    title = 'Top themes';
  }

  if (data?.metadata?.title) {
    title += ` in ${ data.metadata.title }`
  }

  return title;
}

