import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Header, Message, Modal } from 'semantic-ui-react';

interface ErrorModalProps {
  showModal: boolean;
  header: string;
  onClose: () => void;
  errorMessage?: string;
}

class ErrorModal extends React.Component<ErrorModalProps> {
  render() {
    const { showModal, header, onClose, errorMessage } = this.props;

    return (
      <Modal
        open={showModal}
        size="small"
        dimmer="blurring"
        onClose={onClose}
      >
        <Modal.Header as={Header}>{header}</Modal.Header>
        <Modal.Content>
          <Message negative={true}>
            <Message.Content>
              <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
              {errorMessage || 'Something went wrong. Please try again.'}
            </Message.Content>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ErrorModal;
