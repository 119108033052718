import { ErrorMessage } from 'api/interfaces';
import FeatureFeedbackButton from 'components/FeatureFeedback/FeatureFeedbackButton';
import * as React from 'react';
import { focusAskFormInput } from '../utils/focusAskFormInput';
import { getErrorTitle } from '../utils/getErrorTitle';
import { getSavedAnswerId } from '../utils/getSavedAnswerId';
import { Warning } from './Warning';
import { ExampleQuestions } from './ExampleQuestions';
import './error-view-with-example-questions.scss';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, {
    errorCode: 'QUESTION_OUTSIDE_DOMAIN' | 'QUESTION_NOT_SUPPORTED' | 'QUESTION_NOT_CLEAR'
  }>;
  warningTitle: string;
  warningIcon: React.ReactNode;
  warningDescription: React.ReactNode;
  onSuggestionClick: (suggestion: string) => void;
}

export const ErrorViewWithExampleQuestions = (props: Props) => {
  const title = getErrorTitle(props.question);

  const feedbackData = {
    error: props.error,
    timestamp: new Date().toISOString(),
    question: props.question,
  };

  const savedAnswerId = getSavedAnswerId();
  const locationName = `answers:${savedAnswerId || 'new'}`;

  return (
    <section className="error-view-with-example-questions">
      <h4>{title}</h4>
      <Warning
        title={props.warningTitle}
        icon={props.warningIcon}
      >
        {props.warningDescription}
      </Warning>
      <div className="error-view-with-example-questions__example-questions">
        <ExampleQuestions onSuggestionClick={props.onSuggestionClick} />
      </div>
      <p>
        Leave us feedback about use cases you are interested in.
      </p>
      <div role="toolbar">
        <FeatureFeedbackButton
          featureId="answers"
          location={locationName}
          rating={1}
          modalTextConfig={{
            title: 'Give feedback',
            subTitle: 'Your feedback helps us to continuously improve our answers.',
            textAreaPlaceholder: 'Tell us what is missing in your data, or give any other feedback.'
          }}
          data={feedbackData}
          onModalUnmount={() => focusAskFormInput()}
        />
      </div>
    </section>
  );
};