import { set } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, DropdownProps, Form, Input, Modal } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface, ScoreConfig } from 'stores/ui/ConfigureDataUIStore';
import { FieldError, required } from 'validations';
import './configure-score-options.scss';

interface ConfigureScoreOptionsStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureScoreOptionsProps extends ConfigureScoreOptionsStoreProps {
  score: ScoreConfig;

  onClose: () => void;
  onCancel: () => void;
}

interface ConfigureScoreOptionsState {
  threshold?: number;
  thresholdError: FieldError;
}

@inject('configureDataUIStore')
@observer
export default class ConfigureScoreOptions extends
  React.Component<ConfigureScoreOptionsProps, ConfigureScoreOptionsState> {

  state = {
    threshold: 0,
    thresholdError: undefined
  };

  componentDidMount () {
    const { score_options } = this.props.score;
    if (score_options && score_options.threshold) {
      this.setState({ threshold: score_options.threshold });
    }
  }

  cancel = () => {
    this.props.onCancel();
  }

  save = () => {
    const { configureDataUIStore, score } = this.props;
    const { threshold } = this.state;

    const change = { ...score };

    set(change, 'score_options.threshold', threshold);

    configureDataUIStore!.updateScore(score.id, change);
    this.props.onClose();
  }

  handleThresholdChange = ({ value }: DropdownProps) => {
    const thresholdError = required(value as number);
    this.setState({ threshold: value as number, thresholdError });
  }

  render() {
    const { threshold, thresholdError } = this.state;
    const hasErrors = !!thresholdError;

    return <Modal
      className="configure-score-options"
      dimmer="blurring"
      open={true}
      onClose={this.cancel}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Edit Score
      </Modal.Header>
      <Modal.Content>
        <Form>
          <div className="score-type">
            <Form.Field
              error={!!thresholdError}
            >
              <label>Threshold</label>
              <Input
                placeholder="Threshold"
                value={threshold}
                type="number"
                onChange={(_e, data) => this.handleThresholdChange(data)}
              />
              {thresholdError && thresholdError}
            </Form.Field>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={this.cancel}>
          Cancel
        </Button>
        <Button
          color="blue"
          onClick={this.save}
          disabled={hasErrors}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>;
  }
}
