import { IntegrationType } from 'api/enums';
import { map, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceSnowflakeReaderCreateFormProps {
  existingConfiguration?: SnowflakeReaderConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface SnowflakeReaderShareInfo {
  title: string;
  shareId: string;
  kind: string;
}
interface SnowflakeReaderTableInfo {
  title: string;
  tableId: string;
  kind: string;
  rowCount: number;
}

interface SnowflakeReaderConfig {
  shareId: string;
  tableId?: string;
}

export interface DataSourceSnowflakeReaderCreateFormState {
  selectedShare?: SnowflakeReaderShareInfo;
  selectedTable?: SnowflakeReaderTableInfo;
  config?: SnowflakeReaderConfig;

  availableShares?: SnowflakeReaderShareInfo[];
  availableTables?: SnowflakeReaderTableInfo[];
}

@observer
class DataSourceSnowflakeReaderCreateForm extends React.Component<
  DataSourceSnowflakeReaderCreateFormProps,
  DataSourceSnowflakeReaderCreateFormState
> {
  state = {} as DataSourceSnowflakeReaderCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedShare = (_e, { value }: FormInputProps) => {
    const selectedShare = JSON.parse(value);

    const config = { shareId: selectedShare.shareId };

    this.setState({ selectedShare, config });

    if (this.props.setValidity) {
      this.props.setValidity(false); // need table
    }
  }

  changeSelectedTable = (_e, { value }: FormInputProps) => {
    let { config } = this.state;
    const selectedTable = JSON.parse(value);

    // can't set a table if the share hasn't been set
    if (!config || !config.shareId) {
      return;
    }

    // should now have everything
    config = {
      shareId: config.shareId,
      tableId: selectedTable.tableId
    };

    this.setState({ selectedTable, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!config.tableId); // all fields are set
    }
  }

  onEnumeration = (availableItems: SnowflakeReaderShareInfo[] | SnowflakeReaderTableInfo[]): void => {
    const { config } = this.state;

    if (!config || !config.shareId) {
      const availableShares = availableItems as SnowflakeReaderShareInfo[];
      this.setState({ availableShares });
    }
    else if (!config.tableId) {
      const availableTables = availableItems as SnowflakeReaderTableInfo[];
      this.setState({ availableTables });
    }
  }

  getExistingShare = (
    availableShares: SnowflakeReaderShareInfo[],
    existingConfiguration?: SnowflakeReaderConfig
  ) => {
    return availableShares.find(({ shareId }) => shareId == existingConfiguration?.shareId);
  }

  getExistingTable = (
    availableTables: SnowflakeReaderTableInfo[],
    existingConfiguration?: SnowflakeReaderConfig
  ) => {
    return availableTables.find(({ tableId }) => tableId == existingConfiguration?.tableId);
  }

  renderForm(): JSX.Element | null {
    const { selectedShare, selectedTable, availableShares, availableTables } = this.state;
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableShareOptions = map(availableShares, item => {
      return {
        text: item.title,
        value: JSON.stringify(item)
      };
    });
    const sortedAvailableShareOptions = sortBy(availableShareOptions, 'text');

    const availableTableOptions = map(availableTables, item => {
      return {
        text: item.title,
        value: JSON.stringify(item)
      };
    });

    if (availableShares) {
      const defaultShareValue = existingConfiguration
        ? JSON.stringify(this.getExistingShare(availableShares, existingConfiguration))
        : undefined;

      const defaultTableValue = existingConfiguration
        ? JSON.stringify(this.getExistingTable(availableTables || [], existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Share</label>

            <Dropdown
              selection={true}
              options={sortedAvailableShareOptions}
              defaultValue={defaultShareValue}
              placeholder="Select"
              onChange={this.changeSelectedShare}
            />
          </Form.Field>
          {selectedShare && (
            <Form.Field>
              <label>Table</label>

              <Dropdown
                selection={true}
                search={true}
                options={availableTableOptions}
                defaultValue={defaultTableValue}
                placeholder="Select"
                onChange={this.changeSelectedTable}
              />
            </Form.Field>
          )}
          {selectedTable && (
            <div>
              <Form.Field>
                <label>Title</label> <span>{selectedTable.title}</span>
              </Form.Field>
              <Form.Field>
                <label>Row count</label> <span>{selectedTable.rowCount}</span>
              </Form.Field>
            </div>
          )}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={this.onEnumeration}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.SNOWFLAKEREADER}
          enumerationSelection={config} />
      </div>
    );
  }
}

export default DataSourceSnowflakeReaderCreateForm;
