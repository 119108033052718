import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { createHierarchicalFilterOptions, FilterOption, getHierarchicalFilterChildResults } from 'lib/filters/hierarchical-filter-helper';
import { compact, flatMap } from 'lodash';
import { TagInfo } from 'stores/TagStore';
import { createCategoryFilterOptions } from './category-filter-helper';
import { createSentimentFilterOptions } from './sentiment-filter-helper';
import { createTagFilterOptions } from './tag-filter-helper';
import { createThemeFilterOptions, ThemesObject } from './theme-filter-helper';
import { QueryFilter } from 'stores/types';

export interface SearchFilterCut extends AnalysisFilterCut {
  fullName?: string;
  individualId?: string;
}

export type FilterSearchResult = {
  id: string;
  name: string;
  isSelected: boolean;
  source: SearchFilterCut | FilterOption;
  fullName?: string;
  children?: FilterSearchResult[];
};

export type FilterSearchResultCategory = {
  id: string;
  name: string;
  source: AnalysisFilter;
  type: string | undefined;
  results: FilterSearchResult[];
};

const escapeRegExp = (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

// Matches start of string or start of word:
export const getFilterSearchRegex = (searchText: string) => new RegExp(`(^| )(${ escapeRegExp(searchText) })`, 'i');

const optionToResult = (option: FilterOption): FilterSearchResult => {
  return {
    id: option.id || '',
    isSelected: option.isSelected,
    // only child options will have full name
    name: option.fullName || option.name,
    source: option
  };
};

const getHierarchicalCategory = (
  filter: AnalysisFilter,
  regex: RegExp,
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  const filterOptions = filter.cuts ? createHierarchicalFilterOptions(filter.cuts, queryFilter) : [];
  const parentResult: FilterOption[] = filterOptions
    .filter((cut) => cut.id !== 'all_' && regex.test(cut.name));
  // there can be multiple levels in this filter so recursvie method to find the searched term child options
  const childResult =
    compact(flatMap(filterOptions, (cut) =>
      cut.children && getHierarchicalFilterChildResults(cut.children, regex)
    ));
  const results = [...parentResult, ...childResult].map(optionToResult);
  return { id: filter.id, name: filter.name, source: filter, results, type: filter.type };
};

const getThemeCategory = (
  filter: AnalysisFilter,
  regex: RegExp,
  themes: ThemesObject,
  isFeedbackTool: boolean,
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  const themeFilterOptions = createThemeFilterOptions(themes, queryFilter, isFeedbackTool);
  const parentResult = themeFilterOptions
    .filter((parent) => parent.id !== 'all_' && regex.test(parent.name));
  const childResult =
    compact(flatMap(themeFilterOptions, (parent) => (parent.children || [])
      .filter(child => regex.test(child.name))
      .map(childValue => {
        return {
          ...childValue,
          fullName: `${ parent.name }: ${ childValue.name }`,
          id: childValue.id
        };
      })
    ));
  const results = [...parentResult, ...childResult].map(optionToResult);
  return { id: filter.id, name: filter.name, source: filter, results, type: filter.type };
};

const getTagsCategory = (
  filter: AnalysisFilter,
  regex: RegExp,
  tags: TagInfo[],
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  const tagFilterOptions = createTagFilterOptions(tags, queryFilter);
  // searching in both parent tags and child tags
  const parentResult = tagFilterOptions
    .filter((parent) => parent.id !== 'all_' && regex.test(parent.name));
  const childResult =
    compact(flatMap(tagFilterOptions, (parent) => (parent.children || [])
      .filter(child => regex.test(child.name))
      .map(childValue => {
        return {
          ...childValue,
          fullName: `${ parent.name }: ${ childValue.name }`,
          id: childValue.id
        };
      })
    ));
  const results = [...parentResult, ...childResult].map(optionToResult);

  return { id: filter.id, name: filter.name, source: filter, results, type: filter.type };
};

const getListCategory = (
  filter: AnalysisFilter,
  regex: RegExp,
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  // List filter.
  const results = (filter.cuts || [])
    .filter((cut) => cut.id !== 'all_' && regex.test(cut.name))
    .map((cut: AnalysisFilterCut) => {

      const selected = queryFilter?.selected ?? [];
      const isSelected = selected.some(s => s.id && cut.id && s.id === cut.id);

      return {
        id: cut.id || '',
        name: cut.name,
        source: cut,
        isSelected
      };
    });

  return { id: filter.id, name: filter.name, source: filter, results, type: filter.type };
};

const getSentimentCategory = (
  filter: AnalysisFilter,
  regex: RegExp,
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  const filterOptions = createSentimentFilterOptions(queryFilter);
  // List filter.
  const results = filterOptions
    .filter((entry) => entry.id !== 'all_' && regex.test(entry.name))
    .map(optionToResult);

  return { id: filter.id, name: filter.name, source: filter, results, type: filter.type };
};

const getCategoryCategory = (
  filter: AnalysisFilter,
  regex: RegExp,
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  const filterOptions = createCategoryFilterOptions(queryFilter);
  // List filter.
  const results = filterOptions
    .filter((entry) => entry.id !== 'all_' && regex.test(entry.name))
    .map(optionToResult);

  return { id: filter.id, name: filter.name, source: filter, results, type: filter.type };
};

export const getFilterMatchingSearchText = (
  filter: AnalysisFilter,
  regex: RegExp,
  themes: ThemesObject,
  tags: TagInfo[],
  isFeedbackTool: boolean,
  queryFilter?: QueryFilter
): FilterSearchResultCategory => {
  // mapping each type differently
  if (filter.type === 'themes') {
    return getThemeCategory(filter, regex, themes, isFeedbackTool, queryFilter);
  } else if (filter.type === 'hierarchical') {
    return getHierarchicalCategory(filter, regex, queryFilter);
  } else if (filter.type === 'tags') {
    return getTagsCategory(filter, regex, tags, queryFilter);
  } else if (filter.type === 'sentiment') {
    return getSentimentCategory(filter, regex, queryFilter);
  } else if (filter.type === 'categories') {
    return getCategoryCategory(filter, regex, queryFilter);
  } else if (filter.type === undefined) {
    return getListCategory(filter, regex, queryFilter);
  }
  return { id: filter.id, name: filter.name, source: filter, results: [], type: filter.type };
};

export const getAllFiltersMatchingSearchText = (
  filters: AnalysisFilter[],
  searchText: string,
  tags: TagInfo[],
  themes: ThemesObject,
  isFeedbackTool: boolean,
  queryFilters: Record<string, QueryFilter>
): FilterSearchResultCategory[] => {
  const regex = getFilterSearchRegex(searchText);

  // Here we are getting the all the categories of filters with their values in which the searched term exist
  return filters.map(filter => {
      const queryFilter = queryFilters[filter.id];
      return getFilterMatchingSearchText(filter, regex, themes, tags, isFeedbackTool, queryFilter);
    })
    .filter((filter) => filter.results.length > 0);

};
