
export interface Column {
  index: number;
  name: string;
  type: string;
  dayFirst?: boolean;
  isComment?: boolean;
  isScore?: boolean;
  totalValues: number;
  uniqueValues: number;
  sampleHeader: string;
  sample: string[];
}

export enum CommentTreatmentValues {
  SAME_THEMES = 'same_themes',
  CONCATENATE = 'concatenate'
}

export const COMMENT_TREATMENT_OPTIONS = [
  {
    text: 'Same themes for all columns',
    value: CommentTreatmentValues.SAME_THEMES
  },
  {
    text: 'Concatenate columns into one',
    value: CommentTreatmentValues.CONCATENATE
  }
];