import { AnalysisFilter } from 'api/interfaces';
import * as React from 'react';
import { ExportAssistantDateSelect } from './ExportAssistantDateSelect';
import { ExportAssistantFilterValuesSelect } from './ExportAssistantFilterValuesSelect';
import { AvailableDateRange, Category, DateOptions } from './types';

interface Props {
  availableDateRange: AvailableDateRange;
  category: Category;
  updateCategory: (categoryUpdate: Category) => void;
  filters: AnalysisFilter[];
}

export const ExportAssistantCategoryValuesSelect: React.FC<Props> = ({
  availableDateRange,
  category,
  updateCategory,
  filters,
}) => {
  const handleDateSelectChange = (dateOptions: DateOptions) => {
    updateCategory({
      ...category,
      dateOptions,
    });
  };

  if (category.type === 'date') {
    return (
      <ExportAssistantDateSelect
        availableDateRange={availableDateRange}
        onDateSelectChange={handleDateSelectChange}
      />
    );
  } else if (category.type === 'filter') {
    const filter = filters.find(f => f.id === category.id);
    if (filter && filter.cuts) {
      return (
        <ExportAssistantFilterValuesSelect
          filter={filter}
          category={category}
          updateCategory={updateCategory}
        />
      );
    }
  }

  return <></>;
};
