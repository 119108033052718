import { AnalysisFilterCut } from 'api/interfaces';
import { compact, forIn, isString, map, reduce, trim } from 'lodash';
import { QueryFilter } from 'stores/types';
import { utcToDateString } from 'lib/date';

export interface QueryFilterObject {
  [key: string]: QueryFilter;
}

/*
using the queryFilter object, loop thru each key and
generate the fully formed querystring used
*/
const build = queryFilters => {
  // Need to collect all the selected cuts
  // cuts within a filter are expected to be 'OR'ed together. cuts between filters are expected to be 'AND'ed
  // FIXME: unnecessarily wraps lists of 1 item
  const q: string[] = [];
  forIn(queryFilters, filter => {
    const w: string[] = [];
    forIn(filter.selected, sel => {
      if (sel && sel.rql) {
        w.push('(' + sel.rql + ')');
      }
    });
    const ws = w.join(',');
    if (ws !== '') {
      q.push('(' + ws + ')');
    }
  });

  const qs = q.join(';');
  return qs;
};

function getSelectedIds(queryFilters: QueryFilterObject) {
  return reduce(
    queryFilters,
    (result, filter) => {
      const { filterId } = filter;
      const selected = compact(filter.selected); // remove falsey values from `selected`
      const selections = map(selected, 'id');
      if (selections.length && selections.join('') !== 'all_') {
        result[filterId] = selections;
      }

      return result;
    },
    {}
  );
}

function collectNames(queryFilters: QueryFilter[]) {
  const q: string[] = [];
  forIn(queryFilters, (filter: QueryFilter) => {
    // we only consider adding the name if the cut has a query string
    let namesArray: string[] = [];
    forIn(filter.selected, (sel: AnalysisFilterCut) => {
      if (sel && sel.name && sel.name !== 'All') {
        namesArray.push(sel.name);
      }
    });
    let name: string = namesArray.join('/');
    if (name !== '') {
      q.push(name);
    }
  });
  return q;
}

const buildTitle = (queryFilters: QueryFilter[]) => {
  const names = collectNames(queryFilters);

  const title = names.join(', ') || 'All';

  return title;
};

const buildShortTitle = queryFilters => {
  const names = collectNames(queryFilters);

  const { length } = names;
  if (length === 0) {
    return 'All';
  } else if (length === 1) {
    return '1 Filter';
  } else {
    return `${length} Filters`;
  }
};

function buildDateQuery(id: string, startDate: Date, endDate: Date) {
  let filterString = '';
  if (startDate) {
    const startDateStr = utcToDateString(startDate);
    filterString = appendToFilter(filterString, id + '=ge=' + startDateStr);
  }
  if (endDate) {
    const endDateStr = utcToDateString(endDate);
    filterString = appendToFilter(filterString, id + '=le=' + endDateStr);
  }

  return filterString;
}

function appendToFilter(filter: string, querystring: string, concatenator = ';') {
  const filterValid = isString(filter) && !!trim(filter);
  const queryStringValid = isString(querystring) && !!trim(querystring);
  // if the filter string isn't real, return the querystring
  if (!filterValid) {
    return queryStringValid ? querystring : '';
  }
  // if the querysstring isn't real, return the filter
  if (!queryStringValid) {
    // NB: don't have to check filter valid, will be caught above
    return filter;
  }
  let filterOut = `(${filter})`;
  if (filterOut && filterOut[filterOut.length - 1] !== ';') {
    filterOut += concatenator;
  }
  filterOut += `(${querystring})`;
  return filterOut;
}

function appendSegmentFilterToFilter(filter: string, segmentFilter: string, concatenator = ';') {
  return appendToFilter(filter, `segment==${encodeURIComponent(segmentFilter)}`, concatenator);
}

function appendToSegmentFilter(segmentFilter: string, querystring: string) {
  const segmentFilterValid = isString(segmentFilter) && !!trim(segmentFilter);
  const queryStringValid = isString(querystring) && !!trim(querystring);
  // if the filter string isn't real, return the querystring
  if (!segmentFilterValid) {
    return queryStringValid ? querystring : '';
  }
  // if the querysstring isn't real, return the filter
  if (!queryStringValid) {
    // NB: don't have to check filter valid, will be caught above
    return segmentFilter;
  }
  return `${segmentFilter}|${querystring}`;
}

export function buildRql(...filters: string[]) {
  return reduce(
    filters,
    (result, filter) => {
      return appendToFilter(result, filter);
    },
    ''
  );
}

function appendThemesToFilter(filter: string, theme: string, subtheme: string) {
  if (subtheme) {
    filter = appendToFilter(filter, `theme==*!${theme}!${subtheme}$*`);
  } else {
    filter = appendToFilter(filter, `theme==*!${theme}!*`);
  }
  return filter;
}

function appendThemeLevelToFilter(filter: string, theme: string, level: string) {
  if (level === 'theme') {
    return appendToFilter(filter, `theme==*!${theme}!*`);
  } else if (level === 'subtheme') {
    return appendToFilter(filter, `theme==*!${theme}$*`);
  }
  return;
}

function appendThemesToSegmentFilter(segmentFilter: string, theme: string, subtheme?: string) {
  if (subtheme) {
    segmentFilter = appendToSegmentFilter(segmentFilter, `theme:${theme}!${subtheme}`);
  } else {
    segmentFilter = appendToSegmentFilter(segmentFilter, `theme:${theme}`);
  }
  return segmentFilter;
}

export default {
  build,
  buildTitle,
  buildShortTitle,
  buildDateQuery,
  getSelectedIds,
  appendToFilter,
  appendThemesToFilter,
  appendThemeLevelToFilter,
  appendSegmentFilterToFilter,
  appendToSegmentFilter,
  appendThemesToSegmentFilter
};
