<template>
  <div class="configure-widget-fields">
    <configure-widget-score
      v-if="fieldHasScore"
      :target="fieldHasScore.target"
      :analysis-config="analysisConfig"
      :widget-config="widgetConfig"
      @onConfigChange="onConfigUpdate"
    />
    <configure-widget-size
      v-if="fieldHasSize"
      :target="fieldHasSize.target"
      :widget-config="widgetConfig"
      @onConfigChange="onConfigUpdate"
    />
    <configure-widget-summary-type
      v-if="fieldHasSummaryType"
      :target="fieldHasSummaryType.target"
      :widget-config="widgetConfig"
      @onConfigChange="onConfigUpdate"
    />
    <div
      v-for="field in configFields"
      :key="field.target"
    >
      <configure-widget-show-summary
        v-if="field.type === ConfigFieldType.SHOW_SUMMARY && isShowSummaryEnabled"
        :target="field.target"
        :widget-config="widgetConfig"
        @onConfigChange="onConfigUpdate"
      />
      <configure-widget-show-comments
        v-if="field.type === ConfigFieldType.SHOW_COMMENTS"
        :target="field.target"
        :widget-config="widgetConfig"
        @onConfigChange="onConfigUpdate"
      />
    </div>
    <el-collapse>
      <el-collapse-item title="Advanced options">
        <div
          v-for="field in configFields"
          :key="field.target"
        >
          <configure-widget-title
            v-if="field.type === ConfigFieldType.TITLE"
            :target="field.target"
            :widget-config="widgetConfig"
            @onConfigChange="onConfigUpdate"
          />
          <configure-widget-sort
            v-if="field.type === ConfigFieldType.SORT"
            :target="field.target"
            :selected-widget="widgetType"
            :widget-config="widgetConfig"
            @onConfigChange="onConfigUpdate"
          />
          <configure-widget-ordering
            v-if="field.type === ConfigFieldType.ORDERING"
            :target="field.target"
            :selected-widget="widgetType"
            :widget-config="widgetConfig"
            @onConfigChange="onConfigUpdate"
          />
          <configure-widget-theme-level
            v-if="field.type === ConfigFieldType.THEME_LEVEL"
            :target="field.target"
            :widget-config="widgetConfig"
            @onConfigChange="onConfigUpdate"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import ConfigureWidgetScore from './ConfigureWidgetScore.vue';
import ConfigureWidgetSize from './ConfigureWidgetSize.vue';
import ConfigureWidgetSort from './ConfigureWidgetSort.vue';
import { ConfigFieldType } from '../types';
import { CONFIG_FIELDS } from '../constants';
import { values } from 'lodash';
import ConfigureWidgetTitle from './ConfigureWidgetTitle.vue';
import ConfigureWidgetOrdering from './ConfigureWidgetOrdering.vue';
import ConfigureWidgetShowComments from './ConfigureWidgetShowComments.vue';
import ConfigureWidgetShowSummary from './ConfigureWidgetShowSummary.vue';
import ConfigureWidgetThemeLevel from './ConfigureWidgetThemeLevel.vue';
import ConfigureWidgetSummaryType from './ConfigureWidgetSummaryType.vue';

import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';

export default {
  name: 'ConfigureWidgetFields',
  components: {
    ConfigureWidgetScore,
    ConfigureWidgetSize,
    ConfigureWidgetSummaryType,
    ConfigureWidgetTitle,
    ConfigureWidgetSort,
    ConfigureWidgetOrdering,
    ConfigureWidgetShowComments,
    ConfigureWidgetShowSummary,
    ConfigureWidgetThemeLevel,
  },
  props: {
    isNew: { type: Boolean },
    widgetType: { type: String },
    widgetConfig: { type: Object },
    analysisConfig: { type: Object },
  },
  data() {
    return {
      ConfigFieldType: ConfigFieldType,
      validation: {},
    };
  },
  computed: {
    configFields() {
      return CONFIG_FIELDS[this.widgetType];
    },
    fieldHasScore() {
      return this.configFields.find((options) => options.type === ConfigFieldType.SCORE);
    },
    fieldHasSize() {
      return this.configFields.find((options) => options.type === ConfigFieldType.SIZE);
    },
    fieldHasSummaryType() {
      return this.configFields.find((options) => options.type === ConfigFieldType.SUMMARY_TYPE);
    },
    isShowSummaryEnabled() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_SUMMARIES_ON_DASHBOARD);
    },
  },
  watch: {
    widgetType: {
      handler() {
        // reset validation fields on widget type change
        this.validation = {};

        this.configFields.forEach((field) => {
          const { target } = field;
          // Assumes an initial valid state when editing an existing widget
          let isValid = true;
          if (this.isNew) {
            // If new widget, initially valid if the field is not required
            isValid = !field.isRequired;
          }
          this.validation[target] = isValid;
        });
        this.updateValidity();
      },
      immediate: true,
    },
  },
  methods: {
    updateConfig(target, update, isValid) {
      this.validation[target] = isValid;
      this.$emit('onConfigUpdate', target, update);
    },
    updateValidity() {
      const isValid = !values(this.validation).some((isValid) => !isValid);
      this.$emit('onValidityChange', isValid);
    },
    onConfigUpdate(target, update, isValid) {
      this.updateConfig(target, update, isValid);
      this.updateValidity();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';
.configure-widget-fields {
  ::v-deep .el-collapse {
    border-top: none;
    .el-collapse-item {
      &__header {
        flex-direction: row-reverse;
        border-bottom: none;
        font-size: 14px;
        justify-content: flex-end;
        color: $--color-primary;
      }
      &__wrap {
        border-bottom: none;
      }
      &__content {
        padding-bottom: 0;
      }
      &__arrow {
        margin: 0 2px 0 0;
      }
    }
  }
}
</style>
