import * as React from 'react';
import { Column, Columns } from './types';

const columnOptions = [
  {
    label: 'Count',
    value: Columns.count,
  },
  {
    label: 'Volume',
    value: Columns.volume,
  },
  {
    label: 'Impact',
    value: Columns.impact,
  },
  {
    label: 'Score',
    value: Columns.score,
  },
  {
    label: 'Sentiment counts',
    value: Columns.sentimentCounts,
  }
];

interface Props {
  disabledColumns: Column[];
  setColumns: (columns: Column[]) => void;
  columns: Column[];
}

const ExportAssistantColumnSelect = ({
  disabledColumns,
  setColumns,
  columns
}: Props) => {
  const addColumn = (column: Column) => {
    const updatedColumns = [...columns, column];

    setColumns(updatedColumns);
  };

  const removeColumn = (column: Column) => {
    const updatedColumns = columns.filter((col) => col !== column);

    setColumns(updatedColumns);
  };

  const handleColumnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      addColumn(event.target.name as Column);
    } else {
      removeColumn(event.target.name as Column);
    }
  };

  const columnIsSelected = (column: Column) => columns.includes(column);

  const selectOptions = columnOptions.filter(({ value }) => !disabledColumns.includes(value));

  return (
    <ul className="export-assistant-columns">
      {selectOptions.map(({ label, value }) => (
        <li className="export-assistant-columns__item" key={value}>
          <span className="ui checkbox">
            <input
              id={value}
              name={value}
              className="hidden"
              onChange={handleColumnCheckboxChange}
              type="checkbox"
              checked={columnIsSelected(value)}
            />
            <label htmlFor={value}>{label}</label>
          </span>
        </li>
      ))}
    </ul>
  );
};

export { ExportAssistantColumnSelect };
