import Updating from 'images/icons/updating.svg';
import * as React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import './processing-data-modal.scss';

interface ProcessingDataModalProps {
  header: string;
  showUpdatingImage?: boolean;
  buttonText?: string;
  onClose: () => void;
}

class ProcessingDataModal extends React.Component<ProcessingDataModalProps> {
  render(): JSX.Element | null {
    const { header, showUpdatingImage, buttonText, onClose } = this.props;

    return (
      <Modal
        dimmer="blurring"
        open={true}
        onClose={onClose}
        size="tiny"
        className="processing-data-modal"
      >
        <Modal.Header className="modal-header">
          {header}
        </Modal.Header>
        <Modal.Content>
          {this.props.children}
          {showUpdatingImage &&
            <div className="while-updating-wrapper">
              <Updating className="ui active centered inline" />
            </div>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={onClose}
          >
            {buttonText || 'Ok'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ProcessingDataModal;
