import Auth from 'Auth/Auth';
import { SavedAnswer, SavedAnswerCandidate, SavedAnswerMeta, SavedAnswerResponse } from 'api/interfaces';
import analytics from 'lib/analytics';
import { flatten, parseAsNested } from 'lib/dataset-tool-parser';
import { action, computed, observable } from 'mobx';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { DatasetVis, DatasetsNestedMenu } from 'stores/data-structure/datasets';
import { toMeta } from './utils/answers-helpers';

export enum AnswerError {
  NONE = 'none',
  UNKNOWN = 'unknown',
  NO_SAVED_ANSWERS = 'noSavedAnswers',
  FAILED_TO_FETCH_ANSWER = 'failedToFetchAnswer'
}

export interface AnswersStoreInterface {
  userHasAccessToAnswers: boolean;

  flatDatasets: DatasetVis[];
  nestedDatasets: DatasetsNestedMenu;

  abortController: AbortController | null;
  deleteAnswer: (savedAnswerId: string) => Promise<void>;
  error: AnswerError;
  fetchAnswer: (savedAnswerId: string) => Promise<SavedAnswer | null>;
  fetchAnswers: () => Promise<void>;
  init: () => void;
  isDeletingAnswer: boolean;
  isFetchingAnswer: boolean;
  isFetchingAnswers: boolean;
  isSavingAnswer: boolean;
  reset: () => void;
  saveAnswer: (candidate: SavedAnswerCandidate, savedAnswersExists: boolean) => Promise<SavedAnswerResponse | null>;
  savedAnswerMetas: SavedAnswerMeta[];
  savedAnswers: Map<string, SavedAnswer>;
  upsert: (savedAnswer: SavedAnswer) => void;

  emailPreviewHtml: string | null;
  emailPreviewError: Error | null;
  isFetchingEmailPreview: boolean;
  fetchEmailPreview: (savedAnswerId: string) => void;
  resetEmailPreviewHtml: () => void;

  sendEmailError: Error | null;
  isSendingEmail: boolean;
  hasSentEmail: boolean;
  sendEmail: (savedAnswerId: string, emails: string[], emailSubject: string, emailBody: string) => Promise<string | null>;
  resetEmailRequestState: () => void;
}

export default class AnswersStore implements AnswersStoreInterface {
  currentOrg: OrganizationStoreInterface;
  surveyStore: SurveyStoreInterface;

  abortController: AbortController | null = null;
  emailPreviewAbortController: AbortController | null = null;

  @observable
  isSavingAnswer = false;

  @observable
  isFetchingAnswer = false;

  @observable
  isFetchingAnswers = false;

  @observable
  savedAnswerMetas: SavedAnswerMeta[] = [];

  @observable
  savedAnswers = new Map();

  @observable
  error: AnswerError = AnswerError.NONE;

  @observable
  isDeletingAnswer = false;

  @observable
  emailPreviewError: Error | null = null;

  @observable
  emailPreviewHtml: string | null = null;

  @observable
  isFetchingEmailPreview = false;

  @observable
  sendEmailError: Error | null = null;

  @observable
  isSendingEmail = false;

  @observable
  hasSentEmail = false;

  @computed
  get userHasAccessToAnswers() {
    return this.nestedDatasets.children.filter(child => child.isActive).length > 0;
  }

  @computed
  get flatDatasets() {
    const { nestedDatasets } = this;
    return flatten(nestedDatasets);
  }
  @computed
  get nestedDatasets() {
    const { surveysJson, getSurveyCanAction } = this.surveyStore;
    const { orgId, getCanAction } = this.currentOrg;
    const canThematicAdmin = getCanAction('manage:internalResource');
    if (surveysJson && orgId) {
      return parseAsNested(orgId, surveysJson, getSurveyCanAction, canThematicAdmin, 'create:answer');
    } else {
      return { children: [], title: '' };
    }
  }

  constructor(
    currentOrg: OrganizationStoreInterface, surveyStore: SurveyStoreInterface
  ) {
    this.currentOrg = currentOrg;
    this.surveyStore = surveyStore;
  }

  @action
  handleError(message?: string) {

    if (message?.includes('Saved answers not found')) {
      this.error = AnswerError.NO_SAVED_ANSWERS;
      return;
    }

    this.error = AnswerError.UNKNOWN;

  }

  @action
  async deleteAnswer(id: string) {
    const orgId = this.currentOrg.orgId;

    try {
      analytics.track('Answers: Saved Answer', { 'Operation': 'Delete' });

      // The deletion will be optimistic on the FE.
      // We capture these so that if the request fails, we can restore the meta.
      const metaIndex = this.savedAnswerMetas.findIndex(item => item.id === id);
      const meta = this.savedAnswerMetas.length > metaIndex ? this.savedAnswerMetas[metaIndex] : null;

      if (!meta) {
        return;
      }

      this.isDeletingAnswer = true;
      this.savedAnswerMetas = this.savedAnswerMetas.filter(item => item.id !== id);

      this.abortController = new AbortController();
      const response = await Auth.fetch<{ id: string }>(`/savedAnswer/${ id }?organization=${ orgId }`, {
        method: 'DELETE',
        signal: this.abortController.signal
      });

      if (!response.ok) {
        // For now we'll just put it back where it was
        this.savedAnswerMetas.splice(metaIndex, 0, meta);
      }

    } catch (e) {
      this.handleError(e.message);
    } finally {
      this.isDeletingAnswer = false;
    }

  }

  upsert(savedAnswer: SavedAnswer) {

    const meta: SavedAnswerMeta = toMeta(savedAnswer);

    const index = this.savedAnswerMetas.findIndex(m => m.id === meta.id);

    if (index === -1) {
      this.savedAnswerMetas = [meta, ...this.savedAnswerMetas];
    } else {
      this.savedAnswerMetas[index] = meta;
    }

    this.savedAnswers.set(savedAnswer.id, savedAnswer);

  }

  @action
  async saveAnswer(
    savedAnswer: SavedAnswerCandidate | SavedAnswer,
    savedAnswersExists: boolean
  ): Promise<SavedAnswerResponse | null> {
    const orgId = this.currentOrg.orgId;

    if (this.isSavingAnswer) {
      return null;
    }

    try {

      const url = savedAnswersExists
        ? `/savedAnswer/${ savedAnswer.id }?organization=${ orgId }`
        : `/savedAnswer?organization=${ orgId }`;

      const method = savedAnswersExists ? 'PUT' : 'POST';

      this.isSavingAnswer = true;
      this.abortController = new AbortController();
      const response = await Auth.fetch<SavedAnswerResponse>(url, {
        method,
        signal: this.abortController.signal,
        body: JSON.stringify({
          version: 'version1',
          savedAnswer: savedAnswer
        })
      });

      if (!response.ok) {
        return null;
      }

      return response.data ?? null;

    } catch (e) {
      return null;
    } finally {
      this.isSavingAnswer = false;
    }

  }

  @action
  async fetchAnswer(savedAnswerId: string): Promise<SavedAnswer | null> {
    analytics.track('Answers: Saved Answer', { 'Operation': 'Resume' });
    const orgId = this.currentOrg.orgId;
    try {
      this.isFetchingAnswer = true;
      this.abortController = new AbortController();

      const response = await Auth.fetch<SavedAnswer>(
        `/savedAnswer/${ savedAnswerId }?organization=${ orgId }&version=version1`,
        { signal: this.abortController.signal }
      );

      if (response.ok && response.data) {
        this.savedAnswers.set(response.data.id, response.data);
        return response.data;
      } else {
        this.error = AnswerError.FAILED_TO_FETCH_ANSWER;
        return null;
      }

    } catch (e) {
      this.error = AnswerError.FAILED_TO_FETCH_ANSWER;
      return null;
    } finally {
      this.isFetchingAnswer = false;
    }

  }

  @action
  async fetchAnswers() {
    const orgId = this.currentOrg.orgId;
    try {
      this.isFetchingAnswers = true;
      this.abortController = new AbortController();

      const response = await Auth.fetch<SavedAnswerMeta[]>(
        `/savedAnswers?organization=${ orgId }&version=version1`,
        { signal: this.abortController.signal }
      );

      if (response.ok && response.data) {
        this.savedAnswerMetas = response.data;
      } else {
        this.handleError(response.errorData?.message);
      }

    } catch (e) {
      this.handleError(e.message);
    } finally {
      this.isFetchingAnswers = false;
    }

  }

  @action
  async fetchEmailPreview(savedAnswerId: string) {
    if (this.emailPreviewAbortController) {
      this.emailPreviewAbortController.abort();
    }
    this.emailPreviewAbortController = new AbortController();
    const abortSignal = this.emailPreviewAbortController.signal;

    this.emailPreviewError = null;
    this.isFetchingEmailPreview = true;

    try {
      const response = await Auth.fetch<string>(`/savedAnswer/${ savedAnswerId }/createDigest`,
        {
          method: 'POST',
          isText: true,
          signal: abortSignal
        }
      );

      if (!response.ok || !response.data) {
        throw new Error(response.errorData?.message);
      }

      this.emailPreviewHtml = response.data;

    } catch (error) {
      if (!abortSignal.aborted) {
        this.emailPreviewError = error;
      }
    } finally {
      if (!abortSignal.aborted) {
        this.isFetchingEmailPreview = false;
      }
    }

  }

  @action
  async sendEmail(savedAnswerId: string, emails: string[], emailSubject: string, emailBody: string) {
    this.sendEmailError = null;
    this.isSendingEmail = true;
    this.hasSentEmail = false;

    try {
      const response = await Auth.fetch<string>(`/savedAnswer/${savedAnswerId}/sendDigest`,
        {
          method: 'POST',
          body: JSON.stringify({
            emailAddresses: [...emails],
            emailSubject,
            emailBody
          }),
        }
      );

      if (!response.ok || !response.data) {
        throw new Error(response.errorData?.message);
      }

      this.hasSentEmail = true;
      return response.data;

    } catch (error) {
      this.sendEmailError = error;
      return null;
    } finally {
      this.isSendingEmail = false;
    }

  }

  @action
  resetEmailRequestState() {
    this.hasSentEmail = false;
    this.sendEmailError = null;
    this.emailPreviewError = null;
  }

  @action
  init() {
    // retrieve saved answers, if this user can
    if (this.userHasAccessToAnswers) {
      this.fetchAnswers();
    }
  }

  @action
  resetEmailPreviewHtml() {
    this.emailPreviewHtml = null;
  }

  @action
  reset() {

    this.isSavingAnswer = false;
    this.isFetchingAnswer = false;
    this.isFetchingAnswers = false;
    this.savedAnswerMetas = [];
    this.savedAnswers = new Map();
    this.abortController?.abort();
    this.abortController = null;
    this.error = AnswerError.NONE;
    this.emailPreviewHtml = null;

  }

}
