import {
  Conversation,
  ThreadedComment
} from 'types/custom';
import {
  ThreadDisplayOptions,
} from 'api/interfaces';

type ListListPairs = Conversation['pairs'];
type ListPairs = Conversation['pairs'][0];
type Pair = Conversation['pairs'][0][0];

type Result = {
  items: Array<Conversation>,
  currentPairs: ListListPairs
};

export default function toThreadParts(
  threadDisplayConfig: ThreadDisplayOptions,
  threadedComments: Array<ThreadedComment>,
): Array<Conversation> {
  let currentDepth: number | undefined = undefined;
  let currentAuthor: 'comment' | 'submission' | undefined = undefined;
  let currentResult: ListPairs = [];

  const nestedThreadedComments = threadedComments.reduce((
    result: ListListPairs,
    threadedComment: ThreadedComment,
    partIndex: number
  ): ListListPairs => {

    const partAuthor = threadedComment.data[threadDisplayConfig.author!];
    const partDepth = threadedComment.data[threadDisplayConfig.depth!];

    if ( partAuthor !== currentAuthor || partDepth !== currentDepth) {
      if ( currentResult.length ) {
        result.push(currentResult);
        currentResult = [];
      }
      currentAuthor = partAuthor;
      currentDepth = partDepth;
    }

    const pair: Pair = {
      threadedComment,
      meta: {
        author: partAuthor,
        depth: partDepth ? partDepth : 0,
        partIndex: partIndex
      }
    };

    currentResult.push(pair);

    return result;
  }, []);

  // the final part needs appending too
  if ( currentResult.length ) {
    nestedThreadedComments.push(currentResult);
  }

  const collapsableThreadParts = nestedThreadedComments.reduce((
    result: Result,
    pairs: ListPairs
  ): Result => {

    const isSupportMessage: boolean = (threadDisplayConfig.hiddenThreadParts || []).includes(pairs[0].meta.author);

    const partHasThemes: boolean = pairs.reduce((hasThemes, pair) => {
      const themes = pair.threadedComment.segments.reduce((res, seg) => [...res, ...seg.themes], []);
      return hasThemes || themes.length > 0;
    }, false);

    // if it is collapsable add it to the current list of collapsable
    if (isSupportMessage || !partHasThemes) {
      result.currentPairs.push(pairs);
    } else {
      // else we need to add all upstream collapsable and then the current message individually
      if ( result.currentPairs.length > 0) {
        result.items.push({
          pairs: result.currentPairs,
          collapsible: true,
          depth: result.currentPairs[0][0].meta.depth
        });
        result.currentPairs = [];
      }
      result.items.push({
        pairs: [pairs],
        collapsible: false,
        depth: pairs[0].meta.depth
      });
    }

    return result;

  }, {items: [], currentPairs: []});

  // if the thread ends in collapsable parts, add them
  if ( collapsableThreadParts.currentPairs.length > 0) {
    collapsableThreadParts.items.push({
      pairs: collapsableThreadParts.currentPairs,
      collapsible: true,
      depth: collapsableThreadParts.currentPairs[0][0].meta.depth
    });
  }

  return collapsableThreadParts.items;

}
