import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import * as React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import './data-source-twitter.scss';

export interface DataSourceTwitterCreateFormProps {
  existingConfiguration?: TwitterConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface TwitterConfig {
  users: string[];
  searches: string[];
}

interface DataSourceTwitterCreateFormState {
  config: TwitterConfig;
}

class DataSourceTwitterCreateForm extends React.Component<
  DataSourceTwitterCreateFormProps,
  DataSourceTwitterCreateFormState
  > {

  // config starts with one entry because it makes sense
  state = {
    config: {
      users: [] as string[],
      searches: [] as string[],
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;

    if (existingConfiguration) {
      this.setState({
        config: {
          users: existingConfiguration.users,
          searches: existingConfiguration.searches,
        }
      });
    }

    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  updateExternal = () => {
    const { config } = this.state;

    if (this.props.onChange) {
      this.props.onChange(config, '');
    }
  }

  addUserEntry = () => {
    // adds a new entry to the bottom of the list
    const { config } = this.state;
    const newConfig = {
      users: [
        ...config.users,
        ''
      ],
      searches: config.searches
     };

    this.setState({ config: newConfig }, () => {
      this.checkValidity();
    });
  };

  addSearchesEntry = () => {
    // adds a new entry to the bottom of the list
    const { config } = this.state;
    const newConfig = {
      searches: [
        ...config.searches,
        ''
      ],
      users: config.users
     };

    this.setState({ config: newConfig }, () => {
      this.checkValidity();
    });
  };

  checkValidity = () => {
    if (this.props.setValidity) {
      // TODO: this should find non-empty elements only
      const isValid = ((this.state.config.users.length + this.state.config.searches.length) > 0) &&
                      (this.state.config.users.every(entry => entry.startsWith('@'))) &&
                      (this.state.config.searches.every(entry => entry.length > 0));
      this.props.setValidity(isValid);
    }
  }

  render(): JSX.Element | null {
    const { config } = this.state;

    const existingUsers = map(config.users, (user, index) => {

      return (
        <Form.Field key={index}>
          <div className="twitter-row">
            <Input
              className="input"
              placeholder="@getthematic"
              value={user}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                // handle change in app store id
                const { config: localConfig } = this.state;
                localConfig.users[index] = e.currentTarget.value;
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                });

                this.checkValidity();
              }}
            />
            <Button
              className="delete"
                onClick={() => {
                // remove the selected entry
                const { config: localConfig } = this.state;
                localConfig.users.splice(index, 1);
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                  this.checkValidity();
                });
              }}
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="trash"
                  fixedWidth={true}
                />
              } />
          </div>
          <div className="twitter-row">
            <div className="helper-text">Users should look like @getthematic</div>
          </div>
        </Form.Field>
      );
    });

    const existingSearches = map(config.searches, (search, index) => {

      return (
        <Form.Field key={index}>
          <div className="twitter-row">
            <Input
              className="input"
              placeholder="Search terms"
              value={search}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                // handle change in app store id
                const { config: localConfig } = this.state;
                localConfig.searches[index] = e.currentTarget.value;
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                });

                this.checkValidity();
              }}
            />
            <Button
              className="delete"
              onClick={() => {
                // remove the selected entry
                const { config: localConfig } = this.state;
                localConfig.searches.splice(index, 1);
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                  this.checkValidity();
                });
              }}
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="trash"
                  fixedWidth={true}
                />
              } />
          </div>
          <div className="twitter-row">
            <div className="helper-text">Searches should be in the same format as entered at twitter.com</div>
          </div>
        </Form.Field>
      );
    });

    return (
      <div>
        <Form.Field>
          <label>Users to monitor</label>
        </Form.Field>
        {existingUsers}
        <Form.Field>
          <Button onClick={this.addUserEntry}>Add User</Button>
        </Form.Field>
        <Form.Field>
          <label>Search terms to monitor</label>
        </Form.Field>
        {existingSearches}
        <Form.Field>
          <Button onClick={this.addSearchesEntry}>Add Search Terms</Button>
        </Form.Field>
      </div>
    );
  }
}

export default DataSourceTwitterCreateForm;