import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceSurvicateCreateFormProps {
  existingConfiguration?: SurvicateConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface SurvicateInfo {
  name: string;
  id: string;
  responses: number;
}

interface SurvicateConfig {
  surveyIds: string[];
}

export interface DataSourceSurvicateCreateFormState {
  selectedSurvey?: SurvicateInfo;
  config?: SurvicateConfig;
}

@observer
class DataSourceSurvicateCreateForm extends React.Component<
DataSourceSurvicateCreateFormProps,
DataSourceSurvicateCreateFormState
> {
  state = {} as DataSourceSurvicateCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedSurvey = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedSurvey = JSON.parse(value);

    const config = {
      surveyIds: [selectedSurvey.id]
    };

    this.setState({ selectedSurvey, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedSurvey.id);
    }
  }

  setDefaultSurvey = (availableSurveys: SurvicateInfo[]): void => {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    if (availableSurveys.length === 0 || !existingConfiguration || selectedSurvey) {
      return;
    }

    const defaultSelectedSurvey = this.getExistingSurvey(availableSurveys, existingConfiguration);

    if (!defaultSelectedSurvey) {
      return;
    }

    this.setState({
      selectedSurvey: defaultSelectedSurvey,
      config: existingConfiguration,
    });
  }

  getExistingSurvey = (
    availableSurveys: SurvicateInfo[],
    existingConfiguration?: SurvicateConfig
  ) => {
    return availableSurveys.find(({ id }) => existingConfiguration?.surveyIds.includes(id));
  }

  renderForm(availableSurveys: SurvicateInfo[] | undefined): JSX.Element | null {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableSurveyOptions = map(availableSurveys, item => {
      return {
        text: '[' + item.id + ']\t' + item.name,
        value: JSON.stringify(item)
      };
    });

    if (availableSurveys) {
      const defaultValue = existingConfiguration
        ? JSON.stringify(this.getExistingSurvey(availableSurveys, existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Survey</label>

            <Dropdown
              selection={true}
              options={availableSurveyOptions}
              defaultValue={defaultValue}
              placeholder="Select"
              onChange={this.changeSelectedSurvey}
            />
          </Form.Field>
          { selectedSurvey && (
            <div>
               <Form.Field>
                <label>Survey ID</label> <span>{selectedSurvey.id}</span>
              </Form.Field>
              <Form.Field>
                <label>Title</label> <span>{selectedSurvey.name}</span>
              </Form.Field>
              <Form.Field>
                <label>Responses</label> <span>{selectedSurvey.responses}</span>
              </Form.Field>
            </div>
          )}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={(availableSurveys: SurvicateInfo[]) => this.setDefaultSurvey(availableSurveys)}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.SURVICATE} />
      </div>
    );
  }
}

export default DataSourceSurvicateCreateForm;
