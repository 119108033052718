import classnames from 'classnames';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { NotificationStoreInterface } from 'stores/NotificationStore';
import usePrevious from '../../hooks/usePrevious';
import NOTIFICATION_ICONS from './icons';
import './notification.scss';

type Props = {
  notificationStore?: NotificationStoreInterface;
};

type Animation = 'OUT' | 'ENTERING' | 'IN' | 'LEAVING';

const Notification = observer((props: Props) => {

  const [animation, setAnimation] = React.useState<Animation>('ENTERING');
  const prevAnimation = usePrevious(animation);

  const store = props.notificationStore;
  const currentNotification = store?.current;

  React.useEffect(() => {

    if (!currentNotification) {
      setAnimation('OUT');
    }
    if (prevAnimation === 'OUT') {
      setAnimation('ENTERING');
    }

  }, [prevAnimation, currentNotification]);

  const item = store!.current;

  if (!item || item.variant !== 'distinct') {
    return null;
  }

  const className = classnames(`ui card toast`, {
    'toast--entering': animation === 'ENTERING',
    'toast--in': animation === 'IN',
    'toast--leaving': animation === 'LEAVING',
    'toast--clickable': item.action
  });

  let Icon = item.icon ? NOTIFICATION_ICONS[item.icon] : null;

  return (
    <div
      onClick={e => {

        const target = e.target as HTMLElement;

        if (target instanceof HTMLButtonElement || target.classList.contains('close')) {
          return;
        }

        props.notificationStore!.current?.action?.();

      }}
      className={className}
      onAnimationEnd={() => setAnimation('IN')}
      onTransitionEnd={() => {
        if (animation === 'LEAVING') {
          setAnimation('OUT');
          props.notificationStore!.dismiss(item.id);
        }
      }}
      role="alert"
    >
      {Icon && <Icon />}
      {item.title && <strong className="toast__title">{item.title}</strong>}
      <span className={classnames('toast__message', { link: item.link })}>{item.message}</span>
      {!item.preventClose && (
        <Button
          icon="close"
          disabled={animation === 'LEAVING'}
          onClick={() => setAnimation('LEAVING')}
        />
      )}
    </div>
  );
});

export default inject('notificationStore')(Notification);
