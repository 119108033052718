import * as React from 'react';
import INTEGRATION_LOGOS from './logos';

interface IntegrationLogoProps {
  integrationType: string;
}

export default class IntegrationLogo extends React.Component<IntegrationLogoProps> {
  render() {
    const { integrationType } = this.props;
    const Logo = INTEGRATION_LOGOS[integrationType];
    const logoIsSVG = typeof(Logo) === 'function';

    return (
      <div className="integration-logo">
        {logoIsSVG ? (<Logo />) : (<img src={Logo}/>)}
      </div>
    );
  }
}
