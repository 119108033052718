import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoCutSortType, AutoCutType, FilterType, ScoreType } from 'api/enums';
import { SurveyConfiguration } from 'api/interfaces';
import GripDotsVerticalIcon from 'images/icons/grip-dots-vertical.svg';
import { isASubset } from 'lib/object-helper';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, DropdownProps, Form, Input, InputProps } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface, DisplayFilterType, FilterConfig } from 'stores/ui/ConfigureDataUIStore';
import { FieldError, required } from 'validations';
import ConfigureScoreCut from './ConfigureScoreCut';
import { CUSTOM_FILTER_OPTIONS, CUSTOM_FILTER_TYPE, FILTER_TYPE_OPTIONS } from './constants';
import './sortable-column.scss';

interface ConfigureFilterStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureFilterProps extends ConfigureFilterStoreProps {
  id: string;
  toggleForbidDrag: (forbidDrag: boolean) => void;
}

interface ConfigureFilterState {
  isCustom: boolean;
  nameError: FieldError;
  showAdvancedOptions: boolean;
}

@inject('configureDataUIStore')
@observer
export default class ConfigureFilter extends React.Component<ConfigureFilterProps, ConfigureFilterState> {

  state = {
    isCustom: false,
    showAdvancedOptions: false,
    nameError: undefined
  };

  get filter () {
    const { id, configureDataUIStore } = this.props;
    return configureDataUIStore!.getFilter(id);
  }

  get hasAdvancedOptions () {
    // For now we only show the cog for score type autocut
    return !(this.filter || {}).type && get(this.filter, 'auto_cut.type') === AutoCutType.SCORE;
  }

  get hasScoreCutOptions () {
    return get(this.filter, 'auto_cut.type') === AutoCutType.SCORE;
  }

  get filterType () {
    const type = (this.filter || {}).type;
    const autoCutType = get(this.filter, 'auto_cut.type');

    switch (type) {
      case FilterType.HIERARCHICAL:
        return DisplayFilterType.HIERARCHICAL;
      case FilterType.DATE:
        return DisplayFilterType.DATE;
      case FilterType.FREETEXT:
        return DisplayFilterType.FREETEXT;
      default:
        switch (autoCutType) {
          case AutoCutType.SCORE:
            return DisplayFilterType.SCORE;
          default:
            return DisplayFilterType.DEFAULT;
        }
    }
  }

  get filterTypeOptions () {
    const { type, column } = this.filter || {};

    // We don't let people to change the type for freetext filter since it may not have a column value
    if (type === FilterType.FREETEXT && column === undefined) {
      return FILTER_TYPE_OPTIONS.map(option => {
        if (option.value !== DisplayFilterType.FREETEXT) {
          return { ...option, disabled: true };
        }
        return option;
      });
    }

    return FILTER_TYPE_OPTIONS;
  }

  componentDidMount () {
    this.checkForUnsupportedFilter();
  }

  checkForUnsupportedFilter = () => {
    /*
      We make any filter that we don't understand (yet) to be custom
      and uneditable except for name and deleting the filter
     */

    if (!this.filter) {
      return;
    }

    let knownSchema = {
      id: '',
      name: '',
      column: '',
      type: ''
    } as object;

    // Default filter has auto_cuts
    if (!this.filter.type) {
      knownSchema = {
        ...knownSchema,
        auto_cut: {
          include_all: '',
          type: '',
          options: {
            ordering: '',
            sorting: '',
            score_type: ''
          }
        }
      };
    }

    const isCustom = !isASubset(knownSchema, this.filter);

    this.setState({ isCustom });
  }

  handleNameChange = ({ value }: InputProps, filter: FilterConfig) => {
    const { id, configureDataUIStore } = this.props;
    const change = { ...filter, name: value };

    const nameError = required(value);

    /* Only update the error count when valid status changes to avoid updating it on each change */
    if (nameError !== this.state.nameError) {
      configureDataUIStore!.updateErrorCount(!!nameError);
    }

    this.setState({ nameError });

    configureDataUIStore!.updateFilter(id, change);
  }

  handleTypeChange = ({ value }: DropdownProps, filter: FilterConfig) => {
    const { id, configureDataUIStore } = this.props;
    const isDefaultType = value === DisplayFilterType.SCORE || value === DisplayFilterType.DEFAULT;

    let type = isDefaultType ? undefined : value;
    let change = { ...filter, type: type } as FilterConfig;

    let surveyConfig = JSON.parse(configureDataUIStore!.rawSurveyConfig) as SurveyConfiguration;
    if (surveyConfig && surveyConfig.filters) {
      const originalFilterConfig = surveyConfig.filters.find(f => f.id === id);

      // If user is flicking back and forth between filter types,
      // when switching back to the original, revert to original value
      if (originalFilterConfig && originalFilterConfig.type === change.type &&
          this.filter && this.filter.type !== type) {
        change = originalFilterConfig;
      } else {
        if (value === DisplayFilterType.SCORE) {
          let scoreType = ScoreType.AVERAGE;
          const score = filter.column ? configureDataUIStore!.getScoreByColumn(filter.column) : undefined;
          if (score && score.score_type) {
            scoreType = score.score_type;
          }
          change.auto_cut = {
            ...change.auto_cut,
            include_all: true,
            type: AutoCutType.SCORE,
            options: {
              ...change.auto_cut?.options,
              score_type: scoreType
            }
          };
        } else if (value === DisplayFilterType.DEFAULT) {
          change.auto_cut = {
            ...change.auto_cut,
            include_all: true,
            type: AutoCutType.LIST,
            options: {
              ...change.auto_cut?.options,
              ordering: AutoCutSortType.ALPHABETICAL
            }
          };
        }
      }
    }
    configureDataUIStore!.updateFilter(id, change);
  }

  removeFilter = () => {
    const { id, configureDataUIStore } = this.props;
    /* Remove the associated error count if invalid */
    if (this.state.nameError) {
      configureDataUIStore!.decrementErrorCount();
    }
    configureDataUIStore!.removeFilter(id);
  }

  showAdvancedOptions = () => {
    this.setState({ showAdvancedOptions: true });
  }

  hideAdvancedOptions = () => {
    this.setState({ showAdvancedOptions: false });
  }

  render() {
    if (!this.filter) {
      return null;
    }

    const { toggleForbidDrag } = this.props;
    const { name } = this.filter;
    const { nameError, isCustom, showAdvancedOptions } = this.state;
    const namePlaceHolder = nameError ? 'Name is required' : 'Name';

    if (showAdvancedOptions) {
      if (this.hasScoreCutOptions) {
        return <ConfigureScoreCut
          filter={this.filter}
          onClose={this.hideAdvancedOptions}
        />;
      }
    }

    return (
      <div className="sortable-column">
        <GripDotsVerticalIcon />
        <div className="column-name">
          <Form.Field
            error={!!nameError}
          >
            <Input
              onFocus={() => toggleForbidDrag(true)}
              onBlur={() => toggleForbidDrag(false)}
              placeholder={namePlaceHolder}
              value={name}
              onChange={(_e, data) => this.handleNameChange(data, this.filter!)}
            />
          </Form.Field>
        </div>
        <div className="column-type">
          <Form.Field>
            <label>Type</label>
            {isCustom && <Dropdown
              options={CUSTOM_FILTER_OPTIONS}
              disabled={true}
              value={CUSTOM_FILTER_TYPE}
            />}
            {!isCustom && <Dropdown
              options={this.filterTypeOptions}
              placeholder="Select"
              value={this.filterType}
              selectOnBlur={false}
              onChange={(_e, data) => this.handleTypeChange(data, this.filter!)}
            />}
          </Form.Field>
        </div>
        <div className="column-actions">
          {this.hasAdvancedOptions &&
            <Button
              basic={true}
              onClick={this.showAdvancedOptions}
              key="settings"
              size="small"
              className="settings"
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="cog"
                  fixedWidth={true}
                />
              }
            />
          }
          <Button
            basic={true}
            onClick={this.removeFilter}
            key="delete"
            size="small"
            className="delete"
            icon={
              <FontAwesomeIcon
                className="icon"
                icon="trash-alt"
                fixedWidth={true}
              />
            }
          />
        </div>
      </div>
    );
  }
}
