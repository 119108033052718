import { flatMap, includes } from 'lodash';
import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { PhraseSegment, PhraseSegmentTheme } from 'types/custom';
import { isNonSpecialTheme } from 'lib/special-theme-predicates';

export const compareThemes = (selectedThemeCodes: ThemeCodes) => (a: PhraseSegmentTheme, b: PhraseSegmentTheme): 1 | -1 => {

  const aMatchBase = a.base === selectedThemeCodes.base;
  const bMatchBase = b.base === selectedThemeCodes.base;
  const aMatchSub = a.sub === selectedThemeCodes.sub;
  const bMatchSub = b.sub === selectedThemeCodes.sub;

  // Order partial matches before full matches
  if (aMatchBase && !aMatchSub && bMatchBase && bMatchSub) {
    return -1; // prefer A
  }
  if (aMatchBase && aMatchSub && bMatchBase && !bMatchSub) {
    return 1; // prefer B
  }

  // Order matches before non-matches
  if (aMatchBase && !bMatchBase && !bMatchSub) {
    return -1; // prefer A
  }

  if (!aMatchBase && !aMatchSub && bMatchBase) {
    return 1; // prefer B
  }

  // Order by title
  return a.title < b.title ? -1 : 1;

};

export default function segmentsToOrderedThemes(
  segments: Array<PhraseSegment>,
  selectedThemeCodes: ThemeCodes,
): Array<PhraseSegmentTheme> {

  const filteredThemes = flatMap(segments, segment => segment.themes)
    .filter(isNonSpecialTheme)
    .map(theme => {
      const { base, sub, title } = theme;
      if (base === sub && includes(title, ':')) {
        theme.title = title.substring(0, title.indexOf(':'));
      }
      return theme;
    });

  const uniqThemes = filteredThemes.reduce((result: Array<PhraseSegmentTheme>, theme) => {

      const partialMatch = result.find(t => t.base === theme.base && t.sub !== theme.sub);
      const fullMatch = result.find(t => t.base === theme.base && t.sub === theme.sub);

      if (!partialMatch && !fullMatch) {
        // Keep unique themes
        return [...result, theme];
      }

      if (fullMatch) {
        // Discard duplicate themes
        return result;
      }

      if (partialMatch && partialMatch.base === partialMatch.sub) {
        // Prefer themes with subthemes
        return [...result.filter(t => !(t.base === partialMatch.base && t.sub === partialMatch.sub)), theme];
      }

      if (partialMatch && theme.base === theme.sub) {
        // Prefer the partial match
        return result;
      }

      return [...result, theme];

    }, []);

  const compareWithCodes = compareThemes(selectedThemeCodes);

  return uniqThemes.sort(compareWithCodes);

}
