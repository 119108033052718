import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidName } from 'lib/form-validation';
import * as React from 'react';
import * as validator from 'validator';

export type FieldError = JSX.Element | undefined;

export function isEmail(value = ''): JSX.Element | undefined {
  if (validator.isEmail(value)) {
    return undefined;
  } else {
    return <div className="error-message">'{value}' is not a valid email</div>;
  }
}

export function isName(value = ''): JSX.Element | undefined {
  if (isValidName(value)) {
    return undefined;
  } else {
    return <div className="error-message">'{value}' is not a valid name</div>;
  }
}


export function required(value: string | number = '', name?: string) {
  if (value.toString().trim().length) {
    return undefined;
  } else if (name) {
    return <div className="error-message">{name} is required</div>;
  } else {
    return <div className="error-message">This field is required</div>;
  }
}

export function matches(value = '', sourceValue = '', name: string) {
  if (value === sourceValue) {
    return undefined;
  } else {
    return <div className="error-message">This field must match {name}</div>;
  }
}

export function passwordComplexity(value = '') {
  const rules = [
    {rule: '.{8,}', error: 'Be at least 8 characters'},
    {rule: '(?=.*[A-Z])', error: 'Have an upper case letter'},
    {rule: '(?=.*[a-z])', error: 'Have an lower case letter'},
    {rule: '(?=.*[0-9])', error: 'Have a number'},
  ];
  let passedRules = [] as string[];
  let failedRules = [] as string[];
  rules.forEach(rule => {
    if (!value.match(rule.rule)) {
      failedRules.push(rule.error);
    } else {
      passedRules.push(rule.error);
    }
  });
  if (failedRules.length === 0) {
    return undefined;
  } else {
    return (
      <div className="error-message">
        <div className="error-message-title">Password requirements:</div>
        <div>
          <ul>
            {passedRules.map(item => (
              <li key={item}><FontAwesomeIcon className="passed-icon" icon="check-circle" /> {item}</li>
            ))}
            {failedRules.map(item => (
              <li key={item}><FontAwesomeIcon className="failed-icon" icon="exclamation-triangle" /> {item}</li>
            ))}
          </ul>
        </div>
      </div>);
  }
}

export function initialValidationState<T>(value: T): FieldValidation<T> {
  return { error: undefined, pristine: true, value };
}

export interface FieldValidation<T> {
  value: T;
  error: JSX.Element | undefined;
  pristine: boolean;
}
