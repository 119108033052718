const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function clampTimeOfDay(value: number) {
  if (value < 0) {
    return value + 24;
  } else if (value >= 24) {
    return value - 24;
  } else {
    return value;
  }
}

function getTimezoneOffsetInHours() {
  // getTimezoneOffSet returns opposite value therefore minus in front
  return -Math.floor(new Date().getTimezoneOffset() / 60);
}

export function convertTimeOfDayToUtc(value: number) {
  const offset = getTimezoneOffsetInHours();
  return clampTimeOfDay(value - offset);
}

export function convertTimeOfDayFromUtc(value: number) {
  const offset = getTimezoneOffsetInHours();
  return clampTimeOfDay(value + offset);
}

export function getTimezoneAbbreviation() {
  const timeZone = new Date().toTimeString().slice(19, -1);
  if (isSafari) {
    return timeZone;
  } else {
    return timeZone.split(' ').map(word => word[0]).join('');
  }
}