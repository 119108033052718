import { ColumnType, SurveyDataStatus } from 'api/enums';
import { Column as SynopsisColumn } from 'api/interfaces';
import { drop } from 'lodash';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { getAverageWordCount, isValueAScore } from './string-helper';

export const isSurveyNameValid = (name: string): boolean => !!name && name.length <= 50;

export const generateColumnId = (name: string, existingIds: string[]) => {
  const strippedName = name.replace(/[^A-Z0-9]/ig, '').toLowerCase();
  const generatedId = `${strippedName}_`;

  const nameAlreadyExists = (existingIds || []).find(n => n === generatedId);
  if (nameAlreadyExists) {
    // We want the id to be unique, if id already exists,
    // using existing names length should resolve duplicates
    return `${strippedName}${existingIds.length}_`;
  }
  return generatedId;
};

export const hasFailedImport = (survey: LiteSurvey): boolean => {
  const { dataStatus, dataSourceIntegration } = survey;

  if (!dataSourceIntegration) {
    return false;
  }

  const noData = dataStatus === SurveyDataStatus.NODATA;

  let lastFailureDateTimestamp;
  if (dataSourceIntegration?.lastFailureDate) {
    lastFailureDateTimestamp = new Date(dataSourceIntegration.lastFailureDate).getTime();
  }

  let lastSuccessDateTimestamp;
  if (dataSourceIntegration?.lastSuccessDate) {
    lastSuccessDateTimestamp = new Date(dataSourceIntegration.lastSuccessDate).getTime();
  }

  return noData
    ? !!dataSourceIntegration.lastFailureDate
    : lastFailureDateTimestamp > lastSuccessDateTimestamp;
};

export const isColumnAComment = (column: SynopsisColumn) => {
  // Comment type if column is of type text and has at least x% unique values and average word count is more than y
  const UNIQUE_VALUE_THRESHOLD_FOR_COMMENTS = 101; // Backend only sends us a max of 101 unique value count
  const AVERAGE_WORD_COUNT_THRESHOLD = 4;

  if (column.colType === ColumnType.TEXT &&
    column.numUniqueValues >= UNIQUE_VALUE_THRESHOLD_FOR_COMMENTS) {
    const averageWordCount = getAverageWordCount(column.sample);
    if (averageWordCount > AVERAGE_WORD_COUNT_THRESHOLD) {
      return true;
    }
  }
  return false;
};

export const isColumnAScore = (column: SynopsisColumn) => {
  // Score type if column is of type number and has no more than 100 unique values
  // And no value is greater than 100
  const UNIQUE_VALUE_THRESHOLD_FOR_SCORE = 100;
  const MAX_VALUE_FOR_SCORE = 100;

  if (column.colType === ColumnType.NUMBER &&
    column.numUniqueValues <= UNIQUE_VALUE_THRESHOLD_FOR_SCORE &&
    // First value could be a header, check the rest to see if there is atleast one value that isnt a score
    // i.e., all values are scores
    !drop(column.sample).find(s => !!s && !isValueAScore(s, MAX_VALUE_FOR_SCORE))) {
    return true;
  }
  return false;
};
