import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SavedAnswerMeta } from 'api/interfaces';
import * as React from 'react';
import { Button, Input, Loader } from 'semantic-ui-react';
import { LinkButton } from 'components/Shared/LinkButton';
import { getSortedMetadata } from '../utils/getSortedMetadata';
import { SavedAnswer } from './SavedAnswer';
import './saved-answers.scss';

export interface SavedAnswersProps {
  isLoading: boolean;
  metadata: SavedAnswerMeta[];
  hasError: boolean;
  savedAnswerId?: string;
  canShowNewButton: boolean;
  onRetry: () => void;
  onSelect: (id: string) => void;
  onDelete: (id: string) => void;
  onShare: (id: string) => void;
  onNewQuestion: () => void;
  onDownload: () => void;
}

interface SavedAnswersState {
  searchValue: string;
}

export class SavedAnswers extends React.Component<SavedAnswersProps, SavedAnswersState> {
  searchInputRef = React.createRef<Input>();

  state = {
    searchValue: ''
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  }

  renderMetadata = (metadata: SavedAnswerMeta[]) => {
    return metadata.map((data: SavedAnswerMeta) => {
      const isSelected = data.id === this.props.savedAnswerId;
      return (
        <SavedAnswer
          key={data.id}
          metadata={data}
          isSelected={isSelected}
          onSelect={(id) => {
            if (id !== this.props.savedAnswerId) {
              this.props.onSelect(id);
            }
          }}
          onDelete={this.props.onDelete}
          onShare={this.props.onShare}
          onDownload={this.props.onDownload}
        />
      );
    });
  }

  filteredMetadata = (metadata: SavedAnswerMeta[], searchValue: string): SavedAnswerMeta[] => {
    const regex = new RegExp(searchValue, 'i');
    return metadata.filter(
      (meta: SavedAnswerMeta) => regex.test(meta.synopsis)
    );
  }

  refocusSearchInput() {
    this.searchInputRef.current?.focus();
  }

  clearSearch() {
    this.setState({ searchValue: '' });
    this.refocusSearchInput();
  }

  getContent() {

    const hasAnswers = this.props.metadata.length > 0;

    if (this.props.isLoading) {
      return <Loader className="saved-answers__loader" size="large" active={true} />;
    }

    if (this.props.hasError) {

      return (
        <section className="saved-answers__error">
          Something went wrong while fetching answers. Please try again.
          <Button
            color="blue"
            className="saved-answers__error-action"
            onClick={this.props.onRetry}
          >
            Load answers
          </Button>
        </section>
      );

    }

    if (!hasAnswers) {
      return (
        <section className="saved-answers__empty">
          <h2 className="saved-answers__empty-header">Your saved answers will be listed here</h2>
        </section>
      );
    }

    const { searchValue } = this.state;

    const sortedMetadata = getSortedMetadata(this.props.metadata);
    const searchResults = Object.values(sortedMetadata).flatMap(
      (metadata) => this.filteredMetadata(metadata, searchValue)
    );
    return (
      <section className="saved-answers__content">
          {searchResults.length > 0 && searchValue.length > 0 &&
            <h2 className="saved-answers__results-count">
              {searchResults.length}
              {searchResults.length === 1 ? ' Result ' : ' Results '}
              for <span className="saved-answers__search-value">"{searchValue}"</span>
            </h2>
          }
          {searchResults.length === 0 && searchValue.length > 0 &&
            <h2 className="saved-answers__no-results">
              No results,
              <LinkButton onClick={() => this.clearSearch()}>Clear search</LinkButton>
            </h2>
          }
        <ul className="saved-answers__list">
          {Object.entries(sortedMetadata).map(([key, metadata]) => {
            const currentDate = new Date();
            const currentMonth = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });

            const filteredMetadata = this.filteredMetadata(metadata, searchValue);
            return (
              <li className="saved-answers__list-item" key={key}>
                {filteredMetadata.length > 0 &&
                  <h2 className="saved-answers__title ob-saved-answers-title">
                    {key === currentMonth ? 'Recent' : key}
                  </h2>
                }
                <ul className="saved-answers__grouped-list">
                  {this.renderMetadata(filteredMetadata)}
                </ul>
              </li>
            );
          })}
        </ul>
      </section>
    );
  }

  render() {
    const { canShowNewButton, onNewQuestion } = this.props;
    const { searchValue } = this.state;

    return (
      <aside
        className="saved-answers"
        role="complementary"
      >
        <header className="saved-answers__header">
          Answers
          { canShowNewButton &&
            <Button
              color="blue"
              className="saved-answers__header-action ob-new"
              tabIndex={0}
              onClick={onNewQuestion}
            >
              <FontAwesomeIcon
                className="saved-answers__header-icon"
                fixedWidth={true}
                icon="plus"
              />
              New
            </Button>
          }
        </header>
        <Input
          ref={this.searchInputRef}
          role="textbox"
          type="text"
          className="saved-answers__search"
          icon={
            searchValue.length > 0 ?
              <FontAwesomeIcon
                className="saved-answers__close-icon"
                fixedWidth={true}
                icon="times"
                aria-label="clear search"
                tabIndex={0}
                onClick={() => this.clearSearch()}
                onKeyDown={
                  (e: React.KeyboardEvent) => e.key === 'Enter' && this.clearSearch()
                }
              />
            :
              <FontAwesomeIcon
                className="saved-answers__search-icon"
                fixedWidth={true}
                icon="search"
              />
          }
          placeholder="Search"
          value={searchValue}
          onChange={this.handleInputChange}
        />
        {this.getContent()}
      </aside>
    );
  }
}
