export enum CustomDashboardWidgetType {
  UNIFIED_THEMES = 'UNIFIED_THEMES',
  IMPACT_SIMPLE_ASC = 'IMPACT_SIMPLE_ASC',
  IMPACT_SIMPLE_DESC = 'IMPACT_SIMPLE_DESC',
  SIGNIFICANT_CHANGES_VOLUME = 'SIGNIFICANT_CHANGES_VOLUME',
  SIGNIFICANT_CHANGES_EMERGING = 'SIGNIFICANT_CHANGES_EMERGING'
}

export enum ConfigWidgetSize {
  HALF = 'HALF',
  FULL = 'FULL'
}