import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationStatus } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Label, Loader, SemanticCOLORS } from 'semantic-ui-react';
import {
  IntegrationsStoreInterface
} from 'stores/IntegrationsStore';
import IntegrationLogo from './IntegrationLogo';

interface ConnectedIntegrationProps {
  checking: boolean;
  integration: ConnectedIntegration;
  integrationsStore?: IntegrationsStoreInterface;
}

interface ConnectedIntegrationState {
  testing: boolean;
  testingResult?: boolean;
}

@inject('integrationsStore')
@observer
export default class ConnectedIntegrationComponent extends React.Component<
 ConnectedIntegrationProps,
 ConnectedIntegrationState
 > {
  state = {
    testing: false,
  } as ConnectedIntegrationState;
  acceptIntegrationsForm = null as unknown as () => Promise<boolean> | null;

  disconnect = () => {
    const { integrationsStore: store, integration } = this.props;
    if (store && integration.id) {
      store.deleteIntegration(integration.id);
    }
  };
  test = async () => {
    const { integrationsStore: store, integration } = this.props;
    this.setState({testing: true, testingResult: undefined});
    let testingResult = false;
    if (store && integration.id) {
      testingResult = await store.testIntegration(integration.id);
    }
    this.setState({testing: false, testingResult});
  }
  render() {
    const { checking, integration, integrationsStore: store } = this.props;
    const { testing, testingResult} = this.state;

    const {
      authorizedAt,
      authorizedBy,
      status,
    } = integration;

    let deleting = false;
    let testable = false;
    let name = '';
    if (store) {
      deleting = store.deleting;
      const details = store.getIntegrationTypeDetails(integration.type)!;
      name = details?.name;
      testable = details?.testable;
    }

    let label = 'Connected';
    let labelColor = 'green';

    if (status !== IntegrationStatus.SUCCESS) {
      label = 'Not working';
      labelColor = 'red';
    }

    return (
      <li className="integration-list-item integration">
        <IntegrationLogo integrationType={integration.type} />
        <div className="detail">
          <h3>
            {name}
            <Loader inline={true} active={checking} size="tiny" />
            {!checking && <Label color={labelColor as SemanticCOLORS}>{label}</Label>}
          </h3>
          <p className="subtle">
            <b>by</b> {authorizedBy} <b>at</b> {authorizedAt}
          </p>
          <Button
            className="basic"
            disabled={!testable}
            loading={testing}
            onClick={this.test}
            size="small"
          >
            { testable ? 'Test Integration' : 'This integration type can\'t be tested'}
          </Button>
          { testingResult !== undefined && (
            <FontAwesomeIcon
              icon={testingResult ? 'check-circle' : 'times-circle'}
              className={testingResult ? 'test-passed-icon' : 'test-failed-icon'}
              fixedWidth={true} />
          )}
          <Button
          className="disconnect-button"
            color="red"
            loading={deleting}
            onClick={this.disconnect}
            size="small"
          >
            Disconnect
          </Button>

        </div>
      </li>
    );
  }
}
