<template>
  <div class="remove-theme">
    <el-dialog
      :visible="!selectedPhrases.length"
      width="40%"
      :show-close="false"
      :modal-append-to-body="false"
      class="remove-theme-dialog"
    >
      <div class="remove-theme-content">
        <div class="icon">
          <font-awesome-icon
            icon="exclamation-triangle"
          />
        </div>
        <div>
          <div class="title">
            <span class="theme-name">{{ title }}</span> cannot be removed from this comment.
          </div>
          <div class="description">
            This tag was applied because this comment is similar to others with this theme.
            It isn't possible to manually remove themes detected in this way.
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="onClose"
        >
          OK
        </el-button>
      </span>
    </el-dialog>
    <show-comments
      v-if="!!selectedPhrases.length && !canSeeQuickPhraseSelection"
      :selected-phrases="selectedPhrases"
      :selected-theme="selectedTheme"
      :removing-theme="true"
      :comment-id="commentId"
      :selected-block="selectedBlock"
      @onAction="onClose"
    />
    <remove-theme-example-comments
      v-if="!!selectedPhrases.length && canSeeQuickPhraseSelection"
      :selected-phrases="selectedPhrases"
      :selected-theme="selectedTheme"
      :comment-id="commentId"
      :selected-block="selectedBlock"
      @onAction="onClose"
    />
  </div>
</template>

<script>
import ShowComments from './ShowComments.vue';
import RemoveThemeExampleComments from './RemoveThemeExampleComments.vue';
import { getThemesStore, getAnalysisToolsUIStore } from 'stores/RootStore';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';

export default {
  name: 'RemoveTheme',
  components: { ShowComments, RemoveThemeExampleComments },
  props: {
    removeThemeParams: null,
  },
  data() {
    return {
      themesStore: getThemesStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      selectedTheme: undefined,
      selectedBlock: undefined,
      commentId: undefined,
      highlightedSentences: [],

      selectedPhrases: [],
    };
  },
  computed: {
    title() {
      if (
        (this.selectedTheme.title && this.selectedTheme.title.includes(':'))
      ) {
        return `${this.selectedTheme.baseTitle}: ${this.selectedTheme.subTitle}`;
      } else {
        return this.selectedTheme.baseTitle;
      }
    },
    options() {
      return this.removeThemeParams || this.analysisToolsUIStore.removeThemeParams;
    },
    canSeeQuickPhraseSelection() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_QUICK_PHRASE_SELECTION);
    },
  },
  watch: {
    options: {
      handler(options) {
        // populating options
        this.highlightedSentences = options.highlightedSentences || [];
        this.selectedTheme = options.selectedTheme ? options.selectedTheme : undefined;
        this.selectedBlock = options.selectedBlock ? options.selectedBlock : undefined;
        this.commentId = options.commentId ? options.commentId : undefined;
      },
      immediate: true
    }
  },
  mounted() {
    this.findMappedPhrase();
  },
  methods: {
    searchWithinBoundaries(phrase, sentence) {
      const newPhrase = phrase.toLowerCase();
      const newSentence = sentence.toLowerCase();
      // need regexp so we don't select words within words
      const updatedPhrase = newPhrase.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      return new RegExp(`\\b${updatedPhrase}\\b`).test(newSentence);
    },
    activeNode() {
      const { editingGroup: group } = this.themesStore;
      let id;
      let title;
      if (this.selectedTheme.title && this.selectedTheme.title.includes(':')) {
        id = this.selectedTheme.sub;
        title = this.selectedTheme.subTitle;
      } else {
        id = this.selectedTheme.base;
        title = this.selectedTheme.baseTitle;
      }
      group.activeNodeId = id;
      group.title = title;
      return this.themesStore.getActiveNode(group);
    },
    findMappedPhrase() {
      const activeNode = this.activeNode();
      const phrases = activeNode.phrases;

      if (phrases.length) {
        phrases.forEach(phrase => {
          this.highlightedSentences.forEach(text => {
            if (this.searchWithinBoundaries(phrase, text)) {
              const isPhraseAlreadyPushed = this.selectedPhrases.indexOf(phrase) > -1;
              !isPhraseAlreadyPushed && this.selectedPhrases.push(phrase);
            }
          })
        })
      }
    },
    onClose() {
      this.$emit('onClose');
      this.analysisToolsUIStore.hideRemoveThemeDialog();
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.remove-theme ::v-deep .el-dialog__header {
  padding: 0;
}

.remove-theme {
  .remove-theme-dialog {
    ::v-deep .el-dialog__footer {
      display: flex;
      flex-direction: row;
      justify-content: end;
    }
  }
  .remove-theme-content {
    display: flex;
    flex-direction: row;
    .icon {
      color: $--orange-500;
      font-size: 42px;
      padding-right: 16px;
    }
    .title {
      font-size: 16px;
      color: $--color-text-primary;
      padding: 5px 0 7px 0;
      line-height: 22px;
      font-weight: 600;
      .theme-name {
        align-items: center;
        border: none;
        color: $--neutral-700;
        display: inline-flex;
        line-height: 1;
        border-radius: 10em;
        padding: 4px 8px;
        background: $--neutral-100;
        height: 22px;
      }
    }
    .description {
      font-size: 14px;
      color: $--neutral-900;
      font-weight: 400;
      padding-bottom: 10px;
    }
  }
  ::v-deep .el-dialog__footer {
    padding-top: 15px;
    background-color: $--neutral-100;
    .dialog-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .cancel-button {
        padding: 0;
        background: transparent;
        border: none;
        color: $--color-primary;
      }
    }
  }
}

</style>
