import auth from 'Auth/Auth';
import LoginImage from 'images/login-image.png';
import LogoIcon from 'images/logo.svg';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { Button, Message } from 'semantic-ui-react';
import { LoginScreenUIStoreInterface } from 'stores/ui/LoginScreenUIStore';
import './LoginScreen.scss';
import SingleSignOn from './SingleSignOn';

interface LoginScreenProps {
  fatalLoginError?: string;
  loginScreenUIStore?: LoginScreenUIStoreInterface;
}
interface LoginScreenState {
  proposeSingleSignOn: boolean;
}

@inject('loginScreenUIStore')
@observer
class LoginScreen extends React.Component<LoginScreenProps, LoginScreenState> {
  state = {
    proposeSingleSignOn: false
  };
  login = () => {
    auth.login();
  };
  closeSingleSignOn = () => {
    this.setState({ proposeSingleSignOn: false });
  };
  componentDidMount() {
    const { hash } = window.location;
    const search = parse(hash.substring(hash.indexOf('?')));
    if (search.ssoModal === 'open') {
      this.openSingleSignOn();
    }
    this.props.loginScreenUIStore!.initialize();
  }
  openSingleSignOn = (e?: React.MouseEvent) => {
    e?.preventDefault();
    analytics.track('Open SSO', { category: 'Login' });
    this.setState({ proposeSingleSignOn: true });
  };

  render() {
    const { fatalLoginError, loginScreenUIStore } = this.props;
    const { proposeSingleSignOn } = this.state;
    return (
      <div className={'login-screen' + (fatalLoginError ? ' error' : '')}>
        <div className="left-column">
          <div className="login-block">
            <a
              className="logo"
              href="https://getthematic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoIcon />
            </a>
            <div className="description">Log in to your account</div>
            <div role="button">
              <div className="line" />
            </div>
            {fatalLoginError ? (
              <Message className="login-error" negative={true}>
                <Message.Header>Uh oh! We can't log you in</Message.Header>
                <p>
                  Something went wrong. Reloading the page may solve this issue.
                </p>
                <p>Here's the message we got:</p>
                <blockquote>
                  <code>{fatalLoginError}</code>
                </blockquote>
              </Message>
            ) : (
              <>
                <Button
                  className="nw-login-button"
                  color="blue"
                  fluid={true}
                  onClick={this.login}
                >
                  Log in
                </Button>
                <a className="sso-link" onClick={this.openSingleSignOn}>
                  Sign in with SSO
                </a>
              </>
            )}
            <p className="support-line">
              {fatalLoginError ? 'Still can\'t log in?' : 'Can\'t log in?'}{' '}
              <a href="mailto:support@getthematic.com?subject=Portal Login">
                Contact Support
              </a>
            </p>
          </div>
        </div>
        <div className="right-column">
          <div className="cta-block">
            <h1>{loginScreenUIStore!.header}</h1>
            <ul>
              {loginScreenUIStore!.bulletPoints.map((bullet, i) => {
                return <li key={i}>{bullet}</li>;
              })}
            </ul>
            {loginScreenUIStore!.button ? (<a
              className="cta-link ui button"
              href={loginScreenUIStore!.button.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {loginScreenUIStore!.button.text}
            </a>) : null}
          </div>
          <div className="image-block">
            <div className="images">
              <img src={LoginImage} alt="" />
            </div>
          </div>
        </div>
        <SingleSignOn
          open={proposeSingleSignOn}
          cancel={this.closeSingleSignOn}
        />
      </div>
    );
  }
}

export default LoginScreen;
