<template>
  <div
    v-loading="loading"
    class="widget"
    :class="{ 'edit-mode': activeDashboardUIStore.isEditing }"
  >
    <div
      class="widget-header"
      :class="[{'edit-mode': activeDashboardUIStore.isEditing}, activeDashboardUIStore.isEditing ? activeDashboardUIStore.dragHandleClass: '']"
      :style="activeDashboardUIStore.isEditing ? activeDashboardUIStore.dragHandleStyle : ''"
    >
      <h3
        v-if="title"
        class="widget-title"
      >
        {{ title }}
      </h3>
      <slot
        name="controls"
        class="widget-header__controls"
      />
      <div class="edit-options">
        <font-awesome-icon
          v-if="activeDashboardUIStore.isEditing"
          class="reorder-icon"
          icon="arrows"
        />

        <settings-menu
          v-if="activeDashboardUIStore.isEditing"
          :show-edit="isConfigurable"
          :show-delete="isDeletable"
          @onEdit="enterEditMode"
          @onDelete="deleteWidget"
        />
      </div>
    </div>
    <slot name="subtitle" />
    <div
      v-if="error"
      class="initialization-failed widget-body"
    >
      <font-awesome-icon icon="exclamation-triangle" />
      Uh oh! {{ error }}
    </div>
    <slot v-else />

    <div
      v-if="warning"
      class="widget-warning"
    >
      <font-awesome-icon icon="exclamation-triangle" />
      {{ warning }}
    </div>
    <configure-widget
      v-if="isConfigurable && isActivelyEditing"
      :widget-path="widgetPath"
      @onCancel="exitEditMode"
    />
  </div>
</template>

<script>
import { getActiveDashboardUIStore, getAnalysisToolsStore } from 'stores/RootStore';
import ConfigureWidget from '../../DashboardEditable/ConfigureWidget/ConfigureWidget.vue';
import { CONFIG_FIELDS, DELETABLE_WIDGETS } from '../../DashboardEditable/constants';
import { get } from 'lodash';
import SettingsMenu from '../../DashboardEditable/SettingsMenu.vue';
export default {
  name: 'WidgetWrapper',
  components: { ConfigureWidget, SettingsMenu },
  props: {
    loading: { type: Boolean },
    error: { type: String },
    warning: { type: String },
    title: { type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
      analysisToolsStore: getAnalysisToolsStore(),

      isActivelyEditing: false,
    };
  },
  computed: {
    widgetPath() {
      return this.activeDashboardUIStore.getPanelWidgetPath(this.panelOrder, this.widgetOrder);
    },
    widgetConfigPath() {
      return this.widgetPath ? `${this.widgetPath}.config` : undefined;
    },
    widgetType() {
      if (!this.widgetPath) {
        return undefined;
      }
      const widgetConfig = this.activeDashboardUIStore.getValue(this.widgetConfigPath);
      return get(widgetConfig, 'type');
    },
    isConfigurable() {
      try {
        // Widget is configurable if:
        //    current user can access the source dataset for the widget
        //    widget has fields that can be configured
        const source = this.activeDashboardUIStore.getSourceByWidgetPath(this.widgetPath);
        if (!source) {
          throw new Error('Bad config');
        }
        const { survey, view, visualization } = source;
        // TODO: PORTAL-3310 Handle aggregate views
        const analysis = this.analysisToolsStore.getAnalysis(survey, visualization, view);
        return !!analysis && !!this.widgetType && !!CONFIG_FIELDS[this.widgetType];
      } catch (error) {
        return false;
      }
    },
    isDeletable() {
      return !!DELETABLE_WIDGETS.find((val) => val === this.widgetType);
    },
    isEditing() {
      return this.activeDashboardUIStore.isEditing;
    },
  },
  methods: {
    enterEditMode() {
      this.isActivelyEditing = true;
    },
    exitEditMode() {
      this.isActivelyEditing = false;
    },
    deleteWidget() {
      this.activeDashboardUIStore.deleteWidget(this.panelOrder, this.widgetOrder);
    },
  },
};
</script>

