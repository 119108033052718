import { scoreGraphNormalizeTwoDatasetsForThemeFreq } from 'charts/Utils/scoreGraphHelpers';
import { ThemeFreqWithSigDiff } from 'charts/Utils/types';
import _ from 'lodash';
import queryBuilder from 'vue/libs/queryBuilder';
import statistics from 'vue/libs/statistics';
import thematicData from 'vue/libs/thematicData';

export const topNToDisplay = 100;

interface Selection {
  query: string;
  title: string;
  shortTitle: string;
}
interface Selections {
  baseline: Selection;
  comparison: Selection;
}

interface Themes {
  data: number;
  id: string;
  name: string;
  sentimentCounts: {
    neg: number,
    neut: number,
    pos: number
  };
}

interface ThemeDict {
  [key: string]: Themes;
}

interface ThemeTotalValues {
  c: number;
  t: number;
  counts: {
    count: number,
    sentimentCounts: {
      neg: number,
      neut: number,
      pos: number
    }
  };
}

type DimensionFetchOptions = {
  commentColumns: number[];
  selections: Selections;
  selectedDimension: string;
  graphShowBy: string;
  options: any;
  selectionsWithTheme: Selections;
};

function getThemeTotalValues(
  baseline: Themes[],
  baselineTheme: Themes[],
  selection: Themes[],
  selectionTheme: Themes[]
): ThemeTotalValues[] {
  const baselineAllData = baseline.find((theme) => theme.id === 'all_');
  const baselineAllTheme = baselineTheme.find((theme) => theme.id === 'all_');
  const comparisonAllData = selection.find((theme) => theme.id === 'all_');
  const comparisonAllTheme = selectionTheme.find((theme) => theme.id === 'all_');

  // Note: all the filters we receive from backend usually always have an "All" option in it
  const baselineAllThemeCount = baselineAllTheme ? baselineAllTheme.data : 0;
  const baselineAllDataCount = baselineAllData ? baselineAllData.data : 0;
  const comparisonAllThemeCount = comparisonAllTheme ? comparisonAllTheme.data : 0;
  const comparisonAllDataCount = comparisonAllData ? comparisonAllData.data : 0;
  const baselineAllThemeSentimentCounts = baselineAllTheme ? baselineAllTheme.sentimentCounts : {
    neg: 0,
    neut: 0,
    pos: 0
  };
  const comparisonAllThemeSentimentCounts = comparisonAllTheme ? comparisonAllTheme.sentimentCounts : {
    neg: 0,
    neut: 0,
    pos: 0
  };
  return [
    {
      c: baselineAllThemeCount,
      t: baselineAllDataCount,
      counts: {
        count: baselineAllThemeCount,
        sentimentCounts: baselineAllThemeSentimentCounts
      }
    },
    {
      c: comparisonAllThemeCount,
      t: comparisonAllDataCount,
      counts: {
        count: comparisonAllThemeCount,
        sentimentCounts: comparisonAllThemeSentimentCounts
      }
    }
  ];
}

function getDataDict(data: Themes[]): ThemeDict {
  if (!data) {
    return {};
  }
  const dataDict = {};
  data.forEach((theme) => {
    dataDict[theme.id] = theme;
  });
  return dataDict;
}

function getGraphData(
  baseline: Themes[],
  selection: Themes[],
  baselineTheme: Themes[],
  selectionTheme: Themes[],
  graphShowBy: string,
  themeTotalValues: ThemeTotalValues[]
) {
  const baselineDict = getDataDict(baseline);
  const selectionDict = getDataDict(selection);
  return calculateGraphData(
    baselineDict,
    selectionDict,
    baselineTheme,
    selectionTheme,
    graphShowBy,
    themeTotalValues
  );
}

function calculateGraphData(
  baselineDict: ThemeDict,
  selectionDict: ThemeDict,
  baselineTheme: Themes[],
  selectionTheme: Themes[],
  graphShowBy: string,
  themeTotalValues: ThemeTotalValues[]
) {
  const valueLabel = getGraphLabel(graphShowBy);

  const labels = getThemeLabels(baselineTheme);
  const baselineCollectedData = getThemesCollectData(baselineTheme, baselineDict, themeTotalValues, graphShowBy);
  const selectionLabels = getThemeLabels(selectionTheme);
  const comparisonCollectedData = getThemesCollectData(selectionTheme, selectionDict, themeTotalValues, graphShowBy);
  return {
    valueLabel,
    labels,
    selectionLabels,
    baselineCollectedData,
    comparisonCollectedData
  };
}

function getThemeLabels(themes: Themes[]): string[] {
  return themes.filter(theme => theme.id !== 'all_').map((theme) => theme.name);
}

function getThemeVolume(
  theme: Themes,
  themeCount: number,
  graphShowBy: string
): number {
  if (graphShowBy === 'percentage') {
    return themeCount !== 0
    ? (theme.data / themeCount) * 100.0
    : 0;
  } else if (graphShowBy === 'count') {
    return theme.data;
  } else if (graphShowBy === 'totalCount') {
    return themeCount;
  }
  return 0;
}

function getThemesCollectData(
  themes: Themes[],
  themeDict: ThemeDict,
  themeTotalValues: ThemeTotalValues[],
  graphShowBy: string
): ThemeFreqWithSigDiff[] {
  return themes.filter(theme => theme.id !== 'all_').map((theme) => {
    const themeCount = themeDict[theme.id]
      ? themeDict[theme.id].data
      : 0;

    const themeVolume = getThemeVolume(theme, themeCount, graphShowBy);
    return {
      v: themeVolume,
      c: theme.data,
      t: themeCount,
      counts: {
        count: theme.data,
        sentimentCounts: theme.sentimentCounts
      },
      sigDiff: statistics.thresholded_two_way_statistical_significance(
        theme.data,
        themeCount,
        themeTotalValues[0].c,
        themeTotalValues[0].t
      )
    };
  });
}

function getGraphLabel(graphShowBy: string): string {
  if (graphShowBy === 'percentage') {
    return 'Percentage of respondents';
  } else {
    // this string is for when graphShowBy is 'count' or 'totalCount'
    return 'Number of respondents';
  }
}

export default async function doFetch(opts: DimensionFetchOptions) {
  const {
    commentColumns,
    selections,
    selectedDimension,
    graphShowBy,
    options,
    selectionsWithTheme
  } = opts;

  const selectionsWithout = _.cloneDeep(selections);
  selectionsWithout.baseline.query = queryBuilder.appendToFilter(
    selections.baseline.query,
    'theme!=empty_string'
  );
  selectionsWithout.comparison.query = queryBuilder.appendToFilter(
    selections.comparison.query,
    'theme!=empty_string'
  );

  const promises = [
    thematicData.getStatistics(
      selectionsWithout.baseline.query,
      commentColumns,
      options,
      selectedDimension
    ),
    thematicData.getStatistics(
      selectionsWithout.comparison.query,
      commentColumns,
      options,
      selectedDimension
    ),
    thematicData.getStatistics(
      selectionsWithTheme.baseline.query,
      commentColumns,
      options,
      selectedDimension
    ),
    thematicData.getStatistics(
      selectionsWithTheme.comparison.query,
      commentColumns,
      options,
      selectedDimension
    )
  ];

  const [
    baseline,
    selection,
    baselineTheme,
    selectionTheme
  ] = await Promise.all(promises);

  const themeTotalValues = getThemeTotalValues(baseline, baselineTheme, selection, selectionTheme);

  const graphData = getGraphData(
    baseline,
    selection,
    baselineTheme,
    selectionTheme,
    graphShowBy,
    themeTotalValues
  );

  const comparisonCollectedData = scoreGraphNormalizeTwoDatasetsForThemeFreq(
    graphData.labels,
    graphData.selectionLabels,
    graphData.comparisonCollectedData
  );

  // create the bar graph options
  const barOptions = {
    baselineTitle: selections.baseline.title,
    comparisonTitle: selections.comparison.title,
    valueLabel: graphData.valueLabel,
  };

  return {
    barOptions,
    labels: graphData.labels,
    baselineCollectedData: graphData.baselineCollectedData,
    comparisonCollectedData
  };
}
