import { parse, stringify } from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import { compose } from 'lib/composeHOCs';
import { withErrorBoundary } from 'react-error-boundary';
import { inject, observer } from 'mobx-react';
import { DashboardStoreInterface } from 'stores/DashboardStore';
import { Dimmer, Loader } from 'semantic-ui-react';
import { DashboardError } from './DashboardError';

interface DashboardRouteParams {
  orgId: string;
  dashboardId: string;
}

type Props = {
  dashboardStore: DashboardStoreInterface
} & RouteComponentProps<DashboardRouteParams>;

interface DashboardRouteState {
  hasJustBeenCreated: boolean;
}

const withHocs = compose(
  (c: React.ComponentClass) => withErrorBoundary(c, { fallback: <DashboardError/> }),
  withRouter,
  inject('dashboardStore'),
  observer
);

class DashboardRouteClass extends React.Component<Props, DashboardRouteState> {
  state = {
    hasJustBeenCreated: false
  };

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  get dashboardId(): string {
    const { dashboardId } = this.props.match.params;
    return dashboardId;
  }
  componentDidMount() {
    let search = parse(this.props.location.search);

    // isCreate is set when a dashboard has just been created to switch the edit mode on
    const hasJustBeenCreated = !!search.isCreate;

    // Remove query params to keep the URL consistent for edit modes
    // 1. coming from just creating a dashboard; 2. editing an existing dashboard;
    if (hasJustBeenCreated) {
      delete search.isCreate;
      this.props.history.push({ search: stringify(search) });
    }
    this.setState({hasJustBeenCreated});
  }
  render(): JSX.Element | null {
    const isLoadingDashboards = this.props.dashboardStore.dashboardsLoading;
    const orgId = String(this.orgId);
    const dashboardId = String(this.dashboardId);
    const hasJustBeenCreated = this.state.hasJustBeenCreated;

    if (isLoadingDashboards) {
      return (
        <Dimmer active inverted={true}>
          <Loader size="large" content="Loading dashboards..." />
        </Dimmer>
      );
    }

    return (
      <Dashboard orgId={orgId} dashboardId={dashboardId} hasJustBeenCreated={hasJustBeenCreated}/>
    );
  }
}

export const DashboardRoute = withHocs(DashboardRouteClass);
