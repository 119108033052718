import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIntegration from 'components/Integrations/AddIntegration';
import ConnectedIntegration from 'components/Integrations/ConnectedIntegration';
import 'components/Integrations/integrations.scss';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Header, Message, Segment } from 'semantic-ui-react';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';

export interface IntegrationsProps {
  integrationsStore: IntegrationsStoreInterface;
}

@inject('integrationsStore')
@observer
export default class Integrations extends React.Component<IntegrationsProps> {

  render() {
    const { integrationsStore: store } = this.props;
    return (
      <>
        <Segment className="integrations">
          <Header>Manage Integrations</Header>
        </Segment>
        <Segment className="integrations white">
          {store.errored && (
            <Message negative={true} icon={true}>
              <FontAwesomeIcon
                className="icon"
                icon="exclamation-triangle"
                fixedWidth={true}
              />
              <Message.Content>
                <Message.Header>
                  Uh oh! Something went wrong checking your integrations.
                </Message.Header>
                <p>Please refresh the page to try again.</p>
              </Message.Content>
            </Message>
          )}
          <ul>
            <AddIntegration />
            {store.connectedIntegrations.map(integration => (
              <ConnectedIntegration
                key={integration.id}
                integration={integration}
                checking={store.checking}
              />
            ))}
          </ul>
        </Segment>
      </>
    );
  }
}
