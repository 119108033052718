import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Concepts } from 'api/interfaces';
import AlertTag from 'images/icons/alert-tag.svg';
import Exclamation from 'images/icons/exclamation-triangle.svg';
import { debounce, each } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, Form, Header, Input, Loader, Message, Modal, Ref } from 'semantic-ui-react';
import { ConceptsStoreInterface } from 'stores/ConceptsStore';
import { ConceptType, ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import ApplyConcepts from './ApplyConcepts';
import Concept from './Concept';
import NewConcept from './NewConcept';
import './concepts-editor.scss';

interface ConceptsEditorProps {
  conceptsStore?: ConceptsStoreInterface;
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
  orgId: string;
  surveyId: string;
  surveyName: string;
  cancel: () => void;
}

interface ConceptsEditorState {
  showAddNewTerm: string;
  searchValue: string;
  typedValue: string;
  searching: boolean;
  showCancelWarningModal: boolean;
  showApplyModal: boolean;
  showErrorModal: boolean;
  applying: boolean;
  newDescriptorValue: string;
}

const CONCEPTS_TYPE = [
  {
    key: ConceptType.DISCOVERED_CONCEPTS,
    text: 'Discovered synonyms',
    value: ConceptType.DISCOVERED_CONCEPTS,
  },
  {
    key: ConceptType.PREDEFINED_CONCEPTS,
    text: 'Predefined synonyms',
    value: ConceptType.PREDEFINED_CONCEPTS,
  },
];

@inject('conceptsStore')
@inject('conceptsEditorUIStore')
@observer
export default class ConceptsEditor extends React.Component<ConceptsEditorProps, ConceptsEditorState> {

  searchConcepts = debounce(() => {
    this.setState({ searchValue: this.state.typedValue, searching: false });
  }, 300);

  refs = {};
  modalsRef = React.createRef<HTMLDivElement>();
  fileInputEl: HTMLInputElement | null = null;
  fileFormEl: HTMLFormElement | null = null;

  state = {
    showAddNewTerm: '',
    searchValue: '',
    typedValue: '',
    searching: false,
    showCancelWarningModal: false,
    showApplyModal: false,
    showErrorModal: false,
    applying: false,
    newDescriptorValue: ''
  } as ConceptsEditorState;

  get conceptsStore() {
    return this.props.conceptsStore!;
  }

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  get orgId(): string {
    const { orgId } = this.props;
    return orgId;
  }

  get surveyId(): string {
    const { surveyId } = this.props;
    return surveyId;
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: ConceptsEditorProps) {
    if (prevProps.orgId !== this.orgId || prevProps.surveyId !== this.surveyId) {
      this.initialize();
    }
  }

  close = () => {
    this.closeCancelWarningModal();
    this.closeApplyModal();
    this.props.cancel();
    this.conceptsEditorUIStore.reset();
  };

  openCancelWarningModal = () => {
    if (this.conceptsEditorUIStore.isConceptsChanged) {
      this.setState({ showCancelWarningModal: true });
    } else {
      this.close();
    }
  };

  closeCancelWarningModal = () => {
    this.setState({ showCancelWarningModal: false });
  };

  openApplyModal = () => {
    this.setState({ showApplyModal: true });
  };

  closeApplyModal = () => {
    this.setState({ showApplyModal: false });
  };

  openErrorModal = () => {
    this.setState({ showErrorModal: true });
  };

  closeErrorModal = () => {
    this.setState({ showErrorModal: false });
    this.close();
  };

  applyConcepts = async () => {
    const { orgId, surveyId } = this.props;
    this.closeApplyModal();
    this.setState({ applying: true });

    let success = await this.conceptsStore.applyConcepts(this.conceptsEditorUIStore.concepts, orgId, surveyId);
    this.setState({ applying: false });
    if (success) {
      this.close();
    } else {
      this.openErrorModal();
    }
  };

  toggleCreateDescriptorOptions = () => {
    this.conceptsEditorUIStore.toggleCreateDescriptorOptions(true);
  }

  cancelCreateDescriptor = () => {
    this.conceptsEditorUIStore.toggleCreateDescriptorOptions(false);
  }

  getConcepts = () => {
    switch (this.conceptsEditorUIStore.conceptType) {
      case ConceptType.DISCOVERED_CONCEPTS:
        return this.conceptsEditorUIStore.concepts.learned;
      case ConceptType.PREDEFINED_CONCEPTS:
        return { ...this.conceptsEditorUIStore.concepts.include, ...this.conceptsEditorUIStore.concepts.phrases };
      default:
        return this.conceptsEditorUIStore.concepts.learned;
    }
  };

  setSelectedConceptType = (e, { value }) => {
    if (value === ConceptType.DISCOVERED_CONCEPTS) {
      this.conceptsEditorUIStore.switchConcepts(ConceptType.DISCOVERED_CONCEPTS);
      this.resetModalScroll();
    } else if (value === ConceptType.PREDEFINED_CONCEPTS) {
      this.conceptsEditorUIStore.switchConcepts(ConceptType.PREDEFINED_CONCEPTS);
      this.resetModalScroll();
    }
  }

  filteredSearch = (val: string) => {
    if (this.state.searchValue === '') {
      return val;
    } else {
      const concepts = this.getConcepts();
      let searchedValue = val.toLocaleLowerCase().includes(this.state.searchValue.toLocaleLowerCase()) ||
        concepts[val].nonDescriptors.some(value =>
          value.toLocaleLowerCase().includes(this.state.searchValue.toLocaleLowerCase()));
      return searchedValue;
    }
  }

  clearSearch = () => {
    this.setState({ searchValue: '', typedValue: '' });
  };

  initialize = async () => {
    const { orgId, surveyId } = this.props;
    let concepts = await this.conceptsStore.fetchConcepts(orgId, surveyId);
    if (concepts) {
      const parsedConcepts: Concepts = JSON.parse(concepts.contents);
      this.initializeConcepts(parsedConcepts);
    } else {
      this.openErrorModal();
    }
  }

  scrollTo = (id) => {
    this.refs = { [id]: React.createRef() };
    this.setState({ newDescriptorValue: id }, () =>
      this.refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    );
  }

  resetModalScroll = () => {
    if (this.modalsRef.current) {
      this.modalsRef.current.scrollTop = 0;
    }
  }

  initializeConcepts = (data: Concepts) => {
    const concepts: {
      learned: { [key: string]: { nonDescriptors: string[] } };
      exclude: { [key: string]: { nonDescriptors: string[] } };
      include: { [key: string]: { nonDescriptors: string[] } };
      phrases: { [key: string]: { nonDescriptors: string[] } };
    } = {
      learned: {},
      exclude: {},
      include: {},
      phrases: {}
    };
    each(data, (contents: { [keyterm: string]: string }, contentsKey: string) => {
      each(contents, (value: string, key: string) => {
        const keyExists = value in concepts[contentsKey];
        if (keyExists) {
          concepts[contentsKey][value].nonDescriptors.push(key);
        } else {
          concepts[contentsKey][value] = { nonDescriptors: [key] };
        }
      });
    });
    // making sure there are no duplicate keys between learned and include,
    // and if there are then assigning the values of key from include to key of learned.
    each(concepts.learned, (value, key) => {
      if (key in concepts.include) {
        concepts.learned[key].nonDescriptors =
          [...concepts.include[key].nonDescriptors, ...concepts.learned[key].nonDescriptors];
        delete concepts.include[key];
      }
    });
    this.conceptsEditorUIStore.initializeConcepts(concepts);
  }

  uploadConcepts = () => {
    if (this.fileInputEl) {
      this.fileInputEl.click();
    }
  };
  handleConceptsFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) {
      return;
    }
    const file = files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      if (!reader.result) {
        return;
      }
      const conceptsStr = reader.result as string;
      const concepts = JSON.parse(conceptsStr)
      this.initializeConcepts(concepts);

      // Track analytics around theme upload use
      analytics.track('Themes Editor: Manual concepts upload', { category: 'Themes Editor' });

      if (this.fileFormEl) {
        this.fileFormEl.reset();
      }
    };
    reader.readAsText(file);
  };

  render() {
    const {
      typedValue,
      searching,
      showCancelWarningModal,
      showApplyModal,
      showErrorModal,
      applying,
      newDescriptorValue
    } = this.state;
    const {
      surveyName
    } = this.props;
    const concepts = this.getConcepts();
    const filteredConcepts = Object.keys(concepts).filter((val) => this.filteredSearch(val));
    return (
      <div>
        <Modal
          className="concept-editor"
          open={true}
          size="large"
          dimmer={true}
          onClose={this.openCancelWarningModal}
          closeOnDimmerClick={true}
        >
          <Modal.Header as={Header} className="title">Review synonyms</Modal.Header>
          <form ref={c => (this.fileFormEl = c)}>
            <input
              ref={c => (this.fileInputEl = c)}
              style={{ display: 'none' }}
              accept=".json"
              type="file"
              onChange={this.handleConceptsFile}
            />
          </form>
          <p className="modal-description">
            Please verify these potential synonyms that were detected in your data. Do these terms mean the same thing?

            <Dropdown
              icon={null}
              className='concepts-admin-dropdown'
              trigger={
                <Button size="small">
                  Admin
                  <FontAwesomeIcon icon="chevron-down" className="icon" />
                </Button>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.uploadConcepts}>
                  <FontAwesomeIcon className="icon" icon="upload" />
                  Upload
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </p>
          <Ref innerRef={this.modalsRef}>
            <Modal.Content scrolling={true} className="modal-content">
              <div className="heading">
                <div className="toggle">
                  <label className="toggle-label">
                    Show:
                  </label>
                  <Dropdown
                    className="concepts-type"
                    closeOnBlur={true}
                    options={CONCEPTS_TYPE}
                    selection={true}
                    value={this.conceptsEditorUIStore.conceptType}
                    onChange={this.setSelectedConceptType}
                  />
                </div>
                <div className="add-synonym">
                  {this.conceptsEditorUIStore.showCreateDescriptorOptions ?
                    <NewConcept
                      onCancelCreateDescriptor={this.cancelCreateDescriptor}
                      onCreateDescriptor={(id) => this.scrollTo(id)}
                    />
                    :
                    <Button
                      type="button"
                      className="add-concept"
                      size="small"
                      onClick={() => this.toggleCreateDescriptorOptions()}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        fixedWidth={true}
                        icon="plus"
                      />
                      Add synonym
                    </Button>
                  }
                  <div className="search">
                    <Input
                      action={{
                        className: 'basic',
                        icon: typedValue ? 'close' : 'search',
                        onClick: this.clearSearch
                      }}
                      size="small"
                      type="text"
                      className="search-input"
                      placeholder="Search synonyms"
                      value={typedValue}
                      onChange={(event) => {
                        this.setState({ typedValue: event.target.value, searching: true });
                        this.searchConcepts();
                      }}
                    />
                  </div>
                </div>
              </div>
              <Form>
                <div className="content">
                  {this.conceptsStore.fetchingConcepts || searching ?
                    <div className="loader">
                      <Loader active={true} inline="centered" className="loading">
                        Fetching synonyms&hellip;
                      </Loader>
                    </div>
                    :
                    (Object.keys(concepts).length !== 0) ?
                      <div>
                        {filteredConcepts.map((key) => (
                          <div
                            key={key}
                            className={`${ newDescriptorValue === key ? 'new-descriptor' : '' }`}
                            ref={this.refs[key]}
                          >
                            <Concept
                              key={key}
                              descriptor={key}
                              nonDescriptors={[...concepts[key].nonDescriptors]}
                              showAddNewTerm={this.conceptsEditorUIStore.descriptorInEdit === key}
                            />
                          </div>
                        ))}
                        {filteredConcepts.length === 0 &&
                          <div className="empty-search-message">
                            <p className="message-heading">No results</p>
                            <p className="message-content">Try a new search.</p>
                          </div>
                        }
                      </div>
                      :
                      <Message size="huge">
                        There are no synonyms available. Start adding some!
                      </Message>
                  }
                </div>
              </Form>
            </Modal.Content>
          </Ref>
          <Modal.Actions>
            <Button onClick={this.openCancelWarningModal} type="button">
              Cancel
            </Button>
            <Button
              type="button"
              color="blue"
              disabled={applying}
              loading={applying}
              onClick={this.openApplyModal}
            >
              Save synonyms
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={showCancelWarningModal}
          className="cancel-modal"
          size="tiny"
        >
          <Modal.Content>
            <div className="modal-content">
              <Exclamation className="alert-image" />
              <div className="modal-body">
                <div className="modal-title">
                  Do you want to discard your changes?
                  <p className="modal-text">
                    You will lose any changes if you close the editor without saving synonyms.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeCancelWarningModal} type="button">
              Continue editing
            </Button>
            <Button
              type="button"
              color="blue"
              onClick={this.close}
            >
              Discard changes
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={showErrorModal}>
          <Modal.Content>
            <div className="modal-content">
              <AlertTag className="alert-image" />
              <div className="modal-body">
                <div className="modal-title">
                  {this.conceptsStore.errorMessage}
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              color="blue"
              onClick={this.closeErrorModal}
            >
              Close Synonyms Editor
            </Button>
          </Modal.Actions>
        </Modal>
        {showApplyModal && (
          <ApplyConcepts
            surveyName={surveyName}
            onAccept={this.applyConcepts}
            onClose={this.closeApplyModal}
          />
        )}
      </div>
    );
  }
}