import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import {
  CommentThemeItem,
  PhraseSegmentTheme,
  PhraseSegment
} from 'types/custom';
import matches from 'lib/matches';

type Args = {
  theme: PhraseSegmentTheme
  segments: Array<PhraseSegment>,
  isNew: boolean,
  isRemoved: boolean,
  hoveredLocation?: [start: number, end: number],
  hoveredThemeCodes?: ThemeCodes,
  selectedThemeCodes?: ThemeCodes,
};

export default function toCommentThemeItem({
  theme,
  segments,
  isNew,
  isRemoved,
  hoveredLocation,
  hoveredThemeCodes,
  selectedThemeCodes
}: Args): CommentThemeItem {

  const isLocationOrThemeItemHovered = Boolean(hoveredThemeCodes || hoveredLocation);
  const isSelectedTheme = matches(theme, selectedThemeCodes);
  const isThemeItemHovered = matches(theme, hoveredThemeCodes);

  const segmentsContainingTheme = segments
    .filter(s => s.themes.some((t: PhraseSegmentTheme) => matches(t, theme)));

  const isMatchingLocationHovered = hoveredLocation && segmentsContainingTheme.some(
    s => s.location[0] === hoveredLocation[0] && s.location[1] === hoveredLocation[1]
  );

  const sentiment = segmentsContainingTheme
    .reduce((result, s) => result + s.sentiment, 0) / segmentsContainingTheme.length;

  const hasBackgroundColour = !isRemoved &&
    (!isLocationOrThemeItemHovered && isSelectedTheme || isThemeItemHovered || isMatchingLocationHovered) || false;

  return {
    theme,
    sentiment,
    isNew,
    hasBackgroundColour,
    isRemoved
  };

}
