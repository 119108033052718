import { first, get, isArray } from 'lodash';
import { getInitConfigStore } from 'stores/RootStore';

export default {
  data() {
    const selectedScore = first(this.scores);
    return { selectedScore, initConfigStore: getInitConfigStore() };
  },
  props: {
    config: { type: Object },
    selectedScoreExternal: { type: Object },
  },
  computed: {
    scores() {
      return this.initConfigStore.scores;
    },
    stateConfig() {
      return this.initConfigStore.config;
    },

    showScoreSelector() {
      return get(this, 'scores.length') > 1;
    },
    scoreColumn() {
      const defaultValue = get(this, 'stateConfig.score_column', null);
      return get(this, 'selectedConfig.score_column', defaultValue);
    },
    scoreType() {
      const defaultValue = get(this, 'stateConfig.score_type', null);
      return get(this, 'selectedConfig.score_type', defaultValue);
    },
    scoreOptions() {
      const defaultValue = get(this, 'stateConfig.score_options', null);
      return get(this, 'selectedConfig.score_options', defaultValue);
    },
    selectedConfig() {
      const { config, selectedScore } = this;

      // return selectedConfig or config from the options or default to config
      return selectedScore || config;
    }
  },
  watch: {
    scores: {
      handler(scores) {
        // only apply if haven't selected one
        if (isArray(scores) && !this.selectedScore) {
          this.selectedScore = first(scores);
        }
      },
      immediate: true
    },
    selectedScoreExternal: {
      handler(externalSelection) {
        if (externalSelection) {
          this.selectedScore = externalSelection;
        }
      },
      immediate: true
    }
  }
};
