import { AnswerBase } from 'api/interfaces';
import * as React from 'react';
import './answer-header.scss';

interface AnswerHeaderProps {
  answer: AnswerBase;
}

const AnswerHeader = ({ answer }: AnswerHeaderProps) => {
  return (
    <header className="answers__text-header">
      <h4
        className="answers__text-header-question"
        data-testid="question"
      >
        {answer.question}
      </h4>
    </header>
  );
};

export { AnswerHeader };
