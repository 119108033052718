import { AnalysisFilter, AnalysisFilterCut, SegmentFilter, SegmentFilters } from 'api/interfaces';
import { getDefaultDateSelection, isDateFilter, toServerDate } from './date-filter-helper';
import { DateRange } from 'stores/FilterStore';
import { QueryFilter } from 'stores/types';

type FilterId = string;
type ShortcutLabel = string;
export type DateShortcutMap = Record<FilterId, Record<ShortcutLabel, { dateRange: DateRange }>>;

export function getSelectedCuts(
  segmentFilters: SegmentFilters,
  queryFilters: Record<string, QueryFilter>,
  combinedFilters: AnalysisFilter[],
  dateShortcuts: DateShortcutMap
): Record<string, AnalysisFilterCut[]> {
  return Object
    .entries(segmentFilters)
    .reduce((result, [filterId, segmentFilter]) => {
      const queryFilter: QueryFilter | null = queryFilters[filterId] ?? null;
      const selectedFilter: AnalysisFilter | null = combinedFilters.find(filter => filter.id === filterId) ?? null;

      if (!queryFilter || !selectedFilter) {
        return result;
      }

      const cuts = getCutsFromSegmentFilter(
        selectedFilter,
        segmentFilter,
        dateShortcuts[filterId]
      );

      return {
        ...result,
        [filterId]: cuts
      };
    }, {});
}

export function getCutsFromSegmentFilter(
  selectedFilter: AnalysisFilter,
  segmentFilter: SegmentFilter,
  dateShortcuts: Record<string, { dateRange: DateRange }>
): AnalysisFilterCut[] {

  const filterId = selectedFilter.id;
  const selectedCuts: AnalysisFilterCut[] = [];
  const { ids, startDate, endDate, text, name, names }: SegmentFilter = segmentFilter;

  if (ids) {
    ids.forEach(id => {
      if (selectedFilter.type === 'hierarchical' || selectedFilter.type === 'tags') {
        // @ts-expect-error | missing fields: name, rql
        selectedCuts.push({
          id,
        });
      } else if (selectedFilter.type === 'themes') {
        const matchingName = names?.find(value => value.id === id);

        if (matchingName) {
          // @ts-expect-error | missing fields: rql
          selectedCuts.push({
            ...matchingName,
          });
        }
      } else {
        const selectedCut = selectedFilter.cuts?.find(cut => cut.id === id);
        if (selectedCut) {
          selectedCuts.push(selectedCut);
        }
      }
    });
  } else if (startDate && isDateFilter(selectedFilter)) {
    // Assumptions
    // 1. start date and end date always exist together
    // 2. Name property exists only when the date range is from date picker shortcuts
    //    ex. Last 31 days
    const shortcut = name ? dateShortcuts[name.toLowerCase()] : undefined;
    const defaultDateSelection = getDefaultDateSelection(selectedFilter);
    if (shortcut) {
      // If selection is a shortcut, choose the current dates for the shortcut
      // Otherwise ignore the date selection for this filter
      const { dateRange } = shortcut;
      const start = toServerDate(dateRange.start);
      const end = toServerDate(dateRange.end);
      const currentDateSelectionRql = `(${ filterId }=ge=${ start });(${ filterId }=le=${ end })`;
      const selectedCut = {
        startDate: start,
        endDate: end,
        rql: currentDateSelectionRql,
        name: name ? name : `${ start } - ${ end }`,
        isDefaultSelection: defaultDateSelection?.[0]?.isDefaultSelection,
      };
      selectedCuts.push(selectedCut);
    } else {
      const currentDateSelectionRql = `(${ filterId }=ge=${ startDate });(${ filterId }=le=${ endDate })`;
      const selectedCut = {
        startDate,
        endDate,
        rql: currentDateSelectionRql,
        name: `${ startDate } - ${ endDate }`,
        isDefaultSelection: defaultDateSelection?.[0]?.isDefaultSelection,
      };
      selectedCuts.push(selectedCut);
    }
  } else if (text) {
    const selectedCut = {
      name: `Response contains: ${ text }`,
      rql: `${ filterId }==${ text }`,
      text
    };
    selectedCuts.push(selectedCut);
  }
  return selectedCuts;
}
