<template>
  <div
    class="config-field"
    data-testid="select-score"
  >
    <label>
      Score
    </label>
    <el-select
      v-model="selectedScore"
      placeholder="Select"
      @change="onScoreChange"
    >
      <el-option
        v-for="item in scoreOptions"
        :key="`${item.label}-${item.value}`"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'ConfigureWidgetScore',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
    analysisConfig: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      selectedScore: undefined,
      scores: [],
      scoreOptions: []
    }
  },
  watch: {
    analysisConfig: {
      handler(analysisConfig) {
        this.initializeScores(analysisConfig);
        // Reset selected score when analysis config changes (could be caused by changing data source)
        this.initializeSelectedScore(this.widgetConfig);
      },
      immediate: true
    },
    widgetConfig: {
      handler(widgetConfig) {
        this.initializeSelectedScore(widgetConfig);
      },
      immediate: true
    }
  },
  methods: {
    initializeScores(config) {
      this.scores = config.scores || [];
      this.scoreOptions = this.scores.map(score => {
        return {
          value: score.score_column,
          label: score.name
        };
      });
      if (this.scoreOptions.length === 1) {
        // Pre-select the first score if there is only one score
        this.selectedScore = this.scoreOptions[0].value;
        this.onScoreChange()
      }
    },
    initializeSelectedScore(config) {
      const score = get(config, this.target);
      // Initialize selected score if score exists in the config
      if (score) {
        this.selectedScore = score.column || score.id;
      }
    },
    onScoreChange() {
      const score = this.scores.find(score => score.score_column === this.selectedScore);
      const updatedScore = {
        id: score.id,
        name: score.name,
        column: score.score_column,
        type: score.score_type
      }
      this.$emit('onConfigChange', this.target, updatedScore, true);
    }
  }
}
</script>
