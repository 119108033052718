import { AnswersAction, AnswersDataSet } from 'api/interfaces';
import config from 'runtime-config';

export function getAnswerSocket(
  userId: string,
  orgId: string,
  action: AnswersAction,
  dataSet: AnswersDataSet[],
  onMessage: (e: MessageEvent) => void,
  onError: (x: Event) => void,
) {

  const answersUrl = config.answersLocation;
  const token = localStorage.getItem('access_token');

  const socket = new WebSocket(answersUrl);

  socket.addEventListener('open', () => {

    const options = {
      operation: 'agent',
      action,
      dataSet,
      user: userId,
      organization: orgId,
      apiLocation: config.apiLocation,
      Authorization: `Bearer ${ token || '' }`
    };

    socket.send(JSON.stringify(options));
  });

  socket.addEventListener('error', onError);
  socket.addEventListener('message', onMessage);

  return () => {
    socket.close();
  };

}
