import { Block } from 'lib/segments-to-blocks';
import { isEqual } from 'lodash';
import React from 'react';

const useHoveredLocation = () => {
  const [hoveredLocation, setHoveredLocation] = React.useState<[start: number, end: number] | undefined>(undefined);

  const hoverBlock = (block: Block) => {
    if (('hasThemes' in block) && block.hasThemes) {
      setHoveredLocation(block.location);
    }
  };

  const leaveBlock = () => {
    setHoveredLocation(undefined);
  };

  const persistHoveredBlock = (block: Block) => {
    if (!('location' in block)) {
      return;
    }

    if (!isEqual(hoveredLocation, block.location)) {
      setHoveredLocation(block.location);
    }
  };

  return {
    hoveredLocation,
    hoverBlock,
    leaveBlock,
    persistHoveredBlock,
  };
}

export { useHoveredLocation };
