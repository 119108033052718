import Vue from 'vue';
/*
This file is for extensions to the vue library
*/

export const watchAll = function(instance: Vue, props: string[], callback: () => void) {
  props.forEach(prop => {
    instance.$watch(prop, callback);
  });
};
