import React from 'react';
import './bar-comparison.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import toFixed from 'vue/libs/to-fixed';

interface Props {
  value: number;
  range?: number[];
  comparisonValue: number;
  suffix?: string;
  dp: number;
  maxPositive?: number;
  maxNegative?: number;
  displayPercentage?: number;
  comparisonDisplayPercentage?: number;
}

function format(value: number, precision = 1, suffix = '') {
  const prefix = value < 0 ? '' : '+';
  if (precision === 0) {
    return prefix + toFixed(Math.round(value), 0) + suffix;
  }
  return prefix + toFixed(value, precision) + suffix;
}

const BarComparison = withCamelCaseProps(({
  value,
  range,
  comparisonValue,
  suffix,
  dp = 1,
  maxPositive = 0,
  maxNegative = 0,
  displayPercentage,
  comparisonDisplayPercentage,
}: Props) => {
  const isNegative = value < 0;
  const totalDifference = Math.abs(maxNegative) + Math.abs(maxPositive);
  const xOffset = Math.abs(maxNegative / totalDifference);
  const pathCenter = (0.1 + xOffset) * 90;
  const suffixLabel = range && !suffix ? '%' : suffix || '';
  const baseline = format(value, dp, suffixLabel);
  const comparison = format(comparisonValue, dp, suffixLabel);
  const baselineVolume = displayPercent(displayPercentage);
  const comparisonVolume = displayPercent(comparisonDisplayPercentage);
  const width = Math.abs(getWidth(value)) * 90;
  const comparisonWidth = Math.abs(getWidth(comparisonValue)) * 90;
  const x = getX(value) * 90;
  const xComparison = getX(comparisonValue) * 90;

  function getWidth(val: number) {
    return val / totalDifference;
  }

  function getX(val: number) {
    return 0.1 + xOffset + getWidth(val > 0 ? 0 : val);
  }


  function displayPercent(value?: number) {
    if (value === undefined) {
      return '';
    }
    if (dp === 0) {
      return toFixed(Math.round(value), 0) + '%';
    }
    return toFixed(value, dp) + '%';
  }

  return (
    <div className="bar-comparison">
      <svg
        className="bar-comparison__indicator"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="overall">
            <stop offset="0%" stopColor={'var(--primary-400)'} />
            <stop offset="100%" stopColor={'var(--primary-300)'} />
          </linearGradient>
          <linearGradient id="comparison">
            <stop offset="0%" stopColor={'var(--orange-400)'} />
            <stop offset="100%" stopColor={'var(--orange-200)'} />
          </linearGradient>
          <linearGradient id="negative-overall">
            <stop offset="0%" stopColor={'var(--primary-300)'} />
            <stop offset="100%" stopColor={'var(--primary-400)'} />
          </linearGradient>
          <linearGradient id="negative-comparison">
            <stop offset="0%" stopColor={'var(--orange-200)'} />
            <stop offset="100%" stopColor={'var(--orange-400)'} />
          </linearGradient>
        </defs>
        <rect
          x={`${x}%`}
          width={`${width}%`}
          fill={`url(#${isNegative ? 'negative-' : ''}overall)`}
          y="9"
          rx="0"
          ry="0"
          height="10"
        />
        <rect
          x={`${xComparison}%`}
          width={`${comparisonWidth}%`}
          fill={`url(#${isNegative ? 'negative-' : ''}comparison)`}
          y="23"
          rx="0"
          ry="0"
          height="10"
        />
        <rect
          x={`${pathCenter}%`}
          y="0"
          rx="0"
          ry="0"
          width="1"
          height="40"
          fill={'var(--neutral-600)'}
        />
      </svg>
      <div
        className="bar-comparison__label bar-comparison__label--baseline"
      >
        <span className="bar-comparison__value-label" aria-label="baseline value">
          {`>${baseline}`}
        </span>
        {displayPercentage !== undefined && (
          <span className="bar-comparison__percentage-label">{baselineVolume}</span>
        )}
      </div>
      <div
        className="bar-comparison__label bar-comparison__label--comparison"
      >
        <span className="bar-comparison__value-label" aria-label="comparison value">
          {comparison}
        </span>
        {comparisonDisplayPercentage !== undefined && (
          <span className="bar-comparison__percentage-label">{comparisonVolume}</span>
        )}
      </div>
    </div>
  );
});

export { BarComparison };
