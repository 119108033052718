import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Checkbox, Message, Modal } from 'semantic-ui-react';

import AlertTag from 'images/icons/alert-tag.svg';

export interface ApplyFormProps {
  error: undefined;
  surveyName: string;
  working: boolean;
  hasNewThemes: boolean;
  onAccept(clearNewThemes: boolean): void;
  onClose(): void;
}

interface ApplyFormState {
  clearNewThemes: boolean;
  working: boolean;
}

export default class ApplyForm extends React.Component<ApplyFormProps, ApplyFormState> {
  state = {
    clearNewThemes: false,
    working: false
  };
  handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      this.doApply();
    }
  };
  onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { onClose } = this.props;
    this.setState({ working: false });
    onClose();
  };
  toggleClearNewThemes = () => {
    this.setState({ clearNewThemes: !this.state.clearNewThemes });
  };
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }
  doApply = () => {
    this.props.onAccept(this.state.clearNewThemes);
  };

  render() {
    const { error, onClose, surveyName, working, hasNewThemes } = this.props;
    const { clearNewThemes } = this.state;
    return (
      <Modal dimmer="blurring" open={true} onClose={onClose} size="tiny">
        <Modal.Content>
          <div className="modal-content">
            <AlertTag className="alert-image" />
            <div className="modal-body">
              <div className="modal-title">
                Do you want to apply these themes to “{surveyName}
                ”?
              </div>
              <div className="modal-text">
                We will email you when this is complete
              { hasNewThemes && (<div>
                <br/>
                <Checkbox
                  label="Mark new themes as reviewed"
                  checked={clearNewThemes}
                  onChange={this.toggleClearNewThemes}/>
              </div>)}
              </div>
            </div>
          </div>
          {error && (
            <Message negative={true} size="mini" icon={true}>
              <FontAwesomeIcon
                className="icon"
                icon="exclamation-triangle"
                fixedWidth={true}
              />
              <Message.Content>
                <Message.Header>Uh oh! Something went wrong.</Message.Header>
                <p>
                  We couldn’t apply your themes. Please refresh the page and try
                  again.
                </p>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={working} onClick={this.onClose}>
            Cancel
          </Button>
          <Button
            disabled={working}
            loading={working}
            color="blue"
            onClick={this.doApply}
          >
            Apply themes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
