import * as React from 'react';
import { DataSourceIntegrationStatus } from 'api/enums';
import { DataSourceIntegration } from 'api/interfaces';

type Props = {
  dataSource: DataSourceIntegration
};

const DataSourceStatusLabel = ({ dataSource }: Props): JSX.Element | null => {

  if (dataSource.status === DataSourceIntegrationStatus.STOPPED ) {
    return <div className="label label--grey">Paused</div>;
  }
  if (dataSource.lastFailureDate &&
    (!dataSource.lastSuccessDate || dataSource.lastFailureDate > dataSource.lastSuccessDate)
  ) {
    return <div className="label label--red">Errored</div>;
  }
  if (dataSource.lastSuccessDate) {
    return <div className="label label--green">Active</div>;
  }

  return null;

};

export default DataSourceStatusLabel;
