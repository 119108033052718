<template>
  <section>
    <overview-group
      :has-summary-error="hasSummaryError"
      :has-comparison="hasComparisonFilters"
      :has-summary="hasSummary"
      :has-sentiment="hasSentiment"
      :has-key-takeaways="hasKeyTakeaways"
      :baseline-summary="baselineSummary"
      :comparison-summary="comparisonSummary"
      :theme-name="themeName"
      :is-baseline-empty="isBaselineEmpty"
      :is-comparison-empty="isComparisonEmpty"
      @onRetrySummary="onRetrySummary"
    >
      <template #baseline-volume>
        <overview-volume-widget
          v-if="baselineVolume"
          :value="baselineVolume.value"
          :total="baselineVolume.total"
        />
      </template>
      <template #baseline-summary>
        <span
          class="summary"
          @mouseup="onSummaryTextSelection"
        >
          {{ baselineSummary }}
        </span>
      </template>
      <template #baseline-sentiment>
        <overview-sentiment-widget
          v-if="baselineSentiment.segments"
          :value="baselineSentiment.value"
          :pos="baselineSentiment.segments.pos"
          :neut="baselineSentiment.segments.neut"
          :neg="baselineSentiment.segments.neg"
        />
      </template>
      <template #comparison-volume>
        <overview-volume-widget
          :value="comparisonVolume.value"
          :total="comparisonVolume.total"
        />
      </template>
      <template #comparison-summary>
        <span
          class="summary"
          @mouseup="onSummaryTextSelection"
        >
          {{ comparisonSummary }}
        </span>
      </template>
      <template #comparison-sentiment>
        <overview-sentiment-widget
          v-if="comparisonSentiment.segments"
          :value="comparisonSentiment.value"
          :pos="comparisonSentiment.segments.pos"
          :neut="comparisonSentiment.segments.neut"
          :neg="comparisonSentiment.segments.neg"
        />
      </template>
      <template #kt1>
        <key-takeaway
          v-if="keyTakeaways.frequency"
          :icon="keyTakeaways.frequency.icon"
          :html="keyTakeaways.frequency.html"
        />
      </template>
      <template #kt2>
        <key-takeaway
          v-if="keyTakeaways.impact"
          :icon="keyTakeaways.impact.icon"
          :html="keyTakeaways.impact.html"
        />
      </template>
    </overview-group>
    <similar-sentences
      v-if="!hasSummaryError && hasSummary"
      :limit="sentenceLimit"
      :are-support-messages-visible="areSupportMessagesVisible"
      :has-comparison-filter="hasComparisonFilters"
      :baseline="baselineClusters"
      :comparison="comparisonClusters"
      :selected-theme-title="selectedThemeTitle"
      :selected-theme-codes="selectedThemeCodes"
      :has-sentiment="hasSentiment"
      :is-apply-themes="isApplyingThemes"
      :themes-hierarchy="themesHierarchy"
      :transforms="transforms"
      :can-manage-themes="canManageThemes"
      :thread-display-config="threadDisplayConfig"
      :edited-comments="editedComments"
      :survey-id="surveyId"
      :org-id="orgId"
    />
  </section>
</template>

<script>
  import OverviewGroup from "./OverviewGroup.vue";
  import { ReactInVue } from 'vuera';
  import KeyTakeaway from "./KeyTakeaway.vue";
  import OverviewVolumeWidget from './overview-widgets/OverviewVolumeWidget.vue';
  import OverviewSentimentWidget from "./overview-widgets/OverviewSentimentWidget.vue";
  import { SimilarSentences as ReactSimilarSentences }  from "components/SimilarSentences/SimilarSentences";

  export default {
    name: 'Overview',
    components: {
      OverviewGroup,
      OverviewSentimentWidget,
      OverviewVolumeWidget,
      KeyTakeaway,
      SimilarSentences: ReactInVue(ReactSimilarSentences)
    },
    props: {
      areSupportMessagesVisible: Boolean,
      baselineClusters: Array,
      baselineSentiment: Object,
      baselineSummary: String,
      baselineVolume: Object,
      canManageThemes: Boolean,
      comparisonClusters: Array,
      comparisonSentiment: Object,
      comparisonSummary: String,
      comparisonVolume: Object,
      editedComments: Object,
      hasComparisonFilters: Boolean,
      hasKeyTakeaways: Boolean,
      hasSentiment: Boolean,
      hasSummary: Boolean,
      hasSummaryError: Boolean,
      isApplyingThemes: Boolean,
      isBaselineEmpty: Boolean,
      isComparisonEmpty: Boolean,
      keyTakeaways: Object,
      selectedThemeCodes: Object,
      selectedThemeTitle: String,
      sentenceLimit: Number,
      themeName: String,
      themesHierarchy: Object,
      threadDisplayConfig: Object,
      transforms: Array,
      surveyId: String,
      orgId: String,
    },
    methods: {
      onSummaryTextSelection() {
        this.$emit('on-summary-text-selection');
      },
      onRetrySummary() {
        this.$emit('on-retry-summary');
      }
    }
  }
</script>
