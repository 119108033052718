export const SPIKE_TYPES = [
  {
    key: 'volume',
    text: 'Volume Spikes',
    value: 'volume',
  },
  {
    key: 'sentiment',
    text: 'Sentiment Spikes',
    value: 'sentiment',
  }
];

export const MWD_DATE_INTERVAL_OPTIONS = [
  {
    key: 'daily',
    text: 'Daily',
    value: 'daily',
  },
  {
    key: 'weekly',
    text: 'Weekly',
    value: 'weekly',
  },
  {
    key: 'monthly',
    text: 'Monthly',
    value: 'monthly',
  }
];

export const DISCOVERY_INTERVAL = [
  {
    key: 'weekly',
    text: 'Weekly',
    value: 'weekly',
  },
  {
    key: 'monthly',
    text: 'Monthly',
    value: 'monthly',
  }
];

export const TIME = [
  {
    key: '12_am',
    text: '12:00 am',
    value: 0,
  },
  {
    key: '1_am',
    text: '1:00 am',
    value: 1,
  },
  {
    key: '2_am',
    text: '2:00 am',
    value: 2,
  },
  {
    key: '3_am',
    text: '3:00 am',
    value: 3,
  },
  {
    key: '4_am',
    text: '4:00 am',
    value: 4,
  },
  {
    key: '5_am',
    text: '5:00 am',
    value: 5,
  },
  {
    key: '6_am',
    text: '6:00 am',
    value: 6,
  },
  {
    key: '7_am',
    text: '7:00 am',
    value: 7,
  },
  {
    key: '8_am',
    text: '8:00 am',
    value: 8,
  },
  {
    key: '9_am',
    text: '9:00 am',
    value: 9,
  },
  {
    key: '10_am',
    text: '10:00 am',
    value: 10,
  },
  {
    key: '11_am',
    text: '11:00 am',
    value: 11,
  },
  {
    key: '12_pm',
    text: '12:00 pm',
    value: 12,
  },
  {
    key: '1_pm',
    text: '1:00 pm',
    value: 13,
  },
  {
    key: '2_pm',
    text: '2:00 pm',
    value: 14,
  },
  {
    key: '3_pm',
    text: '3:00 pm',
    value: 15,
  },
  {
    key: '4_pm',
    text: '4:00 pm',
    value: 16,
  },
  {
    key: '5_pm',
    text: '5:00 pm',
    value: 17,
  },
  {
    key: '6_pm',
    text: '6:00 pm',
    value: 18,
  },
  {
    key: '7_pm',
    text: '7:00 pm',
    value: 19,
  },
  {
    key: '8_pm',
    text: '8:00 pm',
    value: 20,
  },
  {
    key: '9_pm',
    text: '9:00 pm',
    value: 21,
  },
  {
    key: '10_pm',
    text: '10:00 pm',
    value: 22,
  },
  {
    key: '11_pm',
    text: '11:00 pm',
    value: 23,
  },
];

export const WEEK_DAYS = [
  {
    key: 'monday',
    text: 'Monday',
    value: 1,
  },
  {
    key: 'tuesday',
    text: 'Tuesday',
    value: 2,
  },
  {
    key: 'wednesday',
    text: 'Wednesday',
    value: 3,
  },
  {
    key: 'thursday',
    text: 'Thursday',
    value: 4,
  },
  {
    key: 'friday',
    text: 'Friday',
    value: 5,
  },
  {
    key: 'saturday',
    text: 'Saturday',
    value: 6,
  },
  {
    key: 'sunday',
    text: 'Sunday',
    value: 0,
  }
];

export const DAYS = [
  {
    key: '1_st',
    text: '1st',
    value: 1,
  },
  {
    key: '2_nd',
    text: '2nd',
    value: 2,
  },
  {
    key: '3_rd',
    text: '3rd',
    value: 3,
  },
  {
    key: '4_th',
    text: '4th',
    value: 4,
  },
  {
    key: '5_th',
    text: '5th',
    value: 5,
  },
  {
    key: '6_th',
    text: '6th',
    value: 6,
  },
  {
    key: '7_th',
    text: '7th',
    value: 7,
  },
  {
    key: '8_th',
    text: '8th',
    value: 8,
  },
  {
    key: '9_th',
    text: '9th',
    value: 9,
  },
  {
    key: '10_th',
    text: '10th',
    value: 10,
  },
  {
    key: '11_th',
    text: '11th',
    value: 11,
  },
  {
    key: '12_th',
    text: '12th',
    value: 12,
  },
  {
    key: '13_th',
    text: '13th',
    value: 13,
  },
  {
    key: '14_th',
    text: '14th',
    value: 14,
  },
];

export const DATA_COMPARISON_OPTIONS = [
  {
    key: 'previous_seven_days',
    text: 'Previous 7 days',
    value: 1,
  },
  {
    key: 'previous_seven_same_day_of_week',
    text: 'Previous 7 same day of week',
    value: 7,
  }
];

export const ACTION_OPTIONS = [
  {
    key: 'email',
    text: 'Email',
    value: 'email',
  },
  {
    key: 'slack',
    text: 'Slack',
    value: 'slack',
  }
];