import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Form } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceAskNicelyCreateFormProps {
    onChange(configuration: object, integrationId: string): void;
}

interface AskNicelySurveyInfo {
    NPS: string;
}

interface DataSourceAskNicelyCreateFormState {
}

@observer
class DataSourceAskNicelyCreateForm extends React.Component<
    DataSourceAskNicelyCreateFormProps,
    DataSourceAskNicelyCreateFormState
> {

  renderForm(selectedSurvey: AskNicelySurveyInfo | undefined): JSX.Element | null {
    if (selectedSurvey) {
      return (
        <Form>
            <Form.Field>
                <label>Current NPS</label> <span>{selectedSurvey.NPS}</span>
            </Form.Field>
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
        <div>
            <DataSourceWithIntegrationForm
              renderFormComponent={this}
              onChange={onChange}
              integrationType={IntegrationType.ASKNICELY} />
        </div>
    );
  }
}

export default DataSourceAskNicelyCreateForm;