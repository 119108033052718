import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterType, IntegrationType, WorkflowActionType } from 'api/enums';
import { AnalysisViewSource, SegmentFilters } from 'api/interfaces';
import { WorkflowConfiguration } from 'api/workflow-interfaces';
import { filterPopUpId } from 'components/Filters/types';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Button, Dropdown, Form, Popup, Segment
} from 'semantic-ui-react';
import { convertTimeOfDayFromUtc, convertTimeOfDayToUtc, getTimezoneAbbreviation } from 'vue/libs/calculate-time';
import EmailAction, { EmailsObject } from '../components/EmailAction';
import SlackAction from '../components/SlackAction';
import {
  ACTION_OPTIONS, DATA_COMPARISON_OPTIONS,
  DAYS, MWD_DATE_INTERVAL_OPTIONS, SPIKE_TYPES, TIME, WEEK_DAYS
} from '../constants';
import SingleFilter from 'components/Filters/SingleFilter';
import { TemplateProps } from './WorkflowTemplateBase';
import './spike-template.scss';
import { initializeFilters } from 'components/Filters/filters.service';
import { DatasetVis } from 'stores/data-structure/datasets';
import { flatten, parseAsNested } from 'lib/dataset-tool-parser';
import { getIdFromDatasetVis } from 'lib/dataset-picker-helper';
import { queryFiltersToSegmentFilters } from 'lib/query-filters-to-segment-filters';
import { QueryFilter } from 'stores/types';
import { getSlackIntegrationId } from '../workflow-helper';

export interface SpikeTemplateState {
  enteredEmailValidation: boolean;
  enteredEmailValue: string;
  selectedRole: string;
  selectedSurveyId: string | undefined;
  selectedVisualizationId: string | undefined;
  selectedViewId: string | undefined;
  selectedNotifyType: string | undefined;
  fetchingData: boolean;
  templateTitle: string;
  selectedSpikeType: string;
  selectedSpikeInterval: string;
  selectedSpikeIntervalOffset: number;
  selectedPeriodStep: number;
  enteredEmails: string[];
  selectedSlackIntegration?: string;
  selectedSlackChannel?: string;
  configCorrect: boolean;
  selectedFilterRql: string;
  selectedFilters: SegmentFilters;
}

@inject(
  'organizationStore',
  'workflowsStore',
  'roleStore',
  'surveyStore',
  'filterStore',
  'integrationsStore',
)
@observer
class SpikeTemplate extends React.Component<TemplateProps, SpikeTemplateState> {
  state = {
    selectedRole: '',
    selectedSurveyId: undefined,
    selectedVisualizationId: undefined,
    selectedViewId: undefined,
    selectedNotifyType: this.props.configuration ? '' : ACTION_OPTIONS[0].value,
    fetchingData: false,
    templateTitle: '',
    selectedSpikeType: SPIKE_TYPES[0].value,
    selectedSpikeInterval: MWD_DATE_INTERVAL_OPTIONS[0].value,
    selectedSpikeIntervalOffset: 0,
    selectedPeriodStep: DATA_COMPARISON_OPTIONS[0].value,
    enteredEmails: [],
    configCorrect: false,
    selectedFilterRql: '',
    selectedFilters: {},
    selectedSlackChannel: '',
    enteredEmailValidation: false,
    enteredEmailValue: ''
  } as SpikeTemplateState;

  get roleStore() {
    return this.props.roleStore!;
  }

  get surveyStore() {
    return this.props.surveyStore!;
  }

  get filterStore() {
    return this.props.filterStore!;
  }

  get integrationsStore() {
    return this.props.integrationsStore!;
  }

  static configurationMatches(configuration: WorkflowConfiguration): { dimension: string } | null {
    if (
      configuration.name === 'Spike Template' ||
      configuration.templateId === 'spike'
    ) {
      return { dimension: 'spike' };
    }
    return null;
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.setState({ fetchingData: true });
    this.suggestName();
    // fetch surveys and roles if necessary
    const promises = [] as Promise<void>[];
    if (this.roleStore.roles.length === 0) {
      promises.push(this.roleStore.fetchRoles());
    }
    if (!this.surveyStore.surveysJson) {
      promises.push(this.surveyStore.fetchSurveys());
    }
    await Promise.all(promises);
    if (this.props.configuration) {
      this.initializeConfig();
    }
    this.preselectDefaults();
    await this.initializeFilters();
    this.setState({ fetchingData: false });
    this.determineConfigCorrect();
  }

  initializeConfig = () => {
    const { configuration: config, triggers } = this.props;
    if (config) {
      const spikeDetectionParams = config.stateMachine.States.SpikeDetection.Parameters;
      const notify = config.stateMachine.States.Notify;
      const currentIntegration = this.integrationsStore.getConnectedIntegrationsOfType(IntegrationType.SLACK);
      let notifyType: WorkflowActionType | undefined;
      let selectedSlackIntegration = '';
      let selectedSlackChannel = '';
      let enteredEmails: string[] = [];
      if (notify.Resource === 'local:notify_email') {
        notifyType = WorkflowActionType.EMAIL;
        enteredEmails = notify.Parameters.emailList.split(',');
      } else if (notify.Resource === 'local:notify_slack') {
        notifyType = WorkflowActionType.SLACK;
        selectedSlackIntegration = getSlackIntegrationId(config, currentIntegration);
        selectedSlackChannel = notify.Parameters.channel;
      }

      let timeOffset = triggers.calledWithCadence!.offset;
      if (triggers.calledWithCadence!.period === 'daily') {
        timeOffset = convertTimeOfDayFromUtc(triggers.calledWithCadence!.offset);
      }

      this.setState({
        selectedSpikeInterval: triggers.calledWithCadence!.period,
        selectedNotifyType: notifyType,
        selectedSlackIntegration,
        selectedSlackChannel,
        enteredEmails,
        selectedPeriodStep: spikeDetectionParams.periodStep,
        selectedSpikeIntervalOffset: timeOffset,
        selectedSpikeType: spikeDetectionParams.spikeType,
        selectedFilterRql: spikeDetectionParams.filter,
        selectedFilters: spikeDetectionParams.filterMetadata,
      });
    }
  }

  initializeFilters = async () => {
    const {
      selectedSurveyId,
      selectedViewId,
      selectedVisualizationId,
    } = this.state;

    if (!selectedSurveyId) {
      return;
    }
    const selectedFilters = this.state.selectedFilters;
    const source: AnalysisViewSource = {
      survey: selectedSurveyId,
      visualization: selectedVisualizationId || '_',
      view: selectedViewId
    };

    initializeFilters(
      source,
      [FilterType.DATE, FilterType.THEMES],
      selectedFilters
    );
  }

  preselectDefaults = () => {
    const { selectedRole, configuration: config } = this.props;
    const defaultRole = this.roleStore.roles.find(role => role.name === 'Dataset Administration');
    if (defaultRole) {
      this.selectedRole(null, { value: defaultRole.id });
    } else {
      this.selectedRole(null, { value: this.roleStore.roles[0].id });
    }
    if (this.surveyStore.surveysJson!.length === 1) {
      this.handleSurveySelection(null, { value: this.surveyStore.surveysJson![0].id });
    }

    if (config) {
      const spikeDetectionParams = config.stateMachine.States.SpikeDetection.Parameters;
      if (selectedRole) {
        this.selectedRole(null, { value: `${ selectedRole }` });
      }
      if (spikeDetectionParams.surveyId) {
        this.setState({
          selectedSurveyId: spikeDetectionParams.surveyId,
          selectedVisualizationId: spikeDetectionParams.visualizationId,
          selectedViewId: spikeDetectionParams.viewId,
        });
      }
    }
  }

  suggestName = () => {
    const selectedDatasetName = this.getSelectedDataset()?.fullTitle;
    const { selectedSpikeType } = this.state;
    SPIKE_TYPES.forEach(element => {
      if (selectedSpikeType === element.value) {
        let suggestedTitle = element.text;
        if (selectedDatasetName) {
          suggestedTitle += ` in ${ selectedDatasetName }`;
        }
        const selectedFiltersTitle = this.getSelectedFiltersTitle();
        if (!!selectedFiltersTitle && selectedFiltersTitle.length > 0 && selectedFiltersTitle !== 'All') {
          suggestedTitle += `: ${ selectedFiltersTitle }`;
        }
        this.props.onSuggestName(suggestedTitle);
      }
    });
  }

  getSelectedFiltersTitle = () => {
    const { selectedFilters } = this.state;
    let selectedFiltersTitleString = this.filterStore.queryTitles.baseline;
    if (Object.keys(selectedFilters).length === 0) {
      // empty title if no filters are selected
      selectedFiltersTitleString = '';
    }
    if (selectedFiltersTitleString !== 'All') {
      return selectedFiltersTitleString;
    }
    return;
  }

  selectedRole = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    this.setState({ selectedRole: value }, () => { this.determineConfigCorrect(); });
  }

  handleSurveySelection = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    const flattenedDatasets = this.getFlattenedDatasets();

    if (flattenedDatasets.length === 0) {
      return;
    }

    const selectedDataset = flattenedDatasets.find((dataset) => getIdFromDatasetVis(dataset) === value);
    const selectedSurveyId = selectedDataset?.surveyId;
    const selectedViewId = selectedDataset?.viewId;
    const selectedVisualizationId = selectedDataset?.visualizationId;

    this.setState({
      selectedSurveyId,
      selectedVisualizationId,
      selectedViewId,
      selectedFilterRql: '',
      selectedFilters: {},
    }, () => {
      this.determineConfigCorrect();
      this.suggestName();
      this.initializeFilters();
    });
  }

  selectedSpikeInterval = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    let defaultSelectedSpikeIntervalOffset = 0;
    if (value === 'weekly' || value === 'monthly') {
      defaultSelectedSpikeIntervalOffset = 1;
    }
    if (value !== 'daily') {
      this.setState({ selectedPeriodStep: 1 });
    }
    this.setState({
      selectedSpikeInterval: value,
      selectedSpikeIntervalOffset: defaultSelectedSpikeIntervalOffset
    },
      () => { this.determineConfigCorrect(); }
    );
  }

  selectedSpikeType = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    this.setState({ selectedSpikeType: value }, () => { this.determineConfigCorrect(); this.suggestName(); });
  }

  selectedSpikeIntervalOffsetChange = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    this.setState({ selectedSpikeIntervalOffset: value }, () => { this.determineConfigCorrect(); });
  }

  selectedPeriodStepChange = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    this.setState({ selectedPeriodStep: value }, () => { this.determineConfigCorrect(); });
  }

  selectedNotifyTypeChange = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    this.setState({ selectedNotifyType: value, enteredEmails: [], selectedSlackChannel: undefined },
      () => { this.determineConfigCorrect(); }
    );
  }

  handleEnteredEmails = (emailsObject: EmailsObject) => {
    this.setState(
      {
        enteredEmails: emailsObject.emails,
        enteredEmailValidation: !!emailsObject.enteredValueValidation
      }, () => {
        if (!!emailsObject.enteredValueValidation && !!emailsObject.enteredValue) {
          this.setState({ enteredEmailValue: emailsObject.enteredValue });
        } else {
          this.setState({ enteredEmailValue: '' });
        }
        this.determineConfigCorrect();
      }
    );
  }

  fetchEnteredEmailDetails = (validation: boolean, enteredValue: string) => {
    // this is so the user can save the created workflow with entered email without accepting it
    this.setState({ enteredEmailValidation: validation }, () => {
      if (validation) {
        this.setState({ enteredEmailValue: enteredValue });
      } else {
        this.setState({ enteredEmailValue: '' });
      }
      this.determineConfigCorrect();
    }
    );
  }

  handleSelectedChannel = (integrationId?: string, channel?: string) => {
    this.setState({
      selectedSlackIntegration: integrationId,
      selectedSlackChannel: channel
    },
      () => { this.determineConfigCorrect(); }
    );
  }

  determineConfigCorrect = () => {
    const {
      selectedSpikeInterval,
      selectedSurveyId,
      selectedRole,
      selectedPeriodStep,
      selectedNotifyType,
      enteredEmails,
      selectedSlackIntegration,
      selectedSlackChannel,
      enteredEmailValidation
    } = this.state;
    let configCorrect = !!selectedSpikeInterval && !!selectedSurveyId && !!selectedRole;
    // check for valid periods
    configCorrect = configCorrect && (
      selectedSpikeInterval === 'daily' ?
        (selectedPeriodStep === 1 || selectedPeriodStep === 7) :
        selectedPeriodStep === 1);
    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      configCorrect = configCorrect && (enteredEmails.length > 0 || enteredEmailValidation);
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      configCorrect = configCorrect && !!selectedSlackIntegration && !!selectedSlackChannel;
    } else {
      configCorrect = false;
    }
    this.setState({ configCorrect });
  }

  onSave = async () => {
    const {
      workflowId
    } = this.props;
    const {
      selectedSpikeInterval,
      selectedSurveyId,
      selectedViewId,
      selectedVisualizationId,
      selectedNotifyType,
      selectedSlackIntegration,
      selectedSlackChannel,
      enteredEmails,
      selectedRole,
      selectedPeriodStep,
      selectedSpikeIntervalOffset,
      selectedSpikeType,
      selectedFilterRql,
      selectedFilters,
      enteredEmailValue
    } = this.state;

    let spikeIntervalOffset = selectedSpikeIntervalOffset;
    if (selectedSpikeInterval === 'daily') {
      spikeIntervalOffset = convertTimeOfDayToUtc(selectedSpikeIntervalOffset);
    }
    const updatedEmailsList = enteredEmailValue.length > 0 ? [...enteredEmails, enteredEmailValue] : enteredEmails;

    const selectedFiltersTitle = this.getSelectedFiltersTitle();
    let notifyBlock = {};
    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      notifyBlock = {
        'Resource': 'local:notify_email',
        'Parameters': {
          'emailList': updatedEmailsList.join(','),
          'templateData': {
            'filtersTitle': selectedFiltersTitle,
          },
          'template': 'CQZN1RZ88W4MQ3MYP1GCPWCYS14V',
          'data.$': '$.spikes',
          'workflowId.$': '$.workflowId',
          'accessToken.$': '$.accessToken',
          'clientUrl.$': '$.clientUrl',
          'runId.$': '$.runId',
          'workflowName.$': '$.workflowName',
        },
        'ResultPath': '$.result',
        'Type': 'Task',
        'Next': 'End'
      };
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      notifyBlock = {
        'Resource': 'local:notify_slack',
        'Parameters': {
          'integrationId': `${ selectedSlackIntegration }`,
          'channel': selectedSlackChannel,
          'templateData': {
            'filtersTitle': selectedFiltersTitle,
          },
          'template': 'CQZN1RZ88W4MQ3MYP1GCPWCYS14V',
          'data.$': '$.spikes',
          'accessToken.$': '$.accessToken',
          'clientUrl.$': '$.clientUrl',
          'workflowId.$': '$.workflowId',
          'runId.$': '$.runId',
          'workflowName.$': '$.workflowName',
        },
        'ResultPath': '$.result',
        'Type': 'Task',
        'Next': 'End'
      };
    }

    let config = {
      'stateMachine': {
        'StartAt': 'SpikeDetection',
        'States': {
          'SpikeDetection': {
            'Resource': 'local:process_spike',
            'Parameters': {
              surveyId: selectedSurveyId,
              visualizationId: selectedVisualizationId,
              viewId: selectedViewId,
              'dateResolution': selectedSpikeInterval,
              'previousPeriods': 5,
              'periodStep': selectedPeriodStep,
              'filter': selectedFilterRql,
              'filterMetadata': selectedFilters,
              'spikeType': selectedSpikeType,
              'accessToken.$': '$.accessToken',
              'clientUrl.$': '$.clientUrl',
              'artifactsDestination.$': '$.artifactsDestination'
            },
            'InputPath': '$.input',
            'ResultPath': '$.spikes',
            'Type': 'Task',
            'Next': 'Choices'
          },
          'Choices': {
            'Type': 'Choice',
            'Choices': [
              { 'Variable': '$.spikes.itemCount', 'NumericGreaterThan': 0, 'Next': 'Notify' }
            ],
            'Default': 'End'
          },
          'Notify': notifyBlock,
          'End': {
            'Type': 'Pass',
            'End': true
          }
        }
      },
      'templateId': 'spike'
    };
    let trigger = {
      calledWithCadence: {
        period: selectedSpikeInterval,
        offset: spikeIntervalOffset
      }
    };

    const workflowAnalyticsEvent = workflowId === 'create' ? 'Workflows: Save Workflow' : 'Workflows: Update Workflow';
    analytics.track(workflowAnalyticsEvent, {
      category: 'Workflows',
      template: 'Spike Template',
      dimension: selectedSpikeType,
      notify: selectedNotifyType
    });

    await this.props.onSave(selectedRole, trigger, config);
    this.resetTemplateScroll();
  }

  onFilterChange = (filters: Record<string, QueryFilter>) => {
    const rql = this.filterStore.selections.baseline.query;
    const dateShortcuts = this.filterStore.dateShortcutMaps['baseline'];
    const segmentFilters = queryFiltersToSegmentFilters(filters, dateShortcuts);

    this.setState({
      selectedFilters: segmentFilters,
      selectedFilterRql: rql
    }, () => { this.suggestName(); });
  }

  resetTemplateScroll = () => {
    const {
      createWorkflowErroredMessage,
      updateWorkflowErroredMessage,
    } = this.props.workflowsStore!;

    const anyErrorsExist = !!createWorkflowErroredMessage || !!updateWorkflowErroredMessage;

    if (anyErrorsExist) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  getFlattenedDatasets():DatasetVis[] {
    const { surveyStore, organizationStore } = this.props;
    if (!organizationStore || !surveyStore) {
      return [];
    }
    const { surveysJson, getSurveyCanAction } = surveyStore;
    const { orgId, getCanAction } = organizationStore;

    const canThematicAdmin = getCanAction('manage:internalResource');
    const nested = parseAsNested(
      orgId,
      surveysJson || [],
      getSurveyCanAction,
      canThematicAdmin,
      'read:survey'
    );
    return flatten(nested);
  }

  getSelectedDatasetId() {
    const {
      selectedSurveyId,
      selectedViewId,
      selectedVisualizationId,
    } = this.state;
    return `${selectedSurveyId}-${selectedViewId || ''}-${selectedVisualizationId || '_'}`;
  }

  getSelectedDataset(): DatasetVis | undefined {
    const flattenedDatasets = this.getFlattenedDatasets();
    const selectedDataSetId = this.getSelectedDatasetId();
    return flattenedDatasets.find((dataset) => getIdFromDatasetVis(dataset) === selectedDataSetId);
  }

  render(): JSX.Element | null {
    const {
      fetchingData,
      configCorrect,
      selectedRole,
      selectedSurveyId,
      selectedNotifyType,
      selectedFilters,
      selectedSpikeType,
      selectedSpikeInterval,
      selectedSpikeIntervalOffset,
      selectedPeriodStep,
      enteredEmails,
      selectedSlackChannel,
    } = this.state;

    const {
      performingEvent,
      loading,
      workflowId,
      onCancel
    } = this.props;

    const timezoneAbbreviation = getTimezoneAbbreviation();

    const flattened = this.getFlattenedDatasets();
    const selectedDataSetId = this.getSelectedDatasetId();

    const datasetOptions = flattened.map((vis: DatasetVis) => {
      return {
        key: getIdFromDatasetVis(vis),
        text: vis.fullTitle,
        value: getIdFromDatasetVis(vis)
      };
    });

    return (
      <Form className="spike-template">
        <Form.Field className="spike-template-title">
          <label>
            Get alerted about&nbsp;
            <Dropdown
              inline={true}
              className="spike-template-inline-dropdown"
              options={SPIKE_TYPES}
              value={selectedSpikeType}
              onChange={this.selectedSpikeType}
            />&nbsp;with significance
            <Popup
              content="A significant change is 2 standard deviations above or below the recent average."
              position="right center"
              trigger={
                <FontAwesomeIcon
                  className="icon spike-template-info"
                  icon="info-circle"
                  fixedWidth={true}
                />
              }
            />
          </label>
        </Form.Field>
        <Form.Field className="spike-template-role">
          <h2>Conditions</h2>
          <label>Run workflow using the&nbsp;
            <Dropdown
              inline={true}
              className="spike-template-inline-dropdown"
              placeholder="Select role"
              loading={fetchingData}
              options={
                (this.roleStore.roles.filter(role => !role.isUserSpecific) || []).map(value => {
                  return {
                    key: value.id,
                    text: value.name,
                    value: value.id
                  };
                })
              }
              value={selectedRole}
              onChange={this.selectedRole}
            />&nbsp;role
          </label>
        </Form.Field>
        <Form.Field className="spike-template-dataset">
          <h2>Analyze</h2>
          <div className="spike-template-dataset-options">
            <Dropdown
              placeholder="Select dataset"
              fluid={true}
              selection={true}
              loading={fetchingData}
              icon={<FontAwesomeIcon icon="chevron-down" />}
              options={datasetOptions}
              value={selectedDataSetId}
              onChange={this.handleSurveySelection}
            />
            <span className="spike-template-dataset__filter">
              {selectedSurveyId && (
                <SingleFilter
                  testId="spike-template-filter"
                  filterPopUpId={filterPopUpId.SpikeTemplate}
                  surveyId={selectedSurveyId}
                  selectedFilters={selectedFilters}
                  disabledFilterTypes={[FilterType.DATE, FilterType.THEMES]}
                  onChange={this.onFilterChange}
                  buttonSize="medium"
                />
              )}
            </span>
          </div>
        </Form.Field>
        <Form.Field className="workflow-schedule">
          <h2>Schedule</h2>
          <div className="check-spikes">
            <Dropdown
              className="spike-interval"
              fluid={true}
              selection={true}
              options={MWD_DATE_INTERVAL_OPTIONS}
              value={selectedSpikeInterval}
              onChange={this.selectedSpikeInterval}
            />
            {this.state.selectedSpikeInterval === 'daily' &&
              <div className="check-spikes-selection">
                at
                <Dropdown
                  fluid={true}
                  selection={true}
                  options={TIME}
                  value={selectedSpikeIntervalOffset}
                  onChange={this.selectedSpikeIntervalOffsetChange}
                />
                {timezoneAbbreviation},
                comparing the most recent day with the
                <Dropdown
                  fluid={true}
                  selection={true}
                  options={DATA_COMPARISON_OPTIONS}
                  value={selectedPeriodStep}
                  onChange={this.selectedPeriodStepChange}
                />
                <Popup
                  content={
                    <div>
                      <h4>Previous 7 days</h4>
                      <p>
                        The data of each day will be compared to the average for the previous 7 days.
                      </p>
                      <h4>Previous 7 same day of week</h4>
                      <p>
                        The data of each day will be compared to the same day of each weeks.
                        For example, this Monday will be compared to the previous Mondays.
                      </p>
                    </div>
                  }
                  trigger={
                    <FontAwesomeIcon
                      className="icon data-comparison-info"
                      icon="info-circle"
                      fixedWidth={true}
                    />
                  }
                />
              </div>
            }
            {this.state.selectedSpikeInterval === 'weekly' &&
              <div>
                on
                <Dropdown
                  fluid={true}
                  selection={true}
                  options={WEEK_DAYS}
                  value={selectedSpikeIntervalOffset}
                  onChange={this.selectedSpikeIntervalOffsetChange}
                />,
                comparing the most recent week with the previous 5 weeks.
              </div>
            }
            {this.state.selectedSpikeInterval === 'monthly' &&
              <div>
                on the
                <Dropdown
                  fluid={true}
                  selection={true}
                  options={DAYS}
                  value={selectedSpikeIntervalOffset}
                  onChange={this.selectedSpikeIntervalOffsetChange}
                />,
                comparing the most recent month with the previous 5 months.
              </div>
            }
          </div>
        </Form.Field>
        <Form.Field>
          <Segment className="email-action">
            <label>Actions</label>
            <Dropdown
              fluid={true}
              selection={true}
              options={ACTION_OPTIONS}
              value={selectedNotifyType}
              onChange={this.selectedNotifyTypeChange}
            />
            {selectedNotifyType === WorkflowActionType.EMAIL && (
              <EmailAction
                existingEmails={enteredEmails}
                enteredEmails={this.handleEnteredEmails}
              />
            )}
            {selectedNotifyType === WorkflowActionType.SLACK && (
              <SlackAction
                existingSlackChannel={selectedSlackChannel}
                selectedSlackChannel={this.handleSelectedChannel}
              />
            )}
          </Segment>
        </Form.Field>
        <Button
          type="button"
          disabled={performingEvent || fetchingData}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="button"
          color="blue"
          loading={loading || fetchingData}
          disabled={performingEvent || fetchingData || !configCorrect}
          onClick={this.onSave}
        >
          {workflowId === 'create' ? 'Save workflow' : 'Update'}
        </Button>
      </Form>
    );
  }
}

export default SpikeTemplate;
