
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';

function erf(x: number) {
  let z: number;
  const ERF_A = 0.147;
  let theSignOfX: number;
  if (0 === x) {
    theSignOfX = 0;
    return 0;
  } else if (x > 0) {
    theSignOfX = 1;
  } else {
    theSignOfX = -1;
  }

  let onePlusAxsqrd = 1 + ERF_A * x * x;
  let fourOvrPiEtc = 4 / Math.PI + ERF_A * x * x;
  let ratio = fourOvrPiEtc / onePlusAxsqrd;
  ratio *= x * -x;
  let expofun = Math.exp(ratio);
  let radical = Math.sqrt(1 - expofun);
  z = radical * theSignOfX;
  return z;
}

function statistical_significance(
  population1Count: number,
  population1Total: number,
  population2Count: number,
  population2Total: number
) {
  let p =
    (population2Count + population1Count) /
    (population2Total + population1Total);
  let Z =
    population1Count / population1Total -
    population2Count / population2Total;
  try {
    Z =
      Z /
      Math.sqrt(p * (1 - p) * (1 / population2Total + 1 / population1Total));
  } catch (err) {
    Z = 0;
  }
  p = 1.0 - 0.5 * (1 + erf(Z / Math.sqrt(2)));
  if (Z < 0) {
    p = 1.0 - p;
  }
  // set icon if statistically significant difference
  return [p, Z];
}

function two_way_statistical_significance(
  population1Count: number,
  population1Total: number,
  population2Count: number,
  population2Total: number
) {
  if (population1Total === 0 || population2Total === 0) {
    return [0, 0];
  }
  // try test that there is more in pop2
  const p = statistical_significance(
    population1Count,
    population1Total,
    population2Count,
    population2Total
  );
  const p2 = statistical_significance(
    population2Count,
    population2Total,
    population1Count,
    population1Total
  );
  return p[0] < p2[0] ? p : p2;
}

function thresholded_two_way_statistical_significance(
  population1Count: number,
  population1Total: number,
  population2Count: number,
  population2Total: number,
  threshold?: number
) {
  if (threshold === undefined) {
    threshold = 0.05;
  }
  const p = two_way_statistical_significance(
    population1Count,
    population1Total,
    population2Count,
    population2Total
  );
  if (p[0] < threshold) {
    return p[1] < 0 ? 1 : -1;
  }
  return 0;
}

function create_sig_diff_values(themes1: any, themes2: any, threshold?: number) {
  let pop1 = nonemptyCountFromCountsObject(themes1.counts);
  let pop2 = nonemptyCountFromCountsObject(themes2.counts);
  let count2 = 0;
  // iterate over all themes in themes1 and figure out sig diff based on themes2
  for (let theme in themes1.themes) {
    if (theme in themes1.themes) {
      count2 = 0;
      if (theme in themes2.themes) {
        for (let subtheme in themes1.themes[theme].subthemes) {
          if (subtheme in themes1.themes[theme].subthemes) {
            count2 = 0;
            if (subtheme in themes2.themes[theme].subthemes) {
              count2 = themes2.themes[theme].subthemes[subtheme].count;
            }
            themes1.themes[theme].subthemes[
              subtheme
            ].sig_diff = thresholded_two_way_statistical_significance(
              themes1.themes[theme].subthemes[subtheme].count,
              pop1,
              count2,
              pop2,
              threshold
            );
          }
        }
        count2 = themes2.themes[theme].count;
      }
      themes1.themes[
        theme
      ].sig_diff = thresholded_two_way_statistical_significance(
        themes1.themes[theme].count,
        pop1,
        count2,
        pop2,
        threshold
      );
    }
  }
}

/**
 *  From https://stackoverflow.com/a/41089665/6734812
 */
function pearson_correlation(x: any, y: any) {
  let sumX = 0,
    sumY = 0,
    sumXY = 0,
    sumX2 = 0,
    sumY2 = 0;
  let minLength = (x.length = y.length = Math.min(x.length, y.length)),
    reduce = function(xi: number, idx: number) {
      let yi = y[idx];
      sumX += xi;
      sumY += yi;
      sumXY += xi * yi;
      sumX2 += xi * xi;
      sumY2 += yi * yi;
    };
  x.forEach(reduce);
  return (
    (minLength * sumXY - sumX * sumY) /
    Math.sqrt(
      (minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY)
    )
  );
}

// END from https://stackoverflow.com/a/41089665/6734812

/** From https://dracoblue.net/dev/linear-least-squares-in-javascript/ */

function find_line_by_least_squares(valuesX: any[], valuesY: any[]) {
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let count = 0;

  /*
   * We'll use those variables for faster read/write access.
   */
  let x = 0;
  let y = 0;
  let valuesLength = valuesX.length;

  if (valuesLength !== valuesY.length) {
      throw new Error('The parameters valuesX and valuesY need to have same size!');
  }

  /*
   * Nothing to do.
   */
  if (valuesLength === 0) {
      return [ [], [] ];
  }

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (let v = 0; v < valuesLength; v++) {
      x = valuesX[v];
      y = valuesY[v];
      sumX += x;
      sumY += y;
      sumXX += x * x;
      sumXY += x * y;
      count++;
  }

  /*
   * Calculate m and b for the formular:
   * y = x * m + b
   */
  let m = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
  let b = (sumY / count) - (m * sumX) / count;

  /*
   * We will make the x and y result line now
   */
  let resultValuesX: number[] = [];
  let resultValuesY: number[] = [];

  for (let v = 0; v < valuesLength; v++) {
      x = valuesX[v];
      y = x * m + b;
      resultValuesX.push(x);
      resultValuesY.push(y);
  }

  return [resultValuesX, resultValuesY];
}
/** END from https://dracoblue.net/dev/linear-least-squares-in-javascript/ */

function mean(series: any) {
  if ( series.length === 0 ) {
    return 0;
  }
  let totalSum = series.reduce((acc, curr) => acc + curr, 0);
  return totalSum / series.length;
}

function stdev(series: any) {
  let avg = mean(series);

  series = series.map((el) => {
  return (el - avg) ** 2;
  });

  let total = series.reduce((acc, curr) => acc + curr, 0);

  return Math.sqrt(total / series.length);
}

export default {
  erf,
  statistical_significance,
  pearson_correlation,
  create_sig_diff_values,
  thresholded_two_way_statistical_significance,
  mean,
  stdev,
  find_line_by_least_squares

};
