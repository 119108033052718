import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import './warning.scss';

interface Props {
  title: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

export const Warning = (props: Props) => (
  <section className={classNames('answers-warning', {
    'answers-warning--no-description': !props.children
  })}>
    <span
      className={classNames('answers-warning__icon', {
        'answers-warning__exclamation-icon': !props.icon
      })}
    >
      {props.icon ?
        props.icon
      :
        <FontAwesomeIcon
          icon="exclamation-triangle"
        />
      }
    </span>
    <div className="answers-warning__text">
      <strong
        className="answers-warning__title"
      >
        {props.title}
      </strong>
      {props.children}
    </div>
  </section>
);
