import { calculateTickInterval } from 'charts/Utils/bargraphHelpers';
import * as d3 from 'd3';
import { BarChartField, BarChartInsight } from 'types/custom';
import Vue from 'vue';

type Args = {
  el: HTMLElement | Vue | Element | Vue[] | Element[],
  config: BarChartInsight,
  colors: {[key: string]: string},
  width: number,
  height: number
};

function getMaxLabelWidth(labels: string[]): number {

  // Create a temporary SVG element to measure text width
  const tempSvg = d3.select('body')
    .append('svg')
    .attr('width', 0)
    .attr('height', 0)
    .style('visibility', 'hidden');

  // Iterate through the labels and measure their width
  const labelWidths = labels.map(label => {
    const text = tempSvg
      .append('text')
      .text(label)
      .attr('font-family', 'Open Sans')
      .attr('font-weight', 'bold');
    const width = text.node()?.getBBox().width ?? 0;
    text.remove();
    return width;
  });

  tempSvg.remove();

  // giving a little extra space for the labels, hence "+ 5"
  return Math.max(...labelWidths) + 5;

}

function roundToTen(value: number): number {
  // we only want to round a number when it is greater than 5 else it will return 0
  if (value > 5) {
    return Math.round(value / 10) * 10;
  }
  return value;
}

export default function renderBarChart({ el, config, colors, width, height }: Args): void {

  // For expediency, erase any existing chart and build a new one.
  d3.select(el).select('svg').remove();
  const { shouldShowComparison, shouldShowSentiment } = config.chart;

  const selectedFields = config.fields.reduce((result, field: BarChartField) => {

    const selectedChildren = field.children ? field.children.filter(c => c.isSelected) : [];

    return field.isSelected
      ? [...result, field, ...selectedChildren]
      : [...result, ...selectedChildren];

  }, []);

  const allSelectedFieldValues = selectedFields.reduce((result: number[], field): number[] => {
    return shouldShowComparison && typeof field.comparison === 'number'
      ? [...result, field.baseline, field.comparison]
      : [...result, field.baseline];
  }, []);

  const leftMargin = getMaxLabelWidth(selectedFields.map(f => f.label));

  const margin = { top: 20, right: 50, bottom: 50, left: Math.max(leftMargin, 30) };
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;
  const lineColor = '#e7eefe';

  const areAnyFieldsHighlighted: boolean = config.fields.reduce((result, field: BarChartField) => {
    const isAnyChildHighlighted = field.children ? field.children.some(c => c.isHighlighted) : false;
    return result || field.isHighlighted || isAnyChildHighlighted;
  }, false);

  const { shouldShowBarValues } = config.form;
  const sentimentBars = ['neg', 'neut', 'pos'] as const;
  const sentimentColors = {
    neg: colors.red400,
    neut: colors.neutral400,
    pos: colors.green400,
    negHighlighted: colors.red200,
    neutHighlighted: colors.neutral200,
    posHighlighted: colors.green200,
  };

  const svg = d3
    .select(el)
    .append('svg')
    .attr('width', chartWidth + margin.left + margin.right)
    .attr('height', chartHeight + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // y Axis/Scale -------------------------------------------------------------

  const yScale = d3
    .scaleBand()
    .domain(selectedFields.map((field: BarChartField) => field.label))
    .range([0, chartHeight])
    .padding(0.2);

  const yAxis = d3.axisLeft(yScale).tickSize(0);

  svg
    .append('g')
    .call(yAxis)
    .selectAll('text')
    .attr('font-family', '\'Open Sans\', sans-serif')
    .attr('transform', `translate(-10, 0)`)
    .style('fill', colors.primary900)
    .style('font-size', '12px')
    .style('font-weight', (d) =>
      selectedFields.find((field: BarChartField) => field.label === d && field.isHighlighted) ? 'bold' : 'normal',
    );

  // x Axis/Scale  ------------------------------------------------------------

  const minValue = d3.min(allSelectedFieldValues) || 0;
  // If max value is negative, we want to end the x axis at 0
  const maxValue = d3.max([...allSelectedFieldValues, 0]) || 0;
  const xDomain = minValue < 0 ? [minValue, maxValue] : [0, maxValue];

  const xScale = d3.scaleLinear().domain(xDomain).range([0, chartWidth]);
  const tickInterval = roundToTen(calculateTickInterval(xScale.domain()[0], xScale.domain()[1]));

  const numberOfTicks = (xScale.domain()[1] - xScale.domain()[0]) / tickInterval;

  const xAxis = d3
    .axisBottom(xScale)
    .ticks(numberOfTicks)
    .tickFormat((t) => `${t}${config.chart.units}`)
    .tickSize(0);

  const tickValues = xScale.ticks(numberOfTicks);

  // Vertical lines behind the bars
  svg
    .selectAll('.tick-line')
    .data(tickValues)
    .enter()
    .append('line')
    .attr('class', 'tick-line')
    .attr('x1', (d) => xScale(d))
    .attr('y1', 0)
    .attr('x2', (d) => xScale(d))
    .attr('y2', chartHeight)
    .attr('stroke', lineColor)
    .attr('stroke-width', 1);

  // Tick text
  svg
    .append('g')
    .attr('transform', `translate(0, ${chartHeight})`)
    .call(xAxis)
    .selectAll('text')
    .attr('transform', `translate(0, ${5})`)
    .attr('font-family', '\'Open Sans\', sans-serif')
    .style('fill', colors.primary600)
    .style('font-size', '12px');

  // Axis label
  svg
    .append('text')
    .attr('x', chartWidth / 2)
    .attr('y', chartHeight + 35)
    .attr('text-anchor', 'middle')
    .style('fill', colors.primary400)
    .attr('font-family', '\'Open Sans\', sans-serif')
    .style('font-weight', 'bold')
    .style('font-size', '12px')
    .text(config.chart.countLabel.toUpperCase());

  // Set axes and ticks color
  svg.selectAll('.domain, .tick line').attr('stroke', lineColor);

  // Bars ---------------------------------------------------------------------
  const bars = svg.selectAll('rect').data(selectedFields).enter().append('g');

  if (shouldShowBarValues) {
    bars
      .append('text')
      .attr('x', (d: BarChartField) => xScale(Math.max(0, d.baseline)) + 6)
      .attr(
        'y',
        (d: BarChartField) =>
          (yScale(d.label) as number) + (shouldShowComparison ? yScale.bandwidth() / 4 : yScale.bandwidth() / 2),
      )
      .attr('dy', '.35em')
      .style('fill', colors.primary900)
      .text((d: BarChartField) => `${d.baseline.toFixed(1)}${config.chart.units}`)
      .attr('font-family', '\'Open Sans\', sans-serif')
      .style('font-size', '12px');

    if (shouldShowComparison) {
      bars
        .append('text')
        .attr('x', (d: BarChartField) => xScale(Math.max(0, d.comparison as number)) + 6)
        .attr('y', (d: BarChartField) => (yScale(d.label) as number) + (3 * yScale.bandwidth()) / 4 + 3)
        .attr('dy', '.35em')
        .style('fill', colors.primary900)
        .text((d: BarChartField) => `${(d.comparison as number).toFixed(1)}${config.chart.units}`)
        .style('font-size', '12px');
    }
  }

  if (!shouldShowSentiment) {
    bars
      .append('rect')
      .attr('x', (d: BarChartField) => xScale(Math.min(0, d.baseline)))
      .attr('y', (d: BarChartField) => yScale(d.label) as number)
      .attr('width', (d: BarChartField) => Math.abs(xScale(d.baseline) - xScale(0)))
      .attr('height', shouldShowComparison ? yScale.bandwidth() / 2 : yScale.bandwidth())
      .style('fill', (d: BarChartField) => (d.isHighlighted ? colors.primary500 : colors.primary300));

    if (shouldShowComparison) {
      bars
        .append('rect')
        .attr('x', (d: BarChartField) => xScale(Math.min(0, d.comparison || 0)))
        .attr('y', (d: BarChartField) => (yScale(d.label) as number) + yScale.bandwidth() / 2 + 3)
        .attr('width', (d: BarChartField) => Math.abs(xScale(d.comparison || 0) - xScale(0)))
        .attr('height', yScale.bandwidth() / 2)
        .attr('font-family', '\'Open Sans\', sans-serif')
        .style('fill', (d: BarChartField) => (d.isHighlighted ? colors.orange500 : colors.orange300));
    }
  }

  function getBaselineSentimentWidth(d: BarChartField, sentiment: typeof sentimentBars[number]): number {
    if (!d.baseline || !d.baselineSentiment) { return 0; }
    const totalSentiment = d.baselineSentiment.neg + d.baselineSentiment.neut + d.baselineSentiment.pos;
    const sentimentValue = d.baselineSentiment[sentiment];
    const proportion = totalSentiment === 0 ? 0 : sentimentValue / totalSentiment;
    return proportion * Math.abs(xScale(d.baseline) - xScale(0));
  }

  function getComparisonSentimentWidth(d: BarChartField, sentiment: typeof sentimentBars[number]): number {
    if (!d.comparison || !d.comparisonSentiment) { return 0; }
    const totalSentiment = d.comparisonSentiment.neg + d.comparisonSentiment.neut + d.comparisonSentiment.pos;
    const sentimentValue = d.comparisonSentiment[sentiment];
    const proportion = totalSentiment === 0 ? 0 : sentimentValue / totalSentiment;
    return proportion * Math.abs(xScale(d.comparison || 0) - xScale(0));
  }

  if (shouldShowSentiment) {
    sentimentBars.forEach((sentiment: typeof sentimentBars[number], index) => {
      bars
        .append('rect')
        .attr('x', (d: BarChartField) => (d.baseline || 0) >= 0 ? xScale(0) : 0)
        .attr('y', (d: BarChartField) => yScale(d.label) as number)
        .attr('width', (d: BarChartField) => getBaselineSentimentWidth(d, sentiment))
        .attr('height', shouldShowComparison ? yScale.bandwidth() / 2 : yScale.bandwidth())
        .attr('fill', (d: BarChartField) => {
          return areAnyFieldsHighlighted && !d.isHighlighted
            ? sentimentColors[`${sentiment}Highlighted`]
            : sentimentColors[sentiment];
        })
        .attr('transform', (d: BarChartField) => {

          const prevSentimentsWidth = sentimentBars
            .slice(0, index)
            .reduce((acc, curr) => acc + getBaselineSentimentWidth(d, curr), 0);

          const baselineSentimentWidth = getBaselineSentimentWidth(d, sentiment);

          // Given a negative baseline, reposition the sentiment bands so
          // negative is always closest to 0
          const x = d.baseline >= 0 ? prevSentimentsWidth : xScale(0) - baselineSentimentWidth - prevSentimentsWidth;

          return `translate(${x}, 0)`;

        });

      if (shouldShowComparison) {
        bars
          .append('rect')
          .attr('x', (d: BarChartField) => (d.comparison || 0) >= 0 ? xScale(0) : 0)
          .attr('y', (d: BarChartField) => (yScale(d.label) as number) + yScale.bandwidth() / 2 + 3)
          .attr('width', (d: BarChartField) => getComparisonSentimentWidth(d, sentiment))
          .attr('height', yScale.bandwidth() / 2)
          .attr('fill', (d: BarChartField) => {
            return areAnyFieldsHighlighted && !d.isHighlighted
              ? sentimentColors[`${sentiment}Highlighted`]
              : sentimentColors[sentiment];
          })
          .attr('transform', (d: BarChartField) => {

            const prevSentimentsWidth = sentimentBars
              .slice(0, index)
              .reduce((acc, curr) => acc + getComparisonSentimentWidth(d, curr), 0);

            const comparisonSentimentWidth = getComparisonSentimentWidth(d, sentiment);

            // Given a negative comparison, reposition the sentiment bands so
            // negative is always closest to 0
            const isPositive = (d.comparison || 0) >= 0;

            const x = isPositive ? prevSentimentsWidth : xScale(0) - comparisonSentimentWidth - prevSentimentsWidth;

            return `translate(${x}, 0)`;

          });
      }
    });
  }
}
