/*
  1. Characters are restricted to lowercase letters (a-z), or uppercase letters (A-Z)
  2. Allows multiple words, hyphens, and apostrophes e.g Yuv Raj, Mary-Jane, O'Conner
  3. Allows periods to be used e.g John Jr.
  4. Extended ASCII support for internationalization e.g Müller, Björk, François
*/
const NAME_REGEX = /^([a-zA-Z\xC0-\uFFFF]+([ \-']{0,1}[a-zA-Z\xC0-\uFFFF]+)*[.]{0,1}){1,2}$/

const isValidName = (name: string) => NAME_REGEX.test(name);

export { isValidName }
