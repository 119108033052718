import AlertTag from 'images/icons/alert-tag.svg';
import * as React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export interface ApplyConceptsProps {
  surveyName: string;
  onAccept(): void;
  onClose(): void;
}

export default class ApplyConcepts extends React.Component<ApplyConceptsProps> {

  onClose = () => {
    this.props.onClose();
  };

  doApply = () => {
    this.props.onAccept();
  };

  render() {
    const { surveyName,  onClose} = this.props;
    return (
      <Modal dimmer="blurring" open={true} onClose={onClose} size="tiny">
        <Modal.Content>
          <div className="modal-content">
            <AlertTag className="alert-image" />
            <div className="modal-body">
              <div className="modal-title">
                Do you want to apply these synonyms to “{surveyName}
                ”?
              </div>
              <div className="modal-text">
                We will email you when this is complete
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onClose} type="button">
            Cancel
          </Button>
          <Button
            type="button"
            color="blue"
            onClick={this.doApply}
          >
            Save synonyms
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
