<template>
  <div
    v-loading="loading"
    style="position: relative"
    class="bargraph nw-bargraph-tile"
  >
    <el-alert
      v-if="error"
      :title="`Something went wrong. Try reloading. ${error}`"
      type="error"
      show-icon
    />
    <div class="row row-height-sm row-no-margin">
      <div
        :class="segmentWidth"
        class="base-themes-box col-height-sm col-top"
      >
        <div
          ref="barGraph"
        />
        <section class="key-selection key-selection--base-themes">
          <button
            :class="{
              'count-selection-action': true,
              'count-selection-action--baseline': true,
              'count-selection-action--inactive': !showBaseThemeBaselineValues
            }"
            type="button"
            @click="toggleBaseThemeKeySelection('baseline')"
          >
            {{ getBaselineTitleCount() }}
          </button>
          <button
            :class="{
              'count-selection-action': true,
              'count-selection-action--comparison': true,
              'count-selection-action--inactive': !showBaseThemeComparisonValues
            }"
            type="button"
            @click="toggleBaseThemeKeySelection('comparison')"
          >
            {{ getComparisonTitleCount() }}
          </button>
        </section>
      </div>

      <div
        :class="segmentWidth"
        class="sub-themes-box col-height-sm col-top"
      >
        <div
          class="sub-themes-box-selected"
        >
          <div class="chart-title">
            <span>Subthemes of
              <span class="theme-name-indicator">{{ workingSubthemeTitle }}</span>
            </span>
            <el-dropdown
              :hide-on-click="false"
              class="float-right"
              trigger="click"
            >
              <el-button
                class="chart-action"
                size="small"
                type="plain-text"
              >
                <font-awesome-icon icon="cog" />
                <font-awesome-icon icon="chevron-down" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <div class="chart-options">
                  <div class="chart-option">
                    <div class="chart-option-label">
                      Sort by:
                    </div>
                    <ul class="chart-option-list">
                      <li class="chart-option-item">
                        <el-radio
                          v-model="orderBy"
                          label="high"
                          @change="fetchData()"
                        >
                          Highest volume
                        </el-radio>
                      </li>
                      <li class="chart-option-item">
                        <el-radio
                          v-model="orderBy"
                          label="low"
                          @change="fetchData()"
                        >
                          Lowest volume
                        </el-radio>
                      </li>

                      <li
                        :class="{ disabled: !isComparison }"
                        class="chart-option-item"
                      >
                        <el-radio
                          v-model="orderBy"
                          label="difference"
                          @change="fetchData()"
                        >
                          Comparison difference
                        </el-radio>
                        <th-tooltip> Orders the themes by the difference between blue and orange<br> </th-tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            ref="subThemeGraph"
          />
          <section class="key-selection key-selection--sub-themes">
            <button
              :class="{
                'count-selection-action': true,
                'count-selection-action--baseline': true,
                'count-selection-action--inactive': !showSubthemeBaselineValues
              }"
              type="button"
              @click="toggleSubhemeKeySelection('baseline')"
            >
              {{ getBaselineTitleCount(true) }}
            </button>
            <button
              :class="{
                'count-selection-action': true,
                'count-selection-action--comparison': true,
                'count-selection-action--inactive': !showSubthemeComparisonValues
              }"
              type="button"
              @click="toggleSubhemeKeySelection('comparison')"
            >
              {{ getComparisonTitleCount(true) }}
            </button>
          </section>
          <div
            :style="{ height: `${themeArrowOffset()}px` }"
            class="sub-themes-box-selected-indicator"
          />
        </div>
        <div v-show="!selectedTheme">
          <span>Select a theme</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MINIMUM_ARROW_OFFSET = 42;
const ARROW_OFFSET = 18;
const MIN_CHART_WIDTH = 320;
const PRIMARY_CHART_HEIGHT = 500;
const SECONDARY_CHART_HEIGHT = 480;
import _ from 'lodash';
import doFetch from 'vue/components/Tiles/Utils/bar-graph-fetcher';
import ThematicTooltip from 'vue/components/Tooltip/Tooltip.vue';
import { getInitConfigStore, getUrlParametersStore } from 'stores/RootStore';
import themeHelpers from 'vue/libs/themeHelpers';
import { getVisBarGraphOptions } from 'charts/Utils/visBargraph';
import bargraph from 'charts/Utils/bargraph';

export default {
  name: 'BarGraph',
  components: {
    'th-tooltip': ThematicTooltip,
  },
  props: {
    highlight: {
      type: Object,
      base: { type: String },
      sub: { type: String }
    },
    theme: { type: String },
    subtheme: { type: String },
    selections: {
      type: Object,
      baseline: { type: Object },
      comparison: { type: Object }
    },
    filterThemes: {
      type: Object,
      basethemes: { type: Array },
      subthemes: { type: Array }
    },
    title: { type: String },
  },
  data() {
    const themeFromUrl = this.$route.query.theme;
    return {
      urlParametersStore: getUrlParametersStore(),
      initConfigStore: getInitConfigStore(),
      baselineCount: 0,
      baselineThemes: undefined,
      error: undefined,
      latestFetch: undefined,
      loading: true,
      orderBy: 'difference',
      selectedSubtheme: this.subtheme,
      selectedTheme: this.theme || themeFromUrl,
      selectionCount: 0,
      selectionThemes: [this.theme, this.subtheme],
      themeChangeTimeout: undefined,
      workingSubthemeTitle: '',
      showBaseThemeBaselineValues: true,
      showBaseThemeComparisonValues: false,
      showSubthemeBaselineValues: true,
      showSubthemeComparisonValues: false,
      chartWidth: 400, // default width
      primaryD3Tip: undefined,
      secondaryD3Tip: undefined,
    };
  },
  computed: {
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    dataSource() {
      return this.initConfigStore.getConfigLocation;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    visID() {
      return this.initConfigStore.visId;
    },
    segmentWidth() {
      return 'col-sm-6';
    },
    isComparison() {
      return this.selections.baseline.query !== this.selections.comparison.query;
    },
    baselineSelectedCountLabel() {
      const theme = themeHelpers.getTheme(this.baselineThemes, this.selectedTheme, this.selectedSubtheme);
      const count = theme ? theme.count : 0;
      return `${count} comments`;
    },
    comparisonSelectedCountLabel() {
      const theme = themeHelpers.getTheme(this.selectionThemes, this.selectedTheme, this.selectedSubtheme);
      const count = theme ? theme.count : 0;
      return `${count} comments`;
    },
  },
  watch: {
    selections: {
      handler(selections) {
        if (selections) {
          // if filters differ then we show comparison values
          this.showBaseThemeComparisonValues = this.isComparison;
          this.showSubthemeComparisonValues = this.isComparison;
          this.fetchData();
        }
      },
      immediate: true,
    },
    theme() {
      this.onThemeChange();
    },
    subtheme() {
      this.onThemeChange();
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateChartWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChartWidth);
    this.primaryD3Tip?.destroy();
    this.secondaryD3Tip?.destroy();
  },
  methods: {
    onThemeChange() {
      clearTimeout(this.themeChangeTimeout);
      this.themeChangeTimeout = setTimeout(this.fetchData, 1);
    },
    /**
     * Calls "selectTheme" on parent components?
     */
    broadcastThemeChange() {
      const baselineTheme = themeHelpers.getTheme(this.baselineThemes, this.selectedTheme, this.selectedSubtheme);
      const comparisonTheme = themeHelpers.getTheme(this.selectionThemes, this.selectedTheme, this.selectedSubtheme);
      this.$emit('selectTheme', this.selectedTheme, this.selectedSubtheme);
      this.$emit('onSelectionChanged', this.selectedTheme, this.selectedSubtheme, baselineTheme, comparisonTheme);
    },
    updateChartWidth() {
      const { baselineThemes, selectionThemes } = this;
      if (!baselineThemes || !selectionThemes) {
        return;
      }
      const el = this.$refs.barGraph;
      if (el) {
        this.chartWidth = Math.max(MIN_CHART_WIDTH, el.getBoundingClientRect().width);
      }
      this.setPrimaryChart();
      this.setSecondaryChart(this.selectedTheme);
    },
    themeArrowOffset() {
      const { barGraph } = this.$refs;
      const { selectedTheme, showBaseThemeBaselineValues, showBaseThemeComparisonValues } = this;
      if (!selectedTheme || (!showBaseThemeBaselineValues && !showBaseThemeComparisonValues) || !barGraph) {
        return MINIMUM_ARROW_OFFSET;
      }

      const childElements = barGraph.getElementsByTagName('text');
      const targetTextElement = [...childElements].find((el) => el.textContent.includes(this.selectedTheme));

      if (targetTextElement) {
        const targetElementTop = targetTextElement.getBoundingClientRect().top;
        const parentTop = barGraph.parentElement.getBoundingClientRect().top;
        const barGraphOffset = targetElementTop - parentTop;
        return barGraphOffset + ARROW_OFFSET;
      }

      return MINIMUM_ARROW_OFFSET;
    },
    doFetch: async function () {
      const { commentColumns, selections, orderBy, requestOptions, filterThemes } = this;
      return doFetch({
        commentColumns,
        selections,
        orderBy,
        requestOptions,
        filterThemes,
      });
    },
    fetchData: async function () {
      if (!this.selections) {
        return;
      }
      this.loading = true;
      const fetch = this.doFetch();
      this.latestFetch = fetch;
      try {
        const result = await fetch;
        // if there's been another fetch in between or it failed, bail out now
        if (fetch !== this.latestFetch || !result) {
          return;
        }
        const { baselineCount, selectionCount, baselineThemes, selectionThemes } = result;
        this.baselineThemes = baselineThemes;
        this.selectionThemes = selectionThemes;
        this.baselineCount = baselineCount || 0;
        this.selectionCount = selectionCount || 0;
        this.setPrimaryChart();
        this.checkSelectedTheme();
        this.updateSubthemes(this.selectedTheme);
      } catch (e) {
        this.error = e;
      } finally {
        // only turn off loading if it's the latest fetch
        if (fetch === this.latestFetch) {
          this.loading = false;
        }
      }
    },
    getBaselineTitleCount(isSubtheme) {
      const { shortTitle: baselineShortTitle } = this.selections.baseline;
      if (isSubtheme) {
        return baselineShortTitle;
      }
      return `${baselineShortTitle} (${this.baselineCount.toLocaleString()})`;
    },
    getComparisonTitleCount(isSubtheme) {
      const { shortTitle: comparisonShortTitle } = this.selections.comparison;
      if (isSubtheme) {
        return comparisonShortTitle;
      }
      return`${comparisonShortTitle} (${this.selectionCount.toLocaleString()})`;
    },
    checkSelectedTheme() {
      const themes = this.baselineThemes;
      const labels = Object.keys(themes).sort(function(a, b) {
        return themes[a].count - themes[b].count;
      });
      const isSelectedThemePresentInLabels = labels.includes(this.selectedTheme);

      if (!this.selectedTheme || !isSelectedThemePresentInLabels) {
        // select the highest count theme initially if no theme is selected
        this.selectedTheme = _.last(labels);
      }
    },
    checkSelectedSubtheme(theme) {
      const subthemes = _.get(this, `baselineThemes.${theme}.subthemes`, {});
      const labels = Object.keys(subthemes) || [];
      const isSelectedSubthemePresentInLabels = labels.includes(this.selectedSubtheme);

      if (!isSelectedSubthemePresentInLabels) {
        this.selectedSubtheme = null;
      }
    },
    toggleBaseThemeKeySelection(key) {
      if (key === 'baseline') {
        this.showBaseThemeBaselineValues = !this.showBaseThemeBaselineValues;
      } else {
        this.showBaseThemeComparisonValues = !this.showBaseThemeComparisonValues;
      }
      this.setPrimaryChart();
    },
    toggleSubhemeKeySelection(key) {
      if (key === 'baseline') {
        this.showSubthemeBaselineValues = !this.showSubthemeBaselineValues;
      } else {
        this.showSubthemeComparisonValues = !this.showSubthemeComparisonValues;
      }
      this.updateSubthemes(this.selectedTheme);
    },
    setPrimaryChart() {
      const el = this.$refs.barGraph;
      const primaryChartOptions = getVisBarGraphOptions(
        this.baselineCount,
        this.selectionCount,
        this.baselineThemes,
        this.selectionThemes,
        this.orderBy,
        this.showBaseThemeBaselineValues,
        this.showBaseThemeComparisonValues,
        this.chartWidth,
        PRIMARY_CHART_HEIGHT,
        (theme) => {
          this.selectedTheme = theme;
          this.selectedSubtheme = null;
          this.broadcastThemeChange();
          this.updateSubthemes(this.selectedTheme);
        },
      );
      this?.primaryD3Tip?.destroy();
      this.primaryD3Tip = bargraph.getBarGraphTip(primaryChartOptions);
      bargraph.renderBarGraphData({
        el,
        options: primaryChartOptions,
        tip: this.primaryD3Tip,
      });
    },
    setSecondaryChart(theme) {
      const baselineSubthemes = _.get(this, `baselineThemes.${theme}.subthemes`, {});
      const selectionSubthemes = _.get(this, `selectionThemes.${theme}.subthemes`, {});
      this.workingSubthemeTitle = theme;
      const el = this.$refs.subThemeGraph;
      const secondaryChartOptions = getVisBarGraphOptions(
        this.baselineCount,
        this.selectionCount,
        baselineSubthemes,
        selectionSubthemes,
        this.orderBy,
        this.showSubthemeBaselineValues,
        this.showSubthemeComparisonValues,
        this.chartWidth,
        SECONDARY_CHART_HEIGHT,
        (subtheme) => {
          this.selectedSubtheme = subtheme;
          this.broadcastThemeChange();
        },
      );
      this?.secondaryD3Tip?.destroy();
      this.secondaryD3Tip = bargraph.getBarGraphTip(secondaryChartOptions);
      bargraph.renderBarGraphData({
        el,
        options: secondaryChartOptions,
        tip: this.primaryD3Tip,
      });
    },
    updateSubthemes(theme) {
      this.setSecondaryChart(theme);
      this.checkSelectedSubtheme(theme);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'styling/variables/colors.scss';
.sub-themes-box-selected-indicator {
  transition: height ease-in-out 0.2s;
}
.key-selection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-selection-action {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 12px;
    margin-right: 5px;
    background: var(--primary-500);
  }
}
.count-selection-action--baseline::before {
  background: var(--primary-500);
}
.count-selection-action--comparison::before {
  background: var(--orange-500);
}
.count-selection-action--inactive {
  text-decoration: line-through;
}
</style>
