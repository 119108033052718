import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { includes, map, without } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dimmer, List, Loader, Modal } from 'semantic-ui-react';
import { ThemeDiscoveryStoreInterface } from 'stores/ThemeDiscoveryStore';
import { ThemesStoreInterface, ThemeTreeItem } from 'stores/ThemesStore';
import './mapped-phrases.scss';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';
import safeDiv from 'lib/safe-div';

export interface MappedPhrasesSuggestionFormProps {
  orgId: string;
  surveyId: string;
  activeNode?: ThemeTreeItem;
  onAccept(proposedPhrases: string[]): void;
  onClose(): void;
}

export interface MappedPhrasesSuggestionFormState {
    isErrored: boolean;
    isLoading: boolean;
    suggestablePhrases: string[];
    selectedPhrases: string[];
}

interface InjectedProps {
    themesDiscoveryStore: ThemeDiscoveryStoreInterface;
    themeEditorSessionStore: ThemeEditorSessionStoreInterface;
    themesStore: ThemesStoreInterface;
  }

const withHocs = compose(
  inject(
    'themesStore',
    'themesDiscoveryStore',
    'themeEditorSessionStore'
  ),
  observer,
);

export default withHocs(class MappedPhrasesSuggestionForm extends React.Component<
    MappedPhrasesSuggestionFormProps,
    MappedPhrasesSuggestionFormState> {

  state = {
    isErrored: false,
    isLoading: false,
    suggestablePhrases: [] as string[],
    selectedPhrases: [] as string[]
  };

  get injected(): InjectedProps {
    return this.props as MappedPhrasesSuggestionFormProps & InjectedProps;
  }

  componentDidMount() {
      this.fetchSuggestions();
  }

  fetchSuggestions = async () => {
    const { orgId, surveyId, activeNode } = this.props;
    const { themesDiscoveryStore, themesStore } = this.injected;

    if (!activeNode) {
        return;
    }

    const params = {
        orgId: orgId,
        surveyId: surveyId,
        themeTitle: activeNode.title,
        existingMappedPhrases: activeNode.phrases
    };

    this.setState({ isErrored: false });
    this.setState({ isLoading: true });

    // filter phrases to show
    let suggestions = await themesDiscoveryStore.suggestMappedPhrases( params );
    const allPhrases = themesStore.allPhrases;
    suggestions = suggestions.filter(x => !allPhrases.includes(x));

    this.setState({ suggestablePhrases: suggestions });
    this.setState({ selectedPhrases: [] as string[]});

    this.setState({ isErrored: themesDiscoveryStore.requestSuggestionsErrored });
    this.setState({ isLoading: false });
  };

  onAccept = () => {
    const { onAccept } = this.props;
    const { themeEditorSessionStore } = this.injected;
    const { selectedPhrases } = this.state;
    onAccept( selectedPhrases );


    const numerator = this.state.selectedPhrases.length;
    const denominator = this.state.suggestablePhrases.length;

    const percent = safeDiv(numerator, denominator, 0);

    themeEditorSessionStore.addEvent({
      type : 'Discover',
      subType: 'MappedPhrases',
      timestamp: Date.now(),
      data: percent
    });

  };
  isSelected = (phrase: string) => {
    return includes(this.state.selectedPhrases, phrase);
  };
  togglePhrase = (phrase: string) => {
    const { selectedPhrases } = this.state;
    if (this.isSelected(phrase)) {
      this.setState({ selectedPhrases: without(selectedPhrases, phrase) });
    } else {
      selectedPhrases.push(phrase);
      this.setState({ selectedPhrases });
    }
  };
  allSelected = () => {
    const { suggestablePhrases, selectedPhrases } = this.state;
    return (suggestablePhrases.length === selectedPhrases.length);
  };
  selectAllToggle = () => {
    const allSelected = this.allSelected();
    if (allSelected) {
        // all selected, so deselect
        this.setState({ selectedPhrases: [] as string[] });
    } else {
        // not all selected, so select all;
        const { suggestablePhrases } = this.state;
        this.setState({ selectedPhrases: suggestablePhrases.slice() });
    }
  };

  renderMappedPhrases = () => {
    const { suggestablePhrases } = this.state;
    const phraseEls = (
        <List className="mapped-phrases mapped-phrases__recommendations">
          { map(suggestablePhrases, (phrase) => {
            const isSelected = this.isSelected(phrase);
            const cls = classnames('list-action clickable', { selected: isSelected });
            return (
                <List.Item key={phrase} className={cls} onClick={ () => { this.togglePhrase(phrase); }}>
                <List.Content>
                  <div className="ui checkbox fluid">
                    <input
                      className="hidden"
                      readOnly={true}
                      type="checkbox"
                      checked={isSelected}
                    />
                    <label>{phrase}</label>
                  </div>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      );
    return phraseEls;
  };
  renderLoading() {
    return (<Dimmer active={true} inverted={true}>
        <Loader size="small">Loading suggestions...</Loader>
    </Dimmer>);
  }
  renderErrored() {
    return (
        <div className="mapped-phrases__recommendations-none">
            <div className="mapped-phrases__recommendations-none-contents">
                <p>
                    <FontAwesomeIcon className="icon error-icon" icon="exclamation-triangle"
                    fixedWidth={true}/>
                </p>
                <p>
                    Error loading suggested phrases
                </p>
                <p>
                    <Button size="small" onClick={() => { this.fetchSuggestions(); }}>
                        <FontAwesomeIcon className="icon" icon="redo" />
                        Try Again
                    </Button>
                </p>
            </div>
        </div>
        );

  }
  renderEmpty() {
    return (
        <div className="mapped-phrases__recommendations-none">
            <div className="mapped-phrases__recommendations-none-contents">
                No suggested phrases found
            </div>
        </div>
    );
  }
  renderList() {
    const phraseEls = this.renderMappedPhrases();
    const allSelected = this.allSelected();
    return (
        <>
            <div className="mapped-phrases__header">
                <div className="ui checkbox fluid" onClick={() => { this.selectAllToggle(); }}>
                    <input
                    className="hidden"
                    readOnly={true}
                    type="checkbox"
                    checked={allSelected}
                    />
                    <label>Select All</label>
                </div>
            </div>
            {phraseEls}
        </>
    );
  }
  render() {
    const { onClose, activeNode } = this.props;
    const { suggestablePhrases, isErrored, isLoading } = this.state;

    const themeTitle = activeNode ? activeNode.title : '';
    return (
      <Modal
        dimmer="blurring"
        open={true}
        onClose={onClose}
        size="tiny"
        closeOnDimmerClick={false}
        className="mapped-phrases-modal"
      >
        <Modal.Header>
          Mapped phrase suggestions for&nbsp;
          <div className="theme-tag-pill">
              <div className="theme-tag-pill__name">
                  {themeTitle}
              </div>
          </div>
        </Modal.Header>
        <Modal.Content
          scrolling={true}
        >
          { isLoading ?
              this.renderLoading() :
              ( isErrored ? this.renderErrored() :
                  (suggestablePhrases.length > 0 ?
                      this.renderList() :
                      this.renderEmpty())
              )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={this.onAccept}
          >
           Add to theme
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
});
