import Auth from 'Auth/Auth';
import { Tag } from 'api/interfaces';
import { omit } from 'lodash';
import { action, computed, observable } from 'mobx';
import { OrganizationStoreInterface } from './OrganizationStore';

export interface TagInfo extends Tag {
  fullName?: string;
}

export interface TagStoreInterface {
  createTagError: string;
  fetchTagsError: string;
  deleteTagError: string;

  tags: Record<string, TagInfo>,
  sortedTags: TagInfo[];

  createTag: (tag: string, parentId?: string) => Promise<TagInfo | undefined>;
  getTags: () => Promise<{ [key: string]: TagInfo } | undefined>;
  deleteTag: (tagId: string) => void;
}

class TagStore implements TagStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  fetchTagsError = '';

  @observable
  createTagError = '';

  @observable
  tags: Record<string, TagInfo> = {};

  @observable
  deletingTag = false;

  @observable
  deleteTagError = '';

  constructor( currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;
  }

  @computed
  get sortedTags() {
    return Object.values(this.tags).sort((a, b) => (a.fullName || a.name).localeCompare(b.fullName || b.name));
  }

  getFullTagName(tag: Tag) {
    let { name } = tag;
    if (tag.parentId) {
      const parentTag = this.tags[tag.parentId];
      const parentName = this.getFullTagName(parentTag);
      name = `${parentName}: ${name}`;
    }

    return name;
  }

  @action
  getTags = async () => {
    this.fetchTagsError = '';
    const params = this.currentOrg.orgIdQueryParam;
    const url = `/tags?${ params }`;

    try {
      const { data, ok, errorData } = await Auth.fetch<{ tags: { [key: string]: Tag }}>(url);
      if (!ok || !data) {
        this.fetchTagsError = errorData ? errorData.message : 'Something went wrong while fetching tags, please try again';
      } else {
        this.tags = data.tags;
        Object.keys(this.tags).map(tagId => this.tags[tagId].fullName = this.getFullTagName(this.tags[tagId]));
        return this.tags;
      }
    } catch (e) {
      this.fetchTagsError = `${ e }`;
    }
    return undefined;
  }

  @action
  createTag = async (name: string, parentId?: string) => {
    this.createTagError = '';

    const params = this.currentOrg.orgIdQueryParam;
    const url = `/tag?${ params }`;
    const postData = { name, ...(parentId ? { parentId } : {}) };

    try {
      const { data, ok, errorData } = await Auth.fetch<Tag>(url, {
        method: 'POST',
        body: JSON.stringify(postData)
      });
      if (!ok || !data) {
        this.createTagError = errorData ? errorData.message : 'Something went wront while creating tag, please try again';
      } else {
        this.tags = {
          ...this.tags,
          [data.id]: {
            ...data,
            fullName: this.getFullTagName(data)
          }
        };
      }
      return data;
    } catch (e) {
      this.createTagError = `${ e }`;
    }
    return undefined;
  }

  @action
  deleteTag = async (tagId: string) => {
    this.deleteTagError = '';
    this.deletingTag = true;

    try {
      const { ok, errorData } = await Auth.fetch<Tag>(`/tag/${tagId}`, {
        method: 'DELETE'
      });

      if (ok) {
        this.tags = omit(this.tags, [tagId]);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.deleteTagError = `Failed to delete tag: ${e.message}`;
    } finally {
      this.deletingTag = false;
    }
  }
}

export default TagStore;
