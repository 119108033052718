import Welcome from 'components/Welcome/Welcome';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';

interface InitialScreenParams {
  orgId: string;
}

interface InitialScreenStoreProps {
  organizationStore?: OrganizationStoreInterface;
}

interface InitialScreenProps extends InitialScreenStoreProps, RouteComponentProps<InitialScreenParams> {}

@inject('organizationStore')
@observer
export default class InitialScreen extends React.Component<InitialScreenProps> {
  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  render() {
    const { organizationStore } = this.props;
    const { fetchingOrganization, orgId: currentOrgId } = organizationStore!;

    // Do not render until the org is set to the latest org in the URL
    // This causes race conditions with redirect happening before loading has finished
    const loading = !!fetchingOrganization || this.orgId !== currentOrgId;

    const widget = Welcome;

    return (
      <Segment>
        <Dimmer active={loading} inverted={true}>
          <Loader size="large">Loading config&hellip;</Loader>
        </Dimmer>
        {!loading && <Route path="/c/:orgId" component={widget} />}
      </Segment>
    );
  }
}
