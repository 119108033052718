<template>
  <div class="filter-date-range-picker">
    {{ rangePrefix }}
    <el-button
      class="date-picker"
      @click="openPreviousFilter"
    >
      {{ previousDateFilterName }}
    </el-button>
    {{ rangeSeparator }}
    <el-button
      class="date-picker"
      @click="openCurrentFilter"
    >
      {{ currentDateFilterName }}
    </el-button>
    <filter-date-picker
      ref="previousDateRangeFilter"
      :filter-data="previousDateFilterData"
      :selected-dates="previousPeriodDateRange"
      listen-to-route="previous_date_range"
      filter-key="baseline"
      @selectionChanged="previousDateRangeChanged"
    />
    <filter-date-picker
      ref="currentDateRangeFilter"
      :filter-data="dateFilterData"
      :selected-dates="currentPeriodDateRange"
      filter-key="baseline"
      @selectionChanged="currentDateRangeChanged"
    />
  </div>
</template>

<script>

import { get, isEqual } from 'lodash';
import { getRangeLabel } from 'lib/date';

import FilterDatePicker from 'vue/components/Filters/FilterDatePicker.vue';

export default {
  name: 'FilterDateRangePicker',
  components: {
    FilterDatePicker
  },
  props: {
    dateConfig: { type: Object },
    currentDateFilter: { type: Object },
    previousDateFilter: { type: Object },
    rangePrefix: { type: String, default: '' },
    rangeSeparator: { type: String, default: 'and' }
  },
  data() {
    return {
      currentPeriodDateRange: null,
      previousPeriodDateRange: null,
      currentDateFilterName: 'Loading',
      previousDateFilterName: 'Loading'
    };
  },
  computed: {
    dateFilterData() {
      const column = get(this, 'dateConfig.column', null);
      const range = get(this, 'dateConfig.range', null);
      const resolution = get(this, 'dateConfig.resolution', null);
      return {
        name: '',
        date_end: range[1],
        date_start: range[0],
        column,
        resolution,
        id: 'date'
      };
    },
    previousDateFilterData() {
      const column = get(this, 'dateConfig.column', null);
      const range = get(this, 'dateConfig.range', null);
      const resolution = get(this, 'dateConfig.resolution', null);
      return {
        name: '',
        date_end: range[1],
        date_start: range[0],
        column,
        resolution,
        initial_offset: -1,
        id: 'date'
      };
    }
  },
  watch: {
    currentDateFilter(newFilter, oldFilter) {
      if (!isEqual(newFilter, oldFilter)) {
        this.setCurrentDateSelection(newFilter);
      }
    },
    previousDateFilter(newFilter, oldFilter) {
      if (!isEqual(newFilter, oldFilter)) {
        this.setPreviousDateSelection(newFilter);
      }
    }
  },
  mounted() {
    this.setInitialDateSelection();
  },
  methods: {
    openCurrentFilter() {
      this.$refs.currentDateRangeFilter.open();
    },
    openPreviousFilter() {
      this.$refs.previousDateRangeFilter.open();
    },
    currentDateRangeChanged(selection, isExplicitSelection) {
      const currentDateSelection = get(selection, 'selected.0');
      const { startDate, endDate } = currentDateSelection;
      this.currentDateFilterName = getRangeLabel(startDate, endDate);
      this.$emit('onCurrentDateFilterChange', currentDateSelection, isExplicitSelection);
    },
    previousDateRangeChanged(selection, isExplicitSelection) {
      const previousDateSelection = get(selection, 'selected.0');
      const { startDate, endDate } = previousDateSelection;
      this.previousDateFilterName = getRangeLabel(startDate, endDate);
      this.$emit('onPreviousDateFilterChange', previousDateSelection, isExplicitSelection);
    },
    setInitialDateSelection() {
      this.setCurrentDateSelection(this.currentDateFilter);
      this.setPreviousDateSelection(this.previousDateFilter);
    },
    setCurrentDateSelection(currentDateFilter) {
      if (currentDateFilter && currentDateFilter.period) {
        this.currentPeriodDateRange = currentDateFilter.period.split(':');
      }
    },
    setPreviousDateSelection(previousDateFilter) {
      if (previousDateFilter && previousDateFilter.period) {
        this.previousPeriodDateRange = previousDateFilter.period.split(':');
      }
    }
  }
};
</script>

<style lang="scss">
.filter-date-range-picker {
  .filter {
    height: 0;
  }
  .el-date-editor {
    visibility: hidden;
    height: 0px !important;
    max-height: 0px;
    max-width: 100px;
    padding: 0px;
    border: 0px;
  }
  .date-picker {
    &.el-button+.el-button {
      margin-left: 0;
    }
    margin-top: 10px;
  }
}
</style>
