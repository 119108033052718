import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';

export function getCutsExcludingAll(filter: AnalysisFilter): AnalysisFilterCut[] {
  const cuts = filter.cuts ?? [];
  return cuts.filter((cut) => cut.id !== 'all_');
}

export const toggleFilterCutSelected = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  cut: AnalysisFilterCut,
  currentQueryFilter?: QueryFilter,
) => {
  const selectedCuts = currentQueryFilter?.selected || [];
  const isCurrentlySelected = selectedCuts?.some((c) => c.id === cut.id);
  const newSelectedCuts = isCurrentlySelected ? selectedCuts.filter((c) => c.id !== cut.id) : [...selectedCuts, cut];

  return {
    filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: filter.type ? filter.type : 'radio',
    selected: newSelectedCuts,
  };
};
