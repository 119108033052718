import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, Form, FormInputProps, Input } from 'semantic-ui-react';
import './data-source-trustpilot.scss';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceTrustpilotCreateFormProps {
  existingConfiguration: TrustpilotConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface TrustpilotInfo {
  id: string;
  name: string;
  identifying: number;
}

interface TrustpilotConfig {
  businessUnitIds: string[];
}

interface DataSourceTrustpilotCreateFormState {
  currentSearchTerm: string;
  currentEnumeration: {
    query: string;
  };
  searchedOrgs: TrustpilotInfo[];

  selectedOrg?: TrustpilotInfo;
  selectedOrgs: TrustpilotInfo[];
}

@observer
class DataSourceTrustpilotCreateForm extends React.Component<
DataSourceTrustpilotCreateFormProps,
DataSourceTrustpilotCreateFormState
> {
  state = {
    currentSearchTerm: '',
    currentEnumeration: {
      query: ''
    },
    searchedOrgs: [] as TrustpilotInfo[],
    selectedOrgs: [] as TrustpilotInfo[]
  } as DataSourceTrustpilotCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedOrg = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedOrg = JSON.parse(value);

    this.setState({ selectedOrg });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedOrg.id);
    }
  }

  addSelectedOrg = () => {
    const { selectedOrgs, selectedOrg } = this.state;

    if (selectedOrg) {
      selectedOrgs.push(selectedOrg);
    }

    this.setState({ selectedOrgs });
  }

  removeOrgAtIndex = (index: number) => {
    const { selectedOrgs } = this.state;

    selectedOrgs.splice(index, 1);

    this.setState({ selectedOrgs });
  }

  updateSearch = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    this.setState({ currentSearchTerm: value });
  };

  submitSearch = () => {
    const { currentSearchTerm } = this.state;

    this.setState({ currentEnumeration: { query: currentSearchTerm } });
  };

  renderForm(availableOrganizations: TrustpilotInfo[] | undefined): JSX.Element | null {
    const { selectedOrgs } = this.state;

    // create the source type options as list for dropdown
    const availableOrganizationsOptions = map(availableOrganizations, item => {
      return {
        text: item.name,
        value: JSON.stringify(item)
      };
    });

    const selectedOrgDivs = map(selectedOrgs, (selectedOrg, index) => {
      return (
        <div key={index} className="trustpilot-row">
          <span>{selectedOrg.name} ({selectedOrg.identifying})</span>
          <Button onClick={() => { this.removeOrgAtIndex(index); }}
            icon={
              <FontAwesomeIcon
                className="icon"
                icon="trash"
                fixedWidth={true}
              />
            } />
        </div>);
    });

    if (availableOrganizations) {
      return (
        <Form>
          <Form.Field>
            <label>Search for organization</label>
            <Input
              action={{
                content: 'Search',
                onClick: this.submitSearch
              }}
              placeholder="Search..."
              name="filter"
              onChange={this.updateSearch}
              autoFocus={true}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              selection={true}
              options={availableOrganizationsOptions}
              placeholder="Select..."
              onChange={this.changeSelectedOrg}
            />
            <Button onClick={this.addSelectedOrg}>Add</Button>
          </Form.Field>
          {selectedOrgDivs}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { currentEnumeration, selectedOrgs } = this.state;

    const config = {
      businessUnitIds: map(selectedOrgs, org => {
        return org.id;
      })
    } as TrustpilotConfig;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.TRUSTPILOT}
          enumerationSelection={currentEnumeration} />
      </div>
    );
  }
}

export default DataSourceTrustpilotCreateForm;
