<template>
  <div class="no-analysis">
    <div class="no-analysis-content">
      <promo-message
        :title="content.title"
        :description="content.description"
        :message="content.message"
        :icon="content.icon"
        :image="image"
      />
      <iframe
        v-if="showCalendar"
        :src="callLink"
      />
    </div>
  </div>
</template>
<script>
const ONBOARDING_MESSAGES = {
  'EXPLORE': {
    title: 'Complete a guided tour to access the Themes tool.',
    description: 'See theme volume and impact on your metrics. Compare customer segments. View theme trends over time.',
    icon: 'lock'
  },
  'OVERTIME': {
    title: 'Book a guided tour to see how the Score Change tool gives you deeper insights.',
    description: 'Find out why your score increased or decreased over time.',
    message: 'Available on our paid plans.',
    icon: 'gem'
  },
  'SIGNIFICANTCHANGES': {
    title: 'Book a guided tour to see how the Significant Changes tool gives you deeper insights.',
    description: 'Get alerted to small problems before they turn into big ones. Find out which themes have suddenly increased or decreased in volume.',
    message: 'Available on our paid plans.',
    icon: 'gem'
  }
};
const UPGRADE_MESSAGES = {
  'OVERTIME': {
    title: 'Score Change is available on our paid plans',
    description: 'Find out why your score increased or decreased over time.',
    message: 'Contact your Thematic representative or book a call now.',
    icon: 'gem'
  },
  'SIGNIFICANTCHANGES': {
    title: 'Significant Changes is available on our paid plans',
    description: 'Get alerted to small problems before they turn into big ones. Find out which themes have suddenly increased or decreased in volume.',
    message: 'Contact your Thematic representative or book a call now.',
    icon: 'gem'
  }
};
const IN_REVIEW_MESSAGES = {
  'EXPLORE': {
    title: 'This tool will be available after your themes have been reviewed.',
    description: 'Please contact your customer success representative to review the themes for your new data set.',
    icon: 'sparkles'
  },
  'OVERTIME': {
    title: 'This tool will be available after your themes have been reviewed.',
    description: 'Please contact your customer success representative to review the themes for your new data set.',
    icon: 'sparkles'
  },
  'SIGNIFICANTCHANGES': {
    title: 'This tool will be available after your themes have been reviewed.',
    description: 'Please contact your customer success representative to review the themes for your new data set.',
    icon: 'sparkles'
  }
};
const IMAGES = {
  'EXPLORE': () => import('images/vue-icons/volume-chart.svg'),
  'OVERTIME': () => import('images/vue-icons/score-change.svg'),
  'SIGNIFICANTCHANGES': () => import('images/vue-icons/significant-changes.svg')
};
import PromoMessage from '../PromoMessage/PromoMessage.vue';
import { getAnalysisToolsUIStore } from 'stores/RootStore';
import { constructMixMaxLink } from 'lib/mixmax-link';

export default {
  name: 'NoAnalysis',
  components: {
    PromoMessage
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      onboardingMessages: ONBOARDING_MESSAGES,
      upgradeMessages: UPGRADE_MESSAGES,
      inReviewMessages: IN_REVIEW_MESSAGES,
      images: IMAGES
    };
  },
  computed: {
    showCalendar() {
      return this.analysisToolsUIStore.toolNeedsOnboarding || this.analysisToolsUIStore.toolNeedsUpgrade;
    },
    content() {
      const { visType } = this.analysisToolsUIStore;
      if (!this.showCalendar) {
        return this.inReviewMessages[visType] || {};
      }
      return this.onboardingMessages[visType] || {};
    },
    image() {
      const { visType } = this.analysisToolsUIStore;
      return this.images[visType];
    },
    callLink() {
      return constructMixMaxLink('https://cal.mixmax.com/austinpresley/onboarding');
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
  .no-analysis {
    background: $--neutral-100;
    height: 80vh;
    padding: 20px;
    min-width: 940px;
  }
  .no-analysis-content {
    width: 900px;
    margin: auto;
    padding: 0;
  }
  iframe {
    width: 100%;
    height: 65vh;
    margin-top: 20px;
    box-shadow: $--box-shadow-base;
    border: 0;
  }
</style>

