<template>
  <div class="config-field">
    <label>
      Size
    </label>
    <el-select
      v-model="selectedSize"
      placeholder="Select"
      @change="onSizeChange"
    >
      <el-option
        v-for="item in sizeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { WIDGET_SIZE_OPTIONS } from '../constants';


export default {
  name: 'ConfigureWidgetSize',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
    selectedWidget: { type: String }
  },
  data() {
    return {
      selectedSize: WIDGET_SIZE_OPTIONS[0].value,
    }
  },
  computed: {
    sizeOptions() {
      return WIDGET_SIZE_OPTIONS;
    },
  },
  mounted() {
    const { size } = this.widgetConfig;
    if (size) {
      this.selectedSize = this.widgetConfig.size;
    }
  },
  methods: {
    onSizeChange() {
      this.$emit('onConfigChange', this.target, this.selectedSize, true);
    }
  }
}
</script>