export function roundMinMax(val: number) {
  const round = val < 0 ? Math.floor : Math.ceil;
  const abs = Math.abs(val);

  if (abs <= 1) {
    return round(val * 10) / 10;
  } else if (abs <= 20) {
    return 5 * round(val / 5);
  } else {
    const scale = Math.pow(10, Math.ceil(Math.log10(abs)) - 1);

    return scale * round(val / scale);
  }
}
