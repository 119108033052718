import { Reference } from "api/interfaces";
import { isSpecialTheme } from "lib/special-theme-predicates";
import { CommentTheme, PhraseSegmentTheme, Summary } from "types/custom";

export interface SummaryPage {
  summaryExcerpt: string,
  references: Reference[]
}

export function themeMatchesCommentTheme(theme: PhraseSegmentTheme, commentTheme: CommentTheme): boolean {
  if (commentTheme.subtheme) {
    return theme.base === commentTheme.theme.code && theme.sub === commentTheme.subtheme.code;
  }
  return theme.base === commentTheme.theme.code;
}

export function processSummaries(
  summaries: Summary[],
  commentTheme: CommentTheme
): SummaryPage[] {
  return summaries.flatMap(summary => {
    const { conversation, segments } = summary;

    const segmentsWithTheme = segments
    .filter(segment => segment.themes
            .filter(theme => !isSpecialTheme(theme))
            .some(theme => themeMatchesCommentTheme(theme, commentTheme)));

    return segmentsWithTheme.map(segment => {

      const summaryExcerpt = Array
        .from(summary.comment)
        .slice(segment.location[0], segment.location[1] + 1)
        .join('');

      const indexes = segment.conversationReferences.map(reference => reference.index);
      const parts = conversation.parts.filter((_, index) => indexes.includes(index));
      const references = parts.map(part => {
        return {
          author: part.author,
          excerpts: [part.text]
        };
      });

      return {
        summaryExcerpt,
        references
      };
    });
  });
}
