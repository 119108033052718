import { DataSourceIntegrationStatus } from 'api/enums';
import {
  DataSourceIntegration,
  DataSourceIntegrationRun,
  Segment,
  Survey,
  SurveyConfiguration,
  SurveySynopsis,
  SurveyUpload,
  View
} from 'api/interfaces';
import auth from 'Auth/Auth';
import { action, observable } from 'mobx';
import config from 'runtime-config';
import { OrganizationStoreInterface } from './OrganizationStore';
import { SurveyStoreInterface } from './SurveyStore';

export interface ViewPayload {
  configuration: Partial<SurveyConfiguration>;
  isPreview: boolean;
  name: string;
  order: number;
  segments?: Segment[];
}

export interface DataSourceType {
  name: string;
  requiresIntegration: boolean;
  availableIntegrations: boolean;
}

export interface SurveyOptions {
  isActive?: boolean;
  name?: string;
  configuration?: SurveyConfiguration;
  dataSourceIntegration?: object | null;
  manualUploadAllowed?: boolean;
  segments?: Segment[];
}

// currently we don't support changing the type or configuration
export interface DataSourceOptions {
  integrationId?: string;
}

export function prepareAPIData (surveyOptions: SurveyOptions) {
  // create post data block
  let request = {} as SurveyOptions;
  const {
    name,
    isActive,
    configuration,
    dataSourceIntegration,
    manualUploadAllowed,
    segments
  } = surveyOptions;

  if (isActive !== undefined) {
    request.isActive = isActive;
  }

  if (name) {
    request.name = name;
  }

  if (configuration) {
    request.configuration = configuration;
  }

  if (dataSourceIntegration) {
    request.dataSourceIntegration = dataSourceIntegration;
  }

  if (manualUploadAllowed !== undefined) {
    request.manualUploadAllowed = manualUploadAllowed;
  }

  if (segments) {
    request.segments = segments;
  }

  return request;
}

export const ManualUploadString = 'CSV/XLSX';

export interface SetupStoreInterface {
  getSurveySynopsis: (surveyId: string) => Promise<SurveySynopsis | undefined>;

  createSurvey: (surveyOptions: SurveyOptions) => Promise<object | undefined>;
  createView: (surveyId: string, viewPayload: ViewPayload) => Promise<object | undefined>;
  updateView: (surveyId: string, viewId: string, viewPayload: Partial<ViewPayload>) => Promise<object | undefined>;
  getSurveyHistory: (surveyId: string) => Promise<SurveyUpload[]>;
  runInitialJob: (surveyId: string) => Promise<Survey | undefined>;

  getAvailableDataSources: () => Promise<void>;

  setCurrentSurveyId: (surveyId: string) => void;
  resetCurrentSurveyId: () => void;

  updateDataSource: (surveyId: string, dataSourceOptions: DataSourceOptions) =>
                        Promise<DataSourceIntegration | undefined>;
  getDataSourceHistory: (surveyId: string) =>
                        Promise<DataSourceIntegrationRun[]>;
  pullSurveyDataSource: (surveyId: string) => void;
  setDataSourceStatus: (surveyId: string, status: DataSourceIntegrationStatus) => void;

  reset: () => void;

  creatingSurvey: boolean;
  createSurveyError?: string;

  createViewError?: string;
  updateViewError?: string;

  runningInitialJob: boolean;
  runInitialJobError?: string;

  pullingDataSource: boolean;
  runPullDataSourceError?: string;

  updatingDataSource: boolean;
  updateDataSourceError?: string;

  fetchingSurveySynopsis: boolean;
  fetchSurveySynopsisError?: string;

  fetchingSurveyUploads: boolean;
  fetchSurveyUploadsError?: string;

  fetchingDataSourceRuns: boolean;
  fetchDataSourceRunsError?: string;

  listDataSourcesError: string;
  listDataSourcesLoading: boolean;
  dataSourcesFetched: boolean;
  dataSourceTypes: DataSourceType[];

  currentSurveyId: string;
}

class SetupStore implements SetupStoreInterface {
  currentOrg: OrganizationStoreInterface;
  surveyStore: SurveyStoreInterface;

  @observable
  creatingSurvey = false;

  @observable
  createSurveyError = undefined;

  @observable
  createViewError = undefined;

  @observable
  updateViewError = undefined;

  @observable
  runningInitialJob = false;

  @observable
  runInitialJobError = undefined;

  @observable
  pullingDataSource = false;

  @observable
  runPullDataSourceError = undefined;

  @observable
  updatingDataSource = false;

  @observable
  updateDataSourceError = undefined;

  @observable
  fetchingSurveySynopsis = false;
  @observable
  fetchSurveySynopsisError = undefined;

  @observable
  fetchingSurveyUploads = false;
  @observable
  fetchSurveyUploadsError = undefined;

  @observable
  fetchingDataSourceRuns = false;
  @observable
  fetchDataSourceRunsError = undefined;

  @observable
  listDataSourcesError = '';
  @observable
  listDataSourcesLoading = false;
  @observable
  dataSourcesFetched = false;
  @observable
  dataSourceTypes = [] as DataSourceType[];

  @observable
  currentSurveyId = '';

  constructor(organizationStore: OrganizationStoreInterface, surveyStore: SurveyStoreInterface) {
    this.currentOrg = organizationStore;
    this.surveyStore = surveyStore;
  }

  @action
  setCurrentSurveyId = (surveyId: string) => {
    this.currentSurveyId = surveyId;
  }

  @action
  resetCurrentSurveyId = () => {
    this.currentSurveyId = '';
  }

  @action
  getSurveySynopsis = async (surveyId: string) => {
    this.fetchingSurveySynopsis = true;
    this.fetchSurveySynopsisError = undefined;

    const { apiLocation } = config;
    const url = `${ apiLocation }/survey/${ surveyId }/data_synopsis`;

    try {
      const { data, ok } = await auth.fetch<SurveySynopsis>(url);
      if (!data || !ok) {
        throw new Error(`A problem occurred while processing your data.
        We will look into it and contact you within 1 business day.`);
      }
      return data;
    } catch (e) {
      this.fetchSurveySynopsisError = e.message;
    } finally {
      this.fetchingSurveySynopsis = false;
    }

    return undefined;
  };

  @action
  getAvailableDataSources = async () => {
    /*
    Ensures the loading of available data sources happens and fills the appropriate variable
    */
    if (this.dataSourcesFetched) {
      return;
    }
    const { apiLocation } = config;
    // organization is needed as a parameter
    const params = this.currentOrg.orgIdQueryParam;
    const listUrl = [apiLocation, `/dataSources?${ params }`].join('/');

    this.listDataSourcesError = '';
    this.listDataSourcesLoading = true;
    this.dataSourceTypes = [];

    // do the actual retrieve
    try {
      const { data: dataSourceTypes, ok, errorData } = await auth.fetch<DataSourceType[]>(listUrl);
      if (dataSourceTypes && ok) {
        this.dataSourcesFetched = true;
        this.dataSourceTypes = dataSourceTypes;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.listDataSourcesError = `Failed to fetch data sources: ${ e.message }`;
    } finally {
      this.listDataSourcesLoading = false;
    }
  }

  @action
  createSurvey = async (surveyOptions: SurveyOptions) => {
    /*
    This function creates a new survey in the system for the current organization
    */
    const { apiLocation } = config;
    this.creatingSurvey = true;
    this.createSurveyError = undefined;

    const postData = prepareAPIData(surveyOptions);

    // organization is needed as a parameter
    const params = this.currentOrg.orgIdQueryParam;
    const createUrl = [apiLocation, `survey?${ params }`].join('/');

    try {
      const { data, ok, errorData } = await auth.fetch<object>(createUrl, {
        method: 'POST',
        body: JSON.stringify(postData)
      });

      if (!data || !ok) {
        const errorMessage = errorData && errorData.message ? errorData.message : 'Error creating survey';
        throw new Error(errorMessage);
      }

      this.surveyStore.addSurveyToState(data as Survey);
      return data;
    } catch (e) {
      this.createSurveyError = e.message;
    } finally {
      this.creatingSurvey = false;
    }

    return undefined;
  };

  @action
  createView = async (surveyId: string, viewPayload: ViewPayload) => {
    /*
    This function creates a new view for the specified survey
    */
    const { apiLocation } = config;
    const url = [apiLocation, `survey/${surveyId}/view`].join('/');

    try {
      const { data, ok } = await auth.fetch<object>(url, {
        method: 'POST',
        body: JSON.stringify(viewPayload)
      });

      if (!data || !ok) {
        throw new Error('Error creating view');
      }
      return data;
    } catch (e) {
      this.createViewError = e.message;
    }

    return undefined;
  };

  @action
  updateView = async (surveyId: string, viewId: string, viewPayload: Partial<ViewPayload>) => {
    this.updateViewError = undefined;
    const { apiLocation } = config;
    const url = [apiLocation, `survey/${surveyId}/view/${viewId}`].join('/');

    try {
      const { data, ok } = await auth.fetch<object>(url, {
        method: 'PUT',
        body: JSON.stringify(viewPayload)
      });

      if (!data || !ok) {
        throw new Error('Error updating view');
      }
      this.surveyStore.updateViewInState(surveyId, data as View);
      return data;
    } catch (e) {
      this.updateViewError = e.message;
    }

    return undefined;
  };

  @action
  updateDataSource = async (
    surveyId: string,
    dataSourceOptions: DataSourceOptions
  ) => {
    const { apiLocation } = config;

    this.updatingDataSource = true;
    this.updateDataSourceError = undefined;

    const createUrl = [apiLocation, `survey/${ surveyId }/dataSource`].join('/');

    try {
      const { data, ok } = await auth.fetch<DataSourceIntegration>(createUrl, {
        method: 'PUT',
        body: JSON.stringify(dataSourceOptions)
      });

      if (!data || !ok) {
        throw new Error('Error updating data source');
      }

      this.surveyStore.updateDataSourceInState(surveyId, data);
      return data;
    } catch (e) {
      this.updateDataSourceError = e.message;
    } finally {
      this.updatingDataSource = false;
    }

    return undefined;
  };

  @action
  getSurveyHistory = async (surveyId: string) =>  {
    this.fetchingSurveyUploads = true;
    this.fetchSurveyUploadsError = undefined;

    try {
      const { data, ok } = await auth.fetch<SurveyUpload[]>(`/survey/${ surveyId }/uploads`);

      if (!data || !ok) {
        throw new Error('Error occurred when retrieving survey history');
      }
      return data;
    } catch (e) {
      this.fetchSurveyUploadsError = e.message;
    } finally {
      this.fetchingSurveyUploads = false;
    }

    return [];
  }

  @action
  setDataSourceStatus = async (surveyId: string, status: DataSourceIntegrationStatus) => {

    this.updatingDataSource = true;
    this.runInitialJobError = undefined;

    let url = `/survey/${ surveyId }/dataSource/start`;
    if (status === DataSourceIntegrationStatus.STOPPED) {
      url = `/survey/${ surveyId }/dataSource/pause`;
    }
    try {
      const { data, ok } = await auth.fetch<DataSourceIntegration>(url);

      if (!data || !ok) {
        throw new Error('Error occurred when setting data source status');
      }
      this.surveyStore.updateDataSourceInState(surveyId, data);
      return data;
    } catch (e) {
      this.updateDataSourceError = e.message;
    } finally {
      this.updatingDataSource = false;
    }

    return undefined;
  }

  /*
    Run an initial job for the survey which will populate the first themes
    Comment configuration must have been completed before this step
  */
  @action
  runInitialJob = async (surveyId: string) => {
    this.runningInitialJob = true;
    this.runInitialJobError = undefined;

    try {
      const { data, ok } = await auth.fetch<Survey>(`/survey/${ surveyId }/run_initial`, {
        body: JSON.stringify({}),
        method: 'POST'
      });

      if (!data || !ok) {
        throw new Error('Error occurred when running job');
      }

      this.surveyStore.updateSurveyInState(data as Survey);
      return data;
    } catch (e) {
      this.runInitialJobError = e.message;
    } finally {
      this.runningInitialJob = false;
    }

    return undefined;
  }

  @action
  pullSurveyDataSource = async (surveyId: string) => {
    this.pullingDataSource = true;
    this.runPullDataSourceError = undefined;

    try {
      const { data, ok } = await auth.fetch<boolean>(`/survey/${ surveyId }/dataSource/pull`);

      if (!data || !ok) {
        throw new Error('Error occurred when pulling data');
      }
      return data;
    } catch (e) {
      this.runPullDataSourceError = e.message;
    } finally {
      this.pullingDataSource = false;
    }

    return undefined;
  }

  @action
  getDataSourceHistory = async (surveyId: string) =>  {
    this.fetchingDataSourceRuns = true;
    this.fetchDataSourceRunsError = undefined;

    try {
      const { data, ok } = await auth.fetch<DataSourceIntegrationRun[]>(`/survey/${ surveyId }/dataSource/runs`);

      if (!data || !ok) {
        throw new Error('Error occurred when retrieving data source history');
      }
      return data;
    } catch (e) {
      this.fetchDataSourceRunsError = e.message;
    } finally {
      this.fetchingDataSourceRuns = false;
    }

    return [];
  }

  @action
  reset = () => {
    this.createSurveyError = undefined;
    this.runInitialJobError = undefined;
    this.fetchSurveySynopsisError = undefined;
  }
}

export default SetupStore;
