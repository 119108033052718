<template>
  <div class="score-impact nw-tile__score-impact">
    <div
      v-loading="loading"
      style="position:relative"
    >
      <el-alert
        v-if="error"
        :title="`Something went wrong. Try reloading. ${error}`"
        type="error"
        show-icon
      />
      <div class="row row-height-sm row-no-margin">
        <div
          :class="segmentWidth"
          class="base-themes-box col-height-sm col-top"
        >
          <div class="impact-header">
            <div v-if="showScoreSelector">
              <span v-if="isEffect">Impact on</span>
              <span v-else>Score by</span>
              <el-select
                v-model="selectedScore"
                class="el-select--inline"
                size="mini"
                value-key="key"
                popper-class="select-popper"
              >
                <el-option
                  v-for="item in scores"
                  :key="item.key"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
            <div v-else>
              <span v-if="isEffect">Impact of themes</span>
              <span v-else>Themes by score</span>
            </div>
            <th-tooltip v-if="isEffect">
              <p>The contribution of each theme to the score</p>
              <p>
                People who did not leave a comment are excluded (
                <a
                  href="https://help.getthematic.com/article/49-my-nps-or-review-socre-is-different-in-over-time-and-impact"
                  target="blank"
                  rel="noopener noreferrer"
                >Learn more</a>)
              </p>
              <p>Click on a bar to see subthemes and comments</p>
            </th-tooltip>
            <th-tooltip v-else>
              The score of each theme
              <br>
              <br>Click on a bar to see subthemes
              and comments
            </th-tooltip>
            <el-dropdown
              :hide-on-click="false"
              trigger="click"
            >
              <el-button
                class="chart-action"
                size="small"
                type="secondary"
              >
                Options
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <div class="chart-options">
                  <div class="chart-option">
                    <div class="chart-option-label">
                      Show:
                    </div>
                    <ul class="chart-option-list">
                      <li class="chart-option-item">
                        <el-radio
                          v-model="graphType"
                          label="base"
                          @change="fetchData()"
                        >
                          Theme score
                        </el-radio>
                      </li>
                      <li class="chart-option-item">
                        <el-radio
                          v-model="graphType"
                          label="effect"
                          @change="fetchData()"
                        >
                          Impact
                        </el-radio>
                      </li>
                    </ul>
                  </div>
                  <div class="chart-option">
                    <div class="chart-option-label">
                      Sort by:
                    </div>
                    <ul class="chart-option-list">
                      <li class="chart-option-item">
                        <el-radio
                          v-model="orderBy"
                          label="high"
                          @change="fetchData()"
                        >
                          Highest value
                        </el-radio>
                      </li>
                      <li class="chart-option-item">
                        <el-radio
                          v-model="orderBy"
                          label="low"
                          @change="fetchData()"
                        >
                          Lowest value
                        </el-radio>
                      </li>
                      <li
                        :class="{ disabled:!isComparison }"
                        class="chart-option-item"
                      >
                        <el-radio
                          v-model="orderBy"
                          label="difference"
                          @change="fetchData()"
                        >
                          Comparison difference
                        </el-radio>
                        <th-tooltip>
                          Orders the themes by the difference between
                          blue and orange
                        </th-tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div ref="barGraphScoreImpact" />
          <section
            class="key-selection key-selection--base-themes"
          >
            <button
              :class="{
                'count-selection-action': true,
                'count-selection-action--baseline': true,
                'count-selection-action--inactive': !showBaseThemeBaselineValues
              }"
              type="button"
              @click="toggleBaseThemeKeySelection('baseline')"
            >
              {{ getBaselineTitleCount() }}
            </button>
            <button
              :class="{
                'count-selection-action': true,
                'count-selection-action--comparison': true,
                'count-selection-action--inactive': !showBaseThemeComparisonValues
              }"
              type="button"
              @click="toggleBaseThemeKeySelection('comparison')"
            >
              {{ getComparisonTitleCount() }}
            </button>
          </section>
        </div>
        <div
          :class="segmentWidth"
          class="sub-themes-box col-height-sm col-top"
        >
          <div
            v-if="selectedTheme[0]"
            class="sub-themes-box-selected"
          >
            <div
              class="impact-header"
            >
              Subthemes of <span class="theme-name-indicator">{{ selectedTheme[0] }}</span>
            </div>
            <div
              ref="secondaryBarGraphScoreImpact"
            />
            <section
              class="key-selection key-selection--sub-themes"
            >
              <button
                :class="{
                  'count-selection-action': true,
                  'count-selection-action--baseline': true,
                  'count-selection-action--inactive': !showSubthemeBaselineValues
                }"
                type="button"
                @click="toggleSubhemeKeySelection('baseline')"
              >
                {{ getBaselineTitleCount(true) }}
              </button>
              <button
                :class="{
                  'count-selection-action': true,
                  'count-selection-action--comparison': true,
                  'count-selection-action--inactive': !showSubthemeComparisonValues
                }"
                type="button"
                @click="toggleSubhemeKeySelection('comparison')"
              >
                {{ getComparisonTitleCount(true) }}
              </button>
            </section>
            <div
              :style="{ height: `${themeArrowOffset}px` }"
              class="sub-themes-box-selected-indicator"
            />
          </div>
          <div
            v-if="!selectedTheme[0]"
          >
            <span>Select a theme</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MINIMUM_ARROW_OFFSET = 42;
const ARROW_OFFSET = 18;
const MIN_CHART_WIDTH = 320;
const PRIMARY_CHART_HEIGHT = 500;
const SECONDARY_CHART_HEIGHT = 506;
import { last } from 'lodash';
import { getAnalysisToolsUIStore, getFilterStore, getInitConfigStore } from 'stores/RootStore';
import { setupRequestOptions } from 'vue/libs/setup-request-options';
import scoreImpactFetcher from 'vue/components/Tiles/Utils/score-impact-fetcher';
import ThematicTooltip from 'vue/components/Tooltip/Tooltip.vue';
import themeHelpers from 'vue/libs/themeHelpers';
import { watchAll } from 'vue/libs/vueExt';
import configMixin from 'vue/tools/Utils/configMixin';
import visScoreImpact from 'charts/Utils/visScoreImpact';
import bargraph from 'charts/Utils/bargraph';

export default {
  name: 'ScoreImpact',
  components: {
    'th-tooltip': ThematicTooltip
  },
  mixins: [configMixin],
  props: {
    filteredSelections: { type: Object },
    theme: { type: String },
    subtheme: { type: String },
    selections: { type: Object },

    filterThemes: {
      type: Object,
      basethemes: { type: Array },
      subthemes: { type: Array }
    }
  },
  data() {
    return {
      filterStore: getFilterStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
      error: undefined,
      loading: true,
      graphType: 'effect',
      orderBy: 'difference',
      selectedTheme: [this.theme, this.subtheme],
      themeData: undefined,
      showBaseThemeBaselineValues: true,
      showBaseThemeComparisonValues: false,
      showSubthemeBaselineValues: true,
      showSubthemeComparisonValues: false,
      chartWidth: 400, // default width
      primaryD3Tip: undefined,
      secondaryD3Tip: undefined,
    };
  },
  computed: {
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    shortTitles() {
      return this.filterStore.queryShortTitles;
    },
    titles() {
      return this.filterStore.queryTitles;
    },
    segmentWidth() {
      return 'col-sm-6';
    },
    isEffect() {
      return this.graphType === 'effect';
    },
    isComparison() {
      return (
        this.filteredSelections.baseline.query !==
        this.filteredSelections.comparison.query
      );
    },
    themeArrowOffset() {
      const { barGraphScoreImpact } = this.$refs;
      const { selectedTheme } = this;
      if (!selectedTheme[0] || !barGraphScoreImpact) {
        return MINIMUM_ARROW_OFFSET;
      }

      const childElements = barGraphScoreImpact.getElementsByTagName('text');
      const targetTextElement = [...childElements].find((el) => el.textContent.includes(selectedTheme[0]));

      if (targetTextElement) {
        const { top } = targetTextElement.getBoundingClientRect();
        const parentTop = barGraphScoreImpact.parentElement.getBoundingClientRect().top;
        const barGraphOffset = top - parentTop;
        return barGraphOffset + ARROW_OFFSET;
      }
      return MINIMUM_ARROW_OFFSET;
    },
  },
  watch: {
    filteredSelections: {
      handler(filteredSelections) {
        if (filteredSelections) {
          // if filters differ then we show comparison values
          this.showBaseThemeComparisonValues = this.isComparison;
          this.showSubthemeComparisonValues = this.isComparison;
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateChartWidth);
    watchAll(
      this,
      ['config', 'theme', 'selectedScore', 'subtheme'],
      this.fetchData
    );
    const themeFromUrl = this.$route.query.theme;

    if (!this.theme && themeFromUrl) {
      this.selectTheme(themeFromUrl, null);
    }
    if (this.filteredSelections) {
      this.fetchData();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChartWidth);
    this.primaryD3Tip?.destroy();
    this.secondaryD3Tip?.destroy();
  },
  methods: {
    fetchData: async function() {
      this.error = undefined;
      this.loading = true;
      try {
        const options = setupRequestOptions(this.requestOptions, {
          scoreCommentsOnly: true,
          scoreColumn: this.scoreColumn,
          scoreType: this.scoreType,
          scoreOptions: this.scoreOptions
        });
        this.themeData = await scoreImpactFetcher.doFetch({
          commentColumns: this.commentColumns,
          requestOptions: options,
          selections: this.filteredSelections,
          currentTitles: this.shortTitles,
          filterThemes: this.filterThemes,
        });
        this.setPrimaryChart();
        this.checkSelectedTheme();
        this.updateSubthemes(this.selectedTheme[0]);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    checkSelectedTheme() {
      const { baseline } = this.themeData;
      const themes = baseline.themes || {};
      const labels = Object.keys(themes).sort(function(a, b) {
        return themes[a].count - themes[b].count;
      });
      const isSelectedThemePresentInLabels = labels.includes(this.selectedTheme[0]);

      if (!this.selectedTheme[0] || !isSelectedThemePresentInLabels) {
        // select the highest count theme initially if no theme is selected
        this.selectedTheme = [last(labels), this.selectedTheme[1]];
      }
    },
    updateSubthemes(theme) {
      this.checkSelectedSubtheme(theme);
      this.setSecondaryChart(theme);
    },
    checkSelectedSubtheme(theme) {
      const { baseline } = this.themeData;
      const subthemes = baseline.themes[theme].subthemes || {};
      const labels = Object.keys(subthemes).sort(function(a, b) {
        return subthemes[a].count - subthemes[b].count;
      });
      const isSelectedSubthemePresentInLabels = labels.includes(this.selectedTheme[1]);

      if (!isSelectedSubthemePresentInLabels) {
        // select the highest count subtheme initially if no subtheme is selected
        this.selectedTheme = [this.selectedTheme[0], last(labels)];
      }
    },
    getScoreFormatter(scoreUnits) {
      if (!scoreUnits) {
        return `<%= value %>`;
      }
      if (scoreUnits === 'dollars') {
        return '$<%= value %>';
      }
      return `<%= value %> ${ scoreUnits }`;
    },
    setPrimaryChart() {
      const requestOptions = setupRequestOptions(this.requestOptions, {
        scoreCommentsOnly: true,
        scoreColumn: this.scoreColumn,
        scoreType: this.scoreType,
        scoreOptions: this.scoreOptions
      });
      const range = requestOptions.scoreOptions.range;
      const scoreUnits = requestOptions.scoreOptions.units;
      const scoreName = requestOptions.scoreOptions.name;
      const scoreFormatter = this.getScoreFormatter(scoreUnits);
      const el = this.$refs.barGraphScoreImpact;
      const {
        baselineCount,
        comparisonCount,
        baseline,
        comparison,
      } = this.themeData;

      const primaryChartOptions = visScoreImpact.getScoreImpactOptions({
        baselineCount,
        comparisonCount,
        baseline: baseline.themes,
        comparison: comparison.themes,
        scoreType: requestOptions.scoreType,
        scoreFormatter,
        scoreName,
        graphType: this.graphType,
        orderBy: this.orderBy,
        range,
        showBaselineValues: this.showBaseThemeBaselineValues,
        showComparisonValues: this.showBaseThemeComparisonValues,
        barChartWidth: this.chartWidth,
        barChartHeight: PRIMARY_CHART_HEIGHT,
        onClick: (theme) => {
          this.selectedTheme = [theme, null];
          this.selectTheme(theme, this.selectedTheme[1]);
          this.updateSubthemes(theme);
        }
      });

      this.primaryD3Tip?.destroy();
      this.primaryD3Tip = bargraph.getBarGraphTip(primaryChartOptions);
      bargraph.renderBarGraphData({
        el,
        options: primaryChartOptions,
        tip: this.primaryD3Tip,
      });
    },
    setSecondaryChart(theme) {
      const el = this.$refs.secondaryBarGraphScoreImpact;
      const requestOptions = setupRequestOptions(this.requestOptions, {
        scoreCommentsOnly: true,
        scoreColumn: this.scoreColumn,
        scoreType: this.scoreType,
        scoreOptions: this.scoreOptions
      });
      const range = requestOptions.scoreOptions.range;
      const scoreUnits = requestOptions.scoreOptions.units;
      const scoreName = requestOptions.scoreOptions.name;
      const scoreFormatter = this.getScoreFormatter(scoreUnits);
      const {
        baselineCount,
        comparisonCount,
        baseline,
        comparison,
      } = this.themeData;
      const baselineThemes = baseline.themes[theme]
        ? baseline.themes[theme]
        : { subthemes: {} };
      const comparisonThemes = comparison.themes[theme]
        ? comparison.themes[theme]
        : { subthemes: {} };

      const secondaryChartOptions = visScoreImpact.getScoreImpactOptions({
        baselineCount,
        comparisonCount,
        baseline: baselineThemes['subthemes'],
        comparison: comparisonThemes['subthemes'],
        scoreType: requestOptions.scoreType,
        scoreFormatter,
        scoreName,
        graphType: this.graphType,
        orderBy: this.orderBy,
        range,
        showBaselineValues: this.showSubthemeBaselineValues,
        showComparisonValues: this.showSubthemeComparisonValues,
        barChartWidth: this.chartWidth,
        barChartHeight: SECONDARY_CHART_HEIGHT,
        onClick: (subthemeName) => {
          this.selectTheme(this.selectedTheme[0], subthemeName);
        }
      });

      this.secondaryD3Tip?.destroy();
      this.secondaryD3Tip = bargraph.getBarGraphTip(secondaryChartOptions);
      bargraph.renderBarGraphData({
        el,
        options: secondaryChartOptions,
        tip: this.secondaryD3Tip,
      });
    },
    updateChartWidth() {
      const { themeData } = this;
      if (!themeData) {
        return;
      }
      const el = this.$refs.barGraphScoreImpact;
      if (el) {
        this.chartWidth = Math.max(MIN_CHART_WIDTH, el.getBoundingClientRect().width);
      }
      this.setPrimaryChart();
      this.setSecondaryChart(this.selectedTheme[0]);
    },
    toggleBaseThemeKeySelection(key) {
      if (key === 'baseline') {
        this.showBaseThemeBaselineValues = !this.showBaseThemeBaselineValues;
      } else {
        this.showBaseThemeComparisonValues = !this.showBaseThemeComparisonValues;
      }
      this.fetchData();
    },
    toggleSubhemeKeySelection(key) {
      if (key === 'baseline') {
        this.showSubthemeBaselineValues = !this.showSubthemeBaselineValues;
      } else {
        this.showSubthemeComparisonValues = !this.showSubthemeComparisonValues;
      }
      this.fetchData();
    },
    getBaselineTitleCount(isSubtheme) {
      const { themeData } = this;
      if (!themeData) {
        return 'Baseline';
      }
      if (isSubtheme) {
        return themeData.baselineSubthmeTitle;
      }
      return themeData.baselineThemeTitle;

    },
    getComparisonTitleCount(isSubtheme) {
      const { themeData } = this;
      if (!themeData) {
        return 'Comparison';
      }
      if (isSubtheme) {
        return themeData.comparisonSubthemeTitle;
      }
      return themeData.comparisonThemeTitle;
    },
    selectTheme: function(theme, subtheme) {
      this.selectedTheme = [theme, subtheme];
      this.$emit('selectTheme', theme, subtheme);
      const baselineThemes = this.themeData.baseline.themes;
      const comparisonThemes = this.themeData.comparison.themes;
      const baselineTheme = themeHelpers.getTheme(
        baselineThemes,
        theme,
        subtheme
      );
      const comparisonTheme = themeHelpers.getTheme(
        comparisonThemes,
        theme,
        subtheme
      );
      this.$emit(
        'onSelectionChanged',
        theme,
        subtheme,
        baselineTheme,
        comparisonTheme
      );
    }
  },
};
</script>

<!-- CSS -->
<style lang="scss" scoped>
@import '../styles/select-popper';
@import '../styles/element-variables.scss';
.sub-themes-box-selected-indicator {
  transition: height ease-in-out 0.2s;
}
.impact-header {
  align-items: center;
  display: grid;
  font-weight: 600;
  line-height: 24px;
  grid-gap: 5px;
  grid: auto / auto auto 1fr;
  margin-bottom: 10px;
  .el-dropdown {
    justify-self: flex-end;
    padding: 1px 0;
  }
}
.chart-options {
  max-width: 320px;
  .chart-option-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'chart-option-label chart-option-ul';
    margin-bottom: 15px;
    .chart-option-label {
      text-align: right;
      width: 100%;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .chart-option-ul {
      padding-left: 0px;
      .chart-option-li {
        margin-bottom: 10px;
      }
      .disabled {
        .el-radio {
          pointer-events: none;
          color: $--neutral-400 !important;
        }
        .el-radio__inner {
          background-color: $--neutral-300 !important;
        }
      }
      :last-child {
        margin-bottom: 0;
      }
    }
  }
}
.key-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.count-selection-action {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 12px;
    margin-right: 5px;
    background: var(--primary-500);
  }
}
.count-selection-action--baseline::before {
  background: var(--primary-500);
}
.count-selection-action--comparison::before {
  background: var(--orange-500);
}
.count-selection-action--inactive {
  text-decoration: line-through;
}
</style>
