import * as React from 'react';
import { camelCase } from 'lodash';
import { mapKeys } from 'lodash';


const withCamelCaseProps = WrappedComponent => {
  const Wrapper = props => {
    const transformedProps = mapKeys(props, (_, key) => camelCase(key));

    return <WrappedComponent {...transformedProps} />;
  };

  return Wrapper;
};

export { withCamelCaseProps };
