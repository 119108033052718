
import { action, observable } from 'mobx';
import config from 'runtime-config';

interface LoginScreenButton {
  text: string;
  url: string;
}

export interface LoginScreenUIStoreInterface {
  initialize: () => void;

  header: string;
  bulletPoints: string[];
  button?: LoginScreenButton;
}

class LoginScreenUIStore implements LoginScreenUIStoreInterface {

  @observable
  header = 'Come and hang out with the Insights to Action community';

  @observable
  bulletPoints: string[] = [
    'Share ideas and get tips',
    'Ask questions about using Thematic',
    'Get exclusive invites to AMAs and events'
  ];

  @observable
  button = {
    text: 'Join now',
    url: 'https://join.slack.com/t/insights-to-action/shared_invite/zt-que0xm5e-oZXRe6oTfwrfLKu3OiCSJw'
  } as LoginScreenButton | undefined;

  @action
  initialize = async () => {
    const loginUrl = config.loginConfigLocation;
    try {
      const result = await fetch(loginUrl);
      const { ok } = result;
      if (ok) {
        // replace flagJson
        const data = await result.json();
        // check some types
        if (
          typeof data.headline !== 'string' ||
          !Array.isArray(data.items) ||
          (data.button && typeof data.button.text !== 'string' && typeof data.button.url !== 'string')
        ) {
          return;
        }
        // a valid response
        this.header = data.headline;
        this.bulletPoints = data.items;
        this.button = data.button;
      }
    } catch (e) {
      // do nothing
    }
  }

}

export default LoginScreenUIStore;
