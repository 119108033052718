import { BillingDatasetUsage } from 'api/interfaces';

// only exported for testing purposes
export const formatCSVData = (datasets: BillingDatasetUsage[]) => {

  // first create the header rows based on the first dataset
  const headerRow = [''].concat(datasets[0].byMonth.map(metric => metric.name));
  let contentRows = [] as string[][];

  contentRows.push(['Verbatim Usage']);
  contentRows.push(headerRow);

  datasets.forEach(dataset => {
    let row = [dataset.survey.name];
    dataset.byMonth.forEach(metric => {
      row.push('' + metric.verbatims);
    });
    contentRows.push(row);
  });

  contentRows.push(['']);
  contentRows.push(['Translations Usage']);
  contentRows.push(headerRow);

  datasets.forEach(dataset => {
    let row = [dataset.survey.name];
    dataset.byMonth.forEach(metric => {
      row.push('' + metric.translations);
    });
    contentRows.push(row);
  });

  return contentRows.map(e => e.join(',')).join('\n');
};

export const downloadCSV = async (billingAccountName: string,
                                  startMonth: string,
                                  endMonth: string,
                                  datasets: BillingDatasetUsage[]) => {
  const content = formatCSVData(datasets);
  const type = 'text/csv;charset=UTF-8';

  const blob = new Blob([content], { type });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.style.display = 'none';
  link.innerHTML = 'Download File';
  link.href = url;
  link.download = `Data_Usage_for_${ billingAccountName }_${ startMonth }-${ endMonth }.csv`;
  document.body.appendChild(link); // firefox requires it to be on body
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });

  link.click();
};