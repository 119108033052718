<template>
  <widget-wrapper
    :aria-label="subtitle"
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    class="widget-width-2"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        <span class="key-text">
          {{ subtitle }}
          <font-awesome-icon
            v-if="canNavigate"
            class="link-icon"
            icon="chart-line"
          />
        </span>
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div class="legend">
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ baselineName }}</span>
      </div>
      <div>
        <theme-waterfall
          :data="waterfallData"
          :num-themes-to-show="7"
          @selectTheme="selectTheme"
        />
      </div>
      <div
        v-if="empty"
        class="empty-change-list"
      >
        No changes found
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { get, map } from 'lodash';

import toFixed from 'vue/libs/to-fixed';
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';
import ThemeWaterfall from 'vue/components/Tiles/ThemeWaterfall.vue';

import {
  canNavigate,
  goToAnalysis,
  getDestination
} from 'components/Dashboard/Utils/navigation-helpers';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'SCORE_CHANGES',
  components: { ThemeWaterfall, WidgetWrapper },
  props: {
    orgId: { type: String },
    isWeighted: { type: Boolean, default: false },
    config: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    canNavigate() {
      return canNavigate({ source: this.source, isWeighted: this.isWeighted });
    },
    waterfallData() {
      return {
        contributions: this.contributions,
        noTextContribution: this.noTextContribution,
        previous: {
          score: this.previousScore,
          label: this.previousLabel,
          total: this.previousTotal,
        },
        current: {
          score: this.currentScore,
          label: this.currentLabel,
          total: this.currentTotal,
        },
        themes: this.themes,
      };
    },
    baselineName() {
      return get(this, 'data.name', 'All');
    },
    currentTotal() {
      const counts = get(this, 'data.counts');
      if (counts) {
        return nonemptyCountFromCountsObject(counts);
      }
      return 0;
    },
    empty() {
      return !this.themes || this.themes.length === 0;
    },
    currentScore() {
      return get(this, 'data.scoreAllRows.score');
    },
    currentLabel() {
      return get(this, 'data.period');
    },
    previousTotal() {
      const counts = get(this, 'data.previousCounts');
      if (counts) {
        return nonemptyCountFromCountsObject(counts);
      }
      return 0;
    },
    previousScore() {
      return get(this, 'data.previousScoreAllRows.score');
    },
    previousLabel() {
      return get(this, 'data.previousPeriod');
    },
    themes() {
      return get(this, 'data.themes');
    },
    noTextContribution() {
      const value = get(this, 'data.unthemedWeighting', 0.0);
      return value;
    },
    contributions() {
      const { themes } = this;

      const contributions = map(themes, (theme) => {
        return {
          ...theme,
          title: theme.name,
          label: toFixed(theme.weighting, 2),
          value: theme.weighting,
        };
      });
      return contributions;
    },
    currentPeriodName() {
      return get(this, 'data.currentPeriodName');
    },
    previousPeriodName() {
      return get(this, 'data.previousPeriodName');
    },
    scoreName() {
      const compareFilterName = get(this, 'context.compareFilterName', '');
      const scoreConfigName = get(this.config, 'score.name', get(this, 'scoreConfig.name'));
      return compareFilterName ? compareFilterName : scoreConfigName;
    },
    subtitle() {
      const title = `What has caused ${this.scoreName} to change between ${this.previousPeriodName} and ${this.currentPeriodName}`;
      return title;
    },
  },
  methods: {
    doNavigate() {
      const { source, isWeighted } = this;

      if (!canNavigate({ source, isWeighted })) {
        return;
      }

      const destination = getDestination({
        orgId: this.orgId,
        source,
        tool: 'over-time'
      });
      goToAnalysis(destination);
    },
    selectTheme(theme, subtheme) {
      this.doNavigate(theme, subtheme, { previous_date_range: get(this, 'context.previousPeriod') });
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
