import { AnswerMetadata, AnswersFilters } from 'api/interfaces';
import { stringify } from 'query-string';

export function getAnalysisToolsLinkForMetadataSource(
  metadata: AnswerMetadata,
  filters: AnswersFilters,
  orgId: string
): string {
  if (!metadata.from) {
    return '';
  }

  // TODO: We need to pass through filters too. And have them actually work!!
  const encodedFilters = Object.entries(filters).reduce((result, [key, value]) => {
    result[key] = value.join(',');
    return result;
  }, {});

  const params = stringify({ filters: encodeURIComponent(stringify(encodedFilters)) });
  const { surveyId, visId, viewId } = metadata.from;
  if (viewId) {
    return `/c/${ orgId }/s/${ surveyId }/view/${ viewId }/v/${ visId }/feedback?${ params }`;
  } else {
    return `/c/${ orgId }/s/${ surveyId }/v/${ visId }/feedback?${ params }`;
  }
}
