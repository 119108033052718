import * as React from 'react';
import "./theme-item.scss";
import { CommentThemeItem } from 'types/custom';
import classNames from 'classnames';
import { Sentiment } from 'components/SimilarSentences/Sentiment';
import toSentimentClassname from 'vue/libs/to-sentiment-classname';

type Props = {
  item: CommentThemeItem,
  showSentiment: boolean,
  disabled: boolean,
};

const ThemeItem = ({ item, showSentiment, disabled = false }: Props) => {
  const sentimentClassName = toSentimentClassname(item.sentiment, showSentiment);

  const className = classNames(`react-theme-item ${sentimentClassName}`, {
    'react-theme-item--has-background-colour': item.hasBackgroundColour,
    'react-theme-item--is-highlighted': item.isHighlighted,
    'react-theme-item--disabled': disabled,
  });

  return (
    <span className={className}>
      {showSentiment && (
        <span className="react-theme-item__sentiment">
          <Sentiment sentiment={item.sentiment} />
        </span>
      )}
      <span>{item.theme.title}</span>
    </span>
  );
};

export { ThemeItem };
