export function toRoute(
  orgId: string,
  surveyID: string,
  viewID: string | undefined,
  visID: string
) {
  if (viewID) {
    return `/c/${orgId}/s/${surveyID}/view/${viewID}/v/${visID}`;
  } else {
    return `/c/${orgId}/s/${surveyID}/v/${visID}`;
  }
}

export function getWorkflowsRoute(orgId: string) {
  return `/c/${orgId}/workflows`;
}

export function getUsersRoute(orgId: string) {
  return `/c/${orgId}/users`;
}

export function getRolesRoute(orgId: string) {
  return `/c/${orgId}/roles`;
}