import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

export function initTracing(honeycombID: string, honeycombDataset: string) {
  // The exporter is responsible for sending traces from the browser to your collector
  const exporter = new OTLPTraceExporter({
    url: 'https://api.honeycomb.io:443/v1/traces',
    headers: {
      'x-honeycomb-team': honeycombID,
      'x-honeycomb-dataset': honeycombDataset,
    },
  });
  // The TracerProvider is the core library for creating traces
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'user_portal',
    }),
  });
  // The processor sorts through data as it comes in, before it is sent to the exporter
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
  // A context manager allows OTel to keep the context of function calls across async functions
  // ensuring you don't have disconnected traces
  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      new FetchInstrumentation({
        ignoreUrls: [
          /^(?!.*getthematic\.com\/api.*).*/i, // regex negative match thematic api (exclude all other api requests)
          /.*\/themes\/status.*/i, // exclude thematic urls with substring
        ],
        // ties the FE and BE traces
        propagateTraceHeaderCorsUrls: [/.*.getthematic\.com\/api\/.*/i],
        clearTimingResources: true,
      }),
    ],
  });
}
