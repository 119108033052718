import Auth from 'Auth/Auth';

type Record = {
  column: number | string,
  part?: number,
  location: [number, number],
  sentiment: number
};

export default async function updateSentiment(surveyId: string, recordId: string, record: Record): Promise<void> {

  const url = `/survey/${ surveyId }/records/${ recordId }/sentiment`;

  await Auth.fetch(url, {
    method: 'PUT',
    body: JSON.stringify(record)
  });

}
