import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import './confirm-analysis-modal.scss';

interface ConfirmAnalysisModalProps {
  commentColumns: string[];
  onCancel: () => void;
  onProceed: () => void;
}

class ConfirmAnalysisModal extends React.Component<ConfirmAnalysisModalProps> {

  render() {
    const { commentColumns, onCancel, onProceed } = this.props;

    return (
      <Modal
        role="dialog"
        className="confirm-analysis-modal"
        open={true}
        size="small"
        dimmer="blurring"
        onClose={onCancel}
      >
        <Modal.Header as={Header}>Do you want to start analysis of the following comments?</Modal.Header>
        <Modal.Content scrolling={true}>
          {commentColumns.map((c, i) => {
            return <div className="comment" key={i}>
              <FontAwesomeIcon icon="comment" />
              <p>{c}</p>
            </div>;
          })}
          <p>
            Please make sure you've set the type of all comment, score and date columns correctly.
            You cannot change this later.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onProceed} color="blue">
            Start analysis
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ConfirmAnalysisModal;
