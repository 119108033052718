import { WorkflowConfiguration } from 'api/workflow-interfaces';
import CommentMatchingImage from 'images/icons/comment-matching.svg';
import SpikeDetectionImage from 'images/icons/spike-detection.svg';
import ThemeDiscoveryImage from 'images/icons/theme-discovery.svg';
import { FlagKeys } from 'lib/feature-flag';
import * as React from 'react';
import RouteTemplate from './RouteTemplate';
import SpikeTemplate from './SpikeTemplate';
import ThemeDiscoveryTemplate from './ThemeDiscoveryTemplate';

export interface AvailableTemplate {
  name: string;
  description: string;
  component: any; // React.Component
  defaults: object;
  img: any;
  requiresFlag?: FlagKeys;
}

const AvailableTemplates = [
  {
    name: 'Spike Detection',
    description: 'Get alerted daily, weekly, or monthly about themes that have changed from their historical average.',
    img: <SpikeDetectionImage />,
    component: SpikeTemplate,
    defaults: { dimension: 'spike' }
  },
  {
    name: 'Comment Matching',
    description: 'Get alerted about all new comments that match selected filters.',
    img: <CommentMatchingImage />,
    component: RouteTemplate,
    defaults: { dimesion: 'route' }
  },
  {
    name: 'Theme Discovery',
    description: 'Get alerted about potential new themes in new data.',
    img: <ThemeDiscoveryImage />,
    component: ThemeDiscoveryTemplate,
    defaults: { dimension: 'themeDiscovery' },
    requiresFlag: FlagKeys.CAN_SEE_THEME_DISCOVERY_WORKFLOW
  }
] as AvailableTemplate[];

export function getTemplateFromConfiguration(configuration: WorkflowConfiguration) {
  const spike = SpikeTemplate.configurationMatches(configuration);
  const route = RouteTemplate.configurationMatches(configuration);
  const themeDiscovery = ThemeDiscoveryTemplate.configurationMatches(configuration);
  if (spike) {
    const defaults = spike;
    return { template: SpikeTemplate, defaults };
  }
  if (route) {
    const defaults = route;
    return { template: RouteTemplate, defaults };
  }
  if (themeDiscovery) {
    const defaults = themeDiscovery;
    return { template: ThemeDiscoveryTemplate, defaults };
  }
  return { template: undefined, defaults: {}};
}

export default AvailableTemplates;