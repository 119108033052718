import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceAircallCreateFormProps {
  onChange(configuration: object, integrationId: string): void;
}

interface DataSourceAircallCreateFormState {
}

@observer
class DataSourceAircallCreateForm extends React.Component<
  DataSourceAircallCreateFormProps,
  DataSourceAircallCreateFormState
> {

  renderForm(): JSX.Element | null {
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          integrationType={IntegrationType.AIRCALL} />
      </div>
    );
  }
}

export default DataSourceAircallCreateForm;