import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertTag from 'images/icons/alert-tag.svg';
import * as React from 'react';
import { Button, Message, Modal } from 'semantic-ui-react';

export interface ApplyParametersFormProps {
  error: string;
  includesDestructiveParams: boolean;
  surveyName: string;
  working: boolean;
  onAccept(): void;
  onClose(): void;
}

interface ApplyParametersFormState {
  working: boolean;
}

export default class ApplyParametersForm extends React.Component<ApplyParametersFormProps, ApplyParametersFormState> {
  state = {
    working: false
  };
  handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      this.doApply();
    }
  };
  onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { onClose } = this.props;
    this.setState({ working: false });
    onClose();
  };
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }
  doApply = () => {
    this.props.onAccept();
  };

  render() {
    const { error, onClose, surveyName, working, includesDestructiveParams } = this.props;
    return (
      <Modal dimmer="blurring" open={true} onClose={onClose} size="tiny">
        <Modal.Content>
          <div className="modal-content">
            <AlertTag className="alert-image" />
            <div className="modal-body">
              <div className="modal-title">
                Do you want to apply these parameters to “{surveyName}
                ”?
              </div>
              {includesDestructiveParams ? (
                <div className="alert-bar-yellow">
                  WARNING! This is a destructive operation. Existing themes will be replaced.
                </div>
              ) : (
                <div className="modal-text">
                  We will email you when this is complete
                </div>
              )}
            </div>
          </div>
          {error && (
            <Message negative={true} size="mini" icon={true}>
              <FontAwesomeIcon
                className="icon"
                icon="exclamation-triangle"
                fixedWidth={true}
              />
              <Message.Content>
                <Message.Header>Uh oh! Something went wrong.</Message.Header>
                <p>
                  We couldn’t apply your parameters. Please refresh the page and try
                  again.
                </p>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onClose}>
            Cancel
          </Button>
          <Button
            disabled={working}
            loading={working}
            color="blue"
            onClick={this.doApply}
          >
            Apply
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
