import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceYotpoCreateFormProps {
    onChange(configuration: object, integrationId: string): void;
}


interface DataSourceYotpoCreateFormState {
}

@observer
class DataSourceYotpoCreateForm extends React.Component<
    DataSourceYotpoCreateFormProps,
    DataSourceYotpoCreateFormState
> {

  renderForm(): JSX.Element | null {
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
        <div>
            <DataSourceWithIntegrationForm
              renderFormComponent={this}
              onChange={onChange}
              integrationType={IntegrationType.YOTPO} />
        </div>
    );
  }
}

export default DataSourceYotpoCreateForm;