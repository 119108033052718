<template>
  <div
    :class="{ shaded: shaded }"
    class="percentage-bar"
  >
    <el-tooltip
      :open-delay="300"
      placement="top"
    >
      <div slot="content">
        {{ valueLabel }}
      </div>
      <div
        :class="{ negative: isNegative, 'right-bar': isNegative, 'left-bar': !isNegative }"
        class="percentage-bar-container"
      >
        <svg
          class="percentage-bar-indicator"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            :x="x"
            :width="width + '%'"
            y="0"
            rx="0"
            ry="0"
            height="10"
            fill="currentColor"
          />
        </svg>
      </div>
    </el-tooltip>
    <div class="bar-label">
      {{ countLabel }}
    </div>
  </div>
</template>

<script>
import toFixed from 'vue/libs/to-fixed';
export default {
  name: 'BarNormalized',
  props: {
    count: { default: 0, type: Number },
    countSuffix: { default: '', type: String },
    range: { default: undefined, type: Array },
    precision: { default: 1, type: Number },
    shaded: { default: false, type: Boolean },
    suffix: { default: '%', type: String },
    value: { default: 0, type: Number },
    width: { default: 0, type: Number }
  },
  computed: {
    countLabel() {
      const { count, countSuffix } = this;
      return toFixed(count, 1, countSuffix);
    },
    valueLabel() {
      const { proportion, precision, suffix } = this;
      if (precision === 0) {
        return toFixed(Math.round(proportion), precision, suffix);
      }

      return toFixed(proportion, precision, suffix);
    },
    percent() {
      const { displayPercentage, precision } = this;
      if (!displayPercentage) {
        return '';
      }
      if (precision == 0) {
        return toFixed(Math.round(displayPercentage), 0) + '%';
      }
      return toFixed(displayPercentage, precision) + '%';
    },
    isNegative() {
      return this.value < 0;
    },
    proportion() {
      const { range, value } = this;
      if (range) {
        const [low, high] = this.range;
        return (value / (high - low)) * 100.0;
      } else {
        return value;
      }
    },
    x() {
      if (this.value > 0) {
        return 0;
      } else {
        return 100 - this.width + '%';
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.percentage-bar {
  align-items: center;
  display: grid;
  grid:
    'bar-inner bar-percent' 10px
    / 1fr minmax(40px, auto);
  grid-gap: 5px;
  &.shaded {
    .percentage-bar-container {
      background: $--neutral-100;
    }
  }
  .percentage-bar-container {
    position: relative;
    span {
      color: $--color-white;
      font-size: 12px;
      position: absolute;
      left: 5px;
      text-shadow: 1px 1px 1px $--color-black;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.percentage-bar-indicator {
  height: 10px;
  display: block;
  width: 100%;
  grid-area: bar-inner;
}
.left-bar {
  border-left: 1px solid rgb(146, 146, 146);
}
.right-bar {
  border-right: 1px solid rgb(146, 146, 146);
}
.bar-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  grid-area: bar-percent;
  text-align: right;
}
</style>

