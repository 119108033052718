import * as React from 'react';
import { AnswerComparison } from 'api/interfaces';

const ComparisonIcon = ({ index }: { index: number }) => {
  return (
    <span className="answers__examples-comparison-icon">
      <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="8" height="8" rx="2" fill={index === 0 ? '#71B0FF' : '#005494'} />
      </svg>
    </span>
  );
};

const getComparisonOptions = (comparisons: AnswerComparison[] | undefined) => {
  if (!comparisons) {
    return [];
  }

  const options = [
    {
      key: 'all',
      text: 'All examples',
      value: 'all',
      image: <></>,
    },
    ...comparisons.map(({ name }, index) => ({
      image: <ComparisonIcon index={index} />,
      key: name,
      text: name,
      value: name,
    }))
  ];

  return options;
};

export { getComparisonOptions };