import classNames from 'classnames';
import { includesAll } from 'lib/array-helper';
import { includes, remove } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import {
  SCOPE_ENABLE_MAPPING,
  Scope
} from 'stores/RoleStore';
import { getUpdatedScopes } from 'stores/utils/roles';
import { v4 as uuid4 } from 'uuid';
import './scopes.scss';

export interface ScopesProps {
  availableScopes: Scope[];
  selectedScopes: string[];
  onScopesChange: (scopes: string[]) => void;
}

@observer
class Scopes extends React.Component<ScopesProps> {
  constructor(props: ScopesProps) {
    super(props);
  }
  handleScopeChange = ({ checked, value: scope }: CheckboxProps) => {
    let selectedScopes = getUpdatedScopes(this.props.selectedScopes, scope as string, !!checked);
    // If scope has conditional enabling logic, find scopes that
    // need to be disabled when disabling a particular scope
    // eg. if view is unchecked, download and editthemes need to
    // be unchecked as well, since download and editthemes are
    // only enabled when view is enabled
    if (scope) {
      Object.keys(SCOPE_ENABLE_MAPPING).forEach(key => {
        if (SCOPE_ENABLE_MAPPING[key].includes(scope)) {
          remove(selectedScopes, s => s === key);
        }
      });
    }
    this.props.onScopesChange(selectedScopes);
  }
  renderScope = (scope: Scope) => {
    const { selectedScopes } = this.props;
    const { name, value } = scope;
    const checked = includes(selectedScopes, value);
    const scopeEnableMapping = SCOPE_ENABLE_MAPPING[value];
    const disabled = scopeEnableMapping ? !includesAll(selectedScopes, scopeEnableMapping) : false;
    const classes = classNames({
      'disabled': disabled
    });
    const uniqueId = uuid4();

    return (
      <div
        key={value}
        className="scope"
      >
        <Checkbox
          id={uniqueId}
          label={
            <label
              htmlFor={uniqueId}
              className={classes}
            >
              {name}
            </label>
          }
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={(_e, data) => this.handleScopeChange(data)}
        />
      </div>
    );
  }
  render(): JSX.Element | null {
    const { availableScopes } = this.props;
    if (!availableScopes) {
      return null;
    }
    return (
      <React.Fragment>
        {
          availableScopes.map(scope => {
            return this.renderScope(scope);
          })
        }
      </React.Fragment>
    );
  }
}

export default Scopes;
