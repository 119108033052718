import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToastSuccessIcon from '../../images/icons/toast-success.svg';
import { NotificationStoreInterface } from 'stores/NotificationStore';
import { Button } from './Button';
import usePrevious from 'hooks/usePrevious';
import './toast.scss';

type Animation = 'OUT' | 'ENTERING' | 'IN' | 'LEAVING';

interface Props {
  notificationStore?: NotificationStoreInterface;
}

const ToastComponent = observer(({ notificationStore }: Props) => {
  const [animation, setAnimation] = React.useState<Animation>('ENTERING');
  const prevAnimation = usePrevious(animation);
  const currentNotification = notificationStore?.current;

  React.useEffect(() => {
    if (!currentNotification) {
      setAnimation('OUT');
    }

    if (prevAnimation === 'OUT') {
      setAnimation('ENTERING');
    }
  }, [prevAnimation, currentNotification]);

  if (!currentNotification || currentNotification.variant !== 'default') {
    return null;
  }

  const handleTransitionEnd = () => {
    if (animation === 'LEAVING') {
      setAnimation('OUT');
      notificationStore.dismiss(currentNotification.id);
    }
  }

  const toastClassNames = classNames(`toast-default`, {
    'toast-default--entering': animation === 'ENTERING',
    'toast-default--in': animation === 'IN',
    'toast-default--leaving': animation === 'LEAVING',
    'toast-default--clickable': currentNotification.action
  });

  const { action, actionText, message } = currentNotification

  return (
    <div
      className={toastClassNames}
      onTransitionEnd={handleTransitionEnd}
      role="alert"
      aria-live="assertive"
    >
      <div className="toast-default__icon">
        <ToastSuccessIcon className="toast-default__icon-svg" />
      </div>
      <p className="toast-default__message">{message}</p>
      {action && actionText && (
        <div className="toast-default__action">
          <Button
            onClick={action}
            size="small"
            subvariant="neutral-text"
            variant="tertiary"
          >
            {actionText}
          </Button>
        </div>
      )}
      <div className="toast-default__close">
        <Button
          onClick={() => setAnimation('LEAVING')}
          icon={<FontAwesomeIcon icon="times" />}
          size="small"
          subvariant="neutral-text"
          variant="tertiary"
        />
      </div>
    </div>
  );
});

const Toast = inject('notificationStore')(ToastComponent);

export { Toast };
