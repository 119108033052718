import { FilterType } from 'api/enums';
import { Segment, SegmentFilters } from 'api/interfaces';
import FilterButton from 'components/Filters/FilterButton';
import {
  getAnalysisConfig,
  getCanCreatePublicSavedFilters,
  getCommentColumns,
  getSortedTags,
  getThemesHierarchy,
} from 'components/Filters/filters.service';
import { FilterPopUpId } from 'components/Filters/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { getAnalysisConfigStore, getFilterStore } from 'stores/RootStore';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';
import './single-filter.scss';

interface Props {
  testId: string;
  surveyId: string;
  filterPopUpId: FilterPopUpId;
  filterKey: AnalysisFilterKey;
  buttonSize?: 'small' | 'medium';
  defaultDateToAll?: boolean;
  selectedFilters?: SegmentFilters;
  disabledFilterTypes?: FilterType[];
  onSavedFilterSelected?: (savedFilter: Segment) => void;
  onChange: (filters: Record<string, QueryFilter>) => void;
  onClose?: (filters: Record<string, QueryFilter>) => void;
}

class SingleFilter extends React.Component<Props> {
  static defaultProps = {
    filterKey: 'baseline'
  };

  componentDidMount() {
    const { defaultDateToAll } = this.props;
    if (defaultDateToAll) {
      getFilterStore().updateSetInitialDateFilter(false);
    }
  }

  componentWillUnmount() {
    getFilterStore().updateSetInitialDateFilter(true);
  }

  onChange = () => {
    const { onChange, filterKey } = this.props;
    const newFilters = getFilterStore().filters[filterKey];

    if (newFilters) {
      onChange(newFilters);
    }
  };

  onClose = () => {
    const { onClose, filterKey } = this.props;
    const newFilters = getFilterStore().filters[filterKey];
    if (newFilters && onClose) {
      onClose(newFilters);
    }
  };

  getFilterConfig() {
    const { buttonSize, disabledFilterTypes, filterKey, filterPopUpId, surveyId } = this.props;
    const canCreatePublicSavedFilters = getCanCreatePublicSavedFilters(surveyId);
    const sortedTags = getSortedTags();
    const themes = getThemesHierarchy();
    const { isFeedbackTool, showSentiment } = getAnalysisConfig();
    const commentColumns = getCommentColumns();

    return {
      buttonSize,
      commentColumns,
      canCreatePublicSavedFilters,
      disabledFilterTypes,
      filterKey,
      filterPopUpId,
      isFeedbackTool,
      showSentiment,
      sortedTags,
      themes,
    };
  }

  render() {
    const { filterKey } = this.props;
    const filters = getFilterStore().filters[filterKey];
    const filterConfig = this.getFilterConfig();

    const config = getAnalysisConfigStore().config;
    if (config && filters) {
      return (
        <FilterButton
          filterButtonId={this.props.testId}
          className="single-filter"
          filters={config.filters || []}
          onSavedFilterSelected={this.props.onSavedFilterSelected}
          onChange={this.onChange}
          onClose={this.onClose}
          config={filterConfig}
        />
      );
    } else {
      return <Button className="single-filter-loading-button" loading={true} disabled={true} />;
    }
  }
}

export default observer(SingleFilter);
