import * as React from 'react';
import { Popup as SemanticPopup, PopupProps } from 'semantic-ui-react';
import './tooltip.scss';

export const Tooltip = (props: PopupProps) => {
  return (
    <SemanticPopup
      className="tooltip"
      {...props}
    />
  );
};
