import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import './survey-incomplete-action.scss';

interface SurveyIncompleteActionProps {
  survey: LiteSurvey;
}

class SurveyIncompleteAction extends React.Component<SurveyIncompleteActionProps> {
  render() {
    const { survey } = this.props;
    const { surveyId, orgId } = survey;

    return (
      <div className="survey-action__wrapper">
        <p className="survey-action__message">
          Setup not complete
        </p>
        <Button
          color="blue"
          as={Link}
          to={`/c/${orgId}/s/${surveyId}/configure-survey`}
        >
          Continue setup
        </Button>
      </div>
    );
  }
}

export default SurveyIncompleteAction;