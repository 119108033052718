import OpenBox from 'images/open-box.svg';
import * as React from 'react';
import './empty.scss';

export const EmptyNoDatasets = () => (
  <div className="answers__empty">
    <OpenBox/>
    <h2>No datasets are available</h2>
    <p>Answers can only use datasets that have been reviewed</p>
  </div>
);
