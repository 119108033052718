import * as React from 'react';
import { Dropdown, DropdownItemProps, Popup } from 'semantic-ui-react';
import { ColumnInfo } from 'stores/ui/ConfigureDataUIStore';

/* Returns popups with menu items as triggers if menuSelectCallback is provided */
/* Otherwise a simple span with option text is returned to be used in simple dropdowns */
export function getColumnOptions (
  columns: ColumnInfo[],
  menuSelectCallback?: (columnIndex: number, columnName: string) => void
) {
  return columns.map(column => {
    const { index: value, sampleHeader: text, colType: type } = column;

    const content = getPopupOptionContent(column, text, menuSelectCallback);

    return {
      value,
      type,
      text,
      content
    } as DropdownItemProps;
  });
}

/* Returns popups with menu items as triggers if menuSelectCallback is provided */
/* Otherwise a simple span with option text is returned to be used in simple dropdowns */
function getPopupOptionContent (
  column: ColumnInfo,
  displayText: string,
  menuSelectCallback?: (columnIndex: number, columnName: string) => void
) {
  const { index, sample } = column;
  const content = getOptionContent(sample);

  return <Popup
    key={index as number}
    position="right center"
    hoverable={true}
    mouseEnterDelay={200}
    mouseLeaveDelay={100}
    className="column-popup"
    trigger={
      menuSelectCallback ?
        <Dropdown.Item
          key={index as number}
          value={index as number}
          text={displayText}
          onClick={() => menuSelectCallback(index as number, column.sampleHeader)}
        />
      :
        <div className="item-text">
          <span>{displayText}</span>
        </div>
    }
  >
    <Popup.Content>{content}</Popup.Content>
  </Popup>;
}

function getOptionContent (sample: string[]) {
  return (
    <div>
      <h4>Sample data</h4>
      <div className="sample">
        {(sample || [])
          .slice(0, 5)
          .map((s, i) => {
            return (
              <div
                className="sample-data"
                key={i}
              >
                {s}
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
