import * as React from 'react';
// This is because of some weird typing issue in `react-dropzone`
// and the test gives warnings about missing export default and
// mixed up default and named imports
// https://github.com/react-dropzone/react-dropzone/issues/328
import * as Dropzone from 'react-dropzone/dist/index';
import './import-container.scss';

export enum ImportStatus {
  DEFAULT = 'default',
  IN_PROGRESS = 'inProgress',
  ERROR = 'error',
  SUCCESS = 'success'
}

interface DropzoneProps {
  accept: string;
  acceptClassName: string;
  rejectClassName: string;
  onDrop: (accepted: Dropzone.ImageFile) => void;
  multiple: boolean;
}

interface ImportContainerProps {
  dropzoneProps?: DropzoneProps;
  hideDropzone?: boolean;
  status: ImportStatus;
  icon?: React.SVGProps<SVGElement> | JSX.Element;
  content: JSX.Element;
  footer?: JSX.Element;
}

interface ImportContainerState {
  fileDraggedInDropzone: boolean;
}
class ImportContainer extends React.Component<ImportContainerProps, ImportContainerState> {
  state = {
    fileDraggedInDropzone: false
  };

  renderImportStatus = () => {
    const { icon, content, footer } = this.props;

    const contentClassNames = `content${icon ? '' : ' center'}`;

    return (
      <>
        <div className="import-container__content-wrapper">
          {!!icon && icon}
          <div className={contentClassNames}>
            {content}
          </div>
        </div>
        {!!footer && footer}
      </>
    );
  }

  getImportContainerClassNames = () => {
    const { dropzoneProps, status } = this.props;

    let importContainerClassNames = 'import-container';

    if (!dropzoneProps) {
      importContainerClassNames += ' without-dropzone';
    }
    if (status === ImportStatus.DEFAULT) {
      importContainerClassNames += ' default';
    }
    if (status === ImportStatus.IN_PROGRESS) {
      importContainerClassNames += ' in-progress';
    }
    if (status === ImportStatus.SUCCESS) {
      importContainerClassNames += ' success';
    }

    return importContainerClassNames;
  }

  getDropzoneClassNames = () => {
    const { fileDraggedInDropzone } = this.state;
    const { status, hideDropzone, footer } = this.props;

    const hasBorder = (status === ImportStatus.DEFAULT || status === ImportStatus.ERROR) && !hideDropzone;

    let dropzoneClassNames = 'import-container__dropzone';

    if (hasBorder) {
      dropzoneClassNames += ' border';
    }
    if (status === ImportStatus.ERROR) {
      dropzoneClassNames += ' error';
    }
    if (status === ImportStatus.SUCCESS) {
      dropzoneClassNames += ' success';
    }
    if (footer) {
      dropzoneClassNames += ' small-bottom-padding';
    }
    if (fileDraggedInDropzone) {
      dropzoneClassNames += ' file-dragged';
    }

    return dropzoneClassNames;
  }

  render() {
    const { dropzoneProps } = this.props;

    if (dropzoneProps) {
      return (
        <div className={this.getImportContainerClassNames()}>
          <Dropzone
            {...dropzoneProps}
            className={this.getDropzoneClassNames()}
            data-testid="import-container-dropzone"
            onDragEnter={() => this.setState({ fileDraggedInDropzone: true })}
            onDragLeave={() => this.setState({ fileDraggedInDropzone: false })}
            onDrop={(data) => {
              this.props.dropzoneProps?.onDrop(data);
              this.setState({ fileDraggedInDropzone: false });
            }}
          >
            {this.renderImportStatus()}
          </Dropzone>
        </div>
      );
    }

    return (
      <div className={this.getImportContainerClassNames()}>
        {this.renderImportStatus()}
      </div>
    );
  }
}

export default ImportContainer;
