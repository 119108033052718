<template>
  <div
    v-loading="loading"
    class="widget"
  >
    <h3
      v-if="title"
      class="widget-title"
    >
      {{ title }}
    </h3>
    <slot name="subtitle" />
    <div
      v-if="error"
      class="initialization-failed widget-body"
    >
      <font-awesome-icon icon="exclamation-triangle" />
      Uh oh! {{ error }}
    </div>
    <slot v-else />

    <div
      v-if="warning"
      class="widget-warning"
    >
      <font-awesome-icon icon="exclamation-triangle" />
      {{ warning }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectorWrapper',
  props: {
    loading: { type: Boolean },
    error: { type: String },
    warning: { type: String },
    title: { type: String }
  }
};
</script>

<style lang="scss" scoped>

</style>
