import * as React from 'react';
import { Summary } from 'types/custom';
import "./metadata-list.scss";
import { MetadataColumn } from 'api/interfaces';
import * as moment from 'moment';

type Props = {
  summaryData: Summary['data'],
  metadataColumns: MetadataColumn[]
};

const MetadataList = (props: Props) => {
  const isUrl = (value:string|number):boolean => props.metadataColumns.find(column => column.name === value)?.isUrl ?? false;
  const isString = (value: string|number): value is string => typeof value === 'string';

  const formatItem = (key: string, value: string | number): string => {
    const metadata = props.metadataColumns.find((m) => m.name === key);

    if (metadata?.isDate || key.toLowerCase() === 'date') {
      const format = metadata?.dateFormat ?? 'Do MMM YYYY';
      return moment(value).format(format);
    }

    if (metadata && metadata.template) {
      return metadata.template.replace('{value}', String(value));
    }

    if (metadata && metadata.mapping) {
      return metadata.mapping[String(value)] || value;
    }
    return String(value);
  }

  return (
    <ul className="conversation-metadata-list">
      {Object.entries(props.summaryData).map(([key, value], index) => (
        <li
          key={`${key}--${index}`}
          className="conversation-metadata-item"
        >
          <b>{key}</b>
          { isUrl(value) && isString(value) ? (
            <a
              href={value}
              title={value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          ) : (
            <span>{formatItem(key, value)}</span>
          )}
        </li>
      ))}
    </ul>
  );
};

export { MetadataList };

