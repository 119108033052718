import * as React from 'react';
import { useAsyncFunction } from 'lib/useAsyncFunction';
import { getCoverageAndExamplesForPhrases } from 'vue/libs/quick-edits-review-changes';

const updatedCommentsAndCoverage = async (orgId: string, surveyId: string, selectedPhrases: string[]) => {
  return getCoverageAndExamplesForPhrases(orgId, surveyId, selectedPhrases);
};

interface UseFetchCommentsAndCoverageProps {
  orgId: string;
  selectedPhrases: string[];
  surveyId: string;
}

const useFetchCommentsAndCoverage = ({ orgId, selectedPhrases, surveyId }: UseFetchCommentsAndCoverageProps) => {
  const {
    fetch: fetchCommentsAndCoverage,
    data: commentsAndCoverage,
    error: commentsAndCoverageError,
    loading: loadingCommentsAndCoverage,
  } = useAsyncFunction(updatedCommentsAndCoverage);

  React.useEffect(() => {
    fetchCommentsAndCoverage(orgId, surveyId, selectedPhrases);
  }, [fetchCommentsAndCoverage, orgId, selectedPhrases, surveyId]);

  return {
    commentsAndCoverage,
    commentsAndCoverageError,
    loadingCommentsAndCoverage,
  }
}

export { useFetchCommentsAndCoverage };
