
import { BillingAccountPartial, OrganizationJson } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { Fetchable } from 'lib/fetch';
import { action, computed, observable } from 'mobx';
import { stringify } from 'query-string';

export interface OrganizationStoreInterface {
  orgId: string;
  orgIdQueryParam: string;

  orgName: string;
  orgExists: boolean;
  orgType: string;
  logo?: string;
  ssoDomain?: string;
  availableActions: string[];
  hasIdentityProvider: boolean;
  hasMultipleWorkspaces: boolean;
  billingAccount?: BillingAccountPartial;
  currentOrg?: OrganizationJson;

  fetchingOrganization: Promise<any> | null;
  fetchOrganizationError: string | null;

  setOrg: (orgId: string) => Promise<void>;

  fetchOrganization: (orgId: string) => Promise<void>;
  getCanAction: (actionRequired: string) => boolean;
}

class OrganizationStore implements OrganizationStoreInterface {
  @observable
  fetchingOrganization = null as Promise<Fetchable<OrganizationJson>> | null;

  @observable
  fetchOrganizationError: string | null = null;

  @observable
  currentOrg?: OrganizationJson = undefined;

  @computed
  get orgExists() {
    return !!this.currentOrg;
  }

  @computed
  get orgId() {
    const org = this.currentOrg;
    return org ? org.displayId : '';
  }

  @computed
  get orgName() {
    const org = this.currentOrg;
    return org ? org.name : '';
  }

  @computed
  get orgType() {
    const org = this.currentOrg;
    return org ? org.orgType : '';
  }

  @computed
  get logo() {
    const org = this.currentOrg;
    return org ? org.logo : undefined;
  }

  @computed
  get billingAccount() {
    const org = this.currentOrg;
    return org ? org.billingAccount : undefined;
  }

  @computed
  get ssoDomain() {
    const org = this.currentOrg;
    return org ? org.sso_domain : undefined;
  }

  @computed
  get availableActions() {
    const org = this.currentOrg;
    return org ? org.currentUserCan : [];
  }

  @computed
  get hasIdentityProvider() {
    return !!this.ssoDomain;
  }

  @computed
  get hasMultipleWorkspaces() {
    return !!(this.billingAccount &&
      this.billingAccount.workspaces &&
      this.billingAccount.workspaces.length > 1);
  }

  @computed
  get orgIdQueryParam() {
    return stringify({ organization: this.orgId });
  }

  @action
  setOrg = async (orgId: string) => {
    if (this.orgId !== orgId) {
      this.currentOrg = undefined;
      if (orgId) {
        await this.fetchOrganization(orgId);
        localStorage.setItem('mostRecentOrganization', orgId);
      }
    }
  }

  getCanAction = (actionRequired: string) => {
    if (this.availableActions) {
      return this.availableActions.includes(actionRequired);
    }
    return false;
  }

  @action
  fetchOrganization = async (orgId: string) => {
    const queryParam = stringify({ organization: orgId });
    const url = `/organization?${ queryParam }`;

    if (!this.fetchingOrganization) {
      this.fetchOrganizationError = null;
      this.fetchingOrganization = Auth.fetch<OrganizationJson>(url);
    }

    try {
      const { ok, data, errorData } = await this.fetchingOrganization;
      if (ok && data) {
        this.currentOrg = data;
      }
      if (!ok && errorData) {
        this.fetchOrganizationError = errorData.message;
      }
    } catch (e) {
      this.fetchOrganizationError = e.message;
    } finally {
      this.fetchingOrganization = null;
    }

    return undefined;
  }
}

export default OrganizationStore;
