<template>
  <div
    ref="comment"
    class="exportable-comment-container"
  >
    <div class="exportable-comment">
      <p class="exportable-comment__bd">
        <span
          v-for="block in blocks"
          :key="block.key"
          :class="{
            'no-theme': !block.hasThemes,
            highlighted: block.isHighlighted,
            [sentimentClassname(block.sentiment)]: true,
            [commentHighlightOverride]: true,
          }"
        >{{ block.content }}</span>
      </p>
      <comment-metadata-list
        :info="info"
      />
      <div class="exportable-comment__themes">
        <div class="theme-list">
          <CommentThemesList
            :items="findOrderedThemeItems()"
            :are-themes-in-applying-state="false"
            :has-sentiment="true"
            :can-manage-themes="false"
          />
        </div>
      </div>
      <div class="exportable-comment__logo">
        <export-logo-icon class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  calculateSimpleSentiment,
  ValidSimpleSentiments,
} from 'lib/calculate-sentiment';
import { getAnalysisConfigStore, getAnalysisToolsUIStore, getThemesStore } from 'stores/RootStore';
import CommentThemesList from 'vue/components/Comments/CommentThemesList.vue';
import CommentMetadataList from 'vue/components/Comments/CommentMetadataList.vue';
import reduceBlocksToHighlighted from "lib/reduce-blocks-to-highlighted.ts";
import matches from "lib/matches.ts";
import { copyNodeToClipboardAsImage } from "../../../lib/clipboard";
import getCommentHighlightLocations from "lib/get-comment-highlight-locations.ts";
import segmentsToCategories from "lib/segments-to-categories.ts";
import segmentsToOrderedThemes from "lib/segments-to-ordered-themes.ts";
import segmentsToBlocks from "lib/segments-to-blocks.ts";
import toCommentThemeItem from "vue/libs/to-comment-theme-item.ts";
import ExportLogoIcon from 'images/vue-icons/export-logo.svg';

export default {
  name: 'ExportableComment',
  components: {
    CommentThemesList,
    CommentMetadataList,
    ExportLogoIcon
  },
  props: {
    comment: { default: '', type: String },
    commentTags: { default: () => [], type: Array },
    highlightType: { default: '', type: String },
    info: { default: () => ({}), type: Object },
    segmentsAroundHighlightedSentences: { default: 1, type: Number},
    selectedThemeCodes: { default: () => ({}), type: Object },
    segments: { default: () => [], type: Array },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      themesStore: getThemesStore(),
      hover: undefined,
      hoveredBlock: undefined,
    };
  },
  computed: {
    hasSentiment () {
      return this.analysisConfigStore.hasSentiment;
    },
    commentCategories() {
      return segmentsToCategories(this.segments);
    },
    /**
     * The highlightType is usually `all` that yields the default style
     * different values (passed in from config) let us control highlighting style
     */
    commentHighlightOverride() {
      const { highlightType } = this;
      if (highlightType === 'none') {
        return 'theme-sentiment-off';
      }
      return '';
    },

    highlightedLocations() {
      return getCommentHighlightLocations(
        this.segments,
        this.selectedThemeCodes,
        null,
        null
      );

    },
    blocks() {
      const blocks = segmentsToBlocks(
        this.comment,
        this.segments,
        -1,
        this.highlightedLocations
      );

      // only retain the one sentence either side of any highlighted sentences
      // any others are replaced with elipses and consecutive elipses are collapsed
      return reduceBlocksToHighlighted(blocks, this.segmentsAroundHighlightedSentences);
    },
    orderedThemes() {
      return segmentsToOrderedThemes(
        this.segments,
        this.selectedThemeCodes
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      // Copy comment after view is rendered
      this.copyComment();
    });
  },
  methods: {
    isHighlighted(theme) {
      return !this.hover && matches(theme, this.selectedThemeCodes);
    },
    sentimentClassname(sentiment) {
      const { hasSentiment } = this;

      // if no sentiment, short-circuit
      if (!hasSentiment) {
        return 'no-sentiment';
      }
      const label = calculateSimpleSentiment(sentiment);
      if (Object.values(ValidSimpleSentiments).includes(label)) {
        return `theme-sentiment-${label}`;
      } else {
        return '';
      }
    },
    findOrderedThemeItems() {
      return this.orderedThemes
        .filter(theme => this.isHighlighted(theme))
        .map(theme => toCommentThemeItem({
          theme,
          segments: this.segments,
          hasBackgroundColour: true,
          isNew: false,
          isRemoved: false,
          hoveredLocation: null,
          hoveredThemeCodes: null,
          selectedThemeCodes: this.selectedThemeCodes
        })
      );
    },

    copyComment() {

      const commentRef = this.$refs.comment;

      if (!commentRef) {
        return;
      }

      const scale = 2;
      const style = {
        'transform': 'scale('+scale+')',
        'transform-origin': 'top left'
      }

      copyNodeToClipboardAsImage(commentRef, {
        width: commentRef.clientWidth * scale,
        height: commentRef.clientHeight * scale,
        style
      }).then(() => {
        this.$emit('onExported');
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';


.exportable-comment-container {
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  position: absolute;
  bottom: -1000px;
}

.exportable-comment {
  display: grid;
  grid: auto auto / 1fr 1fr 30px;
  margin: 0px;
  grid-template-areas:
        'bd bd bd'
        'metadata themes logo';
  background: $--white;
  border-radius: $--border-radius-small;
  border: 1px solid $--neutral-200;
  width: 600px;
  padding: 0;


  &__bd {
    font-weight: 400;
    font-size: 14px;
    grid-area: bd;
    line-height: 22px;
    margin: 0;
    padding: 8px 15px;
    position: relative;
    span {
      @extend .theme-highlight-base;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 0;
      white-space: pre-wrap;
    }
  }

  &__metadata {
    align-items: center;
    font-size: 12px;
    grid-area: metadata;
    padding: 5px 15px 0px;
    .metadata-list {
      display: flex;

      flex: 1 1 auto;
      .metadata-list-headers {
        text-align: right;
      }
    }
    .metadata-item {
      span,
      a {
        font-size: 14px;
      }
      flex: 1 1 auto;
      line-height: 19px;
      margin-right: 20px;
      padding-bottom: 5px;
    }
  }

  &__themes b,
  &__metadata .metadata-item b {
    color: $--neutral-500;
    font-weight: 600;
    display: inline;
    letter-spacing: 0.4px;
    margin-right: 10px;
    text-transform: uppercase;
  }

  &__themes b {
    font-size: 12px;
    padding-bottom: 5px;
  }

  &__metadata .metadata-item b {
    font-size: 12px;
  }

  &__themes {
    width: 100%;
  }

  &__themes .theme-list.collapsed {
    position: relative;
    height: 27px;
    overflow: hidden;
    display: flex;

    .theme {
      white-space: nowrap;
      line-height: 1.4em;
    }
  }

  &__themes .theme-list.collapsed::after {
    height: 27px;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
  }

  &__themes {
    grid-area: themes;
    padding: 0px 15px;
  }

  &__logo {
    grid-area: logo;
    position: relative;
    right: 8px;


    svg {
      position: absolute;
      bottom: 8px;
      left: 0px;
      width: 20px;
      g {
        path:nth-child(2) {
          background-color: $--primary-500;
        }
      }
    }
  }
}


.exportable-comment__themes .theme {
  align-items: start;
  border: none;
  color: $--neutral-700;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1;

  $border-radius: 0.3em;
  border-radius: $border-radius;
  margin: 0 5px 5px 0;

  .theme-sentiment {
    display: flex;
    flex-direction: row;
    margin: 2px 6px 2px 0;
    align-items: start;
    cursor: pointer;
    height: 100%;
    svg {
      font-size: 0.8em;
    }
    .sentiment-icon {
      width: 16px;
      height: 16px;
    }
  }
  &.no-sentiment {
    grid-template-columns: auto;
  }
}
</style>
