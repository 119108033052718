import auth from 'Auth/Auth';
import { Segment, SegmentFilters } from 'api/interfaces';
import { createCategoryFilter } from 'lib/filters/category-filter-helper';
import { createSentimentFilter } from 'lib/filters/sentiment-filter-helper';
import { isEqual } from 'lodash';
import { action, computed, observable, reaction, toJS } from 'mobx';
import config from 'runtime-config';
import { AnalysisConfigStoreInterface } from './AnalysisConfigStore';
import { FilterStoreInterface } from './FilterStore';
import { AnalysisFilterKey, QueryFilter } from './types';
import { DateShortcutMap, getSelectedCuts } from 'lib/filters/saved-filter-helper';
import { createThemeFilter } from 'lib/filters/theme-filter-helper';
import { createTagFilter } from 'lib/filters/tag-filter-helper';
import { queryFiltersToSegmentFilters } from 'lib/query-filters-to-segment-filters';
import { getDateShortcuts } from 'lib/filters/date-filter-helper';

export interface SegmentStoreInterface {
  currentSurveyId: string | null;
  currentViewId: string | null;
  segments: Segment[];
  selectedSegment: {
    baseline?: string,
    comparison?: string
  };

  createSegmentError?: string;
  updateSegmentError?: string;
  deleteSegmentError?: string;

  setDatasetIdentifiers: (ids: Partial<{surveyId: string, viewId: string}>) => void;
  getSegmentById: (segmentId?: string) => Segment | undefined;
  selectSegment: (filterKey: AnalysisFilterKey, segmentId: string) => void;
  applySegment: (filterKey: AnalysisFilterKey, selectedSegment: SegmentFilters) => void;
  getSelectedFilters: (filterKey: AnalysisFilterKey) => SegmentFilters;

  hasSelectionChanged: (filterKey: AnalysisFilterKey, selectedSegmentId: string) => boolean;
  updateCurrentSegment: (filterKey: AnalysisFilterKey) => void;
  resetSelectedSegment: (filterKey?: AnalysisFilterKey) => void;

  createSegment: (filterKey: AnalysisFilterKey, segmentName: string, isPublic: boolean) => Promise<void>;
  updateSegment: (segment: Segment) => Promise<void>;
  deleteSegment: (segmentId: string) => Promise<void>;
}

class SegmentStore implements SegmentStoreInterface {

  @observable
  fetchSegmentsError?: string = undefined;

  @observable
  createSegmentError?: string = undefined;

  @observable
  updateSegmentError?: string = undefined;

  @observable
  deleteSegmentError?: string = undefined;

  @observable
  segments: Segment[] = [];

  @observable
  currentSurveyId: string | null = null;

  @observable
  currentViewId: string | null = null;

  @computed
  get hasSegments() {
    return this.segments.length > 0;
  }

  filterStore: FilterStoreInterface;
  analysisConfigStore: AnalysisConfigStoreInterface;

  /* Holds id of the segment currently selected */
  @observable
  selectedSegment = {
    baseline: undefined,
    comparison: undefined
  };

  constructor(
    filterStore: FilterStoreInterface,
    analysisConfigStore: AnalysisConfigStoreInterface,
  ) {
    this.filterStore = filterStore;
    this.analysisConfigStore = analysisConfigStore;

    reaction(
      () => this.currentSurveyId,
      () => {
        this.resetSelectedSegment();
        this.fetchSegments();
      }
    );

    reaction(
      () => this.currentViewId,
      () => {
        this.resetSelectedSegment();
        this.fetchSegments();
      }
    );
  }

  getSegmentById = (segmentId?: string) => {
    return this.segments.find(s => s.id === segmentId);
  }

  /*
    Fetches list of segments in the current survey/view
   */
  @action
  fetchSegments = async () => {
    this.fetchSegmentsError = undefined;

    const url = this.getUrl();
    if (!url) {
      return;
    }

    try {
      const { data, ok, errorData } = await auth.fetch<Segment[]>(url);
      if (data && ok) {
        this.segments = data;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.fetchSegmentsError = `Failed to fetch segments: ${ e.message }`;
    }
  };

  /*
    Gets currently selected filters (that are not `All`) and creates a new
    segment for the current dataset id (survey id + view id)
  */
  @action
  createSegment = async (filterKey: AnalysisFilterKey, segmentName: string, isPublic: boolean) => {
    const url = this.getUrl();
    if (!url) {
      return;
    }

    let selectedFilters = this.getSelectedFilters(filterKey);

    const segment = {
      name: segmentName,
      configuration: {
        filters: selectedFilters
      },
      isPublic
    };

    try {
      const { data, ok, errorData } = await auth.fetch<Segment>(url, {
        method: 'POST',
        body: JSON.stringify(segment)
      });
      if (data && ok) {
        this.segments = [...this.segments, data];
        // Update the selected segment to be the latest segment created
        this.selectedSegment = { ...this.selectedSegment, [filterKey]: data.id };
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.createSegmentError = `Failed to create segment: ${ e.message }`;
    }
  }

  /*
    Resets the selected segment for the given filter key (unselects the segment)
  */
  @action
  resetSelectedSegment = (filterKey?: AnalysisFilterKey) => {
    if (!filterKey) {
      this.selectedSegment = {
        baseline: undefined,
        comparison: undefined
      };
    } else {
      this.selectedSegment = { ...this.selectedSegment, [filterKey]: undefined };
    }
  }

  /*
    Selects a segment by populating the selected cuts from the selections
    stored against the selected segment and applying them
  */
  @action
  selectSegment = (filterKey: AnalysisFilterKey, segmentId: string) => {
    this.selectedSegment = { ...this.selectedSegment, [filterKey]: segmentId };
    const selectedSegment = this.segments.find(s => s.id === segmentId)?.configuration.filters;

    if (!selectedSegment) {
      return;
    }

    this.applySegment(filterKey, selectedSegment);
  }

  @action
  applySegment = (filterKey: AnalysisFilterKey, segmentFilters: SegmentFilters) => {
    const queryFilters = this.filterStore.filters[filterKey] || {};
    const analysisFilters = this.analysisConfigStore.filters || [];
    const combinedFilters = [
      ...analysisFilters,
      createTagFilter(),
      createThemeFilter(),
      createCategoryFilter(),
      createSentimentFilter()
    ];

    const dateShortcuts: DateShortcutMap = Object
      .keys(segmentFilters)
      .reduce((result, filterId) => {
        const dateShortcuts = getDateShortcuts(filterId, this.filterStore.filterConfig);
        return {
          ...result,
          [filterId]: dateShortcuts
        };
      }, {});

    const selectedCuts = getSelectedCuts(
      segmentFilters,
      queryFilters,
      combinedFilters,
      dateShortcuts
    );

    this.filterStore.selectFilterSet(filterKey, selectedCuts);
  }

  /*
    Updates the segment with the updated value
  */
  @action
  updateSegment = async (segment: Segment) => {
    this.updateSegmentError = '';
    const url = this.getUrl();
    if (!url) {
      return;
    }

    try {
      const { data, ok, errorData } = await auth.fetch<Segment>(`${ url }/${ segment.id }`, {
        method: 'PUT',
        body: JSON.stringify(segment)
      });

      if (data && ok) {
        this.segments = this.segments.map(s => s.id === segment.id ? segment : s);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.updateSegmentError = `Failed to update segment: ${ e.message }`;
    }
  }

  /*
    Updates the current segment with the currently selected filter values
    (Used from `Update Segment` CTA)
  */
  @action
  updateCurrentSegment = async (filterKey: AnalysisFilterKey) => {
    const segmentId = this.selectedSegment[filterKey];
    if (segmentId === undefined) {
      return;
    }

    let selectedFilters = this.getSelectedFilters(filterKey);

    const segment = this.segments.find(s => s.id === segmentId);

    if (!segment) {
      return;
    }

    const updatedSegment = {
      ...segment,
      configuration: {
        filters: selectedFilters
      }
    };

    await this.updateSegment(updatedSegment);
  }

  getUrl() {
    const surveyId = this.currentSurveyId;
    if (!surveyId) {
      return;
    }

    const { apiLocation } = config;
    if (this.currentViewId) {
      return `${ apiLocation }/survey/${ surveyId }/view/${ this.currentViewId }/segments`;
    } else {
      return `${ apiLocation }/survey/${ surveyId }/segments`;
    }

  }

  /*
    Deletes segment with the given id in current survey/view
  */
  @action
  deleteSegment = async (segmentId: string) => {
    this.deleteSegmentError = '';
    const url = this.getUrl();
    if (!url) {
      return;
    }

    try {
      const { data, ok, errorData } = await auth.fetch<Segment>(`${ url }/${ segmentId }`, {
        method: 'DELETE'
      });

      if (data && ok) {
        this.segments = this.segments.filter(s => s.id !== segmentId);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.deleteSegmentError = `Failed to delete segment: ${ e.message }`;
    }
  }

  @action
  setDatasetIdentifiers({ surveyId, viewId }: Partial<{surveyId: string, viewId: string}>) {
    this.currentSurveyId = surveyId || null;
    this.currentViewId = viewId || null;
  }

  /*
    Compares the currently selected values and the values stored against a segment to check for changes
  */
  hasSelectionChanged(filterKey: AnalysisFilterKey, selectedSegmentId: string) {
    if (selectedSegmentId === undefined) {
      return false;
    }
    const currentSelectedFilters = this.getSelectedFilters(filterKey);
    const selectedSegment = this.segments.find(s => s.id === selectedSegmentId);
    if (!selectedSegment) {
      return false;
    }

    const selectedFiltersInSegment = toJS(selectedSegment.configuration.filters);

    const currentSelectedFilterKeys = Object.keys(currentSelectedFilters).sort();
    const selectedFiltersInSegmentKeys = Object.keys(selectedFiltersInSegment).sort();

    // Mismatch in keys means the two selections are different, so early exit
    if (!isEqual(currentSelectedFilterKeys, selectedFiltersInSegmentKeys)) {
      return true;
    }

    // Check for at least one mismatch in data for a given filter in the selection
    // For dates, if filter in segment has name, check if the names are equal (ex. Last 31 days
    // can have different dates based on when you're looking at the data). If no name exists,
    // check for mismatch in start and end dates
    const hasChanged = selectedFiltersInSegmentKeys.some(key => {
      const segmentValue = selectedFiltersInSegment[key];
      const selectedValue = currentSelectedFilters[key];
      if (segmentValue.startDate) {
        if (segmentValue.name) {
          return segmentValue.name !== selectedValue.name;
        } else {
          return segmentValue.startDate !== selectedValue.startDate || segmentValue.endDate !== selectedValue.endDate;
        }
      } else {
        return !isEqual(segmentValue, selectedValue);
      }
    });
    return hasChanged;
  }

  /*
    Get selected filter values in the current filter set to be saved as a segment
  */
  getSelectedFilters(filterKey: AnalysisFilterKey): SegmentFilters {
    const filters: Record<string, QueryFilter> | undefined = this.filterStore.filters[filterKey];

    if (!filters) {
      return {};
    }

    const dateShortcuts: DateShortcutMap = this.filterStore.dateShortcutMaps[filterKey];

    return queryFiltersToSegmentFilters(
      filters,
      dateShortcuts
    );
  }
}

export default SegmentStore;
