import { defaults, find, pick } from 'lodash';
import { action, computed, observable } from 'mobx';
import { RequestOptions } from 'stores/InitConfigStore';
import { getAnalysisToolsUIStore, getInitConfigStore } from 'stores/RootStore';
import { Theme, mapThemeObject } from 'vue/explore/theme-data-functions';
import { Score } from 'vue/libs/score-to-segments';

export enum AxisType {
  Volume = 'volume',
  Impact = 'impact',
  Score = 'score',
}

export enum VolumeType {
  All = 'all',
  Negative = 'negative',
  Positive = 'positive',
}

interface ExploreAxis {
  axisType: AxisType;
  key: string;
  scoreColumn?: number;
  scoreOptions?: { name: string; range: number[] };
  scoreType?: string;
  title: string;
}

export interface ScoreConfigJson {
  name?: string;
  score_column: number;
  score_options?: {
    name: string;
    range: string;
    happiness?: boolean;
    scoreCommentsOnly?: boolean;
    units: string;
  };
  score_type: string;
}

export interface ThemeJson {
  baselineCount: number;
  comparisonCount?: number;
  baselineScores?: Score;
  comparisonScores?: Score;
  baselineThemes?: object;
  comparisonThemes?: object;
}

interface Observables extends ThemeJson {
  axisType: AxisType;
  volumeType?: VolumeType;
  searchFilter: string;
  score?: string;
  sort: string;
  themeData?: ThemeJson;
  themes: Theme[];
}

interface Computed {
  exploreAxes: ExploreAxis[];
  impactChartSubtitle: string;
  impactFormatter: string;
  requestOptions:
    | Pick<RequestOptions, 'scoreType' | 'dataSource' | 'exampleColumns' | 'scoreColumn' | 'scoreOptions'>
    | undefined;
  selectedThemeObject?: Theme;
  selectedThemeName?: string;
  scoreName: string;
  scoreFormatter: string;
  range: number[] | undefined;
  selectedScore: ScoreConfigJson | undefined;
}

interface Actions {
  resetExplore: () => void;
  setAxis: (axisType: AxisType) => void;
  setSentimentFilter: (filterType: VolumeType) => void;
  setScore: (score: string) => void;
  setSearchFilter: (searchFilter: string) => void;
  setSort: (sort: string) => void;
  setThemeData: (themes?: ThemeJson) => void;
}

export interface ExploreToolUIStoreInterface extends Observables, Computed, Actions {}

class ExploreToolUIStore implements ExploreToolUIStoreInterface {
  range: number[] | undefined;
  //#region Observables
  @observable
  axisType = AxisType.Volume;

  @observable
  volumeType = VolumeType.All;

  @observable
  searchFilter = '';

  @observable
  score?: string;

  @observable
  sort = 'difference';

  @observable
  themes: Theme[];

  @observable
  baselineCount = 0;

  @observable
  comparisonCount = 0;

  @observable
  baselineScores?: Score;

  @observable
  comparisonScores?: Score;

  @observable
  baselineThemes?: object;

  @observable
  comparisonThemes?: object;
  //#endregion Observables

  //#region Computed
  @computed
  get exploreAxes() {
    const axes: ExploreAxis[] = [{ axisType: AxisType.Volume, key: AxisType.Volume, title: 'Volume' }];
    const initConfigStore = getInitConfigStore();

    if (initConfigStore.scores?.length) {
      axes.push({
        axisType: AxisType.Impact,
        key: AxisType.Impact,
        title: 'Impact',
      });
      axes.push({
        axisType: AxisType.Score,
        key: AxisType.Score,
        title: 'Score',
      });
    }

    return axes;
  }

  @computed
  get impactChartSubtitle(): string {
    // extract score name
    const scoreName = this.requestOptions?.scoreOptions?.name ?? 'score';
    const scoreType = this.requestOptions?.scoreType ?? '';

    const subtitles = {
      average: `Impact on average ${scoreName}`,
      nps: 'Impact on NPS',
      threshold: `Impact on average ${scoreName}`,
    };

    return this.axisType === AxisType.Impact ? subtitles[scoreType] : '';
  }

  @computed
  get impactFormatter(): string {
    const units = this.selectedScore?.score_options?.units;
    const scoreType = this.requestOptions?.scoreType ?? '';

    const defaultImpactFormatter = {
      average: this.requestOptions?.scoreOptions?.range ? '<%= value %>%' : undefined,
      nps: undefined,
      threshold: undefined,
    };

    return units ? this.scoreFormatter : defaultImpactFormatter[scoreType] || '';
  }

  @computed
  get requestOptions() {
    const initConfigStore = getInitConfigStore();
    const existing = initConfigStore.requestOptions;
    const scores = initConfigStore.scores as ScoreConfigJson[];

    if (!Object.keys(existing).length) {
      return undefined;
    }

    const baseOptions = pick(existing, 'dataSource', 'exampleColumns', 'scoreColumn', 'scoreOptions', 'scoreType');
    const { axisType: key } = this;
    const axis = find(this.exploreAxes, { key }) as ExploreAxis | undefined;

    if (!axis) {
      return baseOptions;
    }

    const selectedScore = find(scores, {
      key: this.score,
    }) as ScoreConfigJson | undefined;
    let override = {};
    if (selectedScore) {
      const { score_column: scoreColumn, score_options: scoreOptions, score_type: scoreType } = selectedScore;
      override = { scoreColumn, scoreOptions, scoreType };
    }

    return defaults({}, override, baseOptions);
  }

  @computed
  get selectedThemeObject() {
    const analysisToolsUIStore = getAnalysisToolsUIStore();

    const theme = analysisToolsUIStore.selectedThemeName;
    const subtheme = analysisToolsUIStore.selectedSubthemeName;

    const themeObj = find(this.themes, { title: theme });
    if (themeObj && subtheme) {
      return find(themeObj.subthemes, { title: subtheme });
    } else {
      return themeObj;
    }
  }

  @computed
  get selectedThemeName() {
    return this.selectedThemeObject?.title;
  }

  @computed
  get scoreName() {
    if (!this.selectedScore) {
      return this.score || '';
    }

    if (this.selectedScore.name) {
      return this.selectedScore.name;
    } else {
      return this.selectedScore.score_type;
    }
  }

  @computed
  get scoreFormatter() {
    let units = this.selectedScore?.score_options?.units ?? '';

    if (units === 'dollars') {
      units = '$<%= value %>';
    } else if (units) {
      // we only allow a suffix of 3 chars
      units = `<%= value %> ${units.substring(0, 3)}`;
    } else {
      units = '<%= value %>';
    }

    return units;
  }

  @computed
  get selectedScore() {
    const scores = getInitConfigStore().scores || [];
    return find(scores, {
      key: this.score,
    }) as ScoreConfigJson | undefined;
  }
  //#endregion Computed

  //#region Actions
  @action
  resetExplore() {
    this.axisType = AxisType.Volume;
    this.volumeType = VolumeType.All;
    this.searchFilter = '';
    this.score = undefined;
    this.sort = 'difference';
    this.baselineThemes = undefined;
    this.comparisonThemes = undefined;
  }

  @action
  setAxis(axisType: AxisType) {
    // We record the setting of the axis as a visualization load
    let eventType = 'BARGRAPH';
    if (axisType === AxisType.Impact) {
      eventType = 'SCOREIMPACT';
    } else if (axisType === AxisType.Score) {
      eventType = 'SCORE';
    }

    analytics.track('Visualization Loaded', {
      category: 'Analysis',
      label: eventType,
      type: eventType,
    });

    // TODO: Replace `setAxisType` references
    getAnalysisToolsUIStore().setAxisType(axisType);
    this.axisType = axisType;
  }

  @action
  setSentimentFilter(filterType: VolumeType) {
    this.volumeType = filterType;
  }

  @action
  setScore(score: string) {
    this.score = score;
  }

  @action
  setSearchFilter(searchFilter: string) {
    this.searchFilter = searchFilter;
    this.setThemeData();
  }

  @action
  setSort(sort: string) {
    this.sort = sort;
    this.setThemeData();
  }

  @action
  setThemeData(themes?: ThemeJson) {
    if (themes) {
      this.baselineThemes = { ...themes?.baselineThemes };
      this.comparisonThemes = { ...themes?.comparisonThemes };
      this.baselineScores = { ...themes?.baselineScores } as Score;
      this.comparisonScores = { ...themes?.comparisonScores } as Score;
      this.baselineCount = themes?.baselineCount ?? 0;
      this.comparisonCount = themes?.comparisonCount ?? 0;
    }

    this.themes = mapThemeObject(
      themes?.baselineScores ?? (this.baselineScores as Score),
      themes?.comparisonScores ?? (this.comparisonScores as Score),
      this.requestOptions?.scoreType as string,
      this.requestOptions?.scoreOptions?.range,
      themes?.baselineThemes ?? this.baselineThemes,
      themes?.comparisonThemes ?? this.comparisonThemes,
      themes?.baselineCount ?? this.baselineCount,
      themes?.comparisonCount ?? this.comparisonCount,
      this.sort,
      this.axisType,
      this.searchFilter,
    );
  }
  //#endregion Actions
}

export default ExploreToolUIStore;
