var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-wrapper',{attrs:{"aria-label":_vm.subtitle,"title":_vm.config.title,"loading":_vm.loading,"error":_vm.error,"warning":_vm.warning,"panel-order":_vm.panelOrder,"widget-order":_vm.widgetOrder}},[_c('div',{staticClass:"widget-subtitle",class:{ 'widget-subtitle-clickable': _vm.canNavigate },attrs:{"slot":"subtitle"},on:{"click":function($event){return _vm.doNavigate()}},slot:"subtitle"},[_c('div',{staticClass:"widget-subtitle-text"},[_c('span',{staticClass:"key-text"},[_vm._v("\n        "+_vm._s(_vm.subtitle)+"\n        "),(_vm.canNavigate)?_c('font-awesome-icon',{staticClass:"link-icon",attrs:{"icon":"chart-bar"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"widget-display-categories"},[_vm._v("\n      Volume\n    ")])]),_vm._v(" "),_c('div',{staticClass:"themes-body widget-body",class:{ empty: _vm.empty }},[_c('div',{staticClass:"legend"},[_c('svg',{staticClass:"key-square",attrs:{"preserveAspectRatio":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"7","cy":"7","r":"7","width":"14","height":"14","fill":_vm.colors.primary500}})]),_vm._v(" "),_c('span',{staticClass:"key-text"},[_vm._v(_vm._s(_vm.baselineName))]),_vm._v(" "),_c('svg',{staticClass:"key-square",attrs:{"preserveAspectRatio":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"7","cy":"7","r":"7","width":"14","height":"14","fill":_vm.colors.orange500}})]),_vm._v(" "),_c('span',{staticClass:"key-text"},[_vm._v(_vm._s(_vm.comparisonName))])]),_vm._v(" "),_vm._l((_vm.changedThemes),function(theme,index){return _c('div',{key:index + theme.name,staticClass:"theme-row"},[_c('div',{staticClass:"theme-name",class:{ 'clickable-theme': _vm.canClickTheme },on:{"click":function($event){return _vm.doNavigate({code: theme.code, title: theme.name},
                           theme.subtheme ? {code: theme.subthemeCode, title: theme.subtheme} : undefined)}}},[_c('span',[_vm._v(_vm._s(theme.name))])]),_vm._v(" "),_c('bar-difference-normalized',{attrs:{"baseline-width":theme.volumeBaselineNormalized,"comparison-width":theme.volumeComparisonNormalized,"baseline":theme.volumeBaseline,"comparison":theme.volumeComparison,"suffix":"%","dp":"0"}})],1)}),_vm._v(" "),(_vm.empty)?_c('div',{staticClass:"empty-change-list"},[_vm._v("\n      No changes found\n    ")]):_vm._e()],2),_vm._v(" "),(_vm.isSummaryEnabled)?_c('summary-component',{attrs:{"filters":_vm.filters,"theme":_vm.selectedTheme,"source":_vm.source,"can-navigate":_vm.canNavigate,"comparison-themes":_vm.comparisonThemes},on:{"doNavigate":function () { return _vm.doNavigate(
          _vm.selectedTheme.theme,
          _vm.selectedTheme.subtheme,
          _vm.selectedTheme.volumeBy,
          true
        ); }}}):_vm._e(),_vm._v(" "),(_vm.isCommentsEnabled)?_c('comment-component',{attrs:{"filter-string":_vm.filters ? _vm.filters.baseline : undefined,"theme":_vm.selectedTheme,"source":_vm.source,"can-navigate":_vm.canNavigate},on:{"doNavigate":function () { return _vm.doNavigate(
          _vm.selectedTheme.theme,
          _vm.selectedTheme.subtheme,
          _vm.selectedTheme.volumeBy,
          true
        ); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }