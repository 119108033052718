<template>
  <div
    :class="{ expanded: expanded, narrow: isNarrow, editedComment: isCommentEdited }"
    class="vertical-thread"
  >
    <div class="vertical-thread__bd">
      <div
        v-for="(conversation, index) in conversations"
        :key="`thread-block-${index}`"
        :style="{marginLeft: (conversation.depth * 10) + 'px'}"
      >
        <div v-if="conversation.collapsible && collapsedIndexes.includes(index) && !areSupportMessagesVisible">
          <el-button
            class="vertical-thread__hidden-message"
            type="text"
            @click="() => onExpandPart(index)"
          >
            {{ conversation.pairs.length }} message{{ conversation.pairs.length > 1? 's':'' }} hidden
          </el-button>
        </div>
        <div v-else>
          <vertical-comment-thread-part
            v-for="(threadParts, threadPartIndex) in conversation.pairs"
            :key="`thread-part-${threadPartIndex}`"
            :seen="hasPartBeenSeen(index, threadPartIndex)"
            :indexes="[index, threadPartIndex]"
            :intersection-observer="intersectionObserver"
            :are-themes-in-applying-state="areThemesInApplyingState"
            :comment-column-id="commentColumnId"
            :comment-id="commentId"
            :highlight-type="highlightType"
            :highlighted-locations="highlightedLocations[index][threadPartIndex]"
            :hover-on-themes="hoverOnThemes"
            :is-disabled="isDisabled"
            :is-narrow="isNarrow"
            :selected-phrase="selectedPhrase"
            :thread-parts="threadParts"
            @onTextSelectionStart="onTextSelectionStart"
            @onEnterBlock="onEnterBlock"
            @collapseParts="() => onCollapseParts(index)"
            @hoverBlock="block => hoverBlock(block, index, threadPartIndex)"
            @leaveBlock="leaveBlock"
            @onRemoveThemeFromPart="(theme, block) => removeTheme(theme, block, 'Popup')"
            @onSentimentChange="args => $emit('onSentimentChange', args)"
            @persistHoveredBlock="block => persistHoveredBlock(block, index, threadPartIndex)"
          />
        </div>
      </div>
      <add-theme-to-comment
        v-if="canSeeInlineThemesEditing"
        :selection-status="selectionStatus"
        :max-words="maxWords"
        :vertical-position="selectedPhrasePositionY"
        :horizontal-position="selectedPhrasePositionX"
        :pre-selected-phrase="selectedPhrase"
        :conversations="conversations"
        :comment-id="commentId"
        :are-themes-in-applying-state="areThemesInApplyingState"
      />
    </div>
    <comment-metadata-list
      v-if="expanded"
      :info="info"
      class="vertical-thread__metadata"
    />
    <el-button
      v-if="isNarrow && !alwaysExpanded && !expanded"
      class="vertical-thread__toggle"
      title="Toggle comment metadata"
      type="text"

      @click="expandMetadata()"
    >
      {{ expanded ? 'Hide details' : 'Show details' }}
    </el-button>
    <div
      v-if="expanded"
      class="vertical-thread__themes"
    >
      <div class="theme-list">
        <b>Themes</b>
        <CommentThemesList
          :items="getCommentThemeItems()"
          :are-themes-in-applying-state="areThemesInApplyingState"
          :has-sentiment="true"
          :can-manage-themes="canManageThemes"
          :has-remove-buttons="!isDisabled"
          :is-sentiment-enabled="analysisConfigStore.hasSentiment"
          @removeTheme="(theme) => removeTheme(theme, null, 'Metadata')"
          @mouseOverTheme="(theme) => isThemeRemoved(theme) ? null : hoverTheme(theme)"
          @mouseLeaveTheme="leaveTheme"
          @clickTheme="(theme) => $emit('onCommentThemeSelected', theme)"
        />
        <div
          v-for="(theme, index) in themesAdded()"
          :key="index + JSON.stringify(theme)"
          :class="{'themes-added': !doesThemeAlreadyExist(theme)}"
        >
          <span v-if="!doesThemeAlreadyExist(theme)">
            <font-awesome-icon
              icon="sparkles"
            />
            <span class="added-theme-title">{{ createThemeTitle(theme) }}</span>
          </span>
        </div>
        <add-theme
          v-if="canSeeInlineThemesEditing && !isDisabled"
          :are-themes-in-applying-state="areThemesInApplyingState"
          :conversations="conversations"
          :comment-id="commentId"
          class="no-export-output"
        />
      </div>
      <div class="tags-list">
        <b>Categories</b>
        <comment-categories
          :comment-column-id="commentColumnId"
          :comment-id="commentId"
          :categories="commentCategories"
          location="explore"
        />
      </div>
      <div class="tags-list">
        <b>Labels</b>
        <comment-tags
          v-if="!isDisabled"
          :comment-column-id="commentColumnId"
          :comment-id="commentId"
          :comment-tags="tags"
          location="explore"
          @updateTags="updateTags"
        />
      </div>
    </div>
    <el-tooltip
      v-if="isCommentEdited"
      class="item"
      popper-class="vertical-thread-edit-state-tooltip"
      effect="light"
      content="Changes pending"
      placement="top"
    >
      <font-awesome-icon
        v-if="isCommentEdited"
        icon="hourglass-half"
        class="vertical-comment__edit-state"
      />
    </el-tooltip>
    <el-button
      v-if="isNarrow && !alwaysExpanded && expanded"
      class="vertical-thread__toggle"
      title="Toggle comment metadata"
      type="text"
      @click="expandMetadata()"
    >
      {{ expanded ? 'Hide details' : 'Show details' }}
    </el-button>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import analytics from 'lib/analytics';

import VerticalCommentThreadPart from 'vue/components/Comments/VerticalCommentThreadPart.vue';
import CommentCategories from 'vue/components/Categories/CommentCategories.vue';
import CommentTags from 'vue/components/Tags/CommentTags.vue';
import AddThemeToComment from 'vue/components/Themes/AddThemeToComment.vue';
import segmentsToOrderedThemes from "lib/segments-to-ordered-themes";
import segmentsToCategories from "lib/segments-to-categories.ts";
import toCommentThemeItem from "vue/libs/to-comment-theme-item.ts";

import CommentThemesList from 'vue/components/Comments/CommentThemesList.vue';
import AddTheme from 'vue/components/Themes/AddTheme.vue';
import CommentMetadataList from 'vue/components/Comments/CommentMetadataList.vue';
import getCommentHighlightLocations from "lib/get-comment-highlight-locations.ts";
import {
  createThemeTitle,
  isNewlyAddedTheme,
  doesThemeAlreadyExist,
  isThemeRemoved,
  themesAdded,
} from "vue/libs/edited-comment-helpers";
import addThemeToSelectionMixin from './AddThemeToSelectionMixin.js';
import threadCommentMixin from './ThreadCommentMixin.js';

export default {
  name: 'VerticalCommentThread',
  components: {
    AddThemeToComment,
    CommentCategories,
    CommentTags,
    VerticalCommentThreadPart,
    CommentThemesList,
    CommentMetadataList,
    AddTheme,
  },
  mixins: [
    addThemeToSelectionMixin(),
    threadCommentMixin(),
  ],
  props: {
    alwaysExpanded: { default: false, type: Boolean },
    comment: { default: () => [], type: Array },
    commentColumnId: { type: Number, required: true },
    commentId: { type: String, required: true },
    commentTags: { default: () => [], type: Array },
    highlightType: { default: '', type: String },
    hoverOnThemes: { default: true, type: Boolean},
    info: { default: () => ({}), type: Object },
    intersectionObserver: { default: null, type: IntersectionObserver },
    seenMap: { default : null, type: [Object, null] },
    isCommentEdited: { default: false, type: Boolean },
    isDisabled: { default: false, type: Boolean },
    isNarrow: { default: false, type: Boolean },
    selectedThemeCodes: { default: () => ({}), type: Object },
  },
  data() {
    return {
      tags: this.commentTags,
      collapsedIndexes: [],
      expanded: !this.isNarrow || this.alwaysExpanded,
      hoveredLocation: null,
      hoveredThemeCodes: null,
      selectedSentimentTheme: undefined,
      showConversationAnalyticsDrawer: false,
    };
  },
  computed: {
    highlightedLocations() {

      const isHoveringOverAnyLocation = !!this.hoveredLocation;

      return this.conversations.map((conversation, index) => {

        return conversation.pairs.map((pairs, pairsIndex) => {

          const isHoveringOverThisLocation = isHoveringOverAnyLocation
            && this.hoveredLocation[1] === index
            && this.hoveredLocation[2] === pairsIndex;

          // Questionable
          if (!isHoveringOverThisLocation && isHoveringOverAnyLocation) {
            return [];
          }

          // All the segments in the conversation
          const segments = pairs.reduce(
            (result, pair) => [...result, ...pair.threadedComment.segments],
          []);

          const hoveredLocation = this.hoveredLocation ? this.hoveredLocation[0] : null;

          return getCommentHighlightLocations(
            segments,
            this.selectedThemeCodes,
            hoveredLocation,
            this.hoveredThemeCodes
          );

        });

      });

    },
      commentCategories() {
      return segmentsToCategories(this.segments);
    },
    orderedThemes() {

      const orderedThemes = segmentsToOrderedThemes(
        this.segments,
        this.selectedThemeCodes
      );

      return orderedThemes;

    },
    areSupportMessagesVisible() {
      return this.analysisToolsUIStore.areSupportMessagesVisible;
    },
  },
  watch: {
    selectionStatus(status) {
      if (status !== 'NONE') {
        analytics.track('Analysis: Select Comment', { 'Type': 'Conversation' });
      }
    }
  },
  methods: {
    createThemeTitle,
    doesThemeAlreadyExist(theme) {
      return doesThemeAlreadyExist(this.commentId, theme, this.orderedThemes);
    },
    getThemeLocations(theme) {

      return this.segments.reduce((result, segment) => {

        const index = segment.themes.findIndex(t => {
          return t.base === theme.base && t.sub === theme.sub;
        });

        const segmentHasTheme = index > -1;

        return segmentHasTheme ? [...result, segment.location] : result;

      }, []);
    },
    hasPartBeenSeen(threadIndex, threadPartIndex) {
      if (!this.seenMap) {
        return true;
      }
      return this.seenMap?.[threadIndex]?.[threadPartIndex] ?? false;
    },
    isThemeRemoved(theme) {
      return isThemeRemoved(
        this.commentId,
        theme,
        this.getThemeLocations(theme)
      );
    },
    themesAdded() {
      return themesAdded(this.commentId);
    },
    expandMetadata() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        analytics.track('Comments: Show metadata', {
          category: 'Comments',
          type: 'Thread'
        });
      }
    },
    selectTheme(theme) {
      this.$emit('onCommentThemeSelected', theme);
    },
    hoverTheme(themeCodes) {
      if (this.hoverOnThemes) {
        this.hoveredThemeCodes = { base: themeCodes.base, sub: themeCodes.sub };
      }
    },
    leaveTheme() {
      this.hoveredThemeCodes = null;
    },
    hoverBlock(block, index, threadPartIndex) {
      if (block.hasThemes) {
        this.hoveredLocation = [block.location, index, threadPartIndex];
      }
    },
    leaveBlock() {
      this.hoveredLocation = null;
    },
    persistHoveredBlock(block, index, threadPartIndex) {
      const hoveredLocation = [block.location, index, threadPartIndex];
      if (!isEqual(this.hoveredLocation, hoveredLocation)) {
        this.hoveredLocation = hoveredLocation
      }
    },
    onCollapseParts(index) {
      if (!this.collapsedIndexes.includes(index)) {
        this.collapsedIndexes.push(index);
      }
    },
    onExpandPart(index) {
      this.collapsedIndexes = this.collapsedIndexes.filter(i => i !== index);
    },
    updateTags(newTags) {
      this.tags.splice(0, this.tags.length, ...newTags);
    },
    getCommentThemeItems() {
      return this.orderedThemes.map(theme => toCommentThemeItem({
        theme,
        segments: this.segments,
        isNew: isNewlyAddedTheme(this.commentId, theme),
        isRemoved: this.isThemeRemoved(theme),
        hoveredLocation: this.hoveredLocation,
        hoveredThemeCodes: this.hoveredThemeCodes,
        selectedThemeCodes: this.selectedThemeCodes
      }))
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';

.vertical-thread {
  display: grid;
  grid: auto auto 1fr / 3fr 2fr;
  grid-template-areas:
    'bd metadata'
    'bd themes'
    'bd flag'
    'toggle edit-state';
  margin: 10px 18px;
  background: $--white;
  border-radius: $--border-radius-small;
  padding: 8px 0;

  &.narrow {
    grid: auto auto auto / 1fr 120px;
    &.expanded {
      grid-template-areas:
        'bd bd'
        'themes themes'
        'metadata metadata'
        'toggle edit-state';
    }
    grid-template-areas:
      'bd bd'
      'toggle edit-state';
  }

  &.narrow, &.expanded {
    .vertical-thread__toggle {
      width: fit-content;
      padding: 4px 6px;
      border: none;
      grid-area: toggle;
      border-radius: 12px;
      margin: 0px 9px 10px 20px;
      &:hover {
        background-color: $--primary-200;
      }
    }
    .vertical-comment__edit-state {
      border: none;
      grid-area: edit-state;
      margin: 2px 9px 10px 25px;
      color: $--orange-700;
    }
  }

   &.expanded {
    .vertical-thread__toggle {
      margin: 0px 15px 10px 20px;
    }
  }

  &.editedComment {
    background-color: $--orange-100;
  }

   &__hidden-message {
    padding-left: 15px;
    border-left: 3px dotted $--neutral-400;
    margin-left: 10px;
    border-radius: 0;
    margin-bottom: 10px;
    &:hover {
      border-left: 3px dotted mix(
        $--color-white,
        $--color-primary,
        $--button-hover-tint-percent
      );
    }
  }

  &__bd {
    font-weight: 400;
    font-size: 14px;
    grid-area: bd;
    line-height: 22px;
    margin: 0;
    padding: 8px 15px 0px 15px;
    position: relative;
    word-break: break-word;
    span {
      @extend .theme-highlight-base;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 0;
      white-space: pre-wrap;
    }
  }

  &__metadata {
    grid-area: metadata;
    padding: 8px 25px;
  }

  &__themes b {
    color: $--neutral-500;
    display: block;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  &__themes b {
    font-size: 12px;
    padding-bottom: 5px;
  }

  &__themes .tags-list b {
    padding-top: 8px;
  }

  &__themes {
    width: 100%;
  }

  &__themes .theme-list.collapsed {
    position: relative;
    height: 27px;
    overflow: hidden;
    display: flex;

    .theme {
      white-space: nowrap;
      line-height: 1.4em;
    }
  }

  &__themes .theme-list.collapsed::after {
    height: 27px;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
  }

  &__themes {
    grid-area: themes;
    padding: 8px 15px;

    .themes-added {
      padding: 6px 8px;
      margin: 0 5px 5px 0;
      color: $--orange-700;
      .added-theme-title {
        padding-left: 2px;
      }
    }

  }
}
</style>

<style lang="scss">
@import '../../styles/element-variables';
.el-tooltip__popper.vertical-thread-edit-state-tooltip {
  &.is-light[x-placement^=top] {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    line-height: normal;
    font-size: 14px;
    .popper__arrow {
      border-top-color: transparent;
    }
  }
}
</style>
