<template>
  <component
    :is="svgIcon"
    class="key-takeaways-icon"
  />
</template>

<script>

export default {
  name: 'KeyTakeawaysIcon',
  props: {
    icon: { type: String },
  },
  computed: {
    svgIcon() {
      return () => import(`images/vue-icons/${this.icon}.svg`);
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../styles/element-variables';

.key-takeaways-icon {
  min-width: 3.75rem;
  max-width: 3.75rem;
  path {
    &.st4 {
      fill: transparent;
    }
    &.st1 {
      fill: $--primary-300;
    }
  }
}
</style>
