import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import './survey-incomplete-action.scss';

interface SurveyImportingInitialDataActionProps {
  survey: LiteSurvey;
}

class SurveyImportingInitialDataAction extends React.Component<SurveyImportingInitialDataActionProps> {
  render() {

    return (
      <div className="survey-action__wrapper">
        <FontAwesomeIcon
                className="import-data__spinner-icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
                size="2x"
              />
        <p className="import-data__title">Initial data importing</p>
        <p className="import-data__note">Your data will be available soon.</p>
      </div>
    );
  }
}

export default SurveyImportingInitialDataAction;