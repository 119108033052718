import { uniqBy } from 'lodash';
import { getAnalysisToolsStore, getDashboardStore, getOrganizationStore, getOrganizationsStore, getUserStore } from 'stores/RootStore';

const MAX_ACTIVITY_ITEMS = 10;

enum RecentActivityType {
  Dashboard = 'dashboard',
  ThemesEditing = 'themes-editing',
  Analysis = 'analysis'
}

interface RecentActivity {
  type: RecentActivityType;
  description: string;
  // will be populated if not provided
  url?: string;
  date?: string;
}

class RecentActivityTracker {

    static recordActivity(activity: RecentActivity): void {
        const userStore = getUserStore();

        // prepare record
        if (!activity.date) {
          activity.date = new Date().toISOString();
        }
        if (!activity.url) {
          activity.url = window.location.href;
        }

        // add record to existing preferences
        const preferences = userStore.preferences ? userStore.preferences : {};
        let recentActivity = (preferences.recentActivity ? preferences.recentActivity : []) as RecentActivity[];
        recentActivity.unshift(activity);
        recentActivity = uniqBy(recentActivity, x => x.description);
        if (recentActivity.length > MAX_ACTIVITY_ITEMS) {
          recentActivity = recentActivity.slice(0, MAX_ACTIVITY_ITEMS);
        }
        preferences.recentActivity = recentActivity;

        // save preferences again
        userStore.setPreferences(preferences);
    }

    static dashboardExists(dashboardName: string) {
      const { dashboards } = getDashboardStore();

      return dashboards.some((dashboard) => dashboard.name === dashboardName);
    }

    static recentActivityUserCanAccess = (recentActivity: RecentActivity[]) => {
      const analysisToolsStore = getAnalysisToolsStore();
      const { orgId } = getOrganizationStore();
      const { organizations } = getOrganizationsStore();

      const orgRegex = /c\/([^\/?]+)/;

      return recentActivity.filter((activity) => {
        const activityUrlOrg = organizations.find((org) => {
          const activityOrg = activity.url?.match(orgRegex)?.[1];

          return org.displayId === activityOrg;
        });

        // If recent activity org does not exist in organizations store, don't display item
        if (!activityUrlOrg) {
          return false;
        }

        // If recent activity org is not current org but at least some permissions exist in org, display item
        if (activityUrlOrg.displayId !== orgId && activityUrlOrg.currentUserCan.length > 0) {
          return true;
        }

        // If recent activity org is current org and activity type is dashboard, display item
        if (activity.type === RecentActivityType.Dashboard && this.dashboardExists(activity.description)) {
          return true;
        }

        // If recent activity org is current org and activity type is analysis, display item if survey is active
        if (activity.type === RecentActivityType.Analysis) {
          const surveyUrlRegex = /(\/c\/[^?]+)/;
          const match = activity.url?.match(surveyUrlRegex);

          return match?.[1] ? analysisToolsStore.isActive(match[1]) : false;
        }

        return false;
      });
    };

    static getRecentActivity(): RecentActivity[] {
      const userStore = getUserStore();
      const preferences = userStore.preferences ? userStore.preferences : {};
      const recentActivity = (preferences.recentActivity ? preferences.recentActivity : []) as RecentActivity[];

      return this.recentActivityUserCanAccess(recentActivity);
    }
}

export { RecentActivityType, RecentActivity, RecentActivityTracker };
