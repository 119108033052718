import Updating from 'images/icons/updating.svg';
import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import './processing-comments.scss';

class ProcessingComments extends React.Component {
  render(): JSX.Element | null {
    const { children } = this.props;
    return (
      <Segment className="processing-comments">
          <Updating className="ui active centered inline" />
          <h3>Processing comments</h3>
          {children ?
            children
            :
            <p>You can manage themes when your data is ready</p>
          }
        </Segment>
    );
  }
}

export default ProcessingComments;
