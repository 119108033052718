import toFixed from "vue/libs/to-fixed";
import {
  Column,
  Columns,
  DefaultConfig,
  DimensionsByCategoryConfig,
  ExportAssistantTableData,
  ThemesByCategoryConfig
} from "./types";

const hasColumn = (selectedColumns: Column[], column: Column) => {
  return selectedColumns.some((col) => col === column);
}

const getDateHeaders = (columns: Column[], dateLabels: string[]) => {
  const showSentimentCounts = columns.includes(Columns.sentimentCounts);
  const headerColumnWidth = showSentimentCounts ? columns.length + 2 : columns.length;

  if (!dateLabels) {
    return [];
  }

  const spacedArrayLength = headerColumnWidth * dateLabels.length;
  const spacedArray = new Array(spacedArrayLength).fill('');

  dateLabels.map((dateLabel, index) => {
    spacedArray[index * headerColumnWidth] = dateLabel;
  });

  return spacedArray;
}

const getSelectedColumns = (selectedColumns: Column[]) => {
  const countSelected = hasColumn(selectedColumns, Columns.count);
  const volumeSelected = hasColumn(selectedColumns, Columns.volume);
  const impactSelected = hasColumn(selectedColumns, Columns.impact);
  const scoreSelected = hasColumn(selectedColumns, Columns.score);
  const sentimentSelected = hasColumn(selectedColumns, Columns.sentimentCounts);

  return {
    countSelected,
    volumeSelected,
    impactSelected,
    scoreSelected,
    sentimentSelected,
  };
}

const getSelectedColumnHeaders = (columns: Column[]) => {
  let header: string[] = [];

  if (hasColumn(columns, Columns.count)) {
    header.push('Count');
  }

  if (hasColumn(columns, Columns.volume)) {
    header.push('Volume');
  }

  if (hasColumn(columns, Columns.impact)) {
    header.push('Impact');
  }

  if (hasColumn(columns, Columns.score)) {
    header.push('Score');
  }

  if (hasColumn(columns, Columns.sentimentCounts)) {
    header.push('Negative', 'Neutral', 'Positive');
  }

  return header;
}

const getTableWidthEmptyArray = (columns: Column[], dateLabels: string[]) => {
  const showSentimentCounts = columns.includes(Columns.sentimentCounts);

  if (!dateLabels) {
    return [];
  }

  const headerColumnWidth = showSentimentCounts ? columns.length + 2 : columns.length;
  const spacedArrayLength = headerColumnWidth;

  return new Array(spacedArrayLength).fill('0');
}

const tableDataToArray = (columns: Column[], tableData: ExportAssistantTableData) => {
  const { countSelected, impactSelected, sentimentSelected, scoreSelected, volumeSelected } = getSelectedColumns(columns);

  let row: string[] = [];

  if (countSelected) {
    row.push(tableData?.count?.toString() || '0');
  }

  if (volumeSelected) {
    const roundedValue = toFixed(tableData?.volume, 1);
    const parsedValue = roundedValue.toString() || '0';

    row.push(`${ parsedValue }%`);
  }

  if (impactSelected) {
    const roundedValue = toFixed(tableData?.impact, 2);
    const parsedValue = roundedValue.toString() || '0';

    row.push(parsedValue);
  }

  if (scoreSelected) {
    const roundedValue = toFixed(tableData?.score, 2);
    const parsedValue = roundedValue.toString() || '0';

    row.push(parsedValue);
  }

  if (sentimentSelected) {
    const parsedNegative = tableData?.sentimentCounts?.negative.toString() || '0';
    const parsedNeutral = tableData?.sentimentCounts?.neutral.toString() || '0';
    const parsedPositive = tableData?.sentimentCounts?.positive.toString() || '0';

    row.push(parsedNegative);
    row.push(parsedNeutral);
    row.push(parsedPositive);
  }

  return row;
}

const tableToCSV = (columns: Column[], tableConfig: DefaultConfig) => {
  if (!tableConfig.tableData) {
    throw Error('No data');
  }

  const { columnOneTitle, filterTitle, surveyTitle } = tableConfig;

  const header = [columnOneTitle, ...getSelectedColumnHeaders(columns)];

  const rows = tableConfig.tableData.map((data) => [data.rowNameOne, ...tableDataToArray(columns, data)]);

  const csvData = [header, ...rows];

  const fileName = `${ surveyTitle } - ${ filterTitle }.csv`;

  return {
    csvData,
    fileName,
  };
}

const tableDimensionByCategoryToCSV = (columns: Column[], config: DimensionsByCategoryConfig) => {
  if (!config.tableData) {
    throw Error('No data');
  }

  const { columnOneTitle, categoryLabels, filterTitle, surveyTitle, tableData } = config;

  const rowHeaderSpacing = [''];
  const dateHeaders = [...rowHeaderSpacing, ...getDateHeaders(columns, categoryLabels)];
  const selectedColumnHeaders = getSelectedColumnHeaders(columns);

  const columnTableHeaders = categoryLabels.reduce(
    (result) => {
      return [...result, ...selectedColumnHeaders];
    },
    [columnOneTitle],
  );

  const rows = Object.entries(tableData).map(([dimensionKey, dimensionValue]) => {
    return dimensionValue.reduce(
      (result, dimension) => {
        return [...result, ...tableDataToArray(columns, dimension)];
      },
      [dimensionKey],
    );
  });

  const csvData = [dateHeaders, columnTableHeaders, ...rows];

  const fileName = `${ surveyTitle } - ${ filterTitle }.csv`;

  return {
    csvData,
    fileName,
  };
}

const tableThemesByCategoryToCSV = (columns: Column[], config: ThemesByCategoryConfig) => {
  if (!config.tableData) {
    throw Error('No data');
  }

  const { columnOneTitle, columnTwoTitle, categoryLabels, filterTitle, surveyTitle, tableData } = config;

  const rowHeaderSpacing = ['', ''];
  const dateHeaders = [...rowHeaderSpacing, ...getDateHeaders(columns, categoryLabels)];
  const selectedColumnHeaders = getSelectedColumnHeaders(columns);

  const columnTableHeaders = categoryLabels.reduce(
    (result) => {
      return [...result, ...selectedColumnHeaders];
    },
    [columnOneTitle, columnTwoTitle],
  );

  let tableRows: string[][] = [];

  Object.entries(tableData).map(([baseThemeKey, baseThemeValue]) => {
    let baseThemeRow = [baseThemeKey, ''];

    for (const dateLabel of categoryLabels) {
      const dateData = baseThemeValue[dateLabel];
      baseThemeRow = [...baseThemeRow, ...tableDataToArray(columns, dateData)];
    }

    tableRows.push(baseThemeRow);

    Object.entries(baseThemeValue.subthemes).map(([subThemeKey, subThemeValue]) => {
      let subThemeRow = [baseThemeKey, subThemeKey];

      for (const dateLabel of categoryLabels) {
        const dateData = subThemeValue?.[dateLabel];
        const rowValues = dateData ? tableDataToArray(columns, dateData) : getTableWidthEmptyArray(columns, categoryLabels);

        subThemeRow = [...subThemeRow, ...rowValues];
      }

      tableRows.push(subThemeRow);
    });
  });

  const csvData = [dateHeaders, columnTableHeaders, ...tableRows];

  const fileName = `${ surveyTitle } - ${ filterTitle }.csv`;

  return {
    csvData,
    fileName,
  };
}

const tableThemesToCSV = (columns: Column[], tableConfig: DefaultConfig) => {
  if (!tableConfig.tableData) {
    throw Error('No data');
  }

  const { columnOneTitle, columnTwoTitle, filterTitle, surveyTitle } = tableConfig;

  let header: string[] = [columnOneTitle];

  if (columnTwoTitle) {
    header.push(columnTwoTitle);
  }

  header = [...header, ...getSelectedColumnHeaders(columns)];

  const rows = tableConfig.tableData.map((data) => [
    data.rowNameOne,
    data?.rowNameTwo || '',
    ...tableDataToArray(columns, data),
  ]);

  const csvData = [header, ...rows];

  const fileName = `${ surveyTitle } - ${ filterTitle }.csv`;

  return {
    csvData,
    fileName,
  };
}

export { tableToCSV, tableThemesToCSV, tableDimensionByCategoryToCSV, tableThemesByCategoryToCSV }
