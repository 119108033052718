import safeDiv from 'lib/safe-div';
import { debounce } from 'lodash';
import * as React from 'react';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

interface ThemeEditorEventManagerProps {
  sessionStore: ThemeEditorSessionStoreInterface;
  surveyId: string;
}

export const INTERACTION_TIMEOUT = 180 * 1000;
export const VISIBILITY_TIMEOUT = 60 * 1000;

export const ThemeEditorEventManager: React.FC<ThemeEditorEventManagerProps> = ({
  sessionStore,
  surveyId
}) => {
  React.useEffect(() => {
    if (!surveyId) return;

    const sessionId = sessionStore.loadSession(surveyId);

    const makeActive = () => {
      const isActive = sessionStore.isActive();

      if (!isActive) {
        sessionStore.addEvent({
          type: 'Session',
          subType: 'Active',
          timestamp: Date.now(),
        });
      }
    };

    if (sessionId) {
      // Mark session active on mount
      makeActive();
    }

    let interactionInactivityTimer: number | null = null;
    let visibilityInactivityTimer: number | null = null;

    const clearTimers = () => {
      if (interactionInactivityTimer !== null) {
        window.clearTimeout(interactionInactivityTimer);
        interactionInactivityTimer = null;
      }
      if (visibilityInactivityTimer !== null) {
        window.clearTimeout(visibilityInactivityTimer);
        visibilityInactivityTimer = null;
      }
    };

    const handleInactivity = () => {
      if (!sessionId) {
        return;
      }
      const sessionDuration = sessionStore.getSessionDuration(sessionId);
      const activeSeconds = safeDiv(sessionDuration, 1000, 0);
      sessionStore.addEvent({
        type: 'Session',
        subType: 'Inactive',
        timestamp: Date.now(),
        data: activeSeconds,
      });
    };

    const handleActivity = () => {
      // Mark session inactive, given the user hasn't done anything for a while
      if (interactionInactivityTimer !== null) {
        window.clearTimeout(interactionInactivityTimer);
      }
      interactionInactivityTimer = window.setTimeout(handleInactivity, INTERACTION_TIMEOUT);

      makeActive();
    };

    const handleVisibilityChange = () => {
      // clear any existing visibility inactivity timer
      if (visibilityInactivityTimer !== null) {
        window.clearTimeout(visibilityInactivityTimer);
        visibilityInactivityTimer = null;
      }
      if (document.hidden) {
        visibilityInactivityTimer = window.setTimeout(handleInactivity, VISIBILITY_TIMEOUT);

        if (interactionInactivityTimer) {
          // avoid extra "inactive due to no interactions" events
          window.clearTimeout(interactionInactivityTimer);
          interactionInactivityTimer = null;
        }
      } else {
        handleActivity();
      }
    };

    const handleUserInput = debounce(() => {
      handleActivity();
    }, 1000);

    window.addEventListener('mousemove', handleUserInput);
    window.addEventListener('scroll', handleUserInput);
    window.addEventListener('keydown', handleUserInput);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    interactionInactivityTimer = window.setTimeout(handleInactivity, INTERACTION_TIMEOUT);

    return () => {
      handleUserInput.cancel(); // Prevent a debounced "active after inactive"
      handleInactivity(); // Mark session as inactive on unmount
      window.removeEventListener('mousemove', handleUserInput);
      window.removeEventListener('scroll', handleUserInput);
      window.removeEventListener('keydown', handleUserInput);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimers();
    };
  }, [sessionStore, surveyId]);

  return null; // This component doesn't render anything
};
