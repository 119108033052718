import { isNaN } from 'lodash';
import toFixed from 'vue/libs/to-fixed';

export default (n: number): string => {
  if (isNaN(n)) {
    return '';
  }
  const abs = Math.abs(n);
  if (abs < 1000) {
    return toFixed(n, 1);
  } else if (abs < 4950) {
    return toFixed(n / 1000, 1, 'k');
  } else {
    const thousands = Math.round(n / 1000);

    return `${thousands}k`;
  }
};
