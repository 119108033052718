import { AnalysisDateFilter, AnalysisFilterCut } from 'api/interfaces';
import classNames from 'classnames';
import DateRangePicker from 'components/DateRange/DateRangePicker';
import {
  DateRangePreset,
  applyDateRangePreset,
  dateRangePresets,
  fromServerDate,
  getAvailableDateRange,
  getDateRangePresetLabel,
  getDateRangePresetState
} from 'lib/filters/date-filter-helper';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';
import './date-filter.scss';

interface DateFilterProps {
  filter: AnalysisDateFilter;
  filterKey: AnalysisFilterKey;
  queryFilter?: QueryFilter;
  onSelection: (startDate: Date, endDate: Date) => void;
}

interface DateFilterState {
  previewStartDate: Date;
  previewEndDate: Date;
}

class DateFilter extends React.Component<DateFilterProps, DateFilterState> {
  state: DateFilterState = {
    previewStartDate: new Date(),
    previewEndDate: new Date(),
  };

  componentDidMount() {
    const { queryFilter } = this.props;
    // set the initial preview range
    if (queryFilter && !isEmpty(queryFilter.selected)) {
      const startDate = queryFilter.selected![0].startDate!;
      const endDate = queryFilter.selected![0].endDate!;
      this.updateSelectedDateRange(fromServerDate(startDate), fromServerDate(endDate), true);
    }
  }

  componentDidUpdate(prevProps: DateFilterProps) {
    const { queryFilter } = this.props;
    const { queryFilter: previousQueryFilter } = prevProps;

    // check whether the _actual_ start and end date have changed, and if they have update to preview this selection.
    if (queryFilter && !isEmpty(queryFilter.selected)) {

      const currentSelected: AnalysisFilterCut[] = queryFilter.selected || [];
      const previousSelected: AnalysisFilterCut[] = previousQueryFilter?.selected || [];

      // mobx insists we check array length before doing a lookup
      const currentSelection: AnalysisFilterCut | null = currentSelected.length > 0 ? currentSelected[0] : null;
      const previousSelection: AnalysisFilterCut | null = previousSelected.length > 0 ? previousSelected[0] : null;

      const startDate: string | undefined = currentSelection?.startDate;
      const endDate: string | undefined = currentSelection?.endDate;

      const rangesMatch: boolean = startDate === previousSelection?.startDate && endDate === previousSelection?.endDate;

      if (!rangesMatch && startDate && endDate) {
        this.updateSelectedDateRange(fromServerDate(startDate), fromServerDate(endDate), true);
      }
    }
  }

  updateSelectedDateRange = (startDate: Date, endDate: Date, isPreviewing: boolean) => {
    // update the preview range
    this.setState({ previewStartDate: startDate, previewEndDate: endDate });

    // if this should be applied, apply it to the saved filter
    if (!isPreviewing) {
      this.props.onSelection(startDate, endDate);
    }
  };

  onPresetClick = (preset: DateRangePreset, minDate?: Date, maxDate?: Date) => {
    const { startDate, endDate } = applyDateRangePreset(preset, minDate, maxDate);
    this.updateSelectedDateRange(startDate, endDate, false);
  };

  render() {
    const { filter, filterKey } = this.props;
    const { previewStartDate, previewEndDate } = this.state;
    const { minDate, maxDate } = getAvailableDateRange(filter);
    let aPresetIsAlreadyActive = false;

    return (
      <div className="date-filter-details">
        <div className="date-presets">
          {dateRangePresets.map((preset) => {
            let { isActive, isDisabled } = getDateRangePresetState(
              preset,
              previewStartDate,
              previewEndDate,
              minDate,
              maxDate
            );
            // we only want one preset to be active at a time
            if (!aPresetIsAlreadyActive && isActive) {
              aPresetIsAlreadyActive = true;
            } else {
              isActive = false;
            }
            return (
              <button
                key={preset}
                className={classNames('date-preset plain', { active: isActive })}
                disabled={isDisabled}
                onClick={() => this.onPresetClick(preset, minDate, maxDate)}
              >
                {getDateRangePresetLabel(preset)}
              </button>
            );
          })}
        </div>
        <DateRangePicker
          startDate={previewStartDate}
          endDate={previewEndDate}
          minDate={minDate}
          maxDate={maxDate}
          showComparisonStyling={filterKey === 'comparison'}
          onDateRangeChange={this.updateSelectedDateRange}
        />
      </div>
    );
  }
}

export default DateFilter;
