<template>
  <div
    aria-label="filter set"
    class="filter-set"
    :class="{ 'edit-mode': activeDashboardUIStore.isEditing }"
  >
    <div
      v-for="f in preparedFilters"
      :key="f.id"
      class="dashboard-filter"
    >
      <thematic-hierarchical-filter
        v-if="f.type == 'hierarchical'"
        :filter-data="f"
        :filter-key="filterKey"
        class="filter-item"
        @selectionChanged="selectionChanged"
      />
      <thematic-filter-dropdown-list
        v-else
        :filter-data="f"
        :filter-key="filterKey"
        :selected-filters="selectedFilters"
        class="filter-item"
        @selectionChanged="selectionChanged"
      />
      <div class="settings">
        <settings-menu
          v-if="activeDashboardUIStore.isEditing"
          :show-delete="true"
          @onDelete="deleteFilter(f.id)"
        />
      </div>
    </div>
    <el-button
      v-if="activeDashboardUIStore.isEditing"
      class="add-filter"
      @click="showAddFilterDialog"
    >
      <font-awesome-icon icon="plus" /> Add filter
    </el-button>
    <div
      v-if="loading && activeDashboardUIStore.isEditing"
      v-loading="true"
      class="dashboard-filter loading"
    />
    <add-filter
      v-if="isAddFilterDialogVisible"
      @onClose="hideAddFilterDialog"
    />
  </div>
</template>

<script>
import { get, map } from 'lodash';
import thematicData from 'vue/libs/thematicData';
import queryBuilder from 'vue/libs/queryBuilder';
import analytics from 'lib/analytics';
import { getActiveDashboardUIStore } from 'stores/RootStore';

import ThematicFilterDropdownList from 'vue/components/Filters/FilterDropdownList.vue';
import ThematicHierarchicalFilter from 'vue/components/Filters/FilterDropdownHierarchical.vue';
import SettingsMenu from 'vue/dashboards/DashboardEditable/SettingsMenu.vue';
import AddFilter from 'vue/dashboards/DashboardEditable/AddFilter.vue';

export default {
  name: 'Filters',
  components: {
    'thematic-filter-dropdown-list': ThematicFilterDropdownList,
    'thematic-hierarchical-filter': ThematicHierarchicalFilter,
    SettingsMenu,
    AddFilter,
  },
  // Note: basefilters are provided here so that the filters can respect it. It is not expected that filters will use/append this basefilter to their own selection
  props: {
    filters: {
      type: Array,
    },
    source: {
      type: String,
    },
    baseFilter: {
      type: String,
    },
    dashboardId: {
      type: String,
    }
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),

      filterKey: 'baseline',
      preparedFilters: [],
      selectedFilters: {},
      error: undefined,
      loading: false,
      isAddFilterDialogVisible: false,
    };
  },
  watch: {
    filters(newFilters, oldFilters) {
      // Only show loading screen if there is a new filter, otherwise we would show loadin state when deleting one
      const showLoading = newFilters.length > oldFilters.length;
      this.prepareFilters(showLoading);
    },
  },
  mounted() {
    this.prepareFilters();
  },
  methods: {
    prepareFilters: async function (showLoading) {
      const { dashboardId } = this;
      this.error = undefined;
      if (showLoading) {
        this.loading = true;
      }

      if (this.filters.length) {
        const filterString = get(this, 'baseFilter', '');
        try {
          const filters = await thematicData.getPreparedFilters(
            filterString,
            this.filters,
            { dataSource: this.source },
            dashboardId,
          );
          this.preparedFilters = map(filters, (filter) => {
            // force all these filters to be dropdowns
            filter.forceDropdown = true;
            return filter;
          });
        } catch (e) {
          this.error = e;
        }
      } else {
        this.preparedFilters = [];
      }
      this.loading = false;
    },
    selectionChanged(queryFilter, title) {
      this.selectedFilters[queryFilter.filterId] = queryFilter;
      const queryString = queryBuilder.build(this.selectedFilters);

      this.$emit('setFilterName', queryFilter.filterName, title);
      this.$emit('setQuery', queryString);

      // don't get into a loop & don't report events from URLs
      const filters = queryBuilder.getSelectedIds(this.selectedFilters);

      this.$emit('setFilters', filters);
      analytics.track('Dashboard: Filter Selection', {
        category: 'Dashboard',
        queryString,
      });
    },
    deleteFilter(filterId) {
      this.activeDashboardUIStore.deleteFilter(filterId);
    },
    showAddFilterDialog() {
      this.isAddFilterDialogVisible = true;
    },
    hideAddFilterDialog() {
      this.isAddFilterDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.filter-set {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $--sm) {
    flex-direction: column;
    justify-content: unset;
  }
  ::v-deep .filter-item {
    display: inline-block;

    .header {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 28px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 200px;
    }
  }
  .filter {
    min-width: 200px;
    max-width: 200px;
  }
  @media print {
    display: inline-block;
  }
  > div {
    @media print {
      display: inline-block;
      flex: none;
    }
  }
  .dashboard-filter {
    margin-right: 1.5em;
  }
  &.edit-mode {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin-right: 0;

    .dashboard-filter {
      position: relative;

      &.loading {
        min-height: 50px;
      }

      .settings {
        position: absolute;
        top: 5px;
        right: 10px;
        padding: 0;

        .settings-dropdown.el-dropdown {
          padding: 0;
        }
      }
    }
  }
}
</style>


<style lang="scss">
</style>
