<template>
  <div
    v-loading="loading"
    class="trending-themes"
  >
    <el-alert
      v-if="error"
      :title="`Something went wrong. Try reloading. ${error}`"
      type="error"
      show-icon
    />
    <div>
      Unusual movements in volume and score that exceed each theme's typical variance.
      <a
        href="https://help.getthematic.com/article/76-significant-changes-explanation"
        target="_blank"
        rel="noopener noreferrer"
      >
        How is this calculated?
      </a>
    </div>
    <div>
      <h4>
        Rare themes on the rise
        <thematic-tooltip>
          <p>
            Significant increases to themes averaging less than 1% of total volume
          </p>
        </thematic-tooltip>
      </h4>
      <div>
        <div
          v-if="emergingThemes && emergingThemesLeft.length > 0"
          class="trending-themes__details"
        >
          <div class="trending-themes__left">
            <volume-trend
              v-for="theme in emergingThemesLeft.slice(0, emergingExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
              :key="theme.theme + theme.subtheme"
              v-bind="theme"
              :context="trendingContext"
              :themes="themes"
              show-title-stat-as="count"
              @onThemeSelected="onThemeSelected"
              @onThemeInspected="onThemeInspected"
            />
          </div>
          <div class="trending-themes__right">
            <volume-trend
              v-for="theme in emergingThemesRight.slice(0, emergingExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
              :key="theme.name"
              v-bind="theme"
              :context="trendingContext"
              :themes="themes"
              show-title-stat-as="count"
              @onThemeSelected="onThemeSelected"
              @onThemeInspected="onThemeInspected"
            />
          </div>
        </div>
        <div
          v-else
          class="trending-themes__nothing"
        >
          <h4>All clear!</h4>
          No rare themes have increased significantly in this period
        </div>
        <button
          v-if="emergingThemesLeft.length > numThemesInCollapsedSection || emergingThemesRight.length > numThemesInCollapsedSection"
          class="trending-themes__expand"
          title="Toggle view more rare themes"
          @click="emergingExpanded = !emergingExpanded"
        >
          <font-awesome-icon :icon="emergingExpanded ? 'chevron-double-up': 'chevron-double-down'" />
        </button>
      </div>
      <h4>Volume</h4>
      <div>
        <div class="trending-themes__details">
          <div class="trending-themes__left">
            <font-awesome-icon icon="chart-line" /> Increases
            <div v-if="trendingThemesByVolumeUp && trendingThemesByVolumeUp.length > 0">
              <volume-trend
                v-for="theme in trendingThemesByVolumeUp.slice(0, trendingVolumeExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
                :key="theme.name"
                v-bind="theme"
                :context="trendingContext"
                @onThemeSelected="onThemeSelected"
                @onThemeInspected="onThemeInspected"
              />
            </div>
            <div
              v-else
              class="trending-themes__nothing"
            >
              <h4>All clear!</h4>
              No significant volume increases in this period
            </div>
          </div>
          <div class="trending-themes__right">
            <font-awesome-icon icon="chart-line-down" /> Decreases
            <div v-if="trendingThemesByVolumeDown && trendingThemesByVolumeDown.length > 0">
              <volume-trend
                v-for="theme in trendingThemesByVolumeDown.slice(0, trendingVolumeExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
                :key="theme.name"
                v-bind="theme"
                :context="trendingContext"
                @onThemeSelected="onThemeSelected"
                @onThemeInspected="onThemeInspected"
              />
            </div>
            <div
              v-else
              class="trending-themes__nothing"
            >
              <h4>All clear!</h4>
              No significant volume decreases in this period
            </div>
          </div>
        </div>
        <div class="trending-themes__expand">
          <button
            v-if="(trendingThemesByVolumeUp && trendingThemesByVolumeUp.length > numThemesInCollapsedSection) ||
              (trendingThemesByVolumeDown && trendingThemesByVolumeDown.length > numThemesInCollapsedSection)"

            title="Toggle view more volume trends"
            @click="trendingVolumeExpanded = !trendingVolumeExpanded"
          >
            <font-awesome-icon :icon="trendingVolumeExpanded ? 'chevron-double-up': 'chevron-double-down'" />
          </button>
        </div>
      </div>

      <h4>Score</h4>
      <div
        v-if="trendingThemesByScoreUp"
      >
        <div class="trending-themes__details">
          <div class="trending-themes__left">
            <font-awesome-icon icon="chart-line" /> Increases
            <div v-if="trendingThemesByScoreUp && trendingThemesByScoreUp.length > 0">
              <score-trend
                v-for="theme in trendingThemesByScoreUp.slice(0, trendingScoreExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
                :key="theme.name"
                v-bind="theme"
                :context="trendingContext"
                :score-name="selectedScoreExternal.name"
                @onThemeSelected="onThemeSelected"
                @onThemeInspected="onThemeInspected"
              />
            </div>
            <div
              v-else
              class="trending-themes__nothing"
            >
              <h4>All clear!</h4>
              No significant score increases in this period
            </div>
          </div>
          <div class="trending-themes__right">
            <font-awesome-icon icon="chart-line-down" /> Decreases
            <div v-if="trendingThemesByScoreDown && trendingThemesByScoreDown.length > 0">
              <score-trend
                v-for="theme in trendingThemesByScoreDown.slice(0, trendingScoreExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
                :key="theme.name"
                v-bind="theme"
                :score-name="selectedScoreExternal.name"
                :context="trendingContext"
                @onThemeSelected="onThemeSelected"
                @onThemeInspected="onThemeInspected"
              />
            </div>
            <div
              v-else
              class="trending-themes__nothing"
            >
              <h4>All clear!</h4>
              No significant score decreases in this period
            </div>
          </div>
        </div>
        <div class="trending-themes__expand">
          <button
            v-if="trendingThemesByScoreUp.length > numThemesInCollapsedSection || trendingThemesByScoreDown.length > numThemesInCollapsedSection"
            title="Toggle view more score trends"
            @click="trendingScoreExpanded = !trendingScoreExpanded"
          >
            <font-awesome-icon :icon="trendingScoreExpanded ? 'chevron-double-up': 'chevron-double-down'" />
          </button>
        </div>
      </div>
      <div v-else>
        No notable changes found
      </div>

      <!--h4>Significant impact changes</h4>
      There have been significant changes
        <thematic-tooltip>
          <p>
            More than two standard deviations and statistically significant
          </p>
        </thematic-tooltip> in how much these <br/>
        themes impact the overall score.
      <div
        v-if="trendingThemesByImpactUp"
      >
        <div class="trending-themes__details" >
          <div class="trending-themes_left">
            <impact-trend
              v-for="theme in trendingThemesByImpactUp.slice(0, trendingImpactExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
              :key="theme.name"
              v-bind="theme"
              :scoreName="selectedScoreExternal.name"
              :scoreOptions="selectedScoreExternal.score_options"
              :context="trendingContext"
              @onThemeSelected="onThemeSelected"
              @onThemeInspected="onThemeInspected"
            />
          </div>
          <div class="trending-themes__right">
            <impact-trend
              v-for="theme in trendingThemesByImpactDown.slice(0, trendingImpactExpanded? numThemesInExpandedSection: numThemesInCollapsedSection)"
              :key="theme.name"
              v-bind="theme"
              :scoreName="selectedScoreExternal.name"
              :scoreOptions="selectedScoreExternal.score_options"
              :context="trendingContext"
              @onThemeSelected="onThemeSelected"
              @onThemeInspected="onThemeInspected"
            />
          </div>
        </div>
        <div class="trending-themes__expand">
          <button
            v-if="trendingThemesByImpactUp.length > numThemesInCollapsedSection || trendingThemesByImpactDown.length > numThemesInCollapsedSection"
            title="Toggle view more impact trends"
            @click="trendingImpactExpanded = !trendingImpactExpanded"
          >
            <font-awesome-icon :icon="trendingImpactExpanded ? 'chevron-double-up': 'chevron-double-down'" />
          </button>
        </div>
      </div>
      <div v-else>
        No notable changes found
      </div-->
    </div>
  </div>
</template>

<script>
import analytics from 'lib/analytics';
import { filter, get } from 'lodash';
import { toRoute } from 'vue/libs/routeUtils';
import thematicData from 'vue/libs/thematicData';
import { watchAll } from 'vue/libs/vueExt';

import { setupRequestOptions } from 'vue/libs/setup-request-options';

import ThematicTooltip from 'vue/components/Tooltip/Tooltip.vue';
import ScoreTrend from 'vue/over-time/ScoreTrend.vue';
import VolumeTrend from 'vue/over-time/VolumeTrend.vue';
import configMixin from 'vue/tools/Utils/configMixin';

import { getAnalysisToolsUIStore, getInitConfigStore } from 'stores/RootStore';

export default {
  name: 'ThemeTrends',
  components: { VolumeTrend, ScoreTrend, ThematicTooltip },
  mixins: [configMixin],
  props: {
    selection: { default: undefined, type: Object },
    currentPeriod: { default: undefined, type: Object },
    selectedScoreExternal: { default: undefined, type: Object }
  },
  data() {
    return {
      numThemesInCollapsedSection: 4,
      numThemesInExpandedSection: 10,
      error: undefined,
      loading: true,
      themes: undefined,
      emergingThemes: undefined,
      trendingContext: undefined,
      trendingThemesByVolumeUp: undefined,
      trendingThemesByVolumeDown: undefined,
      trendingThemesByScoreUp: undefined,
      trendingThemesByScoreDown: undefined,
      trendingThemesByImpactUp: undefined,
      trendingThemesByImpactDown: undefined,
      // variables used to track the visual expanded state
      emergingExpanded: false,
      trendingVolumeExpanded: false,
      trendingScoreExpanded: false,
      trendingImpactExpanded: false,
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
    };
  },
  computed: {
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    themeFilterSet() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    emergingThemesLeft() {
      if (!this.emergingThemes) {
        return [];
      }
      return filter(this.emergingThemes.slice(0, 4), (value, index) => {
        return index % 2 === 0;
        });
    },
    emergingThemesRight() {
      if (!this.emergingThemes) {
        return [];
      }
      return filter(this.emergingThemes.slice(0, 4), (value, index) => {
        return index % 2 === 1;
        });
    },
    selectedSubtheme() {
      return get(this, 'themeFilterSet.subtheme.name', null);
    },
    selectedTheme() {
      return get(this, 'themeFilterSet.theme.name', null);
    },
    selectedThemeObject() {
      const { themes, selectedSubtheme, selectedTheme } = this;
      if (themes) {
        const theme = themes[selectedTheme];
        if (theme && theme.subthemes && selectedSubtheme) {
          return theme.subthemes[selectedSubtheme];
        } else {
          return theme;
        }
      } else {
        return undefined;
      }
    }
  },
  watch: {
    selectedScoreExternal() {
      this.fetchData();
    }
  },
  mounted() {
    watchAll(this, ['currentPeriod'], this.fetchData);
    if (this.currentPeriod) {
      this.fetchData();
    }
  },
  methods: {
    onThemeSelected(theme, subtheme) {
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
      // tell the parent so that comments can be set
      this.$emit('onThemeSelection', this.selectedThemeObject);
      analytics.track('Theme Trends: Selected Theme', { category: 'Overtime' });
    },
    onThemeInspected(theme, subtheme) {
      // Need to ensure the theme that was clicked is also selected. Otherwise we can have issues due to multiple being open
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
      this.$emit('onThemeSelection', this.selectedThemeObject);

      analytics.track('Theme Trends: Inspect Theme', { category: 'Overtime' });
      // reroute to explore tool
      this.$router.push(toRoute(this,''));
    },
    fetchData: async function() {
      const { selection, currentPeriod, commentColumns } = this;
      const filterQuery = get(selection, 'query');
      const currentPeriodRange = get(currentPeriod, 'period');
      const endDate = currentPeriodRange.split(':')[1];
      this.loading = true;
      this.error = undefined;
      let options =  setupRequestOptions(this.requestOptions, {
        scoreCommentsOnly: true,
        scoreColumn: this.scoreColumn,
        scoreType: this.scoreType,
        scoreOptions: this.scoreOptions
      });
      options = {...options, dateMaxPast: 6, dateResolution: this.initConfigStore.getChartDateResolution};

      this.scoreName = get(options, "scoreName", "Score");

      this.emergingThemes = [];
      this.trendingThemesByVolumeUp = [];
      this.trendingThemesByVolumeDown = [];
      this.trendingThemesByScoreUp = [];
      this.trendingThemesByScoreDown = [];

      try {
        const data = await thematicData.getThemeTrends(
          filterQuery,
          endDate,
          commentColumns,
          options
        );
        // flatten all these themes
        this.themes = data.mostRecent.themes;
        this.trendingContext = data.periods;

        const trendingThemesByVolume = data.volumeTrends;

        // an emerging theme is one which is small, is increasing and has deviated significantly
        // we also only count things that have less than 0.3% in the current period
        this.emergingThemes = data.emergingThemes;

        // split trending into up and down
        this.trendingThemesByVolumeUp = filter(trendingThemesByVolume, theme => {
          const history = theme.volumeTrend.history;
          const current = history[history.length - 1];
          return (
            current > theme.volumeTrend.avg
          );
        });

        this.trendingThemesByVolumeDown = filter(trendingThemesByVolume, theme => {
          const history = theme.volumeTrend.history;
          const current = history[history.length - 1];
          return (
            current < theme.volumeTrend.avg
          );
        });

        const trendingThemesByScore = data.scoreTrends;
        this.trendingThemesByScoreUp = filter(trendingThemesByScore, theme => {
          const history = theme.scoreTrend.history;
          const current = history[history.length - 1];
          return (
            current > theme.scoreTrend.avg
          );
        });

        this.trendingThemesByScoreDown = filter(trendingThemesByScore, theme => {
          const history = theme.scoreTrend.history;
          const current = history[history.length - 1];
          return (
            current < theme.scoreTrend.avg
          );
        });

        const trendingThemesByImpact = data.impactTrends;
        this.trendingThemesByImpactUp = filter(trendingThemesByImpact, theme => {
          const history = theme.impactTrend.history;
          const current = history[history.length - 1];
          return (
            current > theme.impactTrend.avg
          );
        });

        this.trendingThemesByImpactDown = filter(trendingThemesByImpact, theme => {
          const history = theme.impactTrend.history;
          const current = history[history.length - 1];
          return (
            current < theme.impactTrend.avg
          );
        });

      } catch (e) {
        this.error = e;
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<!-- CSS -->
<style lang="scss">
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../vue/styles/element-variables';
.trending-themes {
  padding-top: 25px;
  h4 {
    color: $--neutral-700;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    margin: 0;
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 10px;
    text-transform: uppercase;
    border-top: $--neutral-100 3px solid;
  }

  &__nothing {
    text-align: center;
    padding-bottom: 20px;
    h4 {
      text-align: center;
      color: $--neutral-700;
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
      margin: 0;
      margin-top: 15px;
      padding-top: 5px;
      padding-bottom: 10px;
      text-transform: none;
      border-top: none;
    }

  }

  &__details {
    display:grid;
    grid-template-columns: 100%;
    padding-right: 10px;
    grid-template-areas:
      "left"
      "right";
    transition: height 0.3s  ease-in-out;

    @media screen and (min-width: $--md) {
      grid-template-columns: 50% 10px 50%;
      grid-template-areas:
        "left . right .";
    }

  }


  &__left {
    grid-area: left;
  }
  &__right {
    grid-area: right;
  }
  &__expand {
    button {
      align-items: center;
      background: $--neutral-100;
      border-radius: $--border-radius-small;
      border: none;
      color: $--neutral-400;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-size: 12px;
      padding: 4px 0;
      transition: all ease-in-out 0.2s;
      width: 100%;
      &:hover {
        color: $--color-primary;
        background-color: $--primary-100;
      }
      &:active {
        color: $--primary-500;
      }
    }
  }
}
</style>
