import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisDateFilter, AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import classNames from 'classnames';
import DateFilter from 'components/Filters/DateFilter';
import FilterSearchInput from 'components/Filters/FilterSearchInput';
import FreeTextFilter from 'components/Filters/FreeTextFilter';
import ListFilter from 'components/Filters/ListFilter';
import ThemeFilter from 'components/Filters/ThemeFilter';
import countAnalysisFilters from 'lib/filters/count-analysis-filters';
import countThemes from 'lib/filters/count-themes';
import {
  getFilterSearchRegex,
  getFilterMatchingSearchText,
} from 'lib/filters/filter-search-helper';
import {
  ThemesObject,
  createThemeFilterOptions,
  getThemeMatchType
} from 'lib/filters/theme-filter-helper';
import { includes } from 'lodash';
import * as React from 'react';
import { TagInfo } from 'stores/TagStore';
import { AnalysisFilterKey, FilterMatchType, QueryFilter } from 'stores/types';
import FilterSearchResultList from './FilterSearchResultList';
import './filter-details.scss';
import { FilterOption, createHierarchicalFilterOptions } from 'lib/filters/hierarchical-filter-helper';
import HierarchicalFilterView from './HierarchicalFilterView';
import { createTagFilterOptions } from 'lib/filters/tag-filter-helper';
import { createCategoryFilterOptions } from 'lib/filters/category-filter-helper';
import { createSentimentFilterOptions } from 'lib/filters/sentiment-filter-helper';
import { getFreeTextMatchType } from 'lib/filters/free-text-filter-helper';
import { getCutsExcludingAll } from 'lib/filters/list-filter-helper';
import { getActiveCutIds } from 'stores/utils/query-filters';
import { FilterAction } from 'types/custom';
import { getFreeTextSearchTerms } from 'lib/filters/free-text-filter-helper';

interface FilterDetailsProps {
  savedFilters: React.ReactElement;
  filter: AnalysisFilter;
  filterKey: AnalysisFilterKey;
  isFeedbackTool: boolean;
  onClose: () => void;
  onSearchResultClick: (filter: AnalysisFilter, cut: AnalysisFilterCut) => void;
  onFilterChange: (action: FilterAction) => void;
  queryFilter?: QueryFilter;
  tags: TagInfo[];
  themes: ThemesObject;
}

interface FilterDetailsState {
  activeItemIndex: number;
  searchText: string;
  style: React.CSSProperties;
}

class FilterDetails extends React.Component<FilterDetailsProps, {}> {
  filterDetailsRef = React.createRef<HTMLDivElement>();

  state = {
    activeItemIndex: -1,
    searchText: '',
    style: {},
  } as FilterDetailsState;

  componentDidMount() {
    this.calculateFilterPopupWidth();
    document.addEventListener('keydown', this.onDocumentKeyDown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeyDown, true);
  }

  componentDidUpdate(prevProps: FilterDetailsProps) {
    const { filter } = this.props;
    if (prevProps.filter.id !== filter.id) {
      this.calculateFilterPopupWidth();
      this.setState({ searchText: '', activeItemIndex: -1 });
    }
  }

  onDocumentKeyDown = (e: KeyboardEvent) => {
    const { searchText, activeItemIndex } = this.state;
    const hasSearchText: boolean = searchText.trim().length > 0;

    if (!hasSearchText) {
      return;
    }

    const regex = getFilterSearchRegex(searchText);
    const category = getFilterMatchingSearchText(
      this.props.filter,
      regex,
      this.props.themes,
      this.props.tags,
      this.props.isFeedbackTool,
    );
    const numberOfResults = category.results?.length ?? 0;

    switch (e.key) {
      case 'Escape':
        this.setState({ activeItemIndex: -1, searchText: '' });
        e.stopPropagation();
        break;
      case 'Enter':
        // A result need to be active to be clicked
        if (activeItemIndex > -1) {
          const cut = category.results[activeItemIndex].source as AnalysisFilterCut;
          this.props.onSearchResultClick(this.props.filter, cut);
        }
        break;
      case 'ArrowUp':
        if (activeItemIndex > 0) {
          this.setState({
            activeItemIndex: activeItemIndex - 1
          });
        }
        e.preventDefault();
        break;
      case 'ArrowDown':
        if (activeItemIndex < numberOfResults - 1) {
          this.setState({
            activeItemIndex: activeItemIndex + 1
          });
        }
        e.preventDefault();
        break;
      default:
        break;
    }

  };

  renderFilterType() {
    const {
      filter,
      filterKey,
      isFeedbackTool,
      queryFilter,
      tags,
      themes
    } = this.props;

    switch (this.props.filter.type) {
      case 'date': {
        const dateFilter = filter as AnalysisDateFilter;

        return (
          <DateFilter
            filter={dateFilter}
            filterKey={filterKey}
            queryFilter={queryFilter}
            onSelection={(startDate: Date, endDate: Date) => {
              this.props.onFilterChange({
                type: 'date',
                startDate,
                endDate
              });
            }}
          />
        );
      }
      case 'hierarchical': {
        const hierarchyFilterOptions: FilterOption[] = filter.cuts
          ? createHierarchicalFilterOptions(filter.cuts, queryFilter)
          : [];

        return (
          <HierarchicalFilterView
            filterOptions={hierarchyFilterOptions}
            onFilterOptionsChanged={(options: FilterOption[]) => {
              this.props.onFilterChange({ type: 'hierarchical', options });
            }}
          />
        );
      }
      case 'tags': {
        const tagFilterOptions: FilterOption[] = createTagFilterOptions(tags, queryFilter);

        return (
          <HierarchicalFilterView
            filterOptions={tagFilterOptions}
            onFilterOptionsChanged={(options: FilterOption[]) => {
              this.props.onFilterChange({ type: 'tags', options });
            }}
          />
        );
      }
      case 'themes': {
        const themeFilterOptions: FilterOption[] = createThemeFilterOptions(
          themes,
          queryFilter,
          isFeedbackTool
        );

        const match = getThemeMatchType(queryFilter);

        return (
          <ThemeFilter
            match={match}
            filterOptions={themeFilterOptions}
            onChange={(options: FilterOption[], nextMatch: FilterMatchType) => {
              this.props.onFilterChange({ type: 'themes', options, match: nextMatch });
            }}
          />
        );
      }
      case 'categories': {
        const categoryFilterOptions: FilterOption[] = createCategoryFilterOptions(
          queryFilter
        );

        return (
          <HierarchicalFilterView
            filterOptions={categoryFilterOptions}
            onFilterOptionsChanged={(options: FilterOption[]) => {
              this.props.onFilterChange({ type: 'categories', options });
            }}
          />
        );
      }
      case 'sentiment': {
        const sentimentFilterOptions: FilterOption[] = createSentimentFilterOptions(
          queryFilter
        );

        return (
          <HierarchicalFilterView
            filterOptions={sentimentFilterOptions}
            onFilterOptionsChanged={(options: FilterOption[]) => {
              this.props.onFilterChange({ type: 'sentiment', options });
            }}
          />
        );
      }
      case 'freetext': {
        const freeTextMatch = getFreeTextMatchType(queryFilter);
        const terms = getFreeTextSearchTerms(queryFilter);

        const initialState = freeTextMatch === 'advanced'
          ? { inputText: terms[0], searches: [] }
          : { inputText: '', searches: terms };

        return (
          <FreeTextFilter
            match={freeTextMatch}
            initialState={initialState}
            onChange={(searches: string[], nextMatch: FilterMatchType) => {
              this.props.onFilterChange({ type: 'freetext', searches, match: nextMatch });
            }}
          />
        );
      }
      case 'saved-filters': {
        return this.props.savedFilters;
      }
      default: {
        const activeCutIds: string[] = queryFilter ? getActiveCutIds(queryFilter) : [];
        const cuts = getCutsExcludingAll(filter);

        return (
          <ListFilter
            activeCutIds={activeCutIds}
            cuts={cuts}
            filterName={filter.name}
            onChange={(cut: AnalysisFilterCut) => {
              this.props.onFilterChange({ type: 'cut', cut });
            }}
          />
        );
      }
    }
  }

  onSearchTextChange = (newText: string) => {
    this.setState({ searchText: newText, activeItemIndex: -1 });
  };

  calculateFilterPopupWidth = () => {
    // we are calculating dimensions of the popup here so it reposition itself if going outside window
    if (this.filterDetailsRef.current) {

      const childRect = this.filterDetailsRef.current.getBoundingClientRect();
      const parentRect = this.filterDetailsRef.current.parentElement?.parentElement?.getBoundingClientRect() as DOMRect;

      const canFitRightOfParent = childRect.width + parentRect.right < window.innerWidth;

      const vertical = window.innerHeight > childRect.bottom ? { top: 0 } : { bottom: 0 };

      const horizontal = canFitRightOfParent
        ? { left: parentRect.width }
        : { right: parentRect.right - window.innerWidth };

      const style = {
        ...vertical,
        ...horizontal
      };

      this.setState({ style });

    }
  };

  getUnfilteredItemCount(): number {

    const { filter } = this.props;

    switch (filter.type) {
      case 'themes':
        return countThemes(this.props.themes);
      case 'tags':
        return this.props.tags.length;
      default:
        return countAnalysisFilters(0, filter);
    }

  }

  render() {
    const { filter, isFeedbackTool, onClose, queryFilter, themes, tags } = this.props;
    const { style, searchText } = this.state;

    const hasSearchText: boolean = searchText.trim().length > 0;

    // We only enable searching for certain filter types
    const isFilterSearchable = !includes([
      'date',
      'freetext',
      'saved-filters'
    ], this.props.filter.type ?? '');
    const count = this.getUnfilteredItemCount();

    // We do not show a search input if there are only a few results
    const shouldShowSearchInput: boolean = isFilterSearchable && count > 5;

    const regex = getFilterSearchRegex(searchText);
    const category = getFilterMatchingSearchText(
      filter,
      regex,
      themes,
      tags,
      isFeedbackTool,
      queryFilter
    );

    return (
      <div className="filter-details-anchor" style={style}>
        <div ref={this.filterDetailsRef} className="filter-details">
          <header className="filter-details-header">
            <span className="filter-name">{filter.name}</span>
            <button className="close-button plain" onClick={onClose}
              aria-label="Close"
            >
              <FontAwesomeIcon icon="times" />
            </button>
          </header>
          {shouldShowSearchInput && (
            <FilterSearchInput value={searchText} onChange={this.onSearchTextChange} />
          )}
          <div
            className={classNames('filter-details-content', {
              'can-scroll': filter.type !== 'date' && filter.type !== 'freetext' && filter.type !== 'saved-filters',
            })}
          >
            {hasSearchText ? (
              <div className="filter-search-results">
                <FilterSearchResultList
                  activeItemIndex={this.state.activeItemIndex}
                  regex={regex}
                  indexedResults={category.results.map((result, index) => [result, index])}
                  onSearchResultClick={(cut) => this.props.onSearchResultClick(filter, cut)}
                />
              </div>
            ) : this.renderFilterType()}
          </div>
        </div>
      </div>
    );
  }
}

export default FilterDetails;
