import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import analytics from 'lib/analytics';
import { constructMixMaxLink } from 'lib/mixmax-link';
import * as React from 'react';
import { LinkButton } from 'components/Shared/LinkButton';
import './upgrade-needed-warning.scss';

interface UpgradeNeededWarningProps {
  warningMessage: string;
  location: string;
}

interface UpgradeNeededWarningState {
}

export default class UpgradeNeededWarning extends React.Component<
UpgradeNeededWarningProps,
UpgradeNeededWarningState> {

  onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { location } = this.props;

    const mixMaxLink = constructMixMaxLink('https://cal.mixmax.com/austinpresley/catchupcall');

    analytics.track(
      'Upgrade promo: contact us click',
      {
        category: 'Upgrade',
        location: location
      }
    );

    window.open(
      mixMaxLink,
      '_blank',
      'noopener,noreferrer'
    );
  }

  render() {
    const { warningMessage } = this.props;

    return (
      <div className="upgrade-needed-box">
        <div className="upgrade-needed-box__icon">
          <FontAwesomeIcon
            className="icon"
            icon="exclamation-triangle"
            fixedWidth={true}
          />
        </div>
        <div className="upgrade-needed-box__message">
          {warningMessage}
        </div>
        <div className="upgrade-needed-box__cta">
          <LinkButton onClick={this.onClick}>Contact us</LinkButton>
        </div>
      </div>
    );
  }
}
