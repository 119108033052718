import { extend } from 'lodash';
import config from 'runtime-config';
import { getNotificationStore, getUserStore } from 'stores/RootStore';
import fetch, { Fetchable } from './fetch';

function getUrl(path: string): string {
  if (/^https?:\/\//.test(path)) {
    return path;
  } else {
    return config.apiLocation + path;
  }
}

export default async <T>(
  path: string,
  opts: RequestInit & { isRaw?: boolean, isText?: boolean } = {}
): Promise<Fetchable<T>> => {

  const { isText } = opts;
  const token = localStorage.getItem('access_token');
  const Authorization = `Bearer ${ token || '' }`;

  const url = getUrl(path);
  const options = extend(
    { headers: { Authorization, 'Content-Type': 'application/json' } },
    opts
  );

  let response = await fetch<T>(url, options);
  const { ok, status } = response;
  if (ok) {
    if (!isText) {
      // we got a good result, unsuggest logout if it's set
      getUserStore().suggestLogout = false;
    }
  } else {

    const { errorData } = response;

    // 401 status means
    // - the backend didn't like that token, or it is expired
    if (status === 401) {
      getUserStore().suggestLogout = true;
    }
    // 403 status means
    // - the user is not authorized to access the resource (so there may be a bug showing them a button they shouldn't be able to press)
    else if (status === 403) {
      getNotificationStore().push({
        id: 'authorization-error',
        title: "Permissions issue",
        message: "Contact support@getthematic.com or your admin for assistance",
        variant: 'distinct'
      });
      if (window.rg4js) {
        window.rg4js('send', new Error(`Unauthorized access to ${ url }:  ${ errorData?.message || '' }`));
      }
    }
  }
  return response;
};
