
import { BarVistype, BarVisualizationSection, VisSeries } from 'api/interfaces';
import classNames from 'classnames';
import * as D3 from 'd3';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import colors from '../../../vue/styles/element-variables.scss';
import { formatCount } from '../utils/formatCount';

interface AxisBottomProps {
  scale: D3.ScaleBand<string>;
  height: number;
  zeroHeight: number;
  title: string;
  width: number;
}

interface AxisLeftProps {
  scale: D3.ScaleLinear<number, number, never>;
  height: number;
  width: number;
  title: string;
}

interface LegendProps {
  series: VisSeries[];
  height: number;
  width: number;
}

interface VisProps {
  seriesIndex: number;
  data: VisData[];
  height: number;
  scaleX: AxisBottomProps['scale'];
  scaleY: AxisLeftProps['scale'];
}

interface VisData {
  label: string;
  value: number;
  components?: {
    count: number;
    source: string;
    volume?: number;
  }[];
}

const LEGEND_PADDING_RIGHT = 15;

function divideStringIntoChunks(words: string[]) {
  const maxLength = 18;
  const chunks: string[] = [];

  let currentChunk = '';
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if ((currentChunk.length + word.length) <= maxLength) {
      if (currentChunk) {
        currentChunk += ' ';
      }
      currentChunk += word;
    } else {
      chunks.push(currentChunk);
      currentChunk = word;
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
}

function labelWrap(selection: any) {

  selection.each(function (this: SVGTextElement) {
    let text = D3.select(this);
    let words = text.text().split(/\s+/);
    text.text(null);
    const chunks: string[] = divideStringIntoChunks(words);

    for (let i = 0; i < words.length; i++) {
      let tspan = text.append('tspan').text(chunks[i]);
      if (i > 0) {
        tspan.attr('x', 0).attr('dy', '15');
      }
    }
  });
}

function getColor(index: number) {
  // we only support 5 partitions at the moment, hence 5 colors
  if (index === 1) {
    return colors.primary800;
  } else if (index === 2) {
    return colors.primary600;
  } else if (index === 3) {
    return colors.orange500;
  } else if (index === 4) {
    return colors.orange600;
  } else {
    return colors.primary400;
  }
}

function AxisBottom({ scale, height, zeroHeight, width, title }: AxisBottomProps) {
  const ref = React.useRef<SVGGElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      let axisLabelX = width / 2;
      let axisLabelY = 60 + height;

      D3.select(ref.current).call(
        D3.axisBottom(scale)
          .tickSize(0)
          .tickPadding(10)
          .tickFormat((t) => `"${ t }"`)
      )
        .selectAll('path')
        .attr('transform', `translate(0, ${ zeroHeight })`)
        .style('stroke', colors.neutral200)
        ;
      D3.select(ref.current).selectAll('g text')
        .style('fill', colors.neutral500)
        .attr('transform', `translate(0, ${ height })`)
        .call(labelWrap)
        ;
      D3.select(ref.current).append('g')
        .attr('transform', `translate(${ axisLabelX }, ${ axisLabelY })`)
        .attr('class', 'axis-label')
        .append('text')
        .attr('text-anchor', 'middle')
        .style('fill', colors.neutral500)
        .text(title)
        ;
    }
  }, [height, scale, title, width, zeroHeight]);

  return <g ref={ref} />;
}

function AxisLeft({ scale, height, width, title }: AxisLeftProps) {
  const ref = React.useRef<SVGGElement>(null);

  React.useEffect(() => {
    let axisLabelX = -60;
    let axisLabelY = height / 2;
    if (ref.current) {
      D3.select(ref.current).append('g')
        .attr('transform', 'translate(' + axisLabelX + ', ' + axisLabelY + ') rotate(-90)')
        .attr('class', 'axis-label')
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', 'currentColor')
        .text(title)
        .call(labelWrap)
        ;
      D3.select(ref.current).call(
        D3.axisLeft(scale)
          .tickSize(0)
          .tickPadding(10)
      )
        .selectAll('path')
        .style('stroke', 'transparent')
        ;
      D3.select(ref.current).selectAll('.tick-line')
        .data(scale.ticks().slice(1)) // removing the first tick because it's the same as the axis
        .enter()
        .append('line')
        .attr('class', 'tick-line')
        .attr('x1', 0)
        .attr('y1', (d) => scale(d))
        .attr('x2', width)
        .attr('y2', (d) => scale(d))
        .attr('stroke-width', 1)
        .attr('stroke', colors.neutral200)
        .attr('stroke-dasharray', '5, 5')
        ;
      D3.select(ref.current).selectAll('text')
        .style('fill', colors.neutral500)
        ;
    }
  }, [height, scale, title, width]);

  return <g ref={ref} />;
}

function getLegendTextWidth(text: string) {
  const fontSize = 14; // Font size of the text

  const svg = D3.select('svg'); // Select the SVG element

  const textElement = svg.append('text')
    .text(text)
    .attr('font-size', fontSize);

  return textElement.node().getBBox().width;
}

function getLegendText(width: number, text?: string): string {
  if (!text) {
    return '';
  }
  const textWidth = getLegendTextWidth(text);
  if (textWidth > width) {
    let truncatedText = '';
    let currentWidth = 0;

    for (let i = 0; i < text.length; i++) {
      const charWidth = getLegendTextWidth(text[i]);

      if ((currentWidth + charWidth) <= (width - LEGEND_PADDING_RIGHT)) {
        truncatedText += text[i];
        currentWidth += charWidth;
      } else {
        truncatedText += '...';
        break;
      }
    }
    return truncatedText;
  }

  return text;
}

function getLegendTransform(series: VisSeries[], width: number, height: number) {
  const axisHeight = 90; // comes from AxisBottom
  const seriesNames = series.map((s) => getLegendText(width, s.name));
  const legendWidth = Math.max(...(seriesNames).map((s) => getLegendTextWidth(s)));
  const transformX = Math.max((width / 2 - legendWidth / 2), 0);
  return `translate(${ transformX }, ${ height + axisHeight })`;
}

function Legend({ series, height, width }: LegendProps) {
  const ref = React.useRef<SVGGElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      const seriesNames = series.map((s) => getLegendText(width, s.name));

      D3.select(ref.current).selectAll('.legend-circle')
        .data(seriesNames)
        .enter()
        .append('circle')
        .attr('class', 'legend-circle')
        .attr('cx', -14)
        .attr('cy', -5)
        .attr('r', 7)
        .attr('transform', function (d: string, i: number) {
          const x = Math.floor(i / 10) * 150; // column width
          const y = (i % 10) * 20; // row height
          return 'translate(' + x + ', ' + y + ')';
        })
        .style('fill', function (_: string, i: number) { return getColor(i); });

      D3.select(ref.current).selectAll('.legend-text')
        .data(seriesNames)
        .enter()
        .append('text')
        .attr('class', 'legend-text')
        .attr('transform', function (d: string, i: number) {
          const x = Math.floor(i / 10) * 150; // column width
          const y = (i % 10) * 20; // row height
          return 'translate(' + x + ', ' + y + ')';
        })
        .text(function (d: string) { return d; })
        .style('fill', colors.neutral500);
    }
  }, [series, width]);

  const legendTransform = getLegendTransform(series, width, height);
  return <g ref={ref} transform={legendTransform} />;
}
function Bars({ data, scaleX, scaleY, seriesIndex }: VisProps) {
  return (
    <>
      {data.map(({ value, label, components }) => {
        const color = getColor(seriesIndex);

        function getPopupContent() {
          return (components ? (
            <ul>
              {components.map((component, i) => {
                const classes = classNames('source');
                return (
                  <li key={`${ component.source }-${ i }`}>
                    <span className={classes}>
                      <h3 style={{ color }}>&#8226;</h3>
                      {component.source}
                    </span>
                    <span>
                      {component.volume && <span className="volume">{(component.volume).toFixed(2)}%</span>}
                      <span className="count">{formatCount(component.count)}</span>
                    </span>
                  </li>
                );
              })}
            </ul>) : <span className="count">{formatCount(value)}</span>
          );
        }
        const zero = scaleY(0);
        const y = scaleY(value);
        const hValue = Math.abs(y - zero);
        return (
          <Popup
            key={`bar-${ label }`}
            className="answers__visualization-chart-popup"
            trigger={
              <g><rect
                x={scaleX(label)}
                y={Math.min(y, zero)}
                width={scaleX.bandwidth()}
                height={hValue}
                fill={color}
                rx="8"
              />
                <rect
                  // adding a small rectangle near the bottom of the bar to make it not rounded
                  x={scaleX(label)}
                  y={y < zero ? zero - 8 : zero}
                  width={scaleX.bandwidth()}
                  height={Math.min(8, hValue)}
                  fill={color}
                />
              </g>
            }
            content={getPopupContent()}
            position="right center"
          />
        );
      })}
    </>
  );
}

function Lines({ data, scaleX, scaleY, seriesIndex }: VisProps) {
  return (
    <>
      {data.map(({ value, label }, index) => {
        if (index > 0) {
          const prevValue = data[index - 1].value;
          const prevLabel = data[index - 1].label;
          return (<line
            key={`line-${ label }`}
            x1={scaleX(prevLabel)}
            y1={scaleY(prevValue)}
            x2={scaleX(label)}
            y2={scaleY(value)}
            stroke={getColor(seriesIndex)}
          />);
        }
        return null;
      })}
    </>
  );
}

interface VisualizationBarChartProps {
  content: BarVisualizationSection;
}

export class VisualizationBarChart extends React.Component<
  VisualizationBarChartProps
> {

  getMaxValue(series: VisSeries[]) {
    // returns the maximum value OR zero if they are all negative
    return Math.max(0, ...series.map((s) => Math.max(...s.values)));
  }

  getMinValue(series: VisSeries[]) {
    // returns the minimum value OR zero if they are all positive
    return Math.min(0, ...series.map((s) => Math.min(...s.values)));
  }

  renderData(
    visType: BarVistype,
    labels: string[],
    series: VisSeries[],
    height: number,
    scaleX: AxisBottomProps['scale'],
    scaleY: AxisLeftProps['scale']) {
    const seriesOut = series.map((s, seriesIndex) => {
      const barData = labels.map((label, index) => ({
        label,
        value: s.values[index],
        components: s.components ? s.components[index] : []
      }));
      const offset = scaleX.step() / (series.length + 1) * (seriesIndex + 1) - 0.5 * scaleX.step();
      return <React.Fragment key={seriesIndex}>
        {visType === 'bar' && <g transform={`translate(${ offset }, 0)`}>
          <Bars
            data={barData}
            height={height}
            scaleX={scaleX}
            scaleY={scaleY}
            seriesIndex={seriesIndex} />
        </g>}
        {visType === 'line' && <Lines
          data={barData}
          height={height}
          scaleX={scaleX}
          scaleY={scaleY}
          seriesIndex={seriesIndex} />}
      </React.Fragment>;
    });
    return <>{seriesOut}</>;
  }

  render() {
    const { content } = this.props;
    const series: VisSeries[] = content.data.series || [content.data];
    const labels = content.data.labels;

    const hasLegend = series.length > 1;
    const legendHeight = (hasLegend ? 50 : 0);
    const margin = { top: 20, right: 0, bottom: 80, left: 70 };
    const width = 750 - margin.left - margin.right;
    const height = 370 - margin.top - margin.bottom - legendHeight;

    // padding must take into consideration how many bands there will be
    const padding = 0.5 + (0.5 - 0.5 / series.length);
    const align = 0.5 / series.length;

    const scaleX = D3.scaleBand()
      .domain(labels)
      .range([0, width])
      .padding(padding)
      .align(align);
    const scaleY = D3.scaleLinear()
      .domain([this.getMinValue(series), this.getMaxValue(series)])
      .range([height, 0]);

    return (
      <section className="answers__visualization-chart">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom + legendHeight}
        >
          <g transform={`translate(${ margin.left }, ${ margin.top })`}>
            <AxisBottom
              scale={scaleX}
              height={height}
              zeroHeight={scaleY(0)}
              title={content.data.xLabel}
              width={width} />
            <AxisLeft
              scale={scaleY}
              height={height}
              width={width}
              title={content.data.yLabel}
            />
            {this.renderData(content.visType, labels, series, height, scaleX, scaleY)}
            {hasLegend &&
              <Legend series={series}
                height={height}
                width={width}
              />}
          </g>
        </svg>
      </section>
    );

  }
}
