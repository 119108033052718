import Auth from 'Auth/Auth';
import LoginScreen from 'components/LoginScreen/LoginScreen';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';

interface AuthenticatedRouteStoreProps {
  userStore?: UserStoreInterface;
}

interface AuthenticatedRoutePropsInterface extends RouteProps, AuthenticatedRouteStoreProps {
  fetching?: boolean;
}

@inject('userStore')
@observer
class AuthenticatedRoute extends React.Component<AuthenticatedRoutePropsInterface> {
  render() {
    const {
      component: Component,
      exact,
      path
    } = this.props;
    const { userStore } = this.props;
    const { fatalLoginError, fetchingCurrentUser: fetching, flagUser } = userStore!;

    const isAuthenticated = Auth.isAuthenticated() && flagUser;

    return (
      <Route
        exact={exact}
        path={path}
        render={props => {
          if (!fetching && isAuthenticated && Component) {
            return <Component {...props} />;
          } else {
            return (
              <>
                <Dimmer active={fetching} inverted={true}>
                  <Loader size="large" content="Checking…" />
                </Dimmer>
                {!fetching && <LoginScreen fatalLoginError={fatalLoginError} />}
              </>
            );
          }
        }}
      />
    );
  }
}

export default AuthenticatedRoute;
