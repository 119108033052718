import * as React from 'react';
import './segmented-bar.scss';
import { Tooltip } from 'components/Shared/Tooltip';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';

type Segment = {
  label: string;
  count: number;
  color: string;
  colorEnd: string;
};

type SegmentRef = {
  segment: HTMLDivElement | null;
  label: HTMLDivElement | null;
  percentage: HTMLSpanElement | null;
};

type SegmentedBarProps = {
  max: number;
  min: number;
  score: number;
  segments: Segment[];
  large?: boolean;
  showCommentCount?: boolean;
};

const SegmentedBar = withCamelCaseProps(({
  max = 0,
  min = 0,
  score = 0,
  segments = [],
  large = false,
  showCommentCount = true
}: SegmentedBarProps) => {
  const [barRef, dimensions] = useResizeObserver<HTMLDivElement>();
  const segmentRefs = React.useRef<SegmentRef[]>([]);
  const [columns, setColumns] = React.useState<string>('');
  const [areas, setAreas] = React.useState<string>('');
  const [hiddenElements, setHiddenElements] = React.useState<{[key: number]: {label: boolean, percentage: boolean}}>({});

  const sum = segments.reduce((acc, segment) => acc + segment.count, 0);
  const range = max - min;

  React.useEffect(() => {
    const calculateColumns = () => {
      const barWidth = 3;
      const widths = segments.map(segment => {
        if (segment.count === 0) return '0';
        return `${((barWidth * segment.count) / sum).toFixed(3)}fr`;
      });

      if (range === 0) {
        widths.unshift('0');
        widths.push('0');
      } else {
        const normalizedOffset = Math.abs((score - min) / range);
        const offsetWidth = 1;
        const minOffset = Math.min(offsetWidth, normalizedOffset);
        const maxOffset = Math.max(0, offsetWidth - minOffset);

        widths.unshift(`${minOffset.toFixed(3)}fr`);
        widths.push(`${maxOffset.toFixed(3)}fr`);
      }

      setColumns(widths.join(' '));
    };

    const calculateAreas = () => {
      const areaNames = segments.map((_, index) => `segment-${index + 1}`);
      areaNames.unshift('neg-space');
      areaNames.push('pos-space');
      setAreas(`"${areaNames.join(' ')}"`);
    };

    calculateColumns();
    calculateAreas();
  }, [segments, sum, range, score, min]);

  React.useEffect(() => {
    if (barRef.current) {
      barRef.current.style.gridTemplateColumns = columns;
      barRef.current.style.gridTemplateAreas = areas;
    }
  }, [columns, areas, barRef]);

  const checkWidths = React.useCallback(() => {
    const newHiddenElements: {[key: number]: {label: boolean, percentage: boolean}} = {};

    segmentRefs.current.forEach((refs, index) => {
      if (refs.segment && refs.label && refs.percentage) {
        const segmentWidth = refs.segment.getBoundingClientRect().width;
        const labelWidth = refs.label.getBoundingClientRect().width;
        const percentageWidth = refs.percentage.getBoundingClientRect().width;

        newHiddenElements[index] = {
          label: labelWidth >= segmentWidth,
          percentage: percentageWidth >= segmentWidth
        };
      }
    });

    setHiddenElements(newHiddenElements);
  }, []);

  React.useEffect(() => {
    checkWidths();
  }, [dimensions, checkWidths]);

  const percentage = (segment: Segment) => {
    if (sum === 0) {
      return '0%';
    }
    return `${((100.0 * segment.count) / sum).toFixed(1)}%`;
  };

  return (
    <div
      ref={barRef}
      className={`segmented-bar ${large ? 'segmented-bar--large' : ''}`}
    >
      <div />
      {segments.map((segment, index) => (
        <Tooltip
          key={index}
          content={
            <>
              {segment.label}
              <br />
              {showCommentCount && (
                <div className="comment-count">
                  {segment.count} comment{segment.count !== 1 && 's'},
                </div>
              )}
              {percentage(segment)}
            </>
          }
          position='top center'
          trigger={
            <div
              ref={el => {
                if (!segmentRefs.current[index]) {
                  segmentRefs.current[index] = { segment: null, label: null, percentage: null };
                }
                segmentRefs.current[index].segment = el;
              }}
              className="segmented-bar__segment"
              data-testid="segment"
              style={{
                background: `linear-gradient(to right, ${segment.color}, ${segment.colorEnd})`
              }}
            >
              <div
                ref={el => {
                  if (segmentRefs.current[index]) {
                    segmentRefs.current[index].label = el;
                  }
                }}
                className={`segmented-bar__label ${hiddenElements[index]?.label ? 'segmented-bar__label--hidden' : ''}`}
              >
                {segment.label}
              </div>
              <span
                ref={el => {
                  if (segmentRefs.current[index]) {
                    segmentRefs.current[index].percentage = el;
                  }
                }}
                className={`segmented-bar__percentage ${hiddenElements[index]?.percentage ? 'segmented-bar__percentage--hidden' : ''}`}
              >
                {percentage(segment)}
              </span>
            </div>
          }
          mouseEnterDelay={300}
          on={'hover'}
          inverted={true}
        />
      ))}
      <div />
    </div>
  );
});

export { SegmentedBar };
