<template>
  <div>
    <h4>Please select a visualization</h4>
  </div>
</template>

<script>
export default {
  name: 'SelectVisualization',
  computed: {}
};
</script>
