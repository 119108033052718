export function getScoreChange (currentScore: number | undefined, previousScore: number | undefined) {
  if (currentScore && previousScore) {
    return currentScore - previousScore;
  }
  return undefined;
}

export function getScoreChangeIndicator (scoreChange: number) {
  if (scoreChange) {
    if (scoreChange >= 0.1) {
      return `arrow-alt-up`;
    } else if (scoreChange <= -0.1) {
      return `arrow-alt-down`;
    }
  }
  return undefined;
}