import { FeatureFlagManager } from 'lib/feature-flag';
import { matchPath } from 'react-router';
import { getOrganizationsStore, getOrganizationStore, getUserStore } from 'stores/RootStore';
import Auth from '../Auth/Auth';
import history from './history';

const fetchPageResources = async (): Promise<void> => {

  const hasAccessToken:boolean = Auth.hasToken();
  const isTokenFresh:boolean = Auth.isAuthenticated();

  if (!hasAccessToken || !isTokenFresh) {
    return undefined;
  }

  const promises: Promise<unknown>[] = [
    Auth.fetchProfile(),
    getUserStore().getFlagJson(),
    FeatureFlagManager.prepareDevFlags(),
    getOrganizationsStore().fetchOrganizations()
  ];

  const orgMatch = matchPath<{ orgId: string }>(history.location.pathname, {
    path: '/c/:orgId',
    exact: false
  });

  if (orgMatch) {
    promises.push(getOrganizationStore().setOrg(orgMatch.params.orgId));
  }

  await Promise.all(promises);

};

export default fetchPageResources;
