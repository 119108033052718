import { debounce } from 'lodash';
import { removeBoundaryPeriodsAndCommas } from 'lib/string-helper';

const MAX_SELECTED_WORDS = 6;

function expandRangeToWord(range) {
  // this function is based on https://stackoverflow.com/questions/7809319/get-selected-text-expanded-to-whole-words
  if (range.collapsed) {
    return;
  }

  while (range.startOffset > 0 && range.toString()[0].match(/\w/)) {
    range.setStart(range.startContainer, range.startOffset - 1);
  }

  while (range.endOffset < range.endContainer.length && range.toString()[range.toString().length - 1].match(/\w/)) {
    range.setEnd(range.endContainer, range.endOffset + 1);
  }
}

export default function () {
  return {
    mounted() {
      document.addEventListener('selectionchange', this.onSelectionChange);
      document.addEventListener('mouseup', this.onTextSelectionEnd);
    },
    beforeDestroy() {
      document.removeEventListener('selectionchange', this.onSelectionChange);
      document.removeEventListener('mouseup', this.onTextSelectionEnd);
    },
    created() {

      // We want to be able to clear selection status when the user
      // clicks anywhere, causing the selected text to disappear.

        // Selection change is a noisy event; we debounce to reduce work.
      this.onSelectionChange = debounce(() => {

        // Given there are multiple instances of this mixin, we stop
        // handling the callback for all the irrelevant instances.
        if (this.selectionStatus === 'NONE') {
          return;
        }

        if (window.getSelection().toString() === '') {
          this.selectionStatus = 'NONE';
        }

      }, 80);
    },
    data() {
      return {
        maxWords: MAX_SELECTED_WORDS,
        lastEnteredBlock: null,
        isSelectionInProgress: false,
        selectionStatus: 'NONE', // 'VALID' | 'INVALID'
        selectedPhrase: '',
        selectedPhrasePositionY: 0,
        selectedPhrasePositionX: 0
      };
    },
    methods: {
      updateSelection() {
        const selection = window.getSelection();

        if (selection.type === 'None' || selection.toString().trim() === '') {
          this.selectedPhrase = '';
          this.selectionStatus = 'NONE';
          return;
        }

        const range = selection.getRangeAt(0);
        expandRangeToWord(range);

        const rangeRect = range.getBoundingClientRect()
        const parentRect = range.startContainer.parentElement.offsetParent.getBoundingClientRect();

        // The user can drag a selection range off the bottom edge of the intended selectable region.
        const rangeGoesBelowParent = rangeRect.bottom > parentRect.bottom;

        const x = rangeRect.left - parentRect.left + (rangeRect.width / 2);

        // The magic numbers are for fine tuning of the position
        const y = rangeGoesBelowParent
          ? parentRect.height - 10
          : rangeRect.top - parentRect.top + rangeRect.height - 10;

        this.selectedPhrasePositionY = y;
        this.selectedPhrasePositionX = x;

        const selectedPhrase = removeBoundaryPeriodsAndCommas(range.toString().trim());
        this.selectedPhrase = selectedPhrase;

        if (!this.lastEnteredBlock) {
          return;
        }

        // The selection could be dragged across multiple segments, or even
        // triple clicked to select all segments.
        const isSelectionWithinOneSegment = this.lastEnteredBlock.content.includes(selectedPhrase);

        if (!isSelectionWithinOneSegment) {
          this.selectionStatus = 'INVALID_CROSSES_BOUNDARY';
          return;
        }

        const selectedWords = selectedPhrase.split(/\s+/).filter(word => word.trim().length > 0);

        if (selectedWords.length > MAX_SELECTED_WORDS) {
          this.selectionStatus = 'INVALID_TOO_MANY_WORDS';
          return;
        }

        this.selectionStatus = 'VALID';

      },

      onEnterBlock(block) {
        this.lastEnteredBlock = block;
      },

      onTextSelectionStart() {
        this.isSelectionInProgress = true;
        this.selectedPhrase = '';
        this.selectionStatus = 'NONE'
      },
      async onTextSelectionEnd() {

        if (!this.isSelectionInProgress) {
          return;
        }

        // This method is triggered by a mouseevent, ideally the release of a mouse
        // that has been selecting some text. It seems that the mouseevent fires before
        // the window knows the selection has changed, so we add an artificial delay to
        // allow the selection to change first.
        await new Promise((res) => setTimeout(res, 100));

        this.isSelectionInProgress = false;

        this.updateSelection();
      }
    }
  };
}
