import { ColumnType } from 'api/enums';
import { ColumnInfo } from 'stores/ui/ConfigureDataUIStore';

export function mapColumnType (type: string) {
  if (type === ColumnType.DATE) {
    return ColumnType.DATE;
  } else if (type === ColumnType.NUMBER) {
    return ColumnType.NUMBER;
  } else if (type === ColumnType.TAGS) {
    return ColumnType.TAGS;
  } else {
    return ColumnType.TEXT;
  }
}

export function isCommentColumn (column: ColumnInfo, commentColumns: number | number [] | undefined) {
  if (!commentColumns) {
    return false;
  }
  const type = typeof commentColumns;
  if ((type === 'number' && commentColumns === column.index) ||
    (type === 'object' && (commentColumns as number[]).includes(column.index))) {
    return true;
  }
  return false;
}