import * as React from 'react';
import { Summary, SummarySegment } from 'types/custom';
import { isSpecialTheme } from 'lib/special-theme-predicates';
import "./conversation-summary.scss";
import classNames from 'classnames';
import { Tooltip } from 'components/Shared/Tooltip';
import { CopyToClipboardButton } from 'components/CopyToClipboard/CopyToClipboardButton';
import { isContentBlock, processSummary } from './utils/processSummary';
import { FeatureFeedbackTextConfig } from 'components/FeatureFeedback/types';
import FeatureFeedbackByThumbs from 'components/FeatureFeedback/FeatureFeedbackByThumbs';

const feedbackText: FeatureFeedbackTextConfig = {
  positivePopupMessage: 'This is a good summary',
  negativePopupMessage: 'This is a poor summary',
};

const isEnabled = (segment: SummarySegment) => {
  const hasReference = segment.conversationReferences.length > 0;
  const hasNonSpecialTheme = !segment.themes.every(isSpecialTheme);

  return hasReference && hasNonSpecialTheme;
}

interface Props {
  hasReferences: boolean;
  onSegmentClick: (index: number) => void;
  onSegmentMouseEnter: (index: number) => void;
  onSegmentMouseLeave: () => void;
  selectedSegmentIndexes: number[];
  preSelectedSegmentIndexes: number[];
  summary: Summary;
}

const ConversationSummary = ({
  hasReferences,
  summary,
  onSegmentClick,
  selectedSegmentIndexes,
  preSelectedSegmentIndexes,
  onSegmentMouseEnter,
  onSegmentMouseLeave
}: Props) => {
  const processedSummary = processSummary(summary);

  const isSegmentSelected = (index: number) => hasReferences && selectedSegmentIndexes.includes(index);

  const handleMouseEnter = (segmentIndex: number) => {
    onSegmentMouseEnter(segmentIndex);
  }

  const handleSegmentClick = (event: React.MouseEvent, segmentIndex: number) => {
    event.stopPropagation();

    if (!hasReferences) {
      return;
    }

    onSegmentClick(segmentIndex);
  }

  return (
    <div className="conversation-summary">
      <p>
      {processedSummary.map((block, index) => (
        <span key={index}>
        {isContentBlock(block) && isEnabled(block.segment) ? (
          <button
            className={classNames('conversation-summary__segment', {
              'conversation-summary__segment--selected': isSegmentSelected(block.segment.index),
              'conversation-summary__segment--pre-selected': preSelectedSegmentIndexes.includes(block.segment.index),
              'conversation-summary__segment--no-references': !hasReferences,
            })}
            onClick={(e) => handleSegmentClick(e, block.segment.index)}
            onMouseEnter={() => handleMouseEnter(block.segment.index)}
            onMouseLeave={() => onSegmentMouseLeave()}
          >
            <Tooltip
              content="Click to highlight references"
              position="bottom left"
              inverted={true}
              disabled={isSegmentSelected(block.segment.index) || !hasReferences}
              trigger={
                <span className="conversation-summary__segment-text">
                  { block.content }
                </span>
              }
            />
          </button>
        ) : (
          <span>{block.content}</span>
        )}
        </span>
      ))}
      </p>
      <CopyToClipboardButton
        popupText='Copy to clipboard'
        text={summary.comment}
      />
      <FeatureFeedbackByThumbs
        featureId="conversation-summary"
        textConfig={feedbackText}
        data={summary}
        tooltipPosition="bottom left"
      />
    </div>
  );
}

export { ConversationSummary };
