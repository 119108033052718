import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from 'components/Shared/Button';
import { ButtonGroup } from 'components/Shared/ButtonGroup';

export type ToolbarItem = {
  onClick: () => void,
  isActive: boolean,
  label: string,
  icon: IconProp
};

export type Props = {
  items: ToolbarItem[],
};

const AnswerToolbar = (props: Props) => {
  return (
    <ButtonGroup role="toolbar">
      {props.items.map(item => (
        <Button
          key={item.label}
          active={item.isActive}
          icon={<FontAwesomeIcon icon={item.icon} fixedWidth={true} />}
          onClick={item.onClick}
          size="small"
          variant="toggle"
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
export { AnswerToolbar };
