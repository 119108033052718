


















export default {
  name: "KeyTakeaway",
  props: {
    html: { type: String },
    isInteractive: { type: Boolean, default: false },
    icon: { type: String },
  },
  computed: {
    svgIcon() {
      return () => import(`images/vue-icons/${this.icon}.svg`);
    }
  }
};

