import { pick } from 'lodash';
import AnalysisToolsStore, { AnalysisToolsStoreInterface } from 'stores/AnalysisToolsStore';
import BillingAccountStore, { BillingAccountStoreInterface } from 'stores/BillingAccountStore';
import ConceptsStore, { ConceptsStoreInterface } from 'stores/ConceptsStore';
import DashboardStore, { DashboardStoreInterface } from 'stores/DashboardStore';
import ManageUsersStore, { ManageUsersStoreInterface } from 'stores/ManageUsersStore';
import RoleStore, { RoleStoreInterface } from 'stores/RoleStore';
import SubscriptionStore, { SubscriptionStoreInterface } from 'stores/SubscriptionStore';
import SupportStore, { SupportStoreInterface } from 'stores/SupportStore';
import ThemeDiscoveryStore, { ThemeDiscoveryStoreInterface } from 'stores/ThemeDiscoveryStore';
import ThemesStore, { ThemesStoreInterface } from 'stores/ThemesStore';
import UserStore, { UserStoreInterface } from 'stores/UserStore';
import AggregateViewStore, { AggregateViewStoreInterface } from './AggregateViewStore';
import AnalysisConfigStore, { AnalysisConfigStoreInterface } from './AnalysisConfigStore';
import AnswersAuditStore, { AnswersAuditStoreInterface } from './AnswersAuditStore';
import AnswersStore, { AnswersStoreInterface } from './AnswersStore';
import FeatureFeedbackStore, { FeatureFeedbackStoreInterface } from './FeatureFeedbackStore';
import FilterStore, { FilterStoreInterface } from './FilterStore';
import InitConfigStore, { InitConfigStoreInterface } from './InitConfigStore';
import IntegrationsStore, { IntegrationsStoreInterface } from './IntegrationsStore';
import ManageThematicAdminsStore, { ManageThematicAdminsStoreInterface } from './ManageThematicAdminsStore';
import MetricsStore, { MetricsStoreInterface } from './MetricsStore';
import NotificationStore, { NotificationStoreInterface } from './NotificationStore';
import OrganizationStore, { OrganizationStoreInterface } from './OrganizationStore';
import OrganizationsStore, { OrganizationsStoreInterface } from './OrganizationsStore';
import SegmentStore, { SegmentStoreInterface } from './SegmentStore';
import { ThemeEditorSessionStore, ThemeEditorSessionStoreInterface } from './ThemeEditorSessionStore';
import SetupStore, { SetupStoreInterface } from './SetupStore';
import SurveyStore, { SurveyStoreInterface } from './SurveyStore';
import TagStore, { TagStoreInterface } from './TagStore';
import TagsUIStore, { TagsUIStoreInterface } from './TagsUIStore';
import UrlParametersStore, { UrlParametersStoreInterface } from './UrlParametersStore';
import WorkflowsStore, { WorkflowsStoreInterface } from './WorkflowsStore';
import ActiveDashboardUIStore, { ActiveDashboardUIStoreInterface } from './ui/ActiveDashboardUIStore';
import AnalysisToolsKeyTakeawaysUIStore, {
  AnalysisToolsKeyTakeawaysUIStoreInterface
} from './ui/AnalysisToolsKeyTakeawaysUIStore';
import AnalysisToolsUIStore, { AnalysisToolsUIStoreInterface } from './ui/AnalysisToolsUIStore';
import AnswersUIStore, { AnswersUIStoreInterface } from './ui/AnswersUIStore';
import ConceptsEditorUIStore, { ConceptsEditorUIStoreInterface } from './ui/ConceptsEditorUIStore';
import ConfigureDataUIStore, { ConfigureDataUIStoreInterface } from './ui/ConfigureDataUIStore';
import ExploreToolUIStore, { ExploreToolUIStoreInterface } from './ui/ExploreToolUIStore';
import ExportInsightUIStore, { ExportInsightUIStoreInterface } from './ui/ExportInsightUIStore';
import LoginScreenUIStore, { LoginScreenUIStoreInterface } from './ui/LoginScreenUIStore';
import QuickEditUIStore, { QuickEditUIStoreInterface } from './ui/QuickEditUIStore';
import ReportUIStore, { ReportUIStoreInterface } from './ui/ReportUIStore';

export interface StoresInterface {
  activeDashboardUIStore: ActiveDashboardUIStoreInterface;
  aggregateViewStore: AggregateViewStoreInterface;
  analysisConfigStore: AnalysisConfigStoreInterface;
  analysisToolsKeyTakeawaysUIStore: AnalysisToolsKeyTakeawaysUIStoreInterface;
  analysisToolsStore: AnalysisToolsStoreInterface;
  analysisToolsUIStore: AnalysisToolsUIStoreInterface;
  answersAuditStore: AnswersAuditStoreInterface;
  answersStore: AnswersStoreInterface;
  answersUIStore: AnswersUIStoreInterface;
  billingAccountStore: BillingAccountStoreInterface;
  conceptsEditorUIStore: ConceptsEditorUIStoreInterface;
  conceptsStore: ConceptsStoreInterface;
  configureDataUIStore: ConfigureDataUIStoreInterface;
  dashboardStore: DashboardStoreInterface;
  exploreToolUIStore: ExploreToolUIStoreInterface;
  exportInsightUIStore: ExportInsightUIStoreInterface;
  featureFeedbackStore: FeatureFeedbackStoreInterface;
  filterStore: FilterStoreInterface;
  initConfigStore: InitConfigStoreInterface;
  integrationsStore: IntegrationsStoreInterface;
  loginScreenUIStore: LoginScreenUIStoreInterface;
  manageThematicAdminsStore: ManageThematicAdminsStoreInterface;
  manageUsersStore: ManageUsersStoreInterface;
  metricsStore: MetricsStoreInterface;
  notificationStore: NotificationStoreInterface;
  organizationStore: OrganizationStoreInterface;
  organizationsStore: OrganizationsStoreInterface;
  quickEditUIStore: QuickEditUIStoreInterface;
  reportUIStore: ReportUIStoreInterface;
  roleStore: RoleStoreInterface;
  rootStore: RootStoreInterface;
  segmentStore: SegmentStoreInterface;
  setupStore: SetupStoreInterface;
  subscriptionStore: SubscriptionStoreInterface;
  supportStore: SupportStoreInterface;
  surveyStore: SurveyStoreInterface;
  tagStore: TagStoreInterface;
  tagsUIStore: TagsUIStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
  themesDiscoveryStore: ThemeDiscoveryStoreInterface;
  themesStore: ThemesStoreInterface;
  urlParametersStore: UrlParametersStoreInterface;
  userStore: UserStoreInterface;
  workflowsStore: WorkflowsStoreInterface;
}

export interface RootStoreInterface {
  stores: StoresInterface;
}

export class RootStore implements RootStoreInterface {
  stores: StoresInterface;

  constructor() {
    const configureDataUIStore = new ConfigureDataUIStore();
    const organizationStore = new OrganizationStore();
    const quickEditUIStore = new QuickEditUIStore();
    const surveyStore = new SurveyStore(organizationStore);
    const notificationStore = new NotificationStore();
    const themeEditorSessionStore = new ThemeEditorSessionStore(surveyStore);
    const themesStore = new ThemesStore(
      surveyStore,
      notificationStore,
      themeEditorSessionStore
    );
    const conceptsStore = new ConceptsStore(surveyStore, themesStore);
    const conceptsEditorUIStore = new ConceptsEditorUIStore();
    const integrationsStore = new IntegrationsStore(organizationStore);
    const organizationsStore = new OrganizationsStore();
    const setupStore = new SetupStore(organizationStore, surveyStore);
    const analysisToolsStore = new AnalysisToolsStore(organizationStore, surveyStore);
    const themesDiscoveryStore = new ThemeDiscoveryStore(analysisToolsStore);
    const userStore = new UserStore(organizationStore);
    const dashboardStore = new DashboardStore(organizationStore, userStore);
    const subscriptionStore = new SubscriptionStore(organizationStore);
    const roleStore = new RoleStore(organizationStore);
    const supportStore = new SupportStore(organizationStore);
    const manageUsersStore = new ManageUsersStore(organizationStore);
    const metricsStore = new MetricsStore();
    const workflowsStore = new WorkflowsStore(organizationStore);
    const analysisConfigStore = new AnalysisConfigStore();
    const activeDashboardUIStore = new ActiveDashboardUIStore(dashboardStore);
    const urlParametersStore = new UrlParametersStore();
    const aggregateViewStore = new AggregateViewStore(organizationStore);
    const tagStore = new TagStore(organizationStore);
    const tagsUIStore = new TagsUIStore();
    const filterStore = new FilterStore(analysisConfigStore, urlParametersStore, themesStore, tagStore);
    const manageThematicAdminsStore = new ManageThematicAdminsStore();
    const answersStore = new AnswersStore(organizationStore, surveyStore);
    const answersAuditStore = new AnswersAuditStore(organizationStore);
    const answersUIStore = new AnswersUIStore(
      organizationStore,
      answersStore,
      userStore,
      analysisConfigStore,
      filterStore
    );
    const analysisToolsKeyTakeawaysUIStore = new AnalysisToolsKeyTakeawaysUIStore();
    const initConfigStore = new InitConfigStore();
    const exploreToolUIStore = new ExploreToolUIStore();
    const exportInsightUIStore = new ExportInsightUIStore();
    const billingAccountStore = new BillingAccountStore();
    const reportUIStore = new ReportUIStore();
    const loginScreenUIStore = new LoginScreenUIStore();
    const analysisToolsUIStore = new AnalysisToolsUIStore(
      analysisConfigStore,
      organizationStore,
      themesStore,
      notificationStore,
      quickEditUIStore,
      surveyStore,
      filterStore,
      urlParametersStore,
    );
    const segmentStore = new SegmentStore(filterStore, analysisConfigStore);

    const featureFeedbackStore = new FeatureFeedbackStore();

    this.stores = {
      activeDashboardUIStore,
      aggregateViewStore,
      analysisConfigStore,
      analysisToolsKeyTakeawaysUIStore,
      analysisToolsStore,
      analysisToolsUIStore,
      answersAuditStore,
      answersStore,
      answersUIStore,
      billingAccountStore,
      conceptsEditorUIStore,
      conceptsStore,
      configureDataUIStore,
      dashboardStore,
      exploreToolUIStore,
      exportInsightUIStore,
      featureFeedbackStore,
      filterStore,
      initConfigStore,
      integrationsStore,
      loginScreenUIStore,
      manageThematicAdminsStore,
      manageUsersStore,
      metricsStore,
      notificationStore,
      organizationStore,
      organizationsStore,
      quickEditUIStore,
      reportUIStore,
      roleStore,
      rootStore: this,
      segmentStore,
      setupStore,
      subscriptionStore,
      supportStore,
      surveyStore,
      tagStore,
      tagsUIStore,
      themeEditorSessionStore,
      themesDiscoveryStore,
      themesStore,
      urlParametersStore,
      userStore,
      workflowsStore,
    };
  }
}

// this lets us lazy construct the rootstore,
// in case something (tests for example) are referencing things in here that aren't the root store
let rootStore = undefined as RootStore | undefined;
export const getRootStore = (): RootStore => {
  if (!rootStore) {
    rootStore = new RootStore();
  }
  return rootStore;
};

type StoreNames = keyof StoresInterface

export const getSelectedStores = <T extends StoreNames>(storeNames: StoreNames[]): Pick<StoresInterface, T> => {
  const rootStore = new RootStore();
  return pick(rootStore.stores, storeNames);
};

export const getUserStore = (): UserStoreInterface => {
  return getRootStore().stores.userStore;
};

export const getOrganizationStore = (): OrganizationStoreInterface => {
  return getRootStore().stores.organizationStore;
};

export const getOrganizationsStore = (): OrganizationsStoreInterface => {
  return getRootStore().stores.organizationsStore;
};

export const getAnalysisToolsStore = (): AnalysisToolsStoreInterface => {
  return getRootStore().stores.analysisToolsStore;
};

export const getSubscriptionStore = (): SubscriptionStoreInterface => {
  return getRootStore().stores.subscriptionStore;
};

export const getSurveyStore = (): SurveyStoreInterface => {
  return getRootStore().stores.surveyStore;
};

export const getAnalysisConfigStore = (): AnalysisConfigStoreInterface => {
  return getRootStore().stores.analysisConfigStore;
};

export const getAnalysisToolsKeyTakeawaysUIStore = (): AnalysisToolsKeyTakeawaysUIStoreInterface => {
  return getRootStore().stores.analysisToolsKeyTakeawaysUIStore;
};

export const getAnalysisToolsUIStore = (): AnalysisToolsUIStoreInterface => {
  return getRootStore().stores.analysisToolsUIStore;
};

export const getThemeDiscoveryStore = (): ThemeDiscoveryStoreInterface => {
  return getRootStore().stores.themesDiscoveryStore;
};

export const getThemesStore = (): ThemesStoreInterface => {
  return getRootStore().stores.themesStore;
};

export const getThemeEditorSessionStore = (): ThemeEditorSessionStoreInterface => {
  return getRootStore().stores.themeEditorSessionStore;
};

export const getActiveDashboardUIStore = (): ActiveDashboardUIStoreInterface => {
  return getRootStore().stores.activeDashboardUIStore;
};

export const getUrlParametersStore = (): UrlParametersStoreInterface => {
  return getRootStore().stores.urlParametersStore;
};

export const getFilterStore = (): FilterStoreInterface => {
  return getRootStore().stores.filterStore;
};

export const getSegmentStore = (): SegmentStoreInterface => {
  return getRootStore().stores.segmentStore;
};

export const getDashboardStore = (): DashboardStoreInterface => {
  return getRootStore().stores.dashboardStore;
};

export const getAggregateViewStore = (): AggregateViewStoreInterface => {
  return getRootStore().stores.aggregateViewStore;
};

export const getTagStore = (): TagStoreInterface => {
  return getRootStore().stores.tagStore;
};

export const getTagsUIStore = (): TagsUIStoreInterface => {
  return getRootStore().stores.tagsUIStore;
};

export const getWorkflowsStore = (): WorkflowsStoreInterface => {
  return getRootStore().stores.workflowsStore;
};

export const getInitConfigStore = (): InitConfigStoreInterface => {
  return getRootStore().stores.initConfigStore;
};

export const getExploreToolUIStore = (): ExploreToolUIStoreInterface => {
  return getRootStore().stores.exploreToolUIStore;
};

export const getExportInsightUIStore = (): ExportInsightUIStoreInterface => {
  return getRootStore().stores.exportInsightUIStore;
};

export const getReportUIStore = (): ReportUIStoreInterface => {
  return getRootStore().stores.reportUIStore;
};

export const getNotificationStore = (): NotificationStoreInterface => {
  return getRootStore().stores.notificationStore;
};

export const getQuickEditUIStore = (): QuickEditUIStoreInterface => {
  return getRootStore().stores.quickEditUIStore;
};

export const getAnswersStore = (): AnswersStoreInterface => {
  return getRootStore().stores.answersStore;
};

export const getAnswersUIStore = (): AnswersUIStoreInterface => {
  return getRootStore().stores.answersUIStore;
};

export const getFeatureFeedbackStore = (): FeatureFeedbackStoreInterface => {
  return getRootStore().stores.featureFeedbackStore;
};
