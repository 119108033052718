import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { kebabCase } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';

interface ThemeAdminDropdownStoreProps {
  surveyStore?: SurveyStoreInterface;
  themesStore?: ThemesStoreInterface;
}

interface ThemeAdminDropdownProps extends ThemeAdminDropdownStoreProps {
  openConceptsEditor: () => void;
  openParametersEditor: () => void;
  openScoreThemes: () => void;
  openRefreshPhrases: () => void;
  openReorganizeThemes: () => void;
  orgId: string;
  surveyId: string;
}

@inject('surveyStore', 'themesStore')
@observer
export default class ThemeAdminDropdown extends React.Component<
  ThemeAdminDropdownProps
> {
  fileInputEl: HTMLInputElement | null = null;
  fileFormEl: HTMLFormElement | null = null;

  downloadConfig = () => {
    const { surveyId, themesStore, surveyStore } = this.props;
    const title = surveyStore!.getSurveyTitle(surveyId);
    const contents = themesStore!.getContents();
    if (contents === undefined) {
      return;
    }
    const blob = new Blob([contents], { type: 'text/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.download = `${ kebabCase(title) }-themes.json`;
    link.innerHTML = 'Download File';
    link.href = url;
    document.body.appendChild(link); // firefox requires it to be on body
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });

    link.click();
  };

  handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { orgId, surveyId, themesStore } = this.props;
    const { files } = e.target;
    if (!files) {
      return;
    }
    const file = files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      if (!reader.result) {
        return;
      }
      const themes = reader.result as string;
      themesStore!.receiveThemes(themes);
      themesStore!.watchUnsaved();
      await themesStore!.saveDraft(orgId, surveyId);

      // Track analytics around theme upload use
      analytics.track('Themes Editor: Manual themes upload', { category: 'Themes Editor' });

      // this.onChange(theme); // rely on onChange to set it back to `uploading: false`
      if (this.fileFormEl) {
        this.fileFormEl.reset();
      }
    };
    reader.readAsText(file);
  };
  uploadConfig = () => {
    if (this.fileInputEl) {
      this.fileInputEl.click();
    }
  };
  openConceptsEditor = () => {
    this.props.openConceptsEditor();
  }
  openParametersEditor = () => {
    this.props.openParametersEditor();
  }
  openScoreThemes = () => {
    this.props.openScoreThemes();
  }
  openRefreshPhrases = () => {
    this.props.openRefreshPhrases();
  }
  openReorganizeThemes = () => {
    this.props.openReorganizeThemes();
  }
  render() {
    const { working } = this.props.themesStore!;
    const canSeeThemesV2Recreate = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_THEMESV2_RECREATE);
    return (
      <>
        <form ref={c => (this.fileFormEl = c)}>
          <input
            ref={c => (this.fileInputEl = c)}
            style={{ display: 'none' }}
            accept=".json"
            type="file"
            onChange={this.handleFile}
          />
        </form>
        <Dropdown
          icon={null}
          trigger={
            <Button size="small">
              Admin
              <FontAwesomeIcon icon="chevron-down" className="icon" />
            </Button>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item disabled={working} onClick={this.uploadConfig}>
              <FontAwesomeIcon className="icon" icon="upload" />
              Upload
            </Dropdown.Item>
            <Dropdown.Item disabled={working} onClick={this.downloadConfig}>
              <FontAwesomeIcon className="icon" icon="save" />
              Download
            </Dropdown.Item>
            <Dropdown.Item disabled={working} onClick={this.openConceptsEditor}>
              Concepts Editor
            </Dropdown.Item>
            <Dropdown.Item disabled={working} onClick={this.openParametersEditor}>
              Parameters Editor
            </Dropdown.Item>
            <Dropdown.Item disabled={working} onClick={this.openScoreThemes}>
              Score Themes
            </Dropdown.Item>
            <Dropdown.Item disabled={working} onClick={this.openRefreshPhrases}>
              Refresh Phrases
            </Dropdown.Item>
            {canSeeThemesV2Recreate && <Dropdown.Item disabled={working} onClick={this.openReorganizeThemes}>
              Recreate Themes
            </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}
