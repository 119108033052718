import { formatDateWithTime } from 'lib/date';
import { dateToDateMonth } from 'lib/date';
import * as React from 'react';
import { Button } from 'components/Shared/Button';
import { Tooltip } from 'components/Shared/Tooltip';
import RefreshIcon from 'images/icons/refresh.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  isReadOnly: boolean;
  onRefreshAnswer?: () => void;
  utcDate: string;
  showRefreshTooltip: boolean;
};

export const RefreshAnswer = (props: Props) => {
  const shortDate = dateToDateMonth(props.utcDate);
  const longDate = formatDateWithTime(props.utcDate);

  return (
    <span className="answers__refresh-answer">
      {!props.showRefreshTooltip && (
        <time
          title={longDate}
          dateTime={props.utcDate}>
          {shortDate}
        </time>
      )}
      {props.showRefreshTooltip && !props.isReadOnly && (
        <Tooltip
          disabled={!props.showRefreshTooltip}
          hoverable
          position="bottom left"
          trigger={
            <span className="answers__refresh-answer-trigger">
              <RefreshIcon />
              <span className="answers__fresh-answer-text">Asked on </span>
              <time
                title={longDate}
                dateTime={props.utcDate}>
                {shortDate}
              </time>
            </span>
          }
        >
          <p>This answer was generated on {shortDate}. Updating will refresh the content of the answer paragraph</p>
          <Button onClick={props.onRefreshAnswer} size="small" variant="primary">
            Update answer
          </Button>
        </Tooltip>
      )}
      {props.showRefreshTooltip && props.isReadOnly && (
        <Tooltip
          hoverable
          position="bottom left"
          trigger={
            <span className="answers__refresh-answer-trigger">
              <FontAwesomeIcon icon="info-circle" />
              <span className="answers__fresh-answer-text">Asked on </span>
              <time
                title={longDate}
                dateTime={props.utcDate}>
                {shortDate}
              </time>
            </span>
          }
          inverted={true}
        >
          <p>This answer was generated on {shortDate}</p>
        </Tooltip>
      )}
    </span>
  );
};
