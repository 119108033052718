import { AnalysisFilter } from 'api/interfaces';
import * as React from 'react';
import { Ask } from 'stores/ui/AnswersUIStore';
import { useContext } from 'react';

interface AnswersContext {
  askParams: Ask;
  analysisFilters: Map<string, AnalysisFilter[]>;
}

const Context = React.createContext<AnswersContext>({
  askParams: {
    dataSets: [],
    filters: {},
    question: '',
  },
  analysisFilters: new Map(),
});

export function useAnswersContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useAnswersContext must be used within a Provider');
  }

  return context;
}

export const Consumer = Context.Consumer;
export const Provider = Context.Provider;
