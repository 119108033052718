import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputColumnType } from 'api/enums';
import analytics from 'lib/analytics';
import { constructMixMaxLink } from 'lib/mixmax-link';
import { cloneDeep, isEmpty } from 'lodash';
import * as React from 'react';
import {
  Checkbox, Divider, Dropdown, DropdownProps, Form, Grid, Input, Label,
  Message, Radio, Table
} from 'semantic-ui-react';
import { Column } from './interfaces';

const COLUMN_TYPE_OPTIONS = [
  {
    text: (
      <span>
        <FontAwesomeIcon icon="text" /> Text
      </span>
    ),
    value: InputColumnType.TEXT,
    key: 'text'
  },
  {
    text: (
      <span>
        <FontAwesomeIcon icon="calendar" /> Date
      </span>
    ),
    value: InputColumnType.DATE,
    key: 'date'
  },
  {
    text: (
      <span>
        <FontAwesomeIcon icon="hashtag" /> Number
      </span>
    ),
    value: InputColumnType.NUMBER,
    key: 'number'
  },
  {
    text: (
      <span>
        <FontAwesomeIcon icon="tag" /> Labels
      </span>
    ),
    value: InputColumnType.TAGS,
    key: 'tags'
  },
];

export interface ConfigureColumnsProps {
  columns: Column[];
  columnAlphabets: { [key: number]: string };
  hasHeader: boolean;
  bypassUniqueValueCheck: boolean;
  onColumnsChange: (columns: Column[]) => void;
  onHasHeaderChange: (hasHeader: boolean) => void;
  onColumnsError: (hasErrorColumns: boolean) => void;
}

interface ConfigureColumnsState {
  columnsWithError: Column[];
  commentColumnsErrorMessage: string;
}

const DATE_FORMAT_OPTIONS = [
  {
    value: true,
    text: 'Day First (DD/MM)',
    key: 'dayfirst'
  },
  {
    value: false,
    text: 'Month First (MM/DD)',
    key: 'monthfirst'
  }
];

export class ConfigureColumns extends React.Component<ConfigureColumnsProps, ConfigureColumnsState> {
  constructor(props: ConfigureColumnsProps) {
    super(props);

    this.state = {
      columnsWithError: [] as Column[],
      commentColumnsErrorMessage: ''
    };
  }

  handleTypeChange = (index: number, { value }: DropdownProps) => {
    let columns = cloneDeep(this.props.columns);
    columns[index].type = value as string;
    columns[index].dayFirst = value === InputColumnType.DATE ? true : undefined;

    if (value !== InputColumnType.TEXT) {
      delete columns[index].isComment;
    }

    if (value !== InputColumnType.NUMBER) {
      delete columns[index].isScore;
    }

    this.props.onColumnsChange(columns);
  }

  handleNameChange = (index: number, { value }: DropdownProps) => {
    let columns = cloneDeep(this.props.columns);
    columns[index].name = value as string;
    this.props.onColumnsChange(columns);
  }

  handleHeaderChange = (hasHeader: boolean) => {
    const { columns, columnAlphabets } = this.props;

    // Reset columns names as per the choice
    const updatedColumns = columns.map(c => {
      let columnName = `Column ${columnAlphabets[c.index]}`;
      const { sampleHeader } = columns[c.index];
      if (hasHeader && sampleHeader) {
        columnName = sampleHeader;
      }
      return {
        ...c,
        name: columnName
      };
    });
    this.props.onColumnsChange(updatedColumns);
    this.props.onHasHeaderChange(hasHeader);
  }

  handleDateFormatChange = (index: number, { value }: DropdownProps ) => {
    let columns = cloneDeep(this.props.columns);
    columns[index].dayFirst = !!value;
    this.props.onColumnsChange(columns);
  }

  validateComments(columns: Column[]) {
    let commentColumnsErrorMessage = '';

    if (this.props.bypassUniqueValueCheck) {
      this.setState({ commentColumnsErrorMessage });
      return;
    }

    const commentColumns = this.getCommentColumns(columns);
    const columnsWithInsufficientUniqueComments = commentColumns.filter(column => column.uniqueValues < 100);
    if (columnsWithInsufficientUniqueComments.length > 0) {
      analytics.track('Survey Flow: Configure Comments, not enough unique values', { category: 'Survey Flow' });
      commentColumnsErrorMessage = `Please make sure each comment column contains at least 100 unique comments.`;
    }
    this.props.onColumnsError(!!commentColumnsErrorMessage);
    this.setState({ commentColumnsErrorMessage, columnsWithError: columnsWithInsufficientUniqueComments });
  }

  getCommentColumns = (columns: Column[]) => {
    return columns.filter(c => c.isComment) || [];
  }

  onIsCommentChange = (index: number) => {
    const columns = cloneDeep(this.props.columns);
    const columnIndex = columns.findIndex(c => c.index === index);
    columns[columnIndex].isComment = !columns[columnIndex].isComment;
    this.validateComments(columns);
    this.props.onColumnsChange(columns);
  }

  onIsScoreChange = (index: number) => {
    const columns = cloneDeep(this.props.columns);
    const columnIndex = columns.findIndex(c => c.index === index);
    columns[columnIndex].isScore = !columns[columnIndex].isScore;
    this.props.onColumnsChange(columns);
  }

  render() {
    const {
      columnsWithError,
      commentColumnsErrorMessage
    } = this.state;
    const {
      columns,
      columnAlphabets,
      hasHeader
    } = this.props;

    if (isEmpty(columns)) {
      return null;
    }

    const mixMaxLink = constructMixMaxLink('https://cal.mixmax.com/austinpresley/onboarding');

    return (
      <>
        <div className="header-row-option nw-header-row-option">
          <h4>Header row</h4>
          <Form.Group className="header-row-option-form-group">
            <label>
              Does the first row contain column names?
            </label>
            <Radio
              data-testid="header-change-no"
              id="header-change-no"
              label={<label htmlFor="header-change-no">No</label>}
              name="radioGroup"
              value="No"
              checked={!hasHeader}
              onChange={() => this.handleHeaderChange(false)}
            />
            <Radio
              data-testid="header-change-yes"
              id="header-change-yes"
              label={<label htmlFor="header-change-yes">Yes</label>}
              name="radioGroup"
              value="Yes"
              checked={hasHeader}
              onChange={() => this.handleHeaderChange(true)}
            />
          </Form.Group>
          <div className="sample-table">
            <Table>
              <Table.Body>
                <Table.Row>
                  {columns.map((c, index) => {
                    if (c.type === InputColumnType.TEXT) {
                      return <Table.Cell
                        className="text-column"
                        singleLine={true}
                        key={index}
                      >
                        {c.sample[0]}
                      </Table.Cell>;
                    }
                    return <Table.Cell singleLine={true} key={index}>{c.sample[0]}</Table.Cell>;
                  })}
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <Divider />
        <div className="column-config nw-column-config">
          <h4>Column settings</h4>
          <Grid columns={5}>
            <Grid.Row>
              <Grid.Column width={2}>
                Column
              </Grid.Column>
              <Grid.Column width={4}>
                Name
              </Grid.Column>
              <Grid.Column width={3}>
                Type
              </Grid.Column>
              <Grid.Column width={4} />
              <Grid.Column width={2} />
            </Grid.Row>
            {columns.map((c, i) => {
              const columnHasError = !!columnsWithError.find(col => col.index === c.index);
              return (
                <React.Fragment key={i}>
                  <Grid.Row className={`${columnHasError ? 'error ' : ''}nw-config-row`} role="row">
                    <Grid.Column width={2} className="column-identifier">
                      <p>{columnAlphabets[i]}</p>
                      {columnHasError && <FontAwesomeIcon icon="exclamation-triangle" />}
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Form.Field className="nw-column-name">
                        <Input
                          value={c.name}
                          onChange={(_e, data) => this.handleNameChange(i, data)}
                        />
                      </Form.Field>

                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Form.Field className="nw-column-type">
                        <Dropdown
                          className="column-type"
                          options={COLUMN_TYPE_OPTIONS}
                          placeholder="Select"
                          value={c.type}
                          selectOnBlur={false}
                          selection={true}
                          onChange={(_e, data) => this.handleTypeChange(i, data)}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      {c.type === InputColumnType.DATE &&
                        <Form.Field className="nw-date-format">
                          <Dropdown
                            data-testid="date-format"
                            options={DATE_FORMAT_OPTIONS}
                            placeholder="Select"
                            value={c.dayFirst}
                            selectOnBlur={false}
                            selection={true}
                            onChange={(_e, data) => this.handleDateFormatChange(i, data)}
                          />
                        </Form.Field>
                      }
                      {c.type === InputColumnType.TAGS &&
                        <Form.Field className="nw-tags-format">
                          Comma Separated Labels
                        </Form.Field>
                      }
                      {c.type === InputColumnType.TEXT &&
                        <Form.Field className="nw-is-comment">
                          <Checkbox
                            id={`comment-${c.index}`}
                            label={<label htmlFor={`comment-${c.index}`}>Contains comments</label>}
                            checked={c.isComment}
                            onChange={() => this.onIsCommentChange(c.index)}
                          />
                        </Form.Field>
                      }
                      {c.type === InputColumnType.NUMBER &&
                        <Form.Field className="nw-is-score">
                          <Checkbox
                            id={`score-${c.index}`}
                            label={<label htmlFor={`score-${c.index}`}>Contains score</label>}
                            checked={c.isScore}
                            onChange={() => this.onIsScoreChange(c.index)}
                          />
                        </Form.Field>
                      }
                    </Grid.Column>
                    <Grid.Column width={2} className="column-label">
                      {c.isComment &&
                        <Label className="comment-label">Comment</Label>
                      }
                      {c.isScore &&
                        <Label className="score-label">Score</Label>
                      }
                    </Grid.Column>
                  </Grid.Row>
                </React.Fragment>
              );
            })}
          </Grid>
        </div>
        {commentColumnsErrorMessage &&
          <Message
            className="column-error"
            negative={true}
          >
            <Message.Header>
              {commentColumnsErrorMessage} Or, <a
                href={mixMaxLink}
                target="blank"
                rel="noopener noreferrer"
              >
                book a guided tour
              </a> and we’ll use one of our datasets to demonstrate our feedback analysis.
            </Message.Header>
          </Message>
        }
      </>
    );
  }
}

export default ConfigureColumns;
