import * as React from 'react';
import classnames from 'classnames';
import * as d3 from 'd3';
import toFixed from 'vue/libs/to-fixed';
import fuzzNum from 'vue/libs/fuzz-num';
import { Popup } from 'semantic-ui-react';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { ThemesStoreInterface } from 'stores/ThemesStore';

interface ThemeFrequencyStoreProps {
  themesStore?: ThemesStoreInterface;
}
interface ThemeFrequencyProps extends ThemeFrequencyStoreProps {
  nodeId: string;
}
interface ThemeFrequencyState {
  value: number;
  max: number;
  total: number;
  working: boolean;
}

@inject('themesStore')
@observer
export default class ThemeFrequency extends React.Component<
  ThemeFrequencyProps,
  ThemeFrequencyState
> {
  state = {
    value: 0,
    max: 0,
    total: 0,
    working: true
  };
  svgEl: SVGSVGElement | null = null;

  componentDidMount() {
    const { themesStore } = this.props;
    disposeOnUnmount(
      this,
      autorun(() => {
        const value = themesStore!.commentCounts[this.props.nodeId] || 0;

        this.setState({
          max: themesStore!.commentCountMax,
          total: themesStore!.exampleComments.length,
          value,
          working: themesStore!.commentCounting
        });
      })
    );
    this.updateSvg();
  }
  componentDidUpdate() {
    this.updateSvg();
  }
  updateSvg = () => {
    const { svgEl } = this;
    const { max, value = 0, working } = this.state;

    const val = Math.log2(value) / Math.log2(max);
    if (!svgEl) {
      return;
    }
    d3.select(svgEl)
      .attr('class', 'theme-frequency')
      .selectAll('g')
      .remove();

    const chart = d3
      .select(svgEl)
      .attr('height', 12)
      .attr('width', 40)
      .append('g');

    const barCount = 6;
    const series = d3.range(0, barCount, 1);
    chart
      .selectAll('.frequency-bar')
      .data(series)
      .enter()
      .append('rect')
      .attr('class', d => {
        return classnames('frequency-bar', {
          filled: d < Math.round(val * barCount),
          working
        });
      })
      .attr('y', 0)
      .attr('x', d => d * 7)
      .attr('height', 12)
      .attr('width', 5);
  };
  render() {
    const { total, value = 0 } = this.state;
    const percent = total === 0 ? 0 : (value * 100) / total;
    const p = toFixed(percent, 1, '%');
    const tot = fuzzNum(total);
    const v = fuzzNum(value);
    const message = `Matches ${v} of ${tot} sample comments (${p})`;
    return (
      <Popup
        content={message}
        inverted={true}
        position="top center"
        trigger={<svg ref={c => (this.svgEl = c)} />}
        wide={true}
      />
    );
  }
}
