import statistics from 'vue/libs/statistics';
import thematicData from 'vue/libs/thematicData';
import themeHelpers from 'vue/libs/themeHelpers';

interface Selection {
  query: string;
  title: string;
}
interface FetchOptions {
  commentColumns: number[];
  requestOptions: object;
  filterThemes?: object;
  selections: {
    baseline: Selection;
    comparison: Selection;
  };
  themeSegmentSelection?: {
    baseline: string;
    comparison: string;
  };
}

export async function fetchData(options: FetchOptions) {
  const { commentColumns, requestOptions, selections, themeSegmentSelection } = options;

  const promises = Promise.all([
    thematicData.getThemes(
      selections.baseline.query,
      commentColumns,
      { ...requestOptions, themesSegmentFilterString: themeSegmentSelection ? themeSegmentSelection.baseline : null }
    ),
    thematicData.getThemes(
      selections.comparison.query,
      commentColumns,
      { ...requestOptions, themesSegmentFilterString: themeSegmentSelection ? themeSegmentSelection.comparison : null }
    )
  ]);
  const [baseline, comparison] = await promises;
  return [baseline, comparison];
}

export default async function doFetch(options: FetchOptions) {
  const { filterThemes, selections } = options;
  const [baseline, comparison] = await fetchData(options);
  return mapResult(baseline, comparison, filterThemes, selections);
}

export function mapResult(
  baseline: any,
  selection: any,
  filterThemes: object | undefined,
  selections: { baseline: Selection; comparison: Selection }
) {
  if (filterThemes) {
    baseline.themes = themeHelpers.filterThemes(baseline.themes, filterThemes);
    selection.themes = themeHelpers.filterThemes(
      selection.themes,
      filterThemes
    );
  }

  if (
    !selection.themes ||
    !baseline.themes ||
    !selection.counts ||
    !baseline.counts
  ) {
    return;
  }
  baseline.title = selections.baseline.title;
  selection.title = selections.comparison.title;

  statistics.create_sig_diff_values(selection, baseline);
  statistics.create_sig_diff_values(baseline, selection);

  const baselineCount = baseline.counts.total - baseline.counts.empty;
  const selectionCount = selection.counts.total - selection.counts.empty;

  const baselineScores = baseline.scoreAllRows;
  const selectionScores = selection.scoreAllRows;

  return {
    baselineCount,
    selectionCount,
    baselineScores,
    selectionScores,
    baselineCounts: baseline.counts,
    selectionCounts: selection.counts,
    baselineThemes: baseline.themes,
    selectionThemes: selection.themes
  };
}
