import * as React from 'react';
import { kebabCase, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import { List, Message, Segment, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ThemesStoreInterface } from 'stores/ThemesStore';

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}
@inject('themesStore')
@observer
export default class ThemeErrorList extends React.Component {
  get injected() {
    return this.props as InjectedProps;
  }
  clear = () => {
    const { themesStore: store } = this.injected;
    store.loadErrors = [];
  };
  render() {
    const { themesStore: store } = this.injected;
    const { loadErrors } = store;
    return (
      <Segment className="fullscreen">
        <Message warning={true}>
          <Message.Content>
            <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
            There were errors loading the themes file.
          </Message.Content>
        </Message>
        <List bulleted={true} size="small">
          {map(loadErrors, (error, i) => {
            const key = kebabCase(`${i}--${error}`);
            return <List.Item key={key}>{error}</List.Item>;
          })}
        </List>
        <Button onClick={this.clear}>Continue</Button>
      </Segment>
    );
  }
}
