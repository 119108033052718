import * as React from 'react';
import { Skeleton } from 'components/Shared/Skeleton';
import './email-preview-skeleton.scss'

export const EmailPreviewSkeleton = () => {
  return (
    <div className="email-preview-skeleton">
      <div className="email-preview-skeleton__container">
        <header className="email-preview-skeleton__icon">
          <Skeleton height={34} width="25%" />
        </header>
        <div className="email-preview-skeleton__sender">
          <Skeleton height={14} width="100%" />
        </div>
        <div className="email-preview-skeleton__title">
          <Skeleton height={66} width="100%" />
        </div>
        <ul className="email-preview-skeleton__content">
          <li className="email-preview-skeleton__content-title">
            <Skeleton height={18} width="80%" />
          </li>
          <li className="email-preview-skeleton__content-text">
            <Skeleton height={14} width="100%%" />
          </li>
          <li className="email-preview-skeleton__content-text">
            <Skeleton height={14} width="90%" />
          </li>
          <li className="email-preview-skeleton__content-text">
            <Skeleton height={14} width="100%" />
          </li>
          <li className="email-preview-skeleton__content-text">
            <Skeleton height={14} width="90%" />
          </li>
          <li className="email-preview-skeleton__content-text">
            <Skeleton height={14} width="100%" />
          </li>
        </ul>
      </div>
    </div>
  );
};
