import { SurveyDataStatus, SurveyStatus } from 'api/enums';
import { DataSourceIntegration } from 'api/interfaces';
import { ThemesObject } from 'lib/filters/theme-filter-helper';
import * as _ from 'lodash';
import { find, first, includes, kebabCase, reduce } from 'lodash';
import { action, computed, observable } from 'mobx';
import { FilterStoreInterface } from 'stores/FilterStore';
import { UrlParametersStoreInterface } from 'stores/UrlParametersStore';
import { PlainComment, Thread } from 'types/custom';
import ExploreTool from 'vue/explore/ExploreTool.vue';
import Feedback from 'vue/feedback/Feedback.vue';
import { Block } from 'lib/segments-to-blocks';
import OverTime from 'vue/over-time/OverTime.vue';
import SignificantChanges from 'vue/over-time/SignificantChanges.vue';
import { AnalysisConfigStoreInterface } from '../AnalysisConfigStore';
import { NotificationStoreInterface } from '../NotificationStore';
import { OrganizationStoreInterface } from '../OrganizationStore';
import { SurveyStoreInterface } from '../SurveyStore';
import { ThemesStoreInterface } from '../ThemesStore';
import { QuickEditUIStoreInterface } from './QuickEditUIStore';

export const COMPONENTS = {
  EXPLORE: ExploreTool,
  OVERTIME: OverTime,
  SIGNIFICANTCHANGES: SignificantChanges,
  FEEDBACK: Feedback
};

export const EXPLORE_VIS = {
  urlSlug: 'explore',
  name: 'Themes',
  icon: 'tag',
  type: 'EXPLORE',
  showVerticalComments: true,
  enableComparisonFilter: true,
  needsOnboarding: true,
  needsUpgrade: false,
};

export const OVERTIME_VIS = {
  urlSlug: 'over-time',
  name: 'Score Change',
  icon: 'chart-line',
  type: 'OVERTIME',
  showVerticalComments: true,
  enableComparisonFilter: false,
  needsOnboarding: false,
  needsUpgrade: true,
};

export const SIGNIFICANTCHANGES_VIS = {
  urlSlug: 'significant-changes',
  name: 'Significant Changes',
  icon: 'meteor',
  type: 'SIGNIFICANTCHANGES',
  showVerticalComments: true,
  enableComparisonFilter: false,
  needsOnboarding: false,
  needsUpgrade: true,
};

export const FEEDBACK_VIS = {
  urlSlug: 'feedback',
  name: 'Feedback',
  icon: 'table',
  type: 'FEEDBACK',
  showVerticalComments: false,
  enableComparisonFilter: false,
  needsOnboarding: false,
  needsUpgrade: false,
};

export const VIS_TYPES = {
  EXPLORE: EXPLORE_VIS,
  OVERTIME: OVERTIME_VIS,
  SIGNIFICANTCHANGES: SIGNIFICANTCHANGES_VIS,
  FEEDBACK: FEEDBACK_VIS
};

export enum AxisType {
  Volume = 'volume',
  Impact = 'impact',
  Score = 'score',
}

export enum ViewOverlay {
  None = 'none',
  Sentiment = 'sentiment',
}

export interface ThemeCodes {
  base: string | undefined;
  sub?: string | null;
}

interface VisConfig {
  name: string;
  type: string;
  icon: string;
  urlSlug: string;
  showVerticalComments: boolean;
  enableComparisonFilter: boolean;
  needsOnboarding: boolean;
  needsUpgrade: boolean;
}

interface ThemeFilterDimension {
  id: string;
  cuts: string[];
  name: string;
  column: number;
}

interface CommentSelection {
  title: string;
  query: string;
  count: number;
  sentimentCounts: {
    neg: number;
    neut: number;
    pos: number;
  };
}

interface CommentsSelections {
  selections: {
    subTitle: string;
    baseline: CommentSelection | null;
    comparison: CommentSelection | null;
  };
}

export interface ThemeFilterSet {
  theme: { name: string; code: string | null };
  subtheme: { name?: string; code?: string | null };
}

export interface RemoveThemeParams {
  commentId: string | number;
  highlightedSentences: Array<string>;
  selectedTheme: {
    base: string,
    baseTitle: string,
    locations?: number[],
    sentiment?: number,
    sub: string,
    subTitle: string,
    title: string,
  };
  selectedBlock?: Block;
}

interface Observables {
  axisType: AxisType;

  baselineComments: PlainComment[] | Thread[];
  comparisonComments: PlainComment[] | Thread[];

  currentSurveyId?: string;
  surveyNeedsOnboarding: boolean;
  surveyNeedsUpgrade: boolean;
  doesSurveyNeedReview: boolean;
  currentSurveyDataSource?: DataSourceIntegration;
  isSurveyLimited: boolean;

  viewOverlay: ViewOverlay;
  toolUrlSlug?: string;
  currentViewId?: string;
  areSupportMessagesVisible: boolean;
  shouldShowFullMessages: boolean;

  themeFilterSet: ThemeFilterSet;
  commentsSelection: CommentsSelections;
  themeFilterDimension: ThemeFilterDimension;
  isRemoveThemeDialogVisible: boolean;
  removeThemeParams: RemoveThemeParams;
  isExploreToolLoading: boolean;
  exportAssistantIsOpen: boolean;
}

interface Computed {
  visType?: string;
  visConfig?: VisConfig;
  defaultVis?: VisConfig;
  filteredVisualizations: VisConfig[];

  isFeedbackTool: boolean;
  enableComparisonFilter: boolean;
  toolNeedsOnboarding: boolean;
  toolNeedsUpgrade: boolean;
  toolNeedsReview: boolean;
  showAnalysisTool: boolean;
  showVerticalComments: boolean;
  showSentiment: boolean;
  showSentimentFilterOnExploreTool: boolean;

  selectedSubthemeName: string;
  selectedThemeName: string;
  selectedThemeCodes: ThemeCodes;
}

interface Actions {
  setAxisType: (axisType: AxisType) => void;
  setCurrentTool: (urlSlug: string) => void;
  initializeAnalysisToolsUI: (surveyId: string, visId: string, viewId?: string) => void;
  reset: () => void;
  toggleSupportMessageVisibility: () => void;
  toggleFullMessageVisibility: () => void;
  setViewOverlay: (viewOverlay: ViewOverlay) => void;

  resetAnalysis: () => void;

  getComments: (type: 'baseline' | 'comparison') => PlainComment[] | Thread[];
  setComments: (type: 'baseline' | 'comparison', comments: PlainComment[] | Thread[]) => void;
  setCommentsSelection: (commentsSelection: CommentsSelections) => void;

  setThemeState: (themeState: { theme: string; subtheme: string }) => void;
  setThemeFilterDimension: (newDimension: ThemeFilterDimension) => void;
  showRemoveThemeDialog: (options: RemoveThemeParams) => void;
  hideRemoveThemeDialog: () => void;
  setExploreToolLoading: (value: boolean) => void;
  showExportAssistant: () => void;
  closeExportAssistant: () => void;
}

export interface AnalysisToolsUIStoreInterface extends Observables, Computed, Actions {
  getThemeFilterDimension: (filters: ThemeFilterDimension[]) => ThemeFilterDimension;
  rqlForThemes: (theme: string, subtheme: string) => string;
}

class AnalysisToolsUIStore implements AnalysisToolsUIStoreInterface {
  analysisConfigStore: AnalysisConfigStoreInterface;
  organizationStore: OrganizationStoreInterface;
  themesStore: ThemesStoreInterface;
  notificationStore: NotificationStoreInterface;
  quickEditUIStore: QuickEditUIStoreInterface;
  surveyStore: SurveyStoreInterface;
  filterStore: FilterStoreInterface;
  urlParametersStore: UrlParametersStoreInterface;

  //#region Observables
  @observable
  axisType = AxisType.Volume;

  @observable
  baselineComments = [] as PlainComment[] | Thread[];

  @observable
  comparisonComments = [] as PlainComment[] | Thread[];

  @observable
  currentSurveyId?: string = undefined;

  @observable
  surveyNeedsOnboarding = false;

  @observable
  surveyNeedsUpgrade = false;

  @observable
  doesSurveyNeedReview = false;

  @observable
  currentSurveyDataSource?: DataSourceIntegration = undefined;

  @observable
  isSurveyLimited = false;

  @observable
  viewOverlay = ViewOverlay.None;

  @observable
  toolUrlSlug?: string = undefined;

  @observable
  currentViewId?: string = undefined;

  @observable
  areSupportMessagesVisible = false;

  @observable
  shouldShowFullMessages = true;

  @observable
  themeFilterSet = {} as ThemeFilterSet;

  @observable
  commentsSelection = {} as CommentsSelections;

  @observable
  themeFilterDimension = {} as ThemeFilterDimension;

  @observable
  isRemoveThemeDialogVisible = false;

  @observable
  isExploreToolLoading = true;

  @observable
  removeThemeParams = {} as RemoveThemeParams;

  @observable
  exportAssistantIsOpen = false;
  //#endregion Observables

  constructor(
    analysisConfigStore: AnalysisConfigStoreInterface,
    organizationStore: OrganizationStoreInterface,
    themesStore: ThemesStoreInterface,
    notificationStore: NotificationStoreInterface,
    quickEditUIStore: QuickEditUIStoreInterface,
    surveyStore: SurveyStoreInterface,
    filterStore: FilterStoreInterface,
    urlParametersStore: UrlParametersStoreInterface,
  ) {
    this.analysisConfigStore = analysisConfigStore;
    this.organizationStore = organizationStore;
    this.surveyStore = surveyStore;
    this.themesStore = themesStore;
    this.notificationStore = notificationStore;
    this.quickEditUIStore = quickEditUIStore;
    this.filterStore = filterStore;
    this.urlParametersStore = urlParametersStore;
  }

  //#region Computed
  @computed
  get visType() {
    return this.visConfig?.type;
  }

  @computed
  get visConfig() {
    const { defaultVis, filteredVisualizations, toolUrlSlug } = this;

    if (toolUrlSlug) {
      return find(filteredVisualizations, (vis) => vis.urlSlug === toolUrlSlug) || defaultVis;
    } else {
      return defaultVis;
    }
  }

  @computed
  get defaultVis() {
    const { filteredVisualizations } = this;
    return first(filteredVisualizations);
  }

  @computed
  get filteredVisualizations() {
    const { config, hasDate, hasScore, excludeSignificantChanges } = this.analysisConfigStore;
    if (!config) {
      return [];
    }

    const obsoleteTypes = [
      'SCOREIMPACT',
      'BARGRAPH',
      'THEMEDETAIL',
      'SCOREGRAPH',
      'OVERTIME',
      'CORRELATION',
      'PANDRGRAPH',
      'SENTIMENT',
      'THEMECLOUD',
    ];

    const initialVis = [] as VisConfig[];

    initialVis.push(VIS_TYPES.EXPLORE);

    if (hasScore && hasDate) {
      initialVis.push(VIS_TYPES.OVERTIME);
      if (!excludeSignificantChanges) {
        initialVis.push(VIS_TYPES.SIGNIFICANTCHANGES);
      }
    }
    let finalVis = reduce(
      config.visualizations,
      (result, vis) => {
        let { type } = vis;

        const obsolete = includes(obsoleteTypes, type);
        // If visualisation doesn't have a name, it displays an empty menu
        if (!obsolete && vis.name) {
          let { icon, name, urlSlug } = VIS_TYPES[type] || {};
          if (!urlSlug) {
            urlSlug = kebabCase(vis.name);
          }
          result.push({
            ...vis,
            name,
            type,
            icon,
            urlSlug,
            showVerticalComments: true,
            enableComparisonFilter: true,
            needsOnboarding: false,
            needsUpgrade: false,
          });
        }
        return result;
      },
      initialVis,
    );

    finalVis.push(VIS_TYPES.FEEDBACK);

    return finalVis;
  }

  @computed
  get isFeedbackTool() {
    return this.visType === 'FEEDBACK';
  }

  @computed
  get enableComparisonFilter() {
    const { visConfig } = this;
    if (!visConfig) {
      return false;
    }
    const { type = '' } = visConfig;
    if (type in VIS_TYPES) {
      return VIS_TYPES[type].enableComparisonFilter;
    }
    return true;
  }

  @computed
  get toolNeedsOnboarding() {
    return this.surveyNeedsOnboarding && !!this.visConfig?.needsOnboarding;
  }

  @computed
  get toolNeedsUpgrade() {
    return this.surveyNeedsUpgrade && !!this.visConfig?.needsUpgrade;
  }

  @computed
  get toolNeedsReview() {
    return !this.isFeedbackTool && this.doesSurveyNeedReview;
  }

  @computed
  get showAnalysisTool() {
    const { isThematicAdmin } = this.analysisConfigStore;
    return isThematicAdmin || !(this.toolNeedsReview || this.toolNeedsOnboarding || this.toolNeedsUpgrade);
  }

  @computed
  get showVerticalComments() {
    if (!this.showAnalysisTool) {
      return false;
    }
    const { visConfig } = this;
    if (!visConfig) {
      return false;
    }
    const { type = '' } = visConfig;
    if (type in VIS_TYPES) {
      return VIS_TYPES[type].showVerticalComments;
    }
    return true;
  }

  @computed
  get showSentiment() {
    const { hasSentiment } = this.analysisConfigStore;
    return hasSentiment;
  }

  @computed
  get showSentimentFilterOnExploreTool() {
    return this.showSentiment && this.axisType === AxisType.Volume;
  }

  @computed
  get selectedSubthemeName() {
    return _.get(this, 'themeFilterSet.subtheme.name', null);
  }

  @computed
  get selectedThemeName() {
    return _.get(this, 'themeFilterSet.theme.name', null);
  }

  @computed
  get selectedThemeCodes() {
    return {
      base: _.get(this, 'themeFilterSet.theme.code', ''),
      sub: _.get(this, 'themeFilterSet.subtheme.code', null),
    };
  }
  //#endregion Computed

  //#region Actions
  @action
  setComments(type: 'baseline' | 'comparison', comments: PlainComment[] | Thread[]) {
    if (type === 'baseline') {
      this.baselineComments = comments;
      return;
    }
    this.comparisonComments = comments;
  }

  @action
  setExploreToolLoading = (loading: boolean) => {
    this.isExploreToolLoading = loading;
  };

  @action getComments(type: 'baseline' | 'comparison'): PlainComment[] | Thread[] {
    return type === 'baseline'
      ? this.baselineComments
      : this.comparisonComments;
  }

  @action
  clearComments(type: 'baseline' | 'comparison') {
    if (type === 'baseline') {
      this.baselineComments = [];
      return;
    }
    this.comparisonComments = [];
  }

  @action
  setAxisType = (axisType: AxisType) => {
    this.axisType = axisType;
  };

  @action
  setCurrentTool = (urlSlug: string) => {
    this.toolUrlSlug = urlSlug;
  };

  @action
  initializeAnalysisToolsUI = async (surveyId: string, visId: string, viewId?: string) => {
    if (this.currentSurveyId !== surveyId) {
      // when the survey changes we clear notification, transforms and
      // cancel the status poll (so a new status poll can be created for another survey)
      this.notificationStore.clear();
      this.themesStore.transforms = [];
      this.themesStore.cancelStatusPoll();
    }
    this.reset();
    this.currentSurveyId = surveyId;
    const surveyStatus = await this.surveyStore.getSurveyStatus(surveyId);
    const surveyDataSource = await this.surveyStore.getSurveyDataSource(surveyId);
    const surveyDataStatus = await this.surveyStore.getSurveyDataStatus(surveyId);

    // Avoid fetching theme status for hidden surveys.
    let themesStatus:string|null = null;
    if (surveyStatus === SurveyStatus.NORMAL) {
       themesStatus = await this.themesStore.getThemesStatusOnce(surveyId);
    }

    this.currentViewId = viewId;
    this.isSurveyLimited = surveyStatus === SurveyStatus.LIMITED;
    this.doesSurveyNeedReview = surveyDataStatus === SurveyDataStatus.REVIEWING;
    this.currentSurveyDataSource = surveyDataSource;
    this.surveyNeedsUpgrade = this.isSurveyLimited;
    if (themesStatus === 'applying') {
      this.quickEditUIStore.fetchEditedComments(surveyId);
    } else {
      this.quickEditUIStore.removeEditedComments(surveyId);
    }
  };

  @action
  reset = () => {
    this.surveyNeedsOnboarding = false;
    this.surveyNeedsUpgrade = false;
    this.doesSurveyNeedReview = false;
    this.currentSurveyDataSource = undefined;
    this.isSurveyLimited = false;
    this.currentSurveyId = undefined;
    this.currentViewId = undefined;
    this.axisType = AxisType.Volume;
    this.viewOverlay = ViewOverlay.None;
  };

  @action
  toggleSupportMessageVisibility() {
    this.areSupportMessagesVisible = !this.areSupportMessagesVisible;
  }

  @action
  toggleFullMessageVisibility() {
    this.shouldShowFullMessages = !this.shouldShowFullMessages;
  }

  @action
  setViewOverlay = (viewOverlay: ViewOverlay) => {
    this.viewOverlay = viewOverlay;
  };

  @action
  resetAnalysis() {
    this.themeFilterSet = {} as ThemeFilterSet;
    this.commentsSelection = {} as CommentsSelections;
    this.themeFilterDimension = {} as ThemeFilterDimension;
  }

  @action
  setCommentsSelection(commentsSelection: CommentsSelections) {
    this.commentsSelection = Object.assign({}, commentsSelection);
  }

  @action
  setThemeState({ theme: themeName, subtheme: subthemeName }: { theme: string; subtheme: string }) {
    const themeFilterSet: ThemeFilterSet = {
      theme: { name: themeName, code: null },
      subtheme: { name: subthemeName, code: null },
    };

    const hierarchy = this.themesStore.themesHierarchy as ThemesObject;

    themeFilterSet.theme.code = _.get(hierarchy, `${ themeName }.id`, null) as unknown as string;
    const subthemeKey = `${ themeName }.subthemes.${ subthemeName }.id`;
    themeFilterSet.subtheme.code = _.get(hierarchy, subthemeKey, null) as unknown as string;

    // if equivalent, nothing to do
    if (_.isEqual(this.themeFilterSet, themeFilterSet)) {
      return;
    }

    this.themeFilterSet = themeFilterSet;

    this.urlParametersStore.setParameter('theme', themeName);
    this.urlParametersStore.setParameter('subtheme', subthemeName);

    if (hierarchy) {
      // TODO: the translation to ids should be done before pushing into the filter
      this.filterStore.setThemesFilter(themeFilterSet, hierarchy);
    }
  }

  @action
  setThemeFilterDimension(newDimension: ThemeFilterDimension) {
    this.themeFilterDimension = newDimension;
  }

  @action
  showRemoveThemeDialog(options: RemoveThemeParams) {
    this.isRemoveThemeDialogVisible = true;
    this.removeThemeParams = options;
  }

  @action
  hideRemoveThemeDialog() {
    this.isRemoveThemeDialogVisible = false;
    this.removeThemeParams = {} as RemoveThemeParams;
  }

  @action
  showExportAssistant() {
    this.exportAssistantIsOpen = true;
  }

  @action
  closeExportAssistant() {
    this.exportAssistantIsOpen = false;
  }

  //#endregion Actions

  getThemeFilterDimension(filters: ThemeFilterDimension[]) {
    if (filters.length === 0) {
      return {} as ThemeFilterDimension;
    }
    let ret = filters[0];
    for (const item of filters) {
      // ensure that the default selected item will have cuts
      if (!ret.cuts && item.cuts) {
        ret = item;
      }
      if (item.id === this.themeFilterDimension.id) {
        ret = item;
        break;
      }
    }
    return ret;
  }

  rqlForThemes = (theme: string, subtheme: string) => {
    const rqlTokens: string[] = [];
    const themes = this.themesStore.themesHierarchy;

    if (themes) {
      const themeObj = themes[theme];
      if (theme && themeObj && themeObj.id) {
        let addedSubtheme = false;
        if (themeObj.subthemes) {
          const subthemeObj = themeObj.subthemes[subtheme];
          if (subtheme && subthemeObj && subthemeObj.id) {
            rqlTokens.push(`theme==*!${ themeObj.id }!${ subthemeObj.id }$*`);
            addedSubtheme = true;
          }
        }
        if (!addedSubtheme) {
          rqlTokens.push(`theme==*!${ themeObj.id }!*`);
        }
      }
    }

    return rqlTokens.join(';');
  };
}

export default AnalysisToolsUIStore;
