import { AnswersDataSet, ErrorMessage } from 'api/interfaces';
import FeatureFeedbackButton from 'components/FeatureFeedback/FeatureFeedbackButton';
import * as React from 'react';
import { Consumer } from '../utils/context';
import { focusAskFormInput } from '../utils/focusAskFormInput';
import { getErrorTitle } from '../utils/getErrorTitle';
import { getSavedAnswerId } from '../utils/getSavedAnswerId';
import { Warning } from './Warning';
import './no-data.scss';
import { ContextNoData } from './ContextNoData';
import NoDataIcon from 'images/icons/no-data.svg';
import { Button } from 'components/Shared/Button';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, { errorCode: 'NO_DATA' | 'NO_DATA_PAIR_COMPARISON' }>;
  datasets: AnswersDataSet[];
  onRefineQuery: (question: string) => void;
}

export const NoData = (props: Props) => {
  const [isRefining, setIsRefining] = React.useState(false);

  const data = props.error.data;

  const hasComparison: boolean = 'metadata' in data && 'comparisons' in data.metadata;
  const title = getErrorTitle(props.question);
  const feedbackData = {
    error: props.error,
    timestamp: new Date().toISOString(),
    question: props.question,
  };
  const savedAnswerId = getSavedAnswerId();
  const locationName = `answers:${savedAnswerId || 'new'}`;

  const handleRefineQuery = () => {
    setIsRefining(true);
    props.onRefineQuery(props.question || '');
  };

  const warningTitle = hasComparison
    ? "We were unable to find data for one or both groups of feedback based on your query."
    : "We were unable to find data matching your query."

  const warningDescription = hasComparison
    ? "Adjust your query or filters for better results."
    : "The information might not be collected yet or outside our datasets. Adjust your query or filters for better results.";

  return (
    <Consumer>
      {({ askParams }) => (
        <section className="answers__no-data">
          <h4 className="answers__no-data-title">{title}</h4>

          <Warning
            title={warningTitle}
            icon={<NoDataIcon className="no-data-icon" />}
          >
            <p
              className="answers-warning__description"
              data-testid="advice"
            >
              {warningDescription}
            </p>
          </Warning>
          <div className="answers__no-data-context">
            <ContextNoData
              answerDataSets={props.datasets}
              answerFilters={data?.filters}
              comparison={'metadata' in data ? data.metadata?.comparisons : undefined}
            />
            <div className="answers__no-data-toolbar" role="toolbar">
              <FeatureFeedbackButton
                featureId="answers"
                location={locationName}
                rating={1}
                modalTextConfig={{
                  title: 'Give feedback',
                  subTitle: 'Your feedback helps us to continuously improve our answers.',
                  textAreaPlaceholder: 'Tell us what is missing in your data, or give any other feedback.'
                }}
                data={feedbackData}
                buttonSubvariant='neutral-text'
                onModalUnmount={() => focusAskFormInput()}
              />
              {askParams.dataSets.length > 0 && (
                <Button
                  basic={true}
                  color="blue"
                  size="medium"
                  disabled={isRefining}
                  onClick={handleRefineQuery}
                >
                  Refine query
                </Button>
              )}
            </div>
          </div>
        </section>
      )}
    </Consumer>
  );
};
