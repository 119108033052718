import * as React from 'react';
import { Modal } from 'components/Shared/Modal';
import './finalize-email-modal.scss';
import { Button } from 'components/Shared/Button';

interface FinalizeEmailModalProps {
  emails: string[];
  onClose: () => void;
}

export const FinalizeEmailModal = ({
  emails,
  onClose
}: FinalizeEmailModalProps) => {

  return (
    <Modal
      open={true}
      size="small"
      closeOnEscape={true}
      onClose={onClose}
    >
      <div
        className="answers-finalize-email-modal"
      >
        <header className="answers-finalize-email-modal__header">
          Email Sent
        </header>
        <div className="answers-finalize-email-modal__content">
          <h3 className="answers-finalize-email-modal__title">Email sent to:</h3>
          <ul className="answers-finalize-email-modal__list">
            {emails.map((email, index) => (
              <li
                key={index}
              >
                {email}
              </li>
            ))}
          </ul>
        </div>
        <div className="answers-email-modal__footer">
          <Button
            variant="secondary"
            subvariant="white"
            size="medium"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
