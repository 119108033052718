

























import { debounce } from 'lodash';
import thematicData from 'vue/libs/thematicData';
import SummaryItem from "vue/components/Summary/SummaryItem.vue";
import { watchAll } from 'vue/libs/vueExt';
import {
  getAnalysisToolsUIStore,
  getInitConfigStore,
} from 'stores/RootStore';
import queryBuilder from 'vue/libs/queryBuilder';

import analytics from 'lib/analytics';

export default {
  name: 'ScoreChangeSummary',
  components: {
    SummaryItem,
  },
  props: {
    currentPeriod: { default: undefined, type: Object },
    previousPeriod: { default: undefined, type: Object }
  },
  data() {
    return {
      initConfigStore: getInitConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      summary: "",
      hasError: false,
      isLoading: true
    };
  },
  mounted() {

    watchAll(this, ['currentPeriod', 'previousPeriod'], this.fetchSummary);

    this.fetchSummary();

  },
  methods: {
    getLocationForFeedback() {
      const surveyId = this.analysisToolsUIStore.currentSurveyId || '';

      return `analysis:${surveyId}:score-change`;
    },
    onTextCopied() {
      analytics.track('Analysis: Copy Summary',
        {
          'Location': 'Score Change',
        }
      );
    },
    onTextSelection() {
      analytics.track('Analysis: Select Summary',
        {
          'Location': 'Score Change',
        }
      );
    },
    fetchSummary() {

      const { currentPeriod, previousPeriod } = this;

      if (!currentPeriod || !previousPeriod) {
        return;
      }

      const [currentStart, currentEnd] = currentPeriod.period.split(':');
      const [previousStart, previousEnd] = previousPeriod.period.split(':');

      const filter = queryBuilder.buildDateQuery('date', currentStart, currentEnd);
      const comparisonFilter = queryBuilder.buildDateQuery('date', previousStart, previousEnd);

      const options = {
        ...this.initConfigStore.requestOptions,
        comparisonFilter,
        summarize: true,
        cluster: false,
      };

      thematicData.clearThemeSummarizationCache();

      this.fetchSummaryDebounced(filter, options);

    },
    fetchSummaryDebounced: debounce(async function(filter, options) {

      this.isLoading = true;
      this.hasError = false;

      try {
        const responseBody =  await thematicData.getThemeSummarization(
          filter,
          'volume',
          options,
        );

        this.summary = responseBody.data.summary;

      } catch(e) {

        this.hasError = true;

        if (e.code !== 'ERR_CANCELED') {
          throw e;
        }

      } finally {

        this.isLoading = false;

      }

    }, 800)

  },
};
