import { QueryFilter } from 'stores/types';
import { FilterAction } from 'types/custom';
import { createThemeQueryFilter } from './theme-filter-helper';
import { createCategoryQueryFilter } from './category-filter-helper';
import { createSentimentQueryFilter } from './sentiment-filter-helper';
import { createFreeTextQueryFilter } from './free-text-filter-helper';
import { createTagQueryFilter } from './tag-filter-helper';
import { createHierarchicalQueryFilter } from './hierarchical-filter-helper';
import { AnalysisDateFilter, AnalysisFilter } from 'api/interfaces';
import { createDateQueryFilter } from './date-filter-helper';
import { FilterConfig } from 'components/Filters/types';

export function createQueryFilter(
  action: FilterAction,
  filter: AnalysisFilter,
  config: FilterConfig
): QueryFilter | null {
    switch (action.type) {
      case 'date':
        return createDateQueryFilter(
          filter as AnalysisDateFilter,
          config.filterKey,
          action.startDate,
          action.endDate
        );
      case 'hierarchical':
        return createHierarchicalQueryFilter(
          filter,
          config.filterKey,
          action.options
        );
      case 'tags':
        return createTagQueryFilter(
          filter,
          config.filterKey,
          action.options,
          config.commentColumns
        );
      case 'themes':
        return createThemeQueryFilter(
          filter,
          config.filterKey,
          action.options,
          action.match
        );
      case 'categories':
         return createCategoryQueryFilter(
          filter,
          config.filterKey,
          action.options
        );
      case 'sentiment':
        return createSentimentQueryFilter(
          filter,
          config.filterKey,
          action.options
        );
      case 'freetext':
        return createFreeTextQueryFilter(
          filter,
          config.filterKey,
          action.searches,
          action.match
        );
      default:
        return null;
    }
}
