import * as React from 'react';
import {
  find,
  findIndex,
  forEach,
  isEmpty,
  reduce,
  reject,
  throttle
} from 'lodash';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { observer, inject } from 'mobx-react';

import './theme-tree.scss';
import ThemeCard from './ThemeCard';
import HintCancel from './HintCancel';
import { ThemeGroup } from 'lib/theme-file-parser';
import { ThemeTreeItem, ThemesStoreInterface } from 'stores/ThemesStore';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';
import { compose } from 'lib/composeHOCs';
import NotificationStore from 'stores/NotificationStore';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { ThemeMultiActions } from '../ThemeEditor/ThemeMultiActions';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import { ContextMenuDirection, ThemeTreeContextMenu } from './ThemeTreeContextMenu';

interface ThemeCardRef {
  id: string;
  parentId?: string;
  themeCardEl: HTMLDivElement;
}

interface ContextMenuPosition {
  top: number;
  left: number;
}

export interface ThemeCardInterface {
  id: string;
  parentId?: string;
}

interface ThemeHintPosition {
  child?: boolean;
  topHalf?: boolean;
  promote?: boolean;
  overMerge?: boolean;
  merge?: boolean;
}

export interface HoverHint extends ThemeHintPosition, ThemeCardInterface {}

interface ThemeTreeStoreProps {
  notificationStore: NotificationStore;
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

interface ThemeTreeProps extends ThemeTreeStoreProps {
  clearMultipleSelect: () => void;
  toggleMultipleSelectItem: (themeCard: ThemeCardInterface) => void;
  multipleSelectItems: ThemeCardInterface[];
  onContextMenuClose: () => void;
  onReviewUntagged: () => void;
  group: ThemeGroup;
  contextMenuPosition: ContextMenuPosition | null;
  contextMenuDirection: ContextMenuDirection;
  expanded: boolean;
}
interface ThemeTreeState {
  currentFocus: number,
  hoverHint?: HoverHint;
  refsCollection: React.RefObject<ThemeCardRef>[];
}

type TopLevelPath = [number];
type ChildPath = [number, number];
type Path = TopLevelPath | ChildPath;

function pathDistance(p1: Path, p2: Path): number {
  // If both paths are top-level
  if (p1.length === 1 && p2.length === 1) {
    return Math.abs(p1[0] - p2[0]);
  }

  // If both paths are child-level
  if (p1.length === 2 && p2.length === 2) {
    // If they're in the same top-level group
    if (p1[0] === p2[0]) {
      return Math.abs(p1[1] - p2[1]);
    }
    // If they're in different top-level groups
    return Math.abs(p1[0] - p2[0]);
  }

  // If one path is top-level and the other is child-level
  if (p1.length !== p2.length) {
    const topLevel = p1.length === 1 ? p1[0] : p2[0];
    const childLevel = p1.length === 2 ? p1 : p2;
    return Math.abs(topLevel - childLevel[0]);
  }

  // This should never happen if the input is correct
  throw new Error("Invalid path combination");
}

const withHocs = compose(
  inject('notificationStore', 'themesStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class ThemeTree extends React.Component<
  ThemeTreeProps,
  ThemeTreeState
> {
  canSeeMovableThemes = false;

  constructor(props: ThemeTreeProps) {
    super(props);
    this.state = {
      currentFocus: 0,
      hoverHint: undefined,
      refsCollection: this.initializeAltRefsCollection(props.group.nodes)
    };
  }

  initializeAltRefsCollection = (nodes) => {
    return nodes.map(() => React.createRef());
  };

  componentDidUpdate(prevProps) {
    const expandedHasUpdated = prevProps.expanded !== this.props.expanded;
    const nodesHaveUpdated = prevProps.group.nodes !== this.props.group.nodes;
    if (nodesHaveUpdated || expandedHasUpdated) {
      const refsCollection = this.initializeAltRefsCollection(this.props.group.nodes);
      this.setState({ refsCollection });
    }
  }
  componentDidMount = () => {
    this.canSeeMovableThemes = !!FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_MOVABLE_THEMES_IN_THEME_EDITOR);
    this.moveHint = throttle(this.moveHint, 50, { trailing: true });

    const canSeeMovableThemes = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_MOVABLE_THEMES_IN_THEME_EDITOR);

    if (canSeeMovableThemes) {
      this.addKeyboardShortCuts();
    }
  };
  addKeyboardShortCuts = () => {
    window.addEventListener('keydown', (event) => {
      if (!this.state.refsCollection) {
        return;
      }

      this.handleKeyDown(event);
    });
  };
  getNextIndex = (key: string) => {
    const { currentFocus } = this.state;
    const focusNext = key === 'ArrowDown';
    const nextIndex = focusNext ? currentFocus + 1 : currentFocus - 1;
    const lastIndex = this.state.refsCollection.length - 1;

    if (nextIndex > lastIndex) {
      return 0;
    }

    if (nextIndex < 0) {
      return lastIndex;
    }

    return nextIndex;
  };
  nextFocus = (event: KeyboardEvent) => {
    const nextIndex = this.getNextIndex(event.key);

    event.preventDefault();

    this.setState({
      currentFocus: nextIndex
    });

    this.state.refsCollection[nextIndex].current?.themeCardEl.focus();
  };
  handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      this.nextFocus(event);
    }
  };
  handleMultiSelect = (id: string, parentId?: string) => {
    const { toggleMultipleSelectItem, group } = this.props;

    const selectedId = group.activeNodeId;

    if (selectedId) {
      const parentTheme = this.findSubThemeParent(selectedId);

      if (!parentTheme) {
        return;
      }

      toggleMultipleSelectItem({
        id: selectedId,
        parentId: parentTheme.id
      });
    }

    group.activeNodeId = undefined;

    toggleMultipleSelectItem({
      id,
      parentId
    });
  }
  findParent = (id?: string) => {
    const { nodes: themes } = this.props.group;
    return find(themes, { id });
  };
  findTheme = (item: ThemeCardInterface) => {
    const parent = this.findParent(item.parentId);

    if (parent) {
      return find(parent.children, { id: item.id });
    } else {
      return this.findParent(item.id);
    }
  };
  findSubThemeParent = (id: string) => {
    const { nodes } = this.props.group;

    return nodes.find((node) => {
      return node?.children?.some((child) => child.id === id);
    });
  };
  promoteCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const { group, themesStore } = this.props;
    // item we're moving
    const promoted = this.findTheme(dragItem);
    if (!promoted) {
      return;
    }
    const { nodes: themes } = group;

    if (dragItem.parentId) {
      // move subtheme
      const movedParent = this.findParent(dragItem.parentId);
      if (movedParent) {
        movedParent.children = reject(
          movedParent.children,
          c => c.id === dragItem.id
        );
      }
      // we're moving a theme
    } else {
      const removeIndex = findIndex(themes, { id: dragItem.id });
      themes.splice(removeIndex, 1);
    }
    let targetIndex;
    if (dropHint.parentId) {
      targetIndex =
        findIndex(themes, { id: dropHint.parentId }) +
        (dropHint.topHalf ? 0 : 1);
    } else {
      targetIndex =
        findIndex(themes, { id: dropHint.id }) + (dropHint.topHalf ? 0 : 1);
    }
    themes.splice(targetIndex, 0, {
      ...promoted,
      children: promoted.children || [],
      expanded: false
    });

    // update data
    themesStore.moveTheme(group, dragItem.id, undefined);

    if (this.canSeeMovableThemes) {
      const { notificationStore } = this.props;
      notificationStore.push({
        id: `${group.id}-${promoted.id}`,
        message: `Promoted '${promoted.title}' to base theme`,
        timeout: 3,
        variant: 'default'
      });
    }
  };
  revertMergeCard = (group: ThemeGroup, itemId: string) => {
    const { notificationStore, themesStore } = this.props;

    themesStore.undoTransform();

    notificationStore.clear();

    notificationStore.push({
      id: `${group.id}-${itemId}`,
      message: `Reverted merge`,
      timeout: 3,
      variant: 'default'
    });
  };
  mergeCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const { themesStore, group } = this.props;
    const { nodes: themes } = group;
    // if merging with self, abort
    if (dragItem.id === dropHint.id) {
      return;
    }
    // item we're moving
    const merged = this.findTheme(dragItem);
    if (!merged) {
      return;
    }
    // we're merging a subtheme
    if (dragItem.parentId) {
      const movedParent = this.findParent(dragItem.parentId);
      if (movedParent) {
        movedParent.children = reject(
          movedParent.children,
          c => c.id === dragItem.id
        );
      }
      // we're merging a theme
    } else {
      const removeIndex = findIndex(themes, { id: dragItem.id });
      themes.splice(removeIndex, 1);
    }

    const target = this.findTheme(dropHint);
    if (target) {
      target.merged.push(...themesStore!.toMergeItems(merged));
    }

    // update data
    themesStore!.mergeTheme(group, dragItem.id, dropHint.id);
    this.setState({ hoverHint: undefined });

    return {
      mergedTitle: merged.title,
      targetTitle: target?.title
    }
  };
  moveCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const {
      themesStore,
      themeEditorSessionStore,
      group
    } = this.props;
    const { nodes: themes } = group;

    const parent: ThemeTreeItem | undefined = dragItem.parentId ? this.findParent(dragItem.parentId) : undefined;
    const parentIndex = parent ? findIndex(themes, { id: parent.id }) : -1;

    const initialPath: Path = parent
      ? [parentIndex, findIndex(parent.children, { id: dragItem.id })]
      : [findIndex(themes, { id: dragItem.id })]

    if (dragItem.id === dropHint.id) {
      // if moving to self, abort
      return;
    } else if (dragItem.id === dropHint.parentId) {
      // if moving parent into children, abort
      return;
    }
    // item we're moving
    const moved = this.findTheme(dragItem);
    if (!moved) {
      return;
    }
    // we're moving a subtheme
    if (dragItem.parentId) {
      const movedParent = this.findParent(dragItem.parentId);
      if (movedParent) {
        movedParent.children = reject(
          movedParent.children,
          c => c.id === dragItem.id
        );
      }
      // we're moving a theme
    } else {
      const removeIndex = findIndex(themes, { id: dragItem.id });
      themes.splice(removeIndex, 1);
    }
    let targetParent;

    // it's dropping onto a subtheme
    if (dropHint.parentId) {
      targetParent = this.findParent(dropHint.parentId);
      if (targetParent) {
        const targetIndex =
          findIndex(targetParent.children, {
            id: dropHint.id
          }) + (dropHint.topHalf ? 0 : 1);
        const newChildren = [moved];
        if (moved.children) {
          newChildren.push(...moved.children);
          delete moved.expanded;
          delete moved.children;
        }

        if (targetParent.children) {
          targetParent.children.splice(targetIndex, 0, ...newChildren);
        }
      }
      // it's dropping onto a theme
    } else {
      if (dropHint.child) {
        targetParent = this.findParent(dropHint.id);
        if (targetParent) {
          const targetIndex =
            findIndex(targetParent.children, {
              id: dropHint.id
            }) + (dropHint.topHalf ? 0 : 1);
          const newChildren = [moved];
          if (moved.children) {
            newChildren.push(...moved.children);
            delete moved.expanded;
            delete moved.children;
          }

          if (targetParent.children) {
            targetParent.children.splice(targetIndex, 0, ...newChildren);
          }
        }
      } else {
        const targetIndex =
          findIndex(themes, { id: dropHint.id }) + (dropHint.topHalf ? 0 : 1);
        themes.splice(targetIndex, 0, {
          ...moved,
          children: moved.children || [],
          expanded: false
        });
      }
    }

    let finalPath:Path = [-1];

    if (dropHint.parentId) {
      const parent = this.findParent(dropHint.parentId);
      if (parent) {
        const parentIndex = findIndex(themes, { id: parent.id });
        const childIndex = findIndex(parent.children, { id: dragItem.id });
        finalPath = [parentIndex, childIndex];
      }
    } else if (dropHint.child) {
      const parent = this.findParent(dropHint.id);
      if (parent) {
        const parentIndex = findIndex(themes, { id: parent.id });
        const childIndex = findIndex(parent.children, { id: dragItem.id });
        finalPath = [parentIndex, childIndex];
      }
    } else {
      finalPath = [findIndex(themes, { id: dragItem.id })];
    }

    // update data
    themesStore.moveTheme(group, moved.id, targetParent && targetParent.id);
    themeEditorSessionStore.addEvent({
      type: 'Modify',
      subType: 'MoveTheme',
      timestamp: Date.now(),
      data: pathDistance(initialPath, finalPath)
    });

    const parentTreeItem = this.findParent(targetParent?.id);

    return {
      movedTitle: moved.title,
      parentTitle: parentTreeItem?.title,
    };
  };
  moveSingleCard = (hoverHint: HoverHint, item: ThemeCardInterface, child: boolean) => {
    const { group, notificationStore } = this.props;
    const movedItem = this.moveCard(hoverHint, item);

    const target = this.findTheme(hoverHint);

    if (child) {
      if (target && target.expanded) {
        group.activeNodeId = item.id;
      } else {
        group.activeNodeId = hoverHint.id;
      }
    } else {
      group.activeNodeId = item.id;
    }

    if (!movedItem) {
      return;
    }

    const { movedTitle, parentTitle } = movedItem;

    if (this.canSeeMovableThemes) {
      notificationStore.push({
        id: `${movedTitle}-${parentTitle}`,
        message: `Moved '${movedTitle}' to '${parentTitle}'`,
        timeout: 5,
        variant: 'default'
      });
    }
  };
  mergeSingleCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const { group, notificationStore } = this.props;
    const mergedItem = this.mergeCard(dragItem, dropHint);
    group.activeNodeId = dropHint.id;

    if (!mergedItem?.mergedTitle || !mergedItem?.targetTitle) {
      return;
    }

    if (this.canSeeMovableThemes) {
      notificationStore.push({
        id: `${dragItem.id}-${dropHint.id}`,
        action: () => this.revertMergeCard(group, dragItem.id),
        actionText: 'Undo',
        message: `Merged '${mergedItem?.mergedTitle}' into '${mergedItem?.targetTitle}'`,
        timeout: 5,
        variant: 'default'
      });
    }
  };
  moveHint = (hoverHint?: ThemeCardInterface & ThemeHintPosition) => {
    this.setState({ hoverHint });
  };
  executeHint = async (item: ThemeCardInterface) => {
    const { group } = this.props;
    const { hoverHint } = this.state;
    if (!hoverHint) {
      return;
    }
    const { child, merge, promote } = hoverHint;
    if (merge) {
      this.mergeSingleCard(item, hoverHint);
    } else if (promote) {
      this.promoteCard(item, hoverHint);
      group.activeNodeId = item.id;
    } else {
      this.moveSingleCard(item, hoverHint, !!child);
    }
  };
  addSubtheme = (title: string, parentId: string) => {
    const {
      group,
      themesStore,
      themeEditorSessionStore: sessionStore
    } = this.props;
    themesStore.addTheme(group, title, parentId);
    if (sessionStore.currentSessionId) {
      sessionStore.addEvent({
        type: 'Addition',
        subType: 'AddSubTheme',
        timestamp: Date.now()
      });
    }
  };
  focusActiveNode = (id: string) => {
    const { refsCollection } = this.state;
    const activeIndex = refsCollection.findIndex(({ current }) => {
      return current?.id === id;
    });

    if (activeIndex < 0) {
      return;
    }

    const activeCard = refsCollection[activeIndex];

    activeCard?.current?.themeCardEl?.focus();

    this.setState({ currentFocus: activeIndex });
  };
  activateNode = (id: string) => {
    const { group } = this.props;
    group.activeNodeId = id;
    this.focusActiveNode(id);
  };
  deleteTheme = (id: string, parentId?: string) => {
    const { group, themesStore } = this.props;

    themesStore!.deleteTheme(group, id);
    const parent = this.findParent(parentId);
    if (parent && parent.children) {
      const index = findIndex(parent.children, { id });
      if (index >= 0) {
        parent.children.splice(index, 1);
      }
      group.activeNodeId = parent.id;
    } else {
      const index = findIndex(group.nodes, { id });
      if (index >= 0) {
        group.nodes.splice(index, 1);
        if (group.nodes[index]) {
          group.activeNodeId = group.nodes[index].id;
        } else if (group.nodes[0]) {
          group.activeNodeId = group.nodes[0].id;
        }
      }
    }
  };
  toggleNode = (id: string) => {
    const node = this.findParent(id);
    if (node) {
      node.expanded = !node.expanded;
    }
  };
  multiActionCleanUp = () => {
    this.props.clearMultipleSelect();
    this.setState({ currentFocus: 0 });
  };
  handleMultiDelete = () => {
    const { multipleSelectItems, notificationStore } = this.props;

    const multipleSelectCount = multipleSelectItems.length;

    for (const selectItem of multipleSelectItems) {
      this.deleteTheme(selectItem.id, selectItem?.parentId);
    }

    notificationStore.push({
      id: `multi-delete-${multipleSelectCount}`,
      message: `Deleted ${multipleSelectCount} themes`,
      timeout: 5,
      variant: 'default'
    });

    this.multiActionCleanUp();
  };
  handleMultiSelectMerge = ({ theme, subtheme }: SelectedTheme) => {
    const { notificationStore } = this.props;
    const targetThemeId = {
      id: subtheme || theme,
      parentId: subtheme ? theme : undefined,
    };

    const multipleSelectItems = this.props.multipleSelectItems;
    const multipleSelectCount = multipleSelectItems.length;

    for (const selectItem of multipleSelectItems) {
      this.mergeCard(selectItem, targetThemeId);
    }

    const targetTheme = this.findTheme(targetThemeId);

    notificationStore.push({
      id: `${targetTheme?.title}`,
      message: `Merged ${multipleSelectCount} items into '${targetTheme?.title}'`,
      timeout: 5,
      variant: 'default'
    });

    this.multiActionCleanUp();
  };
  handleMultiSelectMove = ({ theme }: SelectedTheme) => {
    const { notificationStore } = this.props;
    const targetTheme = {
      id: theme,
      parentId: theme,
    };
    const multipleSelectItems = this.props.multipleSelectItems;
    const multipleSelectCount = multipleSelectItems.length;
    const parentTreeItem = this.findParent(theme);

    for (const selectItem of multipleSelectItems) {
      this.moveCard(selectItem, targetTheme);
    }

    notificationStore.push({
      id: `${parentTreeItem?.title}`,
      message: `Merged ${multipleSelectCount} items into '${parentTreeItem?.title}'`,
      timeout: 5,
      variant: 'default'
    });

    this.multiActionCleanUp();
  };
  handleDeleteTheme = (id: string, parentId?: string) => {
    const { themesStore } = this.props;

    this.deleteTheme(id, parentId);

    if (this.canSeeMovableThemes) {
      const { notificationStore } = this.props;
      const { transforms } = themesStore;
      const lastTransform = transforms[transforms.length - 1];

      notificationStore.push({
        id: `deleted-${lastTransform?.message.theme}`,
        action: () => themesStore.undoTransform(),
        actionText: 'Undo',
        message: `Deleted '${lastTransform?.message.theme}'`,
        timeout: 5,
        variant: 'default'
      })
    }
  };
  handleSelectCard = (id: string) => {
    const { clearMultipleSelect, themesStore } = this.props;

    clearMultipleSelect();

    this.activateNode(id);
    themesStore?.toggleThemeInfo(true);

  };
  render() {
    let themeCount = 0;
    const { hoverHint, refsCollection } = this.state;
    const { contextMenuDirection, contextMenuPosition, group, multipleSelectItems, clearMultipleSelect, onReviewUntagged, onContextMenuClose } = this.props;

    const { id: groupId, nodes: themes } = group;
    const hasMultiSelect = multipleSelectItems.length > 0;
    const multiSelectIds = multipleSelectItems.map(({ id }) => id);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className={classNames('theme-tree', {
          'theme-tree--show-multi-select': hasMultiSelect
        })}>
          {hasMultiSelect && (
            <div className="theme-tree__multi-actions">
              <ThemeMultiActions
                groupId={group.id}
                onClear={clearMultipleSelect}
                onDelete={this.handleMultiDelete}
                onMergeSelect={this.handleMultiSelectMerge}
                onMoveSelect={this.handleMultiSelectMove}
                multipleSelectItems={multipleSelectItems}
              />
            </div>
          )}
          <div className="theme-tree__container">
            <HintCancel cancelHint={this.moveHint} />
            {reduce(
              themes,
              (result, theme) => {
                const { id } = theme;
                let hint;
                if (hoverHint && hoverHint.id === id) {
                  hint = hoverHint;
                }

                if (!refsCollection[themeCount]) {
                  refsCollection[themeCount] = React.createRef();
                }

                result.push(
                  <ThemeCard
                    innerRef={refsCollection[themeCount]}
                    key={theme.id}
                    id={theme.id}
                    isNew={theme.isNew}
                    hasMerged={theme.merged.length > 0}
                    hasMergedNew={!!theme.hasMergedNew}
                    toReview={theme.toReview}
                    onDeleteTheme={this.handleDeleteTheme}
                    expanded={theme.expanded}
                    title={String(theme.title)}
                    hasChildren={!isEmpty(theme.children)}
                    hoverHint={hint}
                    executeHint={this.executeHint}
                    moveHint={this.moveHint}
                    selected={group.activeNodeId === theme.id || multiSelectIds.includes(theme.id)}
                    addSubtheme={this.addSubtheme}
                    toggleNode={this.toggleNode}
                    groupId={groupId}
                    onThemeCardMultiSelect={this.handleMultiSelect}
                    hasMultiSelect={hasMultiSelect}
                    onSelectCard={() => this.handleSelectCard(id)}
                  />
                );

                themeCount++;

                if (theme.expanded) {
                  forEach(theme.children, (subtheme, innerIndex, children) => {
                    let subthemeHint;
                    if (hoverHint && hoverHint.id === subtheme.id) {
                      subthemeHint = hoverHint;
                    }

                    if (!refsCollection[themeCount]) {
                      refsCollection[themeCount] = React.createRef();
                    }

                    result.push(
                      <ThemeCard
                        innerRef={refsCollection[themeCount]}
                        key={subtheme.id}
                        id={subtheme.id}
                        isNew={subtheme.isNew}
                        hasMerged={subtheme.merged.length > 0}
                        hasMergedNew={!!subtheme.hasMergedNew}
                        toReview={subtheme.toReview}
                        onDeleteTheme={this.handleDeleteTheme}
                        title={String(subtheme.title)}
                        parentId={id}
                        hoverHint={subthemeHint}
                        executeHint={this.executeHint}
                        moveHint={this.moveHint}
                        selected={group.activeNodeId === subtheme.id || multiSelectIds.includes(subtheme.id)}
                        isTail={innerIndex === children.length - 1}
                        groupId={groupId}
                        onThemeCardMultiSelect={this.handleMultiSelect}
                        hasMultiSelect={hasMultiSelect}
                        onSelectCard={() => this.handleSelectCard(subtheme.id)}
                      />
                    );

                    themeCount++;
                  });
                }
                return result;
              },
              [] as JSX.Element[]
            )}
            <HintCancel cancelHint={this.moveHint} />
            <Button className="theme-tree__review-untagged-button" onClick={onReviewUntagged}>
            Review untagged comments
            </Button>
          </div>
          {contextMenuPosition && (
            <ThemeTreeContextMenu
              direction={contextMenuDirection}
              groupId={groupId}
              position={contextMenuPosition}
              onClose={onContextMenuClose}
              onDelete={this.handleMultiDelete}
              onMergeSelect={this.handleMultiSelectMerge}
              onMoveSelect={this.handleMultiSelectMove}
            />
          )}
        </div>
      </DndProvider>
    );
  }
});
