import { AnalysisConfig, Score } from "api/interfaces";
import { RequestOptions } from "stores/InitConfigStore";

type Options = {
  selectedScore: Score | null,
  scores: Score[] | undefined,
  analysisConfig: AnalysisConfig | null
};
type Config = Partial<Pick<RequestOptions, 'scoreColumn' | 'scoreType' | 'scoreOptions'>>;


export function getRequestConfig({
  scores,
  selectedScore,
  analysisConfig
}: Options): Config {
  const selectedConfig: Score | AnalysisConfig | null = selectedScore || scores?.[0] || analysisConfig;

  return {
    scoreType:  selectedConfig?.score_type,
    scoreColumn: selectedConfig?.score_column,
    scoreOptions: selectedConfig?.score_options
  };
}
