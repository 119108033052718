
import Auth from 'Auth/Auth';
import { action } from 'mobx';

import {
  getOrganizationStore,
} from 'stores/RootStore';

export interface FeatureFeedbackStoreInterface {

  sendFeedback: (featureId: string, location: string, rating: number, description: string, data: any) => void;
}

class FeatureFeedbackStore implements FeatureFeedbackStoreInterface {

  @action
  sendFeedback = async (featureId: string, location: string, rating: number, description: string, data: any) => {
    const url = `/feedback`;
    const organization = getOrganizationStore().orgId;

    await Auth.fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data: JSON.stringify(data),
        description,
        featureId,
        location,
        organization,
        rating: rating
      })
    });

  }

}

export default FeatureFeedbackStore;
