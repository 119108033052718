<template>
  <div class="new-theme">
    <el-dialog
      :visible="isCreateThemeDialogVisible"
      width="30%"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="title">
        Add new theme
      </div>
      <el-input
        ref="inputElementNewTheme"
        v-model="newThemeInput"
        placeholder="Theme name"
      />
      <div class="new-theme-radio">
        <el-radio
          v-model="radio"
          label="baseTheme"
        >
          Base Theme
        </el-radio>
        <el-radio
          v-model="radio"
          label="subTheme"
        >
          Subtheme of
          <el-popover
            ref="popover"
            :visible-arrow="false"
            :append-to-body="false"
            placement="bottom-start"
            width="100%"
            trigger="click"
            :popper-options="popperOptions"
            @show="onShow"
          >
            <div class="new-theme-popover-content">
              <el-input
                ref="inputElement"
                v-model="filterText"
                placeholder="Search base themes"
                size="small"
              />
              <el-tree
                ref="filterTree"
                class="new-theme-options"
                :data="allThemes"
                :expand-on-click-node="false"
                :props="defaultProps"
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
              />
            </div>
          </el-popover>
          <el-button
            v-popover:popover
            size="small"
            class="select-theme-button"
          >
            <span class="select-theme-button-content">
              {{ selectedBaseTheme ? selectedBaseTheme.label : 'Select base theme' }}
              <font-awesome-icon icon="chevron-down" />
            </span>
          </el-button>
        </el-radio>
      </div>
      <span
        slot="footer"
        class="new-theme-dialog-footer"
      >
        <el-button @click="onNewThemeCancel">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="buttonValidation"
          @click="onNewThemeConfirm"
        >
          Next
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'NewTheme',
  props: {
    allThemes: { default: () => [], type: Array },
    themeName: {default: '', type: String}
  },
  data() {
    return {
      isCreateThemeDialogVisible: !!this.allThemes.length,
      newThemeInput: this.themeName,
      radio: 'baseTheme',
      filterText: '',
      selectedBaseTheme: undefined,
      popperOptions: { boundariesElement: this.$parent._uid },
      defaultProps: {label: 'label', children: ''}
    };
  },
  computed: {
    buttonValidation() {
      if (this.radio === 'subTheme') {
        return !this.newThemeInput.length || !this.selectedBaseTheme;
      } else {
        return !this.newThemeInput.length;
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.filterTree.filter(val);
    },
  },
  mounted() {
    this.focusModalInput();
  },
  methods: {
    onShow() {
      this.focusInput();
    },
    onNewThemeCancel() {
      this.isCreateThemeDialogVisible = false;
      this.onAction();
    },
    onNewThemeConfirm() {
      let newTheme;
      if (this.radio === 'baseTheme') {
        newTheme = {
          base: undefined,
          baseTitle: this.newThemeInput,
          children: [],
          id: undefined,
          label: this.newThemeInput
        };
      } else if (this.radio === 'subTheme') {
        newTheme = {
          base: this.selectedBaseTheme.base,
          baseTitle: this.selectedBaseTheme.baseTitle,
          id: undefined,
          label: this.newThemeInput,
          sub: undefined,
          subTitle: this.newThemeInput
        };
      }
      this.$emit('onThemeCreation', newTheme);
    },
    onAction() {
      this.$emit('onAction');
    },
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.inputElement) {
          this.$refs.inputElement.$refs.input.focus();
        }
      });
    },
    focusModalInput() {
      this.$nextTick(() => {
        if (this.$refs.inputElementNewTheme) {
          this.$refs.inputElementNewTheme.$refs.input.focus();
        }
      });
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    handleNodeClick(data) {
      this.selectedBaseTheme = data;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.new-theme ::v-deep .el-dialog__header {
  padding: 0;
}
.new-theme {
  .title {
    font-size: 16px;
    color: $--color-text-primary;
    padding-bottom: 25px;
    line-height: 22px;
    font-weight: 600;
  }
  .new-theme-radio {
    display: flex;
    flex-direction: column;
    label {
      padding-top: 10px;
      ::v-deep .el-radio__label {
        color: $--neutral-900;
      }
      .new-theme-popover-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-width: 200px;
        max-width: 400px;

        &.disabled {
          pointer-events: none;
        }
      }
      .new-theme-options {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        max-height: 200px;
        min-height: 0;
        overflow-y: auto;
        font-size: 14px;
        ::v-deep .el-tree-node__content {
          padding: 18px 0;
          &:hover {
            background: $--primary-100;
          }
        }
        ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
          padding-right: 0;
        }
      }
    }
  }
}
</style>