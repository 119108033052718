import { ThemeGroup } from 'lib/theme-file-parser';
import { filter, ListIteratee, lowerCase, Many, map, orderBy } from 'lodash';
import { SortType, ThemeTreeItem } from 'stores/ThemesStore';

function sortNodes(
  nodes: ThemeTreeItem[],
  fields: Many<ListIteratee<ThemeTreeItem>>,
  order: ('asc' | 'desc')[]
) {
  const sorted = orderBy(nodes, fields, order);
  return map(sorted, node => {
    if (node.children) {
      const children = sortNodes(
        node.children as ThemeTreeItem[],
        fields,
        order
      );
      return { ...node, children };
    } else {
      return { ...node };
    }
  });
}

export function sortGroup(
  group: ThemeGroup,
  sort: SortType | null,
  commentCounts: { [key: string]: number; }
) {
  let { nodes } = group;
  switch (sort) {
    case SortType.Frequency:
      group.nodes = sortNodes(
        nodes,
        [n => commentCounts[n.id] || 0, 'freq', n => lowerCase(n.title)],
        ['desc', 'asc']
      );
      break;
    case SortType.New:
      group.nodes = sortNodes(
        nodes,
        [
          'isNew',
          'hasMergedNew',
          (n: ThemeTreeItem) =>
            filter(n.children, c => c.isNew || c.hasMergedNew).length,
          n => lowerCase(n.title)
        ],
        ['desc', 'desc', 'desc', 'asc']
      );
      break;
    case SortType.Review:
      group.nodes = sortNodes(
        nodes,
        [
          'toReview',
          (n: ThemeTreeItem) => filter(n.children, c => c.toReview).length,
          n => lowerCase(n.title)
        ],
        ['desc', 'desc', 'asc']
      );
      break;
    case SortType.Name:
      group.nodes = sortNodes(nodes, [n => lowerCase(n.title)], ['asc']);
      break;
    default:
    // do nothing
  }

  return group;
}
