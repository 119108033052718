<template>
  <span
    v-if="activelyEditing"
    class="editing-title"
  >
    <el-input
      v-model="proposedTitle"
      size="medium"
    >
      {{ title }}
      <template slot="append">
        <el-button
          type="blue-text"
          size="small"
          title="Cancel Edit"
          @click="cancelChange"
        >
          <font-awesome-icon icon="times-circle" />
        </el-button>
        <el-button
          type="blue-text"
          size="small"
          title="Save Edit"
          @click="saveChange"
        >
          <font-awesome-icon icon="check-circle" />
        </el-button>
      </template>
    </el-input>
  </span>
  <span
    v-else-if="activeDashboardUIStore.isEditing"
    class="editable-title"
    @click="enterEditMode"
  >
    <span class="dashboard-title">{{ title }}</span>
    <span
      v-if="showEditOnHover"
      class="show-edit-icon"
    >
      <font-awesome-icon icon="pencil" />
    </span>
    <el-button
      v-else
      type="blue-text"
      size="small"
      title="Edit Title"
      @click="enterEditMode"
    >
      <font-awesome-icon icon="pencil" />
    </el-button>
  </span>
  <span
    v-else
    class="editable-title"
  >
    <span class="dashboard-title">{{ title }}</span>
  </span>
</template>

<script>
import { observer } from 'mobx-vue';
import { getActiveDashboardUIStore } from 'stores/RootStore';

export default observer({
  name: 'DashboardEditableString',

  props: {
    target: { default: undefined, type: String },
    showEditOnHover: { default: false, type: Boolean },
  },
  data() {
    return {
      proposedTitle: '',
      activeDashboardUIStore: getActiveDashboardUIStore(),
      activelyEditing: false,
    };
  },
  computed: {
    title() {
      return this.activeDashboardUIStore.getValue(this.target);
    },
  },
  methods: {
    enterEditMode() {
      this.activelyEditing = true;
      this.proposedTitle = this.title;
    },
    saveChange() {
      this.activeDashboardUIStore.setValue(this.target, this.proposedTitle);
      this.activelyEditing = false;
    },
    cancelChange() {
      this.activelyEditing = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.editing-title {
  display: inline-flex;

  .el-input {
    width: 300px;
  }
}
.editable-title {
  display: inline-flex;
  align-items: center;
  .dashboard-title {
    font-weight: 400;
    font-size: 20px;
  }
  .show-edit-icon {
    display: none;
  }
  &:hover .show-edit-icon {
    display: inline-block;
    font-size: 14px;
    padding-left: 5px;
  }
}
</style>