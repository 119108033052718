import { AggregateViewSource, AnalysisViewSource } from 'api/interfaces';
import config from 'runtime-config';

export const getSourceUrl = (source: AnalysisViewSource | AggregateViewSource) => {
  // build the source url from components
  const { apiLocation } = config;

  // handle aggregate view dashboards
  if ('aggregateView' in source) {
    return `${ apiLocation }/aggregateView/${ source.aggregateView }/data/`;
  }
  // handle analysis tools with a view
  if (source.view) {
    return `${ apiLocation }/survey/${ source.survey }/view/${ source.view }/visualization/${ source.visualization }/`;
  }
  // handle analysis tools without a view
  return `${ apiLocation }/survey/${ source.survey }/visualization/${ source.visualization }/`;
};

export const getAnalysisId = (source: AnalysisViewSource | AggregateViewSource) => {
  // handle aggregate view dashboards
  if ('aggregateView' in source) {
    return source.aggregateView;
  }
  // handle analysis tools with a view
  if (source.view) {
    return `${ source.survey }-${ source.view }-${ source.visualization }`;
  }
  // handle analysis tools without a view
  return `${ source.survey }-${ source.visualization }`;
};