import { isString } from 'lodash';
import { parse } from 'query-string';

export function matchesFilter(
  filters: string,
  filterId: string
): string[] | undefined {
  const selections = parse(filters);

  let selected = selections[filterId];
  if (selected) {
    if (isString(selected)) {
      selected = [selected];
    }
    return selected as string[];
  } else {
    return undefined;
  }
}

export function matchesRoute(
  route: { query: { compare: string; filters: string } },
  filterKey: string,
  filterId: string
): string[] | undefined {
  const { query } = route;
  let match;
  if (filterKey === 'comparison') {
    match = matchesFilter(query.compare, filterId);
  }
  if (!match) {
    match = matchesFilter(query.filters, filterId);
  }
  return match;
}
