import { get } from 'lodash';
import thematicData from 'vue/libs/thematicData';
import themeHelpers from 'vue/libs/themeHelpers';
import toFixed from 'vue/libs/to-fixed';

export interface RequestOptions {
  scoreType: string;
}
interface Selection {
  query: string;
  title: string;
  shortTitle: string;
}
export interface Selections {
  baseline: Selection;
  comparison: Selection;
}

export interface Titles {
  baseline: string;
  comparison: string;
}

export interface FilterThemesOptions {
  basethemes: string[];
  subthemes: string[];
}

export interface ScoreImpactFetchOptions {
  commentColumns: number[];
  requestOptions: RequestOptions;
  selections: Selections;
  currentTitles: Titles;
  filterThemes: FilterThemesOptions;
}

async function doFetch(options: ScoreImpactFetchOptions) {
  const {
    commentColumns,
    requestOptions,
    selections,
    currentTitles,
    filterThemes,
  } = options;

  const baseline = await thematicData.getThemes(
    selections.baseline.query,
    commentColumns,
      /* @ts-expect-error */
    requestOptions
  );
  const selection = await thematicData.getThemes(
    selections.comparison.query,
    commentColumns,
      /* @ts-expect-error */
    requestOptions
  );

  baseline.themes = themeHelpers.filterThemes(baseline.themes, filterThemes);
  selection.themes = themeHelpers.filterThemes(selection.themes, filterThemes);

  // Leave uninitialized until data has been recieved
  // this happens when mounted is called early
  if (!selection.themes || !baseline.themes) {
    return;
  }
  const { baseline: baseTitle, comparison: compTitle } = currentTitles;
  baseline.title = baseTitle;
  selection.title = compTitle;
  const { total: baseTotal } = baseline.counts;
  const baselineCount = baseTotal.toLocaleString();
  const { total: compTotal } = selection.counts;
  const selectionCount = compTotal.toLocaleString();
  const baselineScore = toFixed(get(baseline, 'scoreAllRows.score'), 1);
  const selectionScore = toFixed(get(selection, 'scoreAllRows.score'), 1);
  const scoreName = get(requestOptions, 'scoreOptions.name', 'score');
  const baselineTitle = `${ baseTitle } (n: ${ baselineCount } ${ scoreName }: ${ baselineScore })`;
  const selectionTitle = `${ compTitle } (n: ${ selectionCount } ${ scoreName }: ${ selectionScore })`;

  return {
    baseline,
    comparison: selection,
    baselineThemeTitle: baselineTitle,
    comparisonThemeTitle: selectionTitle,
    baselineSubthmeTitle: baseTitle,
    comparisonSubthemeTitle: compTitle,
    baselineCount,
    comparisonCount: selectionCount,
  };
}

export default {
  doFetch,
};
