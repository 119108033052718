<template>
  <div class="show-suggested-phrases">
    <div
      v-for="(phrase, index) in phrases"
      :key="index + phrase"
      class="phrase-pill"
    >
      <span
        v-if="phraseValue === phrase"
        @keydown.stop.prevent.escape="onInputKeyPress"
      >
        <el-tooltip
          v-model="inputError"
          :manual="true"
          effect="dark"
          content="This phrase already exist"
          placement="bottom"
        >
          <el-input
            ref="inputElement"
            v-model="inputValue"
            class="phrase-input"
            :class="{'errored-input': inputError}"
            :style="{width: (((inputValue.length) + 2) + 'ch')}"
            @blur="onInputBlur(index)"
            @change="onInputBlur(index)"
            @input="validateInputValue(inputValue, index)"
          />
        </el-tooltip>
      </span>
      <span
        v-else
        class="phrase"
        @click="updatePhraseInputValue(phrase)"
      >
        {{ phrase }}
      </span>
      <button
        class="delete-button"
        @click="deletePhrase(phrase)"
      >
        <font-awesome-icon icon="trash-alt" />
      </button>
    </div>
    <span v-if="!showNewPhraseInput">
      <button
        class="add-phrase-button"
        @click="toggleNewPhraseInput()"
      >
        <font-awesome-icon icon="plus" /> <span class="add-phrase-text">Add Phrase</span>
      </button>
    </span>
    <span
      v-else
      class="add-phrase-input"
      @keydown.stop.prevent.escape="onNewPhraseInputKeyPress"
    >
      <el-tooltip
        v-model="newPhraseInputError"
        :manual="true"
        popper-class="new-phrase-tooltip"
        effect="dark"
        content="This phrase already exist"
        placement="bottom"
      >
        <el-input
          ref="newPhraseInputElement"
          v-model="newPhraseInputValue"
          :style="{width: (newPhraseInputValue.length + 'ch')}"
          :class="{'errored-new-phrase-input': newPhraseInputError}"
          @blur="onNewPhraseInputBlur"
          @change="onNewPhraseInputBlur"
          @input="validateNewPhraseInputValue"
        />
      </el-tooltip>
      <button
        class="cancel"
        @mousedown="toggleNewPhraseInput()"
      >
        Cancel
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ShowSuggestedPhrases',
  props: {
    suggestedPhrases: {default: () => [], type: Array}
  },
  data() {
    return {
      phrases: this.suggestedPhrases,
      inputValue: '',
      phraseValue: '',
      showNewPhraseInput: false,
      newPhraseInputValue: '',
      inputError: false,
      newPhraseInputError: false
    };
  },
  methods: {
    updatePhraseInputValue(phrase) {
      this.phraseValue = phrase;
      this.inputValue = phrase;
      this.$nextTick(() => {
        if (this.$refs.inputElement) {
          this.$refs.inputElement[0].$refs.input.focus();
        }
      });
    },
    onInputBlur(index) {
      const trimmedValue = this.inputValue.trim();
      if (!this.inputError && trimmedValue.length > 0) {
        this.phraseValue = '';
        this.phrases[index] = trimmedValue;
        this.$emit('onPhrasesUpdate', this.phrases);
        // reset error when input is closed
        this.inputError = false;
      } else {
        this.onInputKeyPress();
      }
    },
    onInputKeyPress() {
      // close input on escape without making any change
      this.inputValue = this.phraseValue;
      this.phraseValue = '';
      // reset error when input is closed
      this.inputError = false;
    },
    validateInputValue(value, index) {
      const trimmedValue = value.trim();
      // if phrase is present we show an error
      this.inputError = this.phrases.some((phrase, phraseIndex) => {
        return phrase === trimmedValue && phraseIndex !== index;
      });
    },
    toggleNewPhraseInput() {
      this.showNewPhraseInput = !this.showNewPhraseInput;
      this.newPhraseInputValue = '';
      if (this.showNewPhraseInput) {
        this.$nextTick(() => {
          if (this.$refs.newPhraseInputElement) {
            this.$refs.newPhraseInputElement.focus();
          }
        });
      }
    },
    onNewPhraseInputBlur() {
      const trimmedValue = this.newPhraseInputValue.trim();
      if (!this.newPhraseInputError) {
        if (trimmedValue.length > 0) {
          this.phrases = [...this.phrases, trimmedValue];
        }
        this.newPhraseInputValue = '';
        this.showNewPhraseInput = false;
        this.$emit('onPhrasesUpdate', this.phrases);
        // reset error when input is closed
        this.newPhraseInputError = false;
      } else {
        this.onNewPhraseInputKeyPress();
      }
    },
    onNewPhraseInputKeyPress() {
      // close input on escape
      this.newPhraseInputValue = '';
      this.showNewPhraseInput = false;
      // reset error when input is closed
      this.newPhraseInputError = false;
    },
    validateNewPhraseInputValue(value) {
      const trimmedValue = value.trim();
      // if phrase is present we show an error
      this.newPhraseInputError = this.phrases.some((phrase) => {
        return phrase === trimmedValue;
      });
    },
    deletePhrase(phrase) {
      this.phrases = this.phrases.filter(val => val !== phrase);
      this.$emit('onPhrasesUpdate', this.phrases);
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.show-suggested-phrases {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $--neutral-100;
  padding-bottom: 10px;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  .phrase-pill {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 10px;
    &:hover .phrase {
      background-color: $--white;
    }
    ::v-deep .el-input.phrase-input {
      min-width: 40px;
    }
    .phrase-input {
      ::v-deep .el-input__inner {
        height: 28px;
        color: $--primary-900;
        background-color: $--white;
        padding: 3px 6px 3px 6px;
        font-size: 14px;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid $--neutral-200;
        margin-bottom: 0;
      }
      &.errored-input {
        ::v-deep .el-input__inner {
          border: 1px solid $--red-500;
        }
      }
    }
    .phrase {
      display: flex;
      height: 28px;
      color: $--primary-900;
      background-color: $--primary-200;
      padding: 3px 6px 3px 6px;
      font-size: 14px;
      border-radius: 4px 0 0 4px;
      align-items: center;
      min-width: 40px;
      &:hover {
        background-color: $--white;
        cursor: text;
      }
      &:hover + .delete-button {
        background-color: $--white;
      }
    }
    .delete-button {
      height: 28px;
      background: $--primary-200;
      text-transform: none;
      border: none;
      border-radius: 0px 4px 4px 0px;
      padding: 3px 6px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: $--red-900;
        background-color: $--red-100;
      }
    }
  }
  .add-phrase-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background-color: $--white;
    border: 1px solid $--neutral-200;
    cursor: pointer;
    font-size: 14px;
    color: $--neutral-900;
    padding: 5px 6px;
    margin: 10px 10px 0 10px;
    &:hover {
      color: $--color-primary;
      background-color: $--color-primary-light-9;
    }
    .add-phrase-text {
      padding-left: 0.4rem;
    }
  }
  .add-phrase-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    ::v-deep .el-input {
      min-width: 8rem;
      .el-input__inner {
        color: $--primary-900;
        background-color: $--white;
        margin: 10px 0 10px 15px;
        padding: 8px 4px;
        font-size: 14px;
        height: 28px;
        min-width: 8rem;
        border-radius: 4px;
        border: 1px solid #e4e6ea;
      }
    }
    ::v-deep .el-input.errored-new-phrase-input {
      .el-input__inner {
        border: 1px solid $--red-500;
      }
    }
    .cancel {
      height: 19px;
      color: $--neutral-900;
      background-color: transparent;
      margin: 10px 0px 10px 15px;
      font-size: 14px;
      border: transparent;
      cursor: pointer;
      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: $--primary-900;
      }
    }
  }
}
</style>

<style lang="scss">
.new-phrase-tooltip.el-tooltip__popper[x-placement^=bottom] {
  margin-top: 0;
}
</style>