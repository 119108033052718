import { DashboardConfig } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { calculateDefaultDashboard } from 'components/Dashboard/Utils/dashboard-helper';
import { filter, includes } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import { stringify } from 'query-string';
import { OrganizationStoreInterface } from './OrganizationStore';
import { UserStoreInterface } from './UserStore';

export interface DashboardJson {
  configuration: string;
  id: string;
  isPreview: boolean;
  name: string;
  order: number;
  version: string;
  currentUserCan: string[];
}

interface CreateDashboardPayload {
  name: string;
  configuration: DashboardConfig;
  isPreview: boolean;
}

export interface DashboardStoreInterface {
  defaultDashboardId?: string;
  dashboardsError: string;
  dashboardsErrored: boolean;
  dashboardsLoading: boolean;
  createDashboardError: string;
  deleteDashboardError: string;
  creatingDashboard: boolean;
  deletingDashboard: boolean;
  dashboards: DashboardJson[];

  canManageSubscriptions: boolean;

  refreshDashboards: () => Promise<void>;
  fetchDashboards: (orgId: string) => Promise<void>;
  createDashboard: (payload: CreateDashboardPayload) => Promise<DashboardJson | undefined>;
  deleteDashboard: (dashboardId: string) => void;
  getDashboardById: (dashboardId: string) => DashboardJson | undefined;
}

class DashboardStore implements DashboardStoreInterface {
  currentOrg: OrganizationStoreInterface;
  userStore: UserStoreInterface;

  @observable
  dashboardsError = '';

  @observable
  dashboardsErrored = false;

  @observable
  createDashboardError = '';

  @observable
  deleteDashboardError = '';

  @observable
  creatingDashboard = false;

  @observable
  deletingDashboard = false;

  @observable
  dashboardsLoading = false;

  @observable
  dashboards: DashboardJson[] = [];

  @computed
  get canManageSubscriptions() {
    const manageableDashboards = filter(this.dashboards, dashboard => {
      return includes(dashboard.currentUserCan, 'manage:subscriptions');
    });
    return manageableDashboards.length > 0;
  }

  @computed
  get defaultDashboardId() {
    const { defaultDashboard } = this.userStore;
    let dashboardId = defaultDashboard;

    const dashboardExists = !!this.dashboards.find(dashboard => dashboard.id === dashboardId);

    if (dashboardId && dashboardExists) {
      return dashboardId;
    }

    if (this.dashboards) {
      return calculateDefaultDashboard(this.dashboards);
    }

    return;
  }

  constructor(currentOrg: OrganizationStoreInterface, userStore: UserStoreInterface) {
    this.currentOrg = currentOrg;
    this.userStore = userStore;

    // React to orgId changes
    reaction(
      () => this.currentOrg.orgId,
      () => this.refreshDashboards(),
      { fireImmediately: true }
    );
  }

  @action
  refreshDashboards = async () => {
    this.reset();

    const { orgId } = this.currentOrg;
    if (orgId) {
      await this.fetchDashboards(orgId);
    }
  };

  @action
  reset = () => {
    this.dashboards = [];
    this.dashboardsError = '';
    this.dashboardsErrored = false;
    this.dashboardsLoading = false;
  };

  @action
  fetchDashboards = async (orgId: string) => {
    const params = stringify({ organization: orgId });
    const url = `/dashboards?${ params }`;

    this.dashboardsLoading = true;
    this.dashboardsErrored = false;
    this.dashboardsError = '';

    try {
      const { ok, data: dashboardList, errorData } = await Auth.fetch<DashboardJson[]>(url);
      if (ok && dashboardList) {
        this.dashboards = dashboardList;
      } else {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        this.dashboardsError = errorMessage;
        this.dashboardsErrored = true;
      }
    } catch (e) {
      this.dashboardsError = `${ e }`;
      this.dashboardsErrored = true;
    } finally {
      this.dashboardsLoading = false;
    }
  }

  @action
  createDashboard = async (payload: CreateDashboardPayload) => {
    this.createDashboardError = '';
    this.creatingDashboard = true;

    try {
      const query = this.currentOrg.orgIdQueryParam;
      const { ok, data, errorData } = await Auth.fetch<DashboardJson>(`/dashboard?${ query }`, {
        body: JSON.stringify({ version: 'editable', ...payload }),
        method: 'POST'
      });

      if (ok && data) {
        this.addDashboardToState(data);
        return data;
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.createDashboardError = `Failed to create dashboard: ${ e.message }`;
    } finally {
      this.creatingDashboard = false;
    }
    return undefined;
  }

  @action
  deleteDashboard = async (dashboardId: string) => {
    this.deleteDashboardError = '';
    this.deletingDashboard = true;

    try {
      const { ok, errorData } = await Auth.fetch<DashboardJson>(`/dashboard/${ dashboardId }`, {
        method: 'DELETE'
      });

      if (ok) {
        this.removeDashboardFromState(dashboardId);
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.deleteDashboardError = `Failed to delete dashboard: ${ e.message }`;
    } finally {
      this.deletingDashboard = false;
    }
  }

  @action
  addDashboardToState = (dashboard: DashboardJson) => {
    if (this.dashboards) {
      this.dashboards = [...this.dashboards, dashboard];
    }
  }

  @action
  removeDashboardFromState = (dashboardId: string) => {
    this.dashboards = filter(this.dashboards, dashboard => dashboard.id !== dashboardId);
  }

  getDashboardById = (dashboardId: string) => {
    if (this.dashboards) {
      return this.dashboards.find(dashboard => dashboard.id === dashboardId);
    }
    return undefined;
  }
}

export default DashboardStore;
