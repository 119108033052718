import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toFixed from 'vue/libs/to-fixed';
import './change-value.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';

interface Props {
  value: number;
  previousValue: number;
  preCalculated?: number;
}

const getValueChange = (previousValue: number, value: number, preCalculated?: number): number => {
  if (preCalculated !== undefined) {
    return preCalculated;
  } else if (previousValue === 0) {
    return Infinity;
  } else {
    return ((value / previousValue - 1) * 100);
  }
}

const getLabel = (valueChange: number): string => {
  if(valueChange >= - 0.5 && valueChange <= 0.5) {
    return "no change";
  }
  else if(valueChange === Infinity) {
    return "new";
  }
  return toFixed(valueChange, 0, '%');
}

const getIcon = (valueChange: number): React.ReactNode  => {
  if (valueChange === Infinity) {
    return null;
  } else if (valueChange < -0.5) {
    return <FontAwesomeIcon icon='arrow-alt-down' />
  } else if (valueChange > 0.5) {
    return <FontAwesomeIcon icon='arrow-alt-up' />
  } else {
    return null;
  }
}

const ChangeValue = withCamelCaseProps(({ value, previousValue, preCalculated }: Props) => {
  const valueChange = getValueChange(previousValue, value, preCalculated);
  const valueChangeLabel = getLabel(valueChange);

  return (
    <div className="change-value">
      {valueChangeLabel}
      {getIcon(valueChange)}
    </div>
  );
});

export { ChangeValue };
