import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Survey } from 'api/interfaces';
import ErrorModal from 'components/ErrorModal/ErrorModal';
import UpgradeNeededWarning from 'components/UpgradePlan/UpgradeNeededWarning';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import { SetupStoreInterface } from 'stores/SetupStore';
import './survey-upload.scss';

interface SurveyCreateStoreProps {
  setupStore?: SetupStoreInterface;
}

interface SurveyCreateProps extends SurveyCreateStoreProps, RouteComponentProps {
  canCreateSurveys: boolean;
}
interface SurveyCreateState {
  showCreateErrorModal: boolean;
}

@inject('setupStore')
@observer
class SurveyCreate extends React.Component<
SurveyCreateProps,
SurveyCreateState
> {
  state = {
    showCreateErrorModal: false,
  };

  createSurveyForSteppedWorkflow = async () => {
    const { setupStore, history, match } = this.props;

    if (setupStore!.creatingSurvey) {
      return;
    }

    const surveyOptions = {
      name: 'Dataset',
      dataSourceIntegration: undefined,
      manualUploadAllowed: true
    };

    const newSurvey = await setupStore!.createSurvey(surveyOptions);

    if (!setupStore!.createSurveyError && newSurvey) {
      const { orgId } = match.params as { orgId: string };
      const { id } = newSurvey as Survey;
      const url = `/c/${orgId}/s/${id}/configure-survey?step=import`;
      history.push(url);
    } else {
      this.setState({ showCreateErrorModal: true });
    }
  }

  render(): JSX.Element | null {
    const { showCreateErrorModal } = this.state;
    const { setupStore, canCreateSurveys } = this.props;

    const { creatingSurvey, createSurveyError } = setupStore!;

    const upgradeHeader = `Upgrade to add more datasets`;

    return (
      <>
        <Card
          className="create-card"
          fluid={true}
          key="createTile"
          onClick={this.createSurveyForSteppedWorkflow}
        >
          <Dimmer active={creatingSurvey} inverted={true}>
            <Loader />
          </Dimmer>

          <Card.Content>
            <div className="body">
              <div className="add-dataset-icon">
                <FontAwesomeIcon
                  icon="database"
                  className="icon"
                  fixedWidth={true}
                />
                <FontAwesomeIcon
                  icon="plus"
                  className="icon plus-icon"
                  fixedWidth={true}
                />
              </div>
              <div className="add-dataset">
                <h3>Add dataset</h3>
                <p className="note">Analysis takes a day</p>
              </div>
            </div>
            {!canCreateSurveys &&
              <div>
                <UpgradeNeededWarning
                  warningMessage={upgradeHeader}
                  location="Manage Data"
                />
              </div>
            }
          </Card.Content>
        </Card>

        {showCreateErrorModal && (
          <ErrorModal
            showModal={showCreateErrorModal}
            header="Create new dataset"
            onClose={() => this.setState({ showCreateErrorModal: false })}
            errorMessage={createSurveyError}
          />
        )}
      </>
    );
  }
}

export default withRouter(SurveyCreate);
