import {
  AnalysisFilterCut,
  AnswerComparison,
  AnswerResponse,
  AnswerSupplementaryResponse,
  AnswersAction,
  AnswersActionType,
  AnswersDataSet,
  AnswersErrorBase,
  ErrorMessage,
  SegmentFilters,
} from 'api/interfaces';
import { FilterOption } from 'lib/filters/hierarchical-filter-helper';
import { FilterMatchType } from 'stores/types';

export type Node<T> = {
  item: T,
  children: Node<T>[]
};

/**
 * Filter UI
 */

export type FilterAction = {
  type: 'date',
  startDate: Date,
  endDate: Date
} | {
  type: 'hierarchical';
  options: FilterOption[]
} | {
  type: 'tags',
  options: FilterOption[]
} | {
  type: 'themes',
  options: FilterOption[],
  match: FilterMatchType
} | {
  type: 'categories',
  options: FilterOption[]
} | {
  type: 'sentiment',
  options: FilterOption[]
} | {
  type: 'freetext',
  searches: string[],
  match: FilterMatchType
} | {
  type: 'cut',
  cut: AnalysisFilterCut
};

/**
 * Insights
 */

export enum InsightVis {
  BarChart = 'barchart',
  Legend = 'legend'
}

type LegendConfig = {
  label: string,
  data: { [key: string]: string | number }
};

export interface LegendInsight {
  type: InsightVis.Legend;
  baseline: LegendConfig;
  shouldShowComparison: boolean;
  comparison?: LegendConfig;
}

export type BarChartField = {
  label: string,
  baseline: number,
  comparison?: number,
  isSelected: boolean,
  // baselineSentiment is optionaal because it can be undefined in sentiment off surveys
  baselineSentiment?: {
    pos: number,
    neut: number,
    neg: number
  },
  comparisonSentiment?: {
    pos: number,
    neut: number,
    neg: number
  },
  isHighlighted: boolean
  children?: Array<Omit<BarChartField, 'children'>>
};

export type ChartDetails = {
  units: string,
  count: number,
  countLabel: string
};

export interface BarChartInsight {
  type: InsightVis.BarChart;
  fields: Array<BarChartField>;
  chart: {
    shouldShowComparison: boolean,
    shouldShowSentiment: boolean,
    units: string,
    count: number,
    countLabel: string
  };
  form: {
    shouldShowBarValues: boolean,
    label: string,
    maxSelectedFields: number
  };
}

export type ExportableInsight =
  | BarChartInsight
  | LegendInsight
  ;

/**
 * Comments
 */

export interface Conversation {
  pairs: Array<Array<{
    meta: { author: string, depth: number, partIndex: number },
    threadedComment: ThreadedComment
  }>>;
  collapsible: boolean;
  depth: number;
}

export interface PhraseSegmentTheme {
  base: string;
  baseTitle: string;
  sub: string;
  subTitle: string;
  title: string;
}

export interface PhraseSegment {
  index: number;
  categories: Array<string>;
  location: [start: number, end: number];
  sentiment: number;
  themes: Array<PhraseSegmentTheme>;
}

export interface PlainComment {
  column: number;
  comment: string;
  data: { [key: string]: string | number };
  id: string;
  segments: Array<PhraseSegment>;
  sentiment: number;
  tags: string[];
}

export interface ThreadedComment {
  data: {
    id: string,
    threadDepth: number,
    sub_or_com: 'comment' | 'submission',
    user_name: string
  };
  segments: Array<PhraseSegment>;
  text: string;
}

export interface Thread {
  column: number;
  comment: Array<ThreadedComment>;
  id: string;
  data: { [key: string]: string | number };
  sentiment: number;
  tags: string[];
}

export type SegmentLocation = [start: number, end: number]

export interface SummarySegment extends PhraseSegment {
  conversationReferences: {
    index: number;
    location: SegmentLocation;
  }[];
}

export interface Summary {
  column: 'threadSummary';
  comment: string;
  data: { [key: string]: string | number };
  id: string;
  key: string;
  segments: SummarySegment[];
  conversation: {
    id: string;
    parts: {
      author: string;
      text: string;
    }[]
  };
  sentiment: number;
  tags: string[];
}

export interface CommentThemeItem {
  theme: PhraseSegmentTheme;
  segments?: PhraseSegment[]
  sentiment: number;
  hasBackgroundColour?: boolean;
  isNew: boolean;
  isRemoved: boolean;
  isHighlighted?: boolean;
}

/**
 * Answers
 */
export type AnswerNodeContent = AnswerResponse | AnswerSupplementaryResponse;

export function isAnswerResponse(content: AnswerNodeContent): content is AnswerResponse {
  return ['text', 'table', 'multipart'].includes(content.type);
}

export function isAskNode(node: AnswerNode): node is Node<AnswerOutput> {
  return node.item.state === 'output' && node.item.actionType === AnswersActionType.ask
}

export function isQuotesAnswerNode(node: AnswerNode): node is Node<AnswerOutput> {
  return node.item.state === 'output' && node.item.actionType === AnswersActionType.selectQuotes
}

export function isComparisonOutput(item: AnswerOutput): item is AnswerOutput & { comparisons: AnswerComparison[] } {
  return !!item.comparisons;
}

export type AnswerLoading = {
  state: 'loading',
  actionType: AnswersActionType,
  message: string,
  isVisible: boolean,
  id: string
};
export type AnswerOutput = {
  actionType: AnswersActionType,
  comparisons?: AnswerComparison[],
  content: AnswerNodeContent,
  createdAt: string,
  dataSets: AnswersDataSet[],
  filters?: SegmentFilters,
  id: string;
  isVisible: boolean,
  state: 'output',
};
export type AnswersErrorMessage = ErrorMessage | AnswersErrorBase & {
  'errorCode': 'CLIENT_ERROR'
};

export type AnswerError = {
  state: 'error',
  id: string,
  isVisible: boolean,
  originalError: AnswersErrorMessage,
  originalAction: AnswersAction;
  originalDatasets: AnswersDataSet[];
};

export type AnswerNode = Node<AnswerError | AnswerLoading | AnswerOutput>;

export enum AnswersVisualizationOptions {
  VISUALIZATION = 'visualization',
  TABLE = 'table'
}

export const AnswerModals = {
  None: 'none',
  Email: 'email',
  Share: 'share',
  Delete: 'delete',
  Finalize: 'finalize',
} as const;

export type AnswerModal = typeof AnswerModals[keyof typeof AnswerModals];

export type AnswersEmailModalState = {
  subject: string | null,
  emails: string[];
};

export interface CommentData {
  dep: {
    id: number;
    subs: any[];
  };
  value: CommentData;
  vmCount: number;
}

export interface Comment {
  column: number;
  comment: string;
  data: CommentData;
  highlightLength: number;
  id: string;
  segments: PhraseSegment[];
}

export interface CommentTheme {
  options: Record<string, string>,
  subtheme: {
    code: string;
    title: string;
  } | null,
  theme: {
    code: string;
    title: string;
  }
}


/**
 * Charts
 */

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
