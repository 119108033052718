import { FilterType } from 'api/enums';
import { AnalysisFilter, AnalysisFilterCut, Segment } from 'api/interfaces';
import FilterCategories from 'components/Filters/FilterCategories';
import FilterDetails from 'components/Filters/FilterDetails';
import FilterSearchInput from 'components/Filters/FilterSearchInput';
import FilterSearchResults from 'components/Filters/FilterSearchResults';
import SavedFiltersPanel from 'components/Filters/SavedFiltersPanel';
import { createQueryFilter } from 'lib/filters/createQueryFilter';
import { getAllFiltersMatchingSearchText } from 'lib/filters/filter-search-helper';
import updateFilter from 'lib/filters/filter-update-helper';
import { createFreeTextQueryFilter, getFreeTextMatchType, getFreeTextSearchTerms } from 'lib/filters/free-text-filter-helper';
import { toggleFilterCutSelected } from 'lib/filters/list-filter-helper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { QueryFilter } from 'stores/types';
import { FilterAction } from 'types/custom';
import { FilterQuickSelect } from './FilterQuickSelect';
import './filter-popup-content.scss';
import { FilterConfig } from './types';
import { getAllFilters } from 'lib/filters/get-all-filters-helper';

const SAVED_SEGMENTS_FILTER_ID = 'saved-segments-filters';
interface FilterPopupContentProps {
  savedFilters: React.ReactElement;
  filters: AnalysisFilter[];
  queryFilters: Record<string, QueryFilter>;
  hasSelectedSavedFilterChanged: boolean;
  selectedSavedFilter: Segment | undefined;
  onQueryFilterChange: (queryFilter: QueryFilter) => void;
  onFilterReset: (allFilters: AnalysisFilter[], filterId: string) => void;
  onAllFiltersReset: () => void;
  onUpdateSavedFilter: () => void;
  config: FilterConfig;
}

interface FilterPopupContentState {
  selectedFilterId?: string;
  searchText: string;
}

class FilterPopupContent extends React.Component<FilterPopupContentProps, FilterPopupContentState> {
  state: FilterPopupContentState = { searchText: '' };

  componentDidMount() {
    document.body.classList.add('filter-popup-showing');
  }

  componentWillUnmount() {
    document.body.classList.remove('filter-popup-showing');
  }

  filterIsDisabled(filterType: FilterType | undefined) {
    const { config } = this.props;

    if (!filterType) {
      return false;
    }

    return config.disabledFilterTypes?.includes(filterType);
  }

  getAllFilters = () => {
    const { filters, config } = this.props;
    const { disabledFilterTypes, sortedTags, showSentiment } = config;

    return getAllFilters({
      filters,
      showSentiment,
      sortedTags,
      disabledFilterTypes
    });
  };

  getSelectedFilter = (): AnalysisFilter | null => {
    const { selectedFilterId } = this.state;
    const allFilters = this.getAllFilters();
    return allFilters.find((filter) => filter.id === selectedFilterId) || null;
  };

  getSelectedQueryFilter = (): QueryFilter | undefined => {
    const { selectedFilterId } = this.state;
    const queryFilters = this.props.queryFilters;
    return selectedFilterId ? queryFilters?.[selectedFilterId] : undefined;
  };

  getQuickSelectFilters = () => {
    const queryFilters = this.props.queryFilters;

    return {
      categoryFilter: queryFilters?.categories,
      sentimentFilter: queryFilters?.sentiment,
    };
  };

  onFilterSelected = (filterId: string) => {
    const { selectedFilterId } = this.state;
    this.setState({ selectedFilterId: selectedFilterId === filterId ? undefined : filterId });
  };

  handleQuickFilterSelection = (action: FilterAction) => {
    const allFilters = this.getAllFilters();

    const selectedFilter = allFilters.find((filter) => filter.id === action.type);

    if (!selectedFilter) {
      return;
    }

    const queryFilter = createQueryFilter(action, selectedFilter, this.props.config);

    if (queryFilter) {
      this.props.onQueryFilterChange(queryFilter);
    }
  }

  clearSelectedFilter = () => this.setState({ selectedFilterId: undefined });

  onSearchResultClick = (filter: AnalysisFilter, cut: AnalysisFilterCut) => {
    const { config } = this.props;
    const nextQueryFilter: QueryFilter = updateFilter(
      filter,
      cut,
      config.commentColumns,
      config.filterKey,
      config.themes,
      config.sortedTags,
      this.props.queryFilters[filter.id],
      config.isFeedbackTool
    );
    this.props.onQueryFilterChange(nextQueryFilter);
  };

  onSearchTextChange = (newText: string) => {
    this.setState({ searchText: newText, selectedFilterId: undefined });
  };

  onFilterChange = (action: FilterAction) => {
    const filter = this.getSelectedFilter();
    const selectedQueryFilter = this.getSelectedQueryFilter();

    if (!filter) {
      return;
    }

    const queryFilter = action.type === 'cut' ?
      toggleFilterCutSelected(
        filter,
        this.props.config.filterKey,
        action.cut,
        selectedQueryFilter
      ) : createQueryFilter(
        action,
        filter,
        this.props.config
      );

    if (queryFilter) {
      this.props.onQueryFilterChange(queryFilter);
    }

  }

  render() {
    const {
      config,
      selectedSavedFilter,
      hasSelectedSavedFilterChanged
    } = this.props;
    const { selectedFilterId, searchText } = this.state;
    const { filterKey } = config;
    const allFilters = this.getAllFilters();
    const selectedFilter = this.getSelectedFilter();
    const selectedQueryFilter = this.getSelectedQueryFilter();
    const queryFilters = this.props.queryFilters;

    const filtersMatchingSearchText = getAllFiltersMatchingSearchText(
      allFilters,
      searchText,
      config.sortedTags,
      config.themes,
      config.isFeedbackTool,
      this.props.queryFilters
    );

    return (
      <div className="filter-popup-content" id={`filter-button-${ filterKey }`}>
        <SavedFiltersPanel
          isDirty={hasSelectedSavedFilterChanged}
          selectedSavedFilter={selectedSavedFilter}
          onUpdateClick={this.props.onUpdateSavedFilter}
          onFilterSelected={() => this.onFilterSelected(SAVED_SEGMENTS_FILTER_ID)}
          onAllFiltersReset={() => this.props.onAllFiltersReset()}
        />

        <div className="filter-popup-content__quick-select">
          <FilterQuickSelect
            config={config}
            filters={this.getQuickSelectFilters()}
            onQuickFilterSelection={this.handleQuickFilterSelection}
          />
        </div>

        <FilterSearchInput value={searchText} onChange={this.onSearchTextChange} />
        {searchText ? (
          <FilterSearchResults
            filtersMatchingSearchText={filtersMatchingSearchText}
            filters={allFilters}
            searchText={searchText}
            onSearchTextCleared={() => this.onSearchTextChange('')}
            onFreeTextFilterClick={(filter: AnalysisFilter) => {
              const queryFilter = queryFilters?.[filter.id];
              const currentSearchTerms = getFreeTextSearchTerms(queryFilter);
              const match = getFreeTextMatchType(queryFilter);
              const newQueryFilter = createFreeTextQueryFilter(
                filter,
                filterKey,
                [...currentSearchTerms, searchText],
                match
              );
              if (!currentSearchTerms.includes(searchText)) {
                this.props.onQueryFilterChange(newQueryFilter);
              }
              this.onSearchTextChange('');
            }}
            onSearchResultClick={this.onSearchResultClick}
          />
        ) : (
          <FilterCategories
            filters={allFilters}
            themes={config.themes}
            tags={config.sortedTags}
            selectedFilterId={selectedFilterId}
            queryFilters={queryFilters}
            isFeedbackTool={config.isFeedbackTool}
            onFilterSelected={this.onFilterSelected}
            onFilterReset={(filterId) => this.props.onFilterReset(allFilters, filterId)}
          />
        )}
        {!searchText && (
          <button
            type="button"
            className="filters-action-button plain"
            data-testid="reset-filters"
            onClick={this.props.onAllFiltersReset}
            onMouseDown={(e) => e.preventDefault()}
          >
            Clear all filters
          </button>
        )}
        {selectedFilter && (
          <FilterDetails
            savedFilters={this.props.savedFilters}
            filter={selectedFilter}
            filterKey={filterKey}
            isFeedbackTool={config.isFeedbackTool}
            onClose={() => this.setState({ selectedFilterId: undefined })}
            onFilterChange={this.onFilterChange}
            onSearchResultClick={this.onSearchResultClick}
            queryFilter={selectedQueryFilter}
            tags={config.sortedTags}
            themes={config.themes}
          />
        )}
      </div>
    );
  }
}

export default observer(FilterPopupContent);
