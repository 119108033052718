import { FilterType } from 'api/enums';
import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { FilterOption } from 'lib/filters/hierarchical-filter-helper';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';

const buildSentimentRql = (id: string) => {
  let rql: string[] = [];
  rql.push(`sentiment==${ id }`);
  return rql.join(',');
};

const sentiments: Record<string, string> = {
  'negative':'Negative',
  'neutral': 'Neutral',
  'positive': 'Positive',
};

export const createSentimentCut = (id: string): AnalysisFilterCut => {
  return {
    id: id,
    name: sentiments[id],
    rql: buildSentimentRql(id),
  };
};

export const createSentimentFilterOptions = (queryFilter?: QueryFilter): FilterOption[] => {
  const selected = queryFilter?.selected;
  return Object.keys(sentiments).map(createSentimentCut).map((row: AnalysisFilterCut): FilterOption => {
    const isSelected: boolean = !!selected?.length && selected.some((s) => s.id === row.id);
    return {
      id: row.id || '',
      rql: row.rql,
      name: row.name,
      isSelected,
    };
  });
};

export const createSentimentQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  options: FilterOption[],
) => {
  const selected: AnalysisFilterCut[] = [];
  options.forEach((option) => {
    if (option.isSelected) {
      selected.push({ id: option.id, name: option.name, rql: buildSentimentRql(option.id) });
    }
  });

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'sentiment',
    selected,
  };
};

export const createSentimentFilter = (): AnalysisFilter => ({
  cuts: Object.keys(sentiments).map(createSentimentCut),
  id: 'sentiment',
  name: 'Sentiment',
  type: FilterType.SENTIMENT,
});
