import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationType, WorkflowActionType } from 'api/enums';
import { WorkflowConfiguration } from 'api/workflow-interfaces';
import analytics from 'lib/analytics';
import { reduce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Button, Dropdown, Form, Segment
} from 'semantic-ui-react';
import EmailAction, { EmailsObject } from '../components/EmailAction';
import SlackAction from '../components/SlackAction';
import {
  ACTION_OPTIONS, DISCOVERY_INTERVAL
} from '../constants';
import './theme-discovery-template.scss';
import { TemplateProps } from './WorkflowTemplateBase';
import { getSlackIntegrationId } from '../workflow-helper';

export interface ThemeDiscoveryTemplateState {
  enteredEmailValidation: boolean;
  enteredEmailValue: string;
  selectedRole: string;
  selectedSurvey: string;
  selectedSurveyName: string;
  selectedNotifyType: string;
  fetchingData: boolean;
  templateTitle: string;
  selectedDiscoveryInterval: string;
  enteredEmails: string[];
  selectedSlackIntegration?: string;
  selectedSlackChannel?: string;
  configCorrect: boolean;
}

const TEMPLATE_ID = 'themeDiscovery';
const TEMPLATE_NAME = 'Theme Discovery Template';

@inject('workflowsStore', 'roleStore', 'surveyStore', 'integrationsStore')
@observer
class ThemeDiscoveryTemplate extends React.Component<TemplateProps, ThemeDiscoveryTemplateState> {

  state = {
    selectedRole: '',
    selectedSurvey: '',
    selectedSurveyName: '',
    selectedNotifyType: this.props.configuration ? '' : ACTION_OPTIONS[0].value,
    fetchingData: false,
    templateTitle: '',
    selectedDiscoveryInterval: DISCOVERY_INTERVAL[0].value,
    enteredEmails: [],
    configCorrect: false,
    selectedSlackChannel: '',
    enteredEmailValidation: false,
    enteredEmailValue: ''
  } as ThemeDiscoveryTemplateState;

  get roleStore() {
    return this.props.roleStore!;
  }

  get surveyStore() {
    return this.props.surveyStore!;
  }

  get integrationsStore() {
    return this.props.integrationsStore!;
  }

  static configurationMatches(configuration: WorkflowConfiguration): { dimension: string } | null {
    if (
      configuration.name === TEMPLATE_NAME ||
      configuration.templateId === TEMPLATE_ID
    ) {
      return { dimension: 'themeDiscovery' };
    }
    return null;
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.setState({ fetchingData: true });
    this.suggestName();
    // fetch surveys and roles if necessary
    const promises = [] as Promise<void>[];
    if (this.roleStore.roles.length === 0) {
      promises.push(this.roleStore.fetchRoles());
    }
    if (!this.surveyStore.surveysJson) {
      promises.push(this.surveyStore.fetchSurveys());
    }
    await Promise.all(promises);
    if (this.props.configuration) {
      this.initializeConfig();
    }
    this.preselectDefaults();
    this.setState({ fetchingData: false });
    this.determineConfigCorrect();
  }

  initializeConfig = () => {
    const { configuration: config, triggers } = this.props;
    if (config) {
      const notify = config.stateMachine.States.Notify;
      const currentIntegration = this.integrationsStore.getConnectedIntegrationsOfType(IntegrationType.SLACK);
      let notifyType;
      let selectedSlackIntegration = '';
      let selectedSlackChannel = '';
      let enteredEmails: string[] = [];
      if (notify.Resource === 'local:notify_email') {
        notifyType = WorkflowActionType.EMAIL;
        enteredEmails = notify.Parameters.emailList.split(',');
      } else if (notify.Resource === 'local:notify_slack') {
        notifyType = WorkflowActionType.SLACK;
        selectedSlackIntegration = getSlackIntegrationId(config, currentIntegration);
        selectedSlackChannel = notify.Parameters.channel;
      }

      this.setState({
        selectedDiscoveryInterval: triggers.calledWithCadence!.period,
        selectedNotifyType: notifyType,
        selectedSlackIntegration,
        selectedSlackChannel,
        enteredEmails,
      });
    }
  }

  preselectDefaults = () => {
    const { selectedRole, configuration: config } = this.props;
    const defaultRole = this.roleStore.roles.find(role => role.name === 'Dataset Administration');
    if (defaultRole) {
      this.selectedRole(null, { value: defaultRole.id });
    } else {
      this.selectedRole(null, { value: this.roleStore.roles[0].id });
    }
    if (this.surveyStore.surveysJson!.length === 1) {
      this.selectedSurvey(null, { value: this.surveyStore.surveysJson![0].id });
    }

    if (config) {
      const params = config.stateMachine.States.DiscoverThemes.Parameters;
      const existingSurvey = this.surveyStore.surveysJson!.find(survey => survey.id === params.surveyId);
      if (selectedRole) {
        this.selectedRole(null, { value: `${ selectedRole }` });
      }
      if (params.surveyId) {
        this.setState({
          selectedSurvey: params.surveyId,
          selectedSurveyName: existingSurvey!.name
        });
      }
    }
  }

  suggestName = () => {
    const { selectedSurveyName } = this.state;
    this.props.onSuggestName(`Discover themes ${ selectedSurveyName ? 'in ' + selectedSurveyName : '' }`);
  }

  selectedRole = (_e, { value }) => {
    this.setState({ selectedRole: value }, () => { this.determineConfigCorrect(); });
  }

  selectedSurvey = (_e, { value }) => {
    if (!this.surveyStore.surveysJson) {
      return;
    }
    const selectedValue = this.surveyStore.surveysJson.find(element => element.id === value);

    this.setState({
      selectedSurvey: selectedValue ? selectedValue.id : '',
      selectedSurveyName: selectedValue ? selectedValue.name : '',
    }, () => { this.determineConfigCorrect(); this.suggestName(); });
  }

  selectedDiscoveryInterval = (e, { value }) => {
    this.setState({
      selectedDiscoveryInterval: value
    },
      () => { this.determineConfigCorrect(); }
    );
  }

  selectedNotifyTypeChange = (e, { value }) => {
    this.setState({ selectedNotifyType: value, enteredEmails: [], selectedSlackChannel: undefined },
      () => { this.determineConfigCorrect(); }
    );
  }

  handleEnteredEmails = (emailsObject: EmailsObject) => {
    this.setState(
      {
        enteredEmails: emailsObject.emails,
        enteredEmailValidation: !!emailsObject.enteredValueValidation
      }, () => {
        if (!!emailsObject.enteredValueValidation && !!emailsObject.enteredValue) {
          this.setState({ enteredEmailValue: emailsObject.enteredValue });
        } else {
          this.setState({ enteredEmailValue: '' });
        }
        this.determineConfigCorrect();
      }
    );
  }

  handleSelectedChannel = (integrationId?: string, channel?: string) => {
    this.setState({
      selectedSlackIntegration: integrationId,
      selectedSlackChannel: channel
    },
      () => { this.determineConfigCorrect(); }
    );
  }

  determineConfigCorrect = () => {
    const {
      selectedDiscoveryInterval,
      selectedSurvey,
      selectedRole,
      selectedNotifyType,
      enteredEmails,
      enteredEmailValidation,
      selectedSlackIntegration,
      selectedSlackChannel } = this.state;
    let configCorrect = !!selectedDiscoveryInterval && !!selectedSurvey && !!selectedRole;

    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      configCorrect = configCorrect && (enteredEmails.length > 0 || enteredEmailValidation);
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      configCorrect = configCorrect && !!selectedSlackIntegration && !!selectedSlackChannel;
    } else {
      configCorrect = false;
    }
    this.setState({ configCorrect });
  }

  onSave = async () => {
    const {
      workflowId
    } = this.props;
    const {
      selectedDiscoveryInterval,
      selectedSurvey,
      selectedNotifyType,
      selectedSlackIntegration,
      selectedSlackChannel,
      enteredEmails,
      selectedRole,
      enteredEmailValue
    } = this.state;

    let notifyBlock = {};

    const updatedEmailsList = enteredEmailValue.length > 0 ? [...enteredEmails, enteredEmailValue] : enteredEmails;

    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      notifyBlock = {
        'Resource': 'local:notify_email',
        'Parameters': {
          'emailList': updatedEmailsList.join(','),
          'template': 'HC7XBQAW1WMDVCKC1TZ4PV4K3VQV',
          'data.$': '$.themes',
          'workflowId.$': '$.workflowId',
          'accessToken.$': '$.accessToken',
          'clientUrl.$': '$.clientUrl',
          'runId.$': '$.runId',
          'workflowName.$': '$.workflowName',
        },
        'ResultPath': '$.result',
        'Type': 'Task',
        'Next': 'End'
      };
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      notifyBlock = {
        'Resource': 'local:notify_slack',
        'Parameters': {
          'integrationId': `${ selectedSlackIntegration }`,
          'channel': selectedSlackChannel,
          'template': 'HC7XBQAW1WMDVCKC1TZ4PV4K3VQV',
          'data.$': '$.themes',
          'accessToken.$': '$.accessToken',
          'clientUrl.$': '$.clientUrl',
          'workflowId.$': '$.workflowId',
          'runId.$': '$.runId',
          'workflowName.$': '$.workflowName',
        },
        'ResultPath': '$.result',
        'Type': 'Task',
        'Next': 'End'
      };
    }

    let config = {
      'stateMachine': {
        'StartAt': 'DiscoverThemes',
        'States': {
          'DiscoverThemes': {
            'Resource': 'local:discover_themes',
            'Parameters': {
              'surveyId': selectedSurvey,
              'dateResolution': selectedDiscoveryInterval,
              'accessToken.$': '$.accessToken',
              'clientUrl.$': '$.clientUrl',
              'artifactsDestination.$': '$.artifactsDestination'
            },
            'InputPath': '$.input',
            'ResultPath': '$.themes',
            'Type': 'Task',
            'Next': 'Choices'
          },
          'Choices': {
            'Type': 'Choice',
            'Choices': [
              { 'Variable': '$.themes.itemCount', 'NumericGreaterThan': 0, 'Next': 'Notify' }
            ],
            'Default': 'End'
          },
          'Notify': notifyBlock,
          'End': {
            'Type': 'Pass',
            'End': true
          }
        }
      },
      'templateId': TEMPLATE_ID
    };
    let trigger = {
      calledWithCadence: {
        period: selectedDiscoveryInterval,
        offset: 1
      }
    };

    const workflowAnalyticsEvent = workflowId === 'create' ? 'Workflows: Save Workflow' : 'Workflows: Update Workflow';
    analytics.track(workflowAnalyticsEvent, {
      category: 'Workflows',
      template: TEMPLATE_NAME,
      notify: selectedNotifyType
    });

    await this.props.onSave(selectedRole, trigger, config);
    this.resetTemplateScroll();
  }

  resetTemplateScroll = () => {
    const {
      createWorkflowErroredMessage,
      updateWorkflowErroredMessage,
    } = this.props.workflowsStore!;

    const anyErrorsExist = !!createWorkflowErroredMessage || !!updateWorkflowErroredMessage;

    if (anyErrorsExist) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  render(): JSX.Element | null {
    const {
      fetchingData,
      configCorrect,
      selectedRole,
      selectedSurvey,
      selectedNotifyType,
      selectedDiscoveryInterval,
      enteredEmails,
      selectedSlackChannel,
    } = this.state;

    const {
      performingEvent,
      loading,
      workflowId,
      onCancel
    } = this.props;

    return (
      <Form className="theme-discovery-template">
        <Form.Field className="theme-discovery-template-role">
          <h2>Conditions</h2>
          <label>Run workflow using the&nbsp;
            <Dropdown
              inline={true}
              className="theme-discovery-template-inline-dropdown"
              placeholder="Select role"
              loading={fetchingData}
              options={
                (this.roleStore.roles.filter(role => !role.isUserSpecific) || []).map(value => {
                  return {
                    key: value.id,
                    text: value.name,
                    value: value.id
                  };
                })
              }
              value={selectedRole}
              onChange={this.selectedRole}
            />&nbsp;role
          </label>
        </Form.Field>
        <Form.Field className="theme-discovery-template-dataset">
          <h2>Analyze</h2>
          <div className="theme-discovery-template-dataset-options">
            <Dropdown
              placeholder="Select dataset"
              fluid={true}
              selection={true}
              loading={fetchingData}
              icon={<FontAwesomeIcon icon="chevron-down" />}
              options={reduce(this.surveyStore.surveysJson || [], (result, value) => {
                result.push({
                  key: value.id,
                  text: value.name,
                  value: value.id
                });
                return result;
              }, [] as object[])
              }
              value={selectedSurvey}
              onChange={this.selectedSurvey}
            />
          </div>
        </Form.Field>
        <Form.Field className="workflow-schedule">
          <h2>Discover themes</h2>
          <div className="check-theme-discoverys">
            <Dropdown
              className="theme-discovery-interval"
              fluid={true}
              selection={true}
              options={DISCOVERY_INTERVAL}
              value={selectedDiscoveryInterval}
              onChange={this.selectedDiscoveryInterval}
            />
          </div>
        </Form.Field>
        <Form.Field>
          <Segment className="email-action">
            <label>Actions</label>
            <Dropdown
              fluid={true}
              selection={true}
              options={ACTION_OPTIONS}
              value={selectedNotifyType}
              onChange={this.selectedNotifyTypeChange}
            />
            {selectedNotifyType === WorkflowActionType.EMAIL && (
              <EmailAction
                existingEmails={enteredEmails}
                enteredEmails={this.handleEnteredEmails}
              />
            )}
            {selectedNotifyType === WorkflowActionType.SLACK && (
              <SlackAction
                existingSlackChannel={selectedSlackChannel}
                selectedSlackChannel={this.handleSelectedChannel}
              />
            )}
          </Segment>
        </Form.Field>
        <Button
          type="button"
          disabled={performingEvent || fetchingData}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="button"
          color="blue"
          loading={loading || fetchingData}
          disabled={performingEvent || fetchingData || !configCorrect}
          onClick={this.onSave}
        >
          {workflowId === 'create' ? 'Save workflow' : 'Update'}
        </Button>
      </Form>
    );
  }
}

export default ThemeDiscoveryTemplate;