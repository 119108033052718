import { Summary, SummarySegment } from "types/custom";

type ContentBlock = {
  content:string,
  index: number,
  segment: SummarySegment
};

type NonContentBlock = {
  content: string
};

type Block = NonContentBlock | ContentBlock;

/* String.prototype.slice will divide surrogate pairs, which is unhelpful.
 * To handle the locations of emoji, we split the original string into strings
 * representing one character (which may be multi-byte).
 * Then we can use the location indexes to extract the intended string slice.
 */
function extract(chars: Array<string>, start: number, end: number): string {
  return chars.slice(start, end).join('');
}

export function isContentBlock(block: Block): block is ContentBlock {
  return 'segment' in block;
}

export function processSummary(summary: Summary): Block[] {
  const { comment, segments } = summary;

  const commentChars = Array.from(comment);

  if (segments.length === 0) {
    return [{
      content: comment,
    }];
  }

  return segments.reduce((
    result: Block[],
    segment: SummarySegment,
    index: number
  ) => {
    const [start, end] = segment.location;

    const prevSegment = segments[index - 1];

    if (prevSegment) {
      const prevEnd = prevSegment.location[1];

      if (prevEnd !== end - 1 ) {
        // Given some characters exist between the end of the last segment
        // and the start of this segment, insert a non-content block
        const nonContentBlockStart = prevEnd + 1;
        const nonContentBlockEnd = start;

        result.push({
          content: extract(commentChars, nonContentBlockStart, nonContentBlockEnd),
        });
      }
    }

    // Given this is the first segment, and some characters exist before it,
    // insert a non-content block
    if (!prevSegment && start > 0) {
      const nonContentBlockStart = 0;
      const nonContentBlockEnd = start;

      result.push({
        content: extract(commentChars, nonContentBlockStart, nonContentBlockEnd),
      });
    }

    const contentBlock: ContentBlock = {
      content: extract(commentChars, start, end + 1),
      index: segment.index,
      segment
    };

    result.push(contentBlock);

    const nextIndex = index + 1;
    const nextSegment = segments.length <= nextIndex ? null : segments[nextIndex];

    // Given this is the last segment and some characters exist after it,
    // insert a non-content block
    if (!nextSegment && commentChars.length -1 > end) {
      const nonContentBlockStart = end + 1;
      const nonContentBlockEnd = commentChars.length;

      result.push({
        content: extract(commentChars, nonContentBlockStart, nonContentBlockEnd),
      });
    }

    return result;
  }, []);
}
