<template>
  <li
    :class="{
      'theme-item': true,
      'theme-item--fit-content': fitContent,
      'theme-item--is-removed': isRemoved,
      'theme-item--has-bg-colour': hasBackgroundColour,
      'theme-item--is-new': isNew,
      [toSentimentClassname(sentiment, (hasSentiment && isSentimentEnabled))]: true,
    }"
    @click="() => $emit('onClick')"
    @mouseover="() => $emit('onMouseOver')"
    @mouseleave="() => $emit('onMouseLeave')"
  >
    <sentiment
      v-if="hasSentiment && isSentimentEnabled"
      :sentiment="sentiment"
    />
    <span class="title">
      {{ text }}
      <span
        v-if="isNew"
        class="new"
      >UPDATED</span>
    </span>

    <el-tooltip
      ref="removeButtonTooltipRef"
      class="item"
      effect="dark"
      content="Theme editing is not possible while theme application is in progress"
      placement="top"
      :enterable="false"
      :disabled="!hasTooltip"
    >
      <span
        class="remove-theme-button"
        @mouseleave="hideTooltip()"
      >
        <el-button
          v-if="!isRemoved && hasRemoveButton"
          :class="{
            'disabled-remove-theme-icon': areThemesInApplyingState
          }"
          @click.stop="() => $emit('onRemoveClick')"
        >
          <font-awesome-icon icon="times" />
        </el-button>
      </span>
    </el-tooltip>
  </li>
</template>

<script>
import Sentiment from 'vue/components/Sentiment/Sentiment.vue';
import toSentimentClassname from 'vue/libs/to-sentiment-classname';

export default {
  name: "ThemeItem",
  components: {
    Sentiment
  },
  props: {
    areThemesInApplyingState: { type: Boolean, required: true },
    fitContent: { type: Boolean, default: false },
    isNew: { type: Boolean, required: true },
    isRemoved: { type: Boolean, required: true },
    hasSentiment: { type: Boolean, required: true },
    hasBackgroundColour: { type: Boolean, required: true },
    hasRemoveButton: { type: Boolean, required: true },
    hasTooltip: { type: Boolean, required: true },
    sentiment: { type: Number, required: true },
    text: { type: String, required: true },
    isSentimentEnabled: {type: Boolean, default: true}
  },
  methods: {
    toSentimentClassname,
    hideTooltip() {
      this.$refs.removeButtonTooltipRef.showPopper = false;
    }
  }
};


</script>


<style lang="scss" scoped>
@import '../../styles/element-variables';

$border-radius: 0.3em;
.theme-item {
  align-items: center;
  border: none;
  color: $--neutral-700;
  display: flex;
  font-size: 1rem;
  line-height: 1.15;
  border-radius: $border-radius;
  min-height: 2rem;
  padding-left: 0.75rem;
  .sentiment-icon  {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 0.5rem;
    flex: 0 0 auto;
  }
  .title {
    margin-right: 0.5rem;
  }
  .new {
    background-color: $--orange-600;
    border-radius: 2px;
    color: $--orange-100;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 0 2px;
  }
  .remove-theme-button {
    margin-left: auto;
  }
}

.theme-item--has-bg-colour {
  .el-button {
    color: inherit;
  }
  background-color: $--primary-100;
  color: $--primary-700;
  &.theme-sentiment-frown {
    background-color: $--simple-sentiment-frown;
    color: $--simple-sentiment-text-color-frown;
    .el-button:not(.disabled-remove-theme-icon):hover {
      background-color: $--red-200;
    }
  }
  &.theme-sentiment-meh {
    background-color: $--simple-sentiment-meh;
    color: $--simple-sentiment-text-color-meh;
    .el-button:not(.disabled-remove-theme-icon):hover {
      background-color: $--yellow-200;
    }
  }
  &.theme-sentiment-smile {
    background-color: $--simple-sentiment-smile;
    color: $--simple-sentiment-text-color-smile;
    .el-button:not(.disabled-remove-theme-icon):hover {
      background-color: $--green-200;
    }
  }
}

.theme-item--is-removed {
  color: $--neutral-500;
  text-decoration: line-through;
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  .sentiment-icon {
    // need to use important here because original sentiment file is using important as well
    color: $--neutral-500!important;
    background-color: $--neutral-300!important;
  }
}
.theme-item--fit-content {
  max-width: fit-content;
}

.el-button {
  padding: 0.5rem 0.75rem;
  background: none;
  border: 0;
  border-radius: 0 $border-radius $border-radius 0;
  margin-left: auto;
  &.disabled-remove-theme-icon {
    opacity: 0.4;
    cursor: not-allowed;
    background: transparent;
    &:hover, &:focus {
      color: $--neutral-700;
      background-color: transparent;
    }
  }
}


</style>
