import { AnswerNode } from 'types/custom';

export function isOnlyOutputItem(item: AnswerNode['item'], nodes: AnswerNode[]): boolean {

  const isOutput = item.state === 'output';
  const isTopLevel = nodes.some(node => node.item.id === item.id);
  const otherOutputNodes = nodes.filter(node => {
    return node.item.id !== item.id && node.item.state === 'output';
  });

  if (!isOutput) {
    return false;
  }

  if (!isTopLevel) {
    // can't be the only item if it is a child
    return false;
  }

  return otherOutputNodes.length === 0;

}
