import * as React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

type Props = {
  isLoading: boolean
};

export default ({ isLoading = true }: Props): JSX.Element => (
  <Dimmer active={isLoading} inverted={true}>
    <Loader size="large">Loading…</Loader>
  </Dimmer>
);
