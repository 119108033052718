<template>
  <div
    ref="root"
    v-loading="loading"
    data-id="volume-over-time"
    class="chart"
  >
    <div class="chart-header">
      <h4 class="chart-title">
        Volume over time
        <th-tooltip> Percentage of responses tagged with the selected theme. </th-tooltip>
      </h4>
      <el-dropdown
        :hide-on-click="false"
        trigger="click"
        placement="bottom-end"
      >
        <el-button
          class="chart-action"
          size="small"
          type="plain-text"
        >
          <font-awesome-icon icon="cog" />
          <font-awesome-icon icon="chevron-down" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <div class="chart-options">
            <div class="chart-option">
              <div class="chart-option-label">
                Show:
              </div>
              <ul class="chart-option-list">
                <li class="chart-option-item">
                  <el-radio
                    v-model="showBy"
                    label="percentage"
                  >
                    Percentage
                  </el-radio>
                </li>
                <li class="chart-option-item">
                  <el-radio
                    v-model="showBy"
                    label="count"
                  >
                    Count
                  </el-radio>
                </li>
              </ul>
            </div>
            <div class="chart-option">
              <div class="chart-option-label">
                Period:
              </div>
              <ul class="chart-option-list">
                <li class="chart-option-item">
                  <el-radio
                    v-model="dateResolution"
                    label="weekly"
                  >
                    Weekly
                  </el-radio>
                </li>
                <li class="chart-option-item">
                  <el-radio
                    v-model="dateResolution"
                    label="monthly"
                  >
                    Monthly
                  </el-radio>
                </li>
                <li class="chart-option-item">
                  <el-radio
                    v-model="dateResolution"
                    label="quarterly"
                  >
                    Quarterly
                  </el-radio>
                </li>
                <li class="chart-option-item">
                  <el-radio
                    v-model="dateResolution"
                    label="biannually"
                  >
                    Biannually
                  </el-radio>
                </li>
                <li class="chart-option-item">
                  <el-radio
                    v-model="dateResolution"
                    label="rolling90"
                  >
                    90 day rolling
                  </el-radio>
                </li>
              </ul>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div
      v-if="fetchError"
      class="error-container"
    >
      <error
        variant="inline-medium"
        title="There was a problem fetching chart data"
        :message="fetchError"
        button-text="Retry"
        @onButtonClick="fetchData"
      />
    </div>
    <div
      v-if="!fetchError"
      class="chart-container"
    >
      <div ref="overTimeFrequencyChart" />
    </div>
  </div>
</template>

<script>
const CHART_WIDTH_OFFSET = 20;
const MIN_CHART_WIDTH = 720;
import { isString } from 'lodash';
import { watchAll } from 'vue/libs/vueExt';

import { getAnalysisToolsUIStore, getAnalysisToolsKeyTakeawaysUIStore, getInitConfigStore } from 'stores/RootStore';
import overTimeFetcher, { getRenderScoreGraphOptions } from 'charts/Utils/over-time-fetcher';
import ThTooltip from 'vue/components/Tooltip/Tooltip.vue';
import { timeSeriesTakeaway } from 'vue/libs/takeaways';
import { getScoreGraphToolTip, renderScoreGraphData } from 'charts/Utils/scoreGraph';
import Error from "vue/components/Error/Error.vue";

export default {
  name: 'OverTimeFrequencyChart',
  components: { ThTooltip, Error },
  props: {
    keyTakeawaysKey: { type: String },
    selectedDateLabel: { default: undefined, type: String },
    selections: { default: undefined, type: Object },
    segmentQueryStrings: { default: undefined, type: Object },
  },
  data() {
    return {
      analysisToolsKeyTakeawaysUIStore: getAnalysisToolsKeyTakeawaysUIStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      fetchError: null,
      initConfigStore: getInitConfigStore(),
      loading: true,
      chartPromise: undefined,
      themes: undefined,
      showBy: 'percentage',
      dateResolution: 'monthly',
      selectedChartOption: null,
      d3Tip: undefined,
    };
  },
  computed: {
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    subtheme() {
      return this.analysisToolsUIStore.selectedSubthemeName;
    },
    theme() {
      return this.analysisToolsUIStore.selectedThemeName;
    },
    isComparison() {
      return this.selections.baseline.query !== this.selections.comparison.query;
    },
    title() {
      const { theme, subtheme } = this;
      if (subtheme) {
        return subtheme;
      }
      return theme;
    },
  },
  watch: {
    dateResolution: {
      handler(newDateResolution) {
        this.$emit('dateResolutionUpdated', newDateResolution);
      },
    },
    selectedDateLabel(label) {
      const { themes } = this;
      if (!themes || this.selectedChartOption === label) {
        return;
      }
      const indexOfLabel = themes.baseline.dateLabels.findIndex(value => value === label);
      const isLabelPresent = indexOfLabel >= 0;
      this.selectedChartOption = isLabelPresent ? label : null;
      this.renderChart();
    },
  },
  mounted() {
    window.addEventListener('resize', this.renderChart);
    watchAll(this, ['selections', 'theme', 'subtheme', 'dateResolution', 'showBy'], this.fetchData);
    this.fetchData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderChart);
    this.d3Tip?.destroy();
  },
  methods: {
    fetchData: async function () {
      this.fetchError = null;
      // we can't do anything (yet) if both these are falsey
      this.analysisToolsKeyTakeawaysUIStore.updateTakeaway(this.keyTakeawaysKey, []);
      if (!this.theme && !this.subtheme) {
        return;
      }

      this.loading = true;
      if (this.chartPromise) {
        await this.chartPromise;
      }
      const opts = this.getScoreGraphOptions();
      try {
        this.chartPromise = overTimeFetcher(opts);
        this.themes = await this.chartPromise;
        const baselineFreq = this.themes.baselineFreq.map(baseline => baseline.v);
        const keyTakeawaySummary = timeSeriesTakeaway(
          'volume',
          this.subtheme ? this.subtheme : this.theme,
          baselineFreq,
          () => {
            this.$refs['root'].scrollIntoView({ behavior: 'smooth' });
          },
        );
        if (keyTakeawaySummary) {
          this.analysisToolsKeyTakeawaysUIStore.updateTakeaway(this.keyTakeawaysKey, [keyTakeawaySummary]);
        }
        this.renderChart();
      } catch(e) {
        this.fetchError = e;
      } finally {
        this.chartPromise = undefined;
        this.loading = false;
      }
    },
    getChartWidth() {
      const el = this.$refs.overTimeFrequencyChart;
      if (el) {
        return el.parentNode.getBoundingClientRect().width + CHART_WIDTH_OFFSET;
      }
      return MIN_CHART_WIDTH;
    },
    renderChart() {
      const { themes } = this;
      if (themes) {
        const {
          baseline,
          baselineFreq,
          comparison,
          selectionFreq
        } = themes;
        const opts = this.getScoreGraphOptions();
        const renderOptions = getRenderScoreGraphOptions(
          baseline,
          comparison,
          baselineFreq,
          selectionFreq,
          opts
        );
        this.d3Tip?.destroy();
        this.d3Tip = getScoreGraphToolTip(renderOptions);

        renderScoreGraphData({
          el: opts.el,
          options: renderOptions,
          tip: this.d3Tip,
        });
      }
    },
    getScoreGraphOptions() {
      return {
        commentColumns: this.commentColumns,
        dateResolution: this.dateResolution,
        el: this.$refs.overTimeFrequencyChart,
        showBy: this.showBy,
        requestOptions: this.requestOptions,
        selectedThemes: [this.theme, this.subtheme],
        selections: this.selections,
        themeSegmentSelection: this.segmentQueryStrings,
        onScoreGraphClick: this.onDateSelect,
        chartWidth: this.getChartWidth(),
        isComparison: this.isComparison,
        selectedChartOption: this.selectedChartOption,
      };
    },
    onDateSelect(label) {
      this.selectedChartOption = label;
      const { themes } = this;
      if (isString(label)) {
        const indexOfLabel = themes.baseline.dateLabels.findIndex(value => value === label);
        const query = indexOfLabel >= 0 ? themes.baseline.dateQueries[indexOfLabel] : '';
        const baselineCounts = themes.baselineFreq[indexOfLabel].counts || 0;
        const comparisonCounts = themes.selectionFreq[indexOfLabel].counts || 0;
        const counts = { baseline: baselineCounts, comparison: comparisonCounts };
        this.$emit('dateSelected', label, query, counts);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
<style lang="scss" scoped>
@import '../styles/element-variables.scss';
.chart {
  text-align: left;
  padding-bottom: 35px;
}
.chart-container {
  height: 200px;
  margin-left: -20px;
}
.chart-header {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  .el-button {
    flex: 0 0 auto;
  }
}
.error-container {
  height: 200px;
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>

