<template>
  <div class="config-field config-field--summary-type">
    <label>
      Summary of
    </label>
    <div
      class="config-radio-options summary-radio-options"
    >
      <el-select
        v-model="selectedSummaryType"
        @change="onSummaryTypeChange"
      >
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { SUMMARY_WIDGET_OPTIONS } from '../constants';
import { WidgetSummaryType } from 'api/enums';

export default {
  name: 'ConfigureWidgetSummaryType',
  props: {
    target: { type: String },
    widgetConfig: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      selectedSummaryType: undefined,
    }
  },
  computed: {
    options() {
      return SUMMARY_WIDGET_OPTIONS;
    },
  },
  mounted() {
    this.selectedSummaryType = this.widgetConfig.summaryType || WidgetSummaryType.ALL;
  },
  methods: {
    onSummaryTypeChange() {
      this.$emit('onConfigChange', this.target, this.selectedSummaryType, true);
    }
  }
}
</script>
<style lang="scss">
.config-field--summary-type {
  display: flex;
  align-items: baseline;
  flex-direction: row;

  .config-radio-options {
    margin-left: 0.5rem;
  }
  .dashboard.edit-mode & .config-radio-options {
    padding-top: 0;
  }
  .dashboard.edit-mode & .el-input,
  .dashboard.edit-mode & .el-select {
    width: unset;
  }
}
</style>
