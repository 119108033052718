import { IntegrationType } from 'api/enums';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Form, FormInputProps } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceAlchemerCreateFormProps {
  existingConfiguration?: AlchemerConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface AlchemerSurveyInfo {
  name: string;
  id: string;
  created: string;
  responses: string;
}

interface AlchemerConfig {
  surveyId: string;
}

export interface DataSourceAlchemerCreateFormState {
  selectedSurvey?: AlchemerSurveyInfo;
  config?: AlchemerConfig;
}

@observer
class DataSourceAlchemerCreateForm extends React.Component<
  DataSourceAlchemerCreateFormProps,
  DataSourceAlchemerCreateFormState
> {
  state = {} as DataSourceAlchemerCreateFormState;

  componentDidMount() {
    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  changeSelectedSurvey = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const selectedSurvey = JSON.parse(value);

    const config = {
      surveyId: selectedSurvey.id
    };

    this.setState({ selectedSurvey, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!selectedSurvey.id);
    }
  }

  setDefaultSurvey = (availableSurveys: AlchemerSurveyInfo[]): void => {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    if (availableSurveys.length === 0 || !existingConfiguration || selectedSurvey) {
      return;
    }

    const defaultSelectedSurvey = this.getExistingSurvey(availableSurveys, existingConfiguration);

    if (!defaultSelectedSurvey) {
      return;
    }
    this.setState({
      selectedSurvey: defaultSelectedSurvey,
      config: existingConfiguration,
    });
  }

  getExistingSurvey = (
    availableSurveys: AlchemerSurveyInfo[],
    existingConfiguration?: AlchemerConfig
  ) => {
    return availableSurveys.find(({ id }) => id === existingConfiguration?.surveyId);
  }

  renderForm(availableSurveys: AlchemerSurveyInfo[] | undefined): JSX.Element | null {
    const { selectedSurvey } = this.state;
    const { existingConfiguration } = this.props;

    // create the source type options as list for dropdown
    const availableSurveysOptions = map(availableSurveys, item => {
      return {
        text: item.name + ` (${ item.id })`,
        value: JSON.stringify(item)
      };
    });

    if (availableSurveys) {
      const defaultValue = existingConfiguration
        ? JSON.stringify(this.getExistingSurvey(availableSurveys, existingConfiguration))
        : undefined;

      return (
        <Form role="form">
          <Form.Field>
            <label>Survey</label>

            <Dropdown
              selection={true}
              options={availableSurveysOptions}
              defaultValue={defaultValue}
              placeholder="Select"
              onChange={this.changeSelectedSurvey}
            />
          </Form.Field>
          {selectedSurvey && (
            <div>
              <Form.Field>
                <label>Id</label> <span>{selectedSurvey.id}</span>
              </Form.Field>
              <Form.Field>
                <label>Name</label> <span>{selectedSurvey.name}</span>
              </Form.Field>
              <Form.Field>
                <label>Created</label> <span>{selectedSurvey.created}</span>
              </Form.Field>
              <Form.Field>
                <label>Responses</label> <span>{selectedSurvey.responses}</span>
              </Form.Field>
            </div>
          )}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          onEnumeration={(availableSurveys: AlchemerSurveyInfo[]) => this.setDefaultSurvey(availableSurveys)}
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.SURVEYGIZMO} />
      </div>
    );
  }
}

export default DataSourceAlchemerCreateForm;
