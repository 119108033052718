<template>
  <el-dialog
    title="Add filter"
    :visible="true"
    width="500px"
    class="add-filter-modal"
    :show-close="false"
  >
    <div v-if="loading">
      <configure-source @onSourceUpdate="onSourceUpdate" />
      <div v-loading="true" />
    </div>
    <div v-else>
      <configure-source @onSourceUpdate="onSourceUpdate" />
      <div class="config-field">
        <label> Filter </label>
        <el-select
          v-model="selectedFilter"
          placeholder="Select"
          value-key="id"
        >
          <el-option
            v-for="item in filterOptions"
            :key="item.id"
            :label="item.name"
            :value="item"
          />
        </el-select>
        <el-checkbox
          v-if="!isFilterScore"
          v-model="isSelectionWidget"
          class="config-check-box"
          :disabled="selectionWidgetExist"
        >
          <p v-if="selectionWidgetExist">
            You can only have 1 comparison filter at a time
          </p>
          <p v-else>
            Use filter for comparison data
          </p>
        </el-checkbox>
      </div>
      <div
        v-if="isSelectionWidget"
        class="config-field"
      >
        <label> Widget Type </label>
        <el-select
          v-model="selectedSelectionWidget"
          placeholder="Select"
          @change="onSelectionWidgetChange"
        >
          <el-option
            v-for="item in selectionWidgetOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <configure-widget-score
        v-if="isSelectionScoreWidgetOption"
        :analysis-config="analysisConfig"
        @onConfigChange="onScoreUpdate"
      />
    </div>
    <div
      slot="footer"
      class="actions"
    >
      <el-button @click="close">
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="!isValid"
        @click="addFilter"
      >
        Ok
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getActiveDashboardUIStore, getAnalysisConfigStore } from 'stores/RootStore';
import { isEmpty } from 'lodash';
import ConfigureSource from './ConfigureSource.vue';
import { SelectionWidgetType } from 'api/enums';
import ConfigureWidgetScore from './ConfigureWidget/ConfigureWidgetScore.vue';
import { SELECTION_WIDGET_OPTIONS, SELECTION_SCORE_WIDGET_OPTIONS } from './constants';

export default {
  name: 'AddFilter',
  components: { ConfigureSource, ConfigureWidgetScore },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
      analysisConfigStore: getAnalysisConfigStore(),

      availableFilters: [],
      sourceOptions: [],

      selectedFilter: undefined,
      selectedSource: undefined,
      selectedSelectionWidget: undefined,
      isSelectionWidget: false,
      analysisConfig: {},
      selectionWidgetScore: undefined,

      filterTarget: 'filters',
      selectionWidgetTarget: 'selectionWidget',

      loading: false,
    };
  },
  computed: {
    isFilterScore() {
      if (this.selectedFilter && this.scoreExist) {
        return !!this.analysisConfig.scores.find((score) => score.score_column === this.selectedFilter.column);
      }
      return false;
    },
    filterOptions() {
      // Only include standard filters (has no filter type)
      return (this.availableFilters || []).filter((filter) => !filter.type);
    },
    selectionWidgetExist() {
      return !isEmpty(this.activeDashboardUIStore.dashboardConfig.selectionWidget);
    },
    isSelectionScoreWidgetOption() {
      return (
        this.selectedSelectionWidget === SelectionWidgetType.SELECTION_SCORERANK ||
        this.selectedSelectionWidget === SelectionWidgetType.SELECTION_SCORE_BREAKDOWN
      );
    },
    scoreExist() {
      // if score exists we show all 3 selection widget but if not we show only (selection_dropdown)
      return !!this.analysisConfig.scores;
    },
    selectionWidgetOptions() {
      if (this.scoreExist) {
        return [...SELECTION_WIDGET_OPTIONS, ...SELECTION_SCORE_WIDGET_OPTIONS];
      } else {
        return SELECTION_WIDGET_OPTIONS;
      }
    },
    isValid() {
      // Must have source and filter
      if (!this.selectedSource || !this.selectedFilter) {
        return false;
      }
      // If used as comparison, must have selection widget
      if (this.isSelectionWidget) {
        if (this.selectedSelectionWidget) {
          // If selection score widget is chosen, must have score
          return (
            !this.isSelectionScoreWidgetOption || (this.isSelectionScoreWidgetOption && !!this.selectionWidgetScore)
          );
        }
        return false;
      }
      // At this point, it's not a selection widget, has source and filter
      return true;
    },
  },
  methods: {
    async onSourceUpdate(sourceKey) {
      this.loading = true;

      // Reset selected option on source change
      this.selectedFilter = undefined;
      this.isSelectionWidget = false;
      this.selectedSelectionWidget = undefined;
      this.selectionWidgetScore = undefined;

      this.selectedSource = sourceKey;

      const source = this.activeDashboardUIStore.sources[sourceKey];
      const dashboardId = this.activeDashboardUIStore.currentDashboardId;
      this.analysisConfig = await this.analysisConfigStore.getConfig(source, dashboardId);

      const { dashboardConfig } = this.activeDashboardUIStore;
      let existingFilters = dashboardConfig.filters || [];

      const { selectionWidget } = dashboardConfig;
      if (selectionWidget && selectionWidget.config.filter) {
        // we don't want to show filter in selection widget to appear in list of available filters, because a filter can either be used as a comparison filter or a standard filter but not both
        existingFilters = [...existingFilters, selectionWidget.config.filter];
      }

      // Add filters that are not already on the dashboard
      this.availableFilters = (this.analysisConfig.filters || []).filter(
        (filter) => !existingFilters.find((f) => f.id === filter.id && f.column === filter.column),
      );

      this.loading = false;
    },
    close() {
      this.$emit('onClose');
    },
    onSelectionWidgetChange() {
      // setting selectionWidgetScore undefined here to mkae sure if user re-selects SELECTION_DROPDOWN option and then go back again to other options which has score then button is disabled
      if (this.selectedSelectionWidget === SelectionWidgetType.SELECTION_DROPDOWN) {
        this.selectionWidgetScore = undefined;
      }
    },
    addFilter() {
      if (!isEmpty(this.selectedFilter)) {
        if (this.selectedSelectionWidget) {
          if (this.selectedSelectionWidget === SelectionWidgetType.SELECTION_DROPDOWN) {
            const selectedSelectionWidget = {
              source: this.selectedSource,
              config: {
                filter: this.selectedFilter,
                type: this.selectedSelectionWidget,
              },
            };
            this.activeDashboardUIStore.setValue(this.selectionWidgetTarget, selectedSelectionWidget);
          } else if (this.selectionWidgetScore) {
            const selectedSelectionWidget = {
              source: this.selectedSource,
              config: {
                filter: this.selectedFilter,
                type: this.selectedSelectionWidget,
                score: this.selectionWidgetScore,
              },
            };
            this.activeDashboardUIStore.setValue(this.selectionWidgetTarget, selectedSelectionWidget);
          }
          // Update widgets to have compareToOverall set to true
          this.activeDashboardUIStore.updateWidgetsForSelectionFilter();
        } else {
          const filterConfig = this.activeDashboardUIStore.getValue(this.filterTarget) || [];
          const updatedFilters = [...(filterConfig || []), this.selectedFilter];
          this.activeDashboardUIStore.setValue(this.filterTarget, updatedFilters);
        }
      }
      this.close();
    },
    onScoreUpdate(target, score) {
      this.selectionWidgetScore = score;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-filter-modal {
  ::v-deep .el-dialog {
    min-height: 150px;
  }
  .no-filter {
    padding-bottom: 20px;
  }
}
</style>