<template>
  <div
    v-loading="true"
    class="dashboard"
    element-loading-text="Loading config..."
  />
</template>

<script>
export default {
  name: 'DashboardLoading',
  props: {
    config: { type: Object }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';
.dashboard {
  min-height: 600px;

  ::v-deep .el-loading-spinner {
    .el-loading-text {
      color: $--neutral-300;
    }
  }

  ::v-deep .el-loading-mask {
    background: none;
  }
}
</style>
