import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceFeefoCreateFormProps {
    onChange(configuration: object, integrationId: string): void;
}

interface DataSourceFeefoCreateFormState {
}

@observer
class DataSourceFeefoCreateForm extends React.Component<
    DataSourceFeefoCreateFormProps,
    DataSourceFeefoCreateFormState
> {

  renderForm(): JSX.Element | null {
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
        <div>
            <DataSourceWithIntegrationForm
              renderFormComponent={this}
              onChange={onChange}
              integrationType={IntegrationType.FEEFO} />
        </div>
    );
  }
}

export default DataSourceFeefoCreateForm;