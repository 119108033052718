import thematicLogo from 'images/thematic-logo-58x58.png';
import { forEach } from 'lodash';

export interface AlternativeRegion {
  name: string;
  url: string;
}

type ThematicConfig = {
  region: string;
  appUrlBase: string;
  adminLocation: string;
  apiLocation: string;
  loginConfigLocation: string;
  auth0ClientID: string;
  auth0Domain: string;
  auth0Redirect: string;
  auth0Config: {
    configurationBaseUrl: string;
    languageDictionary: {
      title: string;
      error?: {
        login?: {
          blocked_user?: string;
        }
      }
    };
    theme: {
      logo: string;
      primaryColor: string;
    };
    redirectUri: string;
    oidcConformant: boolean;
    auth: {
      responseType: string;
      audience: string;
      params: {
        scope: string;
        device?: string;
      };
    };
  };
  debugString: string;
  segmentID: string;
  posthogID: string;
  honeycombID: string;
  honeycombDataset: string;
  raygunID: string;
  ssoDomain: string;
  alternativeRegionList: AlternativeRegion[];
  answersLocation: string;
};

const {
  REACT_APP_REGION = '',
  REACT_APP_URL_BASE = '',
  REACT_APP_ADMIN_LOCATION = '',
  REACT_APP_API_LOCATION = '',
  REACT_APP_SSO_DOMAIN = '',
  REACT_APP_LOGIN_CONFIG_LOCATION = '',
  REACT_APP_AUTH0_CLIENT_ID = '',
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_REDIRECT = '',
  REACT_APP_AUTH0_TITLE = '',
  REACT_APP_DEBUG_STRING = '',
  REACT_APP_SEGMENT_ID = '',
  REACT_APP_POSTHOG_ID = '',
  REACT_APP_HONEYCOMB_ID = '',
  REACT_APP_HONEYCOMB_DATASET = '',
  REACT_APP_RAYGUN_ID = '',
  REACT_APP_ALTERNATIVE_REGION_NAMES = '',
  REACT_APP_ALTERNATIVE_REGION_URLS = '',
  REACT_APP_ANSWERS_LOCATION = '',
} = process.env;

const alternativeRegionList = [] as AlternativeRegion[];
try {
  const names = REACT_APP_ALTERNATIVE_REGION_NAMES.split(',');
  const urls = REACT_APP_ALTERNATIVE_REGION_URLS.split(',');

  forEach(names, (name, i) => {
    const url = urls[i];
    if (name && url) {
      alternativeRegionList.push({ name, url });
    }
  });
} catch (e) {
  // do nothing
}

const config: ThematicConfig = {
  region: REACT_APP_REGION,
  appUrlBase: REACT_APP_URL_BASE,
  adminLocation: REACT_APP_ADMIN_LOCATION,
  apiLocation: REACT_APP_API_LOCATION,
  ssoDomain: REACT_APP_SSO_DOMAIN,
  loginConfigLocation: REACT_APP_LOGIN_CONFIG_LOCATION,
  debugString: REACT_APP_DEBUG_STRING,
  auth0ClientID: REACT_APP_AUTH0_CLIENT_ID,
  auth0Domain: REACT_APP_AUTH0_DOMAIN,
  auth0Redirect: REACT_APP_AUTH0_REDIRECT,
  segmentID: REACT_APP_SEGMENT_ID,
  posthogID: REACT_APP_POSTHOG_ID,
  honeycombID: REACT_APP_HONEYCOMB_ID,
  honeycombDataset: REACT_APP_HONEYCOMB_DATASET,
  raygunID: REACT_APP_RAYGUN_ID,
  auth0Config: {

    configurationBaseUrl: 'https://cdn.auth0.com',

    languageDictionary: {
      title: REACT_APP_AUTH0_TITLE,
      error: {
        login: {
          blocked_user: 'User can\'t login with password. Try SSO or contact your admin'
        }
      }

    },
    theme: {
      logo: thematicLogo,
      primaryColor: '#E73455'
    },
    redirectUri: REACT_APP_AUTH0_REDIRECT,
    oidcConformant: true,
    auth: {
      responseType: 'token id_token',
      audience: 'https://client.getthematic.com/api',
      params: {
        scope: [
          'create:result',
          'create:upload',
          'download:result',
          'download:workflow',
          'email',
          'manage:workflow',
          'manage:idProvider',
          'manage:integration',
          'manage:organization',
          'manage:report',
          'manage:result',
          'manage:subscriptions',
          'manage:survey',
          'manage:themes',
          'manage:user',
          'manage:role',
          'manage:visualization',
          'openid',
          'profile',
          'read:workflow',
          'read:idProvider',
          'read:integration',
          'read:metrics',
          'read:organization',
          'read:report',
          'read:result',
          'read:survey',
          'read:themes',
          'read:upload',
          'read:user',
          'read:role',
          'read:visualization',
          'view:analysis',
          'create:answer',
          'manage:answer',
          'update:workflow',
          'read:aggregateView',
          'manage:aggregateView',
          'download:dataProcessingWorkflow',
          'update:dataProcessingWorkflow',
          'manage:internalResource',
        ].join(' ')
      }
    }
  },
  alternativeRegionList,
  answersLocation: REACT_APP_ANSWERS_LOCATION
};

export default config;
