import * as React from 'react';
import { exampleQuestions } from '../utils/exampleQuestions';
import './welcome.scss';
import { Card } from 'components/Shared/Card';

interface Props {
  onQuestionClick: (suggestion: string) => void;
}

const Welcome = (props: Props) => {
  return (
    <div className="answers-welcome" data-testid="answers-welcome">
      <div className="answers-welcome__card">
        <h1 className="answers-welcome__heading nw-answers-welcome">Welcome to Thematic Answers</h1>
        <h2 className="answers-welcome__blurb">To get started try some of our suggestions or ask your own below</h2>
        <ul className="question-presets__list">
          {exampleQuestions.map((presetQuestion, index) => (
            <li key={index}>
              <Card
                key={index}
                size="medium"
                icon={presetQuestion.icon}
                color={presetQuestion.color}
                heading={presetQuestion.label}
                content={presetQuestion.category}
                onClick={() => props.onQuestionClick(presetQuestion.label)}
              />
            </li>
          ))}
        </ul>
        <footer className="answers-welcome__footer">
          Note: Try to ask about specific themes and dimensions.
          Eg. 'What people were saying about [Updates] in [September]'.
          You can also refer to the <a className="answers-welcome__footer-link"
          href="https://help.getthematic.com/article/133-question-types-that-answers-supports">
          Example questions</a> for guidance.
        </footer>
      </div>
    </div>
  );
};
export { Welcome };
