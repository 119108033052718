import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import analytics from 'lib/analytics';
import ApplyParametersForm from 'components/ThemeEditor/ParametersEditor/ApplyParametersForm';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Slider } from 'react-semantic-ui-range';
import { Button, Form, Header, Message, Modal, Select } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import './theme-parameter.scss';

const taggingModelOptions = [
  { key: 'TFIDF', value: 'TFIDF', text: 'TFIDF (Original)' },
  { key: 'SBERT', value: 'SBERT', text: 'SBERT' }
];
const sentimentModelOptions = [
  { key: 'BERT_V1', value: 'BERT_V1', text: 'BERT_V1 (Original)' },
  { key: 'LSTM', value: 'LSTM', text: 'LSTM' },
];
const maxThemesOptions = [
  { key: '1', value: '{"base": 50, "fold": 200}', text: 'Normal' },
  { key: '2', value: '{"base": 187, "fold": 187}', text: '1.5x' },
  { key: '3', value: '{"base": 250, "fold": 250}', text: '2.0x' },
];

interface ParametersEditorProps {
  cancel: () => void;
  open: boolean;
  orgId: string;
  surveyId: string;
  surveyName: string;
}
interface ParametersEditorState {
  correct: boolean;
  working: boolean;
  showApplyModal: boolean;
  includesDestructiveParams: boolean;
  error: string;
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

@inject('themesStore')
@observer
export default class ParametersEditor extends React.Component<
ParametersEditorProps,
ParametersEditorState
> {
  constructor(props: ParametersEditorProps) {
    super(props);

    this.state = {
      correct: true,
      working: false,
      showApplyModal: false,
      includesDestructiveParams: false,
      error: '',
    };
  }

  get injected(): InjectedProps {
    return this.props as ParametersEditorProps & InjectedProps;
  }

  close = () => {
    this.closeApplyModal();
    this.props.cancel();
  };
  apply = () => {
    this.closeApplyModal();
    const { themesStore } = this.injected;
    this.setState({ working: true });

    themesStore.applyThemeParameters().then(success => {
      this.setState({ working: false });
      if (success) {
        this.close();
      }
    });
  };
  // functions to control the visibility of the apply modal
  openApplyModal = () => {
    // tell the store about the new Parameters
    this.setState({ showApplyModal: true });
  };
  closeApplyModal = () => {
    this.setState({ showApplyModal: false });
  };

  setThemeApplyParameter = (value: number) => {
    const { themesStore: store } = this.injected;
    store.setThemeParameter('APPLY_THEMES_PARAMS.APPLY_CONFIDENCE', value / 100.0);
  };

  handleTaggingModelChange = (data) => {
    const { themesStore: store } = this.injected;
    store.setThemeParameter('APPLY_THEMES_PARAMS.PROB_TAGGER', data.value);
  }

  handleSentimentModelChange = (data) => {
    const { themesStore: store } = this.injected;
    store.setThemeParameter('APPLY_THEMES_PARAMS.SENTIMENT_MODEL', data.value);
  }

  handleThemeNumChange = (data) => {
    const { themesStore: store } = this.injected;
    const value = JSON.parse(data.value);
    store.setThemeParameter('EXTRACT_THEMES_PARAMS.MAX_NUM_BASE_THEMES', value.base);
    store.setThemeParameter('EXTRACT_THEMES_PARAMS.MAX_THEMES_TO_FOLD', value.fold);
    this.setState({ includesDestructiveParams: true });
  }

  render() {
    const { open, surveyName } = this.props;
    const { error, showApplyModal, working, includesDestructiveParams } = this.state;
    const { themesStore: store } = this.injected;
    const applyConfidencePercentage = store.parameters['APPLY_THEMES_PARAMS.APPLY_CONFIDENCE'] * 100.0;

    let taggingModelValue = store.parameters['APPLY_THEMES_PARAMS.PROB_TAGGER'];
    if (!taggingModelValue) {
      taggingModelValue = taggingModelOptions[0].value;
    }

    let sentimentModelValue = store.parameters['APPLY_THEMES_PARAMS.SENTIMENT_MODEL'];
    if (!sentimentModelValue) {
      sentimentModelValue = sentimentModelOptions[0].value;
    }

    let maxThemesValue = maxThemesOptions[0].value;
    let maxBaseThemes = store.parameters['EXTRACT_THEMES_PARAMS.MAX_NUM_BASE_THEMES'];
    if (maxBaseThemes) {
      const foundOption = maxThemesOptions.find(o => o.value.includes(`"base": ${ maxBaseThemes }`));
      if (foundOption) {
        maxThemesValue = foundOption.value;
      }
    }

    const applyThemesSliderSettings = {
      start: applyConfidencePercentage,
      min: 50,
      max: 100,
      step: 1,
      onChange: value => {
        this.setThemeApplyParameter(value);
      }
    };

    return (
      <div>
        <Modal
          open={open}
          size="small"
          dimmer="blurring"
          onClose={this.close}
          className="theme-parameter-modal"
        >
          <Modal.Header as={Header}>Parameters Editor</Modal.Header>
          <Modal.Content scrolling={true}>
            <Form>
              <Form.Field>
                {store.errorMessage && (
                  <Message negative={true}>
                    <Message.Content>
                      <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
                      {store.errorMessage}
                    </Message.Content>
                  </Message>
                )}
              </Form.Field>
              <Form.Field>
                <label>Apply Theme Threshold</label>
                <div className="center">{applyConfidencePercentage}%</div>
                <Slider value={applyThemesSliderSettings.start} settings={applyThemesSliderSettings} />
                <div><span className="detail">50%</span><span className="detail detail-right">100%</span></div>
                <div className="explainer">
                  This threshold sets how much confidence the system has before applying
                  a theme that doesn't match an exact mapped phrase.
                  100% makes the system completely deterministic
                  (at the expense of tagging coverage).
                </div>
              </Form.Field>
              <Form.Field>
                <label>Theme Tagging Model</label>

                <Select
                  value={taggingModelValue}
                  options={taggingModelOptions}
                  onChange={(_e, data) => this.handleTaggingModelChange(data)}
                />
                <div className="explainer">
                  This model selection chooses how comments/sentences that aren't
                  tagged with mapped phrases are handled.
                </div>
              </Form.Field>
              <Form.Field>
                <label>Sentiment Model</label>

                <Select
                  value={sentimentModelValue}
                  options={sentimentModelOptions}
                  onChange={(_e, data) => this.handleSentimentModelChange(data)}
                />
                <div className="explainer">
                  This model selection chooses which of our sentiment models will be
                  used for sentiment extraction.
                </div>
              </Form.Field>
              <div className="destructive-area">
                <h4>Warning: Options in this section are destructive and will replace existing themes</h4>
                <Form.Field>
                  <label>Max themes</label>

                  <Select
                    value={maxThemesValue}
                    options={maxThemesOptions}
                    onChange={(_e, data) => this.handleThemeNumChange(data)}
                    upward={true}
                  />
                  <div className="explainer">
                    This scales the number of 'initial' themes discovered
                  </div>
                </Form.Field>
              </div>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.close}>
              Close
            </Button>
            <Button
              color="blue"
              onClick={this.openApplyModal}
            >
              Apply
            </Button>
          </Modal.Actions>
        </Modal>
        {showApplyModal && (
          <ApplyParametersForm
            working={working}
            includesDestructiveParams={includesDestructiveParams}
            error={error}
            surveyName={surveyName}
            onAccept={this.apply}
            onClose={this.closeApplyModal}
          />
        )}
      </div>
    );
  }
}
