import * as React from 'react';
import { Button } from 'semantic-ui-react';
import 'components/Shared/button.scss';

interface ConfigureSurveyActionsProps {
  disableNext?: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  processing: boolean;
  onNext: () => Promise<void> | void;
  onBack: () => void;
  onCancel: () => void;
}

class ConfigureSurveyActions extends React.Component<ConfigureSurveyActionsProps> {
  render(): JSX.Element | null {
    const { processing, disableNext, isFirstStep, isLastStep } = this.props;

    return (
      <div className="actions">
        <div className="left-action">
          <Button className="link"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>
        </div>
        {!isFirstStep &&
          <Button
            onClick={this.props.onBack}
          >
            Back
          </Button>
        }
        <Button
          color="blue"
          disabled={disableNext}
          loading={processing}
          onClick={this.props.onNext}>
          {isLastStep ? 'Done' : 'Continue' }
        </Button>
      </div>
    );
  }
}

export default ConfigureSurveyActions;