import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Vue from 'vue';
import App from './components/App/App';
import CreateAccountContainer from './components/App/CreateAccountContainer';

const root = document.getElementById('root') as HTMLElement;
const createAccountRoot = document.getElementById('create-account-root') as HTMLElement;
if (root) {
  ReactDOM.render(<App />, root);
  Vue.config.devtools = process.env.NODE_ENV === 'development';
} else if (createAccountRoot) {
  ReactDOM.render(<CreateAccountContainer />, createAccountRoot);
}
