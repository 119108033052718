var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:( _obj = {
    'theme-item': true,
    'theme-item--fit-content': _vm.fitContent,
    'theme-item--is-removed': _vm.isRemoved,
    'theme-item--has-bg-colour': _vm.hasBackgroundColour,
    'theme-item--is-new': _vm.isNew
  }, _obj[_vm.toSentimentClassname(_vm.sentiment, (_vm.hasSentiment && _vm.isSentimentEnabled))] = true, _obj ),on:{"click":function () { return _vm.$emit('onClick'); },"mouseover":function () { return _vm.$emit('onMouseOver'); },"mouseleave":function () { return _vm.$emit('onMouseLeave'); }}},[(_vm.hasSentiment && _vm.isSentimentEnabled)?_c('sentiment',{attrs:{"sentiment":_vm.sentiment}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v("\n    "+_vm._s(_vm.text)+"\n    "),(_vm.isNew)?_c('span',{staticClass:"new"},[_vm._v("UPDATED")]):_vm._e()]),_vm._v(" "),_c('el-tooltip',{ref:"removeButtonTooltipRef",staticClass:"item",attrs:{"effect":"dark","content":"Theme editing is not possible while theme application is in progress","placement":"top","enterable":false,"disabled":!_vm.hasTooltip}},[_c('span',{staticClass:"remove-theme-button",on:{"mouseleave":function($event){return _vm.hideTooltip()}}},[(!_vm.isRemoved && _vm.hasRemoveButton)?_c('el-button',{class:{
          'disabled-remove-theme-icon': _vm.areThemesInApplyingState
        },on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.$emit('onRemoveClick'); })($event)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }