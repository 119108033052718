<docs>
## This component represents a dimension across a specific theme/subtheme selector dropdowns
</docs>

<template>
  <div
    v-if="selectedDimension"
    class="dimension-selector"
  >
    <el-dropdown
      trigger="click"
      @command="setDimension"
    >
      <el-button size="small">
        {{ selectedDimension.name }}
        <font-awesome-icon icon="chevron-down" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in filterData"
          :key="item.id"
          :command="item"
        >
          {{ item.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import _, { find, get } from 'lodash';
import { getAnalysisToolsUIStore, getInitConfigStore } from 'stores/RootStore';

export default {
  name: 'FilterThemeDimensionSelector',
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
      selectedDimension: null,
    };
  },
  computed: {
    initConfig() {
      return this.initConfigStore.config;
    },
    themeFilterDimension() {
      return this.analysisToolsUIStore.getThemeFilterDimension(this.initConfig.filters);
    },
    dimensionId() {
      return get(this, 'themeFilterDimension.id');
    },
    filterData() {
      const filters = _.get(this, 'initConfig.filters', []);

      // if no `cuts` array, can't use them as a dimension
      return _.filter(filters, (filter) => {
        return _.isArray(filter.cuts);
      });
    },
  },
  watch: {
    themeFilterDimension: {
      handler: function () {
        this.selectedDimension = this.themeFilterDimension;
      },
    },
  },
  mounted() {
    const { dimensionId, filterData } = this;
    const dimension = find(filterData, { id: dimensionId });

    // init dimension to the one matching dimensionId or first one
    this.selectedDimension = dimension || filterData[0];
  },
  methods: {
    setDimension(dimension) {
      const { dimensionId } = this;
      const { id } = dimension;
      if (id !== dimensionId) {
        this.analysisToolsUIStore.setThemeFilterDimension(dimension);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dimension-selector {
  display: inline;
  margin: 0 5px;
}
</style>
<style lang="scss" scoped></style>
