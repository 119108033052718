export function includesAll (haystack: string[], needles: string[]) {
  if (!haystack || !needles) {
    return false;
  }
  return needles.every(needle => haystack.includes(needle));
}

/* 0 is a valid index and !!index with 0 would be false  */
export function isAnArrayIndex (value: any) {
  return typeof value === 'number' && value >= 0;
}