import { SavedAnswerMeta } from 'api/interfaces';

interface GroupedSavedAnswerMetadata {
  [key: string]: SavedAnswerMeta[];
}

export function getSortedMetadata(metadata: SavedAnswerMeta[]): GroupedSavedAnswerMetadata {
  const currentDate = new Date();
  const sixMonthsAgo = new Date();
  const sevenMonthsAgo = new Date();
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  sevenMonthsAgo.setMonth(currentDate.getMonth() - 7);

  const groupedMetadata: GroupedSavedAnswerMetadata = {};

  metadata.forEach((data) => {
    // If there is no updatedAt, use createdAt.
    // If there is no createdAt, use sevenMonthsAgo to group it under "older than 6 months".
    const date = new Date(data.updatedAt ?? data.createdAt ?? sevenMonthsAgo);
    const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (date < sixMonthsAgo) {
      if (!groupedMetadata['Older than 6 months']) {
        groupedMetadata['Older than 6 months'] = [];
      }
      groupedMetadata['Older than 6 months'].push(data);
    } else {
      if (!groupedMetadata[monthYear]) {
        groupedMetadata[monthYear] = [];
      }
      groupedMetadata[monthYear].push(data);
    }
  });

  return groupedMetadata;
}