import * as React from 'react';
import { debounce } from 'lodash';

const RESIZE_DEBOUNCE = 100;

export function useResizeObserver<T>(): [React.MutableRefObject<T| null>, { width: number, height: number }] {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const ref = React.useRef<T | null>(null);

  React.useEffect(() => {
    const debouncedSet = debounce((entry: ResizeObserverEntry) => {
      setDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height
      });
    }, RESIZE_DEBOUNCE);

    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(debouncedSet);
    });

    if (observeTarget instanceof HTMLElement) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget instanceof HTMLElement) {
        resizeObserver.unobserve(observeTarget);
      }
      debouncedSet.cancel();
    };
  }, []);

  return [ref, dimensions];
}
