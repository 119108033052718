import { Survey } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import './survey-replace-button.scss';

interface SurveyReplaceButtonStoreProps {
  surveyStore?: SurveyStoreInterface;
  setupStore?: SetupStoreInterface;
}

interface SurveyReplaceButtonProps extends SurveyReplaceButtonStoreProps, RouteComponentProps {
  survey: LiteSurvey;
}

@inject('surveyStore', 'setupStore')
@observer
class SurveyReplaceButton extends React.Component<SurveyReplaceButtonProps> {
  onClick = async () => {
    const { surveyStore, setupStore, survey } = this.props;
    const { deleteSurvey, deleteSurveyError, getSurvey } = surveyStore!;
    const { createSurvey, createSurveyError } = setupStore!;
    const { surveyId, title, dataSourceIntegration } = survey;

    await deleteSurvey(surveyId);

    if (deleteSurveyError) {
      return;
    }

    const newSurvey = await createSurvey({ name: title }) as Survey;

    if (createSurveyError) {
      return;
    }

    const replacementSurvey = await getSurvey(newSurvey.id);

    if (!replacementSurvey) {
      return;
    }

    const { orgId, surveyId: newSurveyId } = replacementSurvey;

    const location = {
      pathname: `/c/${orgId}/s/${newSurveyId}/configure-survey`,
      state: { dataSource: dataSourceIntegration?.type }
    };

    this.props.history.replace(location);
  }

  render() {
    const { surveyStore, setupStore } = this.props;
    const { deletingSurvey, deleteSurveyError } = surveyStore!;
    const { creatingSurvey, createSurveyError } = setupStore!;

    return (
      <div className="survey-replace-button__wrapper">
        <Button onClick={this.onClick} color="blue" loading={deletingSurvey || creatingSurvey}>
          Try again
        </Button>
        {deleteSurveyError &&
          <Message
            className="error"
            negative={true}
            header="Something went wrong. Please try again."
          />
        }
        {createSurveyError &&
          <Message
            className="error"
            negative={true}
            header="Something went wrong. Please try again by creating a new dataset."
          />
        }
      </div>
    );
  }
}

export default withRouter(SurveyReplaceButton);
