import * as moment from 'moment';
import * as React from 'react';
import { DateRange, RangeFocus, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import colors from 'vue/styles/element-variables.scss';
import './date-range-picker.scss';

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  showComparisonStyling?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onDateRangeChange: (startDate: Date, endDate: Date, isPreviewing: boolean) => void;
}

interface DateRangePickerState {
  focusedRange: RangeFocus;
}

class DateRangePicker extends React.Component<DateRangePickerProps, DateRangePickerState> {
  state: DateRangePickerState = { focusedRange: [0, 0] };

  onSelectionChange = (ranges: RangeKeyDict) => {
    const { startDate, endDate } = ranges.selection;
    const { onDateRangeChange } = this.props;
    if (startDate && endDate) {
      const isPreviewing = this.state.focusedRange[1] !== 1;
      onDateRangeChange(startDate, moment(endDate).endOf('day').toDate(), isPreviewing);
    }
  };

  render() {
    const { startDate, endDate, minDate, maxDate, showComparisonStyling } = this.props;
    const { focusedRange } = this.state;
    // when setting up the range to preview, if we assign endDate to the start date
    // then it retains its postion to the most recent date after selection
    const ranges = [{ key: 'selection', startDate: endDate, endDate: startDate }];

    return (
      <div className="date-range-picker">
        <div className="date-range-picker-separator" />
        <DateRange
          direction="vertical"
          scroll={{ enabled: true }}
          months={1}
          weekStartsOn={1} // Monday.
          ranges={ranges}
          rangeColors={[showComparisonStyling ? colors.comparison : colors.baseline]}
          focusedRange={focusedRange}
          showDateDisplay={false}
          dragSelectionEnabled={false}
          preventSnapRefocus={true}
          minDate={minDate}
          maxDate={maxDate}
          showMonthAndYearPickers={true}
          onChange={this.onSelectionChange}
          onRangeFocusChange={(range) => this.setState({ focusedRange: range })}
        />
      </div>
    );
  }
}

export default DateRangePicker;
