import { groupBy, sortBy, uniqBy } from 'lodash';
import { PhraseSegment, PhraseSegmentTheme, PlainComment, Thread } from 'types/custom';
import { RefinedCluster } from './refine-clusters';

export type Sentence<C extends PlainComment | Thread> = RefinedCluster<C>['sentences'][0];

export type RefinedSentence<C extends PlainComment | Thread> = {
  snippet: string,
  sentiment: number,
  comments: Array<C>,
  commentCount: number,
  themes: Array<PhraseSegmentTheme>
};

type Options<C extends PlainComment | Thread> = {
  sentences: Array<Sentence<C>>,
  clusterName: string | null,
  selectedThemeTitle: string
};

function getMatchingSegments(c: PlainComment | Thread, snippet: string): Array<PhraseSegment> {

  const segments: Array<PhraseSegment> = 'segments' in c ? c.segments : c.comment.flatMap(com => com.segments);
  const commentText: string = 'segments' in c ? c.comment : c.comment.reduce((result, comment) => `${result}${comment}`, '');

  return segments.filter((seg: PhraseSegment) => {
    const segmentSnippet = commentText.slice(...seg.location);
    return segmentSnippet.toLowerCase() === snippet.toLowerCase();
  });

}

export function refineSentences<C extends PlainComment | Thread>(opts: Options<C>): Array<RefinedSentence<C>> {

  const groupedSentences = groupBy(opts.sentences, s => s[0].toLowerCase());

  const condensedSentences = Object.values(groupedSentences).map((group: Array<Sentence<C>>): RefinedSentence<C> => {

    const snippet: string =  group[0][0];
    const sentiment: number = group.length === 0 ? 0 : group.reduce((sum, s) => sum + s[1], 0) / group.length;
    const commentCount = group.length;

    // The sentences have been grouped using the snippet text.
    // For each group, we need to surface the themes that match the snippet.
    const comments: Array<C> = group.map(s => s[2][0]);

    const matchingSegments: Array<PhraseSegment> = comments.flatMap(c => getMatchingSegments(c, snippet));

    // We extract the non-special themes, also discarding the currently selected theme
    // as we are only interested in what other themes the snippet relates to.
    const themesFromMatchingSegments: Array<PhraseSegmentTheme> = matchingSegments
      .flatMap((seg) => seg.themes)
      .filter(theme =>
        theme.title !== opts.selectedThemeTitle &&
        theme.title !== 'other' &&
        theme.title !== 'nothing'
      );

    const uniqueThemes = uniqBy(themesFromMatchingSegments, theme => theme.title);

    return {
      snippet,
      sentiment,
      comments,
      commentCount,
      themes: uniqueThemes
    };
  });

  return sortBy(condensedSentences, s => {
    if (s.snippet === opts.clusterName) {
      return -Infinity;
    }
    return 0 - s.commentCount;
  });

}
