<template>
  <widget-wrapper
    :aria-label="subtitle"
    :title="title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
      <div class="widget-display-categories">
        <div class="category">
          Impact
        </div>
        <div class="category">
          Volume
        </div>
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div
        v-for="(theme, index) in themes"
        :key="theme + index"
        class="theme-row"
      >
        <div
          v-if="!isWeighted"
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.code, title: theme.name})"
        >
          <span>{{ theme.name }}</span>
        </div>
        <theme-name-with-sources
          v-else
          :source="source"
          tool="explore"
          subtool="impact"
          :theme="theme.name"
          :theme-components="theme.components"
          :theme-name="theme.name"
          :theme-sources="themeSources"
          :compare-and-filters="compareAndFilters"
        />
        <bar
          :value="theme.impact"
          :max-value="maxValue"
          :range="range"
          :display-percentage="theme.volume"
          :last-item="index + 1 == themes.length"
        />
      </div>
      <div
        v-if="empty"
        class="empty-theme-list"
      >
        {{ emptyMessage }}
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :sentiment="sort === 'asc' ? 'negative' : 'positive'"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import { Bar } from 'components/Dashboard/Widgets/Bar';
import { ThemeNameWithSources } from 'components/Dashboard/Widgets/Components/ThemeNameWithSources';
import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './SummaryComponent';

import { canClickTheme } from 'components/Dashboard/Utils/dashboard-helper';
import {
  canNavigate,
  goToAnalysis,
  getDestination
} from 'components/Dashboard/Utils/navigation-helpers';

import WidgetWrapper from '../WidgetWrapper';

export default {
  name: 'IMPACTCOMPONENT',
  components: {
    Bar: ReactInVue(Bar),
    ThemeNameWithSources: ReactInVue(ThemeNameWithSources),
    WidgetWrapper,
    CommentComponent: ReactInVue(ReactCommentComponent),
    SummaryComponent
  },
  props: {
    orgId: { type: String },
    isWeighted: { type: Boolean, default: false },
    filters: {type: Object},
    count: { type: Number },
    error: { type: String },
    warning: { type: String },
    loading: { default: false, type: Boolean },
    maxValue: { type: Number },
    range: { type: Array },
    scoreName: { type: String },
    showComments: { default: false, type: Boolean },
    showSummary: { default: false, type: Boolean },
    sort: { type: String },
    source: { type: String },
    title: { type: String },
    themes: { type: Array },
    themeSources: { type: Array },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      selectedTheme: null,
      isCommentsEnabled: false,
      isSummaryEnabled: false,
    }
  },
  computed: {
    canClickTheme() {
      return canClickTheme({
        source: this.source,
        isWeighted: this.isWeighted,
        isCommentsEnabled: this.isCommentsEnabled,
        isSummaryEnabled: this.isSummaryEnabled,
      });
    },
    canNavigate() {
      return canNavigate({ source: this.source, isWeighted: this.isWeighted });
    },
    empty() {
      return !this.themes || !this.themes.length;
    },
    subtitle() {
      const { scoreName, sort } = this;
      const tokens = [];

      if (sort === 'asc') {
        tokens.push('Key negative drivers of');
      } else {
        tokens.push('Key positive drivers of');
      }

      if (scoreName) {
        tokens.push(scoreName);
      } else {
        tokens.push('score');
      }

      return tokens.join(' ');
    },
    emptyMessage() {
      if (this.sort === 'asc') {
        return 'No negative themes found';
      } else {
        return 'No positive themes found';
      }
    },
    compareAndFilters() {
      const { compare, filters } = this.$route.query;
      return { compare, filters };
    }
  },
  watch: {
    showComments: {
      handler (showComments) {
        this.isCommentsEnabled = showComments;
      },
      immediate: true
    },
    showSummary: {
      handler (showSummary) {
        this.isSummaryEnabled = showSummary;
      },
      immediate: true
    },
  },
  methods: {
    doNavigate(theme, subtheme, options, isFinalNavigate) {
      const {
        source,
        isCommentsEnabled,
        isSummaryEnabled,
        isWeighted,
        orgId,
      } = this;

      // if the navigation is to a specific theme, and there is a comments component, don't navigate yet
      if ((isCommentsEnabled || isSummaryEnabled) && !isFinalNavigate && theme) {
        this.selectedTheme = { theme, subtheme, options };
        analytics.track('Report: open comments', { category: 'Report' });
        return;
      }
      if (!canNavigate({ source, isWeighted })) {
        return;
      }

      const destination = getDestination({
        options,
        orgId,
        source,
        subtheme,
        theme,
        subtool: 'impact',
        tool: 'explore'
      });
      goToAnalysis(destination);
    },
  },
};
</script>

<style lang="scss" scoped></style>

