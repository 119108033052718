import { PlainComment, Thread } from "types/custom";

export const isPlainComment = (comment: PlainComment | Thread): comment is PlainComment => {
  return 'segments' in comment;
}

export const isThread = (comment: PlainComment | Thread): comment is Thread => {
  return Array.isArray(comment.comment);
}

export function getCommentText(comment: PlainComment | Thread): string {
  if (isPlainComment(comment)) {
    return comment.comment;
  }
  return comment.comment.map(c => c.text).join('\n');
}

export function getAllCommentsText(comments: PlainComment[] | Thread[]): string[] {
  return comments.reduce((acc, comment) => {
    const text = getCommentText(comment);
    return text.trim() === '' ? acc : [...acc, text.trim()];
  }, []);
}
