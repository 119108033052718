import { getQuickEditUIStore, } from 'stores/RootStore';
import { autorun } from 'mobx';

export default function () {
  return {
    data() {
      return {
        quickEditUIStore: getQuickEditUIStore(),
        editedCommentIds: [],
      };
    },
    mounted() {

      autorun(() => {
        this.editedCommentIds = Object.keys(this.quickEditUIStore.editedComments);
      });

    },
  };
}

