import * as React from 'react';
import { AnswerMetadata, AnswerResponse, AnswersDataSet, TranslatedFilter, isComparisonResponse, isNonComparisonResponse } from 'api/interfaces';
import fuzzNum from 'vue/libs/fuzz-num';
import { getAnalysisToolsLinkForMetadataSource } from '../utils/getAnalysisToolsLinkForMetadataSource';
import './metadata.scss';
import { useSingleFilters } from '../hooks/useSingleFilters';
import { Button } from 'components/Shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { ContextMetadataTooltip } from './ContextMetadataTooltip';

const toPercent = (count: number, total: number): string => {
  total = Math.max(1, total);
  return (count / total * 100).toFixed(0);
};

const getSources = (answer: AnswerResponse): { [sourceName: string]: AnswerMetadata } | undefined => {
  const sources =  isNonComparisonResponse(answer)
    ? answer.metadata.sources
    : answer.comparisons.reduce((acc, comparison) => {
      return {
        ...acc,
        ...comparison.metadata.sources
      };
    }, {});

  return sources;
};

const createAnalysisToolsLink = (answer: AnswerResponse, metadata: AnswerMetadata, orgId: string) => {
  const { filters } = answer;
  const link = getAnalysisToolsLinkForMetadataSource(metadata, filters, orgId);

  return link ? link : undefined;
};

interface MetaDataItemProps {
  answer: AnswerResponse;
  dataSet: string;
  metaData: AnswerMetadata;
  orgId: string;
}

type RouteProps = RouteComponentProps<{ orgId: string, savedAnswerId?: string }>;

const MetaDataItem = withRouter(({ answer, dataSet, metaData, orgId }: MetaDataItemProps & RouteProps) => {
  const feedBackUrl = createAnalysisToolsLink(answer, metaData, orgId)
  const history = useHistory();

  const goToAllFeedback = () => {
    if (feedBackUrl) {
      history.push(feedBackUrl);
    }
  }

  return (
    <li className="answer-metadata__list-item" key={dataSet}>
      <div>
        <h4 className="answer-metadata__title">
          <span className="answer-metadata__label">DATASET</span> {dataSet}
        </h4>
        <div className="answer-metadata__count">
          <span>{toPercent(metaData.count, metaData.totalCount)}%, </span>
          <span>{fuzzNum(metaData.count)} of {fuzzNum(metaData.totalCount)} comments</span>
        </div>
      </div>
      <Button
        className="button-to-analysis"
        as={Link}
        size="small"
        variant="secondary"
        target="_blank"
        rel="noopener noreferrer"
        onClick={goToAllFeedback}
      >
        See all feedback
        <FontAwesomeIcon
          icon="table"
          className="icon"
          fixedWidth={true}
        />
      </Button>
    </li>
  )
})

interface TranslatedFiltersProps {
  translatedFilters: TranslatedFilter[];
}

export const TranslatedFilters = ({ translatedFilters }: TranslatedFiltersProps) => {
  return (
    <ul className="answer-metadata__filters">
      {translatedFilters.map(({ filterName, filterValues }, index) => {
        return (
          <li className="answer-metadata__filter-item" key={`${filterName}-${index}`}>
            <span className="answer-metadata__filter-title">{filterName}</span> <span className="answer-metadata__filter-value">{filterValues}</span>
          </li>
        );
      })}
    </ul>
  );

}

interface MetaDataProps {
  answer: AnswerResponse;
  dataSets: AnswersDataSet[];
  orgId: string;
  sources?: { [sourceName: string]: AnswerMetadata };
}

const MetaDataContent = ({ answer, dataSets, orgId, sources }: MetaDataProps) => {
  const translatedFilters = useSingleFilters(answer.filters, dataSets);

  const isMultipleSources = sources && Object.keys(sources).length > 0;
  const isComparisonAnswer = isComparisonResponse(answer);
  const shouldShowTotalComments = !isComparisonAnswer;
  // Total comments for comparison answers are shown in Context.tsx using ContextComparison.tsx
  const shouldShowFilters = translatedFilters.length > 0;

  return (
    <div className="answer-metadata" data-testid={`${isMultipleSources ? 'multiple' : 'single'}-source-meta-data`}>
      <ul className="answer-metadata__list">
        {shouldShowTotalComments && (
          <li className="answer-metadata__total-comments-count answer-metadata__list-item">
            Total comments {fuzzNum(answer.metadata.count)} of {fuzzNum(answer.metadata.totalCount)}
            <ContextMetadataTooltip
              totalCommentsCount={answer.metadata.totalCount}
              dataSets={dataSets}
              filters={translatedFilters}
            />
          </li>
        )}
        {isMultipleSources
          ? Object.entries(sources).map(([key, metaData]) => (
              <MetaDataItem
                key={key}
                answer={answer}
                dataSet={key}
                metaData={metaData}
                orgId={orgId}
              />
            ))
          : <MetaDataItem
              answer={answer}
              dataSet={dataSets[0].title}
              metaData={
                isComparisonAnswer
                  ? answer.comparisons[0].metadata
                  : answer.metadata
              }
              orgId={orgId}
            />
        }
        {shouldShowFilters && (
          <footer>
            <div className="answer-metadata__label">FILTERS</div>
            <TranslatedFilters translatedFilters={translatedFilters} />
          </footer>
        )}
      </ul>
    </div>
  );
};

const Metadata = withRouter(({ answer, dataSets, match }: MetaDataProps & RouteProps) => {
  const orgId = match.params.orgId;
  const sources = getSources(answer);

  return <MetaDataContent answer={answer} dataSets={dataSets} orgId={orgId} sources={sources} />;
})

export { Metadata };
