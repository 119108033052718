import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { ActiveDashboardUIStoreInterface } from 'stores/ui/ActiveDashboardUIStore';
import Vue from 'vue';
import Router from 'vue-router';
import CreateDashboardVue from 'vue/dashboards/DashboardEditable/CreateDashboard.vue';
import { VueInReact } from 'vuera';

Vue.use(Element, { locale });
Vue.use(Router);

const router = new Router();
const CreateDashboardVueComponent = VueInReact({
  router,
  ...CreateDashboardVue
});

interface DashboardParams {
  orgId: string;
}
interface DashboardStoreProps {
  activeDashboardUIStore?: ActiveDashboardUIStoreInterface;
}

interface DashboardProps extends RouteComponentProps<DashboardParams>, DashboardStoreProps {
}

interface DashboardState { }

@inject('activeDashboardUIStore')
@observer
class CreateDashboard extends React.Component<DashboardProps, DashboardState> {

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  componentWillUnmount() {
    const { activeDashboardUIStore: store } = this.props;
    store!.reset();
  }
  render(): JSX.Element | null {
    return (
      <Segment className="fullscreen dashboard-segment">
        <CreateDashboardVueComponent orgId={this.orgId} />
      </Segment>
    );
  }
}

export default withRouter(CreateDashboard);
