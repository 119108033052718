import { SurveyDataStatus, SurveyStatus } from 'api/enums';
import { Survey, View as ViewJson, Visualization } from 'api/interfaces';
import {
  forEach,
  groupBy,
  kebabCase,
  map,
  padStart,
  reduce,
  sortBy
} from 'lodash';
import {
  LiteSurvey
} from 'stores/AnalysisToolsStore';
import { Dataset, DatasetView, DatasetVis, DatasetsNestedMenu } from 'stores/data-structure/datasets';

export function convertSurveyJsontoLiteSurvey(orgId: string, survey: Survey): LiteSurvey {
  const {
    id,
    isPreview,
    isActive,
    configuration,
    name: title,
    hasDraftThemes: hasDraft,
    manualUploadAllowed,
    lastUpdated,
    dataStatus,
    dataSourceIntegration,
    dataAlertingWorkflow,
    surveyStatus,
    currentUserCan: scopes,
    dataVolume } = survey;
  const url = `/c/${ orgId }/s/${ id }/themes`;

  return {
    isActive,
    isPreview,
    configuration,
    hasDraft,
    orgId,
    surveyId: String(id),
    title,
    url,
    manualUploadAllowed,
    lastUpdated,
    dataStatus,
    surveyStatus,
    scopes,
    dataSourceIntegration,
    dataAlertingWorkflow,
    dataVolume
  };
}

export function parseAsSurveys(orgId: string, surveys: Survey[]): LiteSurvey[] {
  return map(surveys, survey => {
    return convertSurveyJsontoLiteSurvey(orgId, survey);
  });
}

export function flatten(menu: DatasetsNestedMenu): DatasetVis[] {
  const result = [] as DatasetVis[];

  forEach(menu.children, survey => {
    forEach(survey.children, child => {
      if ((child as DatasetVis).url) {
        result.push(child as DatasetVis);
      } else {
        result.push(...(child as DatasetView).children);
      }
    });
  });
  return result;
}

export function parseAsNested(
  orgId: string,
  list: Survey[],
  checkPermissionsFunction: (surveyId: string, actionRequired: string, scopes?: string[]) => boolean,
  isThematicAdmin: boolean,
  requiredScope: string
): DatasetsNestedMenu {

  const children = reduce(
    list,
    (result: Dataset[], survey: Survey) => {
      const items = [] as (DatasetView | DatasetVis)[];

      const liteSurvey = convertSurveyJsontoLiteSurvey(orgId, survey);

      // if a survey has no data, it can't be in the list
      if (liteSurvey.dataStatus === SurveyDataStatus.NORESULTS ||
        (liteSurvey.surveyStatus === SurveyStatus.NEEDS_REVIEW && !isThematicAdmin)) {
        return result;
      }

      const visList = extractVisualizations(orgId, survey, survey.visualizations, survey.name);
      // include the survey only if it makes sense
      if (visList.length && checkPermissionsFunction(liteSurvey.surveyId, requiredScope)) {
        items.push(...visList);
      }
      forEach(survey.views, view => {
        const viewItems = extractView(orgId, survey, view, checkPermissionsFunction, requiredScope);
        items.push(...viewItems);
      });

      // if there are no explicitly added visualizations, we can add the default
      if (items.length === 0 && checkPermissionsFunction(liteSurvey.surveyId, requiredScope)) {
        items.push({
          title: survey.name,
          fullTitle: survey.name,
          isPreview: survey.isPreview,
          isReviewing: survey.surveyStatus === SurveyStatus.NEEDS_REVIEW,
          order: `${ survey.order }-0-_`,
          surveyId: survey.id,
          visualizationId: '_',
          url: getUrl(survey.dataStatus, orgId, survey.id, '_')
        });
      }

      if (items.length) {
        const { name: title, isPreview, isActive } = survey;
        const surveyOrder = padStart(String(survey.order), 6, '0');
        const order = `${ surveyOrder }-${ kebabCase(title) }`;
        result.push({ children: sortBy(items, 'order'), order, title, isPreview, isActive });
      }
      return result;
    },
    [] as Dataset[]
  );

  return {
    children: sortBy(children, 'order')
  };
}

function getUrl(
  dataStatus: SurveyDataStatus,
  orgId: string,
  surveyId: string,
  visId: string,
  viewId?: string | number
): string {
  if (dataStatus !== SurveyDataStatus.READY && dataStatus !== SurveyDataStatus.REVIEWING) {
    return `/c/${ orgId }`;
  }
  if (viewId) {
    return `/c/${ orgId }/s/${ surveyId }/view/${ viewId }/v/${ visId }`;
  } else {
    return `/c/${ orgId }/s/${ surveyId }/v/${ visId }`;
  }
}

function extractView(
  orgId: string,
  survey: Survey,
  view: ViewJson,
  checkPermissionsFunction: (surveyId: string, actionRequired: string, scopes?: string[]) => boolean,
  requiredScope: string,
  name?: string) {

  const viewOrder = padStart(String(view.order), 6, '0');
  const order = `${ viewOrder }-999999-${ kebabCase(view.name) }`;
  let viewVisList = extractVisualizations(orgId, survey, view.visualizations, view.name, view);
  const items = [] as (DatasetView | DatasetVis)[];
  if (checkPermissionsFunction(survey.id, requiredScope)) {

    let viewName = view.name;
    if (name) {
      viewName = `${ name }: ${ view.name }`;
    }

    if (!viewVisList.length) {
      viewVisList = [{
        fullTitle: `${ survey.name }: ${ view.name }`,
        title: viewName,
        isPreview: false,
        isReviewing: survey.surveyStatus === SurveyStatus.NEEDS_REVIEW,
        order,
        surveyId: survey.id,
        viewId: view.id,
        visualizationId: '_',
        url: getUrl(survey.dataStatus, orgId, survey.id, '_', view.id)
      }];
    }

    forEach(view.children, childView => {
      const viewItems = extractView(orgId, survey, childView, checkPermissionsFunction, requiredScope, viewName);
      const children = reduce(viewItems, (els: DatasetVis[], view2: DatasetVis | DatasetView) => {
        const isAnalysis = !!(view2 as DatasetVis).url;

        if (isAnalysis) {
          els.push(view2 as DatasetVis);
        } else {
          els.push(...(view2 as DatasetView).children);
        }

        return els;
      },
        [] as DatasetVis[]
      );

      viewVisList.push(...children);
    });

    items.push({
      children: sortBy(
        viewVisList,
        order
      ),
      order,
      title: view.name
    });
  }
  return items;
}

function extractVisualizations(
  orgId: string,
  survey: Survey,
  visualizations: Visualization[],
  name: string,
  view?: ViewJson
): DatasetVis[] {
  const result = [] as DatasetVis[];
  const visByCategory = groupBy(visualizations, 'category');
  forEach(visByCategory, visList => {
    const [vis] = visList;

    if (!vis) {
      return;
    }
    const { id: surveyId } = survey;
    const { hasDraftThemes: hasDraft, dataStatus, isPreview: surveyPreview, surveyStatus } = survey;
    const isReviewing = surveyStatus === SurveyStatus.NEEDS_REVIEW;

    const { category, isPreview: visPreview } = vis;
    const viewOrder = view ? padStart(String(view.order), 6, '0') : '000000';
    const visOrder = padStart(String(vis.order), 6, '0');
    const viewName = view ? `${ kebabCase(view.name) }-${ view.id }-` : '';
    const url = getUrl(dataStatus, orgId, surveyId, vis.id, view?.id);
    let title;
    if (category) {
      title = `${ name }: ${ category }`;
    } else {
      title = name;
    }
    const order = `${ viewOrder }-${ visOrder }-${ viewName }${ kebabCase(title) }`;
    const item: DatasetVis = {
      hasDraft,
      isPreview: surveyPreview || visPreview,
      isReviewing: isReviewing,
      order,
      surveyId,
      visualizationId: vis.id,
      viewId: view ? view.id : undefined,
      title,
      fullTitle: title,
      url
    };

    result.push(item);
  });

  return result;
}
