import * as React from 'react';
import { Column, Columns, DefaultConfig } from './types';
import { Table } from 'semantic-ui-react';
import toFixed from 'vue/libs/to-fixed';

interface Props {
  columns: Column[];
  tableConfig: DefaultConfig;
}

const ExportAssistantTable = ({ columns, tableConfig }: Props) => {
  const { columnOneTitle, columnTwoTitle } = tableConfig;
  const showCount = columns.includes(Columns.count);
  const showImpact = columns.includes(Columns.impact);
  const showScore = columns.includes(Columns.score);
  const showSentimentCounts = columns.includes(Columns.sentimentCounts);
  const showVolume = columns.includes(Columns.volume);

  if (!tableConfig.tableData) {
    return null;
  }

  return (
    <div className="export-assistant__table">
      <Table size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{columnOneTitle}</Table.HeaderCell>
            {columnTwoTitle && <Table.HeaderCell>{columnTwoTitle}</Table.HeaderCell>}
            {showCount && <Table.HeaderCell>Count</Table.HeaderCell>}
            {showVolume && <Table.HeaderCell>Volume</Table.HeaderCell>}
            {showImpact && <Table.HeaderCell>Impact</Table.HeaderCell>}
            {showScore && <Table.HeaderCell>Score</Table.HeaderCell>}
            {showSentimentCounts && (
              <>
                <Table.HeaderCell>Positive</Table.HeaderCell>
                <Table.HeaderCell>Neutral</Table.HeaderCell>
                <Table.HeaderCell>Negative</Table.HeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableConfig.tableData.map((dataItem) => (
            <Table.Row key={`${dataItem.rowNameOne}-${dataItem?.rowNameTwo}`}>
              <Table.Cell>{dataItem.rowNameOne}</Table.Cell>
              {columnTwoTitle && <Table.Cell>{dataItem.rowNameTwo}</Table.Cell>}
              {showCount && <Table.Cell>{toFixed(dataItem?.count, 1)}</Table.Cell>}
              {showVolume && <Table.Cell>{toFixed(dataItem?.volume, 1)}%</Table.Cell>}
              {showImpact && <Table.Cell>{toFixed(dataItem?.impact, 1)}</Table.Cell>}
              {showScore && <Table.Cell>{toFixed(dataItem?.score, 1)}</Table.Cell>}
              {showSentimentCounts && (
                <>
                  <Table.Cell>{dataItem.sentimentCounts?.positive}</Table.Cell>
                  <Table.Cell>{dataItem.sentimentCounts?.neutral}</Table.Cell>
                  <Table.Cell>{dataItem.sentimentCounts?.negative}</Table.Cell>
                </>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export { ExportAssistantTable };
