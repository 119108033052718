import { RefinedSentence, Sentence, refineSentences } from 'lib/refine-sentences';
import * as React from 'react';
import { PhraseSegmentTheme, PlainComment, Thread } from 'types/custom';
import "./cluster.scss";
import { Sentiment } from './Sentiment';
import "./cluster.scss";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { VerticalComment } from './VerticalComment';
import { VerticalCommentThread } from './VerticalCommentThread';
import { Tooltip } from 'components/Shared/Tooltip';
import { isPlainComment, isThread } from 'lib/comment-helpers';

type Props<C extends PlainComment | Thread> = {
  name?: string,
  hasSentiment: boolean,
  isExpanded: boolean,
  onSentenceClick: (sentence: RefinedSentence<C>, subIndex: number) => void;
  selectedIndex?: number,
  selectedThemeCodes: ThemeCodes,
  selectedThemeTitle: string,
  sentences: Sentence<C>[],
  sentiment: number,
  toggleExpansion: () => void,
};

const Cluster = <C extends PlainComment | Thread>(props: Props<C>) => {

  const {
    name,
    isExpanded,
    onSentenceClick,
    selectedIndex,
    selectedThemeCodes,
    selectedThemeTitle,
    sentences,
    sentiment,
    toggleExpansion
  } = props;

  const refinedSentences = refineSentences({
    sentences: sentences,
    clusterName: name || null,
    selectedThemeTitle: selectedThemeTitle
  });

  const getThemeTitle = (themes: PhraseSegmentTheme[]) => {
    return themes.map(t => t.title).join('\n');
  };

  return (
    <div className='react-cluster' data-testid="cluster">
      <button className={classNames({
          'react-cluster__expand-control': true,
          'react-cluster__expand-control--is-expanded': isExpanded
        })}
        onClick={toggleExpansion}
      >
        <FontAwesomeIcon icon="chevron-right"/>
        {sentences.length}
        {name !== 'OTHER SENTENCES' && !isExpanded && (
          <Sentiment sentiment={sentiment} />
        )}
      </button>
      {isExpanded ? (
        <ul className="react-cluster__list">
          <li className="react-cluster__sentence react-cluster__sentence--title">
            {name}
          </li>
          {refinedSentences.map((sentence, index) => (
            <li
              key={`${sentence.snippet}--${index}`}
              className={classNames({
                'react-cluster__sentence': true,
                'react-cluster__sentence--is-selected': index === selectedIndex
              })}
              data-testid="sentence"
            >
              <header
                onClick={() => onSentenceClick(sentence, index)}
              >
                <Sentiment sentiment={sentiment} />
                <span className="react-cluster__sentence-snippet">{ sentence.snippet }</span>
                {sentence.themes.length > 0 && (
                  <Tooltip
                    content={getThemeTitle(sentence.themes)}
                    enterable={false}
                    position="top center"
                    inverted={true}
                    trigger={
                      <span className="react-cluster__sentence-theme-count">
                          <FontAwesomeIcon icon="tag" />
                          <span className="react-cluster__theme-text">{ sentence.themes.length }</span>
                      </span>
                    }
                  />
                )}
                <span className="react-cluster__sentence-comment-count">
                  <FontAwesomeIcon icon="comment" />
                  <span className="react-cluster__count-text">{ sentence.commentCount }</span>
                </span>
              </header>
              {index === selectedIndex && (
                <ul className="react-cluster__sentence-comments">
                  {sentence.comments.map(comment => (
                    <li key={comment.id}>
                      {isPlainComment(comment) && (
                        <VerticalComment
                          alwaysExpanded={false}
                          comment={comment}
                          highlightType="all"
                          hoverOnThemes={true}
                          isDisabled={true}
                          isNarrow={true}
                          sentenceSnippet={sentence.snippet}
                          selectedThemeCodes={selectedThemeCodes}
                          showTagComment={false}
                        />
                      )}
                      {isThread(comment) && (
                        <VerticalCommentThread
                          alwaysExpanded={false}
                          highlightType='all'
                          hoverOnThemes={true}
                          isDisabled={true}
                          isNarrow={true}
                          selectedThemeCodes={selectedThemeCodes}
                          thread={comment}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <ul className="react-cluster__toggle">
          <li
            className={classNames({
              'other-sentences': refinedSentences[0] && refinedSentences[0].snippet === 'OTHER SENTENCES',
              'react-cluster__sentence': true,
            })}
            onClick={() => toggleExpansion()}
            data-testid="sentence"
          >
            <header>
              <span className="react-cluster__sentence-snippet">{ name }</span>
            </header>
          </li>
        </ul>
      )}
    </div>
  );
};

export { Cluster };
