import * as React from 'react';
import { AnalysisFilter, AnswerMetadata, AnswerResponse, AnswersDataSet, AnswersFilters, InputData, TranslatedFilter, isComparisonResponse, isNonComparisonResponse } from 'api/interfaces';
import { translateFilters } from '../utils/getTranslatedFilters';
import { useAnswersContext } from '../utils/context';

function removeCommonFiltersFromComparison(obj1: AnswersFilters, obj2: AnswersFilters) {
  return Object.keys(obj1).reduce((newObj, prop) => {
    const hasDifferentProp = !(prop in obj2) || JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop]);

    if (hasDifferentProp) {
      newObj[prop] = obj1[prop];
    }
    return newObj;
  }, {});
}

export interface TranslatedComparison {
  filters: TranslatedFilter[];
  inputData: InputData[];
  metaData: AnswerMetadata;
  name: string;
}

export const getTranslatedComparison = (answer: AnswerResponse, dataSets: AnswersDataSet[], analysisFilters: Map<string, AnalysisFilter[]>): TranslatedComparison[] => {
  if (isNonComparisonResponse(answer)) {
    return [];
  }

  const deduplicatedComparisonFilters = answer.comparisons.map((comparison) => {
    const deduplicatedFilters = removeCommonFiltersFromComparison(comparison.filters, answer.filters);

    return {
      ...comparison,
      filters: deduplicatedFilters,
    };
  });

  const deduplicatedComparison = deduplicatedComparisonFilters.map(({
    filters,
    metadata,
    name,
    inputData
  }) => {
      const comparisonFilters = translateFilters(filters, dataSets, analysisFilters);

      return {
        filters: comparisonFilters,
        inputData,
        metaData: metadata,
        name,
      };
    });

  return deduplicatedComparison;
};

export const useComparisonFilters = (answer: AnswerResponse, dataSets: AnswersDataSet[]) => {
  const { analysisFilters } = useAnswersContext();
  const [translatedComparisons, setTranslatedComparisons] = React.useState<TranslatedComparison[]>([]);

  React.useEffect(() => {
    if (isComparisonResponse(answer) && answer.comparisons.length > 0) {
      const result = getTranslatedComparison(answer, dataSets, analysisFilters);
      setTranslatedComparisons(result);
    }
  }, [answer.filters, dataSets, analysisFilters, answer]);

  return translatedComparisons;
};
