import { SummarySegment } from "types/custom"

export type CombinedReference = {
  index: number,
  locations: [number, number][]
}

export function combineReferences(references: SummarySegment['conversationReferences'] ): CombinedReference[] {
  const map = references.reduce((result, reference) => {
    const { index, location } = reference;

    if (result.has(index)) {
      result.get(index).locations.push(location);
    } else {
      result.set(index, { index, locations: [location] });
    }

    return result;
  }, new Map());

  return [...map.values()];
}
