import { PermissionJson, PolicyJson, RoleDetailsJson, SurveyPermissionJson } from 'api/interfaces';
import { includesAll } from 'lib/array-helper';
import { isEmpty, remove, uniq } from 'lodash';
import { AdminScopes, ReportScopes, RoleDetails, SCOPES, SurveyScopes, ViewScopes } from 'stores/RoleStore';

/*
  Checks if premissions are on per report basis
  Checks if there are scopes present for any report
  if so they are per report basis
*/
export function isPerEachReport(permissions: PermissionJson[]) {
  if (isEmpty(permissions)) {
    return false;
  }

  // It is only per item if they have access to individual items
  // Otherwise the default is overall permissions
  return !!permissions.find(permission => permission.id !== '*' && !isEmpty(permission.scopes));
}

/*
  Checks if premissions are on per survey basis
  Checks if there are scopes present for any survey
  or view, if so they are per survey basis
*/
export function isPerEachSurvey(permissions: SurveyPermissionJson[]) {
  if (isEmpty(permissions)) {
    return false;
  }

  // It is only per item if they have access to individual items
  // Otherwise the default is overall permissions
  return !!permissions.find(permission => {
    const perSurvey = permission.id !== '*' && !isEmpty(permission.scopes);
    const views = permission.views;
    const perView = views && views.find(view => !isEmpty(view.scopes));
    return perSurvey || perView;
  });
}

/*
  Checks if there are any scopes selected for role
*/
export function hasScopesSelected(roleDetails: RoleDetails) {
  if (!roleDetails) {
    return false;
  }
  const { admin, reports, surveys } = roleDetails;
  const hasAdminScopes = !isEmpty(admin);
  if (hasAdminScopes) {
    return true;
  }
  const hasReportScopes = !!(reports || []).find(report => !isEmpty(report.scopes));
  if (hasReportScopes) {
    return true;
  }
  const hasSurveyScopes = !!(surveys || []).find(survey => {
    let hasViewScopes = false;
    const hasScopes = !isEmpty(survey.scopes);
    if (survey.views) {
      hasViewScopes = !!survey.views.find(view => !isEmpty(view.scopes));
    }
    return hasScopes || hasViewScopes;
  });
  if (hasSurveyScopes) {
    return true;
  }
  return false;
}

/*
  Maps scopes from the API to scopes that will be displayed to the user
  Checks if the scopes that we display to the user are applicable for them
  For ex. survey with 'download:result' & 'read:result' scopes will display
  `Download` scope [SurveyScopes.download] as checked
*/
export function mapScopes(scopesList: { [key: string]: string[] }, scopes: string[] = []) {
  if (!scopes || !scopes.length) {
    return [];
  }
  let mappedScopes = [] as string[];
  Object.keys(scopesList).forEach(key => {
    if (includesAll(scopes, scopesList[key])) {
      mappedScopes.push(key);
    }
  });
  return mappedScopes;
}

/*
  Adds/removes the given scope from a list of scopes
*/
export function getUpdatedScopes(scopes: string[], scope: string, hasAccess: boolean) {
  if (!scope) {
    return scopes;
  }
  const index = scopes.findIndex(s => s === scope);
  if (hasAccess) {
    if (index < 0) {
      scopes.push(scope);
    }
  } else {
    remove(scopes, (s) => s === scope);
  }

  return scopes;
}

/*
  Maps permissions from the API to how we display to the users
  Also deduces which options should be checked
*/
export function mapSelectedPermissions(roleDetails: RoleDetailsJson) {
  let { name, description, policy } = roleDetails;
  let { admin, reports, surveys } = policy;

  admin = mapScopes(SCOPES.admin, admin);
  reports = (reports || [])
    .map(report => {
      report = { ...report, scopes: mapScopes(SCOPES.reports, report.scopes) };
      return report;
    });
  surveys = (surveys || [])
    .map(survey => {
      survey = { ...survey, scopes: mapScopes(SCOPES.surveys, survey.scopes) };
      if (survey.views) {
        survey.views = survey.views.map(view => {
          view = { ...view, scopes: mapScopes(SCOPES.views, view.scopes) };
          return view;
        });
      }
      return survey;
    });

  return {
    name,
    description,
    isPerReport: isPerEachReport(reports),
    isPerSurvey: isPerEachSurvey(surveys),
    admin,
    reports,
    surveys
  } as RoleDetails;
}

/*
  Adds list of scopes applicable to reports / surveys / views
  that are applicable to an organisation
*/
export function mapEmptyPermissions(permissions: PolicyJson) {
  const { reports, surveys } = permissions;

  const adminScopes = Object.keys(AdminScopes);
  const reportScopes = Object.keys(ReportScopes);
  const surveyScopes = Object.keys(SurveyScopes);
  const viewScopes = Object.keys(ViewScopes);

  let reportPermissions = reports.map(report => ({ ...report, scopes: reportScopes })) as PermissionJson[];

  let surveyPermissions = surveys.map(survey => {
    let mappedSurvey = { ...survey, scopes: surveyScopes };
    if (survey.views) {
      mappedSurvey.views = survey.views.map(view => ({ ...view, scopes: viewScopes }));
    }
    return mappedSurvey;
  }) as SurveyPermissionJson[];

  return {
    admin: adminScopes,
    reports: reportPermissions,
    surveys: surveyPermissions
  };
}

/*
  Maps permissions how we display to the users to how API accepts
*/
export function mapPermissionsToPolicy(roleDetails: RoleDetails) {
  const { admin, reports, surveys, isPerReport, isPerSurvey } = roleDetails;

  let adminScopes = [] as string[];
  let reportPermissions = [] as PermissionJson[];
  let surveyPermissions = [] as SurveyPermissionJson[];

  admin.forEach(a => {
    const scopes = SCOPES.admin[a];
    if (scopes) {
      adminScopes = [...adminScopes, ...scopes];
    }
  });

  if (isPerReport) {
    remove(reports, { id: '*' });
    reports.forEach(report => {
      const permission = mapPermission(report, SCOPES.reports);
      if (permission) {
        reportPermissions = [...reportPermissions, permission];
      }
    });
  } else {
    const permission = getOverallPermissions(reports, SCOPES.reports);
    if (permission) {
      reportPermissions = permission;
    }
  }

  if (isPerSurvey) {
    remove(surveys, { id: '*' });
    surveys.forEach(survey => {
      let surveyPermission = mapPermission(survey, SCOPES.surveys);

      let viewPermissions = [] as PermissionJson[];
      (survey.views || []).forEach(view => {
        const viewPermission = mapPermission(view, SCOPES.views);
        if (viewPermission) {
          viewPermissions = [...viewPermissions, viewPermission];
        }
      });

      if (!isEmpty(viewPermissions)) {
        if (surveyPermission) {
          surveyPermission.views = viewPermissions;
        } else {
          surveyPermission = { id: survey.id, scopes: [], views: viewPermissions };
        }
      }
      if (surveyPermission) {
        surveyPermissions = [...surveyPermissions, surveyPermission];
      }
    });
  } else {
    const permission = getOverallPermissions(surveys, SCOPES.surveys);
    if (permission) {
      surveyPermissions = permission;
    }
  }

  return {
    admin: adminScopes,
    reports: reportPermissions,
    surveys: surveyPermissions
  };
}

/*
  Maps permissions how we display to the users to how API accepts
  Common method to translate reports, surveys and views
*/
export function mapPermission(permission: PermissionJson, scopesList: { [key: string]: string[] }) {
  let mappedPermission;
  let scopes = [] as string[];
  (permission.scopes || []).forEach(scope => {
    if (scopesList[scope]) {
      scopes = [...scopes, ...scopesList[scope]];
    }
  });
  if (!isEmpty(scopes)) {
    mappedPermission = { ...permission, scopes: uniq(scopes) };
  }
  return mappedPermission;
}

/*
  Returns the overall permissions for reports / surveys (* level permissions)
*/
function getOverallPermissions(permissions: PermissionJson[], scopesList: { [key: string]: string[] }) {
  let overallPermissions;

  const { scopes } = (permissions.find(permission => permission.id === '*') || {});
  if (scopes) {
    let mappedScopes = [] as string[];
    (scopes || []).forEach(scope => {
      if (scopesList[scope]) {
        mappedScopes = [...mappedScopes, ...scopesList[scope]];
      }
    });
    if (!isEmpty(mappedScopes)) {
      overallPermissions = [{ id: '*', scopes: uniq(mappedScopes) }];
    }
  }

  return overallPermissions;
}
