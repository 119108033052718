<template>
  <div class="show-comments">
    <el-dialog
      :visible="dialogVisible"
      width="50%"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div
        v-if="commentsUpdating | processing"
        v-loading="true"
        element-loading-text="Loading..."
        class="show-comments-loader"
      />
      <div v-else>
        <div
          v-if="removingTheme"
          class="title"
        >
          The theme <span class="theme-name">{{ title }}</span> will be removed from
          {{ affectedCommentsPercentage >= 1 ? "approx." : "less than" }} {{ affectedCommentsPercentage > 1 ? affectedCommentsPercentage : '1' }}% of comments
        </div>
        <div
          v-else
          class="title"
        >
          {{ affectedCommentsPercentage >= 1 ? "Approx." : "Less than" }}
          {{ affectedCommentsPercentage > 1 ? affectedCommentsPercentage : '1' }}% of comments will be tagged with <span class="theme-name">{{ title }}</span>
        </div>
        <div class="description">
          Examples:
        </div>
        <div class="content">
          <example-comments
            v-if="!!exampleComments.length"
            :comments="exampleComments.slice(0, 3)"
            :phrases="selectedPhrases"
            :processing="processing"
            :processerror="false"
            :istruncated="true"
          />
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancel-button"
          @click="onCancel"
        >Cancel</el-button>
        <span>
          <el-button
            v-if="!removingTheme && !isPhrasePreSelected"
            class="back-button"
            @click="onBack"
          >
            Back
          </el-button>
          <el-button
            type="primary"
            :class="{
              'untag-comments': removingTheme,
            }"
            :disabled="commentsUpdating"
            @click="onConfirm"
          >
            {{ removingTheme ? "Remove theme" : "Tag comments" }}
          </el-button>
        </span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExampleComments from 'components/ThemeEditor/ExampleComments.tsx';
import {
  getThemesStore,
  getOrganizationStore,
  getQuickEditUIStore,
  getAnalysisToolsUIStore
} from 'stores/RootStore';
import { getCoverageAndExamplesForPhrases } from 'vue/libs/quick-edits-review-changes';
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import { isEmpty } from 'lodash';

export default {
  name: 'ShowComments',
  components: {
    'example-comments': ReactInVue(ExampleComments),
  },
  commentWorker: Worker,
  props: {
    selectedPhrases: {default: () => [], type: Array},
    selectedTheme: {default: () => ({}), type: Object},
    removingTheme: {default: false, type: Boolean},
    isPhrasePreSelected: {default: false, type: Boolean},
    commentId: { default: '', type: [String, Number] },
    selectedBlock: {default: () => ({}), type: Object},
  },
  data() {
    return {
      themesStore: getThemesStore(),
      organizationStore: getOrganizationStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      quickEditUIStore: getQuickEditUIStore(),

      dialogVisible: !!this.selectedPhrases.length,
      exampleComments: [],
      processing: false,
      affectedCommentsPercentage: 0,
      commentsUpdating: false
    };
  },
  computed: {
    title() {
      if (
        (!this.removingTheme && this.selectedTheme.subTitle) ||
        (this.removingTheme && this.selectedTheme.title && this.selectedTheme.title.includes(':'))
      ) {
        return `${this.selectedTheme.baseTitle}: ${this.selectedTheme.subTitle}`;
      } else {
        return this.selectedTheme.baseTitle;
      }
    },
    isThemeRemovedFromPopup() {
      return !isEmpty(this.selectedBlock);
    }
  },
  mounted() {
    this.updateCommentsAndCoverage();
  },
  methods: {
    onConfirm() {
      this.dialogVisible = false;
      const { editingGroup: group } = this.themesStore;
      if (this.removingTheme) {
        this.deleteMappedPhrases(group);
        analytics.track('Analysis: Remove Theme Completed');
      } else {
        let newNode;
        if (!this.selectedTheme.id) {
          // if no id is present, means it is a new theme
          newNode = this.themesStore.addTheme(group, this.selectedTheme.label, this.selectedTheme.base, this.selectedPhrases);
        }
        this.addMappedPhrases(group, newNode);
        analytics.track('Analysis: Add Theme Completed');
      }
      // create the change to track
      let changes;
      if (this.removingTheme) {
          if (this.isThemeRemovedFromPopup) {
            changes = {
              themesRemovedFromPopup: [{
                theme: {...this.selectedTheme},
                location: this.selectedBlock.location
              }]
            };
          } else {
            changes = {
              themesRemoved: [{...this.selectedTheme}]
            };
          }
      } else {
        changes = {
          themesAdded: [{...this.selectedTheme}],
        };
      }
      this.quickEditUIStore.haveEditedComment(this.commentId, changes);
      this.quickEditUIStore.saveEditedComments(this.analysisToolsUIStore.currentSurveyId);
      this.$emit('onAction');
    },
    deleteMappedPhrases(group) {
      const activeNode = this.themesStore.getActiveNode(group);
      if (!activeNode) {
        return;
      }

      this.themesStore.deletePhrases(group, activeNode, this.selectedPhrases);
    },
    addMappedPhrases(group, newNode) {
      let node = newNode;
      if (!newNode) {
        const updatedGroupWithActiveNodeId = {
          ...group,
          activeNodeId: this.selectedTheme.sub ? this.selectedTheme.sub : this.selectedTheme.base
        };
        const activeNode = this.themesStore.getActiveNode(updatedGroupWithActiveNodeId);
        if (!activeNode) {
          return;
        }
        node = activeNode;
      }

      this.themesStore.addPhrases(group, node, this.selectedPhrases);
    },
    onCancel() {
      this.dialogVisible = false;
      this.$emit('onAction');
    },
    onBack() {
      this.$emit('onBack');
    },
    async updateCommentsAndCoverage() {
      const orgId = this.organizationStore.orgId;
      const surveyId = this.analysisToolsUIStore.currentSurveyId;
      this.processing = true;
      const results = await getCoverageAndExamplesForPhrases(orgId, surveyId, this.selectedPhrases);
      this.affectedCommentsPercentage = results.coverage;
      this.exampleComments = results.exampleComments;
      this.processing = false;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.show-comments ::v-deep .el-dialog__header {
  padding: 0;
}
.show-comments {
  .show-comments-loader {
    height: 200px;
  }
  .title {
    font-size: 16px;
    color: $--color-text-primary;
    padding-bottom: 15px;
    line-height: 22px;
    font-weight: 600;
    .theme-name {
      align-items: center;
      border: none;
      color: $--neutral-700;
      line-height: 1;
      border-radius: 10em;
      padding: 4px 8px;
      background: $--neutral-100;
      height: 22px;
    }
  }
  .description {
    font-size: 14px;
    color: $--neutral-900;
    font-weight: 400;
    padding-bottom: 10px;
  }
  .content {
    font-size: 14px;
    border-radius: 4px;
    line-height: 1.6em;
    div {
      text-align: left;
      ::v-deep .ui.list {
        .list-action {
          background: $--neutral-100;
          padding: 12px;
          border-bottom: none;
          mark {
            background-color: transparent;
            font-weight: bold;
            color: $--neutral-900;
          }
        }
      }
    }
  }
  .dialog-footer {
    .untag-comments {
      background-color: $--red-600;
      border-color: $--red-600;
    }
    .untag-comments.is-disabled {
      background-color: $--red-300;
      border-color: $--red-300;
    }
  }
}
</style>
