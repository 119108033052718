import { SurveyDataStatus, SurveyStatus } from 'api/enums';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import ImportData from './ImportData';
import SurveyHeader from './SurveyHeader';
import SurveyImportingInitialDataAction from './SurveyImportingInitialDataAction';
import SurveyIncompleteAction from './SurveyIncompleteAction';
import SurveyReadyActionButtons from './SurveyReadyActionButtons';
import UploadData from './UploadData';
import UploadDisabled from './UploadDisabled';
import './separator.scss';
import './survey-upload.scss';

export interface SurveyCardProps {
  survey: LiteSurvey;
  isChanging: boolean;
}

interface SurveyCardState {
  showSurveyActions: boolean;
}

@observer
class SurveyCard extends React.Component<SurveyCardProps, SurveyCardState> {
  state = {
    showSurveyActions: true,
  };

  onProgress = (progress: boolean) => {
    this.setState({ showSurveyActions: !progress });
  }

  renderCardContent = () => {
    const { showSurveyActions } = this.state;
    const { survey } = this.props;
    const { surveyId, orgId, manualUploadAllowed, dataStatus, dataSourceIntegration, surveyStatus } = survey;
    const hasUniqueId = !isEmpty(JSON.parse(survey.configuration).sort_column);

    const noData = dataStatus === SurveyDataStatus.NODATA;
    const noResults = dataStatus === SurveyDataStatus.NORESULTS;
    const isSetupComplete = !noData && !noResults;

    const importsViaApi = !manualUploadAllowed && !dataSourceIntegration;
    const isImportingDataFromApi = importsViaApi && noData;

    const isImportingDataFromIntegration = !!dataSourceIntegration && !isSetupComplete;

    const showSurveyIncompleteAction = !isSetupComplete && !isImportingDataFromApi && !isImportingDataFromIntegration;
    // Disable upload for users if survey doesn't have an unique id
    const showUploadDisabled = (dataStatus === SurveyDataStatus.READY || dataStatus === SurveyDataStatus.REVIEWING)
      && !hasUniqueId;
    const showUploadData = !showUploadDisabled && manualUploadAllowed && isSetupComplete;
    const showImportData = !showUploadDisabled && (!manualUploadAllowed
      && (isImportingDataFromApi || isSetupComplete));

    const showActions = showSurveyActions &&
      (dataStatus === SurveyDataStatus.READY || dataStatus === SurveyDataStatus.REVIEWING);

    if (surveyStatus === SurveyStatus.UPLOAD_ONLY && noData) {
      return (
        <>
          <UploadData survey={survey} />
          <div className="separator">or</div>
          <Button
            color="blue"
            as={Link}
            to={`/c/${orgId}/s/${surveyId}/configure-survey`}
          >
            Import from another source
          </Button>
        </>
      );
    } else {
      return (
        <>
        {isImportingDataFromIntegration &&
          <SurveyImportingInitialDataAction survey={survey} />
        }
        {showSurveyIncompleteAction &&
          <SurveyIncompleteAction survey={survey} />
        }
        {showUploadData &&
          <UploadData survey={survey} onProgress={this.onProgress} />
        }
        {showImportData &&
          <ImportData survey={survey} />
        }
        {showUploadDisabled &&
          <UploadDisabled survey={survey} />
        }
        {showActions &&
          <SurveyReadyActionButtons
            orgId={orgId}
            surveyId={surveyId}
            dataStatus={dataStatus}
          />
        }
      </>
      );
    }
  }

  render() {
    const { survey, isChanging } = this.props;

    const classes = classnames('upload-card', {
      'upload-card--is-changing': isChanging,
    });

    return (
      <Card
        className={classes}
        fluid={true}
        data-id={this.props.survey.surveyId}
      >
        <Card.Content>
          <Card.Header>
            <SurveyHeader survey={survey} />
          </Card.Header>

          <div className="upload-card__content">
            {this.renderCardContent()}
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default SurveyCard;
