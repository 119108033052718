import { Concepts } from 'api/interfaces';
import auth from 'Auth/Auth';
import analytics from 'lib/analytics';
import { ErrorData } from 'lib/fetch';
import { action, observable } from 'mobx';
import { stringify } from 'query-string';
import { SurveyStoreInterface } from './SurveyStore';
import { ThemesStoreInterface } from './ThemesStore';

function getUrl(orgId: string, surveyId: string, suffix: string) {
  const params = stringify({ organization: orgId });
  return `/survey/${ surveyId }/${ suffix }?${ params }`;
}

function parseError(e?: ErrorData) {
  const fallback = 'Uh oh! Something went wrong. Please try again later.';
  if (!e) {
    return fallback;
  }
  try {
    return e.message || fallback;
  } catch (e) {
    return fallback;
  }
}

export interface ConceptsStoreInterface {
  fetchingConcepts: boolean;
  errorMessage?: string;
  fetchConcepts: (orgId: string, surveyId: string) => Promise<{ contents: string } | undefined>;
  applyConcepts: (
    concepts:
    {
      include: { [key: string]: { nonDescriptors: string[]} };
      learned: { [key: string]: { nonDescriptors: string[]} };
      exclude: { [key: string]: { nonDescriptors: string[]} };
      phrases: { [key: string]: { nonDescriptors: string[]} };
    },
    orgId: string,
    surveyId: string
  ) => Promise<boolean>;
}

export default class ConceptsStore {
  surveyStore: SurveyStoreInterface;
  themesStore: ThemesStoreInterface;

  @observable
  concepts: Concepts = {};

  @observable
  errorMessage?: string = undefined;

  @observable
  fetchingConcepts = false;

  constructor(surveyStore: SurveyStoreInterface, themesStore: ThemesStoreInterface) {
    this.surveyStore = surveyStore;
    this.themesStore = themesStore;
  }

  @action
  fetchConcepts = async (orgId: string, surveyId: string) => {
    this.errorMessage = '';
    this.fetchingConcepts = true;
    const url = getUrl(orgId, surveyId, 'themes/concepts');

    try {
      const { data, ok, errorData } = await auth.fetch<{ contents: string }>(url);
      if (data && ok) {
        this.concepts = JSON.parse(data.contents);
        return data;
      } else {
        this.errorMessage = parseError(errorData);
      }
    } catch (e) {
      this.errorMessage = e.message;
    } finally {
      this.fetchingConcepts = false;
    }
    return undefined;
  }

  @action
  applyConcepts = async (
    concepts:
    {
      include: { [key: string]: { nonDescriptors: string[]} };
      learned: { [key: string]: { nonDescriptors: string[]} };
      exclude: { [key: string]: { nonDescriptors: string[]} };
      phrases: { [key: string]: { nonDescriptors: string[]} };
    },
    orgId: string,
    surveyId: string
    ) => {
    this.errorMessage = '';
    if (!orgId || !surveyId) {
      this.errorMessage = 'Can\'t find organization and survey';
      return false;
    }

    const analysis = await this.surveyStore.getSurvey(surveyId);

    if (!analysis) {
      this.errorMessage = 'Can\'t find organization and survey';
    }

    const url = getUrl(orgId, surveyId, 'themes/concepts/apply');

    const surveyTarget = new URL(
      `#${ analysis?.url }`,
      window.location.href
    ).toString();

    analytics.track('Concepts Editor: Saved');

    let contents = {
      exclude: {},
      include: {},
      learned: {},
      phrases: {}
    };

    Object.keys(concepts).forEach((contentsKey) => Object.keys(concepts[contentsKey]).forEach((concept) =>
      concepts[contentsKey][concept]?.nonDescriptors?.forEach(
        (value) => {contents[contentsKey][value] = concept; }
    )));

    let contentsString: string = JSON.stringify(contents);

    const { data, ok, errorData } = await auth.fetch<{ contents: string }>(url, {
      body: JSON.stringify({surveyTarget, contents: contentsString}),
      method: 'POST'
    });
    let success = true;
    if (data && ok) {
      // make the themestore update its status
      this.themesStore.checkStatus();
    } else {
      success = false;
      this.errorMessage = parseError(errorData);
    }
    return success;
  }
}