import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';
import { ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import './term.scss';

interface NonDescriptorInputProps {
  nonDescriptor: string;
  showNonDescriptorInput: boolean;
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
  onHandleBlur: (nonDescriptorValue: string) => void;
}

interface NonDescriptorInputState {
  nonDescriptorValue: string;
}

@inject('conceptsEditorUIStore')
@observer
export default class NonDescriptorInput extends React.Component<NonDescriptorInputProps, NonDescriptorInputState> {

  constructor(props: NonDescriptorInputProps) {
    super(props);
    this.state = {
      nonDescriptorValue: props.nonDescriptor
    };
  }

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  handleNonDescriptorChange = (e) => {
    const value = e.target.value;
    this.setState({ nonDescriptorValue: value }, () => {
        this.conceptsEditorUIStore.validateNonDescriptor(value, this.props.nonDescriptor);
    });
  }

  handleNonDescriptorKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.onHandleBlur(this.state.nonDescriptorValue);
    }
  }

  render () {
    const {
      isDuplicate,
      isEmpty,
      existsAsDescriptor
    } = this.conceptsEditorUIStore.nonDescriptorErrors;
    const { nonDescriptorValue } = this.state;
    return (
      <div className="input-field">
        <Form.Field>
          <Input
            type="text"
            value={nonDescriptorValue}
            autoFocus={true}
            ref={input => input && input.focus()}
            onBlur={() => this.props.onHandleBlur(nonDescriptorValue)}
            onChange={this.handleNonDescriptorChange}
            onKeyPress={this.handleNonDescriptorKeyPress}
            style={{width: (((nonDescriptorValue.length) + 2) + 'ch')}}
            error={isDuplicate || isEmpty || existsAsDescriptor}
          />
          {this.props.showNonDescriptorInput &&
            <div>
              {(isDuplicate || existsAsDescriptor) &&
                <Label pointing={false} color="red" className="alert">
                  This term is already in use.
                </Label>
              }
              {isEmpty &&
                <Label pointing={false} color="red" className="alert">
                  Terms cannot be empty.
                </Label>
              }
            </div>
          }
        </Form.Field>
      </div>
    );
  }
}