import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { SelectedTheme } from './types';
import { Modal, ModalActions, ModalHeader } from 'components/Shared/Modal';
import { useFetchCommentsAndCoverage } from 'components/AddTheme/hooks';
import { Loader, ModalContent } from 'semantic-ui-react';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import { Button } from 'components/Shared/Button';

interface Props {
  commentId: string;
  isPhrasePreSelected: boolean;
  onClose: () => void;
  onConfirm: () => void;
  orgId: string;
  selectedPhrases: string[];
  selectedTheme: SelectedTheme;
  surveyId: string;
}

const AddThemeExampleCommentsModal = observer(({
  commentId,
  isPhrasePreSelected,
  onClose,
  onConfirm,
  orgId,
  selectedPhrases,
  selectedTheme,
  surveyId
}: Props) => {
  const {
    commentsAndCoverage,
    commentsAndCoverageError,
    loadingCommentsAndCoverage
  } = useFetchCommentsAndCoverage({ orgId, surveyId, selectedPhrases });

  const getSelectedThemeTitle = () => {
    if (!selectedTheme) {
      return '';
    }

    if ('sub' in selectedTheme) {
      return `${selectedTheme.baseTitle}: ${selectedTheme.subTitle}`;
    }

    return selectedTheme.baseTitle;
  };

  const getHeaderText = () => {
    if (!commentsAndCoverage) {
      return '';
    }

    const { coverage } = commentsAndCoverage;

    const prefix = coverage >= 1 ? 'approx' : 'less than';
    const amount = coverage > 1 ? coverage : '1';

    return `${prefix} ${amount}% of comments`;
  };

  const themeTitle = getSelectedThemeTitle();

  return (
    <Modal onClose={onClose} isSizeMedium={true} open={true}>
      {loadingCommentsAndCoverage && (
        <div className="show-comments-modal__loader">
          <Loader active={true} inline="centered" size="large" />
        </div>
      )}
      {commentsAndCoverage && (
        <>
          <ModalHeader as="h2">
            Add <span>{themeTitle}</span> to {getHeaderText()}
          </ModalHeader>
          <ModalContent>
              <ExampleComments
                comments={commentsAndCoverage.exampleComments.slice(0, 3)}
                phrases={selectedPhrases}
                processing={loadingCommentsAndCoverage}
                processerror={!!commentsAndCoverageError}
                istruncated={true}
              />
          </ModalContent>
        </>
      )}
      <ModalActions>
        <Button variant="tertiary" onClick={onClose}>Cancel</Button>
        {!isPhrasePreSelected && <Button variant="tertiary" onClick={onClose}>Back</Button>}
        <Button onClick={onConfirm}>Add theme</Button>
      </ModalActions>
    </Modal>
  )
});

export { AddThemeExampleCommentsModal };
