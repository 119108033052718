import * as React from 'react';
import { AnswersDataSet, AnswersFilters } from 'api/interfaces';
import { useSingleFilters } from '../hooks/useSingleFilters';
import './context-no-data.scss';
import { formatFilterValues } from '../utils/getTranslatedFilters';

interface Props {
  answerFilters: AnswersFilters;
  answerDataSets: AnswersDataSet[];
  comparison?: {
    filters: AnswersFilters;
    name: string;
  }[];
}

export const ContextNoData = ({ answerDataSets, answerFilters, comparison }: Props) => {
  const translatedFilters = useSingleFilters(answerFilters, answerDataSets);

  return (
    <section className="context-no-data">
      <h3 className="context-no-data__title">Review used data</h3>
      {answerDataSets.length > 0 && (
        <>
          {comparison && comparison.length > 0 && (
            <div className="context-no-data__comparison-group">
              {comparison.map(({ filters, name }, index) => {
                return (
                  <div className="context-no-data__comparison" key={`group-${index}`}>
                    <h3 className="context-no-data__comparison-title">
                      {name}
                    </h3>
                    <ul className="context-no-data__comparison-filters">
                      {Object.entries(filters).map(([filterName, filterValues], index) => {
                        return (
                          <li className="context-no-data__comparison-filter-item" key={`${filterName}-${index}`}>
                            <span className="context-no-data__comparison-filter-title">{filterName}</span>
                            <span className="context-no-data__comparison-filter-value">{formatFilterValues(filterName, filterValues)}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          )}
          <div className="context-no-data-metadata">
            <h4 className="context-no-data-metadata__label">DATASETS</h4>
            <span className="context-no-data-metadata__datasets">
              {answerDataSets.map((dataSet) => dataSet.title).join(', ')}
            </span>
          </div>
          <div className="context-no-data-metadata">
            <h4 className="context-no-data-metadata__label">FILTERS</h4>
            <ul className="context-no-data-metadata__filters">
              {translatedFilters.map(({ filterName, filterValues }, index) => {
                return (
                  <li className="context-no-data-metadata__filter-item" key={`${filterName}-${index}`}>
                    <span className="context-no-data-metadata__filter-title">{filterName}</span> <span className="answer-metadata__filter-value">{filterValues}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </section>
  );
};
