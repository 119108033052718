import _, { get } from 'lodash';
import { SPECIAL_EMPTY_NAME } from 'vue/libs/consts';
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';
import thematicData from 'vue/libs/thematicData';
import { mapBaselineAndComparisonData, scoreGraphNormalizeTwoDatasetsForThemeFreq } from './scoreGraphHelpers';
import Vue from 'vue';
import { RequestOptions } from 'stores/InitConfigStore';
import { ThemeFreq } from './types';

interface Selection {
  query: string;
  shortTitle: string;
  title: string;
}

interface Selections {
  baseline: Selection;
  comparison: Selection;
}

type GraphOptions = {
  el: Vue | Element | Vue[] | Element[] | HTMLElement,
  commentColumns: number[],
  dateResolution: string,
  showBy: string,
  requestOptions: RequestOptions,
  selectedThemes: string[],
  selections: Selections,
  themeSegmentSelection: { baseline?: string; comparison?: string },
  chartWidth: number,
  isComparison: boolean,
  selectedChartOption: string,
  onScoreGraphClick: (label: string) => void,
};

export default async function doFetch(graphOptions: GraphOptions) {
  const {
    commentColumns,
    showBy,
    selectedThemes = [],
    dateResolution,
    requestOptions,
    selections,
    themeSegmentSelection,
  } = graphOptions;
  const [selectedTheme, selectedSubtheme] = selectedThemes;
  // override dateResolution with config
  const requestOpts = _.extend({}, requestOptions, { dateResolution });
  // _.set(requestOpts, 'scoreOptions.scoreCommentsOnly', true);
  const baseline = await thematicData.getThemesByDate(
    selections.baseline.query,
    commentColumns,
    { ...requestOpts, themesSegmentFilterString: themeSegmentSelection ? themeSegmentSelection.baseline : null }
  );

  const comparison = await thematicData.getThemesByDate(
    selections.comparison.query,
    commentColumns,
    { ...requestOpts, themesSegmentFilterString: themeSegmentSelection ? themeSegmentSelection.comparison : null }
  );

  if ('ok' in baseline && !baseline.ok) {
    throw baseline.error;
  }

  if ('ok' in comparison && !comparison.ok) {
    throw comparison.error;
  }

  const baselineFreq: ThemeFreq[] = [];
  let selectionFreq: ThemeFreq[] = [];
  for (let i = 0; i < baseline.themes.length; i++) {
    const total = nonemptyCountFromCountsObject(baseline.themes[i].counts);
    let theme = baseline.themes[i].themes[selectedTheme];
    if (theme && selectedSubtheme !== SPECIAL_EMPTY_NAME && selectedSubtheme) {
      theme = theme.subthemes[selectedSubtheme];
    }
    const count = get(theme, 'count', 0);
    const sentimentCounts = get(theme, 'sentimentCounts', {
      neg: 0,
      pos: 0,
      neut: 0
    });
    if (showBy === 'percentage') {
      baselineFreq.push({
        v: total > 0 ? (count / total) * 100 : 0,
        c: count,
        t: total,
        counts: { count, sentimentCounts }
      });
    } else {
      baselineFreq.push({
        v: count,
        c: count,
        t: total,
        counts: { count, sentimentCounts }
      });
    }
  }
  for (let i = 0; i < comparison.themes.length; i++) {
    const total = nonemptyCountFromCountsObject(comparison.themes[i].counts);
    let theme = comparison.themes[i].themes[selectedTheme];
    if (theme && selectedSubtheme !== SPECIAL_EMPTY_NAME && selectedSubtheme) {
      theme = theme.subthemes[selectedSubtheme];
    }
    const count = get(theme, 'count', 0);
    const sentimentCounts = get(theme, 'sentimentCounts', {
      neg: 0,
      pos: 0,
      neut: 0
    });
    if (showBy === 'percentage') {
      selectionFreq.push({
        v: total > 0 ? (count / total) * 100 : 0,
        c: count,
        t: total,
        counts: { count, sentimentCounts }
      });
    } else {
      selectionFreq.push({
        v: count,
        c: count,
        t: total,
        counts: { count, sentimentCounts }
      });
    }
  }

  selectionFreq = scoreGraphNormalizeTwoDatasetsForThemeFreq(
    baseline.dateLabels,
    comparison.dateLabels,
    selectionFreq
  );

  return {
    baseline,
    comparison,
    baselineFreq,
    selectionFreq
  };
}

export function getRenderScoreGraphOptions(
  baseline: any,
  comparison: any,
  baselineFreq: ThemeFreq[],
  selectionFreq: ThemeFreq[],
  graphOptions: GraphOptions
) {
  const {
    showBy,
    selections,
    chartWidth,
    isComparison,
    selectedChartOption,
    onScoreGraphClick
  } = graphOptions;

  const yAxisLabel = `${showBy === 'percentage' ? 'Percentage' : 'Number'} of respondents`;
  const tipValueUnit = showBy === 'percentage' ? '%' : '';

  const combinedData = mapBaselineAndComparisonData(baselineFreq, selectionFreq, baseline.dateLabels);

   return {
    baselineTitle: selections.baseline.title + ' (' + baseline.count + ')',
    comparisonTitle: selections.baseline.title + ' (' + comparison.count + ')',
    data: combinedData,
    labels: baseline.dateLabels,
    chartLabel: yAxisLabel,
    onScoreGraphClick: onScoreGraphClick,
    barChartWidth: chartWidth,
    chartUnit: '',
    isComparison,
    startFromZero: true,
    tipValueUnit,
    showTotalCommentsInTip: true,
    selectedChartOption
  };
}
