import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import * as React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import './data-source-reddit.scss';

export interface DataSourceRedditCreateFormProps {
  existingConfiguration?: RedditConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface RedditSubreddit {
  subreddit: string;
  search: string;
}

interface RedditConfig {
  subreddits: RedditSubreddit[];
}

interface DataSourceRedditCreateFormState {
  config: RedditConfig;
}

class DataSourceRedditCreateForm extends React.Component<
  DataSourceRedditCreateFormProps,
  DataSourceRedditCreateFormState
  > {

  // config starts with one entry because it makes sense
  state = {
    config: {
      subreddits: [{
        subreddit: '',
        search: ''
      }] as RedditSubreddit[]
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;

    if (existingConfiguration) {
      this.setState({
        config: {
          subreddits: existingConfiguration.subreddits
        }
      });
    }

    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  updateExternal = () => {
    const { config } = this.state;

    if (this.props.onChange) {
      this.props.onChange(config, '');
    }
  }

  addEntry = () => {
    // adds a new entry to the bottom of the list
    const { config } = this.state;
    const newConfig = {
      subreddits: [
        ...config.subreddits,
        {
          subreddit: '',
          search: ''
        }
      ]
     };

    this.setState({ config: newConfig }, () => {
      this.checkValidity();
    });
  };

  checkValidity = () => {
    if (this.props.setValidity) {
      const isValid = this.state.config.subreddits.every(entry => entry.subreddit.startsWith('r/'));
      this.props.setValidity(isValid);
    }
  }

  render(): JSX.Element | null {
    const { config } = this.state;

    const existingSubreddits = map(config.subreddits, (subredditInfo, index) => {
      const { subreddit, search } = subredditInfo;

      return (
        <Form.Field key={index}>
          <div className="reddit-row">
            <Input
            className="subreddit"
              placeholder="Subreddit"
              value={subreddit}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                // handle change subreddit
                const { config: localConfig } = this.state;
                localConfig.subreddits[index].subreddit = e.currentTarget.value;
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                });

                this.checkValidity();
              }}
            />
            <Input
            className="search"
              placeholder="Optional Search"
              value={search}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                // handle change in search
                const { config: localConfig } = this.state;
                localConfig.subreddits[index].search = e.currentTarget.value;
                this.setState({ config: localConfig }, () => {
                  this.updateExternal();
                });

                this.checkValidity();
              }}
            />
            <Button
            className="delete" onClick={() => {
              // remove the selected entry
              const { config: localConfig } = this.state;
              localConfig.subreddits.splice(index, 1);
              this.setState({ config: localConfig }, () => {
                this.updateExternal();
                this.checkValidity();
              });
            }}
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="trash"
                  fixedWidth={true}
                />
              } />
          </div>
          <div className="app-store-row">
            <div className="helper-text">Subreddits should look like r/newzealand</div>
            <div className="helper-text">Optional search parameter</div>
          </div>
        </Form.Field>
      );
    });

    return (
      <div>
        <Form.Field>
          <label>Subreddits to monitor</label>
        </Form.Field>
        {existingSubreddits}
        <Form.Field>
          <Button onClick={this.addEntry}>Add Subreddit</Button>
        </Form.Field>
      </div>
    );
  }
}

export default DataSourceRedditCreateForm;