import * as React from 'react';
import { ThemesObject } from "lib/filters/theme-filter-helper";
import { AddThemeBaseItem } from "./types";
import { Transform } from "components/ThemeEditor/theme-file-transforms";

const useThemes = (themesHierarchy: ThemesObject | null, transforms: Transform[]) => {
  const [themes, setThemes] = React.useState<AddThemeBaseItem[]>([]);

  React.useEffect(() => {
    const updateNestedTheme = (hierarchy: ThemesObject, parentId: string, newTheme: object) => {
      return Object.entries(hierarchy).reduce((acc, [key, value]) => {
        if (value.id === parentId) {
          return {
            ...acc,
            [key]: {
              ...value,
              subthemes: {
                ...value.subthemes,
                ...newTheme,
              },
            },
          };
        }

        return acc;
      }, {});
    };

    const updateThemesHierarchy = (themesHierarchy) => {
      if (!themesHierarchy) {
        return [];
      }

      if (transforms.length === 0) {
        return themesHierarchy;
      }

      return transforms.reduce((updatedHierarchy, transform) => {
        if (!('title' in transform) || !('themeId' in transform)) {
          return updatedHierarchy;
        }

        const theme = {
          [transform.title]: { id: transform.themeId },
        };

        if (transform.parentThemeId) {
          return updateNestedTheme(updatedHierarchy, transform.parentThemeId, theme);
        } else {
          return { ...updatedHierarchy, ...theme };
        }
      }, themesHierarchy);
    };

    const mapThemes = (themeObject: ThemesObject): AddThemeBaseItem[] => {
      return Object.entries(themeObject).reduce((result, [themeName, theme]) => {
        return [
          ...result,
          {
            id: theme.id,
            baseTitle: themeName,
            base: theme.id,
            label: themeName,
            children: Object.entries(theme.subthemes || {})
              .filter(([_, subTheme]) => theme.id !== subTheme.id)
              .map(([subThemeName, subTheme]) => ({
                id: `${theme.id}:${subTheme.id}`,
                baseTitle: themeName,
                base: theme.id,
                sub: subTheme.id,
                subTitle: subThemeName,
                label: subThemeName,
              })),
          },
        ];
      }, []);
    };

    if (!themesHierarchy) {
      return;
    }

    const updatedThemesHierarchy = updateThemesHierarchy(themesHierarchy);
    const mappedThemes = mapThemes(updatedThemesHierarchy);

    setThemes(mappedThemes);
  }, [themesHierarchy, transforms]);

  return themes;
}

export { useThemes };
