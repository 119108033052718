import * as React from 'react';
import * as classnames from 'classnames';
import './quality-indicator.scss';
import { Tooltip } from 'components/Shared/Tooltip';

export const qualityRatings = ['Needs Work', 'Good', 'Great', 'Excellent'] as const;

export type QualityRating = typeof qualityRatings[number];

interface QualityIndicatorProps {
  rating: QualityRating;
  description?: string;
}

const qualityClasses: Record<QualityRating, string> = {
  'Excellent': 'excellent',
  'Good': 'good',
  'Great': 'great',
  'Needs Work': 'needs-work'
};

export const getQualityClass = (value: QualityRating): string => {
  return qualityClasses[value];
};

interface QualityBarsProps {
  qualityRating: QualityRating;
}

const QualityBars: React.FC<QualityBarsProps> = ({ qualityRating }) => {
  const currentQualityIndex = qualityRatings.indexOf(qualityRating);
  const qualityClass = getQualityClass(qualityRating);

  return (
    <div className="quality-indicator__quality-bars">
      {qualityRatings.map((rating, index) => {
        const isBarActive = index <= currentQualityIndex;
        return (
          <Tooltip
            key={rating}
            disabled={!isBarActive}
            content={
              <div className='quality-indicator__quality-bar-tooltip-content'>
                <h4 className='quality-indicator__quality-bar-tooltip-title'>Great</h4>80-100%
              </div>
              // TODO: Show content from API response when that is ready
            }
            position="top left"
            inverted
            trigger={
              <div
                className={classnames('quality-indicator__quality-bar', {
                  [`quality-indicator__quality-bar--${qualityClass}`]: isBarActive
                })}
              />
            }
          />
        );
      })}
    </div>
  );
};

export const QualityIndicator: React.FC<QualityIndicatorProps> = ({ rating, description }) => {
  return (
    <div className="quality-indicator">
      <div className="quality-indicator__quality">
        <QualityBars qualityRating={rating} />
        <p className='quality-indicator__quality-rating'>{rating}</p>
      </div>
      {description && <p className="quality-indicator__description">{description}</p>}
    </div>
  );
};