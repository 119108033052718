<template>
  <div
    class="vertical-thread"
  >
    <p
      class="vertical-thread__bd"
    >
      <vertical-comment-thread-part
        v-for="(threadParts, index) in conversationPairs"
        :key="`thread-part-${index}`"
        :seen="true"
        :indexes="[-1, index]"
        :intersection-observer="null"
        :are-themes-in-applying-state="areThemesInApplyingState"
        :comment-column-id="commentColumnId"
        :comment-id="commentId"
        :highlight-type="highlightType"
        :highlighted-locations="highlightedLocations"
        :selected-phrase="selectedPhrase"
        :support-messages-visible="true"
        :thread-parts="threadParts"
        @onTextSelectionStart="onTextSelectionStart"
        @onEnterBlock="onEnterBlock"
        @hoverBlock="block => $emit('hoverBlock', block)"
        @leaveBlock="() => $emit('leaveBlock')"
        @onRemoveThemeFromPart="(theme, block) => removeTheme(theme, block, 'Popup')"
        @onSentimentChange="args => $emit('onSentimentChange', args)"
      />
    </p>
    <add-theme-to-comment
      v-if="canSeeInlineThemesEditing"
      :selection-status="selectionStatus"
      :max-words="maxWords"
      :vertical-position="selectedPhrasePositionY"
      :horizontal-position="selectedPhrasePositionX"
      :pre-selected-phrase="selectedPhrase"
      :conversations="conversations"
      :comment-id="commentId"
      :are-themes-in-applying-state="areThemesInApplyingState"
    />
  </div>
</template>

<script>
import VerticalCommentThreadPart from 'vue/components/Comments/VerticalCommentThreadPart.vue';
import AddThemeToComment from 'vue/components/Themes/AddThemeToComment.vue';
import getCommentHighlightLocations from "lib/get-comment-highlight-locations.ts";
import addThemeToSelectionMixin from 'vue/components/Comments/AddThemeToSelectionMixin.js';
import threadCommentMixin from 'vue/components/Comments/ThreadCommentMixin.js';
import analytics from 'lib/analytics';

export default {
  name: 'FeedbackTableThreadCell',
  components: {
    VerticalCommentThreadPart,
    AddThemeToComment,
  },
  mixins: [
    addThemeToSelectionMixin(),
    threadCommentMixin()
  ],
  props: {
    comment: { default: () => [], type: Array },
    commentColumnId: { type: Number, required: true },
    commentId: { default: '', type: String },
    highlightType: { default: '', type: String },
    hoveredLocation: { default: null, type: Array },
    hoveredThemeCodes: { default: null, type: Object },
  },
  computed: {
    conversationPairs() {
      return this.conversations.flatMap(conversation => conversation.pairs);
    },
    highlightedLocations() {

      const highlightedLocations = getCommentHighlightLocations(
        this.segments,
        null, // selectedThemeCodes (not used on feedback table)
        this.hoveredLocation,
        this.hoveredThemeCodes
      );
      return highlightedLocations;

    },
  },
  watch: {
    selectionStatus(status) {
      if (status !== 'NONE') {
        analytics.track('Analysis: Select Comment', { 'Type': 'Conversation' });
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';

.vertical-thread {
  background: $--white;
  border-radius: $--border-radius-small;

  &__bd {
    font-weight: 400;
    font-size: 14px;
    grid-area: bd;
    line-height: 22px;
    margin: 0;
    position: relative;
    word-break: break-word;
    span {
      @extend .theme-highlight-base;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 0;
      white-space: pre-wrap;
    }
  }
}
</style>
