import { isEqual } from 'lodash';

export default function () {
  return {
    data() {
      return {
        hoveredLocation: null,
      };
    },
    methods: {
      hoverBlock(block) {
        if (block.hasThemes) {
          this.hoveredLocation = block.location;
        }
      },
      leaveBlock() {
        this.hoveredLocation = null;
      },
      persistHoveredBlock(block) {
        if (!isEqual(this.hoveredLocation, block.location)) {
          this.hoveredLocation = block.location;
        }
      },
    }
  };
}

