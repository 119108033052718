import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisFilterCut } from 'api/interfaces';
import classNames from 'classnames';
import BoldSubstring from 'components/Shared/BoldSubstring';
import * as React from 'react';
import FilterMenuItem from './FilterMenuItem';
import { FilterSearchResult } from 'lib/filters/filter-search-helper';

interface FilterSearchResultListProps {
  activeItemIndex: number;
  indexedResults: [result: FilterSearchResult, index: number][];
  onSearchResultClick: (cut: AnalysisFilterCut) => void;
  regex: RegExp;
}

const FilterSearchResultList = (props: FilterSearchResultListProps) => {
  return (
    <>
      {props.indexedResults.map(([result, index]) => {
        return (
          <FilterMenuItem
            key={result.id + result.name}
            className="search-result"
            active={props.activeItemIndex === index}
            onClick={() => props.onSearchResultClick(
              result.source as AnalysisFilterCut
            )}
            >
            <FontAwesomeIcon
              className={classNames('check-icon', { invisible: !result.isSelected })}
              icon="check"
            />
            <span>
              <BoldSubstring
                value={result.name}
                regex={props.regex}
              />
            </span>
          </FilterMenuItem>
         );
        })
      }
    </>
  );
};

export default FilterSearchResultList;
