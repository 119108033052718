import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterType } from 'api/enums';
import { AnalysisViewSource, DiscoveredThemesJson } from 'api/interfaces';
import SingleFilter from 'components/Filters/SingleFilter';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Checkbox, Form, Header, Message, Modal } from 'semantic-ui-react';
import { AnalysisToolsStoreInterface } from 'stores/AnalysisToolsStore';
import { FilterStoreInterface } from 'stores/FilterStore';
import { GenerateParams, SelectedTheme, ThemeDiscoveryStoreInterface } from 'stores/ThemeDiscoveryStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import ThemeDiscoveryResolution from './ThemeDiscoveryResolution';
import ThemeSelect from './ThemeSelect';
import './theme-discovery.scss';
import { filterPopUpId } from 'components/Filters/types';
import { initializeFilters } from 'components/Filters/filters.service';
import safeDiv from 'lib/safe-div';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

export interface InitialThemeDiscoveryOptions {
  filter?: string;
  excludeSingleWordThemes?: boolean;
  selectedTheme?: SelectedTheme;
  startImmediately?: boolean;
}

export interface ThemeDiscoveryProps {
  orgId: string;
  surveyId: string;
  initialOptions?: InitialThemeDiscoveryOptions;
  close: () => void;
}

interface InjectedProps {
  analysisToolsStore: AnalysisToolsStoreInterface;
  themesDiscoveryStore: ThemeDiscoveryStoreInterface;
  themesStore: ThemesStoreInterface;
  filterStore: FilterStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

interface ThemeDiscoveryState {
  startedImmediately: boolean;
  excludeSingleWordThemes: boolean;
}
const withHocs = compose(
  inject(
    'analysisToolsStore',
    'filterStore',
    'themeEditorSessionStore',
    'themesDiscoveryStore',
    'themesStore',
  ),
  observer,
);

export default withHocs(class ThemeDiscovery extends React.Component<ThemeDiscoveryProps, ThemeDiscoveryState> {
  get injected(): InjectedProps {
    return this.props as ThemeDiscoveryProps & InjectedProps;
  }

  state = {
    startedImmediately: false,
    excludeSingleWordThemes: false,
  };

  componentDidMount = () => {
    // this is a new discovery, so reset
    const { themesDiscoveryStore: store } = this.injected;
    store.propose = true;
    store.generatedThemes = undefined;
    store.requestGenerateErrored = undefined;
    if (this.props.initialOptions) {
      const { initialOptions } = this.props;

      store.selectedTheme = initialOptions.selectedTheme;
      if (initialOptions.filter) {
        store.selectedFilterRql = initialOptions.filter;
      }
      this.setState({ excludeSingleWordThemes: !!initialOptions.excludeSingleWordThemes }, () => {
        if (initialOptions.startImmediately) {
          this.setState({ startedImmediately: true });
          this.discover();
        }
      });
    }
    store.resolvingThemes = undefined;
    this.initializeFilters();
  };

  initializeFilters = () => {
    const surveyId = this.props.surveyId;
    const source: AnalysisViewSource = {
      survey: surveyId,
      visualization: '_'
    };

    initializeFilters(
      source,
      [FilterType.THEMES],
    );
  }

  close = () => {
    const { themesDiscoveryStore: store } = this.injected;
    store.propose = false;
    this.props.close();
  };

  discover = () => {
    const { orgId, surveyId } = this.props;
    const { excludeSingleWordThemes } = this.state;
    const { themesDiscoveryStore: store } = this.injected;
    const { filter, selectedTheme } = store;

    const sentencesOnly = !!selectedTheme;

    const focusTheme = selectedTheme?.subtheme || selectedTheme?.theme;

    const params = {
      filter,
      orgId,
      surveyId,
      focusTheme,
      sentencesOnly,
      excludeSingleWordThemes
    } as GenerateParams;

    store.generate(params);

    analytics.track('Theme Discovery: Discover themes', {
      category: 'Theme Discovery',
      survey: surveyId,
    });
  };

  applySelectedThemes = () => {
    const { surveyId } = this.props;
    const {
      themesDiscoveryStore,
      themeEditorSessionStore,
      themesStore: { groups, addTheme, mergeTheme },
    } = this.injected;

    themesDiscoveryStore.applyProposedThemes(groups, addTheme, mergeTheme);

    analytics.track('Theme Discovery: Add selected themes', {
      category: 'Theme Discovery',
      survey: surveyId,
    });

    const items = themesDiscoveryStore.resolvingThemes ?? [];
    const selectedItems = items.filter(item => item.selected);

    const numerator = selectedItems.length;
    const denominator = items.length;
    const percent = safeDiv(numerator, denominator, 0) * 100;

    themeEditorSessionStore.addEvent({
      type: 'Discover',
      subType: 'Themes',
      timestamp: Date.now(),
      data: percent
    });
    this.props.close();
  };

  onThemeChange = (theme: SelectedTheme) => {
    const { themesDiscoveryStore: store } = this.injected;
    store.selectedTheme = theme;
  };

  onFilterClose = () => {
    const { themesDiscoveryStore, filterStore } = this.injected;
    themesDiscoveryStore.selectedFilterRql = filterStore.selections.baseline.query;
  };

  renderError(errored: string): JSX.Element {
    return (
      <Message negative={true} icon={true}>
        <FontAwesomeIcon className="icon" icon="exclamation-triangle" fixedWidth={true} />
        <Message.Content>
          <p>Uh oh! Couldn't discover new themes: {errored}.</p>
        </Message.Content>
      </Message>
    );
  }

  renderFilterContent(): JSX.Element {
    const { themesStore, themesDiscoveryStore } = this.injected;
    const { surveyId, initialOptions } = this.props;
    const { startedImmediately, excludeSingleWordThemes } = this.state;

    const currentlySelectedTheme = themesDiscoveryStore.selectedTheme;

    const selectedValue = initialOptions?.selectedTheme?.subtheme ?
      initialOptions?.selectedTheme.subtheme :
      initialOptions?.selectedTheme?.theme;

    const groupId = themesStore.groups.length > 0 ? themesStore.groups[0].id : '';

    if (startedImmediately) {
      return (
        <p>
          Retrieving discovery results...
        </p>
      );
    }

    return (
      <Form>
        <p>
          Discover themes from sentences
          {currentlySelectedTheme && currentlySelectedTheme.theme !== 'other' ? ' about' : ''}
        </p>

        <div>
          <div className="search-options">
            <ThemeSelect groupId={groupId} hasOther={true} value={selectedValue} onChange={this.onThemeChange} />
            <span className="theme-discovery__filter">
              <SingleFilter
                testId="theme-discovery-filter"
                filterPopUpId={filterPopUpId.ThemeDiscovery}
                surveyId={surveyId}
                disabledFilterTypes={[FilterType.THEMES]}
                defaultDateToAll={true}
                onChange={() => { /* no-op */ }}
                onClose={this.onFilterClose}
              />
            </span>
          </div>
          <div>
            <Form.Field>
              <Checkbox
                label="Include single word themes"
                checked={!excludeSingleWordThemes}
                onChange={() => this.setState({ excludeSingleWordThemes: !excludeSingleWordThemes })}
              />
            </Form.Field>
          </div>
        </div>
      </Form>
    );
  }

  render(): JSX.Element | null {
    const { themesDiscoveryStore: store } = this.injected;
    const { surveyId } = this.props;
    const {
      requestingGenerate,
      requestGenerateErrored: errored,
      isValid,
      generatedThemes,
      selectedTheme,
      resolvingThemes,
    } = store;

    const numSelectedThemes = resolvingThemes
      ? resolvingThemes.reduce((count, { selected }) => (selected ? count + 1 : count), 0)
      : 0;
    const operationsSelected = resolvingThemes && numSelectedThemes > 0;
    const hasThemesToShow = !errored && !!generatedThemes;

    return (
      <div style={{ marginRight: '5px' }}>
        <Modal className="theme-discovery" open={true} size="large" onClose={this.close} closeOnDimmerClick={false}>
          <Modal.Header as={Header}>
            {generatedThemes ? `${ generatedThemes.themes.length } themes discovered` : 'Theme discovery'}
          </Modal.Header>
          <Modal.Content>
            {errored && this.renderError(errored)}
            {!errored && !generatedThemes && this.renderFilterContent()}
            {hasThemesToShow && (
              <ThemeDiscoveryResolution
                generatedThemes={generatedThemes as DiscoveredThemesJson}
                surveyId={surveyId}
                selectedTheme={selectedTheme}
              />
            )}
          </Modal.Content>

          <Modal.Actions>
            <Button disabled={requestingGenerate} onClick={this.close}>
              Cancel
            </Button>
            <Button
              color="blue"
              disabled={!isValid || (generatedThemes && !operationsSelected)}
              onClick={generatedThemes ? this.applySelectedThemes : this.discover}
              loading={requestingGenerate}
            >
              {generatedThemes && <span>Add{numSelectedThemes ? ` ${ numSelectedThemes } ` : ' '}selected themes</span>}
              {!generatedThemes && <span>Discover themes</span>}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
});
