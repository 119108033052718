<template>
  <div class="subtheme-contribs">
    <div class="subtheme-contribs__header">
      <el-button
        size="medium"
        type="plain"
        @click="close"
      >
        <font-awesome-icon icon="times" />
        Close
      </el-button>
      <div class="subtheme-contribs__header-text">
        Subtheme breakdown
      </div>
    </div>
    <div
      v-if="negatives && negatives.length"
      class="subtheme-contribs__block"
    >
      <div class="subtheme-contribs__block-header">
        Negative contributions of <span>{{ theme }}</span> to {{ score }}
        movement
      </div>

      <div class="subtheme-contribs__block-bd">
        <contribution
          v-for="subtheme in negatives"
          :key="subtheme.key"
          :contribution="subtheme"
          :current-total="currentTotal"
          :previous-total="previousTotal"
          :current-label="currentLabel"
          :min-value="minWeighting"
          :max-value="0"
          :previous-label="previousLabel"
          :selected="subtheme.title === selectedSubtheme"
        />
      </div>
    </div>
    <div
      v-if="positives && positives.length"
      class="subtheme-contribs__block"
    >
      <div class="subtheme-contribs__block-header">
        Positive contributions of <span>{{ theme }}</span> to {{ score }}
        movement
      </div>
      <div class="subtheme-contribs__block-bd">
        <contribution
          v-for="subtheme in positives"
          :key="subtheme.key"
          :contribution="subtheme"
          :current-label="currentLabel"
          :current-total="currentTotal"
          :previous-total="previousTotal"
          :min-value="0"
          :max-value="maxWeighting"
          :previous-label="previousLabel"
          :selected="subtheme.title === selectedSubtheme"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { filter, get, map, max, min, orderBy } from 'lodash';

import analytics from 'lib/analytics';
import fuzzNum from 'vue/libs/fuzz-num';
import { roundMinMax } from 'vue/libs/min-max';
import toFixed from 'vue/libs/to-fixed';

import Contribution from 'vue/over-time/Contribution.vue';

import { getAnalysisToolsUIStore } from 'stores/RootStore';

export default {
  name: 'SubthemeContribs',
  components: { Contribution },
  props: {
    currentLabel: { default: undefined, type: String },
    currentTotal: { default: undefined, type: Number },
    previousLabel: { default: undefined, type: String },
    previousTotal: { default: undefined, type: Number },
    score: { default: undefined, type: String },
    subthemes: { default: () => [], type: Array }
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore()
    };
  },
  computed: {
    theme() {
      return this.analysisToolsUIStore.selectedThemeName;
    },
    selectedSubtheme() {
      return this.analysisToolsUIStore.selectedSubthemeName;
    },
    maximum() {
      return max(this.weightings);
    },
    minimum() {
      return min(this.weightings);
    },
    maxWeighting() {
      const { maximum, minimum } = this;

      if (maximum < 0) {
        return 0;
      } else {
        const val = Math.max(maximum, Math.abs(minimum));
        return roundMinMax(val);
      }
    },
    minWeighting() {
      const { maximum, minimum } = this;

      if (minimum > 0) {
        return 0;
      } else {
        const val = Math.min(minimum, -Math.abs(maximum));
        return roundMinMax(val);
      }
    },
    negatives() {
      const { subthemes } = this;
      const negatives = filter(subthemes, s => s.changeWeighting < 0);
      return orderBy(negatives, ['changeWeighting', 'title'], ['asc', 'asc']);
    },
    positives() {
      const { subthemes } = this;
      const positives = filter(subthemes, s => s.changeWeighting >= 0);
      return orderBy(positives, ['changeWeighting', 'title'], ['desc', 'asc']);
    },
    weightings() {
      const { subthemes } = this;
      return map(subthemes, 'changeWeighting');
    }
  },
  methods: {
    selectTheme(theme, subtheme) {
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
      analytics.track('Waterfall: Selected Subtheme', { category: 'Overtime' });
    },
    close() {
      this.$emit('close');
    },
    formatLabel(val) {
      return fuzzNum(val);
    },
    getPreviousScore(subtheme) {
      return get(subtheme, 'previousScore.score');
    },
    getScore(subtheme) {
      return get(subtheme, 'score.score');
    },
    toFixed(val, dp = 1) {
      return toFixed(val, dp);
    }
  }
};
</script>

<style lang="scss">
@import '../../vue/styles/element-variables';
.subtheme-contribs {
  align-items: center;
  border-radius: $--border-radius-small;
  box-shadow: 0 2px 6px 0 rgba(84, 99, 119, 0.3);
  margin: 0 10px;
  max-width: calc(100vw - 470px);
  &__header {
    color: $--neutral-700;
    padding: 10px 0;
    position: relative;
    text-align: center;
    .el-button {
      align-content: center;
      display: inline-flex;
      position: absolute;
      left: 10px;
      padding: 5px 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__block {
    padding: 10px;
    &-header {
      color: $--neutral-700;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.4px;
      margin-bottom: 10px;
    }
    &-bd {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      grid-gap: 10px;
    }
  }
}
</style>
