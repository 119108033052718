






































import { getExportInsightUIStore } from 'stores/RootStore';

export default {
  name: 'LegendInsight',
  props: {
    insight: { type: Object, required: true }
  },
  data() {
    return {
      exportInsightUIStore: getExportInsightUIStore(),
      legendBaselineData: [] as Array<string>[],
      baselineCommentCount: 0,
      legendComparisonData: [] as Array<string>[],
      comparisonCommentCount: 0,
    }
  },
  watch : {
    insight: {
      handler() {
        this.setLegendInfo();
      }
    }
  },
  mounted() {
    this.setLegendInfo();
  },
  methods: {
    setLegendInfo() {
      this.baselineCommentCount = this.insight.baseline.label;
      this.legendBaselineData = Object.entries(this.insight.baseline.data);
      if (!this.insight.shouldShowComparison) {
        return;
      }
      this.comparisonCommentCount = this.insight.comparison.label;
      this.legendComparisonData = Object.entries(this.insight.comparison.data);
    }
  }
};
