import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/Shared/Button';
import { Modal, ModalContent, ModalHeader } from 'components/Shared/Modal';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import { Loader, ModalActions } from 'semantic-ui-react';
import './show-comments-modal.scss';
import { AddThemeBaseItem, AddThemeSubItem } from 'components/AddTheme/types';
import { useFetchCommentsAndCoverage } from 'components/AddTheme/hooks';

interface Props {
  commentId: string;
  isPhrasePreSelected: boolean;
  onBack: () => void;
  onClose: () => void;
  onConfirm: () => void;
  orgId: string;
  selectedPhrases: string[];
  selectedTheme: AddThemeBaseItem | AddThemeSubItem;
  surveyId: string;
}

const ShowCommentsModal = observer(({
  isPhrasePreSelected,
  onBack,
  onClose,
  onConfirm,
  orgId,
  selectedPhrases,
  selectedTheme,
  surveyId
}: Props) => {
  const {
    commentsAndCoverage,
    commentsAndCoverageError,
    loadingCommentsAndCoverage
  } = useFetchCommentsAndCoverage({ orgId, surveyId, selectedPhrases });

  const getSelectedThemeTitle = () => {
    if (!selectedTheme) {
      return '';
    }

    if ('sub' in selectedTheme) {
      return `${selectedTheme.baseTitle}: ${selectedTheme.subTitle}`;
    }

    return selectedTheme.baseTitle;
  };

  const selectedThemeTitle = getSelectedThemeTitle();

  const getHeaderText = () => {
    if (!commentsAndCoverage) {
      return '';
    }

    const { coverage } = commentsAndCoverage;

    const prefix = coverage >= 1 ? 'Approx' : 'Less than';
    const amount = coverage > 1 ? coverage : '1';

    return `${prefix} ${amount}% of comments will be tagged with`;
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal isSizeMedium={true} onClose={onClose} open={true}>
      {loadingCommentsAndCoverage && (
        <div className="show-comments-modal__loader">
          <Loader active={true} inline="centered" size="large" />
        </div>
      )}
      {commentsAndCoverage && (
        <>
          <ModalHeader>
            <h2>
              {getHeaderText()}
              <span className="show-comments-modal__theme-name">{selectedThemeTitle}</span>
            </h2>
          </ModalHeader>
          <ModalContent>
            <span>Examples:</span>
            <ExampleComments
              comments={commentsAndCoverage.exampleComments.slice(0, 3)}
              phrases={selectedPhrases}
              processing={loadingCommentsAndCoverage}
              processerror={!!commentsAndCoverageError}
              istruncated={true}
            />
          </ModalContent>
          <ModalActions>
            <Button variant="tertiary" onClick={onClose}>Cancel</Button>
            {!isPhrasePreSelected && <Button variant="tertiary" onClick={onBack}>Back</Button>}
            <Button onClick={handleConfirm}>Tag comments</Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
});

export { ShowCommentsModal };
