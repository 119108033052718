import * as React from 'react';
import { Button, List } from 'semantic-ui-react';
import './merged-theme.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FlagKeys from 'Auth/flag-keys';
import { FeatureFlagManager } from 'lib/feature-flag';

export interface MergedThemeProps {
  onUnmerge: () => void;
  isNew: boolean;
  title: string;
}

const MergedTheme = (props: MergedThemeProps) => {
  const { isNew, title, onUnmerge } = props;

  const canSeeNewMergedThemes:boolean = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_NEW_MERGED_THEMES) !== false;

  if (canSeeNewMergedThemes) {
    return (
      <List.Item>
        <div className="merged-theme">
          <button
            className="merged-theme__button merged-theme__filter-button"
          >
            {title}
          </button>
          <button
            className="merged-theme__button merged-theme__undo-button"
          >
            <FontAwesomeIcon icon="undo" className="icon" />
          </button>
        </div>
      </List.Item>
    );
  }

  return (
    <List.Item className="list-action">
      <List.Content content={title} />
      {isNew && <FontAwesomeIcon icon="star" className="icon" />}
      <Button className="action" size="small" onClick={onUnmerge}>
        <FontAwesomeIcon icon="undo" className="icon" />
      </Button>
    </List.Item>
  );
};

export { MergedTheme };
