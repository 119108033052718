import * as React from 'react';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import { MAX_SELECTED_WORDS, SELECTION_STATUS } from './types';
import { Block } from 'lib/segments-to-blocks';
import './select-phrase-modal.scss';
import { Button } from 'components/Shared/Button';
import { useAddThemeToSelection } from 'components/SimilarSentences/useAddThemeToSelection';
import { InlineNotification } from 'components/Shared/InlineNotification';

const SELECTION_ERROR = {
  [SELECTION_STATUS.None]: 'Please select a phrase to continue.',
  [SELECTION_STATUS.InvalidCrossesBoundary]: 'Please select text within a single sentence.',
  [SELECTION_STATUS.InvalidTooManyWords]: `Please select ${MAX_SELECTED_WORDS} or fewer words`
}

interface Props {
  blocks: Block[] | undefined;
  onClose: () => void;
  onConfirmSelectedPhrase: (selectedPhrase: string) => void;
  selectedTheme: any;
}

const SelectPhraseModal = ({ blocks, onClose, onConfirmSelectedPhrase, selectedTheme }: Props) => {
  const [hasConfirmed, setHasConfirmed] = React.useState<boolean>(false);
  const { onEnterBlock, onTextSelectionEnd, onSelectionChange, selectedPhrase, selectedStatus } =
    useAddThemeToSelection();
  const contentRef = React.createRef<HTMLParagraphElement>();
  const { baseTitle, subTitle } = selectedTheme;
  const title = subTitle ? `${baseTitle}: ${subTitle}` : baseTitle;

  React.useEffect(() => {
    const contentElement = contentRef.current;

    contentElement?.addEventListener('selectionchange', onSelectionChange);

    return () => {
      contentElement?.removeEventListener('selectionchange', onSelectionChange);
    };
  }, [contentRef, onSelectionChange]);

  const onConfirm = () => {
    setHasConfirmed(true);

    if (selectedStatus === SELECTION_STATUS.Valid) {
      onConfirmSelectedPhrase(selectedPhrase);
    }
  };

  const showError = () => {
    const invalidSelection = selectedPhrase && selectedStatus !== SELECTION_STATUS.Valid;
    const confirmedWithNoSelection = !selectedPhrase && hasConfirmed;

    return invalidSelection || confirmedWithNoSelection;
  };

  return (
    <Modal onClose={onClose} open={true} isSizeMedium={true}>
      <ModalHeader as="h2">
        Select phrase to tag with <span className="select-phrase-modal__theme-name">{title}</span>
      </ModalHeader>
      <ModalContent>
        <p>Select up to {MAX_SELECTED_WORDS} words</p>
        {showError() && (
          <div className="select-phrase-modal__notification">
            <InlineNotification title={SELECTION_ERROR[selectedStatus]} isHighlighted={true} type="error" />
          </div>
        )}
        <p className="select-phrase-modal__content" onMouseUp={onTextSelectionEnd} ref={contentRef}>
          {blocks?.map((block) => (
            <span key={block.key} onMouseEnter={() => onEnterBlock(block)}>
              {block.content}
            </span>
          ))}
        </p>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant="tertiary">Cancel</Button>
        <Button onClick={onConfirm}>Next</Button>
      </ModalActions>
    </Modal>
  );
};

export { SelectPhraseModal };
