import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './inline-notification.scss';
import classNames from 'classnames';
import { Button } from './Button';

interface Props {
  title: string;
  type: 'error' | 'info';
  isHighlighted?: boolean;
  actionText?: string;
  onActionClick?: () => void;
}

const InlineNotification = ({
  title,
  isHighlighted,
  type,
  actionText,
  onActionClick
}: Props) => {

  const buttonSubVariant = type === 'error' ? 'red' : undefined;
  const icon = type === 'error'
    ? <FontAwesomeIcon icon="exclamation-triangle" />
    : <FontAwesomeIcon icon="info-circle" />;
  return (
    <div
      className={classNames(
        `inline-notification inline-notification--${type}`, {
        'inline-notification--highlighted': isHighlighted,
      })}
    >
      <div
        className="inline-notification__icon"
      >
        {icon}
      </div>
      <h2 className="inline-notification__title">{ title }</h2>
      {actionText && actionText.length > 0 &&
        <Button
          variant="tertiary"
          size="medium"
          subvariant={buttonSubVariant}
          onClick={onActionClick}
        >
          {actionText}
        </Button>
      }
    </div>
  );
}

export { InlineNotification };
