









































import toSentimentClassname from "vue/libs/to-sentiment-classname";
import CommentSentimentPopup from './CommentSentimentPopup.vue';
import CommentThemesList from 'vue/components/Comments/CommentThemesList.vue';
import PopupManager from 'element-ui/src/utils/popup/popup-manager';

export default {
  name: 'CommentThemePopup',
  components: {
    CommentThemesList,
    CommentSentimentPopup,
  },
  props: {
    showTruncation: { default: false, type: Boolean },
    block: { required: true, type: Object },
    hoverOnThemes: { default: true, type: Boolean},
    isSentimentEnabled: {default: true, type: Boolean},
    blockIndex: { required: true, type: Number },
    isHiddenBlock: { default: false, type: Boolean},
    canManageThemes: { required: true, type: Boolean },
    popupThemes: { required: true, type: Array},
    areThemesInApplyingState: { default: false, type: Boolean },
    isPopupDisabled: { default: false, type: Boolean },
  },
  methods: {
    toSentimentClassname,
    onSentencePopupHide() {
      // making sure that sentiment popup closes as well when theme popover is closed
      const sentimentPopoverRef = this.$refs.sentencePopover['$children'][0].$refs.sentimentPopover;
      if (sentimentPopoverRef) {
        sentimentPopoverRef.doClose();
      }
    },
    handleSentimentSelection(value) {
      this.$emit('handleSentimentSelection', value);
      const sentencePopoverRef = this.$refs.sentencePopover as PopupManager;
      sentencePopoverRef.doClose();
    }
  }
};

