<template>
  <el-tooltip
    :enterable="false"
    :open-delay="300"
    placement="top"
    popper-class="el-tooltip__popper-no-mouse"
  >
    <div slot="content">
      <div class="detail-items__3-c">
        <div><b>{{ contribution.title }}</b></div>
        <div>{{ previousLabel }}</div>
        <div>{{ currentLabel }}</div>
        <div>Volume</div>
        <div>{{ previousCount }}</div>
        <div>{{ count }}</div>
        <div>Score</div>
        <div>{{ previousScore }}</div>
        <div>{{ score }}</div>
      </div>
    </div>
    <div
      ref="container"
      :class="{ selected: selected }"
      class="contribution"
      @click="selectTheme(theme, contribution.title)"
    >
      <div class="contribution__name">
        {{ contribution.title }}
      </div>
      <div class="contribution__bar">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          height="30"
          :width="size + 30"
        >
          <line
            y1="0"
            y2="30"
            :x1="isPositive? 0.5 : (size + 29.5)"
            :x2="isPositive? 0.5 : (size + 29.5)"
          />
          <rect
            :class="{ [color]: true }"
            :height="20"
            :width="width"
            :x="xOffset"
            :y="5"
            rx="0"
            ry="0"
          />
          <text
            :class="{ [color]: true }"
            :x="isPositive ? width + 5 : xOffset - 5"
            y="20"
          >
            {{ contribution.label }}
          </text>
        </svg>
      </div>
    </div>
  </el-tooltip>
</template>

<script>
import { get } from 'lodash';
import { getAnalysisToolsUIStore } from "stores/RootStore";

import toFixed from 'vue/libs/to-fixed';
export default {
  name: 'Contribution',
  props: {
    contribution: { default: undefined, type: Object },
    currentLabel: { default: undefined, type: String },
    currentTotal: { default: undefined, type: Number },
    maxValue: { default: 0, type: Number },
    minValue: { default: 0, type: Number },
    previousLabel: { default: undefined, type: String },
    previousTotal: { default: undefined, type: Number },
    selected: { default: false, type: Boolean }
  },
  data() {
    return { size: 180, analysisToolsUIStore: getAnalysisToolsUIStore(), };
  },
  computed: {
    theme() { return this.analysisToolsUIStore.selectedThemeName; },
    count() {
      return this.percentage(this.contribution.count, this.currentTotal);
    },
    color() {
      return this.isPositive ? 'positive' : 'negative';
    },
    isPositive() {
      const val = get(this, 'contribution.changeWeighting');

      return val >= 0;
    },
    previousCount() {
      return this.percentage(
        this.contribution.previousCount,
        this.previousTotal
      );
    },
    previousScore() {
      const val = get(this.contribution, 'previousScore.score');
      return toFixed(val, 2);
    },
    score() {
      const val = get(this.contribution, 'score.score');
      return toFixed(val, 2);
    },
    xOffset() {
      const { range, size, value, minValue } = this;

      if (value < 0) {
        return (size * (value - minValue)) / range + 30;
      } else if (minValue < 0) {
        return Math.abs((size * minValue) / range);
      } else {
        return 0;
      }
    },
    width() {
      const { range, size, w } = this;

      return Math.abs((size * w) / range);
    },
    w() {
      const { maxValue, minValue, value } = this;
      if (value < 0) {
        if (maxValue < 0) {
          return Math.abs(maxValue - value);
        } else {
          return Math.abs(value);
        }
      } else {
        if (minValue < 0) {
          return value;
        } else {
          return value - minValue;
        }
      }
    },
    value() {
      return get(this, 'contribution.changeWeighting', 0);
    },
    range() {
      const { maxValue, minValue } = this;
      return maxValue - minValue;
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateSize);
    this.updateSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateSize);
  },
  methods: {
    percentage(num, denom) {
      if (denom === 0) {
        return '0%';
      }
      return toFixed(100 * (num / denom), 1, '%');
    },
    selectTheme(theme, subtheme) {
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
    },
    updateSize() {
      const { container } = this.$refs;
      if (!container) {
        return;
      }
      const { width } = container.getBoundingClientRect();
      this.size = width - 22 - 30; // adjust for padding
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../vue/styles/element-variables';
.contribution {
  border: 1px solid $--neutral-100;
  border-radius: $--border-radius-small;
  :not(.selected) {
    cursor: pointer;
  }
  .selected {
    border-color: $--primary-100;
  }
  &__name {
    background: $--neutral-100;
    color: $--neutral-700;
    font-size: 13px;
    line-height: 18px;
    padding: 6px 10px;
    .selected & {
      background: $--primary-100;
      color: $--primary-800;
    }
  }
  &__bar {
    display: flex;
    padding: 5px 10px;
    line {
      stroke: $--neutral-300;
      stroke-width: 1;
    }
    rect {
      &.negative {
        fill: $--red-400;
      }
      &.positive {
        fill: $--green-400;
      }
    }
    text {
      fill: $--neutral-700;
      font-size: 13px;
      &.negative {
        text-anchor: end;
      }
      &.positive {
        text-anchor: start;
      }
    }
  }
}
</style>

