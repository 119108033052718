<template>
  <div class="filter">
    <div class="header">
      {{ filterData.name }}
    </div>
    <el-date-picker
      ref="datePicker"
      v-model="model"
      :picker-options="pickerOptions"
      :clearable="clearable"
      :class="filterKey"
      :popper-class="filterKey"
      type="daterange"
      align="left"
      size="small"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      format="d MMM yyyy"
      @change="onChange"
      @focus="setCalendarSelect"
    />
  </div>
</template>


<script>
import { get, find, isEmpty } from 'lodash';
// import and extend moment to support ranges
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import {
  daysInDateRange,
  applyOffsetToDate,
  dateStringToUtc,
  utcToDateString,
  defaultDateFromResolution,
  expandEndToCompleteResolution
} from 'lib/date';
import queryBuilder from 'vue/libs/queryBuilder';
import { matchesRoute } from 'vue/libs/filter-watcher';
import { getFilterStore } from 'stores/RootStore';

export default {
  name: 'FilterDatePicker',
  props: {
    filterKey: { type: String },
    filterData: { type: Object },
    selectedFilters: { type: Object },
    config: { type: Object },
    selectedDates: { type: Array },
    listenToRoute: { type: String, default: undefined }
  },
  data() {
    return {
      model: ['', ''],
      validDateMin: null,
      validDateMax: null,
      lastModel: ['1', '1'],
      smartLabels: {},
      shortcuts: [],
      filterStore: getFilterStore()
    };
  },
  computed: {
    clearable: function() {
      return this.filterKey !== 'baseline';
    },
    isAll() {
      const [min, max] = this.model;
      const { validDateMin, validDateMax } = this;
      return (
        moment(min).isSame(validDateMin, 'day') &&
        moment(max).isSame(validDateMax, 'day')
      );
    },
    pickerOptions: function() {
      // @FIXME side effects in computed property
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      let shortcuts = null;
      const currentDate = moment();
      const yesterday = moment().subtract(1, 'days');
      this.smartLabels = {};
      this.smartLabels[
        this.generateName(currentDate.toDate(), currentDate.toDate())
      ] = 'Today';
      this.smartLabels[
        this.generateName(yesterday.toDate(), yesterday.toDate())
      ] = 'Yesterday';
      // add all the months as smart labels
      let dateToMonth = moment(this.validDateMax);
      while (dateToMonth > this.validDateMin) {
        dateToMonth = dateToMonth.subtract(1, 'months');
        this.smartLabels[
          this.generateName(
            dateToMonth.startOf('month').toDate(),
            dateToMonth.endOf('month').toDate()
          )
        ] = dateToMonth.format('MMM-YYYY');
      }

      if (this.validDateMin) {
        dateToMonth = moment(this.validDateMax);
        // Current partial month labeled as full month
        this.smartLabels[
          this.generateName(
            dateToMonth.startOf('month').toDate(),
            dateToMonth.endOf('month').toDate()
          )
        ] = dateToMonth.format('MMM-YYYY');
        const allDate = [this.validDateMin, this.validDateMax];
        this.smartLabels[this.generateName(allDate[0], allDate[1])] = 'All';
        shortcuts = [];
        const validDates = moment.range(this.validDateMin, this.validDateMax);
        // we have data in the current month: this month
        const thisM = moment()
          .startOf('month')
          .range('month');
        this.addOptionToPickerNoBinding(
          shortcuts,
          validDates,
          thisM,
          'This Month'
        );
        // we have data in the current quarter: this quarter
        const thisQ = moment()
          .startOf('quarter')
          .range('quarter');
        this.addOptionToPickerNoBinding(
          shortcuts,
          validDates,
          thisQ,
          'This Quarter'
        );
        // we have data in the current year: this year
        const thisYear = moment()
          .startOf('year')
          .range('year');
        this.addOptionToPickerNoBinding(
          shortcuts,
          validDates,
          thisYear,
          'This Year'
        );
         // we have data for last 30 days
        const thirtyDaysAgo = moment.range(
          moment(allDate[1]).subtract(1, 'month'),
          moment(allDate[1])
        );
        this.addOptionToPicker(
          shortcuts,
          validDates,
          thirtyDaysAgo,
          'Last ' +
            thirtyDaysAgo.end.diff(thirtyDaysAgo.start, 'days') +
            ' Days'
        );
        // we have data in the past 90 days
        const last90days = moment.range(moment().subtract(90, 'days'), moment());
        this.addOptionToPicker(shortcuts, validDates, last90days, 'Last 90 Days');

        // we have data in the last month: last month
        const lastM = moment()
          .startOf('month')
          .subtract(1, 'month')
          .range('month');
        this.addOptionToPicker(shortcuts, validDates, lastM, 'Last Month');
        // we have data in the last quarter: last quarter
        const lastQ = moment()
          .startOf('quarter')
          .subtract(1, 'quarter')
          .range('quarter');
        this.addOptionToPickerNoBinding(
          shortcuts,
          validDates,
          lastQ,
          'Last Quarter'
        );
        // we have data last year: last year
        const lastYear = moment()
          .startOf('year')
          .subtract(1, 'year')
          .range('year');
        this.addOptionToPicker(shortcuts, validDates, lastYear, 'Last Year');
        // All
        this.addOptionToPicker(shortcuts, validDates, validDates, 'All');
      }
      this.$emit('shortcutsCreated', this.filterData.id, this.shortcuts);
      return {
        disabledDate: date => {
          if (
            date.getTime() > this.validDateMax.getTime() ||
            date.getTime() < this.validDateMin.getTime()
          ) {
            return true;
          }
          return false;
        },
        shortcuts: shortcuts
      };
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.onRouteChange(to);
      }
    },
    selectedDates: {
      handler(selectedDates) {
        // this is so selected filters take precedence and there is no
        // race condition when both selectedFilters & selectedDates are supplied
        if (!this.selectedFilters && selectedDates && selectedDates.length) {
          const [ start, end ] = selectedDates;
          this.updateDate(start, end);
        }
      }
    },
    selectedFilters: {
      handler(newFilter) {
        this.mapDataToID(this.filterKey, newFilter);
      }
    },
    filterData: {
      handler() {
        this.setDefaultSelect();
        this.onRouteChange(this.$route);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const pickerMounter = this.$refs.datePicker.mountPicker;
    this.$refs.datePicker.mountPicker = () => {
      // Date picker.picker doesn't exist until the modal is opened
      // Need to override mount to gain access to the picker
      pickerMounter.call(this.$refs.datePicker);
      //On inital load it needs to be moved to the end also
      this.$nextTick(() => {
        const dateEnd = moment(this.model[1]);
        this.$refs.datePicker.picker.leftDate = dateEnd
          .subtract(1, 'months')
          .toDate();
        this.$refs.datePicker.picker.rightDate = this.model[1];
      });
      this.$refs.datePicker.picker.$on('pick', val => {
        this.$nextTick(() => {
          // After the Date pickers manipulation
          // Set the pickers focus to the end of the date range selected
          const dateEnd = moment(val[1]);
          this.$refs.datePicker.picker.leftDate = dateEnd
            .subtract(1, 'months')
            .toDate();
          this.$refs.datePicker.picker.rightDate = val[1];
        });
      });
    };
    this.updateFilterSelection(false);
  },
  methods: {
    open: function() {
      this.$refs.datePicker.handleClickIcon();
    },
    onChange: function() {
      if (!this.model) {
        if (this.filterKey != 'baseline') {
          //Anything other than baseline
          this.lastModel = ['1', '1'];
          this.mapDataToID('baseline', this.filters);
        }
        return;
      }
      if (
        utcToDateString(this.model[0]) === utcToDateString(this.lastModel[0]) &&
        utcToDateString(this.model[1]) === utcToDateString(this.lastModel[1])
      ) {
        return;
      }
      this.updateFilterSelection(true);
      this.lastModel = this.model;
    },
    onRouteChange(to) {
      if (!to) {
        return;
      }
      const { filterKey } = this;
      // default to empty array for destructuring
      let range;
      if( this.listenToRoute ) {
        range = get(to, `query.${this.listenToRoute}`);
      } else {
        [range] = matchesRoute(to, filterKey,  'date_range') || [];
      }
      if (range) {
        const [start, end] = range.split(':', 2);
        this.updateDate(start, end);
      }
    },
    updateDate(startDate, endDate, name) {
      const range = [dateStringToUtc(startDate), dateStringToUtc(endDate)];
      const [start, end] = range;
      if (!name || name !== this.generateName(start, end)) {
        this.updateFilterSelection(false);
      }
      this.model = range;
      this.onChange();
    },
    updateFilterSelection: function(isExplicitSelection) {
      if (isEmpty(this.smartLabels)) {
        this.pickerOptions;
      }
      const queryFilter = {
        filterKey: this.filterKey,
        filterId: this.filterData.id,
        column: this.filterData.column,
        selected: []
      };
      if (this.model[0] && this.model[1]) {
        queryFilter.selected =[
          {
            name: this.generateName(this.model[0], this.model[1]),
            startDate: utcToDateString(this.model[0]),
            endDate: utcToDateString(this.model[1]),
            rql: this.generateRql()
          }
        ]
      }
      this.$emit('selectionChanged', queryFilter, isExplicitSelection);
    },
    generateName: function(startDate, endDate) {
      const str =
        this.dateToString(startDate) + ' – ' + this.dateToString(endDate);
      if (str in this.smartLabels) {
        return this.smartLabels[str];
      }
      return str;
    },
    dateToString: function(date) {
      return moment(date).format('D MMM YYYY');
    },
    generateRql: function() {
      if (this.isAll) {
        return '';
      } else {
        return queryBuilder.buildDateQuery(
          this.filterData.id,
          this.model[0],
          this.model[1]
        );
      }
    },
    setDefaultSelect: function() {
      if (!this.filterStore.setInitialDateFilter) {
        return;
      }

      let startDate = dateStringToUtc(this.filterData.date_start);
      let endDate = dateStringToUtc(this.filterData.date_end);
      this.validDateMin = dateStringToUtc(this.filterData.date_start);
      this.validDateMax = dateStringToUtc(this.filterData.date_end);

      // handle the selection of 'resolution'
      const resolution = get(this.filterData, 'resolution', null);
      const defaultRange = get(this.filterData, 'default_range', 'monthly');
      const daysInRange = daysInDateRange(startDate, endDate);
      if (resolution) {
        let resolutionBounds = { start: startDate, end: endDate };

        if (this.filterData.initial_offset) {
          resolutionBounds = applyOffsetToDate(resolutionBounds, resolution, this.filterData.initial_offset)
        }
        resolutionBounds = expandEndToCompleteResolution(
          resolutionBounds,
          resolution
        );
        startDate = resolutionBounds.start;
        endDate = resolutionBounds.end;
      } else if (defaultRange !== 'all' && daysInRange > 180) { // limiting dates only applies if the distance is greater than 180 days
        const defaultArray = defaultDateFromResolution(
          startDate,
          endDate,
          defaultRange
        );
        [startDate, endDate] = defaultArray;
      }

      this.model = [startDate, endDate];
      this.pickerOptions;
      this.updateFilterSelection(false);
    },
    mapDataToID(filterKey, data) {
      // Flip full data to rql list
      const key = `${filterKey}.${this.filterData.id}.selected`;
      const selections = get(data, key);

      // find first item to have startDate/endDate
      const selected = find(selections, s => s.startDate && s.endDate);
      if (selected) {
        const { name, startDate, endDate } = selected;
        const range = [dateStringToUtc(startDate), dateStringToUtc(endDate)];
        const [start, end] = range;
        if (!name || name !== this.generateName(start, end)) {
          this.updateFilterSelection(false);
        }
        this.model = range;
        this.onChange();
        return;
      }
      this.setDefaultSelect();
    },
    addOptionToPicker(
      shortcuts,
      validDates,
      thisDateRange,
      name,
      skipIntersect
    ) {
      if (thisDateRange.overlaps(validDates)) {
        if (!skipIntersect) {
          thisDateRange = thisDateRange.intersect(validDates);
        }
        const thisDateRangeAsDates = [
          thisDateRange.start.toDate(),
          thisDateRange.end.toDate()
        ];
        this.smartLabels[this.generateName(...thisDateRangeAsDates)] = name;
        this.shortcuts.push({
          text: name,
          dateRange: [
            thisDateRange.start.format('YYYY-MM-DD'),
            thisDateRange.end.format('YYYY-MM-DD')
          ]
        });
        shortcuts.push({
          text: name,
          onClick(picker) {
            picker.$emit('pick', thisDateRangeAsDates);
          }
        });
      }
    },
    addOptionToPickerNoBinding(shortcuts, validDates, thisDateRange, name) {
      this.addOptionToPicker(shortcuts, validDates, thisDateRange, name, true);
    },
    setCalendarSelect() {
      if (this.filterStore.setInitialDateFilter) {
        return;
      }

      this.filterStore.updateSetInitialDateFilter(true);
      this.setDefaultSelect();
    },
  }
};
</script>
<style lang="scss">
@import '../../styles/element-variables.scss';
.filter {
  text-align: left;
}
.el-picker-panel__sidebar {
  width: 120px !important;
}
.el-date-range-picker__header div {
  font-family: 'Muli', sans-serif;
  font-weight: $--font-weight-semi;
  font-size: 14px;
  vertical-align: middle;
}
.el-picker-panel .el-picker-panel__icon-btn {
  font-weight: $--font-weight-semi;
  font-size: 12px;
}
.el-date-table td.in-range:hover {
  color: white;
}
.el-picker-panel *[slot='sidebar'],
.el-picker-panel__sidebar {
  width: 200px;
}
.comparison .el-date-table {
  td {
    &.available:hover {
      color: $--color-comparison;
    }
    &.in-range div {
      background-color: $--color-comparison-light;
      &:hover {
        color: white;
      }
    }
    &.end-date div span,
    &.start-date div span {
      background-color: $--color-comparison;
    }
  }
}
.baseline .el-date-table {
  td {
    &.in-range div {
      &:hover {
        color: white;
        background-color: $--color-baseline-light;
      }
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.el-range-separator {
  overflow: visible;
  white-space: nowrap;
  z-index: var(--z-index-filter-date-picker-dashboard);
}
.el-date-editor ::v-deep .el-range__close-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.el-date-editor {
  height: 42px !important;
  width: 100% !important;
  &.baseline {
    border-color: $--color-baseline;
    .el-range__close-icon {
      display: none;
    }
    &:hover {
      border-color: $--color-baseline;
    }
    &.is-active {
      &:hover {
        border-color: $--color-baseline;
      }
    }
  }
  &.comparison {
    border-color: $--color-comparison;
    &:focus,
    :hover {
      border-color: $--color-comparison;
    }
    &.is-active {
      &:hover {
        border-color: $--color-comparison;
      }
    }
  }
}
.date-picker ::v-deep .el-icon-date {
  margin-right: 4px;
}
.date-picker ::v-deep .el-range-input {
  background-color: transparent;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  line-height: 20px;
  min-width: 0;
}
.date-picker ::v-deep .el-range-separator {
  width: 30px;
  margin: auto;
  line-height: inherit;
}
</style>
