<template>
  <div class="select-phrase">
    <el-dialog
      :visible="dialogVisible && !preSelectedPhrase.length"
      width="50%"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="title">
        Select phrase to tag with <span class="theme-name">{{ title }}</span>
      </div>
      <div class="description">
        Select up to {{ maxWords }} words
      </div>
      <el-alert
        v-if="error.length > 0"
        :title="error"
        type="error"
        :closable="false"
      />
      <div
        class="content"
        @mousedown="(e) => onTextSelectionStart(e)"
        @mouseup="(e) => onTextSelectionEnd(e)"
      >
        <span
          v-for="(block) in plainBlocks"
          :key="block.key"
        >
          <span
            @mouseenter="onEnterBlock(block)"
          >{{ block.content }}</span>
        </span>

        <ul
          v-for="(threadBlockPairs, pairsIndex) in threadBlocksList"
          :key="`pair-${pairsIndex}`"
          class="thread"
        >
          <li
            v-for="threadBlockPair in threadBlockPairs"
            :key="threadBlockPair.meta.id"
          >
            <div class="author">
              <font-awesome-icon icon="user" />
              <span class="author-text">{{ threadBlockPair.meta.author }}</span>
            </div>
            <span
              v-for="block in threadBlockPair.blocks"
              :key="block.key"
              @mouseenter="onEnterBlock(block)"
            >
              {{ block.content }}
            </span>
          </li>
        </ul>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancel-button"
          @click="onCancel"
        >Cancel</el-button>
        <el-button
          type="primary"
          @click="onConfirm"
        >
          Next
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="dialogVisible && !!preSelectedPhrase.length && error !== ''"
      custom-class="selected-phrase-error-dialog"
      width="40%"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="selected-phrase-error-content">
        <div class="icon">
          <font-awesome-icon
            icon="exclamation-triangle"
          />
        </div>
        <div>
          <div class="title">
            {{ error }}
          </div>
          <div class="description">
            {{ errorDescription }}
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="selected-phrase-error-dialog-footer"
      >
        <el-button
          type="primary"
          @click="onCancel"
        >
          OK
        </el-button>
      </span>
    </el-dialog>
    <show-comments
      v-if="shouldShowComments && !canSeeQuickPhraseSelection"
      :selected-phrases="[preSelectedPhrase || selectedPhrase]"
      :selected-theme="selectedTheme"
      :is-phrase-pre-selected="isPhrasePreSelected"
      :comment-id="commentId"
      @onAction="onAction"
      @onBack="onBack"
    />
    <add-theme-example-comments
      v-if="shouldShowComments && canSeeQuickPhraseSelection"
      :selected-phrases="[preSelectedPhrase || selectedPhrase]"
      :selected-theme="selectedTheme"
      :is-phrase-pre-selected="isPhrasePreSelected"
      :comment-id="commentId"
      @onAction="onAction"
      @onBack="onBack"
    />
  </div>
</template>

<script>
import segmentsToBlocks from "lib/segments-to-blocks.ts";
import ShowComments from './ShowComments.vue';
import AddThemeExampleComments from './AddThemeExampleComments.vue';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import AddThemeToSelectionMixin from 'vue/components/Comments/AddThemeToSelectionMixin.js';

export default {
  name: 'SelectPhrase',
  components: { ShowComments, AddThemeExampleComments },
  mixins: [
    AddThemeToSelectionMixin()
  ],
  props: {
    plainBlocks: { type: [Array, null] },
    conversations: { type: [Array, null] },
    selectedTheme: {default: () => ({}), type: Object},
    preSelectedPhrase: {default: '', type: String},
    commentId: { default: '', type: [String, Number] },
  },
  data() {
    return {
      dialogVisible: !!this.selectedTheme,
      shouldShowComments: !!this.preSelectedPhrase,
      error: '',
      errorDescription: '',
      isPhrasePreSelected: !!this.preSelectedPhrase,
    };
  },
  computed: {
    title() {
      if (this.selectedTheme.subTitle) {
        return `${this.selectedTheme.baseTitle}: ${this.selectedTheme.subTitle}`;
      } else {
        return this.selectedTheme.baseTitle;
      }
    },
    threadBlocksList() {

      if (!this.conversations) {
        return null;
      }

      return this.conversations.map(
        conv => conv.pairs.flatMap(pairs =>
          pairs.map(pair => {
            return {
              meta: pair.meta,
              blocks: segmentsToBlocks(
                pair.threadedComment.text,
                pair.threadedComment.segments,
                pair.meta.partIndex,
                []
              )
            };
          })
        )
      );

    },
    canSeeQuickPhraseSelection() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_QUICK_PHRASE_SELECTION);
    },
  },
  mounted() {
    if (this.preSelectedPhrase.length) {
      // Validity of the preselectedphrase is assured upstream in the flow
      this.selectedPhrase = this.preSelectedPhrase;
      this.selectionStatus = 'VALID';
      this.checkValidity();
    }
  },
  methods: {
    onConfirm() {
      this.checkValidity();
    },
    checkValidity() {
      switch(this.selectionStatus) {
        case 'NONE': {
          this.error = 'Please select a phrase to continue.';
          this.errorDescription = 'Please select a phrase to continue.';
          break;
        }
        case 'INVALID_CROSSES_BOUNDARY': {
          this.error = 'Please select text within a single sentence.';
          this.errorDescription = 'Please select text that does not cross a sentence boundary,'
          break;
        }
        case 'INVALID_TOO_MANY_WORDS': {
          this.error = `Please select ${this.maxWords} words or fewer.`;
          this.errorDescription = `Please select ${this.maxWords} words or fewer,`
          break;
        }
        default: {
          this.error = '';
          this.errorDescription = '';
          this.dialogVisible = false;
          this.shouldShowComments = true;
          break;
        }
      }
    },
    onCancel() {
      this.dialogVisible = false;
      this.onAction();
    },
    onBack() {
      if(this.isPhrasePreSelected) {
        this.onCancel();
      } else {
        this.selectedPhrase = '';
        this.shouldShowComments = false;
        this.dialogVisible = true;
      }
    },
    onAction() {
      this.$emit('onAction');
    },
    isWithinASentence(selectedText) {
      // checking if the selected text is not crossing sentence boundary
      return selectedText.includes('.');
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.select-phrase ::v-deep .el-dialog__header {
  padding: 0;
}
.select-phrase {
  .title {
    font-size: 16px;
    color: $--color-text-primary;
    padding-bottom: 15px;
    line-height: 22px;
    font-weight: 600;
    .theme-name {
      align-items: center;
      border: none;
      color: $--neutral-700;
      line-height: 1;
      border-radius: 10em;
      padding: 4px 8px;
      background: $--neutral-100;
      height: 22px;
    }
  }
  .description {
    font-size: 14px;
    color: $--neutral-900;
    font-weight: 400;
    padding-bottom: 10px;
  }
  .content {
    font-size: 14px;
    background: $--neutral-100;
    border-radius: 4px;
    margin-top: 10px;
    padding: 12px;
    overflow-y: auto;
    max-height: 400px;
    line-height: 1.6em;

    ul + ul {
      border-top: 1px solid $--neutral-200;
    }
    ul {
      margin: 0;
      padding: 0.5rem;
    }
  }

  .thread {
    &:first-child {
      .author {
        padding-top: 0;
      }
    }
  }
  .author {
    padding: 0.5rem 0;
    .author-text {
      font-weight: 600;
      padding-left: 2px;
    }
  }

  .selected-phrase-error-dialog {
    .selected-phrase-error-content {
      display: flex;
      flex-direction: row;
      .icon {
        color: $--orange-500;
        font-size: 42px;
        padding-right: 16px;
      }
      .title {
        font-size: 16px;
        color: $--color-text-primary;
        padding: 5px 0 7px 0;
        line-height: 22px;
        font-weight: 600;
      }
      .description {
        font-size: 14px;
        color: $--neutral-900;
        font-weight: 400;
        padding-bottom: 10px;
      }
    }
  }
  ::v-deep .el-dialog__footer {
    padding-top: 15px;
    background-color: $--neutral-100;
    .dialog-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .cancel-button {
        padding: 0;
        background: transparent;
        border: none;
        color: $--color-primary;
      }
    }
  }

}
</style>
