import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { getThemesStore } from 'stores/RootStore';
import { InlineNotification } from 'components/Shared/InlineNotification';
import { Loader } from 'semantic-ui-react';
import './theme-editor-comments.scss';

interface UntaggedExampleCommentsProps {
  comments: string[];
  processing: boolean;
  processError: boolean;
}

const UntaggedExampleComments = observer(({
  comments,
  processing,
  processError,
}: UntaggedExampleCommentsProps) => {
  const { exampleCommentsError, exampleCommentsLoading } = getThemesStore();

  if (exampleCommentsError || processError) {
    return (
      <InlineNotification
        isHighlighted={true}
        title={exampleCommentsError ? 'Unable to fetch example comments' : 'Unable to process comments'}
        type="error"
      />
    );
  }

  if (exampleCommentsLoading || processing) {
    return (
      <Loader inline="centered" size="small" active={true}>
        {processing ? 'Processing comments' : 'Loading comments'}
      </Loader>
    )
  }

  const sortedComments = comments.sort((a, b) => b.length - a.length);

  return (
    <ul className="theme-editor-comments">
      {sortedComments.map((comment) => (
        <li className="theme-editor-comments__item">
          {comment}
        </li>
      ))}
    </ul>
  );
});

export { UntaggedExampleComments };
