import * as React from 'react';
import * as d3 from 'd3';
import './spark-line.scss';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { useResizeObserver } from 'hooks/useResizeObserver';

const MARGIN_TOP = 5;
const MARGIN_BOTTOM = 5;
const MARGIN_LEFT = 5;
const MARGIN_RIGHT = 5;

interface Props {
  values: number[];
}

const SparkLine = withCamelCaseProps(({ values }: Props) => {
  const [containerRef, dimensions] = useResizeObserver<HTMLDivElement>();
  const sparkLineRef = React.useRef<SVGSVGElement>(null);

  const low = Math.min(...values);
  const high = Math.max(...values);

  const renderSvg = React.useCallback(({ width, height }) => {
    if (!containerRef.current || !sparkLineRef.current || values.length === 0) {
      return;
    }

    d3.select(sparkLineRef.current).selectAll("*").remove();

    // Dimensions
    const margin = { top: MARGIN_TOP, right: MARGIN_RIGHT, bottom: MARGIN_BOTTOM, left: MARGIN_LEFT };
    const svgWidth = width - margin.left - margin.right;
    const svgHeight = height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3.select(sparkLineRef.current)
      .attr('width', svgWidth + margin.left + margin.right)
      .attr('height', svgHeight + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // X Scale
    const x = d3.scaleLinear()
      .domain([0, values.length - 1])
      .range([0, svgWidth]);

    // Y Scale
    const y = d3.scaleLinear()
      .domain([low, high])
      .range([svgHeight, 0]);

    // Line
    const line = d3.line()
      .x((_d: unknown, i: number) => x(i))
      .y((d: unknown) => y(d))
      .curve(d3.curveMonotoneX); // curve line

    // Draw Line
    svg.append('path')
      .datum(values)
      .attr('fill', 'none')
      .attr('stroke', 'var(--primary-500)')
      .attr('stroke-width', 2)
      .attr('d', line);
  }, [containerRef, values, high, low]);

  React.useEffect(() => {
    renderSvg(dimensions);
  }, [dimensions, renderSvg]);

  return (
    <div ref={containerRef} className="spark-line-container">
      <svg ref={sparkLineRef} />
    </div>
  );
});

export { SparkLine };
