import { Cluster } from 'api/interfaces';
import { reduce, sortBy } from 'lodash';
import { PlainComment, Thread } from 'types/custom';

// An intermediate type
type IntCluster<C extends PlainComment | Thread> = {
  name: string,
  sentiment: number,
  size: number,
  sentences: Cluster<C>[keyof Cluster<C>],
};

export type RefinedCluster<C extends PlainComment | Thread> = {
  name: string,
  sentiment: number,
  size: number,
  sentences: Array<[
    snippet: string,
    sentiment: number,
    comments: [ comment: C ],
    showFullComments: boolean
  ]>

};

export function refineClusters<C extends PlainComment | Thread>(
  clusters: Cluster<C>
): Array<RefinedCluster<C>> {

  const struct1: Array<IntCluster<C>> = Object.keys(clusters).map((key: string): IntCluster<C> => {

    const val = clusters[key];

    const sentiment = reduce(val, (result, sentences) => result + sentences[1], 0.0, ) / Math.max(1, val.length);

    return {
      name: key,
      sentiment: sentiment,
      size: clusters[key].length,
      sentences: val,
    };
  });

  const otherCluster: RefinedCluster<C> = {
    name: 'OTHER SENTENCES',
    sentiment: 0,
    size: 0,
    sentences: [],
  };

  const struct2 = sortBy(struct1, (cluster) => {
    return -cluster.sentences.length;
  });

  const struct3 = reduce(struct2, (
    result: Array<RefinedCluster<C>>,
    cluster: IntCluster<C>
  ): Array<RefinedCluster<C>> => {

    const sentences = reduce(cluster.sentences, (
      sentenceResult: RefinedCluster<C>['sentences'],
      sentence: IntCluster<C>['sentences'][0]
    ): RefinedCluster<C>['sentences'] => {

      // if some have been accumulated, and the last one has the same snippet as this
      const snippet: string = sentence[0];
      const sentiment: number = sentence[1];
      const comment = sentence[2];

      const lastResult = sentenceResult[sentenceResult.length - 1];
      const lastSnippet: string|null = lastResult && lastResult[0];

      if (lastSnippet === snippet) {
        lastResult[2].push(sentence[2]);
      } else {
        sentenceResult.push([
          snippet,
          sentiment,
          [comment],
          false // used for tracking whether full comments are shown
        ]);
      }
      return sentenceResult;
    }, []);

    if (sentences.length === 1) {

      otherCluster.sentences = [...otherCluster.sentences, ...sentences];

    } else {

      const nextSentences = sortBy(sentences, (s) => {
        return -s[2].length;
      });

      result.push({
        ...cluster,
        sentences: nextSentences
      });

    }
    return result;
  }, []);

  // other cluster always goes at the end
  otherCluster.size = otherCluster.sentences.length;

  if (otherCluster.size > 0) {
    struct3.push(otherCluster);
  }

  return struct3;
}
