


























import Moment from 'moment';
import { getAnalysisConfigStore } from 'stores/RootStore';

export default {
  name: 'CommentMetadataList',
  components: {},
  props: {
    info: { default: () => ({}), type: Object },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
    };
  },
  computed: {
    /**
     * NOTE: this trusts that the info supplied matches the metadataColumns
     */
    metadata() {
      const result = (this.metadataColumns || []).map((metadata) => {
        return {
          key: metadata.name,
          val: this.info[metadata.name],
        };
      });

      return result;
    },
    metadataColumns () {
      return this.analysisConfigStore.metadataColumns;
    },
  },
  methods: {
    formatItem(item) {
      const metadata = (this.metadataColumns || []).find((m) => m.name === item.key);
      // if the item is a date, we will format it nicely as a date
      if ((metadata && metadata.isDate) || item.key.toLowerCase() === 'date') {
        const format = metadata && metadata.dateFormat ? metadata.dateFormat : 'Do MMM YYYY';
        const value = Moment(item.val);
        return value.format(format);
      }
      else if (metadata && metadata.template) {
        return metadata.template.replace('{value}', item.val);
      } else if (metadata && metadata.mapping) {
        return metadata.mapping[String(item.val)] || item.val;
      }
      return item.val;
    },
    getItemIsUrl(item) {
      const metadata = (this.metadataColumns || []).find((m) => m.name === item.key);
      if (!metadata) {
        return;
      }
      return metadata.isUrl;
    },
  }
};

