import { MetricsResolution, SurveyStatus } from 'api/enums';
import { Metrics, SurveyMetrics } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { isEmpty, sortBy } from 'lodash';
import { action, observable } from 'mobx';
import * as moment from 'moment';
import { stringify } from 'query-string';

export interface MetricsQueryParams {
  organization: string;
  includeSurveyMetrics?: boolean;
  includeUserMetrics?: boolean;
  resolution?: MetricsResolution;
  numPeriods?: number;
  startDate: string;
  endDate: string;
}

export interface DataUsageMetric {
  label: string;
  rowCount: number;
  themedColumnCount: number;
  cumulativeTranslatedCount: number;
  cumulativeVerbatimCount: number;
  incrementalTranslatedCount: number;
  incrementalVerbatimCount: number;
  start: string;
  end: string;
}

export interface DataUsageMetrics {
  metrics: DataUsageMetric[];
  survey_id: number;
  survey_name: string;
  survey_order: number;
  survey_status: SurveyStatus;
}

export interface MetricsStoreInterface {
  metrics: { [key: string]: Metrics }; // Metrics for each workspace
  surveyMetrics: { [key: string]: DataUsageMetrics[] }; // Tranformed survey metrics for each workspace
  startDate?: string;
  endDate?: string;

  fetchMetricsError: string;

  fetchMetrics: (queryParams: MetricsQueryParams, reset?: boolean)  => Promise<void>;
  reset: () => void;
}

class MetricsStore implements MetricsStoreInterface {
  @observable
  metrics = {};

  @observable
  surveyMetrics = {};

  @observable
  fetchMetricsError = '';

  @observable
  startDate?: string = undefined;

  @observable
  endDate?: string = undefined;

  @action
  fetchMetrics = async (queryParams: MetricsQueryParams, excludeStartMonth?: boolean) => {
    // Get data for the month before selected one so we dont show 0 for first month
    const startDate = moment(queryParams.startDate).subtract(1, 'month').format('YYYY-MM-DD');
    const endDate = moment(queryParams.endDate).format('YYYY-MM-DD');
    const queryParam = stringify({ ...queryParams, startDate, endDate });
    const url = `/organization/metrics?${queryParam}`;
    this.startDate = moment(queryParams.startDate).format('MMM YYYY');
    this.endDate = moment(queryParams.endDate).format('MMM YYYY');

    try {
      const { ok, data, errorData } = await Auth.fetch<Metrics>(url);
      if (ok && data) {
        this.metrics[queryParams.organization] = data;
        this.surveyMetrics[queryParams.organization] = this.getActiveSurveyMetrics(data.surveys, excludeStartMonth);
      } else {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.fetchMetricsError = `Failed to fetch metrics: ${e.message}`;
    }
  }

  /*
    We get metrics for a month before the selected month, so the counts start
    with the actual processed counts for that month instead of starting at 0
    Once we get the counts, we remove the starting month from showing
  */
  getActiveSurveyMetrics = (surveys?: SurveyMetrics[], excludeStartMonth?: boolean): DataUsageMetrics[] => {
    if (!surveys) {
      return [];
    }

    let surveyMetrics = surveys
      // Don't include deleted surveys in metrics
      .filter(survey => survey.survey_status !== SurveyStatus.DELETED)
      .map(survey => {
        if (isEmpty(survey.metrics)) {
          return {
            survey_id: survey.survey_id,
            survey_name: survey.survey_name,
            survey_order: survey.survey_order,
            survey_status: survey.survey_status,
            metrics: []
          };
        }

        let sortedMetrics = sortBy(survey.metrics, (a) => new Date(a.end).getTime());

        sortedMetrics = sortedMetrics
        // Don't show metrics for future date
        .filter(metric => moment().diff(moment.utc(metric.start), 'days') >= 0)
        .map(metric => {
          return {
            ...metric,
            verbatimCount: metric.verbatimCount ? metric.verbatimCount : 0,
            translatedCount: metric.translatedCount ? metric.translatedCount : 0
          };
        });

        const startingVerbatimCount = sortedMetrics[0].verbatimCount;
        const startingTranslatedCount = sortedMetrics[0].translatedCount;

        const metrics = sortedMetrics.map((metric, index) => {
          let cumulativeVerbatimCount = 0;
          let incrementalVerbatimCount = 0;
          let cumulativeTranslatedCount = 0;
          let incrementalTranslatedCount = 0;

          if (index > 0) {
            cumulativeVerbatimCount = metric.verbatimCount - startingVerbatimCount;
            cumulativeTranslatedCount = metric.translatedCount - startingTranslatedCount;
            incrementalVerbatimCount = metric.verbatimCount - sortedMetrics[index - 1].verbatimCount;
            incrementalTranslatedCount = metric.translatedCount - sortedMetrics[index - 1].translatedCount;

            // Sometimes DS team can remove rows resulting in less counts than previous month
            // In this case, we just show the counts as 0 as otherwise it will be negative
            if (cumulativeVerbatimCount < 0) {
              cumulativeVerbatimCount = 0;
            }

            if (cumulativeTranslatedCount < 0) {
              cumulativeTranslatedCount = 0;
            }

            if (incrementalVerbatimCount < 0) {
              incrementalVerbatimCount = 0;
            }

            if (incrementalTranslatedCount < 0) {
              incrementalTranslatedCount = 0;
            }
          }

          return {
            ...metric,
            cumulativeVerbatimCount,
            cumulativeTranslatedCount,
            incrementalVerbatimCount,
            incrementalTranslatedCount
          } as DataUsageMetric;
        });

        if (excludeStartMonth) {
          metrics.shift();
        }
        return {
          survey_id: survey.survey_id,
          survey_name: survey.survey_name,
          survey_order: survey.survey_order,
          survey_status: survey.survey_status,
          metrics
         };
      });
    return surveyMetrics;
  }

  @action
  reset = () => {
    this.metrics = {};
    this.surveyMetrics = {};
    this.fetchMetricsError = '';
  }
}

export default MetricsStore;
