import * as React from 'react';
import './button-group.scss';

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

const ButtonGroup = ({ children, ...rest }: Props) => {
  return (
    <div className="button-group" {...rest}>
      {children}
    </div>
  );
};

export { ButtonGroup };
