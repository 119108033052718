import { Orientation } from 'types/custom';
import { generateBarGraphData, sortBarGraphData } from './bargraphHelpers';
import { ThemeCountsWithSigDiff } from './types';

export function getVisBarGraphOptions(
  baselineCount: number,
  comparisonCount: number,
  baseline: ThemeCountsWithSigDiff,
  comparison: ThemeCountsWithSigDiff,
  orderBy: string,
  showBaselineValues: boolean,
  showComparisonValues: boolean,
  chartWidth: number,
  chartHeight: number,
  onClick: (label: string) => void,
) {
  const data = generateBarGraphData(baseline, comparison, baselineCount, comparisonCount);
  const sortedData = sortBarGraphData(data, orderBy);
  const labels = sortedData.map(item => item.label);

  return {
    orderBy: orderBy,
    onClick,
    chartLabel: 'Percentage of respondents',
    labels,
    data: sortedData,
    showBaselineValues,
    showComparisonValues,
    barChartWidth: chartWidth,
    barChartHeight: chartHeight,
    chartUnit: '%',
    orientation: Orientation.HORIZONTAL,
    showCommentsCountInTooltip: true,
    showVerticalChartLabels: false
  };
}
