
import { WaterfallVisualizationSection } from 'api/interfaces';
import renderWaterfall from 'charts/renderWaterfall';
import classNames from 'classnames';
import * as React from 'react';
import './visualization-waterfall-chart.scss';

interface Props {
  content: WaterfallVisualizationSection;
}
interface State {
  labelOverflow: boolean;
}

const MIN_CHART_WIDTH = 700;

export class VisualizationWaterfallChart extends React.Component<
  Props, State
> {
  visWaterfallContainerRef = React.createRef<HTMLDivElement>();
  state = {
    labelOverflow: false,
  };

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.labelOverflow !== this.state.labelOverflow) {
      this.renderChart();
    }
  }

  setLabelOverflow = (labelOverflow: boolean) => {
    this.setState({ labelOverflow });
  }

  renderChart = () => {
    const { content } = this.props;
    if (!this.visWaterfallContainerRef.current) {
      return;
    }
    const chartContainerWidth = this.visWaterfallContainerRef.current.getBoundingClientRect().width;
    const el = this.visWaterfallContainerRef.current.children[0] as SVGElement;
    const options = {
      data: content.data,
      chartWidth: chartContainerWidth ?? MIN_CHART_WIDTH,
      labelOverflow: this.state.labelOverflow,
      setLabelOverflow: this.setLabelOverflow,
    };

    renderWaterfall.renderWaterfallChart({
      el,
      options
    });
  }

  render() {
    return (
      <div
        ref={this.visWaterfallContainerRef}
        className="visualization-waterfall-chart"
      >
        <svg
          className={classNames({'label-overflow': this.state.labelOverflow})}
        />
      </div>
    );
  }
}
