<template>
  <button
    class="full-conversation-button button-base button-base--medium"
    @click="handleClick"
  >
    Full conversation
    <font-awesome-icon
      class="full-conversation-button__icon"
      icon="arrow-right"
    />
  </button>
</template>

<script>
  export default {
    name: 'FullConversationButton',
    methods: {
      handleClick() {
        this.$emit('click');
      }
    },
  }
</script>

<style>
  .full-conversation-button {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: var(--radius-sm);
    padding: var(--spacing-01) var(--spacing-02);
    font-weight: 600;
    color: var(--neutral-900);
    cursor: pointer;
  }

  .full-conversation-button:hover {
    background-color: var(--primary-100);
  }

  .full-conversation-button__icon {
    margin-left: var(--spacing-02);
    font-size: var(--text-sm);
  }
</style>
