











import { FORM_COMPONENTS } from "stores/ui/ExportInsightUIStore";
import { ExportableInsight } from 'types/custom';

export default {
  name: "InsightConfigForm",
  props: {
    insights: { type: Array, required: true }
  },
  methods: {
    getComponent(insight: ExportableInsight){
      return FORM_COMPONENTS[insight.type] || null;
    }
  }
};

