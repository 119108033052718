import { ThemeCodes } from "stores/ui/AnalysisToolsUIStore";

export default function matches(a: ThemeCodes, b?: ThemeCodes): boolean {
  if (!b) {
    return false;
  }
  if (a.base !== b.base) {
    return false;
  }
  return !b.sub || b.sub === a.sub;
}
