export default function getSubscriptionPreviewStyle(): string {

  return `
/* base  */
@media screen {
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}
body,
h1,
h2,
h3,
td {
  font-family: 'Open Sans', arial, sans-serif;
}
img {
  max-width: 100%;
}
table {
  border-collapse: collapse;
}
body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  background: #f5f6f7;
  box-sizing: border-box;
  color: #546377;
  font-size: 14px;
  height: 100%;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  /*width: 100% !important;*/
}
.body-wrap {
  background: #f5f6f7;
  /*padding: 0 10px;*/
}
.container {
  display: block !important;
  max-width: 600px !important;
  margin: 0 auto !important;
  /* makes it centered */
  clear: both !important;
}
.content {
  max-width: 600px;
  margin: 0 auto;
  display: block;
}
.content-wrap {
  padding: 20px 20px 10px;
}
.content-block {
  padding: 0 0 20px;
}
.content-block p {
  margin: 0 0 10px;
}
.content-block p:last-child {
  margin: 0;
}
.content-inner {
  padding: 0 10px;
}
}
/* -------------------------------------
      LINKS & BUTTONS
  ------------------------------------- */
a {
  color: #009aff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.btn-cell {
  border-radius: 4px;
  background-color: #009aff;
}
.btn-primary {
  text-decoration: none;
  color: #ffffff;
  background-color: #009aff;
  border-color: #009aff;
  border-style: solid;
  border-width: 9px 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  text-transform: capitalize;
}
.btn-primary img {
  font-size: 0;
  height: 16px;
  margin-left: 3px;
  vertical-align: middle;
}
.brand-wrap {
  background: #e7efff;
}
.brand-wrap table {
  margin-top: 0.5rem;
}
.brand-wrap h4:empty {
  display: none;
}
.brand {
  font-weight: 600;
  line-height: 40px;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
}
.brand img {
  display: block;
}
.footer {
  clear: both;
  color: #959eab;
  padding: 0 20px 20px;
}
.footer .support {
  padding: 0 20px 20px;
}
.footer p,
.footer a,
.footer unsubscribe,
.footer td {
  font-size: 12px;
}
.aligncenter {
  text-align: center;
}
/* -------------------------------------
      TYPOGRAPHY
  ------------------------------------- */
h1,
h2,
h3 {
  line-height: 1.2;
}
h1 {
  font-size: 32px;
  font-weight: 600;
}
h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin: 5px 0 10px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 14px;
  font-weight: 600;
}
p,
ul,
ol {
  margin-bottom: 10px;
  font-weight: 400;
}
p li,
ul li,
ol li {
  margin-left: 5px;
  list-style-position: inside;
}
/* -------------------------------------
      RESPONSIVE AND MOBILE FRIENDLY STYLES
  ------------------------------------- */
@media only screen and (max-width: 640px) {
  h1,
  h2,
  h3,
  h4 {
    font-weight: 600 !important;
    margin: 5px 0 !important;
  }
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  .container {
    margin-top: 0 !important;
    width: 100% !important;
  }
}

.btn-cell {
  display: none;
}

/* page */

.title h3 {
  color: #69adff;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  padding: 0 20px;
}
.subtitle {
  text-align: center;
}
.subtitle h3 {
  color: #546377;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  line-height: 36px;
  padding: 0 20px;
}
.subtitle h4 {
  color: #546377;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 14px;
  padding: 20px 20px 0;
}

.widget-cell {
  padding: 10px 4px 0;
}
.widget {
  background: #ffffff;
}
tr.empty-row td {
  height: 20px;
}

.widget thead .widget-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  padding: 5px 10px !important;
  text-transform: uppercase;
}
.widget thead .widget-subtitle {
  border-bottom: 1px solid #e4e6ea;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  padding: 5px 10px 10px !important;
  text-transform: uppercase;
}

.widget thead .widget-name {
  border-bottom: 1px solid #e4e6ea;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
}
.widget thead .widget-col-name {
  border-bottom: 1px solid #e4e6ea;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  text-align: right;
}
.content-inner .widget tr td {
  padding: 10px;
}
.content-inner .widget tr td:first-child,
.content-inner .widget tr td:last-child {
  padding: 0;
}

.takeaway-list {
  padding-left: 0px;
}

.score-widget .scores {
  text-align: center;
}
.score-widget .scores .comparison-scores {
  border-collapse: separate;
  border-spacing: 10px;
}
.score-widget .current-score,
.score-widget .comparison-score {
  border-bottom: 2px solid #009aff;
  font-size: 60px;
  font-weight: 300;
  line-height: 1;
  padding: 10px 10px 0 !important;
  text-align: center;
  vertical-align: bottom;
}
.score-widget .comparison-score {
  border-bottom-color: #ff9e1c;
}
.score-widget .previous-score {
  border-bottom: 2px solid #ff9e1c;
  font-size: 60px;
  line-height: 1;
  padding: 10px 10px 0 !important;
  text-align: center;
  vertical-align: bottom;
}
.score-widget .score-difference {
  font-size: 18px;
  line-height: 1;
  padding: 10px 0 20px !important;
  text-align: center;
}

.score-widget .score-difference img {
  height: 14px;
  padding: 3px;
  vertical-align: top;
}
.score-widget .difference {
  font-size: 14px;
  font-weight: 400;
}
.score-widget .difference {
  height: 14px;
  padding: 3px;
}
.score-widget .positive {
  color: #79b960;
}
.score-widget .negative {
  color: #ee6b6b;
}

.impact-widget tbody tr.double-theme > td {
  padding: 3px 10px;
}
.impact-widget .negative-bars {
  text-align: right;
  padding: 0 10px !important;
}
.impact-widget .comparison-bars {
  padding: 0 10px !important;
}
.impact-widget .baseline-impact-bar-wrapper {
  margin-bottom: 1px;
  vertical-align: bottom;
}
.impact-widget .comparison-impact-bar-wrapper {
  margin-top: 1px;
  vertical-align: top;
}
.impact-widget .baseline-impact-bar-wrapper td {
  height: 14px;
  background: #009aff;
}
.impact-widget .comparison-impact-bar-wrapper td {
  height: 14px;
  background: #ffbb60;
}
.impact-widget .negative-bar {
  padding: 0 10px;
  text-align: right;
}
.positive-bar {
  padding: 0 10px !important;
}
.positive-bar table,
.negative-bar table {
  background: #009aff;
}
.positive-bar table td,
.negative-bar td {
  height: 20px;
}
.impact-widget .theme-name {
  line-height: 16px;
}
.theme-impact {
  font-weight: 600;
  text-align: right;
}
.theme-impact td {
  text-align: right;
}
.theme-impact.changes .positive {
  color: #79b960;
}
.theme-impact.changes .negative {
  color: #ee6b6b;
}
.theme-impact.previous,
.theme-impact.current {
  font-weight: normal;
}
.changes-widget .this-period-legend,
.impact-widget .this-period-legend,
.over-time-widget .this-period-legend {
  background-color: #009aff;
  height: 8px;
  padding: 0 !important;
  width: 20px;
}
.changes-widget .last-period-legend,
.impact-widget .last-period-legend,
.over-time-widget .last-period-legend {
  background-color: #ffbb60;
  height: 8px;
  padding: 0 !important;
  width: 20px;
}
.changes-widget tbody tr.theme td {
  padding: 3px 10px;
}
.no-changes {
  text-align: center;
}
.changes-widget .last-period-name,
.changes-widget .this-period-name,
.impact-widget .last-period-name,
.impact-widget .this-period-name,
.over-time-widget .last-period-name,
.over-time-widget .this-period-name {
  font-size: 12px;
  font-weight: 600;
  padding: 0 25px 0 8px !important;
  text-transform: uppercase;
}
.changes-widget .this-period-bar-wrapper {
  padding: 1px 10px !important;
  vertical-align: bottom;
}
.changes-widget .last-period-bar-wrapper {
  padding: 1px 10px !important;
  vertical-align: top;
}
.changes-widget .this-period-bar-wrapper {
  background: #009aff;
  margin-bottom: 1px;
}
.changes-widget .this-period-bar-wrapper td {
  height: 14px;
}

.changes-widget .last-period-bar-wrapper {
  background: #ffbb60;
  margin-top: 1px;
}
.changes-widget .last-period-bar-wrapper td {
  height: 14px;
}

.subscribe-block {
  width: 100%;
}
.subscribe-block td {
  padding: 20px 0 0;
  font-weight: 600;
}
.spacious-block {
  padding: 0 0 30px;
}
.panel-title {
  background: #ff9e1c;
  border-style: solid;
  border-color: #ff9e1c;
  border-width: 16px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-top: -20px;
  padding: 0 26px;
}

.report-content-wrap {
  padding: 0 20px 10px;
}

.over-time-widget .over-time-bar-container {
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  vertical-align: bottom;
}

.over-time-widget .over-time-bar-comparison-container {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  text-align: center;
  vertical-align: bottom;
}

.over-time-widget .over-time-score {
  font-weight: 600;
  text-align: center;
}

.over-time-widget .over-time-bar {
  background: #009aff;
  width: 60px;
}

.over-time-widget .over-time-bar-baseline {
  background: #009aff;
  width: 30px;
}

.over-time-widget .over-time-bar-comparison {
  background: #ffbb60;
  width: 30px;
}

.over-time-widget .date-label {
  color: #748091;
  font-size: 11px;
  padding: 5px 0 25px !important;
  text-align: center;
}
.theme-group-name {
  font-weight: 600;
}
.theme-group {
  margin-right: 15px;
}
.group-impacts td {
  padding-bottom: 5px !important;
  padding-top: 0 !important;
}
.summary--empty img {
  margin-top: 2px;
  margin-right: 5px;
  float: left;
}

/* base  */
@media screen {
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}
body,
h1,
h2,
h3,
td {
  font-family: 'Open Sans', arial, sans-serif;
}
img {
  max-width: 100%;
}
table {
  border-collapse: collapse;
}
body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  background: #f5f6f7;
  box-sizing: border-box;
  color: #546377;
  font-size: 14px;
  height: 100%;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  /*width: 100% !important;*/
}
.body-wrap {
  background: #f5f6f7;
  /*width: 100%;*/
}
.container {
  display: block !important;
  max-width: 600px !important;
  margin: 0 auto !important;
  /* makes it centered */
  clear: both !important;
}
.content {
  max-width: 600px;
  margin: 0 auto;
  display: block;
}
.content-wrap {
  padding: 20px 20px 10px;
}
.content-block {
  padding: 0 0 20px;
}
.content-block p {
  margin: 0 0 10px;
}
.content-block p:last-child {
  margin: 0;
}
/* -------------------------------------
      LINKS & BUTTONS
  ------------------------------------- */
a {
  color: #009aff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.btn-cell {
  border-radius: 4px;
  background-color: #009aff;
}
.btn-primary {
  text-decoration: none;
  color: #ffffff;
  background-color: #009aff;
  border-color: #009aff;
  border-style: solid;
  border-width: 9px 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  text-transform: capitalize;
}
.btn-primary img {
  font-size: 0;
  height: 16px;
  margin-left: 3px;
  vertical-align: middle;
}
.brand-wrap {
  background: #e7efff;
}
.brand-wrap table {
  margin-top: 0.5rem;
}
.brand-wrap h4:empty {
  display: none;
}
.brand {
  font-weight: 600;
  line-height: 40px;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
}
.brand img {
  display: block;
}
.footer {
  clear: both;
  color: #959eab;
  padding: 0 20px 20px;
}
.footer .support {
  padding: 0 20px 20px;
}
.footer p,
.footer a,
.footer unsubscribe,
.footer td {
  font-size: 12px;
}
.aligncenter {
  text-align: center;
}
/* -------------------------------------
      TYPOGRAPHY
  ------------------------------------- */
h1,
h2,
h3 {
  line-height: 1.2;
}
h1 {
  font-size: 32px;
  font-weight: 600;
}
h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin: 5px 0 10px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 14px;
  font-weight: 600;
}
p,
ul,
ol {
  margin-bottom: 10px;
  font-weight: 400;
}
p li,
ul li,
ol li {
  margin-left: 5px;
  list-style-position: inside;
}
/* -------------------------------------
      RESPONSIVE AND MOBILE FRIENDLY STYLES
  ------------------------------------- */
@media only screen and (max-width: 640px) {
  h1,
  h2,
  h3,
  h4 {
    font-weight: 600 !important;
    margin: 5px 0 !important;
  }
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  .container {
    margin-top: 0 !important;
    width: 100% !important;
  }
}

.btn-cell {
  display: none;
}

/* page */
.subtitle h2 {
  color: #69adff;
}

.title h3 {
  color: #69adff;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  padding: 0 20px;
}
.subtitle {
  text-align: center;
}
.subtitle h3 {
  color: #546377;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  line-height: 36px;
  padding: 0 20px;
}
.subtitle h4 {
  color: #546377;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 14px;
  padding: 20px 20px 0;
}

.widget-cell {
  padding: 10px 4px 0;
}
.widget {
  background: #ffffff;
}
tr.empty-row td {
  height: 20px;
}

.widget thead .widget-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  padding: 5px 10px !important;
  text-transform: uppercase;
}
.widget thead .widget-subtitle {
  border-bottom: 1px solid #e4e6ea;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  padding: 5px 10px 10px !important;
  text-transform: uppercase;
}

.widget thead .widget-name {
  border-bottom: 1px solid #e4e6ea;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
}
.widget thead .widget-col-name {
  border-bottom: 1px solid #e4e6ea;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  text-align: right;
}
.content-inner .widget tr td {
  padding: 10px;
}
.content-inner .widget tr td:first-child,
.content-inner .widget tr td:last-child {
  padding: 0;
}

.takeaway-list {
  padding-left: 0px;
}

.score-widget .scores {
  text-align: center;
}
.score-widget .scores .comparison-scores {
  border-collapse: separate;
  border-spacing: 10px;
}
.score-widget .current-score,
.score-widget .comparison-score {
  border-bottom: 2px solid #009aff;
  font-size: 60px;
  font-weight: 300;
  line-height: 1;
  padding: 10px 10px 0 !important;
  text-align: center;
  vertical-align: bottom;
}
.score-widget .comparison-score {
  border-bottom-color: #ff9e1c;
}
.score-widget .previous-score {
  border-bottom: 2px solid #ff9e1c;
  font-size: 60px;
  line-height: 1;
  padding: 10px 10px 0 !important;
  text-align: center;
  vertical-align: bottom;
}
.score-widget .score-difference {
  font-size: 18px;
  line-height: 1;
  padding: 10px 0 20px !important;
  text-align: center;
}

.score-widget .score-difference img {
  height: 14px;
  padding: 3px;
  vertical-align: top;
}
.score-widget .difference {
  font-size: 14px;
  font-weight: 400;
}
.score-widget .difference {
  height: 14px;
  padding: 3px;
}
.score-widget .positive {
  color: #79b960;
}
.score-widget .negative {
  color: #ee6b6b;
}

.impact-widget tbody tr.double-theme > td {
  padding: 3px 10px;
}
.impact-widget .negative-bars {
  text-align: right;
  padding: 0 10px !important;
}
.impact-widget .comparison-bars {
  padding: 0 10px !important;
}
.impact-widget .baseline-impact-bar-wrapper {
  margin-bottom: 1px;
  vertical-align: bottom;
}
.impact-widget .comparison-impact-bar-wrapper {
  margin-top: 1px;
  vertical-align: top;
}
.impact-widget .baseline-impact-bar-wrapper td {
  height: 14px;
  background: #009aff;
}
.impact-widget .comparison-impact-bar-wrapper td {
  height: 14px;
  background: #ffbb60;
}
.impact-widget .negative-bar {
  padding: 0 10px;
  text-align: right;
}
.positive-bar {
  padding: 0 10px !important;
}
.positive-bar table,
.negative-bar table {
  background: #009aff;
}
.positive-bar table td,
.negative-bar td {
  height: 20px;
}
.impact-widget .theme-name {
  line-height: 16px;
}
.theme-impact {
  font-weight: 600;
  text-align: right;
}
.theme-impact td {
  text-align: right;
}
.theme-impact.changes .positive {
  color: #79b960;
}
.theme-impact.changes .negative {
  color: #ee6b6b;
}
.theme-impact.previous,
.theme-impact.current {
  font-weight: normal;
}
.changes-widget .this-period-legend,
.impact-widget .this-period-legend,
.over-time-widget .this-period-legend {
  background-color: #009aff;
  height: 8px;
  padding: 0 !important;
  width: 20px;
}
.changes-widget .last-period-legend,
.impact-widget .last-period-legend,
.over-time-widget .last-period-legend {
  background-color: #ffbb60;
  height: 8px;
  padding: 0 !important;
  width: 20px;
}
.changes-widget tbody tr.theme td {
  padding: 3px 10px;
}
.no-changes {
  text-align: center;
}
.changes-widget .last-period-name,
.changes-widget .this-period-name,
.impact-widget .last-period-name,
.impact-widget .this-period-name,
.over-time-widget .last-period-name,
.over-time-widget .this-period-name {
  font-size: 12px;
  font-weight: 600;
  padding: 0 25px 0 8px !important;
  text-transform: uppercase;
}
.changes-widget .this-period-bar-wrapper {
  padding: 1px 10px !important;
  vertical-align: bottom;
}
.changes-widget .last-period-bar-wrapper {
  padding: 1px 10px !important;
  vertical-align: top;
}
.changes-widget .this-period-bar-wrapper {
  background: #009aff;
  margin-bottom: 1px;
}
.changes-widget .this-period-bar-wrapper td {
  height: 14px;
}

.changes-widget .last-period-bar-wrapper {
  background: #ffbb60;
  margin-top: 1px;
}
.changes-widget .last-period-bar-wrapper td {
  height: 14px;
}

.subscribe-block {
  width: 100%;
}
.subscribe-block td {
  padding: 20px 0 0;
  font-weight: 600;
}
.spacious-block {
  padding: 0 0 30px;
}
.panel-title {
  background: #ff9e1c;
  border-style: solid;
  border-color: #ff9e1c;
  border-width: 16px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-top: -20px;
  padding: 0 26px;
}

.report-content-wrap {
  padding: 0 20px 10px;
}

.over-time-widget .over-time-bar-container {
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  vertical-align: bottom;
}

.over-time-widget .over-time-bar-comparison-container {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  text-align: center;
  vertical-align: bottom;
}

.over-time-widget .over-time-score {
  font-weight: 600;
  text-align: center;
}

.over-time-widget .over-time-bar {
  background: #009aff;
  width: 60px;
}

.over-time-widget .over-time-bar-baseline {
  background: #009aff;
  width: 30px;
}

.over-time-widget .over-time-bar-comparison {
  background: #ffbb60;
  width: 30px;
}

.over-time-widget .date-label {
  color: #748091;
  font-size: 11px;
  padding: 5px 0 25px !important;
  text-align: center;
}
.theme-group-name {
  font-weight: 600;
}
.theme-group {
  margin-right: 15px;
}
.group-impacts td {
  padding-bottom: 5px !important;
  padding-top: 0 !important;
}
.summary--empty img {
  margin-top: 2px;
  margin-right: 5px;
  float: left;
}

/* overrides */
table[width="600"],
td[width="600"] {
  width: 500px;
}
.report-content-wrap {
  padding: 0 0 10px;
}


.score-widget .scores {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.impact-widget tbody tr.double-theme > td {
  padding: 5px;
}
.widget thead .widget-col-name {
  width: 90px;
}
.theme-impact {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.changes-widget tbody tr.theme td {
  padding: 2px;
}
.new-themes-widget td.theme-impact.widget-name {
  padding: 0px;
  width: 90px;
}
.spacious-block {
  text-align: -webkit-center;
  text-align: -moz-center;
}
.btn-cell {
  display: block;
}

/**
 * Dimensions
 */

.table-600 {
  width: 600px;
}

.td-10 {
	width: 10px;
}
.td-60 {
	width: 60px;
}
.td-140 {
	width: 140px;
}
.td-308 {
	width: 308px;
}
.td-508 {
	width: 508px;
}
.widget-width {
	width: 508px;
}

.table-100pc {
  width: 100%;
}

.borderspace-0 {
  border-spacing: 0;
}
.cellpadding-0 {
  padding: 0;
}
.summary p {
   padding: 1rem;
   text-align: left;
}
  `;

}
