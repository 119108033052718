import { InputData, QuotesAnswerResponse } from 'api/interfaces';
import analytics from 'lib/analytics';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AnswerOutput } from 'types/custom';
import { Suggestions } from './Suggestions';
import { Visualization } from './Visualization';
import { ComparisonExamples } from './ComparisonExamples';
import { Examples } from './Examples';

type BaseProps = {
  item: AnswerOutput,
  isReadOnly: boolean;
};

type QuotesProps = BaseProps & {
  item: {
    content: QuotesAnswerResponse;
  };
  onToggleExamplePin: (example: InputData) => void;
};

export type Props = BaseProps | QuotesProps;

export const AnswerSupplementary = observer(function (props: Props) {

  function getContent() {
    switch (props.item.content.type) {
      case 'suggestions': {
        return (
          <Suggestions
            content={props.item.content.contents}
            onTextSelection={() => {
              analytics.track('Answers: Select Answer', { 'Type': 'Suggestions' });
            }}
            onCopyClick={() => {
              analytics.track('Answers: Copy Answer', { 'Type': 'Suggestions' });
            }}
          />
        );
      }
      case 'quotes': {
        const textData: QuotesAnswerResponse = props.item.content;
        // both inputData and comparisons could be present at the same time, therefore, we need to check for comparison first

        if ('onToggleExamplePin' in props) {
          if (props.item.comparisons?.length) {
            return (
              <ComparisonExamples
                comparisons={props.item.comparisons}
                isReadOnly={props.isReadOnly}
                onTextSelection={() => {
                  analytics.track('Answers: Select Answer', { 'Type': 'Quotes' });
                }}
                onToggleExamplePin={props.onToggleExamplePin}
              />
            );
          }

          if (textData.inputData) {
            return (
              <Examples
                content={textData.inputData}
                isReadOnly={props.isReadOnly}
                onToggleExamplePin={props.onToggleExamplePin}
                onTextSelection={() => {
                  analytics.track('Answers: Select Answer', { 'Type': 'Quotes' });
                }}
              />
            );
          }
        }

        return null;
      }
      case 'visualization': {
        return (
          <Visualization
            createdAt={props.item.createdAt}
            content={props.item.content.contents}
          />
        );
      }
      default: return null;
    }
  }

  return (
    <section>
      {getContent()}
    </section>
  );

});