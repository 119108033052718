import { IntegrationType, SurveyDataStatus } from 'api/enums';
import { DataSourceIntegration } from 'api/interfaces';
import Tips from 'components/Tips/Tips';
import { supportedDataSources } from 'components/Upload/constants';
import Updating from 'images/icons/updating.svg';
import analytics from 'lib/analytics';
import { hasFailedImport, isSurveyNameValid } from 'lib/survey-helpers';
import { compact, isEmpty, isEqual } from 'lodash';
import { reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Message, Segment } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { ManualUploadString, SetupStoreInterface, SurveyOptions } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import DataSourceConfigureForm from '../Upload/DataSourceConfigureForm';
import ImportData from '../Upload/ImportData';
import UploadData from '../Upload/UploadData';
import ConfigureSurveyActions from './ConfigureSurveyActions';
import SurveyReplaceButton from './SurveyReplaceButton';
import './import.scss';

type DataSourceIntegrationForSetup = Pick<DataSourceIntegration, 'configuration' | 'integrationId' | 'type'>;

interface ImportStoreProps {
  setupStore?: SetupStoreInterface;
  integrationsStore?: IntegrationsStoreInterface;
  surveyStore?: SurveyStoreInterface;
}

export interface ImportProps extends ImportStoreProps {
  surveyId: string;
  isFirstStep: boolean;
  processingOnNext: boolean;
  connectedIntegrationType?: IntegrationType;
  updatedSurveyName?: string;
  onBack: () => void;
  onNext: (shouldReviewData: boolean) => void;
  onCancel: () => void;
}

export interface ImportState {
  errorMessage?: string;
  updatingSurvey: boolean;
  title: string;
  existingDataSource: string;
  dataSourceIntegration: DataSourceIntegrationForSetup;
  valid: boolean;
  importingData: boolean;
  uploadedFile: boolean;
  uploadingFile: boolean;
  uploadFailed: boolean;
  dataSourceConfigHasChanged: boolean;
  isIntegrationInputValid: boolean;
}

@inject('setupStore', 'integrationsStore', 'surveyStore')
@observer
export class Import extends React.Component<ImportProps, ImportState> {
  interval: ReturnType<typeof setTimeout> | null;

  constructor(props: ImportProps) {
    super(props);

    let dataSourceIntegration = {} as DataSourceIntegrationForSetup;
    if (this.survey?.dataSourceIntegration) {
      const { configuration, integrationId, type } = this.survey.dataSourceIntegration;
      dataSourceIntegration = { configuration, integrationId, type };
    }

    this.state = {
      errorMessage: undefined,
      updatingSurvey: false,
      title: this.survey?.title || '',
      existingDataSource: dataSourceIntegration.type || ManualUploadString,
      dataSourceIntegration,
      valid: false,
      importingData: false,
      uploadedFile: false,
      uploadingFile: false,
      uploadFailed: false,
      dataSourceConfigHasChanged: false,
      isIntegrationInputValid: true
    };
  }

  get currentDataSource() {
    const { dataSourceIntegration } = this.state;

    if (!isEmpty(dataSourceIntegration)) {
      return dataSourceIntegration['type'];
    }

    return ManualUploadString;
  }

  get survey() {
    const { surveyStore, surveyId } = this.props;

    return surveyStore!.surveys.find(s => s.surveyId === surveyId);
  }

  get requiresConfiguration() {
    // Determines whether a configuration is needed
    // Defined as whether a configuration has already been created
    const survey = this.survey;
    if (survey && survey.configuration) {
      const config = JSON.parse(survey.configuration);
      if (config.constructor === Object && Object.keys(config).length !== 0) {
        return false;
      }
    }
    return true;
  }

  componentDidMount() {
    const { setupStore } = this.props;
    if (this.survey) {
      this.setState({ valid: isSurveyNameValid(this.survey.title) });
    }

    const { dataSourceTypes } = setupStore!;
    if (!dataSourceTypes.length) {
      setupStore!.getAvailableDataSources();
    }

    disposeOnUnmount(
      this,
      reaction(
        () => this.survey!,
        (s) => this.watchSurvey(s),
        { fireImmediately: true }
      )
    );
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  watchSurvey = (survey: LiteSurvey) => {
    const { surveyStore } = this.props;
    const connectedIntegration = !!survey?.dataSourceIntegration;
    const inProgress = survey?.dataStatus === SurveyDataStatus.NODATA;
    if (connectedIntegration && inProgress) {
      if (!this.interval) {
        this.interval = setInterval(async () => await surveyStore!.getSurvey(survey.surveyId, true), 30000);
      }
    } else {
      this.clearInterval();
    }
  }

  dataSourceTypeHasChanged = () => {
    return this.state.existingDataSource !== this.currentDataSource;
  }

  onNext = async () => {
    const { surveyStore, onNext, surveyId } = this.props;
    const { title, dataSourceIntegration, uploadedFile, dataSourceConfigHasChanged } = this.state;
    const { requiresConfiguration } = this;

    const nameHasChanged = title !== surveyStore!.getSurveyTitle(surveyId);

    if (!nameHasChanged && !dataSourceConfigHasChanged) {
      if (uploadedFile) {
        await surveyStore!.getSurvey(surveyId, true);
        onNext(true); // we uploaded a file, definitely review data
        return;
      } else {
        onNext(requiresConfiguration); // no new file, no review data?
        return;
      }
    }

    this.setState({ errorMessage: undefined, updatingSurvey: true });

    const surveyOptions = {} as SurveyOptions;

    if (nameHasChanged) {
      surveyOptions.name = title;
    }

    if (dataSourceConfigHasChanged) {
      const isManualUpload = this.currentDataSource === ManualUploadString;
      if (isManualUpload) {
        // TODO: Ensure dataSourceIntegration gets unset when backend can handle nullifying properties
        surveyOptions.dataSourceIntegration = null;
        surveyOptions.manualUploadAllowed = true;
      } else {
        surveyOptions.dataSourceIntegration = dataSourceIntegration;
        surveyOptions.manualUploadAllowed = false;
      }
    }

    await surveyStore!.updateSurvey(surveyId, surveyOptions);
    this.setState({ updatingSurvey: false });

    if (!surveyStore!.updateSurveyError) {
      onNext(requiresConfiguration); // only need to review data if we require configuration
    } else {
      // if not errored it will be undefined
      this.setState({ errorMessage: surveyStore!.updateSurveyError });
    }
  };

  onImport = async () => {
    const { surveyId, surveyStore } = this.props;
    const { dataSourceIntegration } = this.state;

    this.setState({ errorMessage: undefined, updatingSurvey: true });

    const surveyOptions = {} as SurveyOptions;

    surveyOptions.dataSourceIntegration = dataSourceIntegration;
    surveyOptions.manualUploadAllowed = false;
    analytics.track('Setup Flow: Data Imported',
      {
        'Category': 'Setup Flow',
        'Data Source': supportedDataSources[dataSourceIntegration.type]?.name || dataSourceIntegration.type
      }
    );

    const updatedSurvey = await surveyStore!.updateSurvey(surveyId, surveyOptions);
    this.setState({ updatingSurvey: false, importingData: true });

    if (!updatedSurvey) {
      // if not errored it will be undefined
      this.setState({ errorMessage: surveyStore!.updateSurveyError });
    }
  }

  onFormUpdate = (title: string, valid: boolean, dataSourceIntegration: DataSourceIntegrationForSetup) => {
    this.setState(prevState => {
      const updates = { title, valid } as ImportState;

      // User changes data source type in UI
      if (!isEqual(prevState.dataSourceIntegration, dataSourceIntegration)) {
        return {
          ...updates,
          dataSourceConfigHasChanged: true,
          dataSourceIntegration
        };
      }

      return updates;
    });

    if (title.length === 0) {
      this.setState({ errorMessage: 'Dataset needs a name' });
    } else if (title.length > 50) {
      this.setState({ errorMessage: 'Dataset needs a shorter name' });
    } else {
      this.setState({ errorMessage: '' });
    }
  }

  onUploadComplete = () => {
    analytics.track('Setup Flow: Data Imported', { 'Category': 'Setup Flow', 'Data Source': 'Manual' });
    this.setState({ uploadedFile: true });
  }

  onFileUpload = (value: boolean) => {
    this.setState({ uploadingFile: value });
  }

  onFileUploadError = (uploadFailed: boolean) => {
    this.setState({ uploadFailed });
  }

  setInputValidity = (isValid: boolean) => {
    this.setState({ isIntegrationInputValid: isValid });
  }

  render() {
    const {
      errorMessage,
      updatingSurvey,
      title,
      existingDataSource,
      valid,
      importingData,
      uploadedFile,
      uploadingFile,
      uploadFailed,
      isIntegrationInputValid
    } = this.state;

    const {
      isFirstStep,
      processingOnNext,
      onCancel,
      onBack,
      setupStore,
      integrationsStore,
      connectedIntegrationType,
      updatedSurveyName
    } = this.props;

    const { requiresConfiguration } = this;

    const { getIntegrationTypeDetails } = integrationsStore!;
    const {
      listDataSourcesLoading,
      listDataSourcesError,
      dataSourceTypes
    } = setupStore!;

    const errors = compact([
      errorMessage,
      listDataSourcesError
    ]);

    const shouldDisableEdit =
      (this.survey?.dataSourceIntegration && this.survey?.dataStatus === SurveyDataStatus.NODATA)
      || this.survey?.dataStatus === SurveyDataStatus.NORESULTS
      || updatingSurvey
      || importingData
      || processingOnNext;

    const dataSourceTypeHasChanged = this.dataSourceTypeHasChanged();
    const selectedManualUpload = this.currentDataSource === ManualUploadString;
    const integrationTypeDetails = getIntegrationTypeDetails(this.currentDataSource as IntegrationType);
    const showImportButton = this.survey?.dataStatus === SurveyDataStatus.NODATA
      && (integrationTypeDetails ? integrationTypeDetails.connected : !selectedManualUpload);

    const showImportStatus = selectedManualUpload
      || (this.survey?.dataSourceIntegration && !dataSourceTypeHasChanged)
      || importingData;

    const showInitialDropzone = dataSourceTypeHasChanged && selectedManualUpload;

    const importFailed = this.survey && hasFailedImport(this.survey);

    // if we do require a configuration we need to make sure we have data before being able to click next
    const disableNext = (requiresConfiguration && (!valid
      || (this.survey?.dataStatus === SurveyDataStatus.NODATA && !uploadedFile)
      || uploadingFile
      || uploadFailed
      || updatingSurvey
      || processingOnNext)) ||
      // if we don't require a configuration we can click next so long as we are already importing
      (!requiresConfiguration && (!importingData || !valid));

    return (
      <Segment className="import">
        <h4>Import Data</h4>

        {errors.map(error => (
          <Message
            key={error}
            className="error"
            negative={true}
            header={error}
          />
        ))}

        {listDataSourcesLoading || !this.survey ? (
          <div className="while-updating-wrapper nw-updating">
            Loading
            <Updating className="ui active centered inline loader" />
          </div>
        ) : (
          <div className="import__content-wrapper">
            <DataSourceConfigureForm
              existingTitle={updatedSurveyName || title}
              existingSourceType={connectedIntegrationType || existingDataSource}
              existingConfiguration={this.survey.dataSourceIntegration?.configuration}
              onChange={this.onFormUpdate}
              sourceTypes={dataSourceTypes}
              disableEdit={shouldDisableEdit}
              setValidity={this.setInputValidity}
            />

            {showImportButton &&
              <Button
                color="blue"
                onClick={this.onImport}
                disabled={shouldDisableEdit || !isIntegrationInputValid}
                className="import__import-data-button nw-import-data-button"
                role="button"
              >
                Import Data
              </Button>
            }

            {selectedManualUpload &&
              <Tips>
                <h4>What to upload</h4>
                <ul>
                  <li>
                    <a
                      href="https://help.getthematic.com/article/77-thematic-data-formats"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      See an example data file
                    </a>
                  </li>
                  <li>Include at least 100 rows of feedback</li>
                  <li>Put all data on the first sheet</li>
                </ul>
              </Tips>
            }

            {showImportStatus && (
              <div className="import__data-status">
                {(this.survey.manualUploadAllowed || showInitialDropzone)
                  ? <UploadData
                    survey={this.survey}
                    showInitialDropzone={showInitialDropzone}
                    onProgress={this.onFileUpload}
                    onError={this.onFileUploadError}
                    onComplete={this.onUploadComplete}
                  />
                  : <ImportData
                    survey={this.survey}
                    showError={true}
                    doesNotRequireWaiting={!requiresConfiguration}
                  />
                }
              </div>
            )}

            {importFailed &&
              <SurveyReplaceButton survey={this.survey} />
            }
          </div>
        )}

        <ConfigureSurveyActions
          isFirstStep={isFirstStep}
          disableNext={disableNext}
          processing={processingOnNext}
          onCancel={onCancel}
          onNext={this.onNext}
          onBack={onBack}
        />
      </Segment>
    );
  }
}

export default Import;
