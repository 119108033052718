

































































import { getExportInsightUIStore } from 'stores/RootStore';
import { getCount } from 'lib/explore-tool-insight-generator';

export default {
  name: 'BarChartForm',
  props: {
    insight: { type: Object, required: true }
  },
  data() {
    return {
      isAtTop: false,
      isAtBottom: false,
      exportInsightUIStore: getExportInsightUIStore(),
    };
  },
  mounted() {
    setTimeout(() => {
      // waiting for a tick so the modal is loaded in the dom
      this.calculateBarChartFormListHeight();
      this.scrollToSelection();
    }, 300);
    this.handleScroll(this.$el.querySelector('.list'));
  },
  methods: {
    calculateBarChartFormListHeight() {
      // height of the preview chart can differ based on the filter selection
      // that's why we need to set the height of the list inside form based on the height of the preview chart
      const insightPreviewSectionHeight = (document.getElementsByClassName('insight-preview')[0] as HTMLElement).offsetHeight;
      const barChartForm = (this.$el as HTMLElement).offsetHeight;
      const barChartFormList = (this.$el.querySelector('.list') as HTMLElement).offsetHeight;
      (this.$el.querySelector('.list') as HTMLElement).style.maxHeight = ((insightPreviewSectionHeight - (barChartForm - barChartFormList + 40)) + 'px');
    },
    scrollToSelection() {
      const selectedTheme = this.$el.querySelector('.list label.el-checkbox.is-checked') as HTMLElement;
      if (!selectedTheme) {
        return;
      }
      selectedTheme.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    },
    onScroll(e) {
      this.handleScroll(e.target);
    },
    handleScroll(list) {
      this.isAtTop = list.scrollTop === 0;
      this.isAtBottom = list.scrollTop + list.clientHeight === list.scrollHeight;
    },
    getSelectedFieldCount() {
      return this.insight.fields.reduce((result, field) => {
        return result + (field.isSelected ? 1 : 0)
      }, 0);
    },
    shouldDisable(field) {
      const { maxSelectedFields } = this.insight.form;
      const hasReachedLimit = this.getSelectedFieldCount() >= maxSelectedFields;
      const shouldDisable = !field.isSelected && hasReachedLimit;
      return shouldDisable;
    },
    onChangeSelection(field) {
      field.isSelected = !field.isSelected;
      const selectedThemes = this.insight.fields.filter(field => field.isSelected);
      const chartScaleCount = getCount(selectedThemes);

      const chart = {
        ...this.insight.chart,
        count: chartScaleCount
      };

      this.exportInsightUIStore.update({
        ...this.insight,
        chart
      });
    },
    onChangeHighlight(field) {
      field.isHighlighted = !field.isHighlighted;
      this.exportInsightUIStore.update(this.insight);
    },
    onChangeShowBarValues() {
      const form = {
        ...this.insight.form,
        shouldShowBarValues: !this.insight.form.shouldShowBarValues
      }

      this.exportInsightUIStore.update({
        ...this.insight,
        form
      });
    }
  }
};

