<template>
  <div class="tag-dropdown">
    <el-popover
      ref="popover"
      :visible-arrow="false"
      :append-to-body="false"
      :placement="tagDropdownPlacement"
      width="100%"
      trigger="click"
      :popper-options="popperOptions"
      @show="onShow"
      @hide="onHide"
    >
      <div
        :class="['popover-content', { disabled: isCreating }]"
        @keydown.stop.prevent.arrow-up="highlightPreviousItem"
        @keydown.stop.prevent.arrow-down="highlightNextItem"
        @keydown.stop.prevent.enter="handleEnterKey"
      >
        <el-input
          ref="inputElement"
          v-model="filterText"
          placeholder="Add labels"
          size="small"
          clearable
          :disabled="isCreating"
        />
        <el-alert
          v-if="!!errorMessage"
          class="error-message"
          type="error"
          :title="errorMessage"
          @close="errorMessage = ''"
        />
        <div class="tag-options">
          <div
            v-for="(item, index) in filteredTags"
            :key="item.id"
            :class="['tag-option', { active: currentItem === index }]"
            @click="handleTagClicked(item.id, index)"
            @mouseover="currentItem = index"
            @mousedown.prevent
          >
            <div class="tag-option-icon">
              <font-awesome-icon
                v-if="item.isActive"
                icon="check"
              />
            </div>
            <span>
              {{ item.fullName }}
            </span>
          </div>
          <div
            v-if="suggestChildTag"
            :class="['tag-option', { active: isChildTagButtonSelected }]"
            @click="handleSuggestChildTagClick"
            @mouseover="handleChildTagButtonMouseover"
            @mousedown.prevent
          >
            <div class="tag-option-icon" />
            <span>
              {{ filteredTags[0].name + ':' }}
            </span>
            <span class="new-child-tag-text">
              new child tag
            </span>
          </div>
          <div
            v-if="canCreateChildTag"
            :class="['tag-option', { active: isChildTagButtonSelected }]"
            @click="handleChildTagButtonClick"
            @mouseover="handleChildTagButtonMouseover"
            @mousedown.prevent
          >
            <div class="tag-option-icon">
              <i
                v-if="isCreating && isCreatingChild"
                class="el-icon-loading"
              />
            </div>
            <span>
              New child label “
            </span>
            <span
              class="create-child-tag-button-parent-text"
            >{{ createChildTagButtonParentText }}:</span>
            <span> {{ createChildTagButtonChildText }}” </span>
          </div>
          <div
            v-if="canCreateNewTag && filteredTags.length"
            class="divider"
          />
          <div
            v-if="canCreateNewTag"
            :class="['tag-option', { active: isCreateNewTagButtonSelected }]"
            @click="handleNewTagButtonClick"
            @mouseover="currentItem = itemCount - 1"
            @mousedown.prevent
          >
            <div class="tag-option-icon">
              <i
                v-if="isCreating && !isCreatingChild"
                class="el-icon-loading"
              />
            </div>
            <span>
              {{ `New label “${filterText.trim()}”` }}
            </span>
          </div>
        </div>
        <el-button
          v-if="!filterText"
          class="manage-tags-button"
          @click="openManageTags"
        >
          Manage labels
        </el-button>
      </div>
    </el-popover>
    <el-button
      v-popover:popover
      size="small"
      class="add-tag-button"
      :class="{ 'popover-active': isPopoverActive }"
    >
      <span class="add-tag-button-content no-export-output">
        <font-awesome-icon
          icon="plus"
          class="add-tag-button-icon"
        /> Add labels
      </span>
    </el-button>
  </div>
</template>

<script>
import { getAnalysisConfigStore, getTagsUIStore } from 'stores/RootStore';

export default {
  name: 'TagDropdown',
  props: {
    tags: { default: () => [], type: Array },
    tagDropdownPlacement: { default: 'bottom-start', type: String },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      tagsUIStore: getTagsUIStore(),
      filterText: '',
      isPopoverActive: false,
      // Note: currentItem can be any value between 0 and filteredTags.length. If it's
      // equal to filteredTags.length, the 'Create new tag' button is highlighted.
      currentItem: 0,
      popperOptions: { boundariesElement: this.$parent._uid },
      errorMessage: '',
      isCreating: false,
      isCreatingChild: false,
    };
  },
  computed: {
    filteredTags() {
      return this.tags.filter(
        t =>
          !this.filterText ||
          t.fullName.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },
    filteredTagExists() {
      if (this.filterText.trim().length > 0) {
        return this.filteredTags.some(
          t => t.name.toLowerCase() === this.filterText.toLowerCase()
        );
      }
      return true;
    },
    itemCount() {
      let itemCount = this.filteredTags.length;
      if (this.suggestChildTag || this.canCreateChildTag) {
        itemCount++;
      }
      if (this.canCreateNewTag) {
        itemCount++;
      }
      return itemCount;
    },
    activeTagCount() {
      return this.tags.filter(t => t.isActive).length;
    },
    suggestChildTag() {
      return (
        this.analysisConfigStore.canManageSurvey &&
        this.filteredTags.length === 1 &&
        this.filterText &&
        !this.filterText.includes(':')
      );
    },
    canCreateChildTag() {
      return (
        this.analysisConfigStore.canManageSurvey &&
        this.filterText.includes(':')
      );
    },
    createChildTagButtonParentText() {
      return this.filterText.substring(0, this.filterText.indexOf(':')).trim();
    },
    createChildTagButtonChildText() {
      return this.filterText
        .substring(this.filterText.indexOf(':') + 1, this.filterText.length)
        .trim();
    },
    canCreateNewTag() {
      return (
        this.analysisConfigStore.canManageSurvey &&
        !this.filteredTagExists &&
        !this.filterText.includes(':')
      );
    },
    isChildTagButtonSelected() {
      return (
        (this.suggestChildTag || this.canCreateChildTag) &&
        this.currentItem === this.filteredTags.length
      );
    },
    isCreateNewTagButtonSelected() {
      return this.canCreateNewTag && this.currentItem === this.itemCount - 1;
    }
  },
  watch: {
    filterText: {
      handler() {
        this.currentItem = 0;
        this.errorMessage = '';
      }
    },
    activeTagCount: {
      handler() {
        // Repositions the popover if tags are added or removed.
        const { popover } = this.$refs;
        if (popover && popover.popperJS) {
          const popperJS = popover.popperJS;
          popperJS.update();
          setTimeout(() => popperJS.update(), 350); // Waits until animation is finished when removing tags.
        }
      }
    }
  },
  methods: {
    onShow() {
      this.isPopoverActive = true;
      this.focusInput();
    },

    onHide() {
      this.isPopoverActive = false;
      this.currentItem = 0;
      this.filterText = '';
    },

    openManageTags() {
      this.tagsUIStore.openManageTags();
    },

    highlightNextItem() {
      if (!this.isCreating && this.currentItem < this.itemCount - 1) {
        this.currentItem++;
      }
    },

    highlightPreviousItem() {
      if (!this.isCreating && this.currentItem > 0) {
        this.currentItem--;
      }
    },

    handleEnterKey() {
      if (this.isCreating) return;

      if (
        this.currentItem >= 0 &&
        this.currentItem < this.filteredTags.length
      ) {
        const tagId = this.filteredTags[this.currentItem].id;
        this.$emit('toggleTag', tagId);
      } else if (this.isCreateNewTagButtonSelected) {
        this.handleNewTagButtonClick();
      } else if (this.isChildTagButtonSelected) {
        if (this.suggestChildTag) {
          this.handleSuggestChildTagClick();
        } else if (this.canCreateChildTag) {
          this.handleChildTagButtonClick();
        }
      }
    },

    handleTagClicked(tagId, index) {
      if (this.isCreating) return;

      this.currentItem = index;
      this.$emit('toggleTag', tagId);
    },

    handleSuggestChildTagClick() {
      if (this.isCreating) return;

      if (this.filteredTags[0]) {
        this.filterText = this.filteredTags[0].name + ': ';
      }
    },

    handleChildTagButtonMouseover() {
      this.currentItem = this.itemCount - (this.canCreateNewTag ? 2 : 1);
    },

    handleChildTagButtonClick() {
      if (this.isCreating) return;

      const name = this.createChildTagButtonChildText;
      const parentTag = this.tags.find(
        tag => tag.name === this.createChildTagButtonParentText
      );
      if (!this.createChildTagButtonParentText) {
        this.errorMessage = 'Labels cannot start with “:”';
      } else if (!parentTag) {
        this.errorMessage = `Parent label ${this.createChildTagButtonParentText} does not exist`;
      }
      if (!name) {
        this.errorMessage = 'Child label cannot be blank';
      } else if (name.includes(':')) {
        this.errorMessage = 'Labels cannot include “:”';
      } else if (name.length > 50) {
        this.errorMessage = 'Labels must be less than 50 characters';
      } else if (parentTag) {
        this.createNewTag(name, parentTag.id);
      }
    },

    handleNewTagButtonClick() {
      const name = this.filterText.trim();
      if (name.length > 50) {
        this.errorMessage = 'Labels must be less than 50 characters';
      } else {
        this.createNewTag(name);
      }
    },

    createNewTag(name, parentId) {
      this.isCreating = true;
      this.isCreatingChild = !!parentId;
      this.$emit('createNewTag', name, parentId, () => {
        this.isCreating = false;
        this.isCreatingChild = false;
        this.doHidePopover();
      });
    },

    doHidePopover() {
      const { popover } = this.$refs;
      if (popover) {
        popover.doClose();
      }
    },

    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.inputElement) {
          this.$refs.inputElement.$refs.input.focus();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.tag-dropdown ::v-deep .el-popover {
  margin-top: 0 !important;
}

.tag-dropdown ::v-deep .el-input__inner {
  transition: none;
}

.tag-dropdown ::v-deep .el-alert__content {
  text-align: left !important;
  padding-right: 20px !important;
}

.add-tag-button {
  margin-left: 0;
  border: none;
  color: $--neutral-400;

  &.popover-active {
    color: $--color-primary;
    background-color: $--color-primary-light-9;
  }
}

.add-tag-button-content {
  display: flex;
  align-items: center;
}

.add-tag-button-icon {
  font-size: 12px;
  margin-right: 5px;
}

.popover-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 250px;
  max-width: 400px;

  &.disabled {
    pointer-events: none;
  }
}

.input {
  transition: none;
}

.tag-options {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-height: 200px;
  min-height: 0;
  overflow-y: auto;
}

.tag-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;

  &:first-child {
    margin-top: 10px;
  }

  &:not(:first-child) {
    margin-top: 5px;
  }

  &.active {
    background: $--primary-100;
  }

  > * {
    flex-shrink: 0;

    &:last-child {
      flex-shrink: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.tag-option-icon {
  width: 22px;
  min-width: 22px;
}

.new-child-tag-text {
  color: $--color-text-secondary;
  padding-left: 5px;
}

.create-child-tag-button-parent-text {
  color: $--color-text-secondary;
  padding-right: 5px;
}

.divider {
  border-top: 1px solid $--neutral-100;
  margin: 10px 0 5px 0;
}

.manage-tags-button {
  margin-top: 10px;
}

.error-message {
  margin-top: 10px;
}

.el-icon-loading {
  margin-left: 2px;
}
</style>
