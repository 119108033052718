import { includes } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import {
  AdminScopes, RoleStoreInterface
} from 'stores/RoleStore';
import { getUpdatedScopes } from 'stores/utils/roles';
import './admin-permissions.scss';

export interface AdminPermissionsProps {
  roleId: string;
  roleStore?: RoleStoreInterface;
}

@inject('roleStore')
@observer
class AdminPermissions extends React.Component<AdminPermissionsProps> {
  get roleStore(): RoleStoreInterface {
    return this.props.roleStore!;
  }
  get roleId () {
    return this.props.roleId;
  }
  get availableScopes () {
    return this.roleStore.adminScopes || [];
  }
  get selectedScopes () {
    return (this.roleStore.roleDetails[this.roleId] || {}).admin;
  }
  handleScopeChange = (scope: string, { checked }: CheckboxProps) => {
    const updatedScopes = getUpdatedScopes(this.selectedScopes, scope, !!checked);
    this.roleStore.updateAdminPermissions(this.roleId, updatedScopes);
  };
  isSelected = (scope: string) => {
    return this.selectedScopes && includes(this.selectedScopes, scope);
  }
  render(): JSX.Element | null {
    return (
      <div className="admin-permissions">
        <strong>Admin Permissions</strong>
        <ul>
          {this.availableScopes.map(scope => {
            return (
              <li key={scope}>
                <Checkbox
                  id={AdminScopes[scope]}
                  label={<label htmlFor={AdminScopes[scope]}>{AdminScopes[scope]}</label>}
                  className="admin-scope"
                  key={scope}
                  value={scope}
                  checked={this.isSelected(scope)}
                  onChange={(_e, data) => this.handleScopeChange(scope, data)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default AdminPermissions;
