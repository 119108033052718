import DashboardPermissions from 'components/ManageUsers/DashboardPermissions';
import { compact } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Form, Loader, Message, Modal } from 'semantic-ui-react';
import { DEFAULT_ROLE_DETAILS, RoleStoreInterface, TEMP_USER_SPECIFIC_ROLE_ID } from 'stores/RoleStore';
import { mapSelectedPermissions } from 'stores/utils/roles';
import AdminPermissions from './AdminPermissions';
import './permissions.scss';
import SurveyPermissions from './SurveyPermissions';

export interface PermissionsProps {
  roleStore?: RoleStoreInterface;

  roleId: string;
  userEmail?: string;

  close: () => void;
}

@inject('roleStore')
@observer
class Permissions extends React.Component<PermissionsProps> {
  get roleDetails () {
    const { roleId } = this.props;
    return this.roleStore.roleDetails[roleId];
  }
  get roleStore() {
    return this.props.roleStore!;
  }
  componentDidMount () {
    this.initializePermissions();
  }
  initializePermissions = async () => {
    const { emptyPermissions } = this.roleStore;

    if (!emptyPermissions) {
      await this.roleStore.fetchEmptyPermissions();
    }

    // If role details already exists in list of roles, map it to displayable format
    // Otherwise, fetch role details and map it to displayable format
    const { roleId } = this.props;
    if (!this.roleStore.roleDetails[roleId]) {
      if (roleId !== TEMP_USER_SPECIFIC_ROLE_ID) {
        let roleDetails;
        const roleIndex = this.roleStore.roles.findIndex(role => role.id === roleId);
        if (roleIndex < 0) {
          roleDetails = await this.roleStore.fetchRoleDetails(roleId);
        } else {
          roleDetails = this.roleStore.roles[roleIndex];
        }
        if (!this.roleStore.fetchRoleDetailsError) {
          this.roleStore.roleDetails[roleId] = mapSelectedPermissions(roleDetails);
        }
      } else {
        this.roleStore.roleDetails[roleId] = DEFAULT_ROLE_DETAILS;
      }
    }
  }
  clear = async () => {
    const { roleId } = this.props;
    this.roleStore.roleDetails[roleId] = DEFAULT_ROLE_DETAILS;
  }
  close = () => {
    this.props.close();
  }
  render(): JSX.Element | null {
    const { roleId, userEmail } = this.props;
    const {
      emptyPermissions,
      fetchingRoleDetails,
      fetchRoleDetailsError,
      fetchingEmptyPermissions,
      fetchEmptyPermissionsError
    } = this.roleStore;

    // Add check to see if permissions are initialized as we have the
    // interim state when fetching flags of the API calls are not yet set
    const loading = fetchingRoleDetails || fetchingEmptyPermissions;
    const errors = compact([ fetchRoleDetailsError, fetchEmptyPermissionsError ]);
    const showPermissions = !!emptyPermissions && !!this.roleDetails;

    return (
      <Modal
        dimmer="blurring"
        className="permissions"
        open={true}
      >
        <Modal.Content>
          <div className="permissions-name">
            <strong>Custom Permissions</strong>
            <label>{userEmail ? userEmail : 'New user'}</label>
          </div>
          <div className="permissions__banner">
            Custom permissions are applied in addition to any permissions added by a user's roles
          </div>
          {loading &&
            <Loader size="large" content="Loading..." active={loading} />
          }
          {!loading &&
            <React.Fragment>
              {errors.map(error => {
                return (
                  <Message
                    key={error}
                    className="error"
                    negative={true}
                    header={error}
                  />
                );
              })}
              {!!showPermissions &&
                <Form>
                  <AdminPermissions
                    roleId={roleId}
                  />
                  <br />
                  <DashboardPermissions
                    roleId={roleId}
                  />
                  <br />
                  <SurveyPermissions
                    roleId={roleId}
                    isInModal={true}
                  />
                </Form>
              }
            </React.Fragment>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="nw--clear"
            onClick={this.clear}
            type="button"
            disabled={loading}
          >
            Clear all permissions
          </Button>
          <Button
            className="nw--close"
            onClick={this.close}
            type="button"
            color="blue"
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Permissions;
