import { SubscriptionJson } from 'api/interfaces';
import { capitalize, reduce, sortBy } from 'lodash';
import { SubscriptionInterface } from 'stores/SubscriptionStore';

export function mapSubscriptions(digests: SubscriptionJson[]): SubscriptionInterface[] {
  if (!digests) {
    return [];
  }

  const mappedDigests = digests.map(digest => {
    const {
      cadence,
      context,
      id,
      name,
      dashboardID,
      dashboardName,
      subscribers,
      status
    } = digest;
    const cadenceLabel = capitalize(cadence);
    const activeSubscribers = subscribers.filter(s => s.status === 'subscribed');
    const subscribersLabel = reduce(
      activeSubscribers,
      (result, subscriber, index, list) => {
        if (index === 0) {
          result.push(subscriber.email);
        } else if (index === 1) {
          result.push(`, ${ subscriber.email }`);
        } else if (index === 2) {
          if (list.length === 3) {
            result.push(' + 1 other');
          } else {
            result.push(` + ${ list.length - 2 } others`);
          }
        }
        return result;
      },
      [] as string[]
    ).join('');

    return {
      cadence,
      cadenceLabel,
      context,
      id: String(id),
      name: name || '<Unnamed Subscription>',
      dashboardID,
      dashboardName,
      subscribers: activeSubscribers,
      subscribersLabel,
      status
    };
  });
  const sortedDigests = sortBy(mappedDigests, 'name');
  return sortedDigests;
}