import * as React from 'react';
import './suggestions.scss';

interface SuggestionsProps {
  content: string;
  onTextSelection: (text: string) => void;
  onCopyClick: () => void;
}

export class Suggestions extends React.Component<SuggestionsProps> {
  recordSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString() !== '') {
      this.props.onTextSelection(selection.toString());
    }
  }

  render() {
    const { content } = this.props;
    return (
      <section className="answers__suggestions">
        <header className="tree-item__header">
          <h2 className="tree-item__title">Suggestions</h2>
        </header>
        <p
          role="article"
          onMouseUp={() => this.recordSelection()}
          onMouseLeave={() => this.recordSelection()}
        >{content}</p>
      </section>
    );

  }
}
