import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Form, Input, Label } from 'semantic-ui-react';
import { ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import './new-concept.scss';

interface NewConceptProps {
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
  onCancelCreateDescriptor: () => void;
  onCreateDescriptor: (newDescriptor: string) => void;
}

interface NewConceptState {
  newDescriptor: string;
}

@inject('conceptsEditorUIStore')
@observer
export default class NewConcept extends React.Component<NewConceptProps, NewConceptState> {
  state = {
    newDescriptor: ''
  } as NewConceptState;

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  createDescriptor = () => {
    this.conceptsEditorUIStore.validateCreateDescriptor(this.state.newDescriptor);
    const {
      hasMoreThanTwoWords,
      isDuplicate,
      isEmpty,
      existsAsNonDescriptor
    } = this.conceptsEditorUIStore.createDescriptorErrors;
    if (
      !hasMoreThanTwoWords &&
      !isDuplicate &&
      !isEmpty &&
      !existsAsNonDescriptor
    ) {
      this.conceptsEditorUIStore.createDescriptor(this.state.newDescriptor);
      this.props.onCreateDescriptor(this.state.newDescriptor.trim().toLowerCase());
    }
  }

  updateNewDescriptor = (e) => {
    this.setState({ newDescriptor: e.target.value });
    this.conceptsEditorUIStore.validateCreateDescriptor(e.target.value);
  }

  handleAddDescriptorKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.createDescriptor();
    }
  }

  render () {
    const {
      hasMoreThanTwoWords,
      isDuplicate,
      isEmpty,
      existsAsNonDescriptor
    } = this.conceptsEditorUIStore.createDescriptorErrors;
    const {
      onCancelCreateDescriptor
    } = this.props;
    const {
      newDescriptor
    } = this.state;
    return (
      <div className="new-concept">
        <div className="input-field">
          <Form.Field>
            <Input
              type="text"
              placeholder="Concept name"
              size="small"
              autoFocus={true}
              value={newDescriptor}
              onChange={this.updateNewDescriptor}
              onKeyPress={this.handleAddDescriptorKeyPress}
              error={hasMoreThanTwoWords || isDuplicate || isEmpty || existsAsNonDescriptor}
            />
            {(isDuplicate || existsAsNonDescriptor) &&
              <Label pointing={false} color="red" className="alert">
                This term is already in use.
              </Label>
            }
            {hasMoreThanTwoWords &&
              <Label pointing={false} color="red" className="alert">
                Key term cannot contain more than two words.
              </Label>
            }
            {isEmpty &&
              <Label pointing={false} color="red" className="alert">
                Terms cannot be empty.
              </Label>
            }
          </Form.Field>
        </div>
        <Button
          type="button"
          color= "blue"
          size="small"
          onClick={this.createDescriptor}
        >
          Add synonym
        </Button>
        <Button
          type="button"
          size="small"
          onClick={onCancelCreateDescriptor}
        >
          Cancel
        </Button>
      </div>
    );
  }
}