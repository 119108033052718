type countsType = {
  other: number;
  nothing: number;
  total: number;
  empty: number;
};

export function totalCountFromCountsObject(counts: countsType) {
    // the total number of responses in the dataset
    // THIS SHOULD ONLY BE USED IN THE HEADER BAR
  return counts.total;
}

export function nonemptyCountFromCountsObject(counts: countsType) {
    // number of responses that wrote anything at all
    // THIS IS THE ONE WE WANT TO USE WHEN COMPUTING VOLUME ANYWHERE
    return counts.total - (counts.empty);
}
export function meaningfulCountFromCountsObject(counts: countsType) {
    // number of responses that wrote something that wasn't jibberish
    // DO NOT USE THIS
    return counts.total - (counts.empty + counts.nothing);
}
export function taggedCountFromCountsObject(counts: countsType) {
    // number of responses that had something to say that was significant enough to include
    return counts.total - (counts.empty + counts.nothing + counts.other);
}
