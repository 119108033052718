import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceOkendoCreateFormProps {
  onChange(configuration: object, integrationId: string): void;
}

interface DataSourceOkendoCreateFormState {
}

@observer
class DataSourceOkendoCreateForm extends React.Component<
  DataSourceOkendoCreateFormProps,
  DataSourceOkendoCreateFormState
> {

  renderForm(): JSX.Element | null {
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          integrationType={IntegrationType.OKENDO} />
      </div>
    );
  }
}

export default DataSourceOkendoCreateForm;