import { IntegrationType } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import { getIntegrationUrlWithState } from 'components/Integrations/integration-helper';
import { sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, Loader } from 'semantic-ui-react';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import './slack-action.scss';

interface SlackActionProps {
  selectedSlackChannel: (integrationId?: string, channel?: string) => void;
  integrationsStore?: IntegrationsStoreInterface;
  organizationStore?: OrganizationStoreInterface;
  existingSlackChannel?: string;
}
export interface SlackActionState {
  integrationId?: string;
  integrations?: ConnectedIntegration[];
  loadingChannels: boolean;
  channels: {id: string, name: string}[];
  selectedChannel?: string;
  validation: JSX.Element | undefined;
  attemptingToConnectIntegration: boolean;
}

@inject('integrationsStore', 'organizationStore')
@observer
class SlackAction extends React.Component<SlackActionProps, SlackActionState> {

  state = {
    channels: [] as {id: string, name: string}[],
    loadingChannels: false,
    validation: undefined,
    attemptingToConnectIntegration: false,
    selectedChannel: this.props.existingSlackChannel
  } as SlackActionState;

  componentDidMount() {
    this.checkConnectedIntegration();
  }

  checkConnectedIntegration() {
    // checks if there is already a connected integration for the currently selected data soruce type
    const { integrationsStore } = this.props;
    // check the store to find a connected integration
    if (integrationsStore) {
      const integrations = integrationsStore.getConnectedIntegrationsOfType(IntegrationType.SLACK);
      if (integrations.length > 0) {
        this.setState({ integrations, integrationId: integrations[0].id }, () => {this.enumerateChannels(); });
        return true;
      }
    }
    return false;
  }

  checkIntegrationHasConnected = async() => {
    const { integrationsStore } = this.props;
    if (integrationsStore) {
      await integrationsStore.checkIntegrations();
      if (!this.checkConnectedIntegration() ) {
        // queue up another check
        setTimeout(this.checkIntegrationHasConnected, 2000);
      }
    }
  }

  selectSlackChannel = (e, {value}) => {
    const {integrationId} = this.state;
    this.setState({selectedChannel: value});
    this.props.selectedSlackChannel(integrationId, value);
  }

  sendTestMessage = () => {
    const { integrationsStore } = this.props;
    const { integrationId, selectedChannel } = this.state;

    if ( integrationsStore && integrationId && selectedChannel) {
      integrationsStore.testIntegration(integrationId, {channel: selectedChannel});
    }
  }

  openIntegrationConnection() {
    const { integrationsStore, organizationStore } = this.props;
    if (integrationsStore) {
      const slackIntegration = integrationsStore.getIntegrationTypeDetails(IntegrationType.SLACK)!;
      const { orgId } = organizationStore!;

      // Is an Oauth integration so we are going to need to go through an off-site flow
      const integrationUrl = getIntegrationUrlWithState({
        orgId,
        integrationUrl: slackIntegration.integrationUrl,
      });
      if (integrationUrl) {
        window.open(
          integrationUrl,
          '_blank'
        );
      }
      this.setState({attemptingToConnectIntegration: true});
      this.checkIntegrationHasConnected();
    }
  }

  async enumerateChannels() {
    // Enumerates all surveys available in the connected integration
    const { integrationsStore: store } = this.props;
    const { integrationId } = this.state;

    if (store && integrationId) {
      this.setState({ loadingChannels: true });
      const channels = await store.enumerateIntegration(integrationId);

      if (Array.isArray(channels)) {
        const sortedChannels = sortBy(channels, 'name');
        this.setState({ channels: sortedChannels });
      }
      this.setState({ loadingChannels: false });
    }
  }

  renderConnectIntegration(): JSX.Element {
    const { getCanAction } = this.props.organizationStore!;
    const canSeeIntegrations = getCanAction('manage:integration');
    const {attemptingToConnectIntegration} = this.state;
    // renders the form for connecting an integration in situ
    return (
      <div>
        {attemptingToConnectIntegration && (
          <Loader active={true} size="large">
            Waiting for connection to be established
          </Loader>
        )}
        {canSeeIntegrations ?
          <Button
            color="blue"
            onClick={() => {
              this.openIntegrationConnection();
            }} >
            Connect to Slack
          </Button>
        :
          <div>
            You don’t have permission to connect to Slack. Contact your Thematic administrator to request permission.
          </div>
        }
      </div>
    );
  }

  render(): JSX.Element | null {
    const { loadingChannels, channels, selectedChannel, integrationId, integrations } = this.state;
    return (
        <div className="slack-section">
          { integrationId && (
          <div>
            { integrations && integrations.length > 1 && (
              <div>
               <div>Use Slack integration connected by</div>
               <Dropdown
                 className="slack-integration-dropdown"
                 fluid={true}
                 selection={true}
                 placeholder="Select Integration"
                 options={integrations.map(integration => {
                   return {
                     value: integration.id,
                     key: integration.id,
                     text: `${integration.authorizedBy} on ${integration.authorizedAt}`
                   };
                 })}
                 value={integrationId}
                 onChange={ (e, {value}) => {this.setState({integrationId: value as string}); }}
               />
              </div>
            )}
            <div>Send to</div>
            <Dropdown
              className="slack-channel-dropdown"
              loading={loadingChannels}
              fluid={true}
              selection={true}
              search={true}
              placeholder="Select Channel"
              options={channels.map(channel => {
                return {
                  value: channel.id,
                  key: channel.id,
                  text: channel.name
                };
              })}
              value={selectedChannel}
              onChange={this.selectSlackChannel}
            />
            <div>This will require the&nbsp;
            <a
              href="https://getthematic.slack.com/apps/A0311C0J211-thematic?tab=more_info"
              target="_blank"
              rel="noopener noreferrer"
            >
              Thematic Slack App
            </a>
            &nbsp;to be added to the channel. You can do this by writing the command in slack:</div>
            <pre>/invite @Thematic #&lt;channel_name&gt;</pre>
            <div>
              <Button onClick={this.sendTestMessage} disabled={!selectedChannel}>Send test message</Button>
            </div>

          </div>
        )}
        { !integrationId && (
          <div>
            {this.renderConnectIntegration()}
          </div>
        )}

        </div>
    );
  }
}

export default SlackAction;