import { FilterType } from 'api/enums';
import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { FilterOption, selectAllChildren } from 'lib/filters/hierarchical-filter-helper';
import { reduce } from 'lodash';
import { TagInfo } from 'stores/TagStore';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';

export const buildTagRql = (id: string, commentColumns: number | number[]) => {
  let rql: string[] = [];
  // if the type of commentColumns is number that means it only has one comment column
  if (typeof commentColumns === 'number') {
    rql.push(`tags_${ commentColumns }==${ id }`);
  } else {
    rql = reduce(
      commentColumns,
      (result: string[], commentColumn) => {
        result.push(`tags_${ commentColumn }==${ id }`);
        return result;
      },
      [],
    );
  }
  return rql.join(',');
};

export const createTagFilterOptions = (tags: TagInfo[], queryFilter?: QueryFilter): FilterOption[] => {
  const selected = queryFilter?.selected;
  return tags
    .filter((tag) => !tag.parentId)
    .map((parentTag) => {
      const isSelected = !!selected?.length && selected.some((s) => s.id === parentTag.id);
      return {
        id: parentTag.id,
        name: parentTag.name,
        isSelected,
        children: tags
          .filter((tag) => tag.parentId === parentTag.id)
          .map((childTag) => {
            const isChildSelected = !!selected?.length && selected.some((s) => s.id === childTag.id);
            return {
              id: childTag.id,
              name: childTag.name,
              isSelected: isChildSelected,
            };
          }),
      };
    });
};

export const getTagCuts = (
  ids: string[],
  sortedTags: TagInfo[],
  commentColumns: number[]

): AnalysisFilterCut[] => {
  return sortedTags.reduce((result, tag: TagInfo) => {

    const selectedChildren = tag.parentId && ids.includes(tag.parentId)
      ? [{
          id: tag.id,
          name: tag.name,
          rql: buildTagRql(tag.id, commentColumns)
        }]
      : [];


    if (ids.includes(tag.id)) {
      const cut = {
        id: tag.id,
        name: tag.name,
        rql: buildTagRql(tag.id, commentColumns),
      };
      return [...result, cut, ...selectedChildren];
    }

    return [...result, ...selectedChildren]
  }, []);
};

export const createTagQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  options: FilterOption[],
  commentColumns: number[] | number,
) => {
  const selected: AnalysisFilterCut[] = [];
  options.forEach((parent) => {
    if (parent.isSelected) {
      selected.push({ id: parent.id, name: parent.name, rql: buildTagRql(parent.id, commentColumns) });
    }
    parent.children?.forEach((child) => {
      if (child.isSelected) {
        selected.push({
          id: child.id,
          name: child.name,
          rql: buildTagRql(child.id, commentColumns),
        });
      }
    });
  });

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'tags',
    selected,
  };
};

export const createTagSearchQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  cut: AnalysisFilterCut,
  commentColumns: number[] | number,
  tags: TagInfo[],
  currentQueryFilter?: QueryFilter,
) => {
  const tagOptions = createTagFilterOptions(tags, currentQueryFilter);
  let updatedOptions: FilterOption[] = [];
  updatedOptions = tagOptions.map((p) => {
    if (p.id === cut.id) {
      return {
        ...p,
        isSelected: !p.isSelected,
        children: p.children?.map((c) => selectAllChildren(c, !p.isSelected)),
      };
    } else {
      if (p.children) {
        return {
          ...p,
          children: p.children.map(child => {
            if (child.id === cut.id) {
              return {
                ...child,
                isSelected: !child.isSelected
              };
            } else {
              return child;
            }
          })
        };
      } else {
        return p;
      }
    }
  });

  const selected: AnalysisFilterCut[] = [];
  updatedOptions.forEach((parent) => {
    if (parent.isSelected) {
      selected.push({ id: parent.id, name: parent.name, rql: buildTagRql(parent.id, commentColumns) });
    }
    parent.children?.forEach((child) => {
      if (child.isSelected) {
        selected.push({
          id: child.id,
          name: child.name,
          rql: buildTagRql(child.id, commentColumns),
        });
      }
    });
  });

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'tags',
    selected,
  };
};

export const createTagFilter = (): AnalysisFilter => ({
  cuts: [],
  id: 'tags',
  name: 'Labels',
  type: FilterType.TAGS,
});
