<template>
  <el-dialog
    v-if="!isErrorDialogVisible"
    :visible="true"
    :show-close="false"
    width="50%"
    :modal-append-to-body="false"
    class="delete-dashboard-dialog"
  >
    <div class="dialog-content">
      <div class="content-icon">
        <font-awesome-icon
          class="trash-icon"
          icon="trash-alt"
        />
      </div>
      <div class="content">
        <div class="content-header">
          Do you want to delete {{ dashboardName }}?
        </div>
        <div class="content-description">
          You can't undo this action.
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="closeDeleteDialog"> Cancel </el-button>
      <el-button
        type="danger"
        :loading="dashboardStore.deletingDashboard"
        @click="deleteDashboard"
      >Delete</el-button>
    </span>
  </el-dialog>
  <el-dialog
    v-else
    :visible="true"
    :show-close="false"
    width="40%"
    :modal-append-to-body="false"
    class="error-dialog"
  >
    <div class="dialog-content">
      <font-awesome-icon
        class="exclamation-icon"
        icon="exclamation-triangle"
      />
      <span class="content">{{ dashboardStore.deleteDashboardError }}</span>
    </div>
    <span slot="footer">
      <el-button
        type="primary"
        @click="closeErrorDialog"
      > Close </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDashboardStore, getActiveDashboardUIStore, getOrganizationStore } from 'stores/RootStore';

export default {
  name: 'DeleteDashboard',
  props: {
    dashboardName: { type: String },
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
      dashboardStore: getDashboardStore(),
      organizationStore: getOrganizationStore(),

      isErrorDialogVisible: false,
    };
  },
  methods: {
    closeDeleteDialog() {
      this.$emit('onCloseDeleteDialog');
    },
    async deleteDashboard() {
      const dashboardId = this.activeDashboardUIStore.currentDashboardId;
      await this.dashboardStore.deleteDashboard(dashboardId);

      if (this.dashboardStore.deleteDashboardError) {
        this.openErrorDialog();
      } else {
        this.activeDashboardUIStore.stopEditingDashboard();
        const orgId = this.organizationStore.orgId;
        window.location.href = `/#/c/${orgId}`;
      }
    },
    openErrorDialog() {
      this.isErrorDialogVisible = true;
    },
    closeErrorDialog() {
      this.isErrorDialogVisible = false;
      this.$emit('onCloseDeleteDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.delete-dashboard-dialog {
  .dialog-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .content-icon {
      border: 1px solid $--red-500;
      border-radius: 50%;
      margin-right: 18px;
      padding: 10px 12px;
      background-color: $--red-500;
      .trash-icon {
        color: $--color-white;
        font-size: 26px;
        font-weight: 400;
      }
    }
    .content {
      .content-header {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .content-description {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.error-dialog {
  .dialog-content {
    display: flex;
    align-items: center;
    .exclamation-icon {
      font-size: 26px;
      margin-right: 10px;
      color: $--red-500;
    }
    .content {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>

<style lang="scss">
.delete-dashboard-dialog {
  .el-dialog__body {
    padding-top: 5px;
  }
}
.error-dialog {
  .el-dialog__body {
    padding-top: 5px;
  }
}
</style>