import ThemeSelect from 'components/ThemeDiscovery/ThemeSelect';
import * as React from 'react';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { Dropdown, DropdownMenu } from 'semantic-ui-react';
import './theme-select-dropdown.scss';
import { ThemeCardInterface } from 'components/ThemeTree/ThemeTree';

interface Props {
  children?: React.ReactNode;
  direction?: 'left' | 'right';
  groupId: string;
  onThemeChange: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
  hideSubThemes?: boolean;
  onClose: () => void;
  open: boolean;
  onOpen?: () => void;
  trigger: React.ReactNode;
  multipleSelectItems?: ThemeCardInterface[];
}

const ThemeSelectDropdown = ({
  children,
  direction,
  groupId,
  multipleSelectItems,
  onThemeChange,
  hideSubThemes,
  onClose,
  onOpen,
  open,
  trigger,
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  React.useEffect(() => {
    if (!inputRef.current?.firstChild) {
      return;
    }

    if (open) {
      (inputRef.current.firstChild as HTMLInputElement).focus();
    } else {
      (inputRef.current.firstChild as HTMLInputElement).blur();
    }
  }, [open]);

  const multipleSelectIds = multipleSelectItems?.map(({ id }) => id);

  return (
    <Dropdown
      className="theme-select-dropdown"
      closeOnBlur={false}
      direction={direction}
      icon={null}
      onClose={onClose}
      onOpen={handleOpen}
      open={open}
      trigger={trigger}
    >
      <>
        {open && (
          <DropdownMenu open={open} onClick={handleDropdownClick}>
            <ThemeSelect
              excludeIds={multipleSelectIds}
              groupId={groupId}
              hasOther={false}
              themesOnly={hideSubThemes}
              isMerge={false}
              isThemesDiscoveryItem={true}
              onChange={onThemeChange}
              themeSelectionDropdown={true}
              inputRef={inputRef}
            />
            {children}
          </DropdownMenu>
        )}
      </>
    </Dropdown>
  );
};

export { ThemeSelectDropdown };
