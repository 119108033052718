import { InputColumnType } from 'api/enums';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import 'components/Shared/button.scss';
import { Column } from './interfaces';

export interface SampleDataProps {
  columns: Column[];
  columnAlphabets: { [key: number]: string };
  hasHeader: boolean;
}

export class SampleData extends React.Component<SampleDataProps> {
  render() {
    const { columnAlphabets, hasHeader, columns } = this.props;

    return (
      <>
        <div className="sample-table nw-sample-data">
          {!isEmpty(columns) && <Table>
            <Table.Header>
              <Table.Row>
                {columns.map((c, i) => {
                  return (
                    <Table.HeaderCell key={i} role="columnheader">
                      {<div className="column-index nw-column-header">Column {columnAlphabets[i]}</div>}
                      {hasHeader && <div className="column-name nw-column-name">{c.sampleHeader}</div>}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* We only care about the first 5 or 6 (when first row is header) sample rows */}
              {Array.from(Array(6)).map((_s, i) => {
                if (hasHeader) {
                  if (i === 0 || i > 5) {
                    return;
                  }
                } else if (i > 4) {
                  return;
                }
                return (
                  <Table.Row key={i} className="nw-sample-row" role="row">
                    {columns.map((c, index) => {
                      if (c.type === InputColumnType.TEXT) {
                        return <Table.Cell
                          className="text-column"
                          singleLine={true}
                          key={index}
                        >
                          {c.sample[i]}
                        </Table.Cell>;
                      }
                      return <Table.Cell singleLine={true} key={index}>{c.sample[i]}</Table.Cell>;
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>}
        </div>
      </>
    );
  }
}

export default SampleData;
