<template>
  <el-dialog
    :visible="true"
    :show-close="false"
    title="Dashboard settings"
    class="configure-dashboard"
    :modal-append-to-body="false"
  >
    <div class="config-fields">
      <div class="config-field">
        <label>Name</label>
        <el-input
          v-model="dashboardName"
          placeholder="Enter a name for your dashboard"
        />
      </div>
      <div class="config-field">
        <label>Menu name</label>
        <el-input
          v-model="menuName"
          placeholder="Menu name"
        />
      </div>
      <div class="config-field">
        <label>Data sources</label>
        <configure-dashboard-sources @onSourcesUpdate="onSourcesUpdate" />
      </div>
      <div class="config-field">
        <label>Description (optional)</label>
        <el-input
          v-model="dashboardDescription"
          type="textarea"
          placeholder="Enter a short description for your dashboard"
        />
      </div>
    </div>
    <div class="configure-dashboard-actions">
      <el-button
        class="delete-dashboard"
        @click="openDeleteDialog"
      >
        <font-awesome-icon icon="trash-alt" /> Delete dashboard
      </el-button>
      <div>
        <el-button
          :disabled="isSaving"
          @click="cancel"
        >
          Cancel
        </el-button>
        <el-button
          type="primary"
          :loading="isSaving"
          :disabled="!isValid || isSaving"
          @click="done"
        >
          Done
        </el-button>
      </div>
    </div>
    <div v-if="isDeleteDialogVisible">
      <delete-dashboard
        :dashboard-name="dashboardName"
        @onCloseDeleteDialog="closeDeleteDialog"
      />
    </div>
  </el-dialog>
</template>


<script>
import { getActiveDashboardUIStore } from 'stores/RootStore';
import ConfigureDashboardSources from './ConfigureDashboardSources.vue';
import DeleteDashboard from './DeleteDashboard.vue';

export default {
  name: 'ConfigureDashboard',
  components: { ConfigureDashboardSources, DeleteDashboard },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),

      menuName: '',
      dashboardName: '',
      dashboardDescription: '',
      sources: {},

      dashboardNameTarget: 'title',
      dashboardDescriptionTarget: 'metadata.description',
      sourcesTarget: 'sources',

      isSaving: false,
      areSourcesValid: true,

      isDeleteDialogVisible: false,
    };
  },
  computed: {
    isValid() {
      return !!this.dashboardName && this.areSourcesValid;
    },
  },
  mounted() {
    this.menuName = this.activeDashboardUIStore.name;
    this.dashboardName = this.activeDashboardUIStore.getValue(this.dashboardNameTarget);
    this.dashboardDescription = this.activeDashboardUIStore.getValue(this.dashboardDescriptionTarget);
    this.sources = this.activeDashboardUIStore.getValue(this.sourcesTarget);
  },
  methods: {
    async done() {
      this.activeDashboardUIStore.setValue(this.dashboardNameTarget, this.dashboardName);
      this.activeDashboardUIStore.setValue(this.dashboardDescriptionTarget, this.dashboardDescription);
      this.activeDashboardUIStore.setValue(this.sourcesTarget, this.sources);
      this.activeDashboardUIStore.setDashboardMenuName(this.menuName || this.dashboardName);

      this.$emit('onDone');
    },
    cancel() {
      this.$emit('onCancel');
    },
    onSourcesUpdate(sources, areSourcesValid) {
      this.sources = sources;
      this.areSourcesValid = areSourcesValid;
    },
    openDeleteDialog() {
      this.isDeleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.isDeleteDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.configure-dashboard {
  .configure-dashboard-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .delete-dashboard {
      border: transparent;
      color: $--red-700;
      padding-left: 0;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}
</style>