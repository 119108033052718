



























import ThemeItem from 'vue/components/Themes/ThemeItem.vue';

export default {
  name: 'CommentThemesList',
  components: {
    ThemeItem
  },
  props: {
    fitContent: { type: Boolean, default: false },
    items: {
      validator(val) {
        return Array.isArray(val) && val.every(item => {
          return typeof item.theme.title === 'string'
            && typeof item.sentiment === 'number'
            && typeof item.isRemoved === 'boolean'
            && typeof item.isNew === 'boolean'
            && typeof item.hasBackgroundColour === 'boolean'
        });
      }
    },
    canManageThemes: { type: Boolean, required: true },
    areThemesInApplyingState: { type: Boolean, required: true },
    hasRemoveButtons: { type: Boolean, default: true },
    hasSentiment: { type: Boolean, required: true },
    isSentimentEnabled: {type: Boolean, default: true}
  },
};

