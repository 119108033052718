




























import { copyText } from '../../../lib/clipboard.ts';

export default {
  name: 'CopyToClipboard',
  props: {
    text: { type: String },
    popupText: { type: String }
  },
  data() {

    return {
      copyButtonClicked: false,
    };
  },
  methods: {
    copy() {
      this.copyButtonClicked = true;
      copyText(this.text);
      this.$emit('textCopied');
    },
  }
};
