import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Form, FormInputProps, Input } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceGoogleSheetsCreateFormProps {
  existingConfiguration?: GoogleSheetsConfig;
  datasetName?: string;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

interface GoogleSheetsInfo {
  title: string;
  sheets: {
    title: string;
    rowCount: number;
    columnCount: number;
  };
}

interface GoogleSheetsConfig {
  spreadsheetId: string;
}

interface DataSourceGoogleSheetsCreateFormState {
  spreadsheetURL: string;
  spreadsheetDetail?: GoogleSheetsInfo;
  config: GoogleSheetsConfig;
}

@observer
class DataSourceGoogleSheetsCreateForm extends React.Component<
DataSourceGoogleSheetsCreateFormProps,
DataSourceGoogleSheetsCreateFormState
> {
  state = {
    spreadsheetURL: '',
    spreadsheetDetail: undefined,
    config: {
      spreadsheetId: ''
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;
    if (existingConfiguration) {
      this.setState({
        spreadsheetURL: `https://docs.google.com/spreadsheets/d/${ existingConfiguration.spreadsheetId }`,
        config: { spreadsheetId: existingConfiguration.spreadsheetId },
      });
    }

    if (this.props.setValidity) {
      this.props.setValidity(false);
    }
  }

  getSpreadsheetId(spreadsheetURL: string) {
    const regex = '/spreadsheets/d/([a-zA-Z0-9-_]+)';
    const regexMatches = spreadsheetURL.match(regex);
    if (regexMatches && regexMatches.length > 1) {
      return regexMatches[1];
    }
    return '';
  }

  changeSelectedSpreadsheet = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    // update the config
    const spreadsheetId = this.getSpreadsheetId(value);
    const config = {
      spreadsheetId
    };

    this.setState({ spreadsheetURL: value, config });

    if (this.props.setValidity) {
      this.props.setValidity(!!spreadsheetId);
    }
  };

  renderForm(spreadsheetDetail: GoogleSheetsInfo | undefined): JSX.Element | null {
    const { spreadsheetURL } = this.state;

    return (
      <Form role="form">
        <Form.Field>
          <label>Spreadsheet URL</label>
          <Input
            placeholder="Spreadsheet URL"
            value={spreadsheetURL}
            onChange={this.changeSelectedSpreadsheet}
          />
        </Form.Field>
        {spreadsheetURL && (
          <div>
            {spreadsheetDetail && (
              <Form.Field>
                <label>Selected Spreadsheet</label>{' '}
                <span>{spreadsheetDetail.title}</span>
              </Form.Field>
            )}
          </div>
        )}
      </Form>
    );
  }

  render(): JSX.Element | null {
    const { onChange, datasetName } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.GOOGLESHEETS}
          enumerationSelection={config} // this just happens to be the same as config for now
          datasetName={datasetName}
        />
      </div>
    );
  }
}

export default DataSourceGoogleSheetsCreateForm;
