

































import analytics from 'lib/analytics';
import { getExportInsightUIStore } from 'stores/RootStore';
import InsightConfigForm from "./InsightConfigForm.vue";
import InsightPreview from "./InsightPreview.vue";
import { BarChartInsight, ExportableInsight } from 'types/custom';
import downloadAsPNG from 'vue/libs/downloadAsPNG';
import PopupManager from 'element-ui/src/utils/popup/popup-manager';
import { capitalize } from 'lodash';

export default {
  name: 'ExportInsight',
  components: {
    InsightConfigForm,
    InsightPreview
  },
  data() {
    return {
      exportInsightUIStore: getExportInsightUIStore(),
    }
  },
  computed: {
    insights(): ExportableInsight[] {
      return this.exportInsightUIStore.insights;
    },
    isDialogVisible() {
      return this.exportInsightUIStore.isVisible;
    }
  },
  watch: {
    isDialogVisible: {
      handler(isDialogVisible) {
        if (isDialogVisible) {
          document.addEventListener('keydown', this.handleKeyPress);
        } else {
          document.removeEventListener('keydown', this.handleKeyPress);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyPress);
  },
  methods: {
    isDownloadDisabled() {
      if (!this.isDialogVisible) {
        return true;
      }
      const noFieldsAreAvailable = (this.exportInsightUIStore.insights[0] as BarChartInsight).fields.length === 0;
      return noFieldsAreAvailable;
    },
    onDialogClose() {
      this.exportInsightUIStore.hide();
    },
    onDownload: async function () {
      const label = capitalize((this.insights[0] as BarChartInsight).chart.countLabel);
      const shouldShowComparison = (this.insights[0] as BarChartInsight).chart.shouldShowComparison;
      const numberOfThemeSelected = (this.insights[0] as BarChartInsight).fields.filter(field => field.isSelected).length;
      // if fields only have one object in it that means subthemes are selected
      const areSubThemesSelected = (this.insights[0] as BarChartInsight).fields.length === 1;
      const numberOfSubThemeSelected = areSubThemesSelected ? (this.insights[0] as BarChartInsight).fields[0].children!.filter(field => field.isSelected).length : 0;
      analytics.track('Export: PNG', {
        'Source': 'Analysis',
        'Data': `Theme ${label}`,
        'Comparison': shouldShowComparison,
        'Format': 'PNG',
        'Theme Count': numberOfThemeSelected,
        'Subtheme Count': numberOfSubThemeSelected
      });
      const dialogRef = this.$refs.shareableInsightsDialog as PopupManager;
      const targetedChildRef = dialogRef.$children.find(child => child.$el.className === 'insight-preview');
      if (targetedChildRef) {
        const targetedElement = targetedChildRef.$refs.insightPreviewContainer;
        await downloadAsPNG(targetedElement, this.exportInsightUIStore.fileName);
        await new Promise(res => setTimeout(res, 100));
        this.exportInsightUIStore.hide();
      }
    },
    handleKeyPress(event: KeyboardEvent) {
      if (event && event.key === 'Escape') {
        this.onDialogClose();
      }
    },
  }
};
