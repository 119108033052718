import CoffeeGal from 'images/coffee-gal-green.svg';
import * as React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import './setup-complete.scss';

interface SetupCompleteProps {
  onDone: () => void;
}

class SetupComplete extends React.Component<SetupCompleteProps> {
  render() {
    return <Segment className="setup-complete">
      <h4>Great job, you’re all done! We’ll take it from here.</h4>
      <CoffeeGal />
      <p>
        Our AI is busy analyzing your comments. Grab a coffee and relax, we’ll email you when your data is ready.
      </p>
      <Button
        className="done"
        color="blue"
        onClick={this.props.onDone}
      >
        Done
      </Button>
    </Segment>;
  }
}

export default SetupComplete;
