/*
  Returns Excel column header (A, B, C, ... AA, AB, etc.) for a given number
  Index passed starts from 0 like in arrays
*/
export function indexToColumnName(index: number) {
  let columnNumber = index + 1;
  let ret = '';
  let a = 1;
  let b = 26;
  for (ret = '', a, b; (columnNumber -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode((columnNumber % b) / a + 65) + ret;
  }
  return ret;
}