import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { each, map, reduce, sortBy } from 'lodash';
import { AnalysisFilterKey, FilterMatchType, QueryFilter } from 'stores/types';
import { FilterSearchResult } from './filter-search-helper';
import { FilterOption, getParentSelectionStateFromChildren } from './hierarchical-filter-helper';
import { FilterType } from 'api/enums';

export type ThemesObject = {
  [name: string]: {
    id: string,
    subthemes: {
      [name: string]: {
        id: string;
      }
    }
  }
};

export const buildThemeRql = (themeName: string, subthemeName?: string) => {
  if (themeName === 'untagged_comments') {
    return 'segment==theme:other';
  } else if (subthemeName) {
    return `theme==*!${ themeName }!${ subthemeName }$*`;
  }
  return `theme==*!${ themeName }!*`;
};

export const getThemeMatchType = (queryFilter?: QueryFilter) => queryFilter?.match || 'any';

export const createThemeFilterOptions = (
  themeObject: ThemesObject,
  queryFilter?: QueryFilter,
  isFeedbackTool?: boolean
) => {
  const selected = queryFilter?.selected;
  const themes = reduce(
    themeObject,
    (result: FilterOption[], theme, themeName) => {
      const isParentSelected = !!selected?.length && selected.some((s) => s.id === theme.id && s.name === themeName);
      result.push({
        id: theme.id,
        name: themeName,
        isSelected: isParentSelected,
        children:
          map(theme.subthemes, (subtheme, subthemeName) => {
            const isChildSelected =
              !!selected?.length && selected.some((s) => s.id === subtheme.id && s.name === subthemeName);
            return {
              id: subtheme.id,
              parentId: theme.id,
              name: subthemeName,
              isSelected: isChildSelected || isParentSelected,
            };
          })
      });
      return result;
    },
    [],
  );
  let sortedThemes = sortBy(themes, 'title');
  if (isFeedbackTool) {
    sortedThemes = [{
      children: [],
      id: 'untagged_comments',
      name: 'Untagged Segments',
      isSelected: !!selected?.length && selected.some((s) => s.id === 'untagged_comments')
    }, ...sortedThemes];
  }
  return sortedThemes;
};

const calculateThemeFilterRql = (rqls: string[], match: FilterMatchType) => {
  let combinedRqlString = '';
  each(rqls, rql => {
    if (combinedRqlString.length > 0) {
      if (match === 'any') {
        combinedRqlString = `(${ combinedRqlString }),(${ rql })`;
      } else {
        combinedRqlString = `(${ combinedRqlString });(${ rql })`;
      }
    } else {
      combinedRqlString = rql;
    }
  });
  return combinedRqlString;
};

export const createThemeQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  options: FilterOption[],
  match: FilterMatchType,
) => {
  let selected: AnalysisFilterCut[] = [];
  let rqlsArray: string[] = [];
  options.forEach((parent) => {
    if (parent.isSelected) {
      rqlsArray.push(buildThemeRql(parent.id));
      selected.push({ id: parent.id, name: parent.name, rql: '' });
    } else {
      parent.children?.forEach((child) => {
        if (child.isSelected) {
          rqlsArray.push(buildThemeRql(parent.id, child.id));
          selected.push({
            id: child.id,
            parentId: parent.id,
            name: child.name,
            rql: '',
          });
        }
      });
    }
  });
  const combinedRql = calculateThemeFilterRql(rqlsArray, match);
  if (selected.length > 0) {
    selected[0].rql = combinedRql;
  }

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'themes',
    match: match,
    selected,
  };
};

export const findThemeResultSelection = (updateOptions: FilterOption[], result: FilterSearchResult) => {
  // finding search result selection active state
  let isSelected;
  each(updateOptions, (option) => {
    if (option.id === result.id && option.name === result.source.name) {
      isSelected = option.isSelected;
      // when returning false it will stop the lodash each loop
      return false;
    } else if (option.children) {
      const selection = findThemeResultSelection(option.children, result);
      if (selection) {
        isSelected = selection;
      }
    }
    return;
  });
  return isSelected;
};

export const getThemeCuts = (ids: string[], themes: ThemesObject): AnalysisFilterCut[] => {
  const cuts = Object.entries(themes).reduce((result, [themeName, themeValue]) => {
    const themeId = themeValue.id;
    const subthemes = themeValue.subthemes;

    const subthemeCuts = Object.entries(subthemes).reduce((subthemesResult: AnalysisFilterCut[], [subthemeName, subthemeValue]) => {
      const subthemeId = subthemeValue.id;
      if (ids.includes(subthemeId)) {
        const subthemeCut = {
          id: subthemeId,
          parentId: themeId,
          name: subthemeName,
          rql: buildThemeRql(themeId, subthemeId)
        };
        return [...subthemesResult, subthemeCut];
      }
      return subthemesResult;
    }, []);

    if (ids.includes(themeId)) {
      const themeCut = {
        id: themeId,
        name: themeName,
        rql: buildThemeRql(themeId)
      };

      return [...result, themeCut, ...subthemeCuts];
    }
    return [...result, ...subthemeCuts];
  }, []);
  return cuts;
};

export const createThemesSearchQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  cut: AnalysisFilterCut,
  themes: ThemesObject,
  match: FilterMatchType,
  currentQueryFilter?: QueryFilter,
  isFeedbackTool?: boolean
) => {
  let rqlsArray: string[] = [];
  let updatedOptions: FilterOption[] = [];
  const themeFilterOptions = createThemeFilterOptions(themes, currentQueryFilter, isFeedbackTool);

  if (cut.children) {
    // reading parent click here
    const parent = themeFilterOptions.find(value => value.id === cut.id);
    updatedOptions = themeFilterOptions.map((p) => {
      if (p === parent) {
        return {
          ...p,
          isSelected: !p.isSelected,
          children: p.children?.map((c) => ({ ...c, isSelected: !p.isSelected })),
        };
      } else {
        return p;
      }
    });
  } else {
    // reading child click here
    const parent = themeFilterOptions.find(val =>
      val.children?.some(child => child.id === cut.id)
    );
    updatedOptions = themeFilterOptions.map((p) => {
      if (p === parent) {
        return {
          ...p,
          children: p.children?.map((c) => {
            if (c.id === cut.id) {
              return { ...c, isSelected: !c.isSelected };
            } else {
              return c;
            }
          })
        };
      } else {
        return p;
      }
    });
  }

  const newSelectedCuts: AnalysisFilterCut[] = [];

  for (let i = 0; i < updatedOptions.length; i++) {
    const parent = updatedOptions[i];
    const parentSelection = getParentSelectionStateFromChildren(parent);

    if (parentSelection === 'all' || (parent.id === 'untagged_comments' && parent.isSelected)) {
      rqlsArray.push(buildThemeRql(parent.id));
      newSelectedCuts.push({ id: parent.id, name: parent.name, rql: '' });
    } else if (parentSelection === 'some' && parent.children) {
      const selectedChildCuts = parent.children
        .filter((child) => child.isSelected)
        .map((child) => {
          rqlsArray.push(buildThemeRql(parent.id, child.id));
          return { id: child.id, parentId: child.parentId, name: child.name, rql: '' };
        });

      newSelectedCuts.push(...selectedChildCuts);
    }
  }
  const combinedRql = calculateThemeFilterRql(rqlsArray, match);

  if (newSelectedCuts.length > 0) {
    newSelectedCuts[0].rql = combinedRql;
  }

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'themes',
    match: match,
    selected: newSelectedCuts,
  };
};

export const createThemeFilter = (): AnalysisFilter => ({
  cuts: [],
  id: 'themes',
  name: 'Themes',
  type: FilterType.THEMES,
});
