








































import { WidgetSummaryType } from 'api/enums';
import analytics from 'lib/analytics';
import { ReactInVue } from 'vuera';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import CopyToClipboard from "vue/components/CopyToClipboard/CopyToClipboard.vue";
import ReviewControls from 'vue/components/Summary/ReviewControls.vue';
import {
  getActiveDashboardUIStore,
  getAnalysisToolsStore,
} from 'stores/RootStore';

export default {
  name: 'SUMMARY',
  components: {
    CopyToClipboard,
    WidgetWrapper: ReactInVue(WidgetWrapper),
  },
  props: {
    config: { type: Object },
    context: { type: Object },
    data: { type: Object },
    warning: { type: String },
    error: { type: String },
    limit: { default: 5, type: Number },
    loading: { type: Boolean },
    source: { type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
    sourceKey: { type: String }
  },
  data() {
    return {
      analysisToolsStore: getAnalysisToolsStore(),
      activeDashboardUIStore: getActiveDashboardUIStore(),
    };
  },
  computed: {
    summary():string {
      return this.data?.summary ?? '';
    },
    widgetSubtitle():string {

      switch(this.config.summaryType) {
        case WidgetSummaryType.ISSUE: { return 'Summary of issues' }
        case WidgetSummaryType.REQUEST: { return 'Summary of requests' }
        case WidgetSummaryType.QUESTION: { return 'Summary of questions' }
        case WidgetSummaryType.NEGATIVE: { return 'Summary of negative feedback' }
        case WidgetSummaryType.POSITIVE: { return 'Summary of positive feedback' }
        default: {
          const title = this.getSourceTitle() || 'feedback';
          return `Summary of ${title}`;
        }
      }
    }
  },
  methods: {
    getLocationForFeedback() {
      const dashboardId = this.activeDashboardUIStore.currentDashboardId;

      return `dashboard:${dashboardId}`;
    },
    getSourceTitle() {

      const source = this.activeDashboardUIStore.sources[this.sourceKey];

      if (!source) {
        return null;
      }

      const { survey, view, visualization } = source;
      return this.analysisToolsStore.getAnalysisTitle(survey, visualization, view);

    },
    onMouseUp() {
      const selectedText = window.getSelection().toString();
      if (this.data.summary.includes(selectedText) && selectedText.length > 0) {
        analytics.track('Dashboard: Select Summary');
      }
    },
    onTextCopied() {
      analytics.track('Dashboard: Copy Summary');
    },
    subtitleNodes() {
      return this.$createElement('div', {
        class: 'widget-subtitle'
      }, [
        this.$createElement('div', {
          class: 'widget-subtitle-text'
        }, this.widgetSubtitle),
        !this.config.title ? this.$createElement(ReviewControls, {
          class: 'widget-header__controls',
          props: {
            summary: this.summary,
            featureId: 'summarization',
            location: this.getLocationForFeedback()
          }
        }) : null
      ]);
    },
    titleNodes() {
      return this.config.title ? this.$createElement(ReviewControls, {
        class: 'widget-header__controls',
        props: {
          summary: this.summary,
          featureId: 'summarization',
          location: this.getLocationForFeedback()
        }
      }) : null;
    }
  }
};
