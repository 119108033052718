
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisualizationSection } from 'api/interfaces';
import analytics from 'lib/analytics';
import * as React from 'react';
import { Button, ButtonGroup } from 'semantic-ui-react';
import { AnswersVisualizationOptions } from 'types/custom';
import { VisualizationBarChart } from './VisualizationBarChart';
import { VisualizationBarTable } from './VisualizationBarTable';
import { VisualizationWaterfallChart } from './VisualizationWaterfallChart';
import { VisualizationWaterfallTable } from './VisualizationWaterfallTable';
import './visualization-answer-part.scss';

interface VisualizationAnswerPartProps {
  content: VisualizationSection;
  showTitle?: boolean;
}

interface VisualizationAnswerPartState {
  viewChoice: string;
}

export class VisualizationAnswerPart extends React.Component<
  VisualizationAnswerPartProps,
  VisualizationAnswerPartState
> {
  state = {
    viewChoice: AnswersVisualizationOptions.VISUALIZATION
  };

  handleViewSelection = (viewChoice: AnswersVisualizationOptions) => {
    this.setState({ viewChoice });
    analytics.track('Answers: Answers Item', { 'Type': viewChoice === AnswersVisualizationOptions.VISUALIZATION ? 'Chart' : 'Table' });
  }

  renderVisualization(content: VisualizationSection) {
    switch (content.visType) {
      case 'bar':
      case 'line':
        return (
          <VisualizationBarChart
            content={content}
          />
        );
      case 'waterfall':
        return (
          <VisualizationWaterfallChart
            content={content}
          />
        );
      default:
        return null;
    }
  }

  renderTable(content: VisualizationSection) {
    switch (content.visType) {
      case 'bar':
      case 'line':
        return (
          <VisualizationBarTable
            content={content}
          />
        );
      case 'waterfall':
        return (
          <VisualizationWaterfallTable
            content={content}
          />
        );
      default:
        return null;
    }
  }

  render() {
    // Conditionally renders a visualization or table
    // The table is always rendered, but hidden if the visualization is the selected view
    // This is to ensure the table is 'copied' when a copy content is used
    // The visualization is marked as 'do-not-copy' so will be ignored during copy operations
    // The table is marked as 'ensure-contents-copied' so will be included during copy operations even if it is hidden

    const { viewChoice } = this.state;
    const { content, showTitle } = this.props;

    return (
      <div className="answers__visualization-part">
        {showTitle && (<h2>{content.title}</h2>)}
        {viewChoice === AnswersVisualizationOptions.VISUALIZATION && (
          <section className="do-not-copy">
            {this.renderVisualization(content)}
          </section>
        )}
        <section className={viewChoice !== AnswersVisualizationOptions.TABLE ?
          'hide-table ensure-contents-copied' : ''}>
          {this.renderTable(content)}
        </section>
        <ButtonGroup className="content-options" size="small">
          <Button
            primary={viewChoice === AnswersVisualizationOptions.VISUALIZATION}
            onClick={() => this.handleViewSelection(AnswersVisualizationOptions.VISUALIZATION)}
          >
            <FontAwesomeIcon
              icon="chart-bar"
              className="icon"
              fixedWidth={true}
            />
          </Button>
          <Button
            primary={viewChoice === AnswersVisualizationOptions.TABLE}
            onClick={() => this.handleViewSelection(AnswersVisualizationOptions.TABLE)}
          >
            <FontAwesomeIcon
              icon="table"
              className="icon"
              fixedWidth={true}
            />
          </Button>
        </ButtonGroup>
      </div>
    );

  }
}
