import * as d3 from 'd3';
import d3tip, { D3Tip } from 'd3-tip';
import colors from 'styling/variables/colors.scss';
import Vue from 'vue';
import { calculateTickInterval } from './bargraphHelpers';
import { CombinedScoreStats } from './types';

interface Point {
  x: number;
  y: number;
  count: number;
  total: number;
  label: string;
}

export type RenderScoreGraphDataArgs =  {
  el: Vue | Element | Vue[] | Element[] | HTMLElement,
  options: {
    data: CombinedScoreStats[];
    baselineTitle: string;
    comparisonTitle: string;
    labels: string[];
    chartLabel: string;
    barChartWidth: number;
    chartUnit: string;
    isComparison: boolean;
    startFromZero: boolean;
    tipValueUnit: string;
    showTotalCommentsInTip: boolean;
    selectedChartOption: string | null;
    onScoreGraphClick: (label: string) => void;
  },
  tip: D3Tip,
};

const CHART_HEIGHT = 250;
const CHART_LINE_COLOR = colors.neutral200;
const MIN_WIDTH_FOR_VERTICAL_LABELS = 722;
const VERTICAL_LABEL_THRESHOLD = 11;
const THRESHOLD_TO_SKIP_DISPLAYED_LABELS = 500;
const ADDITIONAL_BOTTOM_MARGIN_FOR_VERTICAL_LABELS = 25;

function getMaxLabelWidth(labels: string[]): number {

  // Create a temporary SVG element to measure text width
  const tempSvg = d3.select('body')
    .append('svg')
    .attr('width', 0)
    .attr('height', 0)
    .style('visibility', 'hidden');

  // Iterate through the labels and measure their width
  const labelWidths = labels.map(label => {
    const text = tempSvg
      .append('text')
      .text(label)
      .attr('font-family', 'Open Sans')
      .attr('font-weight', 'bold');
    const width = text.node()?.getBBox().width ?? 0;
    text.remove();
    return width;
  });

  tempSvg.remove();

  return Math.max(...labelWidths);
}

function scoreStatsToBaselinePoint(stats: CombinedScoreStats, index: number): Point {
  return {
    x: index,
    y: stats.baselineVolume,
    count: stats.baselineCount,
    total: stats.baselineTotal,
    label: stats.label
  };
}

function scoreStatsToComparisonPoint(stats: CombinedScoreStats, index: number): Point {
  return {
    x: index,
    y: stats.comparisonVolume,
    count: stats.comparisonCount,
    total: stats.comparisonTotal,
    label: stats.label
  };
}

function renderTipData(point: Point, chartOptions: RenderScoreGraphDataArgs['options']) {
  return `
    <div class="score-graph-tip">
      ${chartOptions.showTotalCommentsInTip ?
        `<h3 class="score-graph-tip__label" style="margin-bottom: 4px;">
          ${point.label}
        </h3>
        <div class="score-graph-tip__description">
          ${point.y.toFixed(1)}${chartOptions.tipValueUnit} (${point.count.toLocaleString()} / ${point.total.toLocaleString()})
        </div>`
      :
        `${point.label}: ${point.y.toFixed(1)}${chartOptions.tipValueUnit}`
      }
    </div>
    <div x-arrow class="popper__arrow" style="top:50%;transform:translateY(-50%);"></div>
  `;
}

function getAllValues(
  baselineValues: number[],
  comparisonValues: number[],
  options: RenderScoreGraphDataArgs['options']
): number[] {
  const combinedValues: number[] = options.isComparison ? [...baselineValues, ...comparisonValues] : baselineValues;
  return options.startFromZero ? [0, ...combinedValues] : combinedValues;
}

function roundToOneDecimalPlace(value: number) {
  return Math.round(value * 10) / 10;
}

function getScoreGraphToolTip(options: RenderScoreGraphDataArgs['options']) {
  return d3tip()
    .attr('class', 'el-tooltip__popper is-dark el-tooltip__popper-no-mouse')
    .attr('x-placement', 'right')
    .direction('e')
    .offset([0, -5])
    .html((point: Point) => {
      return renderTipData(point, options);
    });
  }

function renderScoreGraphData(
  {
    el,
    options,
    tip
  }: RenderScoreGraphDataArgs
) {
  // For expediency, erase any existing chart and build a new one.
  d3.select(el).select('svg').remove();

  const baselineValues: number[] = options.data.map((values) => values.baselineVolume);
  const comparisonValues: number[] = options.data.map((values) => values.comparisonVolume);
  const allValues: number[] = getAllValues(baselineValues, comparisonValues, options);

  const showVerticalChartLabels =
    options.labels.length > VERTICAL_LABEL_THRESHOLD
    || options.barChartWidth < MIN_WIDTH_FOR_VERTICAL_LABELS;
  const bottomMargin = (getMaxLabelWidth(options.labels) / 2)
    + (showVerticalChartLabels ? ADDITIONAL_BOTTOM_MARGIN_FOR_VERTICAL_LABELS : 0);

  const margin = { top: 20, right: 50, bottom: Math.max(bottomMargin, 50), left: 80};
  const chartWidth = options.barChartWidth - margin.left - margin.right;
  const chartHeight = CHART_HEIGHT - margin.top - margin.bottom;

  const svg = d3
    .select(el)
    .append('svg')
    .attr('width', chartWidth + margin.left + margin.right)
    .attr('height', chartHeight + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // x Axis/Scale -------------------------------------------------------------

  const xScale = d3
    .scaleBand()
    .domain(options.labels)
    .range([0, chartWidth])
    .padding(-1);

  const xAxisTickValues = options.barChartWidth < THRESHOLD_TO_SKIP_DISPLAYED_LABELS
    ? options.labels.filter((_, i) => i % 2 === 0)
    : options.labels;
  const xAxis = d3.axisBottom(xScale).tickSize(0).tickValues(xAxisTickValues);

  // y Axis/Scale  ------------------------------------------------------------

  const minValue = d3.min(allValues);
  const maxValue = d3.max([...allValues]);
  const yDomain = [roundToOneDecimalPlace(minValue), Math.ceil(maxValue * 2) / 2];

  const yScale = d3.scaleLinear().domain(yDomain).range([chartHeight, 0]);
  const tickInterval = calculateTickInterval(minValue, maxValue);

  const numberOfTicks = (yScale.domain()[1] - yScale.domain()[0]) / tickInterval;

  const yAxis = d3
    .axisLeft(yScale)
    .ticks(numberOfTicks)
    .tickFormat((t) => {
      return `${t}${options.chartUnit}`;
    })
    .tickSize(0);

  const tickValues = yScale.ticks(numberOfTicks);

  svg
    .append('g')
    .call(yAxis)
    .selectAll('text')
    .attr('font-family', '\'Open Sans\', sans-serif')
    .attr('transform', `translate(-10, 0)`)
    .style('fill', colors.primary900)
    .style('font-size', '12px');

  // Vertical lines behind the bars
  svg
    .selectAll('.tick-line')
    .data(tickValues)
    .enter()
    .append('line')
    .attr('class', 'tick-line')
    .attr('y1', (d) => yScale(d))
    .attr('x1', 0)
    .attr('y2', (d) => yScale(d))
    .attr('x2', chartWidth)
    .attr('stroke', CHART_LINE_COLOR)
    .attr('stroke-width', 1);

  // Tick text
  const xAxisTextTranslation = showVerticalChartLabels ? `translate(-5, 5) rotate(-45)` : `translate(0, 5)`;
  const xAxisTextAnchor = showVerticalChartLabels ? 'end' : 'middle';
  svg
    .append('g')
    .attr('transform', `translate(0, ${chartHeight})`)
    .call(xAxis)
    .selectAll('text')
    .attr('text-anchor', xAxisTextAnchor)
    .attr('font-family', '\'Open Sans\', sans-serif')
    .style('fill', colors.primary900)
    .style('font-size', '12px')
    .attr('transform', xAxisTextTranslation);

  const chartLabelTextTranslation =  showVerticalChartLabels
    ? `rotate(-90), translate(-75, -130)`
    : `rotate(-90), translate(-75, -150)`;

  // Axis label
  svg
    .append('text')
    .attr('y', chartHeight / 2)
    .attr('x', -15)
    .attr('text-anchor', 'middle')
    .attr('transform', chartLabelTextTranslation)
    .style('fill', colors.primary900)
    .attr('font-family', '\'Open Sans\', sans-serif')
    .style('font-weight', 'bold')
    .style('font-size', '11px')
    .text(options.chartLabel.toUpperCase());

  // Set axes and ticks color
  svg.selectAll('.domain, .tick line').attr('stroke', CHART_LINE_COLOR);

  // scales and data for line
  const baselinePoints = options.data.map((stats, index) => scoreStatsToBaselinePoint(stats, index));
  const lineXScale = d3.scaleTime()
    .domain([0, d3.max(baselinePoints, d => d.x)])
    .range([0, chartWidth]);

  const lineYScale = d3.scaleLinear()
    .domain([d3.min(allValues, d => d), Math.ceil(d3.max(allValues, d => d) * 2) / 2])
    .range([chartHeight, 0]);

  const shadedAreaStartingPoint = d3.min(allValues, (volume: number) => {
    if (volume < 0) {
      return -volume; // if the min value is negative, start the shaded area from 0
    }
    return volume;
  });
  const baselineGroup = svg.append('g');
  // different bg below line area
  baselineGroup.append('path')
    .datum(baselinePoints)
    .attr('fill', 'rgba(220, 223, 228, 0.5)') // Set the background color
    .attr('d', d3.area()
      .x((d: Point) => lineXScale(d.x))
      .y0(() => lineYScale(shadedAreaStartingPoint))
      .y1((d: Point) => lineYScale(d.y))
      .curve(d3.curveMonotoneX) // curve line
    );

  // Add the line
  baselineGroup
    .append('path')
    .datum(baselinePoints)
    .attr('fill', 'none')
    .attr('stroke', colors.primary500)
    .attr('stroke-width', 3)
    .attr('d', d3.line()
      .x(function(d: Point) { return lineXScale(d.x); })
      .y(function(d: Point) { return lineYScale(d.y); })
      .curve(d3.curveMonotoneX) // curve line
      );

  let selectedDotRect: d3.Selection | null = null;
  let selectedLabel: string | null = null;
  let previousDot: d3.Selection | null = null;
  // dots at the values over lines
  baselineGroup.selectAll('circle')
    .data(baselinePoints)
    .enter()
    .append('circle')
    .attr('cx', function(d: Point) { return lineXScale(d.x); })
    .attr('cy', function(d: Point) { return lineYScale(d.y); })
    .attr('r', 5) // radius of the circle
    .attr('fill', colors.primary500) // color of the circle
    .attr('data-id', (d: Point) => `circle-${d.label}`)
    .call(tip)
    .on('mouseover', function(this: SVGCircleElement, d: Point) {
      tip.show(d, this);
      d3.select(this)
        .attr('fill', colors.white)
        .attr('r', 5.5)
        .attr('stroke', colors.primary500);
    })
    .on('mouseout', function(this: SVGCircleElement, d: Point) {
      tip.hide(d, this);
      const dot = d3.select(this);
      const isHoveredOverSelectedDot = selectedLabel === d.label;
      dot
        .attr('fill', isHoveredOverSelectedDot ? colors.white : colors.primary500)
        .attr('r', 5)
        .attr('stroke', isHoveredOverSelectedDot ? colors.primary500 : 'none');
    })
    .on('click', function(this: SVGCircleElement, d: Point) {
      options.onScoreGraphClick(d.label);
      selectedLabel = d.label;
      const dot = d3.select(this);
      const dotX = parseFloat(dot.attr('cx'));
      const dotY = parseFloat(dot.attr('cy'));

      if (selectedDotRect) {
        selectedDotRect.remove(); // remove the previously selected rectangle
        previousDot // update the previously selected dot
          .attr('fill', colors.primary500);
      }

      if (selectedDotRect !== dot) {
        previousDot = dot;
        selectedDotRect = svg.append('rect')
          .attr('x', dotX - 5)
          .attr('y', dotY)
          .attr('width', 10)
          .attr('height', chartHeight - dotY)
          .attr('fill', colors.primary500)
          .attr('clip-path', 'inset(3px 0 0)');
      } else {
        selectedDotRect = null; // if the same dot is clicked again, reset the selectedDotRect variable
      }
    });

  if (options.isComparison) {
    const comparisonPoints = options.data.map((stats, index) => scoreStatsToComparisonPoint(stats, index));
    const comparisonLineXScale = d3.scaleTime()
      .domain([0, d3.max(comparisonPoints, d => d.x)])
      .range([0, chartWidth]);

    const comparisonLineYScale = d3.scaleLinear()
      .domain([
        d3.min(allValues, d => d),
        (Math.ceil(d3.max(allValues, d => d) * 2) / 2)
      ])
      .range([chartHeight, 0]);

    const comparisonGroup = svg.append('g');

    // Add the line
    comparisonGroup
      .append('path')
      .datum(comparisonPoints)
      .attr('fill', 'none')
      .attr('stroke', colors.orange500)
      .attr('stroke-width', 3)
      .attr('d', d3.line()
        .x(function(d: Point) { return comparisonLineXScale(d.x); })
        .y(function(d: Point) { return comparisonLineYScale(d.y); })
        .curve(d3.curveMonotoneX) // curve line
        );

    // dots at the values over lines
    comparisonGroup.selectAll('circle')
      .data(comparisonPoints)
      .enter()
      .append('circle')
      .attr('cx', function(d: Point) { return comparisonLineXScale(d.x); })
      .attr('cy', function(d: Point) { return comparisonLineYScale(d.y); })
      .attr('r', 5) // radius of the circle
      .attr('fill', colors.orange500) // color of the circle
      .attr('data-id', (d: Point) => `circle-${d.label}`)
      .call(tip)
      .on('mouseover', function(this: SVGCircleElement, d: Point) {
        tip.show(d, this);
        d3.select(this)
          .attr('fill', colors.white)
          .attr('r', 5.5)
          .attr('stroke', colors.orange500);
      })
      .on('mouseout', function(this: SVGCircleElement, d: Point) {
        tip.hide(d, this);
        const dot = d3.select(this);
        dot
          .attr('fill', colors.orange500)
          .attr('r', 5)
          .attr('stroke', colors.orange500);
      })
      .on('click', function(this: SVGCircleElement, d: Point) {
        options.onScoreGraphClick(d.label);
        const clickedCircle = d3.event.target;
        const circleId = d3.select(clickedCircle).attr('data-id');

        // Select the corresponding circle from baselineGroup
        const correspondingCircle = baselineGroup.select(`circle[data-id="${circleId}"]`);
        selectedLabel = d.label;
        const dot = correspondingCircle;
        const dotX = parseFloat(dot.attr('cx'));
        const dotY = parseFloat(dot.attr('cy'));

        if (selectedDotRect) {
          selectedDotRect.remove(); // remove the previously selected rectangle
          previousDot // update the previously selected dot
            .attr('fill', colors.primary500);
        }

        if (selectedDotRect !== dot) {
          previousDot = dot;
          selectedDotRect = svg.append('rect')
            .attr('x', dotX - 5)
            .attr('y', dotY)
            .attr('width', 10)
            .attr('height', chartHeight - dotY)
            .attr('fill', colors.primary500)
            .attr('clip-path', 'inset(3px 0 0)');
        } else {
          selectedDotRect = null; // if the same dot is clicked again, reset the selectedDotRect variable
        }
      });
  }
  if (options.selectedChartOption) {
    selectedLabel = options.selectedChartOption;
    const dot = baselineGroup.select(`circle[data-id="circle-${selectedLabel}"]`);
    const isDotPresent = dot.node();
    if (isDotPresent) {
      const dotX = parseFloat(dot.attr('cx'));
      const dotY = parseFloat(dot.attr('cy'));

      if (selectedDotRect) {
        selectedDotRect.remove(); // remove the previously selected rectangle
        previousDot // update the previously selected dot
          .attr('fill', colors.primary500);
      }

      if (selectedDotRect !== dot) {
        previousDot = dot;
        selectedDotRect = svg.append('rect')
          .attr('x', dotX - 5)
          .attr('y', dotY)
          .attr('width', 10)
          .attr('height', chartHeight - dotY)
          .attr('fill', colors.primary500)
          .attr('clip-path', 'inset(3px 0 0)');
      } else {
        selectedDotRect = null; // if the same dot is clicked again, reset the selectedDotRect variable
      }
    }
  }
}

export {
  getScoreGraphToolTip,
  renderScoreGraphData
};
