import { assign, forEach, isNumber, map, max, min, reject, sum } from 'lodash';
import { calculateSentiment } from 'lib/calculate-sentiment';
import toFixed from 'vue/libs/to-fixed';
import colors from 'vue/styles/element-variables.scss';

interface OriginalScore {
  name: string;
  type: string;
  score: number;
}

export interface Score {
  score: number;
  hist: number[];
  componentScores?: Score[];
  originalScore?: OriginalScore;
  npsCat?: {
    det: number;
    pas: number;
    pro: number;
  };
}

interface CutJson {
  count: number;
  id: string;
  name: string;
  previousCount: number;
  previousScore?: Score;
  rql: string;
  score: Score;
  sentiment?: Score;
  previousSentiment?: {
    score: number,
    hist: number[]
  };
  type?: string;
}
interface ScoreJson {
  count: number;
  cuts: CutJson[];
  score?: Score;
}
enum Sentiment {
  'tired',
  'frown',
  'meh',
  'smile',
  'grin-stars'
}
interface Cut {
  count: number;
  id: string;
  label: string;
  maxScore: number;
  minScore: number;
  previousCount?: number;
  previousScore?: number;
  rql: string;
  score: number;
  componentScores?: Score[];
  segments?: Segment[];
  sentiment?: Sentiment;
  volume: number;
  volumeNormalized: number;
}

interface Segment {
  color: string;
  colorEnd: string;
  count: number;
  label: string;
}
enum SegmentType {
  AVERAGE = 'average',
  NPS = 'nps',
  THRESHOLD = 'threshold'
}

export function mapCut(type: SegmentType, cut: CutJson, total: number, segmentNames?: string[]): Cut {
  const {
    count = 0,
    id,
    name: label,
    previousCount,
    previousScore: previousScores,
    rql,
    score: scores = { score: 0, hist: [], componentScores: [] }
  } = cut;
  let score = scores ? scores.score : undefined;
  const componentScores = scores ? scores.componentScores : undefined;
  const vals = scores ? scores.hist : [];

  let previousScore;
  if (previousScores) {
    previousScore = previousScores.score;
  }

  let segments;
  if (count === 0 || score === undefined) {
    segments = undefined;
    // if no segments, force score to be treated as Very Low
    score = 0;
  } else if (type === SegmentType.AVERAGE) {
    segments = getAverageSegments(vals, segmentNames);
  } else if (type === SegmentType.THRESHOLD) {
    segments = getThresholdSegments(vals, segmentNames);
  } else {
    segments = getNpsSegments(vals);
  }

  let sentiment;
  if (cut.sentiment) {
    sentiment = calculateSentiment(cut.sentiment.score) as
      | undefined
      | Sentiment;
  }

  let volume;
  if (total === 0) {
    volume = 0;
  } else {
    volume = 100 * (count / total);
  }

  const result: Cut = {
    count,
    id,
    label,
    maxScore: 0,
    minScore: 0,
    previousCount,
    rql,
    score,
    componentScores,
    segments,
    sentiment,
    volume,
    volumeNormalized: 0
  };
  if (isNumber(previousScore)) {
    result.previousScore = previousScore;
  }
  return result;
}

export function getAverageSegments(vals: number[], segmentNames?: string[]): Segment[] {
  const segments = [
    {
      color: colors.red400,
      colorEnd: colors.red400,
      count: vals[vals.length - 5],
      label: segmentNames && segmentNames.length === 5 ? segmentNames[0] : '0 — 20%'
    },
    {
      color: colors.red300,
      colorEnd: colors.red300,
      count: vals[vals.length - 4],
      label: segmentNames && segmentNames.length === 5 ? segmentNames[1] : '20% - 40%'
    },
    {
      color: colors.orange300,
      colorEnd: colors.orange300,
      count: vals[vals.length - 3],
      label: segmentNames && segmentNames.length === 5 ? segmentNames[2] : '40% - 60%'
    },
    {
      color: colors.yellow300,
      colorEnd: colors.yellow300,
      count: vals[vals.length - 2],
      label: segmentNames && segmentNames.length === 5 ? segmentNames[3] : '60% - 80%'
    },
    {
      color: colors.green300,
      colorEnd: colors.green300,
      count: vals[vals.length - 1],
      label: segmentNames && segmentNames.length === 5 ? segmentNames[4] : '80% - 100%'
    }
  ];
  return segments;
}
function getNpsSegments(vals: number[]): Segment[] {
  const promoters = sum(vals.slice(9, 11));
  const passives = sum(vals.slice(7, 9));
  const detractors = sum(vals.slice(3, 7));
  const strongs = sum(vals.slice(0, 3));
  const segments = [
    {
      color: colors.red300,
      colorEnd: colors.red300,
      count: detractors,
      label: 'Detractors'
    },
    {
      color: colors.orange300,
      colorEnd: colors.orange300,
      count: passives,
      label: 'Passives'
    },
    {
      color: colors.green300,
      colorEnd: colors.green300,
      count: promoters,
      label: 'Promoters'
    }
  ];
  if (isNumber(strongs)) {
    segments.unshift({
      color: colors.red400,
      colorEnd: colors.red400,
      count: strongs,
      label: 'Strong detractors'
    });
  }
  return segments;
}

function getThresholdSegments(vals: number[], segmentNames?: string[]): Segment[] {
  const [below = 0, above = 0] = vals;
  const segments = [
    {
      color: colors.yellow300,
      colorEnd: colors.yellow300,
      count: below,
      label:  segmentNames && segmentNames.length === 2 ? segmentNames[0] : 'Below'
    },
    {
      color: colors.green300,
      colorEnd: colors.green300,
      count: above,
      label: segmentNames && segmentNames.length === 2 ? segmentNames[1] : 'Above'
    }
  ];
  return segments;
}

function mapRows(
  type: SegmentType,
  data?: ScoreJson,
  defaultScore?: number,
  segmentNames?: string[]
): undefined | Cut[] {
  if (!data || !isNumber(defaultScore)) {
    return undefined;
  }
  const { cuts } = data;
  const { count: total } = data;
  const rows = map(cuts, cut => mapCut(type, cut, total, segmentNames));

  let valueRows = reject(rows, s => s.id === 'all_' || !s.segments || !s.segments.length);
  const scores = reject(
    map(valueRows, 'score'),
    s => s === Number.MAX_SAFE_INTEGER
  );
  const counts = map(valueRows, 'count');
  const maxScore = max(scores);
  const minScore = min(scores);
  const maxCount = max(counts) || 0;
  forEach(rows, row => {
    row.volumeNormalized = 100 * (row.count / maxCount);
    // 0 can be a valid minScore / maxScore
    row.maxScore = maxScore !== undefined ? maxScore : defaultScore;
    row.minScore = minScore !== undefined ? minScore : defaultScore;
  });

  return rows;
}

export function adjustRowLabels(
  segments: Segment[] | undefined,
  low = 0,
  high = 100
): Segment[] | undefined {
  if (!segments) {
    return segments;
  }

  if (isNumber(low) && isNumber(high)) {
    const labels = [] as string[];
    const len = segments.length;
    let last = toFixed(low, 0);
    for (let i = 1; i <= len; i++) {
      if (i === len) {
        labels.push(`${last} — ${high}`);
      } else {
        const step = (high - low) / len;
        const upper = toFixed(low + i * step, 0);
        labels.push(`${last} — ${upper}`);
        last = upper;
      }
    }
    return map(segments, (seg, index) => {
      const label = labels[index];
      return assign({}, seg, { label });
    });
  }

  return segments || [];
}

export function asNps(
  data?: ScoreJson,
  defaultScore?: number
): undefined | Cut[] {
  return mapRows(SegmentType.NPS, data, defaultScore);
}

export function asThreshold(
  data?: ScoreJson,
  defaultScore?: number
): undefined | Cut[] {
  return mapRows(SegmentType.THRESHOLD, data, defaultScore);
}

export function asAverage(
  data?: ScoreJson,
  defaultScore?: number,
  low?: number,
  high?: number,
  segmentNames?: string[]
): Cut[] {
  const rows = mapRows(SegmentType.AVERAGE, data, defaultScore, segmentNames);

  return map(rows, row => {
    row.segments = adjustRowLabels(row.segments, low, high);

    return row;
  });
}
