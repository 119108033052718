import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';

export  default function countAnalysisFilters(accA: number, filter: AnalysisFilter): number {
  const cuts = filter.cuts || [];
  return cuts.reduce((accB: number, cut: AnalysisFilterCut) => {
    const countValue = cut.id === 'all_' ? 0 : 1;

    if (!cut.children) {
      return accB + countValue;
    }
    return countAnalysisFilters(accB + countValue, cut.children);
  }, accA);
}
