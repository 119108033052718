import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus } from 'api/enums';
import ProcessingComments from 'components/ProcessingComments/ProcessingComments';
import ThemeErrorList from 'components/ThemeEditor/ThemeErrorList';
import WhileUpdatingComponent from 'components/ThemeEditor/WhileUpdatingComponent';
import analytics from 'lib/analytics';
import { RecentActivityTracker, RecentActivityType } from 'lib/recent-activity';
import { isBoolean } from 'lodash';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dimmer, Loader, Message, Segment } from 'semantic-ui-react';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import './theme-groups.scss';
import ThemeEditor from './ThemeEditor';
import ThemeHeader from './ThemeHeader';
import { SegmentStoreInterface } from 'stores/SegmentStore';
import { compose } from 'lib/composeHOCs';

interface ThemeGroupsStoreProps {
  segmentStore: SegmentStoreInterface;
  themesStore: ThemesStoreInterface;
  surveyStore: SurveyStoreInterface;
}

interface ThemeGroupsProps extends
  RouteComponentProps<{ orgId: string; surveyId: string }>,
  ThemeGroupsStoreProps {
}

interface ThemeGroupsState {
  fetchingThemes: boolean;
  isSurveyProcessing: boolean;

  initialAction?: string;
  initialActionOptions?: object;
}
const withHocs = compose(
  inject('themesStore', 'surveyStore', 'segmentStore'),
  observer,
);

export default withHocs(class ThemeGroups extends React.Component<ThemeGroupsProps, ThemeGroupsState> {
  state = {
    fetchingThemes: true,
    isSurveyProcessing: false
  } as ThemeGroupsState;
  componentDidMount() {
    const { surveyId } = this.props.match.params;
    this.fetchData(surveyId);
    const { themesStore } = this.props;
    themesStore!.watch();

    // check if there are any initial states we should be initializing

    const search = parse(this.props.location.search) as Partial<{
      actionOptions: string,
      action: string
    }>;
    if (search.action) {
      const action = search.action;
      let actionOptions = undefined;
      try {
        if (typeof search.actionOptions === 'string') {
          actionOptions = JSON.parse(search.actionOptions);
        }
      } catch (e) {/* do nothing */ }
      this.setState({ initialAction: action, initialActionOptions: actionOptions });
      // Remove query params to keep the URL consistent
      delete search.action;
      delete search.actionOptions;
      this.props.history.replace({ search: stringify(search) });
    }
  }
  componentDidUpdate(prev: ThemeGroupsProps) {
    const { surveyId } = this.props.match.params;
    const { surveyId: prevId } = prev.match.params;
    if (surveyId !== prevId) {
      this.fetchData(surveyId);
    }
  }
  componentWillUnmount() {
    const { themesStore } = this.props;
    themesStore!.unwatch();
  }
  fetchData = async (surveyId: string) => {
    const { surveyStore } = this.props;
    const survey = await surveyStore?.getSurvey(surveyId);
    const isSurveyProcessing = !!survey && (survey.dataStatus === SurveyDataStatus.PROCESSING);
    this.setState({ isSurveyProcessing });
    if (!isSurveyProcessing) {
      when(() => isBoolean(surveyStore?.hasDraft(surveyId)), this.fetchThemes);
    }
  }
  fetchThemes = async () => {
    this.setState({ fetchingThemes: true });
    const { orgId, surveyId } = this.props.match.params;
    const { surveyStore, themesStore } = this.props;

    // NOTE: we assume that it's always become a boolean here so it's safe to cast
    themesStore!.hasDraft = !!surveyStore!.hasDraft(surveyId);
    await themesStore!.getThemes({
      orgId,
      surveyId,
      hasDraft: themesStore!.hasDraft,
      watch: true
    });
    const promises = [
      themesStore!.checkStatus(),
      themesStore!.getExampleComments(),
      themesStore!.getThemeParameters()
    ];
    await Promise.all(promises);

    const title = surveyStore!.getSurveyTitle(surveyId);

    analytics.track('Themes Editor: Opened', {
      category: 'Themes Editor',
      title
    });

    RecentActivityTracker.recordActivity({
      type: RecentActivityType.ThemesEditing,
      description: title
    });

    this.setState({ fetchingThemes: false });
  };
  hasErrors = () => {
    const { themesStore } = this.props;
    return themesStore!.loadErrors.length > 0;
  };

  render() {
    const { orgId, surveyId } = this.props.match.params;
    const { themesStore } = this.props;
    const { loading, applying, discovering, editingGroup, errorMessage } = themesStore!;
    const { fetchingThemes, isSurveyProcessing, initialAction, initialActionOptions } = this.state;

    if (isSurveyProcessing) {
      return (
        <Segment className="theme-groups simple-segment">
          <ThemeHeader
            orgId={orgId}
            surveyId={surveyId}
            hideEditorOptions={isSurveyProcessing}
          />
          <ProcessingComments />
        </Segment>
      );
    }

    let panels;

    if (this.hasErrors()) {
      return <ThemeErrorList />;
    } else if (applying || discovering) {
      panels = <WhileUpdatingComponent discovery={discovering} />;
    } else if (editingGroup) {
      panels = (
        <ThemeEditor
          group={editingGroup}
          orgId={orgId}
          surveyId={surveyId}
        />
      );
    } else {
      panels = null;
    }

    if (loading || fetchingThemes) {
      return <Segment className="theme-groups simple-segment">
        <Dimmer active={true} inverted={true}>
          <Loader className="nw-theme-loader" size="large">Loading themes&hellip;</Loader>
        </Dimmer>
      </Segment>;
    }

    return (
      <Segment className="theme-groups simple-segment">
        <ThemeHeader
          orgId={orgId}
          surveyId={surveyId}
          initialAction={initialAction}
          initialActionOptions={initialActionOptions} />
        {errorMessage && (
          <Message negative={true}>
            <Message.Content>
              <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
              {errorMessage}
            </Message.Content>
          </Message>
        )}
        {panels}
      </Segment>
    );
  }
});
