import { size } from 'lodash';
import * as React from 'react';
import { ConnectDropTarget, DropTarget } from 'react-dnd';
import Card, { SortableCard } from './Card';
import './container.scss';

interface ContainerDropTargetProps {
  connectDropTarget: ConnectDropTarget;
}

/*
  id: unique id for the list on the page so it doesn't drag it to another list
  list: list of components with unique id for each
  onOrderChange: called on drag end with the new order of items in the list as an array of unique component ids
*/
interface ContainerProps extends ContainerDropTargetProps {
  id: string;
  list: SortableCard[];
  onOrderChange: (order: string[]) => void;
  forbidDrag: boolean;
}

interface ContainerState {
  cards: SortableCard[];
}

class Container extends React.Component<ContainerProps, ContainerState> {
  constructor(props: ContainerProps) {
    super(props);
    this.state = { cards: props.list };
  }

  componentDidUpdate (prevProps: ContainerProps) {
    /* We only update the list upon adding or removing an item to save some re-renders */
    if (size(this.props.list) !== size(prevProps.list)) {
      this.setState({ cards: this.props.list });
    }
  }

  moveCard = (dragIndex: number, hoverIndex: number) => {
    const cards = [ ...this.state.cards ];
    const dragCard = cards[dragIndex];

    cards.splice(dragIndex, 1); // removing dragged item
    cards.splice(hoverIndex, 0, dragCard); // inserting it into hoverIndex

    this.setState({ cards });
  }

  onDragEnd = () => {
    const { cards } = this.state;
    const order = cards.map(card => card.id);
    this.props.onOrderChange(order);
  }

  render() {
    const { cards } = this.state;
    const { id, forbidDrag, connectDropTarget } = this.props;

    return connectDropTarget(
      <div className="sortable-list-container">
        {cards.map((card, i) => {
          return (
            <Card
              key={`${id}-${card.id}`}
              index={i}
              listId={id}
              card={card}
              onDragEnd={this.onDragEnd}
              moveCard={this.moveCard}
              forbidDrag={forbidDrag}
            />
          );
        })}
      </div>
    );
  }
}

export default DropTarget('CARD', {}, (connect) => ({
  connectDropTarget: connect.dropTarget()
}))(Container);