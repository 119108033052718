import * as React from 'react';
import Auth from 'Auth/Auth';
import analytics from 'lib/analytics';

export default class Logout extends React.Component {
  render() {
    analytics.track('logout', { action: 'navigated to /logout' });
    Auth.logout();
    return null;
  }
}
