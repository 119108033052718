import Updating from 'images/icons/updating.svg';
import * as React from 'react';
import { Header, Segment } from 'semantic-ui-react';

export interface WhileUpdatingProps {
  discovery?: boolean;
}

export default class WhileUpdatingComponent extends React.Component<
  WhileUpdatingProps
> {
  getHeader = () => {
    const { discovery } = this.props;
    if (discovery) {
      return 'Themes are being discovered';
    } else {
      return 'Great work! We\'re applying your theme changes.';
    }
  };
  getText = () => {
    const { discovery } = this.props;
    if (discovery) {
      return `We'll email you when theme discovery is complete`;
    } else {
      return `We'll email you when your changes are done`;
    }
  };
  render() {
    const header = this.getHeader();
    const text = this.getText();
    return (
      <Segment className="while-updating-wrapper" textAlign="center">
        <Updating className="ui active centered inline loader" />
        <Header>{header}</Header>
        <p>{text}</p>
      </Segment>
    );
  }
}
