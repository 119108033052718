import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExampleComment from 'components/ThemeEditor/ExampleComment';
import Quote from 'images/icons/quote.svg';
import { kebabCase, map } from 'lodash';
import * as React from 'react';
import { List, Loader, Message } from 'semantic-ui-react';
import { getThemesStore } from 'stores/RootStore';
import './example-comments.scss';

interface ExampleCommentsProps {
  comments: string[];
  phrases?: string[];
  processing: boolean;
  // this can not be camel case because it is react in vue
  processerror: boolean;
  istruncated: boolean;
  isquickedit?: boolean;
}

export default class ExampleComments extends React.Component<ExampleCommentsProps> {
  render(): JSX.Element | null {
    const store = getThemesStore();
    const { comments, phrases, processing, processerror: processError, istruncated, isquickedit } = this.props;
    const { exampleCommentsError: error, exampleCommentsLoading: loading } = store;

    let classNames = 'example-comments';
    if (!phrases) {
      classNames += ' example-comments-other';
    }

    if (error || processError) {
      return (
        <Message className="example-comments" negative={true}>
          <Message.Content>
            <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
            {error ? 'Unable to fetch example comments' : 'Unable to process comments'}
          </Message.Content>
        </Message>
      );
    } else if (loading || processing) {
      return (
        <Loader className="example-comments" inline="centered" size="small" active={true}>
          {processing ? 'Processing comments' : 'Loading comments'}
        </Loader>
      );
    } else {
      comments.sort((a, b) => {
        const phrase = a.trim().toLowerCase();
        const comparePhrase = b.trim().toLowerCase();
        return phrase.localeCompare(comparePhrase);
      });

      return (
        <List className={classNames} divided={true}>
          {map(comments, (comment, i) => {
            if (isquickedit) {
              return (
                <div className="comments-with-quote" key={`${i}-${kebabCase(comment.substring(25))}`}>
                  <Quote />
                  <ExampleComment
                    comment={comment}
                    phrases={phrases}
                    isTruncated={istruncated}
                    key={`${i}-${kebabCase(comment.substring(25))}`}
                  />
                </div>
              );
            } else {
              return (
                <ExampleComment
                  comment={comment}
                  phrases={phrases}
                  isTruncated={istruncated}
                  key={`${i}-${kebabCase(comment.substring(25))}`}
                />
              );
            }
          })}
          {comments.length === 0 && <List.Item>No comments match this theme.</List.Item>}
        </List>
      );
    }
  }
}
