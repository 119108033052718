<template>
  <component
    :is="componentType"
    v-if="analysisToolsUIStore.showAnalysisTool"
    :vis-config="analysisToolsUIStore.visConfig"
  />
  <no-analysis
    v-else
  />
</template>

<script>
import analytics from 'lib/analytics';
import { RecentActivityTracker, RecentActivityType } from 'lib/recent-activity';
import { debounce, get } from 'lodash';
import { stringify } from 'query-string';
import { getAnalysisToolsUIStore, getInitConfigStore } from 'stores/RootStore';
import { COMPONENTS, VIS_TYPES } from 'stores/ui/AnalysisToolsUIStore';
import SelectVisualization from 'vue/components/Helpscreens/SelectVisualization.vue';
import NoAnalysis from 'vue/components/NoAnalysis/NoAnalysis.vue';

export default {
  name: 'AnalysisToolSwitcher',
  components: {
    NoAnalysis
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
    };
  },
  computed: {
    title() {
      return this.initConfigStore.title;
    },
    routeSubtheme() {
      return get(this, '$route.query.subtheme', null);
    },
    routeTheme() {
      return get(this, '$route.query.theme', null);
    },
    q() {
      const { query } = this.$route;
      const q = stringify(query);
      return q ? `?${q}` : '';
    },
    componentType() {
      const { visConfig } = this.analysisToolsUIStore;
      if (!visConfig) {
        return;
      }
      const { name = '', type = '' } = visConfig;

      if (type in VIS_TYPES) {
        // called when componentType changes which is a cue that a visualization has loaded
        analytics.track('Visualization Loaded', {
        category: 'Analysis',
        label: type,
        title: name,
        type });

        return COMPONENTS[type];
      } else {
        return SelectVisualization;
      }
    }
  },
  watch: {
    routeSubtheme() {
      this.updateThemeFromRoute();
    },
    routeTheme: {
      handler() {
        this.updateThemeFromRoute();
      },
      immediate: true
    },
    componentType: {
      handler(type, previousType) {
        if (type !== previousType) {
          this.recordActivity();
        }
      }
    }
  },
  mounted() {
    this.updateThemeFromRoute = debounce(this.updateThemeFromRoute, 10);
    this.recordActivity();
  },
  methods: {
    updateThemeFromRoute() {
      const { routeTheme: theme, routeSubtheme: subtheme } = this;
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
    },
    recordActivity() {
      RecentActivityTracker.recordActivity({
            type: RecentActivityType.Analysis,
            description: this.title
      });
    }
  }
};
</script>
