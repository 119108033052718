

























































import CopyToClipboard from "vue/components/CopyToClipboard/CopyToClipboard.vue";
import ReviewControls from 'vue/components/Summary/ReviewControls.vue';
export default {
  name: "summary-item",
  components: {
    CopyToClipboard,
    ReviewControls
  },
  props: {
    hasError: { type: Boolean },
    summaryTitle: { type: String },
    summary: { type: String },
    featureId: { type: String, required: true },
    location: { type: String, required: true },
  },
  computed: {
    isEmpty() {
      return !this.summary || this.summary.trim().length === 0;
    }
  },
  methods: {
    onTextSelection() {
      const selectedText = window.getSelection() as Selection;
      if (selectedText.toString().trim().length > 0) {
        this.$emit('onTextSelection');
      }
    }
  }
};


