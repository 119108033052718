import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Form, Input, Label } from 'semantic-ui-react';
import { ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import './new-term.scss';

interface NewTermProps {
  descriptor: string;
  onCloseNonDescriptorInput: () => void;
  onCancelCreateNonDescriptor: () => void;
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
}

interface NewTermState {
  newNonDescriptorValue: string;
}

@inject('conceptsEditorUIStore')
@observer
export default class NewTerm extends React.Component<NewTermProps, NewTermState> {
  state = {
    newNonDescriptorValue: ''
  } as NewTermState;

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  handleNonDescriptorChange = (e) => {
    this.setState({ newNonDescriptorValue: e.target.value });
    this.conceptsEditorUIStore.validateCreateNonDescriptor(e.target.value);
  }

  createNonDescriptor = () => {
    this.conceptsEditorUIStore.validateCreateNonDescriptor(this.state.newNonDescriptorValue);
    const { isDuplicate, isEmpty, existsAsDescriptor } = this.conceptsEditorUIStore.createNonDescriptorErrors;
    if (!isDuplicate && !isEmpty && !existsAsDescriptor) {
      this.conceptsEditorUIStore.createNonDescriptor(this.state.newNonDescriptorValue, this.props.descriptor);
      this.setState({newNonDescriptorValue: ''});
      this.props.onCloseNonDescriptorInput();
    }
  }

  handleAddTermKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.createNonDescriptor();
    }
  }

  render () {
    const { isDuplicate, isEmpty, existsAsDescriptor } = this.conceptsEditorUIStore.createNonDescriptorErrors;
    const {
      onCancelCreateNonDescriptor
    } = this.props;
    const {
      newNonDescriptorValue
    } = this.state;
    return (
      <div className="new-term">
        <div className="input-field">
          <Form.Field>
            <Input
              type="text"
              placeholder="Enter a new term"
              autoFocus={true}
              value={newNonDescriptorValue}
              ref={input => input && input.focus()}
              onChange={this.handleNonDescriptorChange}
              onBlur={this.createNonDescriptor}
              onKeyPress={this.handleAddTermKeyPress}
              error={isDuplicate || isEmpty || existsAsDescriptor}
            />
            {(isDuplicate || existsAsDescriptor) &&
              <Label pointing={false} color="red" className="alert">
                This term is already in use.
              </Label>
            }
            {isEmpty &&
              <Label pointing={false} color="red" className="alert">
                Terms cannot be empty.
              </Label>
            }
          </Form.Field>
        </div>
        <Button
          type="button"
          className="cancel"
          color="grey"
          // onBlur is being used in the input to accept the term, therefore, onMouseDown is being used here
          onMouseDown={onCancelCreateNonDescriptor}
        >
          Cancel
        </Button>
      </div>
    );
  }
}