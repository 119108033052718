import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisFilter } from 'api/interfaces';
import classNames from 'classnames';
import FilterMenuItem from 'components/Filters/FilterMenuItem';
import { createCategoryFilterOptions } from 'lib/filters/category-filter-helper';
import { createHierarchicalFilterOptions, FilterOption } from 'lib/filters/hierarchical-filter-helper';
import { createSentimentFilterOptions } from 'lib/filters/sentiment-filter-helper';
import { createTagFilterOptions } from 'lib/filters/tag-filter-helper';
import { createThemeFilterOptions, ThemesObject } from 'lib/filters/theme-filter-helper';
import { isTruthy } from 'lib/type-utils';
import { sortBy } from 'lodash';
import * as React from 'react';
import { TagInfo } from 'stores/TagStore';
import { QueryFilter } from 'stores/types';
import './filter-categories.scss';

interface FilterCategoriesProps {
  filters: AnalysisFilter[];
  selectedFilterId?: string;
  queryFilters?: Record<string, QueryFilter>;
  tags: TagInfo[];
  themes: ThemesObject;
  isFeedbackTool: boolean;
  onFilterSelected: (filterId: string) => void;
  onFilterReset: (filterId: string) => void;
}

class FilterCategories extends React.Component<FilterCategoriesProps, {}> {

  findSelectedFilterName = (filterOptions: FilterOption[]) => {
    let selectedNames: string[] = [];
    filterOptions?.forEach((option) => {
      if (option.isSelected && option.id !== 'all_') {
        selectedNames.push(option.name);
      } else if (option.children) {
        const selectedChildOptions = this.findSelectedFilterName(option.children);
        selectedNames = [...selectedNames, ...selectedChildOptions];
      }
    });
    return selectedNames;
  };

  render() {
    const {
      filters,
      selectedFilterId,
      queryFilters,
      themes,
      tags,
      isFeedbackTool,
      onFilterSelected,
      onFilterReset,
    } = this.props;
    const sortedFilters = sortBy(filters, [
      function (filter: AnalysisFilter) {
        const queryFilter = queryFilters?.[filter.id];
        const isActive =
          !!queryFilter?.selected && !!queryFilter.selected.length && !queryFilter.selected[0].isDefaultSelection;
        if (isActive) {
          return filter;
        }
        return;
      },
    ]);
    return (
      <div className="filter-categories">
        {sortedFilters.map((filter) => {
          if (filter.hidden) {
            return;
          }
          const queryFilter = queryFilters?.[filter.id];

          let selectedNames: string[] = [];
          if (filter.type === 'date') {
            if (queryFilter?.selected && queryFilter.selected.length > 0 && queryFilter.selected[0]) {
              selectedNames = [queryFilter.selected[0].name];
            }
          } else if (filter.type === undefined) {
            // For list filters, we want to show the selected names in the same order as the list of options. So for
            // example if you click a score of 3, then 1, then 5, the displayed order will still be 1, 3, 5.
            selectedNames =
              filter?.cuts?.map((cut) => queryFilter?.selected?.find((c) => c.id === cut.id)?.name).filter(isTruthy) ||
              [];
          } else {
            if (filter.type === 'hierarchical') {
              const filterOptions: FilterOption[] = createHierarchicalFilterOptions(filter?.cuts || [], queryFilter);
              selectedNames = this.findSelectedFilterName(filterOptions);
            } else if (filter.type === 'tags') {
              const filterOptions: FilterOption[] = createTagFilterOptions(tags, queryFilter);
              selectedNames = this.findSelectedFilterName(filterOptions);
            } else if (filter.type === 'themes') {
              const filterOptions: FilterOption[] = createThemeFilterOptions(themes, queryFilter, isFeedbackTool);
              selectedNames = this.findSelectedFilterName(filterOptions);
            } else if (filter.type === 'categories') {
              const filterOptions: FilterOption[] = createCategoryFilterOptions(queryFilter);
              selectedNames = this.findSelectedFilterName(filterOptions);
            } else if (filter.type === 'sentiment') {
              const filterOptions: FilterOption[] = createSentimentFilterOptions(queryFilter);
              selectedNames = this.findSelectedFilterName(filterOptions);
            } else {
              selectedNames = queryFilter?.selected?.map((cut) => cut.name) || [];
            }
          }

          let active = false;
          if (selectedNames.length > 0) {
            if (
              queryFilter?.selected &&
              queryFilter.selected.length === 1 &&
              queryFilter.selected[0].isDefaultSelection
            ) {
              active = false;
            } else {
              active = true;
            }
          }
          const detailsShowing = selectedFilterId === filter.id;

          return (
            <FilterMenuItem
              key={filter.id}
              itemName={filter.name}
              active={detailsShowing}
              onClick={() => onFilterSelected(filter.id)}
            >
              {active && (
                <div
                  role="button"
                  className="reset-filter-button plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    onFilterReset(filter.id);
                  }}
                >
                  <FontAwesomeIcon icon="times" />
                </div>
              )}
              <div className={classNames('left-column', { active })} style={{ WebkitBoxOrient: 'vertical' }}>
                {filter.name}
              </div>
              <div className="right-column">
                <span className="selected-names">{selectedNames.join(', ')}</span>
                {selectedNames.length > 1 && (
                  <span className="selected-count">{selectedNames.length}</span>
                )}
                <FontAwesomeIcon icon="chevron-right" />
              </div>
            </FilterMenuItem>
          );
        })}
      </div>
    );
  }
}

export default FilterCategories;
