<template>
  <div
    class="score-trend"
    :class="{ expanded:expanded }"
    @click="toggleExpansion"
  >
    <div class="score-trend__header">
      <div class="score-trend__title">
        {{ name }}
      </div>
      <div
        v-if="score"
        class="score-trend__subtitle"
      >
        {{ scoreName }}: {{ scoreChange }}
        <font-awesome-icon :icon="scoreChangeArrow" />
      </div>
      <div class="score-trend__toggle">
        <font-awesome-icon :icon="expanded ? 'chevron-down': 'chevron-right'" />
      </div>
    </div>
    <div
      v-if="expanded"
      class="score-trend__detail"
    >
      <div class="score-trend__detail-header">
        <div class="score-trend__detail-header__avg">
          <div class="score-trend__detail-header__number">
            {{ averageScore }}
          </div>
          Past Average
        </div>
        <div class="score-trend__detail-header__divider" />
        <div class="score-trend__detail-header__vol">
          <div class="score-trend__detail-header__number">
            {{ score }}
          </div>
          Current {{ scoreName }}
        </div>
      </div>
      <div
        ref="containerEl"
        class="score-trend__detail-chart"
      >
        <svg ref="chartEl" />
      </div>
      <!--div class="score-trend__inspect-container">
        <el-button size="small" type="baseline"
          @click="inspectTheme">
          Inspect Theme
        </el-button>
      </div-->
    </div>
  </div>
</template>

<script>
import { debounce, get, isFinite, last, map } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import fuzzNum from 'vue/libs/fuzz-num';
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';
import * as d3 from 'd3';
import d3tip from 'd3-tip';

d3tip.bind(d3);

export default {
  name: 'ScoreTrend',
  props: {
    scoreName: { default: 'Score', type: String},
    scoreTrend: { default: undefined, type: Object },
    volumeTrend: { default: undefined, type: Object },
    context: { default: undefined, type: Array },
    theme: { default: undefined, type: String },
    subtheme: { default: undefined, type: String },
  },
  data() {
    return {
      expanded: false,
      tip: undefined
    };
  },
  computed: {
    name() {
      const {theme, subtheme} = this;
      if (subtheme) {
        return subtheme;
      }
      return theme;
    },
    history() {
      return get(this, 'scoreTrend.history', []);
    },
    lastItem() {
      const { history } = this;
      return last(history);
    },
    normal() {
      return get(this, 'scoreTrend.avg');
    },
    std() {
      return get(this, 'scoreTrend.std');
    },
    normalLow() {
      const { normal, std } = this;
      if (isFinite(normal)) {
        return normal - 2 * std;
      } else {
        return undefined;
      }
    },
    normalHigh() {
      const { normal, std } = this;
      if (isFinite(normal)) {
        return normal + 2 * std;
      } else {
        return undefined;
      }
    },
    scoreChange() {
      const difference = this.score - this.averageScore;
      const sign = difference > 0 ? '+' : '';
      return sign + toFixed(difference, this.decimalPoints);
    },
    scoreChangeArrow() {
      if (this.scoreChange > 0) {
        return 'arrow-alt-up';
      }
      return 'arrow-alt-down';
    },
    averageScore() {
      const { normal } = this;
      return toFixed(normal, this.decimalPoints);
    },
    rangeText() {
      const { normalLow, normalHigh } = this;
      const low = toFixed(normalLow, 2);
      const high = toFixed(normalHigh, 2);
      if (low === high) {
        return `~${low}`;
      }
      return `${low} - ${high}`;
    },
    score() {
      const { lastItem } = this;
      if (isFinite(lastItem)) {
        return toFixed(lastItem, this.decimalPoints);
      } else {
        return undefined;
      }
    },
    decimalPoints() {
      const { lastItem, normal } = this;
      const difference = Math.abs(lastItem - normal);
      if (difference < 0.2) {
        return 2;
      } else if (difference >= 0.2 && difference < 2) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  watch: {
    expanded() {
      this.updateSvg();
    },
    history() {
      this.updateSvg();
    }
  },
  mounted() {
    this.updateSvg = debounce(this.updateSvg, 1);

    this.resizeEventListener = window.addEventListener('resize', this.updateSvg);
  },
  methods: {
    toggleExpansion() {
      const { expanded, theme, subtheme } = this;
      this.expanded = !expanded;
      if (this.expanded) {
        this.$emit('onThemeSelected', theme, subtheme);
      }
    },
    inspectTheme() {
      const { theme, subtheme } = this;
      this.$emit('onThemeInspected',theme,subtheme);
    },
    updateSvg() {
      const { chartEl, containerEl } = this.$refs;
      const { expanded, history, normal, normalHigh, normalLow, context, volumeTrend, scoreName } = this;

      if (!chartEl || !containerEl || !expanded) {
        return;
      }
      if (!this.tip) {
        // generate the tooltip
        this.tip = d3tip()
          .attr(
            'class',
            'el-tooltip__popper is-dark el-tooltip__popper-no-mouse'
          )
          .attr('x-placement', 'top')
          .offset([-10, 0])
          .html((data) => {
              return `
            <div class="detail-items">
              <div>
                <h4 style="text-align:center">${data.label}</h4>
                <div>
                  <div>${scoreName}: ${toFixed(data.value,2)}</div> 
                  <div>
                  Volume:
                    ${fuzzNum(Math.round(data.volume*(data.total)/100))}
                    /
                    ${fuzzNum(data.total)}
                  </div>
                </div>
              </div>
            </div>
            <div y-arrow class="popper__arrow" style="left:45%;"></div>
          `;
          });
      }
      // it may have just been initialized
      const { tip } = this;

      const { width, height } = containerEl.getBoundingClientRect();
      d3.select(chartEl)
        .attr('class', 'score-trend-svg')
        .attr('height', 180)
        .attr('width', width - 20)
        .selectAll('g')
        .remove();

      const leftMargin = 40;
      const rightMargin = 20;
      const totalMargin = leftMargin + rightMargin;
      const hitpointSize = 10;

      const x = d3
        .scaleLinear()
        .domain([0, history.length-1])
        .range([0, width-totalMargin-hitpointSize]);

      const domain = d3.extent(history);
      // normalLow/High can be slightly outside this range
      domain[0] = Math.min(domain[0],normalLow);
      domain[1] = Math.max(domain[1],normalHigh);
      const y = d3
        .scaleLinear()
        .domain(domain)
        .range([150, 20]);

      const yAxis = d3
        .axisLeft(y)
        .tickValues(history)
        .tickSize(0)
        .tickFormat((d, i) => {
          if (i === 0 || i === history.length - 1) {
            return toFixed(d, 1);
          } else {
            return undefined;
          }
        });

      // the actual chart area. We add a hovered class to know if the mouse is over it
      const chart = d3
        .select(chartEl)
        .append('g')
        .attr('transform', `translate(${leftMargin}, 0)`)
        .on('mouseover', function() {
          d3.select(this).classed('chart-hovered', true);
        })
        .on('mouseout', function() {
          d3.select(this).classed('chart-hovered', false);
        });

      // a white background rect so that we have a hover target for the whole graph
      chart
        .append('rect')
        .attr('class', 'score-trend-svg-internal')
        .attr('x', 1)
        .attr('y', 0)
        .attr('width', width-totalMargin)
        .attr('height', height);

      // the light blue 'typical score' rect
      chart
        .append('rect')
        .attr('class', 'typical-score')
        .attr('x', 1)
        .attr('y', y(normalHigh))
        .attr('width', width - totalMargin)
        .attr('height', y(normalLow) - y(normalHigh));

      // 'typical score' label
      chart
        .append('text')
        .attr('class', 'typical-score-label')
        .attr('x', 3)
        .attr('y', y(normalHigh) - 2)
        .text('Typical Range');

      // lines for average and top/bottom range
      chart
        .append("path")
        .attr("class", "average-line")
        .attr("d", ["M1", y(normal), "L" + (width - totalMargin), y(normal)].join(" "));
      chart
        .append("path")
        .attr("class", "range-line")
        .attr("d", ["M1", y(normalLow), "L" + (width - totalMargin), y(normalLow)].join(" "));
      chart
        .append("path")
        .attr("class", "range-line")
        .attr("d", ["M1", y(normalHigh), "L" + (width - totalMargin), y(normalHigh)].join(" "));

      chart.append('g').call(yAxis);

      // xAxis labels
      chart
        .append('text')
        .attr('class', 'axis-label')
        .attr('x', 0)
        .attr('y', height - 12)
        .text(context[0].labels);
      chart
        .append('text')
        .attr('class', 'axis-label')
        .attr('text-anchor', 'end')
        .attr('x', width - totalMargin)
        .attr('y', height - 12)
        .text(context[context.length - 1].labels);


      chart
        .append('path')
        .attr('class', 'score-line')
        .datum(history)
        .attr(
          'd',
          d3
            .line()
            .x((val, i) => x(i))
            .y(val => y(val))
            .curve(d3.curveMonotoneX)
        );

      chart.call(tip);

      const historyWithTooltip = map(history, (value,i) => {

        return {
          value,
          label: context[i].labels,
          volume: volumeTrend.history[i],
          total: nonemptyCountFromCountsObject(context[i].counts)
        }
      });

      chart.selectAll('.line-point-target')
        .data(historyWithTooltip)
        .enter()
          .append('circle')
          .attr('class', 'line-point')
          .attr('cx', (val, i) => x(i))
          .attr('cy', val => y(val.value))
          .attr('r', hitpointSize/2)
          .on('mouseover', function(data) {
            tip.show(data, this);
          })
          .on('mouseout', function() {
            tip.hide();
          })
          .on('click', function() {
            tip.hide();
          })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../vue/styles/element-variables';
.score-trend {
  align-items: center;
  border-radius: $--border-radius-small;
  background: $--neutral-100;
  color: $--neutral-900;
  cursor: pointer;
  font-size: 14px;
  padding: 7px 15px;
  margin-bottom: 10px;
  &.expanded {
    background: $--primary-100;
  }
  &__header {
    display: grid;
    grid-template-columns: 20px auto auto;
    grid-gap: 3px 10px;
    grid-template-areas:
      'toggle title subtitle';
    padding: 5px 0px;

  }
  &__title {
    grid-area: title;
  }
  &__subtitle {
    @include thematic-subtitle();
    grid-area: subtitle;
    text-align: right;
    line-height: 22px;
  }
  &__toggle {
    grid-area: toggle;
    text-align: left;
    .svg-inline--fa {
      color: $--neutral-400;
      font-size: 12px;
      &:hover {
        color: $--color-primary;
        background-color: $--primary-100;
      }
    }
  }
  &__detail {
    grid-area: detail;
    padding: 20px 0 0 0;
    &-header {
      @include thematic-subtitle();
      font-size: 12px;
      margin-bottom: 10px;
      display: grid;
      grid-template-areas: 'avg divider vol';
      grid-template-columns: 1fr 11px 1fr;
      &__vol {
        grid-area: vol;
      }
      &__divider {
        grid-area: divider;
        border-left: 1px solid $--neutral-200;
      }
      &__avg {
        grid-area: avg;
      }

      &__number {
        color: $--neutral-800;
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 10px;
      }
    }
    &-chart {
      background: $--color-white;
      padding: 10px;
    }
  }
  &__inspect-container {
    text-align: center;
    margin-top: 7px;
  }
}
</style>

<style lang="scss">
@import '../../vue/styles/element-variables';
.score-trend-svg-internal {
  fill: white;
}
.score-line {
  fill: none;
  stroke: $--primary-500;
  stroke-width: 2;
}
.average-line {
  fill: none;
  stroke: $--primary-200;
  stroke-width: 2;
  stroke-dasharray: 2;

}
.range-line {
  fill: none;
  stroke: $--primary-500;
  stroke-width: 1;

}
.typical-score {
  fill: $--primary-100;
}

.axis-label, .tick text {
  fill: $--neutral-600;
  font-size: 12px;
}

.typical-score-label {
  fill: $--primary-500;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.line-point {
  fill: none;
}

.chart-hovered {
  .line-point {
      fill: $--primary-500;
  }
}
</style>