import { AnswersDataSet } from 'api/interfaces';
import classNames from 'classnames';
import { Picker, PickerItem } from 'components/Shared/Picker';
import { countPickerItems, handleDatasetOptionClick, updateSelectionState } from 'lib/dataset-picker-helper';
import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import './dataset-picker.scss';

const MAX_TITLE_LENGTH = 45;
const MAX_SELECTABLE_PICKER_ITEMS = 10;
const DATASET_PICKER_OFFSET_X = 14;
const DATASET_PICKER_OFFSET_Y = 7;

type Props = {
  selectedDatasets: AnswersDataSet[],
  datasets: AnswersDataSet[],
  pickerItems: PickerItem[],
  onChange: (dataset: AnswersDataSet[]) => void
};

function getLabelText(selectedDataSets: AnswersDataSet[]): string {

  const fallback = `${selectedDataSets.length} selected`;

  let proposedTitle = '';

  if (selectedDataSets.length === 0) {
    return 'Please select a dataset';
  }

  if (selectedDataSets.length === 1) {
    proposedTitle =  selectedDataSets[0].title;
  }

  if (selectedDataSets.length === 2) {
    proposedTitle = `${selectedDataSets[0].title}, ${selectedDataSets[1].title}`;
  }

  if (selectedDataSets.length > 2) {
    const remainder = selectedDataSets.length - 2;
    proposedTitle = `${selectedDataSets[0].title}, ${selectedDataSets[1].title} and ${remainder} more`;
  }

  return (proposedTitle.length > MAX_TITLE_LENGTH) ? fallback : proposedTitle;

}

export const DatasetPicker = (props: Props) => {
  const pickerContent = React.useRef<HTMLDivElement>(null);
  const pickerButton = React.useRef<Button>(null);


  const [open, setOpen] = React.useState(false);
  const [selectedDataSets, setSelectedDataSets] = React.useState(props.selectedDatasets);
  const [isKeyboardEvent, setIsKeyboardEvent] = React.useState(false);

  function onConfirm() {
    props.onChange(selectedDataSets);
    setOpen(false);
  }

  function onClearSelection() {
    setSelectedDataSets([]);
  }

  function handleItemClicked(item: PickerItem) {
    const updatedDatasets: AnswersDataSet[] = handleDatasetOptionClick(item, props.datasets, selectedDataSets, MAX_SELECTABLE_PICKER_ITEMS);
    setSelectedDataSets(updatedDatasets);
  }

  function focusFirstPickerItem() {
    const parent = pickerContent.current;
    if (parent) {
      const child = parent.children?.[1];
      const firstItemInPicker = child?.children?.[0].children?.[0];
      const firstItemInPickerButton = firstItemInPicker?.children?.[0].children?.[0] as HTMLButtonElement;
      if (firstItemInPickerButton) {
        firstItemInPickerButton.focus();
      }
    }
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    e.preventDefault();
    if (e.key === 'Enter' || e.key === ' ') {
      setOpen(!open);
      setIsKeyboardEvent(true);
    }
  }

  function popupOffSet(): [number, number] {
    if (pickerButton.current && pickerButton.current['ref']) {
      const buttonElement = pickerButton.current['ref'];
      const askFormOffSetLeft: number = (buttonElement.current.offsetLeft || 0);
      const datasetPickerOffSetLeft = askFormOffSetLeft - DATASET_PICKER_OFFSET_X;

      return [-datasetPickerOffSetLeft, DATASET_PICKER_OFFSET_Y];
    }
    return [0, 0];
  }

  React.useEffect(() => {
    if (isKeyboardEvent) {
      focusFirstPickerItem();
      setIsKeyboardEvent(false);
    }
  }, [isKeyboardEvent]);

  React.useEffect(() => {
    if (!open) {
      setSelectedDataSets(props.selectedDatasets);
    }
  }, [props.selectedDatasets, open]);

  const hasMultipleOptions = countPickerItems(props.pickerItems) > 1;

  const itemsWithUpdatedSelectionState: PickerItem[] = props.pickerItems.map(
    dataset => updateSelectionState(dataset, selectedDataSets)
  );

  const shouldShowCounter = itemsWithUpdatedSelectionState.length >= MAX_SELECTABLE_PICKER_ITEMS;

  const classes = classNames('dataset-picker', {
    ['dataset-picker--open']: open
  });

  return (
    <fieldset className={classes}>
      <Popup
        disabled={props.selectedDatasets.length === 0}
        trigger={
          <label>{getLabelText(props.selectedDatasets)}</label>
        }
      >
        <ul className="dataset-picker__popover">
          {props.selectedDatasets.map(dataset => (
            <li key={`${dataset.title}-${dataset.visId}`}>
              {dataset.title}
            </li>
          ))}
        </ul>
      </Popup>
      <Popup
        open={open}
        className="dataset-picker__popup"
        offset={() => popupOffSet()}
        trigger={
          hasMultipleOptions && (
            <Button
              ref={pickerButton}
              data-testid="dataset-picker-button"
              className="dataset-picker__change-button"
              onClick={() => setOpen(!open)}
              onKeyPress={(e: React.KeyboardEvent) => handleKeyPress(e)}
            >
              Change
            </Button>
          )
        }
        on='click'
        position='top left'
        onClose={() => onConfirm()}
      >
        <div
          className="dataset-picker__content"
          ref={pickerContent}
        >
          {shouldShowCounter ?
            <header className="dataset-picker__header">
              Selected datasets <span className="dataset-picker__counter">{selectedDataSets.length}/{MAX_SELECTABLE_PICKER_ITEMS}</span>
            </header>
          :
            <header className="dataset-picker__header">Select dataset</header>
          }
          <Picker
            items={itemsWithUpdatedSelectionState}
            maxItems={MAX_SELECTABLE_PICKER_ITEMS}
            onItemClicked={item => handleItemClicked(item)}
          />
          <button
            className="dataset-picker__clear-selection"
            onClick={onClearSelection}
          >
            Clear Selection
          </button>
        </div>
      </Popup>
    </fieldset>
  );
};
