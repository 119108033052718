<template>
  <div class="feedback-categories">
    <!-- Only show the first base theme if not expanded -->
    <div class="categories-list">
      <el-tag
        v-for="(details, category) in categories"
        :key="category"
        class="category"
        type="info"
      >
        {{ category }}
      </el-tag>
    </div>
  </div>
</template>


<script>
export default {
  name: 'CommentCategories',
  props: {
    categories: { type: Object, default: () => ({})},
    commentId: { type: String, required: true },
  },
  data() {
    return {
      hover: undefined,
      selectedThemeToRemove: undefined,
    };
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';

.feedback-categories {
  width: calc(100% - 10px);

  .categories-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -5px 5px -5px;

    > * {
      margin: 5px;
    }

    .category {
      line-height: 25px;
      height: 26px;
      font-size: 14px;
    }

    .category.el-tag.el-tag--info {
      color: $--neutral-700;
    }
  }
}
</style>
