import { stringify } from 'query-string';
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Form, Header, Input, Message, Modal } from 'semantic-ui-react';

import analytics from 'lib/analytics';
import config from 'runtime-config';
import './LoginScreen.scss';

interface DomainCheckJson {
  location: string;
  ssoSupported: boolean;
}

export interface SingleSignOnProps {
  cancel: () => void;
  open: boolean;
}
interface SingleSignOnState {
  checking: boolean;
  email: string;
  error: string;
  pristine: boolean;
  redirecting: boolean;
  validEmail: boolean;
}
export default class SingleSignOn extends React.Component<
  SingleSignOnProps,
  SingleSignOnState
> {
  constructor(props: SingleSignOnProps) {
    super(props);
    const email = localStorage.lastSuccessfulSSOEmail || '';
    const validEmail = this.isValid(email);

    this.state = {
      checking: false,
      email,
      error: '',
      pristine: true,
      redirecting: false,
      validEmail
    };
  }
  clear = () => {
    this.setState({ email: '', pristine: true, validEmail: false });
  };
  close = () => {
    this.clear();
    this.props.cancel();
  };
  signIn = async () => {
    const { email, validEmail } = this.state;
    if (!validEmail) {
      return;
    }
    this.setState({ checking: true, error: '' });
    const params = stringify({ email, return_to: window.location });
    const url = `${config.ssoDomain}/sso/domain_check?${params}`;
    try {
      analytics.track('Check SSO Domain', { category: 'Login' });
      const result: Response = await fetch(url);
      const { ok } = result;
      if (ok) {
        const { data } = (await result.json()) as { data: DomainCheckJson };
        const { location, ssoSupported } = data;
        if (ssoSupported) {
          localStorage.lastSuccessfulSSOEmail = email;
          this.setState({ redirecting: true });
          window.location.href = location;
        } else {
          this.setState({
            error: `Your domain doesn't have SSO configured. Please contact your administrator.`
          });
        }
      } else {
        throw new Error('Invalid response');
      }
    } catch (e) {
      this.setState({
        error: 'Uh oh! Unable to sign you in. Please try again later.'
      });
    } finally {
      this.setState({ checking: false });
    }
  };
  isValid = (email: string): boolean => {
    return /[^@]+@[^\.]+\..+/.test(email);
  };
  updateEmail = (e: React.FormEvent<HTMLInputElement>) => {
    const { currentTarget } = e;
    let { value: email } = currentTarget;

    const validEmail = this.isValid(email);

    this.setState({ email, pristine: false, validEmail });
  };
  render() {
    const {
      checking,
      email,
      error,
      pristine,
      redirecting,
      validEmail
    } = this.state;
    const { open } = this.props;
    if (redirecting) {
      return (
        <Modal open={open} size="small" dimmer="blurring">
          <Modal.Header as={Header}>Sign in using SSO</Modal.Header>
          <Modal.Content>
            <Form.Field>
              <Input
                disabled={true}
                fluid={true}
                value="Redirecting to your provider..."
              />
            </Form.Field>
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={true}>Cancel</Button>
            <Button disabled={true} className="sso-sign-in-button">
              Sign in
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return (
      <Modal
        open={open}
        size="small"
        dimmer="blurring"
        onClose={this.close}
        onOpen={this.clear}
      >
        <Modal.Header as={Header}>Sign in using SSO</Modal.Header>
        <Modal.Content>
          {error && (
            <Message negative={true} icon={true}>
              <FontAwesomeIcon
                className="icon"
                icon="exclamation-triangle"
                fixedWidth={true}
              />
              <Message.Content>
                <p>{error}</p>
              </Message.Content>
            </Message>
          )}

          <Form onSubmit={this.signIn}>
            <Form.Field>
              <Input
                autoFocus={true}
                disabled={checking}
                error={!pristine && !validEmail}
                placeholder="Email address"
                type="email"
                value={email}
                name="email"
                onChange={this.updateEmail}
              />
              {!pristine && !validEmail && (
                <div className="error-message">Invalid email address</div>
              )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={checking} onClick={this.close}>
            Cancel
          </Button>
          <Button
            disabled={!validEmail}
            className="sso-sign-in-button"
            onClick={this.signIn}
            loading={checking}
          >
            Sign in
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
