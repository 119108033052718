import getCommentHighlightLocations from "lib/get-comment-highlight-locations";
import reduceBlocksToHighlighted from "lib/reduce-blocks-to-highlighted";
import segmentsToBlocks from "lib/segments-to-blocks";
import { ThemeCodes } from "stores/ui/AnalysisToolsUIStore";
import { Comment, CommentTheme } from "types/custom";

export const getBlocks = (
  currentPage: number,
  comments: Comment[],
  segmentsAroundHighlightedSentences: number,
  theme: CommentTheme
) => {
  if (!comments.length) {
    return [];
  }

  const currentComment = comments[currentPage];
  const selectedThemeCodes: ThemeCodes = {
    base: theme.theme ? theme.theme.code: undefined,
    sub: theme.subtheme ? theme.subtheme.code : undefined,
  };
  const highlightLocations = getCommentHighlightLocations(
    currentComment.segments,
    selectedThemeCodes
  );

  let blocks = segmentsToBlocks(
    currentComment.comment,
    currentComment.segments,
    -1,
    highlightLocations
  );

  // only retain the one sentence either side of any highlighted sentences
  // any others are replaced with elipses and consecutive elipses are collapsed
  blocks = reduceBlocksToHighlighted(blocks, segmentsAroundHighlightedSentences);

  return blocks
}
