
import * as workerPath from 'file-loader?name=[name].[hash].js!components/ThemeEditor/CommentMatcher.worker';
import { getAllCommentsText } from 'lib/comment-helpers';
import { getContextStringFromTerms } from 'lib/string-helper';
import { getInitConfigStore, getThemesStore } from 'stores/RootStore';
import { PlainComment, Thread, ThreadedComment } from 'types/custom';
import thematicData from 'vue/libs/thematicData';

export interface CoverageResults {
  exampleComments: string[];
  coverage: number; // as a percentage
}

export async function getCoverageAndExamplesForPhrases(orgId: string, surveyId: string, phrases: string[]) {
  // first ensure that the themestore is up to date with example comments for this survey
  const themesStore = getThemesStore();
  const initConfigStore = getInitConfigStore();
  await themesStore.getExampleComments(orgId, surveyId);
  const commentWorker = new Worker(workerPath.default);

  const results: CoverageResults = {
    exampleComments: [],
    coverage: 0
  };

  const workerPromise = new Promise<number>((resolve) => {
    commentWorker.onmessage = (ev) => {
      let coverage = 0;
      if (ev.data) {
        coverage = Math.round(100 * ev.data['coverage']);
      }
      resolve(coverage);
    };
  });
  commentWorker.postMessage({
    comments: getAllCommentsText(themesStore.exampleComments),
    phrases,
  });

  // also need to request some comments that match the selected phrases
  const selectedPhrasesRql = phrases.map((text) => `${ 'responsetext' }==${ encodeURIComponent(text) }`).join(',');
  const comments: PlainComment[] | Thread[] = await thematicData.getComments({
    filter: selectedPhrasesRql,
    commentColumns: null,
    requestOptions: initConfigStore.requestOptions,
    pagination: { page: 1, pageSize: 5 }
  });
  results.exampleComments = comments.map((comment: PlainComment | Thread) => {
    let text = '';
    if (typeof comment.comment === 'string') {
      text = getContextStringFromTerms(comment.comment, phrases);
    } else {
      const textParts = comment.comment.reduce((result: string[], c: ThreadedComment) => {
        // check if this part has any of the provided phrases in it
        if (phrases.reduce((containsResult, p) => {
          return c.text.includes(p) || containsResult;
        }, false)) {
          result.push(c.text);
        }
        return result;
      }, []);
      text = getContextStringFromTerms(textParts.join('\n'), phrases);
    }
    return text;
  });

  // make sure the commentWorker is finished
  results.coverage = await workerPromise;

  commentWorker.terminate();

  return results;

}
