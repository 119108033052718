import { ErrorMessage } from 'api/interfaces';
import * as React from 'react';
import QuestionNotClearIcon from 'images/icons/question-outside-domain.svg';
import { ErrorViewWithExampleQuestions } from './ErrorViewWithExampleQuestions';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, { errorCode: 'QUESTION_NOT_CLEAR' }>;
  onSuggestionClick: (suggestion: string) => void;
}

export const QuestionNotClear = (props: Props) => {

  return (
    <ErrorViewWithExampleQuestions
      question={props.question}
      error={props.error}
      warningTitle="It looks like your input might not be in the form of a question."
      warningIcon={<QuestionNotClearIcon />}
      warningDescription={
        <p className="answers-warning__description">
          Asking a question related to customer feedback helps us provide you with more accurate and relevant insights. Take a look at our example questions below for guidance.
        </p>
      }
      onSuggestionClick={props.onSuggestionClick}
    />
  );
};