import { ColumnType, ScoreType } from 'api/enums';
import { DisplayFilterType } from 'stores/ui/ConfigureDataUIStore';

export const COLUMN_TYPES = [
  {
    text: ColumnType.TEXT,
    value: ColumnType.TEXT,
  },
  {
    text: ColumnType.DATE,
    value: ColumnType.DATE,
  },
  {
    text: ColumnType.NUMBER,
    value: ColumnType.NUMBER,
  },
  {
    text: ColumnType.TAGS,
    value: ColumnType.TAGS,
  }
];

export const SCORE_TYPE_OPTIONS = [
  {
    value: ScoreType.AVERAGE,
    text: 'Average'
  },
  {
    value: ScoreType.NPS,
    text: 'NPS'
  },
  {
    value: ScoreType.THRESHOLD,
    text: 'Threshold'
  }
];

export const FILTER_TYPE_OPTIONS = [
  {
    value: DisplayFilterType.DEFAULT,
    text: 'Default'
  },
  {
    value: DisplayFilterType.DATE,
    text: 'Date'
  },
  {
    value: DisplayFilterType.FREETEXT,
    text: 'Search'
  },
  {
    value: DisplayFilterType.SCORE,
    text: 'Score'
  }
];

export const CUSTOM_FILTER_TYPE = 'Custom';

export const CUSTOM_FILTER_OPTIONS = [
  {
    value: CUSTOM_FILTER_TYPE,
    text: CUSTOM_FILTER_TYPE
  }
];