import { FilterType } from 'api/enums';
import { compact } from 'lodash';
import { createCategoryFilter } from './category-filter-helper';
import { createSentimentFilter } from './sentiment-filter-helper';
import { createTagFilter } from './tag-filter-helper';
import { createThemeFilter } from './theme-filter-helper';
import { AnalysisFilter } from 'api/interfaces';
import { TagInfo } from 'stores/TagStore';

const filterIsDisabled = (disabledFilterTypes: string[] | undefined, filterType: FilterType | undefined) => {
  if (!filterType) {
    return false;
  }

  return disabledFilterTypes?.includes(filterType);
};

interface Params {
  disabledFilterTypes?: string[];
  filters: AnalysisFilter[];
  sortedTags?: TagInfo[];
  showSentiment?: boolean;
}

const getAllFilters = ({ disabledFilterTypes, filters, sortedTags, showSentiment }: Params) => {
  const tagsFilter = {
    ...createTagFilter(),
    column: 0,
  };

  const categoryFilter = {
    ...createCategoryFilter(),
    column: 0,
    cuts: [],
  };

  const sentimentFilter = {
    ...createSentimentFilter(),
    column: 0,
    cuts: [],
  };

  const themesFilter = {
    ...createThemeFilter(),
    column: undefined,
  };

  const savedSegmentsFilter = {
    cuts: [],
    id: 'saved-segments-filters',
    name: 'Saved Filters',
    type: FilterType.SAVED_FILTERS,
    hidden: true,
    column: undefined,
  };
  let allFilters = [...filters, themesFilter, savedSegmentsFilter];

  if (sortedTags && sortedTags?.length > 0) {
    allFilters.push(tagsFilter);
  }

  allFilters.push(categoryFilter);

  // remove any existing sentiment filter and then add the one we want
  if (showSentiment) {
    allFilters = allFilters.filter((filter) => filter.type !== 'sentiment' && filter.column !== 'sentiment');
    allFilters.push(sentimentFilter);
  }

  allFilters = compact(
    allFilters.flatMap((filter) => {
      if (filterIsDisabled(disabledFilterTypes, filter.type)) {
        return;
      }
      return filter;
    }),
  );

  return allFilters;
};

export { getAllFilters };
