import * as React from 'react';
import WritingComment from 'images/icons/writing-comment.svg';
import Openbox from 'images/icons/open-box.svg';
import './empty.scss';

interface Props {
  children?: React.ReactNode;
  description?: string;
  title: string;
  icon?: 'writing-comment' | 'open-box'
}

const getIcon = (icon: Props['icon']) => {
  switch(icon) {
    case 'open-box': {
      return <Openbox/>
    }
    default: {
      return <WritingComment/>
    }
  }
};

const Empty = ({ children, description, title, icon = 'writing-comment' }: Props) => {

  return (
    <div className="empty-state">
      <div className="empty-state__content">
        <div className="empty-state__icon">
          {getIcon(icon)}
        </div>
        <h2 className="empty-state__title">{ title }</h2>
        <p className="empty-state__description">{ description }</p>
        {children}
      </div>
    </div>
  );
}

export { Empty };
