<template>
  <div
    :class="{ expanded: expanded, narrow: isNarrow, editedComment: isCommentEdited}"
    class="vertical-comment"
  >
    <p
      class="vertical-comment__bd"
    >
      <span
        v-for="(block, blockIndex) in blocks"
        :key="block.key"
        :class="{
          highlighted: block.isHighlighted && hoverOnThemes,
          'hidden-block': !shouldShowBlock(block, blockIndex),
          'empty-block': block.content.trim().length === 0,
          'theme-highlight-grey': block.hasThemes && hoverOnThemes && !block.isHighlighted,
          [toSentimentClassname(block.sentiment, hasSentiment)]: true,
          [commentHighlightOverride]: true,
        }"
        @click="shouldShowBlock(block, blockIndex) ? undefined : showBlock(blockIndex)"
        @mouseover="hoverBlock(block)"
        @mouseleave="leaveBlock()"
        @mouseenter="onEnterBlock(block)"
        @mousedown="onTextSelectionStart"
      >
        <comment-theme-popup
          :are-themes-in-applying-state="areThemesInApplyingState"
          :block-index="blockIndex"
          :block="block"
          :can-manage-themes="canManageThemes"
          :hover-on-themes="hoverOnThemes"
          :is-hidden-block="!shouldShowBlock(block, blockIndex)"
          :is-popup-disabled="isDisabled || selectedPhrase.length > 0"
          :is-sentiment-enabled="analysisConfigStore.hasSentiment"
          :popup-themes="findPopupThemeItems(block)"
          :show-truncation="true"
          @handleSentimentSelection="(value) => handleSentimentSelection(value, block)"
          @persistHoveredBlock="persistHoveredBlock"
          @removeTheme="(theme) => removeTheme('Sentence Popup', theme, block)"
        />
      </span>
      <add-theme-to-comment
        v-if="canSeeInlineThemesEditing"
        :selection-status="selectionStatus"
        :max-words="maxWords"
        :vertical-position="selectedPhrasePositionY"
        :horizontal-position="selectedPhrasePositionX"
        :pre-selected-phrase="selectedPhrase"
        :plain-blocks="blocks"
        :comment-id="commentId"
        :are-themes-in-applying-state="areThemesInApplyingState"
      />
    </p>
    <comment-metadata-list
      v-if="expanded"
      :info="info"
      class="vertical-comment__metadata"
    />
    <el-button
      v-if="isNarrow && !alwaysExpanded && !expanded"
      class="vertical-comment__toggle no-export-output"
      :class="{ collapsed: !expanded }"
      title="Toggle comment metadata"
      type="text"
      @click.stop="expandMetadata()"
    >
      {{ expanded ? 'Hide details' : showDetailsLabel }}
    </el-button>
    <div
      v-if="expanded"
      class="vertical-comment__themes"
    >
      <div
        v-if="isSummary"
        class="vertical-comment__full-conversation"
      >
        <full-conversation-button
          @click="showFullConversation"
        />
      </div>
      <div>
        <div class="theme-list">
          <b>Themes</b>
          <CommentThemesList
            :items="getCommentThemeItems()"
            :are-themes-in-applying-state="areThemesInApplyingState"
            :has-sentiment="true"
            :can-manage-themes="canManageThemes"
            :is-sentiment-enabled="hasSentiment"
            :has-remove-buttons="!isDisabled"
            @removeTheme="(theme) => removeTheme('Metadata', theme)"
            @mouseOverTheme="(theme) => isThemeRemoved(theme) ? null : hoverTheme(theme)"
            @mouseLeaveTheme="leaveTheme"
            @clickTheme="(theme) => $emit('onCommentThemeSelected', theme)"
          />

          <div
            v-for="(theme, index) in themesAdded()"
            :key="index + JSON.stringify(theme)"
            :class="{'themes-added': !doesThemeAlreadyExist(theme)}"
          >
            <span v-if="!doesThemeAlreadyExist(theme)">
              <font-awesome-icon
                icon="sparkles"
              />
              <span class="added-theme-title">{{ createThemeTitle(theme) }}</span>
            </span>
          </div>
          <add-theme
            v-if="canSeeInlineThemesEditing && !isDisabled"
            :are-themes-in-applying-state="areThemesInApplyingState"
            :plain-blocks="blocks"
            :comment-id="commentId"
            class="no-export-output"
          />
        </div>
      </div>
      <div class="tags-list">
        <b>Categories</b>
        <comment-categories
          :comment-column-id="commentColumnId"
          :comment-id="commentId"
          :categories="commentCategories"
          location="explore"
        />
      </div>
      <div
        v-if="showTagComment"
        class="tags-list"
        :class="{ 'no-export-output': tags.length === 0 }"
      >
        <b>Labels</b>
        <comment-tags
          v-if="!isDisabled"
          :comment-column-id="commentColumnId"
          :comment-id="commentId"
          :comment-tags="tags"
          location="explore"
          @updateTags="updateTags"
        />
      </div>
    </div>
    <div class="vertical-comment__actions no-export-output">
      <el-tooltip
        v-if="isCommentEdited"
        class="item"
        popper-class="vertical-comment-state-tooltip"
        effect="light"
        content="Changes pending"
        placement="top"
      >
        <font-awesome-icon
          v-if="isCommentEdited"
          icon="hourglass-half"
          class="vertical-comment__pending-state"
        />
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
      >
        <font-awesome-icon
          icon="copy"
          class="vertical-comment__copy-comment"
          @click="copyComment"
          @mouseenter="copyButtonClicked = false"
        />
        <div
          slot="content"
          class="copy-comment-text-content"
        >
          <font-awesome-icon
            v-if="copyButtonClicked"
            icon="check-circle"
            class="vertical-comment__comment-copied"
          />
          <p>
            {{ copyButtonClicked ? 'Copied!' : getCopyText() }}
          </p>
        </div>
      </el-tooltip>
    </div>
    <el-button
      v-if="isNarrow && !alwaysExpanded && expanded"
      class="vertical-comment__toggle  no-export-output"
      title="Toggle comment metadata"
      type="text"
      @click="expandMetadata()"
    >
      {{ expanded ? 'Hide details' : 'Show details' }}
    </el-button>
    <div v-if="exportingComment">
      <exportable-comment
        :comment="comment"
        :selected-theme-codes="selectedThemeCodes"
        :highlight-type="highlightType"
        :segments="segments"
        :info="info"
        :comment-tags="tags"
        @onExported="exportingComment = false"
      />
    </div>
  </div>
</template>

<script>
import analytics from 'lib/analytics';
import { getAnalysisConfigStore } from 'stores/RootStore';
import CommentCategories from 'vue/components/Categories/CommentCategories.vue';
import CommentTags from 'vue/components/Tags/CommentTags.vue';
import CommentThemesList from 'vue/components/Comments/CommentThemesList.vue';
import CommentMetadataList from 'vue/components/Comments/CommentMetadataList.vue';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import AddTheme from 'vue/components/Themes/AddTheme.vue';
import AddThemeToComment from 'vue/components/Themes/AddThemeToComment.vue';
import segmentsToBlocks from "lib/segments-to-blocks.ts";
import segmentsToOrderedThemes from "lib/segments-to-ordered-themes.ts";
import segmentsToCategories from "lib/segments-to-categories.ts";
import getCommentHighlightLocations from "lib/get-comment-highlight-locations.ts";
import { copyText } from "../../../lib/clipboard";
import toSentimentClassname from "vue/libs/to-sentiment-classname";
import toCommentThemeItem from "vue/libs/to-comment-theme-item.ts";
import ExportableComment from "vue/components/Comments/ExportableComment.vue";
import CommentThemePopup from './CommentThemePopup.vue';
import {
  createThemeTitle,
  isNewlyAddedTheme,
  doesThemeAlreadyExist,
  isThemeRemoved,
  themesAdded,
} from "vue/libs/edited-comment-helpers";
import addThemeToSelectionMixin from './AddThemeToSelectionMixin';
import plainCommentMixin from "./PlainCommentMixin.js";
import popupDataMixin from './PopupDataMixin';
import hoveredLocationMixin from './HoveredLocationMixin';
import FullConversationButton from 'vue/components/ConversationalAnalytics/FullConversationButton.vue';

export default {
  name: 'VerticalComment',
  components: {
    AddTheme,
    AddThemeToComment,
    CommentCategories,
    CommentTags,
    CommentThemesList,
    ExportableComment,
    CommentMetadataList,
    CommentThemePopup,
    FullConversationButton,
  },
  mixins: [
    plainCommentMixin(),
    addThemeToSelectionMixin(),
    popupDataMixin(),
    hoveredLocationMixin()
  ],
  props: {
    comment: { default: '', type: String },
    selectedThemeCodes: { default: () => ({}), type: Object },
    highlightType: { default: '', type: String },
    hoverOnThemes: { default: true, type: Boolean},
    info: { default: () => ({}), type: Object },
    isCommentEdited: { default: false, type: Boolean },
    isDisabled: { default: false, type: Boolean },
    isNarrow: { default: false, type: Boolean },
    segments: { default: () => [], type: Array },
    alwaysExpanded: { default: false, type: Boolean },
    showTagComment: {default: true, type: Boolean},
    commentTags: { default: () => [], type: Array },
    commentId: { type: String, default: '' },
    commentColumnId: { type: [Number, String], required: true },
    isSummary: { default: false, type: Boolean },
  },
  data() {

    return {
      tags: this.commentTags,
      analysisConfigStore: getAnalysisConfigStore(),
      expanded: !this.isNarrow || this.alwaysExpanded,
      visibleBlocks: {},
      hoveredThemeCodes: null,
      copyButtonClicked: false,
      exportingComment: false,
    };
  },
  computed: {
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    canSeeInlineThemesEditing() {
      return this.canManageThemes;
    },
    hasSentiment () {
      return this.analysisConfigStore.hasSentiment;
    },
    commentCategories() {
      return segmentsToCategories(this.segments);
    },
    highlightedLocations() {

      return getCommentHighlightLocations(
        this.segments,
        this.selectedThemeCodes,
        this.hoveredLocation,
        this.hoveredThemeCodes
      );

    },
    showFullMessages() {
      return this.analysisToolsUIStore.shouldShowFullMessages;
    },
    /**
     * The highlightType is usually `all` that yields the default style
     * different values (passed in from config) let us control highlighting style
     */
    commentHighlightOverride() {
      const { highlightType } = this;
      if (highlightType === 'none') {
        return 'theme-sentiment-off';
      }
      return '';
    },
    blocks() {
      return segmentsToBlocks(
        this.comment,
        this.segments,
        -1,
        this.highlightedLocations
      );
    },
    orderedThemes() {
      return segmentsToOrderedThemes(
        this.segments,
        this.selectedThemeCodes
      );
    },
    showDetailsLabel() {
      if (this.isSummary) {
        return 'Show details & conversation';
      }
      return 'Show details';
    },
  },
  watch: {
    showFullMessages(showFullMessages) {
      if (!showFullMessages) {
        this.visibleBlocks = {};
      }
    },
    selectionStatus(status) {
      if (status !== 'NONE') {
        const commentType = this.isSummary ? 'Summary' : 'Comment';
        analytics.track('Analysis: Select Comment', { 'Type': commentType });
      }
    }
  },
  methods: {
    createThemeTitle,
    doesThemeAlreadyExist(theme) {
      doesThemeAlreadyExist(this.commentId, theme, this.orderedThemes);
    },

    getThemeLocations(theme) {
      return this.segments.reduce((result, segment) => {

        const index = segment.themes.findIndex(t => {
          return t.base === theme.base && t.sub === theme.sub;
        });

        const segmentHasTheme = index > -1;

        return segmentHasTheme ? [...result, segment.location] : result;

      }, []);
    },

    isThemeRemoved(theme) {

      return isThemeRemoved(
        this.commentId,
        theme,
        this.getThemeLocations(theme)
      );
    },
    themesAdded() {
      return themesAdded(this.commentId);
    },
    toSentimentClassname,
    selectTheme(theme) {
      this.$emit('onCommentThemeSelected', theme);
    },
    hoverTheme(themeCodes) {
      if (this.hoverOnThemes) {
        this.hoveredThemeCodes = { base: themeCodes.base, sub: themeCodes.sub };
      }
    },
    leaveTheme() {
      this.hoveredThemeCodes = null;
    },
    getCommentThemeItems() {
      return this.orderedThemes.map(theme => toCommentThemeItem({
        theme,
        segments: this.segments,
        isNew: isNewlyAddedTheme(this.commentId, theme),
        isRemoved: isThemeRemoved(
          this.commentId,
          theme,
          this.getThemeLocations(theme)
        ),
        hoveredLocation: this.hoveredLocation,
        hoveredThemeCodes: this.hoveredThemeCodes,
        selectedThemeCodes: this.selectedThemeCodes
      }))
    },
    expandMetadata() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        analytics.track('Comments: Show metadata', {
          category: 'Comments',
          type: this.isSummary ? 'Summary' : 'Plain Comment'
        });
      }
    },
    showBlock(blockIndex) {
      this.visibleBlocks = Object.assign({}, this.visibleBlocks, { [blockIndex]: true });
    },
    shouldShowBlock(block, blockIndex) {
      if (this.showFullMessages) {
        return true;
      }
      if (block.content.trim().length === 0) {
        return true;
      }
      if (this.visibleBlocks[blockIndex]) {
        return true;
      }
      return block.hasThemes;
    },
    updateTags(newTags) {
      this.tags.splice(0, this.tags.length, ...newTags);
    },
    copyComment() {
      const commentType = this.isSummary ? 'Summary' : 'Comment';
      analytics.track('Analysis: Copy Comment', { 'Type': commentType });

      if ( FeatureFlagManager.checkFlag(FlagKeys.CAN_USE_PROTOTYPE_EXPORT) ) {
        this.exportingComment = true;
      } else {
        copyText(this.comment);
      }

      this.copyButtonClicked = true;
    },
    showFullConversation() {
      this.$emit('onShowFullConversation');
    },
    getCopyText() {
      return this.isSummary ? 'Copy summary' : 'Copy comment';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables';
@import '../../styles/theme-highlights';

.exporting-clipboard {
  .no-export-output {
    display: none;
  }
  .theme-highlight-grey {
    background-color: initial;
  }
}

.vertical-comment {
  display: grid;
  grid: auto auto 1fr / 3fr 2fr;
  grid-template-areas:
    'bd metadata'
    'bd themes'
    'bd flag'
    'toggle actions';
  margin: 10px 18px;
  background: $--white;
  border-radius: $--border-radius-small;
  padding: 8px 0;

  &.narrow {
    grid: auto auto auto / 1fr 120px;
    &.expanded {
      grid-template-areas:
        'bd bd'
        'themes themes'
        'metadata metadata'
        'toggle actions';
    }
    grid-template-areas:
      'bd bd'
      'toggle actions';
  }

  &.narrow, &.expanded {
    .vertical-comment__toggle {
      padding: 4px 6px;
      border: none;
      grid-area: toggle;
      justify-self: start;
      border-radius: 12px;
      margin: 0px 20px 10px 9px;
      height: 20px;
      &:hover {
        background-color: $--primary-200;
      }
    }
    .vertical-comment__actions {
      grid-area: actions;

      margin: 2px 15px 10px 9px;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > *:not(:first-child) {
        margin-left: 1.5em;
      }

      .vertical-comment__pending-state {
        color: $--orange-700;
      }

      .vertical-comment__copy-comment {
        color: $--primary-500;
        cursor: pointer;
      }
    }
  }

  &.expanded {
    .vertical-comment__toggle {
      margin: 0px 20px 10px 9px;
    }
  }

  &.editedComment {
    background-color: $--orange-100;
  }


  &__metadata {
    grid-area: metadata;
    padding: 8px 15px;
  }

  &__bd {
    font-weight: 400;
    font-size: 14px;
    grid-area: bd;
    line-height: 22px;
    margin: 0;
    padding: 8px 15px;
    position: relative;
    span {
      @extend .theme-highlight-base;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 0;
      white-space: pre-wrap;
    }
  }

  &__bd span.hidden-block {
      background: $--color-white;
      color: $--primary-500;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
      margin-right: 3px;
      padding: 0;
      line-height: 0.8;
    }

  &__themes b {
    color: $--neutral-500;
    display: block;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    font-size: 12px;
    padding-bottom: 5px;
  }

  &__themes .tags-list b {
    padding-top: 8px;
  }

  &__themes {
    width: 100%;
    .add-theme {
      margin-top: 0.25rem;
    }
  }

  &__themes .theme-list.collapsed {
    position: relative;
    height: 27px;
    overflow: hidden;
    display: flex;

    .theme {
      white-space: nowrap;
      line-height: 1.4em;
    }
  }

  &__themes .theme-list.collapsed::after {
    height: 27px;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
  }

  &__themes {
    grid-area: themes;
    padding: 8px 15px;
  }

  &__full-conversation {
    margin-bottom: var(--spacing-03);
  }
}

.vertical-comment .vertical-comment__copy-comment {
  opacity:0;
  transition: opacity 200ms ease-out;
}
.vertical-comment:hover .vertical-comment__copy-comment {
  opacity:1;
}

.copy-comment-text-content {
  font-size: 1rem;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  .vertical-comment__comment-copied {
    color: $--green-500;
  }
}

.themes-added {
  padding: 6px 8px;
  margin: 0 5px 5px 0;
  color: $--orange-700;
  .added-theme-title {
    padding-left: 2px;
  }
}
</style>

<style lang="scss">
@import '../../styles/element-variables';
@import '../../styles/inline-popover.scss';
.el-tooltip__popper.vertical-comment-state-tooltip {
  &.is-light[x-placement^=top] {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    line-height: normal;
    font-size: 14px;
    .popper__arrow {
      border-top-color: transparent;
    }
  }
}
.vertical-comment-sentiment-popover.el-popover {
  min-width: 0;
  &.el-popper[x-placement^=bottom] {
    margin-top: 2px;
  }
}
.hidden-block {
  display: inline-block;
}
.hidden-block + .hidden-block {
  display: none;
}
.hidden-block + .empty-block {
  display: none;
}
.hidden-block + .empty-block + .hidden-block {
  display: none;
}
</style>
