import { AnalysisViewSource, AnswersDataSet } from "api/interfaces";

export function dataSetToViewSource (answersDataSet: AnswersDataSet): AnalysisViewSource {
    return {
      survey: answersDataSet.surveyId,
      view: answersDataSet.viewId || undefined,
      visualization: answersDataSet.visId || '_',
    };
}

