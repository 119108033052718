import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceIntercomCreateFormProps {
  onChange(configuration: object, integrationId: string): void;
}

interface DataSourceIntercomCreateFormState {
}

@observer
class DataSourceIntercomCreateForm extends React.Component<
DataSourceIntercomCreateFormProps,
DataSourceIntercomCreateFormState
> {

  renderForm(): JSX.Element | null {
    return (
      <p>
        Pull Intercom Conversations
      </p>
    );
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          integrationType={IntegrationType.INTERCOM}
        />
      </div>
    );
  }
}

export default DataSourceIntercomCreateForm;