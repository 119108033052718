import { DiscoveredTheme, DiscoveredThemesJson } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { SelectedTheme, ThemeDiscoveryStoreInterface } from 'stores/ThemeDiscoveryStore';
import './theme-discovery.scss';
import ThemeDiscoveryItem from './ThemeDiscoveryItem';

interface ThemeDiscoveryResolutionProps {
  surveyId: string;
  generatedThemes: DiscoveredThemesJson;
  selectedTheme?: SelectedTheme;
}

interface InjectedProps {
  themesDiscoveryStore: ThemeDiscoveryStoreInterface;
}

export interface ProposedDiscoveredTheme extends DiscoveredTheme {
  proposedTitle: string;
  selected: boolean;
  proposedTheme?: SelectedTheme;
  suggestedAction?: string;
}

@inject('themesDiscoveryStore')
@observer
export default class ThemeDiscoveryResolution extends React.Component<
ThemeDiscoveryResolutionProps
> {
  get injected(): InjectedProps {
    return this.props as ThemeDiscoveryResolutionProps & InjectedProps;
  }

  private themeItemsRef = React.createRef<HTMLDivElement>();

  render(): JSX.Element | null {
    const { themesDiscoveryStore } = this.injected;
    const { resolvingThemes, handleFullSelection } = themesDiscoveryStore;

    if (!resolvingThemes) {
      return null;
    }

    const haveThemesToResolve = resolvingThemes.length > 0;

    const operationsSelected = resolvingThemes.filter(theme => theme.selected).length === resolvingThemes.length;

    return (
      haveThemesToResolve ? (
        <>
          <div className="theme-item-header-row theme-item">
            <Checkbox
              checked={!!operationsSelected}
              onChange={(_e, { checked }) => handleFullSelection(checked)}
            />
            <div className="theme-item__top-row">Select all</div>
          </div>
          <div className="theme-items" ref={this.themeItemsRef}>
            {resolvingThemes.map((theme, index) =>
              <ThemeDiscoveryItem
                key={index}
                index={index}
                theme={theme}
                themeItemsRef={this.themeItemsRef}
              />
            )}
          </div>
        </>
      ) : (
          <div>All themes have already been discovered</div>
        )
    );
  }
}
