<template>
  <div class="add-theme">
    <el-tooltip
      v-model="showTooltip"
      class="item"
      effect="dark"
      content="Theme editing is not possible while theme application is in progress"
      placement="left-end"
      :disabled="!areThemesInApplyingState"
    >
      <div
        @mouseover="openTooltip()"
        @mouseleave="hideTooltip()"
      >
        <el-button
          :id="`inline-themes-add-theme-button-${commentId}`"
          size="small"
          class="add-theme-button"
          :class="{ 'popover-active': openThemeDropdown }"
          :disabled="areThemesInApplyingState"
          @click.stop="toggleDropdown()"
        >
          <span class="add-theme-button-content">
            <font-awesome-icon
              icon="plus"
              class="add-theme-button-icon"
            /> Add theme
          </span>
        </el-button>
      </div>
    </el-tooltip>
    <theme-dropdown
      v-if="openThemeDropdown"
      :open-theme-dropdown="openThemeDropdown"
      :plain-blocks="plainBlocks"
      :conversations="conversations"
      :comment-id="commentId"
      @closeThemeDropdown="openThemeDropdown = false"
    />
  </div>
</template>

<script>
import analytics from 'lib/analytics';
import ThemeDropdown from './ThemeDropdown.vue';

export default {
  name: 'AddTheme',
  components: { ThemeDropdown },
  props: {
    plainBlocks: { type: [Array, null] },
    conversations: { type: [Array, null] },
    areThemesInApplyingState: {default: false, type: Boolean},
    commentId: { default: '', type: [String, Number] },
  },
  data() {
    return {
      openThemeDropdown: false,
      showTooltip: false
    };
  },
  methods: {
    toggleDropdown() {
      this.openThemeDropdown = !this.openThemeDropdown;
      if (this.openThemeDropdown) {
        analytics.track('Analysis: Add Theme Started', { Location: 'Metadata' });
      }
    },
    openTooltip () {
      if (this.areThemesInApplyingState) {
        this.showTooltip = true;
      }
    },
    hideTooltip() {
      if (this.areThemesInApplyingState) {
        this.showTooltip = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.add-theme {
  position: relative;
  .add-theme-button {
    margin-left: 0;
    border: none;
    color: $--neutral-400;
    &:hover {
      color: $--color-primary;
      background-color: $--color-primary-light-9;
    }

    &.popover-active {
      color: $--color-primary;
      background-color: $--color-primary-light-9;
    }
  }

  .add-theme-button-content {
    display: flex;
    align-items: center;
  }

  .add-theme-button-icon {
    font-size: 12px;
    margin-right: 5px;
  }
}
</style>
