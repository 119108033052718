import { isNaN } from 'lodash';
export default (v: any, precision = 0, suffix = '') => {
  const num = parseFloat(v);
  if (!isNaN(num)) {
    const s = num.toFixed(precision);
    const result = s.replace(/((\.\d+)0+|\.0+)$/, '$2') + suffix;

    // handle if negative 0
    if (result === '-0') {
      return '0';
    } else {
      return result;
    }
  } else {
    return v || '';
  }
};
