import { isNumber } from 'lodash';
export default function format(
  n: number,
  singular = '',
  plural = ''
) {
  if (!isNumber(n)) {
    return undefined;
  } else if (singular) {
    if (n === 1) {
      return `1 ${singular}`;
    } else {
      return `${n.toLocaleString()} ${plural}`;
    }
  } else {
    return n.toLocaleString();
  }
}
