







export default {
  name: "SentimentDialChartMini",
  props: { value: { type: Number, required: true } },
  computed: {
    cssVars() {
      return { '--value': this.value };
    }
  }
};
