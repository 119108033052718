import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HoverHint, ThemeCardInterface } from './ThemeTree';
import { ThemeNodeTypes } from './ThemeCard';
import './theme-card-dropdown.scss';
import { ThemeSelectDropdown } from 'components/ThemeEditor/ThemeSelectDropdown';
import classNames from 'classnames';

interface Props {
  groupId: string;
  id: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  onOpen: () => void;
  parentId: string | undefined;
  setUpdateCandidate: (updateCandidate?: HoverHint) => void;
  updateTheme: (item: ThemeCardInterface) => void;
}

const ThemeCardDropdown = ({ updateTheme, groupId, id, onClose, onDelete, onOpen, open, parentId, setUpdateCandidate }: Props) => {
  const [showMergeMenu, setShowMergeMenu] = React.useState(false);
  const [showMoveMenu, setShowMoveMenu] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState<HoverHint | null>(null);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const mergeItemRef = React.useRef<HTMLSpanElement>(null);

  const handleDropdownClose = React.useCallback(() => {
    setShowMergeMenu(false);
    setShowMoveMenu(false);
    setUpdateCandidate(undefined);
    onClose();
  }, [onClose, setUpdateCandidate]);

  const handleMergeTheme = (selectedTheme: SelectedTheme) => {
    const { subtheme, theme } = selectedTheme;
    const hintId = subtheme || theme;
    const parentHintId = subtheme ? theme : undefined;

    const hintItem = {
      id: hintId,
      parentId: parentHintId,
      merge: true,
      type: ThemeNodeTypes.THEME_CARD
    };

    setUpdateCandidate(hintItem);
    setUpdateItem(hintItem);
  };

  const handleMoveTheme = (selectedTheme: SelectedTheme) => {
    const { subtheme, theme } = selectedTheme;
    const hintId = subtheme || theme;

    const hintItem = {
      id: hintId,
      parentId: theme,
      type: ThemeNodeTypes.THEME_CARD
    };

    setUpdateCandidate(hintItem);
    setUpdateItem(hintItem);

    analytics.track('Themes Editor: Move to')
  };

  React.useEffect(() => {
    if (updateItem) {
      updateTheme({ id, parentId });
      setUpdateItem(null);
      handleDropdownClose();
    }
  }, [handleDropdownClose, updateItem, updateTheme, id, parentId]);

  const handleShowMergeMenu = () => {
    setShowMergeMenu(true);

    analytics.track('Themes Editor: Merge with');
  }

  const handleMergeItemLeave = () => {
    setShowMergeMenu(false);
  };

  const handleShowMoveMenu = () => {
    setShowMoveMenu(true);

    analytics.track('Themes Editor: Move to');
  }

  const handleMoveItemLeave = () => {
    setShowMoveMenu(false);
  };

  const handleSetAsBaseTheme = () => {
    if (!parentId) {
      return;
    }

    const hintItem = {
      id: parentId,
      parentId: undefined,
      promote: true,
      type: ThemeNodeTypes.THEME_CARD
    };

    setUpdateCandidate(hintItem);
    setUpdateItem(hintItem);

    analytics.track('Themes Editor: Move to', {
      'Type': 'Set as base theme'
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { metaKey, key } = event;

    event.preventDefault();
    event.stopPropagation();

    if (metaKey && key === 'm') {
      setShowMergeMenu(false);

      if (!showMoveMenu) {
        handleShowMoveMenu();
      } else {
        setShowMoveMenu(false);
      }
    }

    if (metaKey && key === 'e') {
      setShowMoveMenu(false);

      if (!showMergeMenu) {
        handleShowMergeMenu();
      } else {
        setShowMergeMenu(false);
      }
    }

    if (key === 'Delete' || key === 'Backspace') {
      onDelete();
      handleDropdownClose();
    }

    if (key === 'Escape') {
      handleDropdownClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      const dropdown = dropdownRef?.current?.querySelector('.theme-card-dropdown');

      if (dropdown) {
        (dropdown as HTMLDivElement).focus();
      }
    }
  }, [open]);

  return (
    <div ref={dropdownRef} tabIndex={0} onKeyDown={handleKeyDown}>
      <Dropdown
        className={'theme-card-dropdown'}
        closeOnBlur={false}
        direction="left"
        icon={null}
        open={open}
        openOnFocus={false}
        onClose={onClose}
        onOpen={onOpen}
      >
        <DropdownMenu>
          <div onMouseOver={handleShowMergeMenu} onMouseLeave={handleMergeItemLeave}>
            <DropdownItem>
              <ThemeSelectDropdown
                groupId={groupId}
                open={showMergeMenu}
                onClose={handleDropdownClose}
                onThemeChange={handleMergeTheme}
                trigger={
                  <span
                    className={classNames('theme-card-dropdown__item theme-card-dropdown__item--top-level', {
                      'theme-card-dropdown__item--active': showMergeMenu
                    })}
                    ref={mergeItemRef}
                    tabIndex={0}
                  >
                    <span className="theme-card-dropdown__icon">
                      <FontAwesomeIcon icon="chevron-left" />
                    </span>
                    Merge with
                  </span>
                }
              />
            </DropdownItem>
          </div>
          <div onMouseOver={handleShowMoveMenu} onMouseLeave={handleMoveItemLeave}>
            <DropdownItem tabIndex={0}>
              <ThemeSelectDropdown
                groupId={groupId}
                hideSubThemes={true}
                open={showMoveMenu}
                onClose={handleDropdownClose}
                onThemeChange={handleMoveTheme}
                trigger={
                  <span
                    className={classNames('theme-card-dropdown__item theme-card-dropdown__item--top-level', {
                      'theme-card-dropdown__item--active': showMoveMenu
                    })}
                  >
                    <span className="theme-card-dropdown__icon">
                      <FontAwesomeIcon icon="chevron-left" />
                    </span>
                    Move to
                  </span>
                }
              >
                {parentId && (
                  <DropdownItem
                    as="button"
                    className="theme-select-dropdown__base-theme"
                    onClick={handleSetAsBaseTheme}
                  >
                    Set as base theme
                  </DropdownItem>
                )}
              </ThemeSelectDropdown>
            </DropdownItem>
          </div>
          <DropdownItem as="button" onClick={onDelete} tabIndex={0}>
            <span className="theme-card-dropdown__item theme-card-dropdown__item--top-level">Delete</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export { ThemeCardDropdown };
