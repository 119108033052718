<template>
  <component
    :is="componentType"
    :loading="!config"
    :version="activeDashboardUIStore.version"
    :config="config"
  />
</template>

<script>
import _ from 'lodash';

import analytics from 'lib/analytics';
import { RecentActivityTracker, RecentActivityType } from 'lib/recent-activity';
import DashboardEditable from 'vue/dashboards/DashboardEditable/DashboardEditable.vue';
import DashboardLoading from 'vue/dashboards/DashboardLoading.vue';
import DashboardUnknown from 'vue/dashboards/DashboardUnknown.vue';
import Dashboard from 'vue/dashboards/Dashboard.vue';

import { getActiveDashboardUIStore, getAnalysisToolsUIStore, getFilterStore, getUrlParametersStore, getExploreToolUIStore } from 'stores/RootStore';

export default {
  name: 'DashboardBase',
  props: {},
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
      urlParametersStore: getUrlParametersStore(),
      filterStore: getFilterStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      exploreToolUIStore: getExploreToolUIStore(),
    };
  },
  computed: {
    config() {
      return this.activeDashboardUIStore.dashboardConfig;
    },
    componentType() {
      const version = this.activeDashboardUIStore.version;
      if (this.activeDashboardUIStore.fetchDashboardError) {
        return DashboardUnknown;
      }
      if (this.activeDashboardUIStore.fetchingDashboard) {
        return DashboardLoading;
      }
      if (version == 'dashboard1') {
        return Dashboard;
      } else if (version == 'editable') {
        return DashboardEditable;
      }
      return DashboardUnknown;
    },
    activeDashboardName() {
      return this.activeDashboardUIStore.name;
    },
  },
  watch: {
    activeDashboardName: {
      handler() {
        if (this.activeDashboardName) {
          analytics.track('Dashboard: View Dashboard', {
            category: 'Dashboard',
            version: this.activeDashboardUIStore.version,
            title: this.activeDashboardName,
          });

          RecentActivityTracker.recordActivity({
            type: RecentActivityType.Dashboard,
            description: this.activeDashboardName,
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const shareUrl = _.get(this, '$route.query.share_url');
    if (shareUrl) {
      this.setBaseShareUrl({ newShareUrlBase: shareUrl });
    }
  },
  destroyed() {
    this.analysisToolsUIStore.resetAnalysis();
    this.exploreToolUIStore.resetExplore();
    this.filterStore.resetFilters();
    this.urlParametersStore.reset();
  },
};
</script>

