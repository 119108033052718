import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CreateWorkflowPartial,
  Workflow,
  WorkflowHistoryStatus,
  WorkflowStatus
} from 'api/workflow-interfaces';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Checkbox, CheckboxProps, Loader, Modal, Table } from 'semantic-ui-react';
import { WorkflowsStoreInterface } from 'stores/WorkflowsStore';
import './workflow.scss';

export interface WorkflowProps extends RouteComponentProps {
  workflow: Workflow;
  orgId: string;
  showVisibility: boolean;
  workflowsStore?: WorkflowsStoreInterface;
}

interface WorkflowState {
  deletingWorkflow: boolean;
  activeStateLoading: boolean;
  showDeleteWorkflowModal: boolean;
}

@inject('workflowsStore')
@observer
class WorkflowComponent extends React.Component<WorkflowProps, WorkflowState> {
  state = {
    deletingWorkflow: false,
    activeStateLoading: false,
    showDeleteWorkflowModal: false
  } as WorkflowState;

  manageWorkflow = () => {
    const { orgId, workflow } = this.props;
    const url = `/c/${ orgId }/workflow/${ workflow.id }`;
    this.props.history.push(url);
  }
  viewWorkflowHistory = () => {
    const { orgId, workflow } = this.props;
    const url = `/c/${ orgId }/workflow/${ workflow.id }/history`;
    this.props.history.push(url);
  }
  deleteWorkflow = async () => {
    const { workflowsStore, workflow } = this.props;
    this.setState({ deletingWorkflow: true });

    analytics.track('Workflows: Delete Workflow', { category: 'Workflows' });
    await workflowsStore!.deleteWorkflow(workflow.id!);
    if (workflowsStore!.deleteWorkflowErroredMessage !== undefined) {
      this.setState({ deletingWorkflow: false });
    }
  }

  handleToggleEnabledChange = async ({ checked }: CheckboxProps) => {
    this.setState({ activeStateLoading: true });
    if (!this.props.workflow.id) {
      return;
    }
    const enabled = !!checked;

    const update: CreateWorkflowPartial = { enabled };
    await this.props.workflowsStore!.updateWorkflow(this.props.workflow.id, update);
    this.setState({ activeStateLoading: false });
  };

  handleWorkflowVisibility = (value: boolean) => {
    if (!this.props.workflow.id) {
      return;
    }
    // toggling the visibility here
    let isPreview;
    if (value) {
      isPreview = false;
    } else {
      isPreview = true;
    }

    const update: CreateWorkflowPartial = { isPreview };
    this.props.workflowsStore!.updateWorkflow(this.props.workflow.id, update);
  }

  openDeleteWorkflowModal = () => {
    this.setState({ showDeleteWorkflowModal: true });
  }

  closeDeleteWorkflowModal = () => {
    this.setState({ showDeleteWorkflowModal: false });
  }

  render(): JSX.Element | null {
    const { workflow, showVisibility } = this.props;
    const { deletingWorkflow, activeStateLoading, showDeleteWorkflowModal } = this.state;
    const { status, enabled, name, mostRecentRunStatus } = workflow;
    const isPreview = status === WorkflowStatus.PREVIEW_ONLY;
    const visibilityCellClass = showVisibility ? ' active-visibility-cell' : ' not-active-visibility-cell';

    return (
      <React.Fragment>
        <Table.Row
          className={'nw--workflow-row' + (visibilityCellClass)}
        >
          <Table.Cell className="workflow-active-check">
            {activeStateLoading ?
              <Loader
                size="small"
                active={true}
                inline={true}
              />
              :
              <Checkbox
                className="nw--show-custom-permissions"
                toggle={true}
                label=""
                checked={enabled}
                onChange={(_e, data) => this.handleToggleEnabledChange(data)}
              />
            }
          </Table.Cell>
          {showVisibility && (
            <Table.Cell
              className="workflow-visibility"
            >
              <Button
                type="button"
                data-testid="workflow-visibility-button"
                size="small"
                onClick={() => this.handleWorkflowVisibility(isPreview)}
                icon={isPreview ?
                  <FontAwesomeIcon
                    icon="eye-slash"
                    className="icon"
                    fixedWidth={true}
                  />
                  :
                  <FontAwesomeIcon
                    icon="eye"
                    className="icon"
                    fixedWidth={true}
                  />
                }
              />
            </Table.Cell>
          )}
          <Table.Cell className="nw--name workflow-name">
            {name}
          </Table.Cell>
          <Table.Cell className="workflow-status">
            {mostRecentRunStatus === WorkflowHistoryStatus.ERRORED && (
              <span>
                <FontAwesomeIcon
                  data-testid="error-icon"
                  className="icon errored"
                  icon="exclamation-triangle"
                  fixedWidth={true}
                />
              </span>)}
            {mostRecentRunStatus !== WorkflowHistoryStatus.ERRORED && (
              <span>
                <FontAwesomeIcon
                  data-testid="success-icon"
                  className="icon success"
                  icon="check-circle"
                  fixedWidth={true}
                />
              </span>
            )}
          </Table.Cell>
          <Table.Cell className="workflow-options">
            <Button
              size="small"
              disabled={activeStateLoading}
              onClick={this.manageWorkflow}
            >
              Edit
            </Button>
            <Button
              size="small"
              disabled={activeStateLoading}
              onClick={this.viewWorkflowHistory}
            >
              View History
            </Button>
            <Button
              size="small"
              disabled={activeStateLoading}
              onClick={this.openDeleteWorkflowModal}
            >
              Delete
            </Button>
          </Table.Cell>
          <>
            <Modal
              open={showDeleteWorkflowModal}
              size="small"
              className="delete-workflow-modal"
              closeOnDimmerClick={true}
              dimmer="blurring"
            >
              <Modal.Content>
                <div className="modal-content">
                  <div className="content-icon">
                    <FontAwesomeIcon
                      icon="trash-alt"
                      className="trash-icon"
                      fixedWidth={true}
                    />
                  </div>
                  <div className="modal-body">
                    <div className="modal-header">
                      Do you want to delete "{name}"?
                    </div>
                    <div className="modal-description">
                      You can't undo this action.
                    </div>
                  </div>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="button"
                  onClick={this.closeDeleteWorkflowModal}
                >
                  Cancel
                </Button>
                <Button
                  className="delete-workflow-button"
                  type="button"
                  loading={deletingWorkflow}
                  disabled={deletingWorkflow}
                  onClick={this.deleteWorkflow}
                >
                  Delete
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        </Table.Row>
      </React.Fragment>
    );
  }
}

export default withRouter(WorkflowComponent);
