import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus } from 'api/enums';
import { differenceBy, isEmpty, partition, uniqBy } from 'lodash';
import { reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import { Dimmer, Header, Loader, Segment } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import SurveyCard from './SurveyCard';
import SurveyCreate from './SurveyCreate';
import './survey-upload.scss';
import './upload.scss';

interface UploadStoreProps {
  surveyStore?: SurveyStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

type PartitionedSurveys = {
  hidden: LiteSurvey[],
  active: LiteSurvey[],
  deactivated: LiteSurvey[]
};

type State = {
  changingTo: 'ACTIVE' | 'DEACTIVATED' | null,
  changingDataSetId: string | null
} & PartitionedSurveys;

export interface UploadProps extends UploadStoreProps {
}

function partitionTargets(store: SurveyStoreInterface): PartitionedSurveys {

  const uploadTargets: LiteSurvey[] = uniqBy([
    ...store.getSurveysThatCanAction('create:upload'),
    ...store.getSurveysThatCanAction('manage:survey')
  ], 'surveyId');

  const [hidden, visible] = partition(uploadTargets, t => t.isPreview);
  const [active, deactivated] = partition(visible, t => t.isActive);

  return {
    hidden, active, deactivated
  };

}

@inject(
  'surveyStore',
  'organizationStore'
)
@observer
class Upload extends React.Component<UploadProps, State> {
  interval: ReturnType<typeof setInterval> | null;

  constructor(props: UploadStoreProps) {
    super(props);

    const { active, hidden, deactivated } = props.surveyStore
      ? partitionTargets(props.surveyStore)
      : { active: [], hidden: [], deactivated: [] };

    this.state = {
      changingTo: null,
      changingDataSetId: null,
      hidden,
      active,
      deactivated
    };
  }

  static getDerivedStateFromProps(props: UploadProps, prevState: State): State | null {

    const { surveyStore } = props;

    if (!surveyStore) {
      return null;
    }

    const { active, hidden, deactivated } = partitionTargets(surveyStore);

    const [activeId] = differenceBy(active, prevState.active, s => s.surveyId).map(s => s.surveyId);
    const [deactivatedId] = differenceBy(deactivated, prevState.deactivated, s => s.surveyId).map(s => s.surveyId);

    // By examining the change in props, we determine if the dataset is becoming active or deactivated.
    const changedToActive = !!prevState.deactivated.find(d => d.surveyId === activeId);
    const changedToDeactivated = !!prevState.active.find(a => a.surveyId === deactivatedId);

    // The changingDataSetId identifies which cards should have CSS transitions
    const changingDataSetId = changedToActive ? activeId : changedToDeactivated ? deactivatedId : null;

    return {
      changingDataSetId,

      // We note which group the card is going to so the right card can be scrolled into view
      changingTo: changedToActive ? 'ACTIVE' : changedToDeactivated ? 'DEACTIVATED' : null,

      // Given the dataset is leaving a group, we keep it in the group while
      // the leaving CSS transitions plays.
      active: changedToDeactivated ? prevState.active : active,
      deactivated: changedToActive ? prevState.deactivated : deactivated,
      hidden
    };
  }

  componentDidUpdate(_: UploadProps, prevState: State) {
    if (!prevState.changingDataSetId && this.state.changingDataSetId) {

      // Two cards can be present for the same survey - one playing a leaving
      // animation and the other playing an entry animation. We expect the
      // first item will be in the "active" group and the second in the
      // "deactivated" group.
      const els = document.querySelectorAll(`[data-id="${this.state.changingDataSetId}"]`);

      switch (this.state.changingTo) {
        case 'ACTIVE': {
          els[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
        }
        case 'DEACTIVATED': {
          els[1]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
        }
        default: return;
      }
    }
  }

  onTransitionEnd(e: React.SyntheticEvent) {

    // The leave transition will invoke the entry transition by resetting the state:
    if (e.target instanceof HTMLDivElement && e.target.classList.contains('upload-card')) {
      if (this.state.changingDataSetId) {
        this.setState({
          changingTo: null,
          changingDataSetId: null
        });
      }
    }
  }

  componentDidMount() {
    const { surveyStore } = this.props;

    this.fetchSurveys();

    disposeOnUnmount(
      this,
      reaction(
        () => surveyStore!.surveys.map(s => s),
        (s) => this.watchSurveys(s),
        { fireImmediately: true }
      )
    );
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  watchSurveys(surveys: LiteSurvey[]) {
    const surveysInProgress = surveys.find(survey =>
      (!survey.manualUploadAllowed && survey.dataStatus === SurveyDataStatus.NODATA) ||
      survey.dataStatus === SurveyDataStatus.PROCESSING
    );

    if (surveysInProgress) {
      if (!this.interval) {
        this.interval = setInterval(() => this.fetchSurveys(), 30000);
      }
    } else {
      this.clearInterval();
    }
  }

  fetchSurveys = async () => {
    const { surveyStore } = this.props;
    await surveyStore!.fetchSurveys();
  }

  canCreateSurveys = (): boolean => {
    const { organizationStore } = this.props;
    if (organizationStore) {
      return organizationStore?.getCanAction('manage:survey');
    }
    return false;
  };

  render(): JSX.Element | null {
    const { surveyStore } = this.props;
    const { fetchingSurveys } = surveyStore!;

    const uploadTargets = uniqBy([
      ...surveyStore!.getSurveysThatCanAction('create:upload'),
      ...surveyStore!.getSurveysThatCanAction('manage:survey')
    ], 'surveyId');

    const canCreateSurveys = this.canCreateSurveys();

    if (!!fetchingSurveys && isEmpty(uploadTargets)) {
      return (
        <Segment className="fullscreen">
          <Dimmer active={true} inverted={true}>
            <Loader size="large">Loading&hellip;</Loader>
          </Dimmer>
        </Segment>
      );
    }

    const { active, deactivated, hidden, changingDataSetId } = this.state;

    return (
      <Segment className="every manage-data" onTransitionEnd={(e: React.SyntheticEvent) => this.onTransitionEnd(e)}>
        <Header as="h1">Manage data</Header>
        {!canCreateSurveys && uploadTargets.length === 0 && (
          <div className="no-upload-targets">
            <div>No valid upload targets</div>
          </div>
        )}
        <>
          <div className="container upload-grid" data-id="active-group">
            <Header as="h3">Active</Header>
            {active.map(target => (
              <SurveyCard
                isChanging={target.surveyId === changingDataSetId}
                key={target.surveyId}
                survey={target}
              />
            ))}
            {(canCreateSurveys) && (
              <SurveyCreate canCreateSurveys={canCreateSurveys} />
            )}
          </div>
        </>
        {deactivated.length > 0 && (
          <>
            <div className="container upload-grid" data-id="deactivated-group">
              <Header as="h3">Deactivated</Header>
              {deactivated.map(target => (
                <SurveyCard
                  isChanging={target.surveyId === changingDataSetId}
                  key={target.surveyId}
                  survey={target}
                />
              ))}
            </div>
          </>
        )}
        {hidden.length > 0 && (
          <>
            <div className="container upload-grid" data-id="hidden-group">
            <Header as="h3">
              <FontAwesomeIcon className="icon" icon="eye-slash" />
               Hidden
              </Header>
              {hidden.map(target => (
                <SurveyCard
                  isChanging={target.surveyId === changingDataSetId}
                  key={target.surveyId}
                  survey={target}
                />
              ))}
            </div>
          </>
        )}
      </Segment>
    );
  }

}

export default Upload;
