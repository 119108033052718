import queryBuilder from "vue/libs/queryBuilder";
import thematicData from "vue/libs/thematicData";
import { CommentTheme, PlainComment, Summary, Thread } from "types/custom";

type Sentiment = 'positive' | 'negative' | 'neutral';

const appendThemesToSegmentFilter = (theme: CommentTheme) => {
  return queryBuilder.appendThemesToSegmentFilter(
    '',
    theme.theme.code,
    theme.subtheme ? theme.subtheme.code : undefined
  );
}

const appendToSegmentFilter = (theme: CommentTheme, sentiment: Sentiment) => {
  const themesFilterSegment = appendThemesToSegmentFilter(theme);

  return queryBuilder.appendToSegmentFilter(themesFilterSegment, `sentiment:${sentiment}`);
}

interface FetchWidgetProps {
  dashboardId: string;
  filterString: string;
  sentiment: Sentiment;
  source: string;
  theme: CommentTheme;
}

const useFetchWidgetComments = async ({
  dashboardId,
  filterString,
  sentiment,
  source,
  theme
}: FetchWidgetProps): Promise<PlainComment[] | Thread[] | Summary[] | null> => {
  if (!theme) {
    return null;
  }

  const segmentFilter = sentiment
    ? appendToSegmentFilter(theme, sentiment)
    : appendThemesToSegmentFilter(theme);

  const filterWithSegment = queryBuilder.appendSegmentFilterToFilter(filterString, segmentFilter);

  const url = `${source}reportComments?forReport=${dashboardId}&filter=${encodeURIComponent(filterWithSegment)}&pageSize=10`;

  try {
    const comments = await thematicData.getWidgetComments(url);

    return comments;
  } catch (error) {
    return error;
  }
}

export { useFetchWidgetComments };
