import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MappedPhrasesSuggestionForm from './MappedPhrasesSuggestionForm';
import { ThemeTreeItem } from 'stores/ThemesStore';

export interface MappedPhrasesSuggestionButtonProps {
    orgId: string;
    surveyId: string;
    activeNode?: ThemeTreeItem;
    addPhrases(proposedPhrases: string[]): void;

}

export interface MappedPhrasesSuggestionButtonState {
    showMappedPhrasesSuggestions: boolean;
}

export default class MappedPhrasesSuggestionButton extends React.Component<
  MappedPhrasesSuggestionButtonProps,
  MappedPhrasesSuggestionButtonState
> {
  state = {
    showMappedPhrasesSuggestions: false
  };
  addPhrases = (proposedPhrases: string[]) => {
    // call up the stack to add phrases
    const { addPhrases } = this.props;
    addPhrases(proposedPhrases);
    // and close the dialog
    this.setState({ showMappedPhrasesSuggestions: false });
  };
  cancelModal = () => {
    this.setState({ showMappedPhrasesSuggestions: false });
  };
  showModal = () => {
    this.setState({ showMappedPhrasesSuggestions: true });
  };
  render() {
    const { orgId, surveyId, activeNode } = this.props;
    const { showMappedPhrasesSuggestions } = this.state;
    return (
      <>
        <Button size="small" onClick={this.showModal}>
                    <FontAwesomeIcon icon="magic" className="icon" />
                    Auto-suggest
            </Button>
        {showMappedPhrasesSuggestions && (
          <MappedPhrasesSuggestionForm
            orgId={orgId}
            surveyId={surveyId}
            activeNode={activeNode}
            onAccept={this.addPhrases}
            onClose={this.cancelModal}
          />
        )}
      </>
    );
  }
}
