import * as React from 'react';
import './card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum CardColors {
  YELLOW = 'yellow',
  PURPLE = 'purple',
  TEAL = 'teal',
  GOLD = 'gold',
  LIME = 'lime',
  BLUE = 'blue'
}

interface Props {
  size?: 'small' | 'medium';
  icon: IconDefinition;
  color: 'yellow' | 'purple' | 'teal' | 'gold' | 'lime' | 'blue';
  heading: string;
  content: string;
  onClick: () => void;
}

const Card = ({
  size = 'medium',
  icon,
  color,
  heading,
  content,
  onClick
}: Props) => {
  return (
    <div className={`thematic-card thematic-card--${size}`}>
      <button
        className="thematic-card-action"
        type="button"
        onClick={onClick}
      >
        <span className={`thematic-card-icon thematic-card-icon--${color}`}>
          <FontAwesomeIcon icon={icon} className="icon" />
        </span>
        <span className="thematic-card-text">
          <h3 className="thematic-card-heading">{heading}</h3>
          <p className={`thematic-card-content thematic-card-content--${color}`}>
            {content}
          </p>
        </span>
      </button>
    </div>
  );
};

export { Card };
