import { action, observable } from 'mobx';
import { PhraseSegmentTheme } from 'types/custom';

export interface HaveEditedCommentParams {
  themesAdded?: AddedTheme[],
  themesRemoved?: PhraseSegmentTheme[],
  themesRemovedFromPopup?: Array<{
    location: [start: number, end: number],
    theme: PhraseSegmentTheme
  }>
}
export interface EditedComments {
  [commentId: string]: {
    themesAdded: AddedTheme[],
    themesRemoved: PhraseSegmentTheme[],
    themesRemovedFromPopup: Array<{
      location: [start: number, end: number],
      theme: PhraseSegmentTheme
    }>
  }
}

export interface AddedTheme {
  id: string;
  base: string;
  baseTitle: string;
  sub?: string;
  subTitle?: string;
  label: string;
}

// An intersection of AddedTheme and PhraseTheme
export interface IntersectedTheme {
  base: string;
  baseTitle: string;
  sub?: string;
  subTitle?: string;
}

export interface QuickEditUIStoreInterface {

  saveEditedComments: (surveyId: string) => void;
  fetchEditedComments: (surveyId: string) => void;
  removeEditedComments: (surveyId: string) => void;
  haveEditedComment: (commentId: string, parameters: HaveEditedCommentParams) => void;

  editedComments: EditedComments;

}

function matches(a: IntersectedTheme, b: IntersectedTheme): boolean {
  return a.base === b.base && a.sub === b.sub;
}

class QuickEditUIStore implements QuickEditUIStoreInterface {

  @observable editedComments = {};

  @action
  haveEditedComment = (
    commentId: string,
    parameters: HaveEditedCommentParams
  ) => {

    const editedComment = this.editedComments[commentId] || {
      themesAdded: [] as AddedTheme[],
      themesRemoved: [] as PhraseSegmentTheme[],
      themesRemovedFromPopup: [] as PhraseSegmentTheme[]
    };

    if (parameters.themesAdded) {
      parameters.themesAdded.forEach(theme => {
        if (!editedComment.themesAdded.some((t: AddedTheme) => matches(t, theme))) {
          editedComment.themesAdded.push(theme);
        }
      });

    }
    if (parameters.themesRemoved) {
      parameters.themesRemoved.forEach(theme => {
        if (!editedComment.themesRemoved.some((t: PhraseSegmentTheme) => matches(t, theme))) {
          editedComment.themesRemoved.push(theme);
        }
      });
    }
    if (parameters.themesRemovedFromPopup) {
      parameters.themesRemovedFromPopup.forEach(({ theme, location }) => {
        if (!editedComment.themesRemovedFromPopup.some((t: PhraseSegmentTheme) => matches(t, theme))) {
          editedComment.themesRemovedFromPopup.push({ theme, location });
        }
      });
    }

    this.editedComments[commentId] = editedComment;
  }

  @action
  saveEditedComments = (surveyId: string) => {
    localStorage.setItem(`${ surveyId }_edited_comments`, JSON.stringify(this.editedComments));
  }

  @action
  fetchEditedComments = (surveyId: string) => {
    const fetchedValue = localStorage.getItem(`${ surveyId }_edited_comments`);
    if (fetchedValue) {
      this.editedComments = JSON.parse(fetchedValue);
    }
  }

  @action
  removeEditedComments = (surveyId: string) => {
    localStorage.removeItem(`${ surveyId }_edited_comments`);
    this.editedComments = {};
  }

}

export default QuickEditUIStore;
