import { AnswersActionType, SavedAnswer, SavedAnswerCandidate, SavedAnswerMeta } from 'api/interfaces';
import { reduceNodes } from 'lib/node-helpers';
import { dropWhile, takeWhile } from 'lodash';
import { AnswerNode, AnswerOutput, Node } from 'types/custom';

export const findNodeById = (
  nodes: AnswerNode[],
  id: string,
): AnswerNode | null => {
  return reduceNodes(
    (x: null | AnswerNode, node: AnswerNode) => node.item.id === id ? node : x,
    nodes,
    null
  );
};

function collectOutput(
  result: Node<AnswerOutput>[],
  node: AnswerNode
): Node<AnswerOutput>[] {

  if (node.item.state === 'output') {
    const nextNode = {
      item: node.item,
      children: node.children.reduce((x, c) => collectOutput(x, c), [])
    };

    return [...result, nextNode];

  }

  return result;

}

type Part = SavedAnswerCandidate['answer'][0];

// Takes a tree of nodes and produces a list of 'parts' to be persisted
export function toParts(nodes: AnswerNode[]): Part[] {

  return nodes
    .reduce((result, node) => collectOutput(result, node), [])
    .reduce((result: Part[], node): Part[] => {

      const type = node.item.content.type;

      if (type === 'text' || type === 'table' || type === 'multipart') {

        const part: Part = {
          dataSets: node.item.dataSets,
          filters: node.item.filters || {},
          question: node.item.content.question,
          contents: node.item.content.contents,
          data: node
        };

        return [...result, part];
      }

      return result;

    }, []);

}

// Extracts trees of nodes from persisted parts
export function fromParts(parts: Part[]): Node<AnswerOutput>[] {
  return parts.map(({ data }) => data);
}

export function toMeta(savedAnswer: SavedAnswer): SavedAnswerMeta {

  const synopsis = savedAnswer.answer.reduce((
    result: string,
    part: SavedAnswer['answer'][0]
  ) => {
    return `${ result }${ part.contents }`;
  }, '');

  const datasetNames = savedAnswer.answer.reduce((
    result: string[],
    part: SavedAnswer['answer'][0]
  ) => {
    const newTitles = part.dataSets
      .map(ds => ds.title)
      .filter(title => !result.includes(title));
    return [...result, ...newTitles];
  }, []);

  return {
    id: savedAnswer.id,
    title: savedAnswer.title,
    synopsis,
    datasetNames,
    createdAt: new Date(savedAnswer.createdAt ?? ''),
    updatedAt: new Date(savedAnswer.updatedAt ?? ''),
    createdBy: savedAnswer.createdBy
  };

}

function isOfType(node: AnswerNode, type: string, actionType: AnswersActionType): boolean {
  switch (node.item.state) {
    case 'output': return node.item.content.type === type;
    case 'loading': return node.item.actionType === actionType;
    case 'error': return node.item.originalAction.action === actionType;
    default: return false;
  }
}

export function isVisualizationNode(node: AnswerNode): boolean {
  return isOfType(node, 'visualization', AnswersActionType.addVisualization);
}

export function isSuggestionNode(node: AnswerNode): boolean {
  return isOfType(node, 'suggestions', AnswersActionType.suggestSolutions);
}

export function isQuotesNode(node: AnswerNode): boolean {
  return isOfType(node, 'quotes', AnswersActionType.selectQuotes);
}

export function isToggleableNode(node: AnswerNode): boolean {
  return isVisualizationNode(node) || isSuggestionNode(node) || isQuotesNode(node);
}

export function isMoreDepthNode(node: AnswerNode): boolean {
  if (node.item.state === 'output') {
    return node.item.actionType === 'moreDepth'
  }
  return false;
}

export function canBeToggledByAction(actionType: AnswersActionType, node: AnswerNode): boolean {
  switch (actionType) {
    case AnswersActionType.suggestSolutions: return isSuggestionNode(node);
    case AnswersActionType.addVisualization: return isVisualizationNode(node);
    case AnswersActionType.selectQuotes: return isQuotesNode(node);
    default: return false;
  }
}

function orderToggleableNodes(a: AnswerNode, b: AnswerNode): -1 | 0 | 1 {
  if (isVisualizationNode(a) && !isVisualizationNode(b)) { return -1; }
  if (isVisualizationNode(b) && !isVisualizationNode(a)) { return 1; }

  if (isQuotesNode(a) && !isQuotesNode(b)) { return -1; }
  if (isQuotesNode(b) && !isQuotesNode(a)) { return 1; }

  if (isSuggestionNode(a) && !isSuggestionNode(b)) { return -1; }
  if (isSuggestionNode(b) && !isSuggestionNode(a)) { return 1; }

  return 0;
}

export function insertChild(
  newNode: AnswerNode,
  topLevelNode: AnswerNode,
  originNodeId: string
) {

  if (isToggleableNode(newNode)) {

    // Given the origin is not a child of the top level node, the origin is inferred to be the top level node.
    const originIndex = topLevelNode.children.findIndex(child => child.item.id === originNodeId);

    // We split the list into 3:
    // - nodes up to and including the origin (if present)
    // - any toggleable (vis/quote/suggestion) nodes that follow the origin
    // - everything after that.
    // This enables us to insert the new toggleable node among the others, sort them, then recombine the list.

    const firstIncOrigin = topLevelNode.children.slice(0, originIndex + 1);
    const afterOrigin = topLevelNode.children.slice(originIndex + 1);
    const siblingToggleables = takeWhile(afterOrigin, isToggleableNode);
    const rest = dropWhile(afterOrigin, isToggleableNode);

    const toggleables = [...siblingToggleables, newNode].sort(orderToggleableNodes);

    return [
      ...firstIncOrigin,
      ...toggleables,
      ...rest
    ];

  }

  // Given the new node is not toggleable, it goes to the end of the list.
  return [...topLevelNode.children, newNode];

}

export const toggleableActions = [
  AnswersActionType.addVisualization,
  AnswersActionType.selectQuotes,
  AnswersActionType.suggestSolutions
];
