<template>
  <widget-wrapper
    :aria-label="subtitle"
    :title="title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="score-graph-container widget-body"
    >
      <line-graph
        v-if="!empty"
        :values="values"
        :labels="labels"
      />
      <div v-else>
        No data found
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { LineGraph } from 'components/Dashboard/Widgets/LineGraph';
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import {
  canNavigate,
  goToAnalysis,
  getDestination
} from 'components/Dashboard/Utils/navigation-helpers';

import WidgetWrapper from '../WidgetWrapper';

export default {
  name: 'OVERTIMEGRAPH',
  components: {
    LineGraph: ReactInVue(LineGraph),
    'widget-wrapper': WidgetWrapper,
  },
  props: {
    orgId: { type: String },
    error: { type: String },
    warning: { type: String },
    periodResolution: { type: String },
    labels: { type: Array },
    loading: { type: Boolean },
    scoreName: { type: String },
    isWeighted: { type: Boolean, default: false },
    source: { type: String },
    title: { type: String },
    values: { type: Array },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {
    canNavigate() {
      return canNavigate({ source: this.source, isWeighted: this.isWeighted });
    },
    empty() {
      return !this.values || !this.values.length;
    },
    numPeriods() {
      return this.labels.length;
    },
    periodName() {
      if (this.periodResolution === 'rolling90') {
        return 'periods';
      } else if (this.periodResolution === 'quarterly') {
        return 'quarters';
      } else if (this.periodResolution === 'monthly') {
        return 'months';
      } else if (this.periodResolution === 'weekly') {
        return 'weeks';
      } else if (this.periodResolution === 'daily') {
        return 'days';
      }
      return undefined;
    },
    subtitle() {
      let title = `Change in ${this.scoreName}`;
      if (this.periodName) {
        title += ` over last ${this.numPeriods} ${this.periodName}`;
      }
      return title;
    },
  },
  methods: {
    doNavigate() {
      const { source, isWeighted } = this;

      if (!canNavigate({ source, isWeighted })) {
        return;
      }

      const destination = getDestination({
        orgId: this.orgId,
        source,
        tool: 'over-time'
      });
      goToAnalysis(destination);
    }
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

