import { isNumber } from 'lodash';
export const VALID_SENTIMENTS = [
  'tired',
  'frown',
  'meh',
  'smile',
  'grin-stars'
];
export function calculateSentiment(val: number | string) {
  if (!isNumber(val)) {
    return undefined;
  }
  const [TIRED, FROWN, MEH, SMILE, GRIN] = VALID_SENTIMENTS;
  if (val <= -0.6) {
    return TIRED;
  } else if (val <= -0.05) {
    return FROWN;
  } else if (val <= 0.05) {
    return MEH;
  } else if (val <= 0.6) {
    return SMILE;
  } else {
    return GRIN;
  }
}

export const sentimentMap = new Map<'frown' | 'meh' | 'smile', number>([
  ['frown', -0.2],
  ['meh', 0],
  ['smile', 0.2]
]);

export type SentimentName = 'Negative' | 'Positive' | 'Neutral';

const sentimentNameMap = new Map<'frown' | 'meh' | 'smile', SentimentName>([
  ['frown', 'Negative'],
  ['meh', 'Neutral'],
  ['smile', 'Positive']
]);

export function getSentimentName(sentiment: number): SentimentName | undefined {
  const simpleSentiment = calculateSimpleSentiment(sentiment);
  return simpleSentiment
    ? sentimentNameMap.get(simpleSentiment)
    : undefined;
}

// This is for a simpler sentiment design with just 3 variations
// to make it more colorblind-friendly
export enum ValidSimpleSentiments {
  smile = 'smile',
  meh = 'meh',
  frown = 'frown'
}

export function calculateSimpleSentiment(val: number | string) {
  if (!isNumber(val)) {
    return undefined;
  }
  if (val > 0.05) {
    return ValidSimpleSentiments.smile;
  } else if (val > -0.05) {
    return ValidSimpleSentiments.meh;
  } else {
    return ValidSimpleSentiments.frown;
  }
}
