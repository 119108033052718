import * as escapeRe from 'escape-string-regexp';
import { map, sortBy } from 'lodash';
import * as React from 'react';
import * as Highlighter from 'react-highlight-words';
import { List } from 'semantic-ui-react';

interface ExampleCommentProps {
  comment: string;
  isTruncated: boolean;
  phrases?: string[];
}

export default (props: ExampleCommentProps) => {
  const { comment, phrases: activePhrases, isTruncated } = props;
  const updatedComment = isTruncated ? `...${comment}...` : comment;

  let phrases = [] as string[];
  let search = undefined as RegExp|undefined;

  // handle when there are phrases that should be highlighted
  if (activePhrases) {
    // sort so it finds longest phrases first
    phrases = sortBy(activePhrases, p => -p.length);
    search = new RegExp(map(phrases, p => escapeRe(p)).join('|'), 'i');
  }

  return (
    <List.Item className="list-action">
      <List.Content>
        <Highlighter
          highlightClassName="highlighted-word"
          searchWords={[search]}
          textToHighlight={updatedComment}
        />
      </List.Content>
    </List.Item>
  );
};
