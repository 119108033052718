import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Form } from 'semantic-ui-react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceDelightedCreateFormProps {
    onChange(configuration: object, integrationId: string): void;
}

interface DelightedSurveyInfo {
  response_count: string;
  csat?: string;
  nps?: string;
}

interface DataSourceDelightedCreateFormState {
}

@observer
class DataSourceDelightedCreateForm extends React.Component<
    DataSourceDelightedCreateFormProps,
    DataSourceDelightedCreateFormState
> {

  renderForm(selectedSurvey: DelightedSurveyInfo | undefined): JSX.Element | null {
    if (selectedSurvey) {
      return (
        <Form>
          <Form.Field>
              <label>Response Count</label> <span>{selectedSurvey.response_count}</span>
          </Form.Field>
          {selectedSurvey.nps && (<Form.Field>
              <label>NPS</label> <span>{selectedSurvey.nps}</span>
          </Form.Field>)}
          {selectedSurvey.csat && (<Form.Field>
              <label>CSAT</label> <span>{selectedSurvey.csat}</span>
          </Form.Field>)}
        </Form>
      );
    }
    return null;
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;

    return (
        <div>
            <DataSourceWithIntegrationForm
              renderFormComponent={this}
              onChange={onChange}
              integrationType={IntegrationType.DELIGHTED} />
        </div>
    );
  }
}

export default DataSourceDelightedCreateForm;