import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Auth from 'Auth/Auth';
import classnames from 'classnames';
import NestedAnalysisToolNav from 'components/AnalysisTool/NestedAnalysisToolNav';
import { AnswersNav } from 'components/Answers/AnswersNav';
import DashboardNav from 'components/DashboardNav/DashboardNav';
import UserMenu from 'components/Nav/UserMenu';
import OrgLogo from 'components/OrgNav/OrgLogo';
import OrgName from 'components/OrgNav/OrgName';
import analytics from 'lib/analytics';
import { FeatureFlagManager, FlagKeys, QA_KEY } from 'lib/feature-flag';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import config from 'runtime-config';
import { Button, Dropdown, Menu, Message } from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';
import './nav-bar.scss';

interface NavBarStoreInterface {
  userStore?: UserStoreInterface;
}

interface NavBarParams {
  orgId: string;
}

export interface NavBarProps extends NavBarStoreInterface, RouteComponentProps<NavBarParams> {
  fetching: boolean;
}

@inject('userStore')
@observer
class NavBar extends React.Component<NavBarProps> {
  login = () => {
    Auth.login();
  };
  logout = () => {
    analytics.track('logout', { action: 'clicked logout from NavBar' });
    Auth.logout();
  };
  authComponent = () => {
    const { orgId } = this.props.match.params;

    const { userStore } = this.props;

    if (!userStore!.user) {
      return null;
    }

    const { picture: userIcon } = userStore!.user;

    const { adminLocation, alternativeRegionList } = config;

    const canSeeQA = FeatureFlagManager.checkFlag(FlagKeys.QA);

    const assumingUser = Auth.assumedUser();

    const canQA = !!sessionStorage.getItem(QA_KEY);
    return (
      <>
        {assumingUser && (
          <Menu.Item>
            <div className="org-name">Assuming:&nbsp;</div>
            <div className="org-name"> {assumingUser}</div>
          </Menu.Item>
        )}
        {canSeeQA && (
          <Menu.Item>
            <Button
              className={classnames({ 'super-subtle': !canQA })}
              color={canQA ? 'red' : undefined}
              size="tiny"
              onClick={this.toggleQA}
              title="Toggle QA feature flags. It will reload the page. (admin only)"
            >
              <FontAwesomeIcon className="icon button" icon="flask" />
            </Button>
          </Menu.Item>
        )}
        <Dropdown
          className="normal-size support"
          item={true}
          text="Help"
          icon={<FontAwesomeIcon className="icon" icon="angle-down" fixedWidth={true} />}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              href="https://help.getthematic.com"
              rel="noopener noreferrer"
              target="_blank"
              onClick={this.trackSupportLink}
            >
              Knowledge base
            </Dropdown.Item>
            <Dropdown.Item
              href="https://getthematic.com/product/watch-demo/"
              rel="noopener noreferrer"
              target="_blank"
              onClick={this.trackDemoLink}
            >
              Watch demo videos
            </Dropdown.Item>
            <Dropdown.Item
              href="mailto:support@getthematic.com"
              rel="noopener noreferrer"
              target="_blank">
              Contact support
            </Dropdown.Item>
            <Dropdown.Item href="https://getthematic.com" rel="noopener noreferrer" target="_blank">
              Thematic homepage
            </Dropdown.Item>
            <Dropdown.Item
              href="https://forms.gle/FYdHyxL4t4ZKKyEE6"
              rel="noopener noreferrer"
              target="_blank"
            >
              Leave product feedback
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <UserMenu
          adminLocation={adminLocation}
          orgId={orgId}
          userIcon={userIcon}
          alternativeRegions={alternativeRegionList}
        />
      </>
    );
  };
  toggleQA = () => {
    const qa = sessionStorage.getItem(QA_KEY);
    if (qa) {
      sessionStorage.removeItem(QA_KEY);
    } else {
      sessionStorage.setItem(QA_KEY, 'true');
    }
    window.location.reload();
  };
  trackSupportLink = () => {
    analytics.track('Open Support', { category: 'Nav' });
  };
  trackDemoLink = () => {
    analytics.track('Open Demo Video', { category: 'Nav' });
  };
  render(): JSX.Element | null {
    const { match, fetching, userStore } = this.props;
    const { suggestLogout } = userStore!;
    const { orgId } = match.params;

    return (
      <Menu inverted={true} size="massive" className="nav-bar">
        {suggestLogout && (
          <Message className="fixed nav-bar-logout-message" negative={true}>
            <Message.Content>
              <FontAwesomeIcon icon="exclamation-triangle" className="icon" fixedWidth={true} />
              Uh oh! It looks like you've been logged out. You'll need to log in again. <a onClick={this.logout}>OK</a>
            </Message.Content>
          </Message>
        )}
        {suggestLogout && <div className="nav-bar-overlay" />}
        <OrgLogo orgId={orgId} />
        <Switch>
          <Route path="/c/:orgId">
            <Menu.Menu>
              <DashboardNav orgId={orgId} fetching={!!fetching} />
              <Route path="/c/:orgId" component={NestedAnalysisToolNav} />
              <AnswersNav orgId={orgId} />
            </Menu.Menu>
          </Route>
        </Switch>
        <Menu.Menu position="right">
          <OrgName />
          {this.authComponent()}
        </Menu.Menu>
      </Menu>
    );
  }
}

export default withRouter(NavBar);
