<template>
  <div class="dashboard create">
    <el-card
      v-if="isLoading"
      v-loading="true"
    />
    <el-card v-else>
      <div slot="header">
        <h3>Create dashboard</h3>
      </div>
      <el-alert
        v-if="createDashboardError"
        :title="createDashboardError"
        type="error"
        show-icon
      />
      <div class="config-fields">
        <div class="config-field">
          <label>Name</label>
          <el-input
            v-model="dashboardName"
            placeholder="Untitled dashboard"
            :disabled="creatingDashboard"
          />
        </div>
        <div class="config-field">
          <label>Data sources</label>
          <configure-dashboard-sources @onSourcesUpdate="onSourcesUpdate" />
          <p class="note">
            Choose which datasets this dashboard will use for showing data.
          </p>
        </div>
        <div class="config-field">
          <label>Description (optional)</label>
          <el-input
            v-model="dashboardDescription"
            type="textarea"
            placeholder="Enter a short description for your dashboard"
          />
        </div>
      </div>

      <el-collapse>
        <el-collapse-item title="Advanced options">
          <div class="config-field">
            <label>Menu name</label>
            <el-input
              v-model="menuName"
              :disabled="creatingDashboard"
            />
            <p class="note">
              You can set the name that shows in the "Dashboards" navigation menu. If blank, the dashboard name will be
              used.
            </p>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="actions">
        <el-button
          class="pull-left"
          :disabled="creatingDashboard"
          @click="cancel"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="!isValid"
          :loading="creatingDashboard"
          @click="() => createDashboard()"
        >
          Create Blank
        </el-button>
        <el-button
          type="primary"
          :disabled="!isValid"
          :loading="creatingDashboard"
          @click="createDashboardFromTemplate"
        >
          Create from template
        </el-button>
      </div>
    </el-card>
  </div>
</template>


<script>
import {
  getDashboardStore,
  getActiveDashboardUIStore,
  getAggregateViewStore,
  getSurveyStore,
  getAnalysisConfigStore,
} from 'stores/RootStore';
import ConfigureDashboardSources from './ConfigureDashboardSources.vue';
import { cloneDeep, compact, reduce } from 'lodash';
import analytics from 'lib/analytics';
import { createDefaultDashboardWidgets } from 'components/Dashboard/Utils/dashboard-helper';
import { DashboardDateResolution } from '../../../api/enums';
export default {
  name: 'CreateDashboard',
  components: { ConfigureDashboardSources },
  props: {
    orgId: { type: String },
  },
  data() {
    return {
      dashboardStore: getDashboardStore(),
      activeDashboardUIStore: getActiveDashboardUIStore(),
      aggregateViewStore: getAggregateViewStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      surveyStore: getSurveyStore(),

      creatingDashboard: false,
      createDashboardError: undefined,

      menuName: '',
      dashboardName: '',
      dashboardDescription: '',
      sources: {},

      areSourcesValid: false,
      isLoadingSurveys: false,
      isLoadingAggregateViews: false,
    };
  },
  computed: {
    isValid() {
      return !!this.dashboardName && this.areSourcesValid;
    },
    isLoading() {
      return this.isLoadingSurveys || this.isLoadingAggregateViews;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async createDashboardFromTemplate() {
      const dashboardId = this.activeDashboardUIStore.currentDashboardId;
      // collect the relevant configs to create widgets for
      const configs = await reduce(
        this.sources,
        async (result, source, id) => {
          const config = await this.analysisConfigStore.getConfig(source, dashboardId);
          if (config) {
            (await result).push({ id, config });
          }
          return result;
        },
        [],
      );
      // create the widgets to pre-populate
      const widgets = createDefaultDashboardWidgets(configs);
      this.createDashboard(widgets);
    },
    async createDashboard(widgets) {
      analytics.track('Dashboard: Create Dashboard', { category: 'Dashboard' });

      this.createDashboardError = undefined;
      this.creatingDashboard = true;

      // default behaviour is a blank dashboard
      if (!widgets) {
        widgets = [];
      }

      const name = this.menuName || this.dashboardName;
      const isPreview = false;
      const configuration = {
        title: this.dashboardName,
        metadata: {
          description: this.dashboardDescription,
        },
        sources: this.sources,
        panels: [{ widgets }],
      };

      try {
        const sources = Object.values(this.sources);
        const dashboardId = this.activeDashboardUIStore.currentDashboardId;
        const configs = compact(cloneDeep(await this.analysisConfigStore.getConfigs(sources, dashboardId)));

        if (this.analysisConfigStore.fetchConfigsError) {
          throw new Error(this.analysisConfigStore.fetchConfigsError);
        }

        // Add date filter if all sources have a date column
        const hasDateColumn = configs.every((c) => c.date_column !== undefined);
        if (hasDateColumn) {
          configuration.datePicker = { resolution: DashboardDateResolution.MONTHLY };
        }
      } catch (e) {
        this.createDashboardError = e.message;
        this.creatingDashboard = false;
        return;
      }

      const payload = { name, isPreview, configuration };

      const dashboard = await this.dashboardStore.createDashboard(payload);
      if (dashboard) {
        window.location.href = `/#/c/${this.orgId}/r/${dashboard.id}?isCreate=true`;
      } else if (this.dashboardStore.createDashboardError) {
        this.createDashboardError = this.dashboardStore.createDashboardError;
        this.creatingDashboard = false;
      }
    },
    cancel() {
      window.location.href = `/#/c/${this.orgId}`;
    },
    onSourcesUpdate(sources, areSourcesValid) {
      this.sources = sources;
      this.areSourcesValid = areSourcesValid;
    },
    async getData() {
      await Promise.all([this.getSurveys(), this.getAggregateViews()]);
    },
    async getSurveys() {
      this.isLoadingSurveys = true;
      await this.surveyStore.fetchSurveys(true);
      this.isLoadingSurveys = false;
    },
    async getAggregateViews() {
      this.isLoadingAggregateViews = true;
      await this.aggregateViewStore.fetchAggregateViews();
      this.isLoadingAggregateViews = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './configure-dashboard.scss';
.dashboard.create {
  max-width: 700px;
  margin: 50px auto;

  .el-card {
    min-height: 300px;
    ::v-deep .el-collapse {
      border-top: none;
      .el-collapse-item {
        &__header {
          flex-direction: row-reverse;
          border-bottom: none;
          font-size: 14px;
          justify-content: flex-end;
          color: $--color-primary;
        }
        &__wrap {
          border-bottom: none;
        }
        &__content {
          padding-bottom: 0;
        }
        &__arrow {
          margin: 0 2px 0 0;
        }
      }
    }
  }
}
.pull-left {
  margin-right: auto;
}
</style>