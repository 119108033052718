<template>
  <el-tooltip
    :popper-options="popperOptions"
    :open-delay="300"
    :placement="placement"
    popper-class="th-tooltip"
  >
    <div slot="content">
      <slot />
    </div>
    <span>
      <font-awesome-icon icon="question-circle" />
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    placement: { default: 'right', type: String }
  },
  data() {
    return {
      popperOptions: { boundariesElement: this.$parent._uid }
    };
  }
};
</script>
<style lang="scss">
.th-tooltip {
  text-align: left;
  a {
    color: white;
    text-decoration: underline;
  }
  a:visited {
    color: white;
    text-decoration: underline;
  }
}
</style>
