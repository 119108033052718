import { scoreGraphNormalizeTwoDatasetsForThemeResponse } from 'charts/Utils/scoreGraphHelpers';
import { ThemesResponse } from 'charts/Utils/types';
import { isEmpty, map } from 'lodash';
import { RequestOptions } from 'stores/InitConfigStore';
import queryBuilder from 'vue/libs/queryBuilder';
import statistics from 'vue/libs/statistics';
import thematicData from 'vue/libs/thematicData';

interface Selection {
  query: string;
  shortTitle: string;
  title: string;
}
interface FetchOptions {
  commentColumns: number[];
  requestOptions: RequestOptions;
  selections: {
    baseline: Selection;
    comparison: Selection;
  };
}

interface FetchCountOptions {
  commentColumns: number[];
  requestOptions: any;
  selections: {
    baseline: Selection;
    comparison: Selection;
  };
  timePeriodRql: string;
}

interface ThemeVolume {
  v: number;
}

interface BaselineComparisonVolumes {
  baselineVolume: number;
  comparisonVolume: number;
  label: string;
}

export function mapScore(theme: ThemesResponse | undefined): ThemeVolume {
  if (theme && theme.scoreAllRows) {
    return { v: theme.scoreAllRows.score };
  }
  return { v: 0 };
}

function mapBaselineAndComparisonData(
  baselineTheme: ThemeVolume[],
  comparisonTheme: ThemeVolume[],
  labels: string[]
): BaselineComparisonVolumes[] {
  return baselineTheme.map((baseline, index) => {
    const comparison = comparisonTheme[index];
    return {
      baselineVolume: baseline.v,
      comparisonVolume: comparison.v,
      label: labels[index]
    };
  });
}

export async function doFetchCounts(opts: FetchCountOptions) {
  const { commentColumns, requestOptions, selections, timePeriodRql} = opts;
  if (isEmpty(selections)) {
    return undefined;
  }

  const promises = Promise.all([
    thematicData.getCounts(
      queryBuilder.appendToFilter(selections.baseline.query, timePeriodRql),
      commentColumns,
      requestOptions
    ),
    thematicData.getCounts(
      queryBuilder.appendToFilter(selections.comparison.query, timePeriodRql),
      commentColumns,
      requestOptions
    )
  ]);

  const [baseline, comparison] = await promises;
  const counts = {
    baseline,
    comparison
  };

  return counts;
}

export async function doFetch(opts: FetchOptions) {
  const { commentColumns, requestOptions, selections } = opts;
  // if selections is "empty", we're done
  if (isEmpty(selections)) {
    return undefined;
  }
  const promises = Promise.all([
    thematicData.getThemesByDate(
      selections.baseline.query,
      commentColumns,
      requestOptions
    ),
    thematicData.getThemesByDate(
      selections.comparison.query,
      commentColumns,
      requestOptions
    )
  ]);
  const [baseline, comparison] = await promises;

  //Leave uninitialized until data has been recieved
  //this happens when mounted is called early
  if (!comparison.themes || !baseline.themes) {
    return undefined;
  }

  for (let i = 1; i < baseline.themes.length; i++) {
    statistics.create_sig_diff_values(
      baseline.themes[i],
      baseline.themes[i - 1]
    );
  }
  for (let i = 1; i < comparison.themes.length; i++) {
    statistics.create_sig_diff_values(
      comparison.themes[i],
      comparison.themes[i - 1]
    );
  }

  const scores = map(baseline.themes, mapScore);

  baseline.title = selections.baseline.title;
  comparison.title = selections.comparison.title;

  const isNps = requestOptions.scoreType === 'nps';
  const yAxisLabel = isNps ? 'NPS' : 'Score';

  const normalizedComparison = scoreGraphNormalizeTwoDatasetsForThemeResponse(
    baseline.dateLabels,
    comparison.dateLabels,
    comparison.themes
  );
  const comparisonData: ThemeVolume[] = map(normalizedComparison, mapScore);
  const combinedData = mapBaselineAndComparisonData(scores, comparisonData, baseline.dateLabels);

  return {
    baseline,
    comparison,
    yAxisLabel,
    combinedData
  };
}
