import * as React from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { ThemeSelectDropdown } from 'components/ThemeEditor/ThemeSelectDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './theme-tree-context-menu.scss';
import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import classNames from 'classnames';

export const CONTEXT_MENU_WIDTH = 400;

export type ContextMenuDirection = 'left' | 'right';

export interface ContextMenuPosition {
  top: number;
  left: number;
}

const MenuIcon = ({ direction } : { direction: ContextMenuDirection }) => {
  return (
    <span className="theme-tree-context-menu__icon">
      <FontAwesomeIcon icon={direction === 'right' ? 'chevron-right' : 'chevron-left'} />
    </span>
  );
}

interface Props {
  direction: ContextMenuDirection;
  groupId: string;
  onClose: () => void;
  onDelete: () => void;
  onMergeSelect: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
  onMoveSelect: (theme: SelectedTheme, text?: string, isMounted?: boolean) => void;
  position: ContextMenuPosition;
}

const ThemeTreeContextMenu = ({
  direction = 'left',
  groupId,
  position,
  onClose,
  onDelete,
  onMergeSelect,
  onMoveSelect
}: Props) => {
  const [showMergeMenu, setShowMergeMenu] = React.useState(false);
  const [showMoveMenu, setShowMoveMenu] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { left, top } =  position;

  const handleDropdownClose = () => {
    setShowMergeMenu(false);
    onClose();
  };

  const handleMergeItemHover = () => {
    setShowMergeMenu(true);
  };

  const handleMergeItemLeave = () => {
    setShowMergeMenu(false);
  };

  const handleMergeSelect = (theme: SelectedTheme, text?: string, isMounted?: boolean) => {
    onMergeSelect(theme, text, isMounted);
    handleDropdownClose();
  };

  const handleMoveItemHover = () => {
    setShowMoveMenu(true);
  };

  const handleMoveItemLeave = () => {
    setShowMoveMenu(false);
  };

  const handleMoveSelect = (theme: SelectedTheme, text?: string, isMounted?: boolean) => {
    onMoveSelect(theme, text, isMounted);
    handleDropdownClose();
  };

  useOnClickOutside(ref, handleDropdownClose);

  const openRight = direction === 'right';

  const menuItemClasses = classNames(
    'theme-tree-context-menu__item theme-tree-context-menu__item--top-level', {
    'theme-tree-context-menu__item--open-right': openRight
  });

  const menuTextClasses = classNames('theme-tree-context-menu__text', {
    'theme-tree-context-menu__text--open-right': openRight
  });

  return (
    <div
      aria-modal="true"
      className={classNames('theme-tree-context-menu', {
        'theme-tree-context-menu--open-right': openRight
      })}
      ref={ref}
      role="dialog"
      style={{ top, left }}
    >
      <Dropdown direction={direction} icon={null} open={true}>
        <DropdownMenu>
          <div onMouseOver={handleMergeItemHover} onMouseLeave={handleMergeItemLeave}>
            <DropdownItem>
              <ThemeSelectDropdown
                direction={direction}
                groupId={groupId}
                open={showMergeMenu}
                onClose={handleDropdownClose}
                onThemeChange={handleMergeSelect}
                trigger={
                  <span className={menuItemClasses} tabIndex={0}>
                    <MenuIcon direction={direction} />
                    <span className={menuTextClasses}>
                      Merge with
                    </span>
                  </span>
                }
              />
            </DropdownItem>
          </div>
          <div onMouseOver={handleMoveItemHover} onMouseLeave={handleMoveItemLeave}>
            <DropdownItem>
              <ThemeSelectDropdown
                direction={direction}
                groupId={groupId}
                hideSubThemes={true}
                open={showMoveMenu}
                onClose={handleDropdownClose}
                onThemeChange={handleMoveSelect}
                trigger={
                  <span className={menuItemClasses} tabIndex={0}>
                    <MenuIcon direction={direction} />
                    <span className={menuTextClasses}>
                      Move
                    </span>
                  </span>
                }
              />
            </DropdownItem>
          </div>
          <div>
            <DropdownItem className="theme-tree-context-menu__button" as="button" onClick={onDelete} tabIndex={0}>
              <span className={menuItemClasses} tabIndex={0}>
                <span className={menuTextClasses}>
                  Delete
                </span>
              </span>
            </DropdownItem>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export { ThemeTreeContextMenu };
