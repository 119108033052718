import { zip } from 'lodash';
import { CombinedScoreStats, ThemeFreq, ThemesResponse } from './types';

export function mapBaselineAndComparisonData(
  baselineFreq: ThemeFreq[],
  comparisonFreq: ThemeFreq[],
  labels: string[]
): CombinedScoreStats[] {
  const zipped = zip(baselineFreq, comparisonFreq, labels);
  return zipped.map(([baseline, comparison, label]) => {
    return {
      baselineVolume: baseline?.v || 0,
      baselineCount: baseline?.c || 0,
      baselineTotal: baseline?.t || 0,
      comparisonCount: comparison?.c || 0,
      comparisonTotal: comparison?.t || 0,
      comparisonVolume: comparison?.v || 0,
      label: label || ''
    };
  });
}

export function scoreGraphNormalizeTwoDatasetsForThemeFreq(
  baselineDateLabels: string[],
  comparisonDateLabels: string[],
  comparisonData: ThemeFreq[]
): ThemeFreq[] {
  const mappedComparison = Object.fromEntries(
    comparisonDateLabels.map((label, i) => [label, comparisonData[i]])
  );

  const scoresCompiled = baselineDateLabels.map((label) => {
    return mappedComparison[label] ?? {
      v: 0,
      c: 0,
      t: 0,
      counts: {
        count: 0,
        sentimentCounts: { neg: 0, neut: 0, pos: 0 }
      }
    };
  });

  return scoresCompiled;
}

export function scoreGraphNormalizeTwoDatasetsForThemeResponse(
  baselineDateLabels: string[],
  comparisonDateLabels: string[],
  comparisonData: ThemesResponse[]
): ThemesResponse[] {
  const mappedComparison = Object.fromEntries(
    comparisonDateLabels.map((label, i) => [label, comparisonData[i]])
  );
  return baselineDateLabels.map((label) => mappedComparison[label]);
}