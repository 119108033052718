import { ErrorMessage } from 'api/interfaces';
import * as React from 'react';
import QuestionOutsideDomainIcon from 'images/icons/question-outside-domain.svg';
import { ErrorViewWithExampleQuestions } from './ErrorViewWithExampleQuestions';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, { errorCode: 'QUESTION_OUTSIDE_DOMAIN' }>;
  onSuggestionClick: (suggestion: string) => void;
}

export const QuestionOutsideDomain = (props: Props) => {

  return (
    <ErrorViewWithExampleQuestions
      question={props.question}
      error={props.error}
      warningTitle="It seems you’ve entered a query that doesn't match our customer data topics."
      warningIcon={<QuestionOutsideDomainIcon />}
      warningDescription={
        <p className="answers-warning__description">
          Answers provide insights based on customer feedback data sets. Ask the example questions below or check our <a
            className="answers-warning__description-link"
            rel="noopener noreferrer"
            target="_blank"
            href="https://help.getthematic.com/article/133-question-types-that-answers-supports">
            knowledge base
          </a> to find out how to get insightful answers.
        </p>
      }
      onSuggestionClick={props.onSuggestionClick}
    />
  );
};
