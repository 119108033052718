import AuthenticatedRoute from 'components/AuthenticatedRoute';
import NavBar from 'components/NavBar/NavBar';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';

export default class NotFound extends React.Component {
  render() {
    return (
      <>
        <AuthenticatedRoute component={NavBar} fetching={false} />
        <Segment className="simple-segment fullscreen white hero">
          <h1>404</h1>
          <p>There's nothing here</p>
          <Button as={Link} to="/" className="subtle">
            Go back
          </Button>
        </Segment>
      </>
    );
  }
}
