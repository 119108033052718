import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import './theme-discovery-item-dropdown.scss';
import ThemeSelect from './ThemeSelect';

const DROPDOWN_WIDTH = 215;

interface ThemeDiscoveryItemDropdownProps {
  groupId: string;
  suggestedAction?: string;
  selectedDropdownAction?: SelectedDropdownOption;
  proposedThemeValue?: string;
  proposedTheme?: SelectedTheme;
  onChange: (theme: SelectedTheme, selectTheme: boolean, selectedOption: SelectedDropdownOption | undefined) => void;
  mergeTheme: (theme: SelectedTheme, selectTheme: boolean, selectedOption: SelectedDropdownOption | undefined) => void;
  themeItemsRef: React.RefObject<HTMLDivElement>;
}

interface ThemeDiscoveryItemDropdownState {
  dropdownTop: number;
  dropdownLeft: number;
  dropdownOpen: boolean;
  themeSelectionDropdown: boolean;
  themeMergeDropdown: boolean;
  themesItemsScrollTop: number;
  selectedOption: string;
}
export interface SelectedDropdownOption {
  action: string;
  value: string;
  selectedTheme: SelectedTheme;
  selectedText: string;
}

export default class ThemeDiscoveryItemDropdown extends React.Component<
  ThemeDiscoveryItemDropdownProps,
  ThemeDiscoveryItemDropdownState
> {
  state = {
    dropdownTop: 0,
    dropdownLeft: 0,
    dropdownOpen: false,
    themeSelectionDropdown: false,
    themeMergeDropdown: false,
    themesItemsScrollTop: 0,
    selectedOption: 'Create base theme',
  };

  private dropdownRef = React.createRef<HTMLDivElement>();

  calculateDropdownPosition = () => {
    const domRect = this.dropdownRef.current?.getBoundingClientRect();
    const { height, width, x, y } = domRect || { x: 0, y: 0, height: 0, width: 0 };
    const dropdownTop = y + height;
    const dropdownLeft = x + width - DROPDOWN_WIDTH;

    this.setState({
      dropdownTop,
      dropdownLeft
    });
  }

  mergeTheme = (selectedTheme, text, isMounted) => {
    const { suggestedAction } = this.props;
    if ((suggestedAction && suggestedAction.includes('merge') && isMounted) || !isMounted) {
      // select the option after change in dropdown
      const selectedOptionValue = `Merge into "${text}"`;
      const selectedDropdownOption: SelectedDropdownOption = {
        action: 'merge',
        value: selectedOptionValue,
        selectedTheme: selectedTheme,
        selectedText: text
      };
      const selectTheme = !isMounted;
      this.props.mergeTheme(selectedTheme, selectTheme, {...selectedDropdownOption});
      this.setState({
        selectedOption: selectedOptionValue,
      });
    }
  }

  onChange = (selectedTheme, text, isMounted) => {
    const { suggestedAction } = this.props;
    // select the option after change in dropdown
    const selectedOptionValue = `Create subtheme of "${text}"`;
    const selectedDropdownOption: SelectedDropdownOption = {
      action: 'subtheme',
      value: selectedOptionValue,
      selectedTheme: selectedTheme,
      selectedText: text
    };
    const selectTheme = !isMounted;
    if (suggestedAction && suggestedAction === 'sub_of_nearest_base' && isMounted) {
      this.props.onChange(selectedTheme, selectTheme, {...selectedDropdownOption});
    } else {
      this.props.onChange(selectedTheme, selectTheme, !isMounted ? {...selectedDropdownOption} : undefined);
    }
    this.setState({
      selectedOption: selectedOptionValue,
    });
  }

  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true })
    this.calculateDropdownPosition();
  }

  handleMouseLeave = () => {
    this.setState({ dropdownOpen: false })
  }

  render() {
    const {
      proposedThemeValue,
      groupId,
      selectedDropdownAction,
      suggestedAction,
      proposedTheme,
      onChange
    } = this.props;
    const {
      dropdownTop,
      dropdownLeft,
      dropdownOpen,
      themeSelectionDropdown,
      themeMergeDropdown,
      selectedOption,
    } = this.state;
    const newTheme = {
      theme: '-create-new-',
      subtheme: null,
    };

    return (
      <div className="theme-discovery-item-dropdown" onMouseLeave={this.handleMouseLeave} ref={this.dropdownRef}>
        <Dropdown
          open={dropdownOpen}
          trigger={
            <Button>
              {selectedOption}
              <FontAwesomeIcon icon="chevron-down" className="icon" />
            </Button>
          }
          onOpen={() => this.handleDropdownOpen()}
        >
          <Dropdown.Menu className="theme-discovery-item-selection-menu" style={{ top: dropdownTop, left: dropdownLeft }}>
            {selectedDropdownAction && selectedDropdownAction.value.length > 0 &&
              <>
                <Dropdown.Item>
                  <span
                    onClick={() => {
                      if (selectedDropdownAction.action === 'merge') {
                        this.mergeTheme(
                          selectedDropdownAction.selectedTheme,
                          selectedDropdownAction.selectedText,
                          false
                        );
                      } else if (selectedDropdownAction.action === 'subtheme') {
                        // create subtheme under base theme
                        this.onChange(
                          selectedDropdownAction.selectedTheme,
                          selectedDropdownAction.selectedText,
                          false
                        );
                      }
                    }}
                  >
                    {selectedDropdownAction.value}
                  </span>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            }
            <Dropdown.Item>
              <span
                onClick={() => {
                  onChange(newTheme, true, undefined);
                  this.setState({ selectedOption: 'Create base theme' });
                }}
              >
                Create base theme
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              onMouseEnter={() => this.setState({ themeSelectionDropdown: true })}
              onMouseLeave={() => this.setState({ themeSelectionDropdown: false })}
            >
              <div className="theme-selection">
                <Dropdown
                  open={true}
                  trigger={
                    <span className="dropdown-option">
                      <p>Create subtheme</p>
                      <FontAwesomeIcon icon="chevron-right" className="icon" />
                    </span>
                  }
                  className="theme-discovery-item-dropdown-menu"
                >
                  <>
                    {themeSelectionDropdown && (
                      <Dropdown.Menu open={true}>
                        <ThemeSelect
                          groupId={groupId}
                          hasOther={false}
                          hasMakeNew={false}
                          themesOnly={true}
                          suggestedAction={suggestedAction}
                          proposedTheme={proposedTheme}
                          onChange={(selectedTheme, text, isMounted) => {
                            this.onChange(selectedTheme, text, isMounted);
                          }}
                          value={proposedThemeValue}
                          isThemesDiscoveryItem={true}
                          themeSelectionDropdown={themeSelectionDropdown}
                        />
                      </Dropdown.Menu>
                    )}
                  </>
                </Dropdown>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              onMouseEnter={() => this.setState({ themeMergeDropdown: true })}
              onMouseLeave={() => this.setState({ themeMergeDropdown: false })}
            >
              <div className="theme-selection">
                <Dropdown
                  open={true}
                  trigger={
                    <span className="dropdown-option">
                      <p>Merge</p>
                      <FontAwesomeIcon icon="chevron-right" className="icon" />
                    </span>
                  }
                  className="theme-discovery-item-dropdown-menu"
                >
                  <>
                    {themeMergeDropdown && (
                      <Dropdown.Menu open={themeMergeDropdown}>
                        <ThemeSelect
                          groupId={groupId}
                          hasOther={false}
                          hasMakeNew={false}
                          themesOnly={false}
                          suggestedAction={suggestedAction}
                          proposedTheme={proposedTheme}
                          mergeTheme={(selectedTheme, text, isMounted) => {
                            this.mergeTheme(selectedTheme, text, isMounted);
                          }}
                          value={proposedThemeValue}
                          isThemesDiscoveryItem={true}
                          isMerge={true}
                          themeSelectionDropdown={themeMergeDropdown}
                        />
                      </Dropdown.Menu>
                    )}
                  </>
                </Dropdown>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
