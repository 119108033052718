import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Loader, Segment, Table } from 'semantic-ui-react';
import { SubscriptionStoreInterface } from 'stores/SubscriptionStore';
import Subscription from './Subscription';

export interface SubscriptionsParams {
  orgId: string;
}

interface SubsriptionsStoreProps {
  subscriptionStore?: SubscriptionStoreInterface;
}

export interface SubscriptionsProps extends SubsriptionsStoreProps, RouteComponentProps<SubscriptionsParams> {}

interface SubscriptionsState {
  error?: Error;
  fetching: boolean;
}

@inject('subscriptionStore')
@observer
class Subscriptions extends React.Component<
  SubscriptionsProps,
  SubscriptionsState
> {
  state = {
    error: undefined,
    fetching: false
  };
  componentDidMount() {
    this.fetchSubscriptions();
  }
  // only refetch if new orgId found
  componentDidUpdate(prevProps: SubscriptionsProps) {
    if (prevProps.match.params.orgId !== this.props.match.params.orgId) {
      this.fetchSubscriptions();
    }
  }
  fetchSubscriptions = async () => {
    this.setState({ fetching: true });
    try {
      await this.props.subscriptionStore!.fetchSubscriptions();
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ fetching: false });
    }
  };
  render() {
    const { subscriptions } = this.props.subscriptionStore!;
    const { orgId } = this.props.match.params;
    const { fetching } = this.state;
    const availableDigests = subscriptions.filter(subscription => subscription.status !== 'deleted');

    return (
      <Segment>
        <Table
          selectable={true}
          striped={true}
          unstackable={true}
          className={'shrunken'}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Dashboard</Table.HeaderCell>
              <Table.HeaderCell>Filters</Table.HeaderCell>
              <Table.HeaderCell>Recipients</Table.HeaderCell>
              <Table.HeaderCell>Frequency</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!fetching && availableDigests.map(digest => {
              return (
                <Subscription
                  key={digest.id}
                  digest={digest}
                  orgId={orgId}
                />
              );
            })}
            {fetching && (
              <Table.Row>
                <Table.Cell colSpan={2} width={16}>
                  <Loader inline="centered" active={true} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default Subscriptions;
