import { keys, take, uniq } from 'lodash';
import { ChartData } from './bargraph';
import { ThemeCountsWithImpact, ThemeCountsWithSigDiff, ThemeFreqWithSigDiff } from './types';

export function getBarGraphLabels(
  baseline: ThemeCountsWithSigDiff | ThemeCountsWithImpact,
  comparison: ThemeCountsWithSigDiff | ThemeCountsWithImpact,
  numberOfThemesToShow: number
) {
  const uniqueKeys = uniq([...keys(comparison), ...keys(baseline)]);
  return take(uniqueKeys, numberOfThemesToShow);
}

export function calculateTickInterval(minValue: number, maxValue: number): number {
  const range = maxValue - minValue;
  const tickCount = 5;
  const tickInterval = Math.ceil(range / tickCount);
  return tickInterval;
}

export function generateBarGraphData(
  baseline: ThemeCountsWithSigDiff,
  comparison: ThemeCountsWithSigDiff,
  baselineTotalCommentsCount: number,
  comparisonTotalCommentsCount: number
): ChartData[] {
  const baselineScalar = baselineTotalCommentsCount > 0 ? 100.0 / baselineTotalCommentsCount : 0;
  const comparisonScalar = comparisonTotalCommentsCount > 0 ? 100.0 / comparisonTotalCommentsCount : 0;
  const labels = getBarGraphLabels(baseline, comparison, 15);
  return labels.map(label => {
    const baselineItem = baseline?.[label];
    const comparisonItem = comparison?.[label];

    const baselineVolume = baselineItem ? baseline[label].count * baselineScalar : 0;
    const baselineCount = baselineItem ? baseline[label].count : 0;
    const baselineSigDiff = baselineItem ? baseline[label].sig_diff : 0;
    const comparisonVolume = comparisonItem ? comparison[label].count * comparisonScalar : 0;
    const comparisonCount = comparisonItem ? comparison[label].count : 0;
    const comparisonSigDiff = comparisonItem ? comparison[label].sig_diff : 0;
    return {
      baselineVolume,
      baselineCount,
      baselineSigDiff,
      baselineTotalCommentsCount,
      comparisonVolume,
      comparisonCount,
      comparisonSigDiff,
      comparisonTotalCommentsCount,
      label
    };
  });
}

export function getVolume(
  theme: ThemeCountsWithImpact[keyof ThemeCountsWithImpact],
  graphType: string
) {
  if (!theme) {
    return 0;
  }
  if (graphType === 'effect') {
    return theme.impactPercent ? theme.impactPercent : theme.impact;
  }
  return theme.score.score;
}

export function generateScoreImpactData(
  baseline: ThemeCountsWithImpact,
  comparison: ThemeCountsWithImpact,
  graphType: string,
  baselineTotalCommentsCount: number,
  comparisonTotalCommentsCount: number
): ChartData[] {
  const labels = getBarGraphLabels(baseline, comparison, 15);
  return labels.map((label: string) => {
    const baselineItem = baseline?.[label];
    const comparisonItem = comparison?.[label];

    const baselineCount = baselineItem ? baseline[label].count : 0;
    const baselineVolume = getVolume(baseline[label], graphType);
    const comparisonCount = comparisonItem ? comparison[label].count : 0;
    const comparisonVolume = getVolume(comparison[label], graphType);
    return {
      baselineCount,
      baselineVolume,
      baselineTotalCommentsCount,
      comparisonCount,
      comparisonVolume,
      comparisonTotalCommentsCount,
      label
    };
  });
}

export function generateDimensionData(
  baseline: ThemeFreqWithSigDiff[],
  comparison: ThemeFreqWithSigDiff[],
  labels: string[]
): ChartData[] {
  return labels.map((label: string, index: number) => {
    return {
      baselineCount: baseline[index]?.c || 0,
      baselineVolume: baseline[index]?.v || 0,
      baselineTotalCommentsCount: baseline[index]?.t || 0,
      baselineSigDiff: baseline[index]?.sigDiff || 0,
      comparisonCount: comparison[index]?.c || 0,
      comparisonVolume: comparison[index]?.v || 0,
      comparisonTotalCommentsCount: comparison[index]?.t || 0,
      comparisonSigDiff: comparison[index]?.sigDiff || 0,
      label
    };
  });
}

export function sortBarGraphData(data: ChartData[], orderBy: string) {
  if (orderBy && orderBy !== 'original') {
    const orderByDifference = orderBy === 'difference';
    return data.sort((a, b) => {
      const firstThemeDifference = Math.abs(a.baselineVolume - a.comparisonVolume);
      const secondThemeDifference = Math.abs(b.baselineVolume - b.comparisonVolume);
      let difference = 0;
      if (orderByDifference) {
        difference =
          firstThemeDifference > secondThemeDifference ? -1 : firstThemeDifference === secondThemeDifference ? 0 : 1;
      }
      if (difference === 0) {
        if (
          orderBy === 'highAbs' ||
          orderBy === 'count' ||
          orderByDifference
        ) {
          difference =
            Math.abs(a.baselineVolume) > Math.abs(b.baselineVolume)
              ? -1
              : Math.abs(a.baselineVolume) === Math.abs(b.baselineVolume)
              ? 0
              : 1;
        } else if (orderBy === 'lowAbs') {
          difference =
            Math.abs(a.baselineVolume) > Math.abs(b.baselineVolume)
              ? 1
              : Math.abs(a.baselineVolume) === Math.abs(b.baselineVolume)
              ? 0
              : -1;
        } else if (orderBy === 'high') {
          difference =
            a.baselineVolume > b.baselineVolume
              ? -1
              : a.baselineVolume === b.baselineVolume
              ? 0
              : 1;
        } else if (orderBy === 'low') {
          difference =
            a.baselineVolume > b.baselineVolume
              ? 1
              : a.baselineVolume === b.baselineVolume
              ? 0
              : -1;
        }
      }
      return difference;
    });
  }
  return data;
}