import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SurveyDataStatus } from 'api/enums';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { AnalysisToolsStoreInterface } from 'stores/AnalysisToolsStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import './survey-ready-action-buttons.scss';

interface SurveyReadyActionButtonsStoreProps {
  surveyStore?: SurveyStoreInterface;
  analysisToolsStore?: AnalysisToolsStoreInterface;
}

export interface SurveyReadyActionButtonsProps extends SurveyReadyActionButtonsStoreProps {
  dataStatus: string;
  orgId: string;
  surveyId: string;
}

interface SurveyActionState {
  analysisLink: string;
}

@inject('surveyStore', 'analysisToolsStore')
@observer
class SurveyReadyActionButtons extends React.Component<SurveyReadyActionButtonsProps, SurveyActionState> {
  state = {
    analysisLink: ''
  } as SurveyActionState;

  get surveyStore() {
    return this.props.surveyStore!;
  }
  get analysisToolsStore() {
    return this.props.analysisToolsStore!;
  }

  componentDidMount () {
    const { surveyId } = this.props;
    const analysisLink = this.analysisToolsStore.getAnalysisUrl(surveyId);
    this.setState({ analysisLink });
  }

  canConfigureSurvey = (surveyId: string) => {
    return this.surveyStore.getSurveyCanAction(surveyId, 'manage:survey');
  };

  canEditThemes = (surveyId: string) => {
    // can edit themes if manage:themes
    return this.surveyStore.getSurveyCanAction(surveyId, 'manage:themes');
  };

  render(): JSX.Element | null {
    const { dataStatus, orgId, surveyId } = this.props;
    const { analysisLink } = this.state;

    const canConfigureData = dataStatus !== SurveyDataStatus.NODATA;
    const canConfigureSurvey = this.canConfigureSurvey(surveyId);
    const canEditThemes = this.canEditThemes(surveyId);

    const link = `/c/${orgId}/s/${surveyId}/configure-analysis`;

    return (
      <div className="survey-ready-action-buttons">
        <Button
          as={Link}
          to={analysisLink}
        >
          <FontAwesomeIcon
            className="icon"
            icon="chart-bar"
          />
          View data
        </Button>
        {canConfigureSurvey &&
          <Button
            as={Link}
            disabled={!canConfigureData}
            to={link}
          >
            <FontAwesomeIcon
              className="icon"
              icon="filter"
            />
            Edit filters
          </Button>
        }
        <Button
          as={Link}
          disabled={!canEditThemes}
          to={`/c/${orgId}/s/${surveyId}/themes`}
        >
          <FontAwesomeIcon
            className="icon"
            icon="tag"
          />
          Manage themes
        </Button>
      </div>
    );
  }
}

export default SurveyReadyActionButtons;
