import * as React from 'react';
import { Select } from 'semantic-ui-react';
import { AvailableDateRange, DateOptions, TimePeriod } from './types';
import { getMaxTimePeriodFrequencies } from './export-assistant-date.util';

interface MaxDateFrequencies {
  weekly: number;
  monthly: number;
  quarterly: number;
  biannually: number;
}

const timePeriodOptions = [
  {
    key: 'weekly',
    value: 'weekly',
    text: 'Weekly',
  },
  {
    key: 'monthly',
    value: 'monthly',
    text: 'Monthly',
  },
  {
    key: 'quarterly',
    value: 'quarterly',
    text: 'Quarterly',
  },
  {
    key: 'biannually',
    value: 'biannually',
    text: 'Biannually',
  },
];

interface Props {
  availableDateRange: AvailableDateRange;
  onDateSelectChange: (dateOptions: DateOptions) => void;
}

export const ExportAssistantDateSelect = ({ availableDateRange, onDateSelectChange }: Props) => {
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState<TimePeriod>('monthly');
  const [selectedRepeatFrequency, setSelectedRepeatFrequency] = React.useState<string>('1');
  const [maxDateFrequencies, setMaxDateFrequencies] = React.useState<MaxDateFrequencies>({
    weekly: 0,
    monthly: 0,
    quarterly: 0,
    biannually: 0,
  });

  React.useEffect(() => {
    const getDateRange = () => {
      const resolveDates = async () => {
        if (!availableDateRange.endDate || !availableDateRange.startDate) {
          return;
        }

        const maxFrequencies = await getMaxTimePeriodFrequencies(availableDateRange);

        setMaxDateFrequencies(maxFrequencies);
      };
      resolveDates();
    };

    getDateRange();
  }, [availableDateRange]);

  const handleTimePeriodChange = (timePeriod: TimePeriod) => {
    setSelectedTimePeriod(timePeriod);

    onDateSelectChange({
      timePeriod,
      repeatFrequency: selectedRepeatFrequency,
    });
  };

  const handleRepeatFrequencyChange = (repeatFrequency: string) => {
    setSelectedRepeatFrequency(repeatFrequency);

    onDateSelectChange({
      timePeriod: selectedTimePeriod,
      repeatFrequency,
    });
  };

  const repeatFrequencyOptions = (timePeriod: TimePeriod) => {
    return Array.from(Array(maxDateFrequencies[timePeriod]).keys()).map((_, index) => {
      const frequency = index + 1;
      const frequencyText = frequency.toString();

      return {
        key: frequencyText,
        value: frequencyText,
        text: frequencyText,
      };
    });
  };

  return (
    <div>
      <div className="export-assistant__date-select">
        <label className="export-assistant__date-select-label" htmlFor="time-period">Time period</label>
        <Select
          id="time-period"
          name="time-period"
          onChange={(_, data) => handleTimePeriodChange(data.value as TimePeriod)}
          options={timePeriodOptions}
          value={selectedTimePeriod}
        />
      </div>
      <div className="export-assistant__date-select">
        <label className="export-assistant__date-select-label" htmlFor="repeat-frequency">Repeat frequency</label>
        <Select
          id="repeat-frequency"
          name="repeat-frequency"
          onChange={(_, data) => handleRepeatFrequencyChange(data.value as string)}
          options={repeatFrequencyOptions(selectedTimePeriod)}
          value={selectedRepeatFrequency}
        />
      </div>
    </div>
  );
};
