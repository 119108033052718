import * as React from 'react';
import { CheckboxProps, Checkbox } from 'semantic-ui-react';
import { remove } from 'lodash';
import { observer } from 'mobx-react';
import { RoleDetailsJson, RoleJson } from 'api/interfaces';
import './roles.scss';

export interface RolesProps {
  availableRoles: RoleDetailsJson[];
  selectedRoles: RoleJson[];
  onRolesChange: (roles: RoleJson[]) => void;
}

@observer
class Roles extends React.Component<RolesProps> {
  handleRoleChange = ({ checked, value }: CheckboxProps) => {
    const updatedRoles = this.getUpdatedRoles(checked, value as string);
    this.props.onRolesChange(updatedRoles);
  };
  getUpdatedRoles = (checked: boolean | undefined, value: string) => {
    let selectedRoles = this.props.selectedRoles || [] as RoleJson[];
    const selectedRole = this.props.availableRoles.find(r => r.id === value);
    if (checked) {
      if (selectedRole) {
        selectedRoles = [ ...selectedRoles, selectedRole ];
      }
    } else {
      remove(selectedRoles, (r) => r.id === value);
    }
    return selectedRoles;
  }
  isSelected = (role: RoleDetailsJson) => {
    return !!this.props.selectedRoles.find(r => r.id === role.id);
  }
  render(): JSX.Element | null {
    const { availableRoles } = this.props;

    return (
      <fieldset className="nw--roles">
        <legend>Roles</legend>

        {availableRoles.map(role => {
          return <div
              key={role.id + role.name}
              data-testid={role.name}
            >
            <Checkbox
              label={role.name}
              value={role.id}
              checked={this.isSelected(role)}
              onChange={(_e, data) => this.handleRoleChange(data)}
            />
            <p className="description">{role.description}</p>
          </div>;
        })}
      </fieldset>
    );
  }
}

export default Roles;
