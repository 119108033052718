import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataSourceIntegrationStatus, IntegrationType } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import IntegrationLogo from 'components/Integrations/IntegrationLogo';
import { supportedDataSources } from 'components/Upload/constants';
import { isSurveyNameValid } from 'lib/survey-helpers';
import { compact } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { Button, Dropdown, Form, Input, InputProps, Label, Message, Modal, SemanticCOLORS } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import DataAlerting from './DataAlerting';
import './manage-dataset-modal.scss';

interface ManageDatasetModalStoreProps {
  setupStore?: SetupStoreInterface;
  surveyStore?: SurveyStoreInterface;
  integrationsStore?: IntegrationsStoreInterface;
}

export interface ManageDatasetModalProps extends ManageDatasetModalStoreProps {
  survey: LiteSurvey;
  onClose: () => void;
}

interface ManageDatasetModalState {
  proposedName: string;
  surveyNameLengthError: string;
  integrationId?: string;
  integrations: ConnectedIntegration[];
  showingChangeConnection: boolean;
}

const ERROR_MESSAGE = 'Dataset name must contain something, and up to 50 characters';

@inject('setupStore', 'integrationsStore', 'surveyStore')
@observer
class ManageDatasetModal extends React.Component<ManageDatasetModalProps, ManageDatasetModalState> {
  state = {
    proposedName: this.props.survey.title,
    surveyNameLengthError: '',
    integrations: [] as ConnectedIntegration[],
    showingChangeConnection: false
  } as ManageDatasetModalState;

  componentDidMount() {
    const { integrationsStore, survey } = this.props;
    if (integrationsStore && survey.dataSourceIntegration) {
      const integrationId = survey.dataSourceIntegration.integrationId;
      const integrationType = survey.dataSourceIntegration.type as IntegrationType;
      const integrations = integrationsStore.getConnectedIntegrationsOfType(integrationType);
      this.setState({ integrationId, integrations });
    }
  }

  updateProposedName = ({ currentTarget: { value } }: InputProps) => {
    const { surveyNameLengthError } = this.state;

    this.setState({ proposedName: value });

    if (!isSurveyNameValid(value)) {
      this.setState({ surveyNameLengthError: ERROR_MESSAGE });
    } else {
      if (surveyNameLengthError.length > 0) {
        this.setState({ surveyNameLengthError: '' });
      }
    }
  }

  updateName = async () => {
    const { survey, surveyStore } = this.props;
    const { proposedName } = this.state;

    const nameHasChanged = survey.title !== proposedName;
    if (nameHasChanged) {
      const surveyOptions = { name: proposedName };
      await surveyStore!.updateSurvey(survey.surveyId, surveyOptions);
    }
  }

  updateSource = async () => {
    const { survey, setupStore } = this.props;
    const { integrationId } = this.state;

    const integationIdHasChanged = survey.dataSourceIntegration?.integrationId !== integrationId;
    if (integationIdHasChanged) {
      const dataSourceOptions = { integrationId };
      await setupStore!.updateDataSource(survey.surveyId, dataSourceOptions);
    }
  }

  pullNow = () => {
    const { survey, setupStore } = this.props;

    setupStore?.pullSurveyDataSource(survey.surveyId);
  }

  togglePulling = () => {
    const { setupStore, survey } = this.props;

    const dataSource = survey.dataSourceIntegration;
    if (!dataSource) {
      return;
    }

    let status = DataSourceIntegrationStatus.ENABLED;
    if (dataSource.status === DataSourceIntegrationStatus.ENABLED) {
      status = DataSourceIntegrationStatus.STOPPED;
    }

    setupStore!.setDataSourceStatus(survey.surveyId, status);
  }

  render() {
    const { onClose, setupStore, surveyStore, integrationsStore, survey } = this.props;
    const {
      proposedName,
      surveyNameLengthError,
      integrations,
      showingChangeConnection,
      integrationId
    } = this.state;
    const errorsToShow = compact([surveyNameLengthError,
      surveyStore!.updateSurveyError,
      setupStore!.updateDataSourceError,
      setupStore!.runPullDataSourceError,
    ]);

    let integration;
    let label = 'Unknown';
    let labelColor = 'grey';
    const dataSource = survey.dataSourceIntegration;
    if (dataSource) {
      // get details where there is a connected integration
      if (dataSource.integrationId && integrations && integrationsStore) {
        integration = integrationsStore.getIntegrationById(dataSource.integrationId);
      }
      // get details for all data sources
      if (dataSource.status === DataSourceIntegrationStatus.STOPPED) {
        label = 'Paused';
        labelColor = 'grey';
      } else if (dataSource.lastFailureDate &&
        (!dataSource.lastSuccessDate || dataSource.lastFailureDate > dataSource.lastSuccessDate)) {
        label = 'Errored';
        labelColor = 'red';
      } else if (dataSource.lastSuccessDate) {
        label = 'Active';
        labelColor = 'green';
      }
    }

    return (
      <Modal
        open={true}
        onClose={onClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header className="modal-header">
          Manage dataset
        </Modal.Header>
        <Modal.Content className="manage-dataset-modal">
          <Form>
            <Form.Field>
              <label>Name</label>
              <Input
                value={proposedName}
                onChange={(e) => this.updateProposedName(e)}
                action={proposedName !== survey.title ? (
                  <Button
                    className="nw-name-update-button"
                    color="blue"
                    onClick={this.updateName}
                    loading={surveyStore!.updatingSurvey}
                    disabled={!!surveyNameLengthError}
                  >
                    Update
                  </Button>) : undefined}
              />

            </Form.Field>

            {dataSource && (
              <Form.Field>
                <label>Data Source</label>
                <div className="integration-manage">

                  <div className="integration-details">
                    <IntegrationLogo integrationType={dataSource.type} />
                    <div className="integration-connection">
                      <div className="integration-name">
                        {supportedDataSources[dataSource.type]!.name}
                      </div>
                      <div className="integration-connection-details">
                        {dataSource.lastSuccessDate ? (
                          <span>Refreshed: {moment(dataSource.lastSuccessDate).format('MMMM Do, YYYY')}</span>
                        ) : (
                          <span>No successful connections</span>
                        )}
                      </div>
                      <div className="integration-connection-controls">
                        <Button
                          size="small"
                          loading={setupStore?.pullingDataSource}
                          onClick={this.pullNow}
                        >
                          <span>
                            <FontAwesomeIcon icon="sync-alt" />
                            &nbsp;Refresh
                          </span>
                        </Button>
                        <Button
                          size="small"
                          loading={setupStore?.updatingDataSource}
                          onClick={this.togglePulling}
                        >
                          {dataSource.status === DataSourceIntegrationStatus.ENABLED ? (
                            <span>
                              <FontAwesomeIcon icon="pause" />
                              &nbsp;Pause
                            </span>
                          ) : (
                            <span>
                              <FontAwesomeIcon icon="play" />
                              &nbsp;Resume
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Label color={labelColor as SemanticCOLORS} size="large">{label}</Label>
                    </div>
                  </div>
                  {supportedDataSources[dataSource.type]!.requiresIntegration && (
                    <div className="integration-change">
                      {showingChangeConnection ? (
                        <div>
                          Integration set up by
                          <Dropdown
                            fluid={true}
                            selection={true}
                            placeholder="Select Integration"
                            options={integrations.map(i => {
                              return {
                                value: i.id,
                                key: i.id,
                                text: `${ i.authorizedBy } on ${ i.authorizedAt }`
                              };
                            })}
                            value={integrationId}
                            onChange={(e, { value }) => { this.setState({ integrationId: value as string }); }}
                          />
                        </div>
                      ) : integration ? (
                        <div>
                          Integration set up by&nbsp;
                          {integration.authorizedBy}
                          &nbsp;on&nbsp;
                          {moment(integration.authorizedAt).format('MMMM Do, YYYY')}
                        </div>
                      ) : (
                        <div>Integration missing!</div>
                      )}

                      {(integrations.length > 1 || (integrations.length > 0 && !integration)) && (
                        <div className="integration-change-action">
                          {showingChangeConnection ? (
                            <Button
                              color="blue"
                              size="small"
                              disabled={survey.dataSourceIntegration?.integrationId === integrationId}
                              loading={setupStore?.updatingDataSource}
                              onClick={this.updateSource}
                            >
                              <FontAwesomeIcon icon="edit" />
                              &nbsp;Update
                            </Button>) : (
                            <Button
                              size="small"
                              onClick={() => { this.setState({ showingChangeConnection: true }); }}
                            >
                              <FontAwesomeIcon icon="edit" />
                              &nbsp;Change source
                            </Button>
                          )}
                        </div>
                      )}

                    </div>
                  )}
                </div>
              </Form.Field>
            )}
            <Form.Field>
              <label>Alerting</label>
              <DataAlerting
                surveyId={survey.surveyId}
              />
            </Form.Field>
          </Form>
          {errorsToShow.map(error => (
            <Message
              key={error}
              className="error"
              negative={true}
              header={error}
            />
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ManageDatasetModal;
