<template>
  <div
    ref="container"
    class="explore-scale"
    :class="{ top: top }"
  >
    <div class="explore-scale__title">
      {{ title }}
    </div>
    <svg
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="100%"
    >
      <line
        :y1="top ? 1 : 15.5"
        :x2="size + 16"
        :y2="top ? 1 : 15.5"
        x1="15.5"
        :stroke="colors.neutral100"
      />
      <text
        v-for="(offset, index) in offsets"
        :key="`${offset}-text`"
        :x="offset"
        :y="top ? 13 : 11"
        class="explore-scale__label"
      >{{ labels[index] }}</text>
    </svg>
  </div>
</template>

<script>
import { throttle, template } from 'lodash';
import fuzzNum from 'vue/libs/fuzz-num';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'ExploreScale',
  props: {
    max: { default: 10, type: Number },
    min: { default: 0, type: Number },
    formatter: { default: '<%= value %>', type: String },
    top: { default: false, type: Boolean },
    title: { default: undefined, type: String }
  },
  data() {
    return {
      colors: colors,
      size: 148
    };
  },
  computed: {
    labels() {
      const { max, min } = this;
      let { formatter } = this;

      // convert format string into a compiled template
      formatter = template(formatter);

      const labels = [
        formatter({ value: fuzzNum(min) }),
        formatter({ value: fuzzNum(max) })
      ];
      if (min < 0) {
        labels.splice(1, 0, formatter({ value: 0 }));
      }
      return labels;
    },
    offsets() {
      const { max, min, range, size } = this;
      const offsets = [16, size + 16];

      if (min < 0 && max !== 0) {
        offsets.splice(1, 0, (size * Math.abs(min)) / range + 16);
      }
      return offsets;
    },
    range() {
      const { min, max } = this;
      return max - min;
    }
  },
  mounted() {
    // add throttle
    this.updateSize = throttle(this.updateSize, 100);

    this.updateSize();
    window.addEventListener('resize', this.updateSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateSize);
  },
  methods: {
    updateSize() {
      const { container } = this.$refs;
      if (!container) {
        return;
      }
      const { width } = container.getBoundingClientRect();
      this.size = width - 32;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';
.explore-scale {
  display: flex;
  flex-direction: column;
  &.top {
    flex-direction: column-reverse;
  }
  &__title {
    align-self: center;
    color: $--neutral-400;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
  }
  &__label {
    font-family: 'Open Sans', arial, sans-serif;
    font-weight: normal;
    fill: $--neutral-400;
    font-weight: 600;
    font-size: 11px;
    text-anchor: middle;
  }
}
</style>


