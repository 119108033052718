import { OrganizationJson } from 'api/interfaces';
import LogoIcon from 'images/thematic-logo-64x64.png';
import { filter as filterBy, slice, sortBy } from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Dropdown, Image, Input, Loader, Menu, Popup } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { OrganizationsStoreInterface } from 'stores/OrganizationsStore';
import './org-logo.scss';

interface OrgLogoStoreProps {
  organizationStore?: OrganizationStoreInterface;
  organizationsStore?: OrganizationsStoreInterface;
}
interface OrgLogoProps extends OrgLogoStoreProps, RouteComponentProps<{
  orgId: string;
}> {
  orgId: string;
}

interface Organization extends OrganizationJson {
  configureLink?: string;
}

interface OrgLogoState {
  organizations: OrganizationJson[];
  filteredOrganizations: Organization[];
  filter: string;
  dropDownVisible: boolean;
}

@inject('organizationStore', 'organizationsStore')
@observer
class OrgLogo extends React.Component<OrgLogoProps, OrgLogoState> {
  disposer?: IReactionDisposer = undefined;
  state = {
    organizations: [] as OrganizationJson[],
    filteredOrganizations: [] as Organization[],
    filter: '',
    dropDownVisible: false,
  };

  get organizationStore () {
    return this.props.organizationStore!;
  }

  get organizationsStore () {
    return this.props.organizationsStore!;
  }

  componentDidMount() {
    this.disposer = reaction( () => {
      if (this.props.organizationsStore) {
        // updating title inside this if condition in order to wait long enough for orgs to be fetched
        this.updateDocumentTitle();
        return this.props.organizationsStore.organizations;
      }
      return undefined;
    },
    () => {
      this.checkUpdateOrganizations();
    },
    { fireImmediately: true });
  }

  componentDidUpdate(prevProps: OrgLogoProps) {
    const { orgId } = this.props.match.params;
    const { orgId: prevOrgId } = prevProps.match.params;

    if (orgId !== prevOrgId) {
      this.updateDocumentTitle();
    }
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  updateDocumentTitle = () => {
    const { orgName } = this.organizationStore;
    if (orgName) {
      document.title = `Thematic | ${orgName}`;
    } else {
      document.title = 'Thematic Client Portal';
    }
  }

  checkUpdateOrganizations = () => {
    let { organizations } = this.state;
    const { organizationsStore } = this.props;

    if (organizationsStore && organizationsStore.organizations !== organizations) {
      organizations = sortBy(organizationsStore.organizations, 'name');
      this.setState({ organizations }, this.handleSearch);
    }
  }

  handleSearch = () => {
    const { organizationsStore } = this.props;
    const { filter, organizations } = this.state;
    let data = organizations;
    if (filter) {
      data = filterBy(data, org => {
        return org.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    }
    // restrict to only 25 entries (to not kill the DOM)
    data = slice(data, 0, 25);
    let filteredOrganizations: Organization[] = [];
    if (organizationsStore) {
      filteredOrganizations = data.map(org => ({
        ...org,
        configureLink: organizationsStore.getConfigLink(org.displayId)
      }));
      this.setState({ filteredOrganizations });
    }
  };

  logoItem = () => {
    const { organizationStore } = this.props;
    const orgId = organizationStore ? organizationStore.orgId : '';
    return (
      <Menu.Item
        className="thematic-logo brand-logo"
        header={true}
        as={Link}
        to={`/c/${orgId}`}
      >
        <img src={LogoIcon} alt="" style={{width: '35px'}} />
      </Menu.Item>
    );
  };

  orgIcon = () => {
    const { logo, orgName } = this.organizationStore;
    return (
      <Menu.Item
        header={true}
        className="org-title"
      >
        {logo && <Image src={logo} className="org-logo" />}
        {!logo && <span>{orgName}</span>}
      </Menu.Item>
    );
  };

  updateSearch = (e, { value }) => {
    this.setState({ filter: value }, this.handleSearch);
  };

  render(): JSX.Element | null {
    const { fetchingOrganization, orgExists } = this.organizationStore;
    const hasMultipleOrgs = this.organizationsStore.organizations.length > 1;
    const { filteredOrganizations, filter } = this.state;
    if (orgExists) {
      return (
        <Menu.Menu>
          {fetchingOrganization && (
            <Menu.Item header={true} className="brand-logo">
              <Loader size="small" inline={true} active={true} />
            </Menu.Item>
          )}
          {!fetchingOrganization && (
            <>
              {this.logoItem()}
              {hasMultipleOrgs &&
                <Dropdown
                  floating={true}
                  trigger={this.orgIcon()}
                  className="org-dropdown"
                  onClick={() => this.setState({ dropDownVisible: !this.state.dropDownVisible })}
                  onClose={(e) => this.updateSearch(e, { value: '' })}
                  open={this.state.dropDownVisible}
                >
                  <Dropdown.Menu>
                    <Dropdown.Header>
                      <Input
                        icon="search"
                        ref={(inputEl) => inputEl && inputEl.focus()}
                        placeholder="Search organizations"
                        name="filter"
                        value={filter}
                        onChange={this.updateSearch}
                        autoComplete="off"
                        onKeyDown={(e) => (
                          e.code === 'Space' ? e.stopPropagation() : null
                        )}
                        // This onClick will make sure that dropdown stays open when clicked on search input
                        onClick={e => e.stopPropagation()}
                        onBlur={(e) => {
                          if (!e.relatedTarget) {
                            this.setState({ dropDownVisible: !this.state.dropDownVisible });
                          }
                        }}
                      />
                    </Dropdown.Header>
                    {filteredOrganizations.length > 0 &&
                      <Dropdown.Menu scrolling={true}>
                        {filteredOrganizations.map(org => {
                          return (
                          <Dropdown.Item key={org.displayId}>
                            <div className="org-dropdown-item">
                              <Popup
                                inverted={true}
                                position="bottom left"
                                trigger={this.props.organizationStore?.orgName !== org.name ?
                                    <Link to={`/c/${org.displayId}`} className="org-link">{org.name}</Link>
                                  :
                                    <div className="org-link">{org.name}</div>
                                }
                                content={`ID: ${org.displayId}`}
                              />
                              {org.configureLink && <Button
                                size="small"
                                href={org.configureLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Configure
                              </Button>}
                            </div>
                          </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    }
                    {filteredOrganizations.length === 0 &&
                      <p className="empty-search">No Matches Found</p>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              }
              {!hasMultipleOrgs && this.orgIcon()}
            </>
          )}
        </Menu.Menu>
      );
    } else {
      return (
        <Menu.Item className="brand-logo" header={true} as={Link} to="/">
          <img src={LogoIcon} alt="" style={{width: '35px'}} />
        </Menu.Item>
      );
    }
  }
}
export default withRouter(OrgLogo);
