import { ColumnType } from 'api/enums';
import Container from 'components/SortableList/Container';
import { isEmpty, partition } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Dropdown, DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { ConfigureDataUIStoreInterface, ScoreConfig } from 'stores/ui/ConfigureDataUIStore';
import { getColumnOptions } from './column-options';
import './column-options.scss';
import './configure-scores.scss';
import { isCommentColumn } from './configure-survey-helper';
import ConfigureScore from './ConfigureScore';

interface ConfigureScoresStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
}

interface ConfigureScoresProps extends ConfigureScoresStoreProps {
}

interface ConfigureScoresState {
  allOptions: DropdownItemProps[];
  forbidDrag: boolean;
  numericOptions: DropdownItemProps[];
  otherOptions: DropdownItemProps[];
}

const NO_SCORE_COLUMN_VALUE = -1;

@inject('configureDataUIStore')
@observer
export default class ConfigureScores extends React.Component<ConfigureScoresProps, ConfigureScoresState> {

  state = {
    allOptions: [] as DropdownItemProps[],
    forbidDrag: false,
    numericOptions: [] as DropdownItemProps[],
    otherOptions: [] as DropdownItemProps[]
  };

  componentDidMount () {
    const { columns, addScore, surveyConfig } = this.props.configureDataUIStore!;
    const { comment_columns } = surveyConfig;
    const availableColumns = columns.filter(column => !isCommentColumn(column, comment_columns));
    const [ numericColumns, otherColumns ] = partition(
      availableColumns, (column) => column.colType === ColumnType.NUMBER
    );
    const numericOptions = getColumnOptions(numericColumns, addScore);
    const otherOptions = getColumnOptions(otherColumns, addScore);
    const allOptions = [
      {
        value: NO_SCORE_COLUMN_VALUE,
        text: 'No score column',
        key: 'No score column'
      },
      // Only add header if corresponding options exists
      ...(!isEmpty(numericColumns) ? [{
        value: undefined,
        text: 'Maybe: Score columns',
        key: 'Maybe: Score columns',
        className: 'header',
        disabled: true,
      }] : []),
      ...getColumnOptions(numericColumns),
      // Only add header if corresponding options exists
      ...(!isEmpty(otherColumns) ? [{
        value: undefined,
        text: 'Other columns',
        key: 'Other columns',
        className: 'header',
        disabled: true,
      }] : []),
      ...getColumnOptions(otherColumns)
    ];
    this.setState({ numericOptions, otherOptions, allOptions });
  }

  addScore = ({ value = -1, options }: DropdownProps | DropdownItemProps) => {
    const option = (options || []).find(o => o.value === value);
    const { addScore } = this.props.configureDataUIStore!;

    // Don't update store with the default `No score column` option
    if (typeof value === 'number' && value > -1 && option && option.text) {
      addScore(value as number, option.text as string);
    }
  }

  onOrderChange = (order: string[]) => {
    const surveyConfig = { ...this.props.configureDataUIStore!.surveyConfig };
    if (surveyConfig.scores) {
      let scores = [] as ScoreConfig[];
      order.forEach(id => {
        const score = surveyConfig.scores!.find(f => f.id === id);
        if (score) {
          scores.push(score);
        }
      });
      this.props.configureDataUIStore!.updateScores(scores);
    }
  }

  toggleForbidDrag = (forbidDrag: boolean) => {
    this.setState({ forbidDrag });
  }

  render() {
    const { allOptions, forbidDrag, numericOptions, otherOptions } = this.state;
    const { surveyConfig } = this.props.configureDataUIStore!;
    const { scores } = surveyConfig;

    const components = (scores || []).map((score) => {
      return {
        id: score.id,
        content: <ConfigureScore
          key={score.id}
          id={score.id}
          toggleForbidDrag={this.toggleForbidDrag}
        />
      };
    });

    return (
      <div className="configure-scores">
        <h4>Scores (optional)</h4>
        <p
          className="description">
          Specifying a score column allows you to view the impact of different types of feedback on that score.
        </p>
        {!components.length &&
          <Form.Field>
          <label className="score-label">Score column</label>
          <Dropdown
            className="column-dropdown"
            options={allOptions}
            placeholder="Select"
            value={-1} // No date column
            onChange={(_e, data) => this.addScore(data)}
          />
        </Form.Field>
        }
        {components.length > 0 &&
          <>
            <DndProvider backend={HTML5Backend}>
              <Container
                id="configure-scores"
                list={components}
                onOrderChange={this.onOrderChange}
                forbidDrag={forbidDrag}
              />
            </DndProvider>
            <Dropdown
              text="Add score"
              icon="plus"
              floating={true}
              labeled={true}
              button={true}
              className="icon column-dropdown-menu"
            >
              <Dropdown.Menu>
              <Dropdown.Menu scrolling={true}>
                  <Dropdown.Item text="No score column" value="-1" onClick={(_e, data) => this.addScore(data)}/>
                  <Dropdown.Header>Maybe: Score Columns</Dropdown.Header>
                  {numericOptions.map(option => option.content)}
                  <Dropdown.Header>Other Columns</Dropdown.Header>
                  {otherOptions.map(option => option.content)}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </>
        }
      </div>
    );
  }
}
