import { flow } from 'lodash';
import * as React from 'react';
import { ConnectDragSource, ConnectDropTarget, DragSource, DropTarget, DropTargetMonitor, XYCoord } from 'react-dnd';
import * as ReactDOM from 'react-dom';
import './card.scss';

/*
  id: should be unique for each card
  content: is the component that gets displayed
*/
export interface SortableCard {
  id: string;
  content: JSX.Element;
}

interface CardDropTargetProps {
  connectDropTarget: ConnectDropTarget;
}

interface CardDragSourceProps {
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
}

interface CardProps extends CardDropTargetProps, CardDragSourceProps {
  index: number;
  listId: string;
  card: SortableCard;
  forbidDrag: boolean;

  onDragEnd: () => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const cardSource = {
  beginDrag(props: CardProps) {
    return {
      index: props.index,
      listId: props.listId,
      card: props.card
    };
  },

  endDrag(props: CardProps) {
    /* Notify that the drop is complete */
    props.onDragEnd();
  }
};

class Card extends React.Component<CardProps> {
  render() {
    const { card, isDragging, forbidDrag, connectDragSource, connectDropTarget } = this.props;

    /*
      Set opacity to 0 when dragging so the component that is being moved isn't displayed
     */
    const opacity = isDragging ? 0 : 1;

    const element = (
      <div className="sortable-list-card" style={{ opacity }}>
        {card.content}
      </div>
    );

    return forbidDrag ? element : connectDragSource(connectDropTarget(element));
  }
}

const cardTarget = {
  hover(props: CardProps, monitor: DropTargetMonitor, component: React.Component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const sourceListId = monitor.getItem().listId;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const node = ReactDOM.findDOMNode(component) as Element;
    const hoverBoundingRect = node.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset() as XYCoord;

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    if (props.listId === sourceListId) {
      props.moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  }
};

export default flow(
  DropTarget('CARD', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  })),
  DragSource('CARD', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(Card);