<template>
  <el-dropdown
    v-if="showDropdown"
    trigger="click"
    class="settings-dropdown"
  >
    <span>
      <font-awesome-icon icon="cog" />
    </span>
    <el-dropdown-menu
      slot="dropdown"
      class="settings-menu"
    >
      <el-dropdown-item
        v-if="showEdit"
        class="settings-menu-item"
      >
        <el-button
          @click="handleEdit"
        >
          <font-awesome-icon icon="pencil" /> <span class="item-text">Edit</span>
        </el-button>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="showDelete"
        class="settings-menu-item"
      >
        <el-button
          class="delete"
          @click="handleDelete"
        >
          <font-awesome-icon icon="trash-alt" /> <span class="item-text">Delete</span>
        </el-button>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'SettingsMenu',
  props: {
    showEdit: { type: Boolean, default: false },
    showDelete: { type: Boolean, default: false }
  },
  computed: {
    showDropdown() {
      return this.showEdit || this.showDelete;
    }
  },
  methods: {
    handleEdit() {
      this.$emit('onEdit');
    },
    handleDelete() {
      this.$emit('onDelete');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../styles/element-variables';
.settings-dropdown {
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
}
.settings-menu {
    cursor: pointer;
    .settings-menu-item {
      padding: 0;
      .el-button {
        width: 100%;
        text-align: left;
        border: none;
        color: $--neutral-900;
        padding: 10px 18px;
        .item-text {
          padding-left: 2px;
        }
      }
      .delete {
        color: $--red-700;
      }
    }
  }
</style>

