import { UnregisterCallback } from 'history';
import TagEditIcon from 'images/icons/tag-edit.svg';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { SurveyStoreInterface } from 'stores/SurveyStore';

interface ThemeEditorLinkStoreProps {
  surveyStore?: SurveyStoreInterface;
}

interface ThemeEditorLinkProps extends
  RouteComponentProps<{ orgId: string }>,
  ThemeEditorLinkStoreProps {
}

interface ThemeEditorLinkState {
  surveyId?: string;
}
@inject('surveyStore')
@observer
class ThemeEditorLink extends React.Component<
  ThemeEditorLinkProps,
  ThemeEditorLinkState
> {
  state = { surveyId: '' };
  unregister?: UnregisterCallback = undefined;
  componentDidMount() {
    const { history, location } = this.props;
    this.unregister = history.listen(loc => this.checkSurveyId(loc.pathname));
    this.checkSurveyId(location.pathname);
  }
  componentWillUnmount() {
    if (this.unregister) {
      this.unregister();
    }
  }
  checkSurveyId(pathname: string) {
    const matches = pathname.match(/^\/c\/[^/]+\/s\/([^/]+)/);
    if (matches) {
      this.setState({ surveyId: matches[1] });
    } else {
      this.setState({ surveyId: undefined });
    }
  }
  render() {
    const { surveyId } = this.state;
    const { match } = this.props;
    const { orgId } = match.params;

    // Use survey id from the URL if it exists and is an editable survey
    // Otherwise, use the first editable theme from editable surveys
    // If neither exists, do not show the menu item to edit themes
    let sId;
    const surveys = this.props.surveyStore!.getSurveysThatCanAction('manage:themes');
    if (!isEmpty(surveys)) {
      sId = surveys[0].surveyId;
    }

    if (surveyId) {
      const canEditCurrentSurvey = this.props.surveyStore!.getSurveyCanAction(surveyId, 'manage:themes');
      if (canEditCurrentSurvey) {
        sId = surveyId;
      }
    }

    if (!sId) {
      return null;
    }

    return (
      <Dropdown.Item as={Link} to={`/c/${orgId}/s/${sId}/themes`}>
        <TagEditIcon className="icon fa-fw svg-inline--fa" />
        Manage themes
      </Dropdown.Item>
    );
  }
}

export const WrappedClass = ThemeEditorLink;

export default withRouter(ThemeEditorLink);
