import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IntegrationType, SurveyDataStatus } from 'api/enums';
import { SurveySynopsis } from 'api/interfaces';
import analytics from 'lib/analytics';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Dimmer, Grid, Header, Loader, Message, Segment, Step } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { UserStoreInterface } from 'stores/UserStore';
import Import from './Import';
import ReviewData from './ReviewData';
import SetupComplete from './SetupComplete';
import './configure-survey.scss';

export enum SurveySteps {
  Import = 'Import',
  ReviewData = 'Review data'
}

export interface Step {
  id: string;
  name: string;
  disabled: boolean;
  completed: boolean;
  icon: IconProp;
}

const IMPORT_STEP = {
  id: 'import',
  name: SurveySteps.Import,
  disabled: false,
  completed: false,
  icon: 'cloud-upload-alt'
} as Step;

const REVIEW_DATA_STEP = {
  id: 'review',
  name: SurveySteps.ReviewData,
  disabled: false,
  completed: false,
  icon: 'comments'
} as Step;

export interface ConfigureSurveyStoreProps {
  setupStore?: SetupStoreInterface;
  surveyStore?: SurveyStoreInterface;
  userStore?: UserStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

export interface ConfigureSurveyParams {
  orgId: string;
  surveyId: string;
}

type LocationState = {
  dataSource?: IntegrationType,
  datasetName?: string,
};

export interface ConfigureSurveyProps extends
  ConfigureSurveyStoreProps,
  RouteComponentProps<ConfigureSurveyParams, {}, LocationState> {
}

interface ConfigureSurveyState {
  loading: boolean;
  loadingSurveys: boolean;
  activeStepIndex: number;
  steps: Step[];
  dataSynopsis?: SurveySynopsis;
  surveyDataStatus: SurveyDataStatus;
  processingOnNext: boolean;
  connectedIntegrationType?: IntegrationType;
  updatedSurveyName?: string;
  isAdminOverwrite: boolean;
  isSurveySetup: boolean;
  showSurveyCompleteMessage: boolean;
}

@inject('setupStore', 'surveyStore', 'userStore', 'organizationStore')
@observer
class ConfigureSurvey extends React.Component<ConfigureSurveyProps, ConfigureSurveyState> {
  state = {
    loading: true,
    loadingSurveys: false,
    activeStepIndex: 0,
    steps: [IMPORT_STEP, REVIEW_DATA_STEP],
    processingOnNext: false,
    connectedIntegrationType: this.props.location.state?.dataSource,
    updatedSurveyName: this.props.location.state?.datasetName,
    isSurveySetup: false,
    isAdminOverwrite: false,
    showSurveyCompleteMessage: false
  } as ConfigureSurveyState;

  get survey() {
    const { surveyStore, match } = this.props;
    const { surveyId } = match.params;

    return surveyStore!.surveys.find(s => s.surveyId === surveyId);
  }

  async componentDidMount() {
    const { setupStore, match } = this.props;
    const { surveyId } = match.params;

    analytics.track('Setup Flow: Started', { 'Category': 'Setup Flow' });

    setupStore!.reset();
    setupStore!.setCurrentSurveyId(surveyId);

    this.initialize();

    this.setState({ loadingSurveys: true });
    await this.props.surveyStore!.fetchSurveys();
    this.setState({ loadingSurveys: false });

  }

  componentDidUpdate(prevProps: ConfigureSurveyProps, prevState: ConfigureSurveyState) {
    const { location, match } = this.props;

    if (location.state?.dataSource && location.state.dataSource !== prevState.connectedIntegrationType) {
      this.setState({ connectedIntegrationType: location.state.dataSource });
    }

    if (prevProps.match.params.surveyId !== match.params.surveyId) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.props.setupStore!.resetCurrentSurveyId();
  }

  initialize = async () => {
    this.setState({ loading: true });
    const { surveyId } = this.props.match.params;
    const survey = await this.props.surveyStore!.getSurvey(surveyId, true);

    if (survey) {
      this.updateSteps(survey);
      this.setInitialActiveStep(survey.dataStatus);
      this.setState({ surveyDataStatus: survey.dataStatus });

      const surveyHasData = survey.dataStatus !== SurveyDataStatus.NODATA;
      const isSurveySetup = surveyHasData &&
        survey.dataStatus !== SurveyDataStatus.NORESULTS;
      // Thematic admins can overwrite setup config to fix bad configs
      const isThematicAdmin = this.props.surveyStore!.getSurveyCanAction(survey.surveyId, 'manage:internalResource');
      const isAdminOverwrite = isSurveySetup && isThematicAdmin;
      if (surveyHasData || isAdminOverwrite) {
        await this.getDataSynopsis(survey.surveyId);
      }
      this.setState({ isAdminOverwrite, isSurveySetup });
    }

    this.setState({ loading: false });
  }

  getDataSynopsis = async (surveyId: string) => {
    const dataSynopsis = await this.props.setupStore!.getSurveySynopsis(surveyId);

    if (dataSynopsis) {
      this.setState({ dataSynopsis });
    }
  }

  updateSteps = (survey: LiteSurvey) => {
    const noData = survey.dataStatus === SurveyDataStatus.NODATA;
    let steps = this.state.steps;
    // Data hasn't been imported (could be from direct URLs)
    if (noData) {
      steps = steps.map(s => ({
        ...s,
        disabled: !(s.name === SurveySteps.Import)
      }));

      this.setState({ steps });
    } else {
      this.updateStep(SurveySteps.Import, { completed: true });

      const config = JSON.parse(survey.configuration);
      const commentsSelected = !isEmpty(config.comment_columns);

      if (commentsSelected) {
        this.updateStep(SurveySteps.ReviewData, { completed: true });
      }
    }
  }

  setInitialActiveStep = (dataStatus: SurveyDataStatus): void | JSX.Element => {
    const { search } = this.props.location;
    const searchParams = parse(search.substring(search.indexOf('?')));
    const index = this.state.steps.findIndex(s => s.id === searchParams.step);

    if (index > -1) {
      this.setState({ activeStepIndex: index });
      return;
    }

    const noData = dataStatus === SurveyDataStatus.NODATA;
    const noCommentsSelected = dataStatus === SurveyDataStatus.NORESULTS;

    let activeStepIndex = 0;
    if (noData) {
      activeStepIndex = this.state.steps.findIndex(s => s.name === SurveySteps.Import);
    }
    if (noCommentsSelected) {
      activeStepIndex = this.state.steps.findIndex(s => s.name === SurveySteps.ReviewData);
    }

    if (activeStepIndex < 0) {
      const reversedSteps = [...this.state.steps].reverse();
      activeStepIndex = reversedSteps.findIndex(s => s.completed) || 0;
    }

    this.updateActiveStep(activeStepIndex);
  }

  resetErrors = () => {
    this.props.setupStore!.reset();
    this.updateActiveStep(0);
  }

  updateStep = (stepName: string, options: Partial<Step>) => {
    const index = this.state.steps.findIndex(s => s.name === stepName);
    if (index > -1) {
      let steps = this.state.steps;
      steps[index] = {
        ...steps[index],
        ...options
      };
      this.setState({ steps });
    }
  }

  renderStep = () => {
    const {
      steps,
      activeStepIndex,
      dataSynopsis,
      processingOnNext,
      connectedIntegrationType,
      updatedSurveyName,
      isAdminOverwrite,
      showSurveyCompleteMessage
    } = this.state;
    const { surveyId } = this.props.match.params;

    if (steps[activeStepIndex]) {
      if (showSurveyCompleteMessage) {
        return <SetupComplete onDone={this.onSetupComplete} />;
      } else if (!dataSynopsis) {
        return <Import
          surveyId={surveyId}
          isFirstStep={true}
          processingOnNext={processingOnNext}
          connectedIntegrationType={connectedIntegrationType}
          updatedSurveyName={updatedSurveyName}
          onCancel={this.onCancelFirstStep}
          onBack={this.onBack}
          onNext={this.onImportComplete}
        />;
      } else {
        switch (steps[activeStepIndex].name) {
          case SurveySteps.Import:
            return <Import
              surveyId={surveyId}
              isFirstStep={true}
              processingOnNext={processingOnNext}
              connectedIntegrationType={connectedIntegrationType}
              updatedSurveyName={updatedSurveyName}
              onCancel={this.onCancelFirstStep}
              onBack={this.onBack}
              onNext={this.onImportComplete}
            />;
          case SurveySteps.ReviewData:
            return <ReviewData
              isLastStep={true}
              isAdminOverwrite={isAdminOverwrite}
              dataSynopsis={dataSynopsis}
              onSetupComplete={this.onReviewDataComplete}
              onCancel={this.onCancel}
              onBack={this.onBack}
            />;
          default:
            return <Import
              surveyId={surveyId}
              isFirstStep={true}
              processingOnNext={processingOnNext}
              connectedIntegrationType={connectedIntegrationType}
              updatedSurveyName={updatedSurveyName}
              onCancel={this.onCancelFirstStep}
              onBack={this.onBack}
              onNext={this.onImportComplete}
            />;
        }
      }
    }
    return null;
  }

  updateActiveStep = (activeStepIndex: number) => {
    this.setState({ activeStepIndex });
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('step', this.state.steps[activeStepIndex].id);
    this.props.history.push(`${ this.props.location.pathname }?${ currentUrlParams }`);
  }

  onReviewDataComplete = () => {
    // Make all steps complete and mark them diabled as we show a done message
    let { steps } = this.state;
    steps = steps.map(step => ({
      ...step,
      completed: true,
      disabled: true
    }));

    this.setState({ steps, showSurveyCompleteMessage: true });
  }

  onSetupComplete = () => {
    this.navigateToManageData();
  }

  onBack = () => {
    this.updateActiveStep(this.state.activeStepIndex - 1);
  }

  onImportComplete = async (shouldFetchSurveySynopsis: boolean) => {
    const { setupStore } = this.props;

    if (this.survey) {
      // if we explicitly know we need to fetch the synopsis. Make the next button load while we wait for it
      if (shouldFetchSurveySynopsis) {
        this.setState({ processingOnNext: true });

        const dataSynopsis = await setupStore!.getSurveySynopsis(this.survey.surveyId);

        if (setupStore!.fetchSurveySynopsisError) {
          this.setState({ processingOnNext: false });
          return;
        }
        this.setState({ dataSynopsis, processingOnNext: false }, () => {
          this.updateSteps(this.survey!);
          this.updateActiveStep(this.state.activeStepIndex + 1);
        });
      } else {
        this.updateSteps(this.survey);
        this.onReviewDataComplete();
      }
    }
  }

  navigateToManageData = () => {
    const { orgId } = this.props.match.params;
    const pathname = `/c/${ orgId }/upload`;
    this.props.history.push(pathname);
  }

  navigateToInstructionsPage = () => {
    const { orgId } = this.props.match.params;
    const pathname = `/c/${ orgId }`;
    this.props.history.push(pathname);
  }

  onCancelFirstStep = () => {
    // if cancelling from the first step we want to delete the incomplete survey
    const { surveyStore, match } = this.props;
    const { surveyId } = match.params;

    surveyStore!.deleteSurvey(surveyId);

    this.onCancel();
  }

  onCancel = () => {
    const { steps, activeStepIndex } = this.state;
    const activeStepName = steps[activeStepIndex].name;
    analytics.track('Setup Flow: Cancel Configure Survey', { category: 'Setup Flow', step: activeStepName });

    const { orgId } = this.props.match.params;
    let url = `/c/${ orgId }/upload`;

    this.props.history.push(url);
  }

  goToStep = (stepIndex: number) => {
    const { steps } = this.state;
    if (!steps[stepIndex].disabled) {
      this.updateActiveStep(stepIndex);
    }
  }

  render(): JSX.Element | null {
    const { loading, loadingSurveys, steps, activeStepIndex, isSurveySetup, isAdminOverwrite } = this.state;
    const { match, setupStore, surveyStore } = this.props;

    const { fetchSurveySynopsisError } = setupStore!;
    const { fetchSurveyError, getSurveyTitle } = surveyStore!;

    const { surveyId } = match.params;
    const surveyName = getSurveyTitle(surveyId) || 'dataset';

    if (!loading && isSurveySetup && !isAdminOverwrite) {
      const { orgId } = match.params;
      return <Redirect to={`/c/${ orgId }/upload`} />;
    }

    if (loading || loadingSurveys) {
      return (
        <Segment className="fullscreen">
          <Dimmer active={true} inverted={true}>
            <Loader size="large">Loading&hellip;</Loader>
          </Dimmer>
        </Segment>
      );
    }

    return (
      <Segment className="configure-survey">
        <Header>
          Set up "{surveyName}"
        </Header>
        <Segment className="thematic-steps">
          {fetchSurveyError &&
            <Message
              className="error"
              negative={true}
              header={fetchSurveyError}
            />
          }
          {fetchSurveySynopsisError &&
            <Message
              className="error"
              negative={true}
            >
              <Message.Header>
                <p>The format doesn't match the standard formats we deal with,
                  but we'll have a look and get back to you shortly.
                </p>
                <p>In the meantime, you can <a
                  href="https://help.getthematic.com/article/77-thematic-data-formats"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  reformat your data
                </a> and try <Button className="link" onClick={this.resetErrors}>uploading another file.</Button></p>
              </Message.Header>
            </Message>
          }
          {!fetchSurveyError && !fetchSurveySynopsisError && <Grid celled={true}>
            <Grid.Row>
              <Grid.Column width={4}>
                <Step.Group
                  fluid={true}
                  vertical={true}
                >
                  {steps.map((s, index) => {
                    const active = index === activeStepIndex;
                    return (
                      <Step
                        key={s.name}
                        completed={s.completed}
                        disabled={s.disabled}
                        active={active}
                        onClick={() => this.goToStep(index)}
                      >

                        <div className="step-circle">{index + 1}</div>
                        <Step.Content>
                          <Step.Title>{s.name}</Step.Title>
                        </Step.Content>
                      </Step>
                    );
                  })}
                </Step.Group>
              </Grid.Column>
              <Grid.Column width={12}>
                <Segment attached={true}>
                  {this.renderStep()}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>}
        </Segment>
      </Segment>
    );
  }
}

export default withRouter(ConfigureSurvey);
