import * as React from 'react';
import { ExamplesList } from './ExamplesList';
import { InputData } from 'api/interfaces';

interface Props {
  content: InputData[];
  isReadOnly: boolean;
  onTextSelection: (text: string) => void;
  onToggleExamplePin: (example: InputData) => void;
}

export const Examples = ({ content, isReadOnly, onToggleExamplePin, onTextSelection }: Props) => {

  return (
    <section className="answers__examples">
      <ExamplesList
        content={content}
        isReadOnly={isReadOnly}
        onTextSelection={onTextSelection}
        onToggleExamplePin={onToggleExamplePin}
      />
    </section>
  );
};
