import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { matchPath } from 'react-router';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { AnswersStoreInterface } from 'stores/AnswersStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';

interface AnswersNavProps extends RouteComponentProps<object> {
  orgId: string;
  answersStore?: AnswersStoreInterface;
  surveyStore?: SurveyStoreInterface;
}

export const AnswersNav = withRouter(
  inject(
    'answersStore',
    'surveyStore'
  )(observer(class AnswersNavClass extends React.Component<AnswersNavProps> {

    render(): JSX.Element | null {
      const { orgId, answersStore, surveyStore } = this.props;

      const loading = !!surveyStore!.fetchingSurveys;
      const haveAccessToTools = answersStore!.userHasAccessToAnswers;

      const isActive = matchPath(location.hash.slice(1), {
        path: `/c/:orgId/answers/:savedAnswerId?`,
        exact: true
      });

      const classes = classnames('normal-size', 'nw-answers-menu', 'ob-answers-menu', {
        'is-active': isActive
      });

      if (haveAccessToTools) {
        return (
          <Menu.Item
            as={loading ? 'a' : Link}
            to={loading ? null : `/c/${ orgId }/answers`}
            className={classes}
          >
            Answers
            {loading &&
              <FontAwesomeIcon
                className="icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
              />
            }
          </Menu.Item>
        );
      }

      return null;

    }
  })));
