import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionJson } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Select } from 'semantic-ui-react';
import { RoleStoreInterface } from 'stores/RoleStore';
import Scopes from './Scopes';
import './dashboard-permissions.scss';

export interface DashboardPermissionsProps {
  roleId: string;
  roleStore?: RoleStoreInterface;
}

interface DashboardPermissionsState {
  selectedValue: string;
}

const ALL = 'all';
const INDIVIDUAL = 'individual';
const selectOptions = [
  { key: ALL, value: ALL, text: ALL },
  { key: INDIVIDUAL, value: INDIVIDUAL, text: INDIVIDUAL }
];

@inject('roleStore')
@observer
class DashboardPermissions extends React.Component<DashboardPermissionsProps, DashboardPermissionsState> {
  get roleStore(): RoleStoreInterface {
    return this.props.roleStore!;
  }
  get roleId() {
    return this.props.roleId;
  }
  get roleDetails() {
    return this.roleStore.roleDetails[this.roleId];
  }

  constructor(props: DashboardPermissionsProps) {
    super(props);
    const { isPerReport } = this.roleDetails;

    this.state = {
      selectedValue: isPerReport ? INDIVIDUAL : ALL,
    };
  }

  handlePerReportChange = (isPerDashboard) => {
    this.roleStore.updateIsPerReport(this.roleId, isPerDashboard);
  };

  onScopesChange = (dashboardId: string, scopes: string[]) => {
    this.roleStore.updateDashboardPermissions(this.roleId, dashboardId, scopes);
  };

  renderScopes = (dashboardId: string) => {
    const availableScopes = this.roleStore.reportScopes;
    const selectedScopes = this.roleStore.getSelectedReportScopes(this.roleId, dashboardId);

    return (
      <Scopes
        availableScopes={availableScopes}
        selectedScopes={selectedScopes}
        onScopesChange={(scopes) => this.onScopesChange(dashboardId, scopes)}
      />
    );
  }

  renderPermission = (permission: PermissionJson) => {
    const { id: dashboardId, name } = permission;
    const roleDetails = (this.props.roleStore!.roles.find(r => r.id === this.roleId) || {}).policy;
    const hasRestrictions = roleDetails && !!(roleDetails.reports.find(r => r.id === dashboardId) || {}).subResource;

    return (
      <div key={dashboardId} className="nw--report-permission">
        <div>
          <label className="report-name">{name}</label>
          {this.renderScopes(dashboardId)}
        </div>
        {hasRestrictions && <div className="ui mini compact icon yellow message">
          <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
          <div className="header">
            This report has further hidden restrictions
          </div>
        </div>}
      </div>
    );
  }

  renderPerReportScopes = () => {
    const perDashboardPermissions = this.roleStore.reportPermissions.filter(p => p.id !== '*');

    return (
      <div className="dashboard-permissions">
        {perDashboardPermissions.map(permission => this.renderPermission(permission))}
      </div>
    );
  };

  handleSelectChange = (data) => {
    const { selectedValue } = this.state;
    const { value } = data;
    const isPerReport = value !== ALL;

    if (selectedValue !== value) {
      this.clear();
    }

    this.setState({ selectedValue: value });
    this.handlePerReportChange(isPerReport);
  }

  clear = () => {
    const { roleId } = this.props;
    this.roleStore.roleDetails[roleId].reports = [];
  }

  render(): JSX.Element | null {
    const { selectedValue } = this.state;
    const { isPerReport } = this.roleDetails;

    return (
      <section>
        <strong>Dashboards</strong>
        <div className="nw--is-per-report">
          <legend>
            Set permissions for
            <Select
              className={`nw--per-report ${ selectedValue === ALL ? 'select-short' : 'select-long' }`}
              value={selectedValue}
              options={selectOptions}
              onChange={(_e, data) => this.handleSelectChange(data)}
            />
            dashboards:
          </legend>
          {!isPerReport && this.renderScopes('*')}
        </div>
        {isPerReport && this.renderPerReportScopes()}
      </section>
    );
  }
}

export default DashboardPermissions;
