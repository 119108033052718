
import { WaterfallVisualizationSection } from 'api/interfaces';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import toFixed from 'vue/libs/to-fixed';
import './visualization-waterfall-table.scss';

interface VisualizationWaterfallTableProps {
  content: WaterfallVisualizationSection;
}

export class VisualizationWaterfallTable extends React.Component<
  VisualizationWaterfallTableProps
> {

  render() {
    const { content } = this.props;

    const data = content.data.dataPoints;
    return (<>
      <Table className="answers__visualization-watefall-table" sortable={true} celled={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={3}>
              Reason for score change
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell>
              {content.data.previousData.label}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.Cell>
              {toFixed(content.data.previousData.score, 1)}
            </Table.Cell>
          </Table.Row>

          {data.map((dataPoint) => {
            return <Table.Row key={dataPoint.title}>
              <Table.HeaderCell />
              <Table.HeaderCell>
                {dataPoint.title}
              </Table.HeaderCell>
              <Table.Cell className={dataPoint.value > 0 ? 'positive-change' : 'negative-change'}>
                {toFixed(dataPoint.value, 1)}
              </Table.Cell>
            </Table.Row>;
          })}
          <Table.Row>
            <Table.HeaderCell>
              {content.data.currentData.label}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.Cell>
              {toFixed(content.data.currentData.score, 1)}
            </Table.Cell>
          </Table.Row>
        </Table.Header>
      </Table>

    </>
    );
  }
}
