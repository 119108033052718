import * as React from 'react';
import "./theme-list.scss";
import { CommentThemeItem } from 'types/custom';
import { ThemeItem } from './ThemeItem';
import classNames from 'classnames';

type Props = {
  items: CommentThemeItem[]
  selectedIndex?: number,
  showSentiment: boolean,
  onClick?: (item: CommentThemeItem, index: number) => void,
  onMouseEnter?: (item: CommentThemeItem, index: number) => void,
  onMouseLeave?: () => void
  disabled?: boolean,
};

const ThemeList = (props: Props) => {
  const isSelected = (index: number) => !props.disabled && index === props.selectedIndex;

  return (
    <ul className="react-theme-list">
      {props.items.map((item, index) => (
        <li
          key={`${item.theme.title}--${index}`}
          className={classNames('react-theme-list__item', {
            'react-theme-list__item--selected': isSelected(index),
          })}
          onClick={() => props.onClick?.(item, index)}
          onMouseEnter={() => props.onMouseEnter?.(item, index)}
          onMouseLeave={() => props.onMouseLeave?.()}
        >
          <ThemeItem
            item={item}
            showSentiment={props.showSentiment}
            disabled={!!props.disabled}
          />
        </li>
      ))}
    </ul>
  );
};

export { ThemeList };
