import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Segment, SegmentVisibility } from 'api/interfaces';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import analytics from 'lib/analytics';
import * as React from 'react';
import { Button, Checkbox, Input, Popup } from 'semantic-ui-react';
import { getFilterStore, getSegmentStore } from 'stores/RootStore';
import { AnalysisFilterKey } from 'stores/types';
import FilterMenuItem from './FilterMenuItem';
import './saved-filters.scss';
interface SavedFiltersProps {
  canCreatePublicSavedFilters: boolean;
  disableSavedFilterWithTheme?: boolean;
  filterKey: AnalysisFilterKey;
  onSavedFilterSelected?: (savedFilter: Segment) => void;
  onAllFiltersReset: () => void;
}

interface SavedFiltersState {
  creatingSavedFilter: boolean;
  savedFilterName: string;
  savedFilterVisibility: SegmentVisibility;
  savingFilter: boolean;
  savingFilterError?: string;

  editingSavedFilter?: Segment;
  deletingSavedFilter?: Segment;
  editingSavedFilterError?: string;
  applyingEditToSavedFilter: boolean;
  applyingDeleteToSavedFilter: boolean;
}

class SavedFilters extends React.Component<SavedFiltersProps, SavedFiltersState> {
  state = {
    creatingSavedFilter: false,
    savedFilterName: '',
    savedFilterVisibility: 'private' as SegmentVisibility,
    savingFilter: false,
    applyingEditToSavedFilter: false,
    applyingDeleteToSavedFilter: false,
  } as SavedFiltersState;

  onStartCreateSavedFilter = () => {
    const { filterKey } = this.props;
    const filterStore = getFilterStore();
    const savedFilterName = filterStore.queryTitles[filterKey];
    this.setState({
      creatingSavedFilter: true,
      savedFilterName: savedFilterName ? savedFilterName : '',
      savedFilterVisibility: 'private',
      savingFilterError: undefined,
      editingSavedFilter: undefined,
      deletingSavedFilter: undefined,
      editingSavedFilterError: undefined,
    });
  };

  onChangeSavedFilterVisibility = () => {
    const { savedFilterVisibility } = this.state;
    if (savedFilterVisibility === 'private') {
      this.setState({ savedFilterVisibility: 'public' });
    } else {
      this.setState({ savedFilterVisibility: 'private' });
    }
  };

  onCancelCreateSavedFilter = () => {
    this.setState({ creatingSavedFilter: false });
  };

  onCreateSavedFilter = async () => {
    const segmentStore = getSegmentStore();
    const { filterKey } = this.props;
    const { savedFilterName, savedFilterVisibility } = this.state;

    if (!savedFilterName) {
      this.setState({ savingFilterError: 'Please enter a name' });
      return;
    }

    analytics.track('Segment created');
    this.setState({ savingFilter: true });

    const isPublic = savedFilterVisibility === 'public';
    await segmentStore.createSegment(filterKey, savedFilterName, isPublic);
    if (segmentStore.createSegmentError) {
      this.setState({ savingFilter: false });
      return;
    }

    this.setState({ savingFilter: false, creatingSavedFilter: false });
  };

  onChangeSavedFilterName = (value: string) => {
    this.setState({ savedFilterName: value });
  };

  onSavedFilterClicked = (savedFilter: Segment) => {
    const { filterKey } = this.props;
    analytics.track('Segment selected');
    const segmentStore = getSegmentStore();
    segmentStore.selectSegment(filterKey, savedFilter.id);
    if (this.props.onSavedFilterSelected) {
      this.props.onSavedFilterSelected(savedFilter);
    }
  };

  onEdit = (e: React.MouseEvent<Element>, savedFilter: Segment) => {
    e.stopPropagation();
    this.setState({
      editingSavedFilter: savedFilter,
      savedFilterName: savedFilter.name,
      applyingEditToSavedFilter: false,
    });
  };

  onConfirmEdit = async (e: React.MouseEvent<Element>) => {
    const { editingSavedFilter, savedFilterName } = this.state;
    if (editingSavedFilter) {
      const segmentStore = getSegmentStore();
      editingSavedFilter.name = savedFilterName;
      this.setState({ applyingEditToSavedFilter: true });
      await segmentStore.updateSegment(editingSavedFilter);
      this.setState({ applyingEditToSavedFilter: false });
      analytics.track('Segment edited');
      if (!segmentStore.updateSegmentError) {
        this.onCancelEdit(e);
      }
    }
  };

  onCancelEdit = (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    this.setState({ editingSavedFilter: undefined });
  };

  onDelete = (e: React.MouseEvent<Element>, savedFilter: Segment) => {
    e.stopPropagation();
    this.setState({ deletingSavedFilter: savedFilter });
  };

  onConfirmDelete = async (e: React.MouseEvent<HTMLElement>) => {
    const { filterKey } = this.props;
    e.stopPropagation();
    analytics.track('Segment deleted');
    const { deletingSavedFilter } = this.state;
    if (!deletingSavedFilter) {
      return;
    }
    const segmentStore = getSegmentStore();
    this.setState({ applyingDeleteToSavedFilter: true });

    const selectedSavedFilter = segmentStore.getSegmentById(segmentStore.selectedSegment[filterKey]);
    if (selectedSavedFilter?.id === deletingSavedFilter.id) {
      this.props.onAllFiltersReset();
    }

    await segmentStore.deleteSegment(deletingSavedFilter.id);
    this.setState({ applyingDeleteToSavedFilter: false });
    if (!segmentStore.deleteSegmentError) {
      this.onCloseDeleteModal(e);
    }
  };

  onCloseDeleteModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    this.setState({ deletingSavedFilter: undefined });
  };

  renderDeleteConfirmation() {
    const { filterKey } = this.props;
    const { deletingSavedFilter, applyingDeleteToSavedFilter } = this.state;
    const segmentStore = getSegmentStore();

    if (!deletingSavedFilter) {
      return;
    }
    const mountNode = document.querySelector(`#filter-button-${filterKey}`);

    return (
      <Modal open={true} size="small" onClose={this.onCloseDeleteModal} mountNode={mountNode}>
        <ModalHeader>Are you sure you want to delete "{deletingSavedFilter.name}"?</ModalHeader>
        <ModalContent>
          {deletingSavedFilter.visibility === 'private' ? (
            <div>This saved filter will be deleted. You can't undo this action.</div>
          ) : (
            <div>
              All other users in this workspace will lose access to this saved filter. You can't undo this action.
            </div>
          )}
          {segmentStore.deleteSegmentError && (
            <>
              <div className="saved-filters-delete-modal-error-text">An error occured. Please try again later.</div>
            </>
          )}
        </ModalContent>
        <ModalActions>
          <Button onClick={this.onCloseDeleteModal}>Cancel</Button>
          <Button onClick={this.onConfirmDelete} color="red" loading={applyingDeleteToSavedFilter}>
            Delete
          </Button>
        </ModalActions>
      </Modal>
    );
  }

  renderSegments = (title: string, savedFilters: Segment[]) => {
    const { disableSavedFilterWithTheme } = this.props;
    const { editingSavedFilter, savedFilterName, applyingEditToSavedFilter } = this.state;
    const segmentStore = getSegmentStore();

    if (savedFilters.length === 0) {
      return;
    }

    return (
      <>
        <div className="saved-filters-section-title">{title}</div>
        {savedFilters.map((savedFilter) => {
          let disableSavedFilter = false;
          if (disableSavedFilterWithTheme) {
            const isThemeFilterPresent = 'themes' in savedFilter.configuration.filters;
            disableSavedFilter = isThemeFilterPresent;
          }
          if (editingSavedFilter && savedFilter.id === editingSavedFilter.id) {
            return (
              <FilterMenuItem key={savedFilter.id} className="saved-filters-editing">
                <Input
                  className="edit-saved-filter-input saved-filters-name"
                  value={savedFilterName}
                  size="small"
                  autoFocus={true}
                  spellCheck="false"
                  onChange={(e) => this.onChangeSavedFilterName(e.target.value)}
                />
                <div className="saved-filters-edit-actions">
                  <div className="saved-filters-edit-action-icon confirm">
                    <FontAwesomeIcon
                      icon={applyingEditToSavedFilter ? 'spinner' : 'check'}
                      spin={applyingEditToSavedFilter}
                      onClick={(e) => this.onConfirmEdit(e)}
                    />
                  </div>
                  <div className="saved-filters-edit-action-icon cancel">
                    <FontAwesomeIcon icon="times" onClick={(e) => this.onCancelEdit(e)} />
                  </div>
                </div>
                {segmentStore.updateSegmentError && (
                  <>
                    <div className="saved-filters-error-break" />
                    <div className="saved-filters-error-text">An error occurred. Please try again later.</div>
                  </>
                )}
              </FilterMenuItem>
            );
          }
          return (
            <FilterMenuItem
              key={savedFilter.id}
              disable={disableSavedFilter}
              onClick={() => this.onSavedFilterClicked(savedFilter)}
            >
              <span className="saved-filters-name">{savedFilter.name}</span>
              <div className="saved-filters-actions">
                {disableSavedFilter ?
                  <Popup
                    className="saved-filter-info-popup"
                    position="top center"
                    on="hover"
                    content="Cannot use theme filters for theme discovery"
                    trigger={
                      <FontAwesomeIcon
                        className="saved-filters-info-icon"
                        icon="info-circle"
                      />
                    }
                  />
                :
                  <>
                    <FontAwesomeIcon
                      className="saved-filters-action-icon"
                      icon="pencil"
                      onClick={(e) => this.onEdit(e, savedFilter)}
                    />
                    <FontAwesomeIcon
                      className="saved-filters-action-icon"
                      icon="trash-alt"
                      onClick={(e) => this.onDelete(e, savedFilter)}
                    />
                  </>
                }
              </div>
            </FilterMenuItem>
          );
        })}
      </>
    );
  };

  render() {
    const { creatingSavedFilter, savedFilterName, savedFilterVisibility, savingFilter, savingFilterError } = this.state;
    const { canCreatePublicSavedFilters } = this.props;

    const segmentStore = getSegmentStore();

    const segments = segmentStore.segments;
    const privateSegments = this.renderSegments(
      'Private',
      segments.filter((s) => s.visibility === 'private'),
    );
    const publicSegments = this.renderSegments(
      'Shared',
      segments.filter((s) => s.visibility === 'public'),
    );

    return (
      <>
        <div className="saved-filters">
          {segments.length > 0 ? (
            <div className="saved-filters-list-container">
              <div className="saved-filters-list">
                {privateSegments}
                {privateSegments && publicSegments && <div className="saved-filters-section-separator" />}
                {publicSegments}
                {creatingSavedFilter && <div className="saved-filters-disable" />}
              </div>
            </div>
          ) : (
            <div className="no-saved">No saved filters have been created</div>
          )}
        </div>
        {creatingSavedFilter ? (
          <div className="create-saved-filter">
            <div>Name</div>
            <div className="create-saved-filter-row">
              <Input
                className="create-saved-filter-input"
                value={savedFilterName}
                size="small"
                autoFocus={true}
                spellCheck="false"
                onChange={(e) => this.onChangeSavedFilterName(e.target.value)}
              />
              {savingFilterError && <div className="saved-filters-error-text">{savingFilterError}</div>}
            </div>
            <div className="create-saved-filter-row">
              <Checkbox
                toggle={true}
                disabled={!canCreatePublicSavedFilters}
                size="small"
                label="Share with workspace"
                checked={savedFilterVisibility === 'public'}
                onChange={() => this.onChangeSavedFilterVisibility()}
              />

              <Popup
                className="filter-popup-tooltip"
                content="Only workspace admins can create shared filters."
                position="right center"
                trigger={<FontAwesomeIcon className="icon" icon="info-circle" fixedWidth={true} />}
              />
            </div>
            {segmentStore.createSegmentError && (
              <div className="saved-filters-error-text">An error occured. Please try again later.</div>
            )}
            <div className="create-saved-filter-actions">
              <Button className="filter-button" size="small" onClick={() => this.onCancelCreateSavedFilter()}>
                Cancel
              </Button>

              <Button
                type="button"
                color="blue"
                className="filter-button"
                size="small"
                loading={savingFilter}
                onClick={() => this.onCreateSavedFilter()}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <button
            className="filters-action-button plain"
            onClick={this.onStartCreateSavedFilter}
            onMouseDown={(e) => e.preventDefault()}
          >
            Create Saved Filter
          </button>
        )}
        {this.renderDeleteConfirmation()}
      </>
    );
  }
}

export default SavedFilters;
