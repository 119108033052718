import { Block } from "lib/segments-to-blocks";
import { isNonSpecialTheme } from "lib/special-theme-predicates";
import { PlainComment, Summary } from "types/custom";
import { isNewlyAddedTheme, isThemeRemovedInPopup } from "vue/libs/edited-comment-helpers";

const findPopupThemeItems = (comment: PlainComment | Summary, block: Block) => {
  if (!('hasThemes' in block) || !block.hasThemes) {
    return [];
  }

  return comment.segments
    .filter(segment => segment.location[0] === block.location[0] && segment.location[1] === block.location[1])
    .reduce((result, segment) => {
      const nonSpecialThemes = segment.themes.filter(isNonSpecialTheme);

      const themeItems = nonSpecialThemes.map(theme => ({
        theme,
        sentiment: segment.sentiment,
        hasBackgroundColour: false,
        isNew: isNewlyAddedTheme(comment.id, theme),
        isRemoved: isThemeRemovedInPopup(comment.id, theme, block)
      }));

      return [...result, ...themeItems];
    }, []);
}

export { findPopupThemeItems };
