
import { FeatureFlagManager } from 'lib/feature-flag';
import { find, map } from 'lodash';
import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import './select-template.scss';
import AvailableTemplates, { AvailableTemplate } from './Templates/available-templates';

export interface SelectTemplateProps {
  setTemplate: (component: React.ComponentClass, defaults: object) => void;
}

interface SelectTemplateState {
  selectedTemplate?: AvailableTemplate;
}

class SelectTemplate extends React.Component<SelectTemplateProps, SelectTemplateState> {
  constructor(props: SelectTemplateProps) {
    super(props);

    this.state = {};
  }

  handleSelectedTemplateChange = (value: string) => {
    const selectedTemplate = find(AvailableTemplates, { name: value });
    this.setState({ selectedTemplate }, () => {
      if (selectedTemplate) {
        this.props.setTemplate(selectedTemplate.component, selectedTemplate.defaults);
      }
    });
  }

  render() {
    const { selectedTemplate } = this.state;
    return (
      <Segment className="white select-template workflows">
        <Segment className="select-template-options">
          {map(AvailableTemplates, template => {
            if (template.requiresFlag && !FeatureFlagManager.checkFlag(template.requiresFlag)) {
              return null;
            }
            return (
              <Segment
                key={template.name}
                className={
                  'select-template-option white' +
                  (selectedTemplate?.name === template.name ? ' selected-template-option' : '')
                }
                onClick={() => this.handleSelectedTemplateChange(template.name)}
              >
                {template.img}
                <div className="template-description">
                  <h4>{template.name}</h4>
                  {template.description}
                </div>
              </Segment>
            );
          })}
        </Segment>
      </Segment>
    );
  }
}

export default SelectTemplate;
