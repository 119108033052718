import { defaults } from 'lodash';
import { nonemptyCountFromCountsObject } from 'vue/libs/counts';

interface Counts {
  empty: number;
  nothing: number;
  other: number;
  total: number;
}

export const getBaselineCount = (counts: Counts) => {
  const defaultCounts = {
    other: 0,
    nothing: 0,
    total: 0,
    empty: 0
  };

  return nonemptyCountFromCountsObject(defaults(counts, defaultCounts));
};
