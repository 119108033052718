import * as React from 'react';
import './special-conditions-banner.scss';

export const SpecialConditionsBanner = () => {
  return (
    <div className="special-conditions-banner">
      This billing account has special conditions that may cause
      this page to over-estimate your usage. Please contact your
      account manager for more information.
    </div>
  );
};

export default SpecialConditionsBanner;