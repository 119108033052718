import { action, observable } from 'mobx';
import { ExportableInsight, InsightVis } from 'types/custom';
import BarChartForm from 'vue/components/Insights/BarChartForm.vue';
import BarChart from 'vue/components/Insights/Charts/InsightsBarChart.vue';
import LegendInsight from 'vue/components/Insights/Charts/LegendInsight.vue';

export const FORM_COMPONENTS = {
  [InsightVis.BarChart]: BarChartForm
};

export const PREVIEW_COMPONENTS = {
  [InsightVis.BarChart]: BarChart,
  [InsightVis.Legend]: LegendInsight,
};

export interface ExportInsightUIStoreInterface {
  show: (insights: Array<ExportableInsight>) => void;
  hide: () => void;
  update: (updatedInsight: ExportableInsight) => void;
  updateFileName: (fileName: string) => void;
  isVisible: boolean;
  insights: Array<ExportableInsight>;
  fileName: string;
}

class ExportInsightUIStore implements ExportInsightUIStoreInterface {

  @observable insights: Array<ExportableInsight> = [];

  @observable isVisible = false;

  @observable fileName = '';

  @action
  updateFileName = (fileName: string) => {
    this.fileName = fileName;
  };

  @action show(insights: Array<ExportableInsight>) {
    this.isVisible = true;
    this.insights = insights;
  }

  @action update(updatedInsight: ExportableInsight): void {
    this.insights = this.insights.map(insight => {
      if (insight.type === updatedInsight.type) {
        return updatedInsight;
      }
      return insight;
    });
  }

  @action hide() {
    this.isVisible = false;
    this.insights = [];
  }

}

export default ExportInsightUIStore;
