import { PhraseSegmentTheme, Thread } from "types/custom";
import { Block } from 'lib/segments-to-blocks';
import { getHighlightsFromParts } from "vue/libs/find-highlighted-sentences";

interface GetRemoveThemeParams {
  block?: Block;
  theme: PhraseSegmentTheme;
  thread: Thread;
}

const getRemoveThemeParams = ({
  block,
  theme,
  thread,
}: GetRemoveThemeParams) => {
  const highlightedSentences = block ? [block.content] : getHighlightsFromParts(thread.comment, theme);

  return {
    highlightedSentences,
    commentId: thread.id,
    selectedTheme: theme,
    selectedBlock: block,
  };
}

export { getRemoveThemeParams };
