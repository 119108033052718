import classNames from 'classnames';
import * as React from 'react';
import './popup.scss';

interface Props {
  open: boolean;
  host: React.ReactNode;
  className?: string;
  onClose?: () => void;
}

// This popup is anchored to its host element (e.g. a button), so it will move with the host when scrolling etc.
class Popup extends React.Component<Props, {}> {
  private ref = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('keydown', this.onDocumentKeydown);
    document.addEventListener('click', this.onDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeydown);
    document.removeEventListener('click', this.onDocumentClick, true);
  }

  onDocumentKeydown = (e: KeyboardEvent) => {
    if (this.props.open && e.key === 'Escape') {
      e.stopPropagation();
      this.props.onClose?.();
    }
  };

  onDocumentClick = (e: MouseEvent) => {
    const isDocumentClick = e.target instanceof Node && !this.ref.current?.contains(e.target);
    const isHost = e.target === this.ref.current?.children[0]; // first child in ref is host
    if (this.props.open && (isDocumentClick || isHost)) {
      if (isHost) {
        e.stopPropagation(); // preventing the click event so the popup doesn't stay open
      }
      this.props.onClose?.(); // Closes the popup if the user clicks anywhere outside it or host.
    }
  };

  render() {
    const { open, host, className, children } = this.props;
    return (
      <div className={classNames('popup-wrapper', className)} ref={this.ref}>
        {host}
        {open && (
          <div className="popup-anchor">
            <div className="popup">{children}</div>
          </div>
        )}
      </div>
    );
  }
}

export default Popup;
