import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact } from 'lodash';
import { inject } from 'mobx-react';
import * as React from 'react';
import { Button, Header, Message, Modal, Table } from 'semantic-ui-react';
import { SubscriptionInterface, SubscriptionStoreInterface } from 'stores/SubscriptionStore';
import SubscriptionModal from '../Subscription/SubscriptionModal';

interface SubscriptionStoreProps {
  subscriptionStore?: SubscriptionStoreInterface;
}

export interface SubscriptionProps extends SubscriptionStoreProps {
  digest: SubscriptionInterface;
  orgId: string;
}
export interface SubscriptionState {
  deleting: boolean;
  error: boolean;
  proposeDelete: boolean;
  proposeEdit: boolean;
}

@inject('subscriptionStore')
export default class Subscription extends React.Component<
SubscriptionProps,
SubscriptionState
> {
  state = {
    deleting: false,
    error: false,
    proposeDelete: false,
    proposeEdit: false
  };
  cancelDelete = () => {
    this.setState({ proposeDelete: false });
  };
  cancelEdit = () => {
    this.setState({ proposeEdit: false });
  };
  confirmDelete = async () => {
    const { digest, subscriptionStore } = this.props;
    this.setState({ deleting: true, error: false });
    try {
      await subscriptionStore!.deleteSubscription(digest.id);
      this.setState({ proposeDelete: false });
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.setState({ deleting: false });
    }
  };
  getFilterLabel = () => {
    const { compareFilterName, filterName } = this.props.digest.context;
    const tokens = compact([compareFilterName, filterName]);

    if (tokens.length) {
      return tokens.join(', ');
    } else {
      return undefined;
    }
  };
  proposeDelete = () => {
    this.setState({ proposeDelete: true });
  };
  proposeEdit = () => {
    this.setState({ proposeEdit: true });
  };
  render() {
    const { digest, orgId } = this.props;
    const { deleting, error, proposeDelete, proposeEdit } = this.state;

    const {
      cadenceLabel,
      context,
      id,
      name,
      dashboardName,
      subscribersLabel
    } = digest;

    const filterName = this.getFilterLabel();

    return (
      <Table.Row key={id} verticalAlign="middle" role="row">
        <Table.Cell>
          <a href={context.viewDashboardUrl} title={context.filterName}>
            {name}
          </a>
        </Table.Cell>
        <Table.Cell>{dashboardName}</Table.Cell>
        <Table.Cell width={2} disabled={!filterName}>
          {filterName || 'No filter'}
        </Table.Cell>
        <Table.Cell width={4}>{subscribersLabel}</Table.Cell>
        <Table.Cell collapsing={true}>{cadenceLabel}</Table.Cell>
        <Table.Cell collapsing={true}>
          {proposeDelete && (
            <Modal
              open={true}
              size="small"
              dimmer="blurring"
              onClose={this.cancelDelete}
            >
              <Modal.Header as={Header}>Delete subscription</Modal.Header>

              <Modal.Content>
                Are you sure you want to delete the subscription "{digest.name}"
                for the report <i>{digest.dashboardName}</i>?
              </Modal.Content>
              {error && (
                <Message negative={true} attached={true}>
                  <FontAwesomeIcon
                    icon="exclamation-triangle"
                    className="icon"
                  />
                  Uh oh! Something went wrong deleting this subscription
                </Message>
              )}
              <Modal.Actions>
                <Button disabled={deleting} onClick={this.cancelDelete}>
                  Cancel
                </Button>
                <Button
                  disabled={deleting}
                  loading={deleting}
                  color="red"
                  onClick={this.confirmDelete}
                >
                  Yes, delete
                </Button>
              </Modal.Actions>
            </Modal>
          )}
          {proposeEdit && (
            <SubscriptionModal
              close={this.cancelEdit}
              digest={digest}
              orgId={orgId}
              dashboardId={digest.dashboardID}
              request={{
                context: digest.context,
                dashboardName: digest.dashboardName
              }}
            />
          )}

          <Button
            basic={true}
            size="small"
            content="Edit"
            onClick={this.proposeEdit}
          />
          <Button
            color="red"
            basic={true}
            size="small"
            content="Delete"
            onClick={this.proposeDelete}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}
