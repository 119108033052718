import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubscriptionJson } from 'api/interfaces';
import { capitalize, isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, SemanticCOLORS, Table } from 'semantic-ui-react';
import { SubscriptionStoreInterface } from 'stores/SubscriptionStore';

interface UserSubscriptionInterface {
  cadence: string;
  id: string;
  name: string;
  dashboardName: string;
  subscribed: boolean;
  status: string;
}

interface UserSubscriptionStoreProps {
  subscriptionStore?: SubscriptionStoreInterface;
}

interface UserSubscriptionProps extends UserSubscriptionStoreProps {
  subscription: SubscriptionJson;
}

interface UserSubscriptionState {
  working: boolean;
  error: boolean;
  subscription?: UserSubscriptionInterface;
}

@inject('subscriptionStore')
@observer
class UserSubscription extends React.Component<
UserSubscriptionProps,
UserSubscriptionState
> {
  state = {
    error: false,
    working: false,
    subscription: undefined
  } as UserSubscriptionState;

  componentDidMount() {
    const { subscription } = this.props;
    const mappedSubscription = this.mapSubscription(subscription);
    this.setState({ subscription: mappedSubscription });
  }

  componentDidUpdate(prevProps: UserSubscriptionProps) {
    const prevSubscription = prevProps.subscription;
    const { subscription } = this.props;

    if (!isEqual(subscription, prevSubscription)) {
      const mappedSubscription = this.mapSubscription(subscription);
      this.setState({ subscription: mappedSubscription });
    }
  }
  mapSubscription = (subscription: SubscriptionJson) => {
    const { cadence, id, name, dashboardName, subscriptionStatus, status } = subscription;

    const subscribed = subscriptionStatus === 'subscribed';

    return {
      cadence: capitalize(cadence),
      id: String(id),
      name,
      dashboardName,
      subscribed,
      status
    };
  }
  handleClick = async () => {
    this.setState({ error: false, working: true });
    const { subscriptionStore } = this.props;
    const { subscription } = this.state;

    if (!subscription) {
      return;
    }

    const toStatus = subscription.subscribed ? 'unsubscribed' : 'subscribed';
    try {
      await subscriptionStore!.changeSubscription(subscription.id, toStatus);
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.setState({ working: false });
    }
  };
  render(): JSX.Element | null {
    const { error, working, subscription } = this.state;

    if (!subscription) {
      return null;
    }

    const { cadence, id, name, dashboardName, subscribed } = subscription;

    let color: SemanticCOLORS | undefined = undefined;
    if (error || (!working && subscribed)) {
      color = 'red';
    }
    return (
      <Table.Row key={id} verticalAlign="middle">
        <Table.Cell className="name">{name}</Table.Cell>
        <Table.Cell width={2}>{cadence}</Table.Cell>
        <Table.Cell width={3}>{dashboardName}</Table.Cell>
        <Table.Cell collapsing={true}>
          <Button
            basic={error || subscribed}
            color={color}
            content={subscribed ? 'Unsubscribe' : 'Resubscribe'}
            disabled={working}
            icon={
              error && (
                <FontAwesomeIcon icon="exclamation-triangle" className="icon" />
              )
            }
            loading={working}
            onClick={this.handleClick}
            size="small"
            title={error ? 'Uh oh! Something went wrong. Retry?' : undefined}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default UserSubscription;
