import FileSaver from 'file-saver';
import analytics from 'lib/analytics';
import { forEach, get } from 'lodash';
import { getFilterStore, getInitConfigStore } from 'stores/RootStore';
import Auth from 'Auth/Auth';
import thematicData from 'vue/libs/thematicData';

const CREATE_DIGEST_PATH = 'createDigest';
const REPORT_WIDGET_PATH = 'reportWidget';

type RqlFilter = string;
type DateRange = string;

export interface ReportContext {
    selectedPeriod?: DateRange;
    previousPeriod?: DateRange;
    baseFilter?: RqlFilter;
    filter?: RqlFilter;
    compareFilterName: string;
    compareFilter: RqlFilter;
}

export enum ThemeLevel {
    SUBTHEME = 'subtheme',
    BASETHEME = 'basetheme',
  }

interface BaseWidgetConfig {
    type: string;
    commentColumns: number[];
}

interface ScoreConfig {
  column: number;
  type: string;
  name: string;
  options?: object;
}

export interface ThemesWidgetConfig extends BaseWidgetConfig {
    themeLevel: ThemeLevel;
    compareToOverall?: boolean;
    focusTheme?: string;
    numThemes?: number;
}

export interface ThemesSubWidgetConfig extends BaseWidgetConfig {
    basetheme: string;
    compareToOverall?: boolean;
    focusTheme?: string;
    numThemes?: number;
}

interface ImpactWidgetConfig extends BaseWidgetConfig {
    themeLevel: ThemeLevel;
    compareToOverall?: boolean;
    score: ScoreConfig;
    focusTheme?: string;
    numThemes?: number;
}

interface ImpactSubWidgetConfig extends BaseWidgetConfig {
    basetheme: string;
    compareToOverall?: boolean;
    score: ScoreConfig;
    focusTheme?: string;
    numThemes?: number;
}

interface WidgetContext {
    filter?: RqlFilter;
    filterName?: string;
    compareFilter?: RqlFilter;
    compareFilterName?: string;
}

type WidgetConfig =
  | ThemesWidgetConfig
  | ThemesSubWidgetConfig
  | ImpactWidgetConfig
  | ImpactSubWidgetConfig;

interface Widget {
    config: WidgetConfig;
    data?: object;
    promise?: Promise<object>;
}

interface Panel {
    title?: string;
    widgets: Widget[];
}

interface DigestMetadata {
    filter?: string;
    organizationLogo?: string;
}

interface Digest {
    title: string;
    metadata: DigestMetadata;
    panels: Panel[];
}

export function generateSubtitle( selectedBasetheme: string | null,
                                  selectedSubtheme: string | null,
                                  includeFilters: boolean): string {
    let toolSubtitle = '';

    // first generate the theme name based title
    if (selectedBasetheme) {
        toolSubtitle = selectedBasetheme;
        if (selectedSubtheme) {
          toolSubtitle += ` - ${selectedSubtheme}`;
        }
    }

    // then generate the filters based title
    if (includeFilters) {
        if (toolSubtitle !== '') {
            toolSubtitle += '\n\n';
        }
        const queryTitles = getFilterStore().queryTitles;
        if (queryTitles.baseline === queryTitles.comparison) {
            toolSubtitle += queryTitles.baseline;
        } else {
            toolSubtitle += `${queryTitles.baseline} vs ${queryTitles.comparison}`;
        }
    }

    return toolSubtitle;
}

function createContext(): WidgetContext {
    /*
    Create the context object expected by the reportWidget endpoint
    */
    const queryStrings = getFilterStore().queryStrings;
    const queryTitles = getFilterStore().queryTitles;

    const context = {
        filter: queryStrings.baseline,
        filterName: queryTitles.baseline
    } as WidgetContext;

    if ( queryStrings.baseline !== queryStrings.comparison ) {
        context.compareFilter = queryStrings.comparison;
        context.compareFilterName = queryTitles.comparison;
    }

    return context;
}

function freezeWidget(config: WidgetConfig, context: WidgetContext) {
    /*
    Freezes the data needed for rendering a widget
    */

  const requestOptions = getInitConfigStore().requestOptions;
  let url = get(requestOptions, 'dataSource', '');
  url = `${url}${REPORT_WIDGET_PATH}`;
  return thematicData.getWidget(url, config, context); // NB: this returns a promise
}

export function createDigest(title: string, subtitle: string): Digest {
    /*
    Creates an object to add widgets to. And Eventually render
    */
    const organizationLogo = undefined;
    return {
        title,
        metadata: {
            filter: subtitle,
            organizationLogo
        },
        panels: []
    };
}

export function addWidgetToDigest(digest: Digest, config: WidgetConfig, context?: WidgetContext): void {
    /*
    Given a digest object, add a widget to it.
    Current requirements is that the data is frozen at this point.
    */
    if (digest.panels.length === 0) {
        digest.panels.push({
            widgets: []
        });
    }

    // if not supplied with a pre-built context create one
    if (!context) {
        context = createContext();
    }

    const promise = freezeWidget(config, context);

    const activePanel = digest.panels[digest.panels.length - 1];
    activePanel.widgets.push({
        config,
        promise
    });
}

export async function downloadDigest(digest: Digest) {
    /*
    Given a digest that is 'complete', download it as a pptx
    */
  // the create digest endpoint needs an organization identifier
  const organization = getInitConfigStore().organization;

  // replace the config url with that needed to create a digest
  const requestOptions = getInitConfigStore().requestOptions;
  let url = get(requestOptions, 'dataSource', '');
  url = url.replace(/(api\/).+/g, '$1' + CREATE_DIGEST_PATH);

    // ensure that we have completed getting data for all widgets
  const promises = [] as (Promise<object>|undefined)[];
  const widgets = [] as Widget[];
  forEach(digest.panels, async panel => {
        forEach(panel.widgets, async widget => {
            promises.push(widget.promise);
            widgets.push(widget);
        });
    });

  Promise.all(promises).then( async (values) => {
        // replace data values with fulfilled promises
        for ( let i = 0; i < values.length; i++ ) {
            widgets[i].data = values[i];
            delete widgets[i].promise;
        }

        const content = {
            version: 'export',
            config: digest
        };
        // now download the pptx as a data blob
        const { data, ok } = await Auth.fetch(`${url}?organization=${organization}`, {
            method: 'post',
            body: JSON.stringify({ content, type: 'pptx' }),
            // @ts-expect-error Not defined in the typescript typedefs
            responseType: 'arraybuffer',
        });

        if (!ok) {
          console.error('Error constructing PPTX');
          return;
        }

        // and finally save as a pptx
        const blob = new Blob([data as BlobPart], {
            type:
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        });
        let filename = `${digest.title}.pptx`;
        if (digest.metadata.filter) {
            filename = `${digest.title} - ${digest.metadata.filter}.pptx`;
        }
        FileSaver.saveAs(blob, filename);

        analytics.track('Export: Powerpoint', {
            category: 'Analysis'
        });

    });
}
