<template>
  <widget-wrapper
    :aria-label="subtitle"
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
    :subtitle-nodes="subtitleNodes"
  >
    <div
      v-if="showScore"
      class="widget-body scores"
    >
      <div class="overall score">
        <h2>
          {{ scoreLabel }}
          <div class="response-count-subtext">
            {{ responseCountLabel }}
          </div>
        </h2>
        <legend v-if="previousScore">
          <component
            :is="scoreChangeIndicator"
            v-if="scoreChangeIndicator"
            class="arrowIndicator"
          /> {{ scoreDifferenceLabel }} compared to {{ previousPeriodName }}
        </legend>
      </div>
    </div>
    <div
      v-else
      class="empty widget-body"
    >
      Not enough responses to calculate
    </div>
  </widget-wrapper>
</template>

<script>
import { get, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';

import { ReactInVue } from 'vuera';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper.tsx';

export default {
  name: 'SCORE',
  components: {
    WidgetWrapper: ReactInVue(WidgetWrapper),
  },
  props: {
    config: { type: Object },
    context: { type: Object },
    data: { type: Object },
    warning: { type: String },
    error: { type: String },
    limit: { default: 5, type: Number },
    loading: { type: Boolean },
    source: { type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number }
  },
  // called as a static function from WidgetChooser#widgetType to determine what type to use
  isComparison(config) {
    return config.compareToOverall;
  },
  hasOriginalScore(data) {
    return !!get(data, 'currentScore.score.componentScores.0.originalScore.score');
  },
  computed: {
    arrowUp() {
      return () => import('images/vue-icons/arrow-up.svg');
    },
    arrowDown() {
      return () => import('images/vue-icons/arrow-down.svg');
    },
    currentScore() {
      return get(this, 'data.currentScore.score.score');
    },
    currentPeriodName() {
      return get(this, 'data.currentPeriodName');
    },
    precision() {
      const precision = get(this,'scoreConfig.options.precision', this.config.precision);
      const absDifference = Math.abs(this.scoreDifference);

      if (isNumber(precision)) {
        return precision;
      } else if (absDifference < 10) {
        return 1;
      } else {
        return 0;
      }
    },
    previousScore() {
      return get(this, 'data.previousScore.score.score');
    },
    previousPeriodName() {
      return get(this, 'data.previousPeriodName');
    },
    responseCount() {
      return get(this, 'data.currentScore.count', 0);
    },
    responseCountLabel() {
      return toFixed(this.responseCount, 1, ' responses');
    },
    scoreLabel() {
      return toFixed(this.currentScore, 1);
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    showScore() {
      return this.responseCount > 0;
    },
    scoreName() {
      const compareFilterName = get(this, 'context.compareFilterName', '');
      const scoreConfigName = get(this, 'scoreConfig.name', '');
      return compareFilterName ? compareFilterName : scoreConfigName;
    },
    scoreDifference() {
      return this.currentScore - this.previousScore;
    },
    scoreDifferenceLabel() {
      if (this.currentScore === null || this.previousScore === null) {
        return null;
      }
      const { precision, scoreDifference } = this;
      const absDifference = Math.abs(scoreDifference);
      const label = toFixed(scoreDifference, precision);
      if (absDifference < 0.1) {
        return 'No change';
      } else if (scoreDifference > 0) {
        return `+${label}`;
      } else {
        return label;
      }
    },
    scoreChangeIndicator() {
      const { previousScore, scoreDifference } = this;
      if (previousScore) {
        const absDifference = Math.abs(scoreDifference);
        if (absDifference < 0.1) {
          return undefined;
        } else if (scoreDifference < 0) {
          return this.arrowDown;
        } else if (scoreDifference > 0) {
          return this.arrowUp;
        }
      }
      return undefined;
    },
    subtitle() {
      let title = this.scoreName;
      if (this.currentPeriodName) {
        title += ` in ${this.currentPeriodName}`;
      }
      return title;
    },
    subtitleNodes() {
      return this.$createElement('div', {
        class: 'widget-subtitle'
      }, [
        this.$createElement('div', {
          class: 'widget-subtitle-text'
        }, this.subtitle)
      ]);
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../styles/element-variables';
.empty {
  align-content: center;
  display: flex;
  text-align: center;
}

.response-count-subtext {
  font-size: 12px;
  font-weight: 400;
  color: $--neutral-500;
  padding-bottom: 10px;

}
</style>
