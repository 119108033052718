import { action, observable } from 'mobx';
export interface URLFilters {
  baseline: { [key: string]: string[] };
  comparison: { [key: string]: string[] };
}

export interface UrlParametersStoreInterface {
  parameters: Parameters;
  shareUrlBase: string;

  setBaseShareUrl: (newShareUrlBase: string) => void;
  setParameter: (parameter: string, value: string | URLFilters) => void;
  reset: () => void;
}

export interface Parameters {
  themeFilterDimension?: string;
  filters?: URLFilters;
  theme?: string;
  subtheme?: string;
}

class UrlParametersStore implements UrlParametersStoreInterface {
  @observable
  parameters = {};

  @observable
  shareUrlBase = '';

  @action
  reset() {
    this.parameters = {};
    this.shareUrlBase = window.location.href.split('?')[0];
  }

  @action
  setBaseShareUrl(newShareUrlBase: string) {
    this.shareUrlBase = newShareUrlBase;
  }

  @action
  setParameter(parameter: string, value: string | URLFilters) {
    this.parameters[parameter] = value;
  }
}

export default UrlParametersStore;
