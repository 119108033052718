import * as d3 from 'd3';
import d3tip, { D3Tip } from 'd3-tip';
import colors from 'styling/variables/colors.scss';
import Vue from 'vue';
import toFixed from 'vue/libs/to-fixed';
import { calculateTickInterval } from './bargraphHelpers';

export type RenderLineGraphDataArgs =  {
  el: Vue | Element | Vue[] | Element[] | HTMLElement,
  chartOptions: {
    containerHeight: number,
    containerWidth: number,
    labels: string[],
    low: number,
    high: number,
    baselineValues: number[],
    comparisonValues?: number[]
  },
  tip?: D3Tip
};

const MARGIN_TOP = 40;
const MARGIN_RIGHT = 50;
const MARGIN_BOTTOM = 40;
const MARGIN_LEFT = 50;
const CHART_HEIGHT_OFFSET = 10;
const VALUES_ABOVE_DOTS_OFFSET = 10;

function getLineGraphTip() {
  return d3tip()
    .attr(
      'class',
      'el-tooltip__popper is-dark el-tooltip__popper-no-mouse'
    )
    .attr('x-placement', 'top')
    .offset([-10, 0])
    .html((value) => {
      return `
        <div class="line-graph-tooltip">
          ${toFixed(value, 2)}
        </div>
        <div y-arrow class="popper__arrow" style="left:38%;"></div>
      `;
    });
}

function renderLineGraph(
  {
    el,
    chartOptions,
    tip
  }: RenderLineGraphDataArgs
) {
  // // For expediency, erase any existing chart and build a new one.
  d3.select(el).selectAll('*').remove();

  const {
    containerWidth,
    containerHeight,
    baselineValues,
    labels,
    low,
    high,
    comparisonValues
  } = chartOptions;

  // Dimensions
  const margin = { top: MARGIN_TOP, right: MARGIN_RIGHT, bottom: MARGIN_BOTTOM, left: MARGIN_LEFT };
  const svgWidth = containerWidth - margin.left - margin.right;
  const svgHeight = containerHeight - margin.top - margin.bottom;

  // Create SVG
  const svg = d3.select(el)
    .attr('width', svgWidth + margin.left + margin.right)
    .attr('height', (svgHeight + margin.top + margin.bottom) - CHART_HEIGHT_OFFSET)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  // X Scale
  const x = d3.scaleBand()
    .domain(labels)
    .range([0, svgWidth])
    .padding(-1);

  // Y Scale
  const y = d3.scaleLinear()
    .domain([low, high])
    .nice()
    .range([svgHeight, 0]);

  // Line
  const line = d3.line()
    .x((_, i: number) => x(labels[i]) + x.bandwidth() / 2)
    .y((d: number) => y(d))
    .curve(d3.curveMonotoneX); // curve line

  // X Axis
  svg.append('g')
    .attr('transform', 'translate(0,' + svgHeight + ')')
    .call(
      d3.axisBottom(x)
      .tickSize(0)
      .tickPadding(15)
    )
    .attr('color', 'transparent');

  const tickInterval = calculateTickInterval(low, high);
  const numberOfTicks = (y.domain()[1] - y.domain()[0]) / tickInterval;

  // Y Axis
  const yAxis = d3.axisLeft(y)
    .tickSize(0)
    .ticks(numberOfTicks)
    .tickFormat(d3.format('.0f'))
    .tickPadding(20);

  svg.append('g')
    .call(yAxis)
    .select('.domain')
    .attr('stroke', 'none');

  const tickValues = y.ticks(numberOfTicks);
  // background lines
  svg.selectAll('.y-axis-line')
    .data(tickValues)
    .enter()
    .append('line')
    .attr('class', 'y-axis-line')
    .attr('x1', 0)
    .attr('y1', (d: number) => y(d))
    .attr('x2', svgWidth)
    .attr('y2', (d: number) => y(d))
    .style('stroke', colors.neutral200)
    .style('stroke-width', 0.5);

  const baselineGroup = svg.append('g');

  // Draw Line
  baselineGroup.append('path')
    .datum(baselineValues)
    .attr('fill', 'none')
    .attr('stroke', colors.primary500)
    .attr('stroke-width', 3)
    .attr('d', line);

  // Draw Dots
  baselineGroup.selectAll('.dot')
    .data(baselineValues)
    .enter()
    .append('circle')
    .attr('class', 'dot')
    .attr('cx', (_, i: number) => x(labels[i]) + x.bandwidth() / 2)
    .attr('cy', (d: number) => y(d))
    .attr('r', 4)
    .attr('fill', colors.primary500)
    .call(tip ?? (() => { /* if the tip is not present, do nothing */ }))
    .on('mouseover', tip?.show)
    .on('mouseout', tip?.hide);

  if (!comparisonValues) {
    // Area
    const area = d3.area()
      .x((_, i: number) => x(labels[i]) + x.bandwidth() / 2)
      .y0(svgHeight)
      .y1((d: number) => y(d))
      .curve(d3.curveMonotoneX); // curve area

    // Only draw area when comparison values are not present
    // Draw Area
    baselineGroup.append('path')
      .datum(baselineValues)
      .attr('fill', colors.primary500)
      .style('fill-opacity', 0.1)
      .attr('d', area);

    // Only show baselineValues above Dots when comparison values are not present
    baselineGroup.selectAll('.dot-value')
      .data(baselineValues)
      .enter()
      .append('text')
      .attr('class', 'dot-value')
      .attr('x', (_, i: number) => x(labels[i]) + x.bandwidth() / 2)
      .attr('y', (d: number) => y(d) - VALUES_ABOVE_DOTS_OFFSET)
      .text((d: number) => d.toFixed(1))
      .style('text-anchor', 'middle')
      .style('font-size', '12px');
  }

  if (comparisonValues) {
    const comparisonGroup = svg.append('g');

    // Draw Line
    comparisonGroup.append('path')
      .datum(comparisonValues)
      .attr('fill', 'none')
      .attr('stroke', colors.orange500)
      .attr('stroke-width', 3)
      .attr('d', line);

    // Draw Dots
    comparisonGroup.selectAll('.dot')
      .data(comparisonValues)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', (_, i: number) => x(labels[i]) + x.bandwidth() / 2)
      .attr('cy', (d: number) => y(d))
      .attr('r', 4)
      .attr('fill', colors.orange500);
  }

  // updating text colors
  svg.selectAll('text')
    .style('fill', colors.neutral500);

}

export default {
  renderLineGraph,
  getLineGraphTip
};