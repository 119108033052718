import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Menu } from 'semantic-ui-react';
import { OrganizationsStoreInterface } from 'stores/OrganizationsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';

interface OrgNameStoreInterface {
  organizationStore?: OrganizationStoreInterface;
  organizationsStore?: OrganizationsStoreInterface;
}

interface OrgNameProps extends OrgNameStoreInterface {}

@inject('organizationStore', 'organizationsStore')
@observer
class OrgName extends React.Component<OrgNameProps> {
  render() {
    const { organizationStore, organizationsStore } = this.props;

    const {
      logo,
      orgName,
      orgExists,
    } = organizationStore!;
    const { organizations } = organizationsStore!;
    const hasMultipleOrganizations = organizations.length > 1;

    const shouldShowOrgName = orgExists && logo && hasMultipleOrganizations;

    if (shouldShowOrgName) {
      return (
        <>
          <Menu.Item>
            <div className="org-name">
              {orgName}
            </div>
          </Menu.Item>
          <div className="menu-divider" />
        </>
      );
    } else {
      return null;
    }
  }
}

export default OrgName;