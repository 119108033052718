
import AnalyzeIcon from 'images/icons/icon-analyze.svg';
import DashboardIcon from 'images/icons/icon-dashboard.svg';
import ThemesEditIcon from 'images/icons/icon-edit-themes.svg';
import { RecentActivity, RecentActivityType } from 'lib/recent-activity';
import * as Moment from 'moment';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import './recent-activity-button.scss';
interface RecentActivityButtonProps {
  recentActivityInfo: RecentActivity;
}

class RecentActivityButton extends React.Component<RecentActivityButtonProps> {
  render() {
    const { recentActivityInfo } = this.props;

    let ActivityIcon;
    let text = '';
    if (recentActivityInfo.type === RecentActivityType.Analysis) {
      ActivityIcon = AnalyzeIcon;
      text = 'Analyze';
    } else if (recentActivityInfo.type === RecentActivityType.Dashboard) {
      ActivityIcon = DashboardIcon;
      text = 'View\ndashboard';
    } else if (recentActivityInfo.type === RecentActivityType.ThemesEditing) {
      ActivityIcon = ThemesEditIcon;
      text = 'Edit\nthemes';
    }

    return (
      <Button className="recent-activity-button"
        onClick={() => {
            if (recentActivityInfo.url) {
              window.location.href = recentActivityInfo.url;
            }
      }} >
        <div>
          <div className="recent-activity-icon">{ActivityIcon && (<ActivityIcon />)}</div>
          <div className="recent-activity-type">{text}</div>
        </div>
        <div className="recent-activity-description">{recentActivityInfo.description}</div>
        <div className="recent-activity-date">{Moment(recentActivityInfo.date).fromNow()}</div>
      </Button>
    );
  }
}

export default RecentActivityButton;