import Logo from 'images/logo.svg';
import { parse } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Card, Container, Header, Menu } from 'semantic-ui-react';

export default class Unsubscribed extends React.Component<
  RouteComponentProps<{}>
> {
  parseUnsubscribeParams(search: string): { workflow: string | null; digest: string | null } {
    try {
      const { params } = parse(search);
      if (typeof params === 'string') {
        const { workflowName, digest } = JSON.parse(atob(params));
        return {
          workflow: workflowName || null,
          digest: !workflowName ? (digest || null) : null
        };
      }
    } catch (e) {
      // do nothing; it didn't work
    }
    return { workflow: null, digest: null };
  }

  render() {
    const { location } = this.props;
    const { workflow, digest } = this.parseUnsubscribeParams(location.search);

    return (
      <div>
        <Menu inverted={true} size={'massive'}>
          <Menu.Item header={true}>
            <Logo width="140" />
          </Menu.Item>
        </Menu>
        <Container text={true}>
          <Card fluid={true}>
            <Card.Content>
              <Card.Header as={Header}>Unsubscribed</Card.Header>
              {!workflow && !digest && (
                <p>
                  You have been successfully unsubscribed.
                </p>
              )}
              {workflow && (
                <p>
                  You have been removed from "{workflow}" workflow.
                </p>
              )}
              {digest && (
                <p>
                  You have been removed from "{digest}". Contact your admin to
                  subscribe again.
                </p>
              )}
            </Card.Content>
          </Card>
        </Container>
      </div>
    );
  }
}
