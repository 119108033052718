import { AnswersActionType } from 'api/interfaces';
import { AnswerNode } from 'types/custom';

export function getQuestion(item: AnswerNode['item']): string | null {
  if (item.state === 'output') {
    return item.content.question;
  }
  if (item.state === 'error') {
    return item.originalAction.action === AnswersActionType.moreDepth
      ? item.originalAction.previousQuestion
      : item.originalAction.question;
  }
  return null;
}
