import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { ThemeGroup } from 'lib/theme-file-parser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, List } from 'semantic-ui-react';
import { ThemesStoreInterface, ThemeTreeItem } from 'stores/ThemesStore';

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

export interface MappedPhraseProps {
  onClick: () => void;
  group: ThemeGroup;
  node: ThemeTreeItem;
  phrase: string;
  isSelected: boolean;
}

export default inject('themesStore')(
  observer((props: MappedPhraseProps) => {
    const {
      group,
      node,
      onClick,
      phrase,
      isSelected,
      themesStore: store
    } = props as MappedPhraseProps & InjectedProps;
    const cls = classnames('list-action clickable', { selected: isSelected });
    return (
      <List.Item key={phrase} className={cls} onClick={onClick}>
        <List.Content>
          <div className="ui checkbox fluid">
            <input
              className="hidden"
              readOnly={true}
              type="checkbox"
              checked={isSelected}
            />
            <label>{phrase}</label>
          </div>
        </List.Content>
        <Button
          className="action"
          size="small"
          onClick={() => store.deletePhrases(group, node, [phrase])}
        >
          <FontAwesomeIcon icon="trash" className="icon" />
        </Button>
      </List.Item>
    );
  })
);
