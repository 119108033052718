import * as React from 'react';
import './email-manager.scss';
import LabelList from './LabelList';
import { Input, LabelProps } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from './Button';
import { InlineNotification } from './InlineNotification';
import { isEmail } from 'validator';

interface EmailManagerProps {
  title: string;
  existingEmails: string[];
  inputPlaceholder: string;
  hasUserTriedToSubmitForm: boolean;
  onEmailsChange: (emailInputState: EmailInputState) => void;
}

export interface EmailInputState {
  emails: string[];
  enteredValue: string;
  enteredValueValidation: boolean;
}

export const EmailManager = (props: EmailManagerProps) => {
  const { title, existingEmails, inputPlaceholder, hasUserTriedToSubmitForm, onEmailsChange } = props;
  const [emails, setEmails] = React.useState<string[]>(existingEmails);
  const [enteredEmail, setEnteredEmail] = React.useState<string>('');
  const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);

  function removeEmail(_e: React.MouseEvent<HTMLElement>, props: LabelProps) {
    const newEmails = emails.filter(email => email !== props.content);
    setEmails(newEmails);
    onEmailsChange({
      emails: newEmails,
      enteredValue: '',
      enteredValueValidation: false
    });
  }

  function handleInputChange(e: React.FormEvent<HTMLInputElement>) {
    const value = e.currentTarget.value.trim();
    const isEmailValid = isEmail(value);
    onEmailsChange({
      emails: emails,
      enteredValue: value,
      enteredValueValidation: isEmailValid
    });
    setEnteredEmail(value);
    setIsEmailValid(isEmailValid);
  }

  function addEmail() {
    const trimmedEnteredEmail = enteredEmail.trim();
    if (trimmedEnteredEmail.length === 0) {
      return;
    }
    const isEmailValid = isEmail(trimmedEnteredEmail);

    if (isEmailValid && !emails.includes(trimmedEnteredEmail)) {
      const newEmails = [...emails, trimmedEnteredEmail];
      setEmails(newEmails);
      setEnteredEmail('');

      onEmailsChange({
        emails: newEmails,
        enteredValue: '',
        enteredValueValidation: false
      });

    }
  }

  function onEmailKeypress(
    e: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (e.key === 'Escape') {
      setEnteredEmail('');
      e.preventDefault();
      e.stopPropagation();
    } else if (
      e.key === 'Enter' ||
      e.key === ' ' ||
      e.key === ',' ||
      e.key === 'Tab'
    ) {
      addEmail();
    }
  }

  const shouldShowError = hasUserTriedToSubmitForm
    && emails.length === 0
    && !isEmail(enteredEmail);

  return (
    <div className="email-manager">
      <label className="email-manager__title" htmlFor="email-title">{title}</label>
      <Input
        id="email-title"
        className="email-manager__input"
        action={
          <Button
            variant="secondary"
            size="medium"
            subvariant="white"
            onClick={addEmail}
            ariaLabel="Add email"
          >
            <FontAwesomeIcon icon="plus" className="icon large blue" />
          </Button>
        }
        error={!isEmailValid}
        placeholder={inputPlaceholder}
        type="email"
        fluid={true}
        value={enteredEmail}
        onChange={handleInputChange}
        onKeyDown={onEmailKeypress}
      />
      <LabelList
        size="large"
        itemClassName="green-border"
        items={emails}
        onRemove={removeEmail}
      />
      {shouldShowError &&
        <InlineNotification
          title="Add recipients' email before sending"
          type='error'
        />
      }
    </div>
  );
};
