import { RequestOptions } from 'stores/InitConfigStore';

export type TimePeriod = 'weekly' | 'monthly' | 'quarterly' | 'biannually';

export interface DateOptions {
  timePeriod: TimePeriod;
  repeatFrequency: string;
}

export const Columns = {
  volume: 'volume',
  impact: 'impact',
  score: 'score',
  sentimentCounts: 'sentiment-counts',
  count: 'count',
} as const;

export type Column = typeof Columns[keyof typeof Columns];


export interface FilterCut {
  name: string;
  value: string;
  rql: string;
}

export interface Category {
  id: string;
  name: string;
  dateOptions?: DateOptions;
  filterCuts?: FilterCut[];
  columns: Column[];
  type: string;
}

export interface TableByCategoryThemes {
  [key: string]: {
    [key: string]: ExportAssistantTableData;
    subthemes: ExportAssistantTableData;
  };
}

interface Score {
  hist: number[];
  score: number;
  stdev: number;
}

export interface ThemeBySegment {
  categoryCounts: {
    issue: number;
    nothing: number;
    other: number;
    total: number;
  };
  code: string;
  count: number;
  impact: number;
  impactPercent: number;
  score: Score;
  sentiment: number;
  sentimentCounts: {
    neg: number;
    neut: number;
    pos: number;
  };
  subthemes?: {
    [key: string]: ThemeBySegment
  };
}

export interface Counts {
  empty: number;
  nothing: number;
  other: number;
  total: number;
}

export interface ThemesBySegmentsData {
  counts: Counts;
  themes: {
    [key: string]: ThemeBySegment
  };
}

export interface ThemesBySegments {
  labels: string[];
  queries: string[];
  themes: ThemesBySegmentsData[];
}

export interface Rows {
  id: string,
  name: string,
}

export interface Dimension {
  data: number;
  id: string;
  name: string;
  sentimentCounts: {
    neg: number;
    neut: number;
    pos: number;
  },
  score?: {
    hist: number[];
    score: number;
    stdev: number;
  },
}

export interface ExportAssistantTableData {
  rowNameOne: string;
  rowNameTwo?: string;
  volume?: number;
  impact?: number;
  score?: number;
  sentimentCounts?: {
    negative: number;
    neutral: number;
    positive: number;
  };
  count?: number;
}

export interface Themes {
  categoryCounts: {
    issue: number;
    nothing: number;
    other: number;
    total: number;
  };
  code: string;
  count: number;
  impact: number;
  impactPercent: number;
  score: Score;
  sentiment: number;
  sentimentCounts: {
    neg: number;
    neut: number;
    pos: number;
  };
  subthemes?: {
    [key: string]: Themes
  };
}

export interface ThemesResponse {
  counts: Counts;
  score: Score;
  scoreAllRows: Score;
  themes: Themes[];
}

export interface FetchOptions {
  commentColumns: number[];
  selectionQueries: {
    baseline: {
      query: string;
      queryWithoutDate: string;
    },
    comparison: {
      query: string;
    }
  };
  segmentQueryStrings: {
    baseline?: string | undefined;
    comparison?: string | undefined;
  };
  requestOptions: Pick<RequestOptions, "dataSource" | "exampleColumns" | "scoreColumn" | "scoreOptions" | "scoreType"> | undefined;
}

interface DimensionsByCategoryData {
  [key: string]: ExportAssistantTableData[]
}

export const ConfigType = {
  THEMES: 'themes',
  DIMENSIONS: 'dimensions',
  THEMES_BY_CATEGORY: 'themes-by-category',
  DIMENSIONS_BY_CATEGORY: 'dimensions-by-category',
} as const;

export interface ExportAssistantConfig {
  baselineCount?: number;
  columnOneTitle: string;
  columnTwoTitle?: string;
  filterTitle: string;
  surveyTitle: string;
}

export interface DefaultConfig extends ExportAssistantConfig {
  tableData: ExportAssistantTableData[] | null;
  type: typeof ConfigType.THEMES | typeof ConfigType.DIMENSIONS;
}

export interface ThemesByCategoryConfig extends ExportAssistantConfig {
  categoryLabels: string[];
  tableData: TableByCategoryThemes | null;
  type: typeof ConfigType.THEMES_BY_CATEGORY;
}

export interface DimensionsByCategoryConfig extends ExportAssistantConfig {
  categoryLabels: string[];
  tableData: DimensionsByCategoryData | null;
  type: typeof ConfigType.DIMENSIONS_BY_CATEGORY;
}

export interface AvailableDateRange {
  startDate: Date | null;
  endDate: Date | null;
}
