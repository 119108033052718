import { VisualizationSection } from 'api/interfaces';
import * as React from 'react';
import { VisualizationAnswerPart } from './VisualizationAnswerPart';
import { FormattedDate } from './FormattedDate';
import './visualization.scss';

interface VisualizationProps {
  content: VisualizationSection;
  createdAt: string;
}

export class Visualization extends React.Component<
  VisualizationProps
> {
  render() {
    const { content, createdAt } = this.props;
    return (
      <section className="answers__visualization">
        <header className="answers__visualization-header">
          <h2>Visualization</h2>
          <FormattedDate utcDate={createdAt}/>
        </header>
        <VisualizationAnswerPart
          content={content} />
      </section>
    );

  }
}
