import { IntegrationType } from 'api/enums';
import { observer } from 'mobx-react';
import * as React from 'react';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

interface DataSourceZendeskCreateFormProps {
  onChange(configuration: object, integrationId: string): void;
}

interface ZendeskConfig {
  type: string;
}

interface DataSourceZendeskCreateFormState {
  config?: ZendeskConfig;
}

@observer
class DataSourceZendeskCreateForm extends React.Component<
DataSourceZendeskCreateFormProps,
DataSourceZendeskCreateFormState
> {
  state = {
    config: { type: 'tickets' }
  } as DataSourceZendeskCreateFormState;

  renderForm(): JSX.Element | null {
    return (
      <p>
        Pull Zendesk Support Tickets
      </p>
    );
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          integrationType={IntegrationType.ZENDESK}
        />
      </div>
    );
  }
}

export default DataSourceZendeskCreateForm;