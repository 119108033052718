import TokenizedInput from 'components/Shared/TokenizedInput';
import * as React from 'react';
import { Form, Radio, TextArea } from 'semantic-ui-react';
import { FilterMatchType } from 'stores/types';
import './free-text-filter.scss';

interface FreeTextFilterProps {
  match: FilterMatchType;
  onChange: (searches: string[], match: FilterMatchType) => void;
  initialState: FreeTextFilterState
}

interface FreeTextFilterState {
  searches: string[];
  inputText: string;
}

class FreeTextFilter extends React.Component<FreeTextFilterProps, FreeTextFilterState> {
  textAreaRef: React.RefObject<TextArea>;

  constructor(props: FreeTextFilterProps) {
    super(props);

    this.state = props.initialState;
    this.textAreaRef = React.createRef();
  }

  componentWillUnmount() {
    const { inputText } = this.state;
    const { match } = this.props;
    const isTextPresent = inputText.trim().length > 0;
    if (match === 'advanced' && isTextPresent) {
      this.updateSearches([inputText]);
    }
  }

  componentDidUpdate(prevProps: FreeTextFilterProps) {
    const { searches } = this.state;
    const { match } = this.props;
    const prevMatch = prevProps.match;

    // if the queryFilter has changed, update the state
    if (prevMatch !== match) {
      // if we are in advanced mode, we need to update the input text from searches
      if (match === 'advanced') {
        const joiner = prevMatch === 'any' ? ' OR ' : ' AND ';
        this.setState({ inputText: searches.join(joiner) });
      }
    }
  }

  updateSelection = (match: FilterMatchType) => {
    const currentMatch = this.props.match;

    let searches = this.state.searches;
    let inputText = '';
    // if we are switching to advanced we can interpret the simple and put it correctly in the advanced search
    if (match === 'advanced') {
      const joiner = currentMatch === 'any' ? ' OR ' : ' AND ';
      inputText = searches.join(joiner);
      searches = inputText.length > 0 ? [inputText] : [];
    }
    this.setState({ inputText });
    this.props.onChange(searches, match);
  };

  updateSearches = (searches: string[]) => {
    const { match } = this.props;
    this.setState({ searches });
    this.props.onChange(searches, match);
  };

  updateInputTextAsSearch = (inputText: string) => {
    this.setState({ inputText });
  }

  render() {
    const { searches, inputText } = this.state;
    const { match } = this.props;

    return (
      <div
        className="free-text-filter"
        aria-label="free text filter"
      >
        <div className="match-buttons">
          <Radio
            label="Match any phrase"
            checked={match === 'any'}
            onChange={() => this.updateSelection('any')}
          />
          <Radio
            label="Match all phrases"
            checked={match === 'all'}
            onChange={() => this.updateSelection('all')}
          />
          <Radio
            label="Boolean search"
            checked={match === 'advanced'}
            onChange={() => this.updateSelection('advanced')}
          />
        </div>
        {match === 'advanced' ? (
          <Form
            className="advanced-search">
            <Form.Field>
              <label>Learn more about the <a href="https://help.getthematic.com/article/128-how-to-use-filters" target="_blank">boolean search terms</a></label>
              <TextArea
                placeholder="Enter search phrase"
                ref={this.textAreaRef}
                onChange={(_e, data) => { this.updateInputTextAsSearch('' + data.value); }}
                autoFocus={true}
                value={inputText}
              />
            </Form.Field>
          </Form>
        ) : (
          <TokenizedInput
            inputText={inputText}
            tokens={searches}
            autoFocus={true}
            placeholder="Enter search phrases"
            onInputTextChange={(newValue) => this.setState({ inputText: newValue })}
            onTokensChange={(newSearches) => this.updateSearches(newSearches)}
          />
        )}
      </div>
    );
  }
}

export default FreeTextFilter;
