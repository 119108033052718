import { User as UserJson, UserSeatType } from 'api/interfaces';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Popup, Table } from 'semantic-ui-react';
import { ManageUsersStoreInterface } from 'stores/ManageUsersStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { userName } from 'stores/utils/manage-users';
import analytics from '../../lib/analytics';
import SeatTypeSelector from './SeatTypeSelector';
import './users.scss';

export interface UserProps extends RouteComponentProps {
  orgId: string;
  user: UserJson;
  manageUsersStore?: ManageUsersStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

export interface UserState {
  reinvited: boolean;
  reinviting: boolean;
}

@inject('manageUsersStore', 'organizationStore')
@observer
class User extends React.Component<UserProps, UserState> {
  state = {
    reinvited: false,
    reinviting: false
  };
  manageUser = () => {
    const { orgId, user } = this.props;
    const url = `/c/${ orgId }/users/${ user.id }`;

    this.props.history.push(url);
  }
  updateSeatType = (userId: string, seatType: UserSeatType) => {
    const { manageUsersStore } = this.props;
    const user = {
      ...this.props.user,
      seatType
    };

    manageUsersStore!.update(user);
  }
  reinvite = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    this.setState({ reinviting: true });
    analytics.track('User Mgmt: User reinvite', { category: 'User Mgmt' });

    await this.props.manageUsersStore!.reinvite(this.props.user.id);

    if (!this.props.manageUsersStore!.reinviteError) {
      this.setState({ reinvited: true });
    }
    this.setState({ reinviting: false });
  };
  renderEmailOnly = () => {
    const { user } = this.props;
    const name = userName(user);
    const { email } = user;

    return (
      <Table.Row className="nw--user-row">
        <Table.Cell
          className="nw--name"
          data-testid="user-name"
        >
          {name}
        </Table.Cell>

        <Table.Cell
          title={email}
          className="nw--email email"
          data-testid="user-email"
        >
          <CopyToClipboard
            textToDisplay={email}
            popupText="Copy email address"
          />
        </Table.Cell>

        <Table.Cell
          className="nw--seat-type"
          data-testid="user-seat-type"
        />

        <Table.Cell
          className="nw--role"
          data-testid="user-role"
        >
          Email Only
        </Table.Cell>

        <Table.Cell
          className="nw--action-last-login"
          data-testid="user-action"
        >
          <Button
            size="small"
            color="blue"
            basic={true}
            data-testid="user-action-button"
            onClick={this.manageUser}
          >
            Create account
          </Button>
        </Table.Cell>

      </Table.Row>
    );
  };

  render(): JSX.Element | null {
    const { user } = this.props;
    const { reinvited, reinviting } = this.state;
    const name = userName(user);
    const { id, lastLogin, roles, seatType } = user;
    let rolesList = roles.map(role => role.name);

    if (user.userSpecificRole) {
      rolesList = [...rolesList, 'Custom Permissions'];
    }

    const rolesToDisplay = rolesList.join(', ');

    const needsToConfirmSeatChange = this.props.organizationStore!.hasMultipleWorkspaces;

    if (!user.invitedAt) {
      return this.renderEmailOnly();
    }

    const reinvitable = user.lastLogin === 'Never';

    return (
      <React.Fragment>
        <Table.Row
          className="nw--user-row"
          onClick={this.manageUser}
        >
          <Table.Cell
            className="nw--name"
            data-testid="user-name"
          >
            {name}
          </Table.Cell>

          <Table.Cell
            className="nw--email email"
            data-testid="user-email"
          >
            <CopyToClipboard
              textToDisplay={id}
              popupText="Copy email address"
            />
          </Table.Cell>

          <Table.Cell className="allow-overflow">
            <SeatTypeSelector
              userId={id}
              existingSeatType={seatType}
              needsToConfirmSeatChange={needsToConfirmSeatChange}
              onSeatChange={this.updateSeatType}
            />
          </Table.Cell>

          <Table.Cell
            className="nw--role"
            data-testid="user-role"
          >
            <Popup
              on="hover"
              position="bottom left"
              content={rolesToDisplay}
              trigger={<p className="roles-list">{rolesToDisplay}</p>}
            />
          </Table.Cell>

          <Table.Cell
            className="nw--action-last-login"
            data-testid="user-action"
          >
            {!reinvitable && lastLogin}
            {reinvitable && (
              <Button
                disabled={reinviting}
                loading={reinviting}
                size="small"
                color="blue"
                basic={true}
                onClick={this.reinvite}
                data-testid="user-action-button"
              >
                {reinvited ? 'Reinvited!' : 'Reinvite'}
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      </React.Fragment>
    );
  }
}

export default withRouter(User);
