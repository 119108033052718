import { isNonSpecialTheme } from "lib/special-theme-predicates";
import {
  isNewlyAddedTheme,
  isThemeRemovedInPopup
} from "vue/libs/edited-comment-helpers";

export default function () {
  return {
    props: {
      segments: { default: () => [], type: Array },
    },
    methods: {
      findPopupThemeItems(block) {

        if (!block.hasThemes) {
          return [];
        }

        return this.segments
          .filter(segment => segment.location[0] === block.location[0] && segment.location[1] === block.location[1])
          .reduce((result, segment) => {

            const nonSpecialThemes = segment.themes.filter(isNonSpecialTheme);

            const themeItems = nonSpecialThemes.map(theme => {
              return {
                theme,
                sentiment: segment.sentiment,
                hasBackgroundColour: false,
                isNew: isNewlyAddedTheme(this.commentId, theme),
                isRemoved: isThemeRemovedInPopup(this.commentId, theme, block)
              };
            });

            return [...result, ...themeItems];

          }, []);
      }
    }
  };
}

