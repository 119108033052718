import { Error } from 'components/Shared/Error';
import * as React from 'react';

export const DashboardError = () => (
  <Error
    title="An unknown error occurred"
    description="Please refresh and try again. If the problem persists, please contact your Thematic representative."
  />
);

