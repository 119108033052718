import { BillingAccount, BillingAccountMetrics } from 'api/interfaces';
import Auth from 'Auth/Auth';
import { action, observable } from 'mobx';

export interface BillingAccountStoreInterface {
  billingAccount?: BillingAccount; // Metrics for each workspace

  fetchAccountError: string;
  fetchAccountMetricsError: string;

  setAccount: (accountId: string) => Promise<BillingAccount | undefined>;
  fetchMetrics: (accountId: string, billingDate: string) => Promise<BillingAccountMetrics | undefined>;
}

class BillingAccountStore implements BillingAccountStoreInterface {
  @observable
  billingAccount?: BillingAccount;

  @observable
  fetchAccountError = '';

  @observable
  fetchAccountMetricsError = '';

  @action
  setAccount = async (accountId: string) => {
    this.fetchAccountError = '';
    if (!accountId) {
      this.billingAccount = undefined;
      return undefined;
    }
    const url = `/account/${ accountId }`;

    try {
      const { ok, data, errorData } = await Auth.fetch<BillingAccount>(url);
      if (ok && data) {
        this.billingAccount = data;
      } else {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.fetchAccountError = `Failed to fetch account: ${ e.message }`;
    }
    return this.billingAccount;
  }

  @action
  fetchMetrics = async (accountId: string, billingDate: string) => {
    this.fetchAccountMetricsError = '';
    if (!accountId) {
      this.fetchAccountMetricsError = 'No billing account set';
      return undefined;
    }
    const url = `/account/${ accountId }/usage?billingDate=${ billingDate }`;

    let metrics;
    try {
      const { ok, data, errorData } = await Auth.fetch<BillingAccountMetrics>(url);
      if (ok && data) {
        metrics = data;
      } else {
        throw new Error(errorData ? errorData.message : 'Please try again');
      }
    } catch (e) {
      this.fetchAccountMetricsError = `Failed to fetch account: ${ e.message }`;
    }
    return metrics;
  }
}

export default BillingAccountStore;
