import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Input, Popup } from 'semantic-ui-react';
import './editable-header.scss';

interface EditableHeaderProps {
  title: string;
  isReadOnly: boolean;
  onChange: (value: string) => void;
}

interface EditableHeaderState {
  showInput: boolean;
  inputValue: string;
}

export class EditableHeader extends React.Component<
  EditableHeaderProps, EditableHeaderState
> {
  headingRef = React.createRef<HTMLHeadingElement>();

  state = {
    inputValue: '',
    showInput: false
  };

  componentDidUpdate(prevProps: Readonly<EditableHeaderProps>): void {
    if (prevProps.title !== this.props.title) {
      this.setState({ inputValue: this.props.title });
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: e.target.value });
  }

  handleKeyPress = (event: React.KeyboardEvent) => {
    const inputValue = this.state.inputValue.trim();
    const nextInputValue = (event.key === 'Escape' || inputValue.length === 0)
      ? this.props.title
      : inputValue;

    if (event.key === 'Enter' || event.key === 'Tab') {
      // event prevent to stop the "tab" key press action
      event.preventDefault();
      this.props.onChange(nextInputValue);
    }

    if (['Escape', 'Enter', 'Tab'].includes(event.key)) {
      this.setState({
        showInput: false,
        inputValue: nextInputValue
      }, () => this.headingRef.current?.focus());
    }
  }

  onTitleClick = (title: string) => {
    this.setState({ showInput: true, inputValue: title });
  }

  handleInputBlur = () => {
    const inputValue = this.state.inputValue.trim();
    this.setState({ showInput: false });
    if (inputValue.length > 0) {
      this.props.onChange(inputValue);
    }
  }

  render() {
    const { inputValue, showInput } = this.state;
    const { title, isReadOnly } = this.props;

    return (
      <>
        {showInput ?
          <Input
            role="textbox"
            type="text"
            className="editable-header__input"
            autoFocus={true}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
            onKeyDown={this.handleKeyPress}
          />
          :
          <section
            className="editable-header__container"
            onKeyDown={(e) => e.key === 'Enter' && !isReadOnly && this.onTitleClick(title)}
            onClick={() => !isReadOnly && this.onTitleClick(title)}
          >
            <Popup
              position="bottom left"
              on={['focus', 'hover']}
              trigger={
                <h3
                  className="editable-header__heading"
                  tabIndex={0}
                  ref={this.headingRef}
                >
                  {title}
                </h3>
              }
              content={title}
            />
            {!isReadOnly &&
              <FontAwesomeIcon
                className="editable-header__icon"
                icon="pencil"
              />
            }
          </section>
        }
      </>
    );
  }
}
