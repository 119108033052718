import { TitleAnswerPartType } from 'api/interfaces';
import * as React from 'react';
import './text-answer-part.scss';

interface TitleProps {
  data: TitleAnswerPartType;
}

const TitleAnswerPart = ({data}: TitleProps) => {
  return <h4>{data.contents}</h4>;
};

export { TitleAnswerPart };
