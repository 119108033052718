import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SavedAnswerMeta } from 'api/interfaces';
import classNames from 'classnames';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import * as React from 'react';
import { Dropdown, DropdownProps, Popup } from 'semantic-ui-react';
import { getDatasetNamesString } from '../utils/getDatasetNamesString';

enum DropdownOptions {
  DELETE = 'Delete',
  DOWNLOAD = 'Download',
  SHARE = 'Share',
}

interface Props {
  metadata: SavedAnswerMeta;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onDelete: (id: string) => void;
  onShare: (id: string) => void;
  onDownload: () => void;
}

const SavedAnswer = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');

  const data = props.metadata;
  const datasetNames = Array.isArray(data.datasetNames)
    ? data.datasetNames
    : data.datasetNames.split(',');

  const itemClass = classNames('saved-answers__item', {
    'saved-answers__item--selected': props.isSelected
  });

  function getDropdownOptions() {
    const canSeeDevOnlyTools = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_DEV_ONLY_TOOLS);

    const shareOption = {
      key: DropdownOptions.SHARE,
      id: props.metadata.id,
      text: DropdownOptions.SHARE,
      value: DropdownOptions.SHARE,
    };

    const deleteOption = {
      key: DropdownOptions.DELETE,
      id: props.metadata.id,
      text: DropdownOptions.DELETE,
      value: DropdownOptions.DELETE,
    };

    const downloadOption = {
      key: DropdownOptions.DOWNLOAD,
      id: props.metadata.id,
      text: DropdownOptions.DOWNLOAD,
      value: DropdownOptions.DOWNLOAD,
    }

    const options = [deleteOption, shareOption];

    if (canSeeDevOnlyTools && props.isSelected) {
      options.push(downloadOption);
    }

    return options;
  }

  function handleKeydown(e: React.KeyboardEvent) {
    switch (e.key) {
      case 'Enter':
      case 'Space':
        props.onSelect(data.id);
        return;
      default:
        return null;
    }
  }

  function handleOptionChange(_event: React.SyntheticEvent<HTMLElement>, dropdownData: DropdownProps) {
    if (typeof dropdownData.value === 'string') {
      setSelectedValue(dropdownData.value);
    }

    if (dropdownData.value === DropdownOptions.DELETE) {
      props.onDelete(props.metadata.id);
    }

    if (dropdownData.value === DropdownOptions.SHARE) {
      props.onShare(props.metadata.id);
    }

    if (dropdownData.value === DropdownOptions.DOWNLOAD) {
      props.onDownload();
    }
  }

  function handleDropdownClose() {
    setSelectedValue('');
  }

  function handleItemMouseOver() {
    setIsOpen(true);
  }
  function handleItemMouseOut() {
    setIsOpen(false);
  }
  function handleItemFocus() {
    setIsOpen(true);
  }
  function handleItemBlur() {
    setIsOpen(false);
  }
  function handleMenuFocus(e: React.FocusEvent) {
    e.stopPropagation();
    setIsOpen(false);
  }
  function handleMenuMouseOver(e: React.MouseEvent) {
    e.stopPropagation();
    setIsOpen(false);
  }

  return (
    <Popup
      position="right center"
      key={data.id}
      open={isOpen}
      trigger={
        <li
          className={itemClass}
          tabIndex={0}
          onKeyDown={(e) => handleKeydown(e)}
          onClick={() => props.onSelect(data.id)}
          onMouseOver={() => handleItemMouseOver()}
          onMouseOut={() => handleItemMouseOut()}
          onFocus={() => handleItemFocus()}
          onBlur={() => handleItemBlur()}
        >
          <div
            className="saved-answers__item-content"
          >
            <h3 className="saved-answers__item-title">
              {data.title}
            </h3>
            <section className="saved-answers__item-datasets">
              {getDatasetNamesString(datasetNames)}
            </section>
          </div>
          <Dropdown
            trigger={
              <FontAwesomeIcon
                className="saved-answers__ellipsis-icon"
                fixedWidth={true}
                icon="ellipsis-v"
              />
            }
            value={selectedValue}
            icon={null}
            button={true}
            direction="left"
            onMouseOver={(e: React.MouseEvent) => handleMenuMouseOver(e)}
            onFocus={(e) => handleMenuFocus(e)}
            className="saved-answers__item-menu"
            options={getDropdownOptions()}
            onChange={handleOptionChange}
            onClose={handleDropdownClose}
            selectOnBlur={false}
            selectOnNavigation={false}
          />
        </li>
      }
      content={data.title}
    />
  );
};

export { SavedAnswer };
