import { Node } from 'types/custom';

export function traverseNodes<A, B>(
  f: (a: Node<A>) => Node<B> | null,
  nodes: Node<A>[]
): Node<B>[] {
  return nodes.flatMap((a: Node<A>): Node<B>[] =>  {

    const updatedNode = f(a);

    if (!updatedNode) {
      return [];
    }

    return [{
      ...updatedNode,
      children: traverseNodes(f, a.children)
    }];

  });
}

export function reduceNodes<A, B>(
  f: (b: B, a: Node<A>) => B,
  nodes: Node<A>[],
  b: B
): B {
  return nodes.reduce(
    (result: B, node: Node<A>) => f(reduceNodes(f, node.children, result), node)
  , b);
}

export function removeNodes<T>(
  predicate: (node: Node<T>) => boolean,
  nodes: Node<T>[]
): Node<T>[] {
  return traverseNodes((node: Node<T>): Node<T> | null => {
    if (predicate(node)) {
      return null;
    }
    return node;
  }, nodes);
}

export function insertNode<T>(
  nodes: Node<T>[],
  node: Node<T>,
  path: number[]
) {

  const [index, ...rest] = path;

  // prevent sparse arrays
  if (index > nodes.length) {
    return;
  }

  // we've found the insertion point
  if (rest.length === 0) {
    nodes.splice(index, 0, node);
  } else if (nodes[index]) {
    insertNode(nodes[index].children, node, rest);
  }

}

export function getNode<T> (
  nodes: Node<T>[],
  path: number[]
): Node<T> | null {

  const [index, ...rest] = path;

  if (!nodes[index]) {
    return null;
  }

  if (rest.length === 0) {
    return nodes[index];
  } else {
    return getNode(nodes[index].children, rest);
  }

}

export function insertSiblingsAfter<T>(
  predicate: (node: Node<T>) => boolean,
  newSiblings: Node<T>[],
  nodes: Node<T>[]
): Node<T>[] {

  return nodes.reduce((bs, a) => {

    const b = {
      ...a,
      children: insertSiblingsAfter(predicate, newSiblings, a.children)
    };

    return predicate(a)
      ? [...bs, b, ...newSiblings]
      : [...bs, b];

  }, []);

}
