import { UploadType } from 'api/enums';
import { SurveyUpload } from 'api/interfaces';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { Button, Modal, Popup, Table } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import './view-history-modal.scss';

interface ViewUploadHistoryModalStoreProps {
  setupStore?: SetupStoreInterface;
}

export interface ViewUploadHistoryModalProps extends ViewUploadHistoryModalStoreProps {
  survey: LiteSurvey;
  onClose: () => void;
}

interface ViewUploadHistoryModalState {
  uploads: SurveyUpload[];
}

@inject('setupStore', 'integrationsStore')
@observer
class ViewUploadHistoryModal extends React.Component<ViewUploadHistoryModalProps, ViewUploadHistoryModalState> {
  state = {
    uploads: []
  } as ViewUploadHistoryModalState;

  componentDidMount() {
    const { setupStore, survey } = this.props;
    setupStore!.getSurveyHistory(survey.surveyId).then(uploads => {

      // filter to only the job types we want
      const uploadsToShow = uploads.filter(upload => {
        return upload.job_type === UploadType.INITIALDATA ||
          upload.job_type === UploadType.NEWDATA
          ;
      });

      this.setState({ uploads: uploadsToShow });
    });
  }

  renderStatusCell(status?: string, message?: string) {
    status = status?.substring(20);
    return (
      <Popup
        position="right center"
        hoverable={true}
        mouseEnterDelay={200}
        mouseLeaveDelay={100}
        trigger={
          <div className={`processing-status ${ status }`} >
            {status ? status : 'No data'}
          </div>
        }
      >
        <Popup.Content>
          {message ? message : 'No information available'}
        </Popup.Content>
      </Popup>
    );
  }

  renderJobTypeCell(jobType: UploadType) {
    if (jobType === UploadType.INITIALDATA) {
      return 'Initial data';
    } else if (jobType === UploadType.NEWDATA) {
      return 'Incremental data';
    }
    return '';
  }

  renderHistory() {
    const { uploads } = this.state;

    return (
      <div>
        <h2>Most recent history</h2>
        <div className="history-table">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Upload Type
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {uploads.map(run => {
                return (
                  <Table.Row key={run.id}>
                    <Table.Cell>{moment(run.created).format('hh:mm D MMM YYYY')}</Table.Cell>
                    <Table.Cell>{this.renderJobTypeCell(run.job_type)}</Table.Cell>
                    <Table.Cell>{this.renderStatusCell(run.status, '')}</Table.Cell>
                  </Table.Row>);
              }
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }

  render() {
    const { onClose, setupStore } = this.props;
    const { uploads } = this.state;

    return (
      <Modal
        open={true}
        onClose={onClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header className="modal-header">
          View dataset history
        </Modal.Header>
        <Modal.Content className="view-history-modal">
          {setupStore!.fetchingSurveyUploads ? (
            <div>
              Retrieving history...
            </div>
          ) : !uploads || uploads.length === 0 ? (
            <div>
              No history recorded!
            </div>
          ) : (
            this.renderHistory()
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ViewUploadHistoryModal;
