import * as React from 'react';
import { Column, Columns, ThemesByCategoryConfig } from './types';
import { Table } from 'semantic-ui-react';
import { ExportAssistantCategoryData } from './ExportAssistantCategoryData';

interface Props {
  columns: Column[];
  tableConfig: ThemesByCategoryConfig;
}

const ExportAssistantTableThemesByCategory = ({ columns, tableConfig }: Props) => {
  const { columnOneTitle, columnTwoTitle } = tableConfig;
  const showCount = columns.includes(Columns.count);
  const showImpact = columns.includes(Columns.impact);
  const showScore = columns.includes(Columns.score);
  const showSentimentCounts = columns.includes(Columns.sentimentCounts);
  const showVolume = columns.includes(Columns.volume);

  const headerColumnWidth = showSentimentCounts ? columns.length + 2 : columns.length;

  if (!tableConfig.tableData) {
    return null;
  }

  return (
    <div className="export-assistant__table">
      <div className="export-assistant-category-table">
      <Table size="small" compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2" />
              {tableConfig.categoryLabels?.map((dateLabel) => (
                <Table.HeaderCell key={`${dateLabel}`} colSpan={headerColumnWidth}>
                  {dateLabel}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              {columnOneTitle && <Table.HeaderCell>{columnOneTitle}</Table.HeaderCell>}
              {columnTwoTitle && <Table.HeaderCell>{columnTwoTitle}</Table.HeaderCell>}
              {tableConfig.categoryLabels?.map((dateLabel) => (
                <React.Fragment key={dateLabel}>
                  {showCount && <Table.HeaderCell>Count</Table.HeaderCell>}
                  {showVolume && <Table.HeaderCell>Volume</Table.HeaderCell>}
                  {showImpact && <Table.HeaderCell>Impact</Table.HeaderCell>}
                  {showScore && <Table.HeaderCell>Score</Table.HeaderCell>}
                  {showSentimentCounts && (
                    <>
                      <Table.HeaderCell>Positive</Table.HeaderCell>
                      <Table.HeaderCell>Neutral</Table.HeaderCell>
                      <Table.HeaderCell>Negative</Table.HeaderCell>
                    </>
                  )}
                </React.Fragment>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.entries(tableConfig.tableData).map(([baseThemeKey, baseThemeValue]) => {
              return (
                <React.Fragment key={baseThemeKey}>
                  <Table.Row>
                    {columnOneTitle && (
                      <Table.Cell className="export-assistant-category-table__row-name">{baseThemeKey}</Table.Cell>
                    )}
                    <Table.Cell className="export-assistant-category-table__row-name" />
                    {tableConfig.categoryLabels?.map((dateLabel) => {
                      const dateData = baseThemeValue[dateLabel];
                      return (
                        <ExportAssistantCategoryData
                          key={`${dateLabel}-${baseThemeKey}`}
                          columns={columns}
                          data={dateData}
                        />
                      );
                    })}
                  </Table.Row>
                  {Object.entries(baseThemeValue?.subthemes).map(([subThemeKey, subThemeValue]) => {
                    return (
                      <Table.Row key={`${baseThemeKey}-${subThemeKey}`}>
                        {columnOneTitle && (
                          <Table.Cell className="export-assistant-category-table__row-name">{baseThemeKey}</Table.Cell>
                        )}
                        {columnTwoTitle && (
                          <Table.Cell className="export-assistant-category-table__row-name">{subThemeKey}</Table.Cell>
                        )}
                        {tableConfig.categoryLabels?.map((dateLabel) => {
                          const dateData = subThemeValue?.[dateLabel];

                          return (
                            <ExportAssistantCategoryData
                              key={`${dateLabel}-${subThemeKey}}`}
                              columns={columns}
                              data={dateData}
                            />
                          );
                        })}
                      </Table.Row>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export { ExportAssistantTableThemesByCategory }
