import React from 'react';
import { Input, Popup } from 'semantic-ui-react';
import { Block } from 'lib/segments-to-blocks';
import { TreeView, TreeViewItem } from 'components/Shared/TreeView';
import './theme-dropdown.scss';
import { AddedTheme } from 'stores/ui/QuickEditUIStore';
import { AddThemeBaseItem } from './types';
import { Button } from 'components/Shared/Button';

interface Props {
  commentId: string;
  blocks?: Block[];
  onAddNewTheme: (newTheme: string) => void;
  onClose: () => void;
  onThemeSelect: (theme: AddedTheme) => void;
  open: boolean;
  preSelectedPhrase: string;
  themes: AddThemeBaseItem[];
  themeDropdownRef: React.RefObject<HTMLDivElement>;
}

const ThemeDropdown = ({ onAddNewTheme, onClose, onThemeSelect, open, themes, themeDropdownRef }: Props) => {
  const [filterText, setFilterText] = React.useState<string>('');
  const [filteredListIsEmpty, setFilteredListIsEmpty] = React.useState<boolean>(false);

  const handleFilterChange = (text: string) => {
    setFilterText(text);
  };

  const onClickOutside = () => {
    onClose();
  };

  const getTreeItems = (): TreeViewItem[] => {
    return themes.map((theme) => ({
      id: theme.id,
      label: theme.label,
      children: theme?.children.map((childTheme) => ({
        id: childTheme.id,
        label: childTheme.label,
      })),
    }));
  };

  const treeItems = getTreeItems();

  const handleItemClick = (themeId: string) => {
    const [baseId, subId] = themeId.split(':');

    const baseTheme = themes.find((theme) => theme.id === baseId);

    if (!baseTheme) {
      return
    }

    if (!subId) {
      onThemeSelect(baseTheme);
    }

    const subTheme = baseTheme?.children.find((theme) => theme.sub === subId);

    if (subTheme) {
      onThemeSelect(subTheme);
    }
  };

  const handleEmptyStateChange = (filteredTreeIsEmpty: boolean) => {
    setFilteredListIsEmpty(filteredTreeIsEmpty);
  };

  const showNewThemeButton = !filterText || (filteredListIsEmpty && filterText);

  React.useEffect(() => {
    if (!open || !themeDropdownRef.current) {
      return;
    }

    const searchInput = themeDropdownRef.current.querySelector('[placeholder="Search themes"]');

    if (searchInput) {
      (searchInput as HTMLInputElement).focus({ preventScroll: true });
    }
  }, [open, themeDropdownRef]);

  return (
    <div id="theme-dropdown-popover" className="theme-dropdown">
      <Popup
        basic={true}
        open={open}
        position={'bottom left'}
        onClose={onClickOutside}
        content={{
          children: () => (
            <div className="theme-dropdown__content" ref={themeDropdownRef} tabIndex={0}>
              <div className="theme-dropdown__input">
                <Input
                  fluid={true}
                  icon="search"
                  onChange={(e) => handleFilterChange(e.target.value)}
                  placeholder="Search themes"
                  size="small"
                  value={filterText}
                />
              </div>
              <TreeView
                filter={filterText}
                items={treeItems}
                onEmptyStateChange={handleEmptyStateChange}
                onItemClick={handleItemClick}
              />
              {showNewThemeButton && (
                <div>
                  <Button block={true} onClick={() => onAddNewTheme(filterText)} size="medium" subvariant="white" variant="secondary">
                    { filterText ? `New theme "${filterText}` : 'New theme' }
                  </Button>
                </div>
              )}
            </div>
          )
        }}
        trigger={<span></span>}
      />
    </div>
  );
};

export { ThemeDropdown };
