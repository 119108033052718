import { FilterType } from 'api/enums';
import { AnalysisViewSource, SegmentFilters } from 'api/interfaces';
import { RequestOptions } from 'stores/InitConfigStore';
import { getAnalysisConfigStore, getAnalysisToolsUIStore, getFilterStore, getInitConfigStore, getSegmentStore, getSurveyStore, getTagStore, getThemesStore } from 'stores/RootStore';
import { toConfigUrl } from 'stores/surveys/config-parser';
import { getAnalysisId } from 'stores/utils/analysis-helper';

const getAnalysisConfig = () => {
  const analysisToolsUIStore = getAnalysisToolsUIStore();
  const { isFeedbackTool, showSentiment } = analysisToolsUIStore;

  return { isFeedbackTool, showSentiment };
};

const getCanCreatePublicSavedFilters = (surveyId: string) => {
  const surveyStore = getSurveyStore();

  return surveyStore.getSurveyCanAction(surveyId, 'manage:survey');
};

const getCommentColumns = (): number[] => {
  const initConfigStore = getInitConfigStore();
  const analysisConfigStore = getAnalysisConfigStore();

  const initConfigColumns = initConfigStore.commentColumns;
  const analysisConfigColumns = analysisConfigStore.config?.comment_columns;

  if (initConfigColumns.length > 0) {
    return initConfigColumns;
  }

  if (analysisConfigColumns) {
    return [analysisConfigColumns].flat();
  }

  return [];
};

const getInitConfig = async (configUrl: string) => {
  const initConfigStore = getInitConfigStore();

  initConfigStore.setConfigLocation(configUrl);

  try {
    await initConfigStore.getConfig();

    return {
      commentColumns: initConfigStore.commentColumns,
      requestOptions: initConfigStore.requestOptions,
    };
  } catch (error) {
    return error;
  }
};

export const initializeFilters = async (
  source: AnalysisViewSource,
  disabledFilterTypes: FilterType[],
  selectedFilters?: SegmentFilters
) => {
  const analysisConfigStore = getAnalysisConfigStore();
  const segmentStore = getSegmentStore();
  const filterStore = getFilterStore();
  const shouldInitThemes = !disabledFilterTypes.includes(FilterType.THEMES);

  const configUrl = toConfigUrl(
    source.survey,
    source.visualization,
    source.view
  );

  try {
    filterStore?.resetFilters();

    // Replace any loaded saved filters with a set related to the current dataset
    segmentStore.resetSelectedSegment();
    segmentStore?.setDatasetIdentifiers({ surveyId: source.survey, viewId: source.view });

    // Ensure the relevant themes are available for filtering, if necessary
    if (shouldInitThemes) {
      const { commentColumns, requestOptions } = await getInitConfig(configUrl);
      await initThemesHierarchy(commentColumns, requestOptions);
    }

    // Set the other filter options
    await analysisConfigStore?.getConfig(source);
    const analysisId = getAnalysisId(source);
    const config = analysisConfigStore?.configs[analysisId];

    if (config?.filters) {
      analysisConfigStore?.setConfig(config);
      const filtersToDisplay = config.filters.filter((filter) => !filter.type || !disabledFilterTypes.includes(filter.type));
      filterStore?.setFilterConfig(filtersToDisplay);
    }

    if (selectedFilters) {
      segmentStore?.applySegment('baseline', selectedFilters);
    }
  } catch (e) {
    console.error(e);
  }
};

const getThemes = (configUrl: string) => {
  const themesStore = getThemesStore();
  const initConfigStore = getInitConfigStore();

  initConfigStore.setConfigLocation(configUrl);

  initConfigStore
    .getConfig()
    .then(() => {
      themesStore.initThemesHierarchy(initConfigStore.requestOptions, initConfigStore.commentColumns);
    })
    .catch(() => {
      console.error(initConfigStore.configError);
    });

  return themesStore.themesHierarchy ?? {};
};

const getThemesHierarchy = () => {
  const themesStore = getThemesStore();

  return themesStore.themesHierarchy ?? {};
};

const getSortedTags = () => {
  const tagStore = getTagStore();
  const { sortedTags } = tagStore;

  if (!sortedTags) {
    tagStore.getTags();
  }

  return sortedTags;
};

const initThemesHierarchy = async (commentColumns: number[], requestOptions: RequestOptions) => {
  const themesStore = getThemesStore();

  try {
    await themesStore.initThemesHierarchy(requestOptions, commentColumns);
  } catch (error) {
    return error;
  }
};

export {
  getAnalysisConfig,
  getCanCreatePublicSavedFilters,
  getCommentColumns,
  getInitConfig,
  getSortedTags,
  getThemes,
  getThemesHierarchy,
  initThemesHierarchy
};
