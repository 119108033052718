import auth from 'Auth/Auth';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import NavBar from 'components/NavBar/NavBar';
import UserSubscriptions from 'components/Subscription/UserSubscriptions';
import CreateRefreshTokenModal from 'components/User/CreateRefreshTokenModal';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Card, Image, Segment } from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';
import UserRefreshTokens from './UserRefreshTokens';

interface UserProfileState {
  showRefreshTokenModal: boolean;
}

interface UserProfileProps {
  showReset: () => void;
  userStore?: UserStoreInterface;
}

@inject('userStore')
@observer
class UserProfile extends React.Component<UserProfileProps, UserProfileState> {
  state = {
    showRefreshTokenModal: false
  };

  componentDidMount = () => {
    analytics.track('User Mgmt: View user settings', { category: 'User Mgmt' });
  };
  showReset = () => {
    auth.showReset();
  };

  showCreateRefreshToken = () => {
    this.setState({ showRefreshTokenModal: true });
  };

  hideCreateRefreshToken = () => {
    this.setState({ showRefreshTokenModal: false });
  };

  render(): JSX.Element | null {
    const { user } = this.props.userStore!;
    const { showRefreshTokenModal } = this.state;
    return (
      <div>
        <AuthenticatedRoute exact={true} path="/profile" component={NavBar} />
        <Segment>
          {user && (
            <Card style={{ minWidth: 300, width: 'auto' }}>
              <Card.Content>
                <Image
                  rounded={true}
                  src={user.picture}
                  size="mini"
                  floated="right"
                />
                <Card.Header>
                  {user.firstName +
                    ' ' +
                    user.lastName +
                    (user.preferredName ? ' (' + user.preferredName + ')' : '')}
                </Card.Header>
                <Card.Meta>{user.email}</Card.Meta>
                <Card.Description />
              </Card.Content>
              <Card.Content extra={true}>
                <Button size="small" onClick={this.showReset}>
                  Reset password
                </Button>
              </Card.Content>
              <Card.Content extra={true}>
                {!showRefreshTokenModal && (
                  <Button size="small" onClick={this.showCreateRefreshToken}>
                    Generate Refresh Token
                  </Button>
                )}
                {showRefreshTokenModal && (
                  <CreateRefreshTokenModal
                  />
                )}
              </Card.Content>
            </Card>
          )}
        </Segment>
        <UserSubscriptions />
        <UserRefreshTokens />
      </div>
    );
  }
}

export default UserProfile;
