import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classNames from 'classnames';
import * as React from 'react';
import { getFeatureFeedbackStore } from 'stores/RootStore';
import { FeatureFeedbackModal } from './FeatureFeedbackModal';
import './feature-feedback-by-thumbs.scss';
import { FeatureFeedbackTextConfig, FeedbackValue } from './types';
import { Button } from 'components/Shared/Button';
import { Tooltip } from 'components/Shared/Tooltip';
import { PopupProps } from 'semantic-ui-react';

interface FeatureFeedbackByThumbsProps {
  askForDescription?: boolean;
  textConfig: FeatureFeedbackTextConfig;
  featureId: string;
  location: string;
  data?: any;
  onFeedbackModalUnmount?: () => void;
  tooltipPosition?: PopupProps['position'];
}

interface FeatureFeedbackByThumbsState {
  feedbackValue: FeedbackValue | null;
  hideReviewControls: boolean;
  hideReviewDescription: boolean;
  showDescriptionPopUp: boolean;
  rating?: number;
}

class FeatureFeedbackByThumbs extends React.Component<FeatureFeedbackByThumbsProps> {
  static defaultProps = {
    location: '',
    tooltipPosition: 'top center',
  };

  state: FeatureFeedbackByThumbsState = {
    feedbackValue: null,
    hideReviewControls: false,
    hideReviewDescription: false,
    showDescriptionPopUp: false,
  };

  clickFeedbackButton(feedbackValue: FeedbackValue) {
    const { askForDescription } = this.props;

    const rating = feedbackValue === 'thumbsUp' ? 5 : 1;

    if (askForDescription) {
      this.setState({ feedbackValue, showDescriptionPopUp: true, rating });
    } else {
      this.submitFeedback(rating);
    }
  }

  resolveFeedbackModalText() {
    const { feedbackValue } = this.state;
    const { textConfig } = this.props;

    if (feedbackValue && textConfig) {
      return textConfig[feedbackValue];
    }

    return undefined;
  }

  submitWithMoreFeedback(description?: string) {
    const { rating } = this.state;
    if (rating) {
      this.submitFeedback(rating, description);
    }
    this.setState({ showDescriptionPopUp: false });
  }

  submitFeedback(rating: number, description = '') {
    const { featureId, location, data } = this.props;

    const feedbackStore = getFeatureFeedbackStore();
    feedbackStore.sendFeedback(featureId, location, rating, description, data);

    this.setState({ hideReviewControls: true });
    setTimeout(() => {
      this.setState({ hideReviewDescription: true });
    }, 5000);
  }

  render() {
    const { textConfig } = this.props;
    const { hideReviewControls, hideReviewDescription, showDescriptionPopUp } = this.state;

    return (<>
      {!hideReviewControls ? (
        <span
          className={classNames(
            'review-controls-container',
            { 'review-controls-container--hide': hideReviewControls }
          )}
        >
          <Tooltip
            content={textConfig?.positivePopupMessage}
            disabled={!textConfig?.positivePopupMessage}
            position={this.props.tooltipPosition}
            inverted={true}
            on={['hover', 'focus']}
            trigger={
              <span>
                <Button
                  role="button"
                  variant="tertiary"
                  size="small"
                  ariaLabel={textConfig?.positivePopupMessage}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.clickFeedbackButton('thumbsUp')
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && this.clickFeedbackButton('thumbsUp')}
                  icon={
                    <FontAwesomeIcon
                      className="review-icon thumbs-up"
                      icon="thumbs-up"
                      aria-hidden={false}
                      fixedWidth={true}
                    />
                  }
                />
              </span>
            }
          />
          <Tooltip
            content={textConfig?.negativePopupMessage}
            disabled={!textConfig?.negativePopupMessage}
            position={this.props.tooltipPosition}
            inverted={true}
            on={['hover', 'focus']}
            trigger={
              <span>
                <Button
                  role="button"
                  variant="tertiary"
                  size="small"
                  ariaLabel={textConfig?.positivePopupMessage}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.clickFeedbackButton('thumbsDown')
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && this.clickFeedbackButton('thumbsDown')}
                  icon={
                    <FontAwesomeIcon
                      className="review-icon thumbs-down"
                      icon="thumbs-down"
                      aria-hidden={false}
                      fixedWidth={true}
                    />
                  }
                />
              </span>
            }
          />
        </span>) : (
        <span
          className={classNames(
            'review-description', {
            'hide': hideReviewDescription,
          })}
        >
          Thank you for your feedback
        </span>
      )}
      <FeatureFeedbackModal
        isOpen={showDescriptionPopUp}
        onCancel={() => this.submitWithMoreFeedback()}
        onSubmit={(description) => this.submitWithMoreFeedback(description)}
        textConfig={this.resolveFeedbackModalText()}
        onUnmount={this.props.onFeedbackModalUnmount}
      />
    </>);
  }
}

export default FeatureFeedbackByThumbs;
