import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateResolution, WorkflowActionType } from 'api/enums';
import { WorkflowHistory } from 'api/workflow-interfaces';
import EmailAction, { EmailsObject } from 'components/Workflows/components/EmailAction';
import SlackAction from 'components/Workflows/components/SlackAction';
import {
  ACTION_OPTIONS,
  DAYS, MWD_DATE_INTERVAL_OPTIONS, TIME, WEEK_DAYS
} from 'components/Workflows/constants';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { Cancelable, debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { Button, Checkbox, Dropdown, Loader, Message, Popup, Table } from 'semantic-ui-react';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';

import './data-alerting.scss';

interface DataAlertingModalStoreProps {
  surveyStore?: SurveyStoreInterface;
  integrationsStore?: IntegrationsStoreInterface;
}

export interface DataAlertingModalProps extends DataAlertingModalStoreProps {
  surveyId: string;
}

interface DataAlertingModalState {
  alertingEnabled: boolean;
  configCorrect: boolean;

  selectedInterval: string;
  selectedIntervalOffset: number;

  selectedThreshold: number;

  selectedNotifyType: string;
  enteredEmailValidation: boolean;
  enteredEmailValue: string;
  enteredEmails: string[];
  selectedSlackIntegration?: string;
  selectedSlackChannel?: string;

  loadingHistory: boolean;
  history?: WorkflowHistory[];
}

@inject('setupStore', 'integrationsStore', 'surveyStore')
@observer
class DataAlertingModal extends React.Component<DataAlertingModalProps, DataAlertingModalState> {
  debouncedSaveDataAlerting: (() => void) & Cancelable;

  state = {
    alertingEnabled: false,

    selectedInterval: MWD_DATE_INTERVAL_OPTIONS[0].value,
    selectedIntervalOffset: 0,

    selectedThreshold: 0.8,

    selectedNotifyType: ACTION_OPTIONS[0].value,
    enteredEmails: [],
    configCorrect: false,
    selectedSlackChannel: '',
    enteredEmailValidation: false,
    enteredEmailValue: '',

    loadingHistory: false,
  } as DataAlertingModalState;

  componentDidMount() {
    this.initalizeData();
    this.loadHistory();
  }
  initalizeData = async () => {
    const { surveyId, surveyStore } = this.props;
    const survey = await surveyStore?.getSurvey(surveyId);

    this.debouncedSaveDataAlerting = debounce(this.saveDataAlerting, 1000, { trailing: true });
    // initalize the form with the values from the survey
    if (survey && survey.dataAlertingWorkflow) {
      this.setState({
        alertingEnabled: true,
        selectedInterval: survey.dataAlertingWorkflow.period,
        selectedIntervalOffset: survey.dataAlertingWorkflow.offset,
        selectedThreshold: survey.dataAlertingWorkflow.warningThreshold,
        selectedNotifyType: survey.dataAlertingWorkflow.notification.type,
        enteredEmails: survey.dataAlertingWorkflow.notification.recipients,
        selectedSlackChannel: survey.dataAlertingWorkflow.notification.recipients.join(', '),
        selectedSlackIntegration: survey.dataAlertingWorkflow.notification.slackIntegrationId,
      });
    }
  }

  componentWillUnmount() {
    if (this.debouncedSaveDataAlerting) {
      this.debouncedSaveDataAlerting.flush();
    }
  }

  loadHistory = async () => {
    this.setState({ loadingHistory: true });
    const { surveyId, surveyStore } = this.props;

    const history = await surveyStore?.fetchDataAlertingHistory(surveyId);
    this.setState({ loadingHistory: false, history });
  }

  determineConfigCorrect = () => {
    const {
      selectedInterval,
      selectedNotifyType,
      enteredEmails,
      selectedSlackIntegration,
      selectedSlackChannel,
      enteredEmailValidation
    } = this.state;
    let configCorrect = !!selectedInterval;

    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      configCorrect = configCorrect && (enteredEmails.length > 0 || enteredEmailValidation);
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      configCorrect = configCorrect && !!selectedSlackIntegration && !!selectedSlackChannel;
    } else {
      configCorrect = false;
    }
    this.setState({ configCorrect }, () => { this.debouncedSaveDataAlerting(); });
  }

  saveDataAlerting = () => {
    const { surveyStore, surveyId } = this.props;
    const {
      alertingEnabled,
      configCorrect,
      selectedInterval,
      selectedIntervalOffset,
      selectedThreshold,
      selectedNotifyType,
      enteredEmails,
      enteredEmailValue,
      enteredEmailValidation,
      selectedSlackIntegration,
      selectedSlackChannel,
    } = this.state;

    if (!alertingEnabled) {
      surveyStore!.removeDataAlerting(surveyId);
      return;
    }

    // bad config, don't update
    if (!configCorrect) {
      return;
    }

    // figure out the recipients
    let recipients = enteredEmails;
    if (selectedNotifyType === 'SLACK') {
      recipients = [selectedSlackChannel!];
    } else if (selectedNotifyType === 'EMAIL' && enteredEmailValidation) {
      recipients.push(enteredEmailValue);
    }

    surveyStore!.setDataAlerting(surveyId, {
      period: selectedInterval as DateResolution,
      offset: selectedIntervalOffset,
      warningThreshold: selectedThreshold,
      notification: {
        type: selectedNotifyType as WorkflowActionType,
        recipients,
        slackIntegrationId: selectedSlackIntegration?.toString(),
      }
    });
  }

  onAlertingEnabledChanged() {
    const { alertingEnabled } = this.state;
    this.setState({ alertingEnabled: !alertingEnabled }, () => { this.determineConfigCorrect(); });
  }

  selectedIntervalChange = (e, { value }) => {
    let defaultSelectedSpikeIntervalOffset = 0;
    if (value === 'weekly' || value === 'monthly') {
      defaultSelectedSpikeIntervalOffset = 1;
    }
    this.setState({
      selectedInterval: value,
      selectedIntervalOffset: defaultSelectedSpikeIntervalOffset
    },
      () => { this.determineConfigCorrect(); }
    );
  }

  selectedIntervalOffsetChange = (e, { value }) => {
    this.setState({ selectedIntervalOffset: value }, () => { this.determineConfigCorrect(); });
  }

  selectedThresholdChange = (e, { value }) => {
    this.setState({ selectedThreshold: value }, () => { this.determineConfigCorrect(); });
  }

  selectedNotifyTypeChange = (e, { value }) => {
    this.setState({ selectedNotifyType: value, enteredEmails: [], selectedSlackChannel: undefined },
      () => { this.determineConfigCorrect(); }
    );
  }

  handleEnteredEmails = (emailsObject: EmailsObject) => {
    this.setState(
      {
        enteredEmails: emailsObject.emails,
        enteredEmailValidation: !!emailsObject.enteredValueValidation
      }, () => {
        if (!!emailsObject.enteredValueValidation && !!emailsObject.enteredValue) {
          this.setState({ enteredEmailValue: emailsObject.enteredValue });
        } else {
          this.setState({ enteredEmailValue: '' });
        }
        this.determineConfigCorrect();
      }
    );
  }

  fetchEnteredEmailDetails = (validation: boolean, enteredValue: string) => {
    // this is so the user can save the created workflow with entered email without accepting it
    this.setState({ enteredEmailValidation: validation }, () => {
      if (validation) {
        this.setState({ enteredEmailValue: enteredValue });
      } else {
        this.setState({ enteredEmailValue: '' });
      }
      this.determineConfigCorrect();
    }
    );
  }

  handleSelectedChannel = (integrationId?: string, channel?: string) => {
    this.setState({
      selectedSlackIntegration: integrationId,
      selectedSlackChannel: channel
    },
      () => { this.determineConfigCorrect(); }
    );
  }

  render() {
    const {
      alertingEnabled,
      selectedInterval,
      selectedIntervalOffset,
      selectedNotifyType,
      enteredEmails,
      selectedSlackChannel,
      selectedThreshold,
      loadingHistory,
      history
    } = this.state;

    const { surveyStore } = this.props;
    const { fetchDataAlertingHistoryError } = surveyStore!;

    // only developers get to see the history button for now
    const canSeeDevTools = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_DEV_ONLY_TOOLS);

    const THRESHOLD_OPTIONS = [
      {
        key: '50',
        text: '50%',
        value: 0.5,
      },
      {
        key: '80',
        text: '80%',
        value: 0.8,
      },
      {
        key: '90',
        text: '90%',
        value: 0.9,
      },
      {
        key: '95',
        text: '95%',
        value: 0.95,
      }
    ];

    return (
      <div className="data-alerting-form">
        <Checkbox
          label="Enable Alerting"
          checked={alertingEnabled}
          onChange={() => this.onAlertingEnabledChanged()}
        />
        {alertingEnabled && (
          <div>
            <div className="options-row">
              <span className="first-column">Data is expected</span>
              <Dropdown
                selection={true}
                options={MWD_DATE_INTERVAL_OPTIONS}
                placeholder="Select"
                value={selectedInterval}
                selectOnBlur={false}
                onChange={this.selectedIntervalChange}
              />
              {selectedInterval === 'weekly' &&
                <span>
                  <span className="space-span">by</span>
                  <Dropdown
                    selection={true}
                    options={WEEK_DAYS}
                    value={selectedIntervalOffset}
                    onChange={this.selectedIntervalOffsetChange}
                  />
                </span>
              }
              {selectedInterval === 'monthly' &&
                <span>
                  <span className="space-span">by the</span>
                  <Dropdown
                    selection={true}
                    options={DAYS}
                    value={selectedIntervalOffset}
                    onChange={this.selectedIntervalOffsetChange}
                  />
                </span>
              }
              {selectedInterval === 'daily' &&
                <span>
                  <span className="space-span">by</span>
                  <Dropdown
                    selection={true}
                    options={TIME}
                    value={selectedIntervalOffset}
                    onChange={this.selectedIntervalOffsetChange}
                  />
                </span>
              }

            </div>
            <div className="options-row">
              <span className="first-column">Warn if new data drops below</span>
              <Dropdown
                selection={true}
                options={THRESHOLD_OPTIONS}
                value={selectedThreshold}
                onChange={this.selectedThresholdChange}
              />
              <span className="space-span">of the previous average</span>
            </div>
            <div className="options-row">
              <span className="first-column">Notify via</span>
              <Dropdown
                selection={true}
                options={ACTION_OPTIONS}
                value={selectedNotifyType}
                onChange={this.selectedNotifyTypeChange}
              />
              {selectedNotifyType === WorkflowActionType.EMAIL && (
                <EmailAction
                  existingEmails={enteredEmails}
                  enteredEmails={this.handleEnteredEmails}
                />
              )}
              {selectedNotifyType === WorkflowActionType.SLACK && (
                <SlackAction
                  existingSlackChannel={selectedSlackChannel}
                  selectedSlackChannel={this.handleSelectedChannel}
                />
              )}
            </div>
            {canSeeDevTools && (<div className="options-row">

              <Popup
                position="top left"
                popper={<div style={{ filter: 'none' }} />}
                on="click"
                trigger={
                  <Button size="small">
                    History
                  </Button>
                }>
                <div>
                  {loadingHistory ? (
                    <Loader active={true} inline="centered" />
                  ) : (
                    fetchDataAlertingHistoryError ? (
                      <Message negative={true}>
                        <FontAwesomeIcon className="icon" icon="exclamation-triangle" fixedWidth={true} />
                        Something went wrong getting run history
                      </Message>
                    ) :
                      history && (
                        <div>
                          <Table striped={true}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>
                                  Date
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  Details
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  Ran successfully
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {history.map((entry, index) => {
                                return (
                                  <Table.Row key={index} verticalAlign="middle">
                                    <Table.Cell>
                                      {moment(entry.created).format('YYYY-MM-DD')}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {entry.statusDetails}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {entry.status === 'completed' ? (
                                        <FontAwesomeIcon className="icon workflow-history-status-success" icon="check-circle" fixedWidth={true} />
                                      ) : (
                                        <FontAwesomeIcon className="icon workflow-history-status-errored" icon="exclamation-triangle" fixedWidth={true} />
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                            </Table.Body>
                          </Table>
                        </div>
                      ))}
                </div>
              </Popup>
            </div>)}
          </div>
        )}
      </div>

    );
  }
}

export default DataAlertingModal;
