import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { compact, each, map, memoize, reduce } from 'lodash';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';
import { FilterSearchResult, SearchFilterCut } from './filter-search-helper';

export type FilterOption = {
  id: string;
  parentId?: string;
  name: string;
  children?: FilterOption[];
  isSelected: boolean;
  fullName?: string;
  individualId?: string;
  rql?: string;
};

export const findSelectedFilter = (options: FilterOption[], cutId: string) => {
  // finding the selected cut from the hierarchical option
  let cut;
  each(options, (option) => {
    if (option.id === cutId) {
      cut = option;
      // when returning false it will stop the loadash each loop
      return false;
    } else if (option.children) {
      const selection = findSelectedFilter(option.children, cutId);
      if (selection) {
        cut = selection;
      }
    }
    return;
  });
  return cut;
};

export const getParentSelectionStateFromChildren = (parent: FilterOption) => {
  // to find the parent selection base on how many children have been selected
  if (parent.children?.length && parent.children.every((child) => child.isSelected)) {
    return 'all';
  } else if (parent.children?.length && parent.children.some((child) => child.isSelected)) {
    return 'some';
  } else {
    if (parent.children) {
      const selected = parent.children?.some(child => {
        const selectionState = getParentSelectionStateFromChildren(child);
        return selectionState === 'some';
      });
      if (selected) {
        return 'some';
      }
      return 'none';
    }
    return 'none';
  }
};

const buildRql = (option: FilterOption, rqlMap: Record<string, string>) => {
  const parentSelection = getParentSelectionStateFromChildren(option);
  const rql = rqlMap[option.id];

  if (parentSelection === 'some') {
    const childRqls = compact(map(option.children, opt => buildRql(opt, rqlMap)));

    if (childRqls.length) {
      return `(${ rql };(${ childRqls.join(',') }))`;
    } else {
      return rql;
    }
  } else if (parentSelection === 'all') {
    return rql;
  } else if (parentSelection === 'none' && option.isSelected) {
    return rql;
  } else {
    return;
  }
};

const calculateHierarchicalFilterRql = (options: FilterOption[], rqlMap: Record<string, string>) => {

  const queries = reduce(
    options,
    (result: string[], option) => {
      const query: string = buildRql(option, rqlMap);
      if (query) {
        result.push(query);
      }
      return result;
    },
    []
  );
  return queries.join(',');
};

const createNestedOptions = (
  cut: AnalysisFilterCut,
  queryFilter?: QueryFilter,
  parentCut?: FilterOption
) => {
  // creating options that are needed to display in dropdown
  const { id, name, rql } = cut;
  const selected = queryFilter?.selected;
  let isSelected = !!selected?.length && selected.some((s) => s.id === cut.id);
  let isNestedParentSelected = false;
  if (parentCut) {
    // if we are receving a parent cut then the id of this cut will be parentCut + cut.id
    isSelected = !!selected?.length && selected.some((s) => s.id === (parentCut.id + cut.id));
    isNestedParentSelected = parentCut.isSelected;
  }
  const element: FilterOption = {
    individualId: id || '',
    id: parentCut?.id ? parentCut.id + id : id || '',
    fullName: parentCut?.fullName ? `${ parentCut.fullName }: ${ name }` : name,
    name,
    rql,
    isSelected: isSelected || isNestedParentSelected
  };

  if (cut.children) {
    element.children = map(cut.children.cuts, childCut => createNestedOptions(childCut, queryFilter, element));
  }
  return element;
};

export const createHierarchicalFilterOptions = (
  cuts: AnalysisFilterCut[],
  queryFilter?: QueryFilter,
): FilterOption[] => {
  return map(cuts, cut => createNestedOptions(cut, queryFilter));
};

export const getRqlMap = memoize((filter: FilterOption[]) =>
  filter.reduce<Record<string, string>>((rqlMap, cut) => {
    if (cut.id) {
      rqlMap[cut.id] = cut.rql || '';
      if (cut.children) {
        const childRqls = getRqlMap(cut.children);
        rqlMap = { ...rqlMap, ...childRqls };
      }
    }
    return rqlMap;
  }, {}),
);

const recursiveHierarchicalQueryFilter = (options: FilterOption[]) => {
  // this method to find all the selected child options
  let selected: AnalysisFilterCut[] = [];
  for (let i = 0; i < options.length; i++) {
    const parent = options[i];
    if (parent.children && parent.children.length > 0) {
      const parentSelection = getParentSelectionStateFromChildren(parent);

      if (parentSelection === 'all') {
        selected.push({ id: parent.id, name: parent.name, rql: '' });
      } else if (parentSelection === 'some' && parent.children) {
        const doesChildrenHaveChildren = parent.children.some(child => child.children);
        if (doesChildrenHaveChildren) {
          // if childrens have children then same method will be repeated here
          const selectedOptions = recursiveHierarchicalQueryFilter(parent.children);
          selected = [...selected, ...selectedOptions];
        } else {
          const selectedOptions = parent.children
            .filter((child) => child.isSelected)
            .map((child) => ({ id: child.id, name: child.name, rql: '' }));
          selected.push(...selectedOptions);
        }
      }
    } else {
      if (parent.isSelected) {
        selected.push({ id: parent.id, name: parent.name, rql: '' });
      }
    }
  }
  return selected;
};

export const createHierarchicalQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  options: FilterOption[],
): QueryFilter => {
  const selected: AnalysisFilterCut[] = [];
  const rqlMap = getRqlMap(options);
  const combinedRql = calculateHierarchicalFilterRql(options, rqlMap);

  for (let i = 0; i < options.length; i++) {
    const parent = options[i];
    const parentSelection = getParentSelectionStateFromChildren(parent);

    if (parentSelection === 'all' || parent.isSelected) {
      selected.push({ id: parent.id, name: parent.name, rql: '' });
    } else if (parent.children) {
      const selectedOptions = recursiveHierarchicalQueryFilter(parent.children);
      selected.push(...selectedOptions);
    }
  }

  if (selected.length > 0) {
    selected[0].rql = combinedRql;
  }

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'hierarchical',
    selected,
  };
};

export const selectAllChildren = (child: FilterOption, selected: boolean) => {
  // to select all the children of any option that has been selected and has children array
  if (child.children) {
    return {
      ...child,
      isSelected: selected,
      children: child.children?.map((c) => selectAllChildren(c, selected)),
    };
  } else {
    return {
      ...child,
      isSelected: selected,
    };
  }
};

export const getHierarchicalFilterChildResults = (childOptions: FilterOption[], regex: RegExp) => {
  // this method returns the child result which includes seached term
  let newOptions: FilterOption[] = [];
  const result = childOptions.filter(child => regex.test(child.name));
  childOptions.map(childValue => {
    if (childValue.children) {
      const options = getHierarchicalFilterChildResults(childValue.children, regex);
      newOptions = [...newOptions, ...options];
    }
  });
  return [...newOptions, ...result];
};

const findParent = (filterOptions: FilterOption, child: any) => {
  let parent;
  if (filterOptions.children) {
    each(filterOptions.children, (option => {
      if (option.id === child.id) {
        parent = filterOptions;
        // when returning false it will stop the loadash each loop
        return false;
      } else if (option.children) {
        return findParent(option, child);
      }
    }));
  }
  return parent;
};

export const findResultSelection = (updateOptions: FilterOption[], result: FilterSearchResult) => {
  // finding search result selection active state
  let isSelected;
  each(updateOptions, (option) => {
    if (option.id === result.id) {
      isSelected = option.isSelected;
      // when returning false it will stop the loadash each loop
      return false;
    } else if (option.children) {
      const selection = findResultSelection(option.children, result);
      if (selection) {
        isSelected = selection;
      }
    }
    return;
  });
  return isSelected;
};

const recursiveChildClick = (filterOptions: FilterOption[], child: SearchFilterCut) => {
  return filterOptions.map((p) => {
    let parent;
    if (p.children) {
      parent = findParent(p, child);
    }
    if (p.id !== parent?.id) {
      if (p.children) {
        p.children = recursiveChildClick(p.children, child);
      }
      return p;
    } else {
      const newOption = {
        ...p,
        children: p.children?.map((c) => {
          if (c.id === child.id) {
            if (c.children) {
              return {
                ...c,
                isSelected: !c.isSelected,
                children: c.children?.map((val) => selectAllChildren(val, !c.isSelected)),
              };
            } else {
              return {
                ...c,
                isSelected: !c.isSelected,
              };
            }
          } else {
            return c;
          }
        }),
      };
      if (newOption.children?.every((val) => val.isSelected)) {
        return {
          ...newOption,
          isSelected: true,
        };
      } else {
        return {
          ...newOption,
          isSelected: false,
        };
      }
    }
  });
};

export const createHierarchicalSearchQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  cut: SearchFilterCut,
  currentQueryFilter?: QueryFilter,
) => {

  const hierarchicalFilterOptions = filter.cuts ? createHierarchicalFilterOptions(filter.cuts, currentQueryFilter) : [];
  const rqlMap = getRqlMap(hierarchicalFilterOptions);

  let updatedOptions: FilterOption[] = [];
  if (cut.id === cut.individualId) {
    // reading parent click here
    updatedOptions = hierarchicalFilterOptions.map((p) => {
      if (p.id === cut.id) {
        return {
          ...p,
          isSelected: !p.isSelected,
          children: p.children?.map((c) => selectAllChildren(c, !p.isSelected)),
        };
      } else {
        return p;
      }
    });
  } else {
    // reading child click here
    updatedOptions = recursiveChildClick(hierarchicalFilterOptions, cut);
  }

  const combinedRql = calculateHierarchicalFilterRql(updatedOptions, rqlMap);

  const newSelectedCuts: AnalysisFilterCut[] = [];

  for (let i = 0; i < updatedOptions.length; i++) {
    const parent = updatedOptions[i];
    const parentSelection = getParentSelectionStateFromChildren(parent);

    if (parentSelection === 'all') {
      newSelectedCuts.push({ id: parent.id, name: parent.name, rql: '' });
    } else if (parent.children) {
      const selectedOptions = recursiveHierarchicalQueryFilter(parent.children);
      newSelectedCuts.push(...selectedOptions);
    }
  }

  // assigning all the queries to the first option
  if (newSelectedCuts.length > 0) {
    newSelectedCuts[0].rql = combinedRql;
  }

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'hierarchical',
    selected: newSelectedCuts,
  };
};