import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "components/Shared/Tooltip";
import fuzzNum from 'vue/libs/fuzz-num';
import { AnswersDataSet, TranslatedFilter } from 'api/interfaces';
import './context-metadata-tooltip.scss';

interface Props {
  totalCommentsCount: number;
  dataSets: AnswersDataSet[];
  filters: TranslatedFilter[];
}

export const ContextMetadataTooltip = ({
  totalCommentsCount,
  dataSets,
  filters
}: Props): JSX.Element => {
  const isAll = filters.length === 0;

  return (
    <Tooltip
      position="right center"
      className="context-metadata-tooltip"
      hideOnScroll={true}
      content={
        <span className="context-metadata-tooltip__content">
          <p className="context-metadata-tooltip__total-comments">
            Based on {fuzzNum(totalCommentsCount)} comments
          </p>
          {dataSets.length > 0 &&
            <div className="context-metadata-tooltip__item">
              <span className="context-metadata-tooltip__item-title">Datasets</span>
              <span className="context-metadata-tooltip__item-value">
                {dataSets.map((dataSet) => dataSet.title).join(', ')}
              </span>
            </div>
          }
          {isAll && (
            <div className="context-metadata-tooltip__item">
              <span className="context-metadata-tooltip__item-title">Filters</span>
              <span className="context-metadata-tooltip__item-value">All</span>
            </div>
          )}
          {filters.map(({ filterName, filterValues }, index) => {
            return (
              <div className="context-metadata-tooltip__item" key={`${filterName}-${index}`}>
                <span className="context-metadata-tooltip__item-title">{filterName}</span>
                <span className="context-metadata-tooltip__item-value">{filterValues}</span>
              </div>
            );
          })}
        </span>
      }
      on={['hover', 'focus']}
      inverted={true}
      trigger={
        <FontAwesomeIcon icon="info-circle" className="context__info-icon" tabIndex={0} />
      }
    />
  );
}
