import { FilterType } from 'api/enums';
import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { FilterOption } from 'lib/filters/hierarchical-filter-helper';
import { AnalysisFilterKey, QueryFilter } from 'stores/types';

const buildCategoryRql = (categoryId: string) => {
  let rql: string[] = [];
  rql.push(`category==${ categoryId }`);
  return rql.join(',');
};

const categories: Record<string, string> = {
  'issue': 'Issues',
  'question': 'Questions',
  'request': 'Requests',
}

export const createCategoryFilterOptions = (queryFilter?: QueryFilter): FilterOption[] => {
  const selected = queryFilter?.selected;
  // TODO: this should be sourced from the server
  return Object.keys(categories).map(id => {
    const isSelected = !!selected?.length && selected.some((s) => s.id === id);
    return {
      id,
      name: categories[id],
      isSelected,
    };
  });
};

export const createCategoryCut = (id: string): AnalysisFilterCut => {
  return {
    id: id,
    name: categories[id],
    rql: buildCategoryRql(id),
  };
};

export const createCategoryQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  options: FilterOption[],
) => {
  const selected: AnalysisFilterCut[] = [];
  options.forEach((parent) => {
    if (parent.isSelected) {
      selected.push({ id: parent.id, name: parent.name, rql: buildCategoryRql(parent.id) });
    }
    parent.children?.forEach((child) => {
      if (child.isSelected) {
        selected.push(createCategoryCut(child.id));
      }
    });
  });

  return {
    filterKey: filterKey,
    filterId: filter.id,
    filterName: filter.name,
    filterType: 'category',
    selected,
  };
};

export const createCategorySearchQueryFilter = (
  filter: AnalysisFilter,
  filterKey: AnalysisFilterKey,
  cut: AnalysisFilterCut,
  queryFilter?: QueryFilter
) => {
  const options = createCategoryFilterOptions(queryFilter);
  const updatedOptions = options.map(option => {
    if (option.id === cut.id) {
      return {
        ...option,
        isSelected: !option.isSelected
      };
    }
    return option;
  });
  const selectedOptionsQuery = createCategoryQueryFilter(filter, filterKey, updatedOptions);

  return selectedOptionsQuery;
};

export const createCategoryFilter = (): AnalysisFilter => ({
  cuts: Object.keys(categories).map(createCategoryCut),
  id: 'categories',
  name: 'Categories',
  type: FilterType.CATEGORIES,
});
