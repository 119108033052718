import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoleDetailsJson, RoleJson, User, UserSeatType } from 'api/interfaces';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Tooltip } from 'components/Shared/Tooltip';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { getUsersRoute } from 'lib/route-helper';
import { compact, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Checkbox, CheckboxProps,
  Form,
  FormInputProps,
  Header, Input,
  Label, Loader, Message,
  Modal,
  Segment
} from 'semantic-ui-react';
import { ManageUsersStoreInterface } from 'stores/ManageUsersStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import {
  DEFAULT_ROLE_DETAILS,
  RoleDetails,
  RoleStoreInterface,
  TEMP_USER_SPECIFIC_ROLE_ID
} from 'stores/RoleStore';
import { CreateUserJson } from 'stores/UserStore';
import { hasScopesSelected, mapSelectedPermissions } from 'stores/utils/roles';
import {
  FieldValidation,
  initialValidationState, isEmail,
  isName,
  required
} from 'validations';
import analytics from '../../lib/analytics';
import Permissions from './Permissions';
import Roles from './Roles';
import SeatTypeSelector from './SeatTypeSelector';
import './manage-user.scss';

export interface ManageUserParams {
  userId?: string;
  orgId: string;
}

export interface ManageUserProps extends RouteComponentProps<ManageUserParams> {
  manageUsersStore?: ManageUsersStoreInterface;
  organizationStore?: OrganizationStoreInterface;
  roleStore?: RoleStoreInterface;
}
export interface ManageUserState {
  email: FieldValidation<string>;
  firstName: FieldValidation<string>;
  lastName: FieldValidation<string>;
  preferredName: FieldValidation<string>;
  passwordAccessAllowed: FieldValidation<boolean>;
  roles: FieldValidation<RoleJson[]>;
  seatType: UserSeatType;
  inititalSeatType: UserSeatType;

  showDelete: boolean;
  showCustomPermissions: boolean;
  loading: boolean;
  displaySeatTypeDidntChangeMessage: boolean;
}

@inject('manageUsersStore', 'roleStore', 'organizationStore')
@observer
class ManageUser extends React.Component<ManageUserProps, ManageUserState> {
  nameInput: Input | null;

  get userId(): string | undefined {
    const { userId } = this.props.match.params;
    return userId;
  }
  get roleId(): string {
    // Can either have a user specific role already or it is new
    return this.userSpecificRoleId || TEMP_USER_SPECIFIC_ROLE_ID;
  }
  get manageUsersStore() {
    return this.props.manageUsersStore!;
  }
  get roleStore() {
    return this.props.roleStore!;
  }
  get organizationStore() {
    return this.props.organizationStore!;
  }
  get user(): User | undefined {
    const { getUser } = this.manageUsersStore;
    if (!this.userId) {
      return;
    }
    return getUser(this.userId);
  }
  get userSpecificRoleId(): string | undefined {
    if (!this.user) {
      return undefined;
    }
    return (this.user.userSpecificRole || {}).id;
  }
  get roleDetails(): RoleDetails {
    return this.roleStore.roleDetails[this.roleId];
  }
  get canManageRoles(): boolean {
    return this.organizationStore.getCanAction('manage:role');
  }
  get isValid(): boolean {
    const {
      email,
      firstName,
      lastName,
      roles
    } = this.state;

    const isEmailValid = email.value.length > 0 && !email.error;
    const isFirstNameValid = firstName.value.length > 0 && !firstName.error;
    const isLastNameValid = lastName.value.length > 0 && !lastName.error;
    const hasRoles = roles.value.length > 0;
    const hasCustomPermissionsEnabled = hasScopesSelected(this.roleDetails);

    return isEmailValid && isFirstNameValid && isLastNameValid && (hasRoles || hasCustomPermissionsEnabled);
  }
  get isPristine(): boolean {
    const {
      email,
      firstName,
      lastName,
      preferredName,
      passwordAccessAllowed,
      roles,
      seatType,
      inititalSeatType
    } = this.state;

    const pristine =
      email.pristine &&
      firstName.pristine &&
      lastName.pristine &&
      preferredName.pristine &&
      passwordAccessAllowed.pristine &&
      roles.pristine &&
      seatType === inititalSeatType;

    return pristine;
  }
  get hasUnsavedPermissions(): boolean {
    return this.roleStore.hasChanged(this.roleId);
  }
  get hasUnsavedChanges(): boolean {
    return !this.isPristine || (this.canManageRoles && this.hasUnsavedPermissions);
  }
  constructor(props: ManageUserProps) {
    super(props);

    this.state = {
      email: initialValidationState(''),
      firstName: initialValidationState(''),
      lastName: initialValidationState(''),
      preferredName: initialValidationState(''),
      passwordAccessAllowed: initialValidationState(false),
      roles: initialValidationState([] as RoleJson[]),
      showDelete: false,
      showCustomPermissions: false,
      loading: false,
      seatType: 'core',
      inititalSeatType: 'core',
      displaySeatTypeDidntChangeMessage: false
    };
  }
  componentDidMount() {
    analytics.track('User Mgmt: User detail view', { category: 'User Mgmt' });

    if (this.nameInput) {
      this.nameInput.focus();
    }
    this.initialize();
  }
  componentDidUpdate(prevProps: ManageUserProps) {
    if (prevProps.match.params.userId !== this.userId) {
      this.initialize();
    }
  }
  componentWillUnmount() {
    this.manageUsersStore.resetCurrentUser();
    this.roleStore.resetRoleState(this.roleId);
  }
  initialize = async () => {
    this.manageUsersStore.resetCurrentUser();

    await this.fetchData();
    this.initializeUser();
  }
  initializeUser = () => {
    const { user } = this;
    const { hasIdentityProvider } = this.organizationStore;

    const pristine = true;
    const error = undefined;

    let email = '';
    let firstName = '';
    let lastName = '';
    let preferredName = '';
    let roles = [] as RoleJson[];
    let seatType: UserSeatType = 'core';
    // default depends on if has id provider (assume no password if has one)
    let passwordAccessAllowed = !hasIdentityProvider;

    if (user) {
      email = user.email;
      firstName = user.firstName || '';
      lastName = user.lastName || '';
      preferredName = user.preferredName || '';
      passwordAccessAllowed = user.passwordAccessAllowed;
      roles = user.roles || [] as RoleJson[];
      seatType = user.seatType;
    }

    this.setState({
      email: { value: email, pristine, error },
      firstName: { value: firstName, pristine, error },
      lastName: { value: lastName, pristine, error },
      preferredName: { value: preferredName, pristine, error },
      roles: { value: roles, pristine, error },
      passwordAccessAllowed: { value: passwordAccessAllowed, pristine, error },
      seatType,
      inititalSeatType: seatType
    });
  }
  fetchData = async () => {
    const { users } = this.manageUsersStore;
    const { roles, totalRoles } = this.roleStore;

    this.setState({ loading: true });

    // TODO: empty users is not a reliable way of checking if users have been fetched
    if (isEmpty(users)) {
      // We fetch users instead of just the current user when editing because when
      // the update is complete / cancelled, we would go back to the users list which
      // would require this information anyway and users list has the required user info
      await this.manageUsersStore.fetchUsers();
    }
    // TODO: empty roles is not a reliable way of checking if roles have been fetched
    if (isEmpty(roles) || roles.length < totalRoles) {
      // Display all roles
      await this.roleStore.fetchRoles(250);
    }

    // If role details already exists in list of roles, map it to displayable format
    // Otherwise, fetch role details and map it to displayable format
    if (!this.roleStore.roleDetails[this.roleId]) {
      if (this.roleId !== TEMP_USER_SPECIFIC_ROLE_ID) {
        let roleDetails;
        const roleIndex = this.roleStore.roles.findIndex(role => role.id === this.roleId);
        if (roleIndex < 0) {
          roleDetails = await this.roleStore.fetchRoleDetails(this.roleId);
        } else {
          roleDetails = this.roleStore.roles[roleIndex];
        }
        if (!this.roleStore.fetchRoleDetailsError) {
          this.roleStore.roleDetails[this.roleId] = mapSelectedPermissions(roleDetails);
        }
      } else {
        this.roleStore.roleDetails[this.roleId] = DEFAULT_ROLE_DETAILS;
      }
    }
    this.setState({ loading: false });
  }

  validate = (name: string, value: string): FieldValidation<string> | undefined => {
    let error;
    if (name === 'email') {
      error = required(value, 'Email') || isEmail(value);
    } else if (name === 'firstName') {
      error = required(value, 'A user first name') || isName(value);
    } else if (name === 'lastName') {
      error = required(value, 'A user last name') || isName(value);
    } else if (name === 'preferredName') {
      error = undefined;
    } else {
      return undefined;
    }
    return { error, pristine: false, value };
  };
  handleInputChange = ({ name, value }: FormInputProps) => {
    const update = this.validate(name, value);
    if (!update) {
      return;
    }
    if (name === 'email') {
      this.setState({ email: update });
    } else if (name === 'firstName') {
      this.setState({ firstName: update });
    } else if (name === 'lastName') {
      this.setState({ lastName: update });
    } else if (name === 'preferredName') {
      this.setState({ preferredName: update });
    }
  };
  handlePasswordAllowedChange = ({ checked }: CheckboxProps) => {
    const passwordAccessAllowed = { pristine: false, value: !!checked, error: undefined };
    this.setState({ passwordAccessAllowed });
  };
  handleRoleChange = (roles: RoleJson[]) => {
    const updatedRoles = { value: roles, pristine: false, error: undefined };
    this.setState({ roles: updatedRoles });
  };
  goBack = () => {
    this.props.history.push(getUsersRoute(this.props.match.params.orgId));
  }
  assumeUser = async () => {
    if (!this.userId) {
      return;
    }
    const orgId = this.props.match.params.orgId;
    await this.manageUsersStore.assumeUser(this.userId, orgId);
  }
  showCustomPermissions = () => {
    analytics.track('User Mgmt: Custom permissions view', { category: 'User Mgmt' });

    this.setState({ showCustomPermissions: true });
  }
  closeCustomPermissions = () => {
    this.setState({ showCustomPermissions: false });
  }
  showDelete = () => {
    this.setState({ showDelete: true });
  };
  cancelDelete = () => {
    this.setState({ showDelete: false });
  };
  delete = async () => {
    if (!this.userId) {
      return;
    }
    analytics.track('User Mgmt: User delete', { category: 'User Mgmt' });
    await this.manageUsersStore.delete(this.userId);
    this.setState({ showDelete: false });

    const { deleteError } = this.manageUsersStore;
    if (!deleteError) {
      if (this.userSpecificRoleId) {
        this.roleStore.removeRoleFromState(this.userSpecificRoleId);
      }
      this.manageUsersStore.removeUserFromState(this.userId);
      this.goBack();
    }
  };
  saveUser = async () => {
    if (!this.isValid) {
      return;
    }

    const {
      email,
      firstName,
      lastName,
      preferredName,
      passwordAccessAllowed,
      roles,
      seatType
    } = this.state;

    let user = {
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      passwordAccessAllowed: passwordAccessAllowed.value,
      preferredName: preferredName ? preferredName.value : undefined,
      roles: roles.value,
      seatType
    };

    if (this.user) {
      let userToUpdate = { ...this.user, ...user } as User;
      await this.update(userToUpdate);
    } else {
      await this.create(user);
    }
  };
  update = async (user: User) => {
    let updatedUser;

    let success = true;
    // Only update user if there are any changes to the user details
    if (!this.isPristine) {
      analytics.track('User Mgmt: User update', { category: 'User Mgmt' });

      updatedUser = await this.manageUsersStore.update(user);

      if (!this.manageUsersStore.updateError && !this.manageUsersStore.seatError) {
        // TODO: API doesnt return user specific role, remove when it returns
        updatedUser.userSpecificRole = user.userSpecificRole;
        this.manageUsersStore.refreshUsersState(updatedUser);
      }

      if (this.manageUsersStore.seatError) {
        analytics.track('User Mgmt: Failed to update seat', { category: 'User Mgmt', code: this.manageUsersStore.seatError.code, operation: 'update' });
        this.setState({ displaySeatTypeDidntChangeMessage: true });
        success = false; // don't go back because we need to show a modal (which will trigger the goback)
      }
    } else {
      updatedUser = user;
    }

    if (this.canManageRoles) {
      if (this.hasUnsavedPermissions) {
        // Delete the user specific role if permissions were cleared
        if (this.userSpecificRoleId && !hasScopesSelected(this.roleDetails)) {
          await this.roleStore.deleteRole(this.userSpecificRoleId);

          if (!this.roleStore.deleteRoleError) {
            // Refresh user info with updated role information
            this.manageUsersStore.removeRoleForUsers(this.userSpecificRoleId);
          } else {
            success = false;
          }
        } else {
          success = success && await this.createCustomPermissions(updatedUser);
        }
      }
    }
    if (success) {
      this.goBack();
    }
  }
  create = async (user: CreateUserJson) => {
    analytics.track('User Mgmt: User create', { category: 'User Mgmt' });

    const createdUser = await this.manageUsersStore.create(user);

    let success = true;
    if (!this.manageUsersStore.createError) {
      this.manageUsersStore.refreshUsersState(createdUser);

      if (this.canManageRoles && this.hasUnsavedPermissions) {
        success = success && await this.createCustomPermissions(createdUser);
      }
      if (this.manageUsersStore.seatError) {
        analytics.track('User Mgmt: Failed to update seat', { category: 'User Mgmt', code: this.manageUsersStore.seatError.code, operation: 'create' });
        this.setState({ displaySeatTypeDidntChangeMessage: true });
        success = false; // don't go back because we need to show a modal (which will trigger the goback)
      }
    }
    if (success) {
      this.goBack();
    }
  }
  finishDisplaySeatTypeDidntChangeMessage = () => {
    this.manageUsersStore.resetSeatError();
    this.setState({ displaySeatTypeDidntChangeMessage: false });
    this.goBack();
  }
  createCustomPermissions = async (user: User) => {
    const userSpecificRole = await this.roleStore.createCustomPermissions(user.id, this.roleDetails);

    if (!this.roleStore.createCustomPermissionsError) {
      let updatedUser = { ...user, userSpecificRole };
      // Update user data with updated custom permissions
      this.manageUsersStore.refreshUsersState(updatedUser);

      // Reset temporary custom permissions
      this.roleStore.resetRoleState(TEMP_USER_SPECIFIC_ROLE_ID);
      return true;
    }
    return false;
  }
  renderDelete = () => {
    const { deleting } = this.manageUsersStore;
    return (
      <ConfirmationModal
        confirmationButtonText="Delete"
        danger={true}
        processing={deleting}
        cancel={this.cancelDelete}
        confirm={this.delete}
      />
    );
  };
  renderCustomPermissions = () => {
    let userEmail = this.user ? this.user.email : undefined;

    return <Permissions
      roleId={this.roleId}
      userEmail={userEmail}
      close={this.closeCustomPermissions}
    />;
  }
  renderSeatError = () => {
    const {
      seatError
    } = this.manageUsersStore;

    if (!seatError) {
      return null;
    }

    let header = 'We were unable to allocate a seat to this user';
    let message = 'Please try again later. If the problem persists, please contact support.';

    if (seatError.code == 'NO_SEATS_AVAILABLE') {
      header = 'There are no seats available';
      message = `You are currently at your maximum number of ${ seatError.seatType } user seats.  Please contact your Thematic Customer Success Manager to unlock more seats.  Thank you!`;

    }
    else if (seatError.code == 'ALREADY_HAS_BETTER_SEAT') {
      header = 'This user already has a seat';
      message = 'The user was already in another workspace so they still have the seat they were previously assigned.';
    }

    return (<Modal
      open={true}
      size="small"
      onClose={this.finishDisplaySeatTypeDidntChangeMessage}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={this.finishDisplaySeatTypeDidntChangeMessage}
        >
          OK
        </Button>
      </Modal.Actions>
    </Modal>);
  }
  render(): JSX.Element | null {
    const { hasIdentityProvider } = this.organizationStore;
    const { fetchingUsers } = this.manageUsersStore;
    const user = this.user;

    const {
      creating,
      createError,
      deleting,
      deleteError,
      updating,
      updateError,
      assumingUser,
      assumeUserError
    } = this.manageUsersStore;

    const {
      creatingCustomPermissions,
      createCustomPermissionsError,
      deletingRole,
      deleteRoleError
    } = this.roleStore;

    const userExists = user && user.invitedAt;

    const {
      email,
      firstName,
      lastName,
      passwordAccessAllowed,
      preferredName,
      roles,
      seatType,
      showDelete,
      showCustomPermissions,
      loading,
      displaySeatTypeDidntChangeMessage
    } = this.state;

    const errors = compact([
      deleteError,
      updateError,
      createError,
      deleteRoleError,
      createCustomPermissionsError,
      assumeUserError
    ]);

    const availableRoles = (this.roleStore.roles
      .filter(role => !role.isUserSpecific)) || [] as RoleDetailsJson[];

    const showRoles = !isEmpty(availableRoles);
    const selectedRoles = roles.value;
    const hasRolesSelected = selectedRoles.length > 0;
    const hasCustomPermissionsEnabled = hasScopesSelected(this.roleDetails);

    const performingEvent = deleting || creating || updating ||
      creatingCustomPermissions || deletingRole;
    const disabled = !this.isValid || !this.hasUnsavedChanges || performingEvent;
    const canEdit = userExists || !this.userId;
    const noSuchUser = !canEdit && !fetchingUsers;
    const needsToConfirmSeatChange = this.organizationStore.hasMultipleWorkspaces;
    const assumeUserAllowed = FeatureFlagManager.checkFlag(FlagKeys.CAN_ASSUME_USER);

    if (noSuchUser) {
      return (
        <Segment>
          <Header>No such user exists</Header>
        </Segment>
      );
    }

    if (loading) {
      return (
        <Segment className="fullscreen nw--manage-user">
          <Loader size="large" content="Loading..." active={loading} />
        </Segment>
      );
    }

    return (
      <Segment className="fullscreen nw--manage-user">
        <Header>
          <button className="nw--back" onClick={this.goBack} type="button" aria-label="Go back">
            <FontAwesomeIcon
              size="lg"
              className="icon"
              icon="arrow-alt-circle-left"
            />
          </button>
          {userExists ? 'Edit user' : 'Create user'}
        </Header>

        {showCustomPermissions && this.renderCustomPermissions()}

        {showDelete && this.renderDelete()}

        <Segment className="white content">
          {errors.map(error => {
            return (
              <Message
                key={error}
                className="error"
                negative={true}
                header={error}
              />
            );
          })}
          <Form>
            <Form.Field>
              <label htmlFor="email">Login Email</label>
              <Input
                id="email"
                autoComplete="off"
                readOnly={!!user}
                ref={input => {
                  this.nameInput = input;
                }}
                name="email"
                onChange={(_e, data) => this.handleInputChange(data)}
                placeholder="user@example.com"
                value={email.value}
              />
              {!email.pristine && email.error}
            </Form.Field>
            <Form.Field>
              <label htmlFor="firstName">First Name</label>
              <Input
                id="firstName"
                name="firstName"
                autoComplete="off"
                onChange={(_e, data) => this.handleInputChange(data)}
                placeholder="John"
                value={firstName.value}
              />
              {!firstName.pristine && firstName.error}
            </Form.Field>
            <Form.Field>
              <label htmlFor="lastName">Last Name</label>
              <Input
                id="lastName"
                name="lastName"
                autoComplete="off"
                placeholder="Doe"
                value={lastName.value}
                onChange={(_e, data) => this.handleInputChange(data)}
              />
              {!lastName.pristine && lastName.error}
            </Form.Field>
            <Form.Field>
              <label>Preferred Name (optional)</label>
              <Input
                name="preferredName"
                autoComplete="off"
                value={preferredName.value}
                onChange={(_e, data) => this.handleInputChange(data)}
              />
              {!preferredName.pristine && preferredName.error}
            </Form.Field>
            {hasIdentityProvider &&
              <Checkbox
                toggle={true}
                label="User can login with a password"
                className="nw--password-access"
                checked={passwordAccessAllowed.value}
                onChange={(_e, data) => this.handlePasswordAllowedChange(data)}
              />
            }
            <Form.Field>
              <label>Seat Type</label>
              <SeatTypeSelector
                userId={email.value}
                existingSeatType={seatType}
                needsToConfirmSeatChange={needsToConfirmSeatChange}
                onSeatChange={(_, newSeatType) => this.setState({ seatType: newSeatType })}
              />

            </Form.Field>
            {showRoles &&
              <Roles
                availableRoles={availableRoles}
                selectedRoles={selectedRoles}
                onRolesChange={this.handleRoleChange}
              />
            }
            {this.canManageRoles &&
              <div className="custom-permissions">
                <Button
                  className="nw--edit-permissions"
                  basic={true}
                  type="button"
                  disabled={performingEvent}
                  onClick={this.showCustomPermissions}
                >
                  Set custom permissions
                </Button>
                {hasCustomPermissionsEnabled &&
                  <Label color="green">
                    Enabled
                  </Label>
                }
              </div>
            }
          </Form>
          <div className="actions">
            <div className="left-actions">
              {userExists &&
                <Button
                  className="nw--delete-button"
                  color="red"
                  basic={true}
                  type="button"
                  disabled={performingEvent}
                  onClick={this.showDelete}
                >
                  Delete
                </Button>
              }
            </div>
            {assumeUserAllowed && (
              <Button
                disabled={performingEvent}
                loading={assumingUser}
                onClick={this.assumeUser}
                type="button"
              >
                Assume User
              </Button>)}
            <Button
              className="nw--cancel-button"
              disabled={performingEvent}
              onClick={this.goBack}
              type="button"
            >
              Cancel
            </Button>
            <Tooltip
              disabled={hasRolesSelected || hasCustomPermissionsEnabled}
              position="top center"
              inverted
              content="Please assign a role or custom permission"
              trigger={
                <span>
                  <Button
                    className="nw--update-button"
                    color="blue"
                    onClick={this.saveUser}
                    disabled={disabled}
                    loading={updating || creating || creatingCustomPermissions}
                  >
                    {userExists ? 'Update' : 'Add new user'}
                  </Button>
                </span>
              }
            />
          </div>
        </Segment>
        {displaySeatTypeDidntChangeMessage && this.renderSeatError()}

      </Segment>
    );
  }
}

export default ManageUser;
