import * as React from 'react';
import { Loader } from 'semantic-ui-react';
import './loading.scss';

type LoadingProps = {
  message: string,
};

export class Loading extends React.Component<LoadingProps> {

  render() {
    const { message } = this.props;
    return (
      <div className="answers__loading">
        <Loader size="large" active={true} />
        {message}
      </div>
    );
  }
}
