import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { Input, Popup } from 'semantic-ui-react';
import './tokenized-input.scss';

interface TokenizedInputProps {
  tokens: string[];
  inputText: string;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onTokensChange: (tokens: string[]) => void;
  onInputTextChange: (inputText: string) => void;
}

interface TokenizedInputState {
  inputWidth: number;
}

const MIN_POPUP_WIDTH = 200;

class TokenizedInput extends React.Component<TokenizedInputProps, TokenizedInputState> {
  inputRef = React.createRef<Input>();
  inputWrapperRef = React.createRef<HTMLDivElement>();
  state = {
    inputWidth: MIN_POPUP_WIDTH,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onDocumentKeyPress, true);
    this.setState({ inputWidth: this.getInputContainerWidth() });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeyPress, true);
  }

  onDocumentKeyPress = (e: KeyboardEvent) => {
    const { inputText, onInputTextChange } = this.props;
    if (e.key === 'Escape' && inputText.length > 0) {
      onInputTextChange('');

      // Stop propagation here means that if this component is inside a popup, pressing escape
      // will just clear the current input, but not close the popup.
      e.stopPropagation();
    }
  };

  createToken = () => {
    const { tokens, inputText, onTokensChange, onInputTextChange } = this.props;
    if (inputText.length > 0) {
      if (!tokens.includes(inputText)) {
        onTokensChange([...tokens, inputText]);
      }
      onInputTextChange('');
    }
    this.inputRef.current?.focus();
  };

  getInputContainerWidth = (): number => {
    return this.inputWrapperRef.current?.getBoundingClientRect().width || MIN_POPUP_WIDTH;
  }

  deleteToken = (index: number) => {
    const { tokens, onTokensChange } = this.props;
    onTokensChange(tokens.filter((_, i) => i !== index));
  };

  render() {
    const { tokens, inputText, className, placeholder, autoFocus, onInputTextChange } = this.props;
    const { inputWidth } = this.state;
    return (
      <Popup
        open={!!inputText}
        position='bottom left'
        popper={<div style={{ filter: 'none' }} />} // to prevent the popup having blurred effect if opened inside a modal
        basic={true}
        className={classNames('tokenized-input', className)}
        trigger={
          <div
            ref={this.inputWrapperRef}
            className="tokenized-input__input-wrapper"
            onClick={() => this.inputRef.current?.focus()}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          >
            {tokens.map((token, i) => (
              <div key={i} className="token" onClick={(e) => e.stopPropagation()}>
                <span>{token}</span>
                <button className="plain" onClick={() => this.deleteToken(i)}>
                  <FontAwesomeIcon icon="times" />
                </button>
              </div>
            ))}
            <Input
              autoFocus={autoFocus}
              placeholder={tokens.length === 0 ? placeholder : undefined}
              value={inputText}
              ref={this.inputRef}
              onChange={(e) => onInputTextChange(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent) => e.key === 'Enter' && this.createToken()}
              onBlur={this.createToken}
            />
          </div>
        }
        content={
          <button
            className="add-token-button plain"
            style={{
              width: inputWidth,
              maxWidth: inputWidth,
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={this.createToken}
          >
            <span>Add "</span>
            <span className="input-text">{inputText}</span>
            <span>"</span>
          </button>
        }
        on='click'
      />
    );
  }
}

export default TokenizedInput;
